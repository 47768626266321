import React from 'react';
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import moment from 'moment'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import PrimarySelect from '../../../../PrimarySelect/PrimarySelect'
import {apiGetDistinctShippingDates} from '../../../../../api'

const styles = ({
  selectFormControl: {
    marginBottom: 20
  },
});

const SHIPPING_FILTER_TOMORROW = 'tomorrow';
const SHIPPING_FILTER_LAST_WEEK = 'last_week';
const SHIPPING_FILTER_THIS_WEEK = 'this_week';
const SHIPPING_FILTER_NEXT_WEEK = 'next_week';
const SHIPPING_FILTER_THIS_MONTH = 'this_month';
const SHIPPING_FILTER_LAST_MONTH = 'last_month';
const SHIPPING_FILTER_PAST_THREE_MONTH = 'past_3_months';
const SHIPPING_FILTER_PAST_6_MONTHS = 'past_6_months';
const SHIPPING_FILTER_PAST_YEAR = 'past_year';
const SHIPPING_FILTER_CUSTOM = 'custom';

const propTypes = {
  shared: PropTypes.object,
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onChangeShared: PropTypes.func.isRequired,
};
const defaultProps = {
  selected: {},
};

class DXShippingFilterVariants extends React.Component {

  state = {
    distinctShippingDates: [],
  };

  componentDidMount() {
    this._getDistinctShippingDates();
  }

  getCopy = (object) => {
    return JSON.parse(JSON.stringify(object))
  };

  handleChangeFilterType = (type) => {
    this.props.onChangeShared({filterType: type}, () => {
      if (type !== SHIPPING_FILTER_CUSTOM) {

        let selected = [];

        let nonDateFilterTypes = [
          SHIPPING_FILTER_TOMORROW,
          SHIPPING_FILTER_THIS_WEEK,
          SHIPPING_FILTER_NEXT_WEEK,
          SHIPPING_FILTER_LAST_WEEK,
          SHIPPING_FILTER_THIS_MONTH,
          SHIPPING_FILTER_LAST_MONTH,
          SHIPPING_FILTER_PAST_THREE_MONTH,
          SHIPPING_FILTER_PAST_6_MONTHS,
          SHIPPING_FILTER_PAST_YEAR
        ];

        if (nonDateFilterTypes.indexOf(type) !== -1) {
          selected.push({
            predefined: type,
          });
        } else {
          selected.push({
            day: type
          });
        }

        this.props.onChange(selected)
      }
    });
  };

  _getDistinctShippingDates = () => {
    apiGetDistinctShippingDates(
      dates => this.setState({distinctShippingDates: dates.slice(0, 10)}),
      error => console.error(error)
    );
  };

  render () {
    const {
      intl,
      classes
    } = this.props;

    return (
      <PrimarySelect
        label={intl.formatMessage({id: 'global.filter'})}
        value={this.props.shared.filterType}
        fullWidth
        onChange={e => this.handleChangeFilterType(e.target.value)}
        formControlClassName={classes.selectFormControl}
      >
        <MenuItem key={-1} value={-1}>
          {intl.formatMessage({id: 'global.selectFilter'})}
        </MenuItem>
        <MenuItem key={SHIPPING_FILTER_TOMORROW} value={SHIPPING_FILTER_TOMORROW}>
          {intl.formatMessage({id: 'filter.date.tomorrow'})}
        </MenuItem>
        <MenuItem key={SHIPPING_FILTER_THIS_WEEK} value={SHIPPING_FILTER_THIS_WEEK}>
          {intl.formatMessage({id: 'filter.date.this_week'})}
        </MenuItem>
        <MenuItem key={SHIPPING_FILTER_NEXT_WEEK} value={SHIPPING_FILTER_NEXT_WEEK}>
          {intl.formatMessage({id: 'filter.date.next_week'})}
        </MenuItem>
        <MenuItem key={SHIPPING_FILTER_LAST_WEEK} value={SHIPPING_FILTER_LAST_WEEK}>
          {intl.formatMessage({id: 'filter.date.last_week'})}
        </MenuItem>
        <MenuItem key={SHIPPING_FILTER_THIS_MONTH} value={SHIPPING_FILTER_THIS_MONTH}>
          {intl.formatMessage({id: 'filter.date.this_month'})}
        </MenuItem>
        <MenuItem key={SHIPPING_FILTER_LAST_MONTH} value={SHIPPING_FILTER_LAST_MONTH}>
          {intl.formatMessage({id: 'filter.date.last_month'})}
        </MenuItem>
        <MenuItem key={SHIPPING_FILTER_PAST_THREE_MONTH} value={SHIPPING_FILTER_PAST_THREE_MONTH}>
          {intl.formatMessage({id: 'filter.date.past_3_months'})}
        </MenuItem>
        <MenuItem key={SHIPPING_FILTER_PAST_6_MONTHS} value={SHIPPING_FILTER_PAST_6_MONTHS}>
          {intl.formatMessage({id: 'filter.date.past_6_months'})}
        </MenuItem>
        <MenuItem key={SHIPPING_FILTER_PAST_YEAR} value={SHIPPING_FILTER_PAST_YEAR}>
          {intl.formatMessage({id: 'filter.date.past_year'})}
        </MenuItem>
        <MenuItem key={SHIPPING_FILTER_CUSTOM} value={SHIPPING_FILTER_CUSTOM}>
          {intl.formatMessage({id: 'filter.date.custom'})}
        </MenuItem>

        {this.state.distinctShippingDates.map((date) => (
          <MenuItem key={date} value={date}>{moment(date).format("MMM D, ddd")}</MenuItem>
        ))}
      </PrimarySelect>
    )
  }
}

DXShippingFilterVariants.propTypes = propTypes;
DXShippingFilterVariants.defaultProps = defaultProps;

export default injectIntl(
  withStyles(styles)(DXShippingFilterVariants)
);
