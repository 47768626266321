import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import ScheduleDates from '../../../entities/ScheduleDates';
import ErrorBox from '../../ErrorBox/ErrorBox';
import { ScheduleDialog } from '../../../dialogs';
import { Icon } from '../../index';
import { COLORS } from '../../../helpers';
import IconButton from '@material-ui/core/IconButton';
import { preventClickDefault } from '../../../helpers/events';
import Portal from '../../Portal/Portal';

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  applyOnChange: PropTypes.bool,
  cellProps: PropTypes.object.isRequired,
  onFinishCellEditing: PropTypes.func.isRequired,
  dateFormat: PropTypes.string,
  datePickerProps: PropTypes.object,
  onDateMoveSeries: PropTypes.func,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  forVariant: PropTypes.bool,
  variantId: PropTypes.string,
  strictMode: PropTypes.bool, // update values only from back end
  onClose: PropTypes.func,
  onDateDelete: PropTypes.func,
  asIcon: PropTypes.bool,
  iconComponent: PropTypes.any,
  ValueComponent: PropTypes.elementType,
  openOnSingleClick: PropTypes.bool,
};

const defaultProps = {
  asIcon: false,
  applyOnChange: false,
  dateFormat: process.env.REACT_APP_DATE_FORMAT_WITH_SHORT_DAY_OF_WEEK,
  datePickerProps: {},
  forVariant: false,
  onClose: () => {},
};

const styles = (theme) => ({
  date: {},
  errorBoxWrapper: {
    marginTop: 10,
  },
  paper: {
    padding: 26,

    borderRadius: 10,
    maxHeight: 'calc(100% - 16px)',
    [theme.breakpoints.down('md')]: {
      margin: 10,
      minWidth: 344,
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 450,
    },
    boxShadow: 'box-shadow: 0px 14px 24px 0px #00000026',
    '& *': {
      fontFamily: 'Inter, sans-serif',
    },
  },

  pickerWrapper: {
    width: '100%',
    borderBottom: '1px solid #00000028',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'space-between',
  },
  title: {
    padding: 0,
    margin: 0,

    fontSize: 24,
    fontWeight: 700,
  },
  close: {
    borderRadius: 30,
    cursor: 'pointer',
    padding: 4,
    width: 24,
    height: 24,
    boxSizing: 'content-box',
  },

  contentWrapper: {
    padding: 0,
    margin: 0,
    marginTop: 35,
    marginBottom: 35,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'visible',
  },

  actionsWrapper: {
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  nextDateWrapper: {},
  nextDateText: {
    fontSize: 18,
    fontWeight: 600,
    color: '#222',
  },
  actionsChooserWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  action: {
    cursor: 'pointer',
    fontSize: 16,
    fontWeight: 700,
    color: '#216CF6',
    paddingRight: 10,
    '&:not(:first-child)': {
      paddingLeft: 10,
      borderLeft: '1px solid #00000028',
    },
  },
  actionsTextWrapper: {
    marginTop: 15,
  },
  actionsText: {
    fontSize: 14,
    fontWeight: 400,
  },
  buttonWrapper: {
    marginTop: 35,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonRoot: {
    boxShadow: 'none',
    width: 'auto',
    minWidth: 'auto',
  },
  button: {
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: 18,
    fontFamily: 'Inter, sans-serif',
  },
  availableDay: {
    '& > button': {
      backgroundColor: '#F1F5FE',
    },
  },
});

const CellAsScheduler = (props) => {
  const {
    intl,
    classes,
    cellProps,
    style,
    dateFormat,
    datePickerProps,
    apiRetrieve,
    onDateMoveSeries,
    onDateChange,
    forceReloadTable,
    minDate,
    maxDate,
    forVariant,
    variantId,
    onDateDelete,
    onScheduleDataChange,
    asIcon,
    icon,
    ValueComponent,
    openOnSingleClick,
    absolutePicker,
    disableClick,
  } = props;

  const [cache, setCache] = useState(props.value);
  const [value, setValue] = useState(props.value);
  const [editingMode, setEditingMode] = useState(false);
  const [scheduleData, setScheduleData] = useState(new ScheduleDates({}));
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [isScheduleExist, setIsScheduleExist] = useState(false);

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, false);
    return () => document.removeEventListener('keydown', onKeyDown, false);
  });

  useEffect(() => {
    if (!!editingMode) {
      getScheduleData();
    }
  }, [editingMode]);

  const getScheduleData = () => {
    apiRetrieve(
      (data) => {
        const scheduleData = new ScheduleDates(data);
        let nextDate = scheduleData.getNextDate();

        if (forVariant && !!variantId) {
          const currentVariant = scheduleData.getVariantById(variantId);
          if (!!currentVariant) {
            nextDate = currentVariant.nextDate;
          }
        }

        setScheduleData(scheduleData);
        setValue(nextDate);
        setCache(nextDate);

        setLoading(false);
        setIsScheduleExist(true);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const onError = (error) => {
    setLoading(false);
    setError(error?.response?.data?.message || intl.formatMessage({ id: 'global.somethingWentWrong' }));
  };

  const onCellClick = (event) => {
    preventClickDefault(event);
    setError('');

    setEditingMode(true);
  };

  const onFinishCellEditing = (newValue = value) => {
    const { onFinishCellEditing, strictMode } = props;

    if (!strictMode) {
      setCache(newValue);
      setValue(newValue);
      setEditingMode(false);
    } else {
      setValue(cache);
      setEditingMode(false);
    }

    onFinishCellEditing(newValue, () => {}, onError);
    onScheduleDataChange && onScheduleDataChange(scheduleData);
  };

  const onKeyDown = (event) => {
    const { key } = event;

    if (editingMode) {
      if (key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
        onFinishCellEditing();
      }

      if (key === 'Escape') {
        event.preventDefault();
        event.stopPropagation();
        setEditingMode(false);
        setValue(cache);
      }
    }
  };

  const onClose = () => {
    setValue(cache);

    setEditingMode(false);
    props.onClose && props.onClose(cache);
    forceReloadTable && forceReloadTable();
    onScheduleDataChange && onScheduleDataChange(scheduleData);
  };

  const drawCellValue = (cellValue) => (cellValue ? moment(cellValue).format(dateFormat) : '-//-');

  const onDateMoveSeriesDone = (data, onSuccess, onError) => {
    onDateMoveSeries(
      data,
      (response) => {
        // Make update value after saved on back end
        !!response?.nextDate && setCache(response.nextDate);
        !!response?.nextDate && setValue(response.nextDate);

        onSuccess(response);
      },
      onError
    );
  };

  const onDateChangeDone = (data, onSuccess, onError) => {
    onDateChange(
      data,
      (response) => {
        // Make update value after saved on back end
        !!response?.nextDate && setCache(response.nextDate);
        !!response?.nextDate && setValue(response.nextDate);

        onSuccess(response);
      },
      onError
    );
  };

  const getCellMode = () => {
    return (
      <Table.Cell
        {...cellProps}
        style={{
          cursor: 'pointer',
          userSelect: 'none',
          overflow: 'visible',
          ...style,
        }}
        {...(!disableClick && openOnSingleClick ? { onClick: onCellClick } : { onDoubleClick: onCellClick })}>
        {!!ValueComponent ? (
          <ValueComponent onClick={onCellClick} />
        ) : !editingMode ? (
          drawCellValue(value)
        ) : (
          drawCellValue(cache)
        )}

        {!!error && <ErrorBox error={error} className={classes.errorBoxWrapper} />}
      </Table.Cell>
    );
  };

  const getIconMode = () => {
    return (
      <IconButton onClick={onCellClick}>
        <Icon icon={icon} style={{ fill: COLORS.blue, fontSize: 18 }} className={classes.back} />
      </IconButton>
    );
  };

  return (
    <>
      {!asIcon ? getCellMode() : getIconMode()}

      <Portal>
        <div onClick={preventClickDefault}>
          {editingMode && (
            <ScheduleDialog
              datePickerProps={datePickerProps}
              onClose={onClose}
              onDone={onFinishCellEditing}
              scheduleData={scheduleData}
              onUpdateScheduleData={(newScheduleData) => setScheduleData(newScheduleData)}
              isScheduleExist={isScheduleExist}
              loading={loading}
              value={value}
              minDate={minDate}
              maxDate={maxDate}
              onDateMoveSeries={onDateMoveSeries && onDateMoveSeriesDone}
              onDateChange={onDateChange && onDateChangeDone}
              forVariant={forVariant}
              variantId={variantId}
              onDateDelete={onDateDelete}
              absolutePicker={absolutePicker}
              onChange={(newValue) => {
                setValue(newValue);
              }}
            />
          )}
        </div>
      </Portal>
    </>
  );
};

CellAsScheduler.propTypes = propTypes;
CellAsScheduler.defaultProps = defaultProps;

export default withStyles(styles)(injectIntl(CellAsScheduler));
