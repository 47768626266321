import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import AutoSuggest from 'react-autosuggest';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { withStyles } from '@material-ui/core/styles';
import InputComponent from './InputComponent';
import SuggestionComponent from './SuggestionComponent';
import { SearchCustomersParams } from '../../entities';
import { Spinner, ChipsSelect } from '../../components';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minWidth: 200,
  },
  container: {
    position: 'relative',
    width: 'auto',
  },
  popper: {
    zIndex: theme.zIndex.modal + 100,
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    display: 'block',
    width: 'auto',
    zIndex: 1,
    marginTop: theme.spacing(1),
    marginLeft: -15,
  },
  suggestion: {
    width: '100%',
    textAlign: 'left',
    backgroundColor: '#ffffff',
  },
  suggestionsList: {
    display: 'inline-block',
    width: 'auto',
    margin: 0,
    padding: 0,
    backgroundColor: '#ffffff',
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing(2),
  },
});

const propTypes = {
  onlyActive: PropTypes.bool,
  selected: PropTypes.object,
  inputProps: PropTypes.object,
  classes: PropTypes.object.isRequired,
  onChangeQuery: PropTypes.func.isRequired,
  onAttach: PropTypes.func.isRequired,
  onDetach: PropTypes.func.isRequired,
  clearAfterSelect: PropTypes.bool,
  retriever: PropTypes.func.isRequired,

  customerTypes: PropTypes.array,
  defaultCustomerType: PropTypes.string,
  allowSwitchCustomerType: PropTypes.bool,
  switchCustomerWrapperClass: PropTypes.string,
};

const defaultProps = {
  inputLabel: '',
  labelExtractor: (item) => `${item.name}`,
};

class AutoComplete extends React.Component {
  constructor(props) {
    super(props);

    const { defaultCustomerType, selected, labelExtractor } = this.props;

    let value = (selected && labelExtractor(selected)) || '';

    this.state = {
      value,
      customerType: defaultCustomerType,
      loading: false,
      hits: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { defaultCustomerType: prevCustomerTypeExternal } = prevProps;
    const { defaultCustomerType: newCustomerTypeExternal } = this.props;

    prevCustomerTypeExternal !== newCustomerTypeExternal && this.setState({ customerType: newCustomerTypeExternal });
  }

  refine(value) {
    const { retriever, onlyActive } = this.props;
    const { customerType } = this.state;

    let params = new SearchCustomersParams({
      query: value,
      limit: 10,
      type: customerType,
      active: onlyActive,
    });

    retriever(params, (response) => {
      const hits = response?.data || response;
      this.setState({ hits });
    });
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    this.refine(value);
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      hits: [],
    });
  };

  handleChange = (event, data) => {
    this.setState({ value: data.newValue });
  };

  onSelect = (event, data) => {
    this.props.onAttach(data.suggestion);
    if (this.props.clearAfterSelect) {
      this.setState({ value: '' });
    }
  };

  onDeselect = () => {
    this.props.onDetach();
    this.setState({ value: '' });
  };

  getCustomerTypes = () => {
    const { customerTypes, intl } = this.props;
    return customerTypes.map((type) => {
      return {
        label: intl.formatMessage({ id: `global.${type}` }),
        key: type,
      };
    });
  };

  render() {
    const {
      classes,
      intl,
      inputLabel,
      inputProps,
      labelExtractor,
      allowSwitchCustomerType,
      switchCustomerWrapperClass,
      disabled,
    } = this.props;

    let { hits, customerType } = this.state;

    const autoSuggestProps = {
      alwaysRenderSuggestions: false,
      renderInputComponent: (props) => InputComponent({ ...props, ...inputProps }, inputLabel),
      suggestions: hits,
      onSuggestionSelected: this.onSelect,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue: (hit) => labelExtractor(hit),
      renderSuggestion: (...args) => SuggestionComponent(...args, labelExtractor),
    };

    return (
      <div className={classes.root}>
        {this.state.loading && <Spinner size={80} />}

        <AutoSuggest
          {...autoSuggestProps}
          inputProps={{
            classes,
            intl,
            value: this.state.value,
            onChange: this.handleChange,
            onDeselect: this.onDeselect,
            disabled: disabled,
            inputRef: (node) => {
              this.popperNode = node;
            },
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderSuggestionsContainer={(options) => (
            <Popper
              className={classes.popper}
              placement={'bottom-start'}
              anchorEl={this.popperNode}
              open={Boolean(options.children)}>
              <Paper {...options.containerProps} square>
                {options.children}
              </Paper>
            </Popper>
          )}
        />

        {allowSwitchCustomerType && (
          <ChipsSelect
            onChange={(type) => this.setState({ customerType: type }, () => this.refine())}
            label={''}
            wrapperClass={switchCustomerWrapperClass}
            multiple={false}
            selected={customerType}
            values={this.getCustomerTypes()}
            disabled={disabled}
          />
        )}
      </div>
    );
  }
}

AutoComplete.propTypes = propTypes;
AutoComplete.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(injectIntl(AutoComplete));
