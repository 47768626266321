import React from 'react';
import MomentUtils from "@date-io/moment";
import {
  TimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import {
  MuiThemeProvider,
  createTheme
} from '@material-ui/core/styles';
import {COLORS} from "../../helpers";

const materialTheme = createTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: COLORS.cartSecondary,
        fontSize: '12px',
        fontFamily: '"Roboto", sans-serif',
        lineHeight: 1,
        "&$focused": {
          color: "#363636"
        }
      },
    },

    MuiInput: {
      root: {
        fontSize: '14px',
        color: `#363636`,
      },
      underline: {
        '&:after': {
          borderBottom: `2px solid #363636`,
        },
        '&$focused:after': {
          borderBottomColor: `#363636`,
        },
        '&$error:after': {
          borderBottomColor: `#363636`,
        },
        '&:before': {
          borderBottom: `1px solid #E0E0E0`,
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: `2px solid #363636`,
        },
        '&$disabled:before': {
          borderBottom: `1px dotted #363636`,
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: COLORS.text,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
      },
    },
    MuiPickersDay: {
      day: {
        color: COLORS.black,
      },
      current: {
        color: COLORS.text,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: COLORS.text,
      },
    },
  },
});

const PrimaryInlineTimePicker = (props) => {
  return (
    <MuiThemeProvider theme={materialTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <TimePicker
          variant="inline"
          {...props}
        />
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};

export default PrimaryInlineTimePicker;