import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import DXSelectFilter from '../DXSelectFilter/DXSelectFilter';
import DXFilterConfig from '../DXFilterConfig';
import constants from '../../constants';

const propTypes = {
  equality: PropTypes.string,
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  selected: [],
};

class DXCustomerTypeSingleValueFilter extends React.Component {
  render() {
    const { selected, onChange, intl, equality } = this.props;
    const config = DXCustomerTypeSingleValueFilter.getConfig(intl, equality);
    return (
      <DXSelectFilter
        selected={selected}
        title={config.title}
        values={config.values}
        onChange={onChange}
        multi={config.multi}
      />
    );
  }
}

DXCustomerTypeSingleValueFilter.propTypes = propTypes;
DXCustomerTypeSingleValueFilter.defaultProps = defaultProps;
DXCustomerTypeSingleValueFilter.getLabel = (intl, selectedValues) => {
  return DXSelectFilter.getLabel(intl, 'filter.types.', selectedValues);
};
DXCustomerTypeSingleValueFilter.applyToQueryParams = (queryParams, selectedValues) => {
  selectedValues[0] && (queryParams['type'] = selectedValues[0]);
  return queryParams;
};
DXCustomerTypeSingleValueFilter.getConfig = (intl, equality) => {
  let config = new DXFilterConfig({
    name: 'type',
    selected: [],
    property: 'customer.type',
    title: intl.formatMessage({ id: 'filter.type' }),
    disabled: false,
    equalityStatus: false,
    multi: false,
  });

  if (equality === constants.FILTER_EQUALITY_EQUALS) {
    config.addFilterValue('retail', intl.formatMessage({ id: 'filter.types.retail' }));
    config.addFilterValue('wholesale', intl.formatMessage({ id: 'filter.types.wholesale' }));
  }

  return config;
};

DXCustomerTypeSingleValueFilter.getInitialConfig = (intl, values, readOnly, invisible) => {
  return {
    name: 'type',
    title: intl.formatMessage({ id: 'filter.type' }),
    property: 'type',
    type: 'type',
    selectedValues: values,
    clientSide: false,
    readOnly: readOnly ? readOnly : false,
    fromVariable: !!invisible || false,
  };
};

export default injectIntl(DXCustomerTypeSingleValueFilter);
