import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import classNames from 'classnames';
import PrimarySelect from '../../components/PrimarySelect/PrimarySelect';
import {
  apiPrePurchaseRetrieve,
  apiPrePurchaseCreate,
  apiPrePurchasePatch,
  apiPrePurchaseDeleteFromVariant,
  apiPrePurchaseCreateFromCSA,
} from '../../api';
import {
  PrimaryTextField,
  PrimaryButton,
  PrimaryInlineDatePicker,
  AdminDXTable,
  ErrorBox,
  Spinner,
  CustomersSuggestions,
  VariantsSuggestions,
  ThirdButton,
  PrimarySwitch,
  PrimaryChip,
  CustomerNameChip,
} from '../../components';
import { capitalizeFirstLetter, SHIPPING_TYPES, toCoins, toPaper, validateField } from '../../helpers';
import validationRules from './validation';
import {
  TableCell,
  getVariantPreorderPriceByCustomer,
  calculateSuggestedAmount,
  getVariantsHiddenColumns,
  getGlobalPeriodActivity,
} from './helper';
import { PREPURCHASE_TYPES } from '../../helpers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import BaseDialog from '../../dialogs/BaseDialog';

const PERIOD_PER_WEEK = 'per_week';
const PERIOD_PER_MONTH = 'per_month';
const PERIOD_BI_WEEK = 'per_bi_week';
const EXCLUDED_UPDATE_FIELDS_NAMES = ['globalPeriod'];

const styles = {
  customer: {
    display: 'block',
    marginBottom: 10,
  },
  textField: {
    margin: '10px',
    width: 'calc(50% - 20px)',
  },
  chipsWrap: {
    width: '100%',
    margin: 10,
  },
  chip: {
    margin: '5px 5px 5px 0',
  },
  productsTableWrap: {
    width: '100%',
    margin: 10,
  },
  actionsWrap: {
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
    paddingTop: 30,
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: 1200,
  },
  tint: {
    color: '#A3A6B4',
    fontSize: 11,
  },
  switchCustomerWrapper: {
    margin: '0 10px 10px 10px',
  },
  fullWidthTextInput: {
    width: '100%',
    paddingRight: 20,
  },
  ordersTableWrapper: {
    width: '100%',
    margin: 10,
  },
  absoluteSpinner: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.05)',
    zIndex: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  updatedAt: {
    marginTop: 5,
  },
  smallLabel: {
    color: '#363636',
    fontSize: 16,
    transform: 'translate(0, 1.5px) scale(0.75)',
    marginLeft: -10,
  },
  formLabelControl: {},
  formLabel: {
    margin: '10px',
    width: 'calc(50% - 20px)',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
};

const WEEK_DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

class PrePurchasesForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      table: null,
      name: '',
      value: '',
      percentage: '',
      type: PREPURCHASE_TYPES.AMOUNT,
      orders: [],

      validFrom: moment(),
      validTo: moment(),
      customer: null,
      subscription: null,
      subscriptionOriginal: {},
      period: PERIOD_PER_WEEK,
      globalPeriod: true,
      variants: [],
      errors: {},
      hiddenColumns: ['nextDate', 'endDate', 'scheduledDate'],
      loading: false,
      nextDate: moment(),
      nextOrderError: '',
      tags: [],
      nextOrderLoading: false,
      updatedAt: null,
      subscriptionSwitcher: true,
      showGlobalPeriodChangeAlert: false,
      anchorEl: null,
    };

    this.saveAnyChangeTimer = null;
  }

  componentDidMount() {
    this.onLoadOrder();
  }

  onLoadOrder = (onLoaded) => {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    if (id && id !== 'new') {
      this.setState({ loading: true });
      apiPrePurchaseRetrieve(
        id,
        (res) => {
          this.setLoadedOrder(res);
          onLoaded && onLoaded();
        },
        (err) => {
          this.setState({ loading: false });
          console.warn(err);
        }
      );
    }
  };

  setLoadedOrder = (res, enableAdditional = true) => {
    const variants = (res.variants || []).map((item, index) => {
      let variant = { ...item, ...{ value: toPaper(item.value), index: index + 1 } };
      if (item.limited) {
        variant.limited = {
          id: item.limited.id,
          period: item.limited.period,
          nextDate: item.nextDate ? moment(item.nextDate) : null,
          endDate: item.endDate ? moment(item.endDate) : null,
          scheduledDate: item.scheduledDate ? moment(item.scheduledDate) : null,

          limitPerPeriod: toPaper(item.limited.limitPerPeriod),
          limitOverAll: toPaper(item.limited.limitOverAll),
        };
      }
      return variant;
    });

    this.setState(
      {
        ...res,
        ...{
          value: toPaper(res.value),
          percentage: toPaper(res.percentage),
          type: res.type,
          validFrom: moment(res.validFrom),
          validTo: moment(res.validTo),
          orders: res.orders,
          updatedAt: res.updatedAt,
          tags: !!res.tags ? (Array.isArray(res.tags) ? res.tags : Object.values(res.tags)) : [],
          nextDate: res.subscription ? moment(res.subscription.nextDate) : moment().toISOString(true),
          scheduledDate: res.subscription ? moment(res.subscription.scheduledDate) : moment().toISOString(true),
          subscriptionOriginal: res.subscription,
          subscriptionSwitcher: !res.subscription,
          variants,
          ...(enableAdditional ? { globalPeriod: getGlobalPeriodActivity(res.variants) } : {}),
        },
      },
      () => {
        const hiddenColumns = getVariantsHiddenColumns(
          this.state.variants,
          this.state.subscription,
          this.state.globalPeriod
        );

        if (this.state.variants.length && this.state.variants[0].limited) {
          this.setState(
            {
              loading: false,
              nextOrderLoading: false,
              period: this.state.variants[0].limited.period,
              globalPeriod: !!this.state.variants[0].limited.period,
              hiddenColumns,
            },
            () => this.table.forceReload()
          );
        } else {
          this.setState({
            loading: false,
            nextOrderLoading: false,
            hiddenColumns,
          });

          this.state.variants.length && this.table.forceReload();
        }
      }
    );
  };

  _handleChange = (key, value, onDone) => {
    this.setState(
      {
        [key]: value,
        errors: { ...this.state.errors, [key]: null },
      },
      () => {
        onDone && onDone();
        this.saveAnyChange(key);
      }
    );
  };

  _validate = () => {
    const { intl } = this.props;

    const { name, customer, value, type, variants, percentage, subscription } = this.state;

    const subscriptionShippingTypeValue = this.deliverySelectGetValue(true);

    let errors = {};
    errors.name = validateField(validationRules, 'name', name);
    errors.value = validateField(validationRules, 'value', value);
    if (type === PREPURCHASE_TYPES.PERCENTAGE) {
      errors.percentage = validateField(validationRules, 'percentage', percentage);
    }
    errors.customer = validateField(validationRules, 'customer', customer);

    if (subscription) {
      errors.subscription = validateField(validationRules, 'subscription', subscriptionShippingTypeValue !== -1);
    }

    // Variant is required for only for Prepurchase with type amount
    if (type === PREPURCHASE_TYPES.AMOUNT) {
      if (!Boolean(variants.length)) {
        errors.variants = intl.formatMessage({ id: 'validation.csa.specifyAtLeastOneVariant' });
      }

      for (let i = 0; i < variants.length; i++) {
        if (!Number(variants[i].value)) {
          errors.variants = intl.formatMessage({ id: 'validation.csa.pleaseSpecifyPriceForEachVariant' });
          break;
        }

        if (this.state.period && this.state.globalPeriod) {
          if (!variants[i].limited || !Number(variants[i].limited.limitPerPeriod)) {
            errors.variants = intl.formatMessage({ id: 'validation.csa.pleaseSpecifyLimitPerPeriodForEachVariant' });
            break;
          }

          if (!Number(variants[i].limited.limitOverAll)) {
            errors.variants = intl.formatMessage({ id: 'validation.csa.pleaseSpecifyTotalLimitForEachVariant' });
            break;
          }

          if (Number(variants[i].limited.limitPerPeriod) > Number(variants[i].limited.limitOverAll)) {
            errors.variants = intl.formatMessage({ id: 'validation.csa.limitPerPeriodShouldBeLessThenTotalLimit' });
          }
        }
      }
    }

    this.setState({ errors, nextOrderError: '' });
    return Object.values(errors).every((item) => item === null);
  };

  subscriptionToSend = () => {
    const { subscription, nextDate, scheduledDate, subscriptionOriginal } = this.state;

    if (!!subscription) {
      const toDate = moment(nextDate).toISOString(true);
      const fromDate = subscriptionOriginal ? moment(subscriptionOriginal.nextDate).toISOString(true) : '';
      const newWeekDay = subscription.weekDay;
      const oldWeekDay = subscriptionOriginal ? subscriptionOriginal.weekDay : '';
      const newScheduledDate = moment(scheduledDate).toISOString(true);
      const oldScheduledDate = subscriptionOriginal ? moment(subscriptionOriginal.scheduledDate).toISOString(true) : '';

      return {
        subscription: {
          period: subscription.period,
          type: subscription.type,
          ...(subscription.location ? { location: subscription.location } : {}),
          ...(subscription.deliveryCompany ? { deliveryCompany: subscription.deliveryCompany } : {}),
          ...(toDate !== fromDate ? { nextDate: toDate } : {}),
          ...(newWeekDay !== oldWeekDay ? { weekDay: newWeekDay } : {}),
          ...(newScheduledDate !== oldScheduledDate ? { scheduledDate: newScheduledDate } : {}),
        },
      };
    }

    return { subscription: null };
  };

  _handleSubmit = (e, onSuccess) => {
    e && e.preventDefault();

    const { name, validFrom, validTo, value, type, subscription, customer, percentage, variants } = this.state;

    const valid = this._validate();

    if (!valid) return;

    this.setState({ loading: true, error: '' });

    const {
      match: {
        params: { id },
      },
    } = this.props;

    const data = {
      name,
      type: type,
      value: toCoins(value),
      validFrom: validFrom ? validFrom.toISOString(true) : '',
      validTo: validTo ? validTo.toISOString(true) : '',
      customerId: customer.id,
      ...this.subscriptionToSend(),
    };

    if (this.state.tags && this.state.tags.length) {
      data['tags'] = this.state.tags;
    }

    if (type === PREPURCHASE_TYPES.PERCENTAGE) data.percentage = toCoins(percentage);

    if (type === PREPURCHASE_TYPES.AMOUNT) {
      data.variants = variants.map((item) => {
        let variant = {
          id: item.id,
          value: Number(toCoins(item.value)),
        };

        if (!!subscription && item.nextDate) {
          variant.nextDate = moment(item.nextDate).startOf('day').toISOString(true);
        }

        if (!!subscription && item.nextDate === null) {
          variant.nextDate = null;
        }

        if (!!subscription && item.endDate) {
          variant.endDate = moment(item.endDate).startOf('day').toISOString(true);
        }

        if (!!subscription && item.scheduledDate) {
          variant.scheduledDate = moment(item.scheduledDate).startOf('day').toISOString(true);
        }

        if (item.limited) {
          variant.limited = {
            id: item.limited.id,
            ...(this.state.globalPeriod ? { period: item.limited.period ? item.limited.period : PERIOD_PER_WEEK } : {}),
            limitPerPeriod: Number(toCoins(item.limited.limitPerPeriod)),
            limitOverAll: Number(toCoins(item.limited.limitOverAll)),
          };
        }

        return variant;
      });
    }

    if (id && id === 'new') {
      apiPrePurchaseCreate(
        data,
        (response) => {
          this.setState({ loading: false });
          this.props.onSuccess(response);
        },
        (error) => {
          this.setState({
            loading: false,
            error: (error.response && error.response.data && error.response.data.message) || '',
          });
          console.log(error.response);
        }
      );
    } else {
      apiPrePurchasePatch(
        id,
        data,
        (response) => {
          this.setState({ loading: false });
          if (onSuccess) {
            onSuccess(response);
          } else {
            this.props.onSuccess(response);
          }
        },
        (error) => {
          this.setState({
            loading: false,
            error: (error.response && error.response.data && error.response.data.message) || '',
          });
          console.log(error);
        }
      );
    }
  };

  _handleAddCustomer = (customer) => {
    this.setState((state) => {
      // Set customer
      state.customer = customer;

      return state;
    }, this.saveAnyChange);
  };

  _handleDeleteCustomer = () => {
    this.setState({
      customer: null,
    });
  };

  _handleAddVariant = (variant) => {
    this.setState(
      {
        variants: _.unionBy(this.state.variants, [variant], (item) => item.id),
      },
      () => {
        this.table.forceReload();
      }
    );
  };

  _handleDeleteVariant = (variant) => {
    this.setState(
      {
        variants: this.state.variants.filter((item) => item.id !== variant.id),
      },
      () => {
        // Calculating of suggested amount
        this.setState(
          {
            value: calculateSuggestedAmount(this.state.variants),
          },
          this.saveAnyChange
        );

        this.table.forceReload();
        const {
          match: {
            params: { id },
          },
        } = this.props;
        if (id !== 'new' && typeof variant.skipFromServerSideDeletion === 'undefined') {
          apiPrePurchaseDeleteFromVariant(id, variant.id, undefined, undefined);
        }
      }
    );
  };

  _onFinishPriceEditing = (variant, price) => {
    this.setState((state) => {
      for (let i = 0; i < state.variants.length; i++) {
        if (state.variants[i].id === variant.id) {
          state.variants[i].value = price;
          break;
        }
      }

      // Calculating of suggested amount
      state.value = calculateSuggestedAmount(this.state.variants);

      return state;
    }, this.saveAnyChange);
  };

  _onFinishPeriodEditing = (variant, period) => {
    this.setState((state) => {
      for (let i = 0; i < state.variants.length; i++) {
        if (state.variants[i].id === variant.id) {
          let limited = state.variants[i].limited || { period: this.state.period };
          limited.period = period;
          state.variants[i].limited = limited;
          break;
        }
      }
      return state;
    }, this.saveAnyChange);
  };

  _onFinishLimitEditing = (key, variant, limit) => {
    this.setState((state) => {
      for (let i = 0; i < state.variants.length; i++) {
        if (state.variants[i].id === variant.id) {
          let limited = state.variants[i].limited || { period: this.state.period };
          limited[key] = limit;
          state.variants[i].limited = limited;
          break;
        }
      }

      // Calculating of suggested amount
      state.value = calculateSuggestedAmount(this.state.variants);

      return state;
    }, this.saveAnyChange);
  };

  _onFinishNextDateEditing = (variant, date) => {
    this.setState((state) => {
      for (let i = 0; i < state.variants.length; i++) {
        if (state.variants[i].id === variant.id) {
          state.variants[i].nextDate = date;
          break;
        }
      }
      return state;
    }, this.saveAnyChange);
  };

  _onFinishDateEditing = (variant, date, paramName = 'endDate') => {
    this.setState((state) => {
      for (let i = 0; i < state.variants.length; i++) {
        if (state.variants[i].id === variant.id) {
          state.variants[i][paramName] = date;
          break;
        }
      }
      return state;
    }, this.saveAnyChange);
  };

  _handlePeriodChange = () => {
    const { period, globalPeriod } = this.state;

    if (period && globalPeriod) {
      this.setState((state) => {
        state.hiddenColumns = getVariantsHiddenColumns(this.state.variants, this.state.subscription, globalPeriod);
        for (let i = 0; i < state.variants.length; i++) {
          let limited = state.variants[i].limited || {};
          limited['period'] = period;
          state.variants[i].limited = limited;
        }
        return state;
      });
    } else {
      this.setState((state) => {
        state.hiddenColumns = getVariantsHiddenColumns(this.state.variants, this.state.subscription, globalPeriod);

        for (let i = 0; i < state.variants.length; i++) {
          if (state.variants[i].limited) {
            delete state.variants[i]['limited'];
          }
        }
        return state;
      });
    }
  };

  _handleTypeChange = () => {
    const { type } = this.state;

    if (type !== PREPURCHASE_TYPES.AMOUNT) {
      this.setState({
        subscription: null,
      });
    }
  };

  _toggleSubscription = () => {
    const { subscription } = this.state;

    if (subscription) {
      this.setState({ subscription: null }, () => {
        this.setState(
          {
            hiddenColumns: getVariantsHiddenColumns(this.state.variants, this.state.subscription),
          },
          this._handlePeriodChange
        );
      });
    } else {
      this.setState(
        {
          subscription: {
            type: '',
            period: PERIOD_PER_WEEK,
            weekDay: WEEK_DAYS[0],
            nextDate: moment().toISOString(true),
            scheduledDate: moment().toISOString(true),
          },
        },
        () => {
          this.setState(
            {
              hiddenColumns: getVariantsHiddenColumns(this.state.variants, this.state.subscription),
            },
            this._handlePeriodChange
          );
        }
      );
    }
  };

  _handleCreateOrdersFromCSA = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    // Save CSA
    this._handleSubmit(null, () => {
      this.setState({ loading: true, nextOrderLoading: true });

      // Create new order
      apiPrePurchaseCreateFromCSA(
        id,
        () => {
          this.onLoadOrder(() => {
            this.orderTable.forceReload();
          });
        },
        (error) => {
          this.setState({
            loading: false,
            nextOrderLoading: false,
            nextOrderError: error.response.data.message || '',
          });
          console.log(error.response);
        }
      );
    });
  };

  getOrdersTable = () => {
    const { classes, intl } = this.props;
    const { orders } = this.state;

    if (orders.length) {
      return (
        <div className={classes.ordersTableWrapper}>
          <Paper>
            <AdminDXTable
              onRef={(orderTable) => (this.orderTable = orderTable)}
              enableToolbar={false}
              enableSearch={false}
              enablePager={false}
              apiRetrieve={(params, onSuccess) => onSuccess(this.state.orders || [])}
              pageSize={10}
              columnExtensions={[{ columnName: 'number', width: 100 }]}
              columns={[
                {
                  name: 'number',
                  title: intl.formatMessage({ id: 'global.number' }),
                  getCellValue: (row) => {
                    return this.state.orders.findIndex((item) => item.id === row.id) + 1;
                  },
                },
                { name: 'name', title: intl.formatMessage({ id: 'global.orderName' }) },
                { name: 'shippingType', title: intl.formatMessage({ id: 'global.shippingType' }) },
                {
                  name: 'shippingDate',
                  title: intl.formatMessage({ id: 'global.shippingDate' }),
                  getCellValue: (row) => (row.shippingDate && moment(row.shippingDate)) || '-',
                },
              ]}
              dateColumnNames={['shippingDate']}
              headerCellProps={{ style: { whiteSpace: 'normal' } }}
              onRowClick={(item) => this.handleOpenOrderNextTab(item)}
            />
          </Paper>
        </div>
      );
    }
  };

  handleOpenOrderNextTab = (item) => {
    window.open(`/order/${item.id}`);
  };

  changeDeliveryMethod = (e) => {
    const value = e.target.value;
    const { producer } = this.props;

    const companyReg = new RegExp('^dc_');
    const locationReg = new RegExp('^loc_');

    if (value === -1) return;

    if (value === SHIPPING_TYPES.SHIPPING_PICKUP) {
      this.setState(
        {
          subscription: {
            ...this.state.subscription,
            type: SHIPPING_TYPES.SHIPPING_PICKUP,
            locationId: null,
            location: null,
          },
        },
        this.saveAnyChange
      );
    }

    if (value === SHIPPING_TYPES.SHIPPING_DELIVERY) {
      this.setState(
        {
          subscription: {
            ...this.state.subscription,
            type: SHIPPING_TYPES.SHIPPING_DELIVERY,
            deliveryCompany: null,
          },
        },
        this.saveAnyChange
      );
    }

    if (value.match(companyReg)) {
      this.setState(
        {
          subscription: {
            ...this.state.subscription,
            type: SHIPPING_TYPES.SHIPPING_DELIVERY,
            deliveryCompany: {
              id: value,
            },
          },
        },
        this.saveAnyChange
      );
    }
    if (value.match(locationReg)) {
      const location =
        producer.locations &&
        producer.locations.length &&
        producer.locations.filter((location) => location.id === value)[0];

      this.setState(
        {
          subscription: {
            ...this.state.subscription,
            type: SHIPPING_TYPES.SHIPPING_PICKUP,
            locationId: location.id,
            location: {
              id: location.id,
              name: location.name,
            },
          },
        },
        this.saveAnyChange
      );
    }
  };

  deliverySelectGetValue = (pickupTypeOnly = false) => {
    const { subscription } = this.state;

    if (pickupTypeOnly && subscription?.type === SHIPPING_TYPES.SHIPPING_PICKUP) {
      return subscription.type;
    }

    if (subscription?.type === SHIPPING_TYPES.SHIPPING_DELIVERY && !subscription.deliveryCompany) {
      return subscription.type;
    }

    if (subscription && subscription.type === SHIPPING_TYPES.SHIPPING_DELIVERY && subscription.deliveryCompany)
      return subscription.deliveryCompany ? subscription.deliveryCompany.id : subscription.type;
    if (subscription && subscription.type === SHIPPING_TYPES.SHIPPING_PICKUP && subscription.location)
      return subscription.location ? subscription.location.id : subscription.type;
    if (!subscription || (!subscription.location && !subscription.deliveryCompany)) return -1;

    return subscription.type;
  };

  drawTagsSelector = () => {
    const { intl, classes } = this.props;

    return (
      <PrimaryChip
        className={classes.textField}
        fullWidth
        value={this.state.tags}
        allowDuplicates={false}
        onChange={(chips) => {
          // prevent duplicate values
          let mergedArray = [...this.state.tags, ...chips];
          mergedArray = mergedArray.filter((value, index, array) => array.indexOf(value) === index);
          this._handleChange('tags', mergedArray);
        }}
        onDelete={(chip) => {
          let chipsArr = [...this.state.tags];
          const index = chipsArr.indexOf(chip);
          chipsArr.splice(index, 1);
          this._handleChange('tags', chipsArr);
        }}
        label={intl.formatMessage({ id: 'global.tags' })}
        InputLabelProps={{ shrink: true }}
      />
    );
  };

  saveAnyChange = (key) => {
    clearTimeout(this.saveAnyChangeTimer);
    const {
      match: {
        params: { id },
      },
    } = this.props;

    if (id && id !== 'new') {
      this.saveAnyChangeTimer = setTimeout(() => {
        this._handleSubmit(undefined, (res) => {
          this.setLoadedOrder(res, !EXCLUDED_UPDATE_FIELDS_NAMES.includes(key));
        });
      }, 1000);
    }
  };

  _handleChangeGlobalPeriod = (value) => {
    // save after change
    // this._handleChange('globalPeriod', event.target.checked, this._handlePeriodChange);

    if (!value) {
      this.setState({ globalPeriod: value, showGlobalPeriodChangeAlert: true });
    } else {
      this.setState({ globalPeriod: value }, this._handlePeriodChange);
    }
  };

  drawGlobalPeriodDialog = () => {
    const { classes, intl } = this.props;

    const onClose = () => {
      this.setState({ showGlobalPeriodChangeAlert: false, globalPeriod: !this.state.globalPeriod });
    };

    const onSuccess = () => {
      this.setState({ showGlobalPeriodChangeAlert: false }, this._handlePeriodChange);
    };

    return (
      <BaseDialog
        scroll={'paper'}
        maxWidth="md"
        open
        onClose={onClose}
        title={intl.formatMessage({ id: 'page.csa.showGlobalPeriodChangeAlertMessage' })}>
        <div className={classes.actionsWrap}>
          <PrimaryButton onClick={onClose} style={{ marginRight: 20 }}>
            {intl.formatMessage({ id: 'global.cancel' })}
          </PrimaryButton>

          <PrimaryButton onClick={onSuccess}>{intl.formatMessage({ id: 'global.ok' })}</PrimaryButton>
        </div>
      </BaseDialog>
    );
  };

  getTableActions = () => {
    const { intl } = this.props;

    return [
      {
        title: intl.formatMessage({ id: 'csa.disableProduct' }),
        action: (row) => this._disableProduct(row),
      },
      {
        title: intl.formatMessage({ id: 'csa.removeProduct' }),
        separator: true,
        action: (row) => this._handleDeleteVariant(row),
      },
    ];
  };

  _disableProduct = (variant) => {
    this._onFinishNextDateEditing(variant, null);
  };

  render() {
    const { intl, classes } = this.props;
    const {
      errors,
      customer,
      subscription,
      nextOrderError,
      error,
      period,
      updatedAt,
      subscriptionSwitcher,
      showGlobalPeriodChangeAlert,
    } = this.state;
    const {
      match: {
        params: { id },
      },
      producer,
    } = this.props;
    const subscriptionShippingTypeValue = this.deliverySelectGetValue(true);
    const subscriptionShippingLocation = this.deliverySelectGetValue();

    return (
      <>
        <form onSubmit={this._handleSubmit} className={classes.form}>
          {this.state.loading && <Spinner size={40} />}
          {this.state.nextOrderLoading && (
            <div className={classes.absoluteSpinner}>
              <Spinner size={40} />
            </div>
          )}

          <PrimaryTextField
            value={this.state.name}
            className={classes.textField}
            label={intl.formatMessage({ id: 'csa.name' })}
            onChange={(event) => this._handleChange('name', event.target.value)}
            helperText={errors.name && intl.formatMessage({ id: errors.name })}
            error={!!errors.name}
          />

          <PrimarySelect
            value={this.state.type}
            onChange={(event) => this._handleChange('type', event.target.value, this._handleTypeChange)}
            label={intl.formatMessage({ id: 'global.type' })}
            formControlClassName={classes.textField}
            disabled={id !== 'new'}
            helperText={errors.type && intl.formatMessage({ id: errors.type })}
            error={!!errors.type}>
            <MenuItem value={PREPURCHASE_TYPES.AMOUNT}>{intl.formatMessage({ id: 'global.amount' })}</MenuItem>
            <MenuItem value={PREPURCHASE_TYPES.PERCENTAGE}>{intl.formatMessage({ id: 'global.percentage' })}</MenuItem>
          </PrimarySelect>

          <PrimaryInlineDatePicker
            value={this.state.validFrom}
            className={classes.textField}
            label={intl.formatMessage({ id: 'csa.startDate' })}
            onChange={(event) => this._handleChange('validFrom', event)}
            TextFieldComponent={PrimaryTextField}
          />

          <PrimaryInlineDatePicker
            className={classes.textField}
            value={this.state.validTo}
            label={intl.formatMessage({ id: 'csa.endDate' })}
            onChange={(event) => this._handleChange('validTo', event)}
            TextFieldComponent={PrimaryTextField}
          />

          {id && id === 'new' && (
            <CustomersSuggestions
              selected={null}
              inputProps={{
                className: classes.textField,
                helperText: errors.customer && intl.formatMessage({ id: errors.customer }),
                error: !!errors.customer,
              }}
              allowSwitchCustomerType
              labelExtractor={(item) => item.customerName}
              inputLabel={intl.formatMessage({ id: 'csa.customer' })}
              onDetach={() => {}}
              switchCustomerWrapperClass={classes.switchCustomerWrapper}
              onAttach={(customer) =>
                this._handleAddCustomer({
                  ...customer,
                  ...{
                    skipFromServerSideDeletion: true,
                    name: customer.customerName,
                  },
                })
              }
            />
          )}

          <PrimaryTextField
            className={classes.textField}
            value={this.state.value}
            label={intl.formatMessage({ id: 'csa.amount' })}
            onChange={(event) => this._handleChange('value', event.target.value)}
            helperText={errors.value && intl.formatMessage({ id: errors.value })}
            InputProps={{
              endAdornment: <InputAdornment position="end">$</InputAdornment>,
            }}
            error={!!errors.value}
          />

          {this.state.type === PREPURCHASE_TYPES.PERCENTAGE && (
            <PrimaryTextField
              className={classes.textField}
              value={this.state.percentage}
              label={intl.formatMessage({ id: 'global.percentage' })}
              onChange={(event) => this._handleChange('percentage', event.target.value)}
              helperText={errors.percentage && intl.formatMessage({ id: errors.percentage })}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              error={!!errors.value}
            />
          )}

          {id && id !== 'new' && this.drawTagsSelector()}

          <div className={classes.chipsWrap}>
            {id && id !== 'new' && (
              <Typography className={classes.customer}>{intl.formatMessage({ id: 'global.customer' })}:</Typography>
            )}

            {customer && (
              <CustomerNameChip
                isNew={id && id === 'new'}
                customer={customer}
                onClick={() => {
                  window.open(`/customers/${customer.id}`);
                }}
                onDelete={(customer) => this._handleDeleteCustomer(customer)}
              />
            )}
          </div>

          {id && id === 'new' && <div style={{ width: '100%' }}>{this.drawTagsSelector()}</div>}

          {this.state.type === PREPURCHASE_TYPES.AMOUNT && (
            <React.Fragment>
              <VariantsSuggestions
                selected={null}
                inputProps={{ className: classNames(classes.textField, classes.fullWidthTextInput) }}
                labelExtractor={(item) => `${item.name}`}
                inputLabel={intl.formatMessage({ id: 'csa.products' })}
                onDetach={() => {}}
                clearAfterSelect
                onAttach={(product, variant) =>
                  this._handleAddVariant({
                    id: variant.id,
                    nextDate: null,
                    endDate: null,
                    scheduledDate: null,
                    value: getVariantPreorderPriceByCustomer(customer, variant),
                    product: product.name,
                    name: variant?.name || '',
                    index: this.state.variants.length + 1,
                  })
                }
              />

              {!!this.state.variants.length && (
                <FormControlLabel
                  classes={{
                    label: classes.smallLabel,
                    root: classes.formLabel,
                  }}
                  labelPlacement="top"
                  control={
                    <PrimarySwitch
                      disabled={!subscriptionSwitcher}
                      checked={this.state.globalPeriod}
                      onChange={(event) => {
                        this._handleChangeGlobalPeriod(event.target.checked);
                      }}
                    />
                  }
                  label={intl.formatMessage({ id: 'prepurchases.period' })}
                />
              )}

              <div className={classes.productsTableWrap}>
                {errors.variants && <ErrorBox error={errors.variants} />}

                {!!updatedAt && (
                  <div className={classes.updatedAt}>
                    <Typography className={classes.customer}>
                      {intl.formatMessage({ id: 'global.updatedAt' })}: {moment(this.state.updatedAt).format('LLL')}
                    </Typography>
                  </div>
                )}

                <Paper>
                  <AdminDXTable
                    onRef={(table) => (this.table = table)}
                    cellRenderer={(...props) =>
                      TableCell(
                        this._onFinishPriceEditing,
                        this._onFinishLimitEditing,
                        this._onFinishPeriodEditing,
                        this._onFinishNextDateEditing,
                        this._onFinishDateEditing,
                        intl,
                        period,
                        ...props
                      )
                    }
                    enableToolbar={false}
                    enableSearch={false}
                    enablePager={false}
                    apiRetrieve={(params, onSuccess) => onSuccess(this.state.variants || [])}
                    pageSize={10}
                    columnExtensions={[
                      { columnName: 'price', width: 100 },
                      { columnName: 'limitPerPeriod', width: 100 },
                      { columnName: 'limitOverAll', width: 100 },
                      { columnName: 'period', width: 100 },
                      { columnName: 'nextDate', width: 100 },
                      { columnName: 'scheduledDate', width: 120 },
                      { columnName: 'endDate', width: 100 },
                    ]}
                    hiddenColumnNames={this.state.hiddenColumns}
                    columns={[
                      { name: 'product', title: intl.formatMessage({ id: 'global.product' }) },
                      { name: 'price', title: intl.formatMessage({ id: 'global.newPrice' }) },
                      { name: 'limitPerPeriod', title: intl.formatMessage({ id: 'prepurchases.limitPerPeriod' }) },
                      { name: 'limitOverAll', title: intl.formatMessage({ id: 'prepurchases.totalLimit' }) },
                      { name: 'period', title: intl.formatMessage({ id: 'subscription.period' }) },
                      { name: 'nextDate', title: intl.formatMessage({ id: 'subscription.nextDate' }) },
                      { name: 'scheduledDate', title: intl.formatMessage({ id: 'subscription.scheduledDate' }) },
                      { name: 'endDate', title: intl.formatMessage({ id: 'subscription.endDate' }) },
                    ]}
                    actionsTitle={' '}
                    actionsHeaderCellStyle={{ textAlign: 'center', paddingRight: 0 }}
                    actionsCellStyle={{ textAlign: 'center', paddingRight: 0 }}
                    headerCellProps={{ style: { whiteSpace: 'normal' } }}
                    actionsGroupButton={true}
                    actions={this.getTableActions()}
                  />
                </Paper>
              </div>

              {this.state.type === PREPURCHASE_TYPES.AMOUNT && (
                <div style={{ width: '100%', marginTop: 20 }}>
                  {subscriptionSwitcher && (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <ThirdButton onClick={this._toggleSubscription}>
                        {subscription ? (
                          <FormattedMessage id="prepurchases.disableSubscription" />
                        ) : (
                          <FormattedMessage id="prepurchases.enableSubscription" />
                        )}
                      </ThirdButton>
                    </div>
                  )}

                  {subscription && (
                    <>
                      <PrimarySelect
                        formControlClassName={classes.textField}
                        value={subscription.period}
                        onChange={(e) =>
                          this.setState(
                            {
                              subscription: { ...subscription, ...{ period: e.target.value } },
                            },
                            () => this._handleChange('period', e.target.value, this._handlePeriodChange)
                          )
                        }
                        label={intl.formatMessage({ id: 'prepurchases.subscription.period' })}>
                        <MenuItem value={PERIOD_PER_WEEK}>{intl.formatMessage({ id: 'global.perWeek' })}</MenuItem>
                        <MenuItem value={PERIOD_PER_MONTH}>{intl.formatMessage({ id: 'global.perMonth' })}</MenuItem>
                        <MenuItem value={PERIOD_BI_WEEK}>{intl.formatMessage({ id: 'global.biWeek' })}</MenuItem>
                      </PrimarySelect>

                      <PrimarySelect
                        formControlClassName={classes.textField}
                        value={subscription.weekDay}
                        onChange={(e) =>
                          this.setState(
                            { subscription: { ...subscription, ...{ weekDay: e.target.value } } },
                            this.saveAnyChange
                          )
                        }
                        label={intl.formatMessage({ id: 'prepurchases.subscription.weekday' })}>
                        {WEEK_DAYS.map((day) => (
                          <MenuItem style={{ textTransform: 'capitalize' }} key={day} value={day}>
                            {capitalizeFirstLetter(day)}
                          </MenuItem>
                        ))}
                      </PrimarySelect>

                      <PrimarySelect
                        formControlClassName={classes.textField}
                        value={subscriptionShippingTypeValue}
                        label={intl.formatMessage({ id: 'prepurchases.subscription.shippingType' })}
                        onChange={this.changeDeliveryMethod}
                        helperText={errors.subscription && intl.formatMessage({ id: errors.subscription })}
                        error={!!errors.subscription}>
                        {subscriptionShippingTypeValue === -1 && (
                          <MenuItem key={-1} value={-1}>
                            {intl.formatMessage({ id: 'global.selectDeliveryMethod' })}
                          </MenuItem>
                        )}

                        {!!producer.locations.length && (
                          <MenuItem key={SHIPPING_TYPES.SHIPPING_PICKUP} value={SHIPPING_TYPES.SHIPPING_PICKUP}>
                            {intl.formatMessage({ id: 'global.pickup' })}
                          </MenuItem>
                        )}

                        {!!producer.deliveryCompanies.length && (
                          <MenuItem key={SHIPPING_TYPES.SHIPPING_DELIVERY} value={SHIPPING_TYPES.SHIPPING_DELIVERY}>
                            {intl.formatMessage({ id: 'global.delivery' })}
                          </MenuItem>
                        )}

                        {!!producer.deliveryCompanies.length &&
                          producer.deliveryCompanies.map((company) => (
                            <MenuItem key={company.id} value={company.id}>
                              {intl.formatMessage({ id: 'global.delivery' })} - {company.name}
                            </MenuItem>
                          ))}
                      </PrimarySelect>

                      {subscriptionShippingTypeValue === SHIPPING_TYPES.SHIPPING_PICKUP && (
                        <PrimarySelect
                          formControlClassName={classes.textField}
                          value={subscriptionShippingLocation}
                          label={intl.formatMessage({ id: 'global.locations' })}
                          onChange={this.changeDeliveryMethod}
                          helperText={errors.subscription && intl.formatMessage({ id: errors.subscription })}
                          error={!!errors.subscription}>
                          {subscriptionShippingLocation === -1 && (
                            <MenuItem key={-1} value={-1}>
                              {intl.formatMessage({ id: 'global.selectDeliveryLocation' })}
                            </MenuItem>
                          )}

                          {!!producer.locations.length &&
                            producer.locations.map((location) => (
                              <MenuItem key={location.id} value={location.id}>
                                {location.name}
                              </MenuItem>
                            ))}
                        </PrimarySelect>
                      )}

                      <PrimaryInlineDatePicker
                        className={classes.textField}
                        value={this.state.nextDate}
                        label={intl.formatMessage({ id: 'csa.subscriptionNextOrder' })}
                        onChange={(event) => {
                          this._handleChange('nextDate', event.toISOString(true));
                        }}
                        TextFieldComponent={PrimaryTextField}
                      />

                      <PrimaryInlineDatePicker
                        className={classes.textField}
                        value={this.state.scheduledDate}
                        label={intl.formatMessage({ id: 'csa.subscriptionScheduledDate' })}
                        onChange={(event) => {
                          this._handleChange('scheduledDate', event.toISOString(true));
                        }}
                        TextFieldComponent={PrimaryTextField}
                      />

                      {nextOrderError && (
                        <div style={{ width: '100%', marginTop: 20, display: 'flex', justifyContent: 'center' }}>
                          <ErrorBox error={nextOrderError} />
                        </div>
                      )}

                      {id && id !== 'new' && (
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 40 }}>
                          <PrimaryButton
                            disabled={this.state.loading}
                            type="button"
                            onClick={this._handleCreateOrdersFromCSA}>
                            {intl.formatMessage({ id: 'csa.subscriptionAddNextDate' })}
                          </PrimaryButton>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </React.Fragment>
          )}

          {error && (
            <div style={{ width: '100%', marginTop: 20, display: 'flex', justifyContent: 'center' }}>
              <ErrorBox error={error} />
            </div>
          )}

          {this.getOrdersTable()}

          <div className={classes.actionsWrap}>
            <PrimaryButton disabled={this.state.loading} type="submit">
              {intl.formatMessage({ id: 'global.save' })}
            </PrimaryButton>
          </div>
        </form>

        {showGlobalPeriodChangeAlert && this.drawGlobalPeriodDialog()}
      </>
    );
  }
}

export default withStyles(styles)(injectIntl(PrePurchasesForm));
