import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import QuantitySwitcher from '../QuantitySwitcher';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants/colors';
import CalcButton1 from '../../assets/calcButton1';
import CalcButton2 from '../../assets/calcButton2';
import CalcButton3 from '../../assets/calcButton3';
import CalcButton4 from '../../assets/calcButton4';
import CalcButton5 from '../../assets/calcButton5';
import CalcButton6 from '../../assets/calcButton6';
import CalcButton7 from '../../assets/calcButton7';
import CalcButton8 from '../../assets/calcButton8';
import CalcButton9 from '../../assets/calcButton9';
import CalcButton0 from '../../assets/calcButton0';
import CalcButtonRemove from '../../assets/calcButtonRemove';
import CalcButtonSymbols from '../../assets/calcButtonSymbols';
import ArrowIcon from '../../assets/arrow';
import { BORDER_RADIUS_MAIN, NO_KEYBOARD_CLOSE_DATA_ATTRIBUTE } from '../../constants/globals';
import { FONT_BUTTON } from '../../constants/fonts';
import { ClickAwayListener } from '@material-ui/core';
import KeyboardEntity from '../../entities/keyboardEntity';
import { setKeyboardItemToEdit, setQuantityOverwrite } from '../../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';

export const HEIGHT_OF_NUMBER_KEYBOARD = 414;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    position: 'fixed',
    zIndex: 100,
    backgroundColor: '#fff',
    bottom: 0,
    left: 0,
    '& *': {
      userSelect: 'none',
    },
    borderTop: '1px solid ' + COLORS.numberPadBackground,
    height: HEIGHT_OF_NUMBER_KEYBOARD,
  },
  numbersWrapper: {
    backgroundColor: COLORS.numberPadBackground,
    padding: 5,
    paddingBottom: 20,
  },
  numberGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  number: {
    flex: 1,
    height: 50,
    padding: 3,
    margin: 5,
    backgroundColor: COLORS.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    boxShadow: '0px 1px 0px 0px #0000004D',
    border: 0,
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: COLORS.lightBg,
    },
    '&:active': {
      backgroundColor: COLORS.hover,
    },
  },
  numberSymbol: {
    boxShadow: 'none',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },

  itemNameSelectorWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    paddingBottom: 0,
  },
  itemNameSelector: {
    maxWidth: 400,
    width: '100%',
    minWidth: 286,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  nameSelectorButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 44,
    marginLeft: 5,
    marginRight: 5,
    height: 44,
    cursor: 'pointer',
    borderRadius: BORDER_RADIUS_MAIN,
    '&:hover': {
      backgroundColor: COLORS.lightBg,
    },
    '&:active': {
      backgroundColor: COLORS.hover,
    },
  },
  prevItemButton: {},
  currentItemName: {
    ...FONT_BUTTON,
    flex: 1,
    textAlign: 'center',
  },
  nextItemButton: {},

  quantitySwitcherWrapper: {
    padding: 20,
  },
  clickAwayContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  pseudoKeyboard: {
    height: HEIGHT_OF_NUMBER_KEYBOARD,
  },
  nameSelectorNotActiveButton: {
    '&:hover': {
      backgroundColor: COLORS.transparent,
    },
    '&:active': {
      backgroundColor: COLORS.transparent,
    },
  },
}));

const propTypes = {
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  label: PropTypes.string,
  coloredLabel: PropTypes.bool,
};

const NumberKeyboard = (props) => {
  const {
    quantity: quantityProps,
    onChange,
    coloredLabel,
    currentItem = { name: '' },
    onDone,
    orderedDOMIds = [],
  } = props;

  const currentItemDomId = currentItem.itemDomId;
  const [hasNextItem, setHasNextItem] = useState(false);
  const [hasPrevItem, setHasPrevItem] = useState(false);
  const [quantity, setQuantity] = useState(quantityProps);
  const classes = useStyles();
  const dispatch = useDispatch();
  const setKeyboard = (keyboardObj) => dispatch(setKeyboardItemToEdit(keyboardObj));

  const globalOverwrite = useSelector((state) => state.supplier.overwrite);
  const setOverwrite = (isSet) => dispatch(setQuantityOverwrite(isSet));

  useEffect(() => {
    hasPrevOrNextItem();
    setQuantity(quantityProps);
  }, [currentItemDomId]);

  useEffect(() => {
    onChange(quantity);
  }, [quantity]);

  const drawButton = (icon, action = () => {}, isSymbol) => {
    const Icon = icon;

    return (
      <div className={classNames([classes.number, isSymbol && classes.numberSymbol])} onClick={action}>
        <Icon />
      </div>
    );
  };

  const onButtonPress = (number, remove = false) => {
    let editedQuantity = String(quantity);
    const currentZero = quantity === 0 || quantity === '0' || undefined;

    if (globalOverwrite) {
      setOverwrite(false);

      setQuantity(number);
      return number;
    }

    if (number === 0 && currentZero) return;

    if (!remove) {
      currentZero ? (editedQuantity = number) : (editedQuantity += number);
    } else {
      editedQuantity = editedQuantity.slice(0, -1);
    }

    setQuantity(editedQuantity);
    return editedQuantity;
  };

  const handleClickAway = (event) => {
    onDone && onDone(quantity, quantityProps);

    if (event?.target?.dataset?.keyboard !== NO_KEYBOARD_CLOSE_DATA_ATTRIBUTE.keyboardNoClose) {
      onCloseKeyboard();
      setOverwrite(false);
    }
  };

  const onJumpToOrder = (forward = true) => {
    const indexOfCurrent = orderedDOMIds.findIndex((item) => item === currentItemDomId);
    const prev = orderedDOMIds[indexOfCurrent - 1];
    const next = orderedDOMIds[indexOfCurrent + 1];

    removeSelection();
    // Timeout is needed to deselect boxes
    setTimeout(() => {
      if (forward) {
        document.getElementById(next).click();
        onDone && onDone(quantity);
      } else {
        document.getElementById(prev).click();
        onDone && onDone(quantity);
      }
    }, 50);
  };

  const hasPrevOrNextItem = () => {
    const indexOfCurrent = orderedDOMIds.findIndex((item) => item === currentItemDomId);

    const prev = orderedDOMIds[indexOfCurrent - 1];
    const next = orderedDOMIds[indexOfCurrent + 1];

    setHasNextItem(!!next);
    setHasPrevItem(!!prev);
  };

  const onCloseKeyboard = () => {
    setKeyboard(
      new KeyboardEntity({
        isOpen: false,
      })
    );
  };

  const removeSelection = () => {
    setOverwrite(false);
  };

  return (
    <>
      <div className={classes.root}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <div className={classes.clickAwayContent}>
            {!!currentItem && (
              <div className={classes.itemNameSelectorWrapper}>
                <div className={classes.itemNameSelector}>
                  {hasPrevItem ? (
                    <div
                      className={classNames([classes.nameSelectorButton, classes.prevItemButton])}
                      onClick={() => onJumpToOrder(false)}>
                      <ArrowIcon left />
                    </div>
                  ) : (
                    <div className={classNames([classes.nameSelectorButton, classes.nameSelectorNotActiveButton])} />
                  )}
                  <div className={classes.currentItemName}>{currentItem.name}</div>
                  {hasNextItem ? (
                    <div
                      className={classNames([classes.nameSelectorButton, classes.nextItemButton])}
                      onClick={() => onJumpToOrder(true)}>
                      <ArrowIcon />
                    </div>
                  ) : (
                    <div className={classNames([classes.nameSelectorButton, classes.nameSelectorNotActiveButton])} />
                  )}
                </div>
              </div>
            )}
            <div className={classes.quantitySwitcherWrapper}>
              <QuantitySwitcher
                quantity={quantity}
                onChange={(updatedQuantity) => {
                  setQuantity(updatedQuantity);
                }}
                noLabel
                coloredLabel={coloredLabel}
              />
            </div>
            <div className={classes.numbersWrapper}>
              <div className={classes.numberGroup}>
                {drawButton(
                  () => (
                    <CalcButton1 style={{ height: 35, alignSelf: 'flex-start' }} />
                  ),
                  () => {
                    onButtonPress(1);
                  }
                )}
                {drawButton(CalcButton2, () => {
                  onButtonPress(2);
                })}
                {drawButton(CalcButton3, () => {
                  onButtonPress(3);
                })}
              </div>
              <div className={classes.numberGroup}>
                {drawButton(CalcButton4, () => {
                  onButtonPress(4);
                })}
                {drawButton(CalcButton5, () => {
                  onButtonPress(5);
                })}
                {drawButton(CalcButton6, () => {
                  onButtonPress(6);
                })}
              </div>
              <div className={classes.numberGroup}>
                {drawButton(CalcButton7, () => {
                  onButtonPress(7);
                })}
                {drawButton(CalcButton8, () => {
                  onButtonPress(8);
                })}
                {drawButton(CalcButton9, () => {
                  onButtonPress(9);
                })}
              </div>
              <div className={classes.numberGroup}>
                {drawButton(
                  CalcButtonSymbols,
                  () => {
                    removeSelection();
                  },
                  true
                )}
                {drawButton(CalcButton0, () => {
                  onButtonPress(0);
                })}
                {drawButton(
                  CalcButtonRemove,
                  () => {
                    onButtonPress('', true);
                  },
                  true
                )}
              </div>
            </div>
          </div>
        </ClickAwayListener>
      </div>
      <div className={classes.pseudoKeyboard} />
    </>
  );
};

NumberKeyboard.propTypes = propTypes;

export default NumberKeyboard;
