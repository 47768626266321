import { PADDING_CONTENT, PADDING_HEADER_BOTTOM, PADDING_HEADER_TOP } from '../../constants/globals';
import { FONT_TEXT_INPUT_MULTILINE } from '../../constants/fonts';

const styles = (theme) => ({
  closeButton: {
    width: 40,
    height: 40,
  },
  dialogWrapper: {},
  dialogHeader: {
    paddingLeft: PADDING_CONTENT,
    paddingRight: PADDING_CONTENT,
    paddingTop: PADDING_HEADER_TOP,
    paddingBottom: PADDING_HEADER_BOTTOM,
  },
  dialogContent: {
    paddingLeft: PADDING_CONTENT,
    paddingRight: PADDING_CONTENT,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },

  title: {
    marginBottom: 20,
  },
  itemsWrapper: {
    width: '100%',
    maxWidth: 900,
    paddingBottom: 200,
  },

  subTextWrapper: {},
  text: {
    ...FONT_TEXT_INPUT_MULTILINE,
  },

  button: {
    marginTop: 20,
  },

  iconSeparatedTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 40,
  },

  debugButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export default styles;
