import Discount from './Discount'
import Shipping from './Shipping'
import {
  FINANCIAL_STATUSES,
  ORDER_STATUSES,
  SHIPPING_TYPES
} from '../helpers'
import Customer from './Customer'

class Order {

  constructor(props ={}) {
    this.shipping = props.shipping
      ? new Shipping(props.shipping)
      : null;

    this.status = props.status;
    this.financialStatus = props.financialStatus;
    this.discount = props.discount ?
      new Discount(props.discount) :
      null;
    this.customer = props.customer;
  }

  isFeeEditable () {
    return (
      this.shipping &&
      this.shipping.type === SHIPPING_TYPES.SHIPPING_DELIVERY &&
      this.financialStatus === FINANCIAL_STATUSES.UNPAID
    );
  }

  isEditable() {
    /*let isShippingInThePast =
      this.shipping &&
      this.shipping.isDateInThePast()

    if (isShippingInThePast) {
      return false
    }*/

    return !(
      this.financialStatus === FINANCIAL_STATUSES.PAID &&
      this.status === ORDER_STATUSES.ORDER_RECEIVED
    );
  }

  isPaid () {
    return this.financialStatus === FINANCIAL_STATUSES.PAID;
  }

  isPartiallyRefunded () {
    return this.financialStatus === FINANCIAL_STATUSES.PARTIALLY_REFUNDED;
  }

  getCustomerName() {
    return (this.customer)
      ? (new Customer(this.customer)).getCustomerName()
      : '';
  }

  /**
   * Customer entity getter
   * @return {Customer}
   */
  getCustomer() {
    return new Customer(this.customer || {});
  }

  hasDiscount () {
    return this.discount &&
      this.discount.applied
  }

}

export default Order
