import React from 'react';
import { makeStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: '#000',
    whiteSpace: 'pre-line',
  },
  tooltipArrow: {
    color: '#000',
    padding: 10,
    borderRadius: 9,
  },
}));

function TooltipWrapper(props) {
  const { title, placement = 'bottom-start' } = props;
  const classes = useStyles();

  return (
    <Tooltip
      title={title}
      classes={{
        arrow: classes.tooltipArrow,
        tooltip: classes.tooltip,
      }}
      placement={placement}>
      {props.children}
    </Tooltip>
  );
}

export default TooltipWrapper;
