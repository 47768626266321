import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import {
  getLineItemQuantity,
  getLineItemSystemQuantity,
  getLineItemVariantName,
  getVariantUnit,
  toCurrency,
  weightToUser,
} from '../../../../helpers';
import { QuantitySwitcher, EditableNote } from '../../../../components';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../../../hocs';
import { COLORS } from '../../../../helpers';
import Image from './Image';
import { LineItem as LineItemEntity } from '../../../../entities';
import { DISCOUNT_TYPES } from '../../../../entities/Discount';
import { useDispatch } from 'react-redux';
import { showTemporaryTooltip } from '../../../../store/actions/common';

const styles = {
  text: {
    fontSize: 16,
    fontWeight: 400,
    fontFamily: 'Roboto, sans-serif',
    color: COLORS.text,
  },
  price: {
    lineHeight: '20px',
    textAlign: 'right',
  },
  lh40: {
    lineHeight: '40px',
  },
  discount: {
    fontSize: 16,
    fontWeight: 400,
    color: COLORS.text,
  },
  green: {
    color: COLORS.green,
  },
  prepurchase: {
    fontSize: 16,
    fontWeight: 400,
    color: COLORS.text,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: '22px 20px 5px',
    borderTop: '1px solid ' + COLORS.border,
    background: 'transparent',
    transition: '0.3s',
    '&:hover': {
      background: COLORS.lightGray,
    },
    cursor: 'pointer',
  },
  lastCell: {
    textAlign: 'right',
    paddingRight: 5,
  },
  imageCell: {
    width: '63px',
    paddingRight: '13px',
  },
  image: {
    width: '50px',
  },
  cell: {
    display: 'flex',
    fontSize: 14,
    flexDirection: 'column',
  },
  gray: {
    color: COLORS.cartSecondary,
  },
  center: {
    textAlign: 'center',
  },
  info: {
    width: 250,
    paddingRight: 13,
  },
  readOnly: {
    cursor: 'default',
    '&:hover': {
      background: 'transparent',
    },
  },
};

const propTypes = {
  order: PropTypes.object.isRequired,
  readOnly: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  onPatch: PropTypes.func.isRequired,
  onChangeNote: PropTypes.func.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
const defaultProps = {};

const LineItem = ({ order, item, classes, onQuantityChange, onChangeNote, readOnly, onPatch, onDelete, intl }) => {
  const ItemEntity = new LineItemEntity(item);
  const dispatch = useDispatch();

  const getPrice = () => {
    return ItemEntity.hasDiscount() && ItemEntity.discount.definition.type === DISCOUNT_TYPES.FIXED
      ? `${toCurrency(ItemEntity.discount.price, intl)}`
      : `${toCurrency(item.prepurchasedPrice || item.price, intl)}`;
  };

  const getUnitPrice = () => {
    return ItemEntity.hasDiscount() && ItemEntity.discount.definition.type === DISCOUNT_TYPES.FIXED
      ? `${toCurrency(item.discount.unitPrice, intl)} / ${weightToUser(item.unitQuantity)} ${getVariantUnit(item)}`
      : `${toCurrency(item.unitPrice, intl)} / ${weightToUser(item.unitQuantity)} ${getVariantUnit(item)}`;
  };

  const quantityErrorHandler = (error) => {
    const errorData = error?.response?.data;
    let errorMessage = errorData?.message || intl.formatMessage({ id: 'messages.somethingWentWrong' });

    if (errorData?.code === 3007) {
      errorMessage = intl.formatMessage({ id: 'messages.addOrderedQuantityMoreThanAvailable' });
    }

    dispatch(showTemporaryTooltip(errorMessage));
  };

  return (
    <div
      className={classNames(classes.wrapper, { [classes.readOnly]: readOnly })}
      onClick={() => !readOnly && onPatch(order, item)}>
      <div className={classes.imageCell}>
        <Image lineItem={item} />
      </div>
      <div className={classNames(classes.cell, classes.info)}>
        <div className={classes.text}>
          {typeof item.name === 'object' ? `${item.name.product} ${getLineItemVariantName(item, '-')}` : item.name}
        </div>

        {ItemEntity.hasDiscount() && (
          <div className={classes.discount}>
            {intl.formatMessage({ id: 'global.discount' })}:{' '}
            {ItemEntity.hasDiscount() && ItemEntity.discount.getDiscountLabel(intl)}
          </div>
        )}

        <div style={{ marginTop: 5, display: 'flex', flexDirection: 'column' }}>
          {item.prepurchasedUnitPrice && (
            <span className={classNames(classes.text, classes.green)}>
              {toCurrency(item.prepurchasedUnitPrice, intl)} / {weightToUser(item.unitQuantity)} {getVariantUnit(item)}
            </span>
          )}

          <span className={classNames(classes.text, classes.gray)}>{getUnitPrice()}</span>
        </div>
        <div style={{ marginTop: 5 }}>
          <EditableNote
            title={intl.formatMessage({ id: 'global.addNote' })}
            editable={!readOnly}
            note={item.note}
            onChange={(note) => {
              onChangeNote(item, note);
            }}
          />
        </div>
      </div>
      <div className={classes.cell}>
        <QuantitySwitcher
          readOnly={readOnly}
          changeQuantityByProp
          onChange={(newQuantity) =>
            onQuantityChange(item, getLineItemSystemQuantity(item, newQuantity), () => {}, quantityErrorHandler)
          }
          quantity={getLineItemQuantity(item)}
        />
      </div>
      <div style={{ paddingRight: 20, width: 70 }} className={classes.cell}>
        <p
          className={classNames(
            classes.text,
            classes.price,
            { [classes.lh40]: !ItemEntity.hasDiscount() },
            {
              [classes.green]:
                !!item.prepurchasedPrice ||
                (ItemEntity.hasDiscount() && ItemEntity.discount.definition.type === DISCOUNT_TYPES.FIXED),
            }
          )}>
          {getPrice()}
        </p>

        {ItemEntity.hasDiscount() && ItemEntity.discount.definition.type !== DISCOUNT_TYPES.FIXED && (
          <p style={{ color: COLORS.text }} className={classNames(classes.text, classes.price)}>
            {ItemEntity.discount.getAmountLabel(intl)}
          </p>
        )}
      </div>
      {!readOnly && (
        <div className={classNames(classes.cell, classes.lastCell)}>
          <IconButton
            style={{ padding: 0, marginTop: 7 }}
            onClick={(event) => {
              event.stopPropagation();
              onDelete(item);
            }}>
            <CancelIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

LineItem.propTypes = propTypes;
LineItem.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(injectIntl(withRouter(LineItem)));
