import React from 'react'
import PropsTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import {withStyles} from '@material-ui/core/styles'
import { Spinner } from '../../components'
import {
  googleApiGetCompany,
  googleApiSearchCompanies
} from '../../api'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import {COLORS, parseGoogleFormatterAddress} from '../../helpers'

const styles = ({
  content: {
    '&:last-child' : {
      padding: '10px 10px',
    },
    padding: 0,
    textAlign: 'center',
  },
  name: {
    textAlign: 'center',
    alignSelf: 'flex-end'
  },
  companyName: {
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.text,
    fontWeight: 600,
    fontSize: 14,
  },
  card: {
    marginLeft: 2,
    marginRight: 10,
    marginBottom: 10,
    cursor: 'pointer',
    width: 160,
  },
  text: {
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.text,
    fontSize: 14,
  }
});

const propTypes = {
  queryString: PropsTypes.string.isRequired,
  onAddCustomer: PropsTypes.func.isRequired,
};

class GooglePlacesHits extends React.Component {

  state = {
    hits: [],
    loading: true
  };

  componentDidMount() {
    this.fetchHits();
  }

  componentDidUpdate(prevProps) {
    if (this.props.queryString !== prevProps.queryString) {
      this.fetchHits();
    }
  }

  fetchHits = () => {
    const {
      queryString,
    } = this.props;

    this.setState({loading: true});

    let hits = [];
    googleApiSearchCompanies(queryString, (response) => {
      hits = response;

      this.setState({
        hits: hits,
      });

      this.setState({loading: false});

    }, (error) => {

      this.setState({
        hits: hits,
        loading: false
      });
    })
  };

  fetchHit = (hit) => {
    this.setState({loading: true});
    googleApiGetCompany(hit.id, (response) => {
      this.setState({loading: true});
      this.props.onAddCustomer(response);
    }, (error) => {})
  };

  getDefaultHitView = (hit) => {
    const {classes} = this.props;

    return (
      <Card className={classes.card}
            key={hit.id}
            onClick={() => this.fetchHit(hit)} >
        <CardContent className={classes.content}>
          <Typography className={classes.companyName} align={"left"} noWrap >
            {hit.companyName}
          </Typography>

          {hit.address && (
            <Typography align={"left"} className={classes.text}>
              {hit.address.formatted}
            </Typography>
          )}
        </CardContent>
      </Card>
    )
  };

  getGrid = () => {
    const { hits } = this.state;
    return (
      <React.Fragment>
        {hits.map((hit) => {
          return this.getDefaultHitView({
            id: hit.place_id,
            companyName: hit.name,
            address: parseGoogleFormatterAddress(hit.formatted_address),
          });
        })}
      </React.Fragment>
    )
  };

  render() {
    return (
      <React.Fragment>
        {this.state.loading && (
          <Spinner size={40}/>
        )}

        {!this.state.loading && (
          this.getGrid()
        )}
      </React.Fragment>
    )
  }
}

GooglePlacesHits.propTypes = propTypes;

export default withStyles(styles)(
  injectIntl(GooglePlacesHits)
);
