class SearchCustomersParams {
  constructor(props = {}) {
    this.active = props.active || null;
    this.type = props.type || '';
    this.query = props.query || '';
    this.approved = props.approved || null;
    this.notApproved = props.notApproved || null;
    this.allowCredit = props.allowCredit || null;
    this.hasDefaultPaymentMethod = props.hasDefaultPaymentMethod || null;
    this['created:from'] = props['created:from'] || '';
    this['created:to'] = props['created:to'] || '';
    this['created:date-param'] = props['created:date-param'] || '';
    this['lastOrder:from'] = props['lastOrder:from'] || '';
    this['lastOrder:to'] = props['lastOrder:to'] || '';
    this['lastOrder:date-param'] = props['lastOrder:date-param'] || '';
    this.limit = props.limit || 10;
    this.skip = props.skip || 0;
  }

  toData() {
    let data = {};

    if (!!this.active) data['active'] = this.active;
    if (!!this.type) data['type'] = this.type;
    if (!!this.query) data['query'] = this.query;
    if (!!this.approved) data['approved'] = this.approved;
    if (!!this.notApproved) data['notApproved'] = this.notApproved;
    if (!!this.allowCredit) data['allowCredit'] = this.allowCredit;
    if (!!this.hasDefaultPaymentMethod) data['hasDefaultPaymentMethod'] = this.hasDefaultPaymentMethod;
    if (!!this['created:from']) data['created:from'] = this['created:from'];
    if (!!this['created:to']) data['created:to'] = this['created:to'];
    if (!!this['created:date-param']) data['created:date-param'] = this['created:date-param'];
    if (!!this['lastOrder:from']) data['lastOrder:from'] = this['lastOrder:from'];
    if (!!this['lastOrder:to']) data['lastOrder:to'] = this['lastOrder:to'];
    if (!!this['lastOrder:date-param']) data['lastOrder:date-param'] = this['lastOrder:date-param'];
    data['limit'] = this.limit;
    data['skip'] = this.skip;

    return data;
  }
}

export default SearchCustomersParams;
