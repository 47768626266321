import { toCurrency } from './money';

/**
 * Get line item variant name
 * Should be empty if equals to unit or "Default title"
 * @param {object} item
 * @param {string} prefix
 * @return {string}
 */
export const getLineItemVariantName = (item, prefix) => {
  let name = '';
  if (!!item?.name?.variant && item.name.variant !== item.unit && item.name.variant !== 'Default Title') {
    name = ' ' + prefix + ' ' + item.name.variant;
  }
  return name;
};

export const variantHasPriceWithLevel = (variant, level) => {
  const prices = variant.prices || [];
  return prices.some((item) => item.level === level);
};

export const getVariantPrice = (variant, intl, level) => {
  let price = 0;
  level = level || process.env.REACT_APP_PRICE_LEVEL;
  const filtered = variant.prices.filter((item) => item.level === level);
  return filtered.length ? toCurrency(filtered[0].price, intl).toString() : toCurrency(price, intl).toString();
};

export const getVariantPriceByCustomerType = (variant, intl, customerType) =>
  getVariantPrice(variant, intl, `${customerType}_preorder`);

export const getVariantPriceWithoutCurrency = (variant, level) => {
  let price = 0;
  level = level || process.env.REACT_APP_PRICE_LEVEL;
  const filtered = variant.prices.filter((item) => item.level === level);
  return filtered.length ? filtered[0].price : price;
};

export const getVariantPriceByLocationWithoutCurrency = (variant, level, location) => {
  let price = 0;

  variant.prices.forEach((item) => {
    if (item.level === level) {
      item.locations.forEach((loc) => {
        if (loc.id === location) price = loc.price;
      });
    }
  });

  return price;
};

export const getVariantUnit = (variant, emptyIfSameAsName, prefix) => {
  if (emptyIfSameAsName && variant.name === variant.unit) {
    return '';
  }
  prefix = prefix || '';
  return prefix + variant.unit;
};
