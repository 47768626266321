import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { AdminTabContent, AdminTabContentHeader, AdminTabContentTitle, Icon } from '../../components';
import { capitalizeFirstLetter, COLORS } from '../../helpers';
import { AppBar } from '@material-ui/core';
import { HEIGHT_OF_TOP_BAR, WIDTH_OF_SIDEBAR, WIDTH_OF_SIDEBAR_COLLAPSED } from '../../helpers';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';
import { useNavigate } from 'react-router';

const propTypes = {
  tab: PropTypes.object.isRequired,
  actions: PropTypes.object,
};

const useStyles = makeStyles(() => ({
  appBar: {
    backgroundColor: '#ffffff',
    boxShadow: 'none',
    borderTop: '1px solid ' + COLORS.border,
    borderBottom: '1px solid ' + COLORS.border,
    paddingLeft: 20,
    paddingRight: 20,
  },
  iconButtonWrapper: {},
  iconButton: {
    marginLeft: -10,
    marginBottom: -10,
    marginTop: -10,
    marginRight: 10,
  },
  icon: {
    padding: 0,
  },
}));

const HEIGHT_OF_ACTION_BAR = 70;

const Tab = ({ actions, tab, children }) => {
  const { drawerExpand } = useSelector((state) => ({
    drawerExpand: state.common.drawerExpand,
  }));
  const classes = useStyles();
  const history = useNavigate();

  const getAppBarStyle = (order = 1) => {
    const sideBarWidth = drawerExpand ? WIDTH_OF_SIDEBAR : WIDTH_OF_SIDEBAR_COLLAPSED;

    return { top: HEIGHT_OF_TOP_BAR * order, left: sideBarWidth, width: window.innerWidth - sideBarWidth };
  };

  return (
    <>
      <div style={{ height: HEIGHT_OF_ACTION_BAR }} />

      <AppBar className={classes.appBar} position="fixed" style={getAppBarStyle(2)}>
        <AdminTabContentHeader actions={actions}>
          {!!tab?.withBackButton && (
            <div className={classes.iconButtonWrapper}>
              <IconButton
                onClick={() => {
                  history(-1);
                }}
                className={classes.iconButton}>
                <Icon icon={ArrowBackIosIcon} className={classes.icon} />
              </IconButton>
            </div>
          )}
          <AdminTabContentTitle>{capitalizeFirstLetter(tab.name)}</AdminTabContentTitle>
        </AdminTabContentHeader>
      </AppBar>

      <AdminTabContent>{children}</AdminTabContent>
    </>
  );
};

Tab.propTypes = propTypes;

export default injectIntl(Tab);
