export default ({
  wrapper: {
    paddingTop: 18,
    paddingLeft: 18,
    paddingRight: 18
  },
  filtersWrapper: {
    marginLeft: 13,
    marginRight: 13
  }
});
