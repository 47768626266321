import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withRouter } from '../../../hocs';
import { withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import {
  apiChangeSupplierOrderingSettings,
  apiEditSupplierById,
  apiGetAllSuppliers,
  apiSupplierAddCustomer,
  apiSupplierCreate,
} from '../../../api';
import { AdminDXTable, AdminTabContentHeader, PrimarySwitch, TabActions } from '../../../components';
import { TableCell } from '../helpers';
import {
  COLORS,
  countWidthOfColumns,
  DEFAULT_MAX_CELL_WIDTH,
  DEFAULT_MIN_CELL_WIDTH,
  SHARED_PROPERTY_TYPES,
} from '../../../helpers';
import styles from './styles';
import { getGridPageSize, getGridPageSizes } from '../../../helpers/grid';
import CreateNewSupplierDialog from '../components/CreateNewSupplierDialog/CreateNewSupplierDialog';
import EditSupplierDialog from '../components/EditSupplierDialog/EditSupplierDialog';
import Producer from '../../../entities/Producer';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Block from '../../SettingsScene/Components/Block';
import { connect } from 'react-redux';
import { setProducer } from '../../../store/actions';
import ErrorBox from '../../../components/ErrorBox/ErrorBox';

const propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf([SHARED_PROPERTY_TYPES.TYPE, SHARED_PROPERTY_TYPES.UNIT, SHARED_PROPERTY_TYPES.SUB_TYPE]),
};
const defaultProps = {};

class Tab extends React.Component {
  constructor(props) {
    super(props);
    const { name } = props;

    this.columnExtensions = [{ columnName: 'image', width: 90 }, { columnName: 'name' }];

    this.columns = [
      { name: 'image', title: ' ', type: 'image' },
      { name: 'name', title: name, type: 'text' },
    ];

    this.state = {
      loading: true,
      creating: false,
      updating: null,
      deleting: null,
      suppliers: [],
      supplierToEdit: new Producer({}),

      columnWidths: countWidthOfColumns(this.columns, [], this.columnExtensions, 550),

      supplierOrdering: (props.producer.supplierOrdering && !!props.producer?.customer) || false,
      sendingRequest: false,
      supplierOrderingError: '',
    };
  }

  componentWillUnmount() {
    this.table = null;
  }

  _retrieve = (queryParams, onSuccess, onError) => {
    apiGetAllSuppliers((rows) => {
      this.setState({
        suppliers: rows,
      });
      onSuccess(rows);
    }, onError);
  };

  // handleDelete = (objToDel, onDone, onError) => {
  //   onDone && onDone();
  // };

  handleUpdate = (supplierId, data, onDone, onError) => {
    apiEditSupplierById(
      supplierId,
      data,
      (response) => {
        onDone && onDone(response);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };

  handleCreate = (property, data, onDone, onError) => {
    apiSupplierCreate(
      data,
      (producerObj) => {
        this.table.onUpdateRowsExternally(producerObj.suppliers);
        this.setState({
          creating: false,
        });
        onDone && onDone(producerObj);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };

  onEditSupplier = (row) => {
    this.setState({ updating: true, supplierToEdit: new Producer(row) });
  };

  onChangeSupplierOrdering = (e) => {
    const { intl, onSetProducer } = this.props;

    const onError = (error) => {
      this.setState({
        supplierOrderingError:
          error?.response?.data?.message || intl.formatMessage({ id: 'messages.somethingWentWrong' }),
        sendingRequest: false,
        supplierOrdering: !e.target.checked,
      });
    };

    const onSuccess = (response, customerObj = null) => {
      const supplierOrdering = response?.supplierOrdering;

      if (!!customerObj) {
        onSetProducer({ ...response, customer: customerObj });
      } else {
        onSetProducer(response);
      }

      this.setState({
        sendingRequest: false,
        supplierOrdering: supplierOrdering,
      });
    };

    // Its make change button immediately after click, and in case of error turn it back after request
    this.setState(
      {
        supplierOrdering: e.target.checked,
        sendingRequest: true,
        supplierOrderingError: '',
      },
      () => {
        apiChangeSupplierOrderingSettings(
          e.target.checked,
          (response) => {
            const isCustomerExist = !!response?.customer;

            if (!isCustomerExist && e.target.checked) {
              apiSupplierAddCustomer((newCustomer) => onSuccess(response, newCustomer), onError);
            } else {
              onSuccess(response);
            }
          },
          onError
        );
      }
    );
  };

  drawSupplierOrderingSwitcher = () => {
    const { intl, classes } = this.props;
    const { supplierOrdering, sendingRequest, supplierOrderingError } = this.state;

    return (
      <>
        <ErrorBox error={supplierOrderingError} />

        <Block
          paperClass={classNames(classes.mr0, classes.mt20, classes.mb40)}
          contentClass={classes.contentWithSwitch}>
          <Typography className={classes.text} variant={'body1'}>
            {intl.formatMessage({ id: 'suppliers.enableOrderingFromSuppliers' })}
          </Typography>

          <FormControlLabel
            classes={{
              label: classes.smallLabel,
              root: classes.smallLabelRoot,
            }}
            labelPlacement="bottom"
            control={
              <PrimarySwitch
                checked={supplierOrdering}
                onChange={this.onChangeSupplierOrdering}
                disabled={sendingRequest}
              />
            }
            label={
              supplierOrdering
                ? intl.formatMessage({ id: 'global.enabled' })
                : intl.formatMessage({ id: 'global.disabled' })
            }
          />
        </Block>
      </>
    );
  };

  render() {
    const { intl, classes, type, addButtonText } = this.props;
    const {
      creating,
      updating,
      //deleting,
      supplierToEdit,
    } = this.state;

    return (
      <div className={classes.wrapper}>
        <div className={classes.table}>
          <AdminTabContentHeader
            actions={
              <TabActions
                onCreateEntity={() => this.setState({ creating: true })}
                labels={{
                  createEntity: addButtonText,
                }}
              />
            }
          />

          <AdminDXTable
            onRef={(table) => (this.table = table)}
            apiRetrieve={this._retrieve}
            cellRenderer={(...props) => {
              return TableCell(type, ...props);
            }}
            enableSearch
            pageSize={getGridPageSize()}
            pageSizes={getGridPageSizes()}
            currencyColumnNames={[]}
            columnExtensions={this.columnExtensions}
            actionsTitle={intl.formatMessage({ id: 'global.actions' })}
            columns={this.columns}
            sorting={[{ columnName: 'name', direction: 'asc' }]}
            actionsHeaderCellStyle={{ textAlign: 'center', paddingRight: 0 }}
            actionsCellStyle={{ textAlign: 'center', paddingRight: 0 }}
            actions={[
              {
                icon: <EditIcon style={{ color: COLORS.text, fontSize: 18 }} />,
                action: this.onEditSupplier,
              },
              // delete button
              // {
              //   icon: <CancelIcon style={{ color: COLORS.text, fontSize: 18 }} />,
              //   action: (row) => this.setState({ deleting: row }),
              // },
            ]}
            columnWidths={this.state.columnWidths}
            onColumnWidthsChange={(widths) => {
              this.setState({ columnWidths: widths });
            }}
            minColumnWidth={DEFAULT_MIN_CELL_WIDTH}
            maxColumnWidth={DEFAULT_MAX_CELL_WIDTH}
          />

          {this.drawSupplierOrderingSwitcher()}
        </div>

        {creating && (
          <CreateNewSupplierDialog onClose={() => this.setState({ creating: false })} onSave={this.handleCreate} />
        )}

        {updating && (
          <EditSupplierDialog
            supplierId={supplierToEdit.id}
            onClose={() => this.setState({ updating: false })}
            onSave={this.handleUpdate}
          />
        )}

        {/*
        // Deleting supplier functional
        {deleting && (
          <DeleteProductSharedPropertyDialog
            onClose={() => this.setState({ deleting: null })}
            typeName={type}
            object={deleting}
            types={this.state.types}
            onDelete={this.handleDelete}
          />
        )}
        */}
      </div>
    );
  }
}

Tab.propTypes = propTypes;
Tab.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  return {
    producer: state.producer.object,
    supplierOrdering: state.common.supplierOrdering,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetProducer: (newProducer) => dispatch(setProducer(newProducer)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(injectIntl(Tab))));
