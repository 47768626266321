export const getGridPageSize = () => {
  if (process.env.NODE_ENV === 'production') {
    return parseInt(process.env.REACT_APP_PROD_GRID_ITEMS_PER_PAGE);
  }
  return parseInt(process.env.REACT_APP_DEV_GRID_ITEMS_PER_PAGE);
};

export const getGridPageSizes = () => {
  if (process.env.NODE_ENV === 'production') {
    let size = parseInt(process.env.REACT_APP_PROD_GRID_ITEMS_PER_PAGE);
    return [size, size + size/2, size + size/2 + size/2, 0];
  }
  let size = parseInt(process.env.REACT_APP_DEV_GRID_ITEMS_PER_PAGE);
  return [size, size + size/2, size + size/2 + size/2, 0];
};