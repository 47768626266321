import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Table } from '@devexpress/dx-react-grid-material-ui';

const propTypes = {
  image: PropTypes.object,
  src: PropTypes.string,
};

const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
    paddingTop: 10,
    paddingBottom: 10,
  },
  imageWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    height: 50,
    position: 'relative',
    overflow: 'hidden',
  },
  image: { height: '100%', width: 'auto', position: 'absolute' },
}));

const CellAsImage = (props) => {
  const { onClick, src: initSrc, image, alt, ...others } = props;
  const classes = useStyles();

  const src = !!initSrc ? initSrc : image?.thumbSrc || image?.src;

  const srcWithId = `${src}?${image?.id || 'new'}`;

  return (
    <Table.Cell className={classes.root} {...others}>
      {!!src ? (
        <div className={classes.imageWrapper}>
          <img src={srcWithId} alt={alt || ''} className={classes.image} {...(onClick ? { onClick } : {})} />
        </div>
      ) : (
        <div className={classes.imageWrapper} />
      )}
    </Table.Cell>
  );
};

CellAsImage.propTypes = propTypes;

export default CellAsImage;
