import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { OutlineButton } from '../../../components';
import { COLORS, getVariantPrice, PRICE_LEVELS, toCurrency } from '../../../helpers';
import EmptyImage from '../assets/no.jpg';
import AddIcon from '@material-ui/icons/Add';

const propTypes = {
  onUpdateVariant: PropTypes.func.isRequired,
  onCreateVariant: PropTypes.func.isRequired,
  variants: PropTypes.array,
};

const styles = {
  wrapper: {
    width: '100%',
    borderTop: '1px solid ' + COLORS.border,
    padding: '20px 25px 5px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 5,
    paddingBottom: 5,
    justifyContent: 'space-between',
  },
  title: {
    color: COLORS.cartSecondary,
    fontSize: '12px',
  },
  button: {
    padding: 0,
  },
  icon: {
    fontSize: 32,
    color: COLORS.cartSecondary,
  },
  variant: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid' + COLORS.border,
    padding: '5px 0 10px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 0 10px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cell: {
    flex: 1,
    fontSize: '14px',
    color: COLORS.text,
    fontFamily: 'Roboto',
    marginRight: 5,
    textAlign: 'left',
  },
  headerCell: {
    flex: 1,
    fontSize: '14px',
    color: COLORS.cartSecondary,
    fontFamily: 'Roboto',
    marginRight: 5,
    textAlign: 'left',
  },
  image: {
    height: 40,
    borderRadius: 5,
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  variantsWrapper: {
    marginTop: 40,
  },
  variantsHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    justifyItems: 'center',
  },
  variantsTitle: {
    display: 'flex',
    justifyContent: 'center',
    justifyItems: 'center',
  },
  addVariantButtonWrapper: {},
  variantListWrapper: {
    marginTop: 20,
    borderRadius: 9,
    boxShadow: '0px 1px 3px #00000066',
    paddingRight: 5,
    paddingLeft: 20,
    paddingTop: 20,
    paddingBottom: 5,
  },
};

const getImage = (variant) => {
  if (variant.image && variant.image.src) {
    return variant.image.src;
  }
  if (variant.image && !variant.image.src) {
    return `data:image/png;base64,${variant.image}`;
  }
  return EmptyImage;
};

const getPrice = (variant, intl, level) => {
  if (variant[`price.${level}`]) {
    return toCurrency(variant[`price.${level}`], intl).toString();
  }

  if (variant.prices) {
    return getVariantPrice(variant, intl, level);
  }

  return 0;
};

const Variants = ({ intl, classes, variants, onUpdateVariant, onCreateVariant }) => {
  return (
    <div className={classes.variantsWrapper}>
      <div className={classes.variantsHeader}>
        <div className={classes.variantsTitle}>
          <Typography className={classes.sectionTitle} component="h3" variant="subtitle1">
            {intl.formatMessage({ id: 'screen.product.variants' })}
          </Typography>
        </div>
        <div className={classes.addVariantButtonWrapper}>
          <OutlineButton icon={AddIcon} className={classes.button} onClick={onCreateVariant}>
            {intl.formatMessage({ id: 'screen.product.addVariant' })}
          </OutlineButton>
        </div>
      </div>
      {!!variants.length && (
        <div className={classes.variantListWrapper}>
          {!!variants.length && (
            <div className={classes.headerRow}>
              <div style={{ textAlign: 'left' }} className={classes.cell}>
                &nbsp;
              </div>
              <div className={classes.headerCell}>{intl.formatMessage({ id: 'global.name' })}</div>
              <div className={classes.headerCell}>{intl.formatMessage({ id: 'global.unit' })}</div>
              <div className={classes.headerCell} style={{ textAlign: 'center' }}>
                {intl.formatMessage({ id: 'products.column.retailPreorder' })}
              </div>
              <div style={{ textAlign: 'right' }} className={classes.headerCell}>
                &nbsp;
              </div>
            </div>
          )}

          {variants.map((variant) => (
            <div key={variant.id} className={classes.variant}>
              <div style={{ textAlign: 'left' }} className={classes.cell}>
                <img className={classes.image} alt={'Line item'} src={getImage(variant)} />
              </div>
              <div className={classes.cell}>{variant?.name || ''}</div>
              <div className={classes.cell}>{variant.unit}</div>
              <div className={classes.cell} style={{ textAlign: 'center' }}>
                {getPrice(variant, intl, PRICE_LEVELS.RETAIL_PREORDER)}
              </div>
              <div style={{ textAlign: 'right', flex: 1 }} className={classes.cell}>
                <IconButton className={classes.button} onClick={() => onUpdateVariant(variant)}>
                  <KeyboardArrowRightIcon className={classes.icon} />
                </IconButton>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

Variants.propTypes = propTypes;

export default withStyles(styles)(injectIntl(Variants));
