import { COLORS } from '../../helpers';

const styles = (theme) => ({
  root: {},
  toolbar: {},
  wrapper: {},
  editor: {
    marginTop: 10,
    border: '1px solid #f1f1f1',
    padding: 10,
  },
  textWrapper: {
    fontSize: 14,
    color: COLORS.textSecondary,
    fontFamily: 'Roboto, sans-serif',
  },
  paperClass: {
    marginRight: 0,
  },
  textViewer: {
    '& p': {
      paddingBottom: 22,
      '&:last-of-type': {
        paddingBottom: 0,
      },
    },
    '& a': {
      color: '#000000',
    },
  },
});

export default styles;
