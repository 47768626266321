export default ({
  container: {
    display: 'flex',
    justifyContent: 'left',
  },
  backButtonWrapper: {
    padding: 25,
  },
  mainContent: {
    maxWidth: 800,
    width: '100%',
    paddingTop: 25,
    paddingBottom: 25,
    marginRight: 15
  },
  title: {
    fontSize: 25,
    color: '#000',
    fontWeight: 'bold',
    marginTop: 5,
    marginBottom: 25,
  },
  viewStoreWrapper:{
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 20
  },
  viewStoreButton:{
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontWeight: 'bold',
    transition: 'opacity 0.4s ease-in-out',
    opacity: 1,
    '&:hover, &:active, &:focus': {
      opacity: 0.6
    }
  },
  viewStoreIcon:{
    fontSize: 26,
    marginRight: 10
  },
});
