import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Table } from '@devexpress/dx-react-grid-material-ui'
import { injectIntl } from 'react-intl'
import { withStyles } from '@material-ui/core/styles'

const styles = ({
  cell: {
    textTransform: 'capitalize'
  }
});

const propTypes = {
  row: PropTypes.object.isRequired,
};

const Cell = ({intl, row, classes, ...other}) =>  {
  return (
    <Table.Cell {...other}>
      <span className={classes.cell}>
        {row.until.day} - {moment(row.until.time, "HH:mm:ss").format("hh:mm A")}
      </span>
    </Table.Cell>
  );
};

Cell.propTypes = propTypes;

export default withStyles(styles)(
  injectIntl(Cell)
);
