import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from '../../hocs';
import { withStyles } from '@material-ui/core/styles';
import { AdminLayout, Spinner, TwoColumnContent } from '../../components';
import { SidebarMenu, HeaderMenu, ProductsSearch, OrderCart } from '../Partials';
import { AddToCartDialog, AddCustomItemDialog, PatchItemDialog } from '../../dialogs';
import {
  setDraft,
  unsetDraft,
  // Customer
  draftAttachCustomer,
  draftChangeCustomerAddress,
  draftDetachCustomer,
  // Cart
  draftAttachVariant,
  draftAttachCustomItem,
  draftPatchLineItem,
  draftDetachLineItem,
  draftChangeLineItemNote,
  draftChangeLineItemQuantity,
  draftAddNote,
  draftAttachShipping,
  draftRemoveShippingDeliveryCompany,
  draftAttachPaymentMethod,
  draftComplete,
  refreshDraft,
  draftDeleteDiscount,
} from '../../store/actions/draft';
import styles from './styles';
import { BILLING_ADDRESS_TYPE, DELIVERY_ADDRESS_TYPE, CUSTOMER_TYPE } from '../../helpers';

class EditDraftScene extends React.Component {
  state = {
    draft: null,
    addingProductToCart: null,
    addingCustomItemToCart: false,
    editingLineItem: null,
    loading: true,
    customerType: CUSTOMER_TYPE.WHOLESALE,
  };

  componentDidMount() {
    this.retrieveDraft();
  }

  componentWillUnmount() {
    this.props.unsetDraft();
  }

  retrieveDraft = () => {
    const { match } = this.props;

    this.props.setDraft(
      match.params.id,
      (draft) =>
        this.setState({
          draft,
          loading: false,
          customerType: draft.type || CUSTOMER_TYPE.WHOLESALE,
        }),
      undefined
    );
  };

  render() {
    const {
      producer,
      draft,
      history,
      draftCustomer,
      draftLoading,
      draftAttachCustomer,
      draftChangeCustomerAddress,
      draftDetachCustomer,
      // Cart
      refreshDraft,
      draftAttachVariant,
      draftPatchLineItem,
      draftAttachCustomItem,
      draftDetachLineItem,
      draftChangeLineItemNote,
      draftChangeLineItemQuantity,
      draftAddNote,
      draftAttachShipping,
      draftAttachPaymentMethod,
      draftComplete,
      draftDeleteDiscount,
      draftRemoveShippingDeliveryCompany,
      openDiscountModal,
    } = this.props;

    const { customerType } = this.state;

    return (
      <AdminLayout sidebarComponent={SidebarMenu} headerMenuComponent={HeaderMenu}>
        {this.state.loading && <Spinner size={80} />}

        <TwoColumnContent
          leftContent={
            <ProductsSearch
              producer={producer}
              onAddCustomItem={() => this.setState({ addingCustomItemToCart: true })}
              onProductClick={(product) => this.setState({ addingProductToCart: product })}
            />
          }
          rightContent={
            this.state.loading ? null : (
              <OrderCart
                producer={producer}
                loading={draftLoading}
                customer={draftCustomer}
                order={draft}
                // Customer
                customerType={customerType}
                onChangeCustomerType={(customerType) => this.setState({ customerType })}
                onAttachCustomer={(customer) => draftAttachCustomer(draft, customer)}
                onDetachCustomer={() => draftDetachCustomer(draft)}
                // Address
                onChangeCustomerShippingAddress={(addressId) =>
                  draftChangeCustomerAddress(draft, draftCustomer, addressId, DELIVERY_ADDRESS_TYPE)
                }
                onChangeCustomerBillingAddress={(addressId) =>
                  draftChangeCustomerAddress(draft, draftCustomer, addressId, BILLING_ADDRESS_TYPE)
                }
                // Line item
                onDetachLineItem={(lineItem) => draftDetachLineItem(draft, lineItem)}
                onChangeLineItemNote={(lineItem, note) => draftChangeLineItemNote(draft, lineItem, note)}
                onChangeLineItemQuantity={(lineItem, quantity, onSuccess, onError) =>
                  draftChangeLineItemQuantity(draft, lineItem, quantity, onSuccess, onError)
                }
                onPatchLineItem={(draft, lineItem) => this.setState({ editingLineItem: lineItem })}
                // Order
                onChangeNote={(note) => draftAddNote(draft, note)}
                // Shipping
                onChangeShipping={(method, date, deliveryCompany, location) =>
                  draftAttachShipping(draft, method, date, deliveryCompany, location)
                }
                onChangePaymentMethod={(method) => draftAttachPaymentMethod(draft, method)}
                onComplete={() =>
                  draftComplete(draft, ({ order, subscriptionId }) => {
                    if (subscriptionId) {
                      history.replace(`/subscriptions/${subscriptionId}`);
                      return;
                    }

                    history.replace(`/order/${order.id}`);
                  })
                }
                // Discount
                onCreateDiscount={(order) => refreshDraft(order)}
                onUpdateDiscount={(order) => refreshDraft(order)}
                onDeleteDiscount={(order) => draftDeleteDiscount(order)}
                onRemoveShippingDeliveryCompany={draftRemoveShippingDeliveryCompany}
                mayShowCompleteButton={
                  draft.customer &&
                  draft.payment &&
                  draft.shipping &&
                  !!draft.lineItems.length &&
                  (!!draft.shipping.deliveryCompany || !!draft.shipping.location || !!draft.shipping.type)
                }
                openDiscountModal={openDiscountModal}
              />
            )
          }
        />

        {this.state.editingLineItem && (
          <PatchItemDialog
            onClose={() => this.setState({ editingLineItem: null })}
            customerType={customerType}
            lineItem={this.state.editingLineItem}
            loading={draftLoading}
            onDone={(lineItem, data, onError) =>
              draftPatchLineItem(draft, lineItem, data, () => this.setState({ editingLineItem: null }), onError)
            }
          />
        )}

        {this.state.addingProductToCart && (
          <AddToCartDialog
            onClose={() => this.setState({ addingProductToCart: null })}
            customerType={customerType}
            loading={draftLoading}
            onDone={(product, variant, data, onError) =>
              draftAttachVariant(
                draft,
                product,
                variant,
                data,
                () => this.setState({ addingProductToCart: null }),
                onError
              )
            }
            product={this.state.addingProductToCart}
          />
        )}

        {this.state.addingCustomItemToCart && (
          <AddCustomItemDialog
            producer={producer}
            loading={draftLoading}
            onClose={() => this.setState({ addingCustomItemToCart: false })}
            onDone={(data) =>
              draftAttachCustomItem(draft, data, () => this.setState({ addingCustomItemToCart: false }))
            }
          />
        )}
      </AdminLayout>
    );
  }
}

EditDraftScene.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    draft: state.draft.object,
    draftCustomer: state.draft.customer,
    draftLoading: state.draft.loading,
    producer: state.producer.object,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDraft: (id, onSuccess, onError) => dispatch(setDraft(id, onSuccess, onError)),
    unsetDraft: (draft) => dispatch(unsetDraft(draft)),

    refreshDraft: (draft, onSuccess, onError) => dispatch(refreshDraft(draft, onSuccess, onError)),

    // Discount
    draftDeleteDiscount: (draft, onSuccess, onError) => dispatch(draftDeleteDiscount(draft, onSuccess, onError)),

    // Customer
    draftAttachCustomer: (draft, customer, onSuccess, onError) =>
      dispatch(draftAttachCustomer(draft, customer, onSuccess, onError)),
    draftDetachCustomer: (draft, onSuccess, onError) => dispatch(draftDetachCustomer(draft, onSuccess, onError)),
    draftChangeCustomerAddress: (draft, customer, addressId, addressType, onSuccess, onError) =>
      dispatch(draftChangeCustomerAddress(draft, customer, addressId, addressType, onSuccess, onError)),

    // Cart
    draftAttachVariant: (draft, product, variant, data, onSuccess, onError) =>
      dispatch(draftAttachVariant(draft, product, variant, data, onSuccess, onError)),
    draftAttachCustomItem: (draft, data, onSuccess, onError) =>
      dispatch(draftAttachCustomItem(draft, data, onSuccess, onError)),
    draftDetachLineItem: (draft, lineItem, onSuccess, onError) =>
      dispatch(draftDetachLineItem(draft, lineItem, onSuccess, onError)),
    draftPatchLineItem: (draft, lineItem, data, onSuccess, onError) =>
      dispatch(draftPatchLineItem(draft, lineItem, data, onSuccess, onError)),

    // Shipping
    draftAttachShipping: (draft, method, data, deliveryCompany, location, onSuccess, onError) =>
      dispatch(draftAttachShipping(draft, method, data, deliveryCompany, location, onSuccess, onError)),

    draftRemoveShippingDeliveryCompany: (draft, onSuccess, onError) =>
      dispatch(draftRemoveShippingDeliveryCompany(draft, onSuccess, onError)),

    draftChangeLineItemNote: (draft, lineItem, note, onSuccess, onError) =>
      dispatch(draftChangeLineItemNote(draft, lineItem, note, onSuccess, onError)),
    draftChangeLineItemQuantity: (draft, lineItem, quantity, onSuccess, onError) =>
      dispatch(draftChangeLineItemQuantity(draft, lineItem, quantity, onSuccess, onError)),
    draftAddNote: (draft, note, onSuccess, onError) => dispatch(draftAddNote(draft, note, onSuccess, onError)),
    draftAttachPaymentMethod: (draft, method, data, onSuccess, onError) =>
      dispatch(draftAttachPaymentMethod(draft, method, data, onSuccess, onError)),
    draftComplete: (id, onSuccess, onError) => dispatch(draftComplete(id, onSuccess, onError)),
  };
};

export default withStyles(styles, { withTheme: true })(
  injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(EditDraftScene)))
);
