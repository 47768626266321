import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../../../hocs';
import { COLORS, toCurrency } from '../../../../helpers';

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px 20px 10px',
    margin: '0 -20px',
    borderTop: '1px solid ' + COLORS.border,
    borderBottom: '1px solid ' + COLORS.border,
  },
};

const propTypes = {
  order: PropTypes.object.isRequired,
};
const defaultProps = {};

const Prepurchase = ({ order, classes, intl }) => {
  return (
    <React.Fragment>
      {order.prepurchaseOrder && order.payment && order.payment.prepurchase && (
        <div className={classes.wrapper}>
          {intl.formatMessage({ id: 'order.csa' })}: {order.payment.prepurchase.name}{' '}
          {toCurrency(order.payment.amountPrepurchased, intl)}
        </div>
      )}
    </React.Fragment>
  );
};

Prepurchase.propTypes = propTypes;
Prepurchase.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(injectIntl(withRouter(Prepurchase)));
