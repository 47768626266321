import { CUSTOMER_TYPE } from '../../helpers';

class GeneralInfoFields {
  constructor(props) {
    this.email = props.email || '';
    this.username = props.username || '';
    this.firstName = props.firstName || '';
    this.lastName = props.lastName || '';
    this.phone = props.phone || '';

    if (props?.type === CUSTOMER_TYPE.WHOLESALE) {
      this.companyName = props.companyName || '';
    }
  }
}

export default GeneralInfoFields;
