import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { AdminLayout, ErrorBox } from '../../components';
import { SidebarMenu, HeaderMenu } from '../Partials';
import styles from './styles';
import { makeStyles } from '@material-ui/core';
import SeparatedTableHeader from './components/SeparatedTableHeader/SeparatedTableHeader';
import TitleAsBreadcrumbsWithSearchComponent from './components/TitleAsBreadcrumbsWithSearchComponent/TitleAsBreadcrumbsWithSearchComponent';
import { apiCustomerRetrieve, apiGetMemberLineItems } from '../../api';
import { withRouter } from '../../hocs';
import { getCustomerName } from '../../apps/deliveries/helpers/global';
import { getAllItemsCount, getStartDateAndEndDateOfMonthByDate } from './helpers';
import PeriodChangeHeaderCell from './components/PeriodChangeHeaderCell/PeriodChangeHeaderCell';
import MemberServiceCustomerReportSkeleton from './components/MemberServiceCustomerReportSkeleton/MemberServiceCustomerReportSkeleton';
import { makeTuesdaysData } from './dummy';
import moment from 'moment';
import { getDateFromHash } from '../../helpers/date';
import DatesListComponent from './components/DatesListComponent';
import BaseDialog from '../../dialogs/BaseDialog';
import { SubscriptionForm } from '../../forms';
import { useSelector } from 'react-redux';

const propTypes = {
  title: PropTypes.string,
  searchOnClient: PropTypes.bool,
  apiRetrieve: PropTypes.func,
};

const defaultProps = {
  searchOnClient: true,
  apiRetrieve: apiGetMemberLineItems,
};

const useStyles = makeStyles(styles);

const MemberServiceCustomerReport = (props) => {
  const { title, apiRetrieve, searchOnClient, location, history, openItemModal } = props;
  const classes = useStyles();
  const intl = useIntl();
  const [customer, setCustomer] = useState(null);
  const [itemsData, setItemsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(
    getStartDateAndEndDateOfMonthByDate(getDateFromHash(location?.hash))
  );
  const [query, setQuery] = useState('');
  const [collapsingState, setCollapsingState] = useState([]);
  const [error, setError] = useState('');
  const [searchItemsBackup, setSearchItemsBackup] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const { producer } = useSelector((state) => ({
    producer: state.producer.object,
  }));

  useEffect(() => {
    init();
  }, [selectedMonth?.from, selectedMonth?.to]);

  useEffect(() => {
    if (initialLoading) {
      return;
    }

    if (searchOnClient) {
      makeClientSideSearch(searchItemsBackup);
    } else {
      init();
    }
  }, [query]);

  const init = async () => {
    setLoading(true);
    setError('');
    let customerObj = customer;
    if (!customerObj) {
      customerObj = await getCustomer();
    }

    const params = {
      'shipping:from': selectedMonth.from,
      'shipping:to': selectedMonth.to,
      customerId: customerObj?.id,
    };

    if (!searchOnClient && query) {
      params['query'] = query;
    }

    apiRetrieve(
      params,
      (response) => {
        const withEmpty = makeTuesdaysData(response, selectedMonth);

        if (searchOnClient) {
          setSearchItemsBackup(withEmpty);
          makeClientSideSearch(withEmpty, true); // To apply search on change month
        } else {
          setItemsData(withEmpty);
        }

        setLoading(false);
        if (initialLoading) {
          setInitialLoading(false);
          replacePeriod(selectedMonth?.from); // Set initial month hash
        }
      },
      (error) => {
        setError(error?.response?.data?.message);
        setLoading(false);
      }
    );
  };

  const getCustomer = () => {
    return new Promise((resolve, reject) => {
      apiCustomerRetrieve(
        { id: props.match.params.customerId },
        (response) => {
          setCustomer(response);
          resolve(response);
        },
        (e) => {
          setError(e?.response?.data?.message || intl.formatMessage({ id: 'messages.somethingWentWrong' }));
          reject(e);
          setLoading(false);
        }
      );
    });
  };

  const columnsConfig = {
    columns: [
      { name: 'name', title: intl.formatMessage({ id: 'memberService.header.itemByPickUpDate' }) },
      { name: 'type', title: intl.formatMessage({ id: 'memberService.header.type' }) },
      { name: 'quantity', title: intl.formatMessage({ id: 'memberService.header.qty' }) },
      { name: 'location', title: intl.formatMessage({ id: 'memberService.header.location' }) },
      {
        name: 'action',
        title: ' ',
        component: () => (
          <PeriodChangeHeaderCell selectedPeriod={selectedMonth} onChange={onChaneSelectedMonth} disabled={loading} />
        ),
      },
    ],
    columnExtensions: [
      { columnName: 'name', width: '30%' },
      { columnName: 'type', width: '12%' },
      { columnName: 'quantity', align: 'center', width: '15%' },
      { columnName: 'location', width: '23%' },
      { columnName: 'action', align: 'right', width: '20%' },
    ],
    hiddenColumnNames: [],
    sorting: [],
    appliedFilters: [],
  };

  const onChaneSelectedMonth = useCallback((newSelectedMonth) => {
    setSelectedMonth(newSelectedMonth);
    replacePeriod(newSelectedMonth.from);
  }, []);

  function replacePeriod(month) {
    history.replace({ ...location, hash: moment(month).format('MM-DD-YYYY') }); // replace current hash without update history
  }

  const onCollapseChange = (item, isAdd) => {
    // Disable collapse state change on search (to turn back prev collapse state after search is done)
    if (!!query) {
      return;
    }

    if (!isAdd) {
      setCollapsingState((oldCollapsingState) => [...oldCollapsingState.filter((o) => o !== item.id)]);
    } else {
      setCollapsingState((oldCollapsingState) => [...oldCollapsingState, item.id]);
    }
  };

  const makeClientSideSearch = (searchedData, silent = false) => {
    searchedData = searchedData.map((day) => {
      let items = day.items.filter((item) => {
        if (!query) {
          return true;
        }

        if (query.length === 1) {
          // To make possible find a single tag items by only one letter
          return !!(
            item?.subscription?.tags &&
            item?.subscription?.tags?.findIndex((i) => i.toLowerCase() === query.toLowerCase()) !== -1
          );
        }

        if (
          (item?.name?.product && item?.name?.product.toLowerCase().match(query.toLowerCase())) || // Product name
          (typeof item?.name?.variant === 'string' && item?.name?.variant.toLowerCase().match(query.toLowerCase())) || // Variant name
          (item?.shipping?.location?.name && item?.shipping?.location?.name.toLowerCase().match(query.toLowerCase())) || // Location name
          (item?.shipping?.type && item?.shipping?.type?.toLowerCase().match(query.toLowerCase())) || // Shipping type
          (item?.subscription?.type && item?.subscription?.type?.toLowerCase().match(query.toLowerCase())) // Subscription type
        ) {
          return true;
        }

        return false;
      });

      return {
        ...day,
        items,
      };
    });

    if (!silent) {
      reloadContentOnClientSideUpload(() => setItemsData(searchedData));
    } else {
      setItemsData(searchedData);
    }
  };

  const reloadContentOnClientSideUpload = (onDone) => {
    setLoading(true);
    setTimeout(() => {
      onDone && onDone();
      setLoading(false);
    }, 50); // Need to reload tables in items
  };

  const updateSingleItem = (item, row, newData) => {
    const { quantity } = newData;

    if (quantity !== undefined) {
      const newItemsData = itemsData.map((oldItem) => {
        oldItem.items.map((oldVariant) => {
          if (oldVariant.id === row.id) {
            oldVariant['orderedQuantity'] = quantity;
          }
          return oldVariant;
        });
        return oldItem;
      });
      setItemsData(newItemsData);
    }
  };

  const reloadAllData = () => {
    init();
  };

  const onCloseSubscriptionModal = () => {
    props.history.goBack();
    reloadAllData();
  };

  return (
    <AdminLayout sidebarComponent={SidebarMenu} headerMenuComponent={HeaderMenu}>
      <div className={classes.root}>
        <TitleAsBreadcrumbsWithSearchComponent
          breadcrumbConfig={[
            {
              name: title || intl.formatMessage({ id: 'global.memberService' }),
              action: () => props.history.goBack(),
            },
            { name: customer ? getCustomerName(customer) : '' },
          ]}
          isSticky
          headerOffset={50}
          onSearch={(newQuery) => {
            setQuery(newQuery);
          }}
          customer={customer}
          searchQuery={query}
        />
        <SeparatedTableHeader {...columnsConfig} isSticky headerOffset={127} />
        <ErrorBox error={error} className={classes.errorBox} />

        {loading && (
          <MemberServiceCustomerReportSkeleton
            columnsNumber={columnsConfig.columns.length}
            numberOfItems={10}
            openAll
          />
        )}

        {!loading && !error && (
          <div className={classes.itemsWrapper}>
            {(itemsData.length === 0 || (!!query && !getAllItemsCount(itemsData))) && (
              <div className={classes.noItemsInformer}>
                <p className={classes.noItemsInformerText}>{intl.formatMessage({ id: 'global.noItems' })}</p>
              </div>
            )}

            <DatesListComponent
              itemsData={itemsData}
              collapsingState={collapsingState}
              query={query}
              updateSingleItem={updateSingleItem}
              reloadAllData={reloadAllData}
              columnsConfig={columnsConfig}
              onCollapseChange={onCollapseChange}
            />
          </div>
        )}

        {openItemModal && (
          <BaseDialog
            scroll={'paper'}
            maxWidth="xl"
            open
            onClose={onCloseSubscriptionModal}
            title={intl.formatMessage({ id: 'subscriptions.editSbs' })}>
            <SubscriptionForm match={props.match} producer={producer} onSuccess={onCloseSubscriptionModal} />
          </BaseDialog>
        )}
      </div>
    </AdminLayout>
  );
};

MemberServiceCustomerReport.propTypes = propTypes;
MemberServiceCustomerReport.defaultProps = defaultProps;

export default withRouter(MemberServiceCustomerReport);
