export default {
  error: {
    backgroundColor: 'red',
    color: '#ffffff',
    marginBottom: 20,
    maxWidth: 'none',
    minWidth: 'none',
    '@media (min-width: 960px)': {
      maxWidth: 'none',
    }
  },
};