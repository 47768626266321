export const PRODUCTS_PER_PAGE = 20;
export const ORDERS_PER_PAGE = 20;

export const PADDING_CONTENT = 20;
export const PADDING_CONTENT_TOP = 20;
export const PADDING_HEADER_TOP = 5;
export const PADDING_CONTENT_BOTTOM = 20;
export const PADDING_HEADER_BOTTOM = 10;

export const TRANSITION_MAIN = 'all 0.3s ease-in-out';

export const BORDER_RADIUS_MAIN = 4;
export const BORDER_RADIUS_BUTTON = 12;

export const BUTTON_VARIANTS = {
  blue: 'blue',
  grey: 'grey',
  smallBlue: 'small-blue',
  smallGrey: 'small-grey',
  transparentRed: 'transparent-red',
  transparentBlue: 'transparent-blue',
  transparentGrey: 'transparent-grey',
};

export const PRICES_NAMES = {
  RETAIL_MARKET_PRICE: 'retailMarketPrice',
  RETAIL_PREORDER_PRICE: 'retailPreorderPrice',
  WHOLESALE_MARKET_PRICE: 'wholesaleMarketPrice',
  WHOLESALE_PREORDER_PRICE: 'wholesalePreorderPrice',
  RETAIL_MARKET_PRICE_LEVEL: 'retail_market',
  RETAIL_PREORDER_PRICE_LEVEL: 'retail_preorder',
  WHOLESALE_MARKET_PRICE_LEVEL: 'wholesale_market',
  WHOLESALE_PREORDER_PRICE_LEVEL: 'wholesale_preorder',
};

export const CUSTOMER_TYPES = {
  RETAIL: 'retail',
  WHOLESALE: 'wholesale',
  ANONYMOUS: 'anonymous',
};

export const ORDER_DATE_FORMAT = 'MMMM DD, YYYY';
export const ORDER_DATE_FORMAT_TODAY = 'h:mma';

export const NO_KEYBOARD_CLOSE_DATA_ATTRIBUTE = {
  keyboardClose: 'close',
  keyboardNoClose: 'no-close',
};
