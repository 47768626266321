import { Table } from '@devexpress/dx-react-grid-material-ui';
import React from 'react';
import { CellWithCopyToClipboard } from '../../components/AdminDXTable/Editing';

export const CellRenderer = (reload, { row, ...props }) => {
  if (props.column.name === 'email') {
    return <CellWithCopyToClipboard value={row?.email || ''} onSuccessMessage={'global.emailCopied'} />;
  }

  return <Table.Cell {...props} />;
};
