import React from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {withStyles} from '@material-ui/core/styles';
import Logo from "./assets/images/logo.png";
import styles from './styles';

class Header extends React.Component {
  render() {
    const {
      classes, leftContent, rightContent,
      centerContent, onLogoClick, afterLeftContent
    } = this.props;
    return (
      <AppBar className={(leftContent) ? classes.appBar : classes.appWithoutLeftContent}
              position={this.props.position || 'sticky'}>
        <Toolbar className={classes.topToolbar}>
          {leftContent ?
            leftContent() : null}

          {afterLeftContent ?
            afterLeftContent() : null}

          {centerContent ?
            centerContent() : null}

          <button className={classNames(classes.logoContainer, classes.hideDownMd)}
             onClick={() => {onLogoClick && onLogoClick()}}>
            <img src={Logo} className={classes.logo} alt="Fellow Farmer"/>
          </button>

          {rightContent ?
            (<div className={classes.right}>{rightContent()}</div>) : null}
        </Toolbar>
      </AppBar>
    )
  }
}


Header.propTypes = {
  position: PropTypes.string,
  onLogoClick: PropTypes.func,
  rightContent: PropTypes.func,
  centerContent: PropTypes.func,
  afterLeftContent: PropTypes.func,
  leftContent: PropTypes.func
};


export default withStyles(styles, {withTheme: true})(Header);