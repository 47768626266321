import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import { Typography } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  ErrorBox,
  MessageBox,
  PrimaryButton,
  PrimaryTextField,
  Icon,
  ErrorFormattedMessage,
  SimpleDialogContent,
} from '../../../components';
import { validateField } from '../../../helpers';
import validationRules from './validation';
import { apiProducerCreate } from '../../../api';
import styles from './styles';
import Spinner from '../../../components/Spinner/Spinner';
// import classNames from 'classnames';
import { onEnterPress } from '../../../helpers/events';

class RegisterStage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      requestSent: props.isFinal,
      isLoading: false,

      companyName: '',
      email: '',
      password: '',
      firstName: '',
      lastName: '',

      companyNameError: '',
      emailError: '',
      passwordError: '',
      firstNameError: '',
      lastNameError: '',

      requestError: '',
    };

    this.validate = this.validate.bind(this);
  }

  async validate() {
    if (this.state.isLoading) return false;

    const companyNameError = validateField(validationRules, 'companyName', this.state.companyName);
    const emailError = validateField(validationRules, 'email', this.state.email);
    const passwordError = validateField(validationRules, 'password', this.state.password);
    const firstNameError = validateField(validationRules, 'firstName', this.state.firstName);
    const lastNameError = validateField(validationRules, 'lastName', this.state.lastName);

    if (!companyNameError && !emailError && !firstNameError && !lastNameError && !passwordError) {
      this.setState({
        companyNameError: '',
        emailError: '',
        passwordError: '',
        firstNameError: '',
        lastNameError: '',

        requestError: '',
        isLoading: true,
      });

      try {
        await apiProducerCreate(
          {
            companyName: this.state.companyName,
            email: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            password: this.state.password,
          },
          this.onSuccess,
          this.onError
        );
      } catch (e) {
        console.log(e);
      }
    } else {
      this.setState({
        companyNameError: companyNameError,
        passwordError: passwordError,
        emailError: emailError,
        firstNameError: firstNameError,
        lastNameError: lastNameError,
        isLoading: false,
      });
    }
  }

  onError = (error) => {
    const { intl } = this.props;
    this.setState({
      requestError:
        (error.response && error.response.data && error.response.data.message) ||
        intl.formatMessage({ id: 'messages.somethingWentWrong' }),
      isLoading: false,
    });
  };

  onSuccess = () => {
    this.setState({
      requestError: '',
      isLoading: false,
      requestSent: true,
    });
    this.props.onFinish();
  };

  getForm() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        {this.state.isLoading ? <Spinner size={50} /> : null}

        <MessageBox message={this.props.message} />
        <ErrorBox error={this.state.requestError} />
        <SimpleDialogContent>
          <div className={classes.topInputs}>
            <PrimaryTextField
              error={!!this.state.companyNameError}
              onChange={(event) => this.setState({ companyName: event.target.value })}
              value={this.state.companyName}
              id="company-name-input"
              margin="dense"
              InputLabelProps={{ shrink: true }}
              label={<FormattedMessage id="global.companyName" />}
              helperText={<ErrorFormattedMessage id={this.state.companyNameError} />}
              fullWidth
              autoFocus
              onKeyPress={(event) => {
                onEnterPress(event, this.validate);
              }}
            />

            <PrimaryTextField
              error={!!this.state.firstNameError}
              onChange={(event) => this.setState({ firstName: event.target.value })}
              value={this.state.firstName}
              id="first-name-input"
              margin="dense"
              InputLabelProps={{ shrink: true }}
              label={<FormattedMessage id="global.firstName" />}
              helperText={<ErrorFormattedMessage id={this.state.firstNameError} />}
              fullWidth
              onKeyPress={(event) => {
                onEnterPress(event, this.validate);
              }}
            />

            <PrimaryTextField
              error={!!this.state.lastNameError}
              onChange={(event) => this.setState({ lastName: event.target.value })}
              value={this.state.lastName}
              id="last-name-input"
              margin="dense"
              InputLabelProps={{ shrink: true }}
              label={<FormattedMessage id="global.lastName" />}
              helperText={<ErrorFormattedMessage id={this.state.lastNameError} />}
              fullWidth
              onKeyPress={(event) => {
                onEnterPress(event, this.validate);
              }}
            />
          </div>

          <div className={classes.bottomInputs}>
            <PrimaryTextField
              error={!!this.state.emailError}
              onChange={(event) => this.setState({ email: event.target.value })}
              value={this.state.email}
              id="email-input"
              margin="dense"
              InputLabelProps={{ shrink: true }}
              label={<FormattedMessage id="global.email" />}
              helperText={<ErrorFormattedMessage id={this.state.emailError} />}
              fullWidth
              onKeyPress={(event) => {
                onEnterPress(event, this.validate);
              }}
            />

            <PrimaryTextField
              error={!!this.state.passwordError}
              onChange={(event) => this.setState({ password: event.target.value })}
              value={this.state.password}
              id="password-input"
              type="password"
              margin="dense"
              InputLabelProps={{ shrink: true }}
              label={<FormattedMessage id="global.password" />}
              helperText={<ErrorFormattedMessage id={this.state.passwordError} />}
              fullWidth
              onKeyPress={(event) => {
                onEnterPress(event, this.validate);
              }}
            />
          </div>

          {/*<Typography align={'center'}
                      className={classNames(classes.termsOfServiceAndPrivacyPolicy, classes.mt40)}
                      variant={"body1"}>
            <FormattedMessage id="reg.agreement"/>
          </Typography>
          <Typography align={'center'}
                      className={classes.termsOfServiceAndPrivacyPolicy}
                      variant={"body1"}>
            <a className={classes.termsOfServiceAndPrivacyPolicyLink}
               target={"_blank"}
               href="/terms/terms-of-service">
              <FormattedMessage id="global.termsOfService"/>
            </a>&nbsp;
            {this.props.intl.formatMessage({"id" : "global.and"}).toLowerCase()}&nbsp;
            <a className={classes.termsOfServiceAndPrivacyPolicyLink}
               target={"_blank"}
               href="/terms/privacy-policy">
              <FormattedMessage id="global.privacyPolicy"/>
            </a>
          </Typography>*/}

          <div className={classes.controls}>
            <PrimaryButton className={classes.button} onClick={this.validate}>
              <FormattedMessage id="reg.createAccount" />
            </PrimaryButton>
          </div>
        </SimpleDialogContent>
      </React.Fragment>
    );
  }

  getFinalMessage() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <SimpleDialogContent className={classes.finalMessageContainer}>
          <Icon icon={DoneIcon} classes={{ root: classes.envelope }} />
          <p>
            <FormattedMessage id="reg.final.message" />
          </p>

          <div className={classes.controls}>
            <PrimaryButton className={classes.button} onClick={this.props.onDone}>
              <FormattedMessage id="global.ok" />
            </PrimaryButton>
          </div>
        </SimpleDialogContent>
      </React.Fragment>
    );
  }

  render() {
    return this.state.requestSent ? this.getFinalMessage() : this.getForm();
  }
}

RegisterStage.propTypes = {
  message: PropTypes.string,
  onDone: PropTypes.func.isRequired,
  isFinal: PropTypes.bool,
  onFinish: PropTypes.func,
};

export default withStyles(styles)(injectIntl(RegisterStage));
