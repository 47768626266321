import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../../../hocs';
import MenuItem from '@material-ui/core/MenuItem';
import { PrimarySelect } from '../../../../components';
import {
  PAYMENT_TYPES,
  isPaymentMethodVerified,
  paymentMethodBrandToAbbr,
  FINANCIAL_STATUSES,
} from '../../../../helpers';

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  paymentMethod: {
    width: '80%',
    marginBottom: 5,
  },
};

const propTypes = {
  wrapperClass: PropTypes.string,
  order: PropTypes.object.isRequired,
  customer: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

class PaymentMethods extends PureComponent {
  getSelectedPaymentMethod = () => {
    const { order } = this.props;
    let method = -1;
    if (order.payment && order.payment.method && order.payment.method.id) {
      method = order.payment.method.id;
    } else if (order.payment && order.payment.method && order.payment.method.method) {
      method = order.payment.method.method;
    }

    let paymentMethods = this.getStoredPaymentMethods(true);
    paymentMethods.push(PAYMENT_TYPES.CHECK);
    paymentMethods.push(PAYMENT_TYPES.COD);
    paymentMethods.push(PAYMENT_TYPES.INVOICE);

    method = paymentMethods.indexOf(method) !== -1 ? method : -1;

    if (
      method === -1 &&
      order.payment &&
      (order.payment.type === PAYMENT_TYPES.INVOICE || order.payment.type === PAYMENT_TYPES.PREPURCHASE)
    ) {
      method = PAYMENT_TYPES.INVOICE;
    }

    return method;
  };

  getStoredPaymentMethods = (onlyIds) => {
    const { customer } = this.props;
    let methods = [];

    if (customer && customer.paymentMethods.length) {
      customer.paymentMethods.forEach((method) => {
        if (
          method.method === PAYMENT_TYPES.STRIPE_CARD ||
          (method.method === PAYMENT_TYPES.STRIPE_ACH && isPaymentMethodVerified(method))
        ) {
          if (onlyIds) {
            methods.push(method.id);
          } else {
            methods.push({
              id: method.id,
              label: paymentMethodBrandToAbbr(method) + ' **** ' + method.last4,
            });
          }
        }
      });
    }

    return methods;
  };

  onChange = (method) => {
    const { order } = this.props;
    if (method !== -1 && order.financialStatus === FINANCIAL_STATUSES.UNPAID) {
      this.props.onChange(method);
    }
  };

  render() {
    const { intl, wrapperClass, classes, order } = this.props;

    return (
      <div className={classNames(classes.wrapper, wrapperClass)}>
        <PrimarySelect
          displayEmpty
          disabled={order.financialStatus !== FINANCIAL_STATUSES.UNPAID}
          className={classes.paymentMethod}
          label={intl.formatMessage({ id: 'global.paymentMethod' })}
          value={this.getSelectedPaymentMethod()}
          onChange={(e) => this.onChange(e.target.value)}>
          <MenuItem key={-1} value={-1}>
            {intl.formatMessage({ id: 'global.selectPaymentMethod' })}
          </MenuItem>
          <MenuItem key={PAYMENT_TYPES.COD} value={PAYMENT_TYPES.COD}>
            {intl.formatMessage({ id: 'global.cod' })}
          </MenuItem>
          <MenuItem key={PAYMENT_TYPES.CHECK} value={PAYMENT_TYPES.CHECK}>
            {intl.formatMessage({ id: 'global.check' })}
          </MenuItem>
          <MenuItem key={PAYMENT_TYPES.INVOICE} value={PAYMENT_TYPES.INVOICE}>
            {intl.formatMessage({ id: 'global.invoice' })}
          </MenuItem>

          {/*<MenuItem key={PAYMENT_TYPES.CASH} value={PAYMENT_TYPES.CASH}>
              {intl.formatMessage({id: "global.cash"})}
            </MenuItem>*/}

          {this.getStoredPaymentMethods().map((method) => (
            <MenuItem key={method.id} value={method.id}>
              {method.label}
            </MenuItem>
          ))}
        </PrimarySelect>
      </div>
    );
  }
}

PaymentMethods.propTypes = propTypes;

export default withStyles(styles, { withTheme: true })(injectIntl(withRouter(PaymentMethods)));
