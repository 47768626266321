import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {withStyles} from '@material-ui/core/styles';
import FFLogo from "./assets/ff-logo.png";
import GBLogo from "./assets/gb-logo.png";
import styles from './styles';

const propTypes = {
  appName: PropTypes.string.isRequired,
  history: PropTypes.object
};

class Footer extends React.Component {
  render() {
    const {
      classes,
      history,
      appName
    } = this.props;

    return (
      <div className={classes.footer}>

        <div className={classes.footerTop}>
          <div className={classes.footerTopLeft}>
            <button className={classes.link}
               onClick={() => history.push('/')}>
              <img src={appName === 'fellow.farmer' ? FFLogo : GBLogo}
                   className={classes.footerLogo} alt={process.env.REACT_APP_TITLE}/>
            </button>
          </div>
          <div className={classes.footerTopRight}>
            <span className={classes.infoTitle}>
              <FormattedMessage id="global.info"/>
            </span>

            <button className={classes.link}
               onClick={() => history.push('/contact')}>
              <FormattedMessage id="global.contact"/>
            </button>

            {appName === 'fellow.farmer' && (
              <React.Fragment>
                <button className={classes.link}
                   onClick={() => history.push('/terms/privacy-policy')}>
                  <FormattedMessage id="global.privacyPolicy"/>
                </button>
                <button className={classes.link}
                   onClick={() => history.push('/terms/terms-of-service')}>
                  <FormattedMessage id="global.termsOfService"/>
                </button>
              </React.Fragment>
            )}


          </div>
        </div>

        <div className={classes.footerBottom}>
          <p className={classes.inc}><FormattedMessage id="footer.inc"/></p>
        </div>

      </div>
    );
  }
}

Footer.propTypes = propTypes;

export default withStyles(styles, {withTheme: true})(Footer);