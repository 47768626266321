import {
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  MAKE_INITIALIZED,
  MAKE_NON_INITIALIZED,
  TOGGLE_MENU_DRAWER,
} from '../constants';
import { HIDE_TEMPORARY_TOOLTIP, SHOW_TEMPORARY_TOOLTIP } from '../constants';
import { CHANGE_TABS_STATE } from '../constants/common';

export function showNotification(message) {
  return (dispatch) => {
    dispatch({
      type: SHOW_NOTIFICATION,
      payload: { notification: message },
    });
  };
}

export function hideNotification() {
  return (dispatch) => {
    dispatch({ type: HIDE_NOTIFICATION });
  };
}

export function makeInitialized() {
  return (dispatch) => {
    dispatch({ type: MAKE_INITIALIZED });
  };
}

export function makeNonInitialized() {
  return (dispatch) => {
    dispatch({ type: MAKE_NON_INITIALIZED });
  };
}

export function toggleDrawer() {
  return (dispatch) => {
    dispatch({ type: TOGGLE_MENU_DRAWER });
  };
}

export function showTemporaryTooltip(message, icon) {
  return (dispatch) => {
    dispatch({
      type: SHOW_TEMPORARY_TOOLTIP,
      payload: { message: message, icon },
    });
  };
}

export function hideTemporaryTooltip() {
  return (dispatch) => {
    dispatch({ type: HIDE_TEMPORARY_TOOLTIP });
  };
}

export function changeTabState(newTabState) {
  return (dispatch) => {
    dispatch({ type: CHANGE_TABS_STATE, payload: { newTabState } });
  };
}
