import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { AdminDialogContent, Icon, PrimaryCheckbox } from '../../components';
import DeleteIcon from '@material-ui/icons/Delete';
import { withRouter } from '../../hocs';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { injectIntl } from 'react-intl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Spinner } from '../../components';
import { NOTIFICATIONS_TYPES } from '../../helpers';

const propTypes = {
  selectedRow: PropTypes.object,
  notification: PropTypes.object.isRequired,
  onEditNotification: PropTypes.func,
  onDelete: PropTypes.func,
  error: PropTypes.string,
  loading: PropTypes.bool,
};

const defaultProps = {
  notification: {},
};

function TableStep(props) {
  const { classes, intl, notification, onEditNotification, onDelete, error, loading } = props;

  const makeNotificationTypes = () => {
    const emailNotifications = notification.emailNotifications || {};
    const pushNotifications = notification.pushNotifications || {};
    const smsNotifications = notification.smsNotifications || {};

    return [
      {
        title: intl.formatMessage({ id: 'screen.producerNotificationDialog.newOrder' }),
        emailNotifications: emailNotifications.newOrder || false,
        pushNotifications: pushNotifications.newOrder || false,
        smsNotifications: smsNotifications.newOrder || false,
        id: 'newOrder',
      },
      {
        title: intl.formatMessage({ id: 'screen.producerNotificationDialog.orderNotApproved' }),
        emailNotifications: emailNotifications.orderNotApproved || false,
        // pushNotifications: pushNotifications.orderNotApproved || false,
        // smsNotifications: smsNotifications.orderNotApproved || false,
        id: 'orderNotApproved',
      },
      {
        title: intl.formatMessage({ id: 'screen.producerNotificationDialog.orderPaymentDeclined' }),
        emailNotifications: emailNotifications.orderPaymentDeclined || false,
        // pushNotifications: pushNotifications.orderPaymentDeclined || false,
        // smsNotifications: smsNotifications.orderPaymentDeclined || false,
        id: 'orderPaymentDeclined',
      },
      {
        title: intl.formatMessage({ id: 'screen.producerNotificationDialog.packedOrdersReport' }),
        emailNotifications: emailNotifications.packedOrdersReport || false,
        // pushNotifications: pushNotifications.packedOrdersReport || false,
        // smsNotifications: smsNotifications.packedOrdersReport || false,
        id: 'packedOrdersReport',
      },
      {
        title: intl.formatMessage({ id: 'screen.producerNotificationDialog.paymentReport' }),
        emailNotifications: emailNotifications.paymentReport || false,
        // pushNotifications: pushNotifications.paymentReport || false,
        // smsNotifications: smsNotifications.paymentReport || false,
        id: 'paymentReport',
      },
    ];
  };

  const getCheckbox = (fieldName, isChecked, type) => {
    return (
      <FormControlLabel
        control={
          <PrimaryCheckbox
            checked={isChecked}
            onChange={(event) => {
              onChangeEmailNotification(fieldName, event.target.checked, type);
            }}
            value={`${type}_${fieldName}`}
          />
        }
        label={''}
      />
    );
  };

  const onChangeEmailNotification = (fieldName, status, type) => {
    const data = { [fieldName]: status };
    onEditNotification(data, type);
  };

  return (
    <AdminDialogContent className={classes.wrapper}>
      {!error && (
        <>
          {loading && <Spinner size={50} />}

          <div className={classes.addButtonWrapper}>
            <IconButton onClick={onDelete} aria-label="Delete">
              <Icon icon={DeleteIcon} />
            </IconButton>
          </div>

          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.headerCell}>
                  {intl.formatMessage({ id: 'screen.producerNotificationDialog.table.type' })}
                </TableCell>
                <TableCell className={classes.headerCell}>
                  {intl.formatMessage({ id: 'screen.producerNotificationDialog.table.email' })}
                </TableCell>
                <TableCell className={classes.headerCell}>
                  {intl.formatMessage({ id: 'screen.producerNotificationDialog.table.sms' })}
                </TableCell>
                <TableCell className={classes.headerCell}>
                  {intl.formatMessage({ id: 'screen.producerNotificationDialog.table.pushNotification' })}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {makeNotificationTypes().map((notification) => {
                return (
                  <TableRow className={classes.row} key={notification.id}>
                    <TableCell className={classes.cell}>{notification.title}</TableCell>
                    <TableCell className={classes.cell}>
                      {getCheckbox(notification.id, notification.emailNotifications, NOTIFICATIONS_TYPES.EMAIL)}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {notification.smsNotifications !== undefined
                        ? getCheckbox(notification.id, notification.smsNotifications, NOTIFICATIONS_TYPES.SMS)
                        : intl.formatMessage({ id: 'global.nan' })}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {notification.pushNotifications !== undefined
                        ? getCheckbox(notification.id, notification.pushNotifications, NOTIFICATIONS_TYPES.PUSH)
                        : intl.formatMessage({ id: 'global.nan' })}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </>
      )}
    </AdminDialogContent>
  );
}

TableStep.propTypes = propTypes;
TableStep.defaultProps = defaultProps;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(TableStep)));
