import {
  PAYMENT_METHODS,
  PAYMENT_METHOD_VERIFIED,
} from '../helpers';

export const paymentMethodBrandToAbbr = (paymentMethod, empty) => {
  empty = paymentMethod.bankName || empty || "";

  if (paymentMethod.brand === 'MasterCard') {
    return "MC";
  }

  if (paymentMethod.brand === 'Visa') {
    return "VISA";
  }

  if (paymentMethod.brand === 'American Express') {
    return "AMEX";
  }

  return empty;
};

export const isPaymentMethodVerified = (paymentMethod) => {
  if (paymentMethod && paymentMethod.method === PAYMENT_METHODS.STRIPE_BANK_ACCOUNT) {
    return paymentMethod.status === PAYMENT_METHOD_VERIFIED
  }
  return true;
};