import { getObjectFromStorage } from './storage';

export const genHttpBasicToken = (email, password) => {
  return window.btoa(unescape(encodeURIComponent(email + ':' + password)));
};

/**
 * Checks if token is expired
 * @param {string} jwt - jwt token
 * @returns {boolean}
 */
export function isJWTExpired(jwt) {
  const decoded = jwtDecode(jwt);
  const date = new Date();
  return decoded.exp < date.getTime() / 1000;
}

/**
 * Decodes token
 * @param {string} jwt
 * @returns {object} - decoded jwt
 */
export function jwtDecode(jwt) {
  try {
    const base64Url = jwt.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(atob(base64));
  } catch (e) {
    return 0;
  }
}

/**
 * Set pickups user token
 * @param token - user token
 */
export function setPickupsUserToken(token) {
  return localStorage.setItem('pickups.token', token);
}

/**
 * get pickup token
 */
export function getPickupsUserToken() {
  return localStorage.getItem('pickups.token');
}

/** Removes pickup token */
export function removePickupsUserToken() {
  return localStorage.removeItem('pickups.token');
}

/** Get pickup object */
export function getPickupsUserObject() {
  try {
    return JSON.parse(localStorage.getItem('pickups.user'));
  } catch (e) {
    return null;
  }
}
/**
 * Set pickup object
 * @param pickupsUser - user token
 */
export function setPickupsUserObject(pickupsUser) {
  try {
    return localStorage.setItem('pickups.user', JSON.stringify(pickupsUser));
  } catch (e) {
    return null;
  }
}
/** Removes pickup object */
export function removePickupsUserObject() {
  return localStorage.removeItem('pickups.user');
}

/**
 * Set pickups user parent object
 * @param pickupsParentUser - user token
 */
export function setPickupsUserParentObject(pickupsParentUser) {
  try {
    return localStorage.setItem('pickups.parent.object', JSON.stringify(pickupsParentUser));
  } catch (e) {
    return null;
  }
}
/** Get pickup object */
export function getPickupsUserParentObject() {
  try {
    return JSON.parse(localStorage.getItem('pickups.parent.object'));
  } catch (e) {
    return null;
  }
}

/**
 * Get acl object
 * @return {object}
 */
export function getAcl() {
  return getObjectFromStorage('pickups.acl');
}
