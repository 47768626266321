import {
  CUSTOMER_ALLOW_CREDIT_FILTER
} from '../FilterTypes'

export default class DXCSCustomerAllowCreditFilter {

  static getConfig(intl, values, readOnly) {
    return {
      name: CUSTOMER_ALLOW_CREDIT_FILTER,
      title: intl.formatMessage({id: 'customer.filter.allowCredit'}),
      property: 'allowCredit',
      type: CUSTOMER_ALLOW_CREDIT_FILTER,
      selectedValues: values,
      clientSide: true,
      readOnly: readOnly ? readOnly : false
    }
  }

  constructor(values, propertyName) {
    this.values = values;
    this.property = propertyName;
  }

  isSatisfy(row) {
    let values = [];

    this.values.forEach(value => {
      if (value.toLowerCase() === 'yes') {
        values.push(true);
      }
      if (value.toLowerCase() === 'no') {
        values.push(false);
      }
    });

    return (
      values.length > 0 &&
      values.indexOf(row[this.property]) !== -1
    );
  }

  getLabel(intl) {
    let values = [];
    this.values.forEach(value => {
      switch (value) {
        case 'yes' : {
          values.push(intl.formatMessage({id: 'global.yes'}));
          break;
        }
        case 'no' : {
          values.push(intl.formatMessage({id: 'global.no'}));
          break
        }
        default: {}
      }
    });
    return values.join(', ');
  }

}