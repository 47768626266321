import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../hocs';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import {
  AdminDialog,
  AdminDialogTitle,
  AdminDialogContent,
  AdminDialogControls,
  PrimaryButton,
  PrimaryTextField,
  Spinner,
  ErrorBox,
} from '../../components';
import {
  getLineItemSystemQuantity,
  validateField,
  parseFloatFromString,
  toCoins,
  toPaper,
  weightToUser,
  COLORS,
} from '../../helpers';

import validationRules from './validation';

const styles = {
  wrapper: {},
  name: {
    fontSize: 18,
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.text,
    marginBottom: 40,
  },
  variants: {
    marginBottom: 50,
  },
  input: {
    marginBottom: 25,
  },
  inputText: {
    textAlign: 'right',
  },
  button: {
    minWidth: 330,
    alignSelf: 'center',
    textTransform: 'uppercase',
  },
  controls: {
    margin: '27px 0 0 0',
    textAlign: 'center',
  },
  control: {
    padding: '0 105px',
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
};

const propTypes = {
  customerType: PropTypes.string.isRequired,
  lineItem: PropTypes.object.isRequired,
  onDone: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

const defaultProps = {
  loading: false,
};

class PatchItemDialog extends React.Component {
  constructor(props) {
    super(props);

    const { lineItem } = this.props;

    this.state = {
      quantity: weightToUser(lineItem.orderedQuantity),
      unitPrice: toPaper(lineItem.unitPrice),
      note: lineItem.note || '',

      quantityError: '',
      unitPriceError: '',
      isLoading: false,
      requestError: '',
    };
  }

  validate = () => {
    const unitPrice = toCoins(parseFloatFromString(this.state.unitPrice));
    const quantityError = validateField(validationRules, 'quantity', this.state.quantity);
    const unitPriceError = validateField(validationRules, 'unitPrice', unitPrice);

    if (!quantityError && !unitPriceError) {
      this.setState({
        quantityError: '',
        unitPriceError: '',
        requestError: '',
        isLoading: true,
      });

      this.props.onDone(
        this.props.lineItem,
        {
          note: this.state.note,
          quantity: getLineItemSystemQuantity(this.props.lineItem, this.state.quantity),
          unitPrice: unitPrice,
        },
        this.handleResponseErrors
      );
    } else {
      this.setState({
        quantityError: quantityError,
        unitPriceError: unitPriceError,
        isLoading: false,
      });
    }
  };

  handleResponseErrors = (error) => {
    const { intl } = this.props;
    const errorData = error?.response?.data;
    let errorMessage = errorData?.message || intl.formatMessage({ id: 'messages.somethingWentWrong' });

    if (errorData?.code === 3007) {
      errorMessage = intl.formatMessage({ id: 'messages.addOrderedQuantityMoreThanAvailable' });
    }

    this.setState({
      isLoading: false,
      requestError: errorMessage,
    });
  };

  render() {
    const { onClose, intl, classes, loading, lineItem } = this.props;
    const { isLoading } = this.state;

    return (
      <AdminDialog open closeButton onClose={onClose}>
        <AdminDialogTitle title={intl.formatMessage({ id: 'global.editCartItem' })} />
        <AdminDialogContent className={classes.wrapper}>
          {loading && <Spinner size={50} />}

          <Typography className={classes.name}>
            {typeof lineItem.name === 'object' &&
              `${lineItem.name.product}${!!lineItem?.name?.variant ? ', ' + lineItem.name.variant : ''}`}
            {typeof lineItem.name !== 'object' && `${lineItem.name}`}
          </Typography>

          <PrimaryTextField
            className={classes.input}
            error={!!this.state.quantityError}
            onChange={(event) => this.setState({ quantity: event.target.value.trim() })}
            value={this.state.quantity}
            id="quantity-input"
            label={intl.formatMessage({ id: 'global.quantity' })}
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            InputProps={{
              classes: {
                input: classes.inputText,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <Typography classes={{ root: classes.nowrap }} color="textSecondary">
                    {lineItem.unit}
                  </Typography>
                </InputAdornment>
              ),
            }}
            helperText={this.state.quantityError && intl.formatMessage({ id: this.state.quantityError })}
            fullWidth
          />

          <PrimaryTextField
            className={classes.input}
            error={!!this.state.unitPriceError}
            onChange={(event) => this.setState({ unitPrice: event.target.value.trim() })}
            value={this.state.unitPrice}
            id="unit-price-input"
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            label={intl.formatMessage({ id: 'global.unitPrice' })}
            type="text"
            InputProps={{
              classes: {
                input: classes.inputText,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <Typography classes={{ root: classes.nowrap }} color="textSecondary">
                    {lineItem.unit}
                  </Typography>
                </InputAdornment>
              ),
            }}
            helperText={this.state.unitPriceError && intl.formatMessage({ id: this.state.unitPriceError })}
            fullWidth
          />

          <PrimaryTextField
            className={classes.input}
            onChange={(event) => this.setState({ note: event.target.value })}
            value={this.state.note}
            id="note"
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            label={intl.formatMessage({ id: 'global.note' })}
            type="text"
            fullWidth
          />

          <AdminDialogControls>
            <ErrorBox error={this.state.requestError} />

            <PrimaryButton className={classes.button} onClick={this.validate} isLoading={isLoading}>
              {intl.formatMessage({ id: 'global.addToCart' })}
            </PrimaryButton>
          </AdminDialogControls>
        </AdminDialogContent>
      </AdminDialog>
    );
  }
}

PatchItemDialog.propTypes = propTypes;
PatchItemDialog.defaultProps = defaultProps;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(PatchItemDialog)));
