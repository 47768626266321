import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {COLORS} from "../../helpers";

const styles = {
  title: {
    fontSize: 20,
    fontWeight: 400,
    color: COLORS.headerText,
    fontFamily: 'Roboto',
    margin: 0,
  }
};

const AdminTabContentTitle = (props) => {
  const {classes} = props;
  return (
    <h1 className={classes.title}>
      {props.children}
    </h1>
  );
};

export default withStyles(styles)(
  AdminTabContentTitle
);