import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormHelperText from '@material-ui/core/FormHelperText';

PrimarySearchableSelect.propTypes = {
  helperText: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  containerWrapperClass: PropTypes.string
};

const defaultProps = {
  error: false,
  disabled: false
};

const useStyles = makeStyles((theme) => ({
  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popperDisablePortal: {
    position: 'relative',
  },
}));

function PrimarySearchableSelect(props) {
  const classes = useStyles();
  const {
    options,
    labelExtractor = (option) => option.name,
    label,
    containerWrapperClass, children, helperText, error, disabled, ...other
  } = props;

  return (
    <div className={containerWrapperClass}>
      <Autocomplete
        classes={{
          option: classes.option,
          popperDisablePortal: classes.popperDisablePortal
        }}
        error={error}
        options={options}
        disabled={disabled}
        popupIcon={<ExpandMoreIcon />}
        getOptionLabel={labelExtractor}
        renderInput={(params) => <TextField {...params} label={label || ""} />}
        {...other}
      />
      {helperText && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </div>
  );
}

PrimarySearchableSelect.defaultProps = defaultProps;

export default PrimarySearchableSelect;