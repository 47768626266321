import { parseGooglePlace } from "../../helpers";

/**
 * Search companies via google api
 * @param {string} query
 * @param {function} onSuccess
 * @param {function} onError
 */
export const googleApiSearchCompanies = function (query, onSuccess, onError) {
  let request = {
    type: 'establishment',
    query: `${query}+New+York`
  };

  let map = new window.google.maps.Map(document.createElement('div'));
  let service = new window.google.maps.places.PlacesService(map);
  service.textSearch(request, (results, status) => {
    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
      onSuccess(results)
    } else {
      onError && onError(status);
    }
  });
};

/**
 * Search companies via google api
 * @param {string} id - company id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const googleApiGetCompany = function (id, onSuccess, onError) {
  let request = { placeId: id };

  let map = new window.google.maps.Map(document.createElement('div'));
  let service = new window.google.maps.places.PlacesService(map);
  service.getDetails(request, (place, status) => {
    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
      onSuccess(parseGooglePlace(place))
    } else {
      onError && onError(status);
    }
  });
};