import {
  apiRetrieveDraft,
  apiAddCustomerOnDraftOrder,
  apiRemoveCustomerFromDraftOrder,
  apiAddVariantToDraft,
  apiAddCustomLineItemToDraft,
  apiDraftAddNote,
  apiRemoveLineItemFromDraft,
  apiUpdateDraftLineItem,
  apiDraftLineItemSetQuantity,
  apiDraftLineItemSetNote,
  apiCompleteDraftOrder,
  apiSetDraftShipping,
  apiAddInvoicePaymentMethodOnDraftOrder,
  apiAddPrepurchasePaymentMethodOnDraftOrder,
  apiCustomerRetrieve,
  apiDraftDeleteDiscount,
  apiDraftDeleteDeliveryCompany,
} from '../../api';
import { DRAFT_SET, DRAFT_UNSET, DRAFT_LOADING, DRAFT_LOADED } from '../constants/draft';
import { PAYMENT_TYPES } from '../../helpers';

export function setDraft(id, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });
    apiRetrieveDraft(
      id,
      (draft) => dispatch(refreshDraft(draft, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}

export function refreshDraft(draft, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });
    if (draft.customer) {
      apiCustomerRetrieve(
        draft.customer,
        (customer) => {
          dispatch({
            type: DRAFT_SET,
            payload: {
              draft: draft,
              customer: customer,
            },
          });
          onSuccess && onSuccess(draft);
          dispatch({ type: DRAFT_LOADED });
        },
        (error) => onError && onError(error)
      );
    } else {
      dispatch({
        type: DRAFT_SET,
        payload: {
          draft: draft,
          customer: null,
        },
      });
      onSuccess && onSuccess(draft);
      dispatch({ type: DRAFT_LOADED });
    }
  };
}

export function unsetDraft() {
  return (dispatch) => {
    dispatch({ type: DRAFT_UNSET });
  };
}

export function draftAttachVariant(draft, product, variant, data, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });
    apiAddVariantToDraft(
      draft.id,
      variant.id,
      data,
      (draft) => dispatch(refreshDraft(draft, onSuccess, onError)),
      (error) => {
        dispatch({ type: DRAFT_LOADED });

        onError && onError(error);
      }
    );
  };
}

export function draftAttachCustomItem(draft, data, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });
    apiAddCustomLineItemToDraft(
      draft.id,
      data,
      (draft) => dispatch(refreshDraft(draft, onSuccess, onError)),
      (error) => {
        dispatch({ type: DRAFT_LOADED });

        onError && onError(error);
      }
    );
  };
}

export function draftChangeLineItemNote(draft, lineItem, note, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });
    apiDraftLineItemSetNote(
      draft.id,
      lineItem.id,
      note,
      (draft) => dispatch(refreshDraft(draft, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}

export function draftChangeLineItemQuantity(draft, lineItem, quantity, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });
    apiDraftLineItemSetQuantity(
      draft.id,
      lineItem.id,
      quantity,
      (draft) => dispatch(refreshDraft(draft, onSuccess, onError)),
      (error) => {
        dispatch({ type: DRAFT_LOADED });
        onError && onError(error);
      }
    );
  };
}

export function draftAddNote(draft, note, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });
    apiDraftAddNote(
      draft.id,
      note,
      (draft) => dispatch(refreshDraft(draft, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}

export function draftPatchLineItem(draft, lineItem, data, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });
    apiUpdateDraftLineItem(
      draft.id,
      lineItem.id,
      data,
      (draft) => dispatch(refreshDraft(draft, onSuccess, onError)),
      (error) => {
        dispatch({ type: DRAFT_LOADED });

        onError && onError(error);
      }
    );
  };
}

export function draftDetachLineItem(draft, lineItem, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });
    apiRemoveLineItemFromDraft(
      draft.id,
      lineItem.id,
      (draft) => dispatch(refreshDraft(draft, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}

export function draftAttachCustomer(draft, customer, addresses, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });

    apiAddCustomerOnDraftOrder(
      draft.id,
      { id: customer.id },
      (draft) => dispatch(refreshDraft(draft, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}

export function draftChangeCustomerAddress(draft, customer, addressId, addressType, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });

    const data = {
      id: customer.id,
      [`${addressType}Address`]: { id: addressId },
    };

    apiAddCustomerOnDraftOrder(
      draft.id,
      data,
      (draft) => dispatch(refreshDraft(draft, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}

export function draftDetachCustomer(draft, customer, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });
    apiRemoveCustomerFromDraftOrder(
      draft.id,
      (draft) => dispatch(refreshDraft(draft, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}

export function draftAttachShipping(draft, method, data, deliveryCompany, location, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });

    if (location) {
      data['locationId'] = location;
    }

    apiSetDraftShipping(
      draft.id,
      method,
      data,
      deliveryCompany,
      (draft) => dispatch(refreshDraft(draft, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}

export function draftRemoveShippingDeliveryCompany(draft, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });

    apiDraftDeleteDeliveryCompany(draft.id, (draft) => dispatch(refreshDraft(draft, onSuccess, onError)), onError);
  };
}

export function draftDeleteDiscount(draft, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });
    apiDraftDeleteDiscount(
      draft.id,
      (draft) => dispatch(refreshDraft(draft, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}

export function draftAttachPaymentMethod(draft, method, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });

    // Prepurchase order should use another method for adding payment method
    if (draft.prepurchaseOrder) {
      if ([PAYMENT_TYPES.INVOICE].indexOf(method) !== -1) {
        apiAddPrepurchasePaymentMethodOnDraftOrder(
          draft.id,
          { method: null },
          (draft) => dispatch(refreshDraft(draft, onSuccess, onError)),
          (error) => onError && onError(error)
        );
      }

      if ([PAYMENT_TYPES.CASH, PAYMENT_TYPES.CHECK, PAYMENT_TYPES.COD].indexOf(method) !== -1) {
        apiAddPrepurchasePaymentMethodOnDraftOrder(
          draft.id,
          { method: method },
          (draft) => dispatch(refreshDraft(draft, onSuccess, onError)),
          (error) => onError && onError(error)
        );
      }

      if ([PAYMENT_TYPES.CASH, PAYMENT_TYPES.CHECK, PAYMENT_TYPES.COD, PAYMENT_TYPES.INVOICE].indexOf(method) === -1) {
        apiAddPrepurchasePaymentMethodOnDraftOrder(
          draft.id,
          { paymentMethodId: method },
          (draft) => dispatch(refreshDraft(draft, onSuccess, onError)),
          (error) => onError && onError(error)
        );
      }
    } else {
      if ([PAYMENT_TYPES.INVOICE].indexOf(method) !== -1) {
        apiAddInvoicePaymentMethodOnDraftOrder(
          draft.id,
          '',
          {},
          (draft) => dispatch(refreshDraft(draft, onSuccess, onError)),
          (error) => onError && onError(error)
        );
      }

      if ([PAYMENT_TYPES.CASH, PAYMENT_TYPES.CHECK, PAYMENT_TYPES.COD].indexOf(method) !== -1) {
        apiAddInvoicePaymentMethodOnDraftOrder(
          draft.id,
          method,
          {},
          (draft) => dispatch(refreshDraft(draft, onSuccess, onError)),
          (error) => onError && onError(error)
        );
      }

      if ([PAYMENT_TYPES.CASH, PAYMENT_TYPES.CHECK, PAYMENT_TYPES.COD, PAYMENT_TYPES.INVOICE].indexOf(method) === -1) {
        apiAddInvoicePaymentMethodOnDraftOrder(
          draft.id,
          '',
          { paymentMethodId: method },
          (draft) => dispatch(refreshDraft(draft, onSuccess, onError)),
          (error) => onError && onError(error)
        );
      }
    }
  };
}

export function draftComplete(draft, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });
    apiCompleteDraftOrder(
      draft.id,
      (order) => {
        onSuccess && onSuccess(order);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
}
