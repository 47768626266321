import React from 'react';
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import DXSelectFilter from '../DXSelectFilter/DXSelectFilter'
import DXFilterConfig from '../DXFilterConfig'
import constants from '../../constants'

const propTypes = {
  equality: PropTypes.string,
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  selected: [],
};

class DXOrderSourceFilter extends React.Component {

  render() {
    const { selected, onChange, intl, equality} = this.props;
    const config = DXOrderSourceFilter.getConfig(intl, equality);
    return (
      <DXSelectFilter selected={selected}
                      title={config.title}
                      values={config.values}
                      onChange={onChange}
                      multi={config.multi}
      />
    )
  }
}

DXOrderSourceFilter.propTypes = propTypes;
DXOrderSourceFilter.defaultProps = defaultProps;
DXOrderSourceFilter.getLabel = (intl, selectedValues) => {
  return DXSelectFilter.getLabel(intl, 'filter.source.', selectedValues);
};
DXOrderSourceFilter.applyToQueryParams = (queryParams, selectedValues) => {
  queryParams['source'] = selectedValues;
  return queryParams;
};
DXOrderSourceFilter.getConfig = (intl, equality) => {
  let config = new DXFilterConfig({
    name: 'source',
    selected: [],
    property: 'source',
    title: intl.formatMessage({id: 'filter.source'}),
    disabled: false,
    equalityStatus: false,
    multi: true
  });

  if (equality === constants.FILTER_EQUALITY_EQUALS) {
    config.addFilterValue('storefront', intl.formatMessage({id: 'filter.source.storefront'}));
    config.addFilterValue('admin', intl.formatMessage({id: 'filter.source.admin'}));
    config.addFilterValue('pos', intl.formatMessage({id: 'filter.source.pos'}));
  }

  return config;
};

export default injectIntl(
  DXOrderSourceFilter
);
