class MinimalVariant {
  constructor(props) {
    this.id = props.id || (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase();
    this.type = props.type || 'regular';
    this.name = props.name || '';
    this.unit = props.unit || 'ea';

    this.unitQuantity = props.unitQuantity || 100;

    this.description = props.description || '';
    this.wholesaleDescription = props.wholesaleDescription || '';
    this.active = props.active || true;

    this.offered = props.offered || { wholesale: true, retail: true };
    this.price = props.price || 0;
    this.inventory = props.inventory || {};
    this.inventoryQuantity = props?.inventory?.quantity || 0;

    this.prices = props.prices || [
      {
        level: 'wholesale_market',
        price: 0,
      },
      {
        level: 'wholesale_preorder',
        price: 0,
      },
      {
        level: 'retail_market',
        price: 0,
      },
      {
        level: 'retail_preorder',
        price: 0,
      },
    ];
  }

  getVariant() {
    return this;
  }

  getVariantData(removeArray = []) {
    let variantObj = this;
    delete variantObj.price;

    if (Array.isArray(removeArray)) {
      removeArray.forEach((toRemove) => {
        delete variantObj[toRemove];
      });
    }

    return variantObj;
  }

  static isVariantNew(variant) {
    return !String(variant.id).match('va_');
  }
}

export default MinimalVariant;
