const validation = {
  name: {
    presence: {
      message: '^validation.variant.enterName'
    }
  },
  unit: {
    presence: {
      message: '^validation.variant.enterUnit'
    }
  },
  unitQuantity: {
    presence: {
      message: '^validation.variant.enterUnitQuantity'
    }
  },
  retailMarketPrice: {
    presence: {
      message: '^validation.variant.price'
    }
  },
  retailPreorderPrice: {
    presence: {
      message: '^validation.variant.price'
    }
  },
  wholesaleMarketPrice: {
    presence: {
      message: '^validation.variant.price'
    }
  },
  wholesalePreorderPrice: {
    presence: {
      message: '^validation.variant.price'
    }
  },
  atLeastOnePrice: {
    presence: {
      message: '^validation.needAtLeastOnePrice'
    }
  },
  subscriptionQuantity: {
    numericality: {
      onlyInteger: false,
      message: '^validation.required'
    },
    presence: {
      message: '^validation.required'
    }
  }
};

export default validation;
