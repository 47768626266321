import React from 'react';
import { COLORS } from '../constants/colors';

const CheckIcon = (props) => {
  const { color = COLORS.primary, ...other } = props;
  return (
    <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <path
        d="M6.00001 10.1699L2.53001 6.69995C2.14002 6.30995 1.51001 6.30995 1.12001 6.69995C0.730015 7.08995 0.730015 7.71995 1.12001 8.10995L5.30001 12.2899C5.69001 12.6799 6.32001 12.6799 6.71001 12.2899L17.29 1.70995C17.68 1.31995 17.68 0.689946 17.29 0.299946C16.9 -0.0900537 16.27 -0.0900537 15.88 0.299946L6.00001 10.1699Z"
        fill={color}
      />
    </svg>
  );
};

export default CheckIcon;
