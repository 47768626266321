import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { toCurrency, toPaper } from '../../../../helpers';

export const cellRenderer = (intl, reload, { row, ...props }) => {
  if (row.rowType === 'productSubType') {
    if (props.column.name === 'productSubType') {
      return <Table.Cell {...props}>{row.productSubType}</Table.Cell>;
    }
    if (props.column.name === 'ordered')
      return (
        <Table.Cell {...props} style={{ fontWeight: 'bold' }}>
          {toPaper(row.productSubTypeTotalOrdered)}
        </Table.Cell>
      );
    if (props.column.name === 'packed')
      return (
        <Table.Cell {...props} style={{ fontWeight: 'bold' }}>
          {toPaper(row.productSubTypeTotalPacked)}
        </Table.Cell>
      );
    if (props.column.name === 'price')
      return (
        <Table.Cell {...props} style={{ fontWeight: 'bold' }}>
          {toCurrency(row.productSubTypeTotalPrice, intl)}
        </Table.Cell>
      );
    return <Table.Cell {...props}>&nbsp;</Table.Cell>;
  }

  if (row.rowType === 'lineItemNameProduct') {
    if (props.column.name === 'nameProduct') {
      return <Table.Cell {...props}>{row.nameProduct}</Table.Cell>;
    }

    if (props.column.name === 'ordered')
      return (
        <Table.Cell {...props} style={{ fontWeight: 'bold' }}>
          {toPaper(row.lineItemNameProductTotalOrdered)}
        </Table.Cell>
      );
    if (props.column.name === 'packed')
      return (
        <Table.Cell {...props} style={{ fontWeight: 'bold' }}>
          {toPaper(row.lineItemNameProductTotalPacked)}
        </Table.Cell>
      );
    if (props.column.name === 'price')
      return (
        <Table.Cell {...props} style={{ fontWeight: 'bold' }}>
          {toCurrency(row.lineItemNameProductTotalPrice, intl)}
        </Table.Cell>
      );

    return <Table.Cell {...props}>&nbsp;</Table.Cell>;
  }

  if (row.rowType === 'lineItemNameVariant') {
    if (props.column.name === 'nameVariant') {
      return <Table.Cell {...props}>{row?.nameVariant || ''}</Table.Cell>;
    }

    if (props.column.name === 'ordered')
      return (
        <Table.Cell {...props} style={{ fontWeight: 'bold' }}>
          {toPaper(row.lineItemNameVariantTotalOrdered)}
        </Table.Cell>
      );
    if (props.column.name === 'packed')
      return (
        <Table.Cell {...props} style={{ fontWeight: 'bold' }}>
          {toPaper(row.lineItemNameVariantTotalPacked)}
        </Table.Cell>
      );
    if (props.column.name === 'price')
      return (
        <Table.Cell {...props} style={{ fontWeight: 'bold' }}>
          {toCurrency(row.lineItemNameVariantTotalPrice, intl)}
        </Table.Cell>
      );

    return <Table.Cell {...props}>&nbsp;</Table.Cell>;
  }

  if (
    props.column.name === 'nameProduct' ||
    props.column.name === 'nameVariant' ||
    props.column.name === 'productSubType'
  ) {
    return <Table.Cell {...props}>&nbsp;</Table.Cell>;
  }

  return <Table.Cell {...props} />;
};
