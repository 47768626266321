import React, { useEffect } from 'react';
import SimpleToast from '../SimpleToast';
import MinimalError from '../../entities/minimalError';
import { useDispatch, useSelector } from 'react-redux';
import { reportSupplierError } from '../../../../store/actions/supplier';
const axios = require('axios');

const ErrorToast = () => {
  const dispatch = useDispatch();
  const reportError = (error) => dispatch(reportSupplierError(new MinimalError(error)));

  useEffect(() => {
    const errorsInterceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        reportError(error);
      }
    );

    // Remove interceptor on leave page
    return () => axios.interceptors.request.eject(errorsInterceptor);
  }, []);

  const { error } = useSelector((state) => ({
    error: state.supplier.error,
  }));

  return <SimpleToast message={error.getMessage()} onClose={() => reportError(new MinimalError({}))} />;
};

export default ErrorToast;
