import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import Icon from '../../../components/Icon/Icon';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { withRouter } from '../../../hocs';
import {
  Spinner,
  PrimaryButton,
  EditableNote,
  CustomersSuggestions,
  ErrorBox,
  PrimaryTextField,
  PrimarySelect,
} from '../../../components';
import { BILLING_ADDRESS_TYPE, COLORS, CUSTOMER_TYPE, DELIVERY_ADDRESS_TYPE, PAYMENT_TYPES } from '../../../helpers';
import LineItem from './LineItem/LineItem';
import Summary from './Summary/Summary';
import Shipping from './Shipping/Shipping';
import { Order, Customer } from '../../../entities';
import Prepurchase from './Prepurchase/Prepurchase';
import PaymentMethods from './PaymentMethods/PaymentMethods';
import BaseDialog from '../../../dialogs/BaseDialog';
import { DirectDiscountForm } from '../../../forms';
import EditIcon from '@material-ui/icons/Edit';
import Address from './Address';

const styles = {
  wrapper: {
    position: 'relative',
    marginTop: 5,
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0 20px',
  },
  items: {
    borderBottom: '1px solid ' + COLORS.border,
  },
  footer: {
    padding: 20,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  shippingAndPaymentMethods: {
    width: '50%',
  },
  summaryWrapper: {
    width: '50%',
  },
  orderNoteWrapper: {
    //width: '100%'
  },
  addDiscountIcon: {
    fontSize: 16,
    marginRight: 5,
  },
  addDiscount: {
    fontSize: 16,
    color: COLORS.cartSecondary,
  },
  iconButton: {
    padding: 0,
    borderRadius: 0,
    '&:hover': {
      background: 'none',
      backgroundColor: 'none',
    },
  },
  csa: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: '22px 20px 5px',
    borderTop: '1px solid ' + COLORS.border,
    borderBottom: '1px solid ' + COLORS.border,
  },
  customerType: {
    width: 220,
  },
  linkNewTab: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: COLORS.gray,
    textDecoration: 'none',
    paddingTop: 15,
    paddingBottom: 5,
    marginTop: 15,
    marginLeft: 5,
    paddingLeft: 10,
    paddingRight: 10,
  },
  editIcon: {
    marginRight: 5,
    fontSize: 18,
  },
};

const propTypes = {
  loading: PropTypes.bool,

  // Discount
  onCreateDiscount: PropTypes.func.isRequired,
  onUpdateDiscount: PropTypes.func.isRequired,
  onDeleteDiscount: PropTypes.func.isRequired,

  // Customer
  onChangeCustomerType: PropTypes.func.isRequired,
  onAttachCustomer: PropTypes.func.isRequired,
  onDetachCustomer: PropTypes.func.isRequired,
  customerReadOnly: PropTypes.bool,
  // Line item
  onDetachLineItem: PropTypes.func.isRequired,
  onChangeLineItemNote: PropTypes.func.isRequired,
  onChangeLineItemQuantity: PropTypes.func.isRequired,
  onPatchLineItem: PropTypes.func.isRequired,
  // Order
  onChangeNote: PropTypes.func.isRequired,
  onChangePaymentMethod: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onRemoveShippingDeliveryCompany: PropTypes.func.isRequired,
  // Shipping
  onChangeShipping: PropTypes.func.isRequired,
  onChangeCustomerShippingAddress: PropTypes.func.isRequired,
  onChangeCustomerBillingAddress: PropTypes.func.isRequired,

  order: PropTypes.object.isRequired,
  customer: PropTypes.object,
  customerType: PropTypes.string.isRequired,
  producer: PropTypes.object.isRequired,
  history: PropTypes.object,
  mayShowCompleteButton: PropTypes.bool,
  completeButtonTitleIntlKey: PropTypes.string,
  useSupplierSelector: PropTypes.bool,
  withoutDiscount: PropTypes.bool,
};

const defaultProps = {
  customerReadOnly: false,
  mayShowCompleteButton: false,
  completeButtonTitleIntlKey: 'global.completeOrder',
  useSupplierSelector: false,
};

class OrderCart extends React.Component {
  state = {
    error: '',
  };

  validate = () => {
    const { onComplete, order, intl } = this.props;
    let validated = true;

    this.setState({ error: '' });
    if (
      !order.payment ||
      (order.payment &&
        !order.payment.method &&
        order.payment.type !== PAYMENT_TYPES.INVOICE &&
        order.payment.type !== PAYMENT_TYPES.PREPURCHASE)
    ) {
      validated = false;
      this.setState({ error: intl.formatMessage({ id: 'validation.pleaseSpecifyPaymentMethod' }) });
    }

    validated && onComplete();
  };

  render() {
    const {
      loading,
      order,
      customer,
      producer,
      history,

      // Discount
      onCreateDiscount,
      //onUpdateDiscount,
      onDeleteDiscount,

      // Customer
      onAttachCustomer,
      onDetachCustomer,
      customerReadOnly,
      customerType,
      onChangeCustomerShippingAddress,
      onChangeCustomerBillingAddress,
      onChangeCustomerType,
      // Line item
      onDetachLineItem,
      onChangeLineItemNote,
      onChangeLineItemQuantity,
      onPatchLineItem,
      // Order
      onChangeNote,
      onChangePaymentMethod,
      mayShowCompleteButton,
      completeButtonTitleIntlKey,
      onRemoveShippingDeliveryCompany,
      // Shipping
      onChangeShipping,

      classes,
      intl,
      openDiscountModal,
      useSupplierSelector,
      onChangeSupplier,
      selectedSupplier,
      withoutDiscount,
    } = this.props;

    const { error } = this.state,
      OrderEntity = new Order(order);
    const hasNonSupplierCustomer = !useSupplierSelector && !!order.customer;
    const hasSupplierItems = useSupplierSelector && !!order?.lineItems?.length;

    return (
      <div className={classes.wrapper}>
        <div className={classes.header}>
          {!useSupplierSelector && (
            <PrimarySelect
              formControlClassName={classes.customerType}
              label={intl.formatMessage({ id: 'global.customerType' })}
              value={customerType}
              inputProps={{ style: { width: 220 } }}
              onChange={(e) => onChangeCustomerType(e.target.value)}>
              {[CUSTOMER_TYPE.WHOLESALE, CUSTOMER_TYPE.RETAIL].map((type) => (
                <MenuItem key={type} value={type}>
                  {intl.formatMessage({ id: `global.${type}` })}
                </MenuItem>
              ))}
            </PrimarySelect>
          )}

          {!useSupplierSelector && !customerReadOnly && (
            <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start' }}>
              <CustomersSuggestions
                selected={order.customer}
                defaultCustomerType={customerType}
                inputProps={{ style: { width: 220 } }}
                labelExtractor={(item) => new Customer(item).getCustomerName()}
                inputLabel={intl.formatMessage({ id: 'global.customer' })}
                onAttach={onAttachCustomer}
                onDetach={onDetachCustomer}
              />
              {customer && (
                <a
                  target="_blank"
                  href={`/customers/${customer.id}`}
                  rel="noopener noreferrer"
                  className={classes.linkNewTab}>
                  <Icon icon={EditIcon} className={classes.editIcon} />
                  <Typography className={classes.addDiscount}>{intl.formatMessage({ id: 'global.edit' })}</Typography>
                </a>
              )}
            </div>
          )}

          {!!useSupplierSelector && (
            <PrimarySelect
              formControlClassName={classes.customerType}
              label={intl.formatMessage({ id: 'global.supplierSelect' })}
              value={selectedSupplier || (producer?.suppliers[0] && producer?.suppliers[0].id) || -1}
              inputProps={{ style: { width: 220 } }}
              onChange={(e) => onChangeSupplier(e.target.value)}>
              <MenuItem key={-1} value={-1}>
                {intl.formatMessage({ id: 'global.selectSupplier' })}
              </MenuItem>
              {!!producer?.suppliers &&
                producer?.suppliers?.map((supplier) => (
                  <MenuItem key={supplier.id} value={supplier.id}>
                    {supplier.name}
                  </MenuItem>
                ))}
            </PrimarySelect>
          )}

          {customerReadOnly && (
            <Typography style={{ margin: '10px 0', color: COLORS.text }}>{customer.companyName}</Typography>
          )}

          {OrderEntity.hasDiscount() && (
            <PrimaryTextField
              style={{ marginTop: 16, marginBottom: 8 }}
              label={intl.formatMessage({ id: 'global.discount' })}
              InputProps={{ readOnly: true }}
              value={OrderEntity.discount.getDiscountLabel(intl)}
            />
          )}
        </div>

        {!!order.lineItems.length && (
          <div className={classes.items}>
            {order.lineItems.map((item) => (
              <LineItem
                key={item.id}
                order={order}
                item={item}
                readOnly={!OrderEntity.isEditable()}
                onPatch={onPatchLineItem}
                onChangeNote={onChangeLineItemNote}
                onQuantityChange={onChangeLineItemQuantity}
                onDelete={onDetachLineItem}
              />
            ))}
          </div>
        )}

        <div className={classes.footer}>
          <div style={{ marginBottom: 20 }} className={classes.row}>
            <div className={classes.shippingAndPaymentMethods}>
              {customer && (
                <>
                  <Address
                    type={DELIVERY_ADDRESS_TYPE}
                    onChange={onChangeCustomerShippingAddress}
                    order={order}
                    customer={customer}
                  />
                  <Address
                    type={BILLING_ADDRESS_TYPE}
                    onChange={onChangeCustomerBillingAddress}
                    order={order}
                    customer={customer}
                  />
                </>
              )}

              <Shipping
                order={order}
                producer={producer}
                onChange={onChangeShipping}
                onRemoveShippingDeliveryCompany={onRemoveShippingDeliveryCompany}
              />

              {/* Payment methods might be added only  */}
              {(hasNonSupplierCustomer || hasSupplierItems) && (
                <PaymentMethods order={order} customer={customer} onChange={onChangePaymentMethod} />
              )}
            </div>
            <Summary
              order={order}
              onDeleteDiscount={onDeleteDiscount}
              onPatchShippingFee={onChangeShipping}
              wrapperClass={classes.summaryWrapper}
            />
          </div>

          <div style={{ justifyContent: 'center', marginBottom: 20, flexDirection: 'column' }} className={classes.row}>
            <EditableNote
              editable={OrderEntity.isEditable()}
              noteWrapperClass={classes.orderNoteWrapper}
              title={intl.formatMessage({ id: 'global.addOrderNote' })}
              note={order.note}
              onChange={onChangeNote}
            />

            {!OrderEntity.hasDiscount() && OrderEntity.isEditable() && !withoutDiscount && (
              <IconButton
                onClick={() => history.push(`${this.props.match.url}/discount/new`)}
                className={classes.iconButton}>
                <Icon icon={AddIcon} className={classes.addDiscountIcon} />
                <Typography className={classes.addDiscount}>
                  {intl.formatMessage({ id: 'global.addDiscount' })}
                </Typography>
              </IconButton>
            )}
          </div>

          <Prepurchase order={order} />

          {error && <ErrorBox error={error} />}

          {mayShowCompleteButton && (
            <div style={{ justifyContent: 'center' }} className={classes.row}>
              <PrimaryButton style={{ width: 420 }} onClick={this.validate}>
                {intl.formatMessage({ id: completeButtonTitleIntlKey })}
              </PrimaryButton>
            </div>
          )}
        </div>

        {loading && <Spinner size={50} />}

        {openDiscountModal && (
          <BaseDialog
            scroll={'paper'}
            maxWidth="md"
            open
            onClose={() => history.goBack()}
            title={
              this.props.match.params.did === 'new'
                ? intl.formatMessage({ id: 'discounts.adding' })
                : intl.formatMessage({ id: 'discounts.editing' })
            }>
            <DirectDiscountForm
              {...this.props}
              order={order}
              onSuccess={(order) => {
                onCreateDiscount(order);
                history.goBack();
              }}
            />
          </BaseDialog>
        )}
      </div>
    );
  }
}

OrderCart.propTypes = propTypes;
OrderCart.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(injectIntl(withRouter(OrderCart)));
