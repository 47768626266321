import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from '../../hocs';
import { AdminDXTable, Tab, TabActions } from '../../components';
import { SaveTabSettingDialog, TabTypes, ProductsFilterDialog } from '../../dialogs';
import { prepareActiveTabFilters, TableCell, TableRow, filtersProcessor } from './helpers';
import { getGridPageSize, getGridPageSizes } from '../../helpers/grid';
import BaseDialog from '../../dialogs/BaseDialog';
import UniqueProductsForm from '../../forms/UniqueProductsForm';
import { apiUniqueProductsGet } from '../../api';
import {
  countWidthOfColumns,
  DEFAULT_MAX_CELL_WIDTH,
  DEFAULT_MIN_CELL_WIDTH,
  HEADER_HEIGHT_STANDARD,
  makeColumnWidthEntityArray,
} from '../../helpers';

const propTypes = {
  tab: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  columnExtensions: PropTypes.array,
  onlyActive: PropTypes.bool,
  withFilters: PropTypes.bool,
  allowCreateTab: PropTypes.bool,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onDeleteTab: PropTypes.func,
  onCreateTab: PropTypes.func,
  onPatchTab: PropTypes.func,
  onAddingFilter: PropTypes.func,
};

const defaultProps = {
  onlyActive: false,
};

class UniqueProductsContent extends React.Component {
  constructor(props) {
    super(props);

    const { tab, intl, onlyActive } = props;

    let appliedFilter = [];

    if (onlyActive) {
      appliedFilter = prepareActiveTabFilters(intl, appliedFilter);
    }

    this.state = {
      loading: true,
      appliedFilters: [...(tab.filters || []), ...appliedFilter],
      selectedFilter: null,
      filterDialog: false,
      tabDialog: false,
      sorting: tab.sorting || [{ columnName: 'subType', direction: 'asc' }],
      hiddenColumnNames: tab.excludeColumns || [],

      columnWidths:
        makeColumnWidthEntityArray(tab.columnsWidth, props.columns) ||
        this.updateInitialColumnWidths(tab.excludeColumns),
    };
  }
  onColumnWidthsChange = (columnWidths) => {
    this.setState({ columnWidths });
  };

  updateInitialColumnWidths = (hiddenColumnNames) => {
    return countWidthOfColumns(this.props.columns, hiddenColumnNames, this.props.columnExtensions);
  };

  onUpdateTab = () => {
    const { tab, onPatchTab } = this.props;

    if (tab.id) {
      onPatchTab &&
        onPatchTab(tab, {
          filters: this.state.appliedFilters,
          excludeColumns: this.state.hiddenColumnNames,
          columnsWidth: this.state.columnWidths,
          sorting: this.state.sorting,
        });
    }
  };

  onAddFilter = () => {
    this.setState({ filterDialog: true });
  };

  onCreateTab = () => {
    this.setState({ tabDialog: true });
  };

  onChangeColumnsState = (hiddenColumnNames) => {
    this.setState({ hiddenColumnNames, columnWidths: this.updateInitialColumnWidths(hiddenColumnNames) });
  };

  getFiltersDialog = (onClose) => {
    const { appliedFilters, selectedFilter } = this.state;

    const { producer } = this.props;

    return (
      <ProductsFilterDialog
        onApplyFilter={(appliedFilters) => {
          this.setState({ appliedFilters }, () => {
            onClose && onClose();
          });
        }}
        subTypes={Object.keys(producer.subTypes)}
        types={Object.keys(producer.types)}
        onClose={() => onClose && onClose()}
        appliedFilters={JSON.parse(JSON.stringify(appliedFilters))}
        selectedFilter={selectedFilter}
      />
    );
  };

  onChangeSorting = (sorting) => {
    this.setState({ sorting });
  };

  onCloseFilterDialog = () => {
    this.setState({
      filterDialog: false,
    });
  };

  handleFilterRemove = (removeFilter) => {
    this.setState((state) => {
      const appliedFilters = state.appliedFilters;
      const filters = appliedFilters.filter((filter) => {
        return filter.name !== removeFilter.name;
      });
      return { appliedFilters: filters };
    });
  };

  onManageRelations = () => {
    const { history, match } = this.props;
    history.push(`${match.url}/new`);
  };

  render() {
    const {
      tab,
      index,
      columns,
      classes,
      allowCreateTab,
      onDeleteTab,
      onCreateTab,
      intl,
      columnExtensions,
      openAddModal,
    } = this.props;

    const { appliedFilters, tabDialog, hiddenColumnNames, sorting, filterDialog } = this.state;

    return (
      <Tab
        title={tab.name}
        key={`${index}-tab`}
        tab={tab}
        actions={
          <TabActions
            tab={tab}
            classes={classes}
            columns={columns}
            labels={{
              createEntity: intl.formatMessage({ id: 'tab.addItem' }),
            }}
            allowCreateTab={allowCreateTab}
            onCreateEntity={this.onManageRelations}
            onCreateTab={this.onCreateTab}
            onUpdateTab={this.onUpdateTab}
            allowHideActionsColumn={false}
            onAddingFilter={this.onAddFilter}
            onChangeColumnsState={this.onChangeColumnsState}
            onDeleteTab={onDeleteTab}
          />
        }>
        <AdminDXTable
          flexibleTableWidth
          onRef={(table) => (this.table = table)}
          actionsHeaderCellStyle={{ textAlign: 'center' }}
          actionsCellStyle={{ textAlign: 'center' }}
          cellRenderer={TableCell}
          rowRenderer={TableRow}
          apiRetrieve={apiUniqueProductsGet}
          pageSize={getGridPageSize()}
          pageSizes={getGridPageSizes()}
          clientSideFilters={JSON.parse(JSON.stringify(appliedFilters))}
          clientSideFiltersProcessor={filtersProcessor}
          onChangeSorting={this.onChangeSorting}
          onOpenWithFilter={(index, filter) =>
            this.setState({
              filterDialog: true,
              selectedFilter: filter,
            })
          }
          onRemoveFilter={this.handleFilterRemove}
          columns={columns}
          hiddenColumnNames={hiddenColumnNames}
          sorting={sorting}
          enableTree
          enableTreeSelection
          treeSelectionColumn={'selection'}
          columnExtensions={columnExtensions}
          getTreeChildRows={(row, rootRows) => {
            if (row) {
              return row.variants;
            }

            let customers = [];
            rootRows.forEach((row) => {
              row.variants.forEach((variant) => {
                variant.customers.forEach((customer) => {
                  let index = customers.findIndex((element) => {
                    return element.id === customer.id;
                  });

                  if (index === -1) {
                    customers.push({
                      ...customer,
                      customer: customer.name,
                      isCustomer: true,
                      variants: [
                        {
                          ...variant,
                          ...{
                            originalId: variant.id,
                            id: `${customer.id}-${variant.id}`,
                            variant: `${row.name} - ${variant.name}`,
                            subType: row.subType,
                            isCustomer: false,
                          },
                        },
                      ],
                    });
                  } else {
                    customers[index].variants.push({
                      ...variant,
                      ...{
                        originalId: variant.id,
                        id: `${customer.id}-${variant.id}`,
                        variant: `${row.name} - ${variant.name}`,
                        subType: row.subType,
                        isCustomer: false,
                      },
                    });
                  }
                });
              });
            });
            return customers;
          }}
          getExpandedRowIds={(rows) => {
            let ids = [];
            rows.forEach((row) => {
              row.variants.forEach((variant) => {
                variant.customers.forEach((customer) => {
                  if (ids.indexOf(customer.id) === -1) {
                    ids.push(customer.id);
                  }
                });
              });
            });
            return ids;
          }}
          columnWidths={this.state.columnWidths}
          onColumnWidthsChange={this.onColumnWidthsChange}
          minColumnWidth={DEFAULT_MIN_CELL_WIDTH}
          maxColumnWidth={DEFAULT_MAX_CELL_WIDTH}
          allowExpandAll={true}
          stickyHeader
          stickyHeaderOffset={HEADER_HEIGHT_STANDARD.extended}
        />

        {filterDialog && this.getFiltersDialog(this.onCloseFilterDialog)}
        {tabDialog && (
          <SaveTabSettingDialog
            onClose={() => this.setState({ tabDialog: false })}
            filters={JSON.parse(JSON.stringify(appliedFilters))}
            excludeColumns={hiddenColumnNames}
            sorting={sorting}
            columnWidths={this.state.columnWidths}
            onSave={(data) => onCreateTab(data, () => this.setState({ tabDialog: false }))}
            type={TabTypes.UNIQUE_PRODUCTS}
          />
        )}

        {openAddModal && (
          <BaseDialog
            scroll={'paper'}
            maxWidth="md"
            open
            onClose={() => this.props.history.push('/products/unique')}
            title={intl.formatMessage({ id: 'products.addUniqueItems' })}>
            <UniqueProductsForm
              {...this.props}
              producer={this.props.producer}
              onSuccess={() => {
                this.props.history.push('/products/unique');
                this.table.forceReload();
              }}
            />
          </BaseDialog>
        )}
      </Tab>
    );
  }
}

UniqueProductsContent.propTypes = propTypes;
UniqueProductsContent.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  return {
    producer: state.producer.object,
  };
};

export default injectIntl(withRouter(connect(mapStateToProps)(UniqueProductsContent)));
