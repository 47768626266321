import {
  DELIVERIES_SIGN_IN,
  DELIVERIES_SIGN_OUT,
  DELIVERIES_MAKE_INITIALIZED,
  DELIVERIES_MAKE_NON_INITIALIZED,
  DELIVERIES_CHANGE_LOADING,
} from '../constants';
import { setDeliveriesUserToken, removeDeliveriesUserToken } from '../../helpers';

export function deliveriesSignIn(data, onReady) {
  return (dispatch) => {
    const { token } = data;

    setDeliveriesUserToken(token);

    dispatch({
      type: DELIVERIES_SIGN_IN,
      payload: {
        token: token,
      },
    });

    onReady && onReady();
  };
}

export function deliveriesSignOut() {
  removeDeliveriesUserToken();

  return (dispatch) => {
    dispatch({
      type: DELIVERIES_SIGN_OUT,
    });
  };
}

export function setDeliveriesLoading(isLoading) {
  return (dispatch) => {
    dispatch({ type: DELIVERIES_CHANGE_LOADING, payload: isLoading });
  };
}

export function makeInitialized() {
  return (dispatch) => {
    dispatch({ type: DELIVERIES_MAKE_INITIALIZED });
  };
}

export function makeNonInitialized() {
  return (dispatch) => {
    dispatch({ type: DELIVERIES_MAKE_NON_INITIALIZED });
  };
}
