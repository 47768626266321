import React from "react";
import {Table} from '@devexpress/dx-react-grid-material-ui';
import ProductCell from "./Cells/ProductCell";
import CellAsPriceInput from "../../components/AdminDXTable/Editing/CellAsPriceInput";

export const TableCell = ( onFinishPriceEditing,
                           reload,
                           {row, ...props} ) => {

  if (props.column.name === 'product') {
    return <ProductCell row={row} {...props}/>
  }

  if (props.column.name === 'price') {
    return (
      <CellAsPriceInput value={row.value || '0.00'}
                        style={{margin: 0, width: 'auto'}}
                        cellProps={props}
                        onFinishCellEditing={(value) => {
                          onFinishPriceEditing(row, value)
                        }}
      />
    )
  }


  return <Table.Cell {...props} />;
};