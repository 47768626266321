const validation = {
  /*email: {
    email: {
      message: '^Please enter a valid email'
    },
    presence: {
      message: '^Please enter a email or username'
    }
  },*/
  password: {
    presence: {
      message: '^Please enter a password'
    }
  },
  passwordRepeat: {
    presence: {
      message: '^Please enter confirm password'
    },
    equality: {
      attribute: "password",
      message: "^Confirm password is not equal to password",
    }
  },
};

export default validation;
