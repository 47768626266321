import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { Customer } from '../../../entities';
import { REPLACE_CUSTOMER } from '../../../store/reducers/customer';
import { Spinner, EmailSubscriptionsTable, Tab } from '../../../components';
import styles from './styles';
import { EmailSubscriptionFormDialog } from '../../../dialogs';
import {
  apiCustomerAddEmailSubscription,
  apiCustomerDeleteEmailSubscription,
  apiCustomerPatchEmailSubscription,
} from '../../../api';
import EmailSubscriptionsActions from './EmailSubscriptionsActions';

class EmailSubscriptionsView extends Component {
  state = {
    addingEmailSubscription: false,
    addingEmailSubscriptionError: '',
    dialogLoading: false,
    loading: false,
  };

  addEmailSubscription = (data) => {
    const { customer, replaceCustomer } = this.props;

    this.setState({ dialogLoading: true });
    apiCustomerAddEmailSubscription(
      customer.id,
      data,
      (newCustomer) => {
        replaceCustomer(newCustomer);
        this.setState({
          addingEmailSubscription: false,
          dialogLoading: false,
        });
      },
      (error) => {
        this.setState({
          addingEmailSubscriptionError: error.response.data.message,
          dialogLoading: false,
        });
      }
    );
  };

  patchEmailSubscription = (id, data) => {
    const { customer, replaceCustomer } = this.props;

    this.setState({ loading: true });
    apiCustomerPatchEmailSubscription(
      customer.id,
      id,
      data,
      (newCustomer) => {
        replaceCustomer(newCustomer);
        this.setState({ loading: false });
      },
      (error) => {}
    );
  };

  deleteEmailSubscription = (id) => {
    const { customer, replaceCustomer } = this.props;

    this.setState({ loading: true });
    apiCustomerDeleteEmailSubscription(
      customer.id,
      id,
      (newCustomer) => {
        replaceCustomer(newCustomer);
        this.setState({ loading: false });
      },
      (error) => {}
    );
  };

  render() {
    const { classes, customer, intl } = this.props;

    const { addingEmailSubscription, addingEmailSubscriptionError } = this.state;

    return (
      <Tab
        key="subscriptions-tab"
        tab={{ name: intl.formatMessage({ id: 'customers.tab.emailSubscriptions' }), withBackButton: true }}
        actions={
          <EmailSubscriptionsActions onAddSubscription={() => this.setState({ addingEmailSubscription: true })} />
        }>
        {this.state.loading && <Spinner size={50} />}

        {!!customer.emailSubscriptions.length && (
          <div className={classes.mb20}>
            <EmailSubscriptionsTable
              patchEmailSubscription={this.patchEmailSubscription}
              onDelete={this.deleteEmailSubscription}
              subscriptions={customer.emailSubscriptions}
            />
          </div>
        )}

        {!!!customer.emailSubscriptions.length && (
          <Typography className={classes.empty} align={'center'} component="h3">
            Subscriptions were not added yet
          </Typography>
        )}

        {addingEmailSubscription && (
          <EmailSubscriptionFormDialog
            title={intl.formatMessage({ id: 'account.addingEmailSubscriptions' })}
            error={addingEmailSubscriptionError}
            loading={this.state.dialogLoading}
            onValidated={this.addEmailSubscription}
            onClose={() =>
              this.setState({
                addingEmailSubscription: false,
                addingEmailSubscriptionError: '',
              })
            }
          />
        )}
      </Tab>
    );
  }
}

EmailSubscriptionsView.propTypes = {
  customer: PropTypes.instanceOf(Customer),
};

const mapStateToProps = (state) => ({
  ...state.customer,
});

const mapDispatchToProps = (dispatch) => ({
  replaceCustomer: (customer) => {
    dispatch({ type: REPLACE_CUSTOMER, customer });
  },
});

export default withStyles(styles, { withTheme: true })(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(EmailSubscriptionsView))
);
