import { getStore } from '../store/configure';
import { saveObjectToStorage, getObjectFromStorage } from './storage';

/** @returns {object} */
export const getCurrentProducer = () => {
  const store = getStore();
  const state = store.getState();
  return state.producer.object;
};

/**
 * Save producer to local storage
 * @param {object} producer
 */
export function saveProducerToLocalStorage(producer) {
  return saveObjectToStorage('producer.data', producer);
}

/** Get producer from local storage */
export function getProducerFromLocalStorage() {
  return getObjectFromStorage('producer.data');
}

/** Remove producer from local storage */
export function removeProducerFromLocalStorage() {
  return localStorage.removeItem('producer.data');
}

export const makeStringAddress = (address) => {
  return [
    ...(address.address1 ? [address.address1] : []),
    ...(address.address2 ? [address.address2] : []),
    ...(address.zip ? [address.zip] : []),
    ...(address.city ? [address.city] : []),
    ...(address.countryCode ? [address.countryCode] : []),
  ]
    .map((item) => item && item.replace(/,/g, ''))
    .join(', ');
};

export const makeProducerAddress = (address) => {
  return (
    [
      ...(address.address1 ? [address.address1] : []),
      ...(address.city ? [address.city] : []),
      ...(address.province ? [address.province] : []),
    ]
      .map((item) => item && item.replace(/,/g, ''))
      .join(', ') + `${!!address?.zip ? ' ' + address?.zip : ''}`
  );
};

export function isNorwich(producer) {
  return producer?.id === 've_5afc76bd9a1c2625520813e4';
}
