import { getRequestConfig, REQUEST_TYPES } from '../request';
import API_END_POINTS from '../endpoints';
import axios from 'axios';

/**
 * Get member line items based on subscriptions schedule
 * @param {{"shipping:from": (*|string), query: string, customerIds: *[], "shipping:to": (*|string)}} params
 *
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiGetMemberLineItems = function (params, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.GET_MEMBER_LINE_ITEMS_BASED_ON_SCHEDULE);
  config.params = params;

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};
