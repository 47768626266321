import React from 'react';
import QueryString from 'query-string';
import { withRouter } from '../../hocs';
import { apiQuickBooksExchangeAuthCode } from '../../api';

class QuickBooksScene extends React.Component {
  componentDidMount() {
    const { location } = this.props;
    const query = QueryString.parse(location.search);
    if (query.realmId && query.code) {
      // Send credentials to the backend
      apiQuickBooksExchangeAuthCode({
        realmId: query.realmId,
        code: query.code,
      });
    }
  }

  render() {
    return (
      <div style={{ margin: 10 }}>
        <h1>Quick books redirect screen</h1>
        <p>Please wait...</p>
      </div>
    );
  }
}

export default withRouter(QuickBooksScene);
