import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { PrimaryTextField } from '../../components';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { ScheduleDialog } from '../../dialogs';
import ErrorBox from '../ErrorBox/ErrorBox';
import ScheduleDates from '../../entities/ScheduleDates';

const propTypes = {
  TextFieldComponent: PropTypes.any,
  scheduleData: PropTypes.instanceOf(ScheduleDates),
  isScheduleExist: PropTypes.bool,
  onDone: PropTypes.func,
  dateFormat: PropTypes.string,
  inputProps: PropTypes.object,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onUpdateScheduleData: PropTypes.func,
  onDateDelete: PropTypes.func,
};

const defaultProps = {
  isScheduleExist: false,
  dateFormat: process.env.REACT_APP_DATE_FORMAT_WITH_SHORT_DAY_OF_WEEK,
  inputProps: {},
  onDone: () => {},
  scheduleData: new ScheduleDates({}),
};

const styles = () => ({
  errorBoxWrapper: {
    width: '100%',
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
  },
});

const ScheduleInput = (props) => {
  const {
    classes,
    intl,
    TextFieldComponent = PrimaryTextField,
    scheduleData,
    isScheduleExist,
    onDone,
    dateFormat,
    inputProps,
    onDateMoveSeries,
    onDateChange,
    minDate,
    maxDate,
    onUpdateScheduleData,
    staticValueMode,
    onDateDelete,
  } = props;

  const [cache, setCache] = useState(props.value);
  const [value, setValue] = useState(props.value);
  const [editingMode, setEditingMode] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (props.value !== value) {
      setValue(props.value);
      setCache(props.value);
    }
  }, [props.value]);

  const onError = (error) => {
    setError(error?.response?.data?.message || intl.formatMessage({ id: 'global.somethingWentWrong' }));
  };

  const onFinishEditing = (newValue = value) => {
    setCache(newValue);
    setValue(newValue);
    setEditingMode(false);
    onDone(newValue, () => {}, onError);
  };

  const onClose = () => {
    setValue(cache);

    setEditingMode(false);
  };

  return (
    <>
      <TextFieldComponent
        value={staticValueMode ? moment(props.value).format(dateFormat) : moment(value).format(dateFormat)}
        readOnly={true}
        onClick={() => {
          setEditingMode(true);
        }}
        {...inputProps}
      />

      {!!error && <ErrorBox error={error} className={classes.errorBoxWrapper} />}

      {editingMode && (
        <ScheduleDialog
          onClose={onClose}
          onDone={onFinishEditing}
          scheduleData={scheduleData}
          onUpdateScheduleData={onUpdateScheduleData}
          isScheduleExist={isScheduleExist}
          loading={false}
          value={value}
          minDate={minDate}
          maxDate={maxDate}
          onDateMoveSeries={onDateMoveSeries}
          onDateChange={onDateChange}
          onDateDelete={onDateDelete}
          onChange={(newValue) => {
            setValue(newValue);
          }}
        />
      )}
    </>
  );
};

ScheduleInput.propTypes = propTypes;
ScheduleInput.defaultProps = defaultProps;

export default withStyles(styles)(injectIntl(ScheduleInput));
