import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {COLORS} from '../../../helpers/index'

const styles = ({
  title: {
    fontSize: 18,
    display: 'block',
    color: COLORS.text,
    paddingTop: 14,
    paddingBottom: 14,
    fontWeight: 300,
    fontFamily: "Roboto, sans-serif",
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
});

const propTypes = {
  title: PropTypes.string,
};

const BlockSeparator = ({ title,
                          classes }) => {
  return (
    <div className={classes.wrapper}>
      {title && (
        <Typography className={classes.title}
                    variant={"h4"}>{title}</Typography>
      )}
    </div>
  );
};

BlockSeparator.propTypes = propTypes;

export default withStyles(styles)(
  BlockSeparator
);

