import moment from 'moment';

export function makeTuesdaysData(dates, selectedMonth) {
  const { to } = selectedMonth;

  const toDay = moment(to, true).format('DD');
  const firstPart = moment(to, true).format('YYYY-MM-');
  let datesObj = {};

  for (let i = 1; i <= toDay; i++) {
    const val = i < 10 ? `0${i}` : i;
    const date = firstPart + val;

    // Fill all dates to sort them and fill them with exist dates
    datesObj[date] = null;

    const isToday = moment(firstPart + val).isSame(moment(), 'day');
    const isTuesday = moment(firstPart + val).day() === 2;
    // overwrite tuesdays 2 it's
    if (isTuesday || isToday) {
      datesObj[date] = {
        id: moment(date).toISOString(),
        items: [],
        isTuesday,
        isToday,
      };
    }
  }

  // Fill exist dates in sorted dates obj
  dates.forEach((date) => {
    const isInPast = moment(date.id).startOf('day').valueOf() < moment().startOf('day').valueOf();
    const hasItems = !!date?.items?.length;

    datesObj[date.id] = { ...datesObj[date?.id], ...date, isInPast, open: hasItems && !isInPast }; // to detect first date to open
  });

  return Object.values(datesObj).filter((i) => i !== null); // filter empty days
}
