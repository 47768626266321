import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { toPaper } from '../../../../helpers';
import moment from 'moment';

export const cellRenderer = (intl, reload, { row, ...props }) => {
  if (row.rowType === 'types') {
    if (props.column.name === 'type') {
      return <Table.Cell {...props}>{row.typeName}</Table.Cell>;
    }

    if (props.column.name === 'limitPerPeriod')
      return (
        <Table.Cell {...props} style={{ fontWeight: 'bold' }}>
          {toPaper(row.typeTotalPrice, intl)}
        </Table.Cell>
      );

    return <Table.Cell {...props}>&nbsp;</Table.Cell>;
  }

  if (row.rowType === 'productSubType') {
    if (props.column.name === 'productSubType') {
      return <Table.Cell {...props}>{row.subTypeName}</Table.Cell>;
    }

    if (props.column.name === 'limitPerPeriod')
      return (
        <Table.Cell {...props} style={{ fontWeight: 'bold' }}>
          {toPaper(row.productSubTypeTotalPrice, intl)}
        </Table.Cell>
      );
    return <Table.Cell {...props}>&nbsp;</Table.Cell>;
  }

  if (row.rowType === 'lineItemNameProduct') {
    if (props.column.name === 'nameProduct') {
      return <Table.Cell {...props}>{row.productName}</Table.Cell>;
    }

    if (props.column.name === 'limitPerPeriod')
      return (
        <Table.Cell {...props} style={{ fontWeight: 'bold' }}>
          {toPaper(row.lineItemNameProductTotalPrice, intl)}
        </Table.Cell>
      );

    return <Table.Cell {...props}>&nbsp;</Table.Cell>;
  }

  if (row.rowType === 'lineItemNameVariant') {
    if (props.column.name === 'nameVariant') {
      return <Table.Cell {...props}>{row?.variantName || ''}</Table.Cell>;
    }

    if (props.column.name === 'limitPerPeriod')
      return (
        <Table.Cell {...props} style={{ fontWeight: 'bold' }}>
          {toPaper(row.lineItemNameVariantTotalPrice, intl)}
        </Table.Cell>
      );

    return <Table.Cell {...props}>&nbsp;</Table.Cell>;
  }

  if (row.rowType === 'end') {
    if (props.column.name === 'limitPerPeriod') {
      return <Table.Cell {...props}>{toPaper(row.limitPerPeriod, intl)}</Table.Cell>;
    }
    if (props.column.name === 'nextDate' || props.column.name === 'endDate') {
      return (
        <Table.Cell {...props}>
          {row[props.column.name] ? moment(row[props.column.name]).format('dddd, MMM, DD') : '-'}
        </Table.Cell>
      );
    }
  }

  if (
    props.column.name === 'type' ||
    props.column.name === 'nameProduct' ||
    props.column.name === 'nameVariant' ||
    props.column.name === 'productSubType'
  ) {
    return <Table.Cell {...props}>&nbsp;</Table.Cell>;
  }

  return <Table.Cell {...props} />;
};
