import React from 'react';
import { InventoryIcon, Unlisted } from '../../assets';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import Title from '../Title';
import IconSeparatedTextList from '../IconSeparatedTextList';
import { BUTTON_VARIANTS } from '../../constants/globals';
import PrimaryButton from '../SupplierButton';
import { useDispatch } from 'react-redux';
import { toggleSupplierPopover } from '../../../../store/actions';

const useStyles = makeStyles(styles);

const FirstItemAddedContent = () => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const onClose = () => dispatch(toggleSupplierPopover(null));

  return (
    <>
      <Title className={classes.title} text={intl.formatMessage({ id: 'supplier.popover.firstItemAdded.title' })} />

      <div className={classes.iconSeparatedTextWrapper}>
        <IconSeparatedTextList
          icon={InventoryIcon}
          title={intl.formatMessage({ id: 'supplier.popover.firstItemAdded.yourBuyersWillSeeYourUpdates' })}
          text={intl.formatMessage({ id: 'supplier.popover.firstItemAdded.allBuyersAutomaticallyGetYourUpdates' })}
        />
        <IconSeparatedTextList
          icon={Unlisted}
          title={intl.formatMessage({ id: 'supplier.popover.firstItemAdded.dontWantThatUnlistForLater' })}
          text={intl.formatMessage({ id: 'supplier.popover.firstItemAdded.useUnlistToSaveItemsToEditLater' })}
        />
      </div>

      <PrimaryButton
        classes={{ root: classes.button }}
        variant={BUTTON_VARIANTS.blue}
        text={intl.formatMessage({ id: 'supplier.popover.firstItemAdded.button' })}
        link={'#'}
        onClick={onClose}
      />
    </>
  );
};

export default FirstItemAddedContent;
