import React from 'react';
import TemporaryTooltip from './TemporaryTooltip';
import { useDispatch, useSelector } from 'react-redux';
import { hideTemporaryTooltip } from '../../store/actions/common';

const WrappedTemporaryTooltip = () => {
  const { temporaryTooltip } = useSelector((state) => ({
    temporaryTooltip: state.common.temporaryTooltip,
  }));
  const dispatch = useDispatch();

  const onHideTemporaryTooltip = () => dispatch(hideTemporaryTooltip());

  return (
    <TemporaryTooltip
      message={temporaryTooltip.message}
      icon={temporaryTooltip.icon}
      onHide={onHideTemporaryTooltip}
      isActive={temporaryTooltip.open}
    />
  );
};

export default WrappedTemporaryTooltip;
