import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, useIntl } from 'react-intl';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import { weightToSystem } from '../../../helpers';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { showTemporaryTooltip } from '../../../store/actions/common';
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';

const propTypes = {
  value: PropTypes.string,
  cellProps: PropTypes.object,
  inputProps: PropTypes.object,
  onFinishCellEditing: PropTypes.func.isRequired,
};

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: '#212121',
    },
    secondary: {
      main: '#363636',
    },
    white: {
      main: '#ffffff',
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        display: 'none',
      },
    },

    MuiInput: {
      root: {
        fontFamily: "'Roboto', sans-serif",
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 1.8,
        verticalAlign: 'middle',
        boxSizing: 'border-box',
        color: `#363636`,
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        borderRadius: 100,
        paddingLeft: 0,
        paddingRight: 0,
        minHeight: 26,
        gap: 10,
        width: 'auto',
        minWidth: 30,

        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
          '-moz-appearance': 'textfield',
        },
      },
      underline: {
        '&:after, &$hover:after, &$focused:after, &active:after, &:before, &$hover:before, &$focused:before, &active:before, &:hover:not($disabled):not($focused):not($error):before, &$disabled:before': {
          content: 'none',
          borderBottom: '0',
          borderBottomColor: `transparent`,
        },
      },
    },
    MuiInputBase: {
      input: {
        width: 'auto',
        minWidth: 30,
        height: 26,
        padding: 0,
        paddingLeft: 10,
        paddingRight: 10,
        boxSizing: 'border-box',
        textAlign: 'center',
        verticalAlign: 'middle',
      },
    },
  },
});

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'auto',
    height: '100%',
    paddingTop: 13,
    paddingBottom: 13,
    '& *': {
      boxSizing: 'border-box',
    },
  },
  button: {
    width: 26,
    height: 26,
  },
  buttonIcon: {
    fontSize: 14,
    color: '#000',
    fontFamily: 'Roboto, sans-serif',
  },
  buttonPlus: {
    fontSize: 14,
    width: 100,
  },
  buttonMinus: {
    fontSize: 24,
  },
  copiedSuccessWrapper: {
    position: 'fixed',
    bottom: 30,
    left: 'calc(50% - 150px)',
    backgroundColor: '#000',
    padding: '10px 20px',
    border: '2px solid #000',
    color: 'white',
    borderRadius: 4,
    zIndex: 1000,
    minWidth: 300,
  },
  spinner: {
    marginLeft: 5,
  },
}));

const defaultProps = {
  inputProps: {
    InputProps: {
      type: 'number',
      step: 0.01,
      min: 0,
    },
  },
};

const CellAsQuantityInput = (props) => {
  const { value, cellProps, onFinishCellEditing, inputProps, delay = 1000, onSuccessMessage } = props;
  const [localValue, setLocalValue] = useState(value);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();
  const intl = useIntl();

  useEffect(() => {
    setLocalValue(parseInt(value));
  }, [value]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (localValue.toString() !== value) {
        setLoading(true);
        onFinishCellEditing &&
          onFinishCellEditing(
            weightToSystem(localValue),
            () => {
              setLoading(false);
              const successMessage = onSuccessMessage
                ? intl.formatMessage({ id: onSuccessMessage })
                : intl.formatMessage({ id: 'global.quantityUpdated' });
              dispatch(showTemporaryTooltip(successMessage, CheckIcon));
            },
            (error) => {
              setLoading(false);
              setLocalValue(parseInt(value)); // Load prev value

              const errorMessage =
                error?.response?.data?.message || intl.formatMessage({ id: 'global.somethingWentWrong' });
              dispatch(showTemporaryTooltip(errorMessage));
            }
          );
      }
    }, delay);

    return () => clearTimeout(timer);
  }, [localValue]);

  const onCellClick = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const onChange = useCallback((newValue) => {
    if (inputProps && inputProps.InputProps && inputProps.InputProps.min !== undefined) {
      setLocalValue(parseInt(newValue >= inputProps.InputProps.min ? newValue : 0));
    } else {
      setLocalValue(parseInt(newValue));
    }
  }, []);

  const countDynamicWidth = useCallback(() => {
    const digitsNumber = localValue.toString().length < 11 ? localValue.toString().length : 10;
    let width = 20 + 10 * digitsNumber; // Expand input for new digits

    return { width };
  }, [localValue]);

  return (
    <Table.Cell
      onClick={onCellClick}
      {...cellProps}
      style={{
        cursor: 'pointer',
      }}
      className={classNames([classes.root, 'hideArrow', cellProps?.className])}>
      <MuiThemeProvider theme={theme}>
        {!loading && (
          <IconButton className={classes.button} onClick={() => onChange(localValue - 1)}>
            <p className={classNames([classes.buttonIcon, classes.buttonMinus])}>-</p>
          </IconButton>
        )}
        <TextField
          disabled={loading}
          {...inputProps}
          onChange={(event) => onChange(event.target.value)}
          value={localValue}
          style={countDynamicWidth()}
          className={'hideArrow'}
        />
      </MuiThemeProvider>
      {!loading && (
        <IconButton className={classes.button} onClick={() => onChange(localValue + 1)}>
          <p className={classNames([classes.buttonIcon, classes.buttonPlus])}>+</p>
        </IconButton>
      )}

      {loading && <CircularProgress className={classes.spinner} size={14} />}
    </Table.Cell>
  );
};

CellAsQuantityInput.propTypes = propTypes;
CellAsQuantityInput.defaultProps = defaultProps;

export default injectIntl(CellAsQuantityInput);
