import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {
  AdminTabContent,
  AdminTabContentHeader,
  AdminTabContentTitle,
} from '../../components/index';

const propTypes = {
  actions: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
};

const Tab = ({ intl,
               title,
               actions,
               producer,
               children,
               ...other }) => {
  return (
    <AdminTabContent {...other} >
      <AdminTabContentHeader actions={actions}>
        <AdminTabContentTitle>
          {title}
        </AdminTabContentTitle>
      </AdminTabContentHeader>
      {children}
    </AdminTabContent>
  )
};

Tab.propTypes = propTypes;

export default injectIntl(Tab);