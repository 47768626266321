import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import DXSelectFilter from '../DXSelectFilter/DXSelectFilter';
import DXFilterConfig from '../DXFilterConfig';
import constants from '../../constants';

const propTypes = {
  equality: PropTypes.string,
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  selected: [],
};

class DXCustomerAllowCreditFilter extends React.Component {
  render() {
    const { selected, onChange, intl, equality } = this.props;
    const config = DXCustomerAllowCreditFilter.getConfig(intl, equality);
    return (
      <DXSelectFilter
        selected={selected}
        title={config.title}
        values={config.values}
        onChange={onChange}
        multi={config.multi}
      />
    );
  }
}

DXCustomerAllowCreditFilter.propTypes = propTypes;
DXCustomerAllowCreditFilter.defaultProps = defaultProps;
DXCustomerAllowCreditFilter.getLabel = (intl, selectedValues) => {
  return DXSelectFilter.getLabel(intl, 'customer.filter.allowCredit.', selectedValues);
};
DXCustomerAllowCreditFilter.applyToQueryParams = (queryParams, selectedValues) => {
  selectedValues[0] && (queryParams['allowCredit'] = selectedValues[0]);
  return queryParams;
};
DXCustomerAllowCreditFilter.getConfig = (intl, equality) => {
  let config = new DXFilterConfig({
    name: 'allowCredit',
    selected: [],
    property: 'allowCredit',
    title: intl.formatMessage({ id: 'customer.filter.allowCredit' }),
    disabled: false,
    equalityStatus: false,
    multi: false,
  });

  if (equality === constants.FILTER_EQUALITY_EQUALS) {
    config.addFilterValue('true', intl.formatMessage({ id: 'customer.filter.allowCredit.true' }));
    config.addFilterValue('false', intl.formatMessage({ id: 'customer.filter.allowCredit.false' }));
  }

  return config;
};

export default injectIntl(DXCustomerAllowCreditFilter);
