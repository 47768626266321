import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { makeStyles } from '@material-ui/core';
import { PADDING_CONTENT, PADDING_CONTENT_TOP } from '../../constants/globals';

const useStyles = makeStyles(() => ({
  content: {
    paddingLeft: PADDING_CONTENT,
    paddingRight: PADDING_CONTENT,
    paddingTop: PADDING_CONTENT_TOP,
  },
  title: {
    marginBottom: 10,
  },
  subTitle: {
    marginBottom: 20,
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 5,
  },
  rowItem: {
    marginBottom: 20,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 5,
    paddingRight: 5,
    marginLeft: -5,
    marginRight: -5,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flex: 1,
  },
  rowItem2: {
    paddingLeft: 25,
  },
  rowItem3: {
    paddingLeft: 45,
  },
}));

const OrdersSkeleton = ({ numberOfItems }) => {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <div className={classes.textWrapper}>
        <Skeleton className={classes.title} height={30} count={1} width={150} />
        <Skeleton className={classes.subTitle} height={20} count={1} width={200} />
      </div>

      {Array(numberOfItems)
        .fill()
        .map((item, index) => (
          <div key={`ca_${index}`} className={classes.rowItem}>
            <div className={classes.textWrapper}>
              <Skeleton count={1} height={25} width={100 + Math.random() * 200 + 'px'} />
              <Skeleton count={1} height={10} width={100 + Math.random() * 200 + 'px'} style={{ marginTop: 10 }} />
            </div>
            <Skeleton count={1} width={15} circle />
          </div>
        ))}
    </div>
  );
};

OrdersSkeleton.propTypes = {
  id: PropTypes.string,
  numberOfItems: PropTypes.number,
};

OrdersSkeleton.defaultProps = {
  id: 'default',
  numberOfItems: 5,
};

export default OrdersSkeleton;
