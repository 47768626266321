import React from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../hocs';
import Typography from '@material-ui/core/Typography';
import {
  AdminDialog,
  AdminDialogTitle,
  AdminDialogContent,
  AdminDialogControls,
  InlineSelect,
  PrimaryButton,
  PrimaryTextField,
  Spinner,
  ErrorBox,
} from '../../components';
import { variantsArrayToObject } from './helpers';
import { apiProductGet } from '../../api';
import {
  getLineItemSystemQuantity,
  getVariantPriceByCustomerType,
  validateField,
  parseFloatFromString,
  toCoins,
  weightToUser,
} from '../../helpers';
import validationRules from './validation';
import styles from './styles';

const propTypes = {
  customerType: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
  onDone: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

const defaultProps = {
  loading: false,
};

class AddToCartDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      internalLoading: true,
      product: null,

      quantity: '',
      unitPrice: '',
      note: '',

      quantityError: '',
      unitPriceError: '',
      isLoading: false,
      requestError: '',
    };
  }

  componentDidMount() {
    const { intl, customerType } = this.props;

    this.loadProduct((product) => {
      let state = {
        internalLoading: false,
      };

      state.product = product;
      if (product.variants.length) {
        state.variant = product.variants[0];
        state.unitPrice = getVariantPriceByCustomerType(state.variant, intl, customerType);
      }
      this.setState(state);
    });
  }

  loadProduct = (onLoaded) => {
    const { product } = this.props;
    apiProductGet(product.id, (product) => {
      this.setState({
        product: product,
      });
      onLoaded && onLoaded(product);
    });
  };

  validate = () => {
    const unitPrice = toCoins(parseFloatFromString(this.state.unitPrice));
    const quantityError = validateField(validationRules, 'quantity', this.state.quantity);
    const unitPriceError = validateField(validationRules, 'unitPrice', unitPrice);
    if (!quantityError && !unitPriceError) {
      this.setState({
        quantityError: '',
        unitPriceError: '',
        requestError: '',
        isLoading: true,
      });

      this.props.onDone(
        this.props.product,
        this.state.variant,
        {
          note: this.state.note,
          quantity: getLineItemSystemQuantity(this.state.variant, this.state.quantity),
          unitPrice: unitPrice,
        },
        this.handleResponseErrors
      );
    } else {
      this.setState({
        quantityError: quantityError,
        unitPriceError: unitPriceError,
        isLoading: false,
      });
    }
  };

  handleResponseErrors = (error) => {
    const { intl } = this.props;
    const errorData = error?.response?.data;
    let errorMessage = errorData?.message || intl.formatMessage({ id: 'messages.somethingWentWrong' });

    if (errorData?.code === 3007) {
      errorMessage = intl.formatMessage({ id: 'messages.addOrderedQuantityMoreThanAvailable' });
    }

    this.setState({
      isLoading: false,
      requestError: errorMessage,
    });
  };

  makeVariantsValues = (variants = []) => {
    const result = variants.map((item) => ({
      ...item,
      name: item.name || `${weightToUser(item.unitQuantity, true)} ${item.unit}`,
    }));
    return variantsArrayToObject(result);
  };

  render() {
    const { onClose, intl, classes, loading, customerType } = this.props;

    const { product, internalLoading, isLoading } = this.state;

    return (
      <AdminDialog open closeButton onClose={onClose}>
        <AdminDialogTitle title={intl.formatMessage({ id: 'global.addToCart' })} />
        <AdminDialogContent className={classes.wrapper}>
          {(loading || internalLoading) && <Spinner size={50} />}

          {!internalLoading && (
            <React.Fragment>
              <Typography className={classes.name}>{product.name}</Typography>

              <InlineSelect
                values={this.makeVariantsValues(product.variants)}
                className={classes.variants}
                onSelect={(variant) =>
                  this.setState({
                    variant: variant,
                    unitPrice: getVariantPriceByCustomerType(variant, intl, customerType),
                  })
                }
                selected={this.state.variant.id}
              />

              <PrimaryTextField
                className={classes.input}
                error={!!this.state.quantityError}
                onChange={(event) => this.setState({ quantity: event.target.value.trim() })}
                value={this.state.quantity}
                id="quantity-input"
                label={intl.formatMessage({ id: 'global.quantity' })}
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                InputProps={{
                  classes: {
                    input: classes.inputText,
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography classes={{ root: classes.nowrap }} color="textSecondary">
                        {this.state.variant.unit}
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                helperText={this.state.quantityError && intl.formatMessage({ id: this.state.quantityError })}
                fullWidth
              />

              <PrimaryTextField
                className={classes.input}
                error={!!this.state.unitPriceError}
                onChange={(event) => this.setState({ unitPrice: event.target.value.trim() })}
                value={this.state.unitPrice}
                id="unit-price-input"
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                label={intl.formatMessage({ id: 'global.unitPrice' })}
                type="text"
                InputProps={{
                  classes: {
                    input: classes.inputText,
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography classes={{ root: classes.nowrap }} color="textSecondary">
                        {this.state.variant.unit}
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                helperText={this.state.unitPriceError && intl.formatMessage({ id: this.state.unitPriceError })}
                fullWidth
              />

              <PrimaryTextField
                className={classes.input}
                onChange={(event) => this.setState({ note: event.target.value })}
                value={this.state.note}
                id="note"
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                label={intl.formatMessage({ id: 'global.note' })}
                type="text"
                fullWidth
              />

              <AdminDialogControls>
                <ErrorBox error={this.state.requestError} />

                <PrimaryButton className={classes.button} onClick={this.validate} isLoading={isLoading}>
                  {intl.formatMessage({ id: 'global.addToCart' })}
                </PrimaryButton>
              </AdminDialogControls>
            </React.Fragment>
          )}
        </AdminDialogContent>
      </AdminDialog>
    );
  }
}

AddToCartDialog.propTypes = propTypes;
AddToCartDialog.defaultProps = defaultProps;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(AddToCartDialog)));
