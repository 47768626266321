import React from 'react'
import PropTypes from 'prop-types'
import ImageList from '@material-ui/core/ImageList'
import ImageListItem from '@material-ui/core/ImageListItem'
import List from '@material-ui/core/List'
import IconButton from '@material-ui/core/IconButton'
import Subheader from '@material-ui/core/ListSubheader'
import {withStyles} from '@material-ui/core/styles'
import ListViewIcon from '@material-ui/icons/ViewList'
import GridViewIcon from '@material-ui/icons/GridOn'
import ListWithoutImagesViewIcon from '@material-ui/icons/ViewHeadline'
import {
  COLLECTION_VIEW_TYPE_LIST,
  COLLECTION_VIEW_TYPE_LIST_COMPACT,
  COLLECTION_VIEW_TYPE_GRID
} from '../../helpers'
import styles from './styles'

class SearchHitsWrapper extends React.Component {

  onSwitchView = (view) => {
    this.props.onSwitchView &&
    this.props.onSwitchView(view)
  };

  getHeader = () => {
    const {classes, title, allowSwitchView} = this.props;
    
    if (!allowSwitchView) {
      return null;
    }

    return (
      <Subheader className={classes.title} component="div">
        {title}
        <div className={classes.viewSwitcher}>
          <IconButton className={(this.props.view === COLLECTION_VIEW_TYPE_GRID ? classes.activeIcon : classes.inactiveIcon)}
                      onClick={() => this.onSwitchView(COLLECTION_VIEW_TYPE_GRID)} aria-label="Grid view">
            <GridViewIcon/>
          </IconButton>

          <IconButton className={(this.props.view === COLLECTION_VIEW_TYPE_LIST ? classes.activeIcon : classes.inactiveIcon)}
                      onClick={() => this.onSwitchView(COLLECTION_VIEW_TYPE_LIST)} aria-label="List view">
            <ListViewIcon/>
          </IconButton>

          <IconButton className={(this.props.view === COLLECTION_VIEW_TYPE_LIST_COMPACT ? classes.activeIcon : classes.inactiveIcon)}
                      onClick={() => this.onSwitchView(COLLECTION_VIEW_TYPE_LIST_COMPACT)} aria-label="List view compact">
            <ListWithoutImagesViewIcon/>
          </IconButton>
        </div>
      </Subheader>
    );
  };

  render() {
    const {classes, children, view} = this.props;
    if (view === 'grid') {
      return (
        <ImageList rowHeight={180} className={classes.gridList}>
          <ImageListItem key="Subheader" style={{height: 'auto', width: '100%', padding: 0, overflow: 'hidden'}}>
            {this.getHeader()}
          </ImageListItem>
          {children}
        </ImageList>
      );
    }

    return (
      <List component="nav" subheader={this.getHeader()}>
        {children}
      </List>
    );
  }
}

SearchHitsWrapper.propTypes = {
  view: PropTypes.string,
  allowSwitchView: PropTypes.bool,
  onSwitchView: PropTypes.func,
  title: PropTypes.string
};

SearchHitsWrapper.defaultProps = {
  view: COLLECTION_VIEW_TYPE_GRID,
  allowSwitchView: true,
  title: ""
};

export default withStyles(styles)(
  SearchHitsWrapper
);