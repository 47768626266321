const validation = {
  companyName: {
    presence: {
      message: '^validation.register.enterCompanyName'
    }
  },
  email: {
    email: {
      message: '^validation.register.enterValidEmail'
    },
    presence: {
      message: '^validation.register.enterEmail'
    }
  },
  firstName: {
    presence: {
      message: '^validation.register.enterFirstName'
    }
  },
  password: {
    presence: {
      message: '^validation.register.enterPassword'
    }
  },
  lastName: {
    presence: {
      message: '^validation.register.enterLastName'
    }
  },
};

export default validation;
