import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Icon from '../../../../components/Icon/Icon';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import classNames from 'classnames';

function TabPanel(props) {
  const {children, value, index, ...other} = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} className={classes.boxWrapper}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  tabPanel: {
    flex: 1,
  },
  boxWrapper: {
    flex: 1
  },
  titleWrapper: {
    padding: 10,
    paddingLeft: 25,
    paddingRight: 25,
    borderBottom: '1px solid #E0E0E0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 25,
    margin: 0,
    fontWeight: 'bold',
  },
  moreIcon: {
    marginBottom: '0 !important',
    fontSize: 26,
  },
  moreButton: {
    paddingLeft: 10,
    width: 34,
    height: 34,
  },
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  tabs: {},
  tabRoot: {
    backgroundColor: '#FAFBFB',
    borderBottom: '1px solid #E0E0E0',
    borderRight: '1px solid #E0E0E0',
    textTransform: 'none',
    '&:last-of-type': {
      borderBottom: '0',
    },
  },
  tabWrapper: {
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  tabSelected: {
    backgroundColor: '#ffffff',
    borderRight: '1px solid #ffffff',
    color: '#247356',
    'svg': {
      fill: '#247356 !important'
    }
  },
  tabLabelIcon: {},
  icon: {
    marginBottom: '0 !important',
    paddingRight: 10,
    fontSize: 34,
  },
  iconCheck: {
    fill: '#247356 !important'
  },
  tabsIndicator: {
    width: 3,
    backgroundColor: '#247356',
    left: 0,
    right: 'auto',
  },
}));

LeftSideTabsWrapper.propTypes = {
  tabsContent: PropTypes.arrayOf(PropTypes.object).isRequired,
  ariaLabel: PropTypes.string,
}

export default function LeftSideTabsWrapper(props) {
  const {
    ariaLabel,
    tabsContent,
    title,
  } = props
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getTabsTitles = () => {
    return tabsContent.map((tab, index) => <Tab key={tab.title} icon={<Icon icon={!tab.isFinished ? tab.icon : CheckCircleIcon} className={classNames([classes.icon, tab.isFinished && classes.iconCheck])}/>} classes={{
      root: classes.tabRoot,
      selected: classes.tabSelected,
      wrapper: classes.tabWrapper,
      labelIcon: classes.tabLabelIcon,
    }} label={tab.title} {...a11yProps(index)} />);
  }

  const getTabsContent = (value) => {
    return tabsContent.map((tab, index) => <TabPanel className={classes.tabPanel} key={tab.title} value={value} index={index}>
      {tab.content}
    </TabPanel>);
  }

  return (
    <>
      <div className={classes.titleWrapper}>
        <h2 className={classes.title}>
          {title}
        </h2>
        <div>
          <IconButton className={classes.moreButton}
                      onClick={() => {
                      }}>
            <MoreHorizIcon className={classes.moreIcon}/>
          </IconButton>
        </div>
      </div>

      <div className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          classes={{indicator: classes.tabsIndicator}}
          onChange={handleChange}
          aria-label={ariaLabel || 'Vertical tabs'}
          className={classes.tabs}
        >
          {
            getTabsTitles()
          }
        </Tabs>
        {
          getTabsContent(value)
        }
      </div>
    </>
  );
}
