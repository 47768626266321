import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { TableCheckbox } from '../../components';
import { variantHasPriceWithLevel, getVariantPriceWithoutCurrency, PRICE_LEVELS, toPaper } from '../../helpers';
import { apiActivateVariants, apiInactivateVariants } from '../../api/product/product';
import FiltersProcessor from '../../components/AdminDXTable/ClientSideFilters/FiltersProcessor';
import { ACTIVITY_FILTER as ACTIVITY_FILTER_NAME } from '../../dialogs/ProductsFilterDialog/FilterNames';
import { ACTIVITY_FILTER as ACTIVITY_FILTER_TYPE } from '../../components/AdminDXTable/ClientSideFilters/FilterTypes';

export const prepareActiveTabFilters = (intl, filters) => {
  const defaultActiveFilter = {
    name: ACTIVITY_FILTER_NAME,
    property: 'active',
    type: ACTIVITY_FILTER_TYPE,
    title: intl.formatMessage({ id: 'products.filter.activity' }),
    selectedValues: ['Active'],
    clientSide: true,
    entities: ['product', 'variant'],
  };
  filters.push(defaultActiveFilter);
  return filters;
};

export const TableCell = (reload, { row, ...props }, rows, onUpdateRows) => {
  const type = props.column.type || 'text';
  const image = row.image;

  if (type === 'image' && image) {
    return (
      <Table.Cell {...props}>
        <img src={image.src} alt={row.name} style={{ maxWidth: 50 }} />
      </Table.Cell>
    );
  }

  if (type === 'subType') {
    return <Table.Cell {...props}>{row.subType || ''}</Table.Cell>;
  }

  if (type === 'customer') {
    return <Table.Cell {...props}>{row.isCustomer ? row?.name || '' : ' '}</Table.Cell>;
  }

  if (type === 'variant') {
    return <Table.Cell {...props}>{!row.isCustomer ? row.variant : ''}</Table.Cell>;
  }

  if (type === 'description') {
    return <Table.Cell {...props}>{row.description || ''}</Table.Cell>;
  }

  if (type === 'unit') {
    return <Table.Cell {...props}>{row.unit || ''}</Table.Cell>;
  }

  if (type === PRICE_LEVELS.WHOLESALE_MARKET && variantHasPriceWithLevel(row, PRICE_LEVELS.WHOLESALE_MARKET)) {
    return (
      <Table.Cell {...props}>{toPaper(getVariantPriceWithoutCurrency(row, PRICE_LEVELS.WHOLESALE_MARKET))}</Table.Cell>
    );
  }

  if (type === PRICE_LEVELS.WHOLESALE_PREORDER && variantHasPriceWithLevel(row, PRICE_LEVELS.WHOLESALE_PREORDER)) {
    return (
      <Table.Cell {...props}>
        {toPaper(getVariantPriceWithoutCurrency(row, PRICE_LEVELS.WHOLESALE_PREORDER))}
      </Table.Cell>
    );
  }

  if (type === PRICE_LEVELS.RETAIL_MARKET && variantHasPriceWithLevel(row, PRICE_LEVELS.RETAIL_MARKET)) {
    return (
      <Table.Cell {...props}>{toPaper(getVariantPriceWithoutCurrency(row, PRICE_LEVELS.RETAIL_MARKET))}</Table.Cell>
    );
  }

  if (type === PRICE_LEVELS.RETAIL_PREORDER && variantHasPriceWithLevel(row, PRICE_LEVELS.RETAIL_PREORDER)) {
    return (
      <Table.Cell {...props}>{toPaper(getVariantPriceWithoutCurrency(row, PRICE_LEVELS.RETAIL_PREORDER))}</Table.Cell>
    );
  }

  if (type === 'active' && !row.isCustomer) {
    return getActiveCell(row, props, rows, onUpdateRows);
  }

  if (type === 'active' && row.isCustomer) {
    return <Table.Cell />;
  }

  return <Table.Cell {...props} />;
};

const getActiveCell = (row, props, rows, onUpdateRows) => {
  const onChangeVariantActivityBeforeApiCall = (status) => {
    // Update statuses for variants
    for (let i = 0; i < rows.length; i++) {
      for (let j = 0; j < rows[i].variants.length; j++) {
        if (rows[i].variants[j].id === row.originalId) {
          if (status) {
            rows[i].active = status;
          }
          rows[i].variants[j].active = status;
          if (!status && !rows[i].variants.some((variant) => variant.active)) {
            rows[i].active = status;
          }
          break;
        }
      }
    }
    onUpdateRows(rows);
  };

  const onChangeVariantActivity = (status) => {
    onChangeVariantActivityBeforeApiCall(status);
    status
      ? apiActivateVariants([row.originalId], undefined, undefined)
      : apiInactivateVariants([row.originalId], undefined, undefined);
  };

  return (
    <Table.Cell {...props}>
      <TableCheckbox onChange={onChangeVariantActivity} checked={row.active} />
    </Table.Cell>
  );
};

export const TableRow = (onClick, { row, ...props }) => {
  return (
    <Table.Row
      {...props}
      onClick={() => onClick && onClick(row)}
      style={{
        backgroundColor: row.variants ? 'rgba(224, 224, 224, 0.4)' : '#ffffff',
      }}
    />
  );
};

export const filtersProcessor = (intl, filters, rows, push) => {
  let filtered = [];
  rows.forEach((row) => {
    let processor = new FiltersProcessor(intl, filters);
    if (processor.isSatisfy(row)) {
      filtered.push(row);
    }
  });
  push(filtered);
};
