import { getSupplierRequestConfig, replaceUrlPlaceholder, REQUEST_TYPES } from '../request';
import API_END_POINTS from '../endpoints';
import axios from 'axios';

/**
 * Get buyer info
 * @param {string} buyerId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiGetBuyer = function (buyerId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.GET_BUYER, 'id', buyerId);
  const config = getSupplierRequestConfig(REQUEST_TYPES.GET, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};