import { PRODUCER_SET } from '../constants';
import { saveProducerData } from '../../helpers';

/**
 * Set initial producer info
 * (Uses to set producer after authentication)
 * @param {object} producer
 * @return {function(*)}
 */
export function setProducer(producer) {
  return (dispatch) => {
    // Remove prepurchases parameter from vendor because is too huge
    saveProducerData({
      ...producer,
      prepurchases: [],
      subscriptions: [],
    });

    dispatch({
      type: PRODUCER_SET,
      payload: {
        producer: producer,
      },
    });
  };
}
