import React from 'react';
import { connect } from 'react-redux';
import { Spinner } from '../components';

class NotFoundResolver extends React.Component {
  state = {
    resolving: false,
  };

  render() {
    const { resolving } = this.state;
    const { children } = this.props;

    return (
      <React.Fragment>
        {resolving && (
          <div style={{ height: '100vh' }}>
            <Spinner />
          </div>
        )}
        {!resolving && children}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
  };
}

export default connect(mapStateToProps, null)(NotFoundResolver);
