import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import CancelIcon from '@material-ui/icons/Cancel';
import {
  PrimaryTextField
} from '../../components';
import SearchIcon from '@material-ui/icons/Search'

export default function InputComponent(inputProps, label) {
  const {
    classes,
    inputRef = () => {},
    intl,
    ref,
    onDeselect,
    ...other
  } = inputProps;

  return (
    <PrimaryTextField
      fullWidth
      label={label}
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color='secondary' />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {inputProps.value && (
              <IconButton
                style={{padding: 0}}
                onClick={onDeselect}
              >
                <CancelIcon />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
      {...other}
      InputLabelProps={{shrink: true}}
      margin="normal"
    />
  );
};