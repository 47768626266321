import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  MuiThemeProvider,
  createTheme
} from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';
import styles from "./styles";
import {Icon} from '../index';

const propTypes = {
  desc: PropTypes.any,
  icon: PropTypes.any
};

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiButton: {
      root: {
        fontFamily: "'Roboto', sans-serif",
        fontWeight: 400,
        textTransform: 'none',
        fontSize: 14,
        background: 'transparent',
        borderRadius: 6,
        border: '1px solid #707070',
        color: '#363636',
        height: 37,
        padding: '0 37px !important',
        boxShadow: '0px 3px 10px #00000040',
        minWidth: 200,
        transition: 'background 0.3s ease-in-out',
        '&:hover, &:active, &:focus': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)'
        }
      },
      label: {
        fontWeight: '400',
      }
    },
  },
});

class OutlineButton extends React.Component {
  render = () => {
    const {classes, desc, className, icon, ...other} = this.props;
    let wrapperClasses = {};
    wrapperClasses[classes.descWrapper] = !!desc;
    if (className) {
        wrapperClasses[className] = true;
    }

    return (
      <MuiThemeProvider theme={theme}>
        <Button className={classNames(wrapperClasses)} {...other}>
          {icon ? (
            <Icon className={classes.icon} icon={icon}/>
          ) : null }{this.props.children}
          {desc && (
            <span className={classes.desc}>
              {desc}
            </span>
          )}
        </Button>
      </MuiThemeProvider>
    );
  }
}

OutlineButton.propTypes = propTypes;

export default withStyles(styles)(OutlineButton);
