import React from "react";
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import classNames from 'classnames';
import {
  Icon
} from '../../components';
import {COLORS} from "../../helpers";

const styles = ({
  linkButton: {
    marginRight: 10
  },
  iconButton: {
    padding: 0,
    borderRadius: 0,
    '&:hover' : {
      background: 'none',
      backgroundColor: 'none'
    }
  },
  addNote: {
    fontSize: 16,
    cursor: 'pointer',
    color: '#03a196',
    '&:hover': {
      color: '#03857c'
    }
  },
  controls: {
    textAlign: 'right'
  },
  icon: {
    fontSize: 16,
    marginRight: 5
  },
  noteText: {
    fontSize: 16,
    color: '#000000'
  },
  noteWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  noteTextWrapper: {
    fontSize: 16,
    color: COLORS.cartSecondary,
  },
  iconWrapper: {
    padding: 0,
  },
  noteInputWrapper: {
    padding: 5,
    margin: '0 0 5px',
    border: '1px solid #cccccc',
    borderBottom: '1px solid #cccccc'
  },
  noteInput: {
    color: '#5f6a7d',
    fontSize: 16,
    width: '100%',
    padding: 0,
    border: 'none'
  }
});

class EditableNote extends React.Component {

  state = {
    editing: false,
    noteText: ""
  };

  _onSaveNote = (event) => {
    event.preventDefault();
    const { onChange, beforeSubmitFormatter } = this.props;
    const { noteText } = this.state;
    let text = noteText;

    if (beforeSubmitFormatter) {
      text = beforeSubmitFormatter(text);
    }

    onChange(text);
    this.setState({
      noteText: "",
      editing: false
    });
  };

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this._onSaveNote(e)
    }
  };

  render() {
    const {
      classes,
      note,
      title,
      intl,
      wrapperClass,
      multiLine,
      editable,
    } = this.props;

    const {
      noteInputWrapper,
      noteTextWrapper
    } = this.props.classes;

    const getAddButton = () => {
      return (
        <React.Fragment>

          {editable && (
            <IconButton onClick={(event) => {
              event.stopPropagation();
              this.setState({
                editing: true,
                noteText: note
              })
            }}
                        className={classes.iconButton}>
              <Icon icon={EditIcon} className={classes.icon}/>
              <Typography className={classNames(classes.noteTextWrapper, noteTextWrapper)}>
                {note ? note : (title || "")}
              </Typography>
            </IconButton>
          )}

          {!editable && (
            <Typography className={classNames(classes.noteTextWrapper, noteTextWrapper)}>
              {note}
            </Typography>
          )}

        </React.Fragment>
      )
    };

    return (
      <React.Fragment>
        {this.state.editing ?
          <div className={classNames(classes.noteWrapper, wrapperClass)}>
            <TextField
              classes={{root: classNames(classes.noteInputWrapper, noteInputWrapper)}}
              InputProps={{
                className: classes.noteInput
              }}
              defaultValue={note}
              onKeyDown={this._handleKeyDown}
              onClick={(e) => e.stopPropagation()}
              placeholder={intl.formatMessage({id : "note.text"})}
              onChange={(e) => this.setState({noteText: e.target.value})}
              multiline={multiLine}
              rows={multiLine ? 4 : 1}
              rowsMax={multiLine ? 4 : 1}
            />
            <div className={classes.controls}>
              <IconButton className={classes.iconWrapper} onClick={(e) => {
                e.stopPropagation();
                this.setState({editing: false})
              }}>
                <CloseIcon/>
              </IconButton>
              <IconButton className={classes.iconWrapper} onClick={(e) => {
                e.stopPropagation();
                this._onSaveNote(e)
              }}>
                <CheckIcon/>
              </IconButton>
            </div>
          </div> :
          getAddButton()
        }
      </React.Fragment>
    );
  }
}

EditableNote.propTypes = {
  editable: PropTypes.bool,
  multiLine: PropTypes.bool,
  wrapperClass: PropTypes.string,
  beforeSubmitFormatter: PropTypes.func,
  classes: PropTypes.shape({
    noteInputWrapper: PropTypes.string,
    noteTextWrapper: PropTypes.string,
  }),
  title: PropTypes.string,
  note: PropTypes.string,
  onChange: PropTypes.func
};

EditableNote.defaultProps = {
  editable: true,
  classes: {
    noteInputWrapper: {}
  },
  multiLine: true,
  editWithNoteTitle: "note.edit",
  editWithoutNoteTitle: "note.add",
  note: "",
};

export default withStyles(styles, {
  withTheme: true
})(injectIntl(EditableNote));