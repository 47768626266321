import moment from 'moment';

class SubscriptionOrderInTable {
  constructor(props) {
    this.id = props.id;
    this.name = props.name || '-';
    this.total = props.total || 0;
    this.createdAt = props.createdAt || moment().toISOString(true);
    this.locationName = props.locationName || props?.shipping?.location?.name || '';
    this.shippingDate = props.shippingDate || props?.shipping?.date || '';
    this.shippingType = props.shippingType || props?.shipping?.type || '';
  }
}

export default SubscriptionOrderInTable;
