export default () => ({
  root: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 10,
    paddingTop: 20,
  },
  stickyHeader: {
    position: 'sticky',
    zIndex: 1100,
  },

  headerCell: {
    fontFamily: "'Roboto', sans-serif",
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.5)',
    fontSize: 14,
    fontWeight: 400,
    flex: 1,
  },
  headerCell0: {
    flexBasis: '30%',
  },
  headerCell1: {
    flexBasis: '12%',
  },
  headerCell2: {
    flexBasis: '15%',
    textAlign: 'center',
  },
  headerCell3: {
    flexBasis: '23%',
  },
  headerCell4: {
    flexBasis: '20%',
  },
  cellContent: {
    paddingLeft: 8,
    paddingRight: 8,
    boxSizing: 'border-box',
  },
  cellContentFirst: {
    paddingLeft: 20,
  },

  cellContentLast: {},
  actionCell: {
    textAlign: 'left',
    flex: 1,
  },
});
