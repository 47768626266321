import {
  CUSTOMER_DEFAULT_PAYMENT_METHOD_FILTER
} from '../FilterTypes'

export default class DXCSCustomerDefaultPaymentMethodFilter {

  static getConfig(intl, values, readOnly) {
    return {
      name: CUSTOMER_DEFAULT_PAYMENT_METHOD_FILTER,
      title: intl.formatMessage({id: 'customer.filter.defaultPaymentMethod'}),
      property: 'defaultPaymentMethod',
      type: CUSTOMER_DEFAULT_PAYMENT_METHOD_FILTER,
      selectedValues: values,
      clientSide: true,
      readOnly: readOnly ? readOnly : false
    }
  }

  constructor(values, propertyName) {
    this.values = values;
    this.property = propertyName;
  }

  isSatisfy(row) {
    let values = [];
    let satisfy = true;

    this.values.forEach(value => {
      if (value === 'exists') values.push('exists');
      if (value === 'not_exists') values.push('not_exists');
    });


    if (values.length < 2 &&
        values.indexOf('exists') !== -1 &&
        !row[this.property]) {
      satisfy = false
    }

    if (values.length < 2 &&
        values.indexOf('not_exists') !== -1 &&
        row[this.property]) {
      satisfy = false
    }

    return satisfy;
  }

  getLabel(intl) {
    let values = [];
    this.values.forEach(value => {
      switch (value) {
        case 'exists' : {
          values.push(intl.formatMessage({id: 'global.exists'}));
          break;
        }
        case 'not_exists' : {
          values.push(intl.formatMessage({id: 'global.notExists'}));
          break
        }
        default: {}
      }
    });

    return values.join(', ');
  }

}