import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import DXSelectFilter from '../DXSelectFilter/DXSelectFilter';
import DXFilterConfig from '../DXFilterConfig';

const propTypes = {
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  selected: [],
};

class DXProductSubTypesFilter extends React.Component {
  render() {
    const { selected, onChange, intl, equality, queryParamsByFilterName } = this.props;

    const config = DXProductSubTypesFilter.getConfig(intl, equality, queryParamsByFilterName);
    return (
      <DXSelectFilter
        selected={selected}
        title={config.title}
        values={config.values}
        onChange={onChange}
        multi={config.multi}
        initialLimit={9}
      />
    );
  }
}

DXProductSubTypesFilter.propTypes = propTypes;
DXProductSubTypesFilter.defaultProps = defaultProps;
DXProductSubTypesFilter.getLabel = (intl, selectedValues) => {
  return DXSelectFilter.getLabel(intl, '', selectedValues);
};
DXProductSubTypesFilter.applyToQueryParams = (queryParams, selectedValues) => {
  queryParams['subTypes'] = selectedValues;
  return queryParams;
};
DXProductSubTypesFilter.getConfig = (intl, selectedEquality, queryParamsByFilterName) => {
  let config = new DXFilterConfig({
    name: 'productSubTypes',
    selected: [],
    property: 'productSubTypes',
    title: intl.formatMessage({ id: 'filter.productSubTypes' }),
    disabled: false,
    equalityStatus: false,
    multi: true,
  });

  queryParamsByFilterName.subTypes.forEach((subType) => {
    config.addFilterValue(subType, subType);
  });

  return config;
};

export default injectIntl(DXProductSubTypesFilter);
