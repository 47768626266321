const styles = theme => ({
  topToolbar: {
    display: 'flex',
    flexDirection: 'row',
    height: 70,
  },
  appBar: {
    height: 70,
    zIndex: 9999,
    position: 'fixed',
    backgroundColor: '#ffffff'
  },
  hideDownMd: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
  },
  appWithoutLeftContent: {
    height: 70,
    zIndex: 9999,
    position: 'fixed',
    backgroundColor: '#ffffff'
  },
  logoContainer: {
    cursor: 'pointer'
  },
  logo: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    height: 30,
    color: '#ffffff'
  },
  right: {
    marginLeft: 'auto',
    minWidth: 80
  }
});

export default styles;