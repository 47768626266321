const validation = {
  password: {
    presence: {
      message: '^Please enter a password'
    }
  },
  confirmPassword: {
    presence: {
      message: '^Please enter confirm password'
    },
    equality: {
      attribute: "password",
      message: "^Confirm password is not equal to password",
    }
  },
};

export default validation;
