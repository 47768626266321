import React from 'react';
import { COLORS } from '../constants/colors';

const CalcButton3 = (props) => {
  const { color = COLORS.blackTintedBlue, ...other } = props;
  return (
    <svg width="23" height="33" viewBox="0 0 23 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <path
        d="M0.965626 33H3.73906C5.84844 33 7.04473 31.7109 7.04473 29.4355V29.4258C7.04473 27.2188 5.83379 25.9541 3.73906 25.9541H0.965626V33ZM2.44024 31.7842V27.1699H3.50469C4.78399 27.1699 5.54082 28 5.54082 29.4502V29.46C5.54082 30.9639 4.8084 31.7842 3.50469 31.7842H2.44024ZM10.1461 33H14.8141V31.7842H11.6207V30.002H14.6334V28.8691H11.6207V27.1699H14.8141V25.9541H10.1461V33ZM18.0668 33H19.5414V30.2266H22.4076V29.0596H19.5414V27.1699H22.6811V25.9541H18.0668V33Z"
        fill={color}
      />
      <path
        d="M12.8721 18.293C16.3999 18.293 18.9878 16.1201 18.9878 13.1782V13.1538C18.9878 10.6514 17.2422 9.05225 14.6787 8.83252V8.78369C16.876 8.31982 18.4141 6.83057 18.4141 4.65771V4.6333C18.4141 1.95996 16.2046 0.0922852 12.8477 0.0922852C9.55176 0.0922852 7.28125 2.00879 7.00049 4.87744L6.98828 4.99951H9.1001L9.1123 4.87744C9.29541 3.09521 10.7725 1.99658 12.8477 1.99658C14.9961 1.99658 16.2046 3.05859 16.2046 4.90186V4.92627C16.2046 6.68408 14.7397 7.97803 12.6401 7.97803H10.5283V9.8335H12.7378C15.2036 9.8335 16.7539 11.042 16.7539 13.2026V13.2271C16.7539 15.0947 15.1792 16.3887 12.8721 16.3887C10.5283 16.3887 8.9292 15.1924 8.7583 13.459L8.74609 13.3369H6.63428L6.64648 13.4834C6.87842 16.2666 9.23438 18.293 12.8721 18.293Z"
        fill={color}
      />
    </svg>
  );
};

export default CalcButton3;
