import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { BORDER_RADIUS_BUTTON, TRANSITION_MAIN } from '../../constants/globals';
import { ButtonGroup } from '@material-ui/core';
import classNames from 'classnames';
import { COLORS } from '../../constants';
import { FONT_FAMILY, FONT_SIZES } from '../../constants/fonts';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(() => ({
  root: {
    fontFamily: FONT_FAMILY,
    fontSize: FONT_SIZES.smallText,
    fontWeight: '700',
    textTransform: 'none',
    display: 'flex',
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 20,
    paddingRight: 20,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: BORDER_RADIUS_BUTTON,
    textDecoration: 'none !important',
    boxSizing: 'border-box',
  },
  disabled: {
    cursor: 'not-allowed',
    opacity: 0.2,
  },
  icon: {
    marginRight: 10,
  },
  buttonGroup: {},
}));

const propsTypes = {
  buttons: PropTypes.array,
};

const FewButtonsComponent = (props) => {
  const { buttons } = props;
  const classes = useStyles();

  const useVariants = makeStyles(() => {
    const blue = {
      backgroundColor: COLORS.primary,
      color: COLORS.white,
      transition: TRANSITION_MAIN,
      '&:hover': {
        backgroundColor: COLORS.primaryHovered,
      },
    };

    return {
      smallBlue: {
        ...blue,
        height: 'auto',
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 15,
        paddingRight: 15,
        '&:visited': {
          ...blue,
        },
      },
      smallBlueLabel: {
        fontFamily: FONT_FAMILY,
        fontSize: FONT_SIZES.text,
        fontWeight: '700',
        color: COLORS.white,
        textTransform: 'none',
      },
      smallGrey: {
        backgroundColor: COLORS.backgroundLight,
        color: COLORS.grey,
        transition: TRANSITION_MAIN,
        height: 'auto',
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 15,
        paddingRight: 15,
        '&:visited': {
          backgroundColor: COLORS.backgroundLight,
          color: COLORS.grey,
          transition: TRANSITION_MAIN,
        },
      },
      smallGreyLabel: {
        fontFamily: FONT_FAMILY,
        fontSize: FONT_SIZES.text,
        fontWeight: '700',
        color: COLORS.grey,
        textTransform: 'none',
      },
    };
  });

  const variantClasses = useVariants();

  const drawButtons = () => {
    return buttons.map((button, index) => {
      const { label, action, isActive } = button;

      return (
        <Button
          key={`btn_${index}`}
          onClick={action}
          className={classNames([classes.root, isActive ? variantClasses.smallBlue : variantClasses.smallGrey])}
          classes={{
            text: classNames([isActive ? variantClasses.smallBlueLabel : variantClasses.smallGreyLabel]),
            root: classNames([classes.root, isActive ? variantClasses.smallBlue : variantClasses.smallGrey]),
          }}>
          {label}
        </Button>
      );
    });
  };

  return (
    <ButtonGroup
      classes={{
        root: classNames([classes.buttonGroup]),
      }}>
      {drawButtons()}
    </ButtonGroup>
  );
};

FewButtonsComponent.propTypes = propsTypes;

export default FewButtonsComponent;
