const validation = {
  password: {
    presence: {
      message: '^validation.newPassword.enterPassword'
    }
  },
  passwordRepeat: {
    presence: {
      message: '^validation.newPassword.enterConfirmPassword'
    },
    equality: {
      attribute: "password",
      message: "^validation.newPassword.confirmIsNotEqualToPassword",
    }
  },
};

export default validation;
