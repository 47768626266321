const API_END_POINTS = {
  // Authentication
  LOGIN: 'auth',

  // Producers
  GET_PRODUCER: 'producers/{id}',
  GET_DISTINCT_SHIPPING_DATES: 'producers/distinct-shipping-dates',
  PRODUCER_PATCH_PROFILE: 'producers/profile',
  PRODUCER_GET_TAX_RATES: 'producers/tax-rates',
  PRODUCER_CREATE: 'producers',
  ANONYMOUS_PREVIEW_PRODUCER: 'anonymous/previewProducer/link',
  GENERATE_ANONYMOUS_WHOLESALE_LINK: 'anonymous/wholesale/link',

  // Suppliers
  SUPPLIER_CREATE: 'suppliers',
  GET_SUPPLIERS: 'suppliers',
  GET_SINGLE_SUPPLIER: 'suppliers/{sid}',
  SUPPLIER_PATCH_PROFILE: 'suppliers/{sid}',
  MAKE_SUPPLIER_TOKEN: 'suppliers/token',
  SUPPLIER_PRODUCTS_SEARCH: 'products/search/{sid}',
  // Suppliers draft
  SUPPLIER_ADD_CUSTOMER: 'customers/fromProducer',
  SUPPLIER_CHANGE_ORDERING_SETTINGS: 'producers/supplier-settings',
  SUPPLIER_DRAFT_ORDER_GET_ALL: 'suppliers/drafts',
  SUPPLIER_DRAFT_ORDER_GET_ONE: 'suppliers/drafts/{dr}',
  SUPPLIER_DRAFT_ORDER_CREATE: 'suppliers/drafts',
  SUPPLIER_DRAFT_ORDER_ADD_CUSTOMER: 'suppliers/drafts/{dr}/customer',
  SUPPLIER_DRAFT_ORDER_REMOVE_CUSTOMER: 'suppliers/drafts/{dr}/customer',
  SUPPLIER_DRAFT_ORDER_ADD_VARIANT: 'suppliers/drafts/{dr}/add',
  SUPPLIER_DRAFT_ORDER_ADD_CUSTOM_LINE_ITEM: 'suppliers/drafts/{dr}/custom',
  SUPPLIER_DRAFT_ORDER_REMOVE_LINE_ITEM: 'suppliers/drafts/{dr}/{it}',
  SUPPLIER_DRAFT_ORDER_UPDATE_LINE_ITEM: 'suppliers/drafts/{dr}/update/{it}',
  SUPPLIER_DRAFT_ORDER_SET_SHIPPING: 'suppliers/drafts/{dr}/shipping',
  SUPPLIER_DRAFT_ORDER_ADD_NOTE: 'suppliers/drafts/{dr}/note',
  SUPPLIER_DRAFT_ORDER_COMPLETE: 'suppliers/drafts/{dr}/complete',
  SUPPLIER_DRAFT_BULK_REMOVE: 'suppliers/drafts/bulk-remove',
  SUPPLIER_DRAFT_ORDER_ADD_DIRECT_PAYMENT_METHOD: 'suppliers/drafts/{dr}/payment/direct/{md}',
  SUPPLIER_DRAFT_ORDER_ADD_INVOICE_PAYMENT_METHOD: 'suppliers/drafts/{dr}/payment/invoice',
  SUPPLIER_DRAFT_ORDER_ADD_PREPURCHASE_PAYMENT_METHOD: 'suppliers/drafts/{dr}/payment/prepurchase',
  SUPPLIER_DRAFT_PUT_DELIVERY_COMPANY: 'suppliers/drafts/{dr}/delivery-company',
  SUPPLIER_DRAFT_DELETE_DELIVERY_COMPANY: 'suppliers/drafts/{dr}/delivery-company',
  SUPPLIER_DRAFT_ADD_DIRECT_DISCOUNT: 'suppliers/drafts/{dr}/direct-discount',
  SUPPLIER_DRAFT_DELETE_DISCOUNT: 'suppliers/drafts/{dr}/discount',
  // Supplier orders
  SUPPLIER_ORDER_GET_ALL: 'suppliers/orders-es',
  SUPPLIER_ORDER_GET_ONE: 'suppliers/orders/{or}',
  SUPPLIER_ORDER_REORDER: 'suppliers/orders/{or}/reorder',
  SUPPLIER_ORDER_ADD_NOTE: 'suppliers/orders/{or}/note',
  SUPPLIER_ORDER_CANCEL: 'suppliers/orders/{or}/cancel',
  SUPPLIER_ORDER_BULK_CANCEL: 'suppliers/orders/bulk-cancel',
  SUPPLIER_ORDER_APPROVE: 'suppliers/orders/{or}/received',
  SUPPLIER_ORDER_RECEIVED: 'suppliers/orders/{or}/received',
  SUPPLIER_ORDER_BULK_RECEIVED: 'suppliers/orders/bulk-received',
  SUPPLIER_ORDER_REFUND: 'suppliers/orders/{or}/refund',
  SUPPLIER_ORDER_SET_SHIPPING: 'suppliers/orders/{or}/shipping',
  SUPPLIER_ORDER_PUT_DELIVERY_COMPANY: 'suppliers/orders/{or}/delivery-company',
  SUPPLIER_ORDER_DELETE_DELIVERY_COMPANY: 'suppliers/orders/{or}/delivery-company',
  SUPPLIER_ORDER_ADD_VARIANT: 'suppliers/orders/{or}/add',
  SUPPLIER_ORDER_UPDATE_LINE_ITEM: 'suppliers/orders/{or}/update/{it}',
  SUPPLIER_ORDER_REMOVE_LINE_ITEM: 'suppliers/orders/{or}/{it}',
  SUPPLIER_ORDER_ADD_INVOICE_PAYMENT_METHOD: 'suppliers/orders/{or}/payment/invoice',
  SUPPLIER_ORDER_ADD_DIRECT_PAYMENT_METHOD: 'suppliers/orders/{or}/payment/direct/{md}',
  SUPPLIER_ORDER_GET_INVOICE: 'suppliers/invoice/{hash}',
  // Not uses order api
  SUPPLIER_ORDER_ADD_CUSTOM_LINE_ITEM: 'suppliers/orders/{or}/custom',
  SUPPLIER_ORDER_BULK_PRINT: 'suppliers/orders/invoices-request',
  SUPPLIER_ORDER_PACKING_LIST_BULK_PRINT: 'suppliers/orders/packing-list/pdf',
  SUPPLIER_ORDER_RESEND_EMAIL: 'suppliers/orders/{or}/resend-email',
  SUPPLIER_ORDER_SEND_INVOICE_UPDATED_EMAIL: 'suppliers/orders/{or}/send-order-invoice-updated-email',
  SUPPLIER_ORDER_BOOK: 'suppliers/orders/{or}/book',
  SUPPLIER_ORDER_BOOK_PAYMENT: 'suppliers/orders/{or}/book-payment',
  SUPPLIER_ORDER_ADD_PREPURCHASE_PAYMENT_METHOD: 'suppliers/orders/{or}/payment/prepurchase',
  SUPPLIER_ORDER_ADD_CUSTOMER: 'suppliers/orders/{or}/customer',
  SUPPLIER_ORDER_REMOVE_CUSTOMER: 'suppliers/orders/{or}/customer',
  SUPPLIER_ORDER_ADD_DIRECT_DISCOUNT: 'suppliers/orders/{or}/direct-discount',
  SUPPLIER_ORDER_DELETE_DISCOUNT: 'suppliers/orders/{or}/discount',
  SUPPLIER_ORDER_MARK_APPROVED: 'suppliers/orders/{or}/mark-approved',

  // Buyers
  GET_BUYER: 'buyers/{id}',

  // Producer payments (Stripe)
  PRODUCER_PAYMENTS_SETTINGS_GET: 'payments/settings',
  PRODUCER_PAYMENTS_SETTINGS_ACCEPT_TOS: 'payments/settings/accept-tos',
  PRODUCER_PAYMENTS_SETTINGS_EDIT_ACCOUNT: 'payments/settings/account',
  PRODUCER_PAYMENTS_SETTINGS_ADD_BANK_ACCOUNT: 'payments/settings/bank-accounts',
  PRODUCER_STRIPE_START_ON_BOARD: 'payments/stripe/onboard',

  // Producer payments person (Stripe)
  PRODUCER_STRIPE_PERSON_GET_ALL: 'payments/settings/persons',
  PRODUCER_STRIPE_PERSON_GET_ONE: 'payments/settings/persons/{id}',
  PRODUCER_STRIPE_PERSON_CREATE: 'payments/settings/persons',
  PRODUCER_STRIPE_PERSON_PATCH: 'payments/settings/persons/{id}',
  PRODUCER_STRIPE_PERSON_DELETE: 'payments/settings/persons/{id}',

  // Producer locations
  PRODUCER_LOCATIONS_GET_LIST: 'locations',
  PRODUCER_LOCATIONS_CREATE: 'locations',
  PRODUCER_LOCATIONS_GET_ONE: 'locations/{id}',
  PRODUCER_LOCATIONS_PATCH: 'locations/{id}',
  PRODUCER_LOCATIONS_DELETE: 'locations/{id}',
  PRODUCER_APPROVED_LOCATIONS_GET: 'producerLocations',
  PRODUCER_APPROVED_LOCATIONS_CREATE: 'producerLocations',
  PRODUCER_APPROVED_LOCATIONS_DELETE: 'producerLocations/{id}',

  // Invoices
  PRODUCER_INVOICES_GET_ALL: 'invoices',
  PRODUCER_INVOICES_GET_ONE: 'invoices/{id}',
  PRODUCER_INVOICES_GET_PDF: 'producer/invoices/{id}',
  PRODUCER_INVOICES_PAY: 'invoices/{id}/pay',
  PRODUCER_INVOICES_ADD_ACH_PLAID: 'invoices/payment-methods/ach-plaid',

  // Producer Quick Books
  QUICK_BOOKS_GET_AUTH_URL: 'quickbooks/oauth2/auth-url',
  QUICK_BOOKS_EXCHANGE_AUTH_CODE: 'quickbooks/oauth2/exchange-auth-code',
  QUICK_BOOKS_SYNC_STATUS: 'quickbooks/sync/status',
  QUICK_BOOKS_BOOK_ORDERS_SETTINGS: 'producers/book-orders-settings',
  QUICK_BOOKS_GET_ACCOUNTS: 'quickbooks/quickbooks-accounts',
  QUICK_BOOKS_MAP_ACCOUNT: 'quickbooks/accounts',
  QUICK_BOOKS_GET_ACCOUNTS_MAPPING: 'quickbooks/accounts',
  QUICK_BOOKS_ORDER_SETTINGS: 'producers/book-orders-settings',
  QUICK_BOOKS_CREATE_ORDER_PREFIX: 'quickbooks/config/orderPrefix',
  QUICK_BOOKS_GET_ORDER_PREFIX: 'quickbooks/config/orderPrefix',
  QUICK_BOOKS_DELETE_ORDER_PREFIX: 'quickbooks/config/orderPrefix',
  QUICK_BOOKS_SET_SINGLE_ORDER_AS_BOOKED: 'quickbooks/orders/{id}/markBooked',
  QUICK_BOOKS_SET_MULTIPLY_ORDERS_AS_BOOKED: 'quickbooks/orders/markBooked/bulk',
  QUICK_BOOKS_SET_SINGLE_ORDER_AS_PAID: 'orders/{id}/paid',
  QUICK_BOOKS_SET_MULTIPLY_ORDERS_AS_PAID: 'orders/bulk-paid',
  QUICK_BOOKS_GET_CUSTOMERS: 'quickbooks/customers',
  QUICK_BOOKS_ADD_CUSTOMERS_TO_FF: 'quickbooks/customers/attach',

  // Producer delivery company settings
  PATCH_DELIVERY_COMPANY_SETTINGS: 'producers/delivery-company-settings',

  // Producer Shipping
  PRODUCER_SHIPPING_GET: 'shipping',
  PRODUCER_SHIPPING_DAYS_CREATE: 'shipping/days',
  PRODUCER_SHIPPING_DAYS_PATCH: 'shipping/days/{id}',
  PRODUCER_SHIPPING_DAYS_DELETE: 'shipping/days/{id}',
  PRODUCER_SHIPPING_RATES_CREATE: 'shipping/rates',
  PRODUCER_SHIPPING_RATES_PATCH: 'shipping/rates/{id}',
  PRODUCER_SHIPPING_RATES_DELETE: 'shipping/rates/{id}',
  PRODUCER_SHIPPING_DAYS_ACTIVE_PATCH: 'shipping/days/{id}/locationActive',

  // Payouts
  PRODUCER_PAYOUTS_GET_ALL: 'reports/payouts',
  PRODUCER_PAYOUTS_GET_ONE: 'reports/payouts/{id}',

  // Draft
  DRAFT_ORDER_GET_ALL: 'drafts',
  DRAFT_ORDER_GET_ONE: 'drafts/{dr}',
  DRAFT_ORDER_CREATE: 'drafts',
  DRAFT_ORDER_ADD_CUSTOMER: 'drafts/{dr}/customer',
  DRAFT_ORDER_REMOVE_CUSTOMER: 'drafts/{dr}/customer',
  DRAFT_ORDER_ADD_VARIANT: 'drafts/{dr}/add',
  DRAFT_ORDER_ADD_CUSTOM_LINE_ITEM: 'drafts/{dr}/custom',
  DRAFT_ORDER_REMOVE_LINE_ITEM: 'drafts/{dr}/{it}',
  DRAFT_ORDER_UPDATE_LINE_ITEM: 'drafts/{dr}/update/{it}',
  DRAFT_ORDER_SET_SHIPPING: 'drafts/{dr}/{type}',
  DRAFT_ORDER_ADD_NOTE: 'drafts/{dr}/note',
  DRAFT_ORDER_COMPLETE: 'drafts/{dr}/complete',
  DRAFT_BULK_REMOVE: 'drafts/bulk-remove',
  DRAFT_ORDER_ADD_DIRECT_PAYMENT_METHOD: 'drafts/{dr}/payment/direct/{md}',
  DRAFT_ORDER_ADD_INVOICE_PAYMENT_METHOD: 'drafts/{dr}/payment/invoice',
  DRAFT_ORDER_ADD_PREPURCHASE_PAYMENT_METHOD: 'drafts/{dr}/payment/prepurchase',
  DRAFT_PUT_DELIVERY_COMPANY: 'drafts/{dr}/delivery-company',
  DRAFT_DELETE_DELIVERY_COMPANY: 'drafts/{dr}/delivery-company',
  DRAFT_ADD_DIRECT_DISCOUNT: 'drafts/{dr}/direct-discount',
  DRAFT_DELETE_DISCOUNT: 'drafts/{dr}/discount',

  // Orders
  ORDER_GET_ALL: 'orders',
  ORDER_GET_ALL_FOR_SUPPLIERS: 'orders-es',
  ORDER_GET_ONE: 'orders/{or}',
  ORDER_ADD_VARIANT: 'orders/{or}/add',
  ORDER_ADD_CUSTOM_LINE_ITEM: 'orders/{or}/custom',
  ORDER_REMOVE_LINE_ITEM: 'orders/{or}/{it}',
  ORDER_UPDATE_LINE_ITEM: 'orders/{or}/update/{it}',
  ORDER_ADD_NOTE: 'orders/{or}/note',
  ORDER_SET_SHIPPING: 'orders/{or}/{type}',
  ORDER_CANCEL: 'orders/{or}/cancel',
  ORDER_BULK_CANCEL: 'orders/bulk-cancel',
  ORDER_BULK_RECEIVED: 'orders/bulk-received',
  ORDER_BULK_PRINT: 'orders/invoices-request',
  ORDER_PACKING_LIST_BULK_PRINT: 'orders/packing-list/pdf',
  ORDER_RESEND_EMAIL: 'orders/{or}/resend-email',
  ORDER_SEND_INVOICE_UPDATED_EMAIL: 'orders/{or}/send-order-invoice-updated-email',
  ORDER_REORDER: 'orders/{or}/reorder',
  ORDER_REFUND: 'orders/{or}/refund',
  ORDER_BOOK: 'orders/{or}/book',
  ORDER_BOOK_PAYMENT: 'orders/{or}/book-payment',
  ORDER_ADD_DIRECT_PAYMENT_METHOD: 'orders/{or}/payment/direct/{md}',
  ORDER_ADD_INVOICE_PAYMENT_METHOD: 'orders/{or}/payment/invoice',
  ORDER_ADD_PREPURCHASE_PAYMENT_METHOD: 'orders/{or}/payment/prepurchase',
  ORDER_PUT_DELIVERY_COMPANY: 'orders/{or}/delivery-company',
  ORDER_DELETE_DELIVERY_COMPANY: 'orders/{or}/delivery-company',
  ORDER_ADD_CUSTOMER: 'orders/{or}/customer',
  ORDER_REMOVE_CUSTOMER: 'orders/{or}/customer',
  ORDER_ADD_DIRECT_DISCOUNT: 'orders/{or}/direct-discount',
  ORDER_DELETE_DISCOUNT: 'orders/{or}/discount',
  ORDER_APPROVE: 'orders/{or}/received',
  ORDER_RECEIVED: 'orders/{or}/received',
  ORDER_MARK_APPROVED: 'orders/{or}/mark-approved',
  ORDER_GET_INVOICE: 'invoice/{hash}',

  // Customer
  CUSTOMERS_RETRIEVE: 'customers',
  CUSTOMER_CREATE: 'customers',
  CUSTOMER_PATCH: 'customers/{cr}',
  CUSTOMER_RETRIEVE: 'customers/{cr}',
  CUSTOMER_ADD_ACCOUNT: 'customers/{cr}/accounts',
  CUSTOMER_PATCH_ACCOUNT: 'customers/{cr}/accounts/{ac}',
  CUSTOMER_DELETE_ACCOUNT: 'customers/{cr}/accounts/{ac}',
  CUSTOMER_UPDATE: 'customers/{cr}',
  CUSTOMER_ADD_ADDRESS: 'customers/{cr}/addresses/{at}',
  CUSTOMER_PATCH_ADDRESS: 'customers/{cr}/addresses/{ad}',
  CUSTOMER_DELETE_ADDRESS: 'customers/{cr}/addresses/{ad}',
  CUSTOMER_INVITE_RESET: 'customers/{cr}/invite-reset',
  CUSTOMER_INVITE_RESET_ACCOUNT: 'customers/{cr}/invite-reset/{ac}',
  CUSTOMER_APPROVE: 'customers/{cr}/approve',
  CUSTOMER_UNAPPROVE: 'customers/{cr}/unapprove',
  GET_COMBINED_CUSTOMERS: 'customers-combined',
  CUSTOMER_GENERATE_QR_CODE: 'customers/qr/generate',

  // Payments
  CUSTOMER_ADD_PAYMENT_METHOD: 'customers/{cr}/payment-methods/add/{method}',
  CUSTOMER_MAKE_PAYMENT_METHOD_DEFAULT: 'customers/{cr}/payment-methods/{paymentMethodId}/default',
  CUSTOMER_DELETE_PAYMENT_METHOD: 'customers/{cr}/payment-methods/{paymentMethodId}',

  // Subscriptions
  GET_ALL_SUBSCRIPTIONS: 'subscriptions',
  GET_SUBSCRIPTION_BY_ID: 'subscriptions/{sbs}',
  REMOVE_SUBSCRIPTION_BY_ID: 'subscriptions/{sbs}',
  CREATE_NEW_SUBSCRIPTION: 'subscriptions',
  CHANGE_SUBSCRIPTION_BY_ID: 'subscriptions/{sbs}',
  SUBSCRIPTIONS_CREATE_NEXT_ORDER: 'subscriptions/{sbs}/orders',
  GET_SUBSCRIPTIONS_SCHEDULE: 'subscriptions/{sbs}/schedules',
  CHANGE_SUBSCRIPTIONS_SCHEDULE_DATE: 'subscriptions/{sbs}/schedules/change-date',
  MOVE_SERIES_SUBSCRIPTIONS_SCHEDULE_DATES: 'subscriptions/{sbs}/schedules/move-date',
  REMOVE_SINGLE_SUBSCRIPTIONS_SCHEDULE_DATE: 'subscriptions/{sbs}/schedules/remove-date',
  CHANGE_SUBSCRIPTIONS_SCHEDULE_DATE_FOR_LINE_ITEM: 'subscriptions/{sbs}/schedules/variants/{it}/change-date',
  MOVE_SERIES_SUBSCRIPTIONS_SCHEDULE_DATE_FOR_LINE_ITEM: 'subscriptions/{sbs}/schedules/variants/{it}/move-date',
  REMOVE_SINGLE_SUBSCRIPTIONS_SCHEDULE_DATE_FOR_LINE_ITEM: 'subscriptions/{sbs}/schedules/remove-date',
  SUBSCRIPTIONS_BULK_SET_TAGS: 'subscriptions/tags',
  MOVE_SCHEDULE_DATE_FOR_ALL_SUBSCRIPTIONS: 'subscriptions/schedules/move-date',
  MOVE_VARIANT_SCHEDULE_DATE_FOR_ALL_SUBSCRIPTIONS: 'subscriptions/schedules/variants/{variantId}/move-date',
  GET_ALL_SUBSCRIPTIONS_GROUPS: 'subscriptions/grouped/typeSubTypeVariant',
  SUBSCRIPTIONS_REPORTS_SCHEDULE_BY_CUSTOMER: 'subscriptions/schedules/grouped-by-customers',
  SUBSCRIPTIONS_REPORTS_SCHEDULE_BY_LINE_ITEM: 'subscriptions/schedules/grouped-by-line-items',

  // Member service
  GET_MEMBER_LINE_ITEMS_BASED_ON_SCHEDULE: 'memberService/lineItemsList',

  CUSTOMER_ADD_EMAIL_SUBSCRIPTION: 'customers/{cr}/email-subscriptions',
  CUSTOMER_PATCH_EMAIL_SUBSCRIPTION: 'customers/{cr}/email-subscriptions/{sbs}',
  CUSTOMER_DELETE_EMAIL_SUBSCRIPTION: 'customers/{cr}/email-subscriptions/{sbs}',

  // Notifications
  GET_NOTIFICATIONS: 'notifications/{ac}',
  GET_NOTIFICATIONS_BY_TYPE: 'notifications/{ac}/{type}',
  ADD_NOTIFICATION: 'notifications/{ac}',
  EDIT_NOTIFICATION: 'notifications/{ac}/{type}',
  REMOVE_NOTIFICATIONS: 'notifications/{ac}',

  // Products
  PRODUCT_GET_ONE: 'products/{pr}',
  PRODUCT_CREATE: 'products',
  PRODUCT_EDIT: 'products/{pr}',
  PRODUCT_REMOVE: 'products/{pr}',
  PRODUCT_CREATE_VARIANT: 'products/{pr}/variants',
  PRODUCT_EDIT_VARIANT: 'products/{pr}/variants/{va}',
  PRODUCT_REMOVE_VARIANT: 'products/{pr}/variants/{va}',
  PRODUCT_BULK_STATUSES: 'products/bulk-status/{status}',
  PRODUCT_OFFERED_MARKET_PRESETS_GET_LOCATIONS: 'offeredMarketPresets/availableLocations',
  PRODUCT_OFFERED_MARKET_PRESETS_SET_LOCATIONS: 'offeredMarketPresets',
  PRODUCT_GET_OFFERED_MARKET_PRESETS: 'offeredMarketPresets',
  PRODUCT_CHANGE_OFFERED_MARKETS_PRESETS_LIST: 'products/{pr}',
  PRODUCT_UPLOAD_PRODUCT_LIST_CSV: 'products/bulk-upload',

  PRODUCT_CHANGE_OFFERED: 'products/{pr}/variants/{va}',
  PRODUCT_PRICES_GET_BASE_LOCATIONS: 'products/prices/locations/base',
  PRODUCT_PRICES_GET_AVAILABLE_LOCATIONS: 'products/prices/locations/available',
  PRODUCT_PRICES_SET_PRICES: 'products/prices/locations',
  PRODUCT_GET_PRICES_LOCATIONS: 'products/prices/locations/unique',
  PRODUCT_SET_PRICES_BY_LOCATIONS: 'products/prices/locations',
  PRODUCT_SET_INVENTORY_TO_VARIANTS: 'products/{pr}/variants',
  PRODUCT_CHANGE_INVENTORY_TO_SINGLE_VARIANT: 'products/{pr}/variants/{va}',

  // Search
  PRODUCT_GET_ALL: 'products-es',
  PRODUCTS_SEARCH: 'products/search',
  CUSTOMERS_SEARCH: 'customers/search',

  // Unique products
  CUSTOMER_UNIQUE_PRODUCTS_GET: 'producers/unique-products',
  CUSTOMER_UNIQUE_PRODUCTS_GET_BY_CUSTOMER: 'customers/{cid}/unique-products',
  CUSTOMER_UNIQUE_PRODUCTS_ADD: 'customers/{cid}/unique-products',
  CUSTOMER_UNIQUE_PRODUCTS_DELETE: 'customers/{cid}/unique-products/{vid}',

  // Product shared properties
  PRODUCT_SHARED_PROPERTIES_GET_ALL: 'products/shared/{type}',
  PRODUCT_SHARED_PROPERTY_CREATE: 'products/shared/{type}',
  PRODUCT_SHARED_PROPERTY_UPDATE: 'products/shared/{type}/{id}',
  PRODUCT_SHARED_PROPERTY_DELETE: 'products/shared/{type}/{id}',
  PRODUCT_SHARED_PROPERTY_REPLACE: 'products/shared/replace/{type}/{id}',
  PRODUCT_SHARED_PROPERTIES_REORDER_UNITS: 'products/shared/unit/reorder',

  // Product collections
  PRODUCT_COLLECTIONS_GET_ALL_TAGS: 'collections/tags',
  PRODUCT_COLLECTIONS_GET_AVAILABLE_TAGS: 'collections/available-tags',
  PRODUCT_COLLECTIONS_GET_PRODUCTS: 'collections/{id}',
  PRODUCT_COLLECTIONS_CREATE: 'collections',
  PRODUCT_COLLECTIONS_PATCH: 'collections/{id}',
  PRODUCT_COLLECTIONS_DELETE: 'collections/{id}',

  // Tabs
  TAB_CREATE: 'producers/admin-tab',
  TAB_PATCH: 'producers/admin-tab/{tab}',
  TAB_DELETE: 'producers/admin-tab/{tab}',

  // Reports
  REPORT_SALES_ITEMS: 'reports/sales/items',
  REPORT_SALES_ORDERS: 'reports/sales/orders',
  REPORT_SALES_ITEMS_PER_ACCOUNT: 'reports/sales/items-per-account',
  REPORT_SALES_ITEMS_PER_CSA_ACCOUNT: 'reports/sales/items-per-csa-account',
  REPORT_SALES_COMPANY_PER_MONTH: 'reports/sales/company-per-month',
  REPORT_SALES_ORDERS_WITH_LINE_ITEMS: 'reports/sales/orders-with-line-items',
  REPORT_SALES_ITEMS_GROUPED_BY_PRODUCTS: 'reports/sales/items-grouped-by-products',
  REPORT_CSA: 'reports/sales/prepurchased-items',
  REPORT_QUICK_BOOKS_RECONCILE: 'reports/reconcile/orders-and-quickbooks',
  REPORT_CSA_SUMMARY: 'reports/sales/prepurchased-items-grouped-by-products',
  REPORT_CSA_LINE_ITEMS: 'reports/csa/items-grouped-by-variants',
  REPORT_ORDERS_GROUPED_BY_LOCATION: 'reports/sales/orders-grouped-by-location',

  // Discounts
  DISCOUNT_GET_ALL: 'discounts',
  DISCOUNT_GET_ONE: 'discounts/{id}',
  DISCOUNT_CREATE: 'discounts',
  DISCOUNT_PATCH: 'discounts/{id}',
  DISCOUNT_DELETE: 'discounts/{id}',
  DISCOUNT_DELETE_VARIANT: 'discounts/{id}/variants/{vid}',
  DISCOUNT_DELETE_CUSTOMER: 'discounts/{id}/customers/{cid}',

  // Pre purchases
  PRE_PURCHASE_GET_ALL: 'prepurchases',
  PRE_PURCHASE_GET_ONE: 'prepurchases/{id}',
  PRE_PURCHASE_CREATE: 'prepurchases',
  PRE_PURCHASE_PATCH: 'prepurchases/{id}',
  PRE_PURCHASE_REMOVE: 'prepurchases/{id}',
  PRE_PURCHASE_REMOVE_FROM_CUSTOMER: 'prepurchases/{id}/customers/{cr}',
  PRE_PURCHASE_REMOVE_FROM_VARIANT: 'prepurchases/{id}/variants/{vr}',
  PRE_PURCHASE_CREATE_FROM_CSA: 'prepurchases/{id}/subscription/orders',
  PRE_PURCHASE_GET_TAGS: 'prepurchases/{id}/tags',
  PRE_PURCHASE_ADD_TAG: 'prepurchases/{id}/tags',
  PRE_PURCHASE_DELETE_TAG: 'prepurchases/{id}/tags/{tag}',
  PRE_PURCHASE_BULK_SET_NEXT_DATE: 'prepurchases/nextDate',
  PRE_PURCHASE_BULK_SET_TAGS: 'prepurchases/tags',

  // Producer accounts
  PRODUCER_ACCOUNTS_GET_ALL: 'producers/accounts',
  PRODUCER_ACCOUNTS_GET_ONE: 'producers/accounts/{id}',
  PRODUCER_ACCOUNTS_CREATE: 'producers/accounts',
  PRODUCER_ACCOUNTS_PATCH: 'producers/accounts/{id}',
  PRODUCER_ACCOUNTS_DELETE: 'producers/accounts/{id}',
  PRODUCER_ACCOUNTS_VALIDATE_PHONE: 'producers/accounts/validatePhone/{ph}',

  // Imports fields
  PRODUCER_IMPORT_FIELDS: 'fields/import',

  // Suppliers api
  SUPPLIER_PRODUCT_BULK_UPDATE_VARIANTS: 'products/{id}/bulkVariants',
};

export default API_END_POINTS;
