import {
  OPEN_SUPPLIER_DRAWER,
  CLOSE_SUPPLIER_DRAWER,
  TOGGLE_SUPPLIER_DRAWER,
  TOGGLE_SUPPLIER_POPOVER,
  SET_SUPPLIER,
} from '../constants';
import MinimalError from '../../scenes/SupplierScene/entities/minimalError';
import KeyboardEntity from '../../scenes/SupplierScene/entities/keyboardEntity';
import {
  REPORT_SUPPLIER_ERROR,
  SET_KEYBOARD_ITEM_TO_EDIT,
  SET_QUANTITY_OVERWRITE,
  UPDATE_KEYBOARD_ITEM,
} from '../constants/supplier';
import Producer from '../../entities/Producer';

const initialState = {
  drawer: false,
  error: new MinimalError({}),
  keyboardObj: new KeyboardEntity({}),
  overwrite: false,
  popoverStep: null,
  object: new Producer({}),
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_SUPPLIER_DRAWER: {
      return {
        ...state,
        drawer: true,
      };
    }
    case CLOSE_SUPPLIER_DRAWER: {
      return {
        ...state,
        drawer: false,
      };
    }
    case TOGGLE_SUPPLIER_DRAWER: {
      return {
        ...state,
        drawer: !state.drawer,
      };
    }
    case REPORT_SUPPLIER_ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }
    case SET_KEYBOARD_ITEM_TO_EDIT: {
      return {
        ...state,
        keyboardObj: new KeyboardEntity(action.keyboardObj),
      };
    }
    case UPDATE_KEYBOARD_ITEM: {
      return {
        ...state,
        keyboardObj: {
          ...state.keyboardObj,
          ...action.payload,
        },
      };
    }
    case SET_SUPPLIER: {
      return {
        ...state,
        object: new Producer(action.payload),
      };
    }
    case SET_QUANTITY_OVERWRITE: {
      return {
        ...state,
        overwrite: action.isOverwrite,
      };
    }
    case TOGGLE_SUPPLIER_POPOVER: {
      return {
        ...state,
        popoverStep: action.popoverStep,
      };
    }
    default:
      return state;
  }
};

export default reducer;
