const validation = {
  rule: {
    presence: {
      message: '^validation.required'
    }
  },
  fee: {
    numericality: {
      onlyInteger: false,
    },
    presence: {
      message: '^validation.required'
    }
  },
  min: {
    numericality: {
      onlyInteger: false,
    },
    presence: {
      message: '^validation.required'
    }
  },
  max: {
    numericality: {
      onlyInteger: false,
    },
    presence: {
      message: '^validation.required'
    }
  },
};

export default validation;
