import axios from 'axios';
import {
  replaceUrlPlaceholder,
  replaceUrlPlaceholders,
  getRequestConfig,
  REQUEST_TYPES,
} from '../request';
import API_END_POINTS from '../endpoints';

/**
 * Get unique products for customer
 * @param {string} id - customer id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiUniqueProductsGet = function (id, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.CUSTOMER_UNIQUE_PRODUCTS_GET);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Get unique products for customer
 * @param {string} id - customer id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiUniqueProductsGetByCustomer = function (id, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.CUSTOMER_UNIQUE_PRODUCTS_GET_BY_CUSTOMER, 'cid', id);
  const config = getRequestConfig(REQUEST_TYPES.GET, url);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Create customer to variant relations
 * @param {string} id - customer id
 * @param {array} variantIds
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiUniqueProductsAdd = function (id, variantIds, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.CUSTOMER_UNIQUE_PRODUCTS_ADD, 'cid', id);
  let config = getRequestConfig(REQUEST_TYPES.POST, url);
  config.data = {variantIds: variantIds};
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Delete customer relation to variant
 * @param {string} customerId - customer id
 * @param {string} variantId - variant id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiUniqueProductsDelete = function (customerId, variantId, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.CUSTOMER_UNIQUE_PRODUCTS_DELETE, {
    'cid' : customerId,
    'vid' : variantId
  });
  let config = getRequestConfig(REQUEST_TYPES.DEL, url);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};
