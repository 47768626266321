export default () => ({
  singleItemWrapper: {
    border: '1px solid #dddddd',
    display: 'flex',
    margin: 15,
    borderRadius: 12,
    flexDirection: 'column',
    overflow: 'visible',
  },
  singleItemWrapperNoItems: {
    border: '1px solid transparent',
  },
  singleItemWrapperInPast: {},
  initialItemWrapper: {
    flex: 1,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  initialItemWrapperNoItems: {
    cursor: 'auto',
  },
  itemTitle: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 14,
    fontWeight: 600,
    userSelect: 'none',
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemTitleNoItems: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.4)',
  },
  numberOfItems: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 'normal',
    color: 'rgba(0, 0, 0, 0.4)',
    marginLeft: 20,
  },
  tableWrapper: {
    marginRight: 1,
  },
  hidden: {
    display: 'none',
  },

  expandIconWrapper: {},
  expandIcon: {},

  isMostRecent: {
    display: 'inline-block',
    borderRadius: 6,
    width: 6,
    height: 6,
    marginLeft: 5,
    backgroundColor: 'rgb(73,197,147)',
  },

  todaySeparatorWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: 15,
    marginRight: 15,
    paddingTop: 20,
    paddingBottom: 20,
  },
  todaySeparatorText: {
    marginLeft: 10,
    marginRight: 10,
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.4)',
    fontWeight: 500,
  },
  todaySeparatorBorder: {
    flex: 1,
    height: 1,
    backgroundColor: '#DDDDDD',
  },
  noPaddingBottom: {
    paddingBottom: 0,
  },
  noPaddings: {
    paddingBottom: 0,
    paddingTop: 0,
  },
});
