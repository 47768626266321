import { COLORS } from '../../../helpers';

export default {
  wrapper: {},
  back: {
    position: 'absolute',
    top: 21,
    left: 26,
    zIndex: 1000,
    cursor: 'pointer',
    fill: 'rgba(0, 0, 0, 0.5)',
  },
  name: {
    fontSize: 18,
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.text,
    marginBottom: 40,
  },
  title: {
    fontSize: 18,
    textTransform: 'capitalize',
  },
  input: {
    marginBottom: 25,
  },
  label: {
    fontSize: 14,
  },
  inputText: {
    textAlign: 'right',
  },
  button: {
    minWidth: 330,
    alignSelf: 'center',
    textTransform: 'uppercase',
  },
  controls: {
    margin: '27px 0 0 0',
    textAlign: 'center',
  },
  control: {
    padding: '0 105px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: 107,
    marginLeft: 107,
  },
  column: {
    flex: 1,
  },
  errorBox: {
    marginBottom: -20,
  },
};
