import React from 'react'
import PropTypes from 'prop-types'
import {Table} from '@devexpress/dx-react-grid-material-ui'
import {injectIntl} from 'react-intl'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import {withStyles} from '@material-ui/core/styles'
import {COLORS} from "../../../helpers";
import IconButton from "@material-ui/core/IconButton";
import TagsForm from "../../../forms/TagsForm";
import BaseDialog from "../../../dialogs/BaseDialog";

const styles = ({
  cell: {
    cursor: 'pointer',
    whiteSpace: 'normal'
  },
  button: {
    padding: 0
  }
});

const propTypes = {
  onSuccess: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
};

class TagsCell extends React.Component  {

  constructor(props) {
    super(props);
    this.state = {
      dialog: false
    };
  }

  render() {
    const {intl, classes, row, onSuccess, ...other} = this.props;
    return (
      <React.Fragment>
        <Table.Cell className={classes.cell}
                    onClick={() => this.setState({dialog: true})}
                    {...other}>
          {(row.tags || []).join(", ")}&nbsp;
          {!!!row.tags.length && (
            <IconButton className={classes.button}
                        onClick={() => this.setState({dialog: true})} >
              <AddIcon style={{fontSize: 18, color: COLORS.text}} />
            </IconButton>
          )}
        </Table.Cell>

        {this.state.dialog && (
          <BaseDialog
            scroll={'paper'}
            maxWidth='md'
            open
            onClose={() => this.setState({dialog: false})}
            title={intl.formatMessage({"id" : "tags.editing"})}
          >
            <TagsForm
              product={row.product}
              variant={row}
              onSuccess={(product) => {
                this.setState({dialog: false});
                onSuccess(product);
              }}
            />
          </BaseDialog>
        )}
      </React.Fragment>
    );
  }
}

TagsCell.propTypes = propTypes;

export default withStyles(styles)(
    injectIntl(TagsCell)
);
