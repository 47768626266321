import {
  CUSTOMER_APPROVED_FILTER
} from '../FilterTypes'

export default class DXCSCustomerApprovedFilter {

  static getConfig(intl, values, readOnly, fromVariable) {
    return {
      name: CUSTOMER_APPROVED_FILTER,
      title: intl.formatMessage({id: 'customers.tab.approved'}),
      property: "approved",
      type: CUSTOMER_APPROVED_FILTER,
      selectedValues: values,
      clientSide: true,
      readOnly: readOnly ? readOnly : false,
      fromVariable: fromVariable || false,
    }
  }

  constructor(values, propertyName, fromVariable) {
    this.values = values;
    this.property = propertyName;
    this.fromVariable = fromVariable;
  }

  isSatisfy(row) {
    let values = [];
    this.values.forEach(value => {
      if (value.toLowerCase() === 'approved') {
        values.push(true);
      }
      if (value.toLowerCase() === 'not_approved') {
        values.push(false);
      }
    });

    return (
      values.length > 0 && (
        !!this.fromVariable
          ? values.indexOf(row[this.fromVariable][this.property]) !== -1
          : values.indexOf(row[this.property]) !== -1
      )
    );
  }

  getLabel(intl) {
    let values = [];
    this.values.forEach(value => {
      switch (value) {
        case 'approved' : {
          values.push(intl.formatMessage({id: 'global.yes'}));
          break;
        }
        case 'not_approved' : {
          values.push(intl.formatMessage({id: 'global.no'}));
          break
        }
        default: {}
      }
    });
    return values.join(', ');
  }

}
