import {
  PICKUPS_SIGN_IN,
  PICKUPS_SIGN_OUT,
  PICKUPS_SET_USER_OBJECT,
  PICKUPS_MAKE_INITIALIZED,
  PICKUPS_MAKE_NON_INITIALIZED,
  PICKUPS_CHANGE_LOADING,
} from '../constants';
import {
  setPickupsUserToken,
  removePickupsUserToken,
  removePickupsUserObject,
  setPickupsUserObject,
} from '../../helpers';

export function pickupsSignIn(data, onReady) {
  return (dispatch) => {
    const { token } = data;

    setPickupsUserToken(token);

    dispatch({
      type: PICKUPS_SIGN_IN,
      payload: {
        token: token,
      },
    });

    onReady && onReady();
  };
}

export function pickupsSignOut() {
  removePickupsUserToken();
  removePickupsUserObject();

  return (dispatch) => {
    dispatch({
      type: PICKUPS_SIGN_OUT,
    });
  };
}

export function setPickupsUser(pickupsUser) {
  // Remove prepurchases and subscriptions parameters from user because is too huge
  setPickupsUserObject({ ...pickupsUser, prepurchases: [], subscriptions: [] });

  return (dispatch) => {
    dispatch({ type: PICKUPS_SET_USER_OBJECT, payload: pickupsUser });
  };
}

export function setPickupsLoading(isLoading) {
  return (dispatch) => {
    dispatch({ type: PICKUPS_CHANGE_LOADING, payload: isLoading });
  };
}

export function makeInitialized() {
  return (dispatch) => {
    dispatch({ type: PICKUPS_MAKE_INITIALIZED });
  };
}

export function makeNonInitialized() {
  return (dispatch) => {
    dispatch({ type: PICKUPS_MAKE_NON_INITIALIZED });
  };
}
