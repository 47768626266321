import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { withStyles } from '@material-ui/core/styles'
import {
  apiGetAllTags,
} from '../../api'
import {
  apiEditProductVariant
} from '../../api/product/product'
import {
  PrimaryButton,
  Spinner,
  ChipsSelect,
  PrimaryTextField,
} from '../../components'

const styles = ({
  form: {
    width: 400
  },
  tagButtonWrapper: {
    textAlign: 'right'
  },
  tagButton: {
    padding: 5,
    height: 30,
    minWidth: 100
  },
  actions: {
    marginTop: 20,
    textAlign: 'center'
  }
});

const propTypes = {
  product: PropTypes.object.isRequired,
  variant: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
};

class CollectionForm extends Component {

  constructor (props) {
    super(props);
    const {
      variant,
    } = this.props;

    this.state = {
      tags: [],
      tag: '',
      tagError: '',
      selected: variant.tags || [],
      loading: true,
    };
  }

  componentDidMount () {
    apiGetAllTags((tags) => {
      this.setState({
        tags: tags,
        loading: false,
      })
    }, undefined)
  }

  _handleSubmit = e => {
    e.preventDefault();
    const { selected } = this.state;

    const {
      product,
      variant,
      onSuccess
    } = this.props;

    this.setState({loading: true});
    apiEditProductVariant(product.id, variant.id, {tags: selected}, (product) => {
      this.setState({loading: false});
      onSuccess && onSuccess(product)
    }, undefined)
  };

  _onChangeSelection = (selected) => {
    this.setState({selected})
  };

  _onAddTag = () => {
    const {tag, tags} = this.state;
    this.setState({tagError: ""});
    if (tags.filter(obj => obj.name === tag).length) {
      this.setState({tagError: "messages.tagExists"});
    } else {
      this.setState((state) => {
        state.tags.push({name: tag});
        state.selected.push(tag);
        return state;
      })
    }
  };

  render () {
    const { intl, classes } = this.props;
    return (
      <form onSubmit={this._handleSubmit} className={classes.form}>
        {this.state.loading && <Spinner size={40}/>}

        <PrimaryTextField
          error={!!this.state.tagError}
          onChange={event => this.setState({tag: event.target.value.trim()})}
          value={this.state.tag}
          id="tag-input"
          margin="dense"
          label={intl.formatMessage({id: 'tags.newTag'})}
          autoFocus
          helperText={this.state.tagError && intl.formatMessage({id: this.state.tagError})}
          fullWidth
        />
        {this.state.tag && (
          <div className={classes.tagButtonWrapper}>
            <PrimaryButton className={classes.tagButton} onClick={this._onAddTag}>
              {intl.formatMessage({ id: 'tags.addTag'})}
            </PrimaryButton>
          </div>
        )}

        <ChipsSelect onChange={this._onChangeSelection}
                     multiple
                     label={intl.formatMessage({id: "global.tags"})}
                     values={(() => {
                       return this.state.tags.map(tag => {
                         return {label: tag.name, key: tag.name};
                       })
                     })()}
                     selected={this.state.selected}
        />

        <div className={classes.actions}>
          <PrimaryButton type='submit'>
            {intl.formatMessage({ id: 'global.save'})}
          </PrimaryButton>
        </div>
      </form>
    )
  }
}

CollectionForm.propTypes = propTypes;

export default withStyles(styles)(injectIntl(CollectionForm))
