import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {injectIntl} from 'react-intl'
import {withStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import {
  PrimaryButton,
  Spinner,
  TextAreaWithCopyButton,
} from '../../../components';
import {
  apiCustomerResendAccountInvite,
} from '../../../api';
import {COLORS} from "../../../helpers";

const styles = ({
  textField: {
    margin: '15px 0 4px 0',
  },
  actions: {
    marginBottom: 20,
    justifyContent: 'center',
    display: 'flex'
  },
  message: {
    fontSize: 16,
    marginBottom: 20,
    textAlign: 'center',
    color: COLORS.text
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const propTypes = {
  account: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

class ResendAccountInvite extends Component {

  state = {
    url: false,
    email: false,
    loading: false,
  };

  _resendInviteViaEmail = () => {
    const { customer, account } = this.props;
    this.setState({loading: true});
    apiCustomerResendAccountInvite(customer.id, account.id, false, (data) => {
      this.setState({
        email: true,
        loading: false
      })
    })
  };

  _resendInviteViaUrl = () => {
    const { customer, account } = this.props;
    this.setState({loading: true});
    apiCustomerResendAccountInvite(customer.id, account.id, true, (data) => {
      this.setState({
        url: data.url,
        loading: false
      })
    })
  };

  render () {
    const { intl, classes } = this.props;
    return (
      <div className={classes.wrapper}>
        {this.state.loading && (
          <div style={{marginTop: 80}}>
            <Spinner size={40} />
          </div>
        )}

        {!this.state.loading && (
          <React.Fragment>
            {this.state.url && (
              <TextAreaWithCopyButton
                style={{marginBottom: 20}}
                value={this.state.url}
                id="url-input"
                label={intl.formatMessage({id: "global.url"})}
                type="text"
                InputLabelProps={{shrink: true}}
                fullWidth
              />
            )}

            {this.state.email && (
              <Typography gutterBottom className={classes.message}>
                {intl.formatMessage({id: "messages.emailSent"})}
              </Typography>
            )}

            {(!this.state.url && !this.state.email) && (
              <div className={classes.actions}>
                <PrimaryButton style={{marginRight: 10}} onClick={this._resendInviteViaUrl}>
                  {intl.formatMessage({ id: 'global.copyInviteUrl' })}
                </PrimaryButton>
                <PrimaryButton className={classes.button} onClick={this._resendInviteViaEmail}>
                  {intl.formatMessage({ id: 'global.sendInviteViaEmail' })}
                </PrimaryButton>
              </div>
            )}

            {(this.state.url || this.state.email) && (
              <div className={classes.actions}>
                <PrimaryButton style={{marginRight: 10}} onClick={this.props.onClose}>
                  {intl.formatMessage({ id: 'global.done' })}
                </PrimaryButton>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    )
  }
}

ResendAccountInvite.propTypes = propTypes;

export default withStyles(styles)(
  injectIntl(ResendAccountInvite)
)
