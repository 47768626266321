import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { closeSupplierDrawer } from '../../../../store/actions/supplier';
import IconButton from '@material-ui/core/IconButton';
import { useIntl } from 'react-intl';
import { ArrowIcon, CloseIcon, ContactIcon, InventoryIcon, SalesIcon } from '../../assets';

import {
  BORDER_RADIUS_MAIN,
  PADDING_CONTENT,
  PADDING_HEADER_BOTTOM,
  PADDING_HEADER_TOP,
  TRANSITION_MAIN,
} from '../../constants/globals';
import Title from '../Title';
import { Link } from 'react-router-dom';
import { Link as ExternalLink } from '@material-ui/core';
import classNames from 'classnames';
import { COLORS } from '../../constants/colors';
import { FONT_FAMILY, FONT_SIZES } from '../../constants/fonts';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    width: 40,
    height: 40,
  },
  dialogWrapper: {},
  dialogHeader: {
    paddingLeft: PADDING_CONTENT,
    paddingRight: PADDING_CONTENT,
    paddingTop: PADDING_HEADER_TOP,
    paddingBottom: PADDING_HEADER_BOTTOM,
  },
  dialogContent: {
    paddingLeft: PADDING_CONTENT,
    paddingRight: PADDING_CONTENT,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },

  title: {
    marginBottom: 20,
  },
  itemsWrapper: {
    width: '100%',
    maxWidth: 900,
  },
  itemWrapper: {
    paddingLeft: 10,
    paddingRight: 10,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 20,
    paddingBottom: 20,
    marginBottom: 20,
    textDecoration: 'none',
    transition: TRANSITION_MAIN,
    borderRadius: BORDER_RADIUS_MAIN,
    '&:hover': {
      backgroundColor: COLORS.backgroundLight,
    },
    '&:hover p': {
      color: COLORS.primary,
    },
    '&:hover .supplier-menu-svg-icon>path': {
      fill: COLORS.primary,
    },
  },
  itemIcon: {
    marginRight: 20,
  },
  textWrapper: {
    flex: 1,
  },
  itemText: {
    fontSize: FONT_SIZES.text,
    color: COLORS.text,
    fontWeight: 700,
    fontFamily: FONT_FAMILY,
  },
  itemSubText: {
    color: COLORS.grey + ' !important',
  },
  itemArrow: {},
  itemArrowIcon: {},
}));

const propTypes = {
  isOpen: PropTypes.bool,
};

const NavigationMenu = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const onClose = () => dispatch(closeSupplierDrawer());
  const { isOpened, supplier } = useSelector((state) => ({
    isOpened: state.supplier.drawer,
    supplier: state.supplier.object,
  }));

  const drawMenuItem = (icon, text, link, subText, isExternal = false) => {
    const Icon = icon;

    const LinkComponent = isExternal ? ExternalLink : Link;

    return (
      <LinkComponent className={classes.itemWrapper} to={link} href={link} onClick={onClose}>
        <div className={classes.itemIcon}>
          <Icon className={'supplier-menu-svg-icon'} />
        </div>
        <div className={classes.textWrapper}>
          <p className={classes.itemText}>{text}</p>
          {!!subText && <p className={classNames(classes.itemText, classes.itemSubText)}>{subText}</p>}
        </div>
        <div className={classes.itemArrow}>
          <ArrowIcon />
        </div>
      </LinkComponent>
    );
  };

  return (
    <Dialog fullScreen open={isOpened} onClose={onClose} transitionDuration={0}>
      <div className={classes.dialogWrapper}>
        <div className={classes.dialogHeader}>
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <div className={classes.dialogContent}>
          <Title className={classes.title} text={supplier?.name || ''} />

          <div className={classes.itemsWrapper}>
            {drawMenuItem(InventoryIcon, intl.formatMessage({ id: 'supplier.menu.inventory' }), '/supplier/inventory')}
            {drawMenuItem(SalesIcon, intl.formatMessage({ id: 'supplier.menu.sales' }), '/supplier/sales')}
            {drawMenuItem(
              ContactIcon,
              intl.formatMessage({ id: 'supplier.menu.contact' }),
              'mailto:wiley@permanent.ag?cc=ludvig@fellowfarmer.com&subject=Permanent%20%E2%80%A2%20App%20Feedback&body=Hey%20Wiley%20and%20Ludvig%2C%0AQuestions%2Fhelp%3A%20%0ABugs%2Fissues%3A%20%0AFeedback%2Fideas%3A%20',
              intl.formatMessage({ id: 'supplier.menu.contactSub' }),
              true
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

NavigationMenu.propTypes = propTypes;

export default NavigationMenu;
