import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = {
  header: {
    display: 'block',
    textAlign: 'center',
    padding: 0,
    margin: 0,
    minHeight: 30,
  },
  title: {
    display: 'block',
    color: '#363636',
    fontWeight: 300,
    fontSize: 25,
  },
};

const propTypes = {
  justifyContent: PropTypes.string,
  height: PropTypes.any,
  marginBottom: PropTypes.number,
  title: PropTypes.any.isRequired,
};

const defaultProps = {
  justifyContent: 'center',
  height: 30,
  marginBottom: 50,
};

const AdminDialogTitle = (props) => {
  const { title, classes, marginBottom, height, justifyContent, children } = props;

  return (
    <DialogTitle
      style={{
        height: height,
        marginBottom: marginBottom,
        justifyContent: justifyContent,
      }}
      classes={{ root: classes.header }}>
      {children}
      <span className={classNames([classes.title, props?.className])}>{title}</span>
    </DialogTitle>
  );
};

AdminDialogTitle.propTypes = propTypes;
AdminDialogTitle.defaultProps = defaultProps;

export default withStyles(styles)(AdminDialogTitle);
