export default {
  name: {
    presence: {
      message: '^validation.required'
    }
  },
  email: {
    presence: {
      message: '^validation.required'
    },
    email: {
      message: '^validation.enterValidEmail'
    }
  },
  address1: {
    presence: {
      message: '^validation.required'
    }
  },
  city: {
    presence: {
      message: '^validation.required'
    }
  },
  zipCode: {
    presence: {
      message: '^validation.required'
    }
  },
  province: {
    presence: {
      message: '^validation.required'
    }
  },
}