import React from 'react';

export const QrCodeIcon = (props) => {
  const { size, fillColor = 'black', strokeColor = 'black', ...others } = props;
  return (
    <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" fill="none" {...others}>
      <path fillRule="evenodd" clipRule="evenodd" d="M0 0H5V2H2V5H0V0Z" fill={fillColor} />
      <path fillRule="evenodd" clipRule="evenodd" d="M14 0H9V2H12V5H14V0Z" fill={fillColor} />
      <path fillRule="evenodd" clipRule="evenodd" d="M14 14L14 9H12V12H9V14L14 14Z" fill={fillColor} />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 14L0 9H2L2 12H5V14L0 14Z" fill={fillColor} />
      <rect x="5" y="5" width="4" height="4" stroke={strokeColor} strokeWidth="2" />
    </svg>
  );
};
