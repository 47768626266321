import React from 'react'
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl'
import AutoSuggest from 'react-autosuggest'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import {withStyles} from '@material-ui/core/styles'
import InputComponent from './InputComponent'
import SuggestionComponent from './SuggestionComponent'
import { SearchProductsParams } from '../../entities'
import { Spinner, InlineSelect } from '../../components'
import {apiProductGet} from '../../api'
import { variantsArrayToObject } from '../../dialogs/AddToCartDialog/helpers'

const styles = theme => ({
  root: {
    flexGrow: 1,
    minWidth: 200,
  },
  container: {
    position: 'relative',
    width: 'auto',
  },
  popper: {
    zIndex: theme.zIndex.modal + 100,
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    display: 'flex',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: -30,
    right: 0,
  },
  suggestion: {
    display: 'block',
    textAlign: 'left',
    backgroundColor: '#ffffff'
  },
  suggestionsList: {
    width: 'auto',
    margin: 0,
    padding: 0,
    backgroundColor: '#ffffff',
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing(2),
  },
});

const propTypes = {
  selected: PropTypes.object,
  inputProps: PropTypes.object,
  classes: PropTypes.object.isRequired,
  onChangeQuery: PropTypes.func.isRequired,
  onAttach: PropTypes.func.isRequired,
  onDetach: PropTypes.func.isRequired,
  clearAfterSelect: PropTypes.bool,
  retriever: PropTypes.func.isRequired,
};

const defaultProps = {
  inputLabel: '',
  labelExtractor: item => `${item.name}`
};

class AutoComplete extends React.Component {

  constructor(props) {
    super(props);
    let value = (this.props.selected && props.labelExtractor(this.props.selected)) || "";
    this.state = {
      value: value,
      loading: false,
      product: null,
      hits: [],
    };
  }

  refine() {
    const { retriever } = this.props;
    const { value } = this.state;

    let params = new SearchProductsParams({
      query: value,
      limit: 10
    });

    retriever(params, hits => {
      this.setState({hits: hits})
    })
  }

  componentWillUnmount() {
    this.scheduleUpdate = null;
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    if (this.scheduleUpdate) {
      this.scheduleUpdate();
    }
    this.setState({product: null});
    this.refine(value);
  };

  handleSuggestionsClearRequested = () => {
    this.refine("");
  };

  handleChange = (event, data) => {
    this.setState({value: data.newValue});
  };

  onSelectVariant = (variant) => {
    const { product } = this.state;
    this.props.onAttach(product, variant);
    this.setState({product: null});
  };

  onSelect = (event, data) => {
    this.setState({loading: true});
    apiProductGet(data.suggestion.id, (product) => {
      this.setState({
        loading: false,
        product: product,
      }, () => {
        if (product.variants.length === 1) {
          this.onSelectVariant(product.variants[0])
        }
      });

    }, () => this.setState({loading: false}));

    if (this.props.clearAfterSelect) {
      this.setState({
        value: "",
        product: null
      })
    }
  };

  onDeselect = () => {
    this.props.onDetach();
    this.setState({
      value: "",
      product: null,
    });
  };

  render() {
    const {
      classes,
      intl,
      inputLabel,
      inputProps,
      labelExtractor,
    } = this.props;

    let { hits } = this.state;

    const autoSuggestProps = {
      alwaysRenderSuggestions: false,
      renderInputComponent: props => InputComponent({...props, ...inputProps}, inputLabel),
      suggestions: hits,
      onSuggestionSelected: this.onSelect,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue: hit => labelExtractor(hit),
      renderSuggestion: (...args) => SuggestionComponent(...args, labelExtractor),
    };

    return (
        <div className={classes.root}>
          {this.state.loading && <Spinner size={40} />}

          <AutoSuggest
            {...autoSuggestProps}
            inputProps={{
              classes,
              intl,
              value: this.state.value,
              onChange: this.handleChange,
              onDeselect: this.onDeselect,
              inputRef: node => {
                this.popperNode = node;
              },
            }}
            theme={{
              container: classes.container,
              suggestionsContainerOpen: classes.suggestionsContainerOpen,
              suggestionsList: classes.suggestionsList,
              suggestion: classes.suggestion,
            }}
            renderSuggestionsContainer={options => (
              <Popper className={classes.popper}
                      placement={"bottom-start"}
                      anchorEl={this.popperNode}
                      open={Boolean(options.children)}>
                <Paper {...options.containerProps} square>
                  {options.children}
                </Paper>
              </Popper>
            )}
          />

          {(this.state.product && this.state.product.variants.length > 1) && (
            <InlineSelect values={variantsArrayToObject(this.state.product.variants)}
                          onSelect={this.onSelectVariant}
            />
          )}

        </div>

    );
  }
}

AutoComplete.propTypes = propTypes;
AutoComplete.defaultProps = defaultProps;

export default withStyles(styles, {withTheme: true})(
  injectIntl(AutoComplete)
)
