const styles = theme => ({
  spinner: {
    margin: 'auto',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  requestError: {
    backgroundColor: 'red',
    color: '#ffffff',
    marginBottom: 20,
    marginLeft: 20,
    marginRight: 20
  },
  controls: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: "0 24px 20px",
  },
  dialogContent: {
    minWidth: 655
  },
  paper: {
    paddingTop: 10,
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: 50,
    marginBottom: 50,
    boxSizing: 'border-box',
    paddingBottom: 10
  }
});

export default styles;