import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { BUTTON_VARIANTS, PADDING_CONTENT, PADDING_CONTENT_BOTTOM, PADDING_CONTENT_TOP } from '../../constants/globals';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { PrimaryButton, Title } from '../../components';
import { COLORS } from '../../constants';
import { MessageIcon, AddOutlined } from '../../assets';
import { useDispatch } from 'react-redux';
import { setDeliveriesLoading } from '../../store/actions';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 1,
  },
  content: {
    boxSizing: 'border-box',
    paddingLeft: PADDING_CONTENT,
    paddingRight: PADDING_CONTENT,
    maxWidth: 900,
    textAlign: 'left',
    width: '100%',
    backgroundColor: 'white',
    marginBottom: 20,
    paddingTop: PADDING_CONTENT_TOP,
    paddingBottom: PADDING_CONTENT_BOTTOM,
    minHeight: '100%',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    marginBottom: 20,
  },

  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  button: {
    marginLeft: 10,
    marginRight: 10,
    width: 150,
  },
}));
const ErrorScene = () => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const onLoading = (isLoading) => dispatch(setDeliveriesLoading(isLoading));

  useEffect(
    () => {
      onLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className={classes.root}>
      <div className={classes.contentWrapper}>
        <div className={classes.content}>
          <Title className={classes.title} text={intl.formatMessage({ id: 'supplier.global.wrongCredentials' })} />

          <div className={classes.buttonsWrapper}>
            <div className={classNames([classes.buttonWrapper, classes.stickyBottomButton])}>
              <PrimaryButton
                link={'/'}
                text={intl.formatMessage({ id: 'supplier.global.goToLogIn' })}
                icon={() => <AddOutlined color={COLORS.white} />}
                classes={{ root: classes.button }}
              />
            </div>
            <div className={classes.buttonWrapper}>
              <PrimaryButton
                link={
                  'mailto:wiley@permanent.ag?cc=ludvig@fellowfarmer.com&subject=Permanent%20%E2%80%A2%20App%20Feedback&body=Hey%20Wiley%20and%20Ludvig%2C%0AQuestions%2Fhelp%3A%20%0ABugs%2Fissues%3A%20%0AFeedback%2Fideas%3A%20'
                }
                external
                text={intl.formatMessage({ id: 'supplier.menu.contact' })}
                icon={() => <MessageIcon color={COLORS.grey} />}
                variant={BUTTON_VARIANTS.grey}
                classes={{ root: classes.button }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorScene;
