import React from 'react';
import { COLORS } from '../constants/colors';

const CalcButton6 = (props) => {
  const { color = COLORS.blackTintedBlue, ...other } = props;
  return (
    <svg width="28" height="35" viewBox="0 0 28 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <path d="M0.49004 34H1.80352V29.2051H1.90117L3.70781 34H4.63066L6.43731 29.2051H6.53496V34H7.84844V26.9541H6.15899L4.21563 32.1104H4.12774L2.17949 26.9541H0.49004V34ZM11.2623 34H12.6734V29.4932H12.7516L16.0133 34H17.234V26.9541H15.8229V31.4414H15.7447L12.4928 26.9541H11.2623V34ZM23.7289 34.1807C25.809 34.1807 27.1176 32.7549 27.1176 30.4795V30.4697C27.1176 28.1943 25.8041 26.7734 23.7289 26.7734C21.6537 26.7734 20.3354 28.1895 20.3354 30.4697V30.4795C20.3354 32.7549 21.6342 34.1807 23.7289 34.1807ZM23.7289 32.9307C22.5668 32.9307 21.8393 31.9688 21.8393 30.4795V30.4697C21.8393 28.9756 22.5814 28.0234 23.7289 28.0234C24.8715 28.0234 25.6137 28.9756 25.6137 30.4697V30.4795C25.6137 31.9688 24.8764 32.9307 23.7289 32.9307Z" fill={color}/>
      <path d="M14.9819 19.415C18.5342 19.415 21.1465 16.8638 21.1465 13.3481V13.3237C21.1465 9.94238 18.6807 7.42773 15.3726 7.42773C12.9922 7.42773 11.332 8.70947 10.6851 10.1499H10.4531C10.4531 10.0156 10.4531 9.86914 10.4653 9.73486C10.5996 6.06055 11.9058 2.93555 15.104 2.93555C16.8862 2.93555 18.1191 3.85107 18.6562 5.32812L18.7051 5.4624H20.9146L20.8779 5.30371C20.2798 2.70361 18.0825 0.970215 15.1284 0.970215C10.856 0.970215 8.35352 4.48584 8.35352 10.4917V10.5161C8.35352 17.1445 11.7593 19.415 14.9819 19.415ZM10.978 13.3237V13.3115C10.978 11.0166 12.7114 9.34424 14.9941 9.34424C17.2769 9.34424 18.937 11.0288 18.937 13.3726V13.397C18.937 15.6797 17.167 17.4741 14.9575 17.4741C12.7236 17.4741 10.978 15.6431 10.978 13.3237Z" fill={color}/>
    </svg>
  );
};

export default CalcButton6;
