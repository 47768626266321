import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from '../../hocs';
import { AdminDXTable, Spinner, Tab, TabActions } from '../../components';
import { apiPrePurchaseDelete } from '../../api';
import { getGridPageSize, getGridPageSizes } from '../../helpers/grid';
import { jsonToCsv, TableCellRenderer, TableRowRenderer } from './helpers';
import {
  DEFAULT_MIN_CELL_WIDTH,
  DEFAULT_MAX_CELL_WIDTH,
  countWidthOfColumns,
  HEADER_HEIGHT_STANDARD,
} from '../../helpers';
import { ReportsFilterDialog, SaveTabSettingDialog, TabTypes } from '../../dialogs';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import config, { REPORTS_TYPES } from './config';

const propTypes = {
  tab: PropTypes.object.isRequired,
  columns: PropTypes.array,
  columnExtensions: PropTypes.array,
  onlyActive: PropTypes.bool,
  withFilters: PropTypes.bool,
  allowCreateTab: PropTypes.bool,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onDeleteTab: PropTypes.func,
  onCreateTab: PropTypes.func,
  onPatchTab: PropTypes.func,
  onAddingFilter: PropTypes.func,
  allBackEndSavedReports: PropTypes.array,
};

const defaultProps = {
  onlyActive: false,
  allBackEndSavedReports: [],
};

const REPORT_WITHOUT_DATE_FILTER = [REPORTS_TYPES.CSA_LINE_ITEMS];

class ReportsContent extends React.Component {
  constructor(props) {
    super(props);
    const { onRef, currentReport } = props;
    const currentReportData = this.getCurrentReportData(currentReport.key);

    this.reports = config(props.intl);

    this.state = {
      loading: true,
      deleting: null,
      deletingLoading: false,
      tabDialog: false,
      isFiltersDialogActive: false,
      openWithFilter: null,
      settings: {
        excludeColumns: currentReportData.excludeColumns || [],
        filters: this.prepareFilters(currentReportData.filters || []),
        sorting: currentReportData.sorting || [],
      },
      columnWidths: countWidthOfColumns(
        currentReport?.def?.columns,
        currentReportData.excludeColumns,
        currentReportData?.columnExtensions || currentReport?.def?.columnExtensions || []
      ),
      // old
      tab: 0,
      currentReport: currentReport.def,
      currentReportKey: currentReport.key,
      currentReportName: currentReport.name,

      filtersDialog: false,

      dateSelected: true,

      clientSidePrevSettingsByReportId: {},
    };

    this.table = null;

    onRef && onRef(this);
  }

  getCurrentReportData = (reportKey) => {
    const { allBackEndSavedReports, intl } = this.props;

    const reportData = allBackEndSavedReports.find((report) => report.name === reportKey);
    const clientReportData = this.state && this.state.clientSidePrevSettingsByReportId[reportKey];

    if (!!clientReportData) return clientReportData;

    return reportData
      ? reportData
      : {
          dataNotExist: true,
          excludeColumns: [],
          filters: [
            {
              column: intl.formatMessage({ id: 'filter.report.reportType' }),
              name: 'reportSelection',
              selectedValues: [reportKey],
              readOnly: true,
            },
            {
              column: intl.formatMessage({ id: 'reports.shippingDates' }),
              name: 'reportShippingDates',
              selectedValues: [{ predefined: 'this_week' }],
              readOnly: this.isDateFilterRequired(reportKey),
            },
          ],
          columnWidths: false,
          sorting: [],
        };
  };

  prepareFilters = (filters) => {
    return filters;
  };

  onChangeColumnsState = (excludeColumns) => {
    this.setState((state) => {
      state.settings['excludeColumns'] = excludeColumns;
      return state;
    });
  };

  onDelete = () => {
    this.setState({ deletingLoading: true });
    apiPrePurchaseDelete(
      this.state.deleting.id,
      () => {
        this.setState({
          deleting: null,
          deletingLoading: false,
        });
        this.table.forceReload();
      },
      undefined
    );
  };

  forceTableReload = () => {
    this.table.forceReload();
  };

  onCreateTab = () => {
    this.setState({ tabDialog: true });
  };

  onAddFilter = () => {
    this.setState({ filtersDialog: true });
  };

  onUpdateTab = () => {
    const { tab, onPatchTab } = this.props;

    if (tab.id) {
      onPatchTab(tab, this.makeReportData(this.state.settings));
    }
  };

  getFiltersDialog = () => {
    const { currentReport, settings, openWithFilter } = this.state;

    return (
      <ReportsFilterDialog
        onClose={this.onCloseFilterDialog}
        currentReport={currentReport || {}}
        appliedFilters={JSON.parse(JSON.stringify(settings.filters))}
        openWithFilter={openWithFilter}
        isDateFilterRequired={this.isDateFilterRequired()}
        onApplyFilters={(filters) => {
          this.handleFiltersApply(filters);
          this.setState({
            isFiltersDialogActive: false,
          });
        }}
      />
    );
  };

  isDateFilterRequired = (reportKey) => {
    return !REPORT_WITHOUT_DATE_FILTER.includes(reportKey || this.state.currentReportKey);
  };
  handleFiltersApply = (filtersToApply) => {
    let appliedFilters = [...filtersToApply];

    // handle change report to new one
    let exit = false;
    let isDeliveryDateExist = false;
    let reportId = null;

    appliedFilters.forEach((filter) => {
      if (filter.name === 'reportSelection') {
        reportId = filter.selectedValues[0];

        if (this.state.currentReportKey !== reportId) {
          exit = true;
          this.onReportSelect(reportId, this.reports[reportId], appliedFilters);
        }
      }

      if (filter.name === 'reportShippingDates') {
        isDeliveryDateExist = true;
      }
    });

    if (!isDeliveryDateExist && this.isDateFilterRequired()) {
      appliedFilters.push({
        column: this.props.intl.formatMessage({ id: 'reports.shippingDates' }),
        name: 'reportShippingDates',
        selectedValues: [{ predefined: 'this_week' }],
        readOnly: true,
      });
    }

    if (exit) {
      this.setState({ currentReportKey: reportId });
      return;
    }

    this.setState({
      settings: {
        excludeColumns: this.state.settings.excludeColumns || [],
        filters: appliedFilters,
        sorting: this.state.settings.sorting || [],
      },
      filtersDialog: false,
      addingFilter: false,
      dateSelected: true,
    });
  };

  onChangeSorting = (sorting) => {
    this.setState({
      sorting,
      settings: {
        ...this.state.settings,
        sorting,
      },
    });
  };

  handleFilterRemove = (removeFilter) => {
    this.setState((state) => {
      let dateSelected = state.dateSelected;

      let filters = state.settings.filters;
      let index = filters.findIndex((filter) => filter.name === removeFilter.name);

      if (index !== -1) {
        filters.splice(index, 1);
      }
      return {
        settings: { ...this.state.settings, filters: filters },
        dateSelected,
      };
    });
  };

  onReportSelect = (index, report) => {
    if (report !== -1) {
      this.setState(
        {
          currentReport: null,
          clientSidePrevSettingsByReportId: {
            ...this.state.clientSidePrevSettingsByReportId,
            [this.state.currentReportKey]: this.state.settings,
          },
        },
        () => {
          const currentReportData = this.getCurrentReportData(index);

          setTimeout(() => {
            this.setState({
              currentReportKey: report && report.key,
              currentReportName: report && report.name,
              currentReport: report && report.def,
              filtersDialog: false,
              addingFilter: false,
              dateSelected: true,
              columnWidths: countWidthOfColumns(
                report.def.columns,
                currentReportData.excludeColumns,
                currentReportData?.columnExtensions || []
              ),
              settings: {
                excludeColumns: currentReportData.excludeColumns || [],
                filters: this.prepareFilters(currentReportData.filters || []),
                sorting: currentReportData.sorting || [],
              },
            });
          }, 300);
        }
      );
    }
  };

  onCloseFilterDialog = () => {
    this.setState({
      filtersDialog: false,
    });
  };

  onOpenWithFilter = (index, filter) => {
    this.setState({
      filtersDialog: true,
      openWithFilter: filter,
    });
  };

  onSaveTabDialog = (data) => {
    const { onCreateTab } = this.props;

    onCreateTab(this.makeReportData(data), () => this.setState({ tabDialog: false }));
  };

  makeReportData = (data) => {
    const { allBackEndSavedReports } = this.props;
    const { currentReportKey } = this.state;

    let dataToSave = { ...data };

    const frontendFilters = this.getArrayFromClientSideData();
    const backendFilters = [...allBackEndSavedReports].filter(
      (backendFilter) => !frontendFilters.some((frontEndFilter) => frontEndFilter.name === backendFilter.name)
    );

    const array = [...backendFilters, ...frontendFilters].filter((item) => item.name !== currentReportKey);

    dataToSave['reports'] = [...array, { ...data, name: currentReportKey }];

    return dataToSave;
  };

  getArrayFromClientSideData = () => {
    const { clientSidePrevSettingsByReportId } = this.state;

    return Object.entries(clientSidePrevSettingsByReportId).map((item) => ({ name: item[0], ...item[1] }));
  };

  render() {
    const { tab, index, intl, classes, allowCreateTab, withFilters, onDeleteTab } = this.props;

    const {
      sorting,
      tabDialog,

      currentReport,
      currentReportName,

      filtersDialog,

      settings,
      dateSelected,
    } = this.state;

    return (
      <Tab
        title={tab.name}
        key={`${index}-tab`}
        tab={tab}
        actions={
          <TabActions
            tab={tab}
            allowHideActionsColumn={false}
            withFilters={withFilters}
            allowCreateTab={allowCreateTab}
            onCreateTab={this.onCreateTab}
            onUpdateTab={this.onUpdateTab}
            onAddingFilter={withFilters ? this.onAddFilter : undefined}
            onDeleteTab={onDeleteTab}
            onChangeColumnsState={this.onChangeColumnsState}
            {...(currentReport &&
              dateSelected &&
              currentReport &&
              currentReport.allowExport && {
                onExportTab: () =>
                  jsonToCsv(
                    this.table.getRows(),
                    currentReportName,
                    true,
                    ['id'],
                    // only active columns
                    currentReport.columns.filter((column) => !settings.excludeColumns.includes(column.name))
                  ),
              })}
            columns={currentReport ? currentReport.columns : []}
            appliedFilters={settings.filters}
          />
        }>
        {currentReport && dateSelected ? (
          <AdminDXTable
            filtersToggle
            rowRenderer={(onClick, ...props) => {
              return currentReport.rowRenderer
                ? currentReport.rowRenderer(onClick, ...props)
                : TableRowRenderer(onClick, ...props);
            }}
            cellRenderer={(...props) => {
              return currentReport.cellRenderer
                ? currentReport.cellRenderer(intl, ...props)
                : TableCellRenderer(intl, ...props);
            }}
            onRef={(table) => (this.table = table)}
            onRemoveFilter={(removedFilter) => this.handleFilterRemove(removedFilter)}
            onOpenWithFilter={this.onOpenWithFilter}
            tableSummaryRowMessages={currentReport.tableSummaryRowMessages ? currentReport.tableSummaryRowMessages : {}}
            apiRetrieve={currentReport.apiRetrieve}
            enableTree={currentReport.enableTree}
            getTreeChildRows={(...props) => {
              return currentReport.getTreeChildRows ? currentReport.getTreeChildRows(intl, ...props) : undefined;
            }}
            columns={currentReport.columns}
            treeSelectionColumn={currentReport.treeSelectionColumn ? currentReport.treeSelectionColumn : ''}
            pageSize={getGridPageSize()}
            pageSizes={getGridPageSizes()}
            treeSummaryItems={currentReport.treeSummaryItems ? currentReport.treeSummaryItems : undefined}
            getExpandedRowIds={currentReport.getExpandedRowIds ? currentReport.getExpandedRowIds : undefined}
            totalSummaryItems={currentReport.totalSummaryItems}
            currencyColumnNames={currentReport.currencyColumnNames ? currentReport.currencyColumnNames : undefined}
            summaryCalculator={currentReport.summaryCalculator ? currentReport.summaryCalculator : undefined}
            groupSummaryItems={currentReport.groupSummaryItems || []}
            prepareRowsAfterLoad={currentReport.prepareRowsAfterLoad}
            quantityColumnNames={currentReport.quantityColumnNames}
            groupingColumns={currentReport.groupingColumns}
            enableSelection={currentReport.enableSelection}
            columnExtensions={currentReport.columnExtensions}
            actionsHeaderCellStyle={{ textAlign: 'center' }}
            actionsCellStyle={{ textAlign: 'center' }}
            onChangeSorting={this.onChangeSorting}
            serverSideFilters={JSON.parse(JSON.stringify(settings.filters))}
            appliedFilters={settings.filters || []}
            hiddenColumnNames={settings.excludeColumns || []}
            sorting={settings.sorting || []}
            columnWidths={this.state.columnWidths}
            onColumnWidthsChange={(widths) => {
              this.setState({ columnWidths: widths });
            }}
            minColumnWidth={DEFAULT_MIN_CELL_WIDTH}
            maxColumnWidth={DEFAULT_MAX_CELL_WIDTH}
            allowExpandAll={currentReport.allowExpandAll || false}
            stickyHeader
            stickyHeaderOffset={HEADER_HEIGHT_STANDARD.extended}
          />
        ) : (
          <div className={classes.noRequiredFiltersMessageWrapper}>
            <Spinner size={60} />
          </div>
        )}

        {filtersDialog && this.getFiltersDialog()}

        {tabDialog && (
          <SaveTabSettingDialog
            onClose={() => this.setState({ tabDialog: false })}
            filters={settings.filters.slice()}
            excludeColumns={settings.excludeColumns || []}
            sorting={settings.sorting || sorting || currentReport.sorting}
            onSave={this.onSaveTabDialog}
            type={TabTypes.REPORTS}
          />
        )}
      </Tab>
    );
  }
}

ReportsContent.propTypes = propTypes;
ReportsContent.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  return {
    producer: state.producer.object,
  };
};

export default withStyles(styles)(injectIntl(withRouter(connect(mapStateToProps)(ReportsContent))));
