import { PRICE_LEVELS } from '../../helpers';

export const PRODUCTS_VIEW_TYPES = {
  DEFAULT: 'default',
  ARCHIVED: 'archived',
};

function standard(intl, withoutColumns = []) {
  return {
    config_key: PRODUCTS_VIEW_TYPES.DEFAULT,
    columnExtensions: [
      { columnName: 'selection', width: 120 },
      { columnName: 'image.src', width: 70 },
      { columnName: 'translation', width: 130 },
      { columnName: 'variant', width: 130 },
      { columnName: 'tags', width: 100 },
      { columnName: 'quantity', width: 150, align: 'right' },
      { columnName: 'unit', width: 100 },
      { columnName: 'descriptions', width: 150 },
      { columnName: 'active', width: 100, align: 'center' },
      { columnName: 'wholesaleMarket', align: 'right' },
      { columnName: 'wholesalePreorder', align: 'right' },
      { columnName: 'retailMarket', align: 'right' },
      { columnName: 'retailPreorder', align: 'right' },
    ],
    columns: [
      {
        name: 'selection',
        title: intl.formatMessage({ id: 'products.column.selection' }),
      },
      {
        name: 'image.src',
        title: intl.formatMessage({ id: 'products.column.image' }),
        type: 'image',
      },
      {
        name: 'product.type',
        title: intl.formatMessage({ id: 'products.column.type' }),
        type: 'product.type',
      },
      {
        name: 'subType',
        title: intl.formatMessage({ id: 'products.column.subType' }),
        type: 'subType',
      },
      {
        name: 'name',
        title: intl.formatMessage({ id: 'products.column.product' }),
        type: 'name',
      },
      { name: 'supplier', title: intl.formatMessage({ id: 'products.column.supplier' }), type: 'supplier' },
      {
        name: 'translation',
        title: intl.formatMessage({ id: 'product.translation' }),
        type: 'translation',
      },
      {
        name: 'variant.name',
        title: intl.formatMessage({ id: 'products.column.variant' }),
      },
      {
        name: 'tags',
        title: intl.formatMessage({ id: 'products.column.tags' }),
        type: 'tags',
      },
      {
        name: 'estimatedWeight',
        title: intl.formatMessage({ id: 'products.column.estimatedWeight' }),
        type: 'estimatedWeight',
      },
      {
        name: 'quantity',
        title: intl.formatMessage({ id: 'products.column.quantity' }),
        type: 'quantity',
      },
      {
        name: 'unit',
        title: intl.formatMessage({ id: 'products.column.unit' }),
        type: 'unit',
      },
      {
        name: 'description',
        title: intl.formatMessage({ id: 'products.column.description' }),
        type: 'description',
      },
      {
        name: 'wholesaleMarket',
        title: intl.formatMessage({ id: 'products.column.wholesaleMarket' }),
        type: PRICE_LEVELS.WHOLESALE_MARKET,
      },
      {
        name: 'wholesalePreorder',
        title: intl.formatMessage({
          id: 'products.column.wholesalePreorder',
        }),
        type: PRICE_LEVELS.WHOLESALE_PREORDER,
      },
      {
        name: 'retailMarket',
        title: intl.formatMessage({ id: 'products.column.retailMarket' }),
        type: PRICE_LEVELS.RETAIL_MARKET,
      },
      {
        name: 'retailPreorder',
        title: intl.formatMessage({ id: 'products.column.retailPreorder' }),
        type: PRICE_LEVELS.RETAIL_PREORDER,
      },
      {
        name: 'active',
        title: intl.formatMessage({ id: 'products.column.active' }),
        type: 'active',
      },
      {
        name: 'inventory',
        title: intl.formatMessage({ id: 'products.column.inventory' }),
        type: 'inventory',
      },
      {
        name: 'variant.retailPrice',
        title: intl.formatMessage({ id: 'global.retail' }),
      },
      {
        name: 'variant.wholesalePrice',
        title: intl.formatMessage({ id: 'global.wholesale' }),
      },
    ].filter((column) => !withoutColumns.includes(column.name)),
    defaultHiddenColumnNames: [
      'selection',
      'image.src',
      'translation',
      'tags',
      'estimatedWeight',
      'quantity',
      'description',
      'wholesaleMarket',
      'wholesalePreorder',
      'retailMarket',
      'variant.retailPrice',
      'variant.wholesalePrice',
      'supplier',
    ],
    hiddenColumnNames: [],
  };
}

export default (intl) => {
  return {
    default: standard(intl),
    archived: {
      config_key: PRODUCTS_VIEW_TYPES.ARCHIVED,
      columnExtensions: [],
      onlyArchived: true,
      groupingColumns: [
        { columnName: 'productType' },
        { columnName: 'productSubType' },
        { columnName: 'productName' },
        { columnName: 'productVariant' },
      ],
      columns: [
        ...standard(intl, [
          'translation',
          'tags',
          'estimatedWeight',
          'quantity',
          'description',
          'active',
          'inventory',
          'variant.retailPrice',
          'variant.wholesalePrice',
          'actions',
        ]).columns,
      ],
      hiddenColumnNames: [
        'translation',
        'tags',
        'estimatedWeight',
        'quantity',
        'description',
        'active',
        'inventory',
        'variant.retailPrice',
        'variant.wholesalePrice',
        'actions',
      ],
    },
  };
};
