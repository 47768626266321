import { getSupplierRequestConfig, replaceUrlPlaceholder, replaceUrlPlaceholders, REQUEST_TYPES } from '../request';
import API_END_POINTS from '../endpoints';
import axios from 'axios';
import { SearchProductsParams } from '../../entities';

/**
 * Get product
 * @param {string} productId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierProductGet = function (productId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRODUCT_GET_ONE, 'pr', productId);
  const config = getSupplierRequestConfig(REQUEST_TYPES.GET, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Get shared properties
 * @param {object} data
 * @param {string} type
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierGetSharedProperties = function (data, type, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRODUCT_SHARED_PROPERTIES_GET_ALL, 'type', type);
  const config = getSupplierRequestConfig(REQUEST_TYPES.GET, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Get list of products
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierGetListOfProducts = function (data, onSuccess, onError) {
  const config = getSupplierRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.PRODUCT_GET_ALL);
  let params = new SearchProductsParams(data);
  config.params = params.toData();

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Activate product statuses
 * @param {array} ids - product ids
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierActivateProducts = function (ids, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.PRODUCT_BULK_STATUSES, {
    status: 'activate',
  });
  const config = getSupplierRequestConfig(REQUEST_TYPES.POST, url);
  config.data = {
    productIds: ids,
  };
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Create product
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierAddProduct = function (data = {}, onSuccess, onError) {
  const config = getSupplierRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.PRODUCT_CREATE);
  // Set request params
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Add variant to product
 * @param {string} productId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierAddVariantToProduct = function (productId, data = {}, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRODUCT_CREATE_VARIANT, 'pr', productId);
  const config = getSupplierRequestConfig(REQUEST_TYPES.POST, url);
  // Set request params
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Delete variants
 * @param {array} ids - variants ids
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierDeleteVariants = function (ids, onSuccess, onError) {
  const config = getSupplierRequestConfig(REQUEST_TYPES.DEL, API_END_POINTS.PRODUCT_BULK_REMOVE);
  config.data = {
    variantIds: ids,
  };
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Updates product
 * @param {string} productId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierEditProduct = function (productId, data = {}, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRODUCT_EDIT, 'pr', productId);
  const config = getSupplierRequestConfig(REQUEST_TYPES.PATCH, url);
  // Set request params
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Updates variant
 * @param {string} productId
 * @param {string} variantId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierEditProductVariant = function (productId, variantId, data = {}, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.PRODUCT_EDIT_VARIANT, {
    pr: productId,
    va: variantId,
  });
  const config = getSupplierRequestConfig(REQUEST_TYPES.PATCH, url);
  // Set request params
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Inactivate product statuses
 * @param {array} ids - product ids
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierInactivateProducts = function (ids, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.PRODUCT_BULK_STATUSES, {
    status: 'inactivate',
  });
  const config = getSupplierRequestConfig(REQUEST_TYPES.POST, url);
  config.data = {
    productIds: ids,
  };

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Update inventory to single variant
 * @param {string} productId
 * @param {string} variantId
 * @param {object} data
 * @param {number} data.quantity - 100, quantity
 * @param {boolean|undefined} data.managed - true, (optional, default: false),
 * @param {boolean|undefined} data.stopSelling - false (optional, default: false)
 * @param {function} onSuccess
 * @param {function|undefined} onError
 */
export const apiSupplierChangeInventoryOnProductVariant = function (productId, variantId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.PRODUCT_CHANGE_INVENTORY_TO_SINGLE_VARIANT, {
    pr: productId,
    va: variantId,
  });

  const config = getSupplierRequestConfig(REQUEST_TYPES.PATCH, url);
  config.data = {
    inventory: {
      ...data,
    },
  };

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Activate variants statuses
 * @param {array} ids - product ids
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierActivateVariants = function (ids, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.PRODUCT_BULK_STATUSES, {
    status: 'activate',
  });

  const config = getSupplierRequestConfig(REQUEST_TYPES.POST, url);
  config.data = {
    variantIds: ids,
  };

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Inactivate variant statuses
 * @param {array} ids - product ids
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierInactivateVariants = function (ids, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.PRODUCT_BULK_STATUSES, {
    status: 'inactivate',
  });

  const config = getSupplierRequestConfig(REQUEST_TYPES.POST, url);
  config.data = {
    variantIds: ids,
  };

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Inactivate variant statuses
 * @param {string} productId - product ids
 * @param {array} data - variants data
 * @param {string} data.variants.id - required
 * @param {string|null} data.variants.type - optional
 * @param {string|null} data.variants.name - optional
 * @param {string|null} data.variants.translation -  optional
 * @param {string|null} data.variants.description -  optional
 * @param {string|null} data.variants.wholesaleDescription -  optional
 * @param {string|null} data.variants.unit -  optional
 * @param {int|null} data.variants.unitQuanitity - optional
 * @param {int|null} data.variants.estimatedWeight - optional
 * @param {int|null} data.variants.inventoryQuantity - optional
 * @param {string|null} data.variants.sku - optional
 * @param {bool|null} data.variants.active - optional
 * @param {string|null} data.variants.image - optional
 * @param {array|null} data.variants.tags - optional
 * @param {array|null} data.variants.offered - optional
 * @param {string|null} data.variants.offered.retail - required if offered exists
 * @param {string|null} data.variants.offered.wholesale -  required if offered exists
 * @param {array|null} data.variants.subscription - optional
 * @param {string|null} data.variants.subscription.variantId - optional
 * @param {int|null} data.variants.subscription.quantity - optional
 * @param {string|null} data.variants.subscription.period - optional
 * @param {string|null} data.variants.subscription.billingType - optional
 * @param {array|null} data.variants.availabilities - optional
 * @param {string|null} data.variants.availabilities.locationId - requied if availabilities exists
 * @param {string|null} data.variants.availabilities.shippingType - requied if availabilities exists
 * @param {string|null} data.variants.availabilities.startDate - optional
 * @param {string|null} data.variants.availabilities.endDate - optional
 * @param {array|null} data.variants.prices - optional
 * @param {int|null} data.variants.prices.price - required if prices exists
 * @param {string|null} data.variants.prices.level - required if prices exists
 * @param {string|null} data.variants.prices.market - optional
 * @param {array|null} data.variants.prices.locations - optional
 * @param {string|null} data.variants.prices.locations.id - required if locations exists
 * @param {int|null} data.variants.prices.locations.price - required if locations exists

 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierProductBulkUpdateVariants = function (productId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.SUPPLIER_PRODUCT_BULK_UPDATE_VARIANTS, {
    id: productId,
  });

  const config = getSupplierRequestConfig(REQUEST_TYPES.PATCH, url);
  config.data = data;

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};
