export default {
  name: {
    presence: {
      message: '^validation.is_required'
    }
  },
  type: {
    presence: {
      message: '^validation.is_required'
    }
  },
  value: {
    presence: {
      message: '^validation.is_required'
    }
  },
}
