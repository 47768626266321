import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../../hocs';
import { COLORS } from '../../../helpers';
import { signOut } from '../../../store/actions';
import styles from './styles';

const propTypes = {
  history: PropTypes.object,
};

const ITEM_HEIGHT = 48;

class HeaderMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleSignOut = () => {
    this.setState({ anchorEl: null });
    this.props.signOut();
  };

  render() {
    const { intl, producer } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div>
        <Button
          style={{
            textTransform: 'none',
            color: COLORS.headerText,
          }}
          aria-owns={open ? 'long-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}>
          {producer.name} <KeyboardArrowDownIcon style={{ color: COLORS.headerText }} />
        </Button>

        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: 200,
            },
          }}>
          <MenuItem onClick={this.handleSignOut}>{intl.formatMessage({ id: 'global.logout' })}</MenuItem>
        </Menu>
      </div>
    );
  }
}

HeaderMenu.propTypes = propTypes;

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

const mapStateToProps = (state) => {
  return {
    producer: state.producer.object,
  };
};

export default withStyles(styles, { withTheme: true })(
  injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderMenu)))
);
