export default ({
  wrapper: {
    marginTop: 0,
    minHeight: 100,
  },
  chipsWrap: {
    width: '100%',
    marginTop: 10,
    justifyContent: 'center',
    display: 'flex'
  },
  chip: {
    margin: 5,
  },
  button: {
  }
});
