import React from "react";
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import DialogContent from '@material-ui/core/DialogContent';
import {withStyles} from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import {
  ErrorBox,
  AdminDialogTitle,
  Spinner,
  Icon,
  AdminDialog,
  PrimaryButton,
  PrimaryTextField,
  ErrorFormattedMessage,
} from '../../components';
import {
  validateField,
  USER_TYPES,
} from '../../helpers';
import validationRules from './validation';
import {
  //apiCustomerPatchWithAccessToken,
  //apiCustomerPatchAccountWithAccessToken
} from '../../api';
import styles from './styles';

class NewPasswordForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      requestSent: false,
      isLoading: false,
      password: "",
      passwordError: "",

      passwordRepeat: "",
      passwordRepeatError: "",

      requestError: ""
    };

    this.validate = this.validate.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    this.props.onClose();
  }

  validate() {
    if (this.state.isLoading)
      return false;

    const passwordError = validateField(validationRules, 'password', this.state.password);
    const passwordRepeatError = validateField(validationRules, 'passwordRepeat', this.state.passwordRepeat, {
      password: this.state.password
    });


    if (!passwordError && !passwordRepeatError) {
      this.setState({
        passwordError: "",
        passwordRepeatError: "",
        requestError: "",
        isLoading: true
      });

      /*const onError = (error) => {
        this.setState({
          requestError: "messages.somethingWentWrong",
          isLoading: false
        });
      };

      const onSuccess = (response) => {
        this.setState({
          requestError: "",
          isLoading: false,
          requestSent: true
        });
      };*/

      /*if (this.props.userType === USER_TYPES.ACCOUNT) {
        apiCustomerPatchAccountWithAccessToken(this.props.user.id, this.props.token, {
          password: this.state.password
        }, onSuccess, onError);
      } else {
        apiCustomerPatchWithAccessToken(this.props.user.id, this.props.token, {
          password: this.state.password
        }, onSuccess, onError);
      }*/
    } else {
      this.setState({
        passwordError: passwordError,
        passwordRepeatError: passwordRepeatError,
        isLoading: false
      });
    }
  }

  getForm() {
    const {classes, title, buttonTitle} = this.props;
    return (
      <React.Fragment>
        <AdminDialogTitle title={title}/>

        {this.state.isLoading ?
          <Spinner size={50}/> : null}

        <ErrorBox error={this.state.requestError ? <FormattedMessage id={this.state.requestError}/> : ""}/>
        <DialogContent>

          {/*<p style={{textAlign: 'center'}}>
            You have an account but you have not created a password yet.<br/>
            Please create password.
          </p>*/}

          <PrimaryTextField
            error={!!this.state.passwordError}
            onChange={event => this.setState({password: event.target.value.trim()})}
            value={this.state.password}
            id="password-input"
            margin="dense"
            label={<FormattedMessage id="global.password"/>}
            type="password"
            autoFocus
            helperText={<ErrorFormattedMessage id={this.state.passwordError}/>}
            fullWidth
          />

          <PrimaryTextField
            error={!!this.state.passwordRepeatError}
            onChange={event => this.setState({passwordRepeat: event.target.value.trim()})}
            value={this.state.passwordRepeat}
            id="password-repeat-input"
            margin="dense"
            label={<FormattedMessage id="global.confirmPassword"/>}
            type="password"
            helperText={<ErrorFormattedMessage id={this.state.passwordRepeatError}/>}
            fullWidth
          />
        </DialogContent>

        <div className={classes.controls}>
          <PrimaryButton onClick={this.validate}>
            {buttonTitle}
          </PrimaryButton>
        </div>
      </React.Fragment>
    )
  }

  getFinalMessage() {
    const {classes, finalMessage, finalOk} = this.props;
    return (
      <React.Fragment>
        <DialogContent className={classes.finalMessageContainer}>
          <Icon icon={DoneIcon} classes={{root: classes.envelope}}/>
          <p style={{textAlign: 'center'}}>{finalMessage}</p>
        </DialogContent>

        <div className={classes.controlsCentered}>
          <PrimaryButton onClick={this.props.onDone}>
            {finalOk}
          </PrimaryButton>
        </div>
      </React.Fragment>
    )
  }

  render() {
    return (
      <AdminDialog onClose={this.onClose}
                          open >
        {this.state.requestSent ?
          this.getFinalMessage() : this.getForm()}
      </AdminDialog>
    );
  }
}

NewPasswordForm.propTypes = {
  title: PropTypes.string,
  buttonTitle: PropTypes.string,
  finalMessage: PropTypes.string,
  token: PropTypes.string.isRequired,
  finalOk: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  userType: PropTypes.oneOf([
    USER_TYPES.CUSTOMER,
    USER_TYPES.ACCOUNT
  ]),
  onDone: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

NewPasswordForm.defaultProps = {
  title: <FormattedMessage id="newPassword.title"/>,
  buttonTitle: <FormattedMessage id="newPassword.changePassword"/>,
  finalMessage: <FormattedMessage id="newPassword.changed"/>,
  finalOk: <FormattedMessage id="global.ok"/>
};

export default withStyles(styles)(NewPasswordForm);