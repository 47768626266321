import {
  ACTIVITY_FILTER
} from '../FilterTypes'

export default class DXCSActivityFilter {

  static getConfig(intl, values, readOnly, invisible) {
    return {
      name: ACTIVITY_FILTER,
      title: intl.formatMessage({id: 'filters.activity'}),
      property: "active",
      type: ACTIVITY_FILTER,
      selectedValues: values,
      clientSide: true,
      readOnly: readOnly ? readOnly : false,
      invisible: invisible ? invisible : false,
    }
  }

  constructor(values, propertyName) {
    this.values = values;
    this.property = propertyName;
  }

  isSatisfy(row) {
    let values = [];
    this.values.forEach(value => {
      if (value.toLowerCase() === 'active') {
        values.push(true);
      }
      if (value.toLowerCase() === 'inactive') {
        values.push(false);
      }
    });

    return (
      values.length > 0 &&
      values.indexOf(row[this.property]) !== -1
    );
  }

}