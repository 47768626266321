import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../hocs';
import {
  AdminDialog,
  AdminDialogTitle,
  AdminDialogContent,
  AdminDialogControls,
  PrimaryButton,
  Spinner,
  OrderInfo,
} from '../../components';
import styles from './styles';

const propTypes = {
  order: PropTypes.object.isRequired,
  onReorder: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

const defaultProps = {
  loading: false,
};

const ReorderDialog = ({ onClose, onReorder, intl, classes, loading, order }) => {
  return (
    <AdminDialog open closeButton onClose={onClose}>
      <AdminDialogTitle title={intl.formatMessage({ id: 'global.reorder' })} />
      <AdminDialogContent className={classes.wrapper}>
        {loading && <Spinner size={50} />}

        <OrderInfo order={order} />

        <AdminDialogControls>
          <PrimaryButton className={classNames(classes.button, classes.mr20)} onClick={onClose}>
            {intl.formatMessage({ id: 'global.cancel' })}
          </PrimaryButton>

          <PrimaryButton className={classes.button} onClick={onReorder}>
            {intl.formatMessage({ id: 'global.reorder' })}
          </PrimaryButton>
        </AdminDialogControls>
      </AdminDialogContent>
    </AdminDialog>
  );
};

ReorderDialog.propTypes = propTypes;
ReorderDialog.defaultProps = defaultProps;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(ReorderDialog)));
