import React from 'react';
import { useIntl } from 'react-intl';
import { AdminLayout } from '../../components';
import { SidebarMenu, HeaderMenu } from '../Partials';
import styles from './styles';
import { makeStyles } from '@material-ui/core';
import AdminDxTableDesigned from '../../components/AdminDxTableDesigned';
import { apiCustomersSearch } from '../../api';
import { getGridPageSize, getGridPageSizes } from '../../helpers/grid';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { DEFAULT_MIN_CELL_WIDTH, INFINITY_MAX_CELL_WIDTH } from '../../helpers';
import { CellRenderer } from './helpers';
import { useNavigate } from 'react-router';

const useStyles = makeStyles(styles);

const MemberService = () => {
  const classes = useStyles();
  const intl = useIntl();
  const history = useNavigate();

  const columnsConfig = {
    columns: [
      { name: 'customerName', title: intl.formatMessage({ id: 'global.name' }) },
      { name: 'email', title: intl.formatMessage({ id: 'global.email' }) },
    ],
    columnExtensions: [],
    hiddenColumnNames: [],
    sorting: [],
    appliedFilters: [],
    defaultColumnWidths: [
      { columnName: 'customerName', width: 300 },
      { columnName: 'email', width: 'auto' },
    ],
  };

  return (
    <AdminLayout sidebarComponent={SidebarMenu} headerMenuComponent={HeaderMenu}>
      <div className={classes.root}>
        <AdminDxTableDesigned
          title={intl.formatMessage({ id: 'global.memberService' })}
          searchPlaceholder={intl.formatMessage({ id: 'global.searchForCustomer' })}
          pageSize={getGridPageSize()}
          pageSizes={getGridPageSizes()}
          actions={[
            {
              icon: <ArrowForward style={{ color: '#9B9B9B', fontSize: 18 }} />,
              action: () => {},
            },
          ]}
          actionsTitle={' '}
          apiRetrieve={apiCustomersSearch}
          enablePager
          serverSidePagination
          enableRemoteSearch
          minColumnWidth={DEFAULT_MIN_CELL_WIDTH}
          maxColumnWidth={INFINITY_MAX_CELL_WIDTH}
          columnResizingMode={'nextColumn'}
          cellRenderer={CellRenderer}
          onRowClick={(row) => {
            history(`/member-service/${row.id}`);
          }}
          {...columnsConfig}
        />
      </div>
    </AdminLayout>
  );
};

export default MemberService;
