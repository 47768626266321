import React from "react";
import {FormattedMessage} from 'react-intl';

const ErrorFormattedMessage = (props) => {
  const {id, ...other} = props;
  return (
    id ? <FormattedMessage id={id} {...other}/> : ""
  );
};

export default ErrorFormattedMessage;