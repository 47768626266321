import React from 'react';
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import DXSelectFilter from '../DXSelectFilter/DXSelectFilter'
import DXFilterConfig from "../DXFilterConfig";

const propTypes = {
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  selected: [],
};

class DXShippingTypeFilter extends React.Component {

  render() {
    const { selected, onChange, intl } = this.props;
    const config = DXShippingTypeFilter.getConfig(intl);
    return (
      <DXSelectFilter selected={selected}
                      title={config.title}
                      values={config.values}
                      onChange={onChange}
                      multi={config.multi}
      />
    )
  }
}

DXShippingTypeFilter.propTypes = propTypes;
DXShippingTypeFilter.defaultProps = defaultProps;
DXShippingTypeFilter.getLabel = (intl, selectedValues) => {
  return DXSelectFilter.getLabel(intl, 'global.', selectedValues);
};
DXShippingTypeFilter.applyToQueryParams = (queryParams, selectedValues) => {
  queryParams['shippingType'] = selectedValues;
  return queryParams;
};
DXShippingTypeFilter.getConfig = (intl) => {
  let config = new DXFilterConfig({
    name: 'shipping.type',
    selected: [],
    property: 'shippingType',
    title: intl.formatMessage({id: 'filter.shippingType'}) ,
    disabled: false,
    equalityStatus: false,
    multi: true
  });

  config.addFilterValue('delivery', intl.formatMessage({id: 'global.delivery'}));
  config.addFilterValue('pickup', intl.formatMessage({id: 'global.pickup'}));

  return config;
};

export default injectIntl(
  DXShippingTypeFilter
);
