import { COLORS } from './colors';

export const FONT_FAMILY = "'DM Sans', sans-serif";

export const FONT_SIZES = {
  title: 24,
  text: 15,
  smallText: 13,
  label: 12,
  textHeader: 18,
};

export const FONT_TITLE = {
  fontFamily: FONT_FAMILY,
  fontSize: FONT_SIZES.title,
  fontWeight: '700',
};
export const FONT_SUB_TITLE = {
  fontFamily: FONT_FAMILY,
  fontSize: FONT_SIZES.textHeader,
  fontWeight: '700',
};
export const FONT_CART_PRODUCT = {
  fontFamily: FONT_FAMILY,
  fontSize: FONT_SIZES.textHeader,
  fontWeight: '700',
};
export const FONT_CART_VARIANT_TITLE = {
  fontFamily: FONT_FAMILY,
  fontSize: FONT_SIZES.text,
  fontWeight: '700',
};
export const FONT_CART_VARIANT_SUB = {
  fontFamily: FONT_FAMILY,
  fontSize: FONT_SIZES.smallText,
  fontWeight: '500',
};
export const FONT_ORDER_NAME = {
  fontFamily: FONT_FAMILY,
  fontSize: FONT_SIZES.text,
  fontWeight: '500',
};
export const FONT_ORDER_TOTAL = {
  fontFamily: FONT_FAMILY,
  fontSize: FONT_SIZES.text,
  fontWeight: '400',
};
export const FONT_QUANTITY = {
  fontFamily: FONT_FAMILY,
  fontSize: FONT_SIZES.textHeader,
  fontWeight: '700',
};
export const FONT_LABEL = {
  fontFamily: FONT_FAMILY,
  fontSize: FONT_SIZES.label,
  fontWeight: '400',
  color: COLORS.blackTintedBlue,
};
export const FONT_TEXT_INPUT = {
  fontFamily: FONT_FAMILY,
  fontSize: FONT_SIZES.textHeader,
  fontWeight: '500',
  color: COLORS.blackTintedBlue,
};
export const FONT_TEXT_INPUT_MULTILINE = {
  fontFamily: FONT_FAMILY,
  fontSize: FONT_SIZES.text,
  fontWeight: '400',
  color: COLORS.blackTintedBlue,
};
export const FONT_BUTTON = {
  fontFamily: FONT_FAMILY,
  fontSize: FONT_SIZES.text,
  fontWeight: '700',
};
