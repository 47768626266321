import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../hocs';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { SidebarMenu } from '../Partials';
import HeaderMenu from '../Partials/HeaderMenu/HeaderMenu';
import { AdminTabs, AdminLayout, Spinner } from '../../components';
import { MAIN_TAB_NAMES } from '../../helpers/tabs';
import { TabTypes } from '../../dialogs';
import { apiCreateTab, apiPatchTab, apiDeleteTab } from '../../api';
import { setProducer } from '../../store/actions';
import { PRICE_LEVELS } from '../../helpers';
import UniqueProductsContent from './UniqueProductsContent';
import styles from './styles';

class UniqueProductsScene extends React.Component {
  constructor(props) {
    super(props);

    const { intl, tabs } = this.props;
    this.state = {
      tabIndex: tabs.getInitialIndex(window.location.hash, true),
      columnExtensions: [
        { columnName: 'selection', width: 120 },
        { columnName: 'image.src', width: 70 },
        { columnName: 'subType', width: 100 },
        { columnName: 'variant', width: 200 },
        { columnName: 'unit', width: 100 },
        { columnName: 'descriptions', width: 200 },
        { columnName: 'description', width: 400 },
        { columnName: 'wholesaleMarket', align: 'right' },
        { columnName: 'wholesalePreorder', align: 'right' },
        { columnName: 'retailMarket', align: 'right' },
        { columnName: 'active', width: 100, align: 'center' },
      ],
      columns: [
        { name: 'selection', title: ' ' },
        { name: 'image.src', title: ' ', type: 'image' },
        { name: 'customer', title: intl.formatMessage({ id: 'products.column.customer' }), type: 'customer' },
        { name: 'subType', title: intl.formatMessage({ id: 'products.column.subType' }), type: 'subType' },
        { name: 'variant', title: intl.formatMessage({ id: 'products.column.variant' }), type: 'variant' },
        { name: 'unit', title: intl.formatMessage({ id: 'products.column.unit' }), type: 'unit' },
        { name: 'description', title: intl.formatMessage({ id: 'products.column.description' }), type: 'description' },
        {
          name: 'wholesaleMarket',
          title: intl.formatMessage({ id: 'products.column.wholesaleMarket' }),
          type: PRICE_LEVELS.WHOLESALE_MARKET,
        },
        {
          name: 'wholesalePreorder',
          title: intl.formatMessage({ id: 'products.column.wholesalePreorder' }),
          type: PRICE_LEVELS.WHOLESALE_PREORDER,
        },
        {
          name: 'retailMarket',
          title: intl.formatMessage({ id: 'products.column.retailMarket' }),
          type: PRICE_LEVELS.RETAIL_MARKET,
        },
        {
          name: 'retailPreorder',
          title: intl.formatMessage({ id: 'products.column.retailPreorder' }),
          type: PRICE_LEVELS.RETAIL_PREORDER,
        },
        { name: 'active', title: intl.formatMessage({ id: 'products.column.active' }), type: 'active' },
      ],
    };
  }

  onCreateTab = (data, onDone) => {
    this.setState({ loading: true });
    apiCreateTab(
      data,
      (producer) => {
        this.props.setProducer(producer);
        this.setState((state) => {
          state.loading = false;
          onDone && onDone();
          return state;
        });
      },
      undefined
    );
  };

  onPatchTab = (tab, data, onDone) => {
    this.setState({ loading: true });
    apiPatchTab(
      tab.id,
      data,
      (producer) => {
        this.props.setProducer(producer);
        this.setState({ loading: false });
        onDone && onDone();
      },
      undefined
    );
  };

  onDeleteTab = (tab) => {
    this.setState({ loading: true });
    apiDeleteTab(
      tab.id,
      (producer) => {
        this.setState(
          {
            loading: false,
            tabIndex: 0,
          },
          () => {
            this.props.setProducer(producer);
          }
        );
      },
      undefined
    );
  };

  onPatchDefaultTab = (name, tab, data, onDone) => {
    const { tabs } = this.props;
    let ordersTab = tabs.getTabByName(name);
    if (ordersTab) {
      this.onPatchTab(ordersTab, data, onDone);
    } else {
      data.name = name;
      data.type = TabTypes.UNIQUE_PRODUCTS;
      this.onCreateTab(data);
    }
  };

  onChangeTab = (index) => {
    const { tabs } = this.props;
    window.location.hash = tabs.getHashByIndex(index);
    this.setState({ tabIndex: index });
  };

  render() {
    const { tabIndex, loading, columnExtensions } = this.state;

    const { intl, tabs, openAddModal } = this.props;

    const tabsTitles = tabs.getTabNames([
      intl.formatMessage({ id: 'products.tab.all' }),
      intl.formatMessage({ id: 'products.tab.active' }),
    ]);

    const customTabs = tabs.getOnlyCustom();
    let allTab = tabs.getTabByName(MAIN_TAB_NAMES.UNIQUE_PRODUCTS_ALL, intl.formatMessage({ id: 'global.products' }), {
      id: MAIN_TAB_NAMES.UNIQUE_PRODUCTS_ALL,
      name: intl.formatMessage({ id: 'global.products' }),
    });
    let activeTab = tabs.getTabByName(
      MAIN_TAB_NAMES.UNIQUE_PRODUCTS_ACTIVE,
      intl.formatMessage({ id: 'global.products' }),
      {
        id: MAIN_TAB_NAMES.UNIQUE_PRODUCTS_ACTIVE,
        name: intl.formatMessage({ id: 'global.products' }),
      }
    );

    return (
      <AdminLayout sidebarComponent={SidebarMenu} headerMenuComponent={HeaderMenu}>
        {loading && <Spinner size={60} />}
        <AdminTabs onChangeTab={this.onChangeTab} tab={tabIndex} tabs={tabsTitles}>
          {tabIndex === 0 && (
            <UniqueProductsContent
              tab={allTab}
              columns={this.state.columns}
              key={'all-products'}
              index={'all-products'}
              allowCreateTab
              columnExtensions={columnExtensions}
              onPatchTab={(tab, data, onDone) =>
                this.onPatchDefaultTab(MAIN_TAB_NAMES.UNIQUE_PRODUCTS_ALL, tab, data, onDone)
              }
              onCreateTab={this.onCreateTab}
              openAddModal={openAddModal}
            />
          )}

          {tabIndex === 1 && (
            <UniqueProductsContent
              tab={activeTab}
              columns={this.state.columns}
              key={'active-products'}
              index={'active-products'}
              onlyActive
              allowCreateTab
              columnExtensions={columnExtensions}
              onPatchTab={(tab, data, onDone) =>
                this.onPatchDefaultTab(MAIN_TAB_NAMES.UNIQUE_PRODUCTS_ACTIVE, tab, data, onDone)
              }
              onCreateTab={this.onCreateTab}
              openAddModal={openAddModal}
            />
          )}

          {!!customTabs.length && tabIndex > 1 && (
            <UniqueProductsContent
              tab={customTabs[tabIndex - 2]}
              columns={this.state.columns}
              key={`custom-${customTabs[tabIndex - 2].id}`}
              index={tabIndex}
              columnExtensions={columnExtensions}
              onPatchTab={this.onPatchTab}
              onDeleteTab={this.onDeleteTab}
              openAddModal={openAddModal}
            />
          )}
        </AdminTabs>
      </AdminLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tabs: state.producer.tabs[TabTypes.UNIQUE_PRODUCTS],
    producer: state.producer.object,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProducer: (producer) => dispatch(setProducer(producer)),
  };
};

export default withStyles(styles)(
  withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(UniqueProductsScene)))
);
