import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { COLORS } from '../../constants';

class Spinner extends React.Component {
  render() {
    const { size, classes } = this.props;
    const styles = {
      position: 'absolute',
      color: COLORS.text,
      overflow: 'hidden',
      margin: 'auto',
      height: size,
      width: size,
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: 999999,
    };
    return (
      <div style={styles}>
        <CircularProgress className={classes.spinner} size={size} />
      </div>
    );
  }
}

Spinner.propTypes = {
  size: PropTypes.number,
};

Spinner.defaultProps = {
  size: 80,
};

export default withStyles(styles)(Spinner);
