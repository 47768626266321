import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import classNames from 'classnames';
import { withRouter } from '../../hocs';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {
  AdminLayout,
  OrderLineItems,
  OrderSummary,
  Spinner,
  SceneTitle,
  PrimaryButton,
  Signature,
  Notification,
} from '../../components';
import styles from './styles';
import { SidebarMenu, HeaderMenu } from '../Partials';
import { ORDER_STATUSES, toCurrency, COLORS, isDelivered, capitalizeFirstLetter, SHIPPING_TYPES } from '../../helpers';
import { apiRetrieveOrder } from '../../api';
import { ReorderDialog } from '../../dialogs';
import { apiReorderOrder, apiApproveOrder } from '../../api';
import Address from './Address';
import PaymentMethod from './PaymentMethod';
import Prepurchase from './Prepurchase';
import Order from '../../entities/Order';
import RefundDialog from '../../dialogs/RefundDialog/RefundDialog';
import { Payment } from '../../entities';
import { hasSubscription } from '../../helpers/orders';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';

class OrderScene extends React.Component {
  state = {
    order: null,
    loading: true,
    reorder: false,
    refund: false,
    reordering: false,
    invoices: [],
    notification: false,
    refundCompleted: false,
    paymentEntity: {},
  };

  componentDidMount() {
    this.retrieveOrder();
  }

  retrieveOrder = () => {
    const { match } = this.props;
    apiRetrieveOrder(
      match.params.id,
      (order) =>
        this.setState({
          order,
          orderEntity: new Order(order),
          paymentEntity: new Payment(order.payment),
          loading: false,
        }),
      (error) => console.error(error)
    );
  };

  approveOrder = () => {
    const { match } = this.props;
    this.setState({ loading: true });
    apiApproveOrder(
      match.params.id,
      (order) => this.setState({ order: order, loading: false }),
      (error) => console.error(error)
    );
  };

  onRefundSuccess = (order) => {
    this.setState({
      ...this.state,
      order,
      refundCompleted: true,
    });
  };

  render() {
    const { intl, history, classes, onReorder, onEdit } = this.props;

    const { order, orderEntity, loading, refund, refundCompleted, paymentEntity } = this.state;

    return (
      <AdminLayout sidebarComponent={SidebarMenu} headerMenuComponent={HeaderMenu}>
        {loading && <Spinner size={80} />}
        <div className={classes.wrapper}>
          {order && (
            <Paper className={classes.left} elevation={1}>
              <div className={classes.titleWrapper}>
                <IconButton className={classes.backButton} onClick={() => history.goBack()}>
                  <ArrowBackIcon className={classes.backIcon} />
                </IconButton>
                <SceneTitle className={classes.title}>
                  {intl.formatMessage({ id: 'global.order' })} {order.name.replace('#', '')}
                </SceneTitle>
                <div className={classes.backButtonPlaceholder} />
              </div>
              <Typography className={classes.companyName}>{orderEntity.getCustomerName()}</Typography>

              <OrderLineItems order={order} />

              <div className={classes.summaryContainerWrapper}>
                <div style={{ flex: 5, marginLeft: 70 }} />
                <OrderSummary
                  containerClass={classes.summaryContainer}
                  order={order}
                  discount={order.discount && toCurrency(order.discount.amount, intl)}
                  fulfillmentFee={
                    paymentEntity.getFulfillmentFee() ? toCurrency(paymentEntity.getFulfillmentFee(), intl) : null
                  }
                  subtotal={toCurrency(order.subtotal, intl)}
                  shipping={toCurrency(paymentEntity.getShippingFee(), intl)}
                  total={toCurrency(order.total, intl)}
                />
                <div style={{ flex: 2.8 }} />
              </div>
            </Paper>
          )}

          <div className={classes.rightColumn}>
            {this.state.loading && <Spinner size={80} />}

            {order && (
              <React.Fragment>
                <div className={classes.blackButtonWrapper}>
                  {orderEntity.isEditable() && (
                    <PrimaryButton
                      className={classes.blackButton}
                      onClick={() => (!!onEdit ? onEdit(order) : history.push('/order/edit/' + order.id))}>
                      {intl.formatMessage({ id: 'order.edit' })}
                    </PrimaryButton>
                  )}

                  {(orderEntity.isPaid() || orderEntity.isPartiallyRefunded()) && (
                    <PrimaryButton className={classes.blackButton} onClick={() => this.setState({ refund: true })}>
                      {intl.formatMessage({ id: 'global.refund' })}
                    </PrimaryButton>
                  )}

                  <PrimaryButton className={classes.blackButton} onClick={() => this.setState({ reorder: true })}>
                    {intl.formatMessage({ id: 'global.reorder' })}
                  </PrimaryButton>

                  {(isDelivered(order) || hasSubscription(order)) && (
                    <div className={classNames([classes.separator, classes.mt0])} />
                  )}

                  {hasSubscription(order) && (
                    <PrimaryButton
                      className={classes.blackButton}
                      onClick={() => history.push(`/subscriptions/${order?.subscription}`)}>
                      {intl.formatMessage({ id: 'global.viewSubscription' })}
                    </PrimaryButton>
                  )}

                  {isDelivered(order) && (
                    <PrimaryButton
                      className={classes.blackButton}
                      onClick={() => {
                        window.open(`/order/${order.id}/invoice/${order.hash}`, '_blank');
                      }}>
                      {intl.formatMessage({ id: 'global.viewInvoice' })}
                    </PrimaryButton>
                  )}
                </div>

                <Paper className={classes.rightBox} elevation={1}>
                  {order.status === ORDER_STATUSES.ORDER_NOT_APPROVED && (
                    <div style={{ textAlign: 'center' }}>
                      <Typography variant={'h2'} style={{ color: COLORS.violet }} className={classes.rightHeader}>
                        {intl.formatMessage({ id: 'global.notApproved' })}
                      </Typography>
                      <PrimaryButton disabled={loading} style={{ width: '80%' }} onClick={() => this.approveOrder()}>
                        {intl.formatMessage({ id: 'global.approveOrder' })}
                      </PrimaryButton>
                    </div>
                  )}

                  {order.status === ORDER_STATUSES.ORDER_RECEIVED && (
                    <React.Fragment>
                      <Typography variant={'h2'} style={{ color: COLORS.text }} className={classes.rightHeader}>
                        {intl.formatMessage({ id: 'global.received' })}
                      </Typography>
                      <hr className={classes.separator} />
                    </React.Fragment>
                  )}

                  {order.payment && (
                    <React.Fragment>
                      <Typography style={{ marginTop: 10 }} className={classNames(classes.rightBoxText, classes.bold)}>
                        {intl.formatMessage({ id: 'payment.method' })}: <PaymentMethod order={order} />
                      </Typography>
                      <Typography className={classes.rightBoxText}>
                        {order.payment.autoCharge
                          ? intl.formatMessage({ id: 'payment.chargeAuto' })
                          : intl.formatMessage({ id: 'payment.chargeManual' })}
                      </Typography>
                      {paymentEntity.initiatedAt && (
                        <Typography className={classes.rightBoxText}>
                          {`${intl.formatMessage({ id: 'payment.initiatedAt' })}: ${moment
                            .parseZone(paymentEntity.initiatedAt)
                            .format(process.env.REACT_APP_DATE_FORMAT_WITH_MONTH_DAY_AND_YEAR)}`}
                        </Typography>
                      )}

                      {paymentEntity.getBalanceAvailableOn() && (
                        <Typography className={classes.rightBoxText}>
                          {intl.formatMessage({ id: 'payment.balanceAvailableOn' })}:{' '}
                          {moment
                            .parseZone(paymentEntity.getBalanceAvailableOn())
                            .format(process.env.REACT_APP_DATE_FORMAT_WITH_MONTH_DAY_AND_YEAR)}
                        </Typography>
                      )}
                    </React.Fragment>
                  )}

                  {isDelivered(order) && order.receivedAt && (
                    <React.Fragment>
                      <hr className={classes.separator} />
                      <Typography style={{ marginTop: 10 }} className={classNames(classes.rightBoxText, classes.bold)}>
                        {intl.formatMessage({ id: 'global.receivedByCustomer' })}&nbsp;
                        {moment
                          .parseZone(order.receivedAt)
                          .format(process.env.REACT_APP_DATE_FORMAT_WITH_MONTH_DAY_YEAR_AND_TIME)}
                        &nbsp;
                      </Typography>
                      {order.signature && <Signature signature={order.signature} />}
                    </React.Fragment>
                  )}

                  {order.shipping && order.shipping.deliveryCompany && order.shipping.deliveryCompany.outForDeliveryAt && (
                    <React.Fragment>
                      <hr className={classes.separator} />
                      <Typography style={{ marginTop: 10 }} className={classNames(classes.rightBoxText, classes.bold)}>
                        {intl.formatMessage({ id: 'global.pickedUpByDriver' })}&nbsp;
                        {moment
                          .parseZone(order.shipping.deliveryCompany.outForDeliveryAt)
                          .format(process.env.REACT_APP_DATE_FORMAT_WITH_MONTH_DAY_YEAR_AND_TIME)}
                      </Typography>
                      {order.shipping && order.shipping.deliveryCompany && order.shipping.deliveryCompany.signature && (
                        <Signature signature={order.shipping.deliveryCompany.signature} />
                      )}
                      <Typography style={{ marginTop: 10 }} className={classNames(classes.rightBoxText)}>
                        {order.shipping.deliveryCompany.by && `${order.shipping.deliveryCompany.by.name} - `}
                        {order.shipping.deliveryCompany.name}
                      </Typography>
                    </React.Fragment>
                  )}

                  {order.packedAt && (
                    <React.Fragment>
                      <hr className={classes.separator} />
                      <Typography style={{ marginTop: 10 }} className={classNames(classes.rightBoxText, classes.bold)}>
                        {intl.formatMessage({ id: 'global.packed' })}&nbsp;
                        {moment
                          .parseZone(order.packedAt)
                          .format(process.env.REACT_APP_DATE_FORMAT_WITH_MONTH_DAY_YEAR_AND_TIME)}
                      </Typography>

                      {order.packedBy && (
                        <Typography className={classes.rightBoxText}>
                          {capitalizeFirstLetter((order.packedBy && order.packedBy.name) || '')}
                        </Typography>
                      )}
                    </React.Fragment>
                  )}

                  {order.createdAt && (
                    <React.Fragment>
                      <hr className={classes.separator} />
                      <Typography style={{ marginTop: 10 }} className={classNames(classes.rightBoxText, classes.bold)}>
                        {intl.formatMessage({ id: 'global.createdIn' })}&nbsp;{order.source}&nbsp;
                        {moment
                          .parseZone(order.createdAt)
                          .format(process.env.REACT_APP_DATE_FORMAT_WITH_MONTH_DAY_YEAR_AND_TIME)}
                      </Typography>

                      {order.createdBy && (
                        <Typography className={classes.rightBoxText}>{order.createdBy.name}</Typography>
                      )}
                    </React.Fragment>
                  )}
                </Paper>

                <Paper className={classes.rightBox} elevation={1}>
                  <Typography style={{ marginTop: 10 }} className={classNames(classes.rightBoxText, classes.bold)}>
                    {orderEntity.getCustomerName()}
                  </Typography>

                  {!!orderEntity.getCustomer().email && (
                    <Typography className={classNames(classes.rightBoxText)}>
                      {orderEntity.getCustomer().email}
                    </Typography>
                  )}

                  {!!orderEntity.getCustomer().phone && (
                    <Typography className={classNames(classes.rightBoxText)}>
                      {orderEntity.getCustomer().phone}
                    </Typography>
                  )}

                  <Prepurchase order={order} />

                  {order.shipping && (
                    <div style={{ marginBottom: 10 }}>
                      <hr className={classes.separator} />
                      <Typography style={{ marginTop: 10 }} className={classNames(classes.rightBoxText, classes.bold)}>
                        {capitalizeFirstLetter(order.shipping.type)}&nbsp;-&nbsp;
                        {moment
                          .parseZone(order.shipping.date)
                          .format(process.env.REACT_APP_DATE_FORMAT_WITH_MONTH_DAY_AND_YEAR)}
                      </Typography>

                      {/* Show delivery company name for shipping type = delivery */}
                      {order.shipping.type === SHIPPING_TYPES.SHIPPING_DELIVERY && order.shipping.deliveryCompany && (
                        <Typography className={classNames(classes.rightBoxText)}>
                          {capitalizeFirstLetter(order.shipping.deliveryCompany.name)}
                        </Typography>
                      )}

                      {/* Show delivery company name for shipping type = pickup */}
                      {order.shipping.type === SHIPPING_TYPES.SHIPPING_PICKUP && order.shipping.location && (
                        <Typography className={classNames(classes.rightBoxText)}>
                          {capitalizeFirstLetter(order.shipping.location.name)}
                        </Typography>
                      )}
                    </div>
                  )}

                  {order.note && (
                    <React.Fragment>
                      <hr className={classes.separator} />
                      <Typography style={{ marginTop: 10 }} className={classNames(classes.rightBoxText, classes.bold)}>
                        {intl.formatMessage({ id: 'global.note' })}
                      </Typography>
                      <Typography className={classes.rightBoxText}>{order.note}</Typography>
                    </React.Fragment>
                  )}

                  {order.customer && order.customer.shippingAddress && (
                    <Address
                      address={order.customer.shippingAddress}
                      title={intl.formatMessage({ id: 'address.shipping' })}
                    />
                  )}
                  {order.customer && order.customer.billingAddress && (
                    <Address
                      address={order.customer.billingAddress}
                      title={intl.formatMessage({ id: 'address.billing' })}
                    />
                  )}
                </Paper>
              </React.Fragment>
            )}
          </div>
        </div>

        {this.state.loading && <Spinner size={80} />}

        {this.state.reorder && (
          <ReorderDialog
            order={order}
            loading={this.state.reordering}
            onClose={() => this.setState({ reorder: false })}
            onReorder={() => {
              this.setState({ reordering: true });
              apiReorderOrder(
                order.id,
                (draft) => {
                  this.setState({ reordering: false });

                  !!onReorder ? onReorder(draft) : history.push('/draft/' + draft.id);
                },
                (error) => {
                  if (error && error.response && error.response.data.code === 2016) {
                    this.setState({
                      notification: intl.formatMessage({ id: 'error.canNotReorder2016' }),
                      reordering: false,
                      reorder: false,
                    });
                  }
                }
              );
            }}
          />
        )}

        {refund && (
          <RefundDialog
            order={order}
            onClose={() =>
              this.setState({
                refund: false,
                refundCompleted: false,
              })
            }
            onRefundSuccess={this.onRefundSuccess}
            completed={refundCompleted}
          />
        )}

        {this.state.notification && (
          <Notification message={this.state.notification} onClose={() => this.setState({ notification: '' })} />
        )}
      </AdminLayout>
    );
  }
}

OrderScene.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(injectIntl(OrderScene)));
