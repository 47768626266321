import axios from 'axios';
import { replaceUrlPlaceholder, replaceUrlPlaceholders, getRequestConfig, REQUEST_TYPES } from '../request';
import { SearchProductsParams } from '../../entities';
import API_END_POINTS from '../endpoints';

/**
 * Get product
 * @param {string} productId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProductGet = function (productId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRODUCT_GET_ONE, 'pr', productId);
  const config = getRequestConfig(REQUEST_TYPES.GET, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Get list of products
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiGetListOfProducts = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.PRODUCT_GET_ALL);
  let params = new SearchProductsParams(data);
  config.params = params.toData();

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Create product
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiAddProduct = function (data = {}, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.PRODUCT_CREATE);
  // Set request params
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Updates product
 * @param {string} productId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiEditProduct = function (productId, data = {}, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRODUCT_EDIT, 'pr', productId);
  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  // Set request params
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Deletes product
 * @param {string} productId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProductDelete = function (productId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRODUCT_REMOVE, 'pr', productId);
  const config = getRequestConfig(REQUEST_TYPES.DEL, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Add variant to product
 * @param {string} productId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiAddVariantToProduct = function (productId, data = {}, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRODUCT_CREATE_VARIANT, 'pr', productId);
  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  // Set request params
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Updates variant
 * @param {string} productId
 * @param {string} variantId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiEditProductVariant = function (productId, variantId, data = {}, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.PRODUCT_EDIT_VARIANT, {
    pr: productId,
    va: variantId,
  });
  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  // Set request params
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Deletes product variant
 * @param {string} productId
 * @param {string} variantId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProductVariantDelete = function (productId, variantId, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.PRODUCT_REMOVE_VARIANT, {
    pr: productId,
    va: variantId,
  });
  const config = getRequestConfig(REQUEST_TYPES.DEL, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Activate product statuses
 * @param {array} ids - product ids
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiActivateProducts = function (ids, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.PRODUCT_BULK_STATUSES, {
    status: 'activate',
  });
  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  config.data = {
    productIds: ids,
  };
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Inactivate product statuses
 * @param {array} ids - product ids
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiInactivateProducts = function (ids, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.PRODUCT_BULK_STATUSES, {
    status: 'inactivate',
  });
  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  config.data = {
    productIds: ids,
  };

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Activate variants statuses
 * @param {array} ids - product ids
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiActivateVariants = function (ids, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.PRODUCT_BULK_STATUSES, {
    status: 'activate',
  });

  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  config.data = {
    variantIds: ids,
  };

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Change offered prices
 * @param {string} productId
 * @param {string} variantId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiChangeVariantsOffered = function (productId, variantId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.PRODUCT_CHANGE_OFFERED, {
    pr: productId,
    va: variantId,
  });

  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  config.data = {
    ...data,
  };

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Inactivate variant statuses
 * @param {array} ids - product ids
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiInactivateVariants = function (ids, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.PRODUCT_BULK_STATUSES, {
    status: 'inactivate',
  });

  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  config.data = {
    variantIds: ids,
  };

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Delete products
 * @param {array} ids - product ids
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDeleteProducts = function (ids, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.DEL, API_END_POINTS.PRODUCT_BULK_REMOVE);
  config.data = {
    productIds: ids,
  };
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Delete variants
 * @param {array} ids - variants ids
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDeleteVariants = function (ids, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.DEL, API_END_POINTS.PRODUCT_BULK_REMOVE);
  config.data = {
    variantIds: ids,
  };
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Get all available locations to send price for
 * @param {string} priceLevel
 */
export const apiPriceAllAvailableLocationsGet = (priceLevel) =>
  new Promise((resolve, reject) => {
    const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.PRODUCT_PRICES_GET_AVAILABLE_LOCATIONS);

    config.params = {
      priceLevel: priceLevel,
    };

    axios(config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

/**
 * Get locations that can be inherited
 * @param {string} priceLevel
 */
export const apiPriceBaseLocationsGet = (priceLevel) =>
  new Promise((resolve, reject) => {
    const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.PRODUCT_PRICES_GET_BASE_LOCATIONS);

    config.params = {
      priceLevel: priceLevel,
    };

    axios(config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

/**
 * Get locations that can be inherited
 * @param {string} priceLevel
 * @param {string} locationId
 * @param {string} baseLocationId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiPriceSet = function (priceLevel, locationId, baseLocationId, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.PRODUCT_PRICES_SET_PRICES);

  config.data = {
    priceLevel: priceLevel,
    locationId: locationId,
    ...(baseLocationId ? { baseLocationId: baseLocationId } : {}),
  };

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Get list of products prices location
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiGetListOfProductsPricesLocations = function (onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.PRODUCT_GET_PRICES_LOCATIONS);

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Updates variant
 * @param {string} productId
 * @param {string} variantId
 * @param {string} priceLevel
 * @param {string} locationId
 * @param {number} price
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiEditProductVariantPriceByLocation = function (
  productId,
  variantId,
  priceLevel,
  locationId,
  price,
  onSuccess,
  onError
) {
  const config = getRequestConfig(REQUEST_TYPES.PATCH, API_END_POINTS.PRODUCT_SET_PRICES_BY_LOCATIONS);

  // Set request params
  config.data = {
    productId,
    variantId,
    priceLevel,
    locationId,
    price,
  };

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Get product available locations
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiOfferedMarketPresetsGetLocations = function (onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.PRODUCT_OFFERED_MARKET_PRESETS_GET_LOCATIONS);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Get product available locations
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiGetOfferedMarketPresets = function (onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.PRODUCT_GET_OFFERED_MARKET_PRESETS);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Get product available locations
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiOfferedMarketPresetsSetLocations = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.PRODUCT_OFFERED_MARKET_PRESETS_SET_LOCATIONS);

  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Change product available offered market presets
 * @param {string} productId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProductChangeOfferedMarketsPresetsList = function (productId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.PRODUCT_CHANGE_OFFERED_MARKETS_PRESETS_LIST, {
    pr: productId,
  });

  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);

  config.data = { offeredMarkets: data };
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Send products list in csv
 * @param {object} formData
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSendProductsListCSV = function (formData, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.PRODUCT_UPLOAD_PRODUCT_LIST_CSV);

  config.headers = {
    ...config.headers,
    'Content-Type': 'multipart/form-data',
  };
  config.data = formData;

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Add inventory to all variants in product
 * @param {string} productId
 * @param {object} data
 * @param {number} data.quantity - 100, quantity
 * @param {boolean|undefined} data.managed - true, (optional, default: false),
 * @param {boolean|undefined} data.stopSelling - false (optional, default: false)
 * @param {function} onSuccess
 * @param {function|undefined} onError
 */
export const apiAddInventoryToAllProductVariants = function (productId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.PRODUCT_SET_INVENTORY_TO_VARIANTS, {
    pr: productId,
  });

  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  config.data = {
    inventory: {
      ...data,
    },
  };

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Update inventory to single variant
 * @param {string} productId
 * @param {string} variantId
 * @param {object|null} inventory
 * @param {number} inventory.quantity - 100, quantity
 * @param {boolean|undefined} inventory.managed - true, (optional, default: false),
 * @param {boolean|undefined} inventory.stopSelling - false (optional, default: false)
 * @param {function} onSuccess
 * @param {function|undefined} onError
 */
export const apiChangeInventoryOnProductVariant = function (
  productId,
  variantId,
  inventory,
  onSuccess,
  onError = undefined
) {
  const url = replaceUrlPlaceholders(API_END_POINTS.PRODUCT_CHANGE_INVENTORY_TO_SINGLE_VARIANT, {
    pr: productId,
    va: variantId,
  });

  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  let data = {
    inventory: null,
  };

  if (inventory !== null) {
    data = { inventory: inventory };
  }

  config.data = data;

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Archive items statuses
 * @param {object} data
 * @param {string[]} data.productIds - product ids
 * @param {string[]} data.variantIds - variant ids
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiArchiveItem = function ({ productIds, variantIds }, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.PRODUCT_BULK_STATUSES, {
    status: 'archive',
  });

  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  config.data = {
    productIds,
    variantIds,
  };

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Un archive items statuses
 * @param {object} data
 * @param {string[]} data.productIds - product ids
 * @param {string[]} data.variantIds - variant ids
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiUnArchiveItem = function ({ productIds, variantIds }, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.PRODUCT_BULK_STATUSES, {
    status: 'unarchive',
  });

  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  config.data = {
    productIds,
    variantIds,
  };

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};
