import React from 'react';
import { SalesIcon, InventoryIcon } from '../../assets';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import Title from '../Title';
import IconSeparatedTextList from '../IconSeparatedTextList';
import { BUTTON_VARIANTS } from '../../constants/globals';
import PrimaryButton from '../SupplierButton';
import { useDispatch } from 'react-redux';
import { toggleSupplierPopover } from '../../../../store/actions';

const useStyles = makeStyles(styles);

const FirstSaleContent = () => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const onClose = () => dispatch(toggleSupplierPopover(null));

  return (
    <>
      <Title className={classes.title} text={intl.formatMessage({ id: 'supplier.popover.firstSale.title' })} />

      <div className={classes.iconSeparatedTextWrapper}>
        <IconSeparatedTextList
          icon={InventoryIcon}
          title={intl.formatMessage({ id: 'supplier.popover.firstSale.inventoryNumbersWillDecrease' })}
          text={intl.formatMessage({ id: 'supplier.popover.firstSale.inventoryShowsWhatYouHaveRemaining' })}
        />
        <IconSeparatedTextList
          icon={SalesIcon}
          title={intl.formatMessage({ id: 'supplier.popover.firstSale.seeYourSalesInRealTime' })}
          text={intl.formatMessage({ id: 'supplier.popover.firstSale.updateContinuouslyAsSalesComeIn' })}
        />
      </div>

      <PrimaryButton
        classes={{ root: classes.button }}
        variant={BUTTON_VARIANTS.blue}
        text={intl.formatMessage({ id: 'supplier.popover.firstSale.button' })}
        link={'/supplier/sales'}
        onClick={onClose}
      />
    </>
  );
};

export default FirstSaleContent;
