import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withRouter } from '../../hocs';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import { COLORS, toPaper } from '../../helpers';
import { PrimaryButton, Spinner, ErrorBox, Icon } from '../../components';
import { apiProducerInvoicesGetOne, apiProducerPayInvoice } from '../../api';

const styles = {
  wrapper: {
    margin: '0 50px',
  },
  name: {
    fontSize: 18,
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.text,
    marginBottom: 40,
  },
  variants: {
    marginBottom: 50,
  },
  input: {
    marginBottom: 25,
  },
  inputText: {
    textAlign: 'right',
  },
  button: {
    minWidth: '40%',
    textTransform: 'uppercase',
  },
  mr20: {
    marginRight: 10,
  },
  controls: {
    margin: '27px 0 0 0',
    textAlign: 'center',
  },
  control: {
    padding: '0 105px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    flex: 1,
  },
  details: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 30,
  },
  bankAccount: {
    textAlign: 'center',
    marginBottom: 30,
  },
  text: {
    fontSize: 14,
    color: COLORS.textSecondary,
    fontFamily: 'Roboto, sans-serif',
  },
  finalTextStyle: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  finalIconCheckStyle: {
    fontSize: 42,
  },
};

const propTypes = {
  producer: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

class PayInvoiceForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
      loading: true,
      paymentMethod: '',
      invoice: null,
      showSuccessScreen: false,
    };
  }

  componentDidMount() {
    this._fetch();
  }

  _fetch = () => {
    const { match, intl } = this.props;
    apiProducerInvoicesGetOne(
      match.params.id,
      (invoice) => {
        this.setState({
          invoice,
          loading: false,
        });
      },
      () => {
        this.setState({
          error: intl.formatMessage({ id: 'messages.somethingWentWrong' }),
          loading: false,
        });
      }
    );
  };

  _handleSubmit = (e) => {
    e.preventDefault();

    const { invoice } = this.state;
    const { onSuccess, intl } = this.props;

    this.setState({ loading: true });
    apiProducerPayInvoice(
      invoice.id,
      (data) => {
        this.setState(
          {
            error: '',
            loading: false,
            showSuccessScreen: true,
          },
          () => {
            onSuccess && onSuccess(data);
          }
        );
      },
      (error) => {
        this.setState({
          error:
            (error.response.data && error.response.data.message) ||
            intl.formatMessage({ id: 'messages.somethingWentWrong' }),
          loading: false,
        });
      }
    );
  };

  drawFinalScreen = () => {
    const { intl, classes, onClose } = this.props;
    return (
      <div style={{ textAlign: 'center' }}>
        <div className={classes.details}>
          <Icon icon={CheckIcon} className={classes.finalIconCheckStyle} style={{ fill: 'rgba(0, 0, 0, 0.5)' }} />
        </div>

        <div className={classes.actionsWrap}>
          <PrimaryButton onClick={onClose}>{intl.formatMessage({ id: 'global.ok' })}</PrimaryButton>
        </div>
      </div>
    );
  };

  render() {
    const {
      props: { intl, classes, producer },
      state: { error, loading, invoice, showSuccessScreen },
    } = this;

    return (
      <>
        {!showSuccessScreen && (
          <form onSubmit={this._handleSubmit} className={classes.form}>
            {loading && <Spinner size={40} />}
            {error && <ErrorBox error={error} />}

            {!loading && invoice && (
              <React.Fragment>
                {!!producer.invoiceSettings && !!producer.invoiceSettings.paymentMethod && (
                  <div style={{ textAlign: 'center' }}>
                    <div className={classes.details}>
                      {intl.formatMessage({ id: 'global.number' })} {invoice.number} / ${toPaper(invoice.total)}
                    </div>

                    <div className={classes.bankAccount}>
                      <Typography style={{ fontWeight: 'bold' }} className={classes.text} variant={'body1'}>
                        {intl.formatMessage({ id: 'global.bankAccount' })}
                      </Typography>
                      <Typography className={classes.text} variant={'body1'}>
                        {producer.invoiceSettings.paymentMethod.bankName || ''}
                      </Typography>
                      <Typography className={classes.text} variant={'body1'}>
                        •••• •••• •••• {producer.invoiceSettings.paymentMethod.last4 || ''}
                      </Typography>
                      <Typography className={classes.text} variant={'body1'}>
                        {(producer.invoiceSettings.paymentMethod.currency || '').toUpperCase()}
                      </Typography>
                    </div>

                    <div className={classes.actionsWrap}>
                      <PrimaryButton disabled={loading} type="submit">
                        {intl.formatMessage({ id: 'global.pay' })}
                      </PrimaryButton>
                    </div>
                  </div>
                )}

                {(!!!producer.invoiceSettings || !!!producer.invoiceSettings.paymentMethod) && (
                  <div style={{ textAlign: 'center' }}>
                    <div className={classes.bankAccount}>
                      <Typography className={classes.text} variant={'body1'}>
                        {intl.formatMessage({ id: 'messages.needToAddBankAccount' })}
                      </Typography>
                    </div>

                    <div className={classes.actionsWrap}>
                      <PrimaryButton onClick={() => (window.location = '/settings#payment')} disabled={loading}>
                        {intl.formatMessage({ id: 'global.addBankAccount' })}
                      </PrimaryButton>
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
          </form>
        )}

        {showSuccessScreen && this.drawFinalScreen()}
      </>
    );
  }
}

PayInvoiceForm.propTypes = propTypes;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(PayInvoiceForm)));
