import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import { apiDiscountRetrieve, apiDiscountPatch, apiOrderAddDirectDiscount, apiDraftAddDirectDiscount } from '../../api';
import { PrimaryTextField, PrimaryButton, Spinner, ChipsSelect } from '../../components';
import { toCoins, validateField, toPaper, ORDER_STATUSES } from '../../helpers';
import validationRules from './validation';

const styles = {
  actionsWrap: {
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
    paddingTop: 60,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: 300,
  },
};

const propTypes = {
  order: PropTypes.object.isRequired,
};

const defaultProps = {};

class DirectDiscountForm extends Component {
  constructor(props) {
    super(props);

    const { order } = this.props;
    this.addDiscount =
      order.status === ORDER_STATUSES.ORDER_DRAFT ? apiDraftAddDirectDiscount : apiOrderAddDirectDiscount;

    this.state = {
      type: 'percentage',
      value: '',
      errors: {},
      loading: false,
    };
  }

  componentWillUnmount() {
    this.addDiscount = null;
  }

  componentDidMount() {
    const {
      match: {
        params: { did },
      },
    } = this.props;
    if (did && did !== 'new') {
      this.setState({ loading: true });
      apiDiscountRetrieve(
        did,
        (res) => {
          this.setState({
            ...res,
            ...{
              loading: false,
              value: toPaper(res.value),
            },
          });
        },
        (err) => {
          console.warn(err);
        }
      );
    }
  }

  _handleChange = (key, value) => {
    this.setState({ [key]: value, errors: { ...this.state.errors, [key]: null } });
  };

  _validate = () => {
    const { type, value } = this.state;
    let errors = {};

    errors.type = validateField(validationRules, 'type', type);
    errors.value = validateField(validationRules, 'value', value);

    this.setState({ errors });
    return Object.values(errors).every((item) => item === null);
  };

  _handleSubmit = (e) => {
    e.preventDefault();

    const { value, type } = this.state;

    const { order } = this.props;

    const valid = this._validate();

    if (!valid) return;

    let data = {
      value: toCoins(value),
      type,
    };

    const {
      match: {
        params: { did },
      },
    } = this.props;
    this.setState({ loading: true });
    if (did === 'new') {
      this.addDiscount(
        order.id,
        data,
        (response) => {
          this.setState({ loading: false });
          this.props.onSuccess(response);
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      apiDiscountPatch(
        did,
        data,
        (response) => {
          this.setState({ loading: false });
          this.props.onSuccess(response);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  render() {
    const { intl, classes } = this.props;

    const {
      match: {
        params: { did },
      },
    } = this.props;
    const { errors } = this.state;

    return (
      <form onSubmit={this._handleSubmit} className={classes.form}>
        {this.state.loading && <Spinner size={40} />}

        <ChipsSelect
          onChange={(type) => this.setState({ type })}
          multiple={false}
          label={intl.formatMessage({ id: 'global.type' })}
          values={[
            { label: intl.formatMessage({ id: 'global.percentage' }), key: 'percentage' },
            { label: intl.formatMessage({ id: 'global.amount' }), key: 'amount' },
          ]}
          selected={this.state.type}
        />

        <PrimaryTextField
          value={this.state.value}
          label={intl.formatMessage({ id: 'global.discount' })}
          onChange={(event) => this._handleChange('value', event.target.value)}
          helperText={errors.value && intl.formatMessage({ id: errors.value })}
          InputProps={{
            endAdornment: <InputAdornment position="end">{this.state.type === 'amount' ? '$' : '%'}</InputAdornment>,
          }}
          error={!!errors.value}
        />

        <div className={classes.actionsWrap}>
          <PrimaryButton type="submit">
            {did === 'new'
              ? intl.formatMessage({ id: 'global.addDiscount' })
              : intl.formatMessage({ id: 'global.save' })}
          </PrimaryButton>
        </div>
      </form>
    );
  }
}

DirectDiscountForm.propTypes = propTypes;
DirectDiscountForm.defaultProps = defaultProps;

export default withStyles(styles)(injectIntl(DirectDiscountForm));
