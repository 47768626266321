const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  mb20: {
    marginBottom: 20,
  },
  selectFormControl: {
    width: '100%',
    marginBottom: 20
  },
  hide: {
    display: 'none'
  },
  formControl: {
  },
  actions: {
    marginTop: 48,
    display: 'flex',
    justifyContent: 'center',
  }
});

export default styles;
