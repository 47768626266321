import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { CellAsIntegerInput } from '../../../components/AdminDXTable/Editing';
import { COLORS, weightToSystem, weightToUser } from '../../../helpers';
import { apiChangeInventoryOnProductVariant } from '../../../api';
import BackspaceIcon from '@material-ui/icons/Backspace';
import IconButton from '@material-ui/core/IconButton';
import { showTemporaryTooltip } from '../../../store/actions/common';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(() => ({
  messageWrapper: {
    cursor: 'pointer',
  },
  messageText: {
    fontSize: 14,
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.text,
    display: 'inline-block',
  },
  resetButton: {
    marginLeft: 10,
    cursor: 'pointer',
  },
  resetButtonIcon: {
    fontSize: 22,
  },
}));

const propTypes = {
  onSuccess: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
};

function VariantInventoryCell(props) {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const { onSuccess, cellProps, row } = props;

  const getViewValue = React.useCallback(() => {
    const quantity = row?.inventory?.quantity;
    let title = intl.formatMessage({ id: 'global.inventory.set' });
    let message = intl.formatMessage({ id: 'global.inventory.unlimited' });

    if (quantity) {
      title = intl.formatMessage({ id: 'global.inventory.change' });
      message = weightToUser(row.inventory.quantity);
    }

    if (quantity === 0) {
      message = intl.formatMessage({ id: 'global.inventory.soldOut' });
    }

    return (
      <div title={title} className={classes.messageWrapper}>
        <p className={classes.messageText}>{message}</p>
        {getResetButton()}
      </div>
    );
  }, [row?.inventory?.quantity]);

  const getResetButton = React.useCallback(() => {
    if (row?.inventory?.quantity === undefined) {
      return null;
    }

    return (
      <IconButton
        title={intl.formatMessage({ id: 'global.inventory.reset' })}
        className={classes.resetButton}
        onClick={() => {
          onResetInventory();
        }}>
        <BackspaceIcon className={classes.resetButtonIcon} />
      </IconButton>
    );
  }, [row?.inventory?.quantity]);

  const getEditValue = React.useCallback(() => {
    const quantity = row?.inventory?.quantity;
    return quantity ? weightToUser(row.inventory.quantity) : 0;
  }, [row?.inventory?.quantity]);

  const onFinishCellEditing = React.useCallback(
    (value) => {
      setLoading(true);
      apiChangeInventoryOnProductVariant(
        row.product.id,
        row.id,
        { quantity: weightToSystem(value), managed: true, stopSelling: false },
        (updatedProduct) => {
          onSuccess(updatedProduct);
          setLoading(false);
        },
        onError
      );
    },
    [row.id, row?.product?.id, row?.variant?.id]
  );

  const onResetInventory = React.useCallback(() => {
    setLoading(true);
    apiChangeInventoryOnProductVariant(
      row.product.id,
      row.id,
      null,
      (updatedProduct) => {
        onSuccess(updatedProduct);
        setLoading(false);
      },
      onError
    );
  }, [row.id, row?.product?.id, row?.variant?.id]);

  if (loading) {
    return <Table.Cell {...cellProps}>{intl.formatMessage({ id: 'global.loading' })}</Table.Cell>;
  }

  const onError = (error) => {
    const message = error?.response?.data?.message || intl.formatMessage({ id: 'global.somethingWentWrong' });
    dispatch(showTemporaryTooltip(message));
    setLoading(false);
  };

  return (
    <CellAsIntegerInput
      value={getEditValue()}
      view={getViewValue()}
      key={`inventory-${row.id}`}
      cellProps={cellProps}
      onFinishCellEditing={onFinishCellEditing}
    />
  );
}

VariantInventoryCell.propTypes = propTypes;

export default VariantInventoryCell;
