import React from 'react';
import { COLORS } from '../constants/colors';

const CalcButton4 = (props) => {
  const { color = COLORS.blackTintedBlue, ...other } = props;
  return (
    <svg width="21" height="34" viewBox="0 0 21 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <path
        d="M3.55938 33.1807C5.4832 33.1807 6.68438 32.0088 6.68438 30.1387V29.2451H3.72539V30.3193H5.24883L5.24395 30.4512C5.21465 31.3398 4.55059 31.9307 3.58379 31.9307C2.42168 31.9307 1.68438 30.9883 1.68438 29.46V29.4502C1.68438 27.9512 2.3875 27.0234 3.5252 27.0234C4.35039 27.0234 4.95098 27.4385 5.15117 28.1367L5.16094 28.1855H6.62578L6.61602 28.1367C6.40606 26.7305 5.19024 25.7734 3.5252 25.7734C1.47441 25.7734 0.18047 27.2041 0.18047 29.4648V29.4746C0.18047 31.7695 1.46465 33.1807 3.55938 33.1807ZM9.80039 33H11.275V30.0312H14.4488V33H15.9234V25.9541H14.4488V28.8154H11.275V25.9541H9.80039V33ZM19.3324 33H20.807V25.9541H19.3324V33Z"
        fill={color}
      />
      <path
        d="M13.7153 18H15.8638V14.3501H18.3662V12.3848H15.8638V0.385254H12.6777C10.2119 4.05957 7.51416 8.35645 5.13379 12.3481V14.3501H13.7153V18ZM7.37988 12.397V12.2505C9.29639 9.01562 11.6035 5.37793 13.5811 2.42383H13.7275V12.397H7.37988Z"
        fill={color}
      />
    </svg>
  );
};

export default CalcButton4;
