import {COLORS} from "../../helpers";

export default ({
  wrapper: {
  },
  selectFormControl: {
    minWidth: '100%',
  },
  name: {
    fontSize: 18,
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.text,
    marginBottom: 40
  },
  variants: {
    marginBottom: 50,
  },
  input: {
    marginBottom: 25,
  },
  select: {
    display: 'flex',
    minWidth :'100%'
  },
  inputText: {
    textAlign: 'right'
  },
  button: {
    minWidth: 330,
    alignSelf: 'center',
    textTransform: 'uppercase',
  },
  controls: {
    margin: '27px 0 0 0',
    textAlign: 'center'
  },
  control: {
    padding: '0 105px'
  },
  nowrap: {
    whiteSpace: 'nowrap'
  }
});
