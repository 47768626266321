import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { COLORS } from '../../../helpers/'

const styles = ({
  text: {
    fontSize: 14,
    color: COLORS.textSecondary,
    fontFamily: "Roboto, sans-serif",
  },
});

const propTypes = {
  classes: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
};

const ExternalAccount = ({ account,
                           classes }) => {
  return (
    <div className={classes.wrapper}>
      <Typography className={classes.text} variant={"body1"}>
        {account.bank_name || account.bankName || ""}
      </Typography>
      <Typography className={classes.text} variant={"body1"}>
        •••• •••• •••• {account.last4 || ""}
      </Typography>
      <Typography className={classes.text} variant={"body1"}>
        {(account.currency || "").toUpperCase()}
      </Typography>
    </div>
  );
};

ExternalAccount.propTypes = propTypes;

export default withStyles(styles)(
  injectIntl(ExternalAccount)
);

