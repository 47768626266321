import React from 'react';
import { COLORS } from '../constants';

const MessageIcon = (props) => {
  const { color = COLORS.blackTintedBlue, ...other } = props;

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <path
        d="M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 20L4 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM15 12H5C4.45 12 4 11.55 4 11C4 10.45 4.45 10 5 10H15C15.55 10 16 10.45 16 11C16 11.55 15.55 12 15 12ZM15 9H5C4.45 9 4 8.55 4 8C4 7.45 4.45 7 5 7H15C15.55 7 16 7.45 16 8C16 8.55 15.55 9 15 9ZM15 6H5C4.45 6 4 5.55 4 5C4 4.45 4.45 4 5 4H15C15.55 4 16 4.45 16 5C16 5.55 15.55 6 15 6Z"
        fill={color}
      />
    </svg>
  );
};

export default MessageIcon;
