import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {
  COLORS,
} from '../../../helpers/index';

const styles = ({
  button: {
    marginLeft: 9,
    marginRight: 9,
    padding: 0,
  },
  icon: {
    color: COLORS.black
  }
});

const propTypes = {
  onAddSubscription: PropTypes.func,
};

const defaultProps = {};

const EmailSubscriptionsActions = (props) => {
  const {
    classes,
    onAddSubscription,
  } = props;

  return (
    <React.Fragment>
      {onAddSubscription && (
        <IconButton className={classes.button}
                    onClick={onAddSubscription} >
          <AddCircleIcon style={{color: COLORS.text}} />
        </IconButton>
      )}
    </React.Fragment>
  );
};

EmailSubscriptionsActions.propTypes = propTypes;
EmailSubscriptionsActions.defaultProps = defaultProps;

export default withStyles(styles)(
  injectIntl(EmailSubscriptionsActions)
);
