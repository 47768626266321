class Payment {
  constructor(props = {}) {
    this.fulfillmentFee = props.fulfillmentFee || null;
    this.shippingFee = props.shippingFee || null;
    this.initiatedAt = props.initiatedAt || null;
    this.balanceAvailableOn = props.processing
			? props.processing.balanceAvailableOn || null
			: null;
  }
  
  getFulfillmentFee() {
    return !!this.fulfillmentFee &&
      this.fulfillmentFee
  }
  
  getShippingFee() {
    return !!this.shippingFee &&
      this.shippingFee
  }
  
  getBalanceAvailableOn() {
    return !!this.balanceAvailableOn &&
      this.balanceAvailableOn
  }
}

export default Payment;