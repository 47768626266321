import { Paper } from '@material-ui/core';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  detailContainer: {
    margin: '20px',
  },
});

const GridDetailContainer = (props) => {
  const { row, classes, detailRowColumns, detailRowColumnExtensions, detailRowsGetter, detailRowsPropertyName } = props;
  return (
    <div className={classes.detailContainer}>
      <Paper>
        <Grid
          rows={!!detailRowsGetter ? detailRowsGetter(row) : row[detailRowsPropertyName]}
          columns={detailRowColumns}>
          <Table columnExtensions={detailRowColumnExtensions} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(GridDetailContainer);
