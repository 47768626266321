import React from 'react';
import { makeStyles } from '@material-ui/core';
import styles from './styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import classNames from 'classnames';

const useStyles = makeStyles(styles);

const BreadcrumbsComponent = (props) => {
  const { breadcrumbConfig } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {breadcrumbConfig.map((item, index) => (
        <div
          key={item?.name || index}
          className={classNames([classes.singleItem, item?.action && classes.withLink])}
          {...(item?.action ? { onClick: item.action } : {})}>
          <p className={classes.item}>
            {index !== 0 && <ArrowForwardIosIcon className={classes.separator} />}
            {item.name}
          </p>
        </div>
      ))}
    </div>
  );
};

export default BreadcrumbsComponent;
