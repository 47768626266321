export default () => ({
  rowHover: {
    color: '#fff',
    transition: 'background-color 0.3s ease-in-out',
    '&>.actionColumnBodyCell button, & .showOnCellHover': {
      opacity: 0,
      transition: 'opacity 0.3s ease-in-out',
    },
    '& .underlineOnRowHover': {
      textDecoration: 'none',
    },

    '&:hover': {
      backgroundColor: '#F6F6F6',
      '&>.actionColumnBodyCell button, & .showOnCellHover': {
        opacity: 1,
      },
      '& .underlineOnRowHover': {
        textDecoration: 'underline',
      },
    },
  },
  headerCell: {
    textAlign: 'left',
    color: '#9B9B9B',
    fontSize: 14,
    fontWeight: 400,
  },
  toolbarRoot: {
    paddingLeft: 20,
    position: 'sticky',
    top: 50,
    backgroundColor: '#fff',
    zIndex: 99,
    paddingTop: 25,
    paddingRight: 30,
    paddingBottom: 15,
  },
  rowWithClick: {
    cursor: 'pointer',
  },
});
