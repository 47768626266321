import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../../../hocs';
import {
  AdminDialog,
  AdminDialogTitle,
  AdminDialogContent,
  AdminDialogControls,
  PrimaryTextField,
  PrimaryButton,
  Spinner,
  RichTextEditor,
  RichTextViewer,
  ImageUpload,
  ErrorBox,
  CopyToClipboard,
} from '../../../../components';
import { validateField } from '../../../../helpers';
import validationRules from './validate';
import styles from './styles';
import { getEditorStateFromHtml, getHtmlFromEditor } from '../../../../components/RichTextField/helpers';
import Address from '../../../SettingsScene/Components/Address';
import Block from '../../../SettingsScene/Components/Block';
import Image from '../../../SettingsScene/Components/Image';
import Logotype from '../../../SettingsScene/Components/Logotype';
import { apiGetSupplierById, apiMakeSupplierToken } from '../../../../api';
import Producer from '../../../../entities/Producer';
import SubWrapper from '../../../SettingsScene/Components/SubWrapper';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

const propTypes = {
  supplierId: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const SUPPLIER_DIALOG_STEPS = {
  VIEW_STEP: 'VIEW_STEP',
  EDIT_ADDRESS: 'EDIT_ADDRESS',
  EDIT_DESCRIPTION_RETAIL: 'EDIT_DESCRIPTION_RETAIL',
  EDIT_PROFILE_IMAGE: 'EDIT_PROFILE_IMAGE',
  EDIT_LOGO: 'EDIT_LOGO',
};

const EditSupplierDialog = (props) => {
  const { supplierId, intl, classes, onClose, onSave } = props;

  const { globalProducer } = useSelector((state) => ({
    globalProducer: state.producer.object,
  }));

  const [loading, setLoading] = useState(true);
  const [producer, setProducer] = useState(new Producer({}));
  const [supplierAddress, setSupplierAddress] = useState({
    name: '',
    nameShort: '',
    phone: '',
    email: '',
    address1: '',
    address2: '',
    zipCode: '',
    city: '',
    province: '',

    nameError: '',
    emailError: '',
    address1Error: '',
    cityError: '',
    zipError: '',
    provinceError: '',
  });

  const [step, setStep] = useState(SUPPLIER_DIALOG_STEPS.VIEW_STEP);
  const [descriptionRetail, setDescriptionRetail] = useState({
    state: getEditorStateFromHtml(''),
    text: '',
  });
  const [profileImageFile, setProfileImageFile] = useState('');
  const [logoFile, setLogoFile] = useState('');
  const [token, setToken] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    getProducer();
  }, []);

  const getProducer = () => {
    apiGetSupplierById(
      supplierId,
      (producerObj) => {
        apiMakeSupplierToken(
          supplierId,
          ({ token }) => {
            setToken(token);
            updateSupplierState(producerObj);
          },
          (error) => {
            setError(error?.response?.data?.message);
          }
        );
      },
      (error) => {
        setError(error?.response?.data?.message);
      }
    );
  };

  const updateSupplierState = (producerObj) => {
    const newProducer = new Producer(producerObj);

    setLoading(false);
    setError('');
    setProducer(newProducer);

    setSupplierAddress({
      name: producerObj.name,
      nameShort: producerObj.nameShort,
      phone: producerObj.phone,
      email: producerObj.email,
      address1: producerObj.address1,
      address2: producerObj.address2,
      zipCode: producerObj.zip,
      city: producerObj.city,
      province: producerObj.province,

      nameError: '',
      emailError: '',
      address1Error: '',
      cityError: '',
      zipError: '',
      provinceError: '',
    });

    setDescriptionRetail({
      state: getEditorStateFromHtml(producerObj.description || ''),
      text: producerObj.description,
    });
  };

  function getTextField(fieldName, required) {
    return (
      <PrimaryTextField
        className={classes.input}
        InputLabelProps={{
          classes: {
            root: classes.label,
          },
        }}
        autoComplete="no"
        error={!!supplierAddress[`${fieldName}Error`]}
        onChange={(event) => {
          let value = event.target.value;
          setSupplierAddress((state) => {
            let newState = { ...state };
            newState[fieldName] = value;
            return newState;
          });
        }}
        value={supplierAddress[fieldName]}
        id={`${fieldName}-input`}
        label={(required ? '* ' : '') + intl.formatMessage({ id: `global.${fieldName}` })}
        type="text"
        margin="normal"
        helperText={
          supplierAddress[`${fieldName}Error`] && intl.formatMessage({ id: supplierAddress[`${fieldName}Error`] })
        }
        fullWidth
      />
    );
  }

  const validate = () => {
    const nameError = validateField(validationRules, 'name', supplierAddress.name);
    const emailError = validateField(validationRules, 'email', supplierAddress.email);
    const address1Error = validateField(validationRules, 'address1', supplierAddress.address1);
    const cityError = validateField(validationRules, 'city', supplierAddress.city);
    const zipCodeError = validateField(validationRules, 'zipCode', supplierAddress.zipCode);
    const provinceError = validateField(validationRules, 'province', supplierAddress.province);

    setLoading(true);

    if (step === SUPPLIER_DIALOG_STEPS.EDIT_DESCRIPTION_RETAIL) {
      onSaveSupplier({
        description: descriptionRetail.text,
      });
      return;
    }
    if (step === SUPPLIER_DIALOG_STEPS.EDIT_PROFILE_IMAGE) {
      onSaveSupplier({
        image: profileImageFile,
      });
      return;
    }
    if (step === SUPPLIER_DIALOG_STEPS.EDIT_LOGO) {
      onSaveSupplier({
        logotype: logoFile,
      });
      return;
    }

    if (!nameError && !emailError && !address1Error && !cityError && !zipCodeError && !provinceError) {
      const errors = {
        nameError: '',
        emailError: '',
        addressError: '',
        cityError: '',
        zipCodeError: '',
        provinceError: '',

        requestError: '',
      };

      setSupplierAddress((state) => {
        return { ...state, ...errors };
      });

      onSaveSupplier({
        name: supplierAddress.name,
        nameShort: supplierAddress.nameShort,
        phone: supplierAddress.phone,
        email: supplierAddress.email,
        address1: supplierAddress.address1,
        address2: supplierAddress.address2,
        zip: supplierAddress.zipCode,
        city: supplierAddress.city,
        province: supplierAddress.province,
      });
    } else {
      setSupplierAddress({
        ...supplierAddress,
        nameError: nameError,
        emailError: emailError,
        address1Error: address1Error,
        cityError: cityError,
        zipCodeError: zipCodeError,
        provinceError: provinceError,
      });
      setLoading(false);
    }
  };

  const onSaveSupplier = (data) => {
    onSave &&
      onSave(
        supplierId,
        data,
        (producerObj) => {
          setStep(SUPPLIER_DIALOG_STEPS.VIEW_STEP);
          updateSupplierState(producerObj);
        },
        (error) => {
          setLoading(false);
          setError(error?.response?.data?.message);
        }
      );
  };

  const drawViewAll = () => {
    return (
      <>
        <Block
          title={intl.formatMessage({ id: 'producer.block.address' })}
          wrapperClass={classes.viewElementWrapper}
          paperClass={classes.viewElementPaper}
          onEdit={() => {
            setStep(SUPPLIER_DIALOG_STEPS.EDIT_ADDRESS);
          }}>
          <Address producer={producer} />
        </Block>

        <Block
          title={intl.formatMessage({ id: 'producer.block.description' })}
          wrapperClass={classes.viewElementWrapper}
          paperClass={classes.viewElementPaper}
          onEdit={() => {
            setStep(SUPPLIER_DIALOG_STEPS.EDIT_DESCRIPTION_RETAIL);
          }}>
          <RichTextViewer html={descriptionRetail.text} className={classes.textViewer} />
        </Block>

        <SubWrapper subWrapperClass={classes.subWrapperClass}>
          <Block
            title={intl.formatMessage({ id: 'producer.block.profileImage' })}
            wrapperClass={classNames([classes.viewElementWrapper, classes.viewElementWrapperLeft])}
            paperClass={classes.viewElementPaper}
            onEdit={() => {
              setStep(SUPPLIER_DIALOG_STEPS.EDIT_PROFILE_IMAGE);
            }}>
            <Image producer={producer} disableDescription />
          </Block>

          <Block
            title={intl.formatMessage({ id: 'producer.block.logo' })}
            wrapperClass={classNames([classes.viewElementWrapper, classes.viewElementWrapperRight])}
            paperClass={classes.viewElementPaper}
            onEdit={() => {
              setStep(SUPPLIER_DIALOG_STEPS.EDIT_LOGO);
            }}>
            <Logotype producer={producer} disableDescription />
          </Block>
        </SubWrapper>

        <Block
          title={intl.formatMessage({ id: 'supplier.global.inviteUrl' })}
          wrapperClass={classNames([classes.copyLinkWrapper])}
          paperClass={classes.viewElementPaper}>
          <CopyToClipboard
            text={`${process.env.REACT_APP_BACK_URL}supplier/${token}/${globalProducer.id}`}
            copyContent={`${process.env.REACT_APP_BACK_URL}supplier/${token}/${globalProducer.id}`}
          />
        </Block>
      </>
    );
  };

  const drawEditAddress = () => {
    return (
      <div className={classes.row}>
        <div style={{ marginRight: 72 }} className={classes.column}>
          {getTextField('name', true)}
          {getTextField('email', true)}
          {getTextField('address1', true)}
          {getTextField('city', true)}
          {getTextField('zipCode', true)}
        </div>
        <div className={classes.column}>
          {getTextField('nameShort')}
          {getTextField('phone')}
          {getTextField('address2')}
          {getTextField('province', true)}
        </div>
      </div>
    );
  };

  const drawEditDescription = (descriptionStateParam, setDescriptionStateParam) => {
    return (
      <RichTextEditor
        onChange={(state) => {
          setDescriptionStateParam({
            state,
            text: getHtmlFromEditor(state),
          });
        }}
        state={descriptionStateParam.state}
        fullWidth
      />
    );
  };

  const drawEditProfileImage = () => {
    return (
      <div className={classes.imageWrapper}>
        <div className={classes.image}>
          <ImageUpload
            object={{ image: producer.image, name: producer.name }}
            base64={profileImageFile}
            onUploaded={(file) => setProfileImageFile(file)}
          />
        </div>
      </div>
    );
  };

  const drawEditLogoImage = () => {
    return (
      <div className={classes.imageWrapper}>
        <div className={classes.image}>
          <ImageUpload
            object={{ image: producer.logotype, name: producer.name }}
            base64={logoFile}
            onUploaded={(file) => setLogoFile(file)}
          />
        </div>
      </div>
    );
  };

  return (
    <AdminDialog
      open
      closeButton
      onClose={onClose}
      paperClass={classes.editDialogPaper}
      withBack={step !== SUPPLIER_DIALOG_STEPS.VIEW_STEP}
      onBackClick={() => {
        setStep(SUPPLIER_DIALOG_STEPS.VIEW_STEP);
      }}>
      <AdminDialogTitle title={intl.formatMessage({ id: 'suppliers.update.dialog.title' })} />
      <AdminDialogContent className={classes.wrapper}>
        {loading && <Spinner size={50} />}
        {step === SUPPLIER_DIALOG_STEPS.VIEW_STEP && drawViewAll()}
        {step === SUPPLIER_DIALOG_STEPS.EDIT_ADDRESS && drawEditAddress()}
        {step === SUPPLIER_DIALOG_STEPS.EDIT_DESCRIPTION_RETAIL &&
          drawEditDescription(descriptionRetail, setDescriptionRetail)}

        {step === SUPPLIER_DIALOG_STEPS.EDIT_PROFILE_IMAGE && drawEditProfileImage()}
        {step === SUPPLIER_DIALOG_STEPS.EDIT_LOGO && drawEditLogoImage()}

        {error && <ErrorBox error={error} onClose={() => setError('')} />}

        {step !== SUPPLIER_DIALOG_STEPS.VIEW_STEP && (
          <AdminDialogControls>
            <PrimaryButton className={classes.button} onClick={() => validate()}>
              {intl.formatMessage({ id: 'global.save' })}
            </PrimaryButton>
          </AdminDialogControls>
        )}
      </AdminDialogContent>
    </AdminDialog>
  );
};

EditSupplierDialog.propTypes = propTypes;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(EditSupplierDialog)));
