import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withRouter } from '../../../hocs';
import { OrdersFilterDialog, SaveTabSettingDialog, TabTypes } from '../../../dialogs';
import { AdminDXTable } from '../../../components';
import { apiRetrieveOrders } from '../../../api';
import OrderBatchControls from '../Controls/OrderBatchControls';
import { getGridPageSize, getGridPageSizes } from '../../../helpers/grid';
import { TableCell } from '../helpers';
import { QuickBooksAddCustomerDialog } from '../../../dialogs';
import { DEFAULT_MAX_CELL_WIDTH, DEFAULT_MIN_CELL_WIDTH, HEADER_HEIGHT_STANDARD } from '../../../helpers';
import { changeTabState } from '../../../store/actions/common';
import { connect } from 'react-redux';

const propTypes = {
  tab: PropTypes.object.isRequired,
  onApplyFilter: PropTypes.func,
  onRef: PropTypes.func,

  isFiltersDialogActive: PropTypes.bool,
  showTabDialog: PropTypes.bool,
  onCreateTab: PropTypes.func,
  onPatchTab: PropTypes.func,

  isShowAddCustomerDialog: PropTypes.bool,
  addCustomerDialogRow: PropTypes.object,
  openAddCustomerDialog: PropTypes.func,
  closeAddCustomerDialog: PropTypes.func,

  openFilterDialog: PropTypes.func,
  onCloseFilterDialog: PropTypes.func,
  onCloseTabDialog: PropTypes.func,

  hiddenColumnNames: PropTypes.array,
  sorting: PropTypes.array,
  appliedFilters: PropTypes.array,
  columns: PropTypes.array,
  enableSelection: PropTypes.bool,
  onRowClick: PropTypes.func,
};

const defaultProps = {
  hiddenColumnNames: [],
  sorting: [],
  columns: [],
  appliedFilters: [],
  enableSelection: true,
  onRowClick: undefined,
};

class OrdersTabContent extends React.Component {
  constructor(props) {
    super(props);

    const { sorting, onRef, appliedFilters } = props;

    onRef && onRef(this);

    this.state = {
      openWithFilter: null,
      saveTabSettings: false,
      addingFilter: false,
      savingTabSettings: false,
      appliedFilters: this.prepareFilters(appliedFilters),
      sorting: sorting.length ? sorting : [{ columnName: 'name', direction: 'desc' }],
    };
  }

  componentWillUnmount() {
    this.saveTabOnStore();
  }

  saveTabOnStore = () => {
    const searchValue = this.table.state?.savedSearchValue || '';
    const { tab, hiddenColumnNames, columnWidths, appliedFilters, sorting } = this.props;

    this.props.changeTabState({
      key: tab.id,
      excludeColumns: hiddenColumnNames,
      columnWidths,
      filters: appliedFilters,
      sorting,
      searchValue,
    });
  };

  prepareFilters = (filters) => {
    return filters;
  };

  handleFiltersApply = (appliedFilters) => {
    this.setState(
      {
        appliedFilters: appliedFilters,
        addingFilter: false,
      },
      () => {
        this.onPatchTab();
      }
    );
  };

  handleFilterRemove = (removedFilter) => {
    this.setState(
      (state) => {
        let appliedFilters = state.appliedFilters;
        let filterToDelete = appliedFilters.indexOf(removedFilter);
        appliedFilters.splice(filterToDelete, 1);
        return { appliedFilters };
      },
      () => {
        this.onPatchTab();
      }
    );
  };

  onPatchTab = () => {
    const { tab, onPatchTab, hiddenColumnNames } = this.props;

    if (tab.id) {
      onPatchTab &&
        onPatchTab(tab, {
          filters: this.state.appliedFilters,
          excludeColumns: hiddenColumnNames,
          sorting: this.state.sorting,
          columnsWidth: this.props.columnWidths,
        });
    }
  };

  onChangeSorting = (sorting) => {
    this.setState({ sorting }, () => {
      this.onPatchTab();
    });
  };

  getFiltersDialog = (onClose) => {
    const { appliedFilters, openWithFilter } = this.state;

    return (
      <OrdersFilterDialog
        onApplyFilters={(appliedFilters) => {
          this.handleFiltersApply(appliedFilters);
          onClose && onClose();
        }}
        onClose={() => this.setState({ openWithFilter: null }, () => onClose && onClose())}
        appliedFilters={JSON.parse(JSON.stringify(appliedFilters))}
        openWithFilter={openWithFilter}
      />
    );
  };

  render() {
    const { appliedFilters, sorting } = this.state;

    const {
      intl,
      columns,
      hiddenColumnNames,
      isFiltersDialogActive,
      openFilterDialog,
      showTabDialog,
      onCloseTabDialog,
      onCreateTab,
      onCloseFilterDialog,
      history,
      isShowAddCustomerDialog,
      addCustomerDialogRow,
      openAddCustomerDialog,
      closeAddCustomerDialog,
      sortingColumnExtensions,
      columnWidths,
      onColumnWidthsChange,
      tableWidth,
      enableSelection,
      onRowClick,
      searchValue,
    } = this.props;

    return (
      <React.Fragment>
        <AdminDXTable
          defaultSearchValue={searchValue || ''}
          flexibleTableWidth
          tableWidth={tableWidth}
          enableSelection={enableSelection}
          onRef={(table) => (this.table = table)}
          cellRenderer={(...props) => TableCell(intl, ...props)}
          openAddCustomerDialog={openAddCustomerDialog}
          onForceReload={() => this.table.forceReload()}
          apiRetrieve={apiRetrieveOrders}
          serverSideFilters={appliedFilters.slice()}
          onRowClick={!!onRowClick ? onRowClick : (row) => history.push(`/order/${row.id}`)}
          onRemoveFilter={(removedFilter) => this.handleFilterRemove(removedFilter)}
          onChangeSorting={this.onChangeSorting}
          onOpenWithFilter={(index, filter) =>
            this.setState(
              {
                openWithFilter: filter,
              },
              () => {
                openFilterDialog && openFilterDialog();
              }
            )
          }
          pageSize={getGridPageSize()}
          pageSizes={getGridPageSizes()}
          batchControlsComponent={OrderBatchControls}
          columns={columns}
          hiddenColumnNames={hiddenColumnNames}
          sorting={sorting}
          totalSummaryItems={[{ columnName: 'total', type: 'sum' }]}
          columnExtensions={[
            { columnName: 'total', align: 'right' },
            { columnName: 'booking', width: 180, align: 'center' },
          ]}
          sortingColumnExtensions={sortingColumnExtensions}
          columnWidths={columnWidths}
          onColumnWidthsChange={onColumnWidthsChange}
          minColumnWidth={DEFAULT_MIN_CELL_WIDTH}
          maxColumnWidth={DEFAULT_MAX_CELL_WIDTH}
          allowExpandAll={true}
          stickyHeader
          stickyHeaderOffset={HEADER_HEIGHT_STANDARD.extended}
        />

        {isFiltersDialogActive && this.getFiltersDialog(onCloseFilterDialog)}

        {showTabDialog && (
          <SaveTabSettingDialog
            onClose={onCloseTabDialog}
            filters={appliedFilters.slice()}
            excludeColumns={hiddenColumnNames}
            columnWidths={columnWidths}
            sorting={this.state.sorting}
            onSave={(data) => onCreateTab(data, onCloseTabDialog)}
            type={TabTypes.ORDERS}
          />
        )}

        {isShowAddCustomerDialog && (
          <QuickBooksAddCustomerDialog
            intl={intl}
            onClose={() => closeAddCustomerDialog()}
            row={addCustomerDialogRow}
            forceReload={() => this.table.forceReload()}
          />
        )}
      </React.Fragment>
    );
  }
}

OrdersTabContent.propTypes = propTypes;
OrdersTabContent.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  tabsState: state.common.tabsState,
});

const mapDispatchToProps = (dispatch) => {
  return {
    changeTabState: (newTabState) => dispatch(changeTabState(newTabState)),
  };
};

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(OrdersTabContent)));
