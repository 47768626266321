const styles = theme => ({
  subTittle: {
    fontSize: 16,
  },
  tableTittleWrapper: {
    marginTop: 10,
    marginBottom: -40,
    paddingLeft: 13,
  },
  tableTitleText: {
    fontSize: 16,
    fontWeight: 'bold'
  },
});

export default styles;