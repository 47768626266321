import React from "react";
import PropTypes from "prop-types";
import FellowFarmerHeader from './FellowFarmer/Header';
import GardsbudetHeader from './Gardsbudet/Header';

class Header extends React.Component {
  render() {
    const {
      appName,
      ...other
    } = this.props;

    switch (appName) {
      case 'fellow.farmer' : {
        return <FellowFarmerHeader {...other}/>;
      }
      case 'gardsbudet' : {
        return <GardsbudetHeader {...other}/>;
      }
      default : {
        return <FellowFarmerHeader {...other}/>;
      }
    }
  }
}


Header.propTypes = {
  appName: PropTypes.string.isRequired,
  position: PropTypes.string,
  onLogoClick: PropTypes.func,
  rightContent: PropTypes.func,
  centerContent: PropTypes.func,
  afterLeftContent: PropTypes.func,
  leftContent: PropTypes.func
};


export default Header;