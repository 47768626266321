import axios from 'axios';
import {
  getRequestConfig,
  REQUEST_TYPES,
  replaceUrlPlaceholder
} from  '../request';
import API_END_POINTS from  '../endpoints';

/**
 * Creates new tab
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCreateTab = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.TAB_CREATE);
  config.data = data;
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Updates tab by id
 * @param {string} tabId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiPatchTab = function (tabId, data = {}, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.TAB_PATCH, 'tab', tabId);
  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  config.data = data;
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Deletes tab by id
 * @param {string} tabId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDeleteTab = function (tabId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.TAB_DELETE, 'tab', tabId);
  const config = getRequestConfig(REQUEST_TYPES.DEL, url);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};