import {
  apiRetrieveOrder,
  apiAddVariantToOrder,
  apiAddCustomLineItemToOrder,
  apiOrderAddNote,
  apiRemoveLineItemFromOrder,
  apiAddInvoicePaymentMethodOnOrder,
  apiAddPrepurchasePaymentMethodOnOrder,
  apiOrderLineItemSetQuantity,
  apiOrderLineItemSetNote,
  apiSetOrderShipping,
  apiUpdateOrderLineItem,
  apiOrderDeleteDiscount,
  apiOrderUpdatedSendEmails,
  apiApproveOrderLineItems,
} from '../../api/order/order';
import {
  apiCustomerRetrieve,
  apiAddCustomerOnOrder,
  apiRemoveCustomerFromOrder,
  apiOrderDeleteDeliveryCompany,
} from '../../api';

import { ORDER_SET, ORDER_UNSET, ORDER_LOADING, ORDER_LOADED } from '../constants/order';
import { ORDER_STATUSES, PAYMENT_TYPES } from '../../helpers';

export function setOrder(id, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });
    apiRetrieveOrder(
      id,
      (order) => dispatch(refreshOrder(order, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}

export function refreshOrder(order, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });
    if (order.customer) {
      apiCustomerRetrieve(
        order.customer,
        (customer) => {
          dispatch({
            type: ORDER_SET,
            payload: {
              order: order,
              customer: customer,
            },
          });
          onSuccess && onSuccess(order);
          dispatch({ type: ORDER_LOADED });
        },
        (error) => onError && onError(error)
      );
    } else {
      dispatch({
        type: ORDER_SET,
        payload: {
          order: order,
          customer: null,
        },
      });
      onSuccess && onSuccess(order);
      dispatch({ type: ORDER_LOADED });
    }
  };
}

export function unsetOrder() {
  return (dispatch) => {
    dispatch({ type: ORDER_UNSET });
  };
}

export function orderAttachVariant(order, product, variant, data, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });
    apiAddVariantToOrder(
      order.id,
      variant.id,
      data,
      (order) => dispatch(refreshOrder(order, onSuccess, onError)),
      (error) => {
        dispatch({ type: ORDER_LOADED });

        onError && onError(error);
      }
    );
  };
}

export function orderAttachCustomItem(order, data, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });
    apiAddCustomLineItemToOrder(
      order.id,
      data,
      (order) => dispatch(refreshOrder(order, onSuccess, onError)),
      (error) => {
        dispatch({ type: ORDER_LOADED });

        onError && onError(error);
      }
    );
  };
}

export function orderChangeLineItemNote(order, lineItem, note, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });
    apiOrderLineItemSetNote(
      order.id,
      lineItem.id,
      note,
      (order) => dispatch(refreshOrder(order, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}

export function orderChangeLineItemQuantity(order, lineItem, quantity, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });
    apiOrderLineItemSetQuantity(
      order.id,
      lineItem.id,
      quantity,
      (order) => dispatch(refreshOrder(order, onSuccess, onError)),
      (error) => {
        dispatch({ type: ORDER_LOADED });
        onError && onError(error);
      }
    );
  };
}

export function orderAddNote(order, note, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });
    apiOrderAddNote(
      order.id,
      note,
      (order) => dispatch(refreshOrder(order, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}

export function orderPatchLineItem(order, lineItem, data, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });
    apiUpdateOrderLineItem(
      order.id,
      lineItem.id,
      data,
      (order) => dispatch(refreshOrder(order, onSuccess, onError)),
      (error) => {
        dispatch({ type: ORDER_LOADED });

        onError && onError(error);
      }
    );
  };
}

export function orderDetachLineItem(order, lineItem, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });
    apiRemoveLineItemFromOrder(
      order.id,
      lineItem.id,
      (order) => dispatch(refreshOrder(order, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}

export function orderAttachShipping(order, method, data, deliveryCompany, location, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });

    if (location) {
      data['locationId'] = location;
    }

    apiSetOrderShipping(
      order.id,
      method,
      data,
      deliveryCompany,
      (order) => dispatch(refreshOrder(order, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}

export function orderRemoveShippingDeliveryCompany(draft, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });

    apiOrderDeleteDeliveryCompany(draft.id, (order) => dispatch(refreshOrder(order, onSuccess, onError)), onError);
  };
}

export function orderFinishEditing(order, emails, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });

    if (order.status === ORDER_STATUSES.ORDER_RECEIVED) {
      apiApproveOrderLineItems(
        order.id,
        () => {},
        () => {}
      );
    }

    apiOrderUpdatedSendEmails(order, emails, onSuccess, onError);
  };
}

export function orderDeleteDiscount(order, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });
    apiOrderDeleteDiscount(
      order.id,
      (order) => dispatch(refreshOrder(order, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}

export function orderAttachPaymentMethod(order, method, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });

    // Prepurchase order should use another method for adding payment method
    if (order.prepurchaseOrder) {
      if ([PAYMENT_TYPES.INVOICE].indexOf(method) !== -1) {
        apiAddPrepurchasePaymentMethodOnOrder(
          order.id,
          { method: null },
          (order) => dispatch(refreshOrder(order, onSuccess, onError)),
          (error) => onError && onError(error)
        );
      }

      if ([PAYMENT_TYPES.CASH, PAYMENT_TYPES.CHECK, PAYMENT_TYPES.COD].indexOf(method) !== -1) {
        apiAddPrepurchasePaymentMethodOnOrder(
          order.id,
          { method: method },
          (order) => dispatch(refreshOrder(order, onSuccess, onError)),
          (error) => onError && onError(error)
        );
      }

      if ([PAYMENT_TYPES.CASH, PAYMENT_TYPES.CHECK, PAYMENT_TYPES.COD, PAYMENT_TYPES.INVOICE].indexOf(method) === -1) {
        apiAddPrepurchasePaymentMethodOnOrder(
          order.id,
          { paymentMethodId: method },
          (order) => dispatch(refreshOrder(order, onSuccess, onError)),
          (error) => onError && onError(error)
        );
      }
    } else {
      if ([PAYMENT_TYPES.INVOICE].indexOf(method) !== -1) {
        apiAddInvoicePaymentMethodOnOrder(
          order.id,
          '',
          {},
          (order) => dispatch(refreshOrder(order, onSuccess, onError)),
          (error) => onError && onError(error)
        );
      }

      if ([PAYMENT_TYPES.CASH, PAYMENT_TYPES.CHECK, PAYMENT_TYPES.COD].indexOf(method) !== -1) {
        apiAddInvoicePaymentMethodOnOrder(
          order.id,
          method,
          {},
          (order) => dispatch(refreshOrder(order, onSuccess, onError)),
          (error) => onError && onError(error)
        );
      }

      if ([PAYMENT_TYPES.CASH, PAYMENT_TYPES.CHECK, PAYMENT_TYPES.COD, PAYMENT_TYPES.INVOICE].indexOf(method) === -1) {
        apiAddInvoicePaymentMethodOnOrder(
          order.id,
          '',
          { paymentMethodId: method },
          (order) => dispatch(refreshOrder(order, onSuccess, onError)),
          (error) => onError && onError(error)
        );
      }
    }
  };
}

export function orderAttachCustomer(order, customer, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });
    apiAddCustomerOnOrder(
      order.id,
      { id: customer.id },
      (order) => dispatch(refreshOrder(order, onSuccess, onError)),
      (error) => {
        dispatch({ type: ORDER_LOADED });
        onError && onError(error);
      }
    );
  };
}

export function orderDetachCustomer(order, customer, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });
    apiRemoveCustomerFromOrder(
      order.id,
      (order) => dispatch(refreshOrder(order, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}

export function orderChangeCustomerAddress(order, customer, addressId, addressType, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });

    const data = {
      id: customer.id,
      [`${addressType}Address`]: { id: addressId },
    };

    apiAddCustomerOnOrder(
      order.id,
      data,
      (order) => dispatch(refreshOrder(order, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}
