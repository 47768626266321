import React from "react";
import PropTypes from "prop-types";
import {
  FormattedMessage,
  injectIntl,
} from "react-intl";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {withStyles} from '@material-ui/core/styles';
import {validateField} from '../../helpers';
import {
  Spinner,
  PrimaryCheckbox,
  PrimaryTextField,
  PrimaryButton,
  AdminDialog,
  AdminDialogControls,
  AdminDialogContent,
  AdminDialogTitle
} from '../../components';
import validationRules from './validation';
import styles from './styles';

class EmailSubscriptionFormDialog extends React.Component {

  constructor(props) {
    super(props);

    const {subscription} = props;
    let state = {
      emailError: "",
      confirmation: true,
      invoice: true,
      news: true,
      orderFulfillment: true,
      orderUpdated: true,
      paymentConfirmation: true,
    };

    if (subscription) {
      state.email = subscription.email;
      state.confirmation = subscription.confirmation;
      state.invoice = subscription.invoice;
      state.news = subscription.news;
      state.orderFulfillment = subscription.orderFulfillment;
      state.orderUpdated = subscription.orderUpdated;
      state.paymentConfirmation = subscription.paymentConfirmation;
    }

    this.state = state;
    this.validate = this.validate.bind(this);
    this.validateField = this.validateField.bind(this);
  }

  validate() {
    const {subscription} = this.props;
    const {onValidated} = this.props;

    const emailError = validateField(validationRules, 'email', this.state.email);

    if (!emailError) {
      this.setState({
        emailError: ""
      });

      if (subscription) {
        let data = {
          email: this.state.email,
          confirmation: this.state.confirmation,
          invoice: this.state.invoice,
          news: this.state.news,
          orderFulfillment: this.state.orderFulfillment,
          orderUpdated: this.state.orderUpdated,
          paymentConfirmation: this.state.paymentConfirmation,
        };

        onValidated && onValidated(subscription.id, data)
      } else {
        let data = {
          email: this.state.email,
          confirmation: this.state.confirmation,
          invoice: this.state.invoice,
          news: this.state.news,
          orderFulfillment: this.state.orderFulfillment,
          orderUpdated: this.state.orderUpdated,
          paymentConfirmation: this.state.paymentConfirmation,
        };

        onValidated && onValidated(data)
      }

    } else {

      this.setState({
        emailError: emailError
      });
    }
  }

  validateField(fieldName) {
    const errorFieldName = fieldName + "Error";
    let state = {};
    state[errorFieldName] = validateField(validationRules, fieldName, this.state[fieldName]);
    this.setState(state);
  }

  getCheckbox = (subscriptionName, label) => {
    return (
      <FormControlLabel
        control={
          <PrimaryCheckbox
            checked={this.state[subscriptionName]}
            onChange={(event) => {
              let state = {};
              state[subscriptionName] = event.target.checked;
              this.setState(state)
            }}
            value={subscriptionName}
          />
        }
        label={label}
      />
    );
  };

  render() {
    const {
      onClose,
      loading,
      title,
      classes,
      intl,
    } = this.props;

    return (
      <AdminDialog open
                   closeButton
                   onClose={onClose} >
        <AdminDialogTitle title={title} />
        <AdminDialogContent className={classes.wrapper} >

          {loading && <Spinner size={50}/>}

          <PrimaryTextField
            error={!!this.state.emailError}
            onBlur={() => this.validateField("email")}
            onChange={event => this.setState({email: event.target.value})}
            value={this.state.email || ""}
            required
            autoFocus
            margin="dense"
            id="email"
            label={intl.formatMessage({id: "global.email"})}
            helperText={this.state.emailError ? intl.formatMessage({id: this.state.emailError}) : ""}
            fullWidth
          />

          <div className={classes.row}>
            <div className={classes.column}>
              {this.getCheckbox("confirmation", intl.formatMessage({id: "emailSubscriptions.orderConfirmations"}))}
              {this.getCheckbox("invoice", intl.formatMessage({id: "emailSubscriptions.invoices"}))}
              {this.getCheckbox("news", intl.formatMessage({id: "emailSubscriptions.news"}))}
            </div>
            <div className={classes.column}>
              {this.getCheckbox("orderFulfillment", intl.formatMessage({id: "emailSubscriptions.orderFulfillment"}))}
              {this.getCheckbox("orderUpdated", intl.formatMessage({id: "emailSubscriptions.orderUpdates"}))}
              {this.getCheckbox("paymentConfirmation", intl.formatMessage({id: "emailSubscriptions.paymentConfirmation"}))}
            </div>
          </div>


          <AdminDialogControls>
            <PrimaryButton onClick={this.validate}>
              <FormattedMessage id="global.saveChanges"/>
            </PrimaryButton>
          </AdminDialogControls>
        </AdminDialogContent>
      </AdminDialog>
    )
  }
}

EmailSubscriptionFormDialog.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onValidated: PropTypes.func,
  subscription: PropTypes.object
};

EmailSubscriptionFormDialog.defaultProps = {};

export default withStyles(styles, {withTheme: true})(
  injectIntl(EmailSubscriptionFormDialog)
);
