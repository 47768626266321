import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { makeStyles } from '@material-ui/core';
import { PADDING_CONTENT, PADDING_CONTENT_BOTTOM, PADDING_CONTENT_TOP } from '../../constants/globals';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 1,
  },
  content: {
    boxSizing: 'border-box',
    paddingLeft: PADDING_CONTENT,
    paddingRight: PADDING_CONTENT,
    maxWidth: 900,
    textAlign: 'left',
    width: '100%',
    backgroundColor: 'white',
    marginBottom: 20,
    paddingTop: PADDING_CONTENT_TOP,
    paddingBottom: PADDING_CONTENT_BOTTOM,
    minHeight: '100%',
  },
  title: {
    marginBottom: 20,
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 5,
  },
  rowText: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flex: 1,
  },
  mb10: {
    marginBottom: 10,
  },
  mt20: {
    marginTop: 20,
  },
  mb20: {
    marginBottom: 20,
  },
  rowItem: {
    marginBottom: 20,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 5,
    paddingRight: 5,
    marginLeft: -5,
    marginRight: -5,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  columnText: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
}));

const OrderSkeleton = ({ numberOfItems }) => {
  const classes = useStyles();

  return (
    <div className={classes.contentWrapper}>
      <div className={classes.content}>
        <div className={classes.textWrapper}>
          <Skeleton className={classes.title} height={30} count={1} width={100} />
        </div>
        <Skeleton count={1} height={25} width={150 + Math.random() * 150 + 'px'} className={classes.mb10} />
        <div className={classNames([classes.rowText, classes.mb10])}>
          <Skeleton count={1} height={15} width={100 + Math.random() * 50 + 'px'} />
          <Skeleton count={1} height={15} width={100} />
        </div>
        <div className={classNames([classes.rowText, classes.mb10])}>
          <Skeleton count={1} height={15} width={150 + Math.random() * 50 + 'px'} />
          <Skeleton count={1} height={15} width={100} />
        </div>
        <Skeleton count={1} height={15} width={150 + Math.random() * 50 + 'px'} />

        <div className={classNames([classes.rowText, classes.mt20, classes.mb20])}>
          <Skeleton count={1} height={20} width={150 + Math.random() * 50 + 'px'} />
          <Skeleton count={1} height={20} width={100} />
        </div>

        {Array(numberOfItems)
          .fill()
          .map((item, index) => (
            <div key={`ca_${index}`} className={classes.rowItem}>
              <Skeleton count={1} height={56} width={56} style={{ marginRight: 10 }} />
              <div className={classes.columnText}>
                <Skeleton count={1} height={25} width={150} className={classes.mb10} />
                <Skeleton count={1} width={15} />
              </div>
              <Skeleton count={1} height={24} width={20} style={{ marginLeft: 10 }} />
            </div>
          ))}
      </div>
    </div>
  );
};

OrderSkeleton.propTypes = {
  id: PropTypes.string,
  numberOfItems: PropTypes.number,
};

OrderSkeleton.defaultProps = {
  id: 'default',
  numberOfItems: 3,
};

export default OrderSkeleton;
