import React from "react";
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import EmptyImage from "./assets/no50x43.jpg";

const styles = {
  image: {
    width: '50px'
  },
  imageBox: {
    maxHeight: 33,
    overflow: 'hidden',
    width: '100%'
  },
};

const propTypes = {
  lineItem: PropTypes.object.isRequired
};

const Image = (props) => {
  const {
    classes,
    lineItem,
  } = props;

  const image =  lineItem.image && lineItem.image.thumbSrc ?
    lineItem.image.thumbSrc : EmptyImage;

  return (
    <div className={classes.imageBox}>
      <img className={classes.image}
           src={image}
           alt={lineItem.name.product}/>
    </div>
  );
};

Image.propTypes = propTypes;

export default withStyles(styles)(Image);