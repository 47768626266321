const validation = {
  name: {
    presence: {
      message: '^validation.product.enterName'
    }
  },
  type: {
    presence: {
      message: '^validation.product.selectType'
    }
  },
  subType: {
    presence: {
      message: '^validation.product.selectSubType'
    }
  },
};

export default validation;
