import {getRequestConfig, replaceUrlPlaceholders, REQUEST_TYPES} from '../request';
import API_END_POINTS from '../endpoints';
import axios from 'axios';

/**
 * fetch producer account notifications (email, sms and push)
 * @param {string} accountId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiGetProducerNotifications = function(accountId, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.GET_NOTIFICATIONS, {
    'ac': accountId,
  });
  const config = getRequestConfig(REQUEST_TYPES.GET, url);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * fetch producer email notification by type and ID
 * @param {string} accountId
 * @param {string} type
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiGetProducerNotificationById = function(accountId, type, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.GET_NOTIFICATIONS_BY_TYPE, {
    'ac': accountId,
    type
  });
  const config = getRequestConfig(REQUEST_TYPES.GET, url);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};


/**
 * create producer notifications
 * @param {string} accountId
 * @param {object} data
 * data.emailNotification - array (required) - email notification data (types: pushNotifications, emailNotification)
 * data.emailNotification.email - string (required) - email that should receive notifications
 * data.emailNotification.newOrder - bool (optional, default: true) - if should receive new order email
 * data.emailNotification.orderNotApproved - bool (optional, default: true)
 * data.emailNotification.orderPaymentDeclined - bool (optional, default: true)
 * data.emailNotification.paymentReport - bool (optional, default: true)
 * data.emailNotification.packedOrdersReport - bool (optional, default: true)
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiAddProducerNotification = function (accountId, data= {}, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.ADD_NOTIFICATION, {
    'ac': accountId,
  });
  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  config.data = data;
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * update notification by type and ID
 * @param {string} accountId - customer id
 * @param {string} notificationType - notification type (email, sms and push)
 * @param {object} data
 * data.emailNotifications - type (emailNotifications, pushNotifications)
 * data.emailNotifications.email - string (optional) - email that should receive notifications
 * data.emailNotifications.newOrder - bool (optional) - if should receive new order email
 * data.emailNotifications.orderNotApproved - bool (optional)
 * data.emailNotifications.orderPaymentDeclined - bool (optional)
 * data.emailNotifications.paymentReport - bool (optional)
 * data.emailNotifications.packedOrdersReport - bool (optional)
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiUpdateProducerNotification = function (accountId, notificationType, data= {}, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.EDIT_NOTIFICATION, {
    'type': notificationType,
    'ac': accountId,
  });
  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  config.data = data;
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};


/**
 * remove account all notifications
 * @param {string} accountId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiRemoveProducerNotification = function (accountId, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.REMOVE_NOTIFICATIONS, {
    'ac': accountId,
  });
  const config = getRequestConfig(REQUEST_TYPES.DEL, url);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};