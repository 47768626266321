import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { AdminDialog, AdminDialogTitle, AdminDialogContent, PrimaryButton } from '../../components';
import {
  DXCustomerFilter,
  DXCustomerTypeFilter,
  DXShippingTypeFilter,
  DXPaymentMethodFilter,
  DXFiltersManager,
  DXOrderStatusFilter,
  DXOrderPaymentStatusFilter,
  DXOrderLocationFilter,
  DXDeliveryCompanyFilter,
  DXReportFilter,
  DXReportsShippingDatesFilter,
} from '../../components/AdminDXTable/Filters';
import styles from './styles';

const propTypes = {
  currentReport: PropTypes.object.isRequired,
  openWithFilter: PropTypes.object,
  appliedFilters: PropTypes.array,
  onApplyFilters: PropTypes.func,
  onClose: PropTypes.func,
};

const defaultProps = {
  appliedFilters: [],
};

class ReportsFilterDialog extends React.Component {
  constructor(props) {
    super(props);
    const { currentReport } = this.props;

    let filters = [
      DXCustomerFilter,
      DXCustomerTypeFilter,
      DXShippingTypeFilter,
      DXPaymentMethodFilter,
      DXOrderStatusFilter,
      DXOrderPaymentStatusFilter,
      DXOrderLocationFilter,
      DXDeliveryCompanyFilter,
      DXReportFilter,
      DXReportsShippingDatesFilter,
    ];

    this.filters = [
      ...filters,
      // Filters dependant on report
      ...(currentReport.filters || []),
    ];
  }

  render() {
    const {
      intl,
      classes,
      onClose,
      onApplyFilters,
      openWithFilter,
      appliedFilters,
      isDateFilterRequired,
      open = true,
    } = this.props;

    return (
      <AdminDialog open={open} closeButton onClose={onClose}>
        <AdminDialogTitle title={intl.formatMessage({ id: 'global.addFilter' })} />
        <AdminDialogContent className={classes.wrapper}>
          <DXFiltersManager
            filters={this.filters}
            onRef={(filters) => (this.filtersManager = filters)}
            queryParamsByFilterName={{ reportShippingDates: { readOnly: isDateFilterRequired } }}
            openWithFilter={openWithFilter}
            appliedFilters={appliedFilters}
            defaultFilterName={'customer'}
          />

          <div className={classes.controls}>
            <PrimaryButton onClick={() => onApplyFilters(this.filtersManager.getAppliedFilters())}>
              {intl.formatMessage({ id: 'global.apply' })}
            </PrimaryButton>
          </div>
        </AdminDialogContent>
      </AdminDialog>
    );
  }
}

ReportsFilterDialog.propTypes = propTypes;
ReportsFilterDialog.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(injectIntl(ReportsFilterDialog));
