import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { PrimaryButton } from '../../components';
import { COLORS } from '../../helpers';
import { getCustomerName } from '../../helpers/customer';

const styles = () => ({
  wrap: {
    padding: 10,
    width: 300,
    margin: '0 15px 15px 0',
  },
  button: {
    height: 'auto',
    minWidth: 'auto',
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    marginRight: 10,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});

const propTypes = {
  account: PropTypes.object,
  main: PropTypes.bool,
  onResentInvite: PropTypes.func,
  onResetPassword: PropTypes.func,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

class CustomerAccountBox extends Component {
  render() {
    const { account, onEdit, onDelete, classes, onResendInvite, onResetPassword, main, intl } = this.props;

    return (
      <Paper className={classes.wrap}>
        <div>
          <Typography component="h6" variant="subtitle1">
            {main
              ? getCustomerName(account)
              : `${account?.firstName ? account.firstName + ' ' : ''}${account.lastName || ''}`}
            {main && (
              <span style={{ display: 'inline', color: COLORS.text }}>
                &nbsp;({intl.formatMessage({ id: 'global.main' })})
              </span>
            )}
          </Typography>
          <Typography component="h6" variant="subtitle1">
            {main ? account.email : account.username}
          </Typography>

          <div className={classes.buttons}>
            {account.passwordDefault !== null && account.passwordDefault === false && onResetPassword && (
              <PrimaryButton className={classes.button} onClick={onResetPassword}>
                {intl.formatMessage({ id: 'global.resetPassword' })}
              </PrimaryButton>
            )}

            {(account.passwordDefault === null || account.passwordDefault === true) && onResendInvite && (
              <PrimaryButton className={classes.button} onClick={onResendInvite}>
                {intl.formatMessage({ id: 'global.sendInvite' })}
              </PrimaryButton>
            )}
          </div>
        </div>
        <div className={classes.actions}>
          <IconButton onClick={() => onEdit(account)}>
            <EditIcon color="secondary" />
          </IconButton>
          {onDelete && (
            <IconButton onClick={() => onDelete(account)}>
              <DeleteIcon color="secondary" />
            </IconButton>
          )}
        </div>
      </Paper>
    );
  }
}

CustomerAccountBox.propTypes = propTypes;

export default withStyles(styles)(injectIntl(CustomerAccountBox));
