import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../hocs';
import { AdminDialog } from '../../components';
import SearchStage from './SearchStage/SearchStage';
import Stages from './Stages';
import styles from './styles';
import AddLocationStage from './AddLocationStage/AddLocationStage';

const propTypes = {
  producer: PropTypes.object.isRequired,
  onDone: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onLocationsUpdate: PropTypes.func,
  setEditingLocation: PropTypes.func,
  editingLocation: PropTypes.object,
  selected: PropTypes.array,
};

const defaultProps = {
  loading: false,
  onLocationsUpdate: () => {},
  selected: [],
};

const ProducerSearchLocationDialog = (props) => {
  const {
    onDone,
    classes,
    onClose,
    locations,
    onLocationsUpdate,
    editingLocation,
    setEditingLocation,
    selected,
    onFinishEditing,
  } = props;
  useEffect(() => {
    if (!!editingLocation) {
      setStage(Stages.ADD_LOCATION);
    }
  }, [editingLocation]);

  const [stage, setStage] = useState(Stages.SEARCH);

  const _onDone = (location) => {
    onDone(location);
    setEditingLocation(null);
  };

  const _onFinishEditing = (location) => {
    onFinishEditing(location);
    setEditingLocation(null);
  };

  const getNotAddedLocations = () => {
    return locations.filter((location) => !selected.includes(location.id));
  };

  return (
    <AdminDialog open closeButton paperClass={classes.paper} onClose={() => onClose()}>
      {stage === Stages.SEARCH && (
        <SearchStage
          onApprove={_onDone}
          locations={getNotAddedLocations()}
          onAddLocation={() => setStage(Stages.ADD_LOCATION)}
        />
      )}

      {stage === Stages.ADD_LOCATION && (
        <AddLocationStage
          entity={editingLocation || {}}
          onDone={_onFinishEditing}
          onLocationsUpdate={onLocationsUpdate}
        />
      )}
    </AdminDialog>
  );
};

ProducerSearchLocationDialog.propTypes = propTypes;
ProducerSearchLocationDialog.defaultProps = defaultProps;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(ProducerSearchLocationDialog)));
