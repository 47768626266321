class ScheduleVariant {
  constructor(props) {
    this.id = props?.variantId || 'new';
    this.dates = props?.dates || [];
    this.nextDate = props?.nextDate || null;
  }

  getDates() {
    return this.dates;
  }

  getNextDate() {
    return this.nextDate;
  }

  getDatesWithoutStatuses() {
    return this.dates.map((date) => date.date);
  }
}

export default ScheduleVariant;
