import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import DXSelectFilter from '../DXSelectFilter/DXSelectFilter';
import DXFilterConfig from '../DXFilterConfig';

const propTypes = {
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  selected: [],
};

class DXProductTypesFilter extends React.Component {
  render() {
    const { selected, onChange, intl, equality, queryParamsByFilterName } = this.props;

    const config = DXProductTypesFilter.getConfig(intl, equality, queryParamsByFilterName);
    return (
      <DXSelectFilter
        selected={selected}
        title={config.title}
        values={config.values}
        onChange={onChange}
        multi={config.multi}
        initialLimit={9}
      />
    );
  }
}

DXProductTypesFilter.propTypes = propTypes;
DXProductTypesFilter.defaultProps = defaultProps;
DXProductTypesFilter.getLabel = (intl, selectedValues) => {
  return DXSelectFilter.getLabel(intl, '', selectedValues);
};
DXProductTypesFilter.applyToQueryParams = (queryParams, selectedValues) => {
  queryParams['types'] = selectedValues;
  return queryParams;
};
DXProductTypesFilter.getConfig = (intl, selectedEquality, queryParamsByFilterName) => {
  let config = new DXFilterConfig({
    name: 'productTypes',
    selected: [],
    property: 'productTypes',
    title: intl.formatMessage({ id: 'filter.productTypes' }),
    disabled: false,
    equalityStatus: false,
    multi: true,
  });

  queryParamsByFilterName.types.forEach((type) => {
    config.addFilterValue(type, type);
  });

  return config;
};

export default injectIntl(DXProductTypesFilter);
