import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ProductSingle from '../ProductSingle';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const propTypes = {
  products: PropTypes.array.isRequired,
  unlisted: PropTypes.bool,
};

const ProductsList = (props) => {
  const { products, unlisted, setToastAction } = props;

  const classes = useStyles();

  let prevType = '';
  let prevSubType = '';

  const isDrawTitle = (product) => {
    const savedType = prevType;
    const savedSubType = prevSubType;

    prevType = product.type;
    prevSubType = product.subType;

    return product.subType !== savedSubType || product.type !== savedType;
  };

  return (
    <div className={classes.root}>
      {!!products?.length &&
        products.map((product) => (
          <ProductSingle
            isDrawTitle={isDrawTitle(product)}
            product={product}
            key={product.id}
            unlisted={unlisted}
            setToastAction={setToastAction}
          />
        ))}
    </div>
  );
};

ProductsList.propTypes = propTypes;

export default ProductsList;
