import axios from 'axios';
import { replaceUrlPlaceholder, replaceUrlPlaceholders, getRequestConfig, REQUEST_TYPES } from '../request';
import API_END_POINTS from '../endpoints';
import { showFileFromBlob } from '../../helpers';

/**
 * Retrieve orders
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSuppliersRetrieveOrders = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.SUPPLIER_ORDER_GET_ALL);
  config.params = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data, response.headers);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Cancel orders
 * @param {object} ids
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSuppliersOrdersCancel = function (ids, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.SUPPLIER_ORDER_BULK_CANCEL);
  config.params = {
    orderIds: ids,
  };
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Mark orders as received
 * @param {object} ids
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSuppliersOrdersMarkReceived = function (ids, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.SUPPLIER_ORDER_BULK_RECEIVED);
  config.params = {
    orderIds: ids,
  };
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Retrieve order
 * @param {string} id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSuppliersRetrieveOrder = function (id, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.SUPPLIER_ORDER_GET_ONE, 'or', id);
  const config = getRequestConfig(REQUEST_TYPES.GET, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Adds line item on order
 * @param {string} orderId
 * @param {string} variantId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSuppliersAddVariantToOrder = function (orderId, variantId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.SUPPLIER_ORDER_ADD_VARIANT, 'or', orderId);
  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  // Set request params
  data = data || {};
  data.variantId = variantId;
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Updates line item of the order
 * @param {string} orderId
 * @param {string} lineItemId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSuppliersUpdateOrderLineItem = function (orderId, lineItemId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.SUPPLIER_ORDER_UPDATE_LINE_ITEM, {
    or: orderId,
    it: lineItemId,
  });
  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  // Set request params
  data = data || {};
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Deletes line item from order
 * @param {string} orderId
 * @param {string} lineItemId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSuppliersRemoveLineItemFromOrder = function (orderId, lineItemId, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.SUPPLIER_ORDER_REMOVE_LINE_ITEM, {
    or: orderId,
    it: lineItemId,
  });
  const config = getRequestConfig(REQUEST_TYPES.DEL, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Add note on order
 * @param {string} orderId
 * @param {string} note
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSuppliersOrderAddNote = function (orderId, note, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.SUPPLIER_ORDER_ADD_NOTE, 'or', orderId);
  const config = getRequestConfig(REQUEST_TYPES.PUT, url);
  config.data = { note: note };
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Cancel order
 * @param {string} orderId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSuppliersOrderCancel = function (orderId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.SUPPLIER_ORDER_CANCEL, 'or', orderId);
  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Adds note for line item
 * @param {string} orderId
 * @param {string} lineItemId
 * @param {string} note
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSuppliersOrderLineItemSetNote = function (orderId, lineItemId, note, onSuccess, onError) {
  apiSuppliersUpdateOrderLineItem(orderId, lineItemId, { note: note }, onSuccess, onError);
};

/**
 * Set line item quantity
 * @param {string} orderId
 * @param {string} lineItemId
 * @param {string} quantity
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSuppliersOrderLineItemSetQuantity = function (orderId, lineItemId, quantity, onSuccess, onError) {
  apiSuppliersUpdateOrderLineItem(orderId, lineItemId, { quantity: quantity }, onSuccess, onError);
};

/**
 * Adds invoice payment method on order
 * @param {string} orderId
 * @param {string} method
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSuppliersAddInvoicePaymentMethodOnOrder = function (orderId, method, data, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.SUPPLIER_ORDER_ADD_INVOICE_PAYMENT_METHOD, {
    or: orderId,
  });
  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  // Set request params
  data = data || {};
  data.method = method ? method : null;

  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Adds direct payment method on order
 * @param {string} orderId
 * @param {string} method
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSuppliersAddDirectPaymentMethodOnOrder = function (orderId, method, data, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.SUPPLIER_ORDER_ADD_DIRECT_PAYMENT_METHOD, {
    or: orderId,
    md: method,
  });
  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  // Set request params
  data = data || {};
  config.data = data;

  axios(config)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Adds shipping to order
 * @param {string} orderId
 * @param {string} type delivery|pickup
 * @param {object} data
 * @param {string} deliveryCompanyId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSuppliersSetOrderShipping = function (orderId, type, data, deliveryCompanyId, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.SUPPLIER_ORDER_SET_SHIPPING, {
    or: orderId,
    type: type,
  });
  const config = getRequestConfig(REQUEST_TYPES.PUT, url);
  // Set request params
  data = data || {};
  config.data = { ...data, type };

  axios(config)
    .then((shippingResponse) => {
      if (deliveryCompanyId) {
        // Set draft delivery company
        apiSuppliersOrderSetDeliveryCompany(
          orderId,
          deliveryCompanyId,
          (deliveryCompanyResponse) => {
            onSuccess && onSuccess(deliveryCompanyResponse);
          },
          (error) => onError && onError(error)
        );
      } else {
        onSuccess && onSuccess(shippingResponse.data);
      }
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Reorder order
 * @param {string} orderId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSuppliersReorderOrder = function (orderId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.SUPPLIER_ORDER_REORDER, 'or', orderId);
  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Refund for order
 * @param {string} orderId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSuppliersRefundOrder = function (orderId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.SUPPLIER_ORDER_REFUND, {
    or: orderId,
  });

  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  // Set request params
  data = data || {};
  config.data = data;

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Adds order shipping as delivery
 * @param {string} orderId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSuppliersSetOrderShippingAsDelivery = function (orderId, data, onSuccess, onError) {
  apiSuppliersSetOrderShipping(orderId, 'delivery', data, onSuccess, onError);
};

/**
 * Adds order shipping as pickup
 * @param {string} orderId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSuppliersSetOrderShippingAsPickup = function (orderId, data, onSuccess, onError) {
  apiSuppliersSetOrderShipping(orderId, 'pickup', data, onSuccess, onError);
};

/**
 * Set delivery company
 * @param {string} orderId
 * @param {string} companyId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSuppliersOrderSetDeliveryCompany = function (orderId, companyId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.SUPPLIER_ORDER_PUT_DELIVERY_COMPANY, 'or', orderId);
  const config = getRequestConfig(REQUEST_TYPES.PUT, url);
  config.data = { id: companyId };
  axios(config)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Delete delivery company
 * @param {string} orderId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSuppliersOrderDeleteDeliveryCompany = function (orderId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.SUPPLIER_ORDER_DELETE_DELIVERY_COMPANY, 'or', orderId);
  const config = getRequestConfig(REQUEST_TYPES.DEL, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Approve order
 * @param {string} orderId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSuppliersApproveOrder = function (orderId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.SUPPLIER_ORDER_APPROVE, 'or', orderId);
  const config = getRequestConfig(REQUEST_TYPES.PUT, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Get order invoice
 * @param {string} hash
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSuppliersGetOrderInvoice = function (hash, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.SUPPLIER_ORDER_GET_INVOICE, 'hash', hash);
  const config = getRequestConfig(REQUEST_TYPES.GET, url);
  config.responseType = 'arraybuffer';
  config.headers['Accept'] = 'application/pdf';

  axios(config)
    .then((response) => {
      const pdfUrl = showFileFromBlob(response.data, 'invoice');
      onSuccess && onSuccess(pdfUrl);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Print orders
 * @param {object} ids
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSuppliersOrdersPrint = function (ids, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.SUPPLIER_ORDER_BULK_PRINT);
  config.params = {
    orderIds: ids,
  };
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Adds custom line item
 * @param {string} orderId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSuppliersAddCustomLineItemToOrder = function (orderId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.SUPPLIER_ORDER_ADD_CUSTOM_LINE_ITEM, 'or', orderId);
  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  // Set request params
  data = data || {};
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};
export const apiSuppliersOrderUpdatedSendEmails = function (
  order,
  { orderUpdated, invoiceUpdated },
  onSuccess,
  onError
) {
  let requests = [];

  if (orderUpdated) {
    const url = replaceUrlPlaceholder(API_END_POINTS.SUPPLIER_ORDER_RESEND_EMAIL, 'or', order.id);
    const config = getRequestConfig(REQUEST_TYPES.POST, url);
    requests.push(axios.post(config.url, {}, config));
  }

  if (invoiceUpdated) {
    const url = replaceUrlPlaceholder(API_END_POINTS.SUPPLIER_ORDER_SEND_INVOICE_UPDATED_EMAIL, 'or', order.id);
    const config = getRequestConfig(REQUEST_TYPES.POST, url);
    requests.push(axios.post(config.url, {}, config));
  }

  Promise.all(requests)
    .then((...res) => {
      onSuccess && onSuccess(res);
    })
    .catch((error) => {
      onError && onError(error);
    });
};
/**
 * Adds payment method on prepurchase draft order
 * @param {string} orderId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSuppliersAddPrepurchasePaymentMethodOnOrder = function (orderId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.SUPPLIER_ORDER_ADD_PREPURCHASE_PAYMENT_METHOD, {
    or: orderId,
  });

  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  // Set request params
  data = data || {};
  config.data = data;

  axios(config)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch((error) => {
      onError(error);
    });
};
/**
 * Book order
 * @param {string} orderId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSuppliersOrderBook = function (orderId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.SUPPLIER_ORDER_BOOK, 'or', orderId);
  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Order book payment
 * @param {string} orderId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSuppliersOrderBookPayment = function (orderId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.SUPPLIER_ORDER_BOOK_PAYMENT, 'or', orderId);
  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};
/**
 * Add existing customer to order
 * @param {string} orderId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSuppliersAddCustomerOnOrder = function (orderId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.SUPPLIER_ORDER_ADD_CUSTOMER, 'or', orderId);
  const config = getRequestConfig(REQUEST_TYPES.PUT, url);

  // Set request params
  data = data || {};
  config.data = data;

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Remove customer from order
 * @param {string} orderId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSuppliersRemoveCustomerFromOrder = function (orderId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.SUPPLIER_ORDER_REMOVE_CUSTOMER, 'or', orderId);
  const config = getRequestConfig(REQUEST_TYPES.DEL, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

export const apiSuppliersOrderAddDirectDiscount = (orderId, data, onSuccess, onError) => {
  const url = replaceUrlPlaceholder(API_END_POINTS.SUPPLIER_ORDER_ADD_DIRECT_DISCOUNT, 'or', orderId);
  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

export const apiSuppliersOrderDeleteDiscount = (orderId, onSuccess, onError) => {
  const url = replaceUrlPlaceholder(API_END_POINTS.SUPPLIER_ORDER_DELETE_DISCOUNT, 'or', orderId);
  const config = getRequestConfig(REQUEST_TYPES.DEL, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

export const apiSuppliersOrderPackingListBulkPrint = (orderIds, onSuccess, onError) => {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.SUPPLIER_ORDER_PACKING_LIST_BULK_PRINT);
  config.data = { orderIds: orderIds };
  config.responseType = 'blob';

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};
/**
 * Approve order line items
 * @param {string} orderId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSuppliersApproveOrderLineItems = function (orderId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.SUPPLIER_ORDER_MARK_APPROVED, 'or', orderId);
  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};
