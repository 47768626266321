import React from "react";
import {Table} from '@devexpress/dx-react-grid-material-ui';
import {
  weightToUser,
  variantHasPriceWithLevel,
  getVariantPriceWithoutCurrency,
  PRICE_LEVELS,
  toPaper,
} from "../../helpers";

export const TableCell = (reload, {row, ...props}) => {
  const type = props.column.type || "text";
  const image = row.image;

  if (type === 'image' && image) {
    return (
      <Table.Cell {...props}>
        <img src={image.src} alt={row.name} style={{maxWidth: 50}}/>
      </Table.Cell>
    )
  }

  if (type === 'tags' && isVariant(row)) {
    return (
      <Table.Cell {...props}>
        {(row.tags || []).join(", ")}
      </Table.Cell>
    )
  }

  if (type === 'quantity' && isVariant(row)) {
    return (
      <Table.Cell {...props}>
        {row.unitQuantity ?
          parseFloat(weightToUser(row.unitQuantity)).toFixed(2) : ""}
      </Table.Cell>
    )
  }

  if (type === 'subType' && isProduct(row)) {
    return (
      <Table.Cell {...props}>
        {row.subType || ""}
      </Table.Cell>
    )
  }

  if (type === 'name' && isVariant(row)) {
    return (
      <Table.Cell {...props}>
        {row.name || ""}
      </Table.Cell>
    )
  }

  if (type === 'name' && isProduct(row)) {
    return (
      <Table.Cell {...props}>
        {row.name || ""}
      </Table.Cell>
    )
  }

  if (type === 'description' && isVariant(row)) {
    return (
      <Table.Cell {...props}>
        {row.description || ""}
      </Table.Cell>
    )
  }

  if (type === 'description' && isProduct(row)) {
    return (
      <Table.Cell {...props}>
        {row.description || ""}
      </Table.Cell>
    )
  }

  if (type === 'unit' && isVariant(row)) {
    return (
      <Table.Cell {...props}>
        {row.unit || ""}
      </Table.Cell>
    )
  }

  if (type === PRICE_LEVELS.WHOLESALE_MARKET &&
    variantHasPriceWithLevel(row, PRICE_LEVELS.WHOLESALE_MARKET)) {
    return (
      <Table.Cell {...props}>
        {toPaper(getVariantPriceWithoutCurrency(row, PRICE_LEVELS.WHOLESALE_MARKET))}
      </Table.Cell>
    )
  }

  if (type === PRICE_LEVELS.WHOLESALE_PREORDER && variantHasPriceWithLevel(row, PRICE_LEVELS.WHOLESALE_PREORDER)) {
    return (
      <Table.Cell {...props}>
        { toPaper(getVariantPriceWithoutCurrency(row, PRICE_LEVELS.WHOLESALE_PREORDER))}
      </Table.Cell>
    )
  }

  if (type === PRICE_LEVELS.RETAIL_MARKET &&
    variantHasPriceWithLevel(row, PRICE_LEVELS.RETAIL_MARKET)) {
    return (
      <Table.Cell {...props}>
        {toPaper(getVariantPriceWithoutCurrency(row, PRICE_LEVELS.RETAIL_MARKET))}
      </Table.Cell>
    )
  }

  if (type === PRICE_LEVELS.RETAIL_PREORDER &&
    variantHasPriceWithLevel(row, PRICE_LEVELS.RETAIL_PREORDER)) {
    return (
      <Table.Cell {...props}>
        {toPaper(getVariantPriceWithoutCurrency(row, PRICE_LEVELS.RETAIL_PREORDER))}
      </Table.Cell>
    )
  }

  return <Table.Cell {...props} />;
};

const isProduct = (row) => {
  return  typeof(row.variants) !== 'undefined';
};

const isVariant = (row) => {
  return  typeof(row.variants) === 'undefined';
};

export const TableRow = (onClick, {row, ...props}) => {
  return (
    <Table.Row
      {...props}
      onClick={() => onClick && onClick(row)}
      style={{
        backgroundColor: (row.variants) ?
          'rgba(224, 224, 224, 0.4)' : '#ffffff'
      }}
    />
  )
};