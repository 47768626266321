import {COLORS} from '../../helpers';

export default ({
  wrapper: {},
  name: {
    fontSize: 18,
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.text,
    marginBottom: 40
  },
  variants: {
    marginBottom: 50,
  },
  successMessageWrap: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  okIcon: {
    fill: COLORS.text,
    fontSize: 78,
    marginRight: 10,
    marginBottom: 40,
  },
  textSuccess: {
    marginBottom: 20,
  },
  productSubType: {
    paddingLeft: 10
  },
  hr: {
    width: 200,
    height: 1,
    backgroundColor: COLORS.text,
    marginBottom: 15
  },
  orderDetails: {
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  input: {
    marginBottom: 25,
  },
  inputText: {
    textAlign: 'right'
  },
  button: {
    minWidth: '40%',
    textTransform: 'uppercase',
  },
  mr20: {
    marginRight: 10
  },
  controls: {
    margin: '27px 0 0 0',
    textAlign: 'center'
  },
  control: {
    padding: '0 105px'
  },
  // table
  table: {
    marginBottom: 10,
    '& > tbody td, & > thead th': {
      minWidth: 50,
      maxWidth: 200,
      textAlign: 'center',
      paddingLeft: 5,
      paddingRight: 5
    },
    '& > tbody td:first-child, & > thead th:first-child': {
      textAlign: 'left',
    },
    '& > thead th:last-child': {
      textAlign: 'center'
    }
  },
  row: {
    padding: '0 !important',
  },
  redRow: {
    padding: 0,
    backgroundColor: 'rgba(245, 0, 87, 0.1)',
  },
  subType: {
    color: '#855E42'
  },
  note: {
    fontSize: 10
  },
  noteTitle: {
    fontWeight: 'bold'
  },
  orderNote: {
    width: '100%'
  },
  quantitySwitcher: {
    width: 110,
  },
  activeElementsWrapper: {
    padding: 20,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  refundAll: {
    marginBottom: 15,
  }
  
});
