import React from 'react';
import {
  MuiThemeProvider,
  createTheme
} from '@material-ui/core/styles';
import InputLabel from "@material-ui/core/InputLabel";

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: "#363636",
        fontSize: 12,
        "&$focused": {
          color: "#363636",
        },
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: 'translate(0, 1.5px)',
      },
    }
  },
});

const PrimaryLabel = (props) => {
  const {children, ...other} = props;
  return (
    <MuiThemeProvider theme={theme} >
      <InputLabel {...other}>{children}</InputLabel>
    </MuiThemeProvider>
  );
};

export default PrimaryLabel;
