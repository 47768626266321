import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import EmptyImage from './assets/no.jpg';
import { COLORS } from '../../helpers';

const propTypes = {
  onClick: PropTypes.func,
  product: PropTypes.object.isRequired,
};

const styles = {
  product: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    width: 160,
    height: 140,
    overflow: 'visible',
    marginLeft: 2,
    marginRight: 17,
    marginBottom: 17,
  },
  content: {
    '&:last-child': {
      padding: '8px 5px',
    },
    padding: 0,
    textAlign: 'center',
  },
  img: {},
  name: {
    textAlign: 'center',
    alignSelf: 'flex-end',
  },
  tile: {
    overflow: 'visible',
  },
  paper: {
    height: '100%',
    width: '100%',
  },
  media: {
    position: 'relative',
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  productName: {
    height: 20,
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.text,
    fontSize: 13,
    fontWeight: 'bold',
  },
  text: {
    height: 20,
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.text,
    fontSize: 13,
  },
  card: {
    marginLeft: 2,
    marginRight: 10,
    marginBottom: 10,
    cursor: 'pointer',
    width: 160,
  },
};

const ProductAsCard = ({ product, classes, onClick }) => {
  return (
    <Card className={classes.card} onClick={() => onClick(product)}>
      <CardMedia
        className={classes.media}
        image={product.thumb ? product.thumb : product.image ? product.image : EmptyImage}
        title={product.name}
      />

      <CardContent className={classes.content}>
        <Typography className={classes.productName} noWrap>
          {product.name}
        </Typography>

        {!!product?.supplier?.name && (
          <Typography className={classes.text} noWrap>
            {product.supplier.name}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

ProductAsCard.propTypes = propTypes;

export default withStyles(styles)(ProductAsCard);
