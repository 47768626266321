import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';

const PrivateRoute = (props) => {
  const { component: Component, render, ...rest } = props;

  const authenticated = useSelector((state) => state.auth.authenticated);

  if (!!authenticated) {
    return <Component {...rest} />;
  } else {
    return <Navigate to={'/'} replace={true} state={{ from: props.location }} />;
  }
};

export default PrivateRoute;
