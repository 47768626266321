export default (types) => {
  return (
    {
      name: {
        presence: {
          message: '^validation.product.prop.nameIsMandatory'
        },
        exclusion: {
          within: types,
          message: "^validation.product.prop.nameShouldBeUnique"
        }
      },
      subQuantity: {
        numericality: true
      },
      sharedType: {
        presence: {
          message: "^validation.product.prop.sharedTypeError"
        },
      }
    }
  )
};
