import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { Fade, makeStyles } from '@material-ui/core';
import { CopyIcon } from '../../CustomIcons';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
  copiedSuccessWrapper: {
    position: 'fixed',
    bottom: 30,
    left: 'calc(50% - 150px)',
    backgroundColor: '#000',
    padding: '10px 20px',
    border: '2px solid #000',
    color: 'white',
    borderRadius: 4,
    zIndex: 1000,
    minWidth: 300,
  },
  textWrapper: {
    marginRight: 5,
  },
  iconWrapper: {
    display: 'inline-block',
    position: 'relative',
  },
  icon: {},
}));

const propTypes = {
  value: PropTypes.string,
};

const defaultProps = {
  formatValueBeforeApplyEditing: (value) => value,
};

const CellWithCopyToClipboard = (props) => {
  const { cellProps, value, onSuccessMessage } = props;
  const classes = useStyles();
  const intl = useIntl();

  const [isCopied, setIsCopied] = useState(false);
  const successMessage = onSuccessMessage
    ? intl.formatMessage({ id: onSuccessMessage })
    : intl.formatMessage({ id: 'global.copied' });

  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => {
        setIsCopied(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isCopied]);

  const onClick = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(value).then(() => {
      setIsCopied(true);
    });
  };

  return (
    <Table.Cell
      {...cellProps}
      style={{
        cursor: 'pointer',
      }}>
      <div className={classes.root}>
        <span className={classes.textWrapper} onClick={onClick}>
          {value}
        </span>
        <div className={classes.iconWrapper}>
          <IconButton onClick={onClick}>
            <CopyIcon size={14} className={classNames([classes.icon, 'showOnCellHover'])} />
          </IconButton>
          <Fade in={isCopied}>
            <div className={classes.copiedSuccessWrapper}>{successMessage}</div>
          </Fade>
        </div>
      </div>
    </Table.Cell>
  );
};

CellWithCopyToClipboard.propTypes = propTypes;
CellWithCopyToClipboard.defaultProps = defaultProps;

export default CellWithCopyToClipboard;
