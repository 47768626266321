import { COLORS } from '../../helpers';

export default (theme) => ({
  tabsRoot: {
    boxShadow: 'none',
    backgroundColor: '#ffffff',
  },
  appBar: {
    backgroundColor: '#ffffff',
    boxShadow: 'none',
    borderTop: '1px solid ' + COLORS.border,
    borderBottom: '1px solid ' + COLORS.border,
  },
  tabsIndicator: {
    borderBottom: `1px solid ${COLORS.green}`,
    backgroundColor: COLORS.green,
  },
  scrollButtons: {
    color: '#363636',
  },
  tabRoot: {
    textTransform: 'uppercase',
    minWidth: 120,
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: theme.spacing(4),
    fontFamily: 'Roboto',
    '&:hover': {
      color: COLORS.green,
      opacity: 1,
    },
    '&$tabSelected': {
      color: COLORS.green,
    },
    '&:focus': {
      color: COLORS.green,
    },
  },
  tabSelected: {},
});
