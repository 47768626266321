import React from 'react';
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import DXSelectFilter from '../DXSelectFilter/DXSelectFilter'
import DXFilterConfig from '../DXFilterConfig'
import constants from '../../constants'

const propTypes = {
  equality: PropTypes.string,
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  selected: [],
};

class DXOrderPaymentStatusFilter extends React.Component {

  render() {
    const { selected, onChange, intl, equality} = this.props;
    const config = DXOrderPaymentStatusFilter.getConfig(intl, equality);
    return (
      <DXSelectFilter selected={selected}
                      title={config.title}
                      values={config.values}
                      onChange={onChange}
                      multi={config.multi}
      />
    )
  }
}

DXOrderPaymentStatusFilter.propTypes = propTypes;
DXOrderPaymentStatusFilter.defaultProps = defaultProps;
DXOrderPaymentStatusFilter.getLabel = (intl, selectedValues) => {
  return DXSelectFilter.getLabel(intl, 'filter.financialStatus.', selectedValues);
};
DXOrderPaymentStatusFilter.applyToQueryParams = (queryParams, selectedValues) => {
  queryParams['financialStatus'] = selectedValues;
  return queryParams;
};
DXOrderPaymentStatusFilter.getConfig = (intl, equaltity) => {
  let config = new DXFilterConfig({
    name: 'financialStatus',
    selected: [],
    property: 'financialStatus',
    title: intl.formatMessage({id: 'filter.financialStatus'}),
    disabled: false,
    equalityStatus: false,
    multi: true
  });

  if (equaltity === constants.FILTER_EQUALITY_EQUALS) {
    config.addFilterValue('unpaid', intl.formatMessage({id: 'filter.financialStatus.unpaid'}));
    config.addFilterValue('pending', intl.formatMessage({id: 'filter.financialStatus.pending'}));
    config.addFilterValue('paid', intl.formatMessage({id: 'filter.financialStatus.paid'}));
  }

  return config;
};

export default injectIntl(
  DXOrderPaymentStatusFilter
);
