import moment from 'moment';
import { ORDER_STATUSES } from './index';

/**
 * Whether order is packed or not
 * @return {boolean}
 */
export const isOrderPacked = (order) => {
  return (
    order.status === ORDER_STATUSES.ORDER_RECEIVED ||
    order.status === ORDER_STATUSES.ORDER_PACKED ||
    order.status === ORDER_STATUSES.ORDER_NOT_APPROVED ||
    order.status === ORDER_STATUSES.ORDER_OUT_FOR_DELIVERY
  );
};

export const isMayOrderPay = (customer, order) => {
  return order.payment.type === 'invoice' && !customer.autoCharge && order.status === ORDER_STATUSES.ORDER_RECEIVED;
};

/**
 * Checks whether customer may edit order or not
 * @param customer
 * @param order
 * @return {boolean}
 */
export const isMayOrderEdit = (customer, order) => {
  if (order.shipping && order.shipping.until) {
    const current = moment.tz(process.env.REACT_APP_TIME_ZONE).format('MM/D/Y HH:mm');
    const until = moment.parseZone(order.shipping.until).format('MM/D/Y HH:mm');
    return current < until;
  }
  return false;
};

export const isDelivered = (order) => {
  return (
    order.status === ORDER_STATUSES.ORDER_RECEIVED ||
    order.status === ORDER_STATUSES.ORDER_NOT_APPROVED ||
    order.status === ORDER_STATUSES.ORDER_PACKED ||
    order.status === ORDER_STATUSES.ORDER_OUT_FOR_DELIVERY
  );
};

export const sourceToHuman = (order) => {
  if (order.source === 'pos') {
    return 'Market';
  }
  if (order.source === 'admin' || order.source === 'storefront') {
    return 'Pre-order';
  }
  return order.source;
};

export const hasSubscription = (order) => {
  return !!order?.subscription;
};
