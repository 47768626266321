import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Icon from '../../../../components/Icon/Icon';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { ErrorBox, GreenButton, MessageBox } from '../../../../components';
import CheckIcon from '@material-ui/icons/Check';
import {apiSendProductsListCSV} from '../../../../api';

ProductsContent.propTypes = {
  isFinished: PropTypes.bool,
  history: PropTypes.object,
};


const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  mainContent: {
    flexBasis: 500,
    flex: 0.8,
  },
  iconContentWrapper: {
    flex: 0.2,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  iconWrapper: {
    width: 100,
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 30,
    backgroundColor: '#EEEFF0',
    borderRadius: 50,
    position: 'relative',
  },
  sectionTitleWrapper: {},
  sectionTitle: {
    fontSize: 16,
    margin: 0,
    marginBottom: 30,
  },
  sectionInformation: {},
  icon: {
    fontSize: 60,
    fill: '#707070 !important',
  },
  iconCheck: {
    fontSize: 36,
    fill: '#fff !important',
    position: 'absolute',
    bottom: 0,
    right: 0,
    backgroundColor: '#247356',
    borderRadius: 36,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
  },
  controls: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: 30,
  },
  button: {
    textTransform: 'none',
    marginBottom: 20,
    minWidth: 220,
  },
  fileLoadedWrapper: {
    marginLeft: 0
  },
  errorWrapper: {

  }
}));

function ProductsContent(props) {
  const {intl, isFinished, history} = props;
  const [loading, setLoading] = useState(false);
  const [fileLoad, setFileLoad] = useState(false);
  const [fileLoadError, setFileLoadError] = useState('');

  const classes = useStyles();
  let fileRef = null;

  const getContentStep = () => {
    return (
      <>
        <div className={classes.sectionTitleWrapper}><h2
          className={classes.sectionTitle}>{intl.formatMessage({id: 'screen.homeScreen.addProducts'})}</h2></div>

        <div className={classes.sectionInformation}>
          <p>{intl.formatMessage({id: 'screen.homeScreen.addProducts.text'})}</p>
        </div>

        <div className={classes.controls}>
          <GreenButton onClick={() => {
            history.push('/product/new-product')
          }} className={classes.button}>
            {intl.formatMessage({id: 'screen.homeScreen.addProducts.addProductsManually'})}
          </GreenButton>

          <GreenButton
            disabled={loading}
            onClick={() => {
              fileRef.click();
            }} className={classes.button}>
            {intl.formatMessage({id: 'screen.homeScreen.addProducts.bulkImport'})}
          </GreenButton>
          {!!fileLoad && (
            <div className={classes.sectionInformation}>
              <p>{intl.formatMessage({id: 'screen.homeScreen.addProducts.productsSuccessfullyLoaded'})}</p>
            </div>
          )}
          {!!fileLoadError && (
            <div className={classes.sectionInformation}>
              <p>{fileLoadError}</p>
            </div>
          )}
        </div>
      </>
    )
  }

  const getFinishStep = () => {
    return (
      <>
        <div className={classes.sectionTitleWrapper}><h2
          className={classes.sectionTitle}>{intl.formatMessage({id: 'screen.homeScreen.addProducts.finalTitle'})}</h2>
        </div>

        <div className={classes.sectionInformation}>
          <p>{intl.formatMessage({id: 'screen.homeScreen.addProducts.finalText'})}</p>
        </div>

        <div className={classes.controls}>
          <GreenButton onClick={() => {
            history.push('/products')
          }} className={classes.button}>
            {intl.formatMessage({id: 'screen.homeScreen.addProducts.viewProducts'})}
          </GreenButton>

          <GreenButton onClick={() => {
            history.push('/product/new-product')
          }} className={classes.button}>
            {intl.formatMessage({id: 'screen.homeScreen.addProducts.addProductsManually'})}
          </GreenButton>

          <GreenButton
            disabled={loading}
            onClick={() => {
              fileRef.click();
            }} className={classes.button}>
            {intl.formatMessage({id: 'screen.homeScreen.addProducts.bulkImport'})}
          </GreenButton>
          {!!fileLoad && (
            <MessageBox message={intl.formatMessage({id: 'screen.homeScreen.addProducts.productsSuccessfullyLoaded'})}
            className={classes.fileLoadedWrapper}
            onClose={() => setFileLoad(false)}
            />
          )}
          {!!fileLoadError && (
            <div className={classes.errorWrapper}>
              <ErrorBox error={fileLoadError} onClose={() => {setFileLoadError('')}}/>
            </div>
          )}
        </div>
      </>
    )
  }

  const fileUploaded = (event) => {
    setLoading(true);

    let formData = new FormData();

    const loadedFile = event.target.files[0];

    setFileLoad(false);
    setFileLoadError('');

    formData.append('csv', loadedFile, loadedFile.name);
    apiSendProductsListCSV(formData, () => {
      setFileLoad(true);
      setLoading(false);
    }, (error) => {
      setFileLoad(false);
      setFileLoadError(error.response && error.response.data && error.response.data.message);
      setLoading(false);
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.mainContent}>

        {!isFinished
          ? getContentStep()
          : getFinishStep()
        }

        <input
          onChange={fileUploaded}
          ref={ref => fileRef = ref}
          accept="text/csv"
          style={{display: 'none'}}
          id="file"
          type="file"
        />
      </div>
      <div className={classes.iconContentWrapper}>
        <div className={classes.iconWrapper}>
          <Icon icon={LocalOfferIcon} className={classes.icon}/>
          {isFinished && <Icon icon={CheckIcon} className={classes.iconCheck}/>}
        </div>
      </div>
    </div>
  );
}

export default ProductsContent;