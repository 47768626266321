import React from 'react';
import PropTypes from 'prop-types';
import {
  BlackImageUploader,
  OutlineButton,
  PrimaryChip,
  PrimarySelect,
  PrimaryTextField,
  RichTextEditableBox,
} from '../../../components';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import styles from '../styles';
import Variants from '../components/Variants';
import Typography from '@material-ui/core/Typography';
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton';
import AddIcon from '@material-ui/icons/Add';

ProductView.propTypes = {
  intl: PropTypes.object,
  classes: PropTypes.object,
  product: PropTypes.object,
  file: PropTypes.any,
  setFile: PropTypes.func,
  setName: PropTypes.func,
  name: PropTypes.string,
  nameError: PropTypes.string,
  type: PropTypes.string,
  setType: PropTypes.func,
  types: PropTypes.array,
  subType: PropTypes.string,
  setSubType: PropTypes.func,
  subTypes: PropTypes.array,
  setDescription: PropTypes.func,
  description: PropTypes.string,
  setTranslation: PropTypes.func,
  translation: PropTypes.string,
  search: PropTypes.array,
  setSearch: PropTypes.func,
  variants: PropTypes.array,
  onUpdateVariant: PropTypes.func,
  onCreateVariant: PropTypes.func,
  setDescriptionWholesale: PropTypes.func,
  descriptionWholesale: PropTypes.string,
  separateDescriptions: PropTypes.bool,
  setSeparateDescriptions: PropTypes.func,
  supplier: PropTypes.string,
  setSupplier: PropTypes.func,
  suppliers: PropTypes.array,
  hasUnsavedChanges: PropTypes.bool,
  loading: PropTypes.bool,
};

function ProductView(props) {
  const {
    intl,
    classes,
    product,
    file,
    setFile,
    setName,
    name,
    nameError,
    type,
    typeError,
    setType,
    types,
    subType,
    subTypeError,
    setSubType,
    subTypes,
    setDescription,
    description,
    setTranslation,
    translation,
    search,
    setSearch,
    variants,
    onUpdateVariant,
    onCreateVariant,
    preValidate,
    setDescriptionWholesale,
    descriptionWholesale,
    separateDescriptions,
    setSeparateDescriptions,
    supplier,
    setSupplier,

    suppliers,
    hasUnsavedChanges,
    loading,
  } = props;

  return (
    <>
      <div className={classes.productHeaderWrapper}>
        <div className={classes.productHeaderText}>
          <Typography className={classes.productHeaderH1} component="h1" variant="subtitle1">
            {intl.formatMessage({ id: 'screen.product.productInformation' })}
          </Typography>
        </div>
        <div className={classes.productHeaderSaveButton}>
          {
            <PrimaryButton
              className={classes.button}
              onClick={preValidate}
              disabled={!hasUnsavedChanges}
              isLoading={loading}>
              {intl.formatMessage({ id: 'screen.product.saveProduct' })}
            </PrimaryButton>
          }
        </div>
      </div>

      <div className={classes.productMainTwoSidesWrapper}>
        <div className={classes.imageBoxWrapper}>
          <BlackImageUploader object={product} base64={file} onUploaded={(uploadedFile) => setFile(uploadedFile)} />
        </div>
        <div className={classes.fieldSetWrapper}>
          <PrimaryTextField
            className={classes.input}
            error={!!nameError}
            onChange={(event) => setName(event.target.value)}
            value={name}
            id="name-input"
            label={intl.formatMessage({ id: 'product.name' })}
            type="text"
            InputLabelProps={{ shrink: true }}
            helperText={nameError && intl.formatMessage({ id: nameError })}
            fullWidth
          />

          <PrimarySelect
            className={classes.input}
            label={intl.formatMessage({ id: 'product.type' })}
            value={type}
            error={!!typeError}
            helperText={typeError && intl.formatMessage({ id: typeError })}
            onChange={(e) => setType(e.target.value)}>
            {types.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </PrimarySelect>

          <PrimarySelect
            className={classes.input}
            label={intl.formatMessage({ id: 'product.subtype' })}
            value={subType}
            error={!!subTypeError}
            helperText={subTypeError && intl.formatMessage({ id: subTypeError })}
            onChange={(e) => setSubType(e.target.value)}>
            {subTypes.map((subType) => (
              <MenuItem key={subType} value={subType}>
                {subType}
              </MenuItem>
            ))}
          </PrimarySelect>
        </div>
      </div>

      <RichTextEditableBox
        titleClass={classes.descriptionTitleClass}
        title={intl.formatMessage({
          id: separateDescriptions ? 'product.descriptionRetail' : 'product.description',
        })}
        editingTitle={intl.formatMessage({
          id: separateDescriptions ? 'product.descriptionRetail' : 'product.description',
        })}
        withEditIcon
        text={description}
        onSave={(html) => setDescription(html)}
        notEditingWrapperStyle={{ width: '100%' }}
        placeholder={intl.formatMessage({ id: 'global.descriptionNotAdded' })}
      />

      <div className={classes.wholesaleDescriptionWrapper}>
        {!separateDescriptions && (
          <OutlineButton icon={AddIcon} className={classes.button} onClick={() => setSeparateDescriptions(true)}>
            {intl.formatMessage({ id: 'screen.product.addWholesaleDescription' })}
          </OutlineButton>
        )}

        {!!separateDescriptions && (
          <RichTextEditableBox
            titleClass={classes.descriptionTitleClass}
            title={intl.formatMessage({ id: 'product.descriptionWholesale' })}
            editingTitle={intl.formatMessage({
              id: 'product.descriptionWholesale',
            })}
            withEditIcon
            text={descriptionWholesale}
            onSave={(html) => setDescriptionWholesale(html)}
            notEditingWrapperStyle={{ width: '100%' }}
            placeholder={intl.formatMessage({ id: 'global.descriptionNotAdded' })}
          />
        )}
      </div>

      <div className={classes.productMainTwoSidesWrapper}>
        <div className={classes.productMainLeft}>
          <PrimaryTextField
            className={classes.input}
            onChange={(event) => setTranslation(event.target.value)}
            value={translation}
            id="translation-input"
            label={intl.formatMessage({ id: 'product.translation' })}
            type="text"
            InputLabelProps={{ shrink: true }}
            fullWidth
          />

          <PrimarySelect
            className={classes.input}
            formControlClassName={classes.mt20}
            label={intl.formatMessage({ id: 'product.supplier' })}
            value={supplier.id}
            error={!!subTypeError}
            helperText={subTypeError && intl.formatMessage({ id: subTypeError })}
            onChange={(e) => {
              const currentSupplierObj = suppliers.find((item) => item.id === e.target.value);

              if (!!currentSupplierObj) setSupplier(currentSupplierObj);
            }}>
            <MenuItem key={'no_supplier'} value={-1} disabled>
              {intl.formatMessage({ id: 'suppliers.properties.tab.selectSupplier' })}
            </MenuItem>
            {suppliers.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </PrimarySelect>
        </div>
        <div className={classes.productsMainRight}>
          <PrimaryChip
            className={classes.input}
            fullWidth
            value={search}
            onAdd={(chip) => {
              let chipsArr = [...search];
              chipsArr.push(chip);
              setSearch(chipsArr);
            }}
            onDelete={(chip) => {
              let chipsArr = [...search];
              const index = chipsArr.indexOf(chip);
              chipsArr.splice(index, 1);
              setSearch(chipsArr);
            }}
            label={intl.formatMessage({ id: 'product.search' })}
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </div>

      <Variants
        variants={variants}
        onUpdateVariant={(variant, index) => onUpdateVariant(variant, index)}
        onCreateVariant={onCreateVariant}
      />
    </>
  );
}

export default withStyles(styles)(ProductView);
