import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import DXFilterConfig from '../DXFilterConfig';
import constants from '../../constants';
import { getStore } from '../../../../../store/configure';
import SelectWithSearch from '../../../../SelectWithSearch';

const propTypes = {
  equality: PropTypes.string,
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  selected: [],
};

class DXSuppliersFilter extends React.Component {
  render() {
    const { selected = [], onChange, intl, equality } = this.props;
    let config = DXSuppliersFilter.getConfig(intl, equality);

    return (
      <SelectWithSearch
        value={selected}
        label={config.title}
        onChange={(newValue) => {
          onChange(newValue);
        }}
        options={config.values}
      />
    );
  }
}

DXSuppliersFilter.propTypes = propTypes;
DXSuppliersFilter.defaultProps = defaultProps;
DXSuppliersFilter.getLabel = (intl, selectedValues) => {
  let label = '';
  let parts = [];
  if (Array.isArray(selectedValues) && selectedValues.length) {
    selectedValues.forEach((value) => {
      parts.push(value.label);
    });

    label = parts.length > 3 ? parts.splice(0, 3).join(', ') + '...' : parts.join(', ');
  }
  return label;
};
DXSuppliersFilter.applyToQueryParams = (queryParams, selectedValues) => {
  queryParams['supplierIds'] = selectedValues.map((item) => item.key);
  return queryParams;
};

DXSuppliersFilter.getConfig = (intl, equality) => {
  const store = getStore();
  let suppliers = store.getState()?.producer?.object?.suppliers || [];

  if (!!suppliers?.length) {
    suppliers = suppliers.sort((prev, next) => (prev.name < next.name ? 1 : -1));
  }

  let config = new DXFilterConfig({
    name: 'suppliers',
    selected: [],
    property: 'suppliers',
    title: intl.formatMessage({ id: 'filter.supplier' }),
    disabled: false,
    equalityStatus: false,
    multi: false,
  });

  if (equality === '*' || equality === constants.FILTER_EQUALITY_EQUALS) {
    !!suppliers && suppliers.sort().map((item) => config.addFilterValue(item.id, item.name));
  }

  return config;
};

export default injectIntl(DXSuppliersFilter);
