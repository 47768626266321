import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../hocs';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { PrimaryTextField, PrimaryCheckbox } from '../../components';
import styles from './styles';
import PrimaryButton from '../PrimaryButton/PrimaryButton';

const propTypes = {
  terms: PropTypes.func.isRequired,
  onValidated: PropTypes.func.isRequired,
  bookToQuickBooks: PropTypes.object.isRequired,
  producerId: PropTypes.string.isRequired,
};

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    const { terms } = this.props;

    this.state = {
      terms: terms.paymentTerms,
      allowCredit: terms.allowCredit,
      freeDelivery: terms.freeDelivery,
      allowShipping: terms.allowShipping,
      bookToQuickBooks: props.bookToQuickBooks === null ? true : props.bookToQuickBooks[props.producerId],
      termsError: '',
    };
  }

  getTextField(fieldName) {
    const { classes, intl } = this.props;

    return (
      <PrimaryTextField
        className={classes.input}
        InputLabelProps={{
          classes: {
            root: classes.label,
          },
        }}
        autoComplete="no"
        error={!!this.state[`${fieldName}Error`]}
        onChange={(event) => {
          let value = event.target.value;
          this.setState((state) => {
            state[fieldName] = value;
            return state;
          });
        }}
        value={this.state[fieldName]}
        id={`${fieldName}-input`}
        label={intl.formatMessage({ id: `global.${fieldName}` })}
        type="text"
        margin="normal"
        helperText={this.state[`${fieldName}Error`] && intl.formatMessage({ id: this.state[`${fieldName}Error`] })}
        fullWidth
      />
    );
  }

  getCheckbox = (fieldName, needConfirm = false) => {
    const { intl } = this.props;

    return (
      <FormControlLabel
        style={{ width: '100%' }}
        control={
          <PrimaryCheckbox
            checked={this.state[fieldName]}
            onChange={(event) => {
              let checked = event.target.checked;

              needConfirm
                ? this.onConfirm(checked, () =>
                    this.setState((state) => {
                      state[fieldName] = checked;
                      return state;
                    })
                  )
                : this.setState((state) => {
                    state[fieldName] = checked;
                    return state;
                  });
            }}
            color="default"
          />
        }
        label={intl.formatMessage({ id: `global.${fieldName}` })}
      />
    );
  };

  onConfirm = (isAllow, onDone) => {
    const { intl, onConfirm } = this.props;

    onConfirm
      ? onConfirm(
          onDone,
          'global.confirm',
          intl.formatMessage({ id: 'messages.confirm.setQuickBooksTitle' }),
          intl.formatMessage(
            { id: 'messages.confirm.setQuickBooksMessage' },
            {
              type: isAllow
                ? intl.formatMessage({ id: 'global.allow' }).toLowerCase()
                : intl.formatMessage({ id: 'global.disallow' }).toLowerCase(),
            }
          )
        )
      : onDone();
  };

  validate = () => {
    return this.props.onValidated({
      paymentTerms: this.state.terms,
      allowCredit: this.state.allowCredit,
      freeDelivery: this.state.freeDelivery,
      allowShipping: this.state.allowShipping,
      bookToQuickBooks: this.state.bookToQuickBooks,
    });
  };

  render() {
    const { classes, intl } = this.props;

    return (
      <div className={classes.wrapper}>
        {this.getTextField('terms')}
        {this.getCheckbox('allowCredit')}
        {this.getCheckbox('freeDelivery')}
        {this.getCheckbox('allowShipping')}
        {this.getCheckbox('bookToQuickBooks', true)}

        <div className={classes.controls}>
          <PrimaryButton onClick={this.validate}>{intl.formatMessage({ id: 'global.saveChanges' })}</PrimaryButton>
        </div>
      </div>
    );
  }
}

UserForm.propTypes = propTypes;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(UserForm)));
