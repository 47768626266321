import React from 'react';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import { getCustomerName } from '../../helpers/customer';

const styles = () => ({
  chip: {
    margin: '5px 5px 5px 0',
  },
});

const CustomerNameChip = (props) => {
  const { customer, isNew, onDelete, onClick, classes } = props;

  return (
    <Chip
      classes={{ root: classes.chip }}
      key={customer.id}
      label={getCustomerName(customer)}
      onDelete={isNew ? () => onDelete(customer) : null}
      className={classes.chip}
      onClick={onClick}
    />
  );
};

export default withStyles(styles)(CustomerNameChip);
