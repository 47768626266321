import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from '../../hocs';
import { withStyles } from '@material-ui/core/styles';
import { AdminLayout, Spinner, TwoColumnContent } from '../../components';
import { SidebarMenu, HeaderMenu } from '../Partials';
import { ProductsSearch, OrderCart } from '../Partials';
import {
  AddToCartDialog,
  // AddCustomItemDialog,
  PatchItemDialog,
} from '../../dialogs';
import {
  setSuppliersOrder,
  refreshSuppliersOrder,
  unsetSuppliersOrder,
  orderSuppliersAttachVariant,
  orderSuppliersAttachCustomItem,
  orderSuppliersChangeLineItemNote,
  orderSuppliersChangeLineItemQuantity,
  orderSuppliersAddNote,
  orderSuppliersPatchLineItem,
  orderSuppliersDetachLineItem,
  orderSuppliersAttachShipping,
  orderSuppliersRemoveShippingDeliveryCompany,
  orderSuppliersFinishEditing,
  orderSuppliersDeleteDiscount,
  orderSuppliersAttachPaymentMethod,
  orderSuppliersAttachCustomer,
  orderSuppliersDetachCustomer,
  orderSuppliersChangeCustomerAddress,
} from '../../store/actions';
import styles from './styles';
import { BILLING_ADDRESS_TYPE, DELIVERY_ADDRESS_TYPE, CUSTOMER_TYPE, FINANCIAL_STATUSES } from '../../helpers';
import { Order } from '../../entities';
// import BaseDialog from '../../dialogs/BaseDialog';
// import { OrderUpdatedEmailsForm } from '../../forms';
import { apiSupplierProductsSearch } from '../../api';

const propTypes = {
  classes: PropTypes.object.isRequired,
};

class SuppliersEditOrderScene extends React.Component {
  constructor(props) {
    super(props);

    const firstSupplier = !!this?.props?.producer?.suppliers[0] && this.props.producer.suppliers[0].id;

    this.state = {
      order: null,
      addingProductToCart: null,
      addingCustomItemToCart: false,
      editingLineItem: null,
      loading: true,
      customerType: CUSTOMER_TYPE.WHOLESALE,
      selectedSupplier: firstSupplier || null,
    };
  }

  componentDidMount() {
    this.retrieveOrder();
  }

  componentWillUnmount() {
    this.props.unsetOrder();
  }

  retrieveOrder = () => {
    const { match } = this.props;
    this.props.setOrder(
      match.params.id,
      (order) =>
        this.setState({
          order: order,
          loading: false,
          customerType: order.type || CUSTOMER_TYPE.WHOLESALE,
        }),
      undefined
    );
  };

  render() {
    const {
      producer,
      order,
      history,
      orderCustomer,
      orderLoading,
      // Cart
      // refreshOrder,
      orderAttachVariant,
      // orderAttachCustomItem,
      orderDetachLineItem,
      orderChangeLineItemNote,
      orderChangeLineItemQuantity,
      orderAddNote,
      orderAttachShipping,
      orderAttachPaymentMethod,
      // orderFinishEditing,
      orderAttachCustomer,
      orderDetachCustomer,
      orderChangeCustomerAddress,
      orderPatchLineItem,
      // Discount
      // orderDeleteDiscount,
      orderRemoveShippingDeliveryCompany,
      // intl,
      // openUpdatesModal,
      // openDiscountModal,
    } = this.props;

    const { customerType, selectedSupplier } = this.state;

    return (
      <AdminLayout sidebarComponent={SidebarMenu} headerMenuComponent={HeaderMenu}>
        {this.state.loading && <Spinner size={80} />}

        <TwoColumnContent
          leftContent={
            <ProductsSearch
              updaterCounter={selectedSupplier}
              retriever={(...other) => apiSupplierProductsSearch(selectedSupplier, ...other)}
              producer={producer}
              // onAddCustomItem={() => new Order(order).isEditable() && this.setState({ addingCustomItemToCart: true })}
              onProductClick={(product) =>
                new Order(order).isEditable() && this.setState({ addingProductToCart: product })
              }
            />
          }
          rightContent={
            this.state.loading ? null : (
              <OrderCart
                producer={producer}
                customer={orderCustomer}
                completeButtonTitleIntlKey={'order.finishEditing'}
                loading={orderLoading}
                order={order}
                customerReadOnly={order.financialStatus !== FINANCIAL_STATUSES.UNPAID}
                // Customer
                customerType={customerType}
                onChangeCustomerType={(customerType) => this.setState({ customerType })}
                onAttachCustomer={(customer) => orderAttachCustomer(order, customer)}
                onDetachCustomer={() => orderDetachCustomer(order)}
                // Address
                onChangeCustomerShippingAddress={(addressId) =>
                  orderChangeCustomerAddress(order, orderCustomer, addressId, DELIVERY_ADDRESS_TYPE)
                }
                onChangeCustomerBillingAddress={(addressId) =>
                  orderChangeCustomerAddress(order, orderCustomer, addressId, BILLING_ADDRESS_TYPE)
                }
                // Line item
                onDetachLineItem={(lineItem) => orderDetachLineItem(order, lineItem)}
                onChangeLineItemNote={(lineItem, note) => orderChangeLineItemNote(order, lineItem, note)}
                onChangeLineItemQuantity={(lineItem, quantity, onSuccess, onError) =>
                  orderChangeLineItemQuantity(order, lineItem, quantity, onSuccess, onError)
                }
                onPatchLineItem={(order, lineItem) => this.setState({ editingLineItem: lineItem })}
                // Shipping
                onChangeShipping={(method, data, deliveryCompany, location) =>
                  orderAttachShipping(order, method, data, deliveryCompany, location)
                }
                // Order
                onChangeNote={(note) => orderAddNote(order, note)}
                onChangePaymentMethod={(method) => orderAttachPaymentMethod(order, method)}
                onComplete={() => {
                  history.push('/suppliers/order/' + order.id);

                  // history.push(`${this.props.match.url}/updates`);
                }}
                // Discount
                // onCreateDiscount={(order) => refreshOrder(order)}
                // onUpdateDiscount={(order) => refreshOrder(order)}
                // onDeleteDiscount={(order) => orderDeleteDiscount(order)}
                // openDiscountModal={openDiscountModal}
                withoutDiscount
                onRemoveShippingDeliveryCompany={orderRemoveShippingDeliveryCompany}
                mayShowCompleteButton={
                  new Order(order).isEditable() &&
                  selectedSupplier !== -1 &&
                  order.payment &&
                  order.shipping &&
                  !!order.lineItems.length &&
                  (!!order.shipping.deliveryCompany || !!order.shipping.location)
                }
                useSupplierSelector
                onChangeSupplier={(supplierId) => this.setState({ selectedSupplier: supplierId })}
                selectedSupplier={selectedSupplier}
              />
            )
          }
        />

        {this.state.editingLineItem && (
          <PatchItemDialog
            onClose={() => this.setState({ editingLineItem: null })}
            customerType={customerType}
            lineItem={this.state.editingLineItem}
            loading={orderLoading}
            onDone={(lineItem, data, onError) =>
              orderPatchLineItem(order, lineItem, data, () => this.setState({ editingLineItem: null }), onError)
            }
          />
        )}

        {this.state.addingProductToCart && (
          <AddToCartDialog
            onClose={() => this.setState({ addingProductToCart: null })}
            customerType={customerType}
            loading={orderLoading}
            onDone={(product, variant, data, onError) =>
              orderAttachVariant(
                order,
                product,
                variant,
                data,
                () => this.setState({ addingProductToCart: null }),
                onError
              )
            }
            product={this.state.addingProductToCart}
          />
        )}

        {/*{this.state.addingCustomItemToCart && (*/}
        {/*  <AddCustomItemDialog*/}
        {/*    producer={producer}*/}
        {/*    loading={orderLoading}*/}
        {/*    onClose={() => this.setState({ addingCustomItemToCart: false })}*/}
        {/*    onDone={(data) =>*/}
        {/*      orderAttachCustomItem(order, data, () => this.setState({ addingCustomItemToCart: false }))*/}
        {/*    }*/}
        {/*  />*/}
        {/*)}*/}

        {/*{openUpdatesModal && (*/}
        {/*  <BaseDialog*/}
        {/*    scroll={'paper'}*/}
        {/*    maxWidth="md"*/}
        {/*    open*/}
        {/*    onClose={() => history.goBack()}*/}
        {/*    title={intl.formatMessage({ id: 'order.sendUpdates' })}>*/}
        {/*    <OrderUpdatedEmailsForm*/}
        {/*      {...this.props}*/}
        {/*      order={order}*/}
        {/*      onDone={(emails) => {*/}
        {/*        orderFinishEditing(order, emails, () => {*/}
        {/*          history.push('/suppliers/order/' + order.id);*/}
        {/*        });*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </BaseDialog>*/}
        {/*)}*/}
      </AdminLayout>
    );
  }
}

SuppliersEditOrderScene.propTypes = propTypes;

const mapStateToProps = (state) => {
  return {
    order: state.order.object,
    orderCustomer: state.order.customer,
    orderLoading: state.order.loading,
    producer: state.producer.object,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOrder: (id, onSuccess, onError) => dispatch(setSuppliersOrder(id, onSuccess, onError)),
    unsetOrder: (order) => dispatch(unsetSuppliersOrder(order)),

    // Discount
    orderDeleteDiscount: (order, onSuccess, onError) =>
      dispatch(orderSuppliersDeleteDiscount(order, onSuccess, onError)),

    refreshOrder: (order, onSuccess, onError) => dispatch(refreshSuppliersOrder(order, onSuccess, onError)),

    // Customer
    orderAttachCustomer: (order, customer, onSuccess, onError) =>
      dispatch(orderSuppliersAttachCustomer(order, customer, onSuccess, onError)),
    orderDetachCustomer: (order, onSuccess, onError) =>
      dispatch(orderSuppliersDetachCustomer(order, onSuccess, onError)),
    orderChangeCustomerAddress: (order, customer, addressId, addressType, onSuccess, onError) =>
      dispatch(orderSuppliersChangeCustomerAddress(order, customer, addressId, addressType, onSuccess, onError)),

    // Shipping
    orderAttachShipping: (order, method, data, deliveryCompany, location, onSuccess, onError) =>
      dispatch(orderSuppliersAttachShipping(order, method, data, deliveryCompany, location, onSuccess, onError)),
    orderRemoveShippingDeliveryCompany: (draft, onSuccess, onError) =>
      dispatch(orderSuppliersRemoveShippingDeliveryCompany(draft, onSuccess, onError)),

    // Cart
    orderAttachVariant: (order, product, variant, data, onSuccess, onError) =>
      dispatch(orderSuppliersAttachVariant(order, product, variant, data, onSuccess, onError)),
    orderAttachCustomItem: (order, data, onSuccess, onError) =>
      dispatch(orderSuppliersAttachCustomItem(order, data, onSuccess, onError)),
    orderPatchLineItem: (order, lineItem, data, onSuccess, onError) =>
      dispatch(orderSuppliersPatchLineItem(order, lineItem, data, onSuccess, onError)),
    orderDetachLineItem: (order, lineItem, onSuccess, onError) =>
      dispatch(orderSuppliersDetachLineItem(order, lineItem, onSuccess, onError)),
    orderChangeLineItemNote: (order, lineItem, note, onSuccess, onError) =>
      dispatch(orderSuppliersChangeLineItemNote(order, lineItem, note, onSuccess, onError)),
    orderChangeLineItemQuantity: (order, lineItem, quantity, onSuccess, onError) =>
      dispatch(orderSuppliersChangeLineItemQuantity(order, lineItem, quantity, onSuccess, onError)),
    orderAddNote: (order, note, onSuccess, onError) => dispatch(orderSuppliersAddNote(order, note, onSuccess, onError)),
    orderAttachPaymentMethod: (order, method, data, onSuccess, onError) =>
      dispatch(orderSuppliersAttachPaymentMethod(order, method, data, onSuccess, onError)),
    orderFinishEditing: (order, emails, onSuccess, onError) =>
      dispatch(orderSuppliersFinishEditing(order, emails, onSuccess, onError)),
  };
};

export default withStyles(styles, { withTheme: true })(
  injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(SuppliersEditOrderScene)))
);
