import {COLORS} from "../../helpers";

export default ({
  wrapper: {
  },
  name: {
    fontSize: 18,
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.text,
    marginBottom: 40
  },
  variants: {
    marginBottom: 50,
  },
  input: {
    marginBottom: 25,
    width: '100%'
  },
  inputText: {
    textAlign: 'right'
  },
  button: {
    minWidth: '40%',
    textTransform: 'uppercase',
  },
  mr20: {
    marginRight: 10
  },
  controls: {
    margin: '27px 0 0 0',
    textAlign: 'center'
  },
  control: {
    padding: '0 105px'
  },
  image: {
    display: 'inline-block',
    width: 300,
    maxHeight: 400
  },
  imageWrapper: {
    textAlign: 'center'
  }
});
