import React from 'react';
import { injectIntl } from 'react-intl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../../../../hocs';
import { PrimaryCheckbox, ErrorBox, PrimarySearchableSelect } from '../../../../../components';
import { validateField } from '../../../../../helpers';
import { apiGetProducerApprovedLocations } from '../../../../../api';
import validationRules from './validation';

const styles = {
  selectFormControlClassName: {
    display: 'block',
    marginTop: 20,
    marginBottom: 20,
  },
  select: {
    width: '100%',
  },
  shippingType: {
    color: '#707070',
    padding: 0,
    fontSize: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  label: {
    fontSize: 14,
  },
};

class LocationForm extends React.Component {
  constructor(props) {
    super(props);
    this.props.onRef(this);
    const isEdit = !!props.editLocation;

    this.state = {
      loading: true,
      id: isEdit ? props.editLocation.id : '',
      location: true,
      locations: [],
      retailPickup: isEdit ? props.editLocation.retail.pickup : false,
      retailDelivery: isEdit ? props.editLocation.retail.delivery : false,
      wholesalePickup: isEdit ? props.editLocation.wholesale.pickup : false,
      wholesaleDelivery: isEdit ? props.editLocation.wholesale.delivery : false,
      locationError: '',
      error: '',
      isAdd: !props.editLocation,
    };

    this.refSelect = null;
  }

  componentDidMount() {
    apiGetProducerApprovedLocations((locations) => {
      this.setState({
        locations,
        loading: false,
      });
    });
  }

  validate = () => {
    const { intl } = this.props;
    const { locations } = this.state;
    const locationError = validateField(validationRules, 'location', this.state.id);

    let error = '';

    // At least one option must be selected
    if (
      !this.state.retailPickup &&
      !this.state.retailDelivery &&
      !this.state.wholesalePickup &&
      !this.state.wholesaleDelivery
    ) {
      error = intl.formatMessage({ id: 'validation.specifyAtLeastOneShippingType' });
    }

    let validated = false;
    if (!locationError && !error) {
      this.setState({
        locationError: '',
        error: '',
      });
      validated = true;
    } else {
      this.setState({
        locationError: locationError,
        error: error,
      });
    }

    let filtered = locations.find((item) => item.id === this.state.id);
    let data = {
      id: filtered && filtered.id,
      name: filtered && filtered.name,
    };

    data['retail'] = {
      pickup: this.state.retailPickup,
      delivery: this.state.retailDelivery,
    };

    data['wholesale'] = {
      pickup: this.state.wholesalePickup,
      delivery: this.state.wholesaleDelivery,
    };

    return {
      validated: validated,
      data: data,
    };
  };

  getSelectedValue = () => {
    return this.state.locations.find((location) => location.id === this.state.id) || { name: '' };
  };

  render() {
    const { classes, intl } = this.props;
    const { locations } = this.state;

    return (
      <div className={classes.wrapper}>
        {this.state.error && <ErrorBox error={this.state.error} />}

        {!this.props.id && (
          <>
            <PrimarySearchableSelect
              containerWrapperClass={classes.selectFormControlClassName}
              options={locations}
              labelExtractor={(option) => option.name || ''}
              onChange={(event, newValue) => {
                this.setState({ id: newValue ? newValue.id : '' });
              }}
              value={!this.state.loading && this.getSelectedValue()}
              {...(this.state.isAdd && !this.state.loading
                ? { inputValue: this.getSelectedValue().name, value: this.getSelectedValue() }
                : {})}
              helperText={this.state.locationError && intl.formatMessage({ id: this.state.locationError })}
              error={!!this.state.locationError}
              disabled={!this.state.isAdd}
              classes={{ root: classes.select }}
              InputProps={{ shrink: true }}
              InputLabelProps={{ shrink: true }}
              className={classes.select}
              label={intl.formatMessage({ id: 'global.location' })}
            />
          </>
        )}

        <div className={classes.shippingType}>{intl.formatMessage({ id: 'filter.types.retail' })}</div>

        <FormControlLabel
          control={
            <PrimaryCheckbox
              checked={this.state.retailPickup}
              onChange={(event) => this.setState({ retailPickup: event.target.checked })}
              color="default"
            />
          }
          label={intl.formatMessage({ id: 'global.pickup' })}
        />

        <FormControlLabel
          control={
            <PrimaryCheckbox
              checked={this.state.retailDelivery}
              onChange={(event) => this.setState({ retailDelivery: event.target.checked })}
              color="default"
            />
          }
          label={intl.formatMessage({ id: 'global.delivery' })}
        />
        <div className={classes.shippingType}>{intl.formatMessage({ id: 'filter.types.wholesale' })}</div>

        <FormControlLabel
          control={
            <PrimaryCheckbox
              checked={this.state.wholesalePickup}
              onChange={(event) => this.setState({ wholesalePickup: event.target.checked })}
              color="default"
            />
          }
          label={intl.formatMessage({ id: 'global.pickup' })}
        />

        <FormControlLabel
          control={
            <PrimaryCheckbox
              checked={this.state.wholesaleDelivery}
              onChange={(event) => this.setState({ wholesaleDelivery: event.target.checked })}
              color="default"
            />
          }
          label={intl.formatMessage({ id: 'global.delivery' })}
        />
      </div>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(LocationForm)));
