import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PlusIcon from '@material-ui/icons/AddCircleOutline';
import { Customer } from '../../entities';
import { Spinner, SecondaryButton, ConfirmDialog } from '../../components';
import { StripeCreditCardForm } from '../../forms';
import CustomerAccountBox from '../../components/CustomerAccountBox';
import CustomerAddressBox from '../../components/CustomerAddressBox';
import CustomerAccountForm from '../../components/CustomerAccountForm';
import CustomerAddressForm from '../../components/CustomerAddressForm';
import CustomerPaymentMethodBox from '../../components/CustomerPaymentMethodBox';
import CustomerGeneralInfoForm from '../../components/CustomerGeneralInfoForm';
import CustomerPaymentTerms from '../../components/CustomerPaymentTerms';
import CustomerPaymentTermsForm from '../../components/CustomerPaymentTermsForm';
import {
  apiCustomerAddAccount,
  apiCustomerPatchAccount,
  apiCustomerDeleteAccount,
  apiCustomerDeleteAddress,
  apiCustomerAddAddress,
  apiCustomerPatchAddress,
  apiCustomerUpdate,
  apiCustomerPatch,
} from '../../api/customer/customer';
import {
  apiCustomerAddCreditCard,
  apiCustomerMakePaymentMethodDefault,
  apiCustomerDeletePaymentMethod,
} from '../../api';
import { REPLACE_CUSTOMER } from '../../store/reducers/customer';
import { capitalizeFirstLetter } from '../../helpers';
import BaseDialog from '../../dialogs/BaseDialog';
import ResetCustomerPassword from './Components/ResetCustomerPassword';
import ResetAccountPassword from './Components/ResetAccountPassword';
import ResendCustomerInvite from './Components/ResendCustomerInvite';
import ResendAccountInvite from './Components/ResendAccountInvite';
import { CONFIRM_DIALOG_VARIANTS } from '../../components/ConfirmDialog/ConfirmDialog';

const styles = {
  wrap: {
    padding: '16px 13px',
    '@media (min-width: 960px)': {
      maxWidth: '50%',
    },
  },
  accountsWrap: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  titleWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonWrapper: {
    marginTop: 10,
    marginBottom: 20,
  },
};

class CustomerView extends Component {
  state = {
    dialogLoading: false,
    editingAccount: {},
    editingBillingAddress: {},
    editingShippingAddress: {},
    errors: {},
    dialogOpened: false,
    dialogTitle: '',
    requestError: '',

    resendingAccountInvite: null,
    resettingAccountPassword: null,
    resendingCustomerInvite: null,
    resettingCustomerPassword: null,

    editingGeneralInfo: false,
    addingCreditCard: false,
    editingPaymentTerms: false,

    confirmState: {
      open: false,
      action: () => {},
      confirmTitle: '',
      title: '',
      message: '',
    },
  };

  closeDialog = () => {
    this.setState({
      editingAccount: {},
      editingBillingAddress: {},
      editingShippingAddress: {},
      resendingAccountInvite: null,
      resettingAccountPassword: null,
      resendingCustomerInvite: null,
      resettingCustomerPassword: null,
      dialogOpened: false,
      editingGeneralInfo: false,
      addingCreditCard: false,
      editingPaymentTerms: false,
      dialogLoading: false,
      requestError: '',

      confirmState: {
        open: false,
        action: () => {},
        confirmTitle: '',
        title: '',
        message: '',
      },
    });
  };

  handleEditPaymentTerms = () => {
    this.setState({ editingPaymentTerms: true, dialogOpened: true });
  };

  handleSubmitPaymentTerms = (data) => {
    const { customer, replaceCustomer } = this.props;

    this.setState({ dialogLoading: true });
    apiCustomerPatch(
      customer.id,
      data,
      (newCustomer) => {
        replaceCustomer(newCustomer);
        this.closeDialog();
      },
      (error) => {
        this.setState({ dialogLoading: false });
        console.warn(error);
      }
    );
  };

  handleAddCreditCard = () => {
    this.setState({ addingCreditCard: true, dialogOpened: true });
  };

  handleSubmitCreditCard = (stripePayload) => {
    const { customer, replaceCustomer } = this.props;

    this.setState({ dialogLoading: true });
    apiCustomerAddCreditCard(
      stripePayload,
      customer,
      {},
      (newCustomer) => {
        replaceCustomer(newCustomer);
        this.closeDialog();
      },
      (error) => {
        this.setState({ dialogLoading: false });
        console.warn(error);
      }
    );
  };

  handleDeletePaymentMethod = (paymentMethod) => {
    const { customer, replaceCustomer } = this.props;

    apiCustomerDeletePaymentMethod(
      customer,
      paymentMethod.id,
      (newCustomer) => {
        replaceCustomer(newCustomer);
      },
      (error) => console.warn(error)
    );
  };

  handleMakePaymentMethodDefault = (paymenMethod) => {
    const { customer, replaceCustomer } = this.props;

    apiCustomerMakePaymentMethodDefault(
      customer,
      paymenMethod.id,
      (newCustomer) => {
        replaceCustomer(newCustomer);
      },
      (error) => console.warn(error)
    );
  };

  resetCustomerPassword = () => {
    const { customer } = this.props;
    this.setState({
      resettingCustomerPassword: customer,
      dialogOpened: true,
    });
  };

  resendCustomerInvite = () => {
    const { customer } = this.props;
    this.setState({
      resendingCustomerInvite: customer,
      dialogOpened: true,
    });
  };

  resetAccountPassword = (account) => {
    this.setState({
      resettingAccountPassword: account,
      dialogOpened: true,
    });
  };

  resendAccountInvite = (account) => {
    this.setState({
      resendingAccountInvite: account,
      dialogOpened: true,
    });
  };

  handleEditAccount = (editingAccount) => {
    this.setState({ editingAccount, dialogOpened: true });
  };

  handleEditBillingAddress = (editingBillingAddress) => {
    this.setState({ editingBillingAddress, dialogOpened: true });
  };

  handleEditAddress = (addressType, address) => {
    this.setState({ [`editing${capitalizeFirstLetter(addressType)}Address`]: address, dialogOpened: true });
  };

  handleEditGeneralInfo = () => {
    this.setState({ editingGeneralInfo: true, dialogOpened: true });
  };

  handleSubmitGeneralInfo = (fields, onError) => {
    const { replaceCustomer, customer } = this.props;

    apiCustomerUpdate(
      customer.id,
      fields,
      (response) => {
        replaceCustomer(response);
        this.setState({ editingGeneralInfo: false, dialogOpened: false });
      },
      (error) => {
        onError && onError(error.response);
      }
    );
  };

  handleDeleteAccount = (account) => {
    const { customer, replaceCustomer } = this.props;
    apiCustomerDeleteAccount(
      customer.id,
      account,
      (result) => replaceCustomer(result),
      (error) => console.warn(error)
    );
  };

  handleSubmitAddress = (addressType, address) => {
    const { customer, replaceCustomer } = this.props;

    address.id === 'new'
      ? apiCustomerAddAddress(
          customer.id,
          addressType,
          address,
          (result) => {
            replaceCustomer(result);
            this.setState({ [`editing${capitalizeFirstLetter(addressType)}Address`]: {}, dialogOpened: false });
          },
          (error) => console.warn(error)
        )
      : apiCustomerPatchAddress(
          customer.id,
          address.id,
          address,
          (result) => {
            replaceCustomer(result);
            this.setState({ [`editing${capitalizeFirstLetter(addressType)}Address`]: {}, dialogOpened: false });
          },
          (error) => console.warn(error)
        );
  };

  handleCancelAddress = (addressType) => {
    this.setState({ [`editing${capitalizeFirstLetter(addressType)}Address`]: {}, dialogOpened: false });
  };

  handleDeleteAddress = (address) => {
    const { customer, replaceCustomer } = this.props;

    apiCustomerDeleteAddress(
      customer.id,
      address.id,
      (result) => replaceCustomer(result),
      (error) => console.warn(error)
    );
  };

  handleSubmitAccount = (account) => {
    const { customer, replaceCustomer } = this.props;
    account.username = account.email;

    account.id === 'new'
      ? apiCustomerAddAccount(
          customer.id,
          account,
          (result) => {
            replaceCustomer(result);
            this.setState({ editingAccount: {}, dialogOpened: false, requestError: '' });
          },
          (error) => this.setState({ requestError: error })
        )
      : apiCustomerPatchAccount(
          customer.id,
          account.id,
          account,
          (result) => {
            replaceCustomer(result);
            this.setState({ editingAccount: {}, dialogOpened: false, requestError: '' });
          },
          (error) => this.setState({ requestError: error })
        );
  };

  handleCancelAccount = () => {
    this.setState({ editingAccount: {}, dialogOpened: false });
  };

  handleAutoChargeChange = (event) => {
    const { customer, replaceCustomer } = this.props;

    apiCustomerUpdate(
      customer.id,
      {
        autoCharge: event.target.value,
      },
      (response) => {
        replaceCustomer(response);
      },
      (error) => {
        console.warn(error);
      }
    );
  };

  renderDialogContent = () => {
    const { customer, producer } = this.props;
    const {
      editingAccount,
      editingBillingAddress,
      editingShippingAddress,
      editingGeneralInfo,
      addingCreditCard,
      editingPaymentTerms,
      resettingCustomerPassword,
      resendingCustomerInvite,
      resendingAccountInvite,
      resettingAccountPassword,
    } = this.state;

    if (resettingCustomerPassword) {
      return <ResetCustomerPassword customer={customer} onClose={this.closeDialog} />;
    }

    if (resettingAccountPassword) {
      return <ResetAccountPassword customer={customer} account={resettingAccountPassword} onClose={this.closeDialog} />;
    }

    if (resendingCustomerInvite) {
      return <ResendCustomerInvite customer={customer} onClose={this.closeDialog} />;
    }

    if (resendingAccountInvite) {
      return <ResendAccountInvite customer={customer} account={resendingAccountInvite} onClose={this.closeDialog} />;
    }

    if (editingPaymentTerms) {
      const terms = customer.getTerms();
      return (
        <CustomerPaymentTermsForm
          onValidated={this.handleSubmitPaymentTerms}
          terms={terms}
          producerId={producer.id}
          bookToQuickBooks={customer.bookToQuickBooks}
          onConfirm={(action, confirmTitle, title, message) =>
            this.setState({
              confirmState: {
                open: true,
                action: () => {
                  action();
                  this.setState({ confirmState: { open: false } });
                },
                confirmTitle,
                title,
                message,
              },
            })
          }
        />
      );
    }

    if (addingCreditCard) {
      return <StripeCreditCardForm onValidated={this.handleSubmitCreditCard} />;
    }

    if (editingGeneralInfo) {
      return <CustomerGeneralInfoForm customer={customer} onSubmit={this.handleSubmitGeneralInfo} />;
    }

    if (editingAccount.id) {
      return (
        <CustomerAccountForm
          account={editingAccount}
          requestError={this.state.requestError}
          onSubmit={this.handleSubmitAccount}
        />
      );
    }

    if (editingBillingAddress.id) {
      return (
        <CustomerAddressForm
          address={editingBillingAddress}
          onSubmit={(address) => this.handleSubmitAddress('billing', address)}
        />
      );
    }

    if (editingShippingAddress.id) {
      return (
        <CustomerAddressForm
          address={editingShippingAddress}
          onSubmit={(address) => this.handleSubmitAddress('shipping', address)}
        />
      );
    }
  };

  renderDialogTitle = () => {
    const { intl } = this.props;
    const {
      editingAccount,
      editingBillingAddress,
      editingShippingAddress,
      editingGeneralInfo,
      addingCreditCard,
      editingPaymentTerms,
      resendingAccountInvite,
      resettingAccountPassword,
      resendingCustomerInvite,
      resettingCustomerPassword,
    } = this.state;

    if (resendingAccountInvite || resendingCustomerInvite) {
      return intl.formatMessage({ id: 'customer.dialogTitles.sendingInvite' });
    }

    if (resettingAccountPassword || resettingCustomerPassword) {
      return intl.formatMessage({ id: 'customer.dialogTitles.resettingPassword' });
    }

    if (editingPaymentTerms) {
      return intl.formatMessage({ id: 'customer.dialogTitles.editingPaymentTerms' });
    }

    if (editingPaymentTerms) {
      return intl.formatMessage({ id: 'customer.dialogTitles.editingPaymentTerms' });
    }

    if (addingCreditCard) {
      return intl.formatMessage({ id: 'customer.dialogTitles.addingCreditCard' });
    }

    if (editingGeneralInfo) {
      return intl.formatMessage({ id: 'customer.dialogTitles.editingMainAccount' });
    }

    if (editingAccount.id) {
      return editingAccount.id === 'new'
        ? intl.formatMessage({ id: 'customer.dialogTitles.addingAccount' })
        : intl.formatMessage({ id: 'customer.dialogTitles.editingAccount' });
    }

    if (editingBillingAddress.id) {
      return editingBillingAddress.id === 'new'
        ? intl.formatMessage({ id: 'customer.dialogTitles.addingAddress' })
        : intl.formatMessage({ id: 'customer.dialogTitles.editingAddress' });
    }

    if (editingShippingAddress.id) {
      return editingShippingAddress.id === 'new'
        ? intl.formatMessage({ id: 'customer.dialogTitles.addingAddress' })
        : intl.formatMessage({ id: 'customer.dialogTitles.editingAddress' });
    }
  };

  render() {
    const { customer, loading, intl, classes, producer } = this.props;
    const { confirmState } = this.state;

    const terms = customer.getTerms();

    return (
      <React.Fragment>
        {loading && <Spinner size={80} />}
        <div className={classes.wrap}>
          <div className={classes.titleWrap}>
            <Typography component="h5" variant="h5">
              {intl.formatMessage({ id: 'customer.userAccounts' })}
            </Typography>
            <IconButton onClick={() => this.handleEditAccount({ id: 'new' })}>
              <PlusIcon color="secondary" />
            </IconButton>
          </div>
          <div className={classes.accountsWrap}>
            <CustomerAccountBox
              key={`account-${customer.id}`}
              account={customer}
              onEdit={this.handleEditGeneralInfo}
              onResetPassword={this.resetCustomerPassword}
              onResendInvite={this.resendCustomerInvite}
              main
            />

            {customer.accounts.map((account) => (
              <CustomerAccountBox
                key={account.id}
                account={account}
                onResetPassword={() => this.resetAccountPassword(account)}
                onResendInvite={() => this.resendAccountInvite(account)}
                onDelete={() => this.handleDeleteAccount(account)}
                onEdit={this.handleEditAccount}
              />
            ))}
          </div>

          <div className={classes.titleWrap}>
            <Typography component="h5" variant="h5">
              {intl.formatMessage({ id: 'customer.billingAddresses' })}
            </Typography>
            <IconButton onClick={() => this.handleEditAddress('billing', { id: 'new' })}>
              <PlusIcon color="secondary" />
            </IconButton>
          </div>
          <div className={classes.accountsWrap}>
            {customer.billingAddresses.map((address) => (
              <CustomerAddressBox
                key={address.id}
                address={address}
                onDelete={this.handleDeleteAddress}
                onEdit={() => this.handleEditAddress('billing', address)}
              />
            ))}
          </div>

          <div className={classes.titleWrap}>
            <Typography component="h5" variant="h5">
              {intl.formatMessage({ id: 'customer.shippingAddresses' })}
            </Typography>
            <IconButton onClick={() => this.handleEditAddress('shipping', { id: 'new' })}>
              <PlusIcon color="secondary" />
            </IconButton>
          </div>
          <div className={classes.accountsWrap}>
            {customer.shippingAddresses.map((address) => (
              <CustomerAddressBox
                key={address.id}
                address={address}
                onDelete={this.handleDeleteAddress}
                onEdit={() => this.handleEditAddress('shipping', address)}
              />
            ))}
          </div>

          <div>
            <Typography component="h5" variant="h5">
              {intl.formatMessage({ id: 'customer.paymentMethods' })}
            </Typography>
            <div className={classes.buttonWrapper}>
              <SecondaryButton onClick={this.handleAddCreditCard}>
                <FormattedMessage id="account.addCreditCard" />
              </SecondaryButton>
            </div>
          </div>
          <div className={classes.accountsWrap}>
            {customer.paymentMethods.map((method) => (
              <CustomerPaymentMethodBox
                key={method.id}
                paymentMethod={method}
                onDelete={this.handleDeletePaymentMethod}
                onMakeDefault={this.handleMakePaymentMethodDefault}
              />
            ))}
          </div>

          {terms && (
            <React.Fragment>
              <div className={classes.titleWrap}>
                <Typography component="h5" variant="h5" gutterBottom>
                  {intl.formatMessage({ id: 'customer.accountSettings' })}
                </Typography>
              </div>
              <div className={classes.accountsWrap}>
                <CustomerPaymentTerms
                  terms={terms}
                  producerId={producer.id}
                  bookToQuickBooks={customer.bookToQuickBooks}
                  onEdit={this.handleEditPaymentTerms}
                />
              </div>
            </React.Fragment>
          )}
        </div>
        <BaseDialog
          open={this.state.dialogOpened}
          title={this.renderDialogTitle()}
          containerStyle={{ minWidth: 480 }}
          onClose={() => this.closeDialog()}>
          {this.state.dialogLoading && <Spinner size={40} />}
          {this.renderDialogContent()}
        </BaseDialog>

        {confirmState.open && (
          <ConfirmDialog
            onClose={() => this.setState({ confirmState: { open: false } })}
            confirmTitle={confirmState.confirmTitle}
            variant={CONFIRM_DIALOG_VARIANTS.TINY}
            title={confirmState.title}
            withCancel
            message={confirmState.message}
            onConfirm={confirmState.action}
          />
        )}
      </React.Fragment>
    );
  }
}

CustomerView.propTypes = {
  customer: PropTypes.instanceOf(Customer),
};

const mapStateToProps = (state) => ({
  producer: state.producer.object,
  ...state.customer,
});

const mapDispatchToProps = (dispatch) => ({
  replaceCustomer: (customer) => dispatch({ type: REPLACE_CUSTOMER, customer }),
});

export default withStyles(styles)(injectIntl(connect(mapStateToProps, mapDispatchToProps)(CustomerView)));
