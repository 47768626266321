import axios from "axios";

/**
 * Create axios handler for all requests and errors for them
 * @param {function} setGlobalNotificationText -
 * @param {object} intl
 */
export const addAxiosInterceptors = (setGlobalNotificationText, intl) => {
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      errorHandler(setGlobalNotificationText, error, intl);
      return Promise.reject(error);
    }
  );
};

/**
 * @param {function} setGlobalNotificationText -
 * @param {object} error -
 * @param {object} intl
 * @returns {boolean}
 */
export const errorHandler = (setGlobalNotificationText, error, intl) => {
  const code = error?.response?.data?.code;
  // const message = error?.response?.data?.message;

  if (!!code) {
    switch (code) {
      case 4056: {
        errorMessageHandler(
          setGlobalNotificationText,
          intl.formatMessage({ id: "global.error.codeMessage.4056" })
        );
        break;
      }
      default: {
        return false;
      }
    }
  }
};

export const errorMessageHandler = (setGlobalNotificationText, message) => {
  if (!!message) {
    setGlobalNotificationText(message);
  }
};
