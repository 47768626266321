import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import DXTextFilter from '../DXTextFilter/DXTextFilter';
import DXFilterConfig from '../DXFilterConfig';
import constants from '../../constants';
import MenuItem from '@material-ui/core/MenuItem';
import PrimarySelect from '../../../../PrimarySelect/PrimarySelect';

const propTypes = {
  equality: PropTypes.string,
  selected: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  selected: '',
};

class DXAmountFilter extends React.Component {
  render() {
    const { selected, onChange, intl, equality } = this.props;
    const config = DXAmountFilter.getConfig(intl, equality);

    return (
      <DXTextFilter value={selected} title={config.title} isNumber={true} onChange={onChange} values={config.values} />
    );
  }
}

DXAmountFilter.propTypes = propTypes;
DXAmountFilter.defaultProps = defaultProps;
DXAmountFilter.getLabel = (intl, selectedValues) => {
  return DXTextFilter.getLabel(intl, 'filter.amountChips', selectedValues);
};
DXAmountFilter.applyToQueryParams = (queryParams, selectedValues, config) => {
  selectedValues[0] && (queryParams['amount:' + config.selectedEquality] = selectedValues[0]);
  return queryParams;
};
DXAmountFilter.getConfig = (intl, equality) => {
  return new DXFilterConfig({
    name: 'amount',
    selected: [],
    property: 'amount',
    title: intl.formatMessage({ id: 'filter.amount' }),
    disabled: false,
    equalityStatus: false,
    selectedEquality: equality,
    variants: ({ value, onChangeEquality }) => (
      <PrimarySelect
        label={intl.formatMessage({ id: 'global.filter' })}
        value={value}
        fullWidth
        onChange={onChangeEquality}>
        <MenuItem key={constants.FILTER_EQUALITY_EQUALS} value={constants.FILTER_EQUALITY_EQUALS}>
          {intl.formatMessage({ id: 'filter.amount.equal' })}
        </MenuItem>
        <MenuItem key={constants.FILTER_EQUALITY_LESS} value={constants.FILTER_EQUALITY_LESS}>
          {intl.formatMessage({ id: 'filter.amount.less' })}
        </MenuItem>
        <MenuItem key={constants.FILTER_EQUALITY_MORE} value={constants.FILTER_EQUALITY_MORE}>
          {intl.formatMessage({ id: 'filter.amount.more' })}
        </MenuItem>
      </PrimarySelect>
    ),
  });
};

export default injectIntl(DXAmountFilter);
