import { PRODUCER_SET } from '../constants';
import Producer from '../../entities/Producer';
import Tabs from '../../entities/UI/Tabs';

const initialState = {
  object: {},
  entity: new Producer({}),
  tabs: {
    orders: new Tabs('orders', []),
    drafts: new Tabs('drafts', []),
    products: new Tabs('products', []),
    prepurchases: new Tabs('prepurchases', []),
    subscriptions: new Tabs('subscriptions', []),
    discounts: new Tabs('discounts', []),
    unique_products: new Tabs('unique_products', []),
    customers: new Tabs('customers', []),
    reports: new Tabs('reports', []),
    supplier_orders: new Tabs('supplier_orders', []),
    supplier_drafts: new Tabs('supplier_drafts', []),
  },
  loading: false,
  error: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCER_SET: {
      const { producer } = action.payload;

      return {
        ...state,
        object: producer,
        entity: new Producer(producer),
        tabs: {
          orders: new Tabs('orders', producer.adminTabs),
          drafts: new Tabs('drafts', producer.adminTabs),
          products: new Tabs('products', producer.adminTabs),
          prepurchases: new Tabs('prepurchases', producer.adminTabs),
          subscriptions: new Tabs('subscriptions', producer.adminTabs),
          discounts: new Tabs('discounts', producer.adminTabs),
          unique_products: new Tabs('unique_products', producer.adminTabs),
          customers: new Tabs('customers', producer.adminTabs),
          reports: new Tabs('reports', producer.adminTabs),
          supplier_orders: new Tabs('supplier_orders', producer.adminTabs),
          supplier_drafts: new Tabs('supplier_drafts', producer.adminTabs),
        },
        loading: false,
        error: false,
      };
    }
    default:
      return state;
  }
};

export default reducer;
