import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import styles from './styles';
import { AdminDxTableDesigned } from '../../../../components';
import CellRenderer from './CellRenderer';
import classNames from 'classnames';
import moment from 'moment';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useLocation, useNavigate } from 'react-router';

const useStyles = makeStyles(styles);

const ItemsByDateTreeComponent = (props) => {
  const {
    columnsConfig,
    item,
    initialOpen = true,
    onCollapseChange,
    dontShowEmpty,
    updateSingleItem,
    reloadAllData,
    index,
    itemsData,
  } = props;
  const classes = useStyles();
  const history = useNavigate();
  const location = useLocation();
  const intl = useIntl();
  const [open, setOpen] = useState(initialOpen);
  const hasItems = !!item?.items?.length;
  const todayItem = !!item?.isToday;
  const tuesdayItem = !!item?.isTuesday;

  useEffect(() => {
    setOpen(initialOpen);
  }, [initialOpen]);

  const onItemClick = () => {
    hasItems &&
      setOpen((value) => {
        onCollapseChange(item, !value);

        return !value;
      });
  };

  const onRowClick = (row) => {
    const isSubscription = !!row.subscription;
    const hasOrder = !!row?.order?.id;

    if (!hasOrder && !isSubscription) {
      return;
    }

    history(isSubscription ? `${location.pathname}/${row.subscription.id}${location.hash}` : `/order/${row.order.id}`);
  };

  const drawTodaySeparator = () => {
    const prevItem = itemsData[index - 1];
    const nextItem = itemsData[index + 1];
    const noPaddingBottom = !nextItem?.items?.length && prevItem?.items?.length;
    const noPaddings = !nextItem?.items?.length && !prevItem?.items?.length;

    return (
      <div
        className={classNames([
          classes.todaySeparatorWrapper,
          noPaddingBottom && classes.noPaddingBottom,
          noPaddings && classes.noPaddings,
          hasItems && classes.noPaddingBottom,
        ])}>
        <span className={classes.isMostRecent} />
        <p className={classes.todaySeparatorText}>{intl.formatMessage({ id: 'global.today' })}</p>
        <div className={classes.todaySeparatorBorder} />
      </div>
    );
  };

  if (dontShowEmpty && !hasItems) {
    return null;
  }

  if (todayItem && !hasItems && !tuesdayItem) {
    return drawTodaySeparator();
  }

  return (
    <>
      {todayItem && drawTodaySeparator()}
      <div
        className={classNames([
          classes.singleItemWrapper,
          !hasItems && classes.singleItemWrapperNoItems,
          item.isInPast && classes.singleItemWrapperInPast,
        ])}>
        <div
          className={classNames([classes.initialItemWrapper, !hasItems && classes.initialItemWrapperNoItems])}
          onClick={onItemClick}>
          <div className={classNames([classes.itemTitle, !hasItems && classes.itemTitleNoItems])}>
            <span className={classNames([classes.itemDate, item.isInPast && !hasItems && 'itemInPast'])}>
              {moment(item.id).format('ddd, MMMM D, YYYY')}
            </span>
            {!open && hasItems && (
              <span className={classNames([classes.numberOfItems, item.isInPast && 'itemInPast'])}>
                {item?.items?.length === 1
                  ? intl.formatMessage({ id: 'memberService.table.numberOfItemsSingle' })
                  : intl.formatMessage({ id: 'memberService.table.numberOfItems' }, { number: item?.items?.length })}
              </span>
            )}
            {!hasItems && (
              <span className={classes.numberOfItems}>{intl.formatMessage({ id: 'memberService.table.noItems' })}</span>
            )}
          </div>
          {hasItems && (
            <div className={classNames([classes.expandIconWrapper, item.isInPast && 'itemInPast'])}>
              {open ? (
                <ExpandLessIcon className={classes.expandIcon} />
              ) : (
                <ExpandMoreIcon className={classes.expandIcon} />
              )}
            </div>
          )}
        </div>

        {hasItems && (
          <div className={classNames([classes.tableWrapper, open ? classes.active : classes.hidden])}>
            <AdminDxTableDesigned
              title={''}
              enableSearch={false}
              enablePager={false}
              apiRetrieve={(params, onSuccess) => onSuccess(item.items)}
              cellRenderer={(reload, { row, ...props }, rows, onUpdateRowsExternally, table) =>
                CellRenderer(
                  reload,
                  { row, rows, ...props },
                  (newData) => updateSingleItem(item, row, newData),
                  reloadAllData,
                  table
                )
              }
              CustomHeaderComponent={() => null}
              toolbarRootComponent={() => null}
              TableStubCellComponent={() => null}
              onRowClick={onRowClick}
              {...columnsConfig}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ItemsByDateTreeComponent;
