import React from 'react';
import PropTypes from 'prop-types'
import {injectIntl} from 'react-intl'
import DXSelectFilter from '../DXSelectFilter/DXSelectFilter'
import DXFilterConfig from '../DXFilterConfig'
import constants from '../../constants'

const propTypes = {
  equality: PropTypes.string,
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  selected: [],
};

class DXSubscriptionFilter extends React.Component {
  
  render() {
    const {selected, onChange, intl, equality} = this.props;
    const config = DXSubscriptionFilter.getConfig(intl, equality);
    return (
      <DXSelectFilter selected={selected}
                      title={config.title}
                      values={config.values}
                      onChange={onChange}
                      multi={config.multi}
      />
    )
  }
}

DXSubscriptionFilter.propTypes = propTypes;
DXSubscriptionFilter.defaultProps = defaultProps;
DXSubscriptionFilter.getLabel = (intl, selectedValues) => {
  return DXSelectFilter.getLabel(intl, 'filter.subscription.', selectedValues);
};
DXSubscriptionFilter.applyToQueryParams = (queryParams, selectedValues) => {
  selectedValues[0] && (queryParams['withSubscription'] = selectedValues[0]);
  return queryParams;
};
DXSubscriptionFilter.getConfig = (intl, equality) => {
  let config = new DXFilterConfig({
    name: 'subscription',
    selected: [],
    property: 'subscription',
    title: intl.formatMessage({id: 'filter.orderType.subscription'}),
    disabled: false,
    equalityStatus: false,
    multi: false
  });
  
  if (equality === constants.FILTER_EQUALITY_EQUALS) {
    config.addFilterValue('true', intl.formatMessage({id: 'filter.subscription.true'}));
    config.addFilterValue('false', intl.formatMessage({id: 'filter.subscription.false'}));
  }
  
  return config;
};

export default injectIntl(
  DXSubscriptionFilter
);
