import React from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { COLORS } from '../../constants/colors';
import { FONT_LABEL, FONT_TEXT_INPUT, FONT_TEXT_INPUT_MULTILINE } from '../../constants/fonts';
import InputAdornment from '@material-ui/core/InputAdornment';

const SupplierTextField = (props) => {
  const { coloredLabel, moneyField, ...others } = props;

  const theme = createTheme({
    typography: {
      useNextVariants: true,
    },
    palette: {
      primary: {
        main: COLORS.blackTintedBlue,
      },
      secondary: {
        main: COLORS.text,
      },
      white: {
        main: COLORS.white,
      },
    },
    overrides: {
      MuiFormLabel: {
        root: {
          color: COLORS.text,
          '&$focused': {
            color: COLORS.brightBlue,
          },
        },
        /*"&$focused": {
          "&$focused": {
            color: "#41CD8C"
          }
        }*/
      },

      MuiInput: {
        root: {
          ...FONT_TEXT_INPUT,
          minHeight: 40,
        },
        underline: {
          '&:after': {
            borderBottom: `2px solid ` + COLORS.border,
          },
          '&$focused:after': {
            borderBottomColor: COLORS.border,
          },
          '&$error:after': {
            borderBottomColor: COLORS.border,
          },
          '&:before': {
            borderBottom: `1px solid ` + COLORS.border,
          },
          '&:hover:not($disabled):not($focused):not($error):before': {
            borderBottom: `2px solid ` + COLORS.border,
          },
          '&$disabled:before': {
            borderBottom: `1px dotted ` + COLORS.border,
          },
        },
      },
      MuiInputBase: {
        multiline: {
          marginTop: '25px !important',
          borderRadius: 10,
          border: '1px solid ' + COLORS.border,
          '&:after, &:before': {
            borderBottom: '0 !important',
          },
          padding: 10,
        },
        inputMultiline: {
          ...FONT_TEXT_INPUT_MULTILINE,
        },
      },
      MuiInputLabel: {
        root: {
          ...FONT_LABEL,
          // Shrink make font size smaller in 75%
          fontSize: FONT_LABEL.fontSize / 0.75,
          ...(coloredLabel
            ? {
                color: COLORS.brightBlue,
                fontWeight: '700',
              }
            : {}),
        },
      },
      MuiInputAdornment: {
        root: {
          ...FONT_TEXT_INPUT,
          width: 5,
        },
      },
      MuiFormHelperText: {
        root: {
          ...FONT_LABEL,
          color: COLORS.grey,
          marginTop: 6,
        },
      },
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      <TextField
        {...others}
        {...(moneyField
          ? {
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start" disableTypography>
                    {'$'}
                  </InputAdornment>
                ),
              },
            }
          : {})}>
        {props.children}
      </TextField>
    </MuiThemeProvider>
  );
};

export default SupplierTextField;
