import React from 'react'
import PropTypes from 'prop-types'
import {injectIntl} from 'react-intl'
import {withStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {COLORS} from '../../../helpers/index'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/ErrorOutline'

const styles = ({
  text: {
    fontSize: 14,
    color: COLORS.textSecondary,
    fontFamily: "Roboto, sans-serif",
  },
  okIcon: {
    fill: COLORS.text,
    fontSize: 28,
    marginRight: 10
  },

  errorIcon: {
    fill: COLORS.violet,
    fontSize: 28,
    marginRight: 10
  },

  taxId: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    fontSize: 14,
    color: COLORS.textSecondary,
    fontFamily: "Roboto, sans-serif",
  },

  icon: {
    color: COLORS.text,
    fontSize: 18
  },

  wrapper: {
    position: 'relative'
  },

  controls: {
    position: 'absolute',
    top: 0,
    right: 0,
  }

});

const propTypes = {
  classes: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
};

const CompanyAccount = ({ settings,
                          intl,
                          classes }) => {
  return (
    <div className={classes.wrapper}>
      {(settings.company &&
        settings.company.name) && (
        <React.Fragment>
          <Typography className={classes.text} variant={"body1"}>
            {settings.company.name || ""}
          </Typography>
          <Typography className={classes.text} variant={"body1"}>
            {settings.company.address.line1 || ""}
          </Typography>
          <Typography className={classes.text} variant={"body1"}>
            {settings.company.address.city},&nbsp;
            {settings.company.address.postal_code},&nbsp;
            {settings.company.address.country}&nbsp;
          </Typography>
          <Typography className={classes.text} variant={"body1"}>
            {settings.company.phone || ""}
          </Typography>
          <Typography className={classes.taxId} variant={"body1"}>
            {settings.company.tax_id_provided ?
              <CheckCircleIcon className={classes.okIcon} /> :
              <ErrorIcon className={classes.errorIcon} />
            }

            {settings.company.tax_id_provided ?
              intl.formatMessage({id: "business.taxIdProvided"}) :
              intl.formatMessage({id: "business.taxIdNotProvided"})}
          </Typography>
        </React.Fragment>
      )}

      {(!settings.company || !settings.company.name) && (
        <Typography className={classes.text} variant={"body1"}>
          {intl.formatMessage({id: "messages.notAdded"})}
        </Typography>
      )}

    </div>
  );
};

CompanyAccount.propTypes = propTypes;

export default withStyles(styles)(
  injectIntl(CompanyAccount)
);

