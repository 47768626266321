import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import Autosuggest from 'react-autosuggest'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import Popper from '@material-ui/core/Popper'
import { withStyles } from '@material-ui/core/styles'

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, i) => (
          <span key={`${part.text}-${i}`} style={{ fontWeight: part.highlight ? 500 : 400 }}>
            {part.text}
          </span>
        ))}
      </div>
    </MenuItem>
  );
}

function getSuggestions(value, suggestions) {
  return suggestions
}

function getSuggestionValue(suggestion) {
  return suggestion.label;
}

const styles = ({
  root: {
    flexGrow: 1,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
  },
  suggestion: {
    listStyleType: 'none !important',
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    maxHeight: 200,
    overflowX: 'hidden',
    overflowY: 'visible',
    listStyleType: 'none !important',
  },
});

const propTypes = {
  onAttach: PropTypes.func.isRequired,
  selected: PropTypes.object,
  suggestions: PropTypes.array.isRequired,
};

const Suggestions = ({ classes,
                       intl,
                       selected,
                       onAttach,
                       suggestions }) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState({
    single: '',
    popper: selected ? selected.label : "",
  });

  const [stateSuggestions, setSuggestions] = React.useState(suggestions);

  const handleSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value, suggestions));
  };

  const handleSuggestionSelect = (...props) => {
    onAttach(...props)
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleChange = name => (event, { newValue }) => {
    setState({
      ...state,
      [name]: newValue,
    });
  };

  const autoSuggestProps = {
    renderInputComponent,
    suggestions: stateSuggestions,
    onSuggestionSelected: handleSuggestionSelect,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue : (value) => getSuggestionValue(value, suggestions),
    renderSuggestion,
  };

  return (
    <div className={classes.root}>
      <Autosuggest
        {...autoSuggestProps}
        shouldRenderSuggestions={() => true}
        inputProps={{
          classes,
          id: 'react-autosuggest-popper',
          placeholder: intl.formatMessage({id: "qb.account"}),
          value: state.popper,
          onChange: handleChange('popper'),
          inputRef: node => {
            setAnchorEl(node);
          },
          InputLabelProps: {
            shrink: true,
          },
        }}
        theme={{
          suggestionsContainer: classes.suggestionsContainer,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderSuggestionsContainer={options => (
          <Popper anchorEl={anchorEl} open={Boolean(options.children)}>
            <Paper
              square
              {...options.containerProps}
              style={{ width: anchorEl ? anchorEl.clientWidth : undefined, listStyleType: "none" }}
            >
              {options.children}
            </Paper>
          </Popper>
        )}
      />
    </div>
  );
};

Suggestions.propTypes = propTypes;

export default withStyles(styles, {withTheme: true})(
  injectIntl(Suggestions)
);