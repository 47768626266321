import React from 'react';
import PropTypes from 'prop-types';
import {
  MuiThemeProvider,
  createTheme
} from '@material-ui/core/styles';
import Select from "@material-ui/core/Select";
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from "@material-ui/core/FormControl";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {PrimaryLabel} from '../../components';
import {COLORS} from "../../helpers";

const propTypes = {
  helperText: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  formControlClassName: PropTypes.string
};

const defaultProps = {
  error: false,
};

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiSelect: {
      root: {
        color: COLORS.text,
        fontSize: '14px',
      },
      icon: {
        fill: '#E0E0E0',
        fontSize: 22
      }
    },
    MuiInput: {
      root: {
        color: COLORS.text,
        fontSize: '14px',
        minHeight: 40,
      },
      underline: {
        '&:after': {
          borderBottom: `2px solid #363636`,
        },
        '&$focused:after': {
          borderBottomColor: `#363636`,
        },
        '&$error:after': {
          borderBottomColor: `#363636`,
        },
        '&:before': {
          borderBottom: `1px solid #E0E0E0`,
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: `2px solid #E0E0E0`,
        },
        '&$disabled:before': {
          borderBottom: `1px dotted #E0E0E0`,
        },
        '&$focused svg': {
          fill: '#363636',
        }
      },
    },
  },
});

class PrimarySelect extends React.Component {
  render() {
    const {formControlClassName, children, label, helperText, error, disabled, ...other} = this.props;

    return (
      <MuiThemeProvider theme={theme} >
        <FormControl className={formControlClassName} error={error} disabled={disabled}>
          {label && (
            <PrimaryLabel>{label}</PrimaryLabel>
          )}
          <Select
            IconComponent={KeyboardArrowDownIcon}
            {...other} >
            {children}
          </Select>

          {helperText && (
            <FormHelperText>{helperText}</FormHelperText>
          )}
        </FormControl>
      </MuiThemeProvider>
    );
  }
}

PrimarySelect.propTypes = propTypes;
PrimarySelect.defaultProps = defaultProps;

export default PrimarySelect;
