import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import {COLORS} from '../../../helpers/index'
import EditIcon from '@material-ui/icons/Edit'
import {IconButton} from '@material-ui/core'

const styles = ({
  title: {
    fontSize: 22,
    display: 'block',
    marginBottom: 12,
    color: COLORS.text,
    fontWeight: 300,
    fontFamily: "Roboto, sans-serif",
  },
  footer: {
    paddingTop: '14px',
    paddingBottom: '14px',
    paddingLeft: '23px',
    paddingRight: '23px',
    textAlign: 'right',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 368
  },
  paper: {
    minHeight: 70,
    marginRight: 60,
    //padding: '14px 23px'
  },
  content: {
    paddingTop: '14px',
    paddingBottom: '14px',
    paddingLeft: '23px',
    paddingRight: '23px',
  },
  icon: {
    color: COLORS.text,
    fontSize: 18
  }
});

const propTypes = {
  contentClass: PropTypes.string,
  wrapperClass: PropTypes.string,
  headerComponent: PropTypes.func,
  title: PropTypes.string,
  onEdit: PropTypes.func,
  wrapperStyles: PropTypes.object,
};

const Block = ({ children,
                 title,
                 headerComponent,
                 onEdit,
                 wrapperStyles,
                 paperClass,
                 contentClass,
                 wrapperClass,
                 titleClass,
                 classes }) => {
  return (
    <div style={wrapperStyles || {}} className={classNames(classes.wrapper, wrapperClass)}>

      {title && (
        <Typography className={classNames([classes.title, titleClass])}
                    variant={"h5"}>{title}</Typography>
      )}

      <Paper className={classNames(classes.paper, paperClass)}>
        {headerComponent && (
          headerComponent()
        )}


        <div className={classNames(classes.content, contentClass)}>
          {children}
        </div>

        {onEdit && (
          <div className={classes.footer}>
            <IconButton style={{padding: 5, marginRight: -5, marginBottom: -5}} onClick={() => onEdit && onEdit()}>
              <EditIcon className={classes.icon} />
            </IconButton>
          </div>
        )}
      </Paper>
    </div>
  );
};

Block.propTypes = propTypes;

export default withStyles(styles)(
  Block
);

