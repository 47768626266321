import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { COLORS } from '../../../helpers/index'

const styles = ({
  title: {
    fontSize: 20,
    display: 'block',
    color: COLORS.text,
    fontWeight: 300,
    fontFamily: "Roboto, sans-serif",
  },
  header: {
    display: 'flex',
    flexDirection: 'space-between',
    alignItems: 'center',
    paddingTop: '14px',
    paddingBottom: '14px',
    paddingLeft: '23px',
    paddingRight: '23px',
    borderBottom: `1px solid ${COLORS.border}`,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 368
  },
  icon: {
    color: COLORS.text,
    fontSize: 18
  }
});

const propTypes = {
  title: PropTypes.string,
};

const BusinessEntityHeader = ({ classes,
                                intl }) => {
  return (
    <div className={classes.header}>
      <Typography className={classes.title}>
        {intl.formatMessage({id: "producer.payment.businessEntity"})}
      </Typography>
    </div>
  );
};

BusinessEntityHeader.propTypes = propTypes;

export default withStyles(styles)(
  injectIntl(BusinessEntityHeader)
);

