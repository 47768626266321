import React from 'react';
import { COLORS } from '../constants/colors';

const AddImage = (props) => {
  const { color = COLORS.primary, ...other } = props;
  return (
    <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <path
        d="M3.5 3V0H5.5V3H8.5V5H5.5V8H3.5V5H0.5V3H3.5ZM6.5 9V6H9.5V3H16.5L18.33 5H21.5C22.6 5 23.5 5.9 23.5 7V19C23.5 20.1 22.6 21 21.5 21H5.5C4.4 21 3.5 20.1 3.5 19V9H6.5ZM13.5 18C16.26 18 18.5 15.76 18.5 13C18.5 10.24 16.26 8 13.5 8C10.74 8 8.5 10.24 8.5 13C8.5 15.76 10.74 18 13.5 18ZM10.3 13C10.3 14.77 11.73 16.2 13.5 16.2C15.27 16.2 16.7 14.77 16.7 13C16.7 11.23 15.27 9.8 13.5 9.8C11.73 9.8 10.3 11.23 10.3 13Z"
        fill={color}
      />
    </svg>
  );
};

export default AddImage;
