import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import DXSelectFilter from '../DXSelectFilter/DXSelectFilter';
import DXFilterConfig from '../DXFilterConfig';
import constants from '../../constants';

const propTypes = {
  equality: PropTypes.string,
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  selected: [],
};

class DXCustomerApprovedFilter extends React.Component {
  render() {
    const { selected, onChange, intl, equality } = this.props;
    const config = DXCustomerApprovedFilter.getConfig(intl, equality);
    return (
      <DXSelectFilter
        selected={selected}
        title={config.title}
        values={config.values}
        onChange={onChange}
        multi={config.multi}
      />
    );
  }
}

DXCustomerApprovedFilter.propTypes = propTypes;
DXCustomerApprovedFilter.defaultProps = defaultProps;
DXCustomerApprovedFilter.getLabel = (intl, selectedValues) => {
  return DXSelectFilter.getLabel(intl, 'customer.filter.approved.', selectedValues);
};
DXCustomerApprovedFilter.applyToQueryParams = (queryParams, selectedValues) => {
  selectedValues[0] && (queryParams['approved'] = selectedValues[0]);
  return queryParams;
};
DXCustomerApprovedFilter.getConfig = (intl, equality) => {
  let config = new DXFilterConfig({
    name: 'customerApproved',
    selected: [],
    property: 'approved',
    title: intl.formatMessage({ id: 'customer.filter.approvedFilter' }),
    disabled: false,
    equalityStatus: false,
    multi: false,
  });

  if (equality === constants.FILTER_EQUALITY_EQUALS) {
    config.addFilterValue('true', intl.formatMessage({ id: 'customer.filter.approved.true' }));
    config.addFilterValue('false', intl.formatMessage({ id: 'customer.filter.approved.false' }));
  }

  return config;
};

DXCustomerApprovedFilter.getInitialConfig = (intl, values, readOnly, invisible) => {
  return {
    name: 'customerApproved',
    title: intl.formatMessage({ id: 'customer.filter.approvedFilter' }),
    property: 'approved',
    type: 'customerApproved',
    selectedValues: values,
    clientSide: false,
    readOnly: readOnly ? readOnly : false,
    fromVariable: !!invisible || false,
  };
};

export default injectIntl(DXCustomerApprovedFilter);
