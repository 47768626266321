import React from 'react';
import PropTypes from 'prop-types';
import LensIcon from '@material-ui/icons/Lens';
import PanoramaFishEyeIcon from '@material-ui/icons/PanoramaFishEye';
import {COLORS} from "../../helpers";

const propTypes = {
  status: PropTypes.bool,
};

const defaultProps = {
  status: false,
};

const ActiveStatus = ({ status }) => {
  return (
    status
      ? <LensIcon style={{color: COLORS.text, fontSize: 16}} />
      : <PanoramaFishEyeIcon style={{color: COLORS.text, fontSize: 16}} />
  )
};

ActiveStatus.propTypes = propTypes;
ActiveStatus.defaultProps = defaultProps;

export default ActiveStatus
