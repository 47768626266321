import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { withRouter } from '../../hocs';
import { signIn, makeNonInitialized, makeInitialized } from '../../store/actions';
import FFBackgroundImg from './assets/ff-background.jpg';
import GBBackgroundImg from './assets/gb-background.jpg';
import {
  AUTH_EMAIL_AND_PASSWORD_STAGE,
  AUTH_REGISTER_STAGE,
  AUTH_RESTORE_PASSWORD_HASH,
  AUTH_CREATE_PASSWORD_HASH,
  isFellowFarmer,
} from '../../helpers';
import { LandingPageHeader } from '../../components';
import { apiGetProducerByToken } from '../../api';
import { LoginForm, NewPasswordForm, NewAccountForm } from '../../forms';
import FFStyles from './ff-styles';
import GBStyles from './gb-styles';
import Producer from '../../entities/Producer';

const styles = isFellowFarmer() ? FFStyles : GBStyles;

const RESOLVER_ACTION_LOGIN = 'login';
const RESOLVER_ACTION_REGISTER = 'register';

class LoginScene extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPasswordToken: null,
      newPasswordUser: null,
      newPasswordUserId: null,
      newAccountToken: null,
      newAccountEmail: null,
      restorePassword: false,
      stage: AUTH_EMAIL_AND_PASSWORD_STAGE,
      dialog: false,
      open: true,
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  handleOpen(stage, withBack) {
    this.setState({
      dialog: true,
      withBack: withBack,
      open: true,
      stage: stage,
    });
  }

  handleClose() {
    this.setState({
      dialog: false,
      open: false,
    });
  }

  handleNewPasswordClose = () => {
    this.setState({
      newPasswordToken: null,
      newPasswordUser: null,
      newPasswordUserId: null,
      newPassword: false,
      restorePassword: false,
    });
  };

  handleNewAccountClose = () => {
    this.setState({
      newAccountToken: null,
      newAccountEmail: null,
    });
  };

  handleNewAccountDone = () => {
    this.setState(
      {
        newAccountToken: null,
        newAccountEmail: null,
      },
      () => this.handleOpen(AUTH_EMAIL_AND_PASSWORD_STAGE, true)
    );
  };

  handleLogin(data) {
    this.setState({ open: false });
    const { history } = this.props;

    this.props.makeNonInitialized();
    this.props.onLogin(data, () => {
      this.props.makeInitialized();

      if (Producer.hasAccessToSection('home')) {
        history.push('/home');
      } else {
        history.push('/about');
      }
    });
  }

  async componentDidMount() {
    const { location, match, history } = this.props;
    window.scrollTo(0, 0);

    if (match.url === '/sign-in-via-link') {
      // Sign In with Link + Token
      let path = location.search;
      let token = path.split('?t=')[1];
      await apiGetProducerByToken(
        token,
        (response) => {
          response.token = token;
          this.handleLogin(response);
        },
        () => {
          history.push('/');
        }
      );
    }
    // Check if user wants to make password reset
    if (location.hash === AUTH_CREATE_PASSWORD_HASH || location.hash === AUTH_RESTORE_PASSWORD_HASH) {
      //const userId = QueryString.parse(location.search).c;
      //const token = QueryString.parse(location.search).t;
      /*apiCustomerVerifyAccessToken(userId, token,
        (response) => {

          if (this.props.location.hash === AUTH_CREATE_PASSWORD_HASH) {
            this.setState({
              newPasswordToken: response.data.token,
              newPasswordUser: response.data.user,
              newPasswordUserType: response.data.type,
              newPasswordUserId: userId,
              newPassword: true
            });
          }

          if (this.props.location.hash === AUTH_RESTORE_PASSWORD_HASH) {
            this.setState({
              newPasswordToken: response.data.token,
              newPasswordUser: response.data.user,
              newPasswordUserType: response.data.type,
              newPasswordUserId: userId,
              restorePassword: true
            });
          }
        },
        () => {}
      );*/
    }

    if (location.state && location.state.fromResolver && location.state.action) {
      switch (location.state.action) {
        case RESOLVER_ACTION_LOGIN: {
          this.handleOpen(AUTH_EMAIL_AND_PASSWORD_STAGE, true);
          break;
        }
        case RESOLVER_ACTION_REGISTER: {
          this.setState({
            newAccountToken: location.state.token,
            newAccountEmail: location.state.email,
          });
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  render() {
    const {
      classes,
      authenticated,
      //history
    } = this.props;

    let imageClasses = {};
    imageClasses[classes.bannerImage] = true;
    imageClasses[classes.blurBanner] =
      this.state.dialog || this.state.newPassword || this.state.restorePassword || this.state.newAccountToken;

    return (
      <div className={classes.container}>
        <LandingPageHeader
          appName={process.env.REACT_APP_NAME}
          onLoginClick={() => this.handleOpen(AUTH_EMAIL_AND_PASSWORD_STAGE, true)}
          onSignUpClick={() => this.handleOpen(AUTH_REGISTER_STAGE, false)}
        />

        <div className={classes.banner}>
          <div className={classes.caption}>
            {/*<p className={classes.headLine}>
                <FormattedMessage id="landing.hero"/>
              </p>*/}
            {/*<div className={classes.controls}>
              <PrimaryButton role="button"
                             className={classes.login}
                             onClick={() => {
                               window.scrollTo(0, 0);
                               this.handleOpen(AUTH_EMAIL_AND_PASSWORD_STAGE, true)
                             }} >
                <FormattedMessage id="global.login"/>
              </PrimaryButton>
            </div>*/}
          </div>

          <img
            className={classNames(imageClasses)}
            src={isFellowFarmer() ? FFBackgroundImg : GBBackgroundImg}
            alt={process.env.REACT_APP_TITLE}
          />
        </div>

        {/*<div className={classes.content}>
          <div className={classes.contentContainer}>
            <h1 className={classes.h1}><FormattedMessage id="landing.subHero"/></h1>

            <div className={classes.benefits}>
              <div className={classes.benefit}>
                <img className={classes.benefitImg}
                     src={OneImg}
                     alt={<FormattedMessage id="landing.benefitTitle1"/>}/>
                <h2 className={classes.h2}>{<FormattedMessage id="landing.benefitTitle1"/>}</h2>
                <p className={classes.p}>
                  <FormattedMessage id="landing.benefitMessage1"/>
                </p>
              </div>

              <div className={classes.benefit}>
                <img className={classes.benefitImg}
                     src={TwoImg}
                     alt={<FormattedMessage id="landing.benefitTitle2"/>}/>

                <h2 className={classes.h2}>
                  <FormattedMessage id="landing.benefitTitle2"/>
                </h2>
                <p className={classes.p}>
                  <FormattedMessage id="landing.benefitMessage2"/>
                </p>
              </div>

              <div className={classes.benefit}>
                <img className={classes.benefitImg}
                     src={ThreeImg}
                     alt={<FormattedMessage id="landing.benefitTitle3"/>}/>
                <h2 className={classes.h2}>
                  <FormattedMessage id="landing.benefitTitle3"/>
                </h2>
                <p className={classes.p}>
                  <FormattedMessage id="landing.benefitMessage3"/>
                </p>
              </div>

            </div>

            <div className={classes.bottomButtons}>
              <PrimaryButton role="button"
                             className={classes.login}
                             onClick={() => {
                               window.scrollTo(0, 0);
                               this.handleOpen(AUTH_REGISTER_STAGE, true)
                             }} >
                <FormattedMessage id="reg.signUp"/>
              </PrimaryButton>
            </div>
          </div>

          <Footer appName={process.env.REACT_APP_NAME}
                  history={history}/>
        </div>*/}

        {!authenticated && this.state.open && (
          <LoginForm
            open={this.state.open}
            stage={this.state.stage}
            withBack={this.state.withBack}
            onLogin={this.handleLogin}
            onClose={this.handleClose}
          />
        )}

        {this.state.newPassword && (
          <NewPasswordForm
            title={<FormattedMessage id="createPassword.title" />}
            buttonTitle={<FormattedMessage id="createPassword.title" />}
            finalMessage={
              <FormattedMessage
                id="createPassword.finalMessage"
                values={{ firstName: this.state.newPasswordUser.firstName }}
              />
            }
            finalOk={<FormattedMessage id="global.login" />}
            token={this.state.newPasswordToken}
            user={this.state.newPasswordUser}
            userType={this.state.newPasswordUserType}
            onDone={() => {
              this.handleNewPasswordClose();
              this.handleOpen(AUTH_EMAIL_AND_PASSWORD_STAGE);
            }}
            onClose={this.handleNewPasswordClose}
          />
        )}

        {this.state.restorePassword && (
          <NewPasswordForm
            token={this.state.newPasswordToken}
            user={this.state.newPasswordUser}
            userType={this.state.newPasswordUserType}
            onDone={this.handleNewPasswordClose}
            onClose={this.handleNewPasswordClose}
          />
        )}

        {this.state.newAccountToken && (
          <NewAccountForm
            token={this.state.newAccountToken}
            email={this.state.newAccountEmail}
            onDone={this.handleNewAccountDone}
            onClose={this.handleNewAccountClose}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    makeInitialized: () => dispatch(makeInitialized()),
    makeNonInitialized: () => dispatch(makeNonInitialized()),
    onLogin: (data, onReady) => dispatch(signIn(data, onReady)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(LoginScene))
);
