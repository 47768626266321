export default {
  requestError: {
    backgroundColor: 'red',
    color: '#ffffff',
    marginBottom: 20,
    marginLeft: 20,
    marginRight: 20,
  },

  login: {
    padding: '0 100px',
  },
  dialogContent: {
    minWidth: 300,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 30,
    marginBottom: 30,
    paddingTop: 20,
    paddingBottom: 20,
  },
  loginWrapper: {
    textAlign: 'center',
  },
  userNameWrapper: {
    marginTop: 30,
    marginBottom: 40,
  },
  passwordWrapper: {
    marginBottom: 20,
  },
  preThirdButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
  },
  button: {
    width: '100%',
    height: 43,
    textTransform: 'none',
  },
};