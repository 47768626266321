import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AdminDialogTitle from '../AdminDialog/AdminDialogTitle';
import AdminDialogContent from '../AdminDialog/AdminDialogContent';
import AdminDialogControls from '../AdminDialog/AdminDialogControls';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import AdminDialog from '../AdminDialog/AdminDialog';
import { Spinner } from '../../components';
import { COLORS } from '../../helpers';
import moment from 'moment';
import PrimaryInlineDatePicker from '../PrimaryInlineDatePicker/PrimaryInlineDatePicker';

const styles = {
  wrapper: {},
  message: {
    textAlign: 'center',
    fontSize: 18,
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.text,
    marginBottom: 40,
    whiteSpace: 'pre-line',
  },
  button: {
    minWidth: '40%',
    textTransform: 'uppercase',
  },
  mr20: {
    marginRight: 10,
  },
  controls: {
    margin: '27px 0 0 0',
    textAlign: 'center',
  },
  control: {
    padding: '0 105px',
  },
};

const propTypes = {
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  reloadTable: PropTypes.func,

  label: PropTypes.string,
  buttonText: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  completeButtonText: PropTypes.string,
  completeTitle: PropTypes.string,
  completeMessage: PropTypes.string,
  errorButtonText: PropTypes.string,
  errorTitle: PropTypes.string,
};
const defaultProps = {
  label: 'prePurchase.dateModal.updateDatesLabel',
  buttonText: 'prePurchase.dateModal.updateDatesButton',
  title: 'prePurchase.dateModal.updateDatesTitle',
  message: 'prePurchase.dateModal.updateDatesText',
  completeButtonText: 'global.ok',
  completeTitle: 'prePurchase.dateModal.completedTitle',
  completeMessage: 'prePurchase.dateModal.completedText',
  errorButtonText: 'global.ok',
  errorTitle: 'prePurchase.dateModal.errorTitle',
};

const STEP_ACTION = 1;
const STEP_COMPLETE = 2;
const STEP_ERROR = 3;

export const SELECT_DATES_DIALOG_STEPS = {
  STEP_ACTION,
  STEP_COMPLETE,
  STEP_ERROR,
};

const SelectDatesDialog = ({
  onClose,
  intl,
  classes,
  loading,
  onConfirm,

  buttonText,
  title,
  message,
  completeButtonText,
  completeTitle,
  completeMessage,
  errorButtonText,
  errorTitle,
  label,
  reloadTable,
}) => {
  const [step, setStep] = useState(STEP_ACTION);
  const [date, setDate] = useState(moment());
  const [error, setError] = useState('');

  const getTitle = () => {
    switch (step) {
      case STEP_ACTION: {
        return intl.formatMessage({ id: title });
      }
      case STEP_COMPLETE: {
        return intl.formatMessage({ id: completeTitle });
      }
      case STEP_ERROR: {
        return intl.formatMessage({ id: errorTitle });
      }
      default: {
        return intl.formatMessage({ id: title });
      }
    }
  };

  const getButton = () => {
    switch (step) {
      case STEP_ACTION: {
        return (
          <PrimaryButton className={classes.button} onClick={onActionFinish}>
            {intl.formatMessage({ id: buttonText })}
          </PrimaryButton>
        );
      }
      case STEP_COMPLETE: {
        return (
          <PrimaryButton className={classes.button} onClick={_onClose}>
            {intl.formatMessage({ id: completeButtonText })}
          </PrimaryButton>
        );
      }
      case STEP_ERROR: {
        return (
          <PrimaryButton className={classes.button} onClick={_onClose}>
            {intl.formatMessage({ id: errorButtonText })}
          </PrimaryButton>
        );
      }
      default: {
        return (
          <PrimaryButton className={classes.button} onClick={onConfirm}>
            {intl.formatMessage({ id: buttonText })}
          </PrimaryButton>
        );
      }
    }
  };

  const onActionFinish = () => {
    const formattedDate = date.toISOString(true);
    onConfirm(formattedDate, (step, error) => {
      setStep(step);
      if (error) {
        setError(error);
      }
    });
  };

  const getContent = () => {
    switch (step) {
      case STEP_ACTION: {
        return actionContent();
      }
      case STEP_COMPLETE: {
        return <p className={classes.message}>{intl.formatMessage({ id: completeMessage })}</p>;
      }
      case STEP_ERROR: {
        return <p className={classes.message}>{error}</p>;
      }
      default: {
        return actionContent();
      }
    }
  };

  const actionContent = () => {
    return (
      <div>
        <p className={classes.message}>{intl.formatMessage({ id: message })}</p>

        <PrimaryInlineDatePicker
          fullWidth
          label={intl.formatMessage({ id: label })}
          value={date}
          onChange={(value) => setDate(value)}
        />
      </div>
    );
  };

  const _onClose = () => {
    if (step === STEP_ACTION) {
      onClose();
    } else {
      reloadTable && reloadTable();
    }
  };

  return (
    <AdminDialog open closeButton onClose={_onClose}>
      <AdminDialogTitle title={getTitle()} />
      <AdminDialogContent className={classes.wrapper}>
        {loading && <Spinner size={50} />}

        {getContent()}

        <AdminDialogControls>{getButton()}</AdminDialogControls>
      </AdminDialogContent>
    </AdminDialog>
  );
};

SelectDatesDialog.propTypes = propTypes;
SelectDatesDialog.defaultProps = defaultProps;

export default withStyles(styles)(injectIntl(SelectDatesDialog));
