import moment from 'moment'
import {
  DATE_FILTER
} from '../FilterTypes'
import {
  FILTER_THIS_WEEK,
  FILTER_LAST_MONTH,
  FILTER_PAST_THREE_MONTH,
} from '../Views/DXCSDateFilterView/DXCSDateFilterViewConstants'

export default class DXCSCustomerAllowCreditFilter {

  static getConfig(intl, values, readOnly, title, property) {
    return {
      name: DATE_FILTER,
      title: title,
      property: property,
      type: DATE_FILTER,
      selectedValues: values,
      clientSide: true,
      readOnly: readOnly ? readOnly : false
    }
  }

  constructor(values, propertyName) {
    this.values = values;
    this.property = propertyName;
  }

  isSatisfy(row) {
    let satisfy = true;

    if (!row[this.property]) {
      return false
    }

    if (this.values.length) {
      let filter = this.values[0];
      if (filter.from && filter.to) {
        let from = moment(filter.from);
        let to = moment(filter.to);
        satisfy = moment(row[this.property]).isBetween(from, to);
      }

      if (filter.predefined) {
        if (filter.predefined === FILTER_THIS_WEEK) {
          let startOfWeek = moment().startOf('week');
          satisfy = moment(row[this.property]).isAfter(startOfWeek)
        }

        if (filter.predefined === FILTER_LAST_MONTH) {
          let startOfMonth = moment().startOf('month');
          satisfy = moment(row[this.property]).isAfter(startOfMonth)
        }

        if (filter.predefined === FILTER_PAST_THREE_MONTH) {
          let minusThreeMonth = moment().subtract(3, 'month');
          satisfy = moment(row[this.property]).isAfter(minusThreeMonth)
        }
      }
    }

    return satisfy;
  }

  getLabel(intl) {
    let value = "";
    if (this.values.length) {
      let filter = this.values[0];

      if (filter.from || filter.to) {
        let parts = [];
        if (filter.from) {
          parts.push(moment(filter.from).format("L"));
        }

        if (filter.to) {
          parts.push(moment(filter.to).format("L"));
        }

        value = parts.join('—');
      }

      if (filter.predefined) {
        value = intl.formatMessage({id: `filter.date.${filter.predefined}`});
      }

      return value;
    }
  }

}