import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import {
  apiEditSubscriptionById,
  apiGetSubscriptionSchedule,
  apiChangeSubscriptionScheduleDateById,
  apiMoveSeriesSubscriptionScheduleDatesById,
  apiDeleteSubscriptionScheduleDateById,
} from '../../api';
import TableCheckbox from '../../components/AdminDXTable/TableCheckbox';
import { PERIOD_BI_WEEK, PERIOD_PER_WEEK, toPaper } from '../../helpers';
import { FormattedMessage } from 'react-intl';
import CellAsScheduler from '../../components/AdminDXTable/Editing/CellAsScheduler';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';

export const CellRenderer = (reload, { row, ...props }, rows, onUpdateRows) => {
  if (props.column.name === 'name') {
    return <Table.Cell {...props}>{(!!row?.name && row.name.toString()) || ''}</Table.Cell>;
  }

  if (props.column.name === 'amount') {
    return <Table.Cell {...props}>{row.amount && toPaper(row.amount)}</Table.Cell>;
  }

  if (props.column.name === 'period') {
    return <Table.Cell {...props}>{row.period && <FormattedMessage id={`global.${row.period.type}`} />}</Table.Cell>;
  }

  if (props.column.name === 'weekday') {
    return (
      <Table.Cell {...props}>{row.period && <FormattedMessage id={`global.day.${row.period.weekday}`} />}</Table.Cell>
    );
  }

  if (props.column.name === 'locationName') {
    return <Table.Cell {...props}>{row.market && row.market.name}</Table.Cell>;
  }

  if (props.column.name === 'type') {
    return <Table.Cell {...props}>{!!row.type ? <FormattedMessage id={`global.${row.type}`} /> : '-'}</Table.Cell>;
  }

  // if (props.column.name === 'nextDate') {
  //   return <Table.Cell {...props}>{moment(row.nextDate).format('MMM D, Y') || '-'}</Table.Cell>;
  // }

  if (props.column.name === 'tags') {
    if (!row?.tags?.length) return <Table.Cell {...props}>{'-'}</Table.Cell>;

    return (
      <Table.Cell {...props}>
        {row.tags.map((tag) => (
          <Chip key={tag} label={tag} style={{ margin: 5 }} />
        ))}
      </Table.Cell>
    );
  }

  if (props.column.name === 'nextDate') {
    return (
      <CellAsScheduler
        value={row.nextDate || null}
        style={{ margin: 0, width: 'auto' }}
        cellProps={props}
        forceReloadTable={reload}
        strictMode
        minDate={moment().add(1, 'days')}
        apiRetrieve={(onSuccess, onError) => apiGetSubscriptionSchedule(row.subscriptionId, onSuccess, onError)}
        onFinishCellEditing={(value, onSuccess, onError) => {
          console.log('onFinishCellEditing', value, row);

          // apiEditSubscriptionById(row.subscriptionId, { nextDate: moment(value).toISOString(true) }, onSuccess, onError);
        }}
        onDateChange={(data, onSuccess, onError) => {
          apiChangeSubscriptionScheduleDateById(row.subscriptionId, data, onSuccess, onError);
        }}
        onDateMoveSeries={(data, onSuccess, onError) => {
          apiMoveSeriesSubscriptionScheduleDatesById(row.subscriptionId, data, onSuccess, onError);
        }}
        onDateDelete={(data, onSuccess, onError) => {
          apiDeleteSubscriptionScheduleDateById(row.subscriptionId, data, onSuccess, onError);
        }}
      />
    );
  }

  if (props.column.name === 'active') {
    return getActiveCell(row, props, rows, onUpdateRows);
  }

  return <Table.Cell {...props} />;
};

const getActiveCell = (row, props, rows, onUpdateRows) => {
  const turnBackOnError = (status) => {
    for (let i = 0; i < rows.length; i++) {
      if (row.subscriptionId === rows[i].subscriptionId) {
        rows[i].active = status;
      }
    }
    onUpdateRows(rows);
  };

  const onChangeActivity = (active) => {
    apiEditSubscriptionById(
      row.subscriptionId,
      {
        active,
      },
      undefined,
      () => {
        turnBackOnError(!active);
      }
    );
  };

  return (
    <Table.Cell {...props}>
      <TableCheckbox onChange={onChangeActivity} checked={row.active} />
    </Table.Cell>
  );
};

export function summaryCalculator(type, rows) {
  if (type === 'count') {
    let weekly = 0,
      biweekly = 0;

    rows.forEach((row) => {
      if (row?.period?.type === PERIOD_PER_WEEK) {
        weekly = ++weekly;
      }
      if (row?.period?.type === PERIOD_BI_WEEK) {
        biweekly = ++biweekly;
      }
    });

    return { weekly, biweekly, total: rows.length };
  }

  return '-';
}

export function prepareRowsAfterLoadGroupedSubType(rows) {
  let result = [];

  rows.forEach((row) => {
    const productType = row?.productType?.name;
    const productSubType = `${row?.productType?.name}|${row?.productSubType?.name}`;

    if (!result.includes(productType)) {
      result.push(row?.productType?.name);
    }
    if (!result.includes(productSubType)) {
      result.push(productSubType);
    }
  });

  return {
    expandedRowIds: result,
    expandedGroups: result,
    rows: rows,
  };
}
