import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import DXSelectFilter from '../DXSelectFilter/DXSelectFilter'
import DXFilterConfig from '../DXFilterConfig'
import constants from '../../constants'

const propTypes = {
  equality: PropTypes.string,
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  selected: [],
};

class DXOrderTypeFilter extends React.Component {
  
  render() {
    const { selected, onChange, intl, equality} = this.props;
    const config = DXOrderTypeFilter.getConfig(intl, equality);
    return (
      <DXSelectFilter selected={selected}
                      title={config.title}
                      values={config.values}
                      onChange={onChange}
                      multi={config.multi}
      />
    )
  }
}

DXOrderTypeFilter.propTypes = propTypes;
DXOrderTypeFilter.defaultProps = defaultProps;
DXOrderTypeFilter.getLabel = (intl, selectedValues) => {
  return DXSelectFilter.getLabel(intl, 'filter.orderType.', selectedValues);
};
DXOrderTypeFilter.applyToQueryParams = (queryParams, selectedValues) => {
  queryParams['orderType'] = Array.isArray(selectedValues) ?
    selectedValues[0] : 'all';
  return queryParams;
};
DXOrderTypeFilter.getConfig = (intl, equality) => {
  let config = new DXFilterConfig({
    name: 'orderType',
    selected: [],
    property: 'orderType',
    title: intl.formatMessage({id: 'filter.orderType'}),
    disabled: false,
    equalityStatus: false,
    multi: false
  });

  if (equality === constants.FILTER_EQUALITY_EQUALS) {
    config.addFilterValue('all', intl.formatMessage({id: 'filter.orderType.all'}));
    config.addFilterValue('subscription', intl.formatMessage({id: 'filter.orderType.subscription'}));
    config.addFilterValue('standard', intl.formatMessage({id: 'filter.orderType.standard'}));
  }

  return config;
};

export default injectIntl(
  DXOrderTypeFilter
);


