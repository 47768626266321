export default ({
  controls: {
    margin: '27px 0 0 0',
    textAlign: 'center'
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row'
  },
  column: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  control: {
    padding: '0 105px'
  },
});
