import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AdminDialogTitle from '../../components/AdminDialog/AdminDialogTitle';
import AdminDialogContent from '../../components/AdminDialog/AdminDialogContent';
import AdminDialogControls from '../../components/AdminDialog/AdminDialogControls';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import AdminDialog from '../../components/AdminDialog/AdminDialog';
import { ErrorBox, SchedulePicker, Spinner, VariantsSuggestions } from '../../components';
import { COLORS, formatDate } from '../../helpers';
import moment from 'moment';
import classNames from 'classnames';
import Chip from '@material-ui/core/Chip';
import { apiMoveAllSubscriptionsScheduleDate, apiMoveAllSubscriptionsScheduleDateForLineItem } from '../../api';

const styles = (theme) => ({
  paper: {
    padding: 40,
    borderRadius: 8,
    maxHeight: 'calc(100% - 16px)',
    maxWidth: 1000,
    [theme.breakpoints.down('md')]: {
      margin: 10,
      minWidth: 344,
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 580,
    },
  },
  wrapper: {},
  message: {
    textAlign: 'center',
    fontSize: 18,
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.text,
    marginBottom: 40,
    whiteSpace: 'pre-line',
  },
  mr20: {
    marginRight: 10,
  },
  controls: {
    margin: '27px 0 0 0',
    textAlign: 'center',
  },
  control: {
    padding: '0 105px',
  },
  checkBoxWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
  },

  textField: {
    margin: '10px',
    width: 'calc(50% - 20px)',
  },
  chipsWrap: {
    width: '100%',
    margin: 10,
  },
  chip: {
    margin: '5px 5px 5px 0',
  },
  fullWidthTextInput: {
    width: '100%',
    paddingRight: 20,
  },
  moveFromDay: {
    '& > button': {
      border: '1px solid #1F63DE',
    },
  },
  dateMovingTextWrapper: {
    marginTop: 20,
    marginBottom: 20,
  },
  dateMovingText: {
    textAlign: 'center',
    fontSize: 18,
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.text,
    whiteSpace: 'pre-line',
  },
  contentWrapper: {
    width: 830,
    margin: 'auto',
  },
  buttonsWrapper: {
    marginTop: 30,
  },
  errorBoxWrapper: {
    width: 840,
    margin: '0px auto 20px auto',
  },

  pickersWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: 20,
  },
  pickerHeader: {
    textAlign: 'center',
    fontSize: 18,
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.text,
  },
  pickerFrom: {},
  pickerTo: {},

  buttonRoot: {
    boxShadow: 'none',
    width: 'auto',
    minWidth: 'auto',
  },
  button: {
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: 18,
    fontFamily: 'Inter, sans-serif',
  },
});

const propTypes = {
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  reloadTable: PropTypes.func,
  title: PropTypes.string,
  titleVariant: PropTypes.string,
  completeButtonText: PropTypes.string,
  completeTitle: PropTypes.string,
  completeMessage: PropTypes.string,
  completeMessageVariant: PropTypes.string,
  moveVariant: PropTypes.string,
};
const defaultProps = {
  completeButtonText: 'subscription.dateModal.move',
  title: 'subscription.dateModal.title',
  titleVariant: 'subscription.dateModal.titleForVariant',
  completeTitle: 'subscription.dateModal.completeTitle',
  completeMessageVariant: 'subscription.dateModal.completeMessageVariant',
  completeMessage: 'subscription.dateModal.completeMessage',
};

const STEP_ACTION = 1;
const STEP_COMPLETE = 2;

export const SELECT_DATES_DIALOG_STEPS = {
  STEP_ACTION,
  STEP_COMPLETE,
};

export const SELECT_DATES_DIALOG_MOVING_VARIANTS = {
  FOR_VARIANT: 'variant',
  FOR_SUBSCRIPTION: 'subscription',
};

const ChangeSubscriptionsDatesDialog = ({
  onClose,
  intl,
  classes,
  loading,
  title,
  titleVariant,
  completeButtonText,
  completeTitle,
  completeMessage,
  completeMessageVariant,
  reloadTable,
  datePickerProps,
  moveVariant,
}) => {
  const [step, setStep] = useState(STEP_ACTION);
  const isForVariant = moveVariant === SELECT_DATES_DIALOG_MOVING_VARIANTS.FOR_VARIANT;
  const [selectedProductVariant, setSelectedProductVariant] = useState(null);
  const [error, setError] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [fromDate, setFromDate] = useState(moment());
  const [toDate, setToDate] = useState(moment().add(1, 'day'));

  const getTitle = () => {
    switch (step) {
      case STEP_ACTION: {
        return intl.formatMessage({ id: isForVariant ? titleVariant : title });
      }
      case STEP_COMPLETE: {
        return intl.formatMessage({ id: completeTitle });
      }
      default: {
        return intl.formatMessage({ id: isForVariant ? titleVariant : title });
      }
    }
  };

  const actionButton = () => {
    return (
      <div className={classes.buttonWrapper}>
        {!!error && <ErrorBox error={error} className={classes.errorBoxWrapper} />}
        <PrimaryButton
          disabled={!!isForVariant && !selectedProductVariant?.variant?.id}
          className={classes.buttonRoot}
          labelClass={classes.button}
          onClick={onDateMoveSeriesFinish}
          isLoading={buttonLoading}>
          {intl.formatMessage({ id: completeButtonText })}
        </PrimaryButton>
      </div>
    );
  };
  const getButton = () => {
    switch (step) {
      case STEP_ACTION: {
        return actionButton();
      }
      case STEP_COMPLETE: {
        return (
          <PrimaryButton className={classes.button} onClick={_onClose}>
            {intl.formatMessage({ id: completeButtonText })}
          </PrimaryButton>
        );
      }
      default: {
        return actionButton();
      }
    }
  };

  const onDateMoveSeriesFinish = () => {
    if (moment(fromDate).isSame(toDate, 'day')) {
      setError(intl.formatMessage({ id: 'subscription.dateModal.errorDatesTheSame' }));

      return;
    }

    setError('');
    setButtonLoading(true);
    const data = {
      fromDate: moment(fromDate).startOf('day'),
      toDate: moment(toDate).startOf('day'),
    };
    const variantId = selectedProductVariant?.variant?.id;

    if (isForVariant) {
      apiMoveAllSubscriptionsScheduleDateForLineItem(variantId, data, onDone, onError);
    } else {
      apiMoveAllSubscriptionsScheduleDate(data, onDone, onError);
    }
  };

  const onDone = () => {
    setStep(SELECT_DATES_DIALOG_STEPS.STEP_COMPLETE);
    setButtonLoading(false);
  };

  const onError = (error) => {
    setButtonLoading(false);
    setError(error?.response?.data?.message || intl.formatMessage({ id: 'messages.somethingWentWrong' }));
  };

  const getContent = () => {
    switch (step) {
      case STEP_ACTION: {
        return actionContent();
      }
      case STEP_COMPLETE: {
        return (
          <p className={classes.message}>
            {intl.formatMessage(
              { id: isForVariant ? completeMessageVariant : completeMessage },
              {
                fromDate: formatDate(fromDate),
                toDate: formatDate(toDate),
              }
            )}
          </p>
        );
      }
      default: {
        return actionContent();
      }
    }
  };

  const actionContent = () => {
    return (
      <div className={classes.contentWrapper}>
        {isForVariant && (
          <>
            <VariantsSuggestions
              selected={null}
              inputProps={{ className: classNames(classes.textField, classes.fullWidthTextInput) }}
              labelExtractor={(item) => `${item.name}`}
              inputLabel={intl.formatMessage({ id: 'subscription.dateModal.products' })}
              onDetach={() => {}}
              clearAfterSelect
              onAttach={(product, variant) => {
                setSelectedProductVariant({
                  product,
                  variant,
                });
              }}
            />
            {!!selectedProductVariant?.variant?.id && (
              <Chip
                classes={{ root: classes.chip }}
                key={selectedProductVariant.variant.id}
                label={`${selectedProductVariant.product.name || selectedProductVariant.variant.name}`}
                onDelete={() => setSelectedProductVariant(null)}
                className={classes.chip}
              />
            )}
          </>
        )}

        <div className={classes.dateMovingTextWrapper}>
          <p className={classes.dateMovingText}>
            {!isForVariant && !!fromDate && !!toDate && (
              <>
                {intl.formatMessage(
                  { id: 'subscription.dateModal.moveDate' },
                  {
                    fromDate: formatDate(fromDate),
                    toDate: formatDate(toDate),
                  }
                )}
              </>
            )}
            {!!isForVariant && !!selectedProductVariant && !!fromDate && !!toDate && (
              <>
                {intl.formatMessage(
                  { id: 'subscription.dateModal.moveDateForVariant' },
                  {
                    fromDate: formatDate(fromDate),
                    toDate: formatDate(toDate),
                    productName: selectedProductVariant?.product?.name || '',
                    variantName: selectedProductVariant?.variant?.name || '',
                  }
                )}
              </>
            )}
          </p>
        </div>

        <div className={classes.pickersWrapper}>
          <div className={classes.pickerFrom}>
            <p className={classes.pickerHeader}>{intl.formatMessage({ id: 'global.from' })}:</p>

            <SchedulePicker
              className={classes.date}
              fullWidth
              value={fromDate}
              initialFocusedDate={fromDate}
              minDate={new Date()}
              onChange={(newValue) => {
                setFromDate(newValue);
              }}
              {...datePickerProps}
            />
          </div>
          <div className={classes.pickerTo}>
            <p className={classes.pickerHeader}>{intl.formatMessage({ id: 'global.to' })}:</p>

            <SchedulePicker
              className={classes.date}
              fullWidth
              value={toDate}
              initialFocusedDate={toDate}
              shouldDisableDate={(date) => {
                return moment(date).isSame(fromDate, 'day');
              }}
              minDate={new Date()}
              onChange={(newValue) => {
                setToDate(newValue);
              }}
              {...datePickerProps}
            />
          </div>
        </div>
      </div>
    );
  };

  const _onClose = () => {
    reloadTable && reloadTable();
    onClose();
  };

  return (
    <AdminDialog open closeButton onClose={_onClose} paperClass={classes.paper}>
      <AdminDialogTitle title={getTitle()} marginBottom={30} />
      <AdminDialogContent className={classes.wrapper}>
        {loading && <Spinner size={50} />}

        {getContent()}

        <AdminDialogControls className={classes.buttonsWrapper}>{getButton()}</AdminDialogControls>
      </AdminDialogContent>
    </AdminDialog>
  );
};

ChangeSubscriptionsDatesDialog.propTypes = propTypes;
ChangeSubscriptionsDatesDialog.defaultProps = defaultProps;

export default withStyles(styles)(injectIntl(ChangeSubscriptionsDatesDialog));
