import {COLORS} from '../../helpers'

export default {
  row : {
    display: 'flex',
    minWidth: 150,
    marginBottom: 10
  },
  title: {
    flex: 1,
    fontSize: 14,
    textAlign: 'left'
  },
  info: {
    flex: 1,
    fontSize: 14,
    textAlign: 'right'
  },
  green: {
    color: COLORS.text
  }
};