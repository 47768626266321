import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { capitalizeFirstLetter, FINANCIAL_STATUSES, toCurrency } from '../../helpers';

const propTypes = {
  containerClass: PropTypes.string,
  order: PropTypes.object.isRequired,
  subtotal: PropTypes.string.isRequired,
  shipping: PropTypes.string.isRequired,
  fulfillmentFee: PropTypes.string,
  discount: PropTypes.string,
  salesTax: PropTypes.string,
  total: PropTypes.string.isRequired,
};

const defaultProps = {
  containerStyles: {},
};

const OrderSummary = ({
  classes,
  subtotal,
  fulfillmentFee,
  total,
  order,
  salesTax,
  shipping,
  discount,
  containerClass,
  intl,
}) => {
  return (
    <div className={classNames(classes.container, containerClass)}>
      <div className={classes.row}>
        <span className={classes.title}>{intl.formatMessage({ id: 'global.subtotal' })}:</span>
        <span className={classes.info}>{subtotal}</span>
      </div>

      {!!discount && (
        <div className={classes.row}>
          <span className={classes.title}>{intl.formatMessage({ id: 'global.discount' })}:</span>
          <span className={classes.info}>{discount}</span>
        </div>
      )}

      {!!fulfillmentFee && (
        <div className={classes.row}>
          <span className={classes.title}>{intl.formatMessage({ id: 'order.fulfillmentFee' })}:</span>
          <span className={classes.info}>{fulfillmentFee}</span>
        </div>
      )}

      {!!shipping && (
        <div className={classes.row}>
          <span className={classes.title}>{intl.formatMessage({ id: 'global.delivery' })}:</span>
          <span className={classes.info}>{shipping}</span>
        </div>
      )}
      {!!salesTax && (
        <div className={classes.row}>
          <span className={classes.title}>{intl.formatMessage({ id: 'global.salesTax' })}:</span>
          <span className={classes.info}>{salesTax}</span>
        </div>
      )}

      {!!order.taxesTotal && (
        <div className={classes.row}>
          <span className={classNames(classes.title, classes.titleAlign)}>
            {intl.formatMessage({ id: 'global.taxes' })}:
          </span>
          <span className={classNames(classes.info)}>{toCurrency(order.taxesTotal, intl)}</span>
        </div>
      )}

      <div className={classes.row}>
        <span className={classes.title}>{intl.formatMessage({ id: 'global.total' })}:</span>
        <span className={classes.info}>{total}</span>
      </div>

      {!!order?.refundedAmount && (
        <div className={classes.row}>
          <span className={classes.title}>{intl.formatMessage({ id: 'global.refunded' })}:</span>
          <span className={classes.info}>{toCurrency(order.refundedAmount, intl)}</span>
        </div>
      )}

      {order.prepurchaseOrder && order.payment && (
        <React.Fragment>
          <div style={{ marginTop: 20 }} className={classes.row}>
            <span className={classNames(classes.title, classes.titleAlign)}>
              {intl.formatMessage({ id: 'global.payments' })}:
            </span>
            <span className={classNames(classes.title, classes.info)} />
          </div>
          <div className={classes.row}>
            <span className={classNames(classes.title, classes.titleAlign, classes.green)}>
              {intl.formatMessage({ id: 'order.csa' })}: {order.payment.name}
            </span>
            <span className={classNames(classes.info, classes.green)}>
              {toCurrency(order.payment.amountPrepurchased, intl)}
            </span>
          </div>

          {order.financialStatus === FINANCIAL_STATUSES.PAID && order.payment.method && order.payment.method.method && (
            <div className={classes.row}>
              <span className={classNames(classes.title, classes.titleAlign)}>
                {capitalizeFirstLetter(order.payment.method.method)}:
              </span>
              <span className={classNames(classes.info)}>{toCurrency(order.payment.amountPaidWithMethod, intl)}</span>
            </div>
          )}

          <div className={classes.row}>
            <span className={classNames(classes.title, classes.titleAlign)}>
              {intl.formatMessage({ id: 'global.amountDue' })}:
            </span>
            <span className={classes.info}>{toCurrency(order.amountDue, intl)}</span>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

OrderSummary.propTypes = propTypes;
OrderSummary.defaultProps = defaultProps;

export default withStyles(styles)(injectIntl(OrderSummary));
