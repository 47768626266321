import React from 'react';
import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

const materialTheme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: 'Inter, sans-serif',
    fontSize: 16,
    fontWeight: 400,
  },
  overrides: {
    MuiPickersBasePicker: {
      pickerView: {
        width: 400,
        maxWidth: 400,
        minHeight: 410,
        justifyContent: 'flex-start',
      },
    },
    MuiTypography: {
      body1: {
        fontSize: 18,
        fontWeight: 600,
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        width: 49,
      },
    },
    MuiPickersDay: {
      day: {
        width: 46,
        height: 46,
        margin: 3,
        color: '#1F63DE',
        fontFamily: 'Inter, sans-serif',
        fontSize: 16,
        fontWeight: 700,
      },
      daySelected: {
        color: '#fff !important',
        backgroundColor: '#216CF6 !important',
        fontWeight: 700,
        fontSize: 16,
        fontFamily: 'Inter, sans-serif',
      },
      dayDisabled: {
        backgroundColor: 'transparent',
        color: '#666666',
        fontWeight: 400,
        fontSize: 16,
        fontFamily: 'Inter, sans-serif',
      },
    },
  },
});

class LocalizedMomentUtils extends MomentUtils {
  getWeekdays = function () {
    return ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  };
}

const SchedulePicker = (props) => {
  const { variant = 'static' } = props;

  return (
    <MuiThemeProvider theme={materialTheme}>
      <MuiPickersUtilsProvider utils={LocalizedMomentUtils}>
        <DatePicker
          open
          variant={variant}
          leftArrowIcon={<KeyboardArrowLeftIcon />}
          rightArrowIcon={<KeyboardArrowRightIcon />}
          views={['date']}
          disableToolbar
          {...props}
        />
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};

export default SchedulePicker;
