export default {
  title: {
    fontSize: 20,
    color: '#212121',
    minHeight: 55
  },
  header: {},
  activeIcon: {},
  inactiveIcon: {
    opacity: 0.5
  },
  gridList: {
    width: '100%',
    height: 'auto',
    overflowX: 'hidden',
  },
  viewSwitcher: {
    float: 'right'
  }
};