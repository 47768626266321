import React from "react";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';

class LinkButton extends React.Component {
  render() {
    const {
      onClick, classes, text,
      className, iconComponent
    } = this.props;

    return (
      <div className={classes.container}>
        {iconComponent && iconComponent()}
        <button
           onClick={(e) => onClick && onClick(e)}
           className={classNames(classes.button, className)}>{text}</button>
      </div>
    );
  }
}

LinkButton.propTypes = {
  iconComponent: PropTypes.func,
  text: PropTypes.string,
  iconClassName: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.string
};

LinkButton.defaultProps = {
  className: ""
};

export default withStyles(styles)(LinkButton);