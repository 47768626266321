import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core/styles';
import EmptyImage from './assets/no.jpg';
import { capitalizeFirstLetter } from '../../helpers';

const propTypes = {
  onClick: PropTypes.func,
  product: PropTypes.object.isRequired,
};

const styles = {
  hitImage: {
    maxWidth: 100,
    marginRight: 10,
  },
  productName: {
    cursor: 'pointer',
    fontSize: 14,
    marginTop: 0,
    marginBottom: 5,
  },
  productInfo: {
    flex: 1,
    justifyContent: 'row',
    alignItems: 'flex-start',
  },
  text: {
    fontSize: 12,
    marginTop: 0,
    marginBottom: 5,
  },
  listItem: {
    cursor: 'pointer',
    minHeight: 30,
    alignItems: 'flex-start',
    borderBottom: '1px solid #e6e6e6',
    paddingLeft: 0,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.025)',
      transition: 'background-color 300ms ease',
    },
  },
};

const ProductAsListItem = ({ product, classes, onClick }) => {
  return (
    <ListItem onClick={() => onClick(product)} className={classes.listItem}>
      <img
        className={classes.hitImage}
        src={product.thumb ? product.thumb : product.image ? product.image : EmptyImage}
        alt={product.name}
      />
      <div className={classes.productInfo}>
        <h3 className={classes.productName}>{capitalizeFirstLetter(product.name)}</h3>

        {!!product?.supplier?.name && <p className={classes.text}>{product.supplier.name}</p>}
      </div>
    </ListItem>
  );
};

ProductAsListItem.propTypes = propTypes;

export default withStyles(styles)(ProductAsListItem);
