import axios from 'axios';
import { getRequestConfig, REQUEST_TYPES, replaceUrlPlaceholder, replaceUrlPlaceholders } from '../request';
import API_END_POINTS from '../endpoints';

/**
 * Fetch pre purchases
 * @param {id} id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiPrePurchaseRetrieve = function (id, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRE_PURCHASE_GET_ONE, 'id', id);
  const config = getRequestConfig(REQUEST_TYPES.GET, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Fetch pre purchases
 * @param {filters} filters
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiPrePurchasesRetrieve = function (filters, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRE_PURCHASE_GET_ALL);
  const config = getRequestConfig(REQUEST_TYPES.GET, url);
  config.params = filters;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Creates pre purchase
 * @param {data} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiPrePurchaseCreate = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.PRE_PURCHASE_CREATE);
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Creates pre purchase from CSA
 * @param {string} id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiPrePurchaseCreateFromCSA = function (id, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRE_PURCHASE_CREATE_FROM_CSA, 'id', id);
  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Patches pre purchase
 * @param {string} id
 * @param {data} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiPrePurchasePatch = function (id, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRE_PURCHASE_PATCH, 'id', id);
  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Deletes pre purchase
 * @param {string} id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiPrePurchaseDelete = function (id, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRE_PURCHASE_REMOVE, 'id', id);
  const config = getRequestConfig(REQUEST_TYPES.DEL, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Deletes pre purchase from variant
 * @param {string} id
 * @param {string} variantId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiPrePurchaseDeleteFromVariant = function (id, variantId, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.PRE_PURCHASE_REMOVE_FROM_VARIANT, {
    id: id,
    vr: variantId,
  });
  const config = getRequestConfig(REQUEST_TYPES.DEL, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Enable pre purchase
 * @param {string} id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiPrePurchaseEnable = function (id, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRE_PURCHASE_PATCH, 'id', id);
  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  config.data = { active: true };
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Disable pre purchase
 * @param {string} id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiPrePurchaseDisable = function (id, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRE_PURCHASE_PATCH, 'id', id);
  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  config.data = { active: false };
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Creates pre purchase tag
 * @param {string} id - prepurchaseId
 * @param {data} data
 * @param {string} data.tag
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiPrePurchaseTagCreate = function (id, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRE_PURCHASE_ADD_TAG, 'id', id);
  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Fetch pre purchase tags by prepurchaseId
 * @param {string} id - prepurchaseId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiPrePurchasesTagsRetrieve = function (id, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRE_PURCHASE_ADD_TAG, 'id', id);
  const config = getRequestConfig(REQUEST_TYPES.GET, url);

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Remove pre purchase tag
 * @param {string} id - prepurchaseId
 * @param {data} tag - string
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiPrePurchaseTagRemove = function (id, tag, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.PRE_PURCHASE_DELETE_TAG, { id, tag });
  const config = getRequestConfig(REQUEST_TYPES.DEL, url);

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * change next order date to selected prepurchases
 * @param {data} data
 * @param {array} data.prepurchases - (required)
 * @param {array} data.nextDate - (required)
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiPrePurchaseBulkSetNextDate = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.PRE_PURCHASE_BULK_SET_NEXT_DATE);
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Add tags to selected prepurchases
 * @param {data} data
 * @param {array} data.prepurchases - (required)
 * @param {array} data.tags - (required)
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiPrePurchaseBulkAddTags = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.PRE_PURCHASE_BULK_SET_TAGS);
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};
