import Discount from './Discount'

class LineItem {

  constructor(props ={}) {
    this.discount = props.discount ?
      new Discount(props.discount) : null;
  }

  hasDiscount () {
    return this.discount &&
      this.discount.applied
  }

}

export default LineItem
