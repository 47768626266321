import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from '../../hocs';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { AdminDXTable, ConfirmDialog, Tab, TabActions } from '../../components';
import { apiPrePurchaseDelete, apiPrePurchasesRetrieve } from '../../api';
import {
  COLORS,
  countWidthOfColumns,
  DEFAULT_MAX_CELL_WIDTH,
  DEFAULT_MIN_CELL_WIDTH,
  HEADER_HEIGHT_STANDARD,
  makeColumnWidthEntityArray,
} from '../../helpers';
import { getGridPageSize, getGridPageSizes } from '../../helpers/grid';
import { CellRenderer } from './helpers';
import { GroupByDialog, PrePurchasesFilterDialog, SaveTabSettingDialog, TabTypes } from '../../dialogs';
import {
  ACTIVITY_FILTER,
  CUSTOMER_ALLOW_CREDIT_FILTER,
  CUSTOMER_APPROVED_FILTER,
  CUSTOMER_DEFAULT_PAYMENT_METHOD_FILTER,
  CUSTOMER_TYPE_FILTER,
  CUSTOMER_TYPE_FILTER_FROM_VARIABLE,
  DATE_FILTER,
  HAS_SUBSTRING_FILTER,
  IMAGE_FILTER,
  IN_ARRAY_FILTER,
} from '../../components/AdminDXTable/ClientSideFilters/FilterTypes';
import PrePurchasesBatchControls from './PrePurchasesBatchControls';

const propTypes = {
  tab: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  columnExtensions: PropTypes.array,
  onlyActive: PropTypes.bool,
  withFilters: PropTypes.bool,
  allowCreateTab: PropTypes.bool,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onDeleteTab: PropTypes.func,
  onCreateTab: PropTypes.func,
  onPatchTab: PropTypes.func,
  onAddingFilter: PropTypes.func,
};

const defaultProps = {
  onlyActive: false,
};

const CLIENT_SIDE_FILTERS_TO_EXCLUDE = [
  CUSTOMER_APPROVED_FILTER,
  CUSTOMER_TYPE_FILTER,
  CUSTOMER_TYPE_FILTER_FROM_VARIABLE,
  CUSTOMER_ALLOW_CREDIT_FILTER,
  CUSTOMER_DEFAULT_PAYMENT_METHOD_FILTER,
  HAS_SUBSTRING_FILTER,
  IN_ARRAY_FILTER,
  ACTIVITY_FILTER,
  DATE_FILTER,
  IMAGE_FILTER,
];

class PrePurchasesContent extends React.Component {
  constructor(props) {
    super(props);
    const { onRef, tab, appliedFilters } = this.props;

    this.state = {
      loading: true,
      deleting: null,
      deletingLoading: false,
      hiddenColumnNames: tab.excludeColumns || [],
      tabDialog: false,
      isFiltersDialogActive: false,
      appliedFilters: this.prepareFilters(appliedFilters),
      openWithFilter: null,
      settings: {
        excludeColumns: tab.excludeColumns || [],
        filters: this.prepareFilters(appliedFilters),
      },

      columnWidths:
        makeColumnWidthEntityArray(tab.columnsWidth, props.columns) ||
        countWidthOfColumns(props.columns, tab.excludeColumns, props.columnExtensions),

      isGroupByDialogActive: false,
      rebuildingTable: false,
      groupBy: tab?.groupBy || [],
    };
    onRef && onRef(this);
  }

  prepareFilters = (filters) => {
    return filters.filter((filter) => !CLIENT_SIDE_FILTERS_TO_EXCLUDE.includes(filter.name));
  };

  onChangeColumnsState = (excludeColumns) => {
    this.setState((state) => {
      state.settings['excludeColumns'] = excludeColumns;
      return state;
    });
  };

  onDelete = () => {
    this.setState({ deletingLoading: true });
    apiPrePurchaseDelete(
      this.state.deleting.id,
      () => {
        this.setState({
          deleting: null,
          deletingLoading: false,
        });
        this.table.forceReload();
      },
      undefined
    );
  };

  forceTableReload = () => {
    this.table.forceReload();
  };

  rebuildTable = () => {
    this.setState({ rebuildingTable: true }, () => {
      this.setState({ rebuildingTable: false });
    });
  };

  onCreateTab = () => {
    this.setState({ tabDialog: true });
  };

  onAddFilter = () => {
    this.setState({ isFiltersDialogActive: true });
  };
  onGroupBy = () => {
    this.setState({ isGroupByDialogActive: true });
  };

  onUpdateTab = () => {
    const { tab, onPatchTab } = this.props;
    const { groupBy, columnWidths } = this.state;

    if (tab.id) {
      onPatchTab(tab, { ...this.state.settings, groupBy, columnsWidth: columnWidths });
    }
  };

  getFiltersDialog = (onClose) => {
    const { appliedFilters, selectedFilter } = this.state;

    return (
      <PrePurchasesFilterDialog
        onApplyFilters={(appliedFilters) => {
          this.handleFiltersApply(appliedFilters);
          onClose && onClose();
        }}
        onClose={() => this.setState({ selectedFilter: null }, () => onClose && onClose())}
        appliedFilters={JSON.parse(JSON.stringify(appliedFilters))}
        openWithFilter={selectedFilter}
      />
    );
  };

  getGroupedByDialog = () => {
    const { groupBy } = this.state;

    return (
      <GroupByDialog
        onClose={() => this.setState({ isGroupByDialogActive: null })}
        selected={groupBy}
        onDone={this.onChangeGroupBy}
      />
    );
  };

  onChangeGroupBy = (newGroupedBy) => {
    this.setState(
      {
        groupBy: newGroupedBy,
        isGroupByDialogActive: false,
      },
      this.rebuildTable
    );
  };

  handleFiltersApply = (appliedFilters) => {
    this.setState({
      settings: {
        excludeColumns: this.state.settings.excludeColumns || [],
        filters: appliedFilters,
      },
      appliedFilters: appliedFilters,
      addingFilter: false,
    });
  };

  onChangeSorting = (sorting) => {
    this.setState({
      sorting,
      settings: {
        ...this.state.settings,
        sorting,
      },
    });
  };

  handleFilterRemove = (removedFilter) => {
    this.setState((state) => {
      let appliedFilters = state.appliedFilters;
      let indexToDelete = appliedFilters.findIndex((appliedFilter) => appliedFilter.name === removedFilter.name);
      appliedFilters.splice(indexToDelete, 1);
      return { appliedFilters, settings: { ...this.state.settings, filters: appliedFilters } };
    });
  };

  getTableGroupByConfig = () => {
    const { groupBy } = this.state;

    if (!!groupBy?.length) {
      return {
        groupSummaryItems: [{ columnName: 'customer.name', showInGroupFooter: false, type: 'count' }],
        groupingColumns: groupBy.map((group) => ({ columnName: group })),
        prepareRowsAfterLoad: this.makeAllExpanded,
        allowCollapseAll: true,
      };
    }

    return {};
  };

  makeAllExpanded = (rows) => {
    const { intl } = this.props;
    let result = [];

    rows.forEach((row) => {
      if (!!row?.subscription?.location?.name && !result.includes(row?.subscription?.location?.name)) {
        result.push(row?.subscription?.location?.name);
      }
    });

    result.push(intl.formatMessage({ id: 'dialog.groupBy.noLocation' }));

    return { expandedRowIds: result, expandedGroups: result, rows: rows };
  };

  makeGroupOptions = () => {
    const { intl } = this.props;
    const { groupBy } = this.state;

    if (!groupBy?.length) {
      return [];
    }

    return groupBy.map((group) => ({
      key: group,
      title: intl.formatMessage({ id: `adminTable.chips.groupBy.${group}` }),
      value: group,
    }));
  };

  render() {
    const {
      tab,
      index,
      intl,
      columns,
      classes,
      history,
      allowCreateTab,
      withFilters,
      onDeleteTab,
      columnExtensions,
      onCreateTab,
    } = this.props;

    const {
      sorting,
      isFiltersDialogActive,
      tabDialog,
      appliedFilters,
      isGroupByDialogActive,
      groupBy,
      rebuildingTable,
      columnWidths,
    } = this.state;

    return (
      <Tab
        title={tab.name}
        key={`${index}-tab`}
        tab={tab}
        actions={
          <TabActions
            tab={tab}
            classes={classes}
            columns={columns}
            labels={{ createEntity: intl.formatMessage({ id: 'prepurchases.new' }) }}
            appliedFilters={this.state.appliedFilters}
            withFilters={withFilters}
            allowCreateTab={allowCreateTab}
            onCreateTab={this.onCreateTab}
            onUpdateTab={this.onUpdateTab}
            onAddingFilter={withFilters ? this.onAddFilter : undefined}
            onDeleteTab={onDeleteTab}
            onCreateEntity={() => history.push(`${this.props.match.url}/new`)}
            onChangeColumnsState={this.onChangeColumnsState}
            onGroupBy={this.onGroupBy}
          />
        }>
        {!rebuildingTable && (
          <AdminDXTable
            enableRemoteSearchWithFilters={true}
            batchControlsComponent={PrePurchasesBatchControls}
            onRef={(table) => (this.table = table)}
            serverSideFilters={JSON.parse(JSON.stringify(appliedFilters))}
            onRemoveFilter={this.handleFilterRemove}
            onOpenWithFilter={(index, filter) =>
              this.setState({
                isFiltersDialogActive: true,
                selectedFilter: filter,
              })
            }
            pageSize={getGridPageSize()}
            pageSizes={getGridPageSizes()}
            columnExtensions={columnExtensions}
            hiddenColumnNames={this.state.settings.excludeColumns || []}
            actionsHeaderCellStyle={{ textAlign: 'center' }}
            actionsCellStyle={{ textAlign: 'center' }}
            onChangeSorting={this.onChangeSorting}
            sorting={tab.sorting || sorting}
            appliedFilters={tab.filters}
            actions={[
              {
                icon: <EditIcon style={{ color: COLORS.text, fontSize: 18 }} />,
                action: (row) => history.push(`${this.props.match.url}/${row.id}${this.props.location.hash}`),
              },
              {
                icon: <DeleteIcon style={{ color: COLORS.violet, fontSize: 18 }} />,
                action: (row) => this.setState({ deleting: row }),
              },
            ]}
            allowExpandAll={true}
            stickyHeader
            stickyHeaderOffset={HEADER_HEIGHT_STANDARD.extended}
            onColumnWidthsChange={(widths) => {
              this.setState({ columnWidths: widths });
            }}
            minColumnWidth={DEFAULT_MIN_CELL_WIDTH}
            maxColumnWidth={DEFAULT_MAX_CELL_WIDTH}
            columnWidths={this.state.columnWidths}
            groupByOptions={this.makeGroupOptions()}
            onRemoveGroupBy={() => this.onChangeGroupBy('')}
            onOpenWithGroupBy={this.onGroupBy}
            enableSelection
            cellRenderer={CellRenderer}
            apiRetrieve={(filters, onSuccess, onError) =>
              // Disable all pre purchases with subscriptions
              apiPrePurchasesRetrieve(
                filters,
                (items) => {
                  onSuccess(items.filter((item) => !item?.subscription));
                },
                onError
              )
            }
            columns={columns}
            {...this.getTableGroupByConfig()}
          />
        )}

        {this.state.deleting && (
          <ConfirmDialog
            onClose={() => this.setState({ deleting: null })}
            loading={this.state.deletingLoading}
            confirmTitle={'confirm.deletePrepurchase'}
            title={intl.formatMessage({ id: 'titles.deletePrepurchase' })}
            message={intl.formatMessage({ id: 'messages.confirmDeletePrepurchase' })}
            onConfirm={this.onDelete}
          />
        )}

        {isFiltersDialogActive && this.getFiltersDialog(() => this.setState({ isFiltersDialogActive: false }))}
        {isGroupByDialogActive && this.getGroupedByDialog()}

        {tabDialog && (
          <SaveTabSettingDialog
            onClose={() => this.setState({ tabDialog: false })}
            filters={appliedFilters.slice()}
            excludeColumns={this.state.settings.excludeColumns || []}
            groupBy={groupBy}
            columnWidths={columnWidths}
            sorting={this.state.sorting}
            onSave={(data) => onCreateTab(data, () => this.setState({ tabDialog: false }))}
            type={TabTypes.PREPURCHASES}
          />
        )}
      </Tab>
    );
  }
}

PrePurchasesContent.propTypes = propTypes;
PrePurchasesContent.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  return {
    producer: state.producer.object,
  };
};

export default injectIntl(withRouter(connect(mapStateToProps)(PrePurchasesContent)));
