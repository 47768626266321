import React from 'react'
import PropTypes from 'prop-types'
import {Table} from '@devexpress/dx-react-grid-material-ui'
import {injectIntl} from 'react-intl'
import {withStyles} from '@material-ui/core/styles'
import {capitalizeFirstLetter, COLORS} from '../../../helpers'

const styles = ({
  shippingMethod: {
    fontSize: 12,
  },
  deliveryCompany: {
    fontSize: 12,
    fontWeight: 600
  },
  error: {
    display: 'block',
    color: COLORS.violet,
    fontSize: 12
  }
});

const propTypes = {
  row: PropTypes.object.isRequired,
};

const PaymentStatusCell = ({intl, row, classes, ...other}) =>  {
  return (
    <Table.Cell style={{whiteSpace: 'normal'}} {...other}>
      {!!row.financialStatus && capitalizeFirstLetter(row.financialStatus)}
      <span className={classes.error}>
        {row.payment && row.payment.method && row.payment.method.error && (
          row.payment.method.error
        )}
      </span>

    </Table.Cell>
  );
};

PaymentStatusCell.propTypes = propTypes;

export default withStyles(styles)(
  injectIntl(PaymentStatusCell)
);
