export const CUSTOMER_APPROVED_FILTER = 'CUSTOMER_APPROVED_FILTER';
export const CUSTOMER_TYPE_FILTER = 'CUSTOMER_TYPE_FILTER';
export const CUSTOMER_TYPE_FILTER_FROM_VARIABLE = 'CUSTOMER_TYPE_FILTER_FROM_VARIABLE';
export const CUSTOMER_ALLOW_CREDIT_FILTER = 'CUSTOMER_ALLOW_CREDIT_FILTER';
export const CUSTOMER_DEFAULT_PAYMENT_METHOD_FILTER = 'CUSTOMER_DEFAULT_PAYMENT_METHOD_FILTER';
export const HAS_SUBSTRING_FILTER = 'HAS_SUBSTRING_FILTER';
export const IN_ARRAY_FILTER = 'IN_ARRAY';
export const ACTIVITY_FILTER = 'ACTIVITY_FILTER';
export const DATE_FILTER = 'DATE_FILTER';
export const IMAGE_FILTER = 'IMAGE_FILTER';
