import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { ConfirmDialog, Icon, PrimaryButton, SchedulePicker } from '../../components';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import { ScheduleDates, ScheduleVariant } from '../../entities';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import ErrorBox from '../../components/ErrorBox/ErrorBox';
import styles from './styles';

const COMPARISON_DATES_FORMAT = 'YYYY-MM-DD';

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  apiRetrieve: PropTypes.func,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  isScheduleExist: PropTypes.bool, // If api loading is success = true
  scheduleData: PropTypes.instanceOf(ScheduleDates),
  onDone: PropTypes.func,
  onClose: PropTypes.func,
  datePickerProps: PropTypes.object,
  onDateMoveSeries: PropTypes.func,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  forVariant: PropTypes.bool,
  variantId: PropTypes.string,
  onUpdateScheduleData: PropTypes.func,
  onDateDelete: PropTypes.func,
};

const MOVE_ACTION_TYPES = {
  MOVE_SINGLE_DATE: 'single',
  MOVE_SERIES_OF_DATES: 'series',
};

const ScheduleDialog = (props) => {
  const {
    intl,
    classes,
    datePickerProps,
    onClose,
    onDone,
    scheduleData = new ScheduleDates({}),
    isScheduleExist,
    loading,
    value,
    onChange,
    onDateMoveSeries,
    onDateChange,
    minDate,
    forVariant,
    variantId,
    onUpdateScheduleData,
    onDateDelete,
    absolutePicker,
  } = props;

  const [localScheduleData, setLocalScheduleData] = useState(scheduleData);
  const [localValue, setLocalValue] = useState(value);
  const [updateCounter, setUpdateCounter] = useState(0);
  const [localLoading, setLocalLoading] = useState(false);
  const initialMovingState = {
    isDateMoving: false,
    fromDate: '',
    toDate: '',
    moveActionType: MOVE_ACTION_TYPES.MOVE_SINGLE_DATE,
  };
  const [showVariantSchedule, setShowVariantSchedule] = useState(false);
  const [scheduleVariantData, setScheduleVariantData] = useState(new ScheduleVariant({}));
  const [dateMovingState, setDateMovingState] = useState(initialMovingState);
  const [confirmDeleteDate, setConfirmDeleteDate] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { isDateMoving } = dateMovingState;
  const [localError, setLocalError] = useState('');
  const nextDate = getNextDate();

  useEffect(() => {
    if (!loading) {
      setLocalScheduleData(scheduleData);
      setLocalValue(value);

      if (forVariant) {
        setShowVariantSchedule(true);
        setScheduleVariantData(new ScheduleVariant(scheduleData.getVariantById(variantId)));
      }
      setUpdateCounter(updateCounter + 1);
    }
  }, [loading]);

  const onDateMoveSeriesStart = (moveActionType) => {
    setLocalError('');

    setDateMovingState({
      isDateMoving: true,
      fromDate: moment(localValue, moment.ISO_8601),
      toDate: '',
      moveActionType: moveActionType || MOVE_ACTION_TYPES.MOVE_SINGLE_DATE,
    });
  };

  const onDateMoveSeriesChange = (toDate) => {
    setDateMovingState((state) => ({
      ...state,
      toDate,
    }));
  };

  const onDateMoveSeriesFinish = () => {
    const { moveActionType } = dateMovingState;
    const apiAction = moveActionType === MOVE_ACTION_TYPES.MOVE_SINGLE_DATE ? onDateChange : onDateMoveSeries;

    apiAction(
      dateMovingState,
      (response) => {
        const newScheduleData = new ScheduleDates(response);
        onUpdateScheduleData && onUpdateScheduleData(newScheduleData);
        setDateMovingState(initialMovingState);
        setLocalValue(dateMovingState.toDate);
        setLocalScheduleData(newScheduleData);

        if (forVariant) {
          setShowVariantSchedule(true);
          setScheduleVariantData(new ScheduleVariant(newScheduleData.getVariantById(variantId)));
        }
        setUpdateCounter(updateCounter + 1);
      },
      (error) => {
        setDateMovingState(initialMovingState);
        onDoneError(error);
      }
    );
  };

  const onDateMoveSeriesCancel = () => {
    setDateMovingState(initialMovingState);
    forceReloadPicker();
  };

  const shouldDisableDate = useCallback(
    (date) => {
      const { moveActionType, fromDate } = dateMovingState;

      if (!isScheduleExist) {
        return false;
      }

      // Only future dates from selected are available to move
      if (moveActionType === MOVE_ACTION_TYPES.MOVE_SERIES_OF_DATES) {
        return moment(fromDate).format(COMPARISON_DATES_FORMAT) >= moment(date).format(COMPARISON_DATES_FORMAT);
      }

      if (isDateMoving) {
        return moment().format(COMPARISON_DATES_FORMAT) >= moment(date).format(COMPARISON_DATES_FORMAT);
      }

      if (showVariantSchedule) {
        return !scheduleVariantData
          .getDatesWithoutStatuses()
          .some(
            (scheduleDate) =>
              moment(scheduleDate).format(COMPARISON_DATES_FORMAT) === moment(date).format(COMPARISON_DATES_FORMAT)
          );
      }

      return !localScheduleData
        .getDatesWithoutStatuses()
        .some(
          (scheduleDate) =>
            moment(scheduleDate).format(COMPARISON_DATES_FORMAT) === moment(date).format(COMPARISON_DATES_FORMAT)
        );
    },
    [showVariantSchedule, isDateMoving, loading, localScheduleData.nextDate, isScheduleExist, updateCounter]
  );

  const renderDay = useCallback(
    (day, selectedDate, dayInCurrentMonth, dayComponent) => {
      const currentDay = day.format(COMPARISON_DATES_FORMAT);

      if (isDateMoving && currentDay === moment(localValue).format(COMPARISON_DATES_FORMAT)) {
        return <div className={classes.moveFromDay}>{dayComponent}</div>;
      }

      if (
        showVariantSchedule &&
        scheduleVariantData
          .getDatesWithoutStatuses()
          .some((scheduleDate) => moment(scheduleDate).format(COMPARISON_DATES_FORMAT) === currentDay)
      ) {
        return <div className={classes.availableDay}>{dayComponent}</div>;
      }

      if (
        !showVariantSchedule &&
        localScheduleData
          .getDatesWithoutStatuses()
          .some((scheduleDate) => moment(scheduleDate).format(COMPARISON_DATES_FORMAT) === currentDay)
      ) {
        return <div className={classes.availableDay}>{dayComponent}</div>;
      }

      return dayComponent;
    },
    [localValue, showVariantSchedule, loading, updateCounter]
  );

  const onValueChange = (newValue) => {
    if (isDateMoving) {
      onDateMoveSeriesChange(newValue);
      return;
    }

    setLocalValue(newValue);
    onChange(newValue);
  };

  function forceReloadPicker() {
    setLocalLoading(true);

    setTimeout(() => {
      setLocalLoading(false);
    }, 50);
  }

  function getNextDate() {
    let result = localScheduleData.getNextDate();

    if (forVariant && localScheduleData.getVariantById(variantId)) {
      result = localScheduleData.getVariantById(variantId)?.nextDate;
    }

    return result;
  }

  const onDoneSuccess = () => {};
  const onDoneError = (error) => {
    const errorDefaultMessage = error?.response?.data?.message || '';

    setLocalError(errorDefaultMessage);
  };

  const onFinish = () => {
    setLocalError('');
    onDone(localValue, onDoneSuccess, onDoneError);
  };

  const onDateDeleteStart = () => {
    setConfirmDeleteDate(true);
  };

  const onDateDeleteFinish = () => {
    setConfirmLoading(true);
    onDateDelete(
      {
        date: moment(localValue, moment.ISO_8601),
      },
      (response) => {
        const newScheduleData = new ScheduleDates(response);
        onUpdateScheduleData && onUpdateScheduleData(newScheduleData);
        setLocalScheduleData(newScheduleData);

        if (forVariant) {
          setShowVariantSchedule(true);
          setScheduleVariantData(new ScheduleVariant(newScheduleData.getVariantById(variantId)));
        }
        setUpdateCounter(updateCounter + 1);
        setConfirmLoading(false);
        setConfirmDeleteDate(false);
        forceReloadPicker();
      },
      (error) => {
        setConfirmDeleteDate(false);
        setConfirmLoading(false);
        onDoneError(error);
      }
    );
  };

  const drawDialog = () => {
    const dialogHeader = () => (
      <div className={classes.titleWrapper}>
        <Typography variant={'inherit'} component={'h2'} className={classes.title}>
          {intl.formatMessage({ id: 'modal.schedule.changeDates' })}
        </Typography>
        <Icon icon={CloseIcon} style={{ fill: 'rgba(0, 0, 0, 0.5)' }} className={classes.close} onClick={onClose} />
      </div>
    );
    const dialogContent = () => (
      <>
        {!!localError && <ErrorBox error={localError} className={classes.errorBoxWrapper} />}

        {loading || localLoading ? (
          <CircularProgress size={30} color={'secondary'} />
        ) : (
          <div className={classes.pickerWrapper}>
            <SchedulePicker
              className={classes.date}
              fullWidth
              value={value}
              initialFocusedDate={value}
              shouldDisableDate={shouldDisableDate}
              renderDay={renderDay}
              onChange={onValueChange}
              minDate={minDate}
              //maxDate={maxDate && moment(maxDate).endOf('day')}
              {...datePickerProps}
            />
          </div>
        )}
      </>
    );
    const dialogActions = () => (
      <>
        {!isDateMoving && isScheduleExist && (
          <>
            <div className={classes.nextDateWrapper}>
              {!!nextDate && (
                <p className={classes.nextDateText}>
                  {intl.formatMessage(
                    { id: 'modal.schedule.nextDeliveryDate' },
                    { nextDate: moment(localValue).format('MMMM DD, yyyy') }
                  )}
                </p>
              )}
            </div>

            <div className={classes.actionsChooserWrapper}>
              {!!onDateChange && (
                <p className={classes.action} onClick={() => onDateMoveSeriesStart(MOVE_ACTION_TYPES.MOVE_SINGLE_DATE)}>
                  {intl.formatMessage({ id: 'modal.schedule.moveOccurrence' })}
                </p>
              )}
              {!!onDateMoveSeries && (
                <p
                  className={classes.action}
                  onClick={() => onDateMoveSeriesStart(MOVE_ACTION_TYPES.MOVE_SERIES_OF_DATES)}>
                  {intl.formatMessage({ id: 'modal.schedule.moveSeries' })}
                </p>
              )}
              {!!onDateDelete && (
                <p onClick={onDateDeleteStart} className={classes.action}>
                  {intl.formatMessage({ id: 'modal.schedule.deleteOccurrence' })}
                </p>
              )}
            </div>
          </>
        )}

        {!isDateMoving ? (
          <div className={classes.buttonWrapper}>
            <PrimaryButton className={classes.buttonRoot} labelClass={classes.button} onClick={onFinish}>
              {intl.formatMessage({ id: 'global.save' })}
            </PrimaryButton>
          </div>
        ) : (
          <div className={classes.buttonWrapper}>
            <PrimaryButton
              className={classNames([classes.buttonRoot, classes.mr20])}
              labelClass={classes.button}
              onClick={onDateMoveSeriesCancel}>
              {intl.formatMessage({ id: 'global.cancel' })}
            </PrimaryButton>
            <PrimaryButton className={classes.buttonRoot} labelClass={classes.button} onClick={onDateMoveSeriesFinish}>
              {intl.formatMessage({ id: 'global.ok' })}
            </PrimaryButton>
          </div>
        )}
      </>
    );

    if (absolutePicker) {
      return (
        <div className={classes.absolutePickerRoot}>
          <div className={classes.absolutePicker}>
            <div className={classes.absolutePickerWrapper}>
              {dialogHeader()}
              <div className={classes.contentWrapper}>{dialogContent()}</div>
              <div className={classes.actionsWrapper}>{dialogActions()}</div>
            </div>
          </div>
          <div className={classes.absolutePickerBackdrop} onClick={onClose} />
        </div>
      );
    }

    return (
      <Dialog onClose={onClose} open={true} classes={{ paper: classes.paper }} fullScreen={false}>
        {dialogHeader()}
        <DialogContent className={classes.contentWrapper}>{dialogContent()}</DialogContent>
        <DialogActions className={classes.actionsWrapper} disableSpacing>
          {dialogActions()}
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      {drawDialog()}

      {confirmDeleteDate && (
        <ConfirmDialog
          onClose={() => setConfirmDeleteDate(false)}
          loading={confirmLoading}
          title={intl.formatMessage({ id: 'modal.schedule.confirmDeleteDate.title' })}
          message={intl.formatMessage(
            { id: 'modal.schedule.confirmDeleteDate.content' },
            { nextDate: moment(localValue).format('MMMM DD, yyyy') }
          )}
          confirmTitle={'modal.schedule.confirmDeleteDate.button'}
          onConfirm={onDateDeleteFinish}
        />
      )}
    </>
  );
};

ScheduleDialog.propTypes = propTypes;

export default withStyles(styles)(injectIntl(ScheduleDialog));
