/**
 * Converts variants array to object
 * @param {array} variants
 */
export const variantsArrayToObject = (variants) => {
  let object = {};
  variants.forEach((variant) => {
    object[variant.id] = variant;
  });
  return object;
};
