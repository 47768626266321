import { COLORS } from '../../../helpers';

export default {
  searchWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  search: {
    minWidth: 300,
  },

  wrapper: {},
  name: {
    fontSize: 18,
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.text,
    marginBottom: 40,
  },
  input: {
    marginBottom: 25,
  },
  label: {
    fontSize: 14,
  },
  title: {
    fontSize: 18,
    textTransform: 'capitalize',
  },
  inputText: {
    textAlign: 'right',
  },
  button: {
    minWidth: 330,
    alignSelf: 'center',
    textTransform: 'uppercase',
  },
  controls: {
    margin: '27px 0 0 0',
    textAlign: 'center',
  },
  control: {
    padding: '0 105px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: 107,
    marginLeft: 107,
  },
  column: {
    flex: 1,
  },
  content: {
    '&:last-child': {
      padding: '10px 10px',
    },
    padding: 0,
    textAlign: 'center',
  },
  companyName: {
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.text,
    fontWeight: 600,
    fontSize: 14,
  },
  card: {
    marginLeft: 2,
    marginRight: 10,
    marginBottom: 10,
    cursor: 'pointer',
    width: 160,
  },
  noItems: {
    fontSize: 18,
    fontWeight: 500,
  },
};
