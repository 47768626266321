import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { withStyles } from '@material-ui/core/styles';
import { PrimaryInlineDatePicker, PrimaryTextField } from '../../../components';
import { preventClickDefault } from '../../../helpers/events';
import { ClickAwayListener } from '@material-ui/core';

const styles = {
  absolutePicker: {
    position: 'absolute',
    left: 0,
    top: -210,
    zIndex: 1000,
    backgroundColor: 'white',
    boxShadow: '0px 14px 24px 0px #00000026',
    borderRadius: 9,
    overflow: 'hidden',
  },
};

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  applyOnChange: PropTypes.bool,
  cellProps: PropTypes.object.isRequired,
  onFinishCellEditing: PropTypes.func.isRequired,
  dateFormat: PropTypes.string,
  datePickerProps: PropTypes.object,
  openOnSingleClick: PropTypes.bool,
  absolutePicker: PropTypes.bool,
  ValueComponent: PropTypes.elementType,
};

const defaultProps = {
  applyOnChange: false,
  dateFormat: process.env.REACT_APP_DATE_FORMAT_WITH_SHORT_DAY_OF_WEEK,
  datePickerProps: {},
};

class CellAsDateInput extends React.Component {
  state = {
    cache: this.props.value,
    value: this.props.value,
    editingMode: false,
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown, false);
  }

  onCellClick = (event) => {
    preventClickDefault(event);

    this.setState({
      editingMode: true,
    });
  };

  onFinishCellEditing = () => {
    const { onFinishCellEditing } = this.props;
    const { value } = this.state;

    this.setState(
      {
        cache: value,
        value: value,
        editingMode: false,
      },
      () => {
        onFinishCellEditing(value);
      }
    );
  };

  onKeyDown = (event) => {
    const { key } = event;
    if (this.state.editingMode) {
      if (key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
        this.onFinishCellEditing();
      }

      if (key === 'Escape') {
        event.preventDefault();
        event.stopPropagation();
        this.setState({
          editingMode: false,
          value: this.state.cache,
        });
      }
    }
  };

  drawPicker = () => {
    const { classes, applyOnChange, datePickerProps, absolutePicker } = this.props;

    const picker = () => (
      <PrimaryInlineDatePicker
        className={classes.date}
        fullWidth
        autoOk={true}
        value={this.state.value}
        onChange={(value) => {
          this.setState({ value }, () => {
            if (applyOnChange) {
              this.onFinishCellEditing();
            }
          });
        }}
        TextFieldComponent={PrimaryTextField}
        {...datePickerProps}
      />
    );

    if (this.state.editingMode && !absolutePicker) {
      return picker();
    }

    if (this.state.editingMode && absolutePicker) {
      return (
        <ClickAwayListener onClickAway={() => this.setState({ editingMode: false })}>
          <div className={classes.absolutePicker} onClick={preventClickDefault}>
            {picker()}
          </div>
        </ClickAwayListener>
      );
    }

    return null;
  };

  render() {
    const { cellProps, style, dateFormat, openOnSingleClick, ValueComponent, disableClick } = this.props;

    return (
      <Table.Cell
        {...cellProps}
        style={{
          position: 'relative',
          cursor: 'cell',
          overflow: 'visible',
          ...style,
        }}
        {...(!disableClick && openOnSingleClick ? { onClick: this.onCellClick } : { onDoubleClick: this.onCellClick })}>
        {!!ValueComponent ? (
          <ValueComponent onClick={this.onCellClick} />
        ) : (
          !this.state.editingMode && (this.state.value ? moment(this.state.value).format(dateFormat) : '-//-')
        )}
        {this.drawPicker()}
      </Table.Cell>
    );
  }
}

CellAsDateInput.propTypes = propTypes;
CellAsDateInput.defaultProps = defaultProps;

export default withStyles(styles)(injectIntl(CellAsDateInput));
