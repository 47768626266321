import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  BlackImageUploader,
  PrimarySelect,
  PrimaryTextField,
  ButtonWithSelect,
  OutlineButton,
  Spinner,
  PrimaryInlineDatePicker,
  PrimaryCheckbox,
  RichTextEditableBox,
  VariantsSuggestions,
} from '../../../components';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import styles from '../styles';
import Typography from '@material-ui/core/Typography';
import {
  SHIPPING_TYPES,
  CUSTOMER_TYPE,
  toCoins,
  toPaper,
  validateField,
  weightToSystem,
  weightToUser,
  BILLING_UPFRONT,
  BILLING_RECURRING,
} from '../../../helpers';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from '@material-ui/icons/Add';
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton';
import validationRules from './validationVariant';
import { Variant } from '../../../entities';
import { apiAddVariantToProduct, apiEditProductVariant } from '../../../api/product/product';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Availability from '../../../entities/Availability';
import classNames from 'classnames';
import RemoveIcon from '@material-ui/icons/Remove';

VariantView.propTypes = {
  variant: PropTypes.object,
  setUnsavedChanges: PropTypes.func,
  hasUnsavedChanges: PropTypes.bool,
};

const PRICES_NAMES = {
  RETAIL_MARKET_PRICE: 'retailMarketPrice',
  RETAIL_PREORDER_PRICE: 'retailPreorderPrice',
  WHOLESALE_MARKET_PRICE: 'wholesaleMarketPrice',
  WHOLESALE_PREORDER_PRICE: 'wholesalePreorderPrice',
  RETAIL_MARKET_PRICE_LEVEL: 'retail_market',
  RETAIL_PREORDER_PRICE_LEVEL: 'retail_preorder',
  WHOLESALE_MARKET_PRICE_LEVEL: 'wholesale_market',
  WHOLESALE_PREORDER_PRICE_LEVEL: 'wholesale_preorder',
};
const PERIOD_PER_ALL = 'all';
const PERIOD_PER_WEEK = 'week';
const PERIOD_BI_WEEK = 'bi_week';

const LOCATION_ALL = 'all';
const DELIVERY_METHOD_ALL = 'all';

function VariantView(props) {
  const {
    variant,
    classes,
    intl,
    producer,
    product,
    onDone,
    onDoneNewProduct,
    setError,
    notifySaved,
    getError,
    setUnsavedChanges,
    hasUnsavedChanges,
  } = props;

  const [type, setType] = useState(variant.type ? variant.type : Variant.TYPE_REGULAR);
  const [initialVariantType] = useState(variant.type || '');
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null);
  const [name, setName] = useState(variant.name || '');
  const [unit, setUnit] = useState(variant.unit || '');
  const variantId = variant.id;
  const [unitQuantity, setUnitQuantity] = useState(variant.unitQuantity ? weightToUser(variant.unitQuantity) : 1);
  const [description, setDescription] = useState(variant.description || '');
  const [wholesaleDescription, setWholesaleDescription] = useState(variant.wholesaleDescription || '');
  const [separateDescriptions, setSeparateDescriptions] = useState(variant.wholesaleDescription || false);
  const [subscriptionQuantity, setSubscriptionQuantity] = useState(
    toPaper(variant?.subscription?.quantity || variant.subscriptionQuantity) || ''
  );
  const [subscriptionIsActive, setSubscriptionIsActive] = useState(
    !!variant?.subscription?.quantity || !!variant.subscriptionQuantity
  );
  const [subscriptionVariantId, setSubscriptionVariantId] = useState(variant.baseVariantId || '');
  const [subscriptionVariantName, setSubscriptionVariantName] = useState(makeProductNameWithVariant());
  const [subscriptionVariantIdError, setSubscriptionVariantIdError] = useState('');
  const [subscriptionPeriod, setSubscriptionPeriod] = useState(
    variant?.subscription?.period || variant.subscriptionPeriod || PERIOD_PER_ALL
  );
  const [subscriptionBillingType, setSubscriptionBillingType] = useState(
    variant?.subscription?.billingType || variant.billingType || ''
  );
  // const [subscriptionPeriodError, setSubscriptionPeriodError] = useState('');
  const [active, setActive] = useState(variant.active || false);
  const [nameError, setNameError] = useState('');
  const [unitError, setUnitError] = useState('');
  const [unitQuantityError, setUnitQuantityError] = useState('');
  const [retailMarketPriceError, setRetailMarketPriceError] = useState('');
  const [retailPreorderPriceError, setRetailPreorderPriceError] = useState('');
  const [wholesaleMarketPriceError, setWholesaleMarketPriceError] = useState('');
  const [wholesalePreorderPriceError, setWholesalePreorderPriceError] = useState('');
  const [subscriptionQuantityError, setSubscriptionQuantityError] = useState('');
  const pricesLevels = [
    {
      value: CUSTOMER_TYPE.RETAIL,
      title: intl.formatMessage({ id: `global.retail` }),
    },
    {
      value: CUSTOMER_TYPE.WHOLESALE,
      title: intl.formatMessage({ id: `global.wholesale` }),
    },
  ];
  function makeProductNameWithVariant() {
    return !!variant?.baseVariantId
      ? `${!!variant?.baseProductName ? variant.baseProductName : ''}${
          !!variant.baseProductName && !!variant?.baseVariantName ? ' - ' : ''
        }${variant?.baseVariantName ? variant?.baseVariantName : ''}`
      : '';
  }
  const getVariantPrices = (prices) => {
    const result = {};
    prices.forEach((price) => {
      result[price.level] = {
        level: price.level,
        price: toPaper(price.price || ''),
      };
    });
    return result;
  };
  const getActivePrices = (prices) => {
    let active = [];

    prices.retail && active.push(CUSTOMER_TYPE.RETAIL);
    prices.wholesale && active.push(CUSTOMER_TYPE.WHOLESALE);

    return active;
  };
  const getAvailabilities = (availabilitiesArr) => {
    let result = [];
    !!availabilitiesArr &&
      availabilitiesArr.forEach((availability) => {
        result.push(new Availability(availability));
      });
    return result;
  };
  const [prices, setPrices] = useState(variant.prices ? getVariantPrices(variant.prices) : {});
  const [offered, setOffered] = useState(variant.offered || { wholesale: false, retail: false });
  const [activePrices, setActivePrices] = useState(variant.offered ? getActivePrices(variant.offered) : []);
  const [availabilities, setAvailabilities] = useState(getAvailabilities(variant.availabilities));

  useEffect(() => {
    setLoading(false);
  }, [variant]);

  useEffect(() => {
    !loading && setUnsavedChanges(true);
  }, [
    type,
    file,
    name,
    unit,
    unitQuantity,
    description,
    wholesaleDescription,
    subscriptionQuantity,
    subscriptionIsActive,
    subscriptionVariantId,
    subscriptionVariantName,
    subscriptionPeriod,
    subscriptionBillingType,
    active,
    prices,
  ]);

  const changePrice = (level, price) => {
    setPrices({
      ...prices,
      [level]: {
        level,
        price,
      },
    });
  };

  const getPrice = (level) => {
    return prices[level] ? prices[level].price : null;
  };

  const handlePriceAdd = (priceType) => {
    setOffered({
      retail: priceType.includes(CUSTOMER_TYPE.RETAIL),
      wholesale: priceType.includes(CUSTOMER_TYPE.WHOLESALE),
    });
    setActivePrices(priceType);
  };

  const clearErrors = () => {
    setError('');
    setNameError('');
    setUnitError('');
    setUnitQuantityError('');
    setRetailMarketPriceError('');
    setRetailPreorderPriceError('');
    setWholesaleMarketPriceError('');
    setWholesalePreorderPriceError('');
    setSubscriptionQuantityError('');
    setSubscriptionVariantIdError('');
  };

  const onSaveVariant = () => {
    const totalVariantsCount = (product && product.variants && product.variants.length) || 0;
    // const nameError = validateField(validationRules, 'name', name);
    const unitError = validateField(validationRules, 'unit', unit);
    const unitQuantityError = validateField(validationRules, 'unitQuantity', unitQuantity);

    const availabilitiesErrors = validateAvailabilityParameters();

    let retailMarketPriceError, retailPreorderPriceError, wholesaleMarketPriceError, wholesalePreorderPriceError;

    if (offered.retail) {
      retailMarketPriceError = validateField(
        validationRules,
        'retailMarketPrice',
        getPrice(PRICES_NAMES.RETAIL_MARKET_PRICE_LEVEL)
      );
      retailPreorderPriceError = validateField(
        validationRules,
        'retailPreorderPrice',
        getPrice(PRICES_NAMES.RETAIL_PREORDER_PRICE_LEVEL)
      );
    }
    if (offered.wholesale) {
      wholesaleMarketPriceError = validateField(
        validationRules,
        'wholesaleMarketPrice',
        getPrice(PRICES_NAMES.WHOLESALE_MARKET_PRICE_LEVEL)
      );
      wholesalePreorderPriceError = validateField(
        validationRules,
        'wholesalePreorderPrice',
        getPrice(PRICES_NAMES.WHOLESALE_PREORDER_PRICE_LEVEL)
      );
    }

    const atLeastOnePriceSelect = offered.retail || offered.wholesale;

    if (!atLeastOnePriceSelect) {
      setError(intl.formatMessage({ id: 'validation.screen.variants.priceError' }));
    }

    // Subscription qty validation
    let subscriptionQuantityError = '';
    let subscriptionVariantIdError = '';
    if (type === Variant.TYPE_SUBSCRIPTION) {
      subscriptionQuantityError = validateField(validationRules, 'subscriptionQuantity', subscriptionQuantity);
      subscriptionVariantIdError = validateField(validationRules, 'subscriptionVariantId', subscriptionVariantId);
    }

    if (
      !unitError &&
      !unitQuantityError &&
      !retailMarketPriceError &&
      !retailPreorderPriceError &&
      !wholesaleMarketPriceError &&
      !wholesalePreorderPriceError &&
      !subscriptionQuantityError &&
      !subscriptionVariantIdError &&
      !availabilitiesErrors &&
      atLeastOnePriceSelect
    ) {
      setLoading(true);

      clearErrors();

      let data = {
        name: name,
        unit: unit,
        type: type,
        unitQuantity: weightToSystem(unitQuantity),
        description: description,
        wholesaleDescription,
        active: active,
        prices: [
          {
            level: PRICES_NAMES.WHOLESALE_MARKET_PRICE_LEVEL,
            price: toCoins(getPrice(PRICES_NAMES.WHOLESALE_MARKET_PRICE_LEVEL)),
          },
          {
            level: PRICES_NAMES.WHOLESALE_PREORDER_PRICE_LEVEL,
            price: toCoins(getPrice(PRICES_NAMES.WHOLESALE_PREORDER_PRICE_LEVEL)),
          },
          {
            level: PRICES_NAMES.RETAIL_MARKET_PRICE_LEVEL,
            price: toCoins(getPrice(PRICES_NAMES.RETAIL_MARKET_PRICE_LEVEL)),
          },
          {
            level: PRICES_NAMES.RETAIL_PREORDER_PRICE_LEVEL,
            price: toCoins(getPrice(PRICES_NAMES.RETAIL_PREORDER_PRICE_LEVEL)),
          },
        ],
        offered,

        ...(!!availabilities.length ? { availabilities } : {}),
        /**
         * Variant subscription
         * Subscription should work only in case we have more or equals to one variant
         */
        ...(totalVariantsCount >= 1 && variant && variant.id ? {} : { type }),
        ...(totalVariantsCount >= 1 && type === Variant.TYPE_SUBSCRIPTION
          ? {
              subscription: {
                quantity: weightToSystem(subscriptionQuantity),
                ...(subscriptionBillingType ? { billingType: subscriptionBillingType } : {}),
                ...(subscriptionPeriod ? { period: subscriptionPeriod } : {}),
                ...(subscriptionVariantId ? { variantId: subscriptionVariantId } : {}),
              },
            }
          : {}),
      };

      if (file) {
        data.image = file;
      }

      if (product && product.id) {
        setLoading(true);
        if (variant && variant.id) {
          apiEditProductVariant(
            product.id,
            variant.id,
            data,
            (product) => {
              onDone(product);
              notifySaved(true);
              setUnsavedChanges(false);
            },
            handleError
          );
        } else {
          data.price = toCoins(getPrice(PRICES_NAMES.WHOLESALE_MARKET_PRICE_LEVEL));
          data.prices = [
            {
              level: PRICES_NAMES.RETAIL_MARKET_PRICE_LEVEL,
              price: toCoins(getPrice(PRICES_NAMES.RETAIL_MARKET_PRICE_LEVEL)),
            },
            {
              level: PRICES_NAMES.RETAIL_PREORDER_PRICE_LEVEL,
              price: toCoins(getPrice(PRICES_NAMES.RETAIL_PREORDER_PRICE_LEVEL)),
            },
            {
              level: PRICES_NAMES.WHOLESALE_PREORDER_PRICE_LEVEL,
              price: toCoins(getPrice(PRICES_NAMES.WHOLESALE_PREORDER_PRICE_LEVEL)),
            },
            {
              level: PRICES_NAMES.WHOLESALE_MARKET_PRICE_LEVEL,
              price: toCoins(getPrice(PRICES_NAMES.WHOLESALE_MARKET_PRICE_LEVEL)),
            },
          ];
          apiAddVariantToProduct(
            product.id,
            data,
            (product) => {
              onDone(product);
              notifySaved(true);
              setUnsavedChanges(false);
            },
            handleError
          );
        }
      } else {
        data.base64 = file;
        data.price = toCoins(getPrice(PRICES_NAMES.WHOLESALE_MARKET_PRICE_LEVEL));
        data.prices = [
          {
            level: PRICES_NAMES.RETAIL_MARKET_PRICE_LEVEL,
            price: toCoins(getPrice(PRICES_NAMES.RETAIL_MARKET_PRICE_LEVEL)),
          },
          {
            level: PRICES_NAMES.RETAIL_PREORDER_PRICE_LEVEL,
            price: toCoins(getPrice(PRICES_NAMES.RETAIL_PREORDER_PRICE_LEVEL)),
          },
          {
            level: PRICES_NAMES.WHOLESALE_PREORDER_PRICE_LEVEL,
            price: toCoins(getPrice(PRICES_NAMES.WHOLESALE_PREORDER_PRICE_LEVEL)),
          },
          {
            level: PRICES_NAMES.WHOLESALE_MARKET_PRICE_LEVEL,
            price: toCoins(getPrice(PRICES_NAMES.WHOLESALE_MARKET_PRICE_LEVEL)),
          },
        ];
        if (!variantId) {
          data.id = (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase();
        } else {
          data.id = variantId;
        }
        data.baseVariantId = subscriptionVariantId;
        data.baseVariantName = subscriptionVariantName;

        onDoneNewProduct(data);
      }
    } else {
      // setNameError(nameError);
      setUnitError(unitError);
      setUnitQuantityError(unitQuantityError);
      setRetailPreorderPriceError(retailPreorderPriceError);
      setRetailMarketPriceError(retailMarketPriceError);
      setWholesaleMarketPriceError(wholesaleMarketPriceError);
      setWholesalePreorderPriceError(wholesalePreorderPriceError);
      setSubscriptionQuantityError(subscriptionQuantityError);
      setSubscriptionVariantIdError(subscriptionVariantIdError);
    }
  };

  const handleError = (error) => {
    const errorMessage = getError(error);
    setError(errorMessage);
    setLoading(false);
  };

  const onAddNewAvailability = () => {
    setAvailabilities([...availabilities, new Availability()]);
    setUnsavedChanges(true);
  };
  const setAvailabilityParameterByIndex = (index, parameter = {}) => {
    let changedAvailabilities = [...availabilities];

    changedAvailabilities[index] = {
      ...changedAvailabilities[index],
      ...parameter,
    };

    setAvailabilities(changedAvailabilities);
  };
  const getAvailabilityParameterByIndex = (index, parameter = '') => {
    return availabilities[index][parameter] || '';
  };
  const validateAvailabilityParameters = () => {
    const validated = [];
    let hasErrors = false;

    availabilities.forEach((availability) => {
      const locationIdError = validateField(validationRules, 'shippingLocation', availability.locationId);
      const shippingTypeError = validateField(validationRules, 'shippingMethod', availability.shippingType);

      if (!!locationIdError || !!shippingTypeError) hasErrors = true;

      validated.push({ ...availability, locationIdError, shippingTypeError });
    });

    setAvailabilities(validated);

    return hasErrors;
  };

  if (loading) return <Spinner size={60} />;

  return (
    <>
      <div className={classes.productHeaderWrapper}>
        <div className={classes.productHeaderText}>
          <Typography className={classes.productHeaderH1} component="h1" variant="subtitle1">
            {intl.formatMessage({ id: 'screen.product.productVariant' })}
          </Typography>
        </div>
        <div className={classes.productHeaderSaveButton}>
          {
            <PrimaryButton
              className={classes.button}
              onClick={onSaveVariant}
              disabled={!hasUnsavedChanges}
              isLoading={loading}>
              {intl.formatMessage({ id: 'screen.variants.saveVariant' })}
            </PrimaryButton>
          }
        </div>
      </div>

      <div className={classes.productMainTwoSidesWrapper}>
        <div className={classes.imageBoxWrapper}>
          <BlackImageUploader object={variant} base64={file} onUploaded={(uploadedFile) => setFile(uploadedFile)} />
        </div>
        <div className={classes.fieldSetWrapper}>
          <PrimaryTextField
            className={classes.input}
            error={!!nameError}
            onChange={(event) => setName(event.target.value)}
            value={name}
            id="name-input"
            label={intl.formatMessage({ id: 'product.nameOptional' })}
            type="text"
            InputLabelProps={{ shrink: true }}
            helperText={nameError && intl.formatMessage({ id: nameError })}
            fullWidth
          />

          <PrimarySelect
            className={classes.input}
            label={intl.formatMessage({ id: 'variant.unit' })}
            error={!!unitError}
            helperText={unitError && intl.formatMessage({ id: unitError })}
            value={unit}
            onChange={(e) => setUnit(e.target.value)}>
            {Object.keys(producer.units).map((unit) => (
              <MenuItem key={unit} value={unit}>
                {unit}
              </MenuItem>
            ))}
          </PrimarySelect>

          <PrimaryTextField
            className={classes.input}
            error={!!unitQuantityError}
            onChange={(event) => setUnitQuantity(event.target.value)}
            value={unitQuantity}
            id="unitQuantity-input"
            label={intl.formatMessage({ id: 'variant.unitQuantity' })}
            type="text"
            InputLabelProps={{ shrink: true }}
            helperText={unitQuantityError && intl.formatMessage({ id: unitQuantityError })}
            fullWidth
          />
        </div>
      </div>

      <RichTextEditableBox
        titleClass={classes.descriptionTitleClass}
        title={intl.formatMessage({
          id: separateDescriptions ? 'variant.descriptionRetail' : 'variant.description',
        })}
        editingTitle={intl.formatMessage({
          id: separateDescriptions ? 'variant.descriptionRetail' : 'variant.description',
        })}
        withEditIcon
        text={description}
        onSave={(html) => setDescription(html)}
        notEditingWrapperStyle={{ width: '100%' }}
        placeholder={intl.formatMessage({ id: 'global.descriptionNotAdded' })}
      />

      <div className={classes.wholesaleDescriptionWrapper}>
        {!separateDescriptions && (
          <OutlineButton icon={AddIcon} className={classes.button} onClick={() => setSeparateDescriptions(true)}>
            {intl.formatMessage({ id: 'screen.product.addWholesaleDescription' })}
          </OutlineButton>
        )}

        {!!separateDescriptions && (
          <RichTextEditableBox
            titleClass={classes.descriptionTitleClass}
            title={intl.formatMessage({ id: 'variant.descriptionWholesale' })}
            editingTitle={intl.formatMessage({
              id: 'variant.descriptionWholesale',
            })}
            withEditIcon
            text={wholesaleDescription}
            onSave={(html) => setWholesaleDescription(html)}
            notEditingWrapperStyle={{ width: '100%' }}
            placeholder={intl.formatMessage({ id: 'global.descriptionNotAdded' })}
            subWrapperClass={classes.subWrapperClass}
          />
        )}
      </div>

      <div className={classes.sectionWrapper}>
        <div className={classes.sectionTitleWrapper}>
          <Typography className={classes.sectionTitle} component="h3" variant="subtitle1">
            {intl.formatMessage({ id: 'screen.variants.prices' })}
          </Typography>
        </div>

        <div className={classes.sectionBodyWrapper}>
          {offered.retail && (
            <div className={classes.productMainTwoSidesWrapper}>
              <div className={classes.productMainLeft}>
                <PrimaryTextField
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  error={!!retailPreorderPriceError}
                  helperText={retailPreorderPriceError && intl.formatMessage({ id: retailPreorderPriceError })}
                  className={classes.input}
                  onChange={(event) => changePrice(PRICES_NAMES.RETAIL_PREORDER_PRICE_LEVEL, event.target.value)}
                  value={getPrice(PRICES_NAMES.RETAIL_PREORDER_PRICE_LEVEL) || ''}
                  id={`${PRICES_NAMES.RETAIL_PREORDER_PRICE}-input`}
                  label={intl.formatMessage({
                    id: `variant.${PRICES_NAMES.RETAIL_PREORDER_PRICE}`,
                  })}
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </div>
              <div className={classes.productsMainRight}>
                <PrimaryTextField
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  error={!!retailMarketPriceError}
                  helperText={retailMarketPriceError && intl.formatMessage({ id: retailMarketPriceError })}
                  className={classes.input}
                  onChange={(event) => changePrice(PRICES_NAMES.RETAIL_MARKET_PRICE_LEVEL, event.target.value)}
                  value={getPrice(PRICES_NAMES.RETAIL_MARKET_PRICE_LEVEL) || ''}
                  id={`${PRICES_NAMES.RETAIL_MARKET_PRICE}-input`}
                  label={intl.formatMessage({
                    id: `variant.${PRICES_NAMES.RETAIL_MARKET_PRICE}`,
                  })}
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </div>
            </div>
          )}

          {offered.wholesale && (
            <div className={classes.productMainTwoSidesWrapper}>
              <div className={classes.productMainLeft}>
                <PrimaryTextField
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  error={!!wholesalePreorderPriceError}
                  helperText={wholesalePreorderPriceError && intl.formatMessage({ id: wholesalePreorderPriceError })}
                  className={classes.input}
                  onChange={(event) => changePrice(PRICES_NAMES.WHOLESALE_PREORDER_PRICE_LEVEL, event.target.value)}
                  value={getPrice(PRICES_NAMES.WHOLESALE_PREORDER_PRICE_LEVEL) || ''}
                  id={`${PRICES_NAMES.WHOLESALE_PREORDER_PRICE}-input`}
                  label={intl.formatMessage({
                    id: `variant.${PRICES_NAMES.WHOLESALE_PREORDER_PRICE}`,
                  })}
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </div>
              <div className={classes.productsMainRight}>
                <PrimaryTextField
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  error={!!wholesaleMarketPriceError}
                  helperText={wholesaleMarketPriceError && intl.formatMessage({ id: wholesaleMarketPriceError })}
                  className={classes.input}
                  onChange={(event) => changePrice(PRICES_NAMES.WHOLESALE_MARKET_PRICE_LEVEL, event.target.value)}
                  value={getPrice(PRICES_NAMES.WHOLESALE_MARKET_PRICE_LEVEL) || ''}
                  id={`${PRICES_NAMES.WHOLESALE_MARKET_PRICE}-input`}
                  label={intl.formatMessage({
                    id: `variant.${PRICES_NAMES.WHOLESALE_MARKET_PRICE}`,
                  })}
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </div>
            </div>
          )}
        </div>
        <ButtonWithSelect
          values={pricesLevels}
          selected={activePrices}
          buttonText={intl.formatMessage({ id: 'screen.variants.addPriceLevel' })}
          onChange={(priceArr) => handlePriceAdd(priceArr)}
        />
      </div>

      {/*section subscription*/}
      {!!product?.variants?.length && (
        <div className={classes.sectionWrapper}>
          <div className={classes.sectionTitleWrapper}>
            <Typography className={classes.sectionTitle} component="h3" variant="subtitle1">
              {intl.formatMessage({ id: 'screen.variants.subscription' })}
            </Typography>
          </div>

          {(subscriptionIsActive || type === 'subscription') && (
            <div className={classes.sectionBodyWrapper}>
              <div className={classes.productMainTwoSidesWrapper}>
                <div className={classes.productMainLeft}>
                  <PrimarySelect
                    formControlClassName={classes.input}
                    value={subscriptionPeriod}
                    onChange={(event) => setSubscriptionPeriod(event.target.value)}
                    label={intl.formatMessage({
                      id: 'screen.variants.subscriptionPeriod',
                    })}>
                    <MenuItem value={PERIOD_PER_ALL}>{intl.formatMessage({ id: 'global.all' })}</MenuItem>
                    <MenuItem value={PERIOD_PER_WEEK}>{intl.formatMessage({ id: 'global.weekly' })}</MenuItem>
                    <MenuItem value={PERIOD_BI_WEEK}>{intl.formatMessage({ id: 'global.biWeek' })}</MenuItem>
                  </PrimarySelect>
                </div>
                <div className={classes.productsMainRight}>
                  <PrimaryTextField
                    className={classes.input}
                    onChange={(event) => setSubscriptionQuantity(event.target.value)}
                    error={!!subscriptionQuantityError}
                    helperText={subscriptionQuantityError && intl.formatMessage({ id: subscriptionQuantityError })}
                    value={subscriptionQuantity}
                    id={`subscription-quantity-input`}
                    label={intl.formatMessage({
                      id: 'screen.variants.subscriptionNumberOfOrders',
                    })}
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </div>
              </div>
              <div className={classes.productMainTwoSidesWrapper}>
                <div className={classes.productMainLeft}>
                  <PrimarySelect
                    formControlClassName={classes.input}
                    value={subscriptionBillingType}
                    onChange={(event) => setSubscriptionBillingType(event.target.value)}
                    label={intl.formatMessage({
                      id: 'screen.variants.subscriptionBillingType',
                    })}>
                    {!variant.billingType && <MenuItem value="">{intl.formatMessage({ id: 'global.none' })}</MenuItem>}
                    <MenuItem value={BILLING_UPFRONT}>{intl.formatMessage({ id: 'global.upfront' })}</MenuItem>
                    <MenuItem value={BILLING_RECURRING}>{intl.formatMessage({ id: 'global.recurring' })}</MenuItem>
                  </PrimarySelect>
                </div>

                <div className={classes.productsMainRight}>
                  <VariantsSuggestions
                    selected={null}
                    inputProps={{
                      className: classNames(classes.input, classes.variantsSuggestionInput),
                      error: !!subscriptionVariantIdError,
                      ...(subscriptionVariantIdError
                        ? { helperText: intl.formatMessage({ id: subscriptionVariantIdError }) }
                        : {}),
                      ...(subscriptionVariantId ? { value: subscriptionVariantName || subscriptionVariantId } : {}),
                    }}
                    labelExtractor={(item) => `${item.name}`}
                    inputLabel={intl.formatMessage({ id: 'global.subscriptionVariant' })}
                    onDetach={() => {
                      setSubscriptionVariantId('');
                    }}
                    clearAfterSelect
                    onAttach={(product, variant) => {
                      setSubscriptionVariantId(variant.id);
                      setSubscriptionVariantName(`${product.name} - ${variant?.name || variant.unit}`);
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          {!subscriptionIsActive && (
            <OutlineButton
              icon={AddIcon}
              className={classes.button}
              onClick={() => {
                setType(Variant.TYPE_SUBSCRIPTION);
                setSubscriptionIsActive(true);
              }}>
              {intl.formatMessage({ id: 'screen.variants.addSubscription' })}
            </OutlineButton>
          )}

          {!!subscriptionIsActive && initialVariantType !== Variant.TYPE_SUBSCRIPTION && (
            <OutlineButton
              icon={RemoveIcon}
              className={classes.button}
              onClick={() => {
                setType(Variant.TYPE_REGULAR);
                setSubscriptionIsActive(false);
              }}>
              {intl.formatMessage({ id: 'screen.variants.removeSubscription' })}
            </OutlineButton>
          )}
        </div>
      )}

      {/* section availabilities */}
      {!!variant.id && (
        <div className={classes.sectionWrapper}>
          <div className={classes.sectionTitleWrapper}>
            <Typography className={classes.sectionTitle} component="h3" variant="subtitle1">
              {intl.formatMessage({ id: 'screen.variants.specificShipping' })}
            </Typography>
          </div>

          {!!availabilities.length &&
            availabilities.map((availability, index) => (
              <div className={classes.productMainTwoSidesWrapper}>
                <div className={classes.productMainLeft}>
                  <PrimarySelect
                    formControlClassName={classes.input}
                    value={getAvailabilityParameterByIndex(index, 'locationId') || LOCATION_ALL}
                    onChange={(event) =>
                      setAvailabilityParameterByIndex(index, {
                        locationId: event.target.value,
                      })
                    }
                    label={intl.formatMessage({
                      id: 'screen.variants.location',
                    })}
                    helperText={
                      getAvailabilityParameterByIndex(index, 'locationIdError') &&
                      intl.formatMessage({
                        id: getAvailabilityParameterByIndex(index, 'locationIdError'),
                      })
                    }
                    error={!!getAvailabilityParameterByIndex(index, 'locationIdError')}>
                    <MenuItem value={LOCATION_ALL}>{intl.formatMessage({ id: 'global.all' })}</MenuItem>

                    {producer.locations.map((location) => (
                      <MenuItem value={location.id} key={location.id}>
                        {location.name}
                      </MenuItem>
                    ))}
                  </PrimarySelect>
                </div>
                <div className={classes.productMainLeft}>
                  <PrimarySelect
                    formControlClassName={classes.input}
                    value={getAvailabilityParameterByIndex(index, 'shippingType') || DELIVERY_METHOD_ALL}
                    onChange={(event) =>
                      setAvailabilityParameterByIndex(index, {
                        shippingType: event.target.value,
                      })
                    }
                    label={intl.formatMessage({ id: 'screen.variants.method' })}
                    helperText={
                      getAvailabilityParameterByIndex(index, 'shippingTypeError') &&
                      intl.formatMessage({
                        id: getAvailabilityParameterByIndex(index, 'shippingTypeError'),
                      })
                    }
                    error={!!getAvailabilityParameterByIndex(index, 'shippingTypeError')}>
                    <MenuItem value={DELIVERY_METHOD_ALL}>{intl.formatMessage({ id: 'global.all' })}</MenuItem>
                    <MenuItem value={SHIPPING_TYPES.SHIPPING_PICKUP}>
                      {intl.formatMessage({ id: 'global.pickup' })}
                    </MenuItem>
                    <MenuItem value={SHIPPING_TYPES.SHIPPING_DELIVERY}>
                      {intl.formatMessage({ id: 'global.delivery' })}
                    </MenuItem>
                  </PrimarySelect>
                </div>
                <div className={classes.productsMainRight}>
                  <PrimaryInlineDatePicker
                    label={intl.formatMessage({ id: 'screen.variants.date' })}
                    value={getAvailabilityParameterByIndex(index, 'startDate')}
                    onChange={(value) =>
                      setAvailabilityParameterByIndex(index, {
                        startDate: value.startOf('day').toISOString(),
                      })
                    }
                  />
                </div>
              </div>
            ))}

          <OutlineButton
            icon={AddIcon}
            className={classes.button}
            onClick={() => {
              onAddNewAvailability();
            }}>
            {intl.formatMessage({ id: 'screen.variants.addShippingOptions' })}
          </OutlineButton>
        </div>
      )}

      {/*activation checkbox*/}

      <div className={classes.sectionWrapper}>
        <div className={classes.sectionTitleWrapper}>
          <Typography className={classes.sectionTitle} component="h3" variant="subtitle1">
            {intl.formatMessage({ id: 'screen.variants.activate' })}
          </Typography>
        </div>
        <FormControlLabel
          control={
            <PrimaryCheckbox color="default" checked={active} onChange={(event) => setActive(event.target.checked)} />
          }
          label={intl.formatMessage({ id: 'variant.active' })}
        />
      </div>
    </>
  );
}

export default withStyles(styles)(VariantView);
