import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { withStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import PrimaryTextField from '../PrimaryTextField/PrimaryTextField';
import PrimaryCheckbox from '../PrimaryCheckbox/PrimaryCheckbox';

const styles = theme => ({
  textField: {
    margin: '15px 0 4px 0',
  },
  actionsWrap: {
    justifyContent: 'center',
    display: 'flex'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
})

class CustomerAddressForm extends Component {

  constructor(props) {
    super(props)

    const { address } = this.props

    this.state = { address }
  }

  _handleChange = (key, value) => {
    this.setState({
      address: {
        ...this.state.address,
        [key]: value
      }
    })
  }

  _handleSubmit = e => {
    e.preventDefault()
    const { address } = this.state

    this.props.onSubmit(address)
  }

  render () {
    const { intl, classes } = this.props
    const { address } = this.state

    return (
      <form onSubmit={this._handleSubmit} className={classes.form}>
        <PrimaryTextField
          value={address.address1}
          label={intl.formatMessage({ id: 'customer.address1' }) + ' *'}
          onChange={event => this._handleChange('address1', event.target.value)}
          className={classes.textField}
        />
        <PrimaryTextField
          value={address.zip}
          label={intl.formatMessage({ id: 'customer.zip' }) + ' *'}
          onChange={event => this._handleChange('zip', event.target.value)}
          className={classes.textField}
        />
        <PrimaryTextField
          value={address.city}
          label={intl.formatMessage({ id: 'customer.city' }) + ' *'}
          onChange={event => this._handleChange('city', event.target.value)}
          className={classes.textField}
        />
        <PrimaryTextField
          value={address.address2}
          label={intl.formatMessage({ id: 'customer.address2' })}
          onChange={event => this._handleChange('address2', event.target.value)}
          className={classes.textField}
        />
        <PrimaryTextField
          value={address.province}
          label={intl.formatMessage({ id: 'customer.province' })}
          onChange={event => this._handleChange('province', event.target.value)}
          className={classes.textField}
        />
        <FormControlLabel
          control={
            <PrimaryCheckbox
              checked={address.default}
              onChange={event => this._handleChange('default', event.target.checked)}
            />
          }
          label={intl.formatMessage({ id: 'address.defaultAddress' })}
        />
        <br/>
        <div className={classes.actionsWrap}>
          <PrimaryButton type='submit'>
            {
              address.id === 'new'
                ? intl.formatMessage({ id: 'customer.createAddress' })
                : intl.formatMessage({ id: 'customer.editAddress' })
            }
          </PrimaryButton>
        </div>
      </form>
    )
  }
}

CustomerAddressForm.propTypes = {
  account: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default withStyles(styles, {withTheme: true})(injectIntl(CustomerAddressForm))
