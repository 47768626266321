import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { connect } from 'react-redux';
import { toggleDrawer } from '../../store/actions/common';
import Arrows from './images/arrows';

const propTypes = {
  headerMenuComponent: PropTypes.object.isRequired,
  sidebarComponent: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};
const defaultProps = {};

const AdminLayout = (props) => {
  const { toggleDrawer, classes, open, sidebarComponent, headerMenuComponent, children } = props;

  let SidebarComponent = sidebarComponent;
  let HeaderMenuComponent = headerMenuComponent;
  const logo = require(`./images/${process.env.REACT_APP_NAME}.logo.png`).default;
  const smallLogo = require(`./images/${process.env.REACT_APP_NAME}.logo.small.png`).default;

  const [afterOpenTransaction, setAfterOpenTransaction] = useState(false);

  useEffect(() => {
    if (!open) {
      const timer = setTimeout(() => {
        setAfterOpenTransaction(true);
      }, 300);

      return () => clearTimeout(timer);
    } else {
      setAfterOpenTransaction(false);
    }
  }, [open]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={classNames(classes.appBar, {
          [classes.appBarShift]: open,
          [classes.appBarSmallShift]: !open,
        })}>
        <Toolbar disableGutters={!open} className={classes.headerToolbar}>
          <div className={classes.headerMenu}>
            <HeaderMenuComponent />
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: classNames(classes.drawerPaper, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
            [classes.hoverAfterDelay]: afterOpenTransaction,
          }),
        }}
        open={open}>
        <div className={classNames(classes.toolbar, classes.toolbarCenter, classes.logoWrapper)}>
          <img
            src={open ? logo : smallLogo}
            className={classNames({
              [classes.logo]: open,
              [classes.logoSmall]: !open,
              'sidebar-hideOnHover': !open,
            })}
            alt="Fellow Farmer"
          />
          {!open && (
            <img
              src={logo}
              className={classNames([classes.logo, classes.hidden, 'sidebar-showOnHover'])}
              alt="Fellow Farmer"
            />
          )}

          <IconButton
            onClick={toggleDrawer}
            className={classNames([classes.menuButton, !open && classes.hidden, 'sidebar-showOnHover'])}>
            {open ? <Arrows /> : <Arrows rotate={180} />}
          </IconButton>
        </div>
        <Divider />

        <SidebarComponent fullWidth={open} />
      </Drawer>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
};

AdminLayout.propTypes = propTypes;
AdminLayout.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  return {
    producer: state.producer.object,
    open: state.common.drawerExpand,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleDrawer: () => dispatch(toggleDrawer()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(styles, {
    withTheme: true,
  })(AdminLayout)
);
