import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CancelIcon from '@material-ui/icons/Cancel';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import classNames from 'classnames';
import PrimarySelect from '../../components/PrimarySelect/PrimarySelect';
import {
  apiAddNewSubscription,
  apiGetSubscriptionById,
  apiEditSubscriptionById,
  //customer
  apiCustomerRetrieve,
  apiSubscriptionCreateNextOrder,
  apiGetSubscriptionSchedule,
  apiChangeSubscriptionScheduleDateById,
  apiMoveSeriesSubscriptionScheduleDatesById,
  apiDeleteSubscriptionScheduleDateById,
} from '../../api';
import {
  PrimaryTextField,
  PrimaryButton,
  PrimaryInlineDatePicker,
  AdminDXTable,
  ErrorBox,
  Spinner,
  CustomersSuggestions,
  VariantsSuggestions,
  ScheduleInput,
  PrimaryChip,
  CustomerNameChip,
} from '../../components';
import {
  BILLING_RECURRING,
  BILLING_UPFRONT,
  capitalizeFirstLetter,
  COLORS,
  countWidthOfColumns,
  DEFAULT_MAX_CELL_WIDTH,
  DEFAULT_MIN_CELL_WIDTH,
  SHIPPING_TYPES,
  toCoins,
  toPaper,
  validateField,
} from '../../helpers';
import validationRules from './validation';
import {
  TableCell,
  PERIODS,
  objectParamComparison,
  PARAM_COMPARISON_TYPES,
  TableAction,
  TABLE_ACTION_TYPES,
} from './helper';
import { SubscriptionOrderInTable } from '../../entities';
import ScheduleDates from '../../entities/ScheduleDates';
import EventIcon from '@material-ui/icons/Event';

const UNION_SQUARE_GREEN_MARKET_ID = 'loc_5d2de6eac8b08e03ab7351ee';

const styles = () => ({
  customer: {
    display: 'block',
    marginBottom: 10,
  },
  textField: {
    margin: '10px',
    width: 'calc(50% - 20px)',
  },
  chipsWrap: {
    width: '100%',
    margin: 10,
  },
  chip: {
    margin: '5px 5px 5px 0',
  },
  productsTableWrap: {
    width: '100%',
    margin: 10,
  },
  actionsWrap: {
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
    paddingTop: 30,
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: 1400,
  },
  tint: {
    color: '#A3A6B4',
    fontSize: 11,
  },
  switchCustomerWrapper: {
    margin: '0 10px 10px 10px',
  },
  fullWidthTextInput: {
    width: '100%',
    paddingRight: 20,
  },
  ordersTableWrapper: {
    width: '100%',
    margin: 10,
  },
  errorBoxWrapper: {
    width: '100%',
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
  },
});

const WEEK_DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

class SubscriptionForm extends Component {
  constructor(props) {
    super(props);
    const { intl } = props;

    const columnExtensions = [
      { columnName: 'product', width: 300 },
      { columnName: 'subscription', width: 200 },
    ];

    const columns = [
      { name: 'product', title: intl.formatMessage({ id: 'global.product' }) },
      {
        name: 'subscription',
        title: intl.formatMessage({ id: 'subscription.variantTable.subscriptionProductAndVariantName' }),
      },
      { name: 'price', title: intl.formatMessage({ id: 'global.newPrice' }) },
      { name: 'quantity', title: intl.formatMessage({ id: 'subscription.lineItemQuantity' }) },
      { name: 'ordersQuantity', title: intl.formatMessage({ id: 'subscription.ordersQuantity' }) },
      { name: 'nextDate', title: intl.formatMessage({ id: 'subscription.nextDate' }) },
      { name: 'period', title: intl.formatMessage({ id: 'subscription.period' }) },
    ];

    const hiddenColumns = this.getHiddenColumns();
    const modalTableWidth = 1250;

    this.state = {
      originalSubscriptionData: {}, // do not change it, using for compare parameters

      table: null,
      name: '',
      value: '',
      orders: [],
      customer: null,
      shippingType: SHIPPING_TYPES.SHIPPING_PICKUP,
      billingType: BILLING_UPFRONT,
      period: PERIODS.PER_WEEK,
      weekDay: WEEK_DAYS[0],
      monthlyPeriodDay: 1,
      validFrom: moment(),
      validTo: moment(),
      variants: [],
      tags: [],
      errors: {},
      loading: false,
      nextOrderError: '',
      subscription: {},
      customerFullInfo: {},
      paymentMethods: [],
      paymentMethod: '',
      locationId: '',
      deliveryCompany: '',
      nextDate: null,
      nextOrderLoading: false,

      isScheduleExist: false,
      scheduleDataLoading: true,
      scheduleData: new ScheduleDates({}),
      hiddenColumns,
      columnExtensions,
      columns,
      columnWidths: countWidthOfColumns(columns, hiddenColumns, columnExtensions, modalTableWidth),
    };

    this.orderTable = null;
    this.saveAnyChangeTimer = null;
  }

  componentDidMount() {
    this.init();
  }

  init = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    if (id && id !== 'new') {
      this.setState({ loading: true });
      apiGetSubscriptionById(
        id,
        (res) => {
          this.setLoadedOrder(res);
          this.getScheduleData(id);
        },
        (err) => {
          this.setState({ loading: false });
          console.warn(err);
        }
      );
    }
  };

  getScheduleData = (subscriptionId) => {
    apiGetSubscriptionSchedule(
      subscriptionId,
      (data) => {
        const scheduleData = new ScheduleDates(data);
        const nextDate = data.nextDate ? moment(data.nextDate) : null;

        this.setState({
          scheduleData,
          isScheduleExist: true,
          scheduleDataLoading: false,

          nextDate,
          originalSubscriptionData: {
            ...this.state.originalSubscriptionData,
            nextDate,
          },
        });
      },
      () => {
        this.setState({
          scheduleDataLoading: false,
        });
      }
    );
  };

  setLoadedOrder = (res) => {
    this._getSelectedCustomerFullInfo(res.customer, false);

    this.setState(
      {
        originalSubscriptionData: res,
        name: res.name,
        value: toPaper(res.amount),
        orders: res.orders,
        customer: res.customer,
        shippingType: res.shippingType,
        billingType: res.type,
        period: res.period.type,
        weekDay: res.period.weekday,
        validFrom: moment(res.validFrom),
        validTo: moment(res.validTo),
        variants: (res.variants || []).map((item) => ({
          ...item,
          ...{
            value: toPaper(item.amount),
            product: item.productName,
            name: item?.variantName || '',
            quantity: toPaper(item.quantity),
          },
        })),
        tags: !!res.tags ? (Array.isArray(res.tags) ? res.tags : Object.values(res.tags)) : [],
        errors: {},
        loading: false,
        nextDate: res.nextDate ? moment(res.nextDate) : null,
        nextOrderLoading: false,
        nextOrderError: '',
        customerFullInfo: {},
        paymentMethods: [],
        paymentMethod: res.paymentMethodId,
        locationId: res?.market?.id || '',
        deliveryCompany: (res.deliveryCompany && res.deliveryCompany.id) || '',
        hiddenColumns: this.getHiddenColumns(),
      },
      () => {
        this.table.forceReload();
      }
    );
  };

  getHiddenColumns = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    let hiddenColumns = [];

    if (id === 'new') {
      hiddenColumns = ['endDate', 'scheduledDate'];
    }

    return hiddenColumns;
  };

  _handleChange = (key, value, onDone) => {
    this.setState(
      {
        [key]: value,
        errors: { ...this.state.errors, [key]: null },
      },
      onDone
    );
  };

  _validate = () => {
    const { intl } = this.props;

    const {
      name,
      customer,
      // value,
      variants,
      paymentMethod,
      nextDate,
      billingType,
    } = this.state;

    let errors = {};
    errors.name = validateField(validationRules, 'name', name);
    // errors.value = validateField(validationRules, 'value', value);
    errors.customer = validateField(validationRules, 'customer', customer);
    errors.nextDate =
      moment(nextDate) < moment.utc().add(3, 'days')
        ? intl.formatMessage({ id: 'validation.subscription.nextDate' })
        : null;

    if (billingType !== BILLING_UPFRONT) {
      errors.paymentMethod = validateField(validationRules, 'paymentMethod', paymentMethod);
    }

    if (this.deliverySelectGetValue() === -1) {
      errors.subscription = 'validation.csa.pleaseSpecifyShippingType';
    }

    if (billingType === -1) {
      errors.billingType = 'validation.csa.pleaseSpecifyBillingType';
    }

    if (!Boolean(variants.length)) {
      errors.variants = intl.formatMessage({ id: 'validation.csa.specifyAtLeastOneVariant' });
    }

    for (let i = 0; i < variants.length; i++) {
      if (!Number(variants[i].value)) {
        errors.variants = intl.formatMessage({ id: 'validation.csa.pleaseSpecifyPriceForEachVariant' });
        break;
      }

      if (!Number(variants[i].quantity)) {
        errors.variants = intl.formatMessage({ id: 'validation.subscription.pleaseSpecifyQuantityForEachVariant' });
        break;
      }

      if (!Number(variants[i].ordersQuantity)) {
        errors.variants = intl.formatMessage({
          id: 'validation.subscription.pleaseSpecifyOrdersQuantityForEachVariant',
        });
        break;
      }
    }

    this.setState({ errors, nextOrderError: '' });
    return Object.values(errors).every((item) => item === null);
  };

  _handleSubmit = (e, onSuccess) => {
    e && e.preventDefault();

    const {
      name,
      validFrom,
      // validTo,
      // value,
      customer,
      variants,
      shippingType,
      billingType,
      weekDay,
      locationId,
      deliveryCompany,
      paymentMethod,
      nextDate,
      originalSubscriptionData,
    } = this.state;

    const valid = this._validate();

    if (!valid) return;

    this.setState({ loading: true, error: '' });

    const {
      match: {
        params: { id },
      },
    } = this.props;

    const data = {
      name,
      // amount: toCoins(value),
      customerId: customer.id,
      shippingType,
      billingType,
      weekday: weekDay,
      validFrom: validFrom ? validFrom.startOf('day').toISOString(true) : '',
      // validTo: validTo ? validTo.endOf('day').toISOString(true) : "",
      nextDate: nextDate ? moment(nextDate).startOf('day').toISOString(true) : nextDate,
      ...(locationId ? { locationId } : {}),
      ...(deliveryCompany ? { deliveryCompanyId: deliveryCompany, locationId: UNION_SQUARE_GREEN_MARKET_ID } : {}),
      ...(billingType !== BILLING_UPFRONT ? { paymentMethodId: paymentMethod } : {}),
    };

    data.variants = variants.map((item) => ({
      id: item.id,
      amount: Number(toCoins(item.value)),
      quantity: Number(toCoins(item.quantity)),
      period: item.period ? item.period : PERIODS.PER_WEEK,
      ordersQuantity: item.ordersQuantity,
      nextDate: item?.nextDate ? item.nextDate : null,
    }));

    if (this.state.tags && this.state.tags.length) {
      data['tags'] = this.state.tags;
    }

    // Send on server only changed params
    const changedParams = objectParamComparison(data, originalSubscriptionData, {
      customerId: { key: 'customer.id' },
      billingType: { key: 'type' },
      validFrom: { key: 'validFrom', type: PARAM_COMPARISON_TYPES.DATE },
      nextDate: { key: 'nextDate', type: PARAM_COMPARISON_TYPES.DATE },
      locationId: { key: 'market.id' },
      deliveryCompanyId: { key: 'deliveryCompany.id' },
      paymentMethodId: { key: 'paymentMethodId' },
      variants: { key: 'variants', type: PARAM_COMPARISON_TYPES.VARIANT },
      tags: { key: 'tags', type: PARAM_COMPARISON_TYPES.ARRAY },
      weekday: { key: 'period.weekday' },
    });

    if (id && id === 'new') {
      apiAddNewSubscription(
        data,
        (response) => {
          this.setState({ loading: false });
          this.props.onSuccess(response);
        },
        (error) => {
          this.setState({
            loading: false,
            error: error.response.data.message || '',
          });
          console.log(error.response);
        }
      );
    } else {
      apiEditSubscriptionById(
        id,
        changedParams,
        (response) => {
          this.setState({ loading: false });
          if (onSuccess) {
            onSuccess(response);
          } else {
            this.props.onSuccess(response);
          }
        },
        (error) => {
          this.setState({
            loading: false,
            error: error?.response?.data?.message || '',
          });
          console.log(error);
        }
      );
    }
  };

  saveAnyChange = () => {
    clearTimeout(this.saveAnyChangeTimer);
    const {
      match: {
        params: { id },
      },
    } = this.props;

    if (id && id !== 'new') {
      this.saveAnyChangeTimer = setTimeout(() => {
        this._handleSubmit(undefined, (res) => {
          this.setLoadedOrder(res);
        });
      }, 1000);
    }
  };

  _handleAddCustomer = (customer) => {
    this._getSelectedCustomerFullInfo(customer, true);

    this.setState({
      customer: customer,
    });
  };

  _getSelectedCustomerFullInfo = (customer, initial = false) => {
    apiCustomerRetrieve(
      customer,
      (response) => {
        const paymentMethods = response.paymentMethods;

        this.setState({
          customerFullInfo: customer,
          paymentMethods,
          ...(initial && !!paymentMethods.length ? { paymentMethod: paymentMethods[0].id } : {}),
        });
      },
      (error) => {
        this.setState({ error: error.response && error.response.data && error.response.data.message });
      }
    );
  };

  _handleDeleteCustomer = () => {
    this.setState({
      customer: null,
    });
  };

  _handleAddVariant = (variant) => {
    this.setState(
      {
        variants: _.unionBy(this.state.variants, [variant], (item) => item.id),
      },
      () => {
        this.table.forceReload();
      }
    );
  };

  _handleDeleteVariant = (variant) => {
    this.setState(
      {
        variants: this.state.variants.filter((item) => item.id !== variant.id),
      },
      () => {
        this.table.forceReload();
      }
    );
  };

  _onFinishPriceEditing = (variant, price) => {
    this.setState((state) => {
      for (let i = 0; i < state.variants.length; i++) {
        if (state.variants[i].id === variant.id) {
          state.variants[i].value = price;
          break;
        }
      }
      return state;
    });
  };

  _onFinishLimitEditing = (variant, quantity) => {
    this.setState((state) => {
      for (let i = 0; i < state.variants.length; i++) {
        if (state.variants[i].id === variant.id) {
          state.variants[i].quantity = quantity;
          break;
        }
      }
      return state;
    });
  };

  _onFinishVariantDataEditing = (propertiesObj, variant) => {
    this.setState((state) => {
      for (let i = 0; i < state.variants.length; i++) {
        if (state.variants[i].id === variant.id) {
          state.variants[i] = { ...state.variants[i], ...propertiesObj };
          break;
        }
      }
      return state;
    });
  };

  _onFinishPeriodEditing = (variant, period) => {
    this.setState((state) => {
      for (let i = 0; i < state.variants.length; i++) {
        if (state.variants[i].id === variant.id) {
          state.variants[i].period = period;
          break;
        }
      }
      return state;
    });
  };

  _onScheduleDataChange = (data) => {
    const scheduleData = new ScheduleDates(data);
    const nextDate = data.nextDate ? moment(data.nextDate) : null;

    this.setState({
      scheduleData,
      nextDate,
      originalSubscriptionData: {
        ...this.state.originalSubscriptionData,
        nextDate,
      },
    });
  };

  changeDeliveryMethod = (e) => {
    const value = e.target.value;

    const companyReg = new RegExp('^dc_');
    const locationReg = new RegExp('^loc_');

    if (value === -1) return;

    if (!!value.match(companyReg)) {
      this.setState({
        shippingType: SHIPPING_TYPES.SHIPPING_DELIVERY,
        deliveryCompany: value,
        locationId: null,
      });
    }
    if (!!value.match(locationReg)) {
      this.setState({
        shippingType: SHIPPING_TYPES.SHIPPING_PICKUP,
        locationId: value,
        deliveryCompany: null,
      });
    }
  };

  deliverySelectGetValue = () => {
    const { shippingType, locationId, deliveryCompany } = this.state;

    if (shippingType === SHIPPING_TYPES.SHIPPING_DELIVERY) return deliveryCompany ? deliveryCompany : -1;
    if (shippingType === SHIPPING_TYPES.SHIPPING_PICKUP) return locationId ? locationId : -1;

    if (shippingType !== SHIPPING_TYPES.SHIPPING_PICKUP) return -1;

    return shippingType;
  };

  drawPaymentMethod = (paymentMethod) => {
    const { classes } = this.props;

    if (paymentMethod.method === 'stripe_ach') {
      return (
        <MenuItem value={paymentMethod.id} key={paymentMethod.id}>
          <Typography align={'left'} component="p">
            {paymentMethod.bankName}, {paymentMethod.status.toUpperCase()}
            {paymentMethod.default && (
              <span className={classes.defaultPaymentMethod}>
                {' '}
                (<FormattedMessage id="global.default" />) &nbsp;
              </span>
            )}
          </Typography>
          <Typography align={'left'} component="p">
            **** **** **** {paymentMethod.last4}
          </Typography>
          <Typography align={'left'} component="p">
            {paymentMethod.currency.toUpperCase()}
          </Typography>
        </MenuItem>
      );
    } else {
      return (
        <MenuItem value={paymentMethod.id}>
          <Typography align={'left'} component="p">
            {paymentMethod.brand}, {paymentMethod.expMonth}/{paymentMethod.expYear}
            {paymentMethod.default && (
              <span className={classes.defaultPaymentMethod}>
                {' '}
                (<FormattedMessage id="global.default" />) &nbsp;
              </span>
            )}
          </Typography>
          <Typography align={'left'} component="p">
            **** **** **** {paymentMethod.last4}
          </Typography>
          <Typography align={'left'} component="p">
            {paymentMethod.funding}
          </Typography>
        </MenuItem>
      );
    }
  };

  getOrdersTable = () => {
    const { classes, intl } = this.props;
    const { orders } = this.state;

    if (!!orders.length) {
      return (
        <div className={classes.ordersTableWrapper}>
          <Paper>
            <AdminDXTable
              onRef={(orderTable) => (this.orderTable = orderTable)}
              enableToolbar={false}
              enableSearch={false}
              enablePager={false}
              apiRetrieve={(params, onSuccess) => onSuccess(this.state.orders || [])}
              pageSize={10}
              columnExtensions={[{ columnName: 'number', width: 100 }]}
              columns={[
                {
                  name: 'number',
                  title: intl.formatMessage({ id: 'global.number' }),
                  getCellValue: (row) => {
                    return this.state.orders.findIndex((item) => item.id === row.id) + 1;
                  },
                },
                { name: 'name', title: intl.formatMessage({ id: 'global.orderName' }) },
                { name: 'shippingType', title: intl.formatMessage({ id: 'global.shippingType' }) },
                {
                  name: 'shippingDate',
                  title: intl.formatMessage({ id: 'global.shippingDate' }),
                  getCellValue: (row) => (row.shippingDate && moment(row.shippingDate)) || '-',
                },
              ]}
              dateColumnNames={['shippingDate']}
              headerCellProps={{ style: { whiteSpace: 'normal' } }}
              onRowClick={(item) => this.handleOpenOrderNextTab(item)}
            />
          </Paper>
        </div>
      );
    }
  };

  handleOpenOrderNextTab = (item) => {
    window.open(`/order/${item.id}`);
  };

  _handleCreateNewOrder = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    this.setState({ loading: true, nextOrderLoading: true });
    apiSubscriptionCreateNextOrder(
      id,
      (response) => {
        this.setState(
          (state) => {
            const newOrder = new SubscriptionOrderInTable(response);
            state.orders.push(newOrder);
            state.loading = false;
            state.nextOrderLoading = false;
            state.nextOrderError = '';
            return { ...state };
          },
          () => {
            this.getScheduleData(id);
            this?.orderTable?.forceReload && this.orderTable.forceReload();
          }
        );
      },
      (error) => {
        this.setState({
          loading: false,
          nextOrderLoading: false,
          nextOrderError: error?.response?.data?.message || '',
        });
      }
    );
  };

  drawTagsSelector = () => {
    const { intl, classes } = this.props;

    return (
      <PrimaryChip
        className={classes.textField}
        fullWidth
        value={this.state.tags}
        allowDuplicates={false}
        onChange={(chips) => {
          // prevent duplicate values
          let mergedArray = [...this.state.tags, ...chips];
          mergedArray = mergedArray.filter((value, index, array) => array.indexOf(value) === index);
          this._handleChange('tags', mergedArray);
        }}
        onDelete={(chip) => {
          let chipsArr = [...this.state.tags];
          const index = chipsArr.indexOf(chip);
          chipsArr.splice(index, 1);
          this._handleChange('tags', chipsArr);
        }}
        label={intl.formatMessage({ id: 'global.tags' })}
        InputLabelProps={{ shrink: true }}
      />
    );
  };

  isNextDateExist = () => {
    return moment(this.state?.nextDate).isValid();
  };

  getLineItemsActions = () => {
    const {
      intl,
      match: {
        params: { id },
      },
    } = this.props;
    const { validFrom, validTo, scheduleData, isScheduleExist, scheduleDataLoading } = this.state;
    const isNew = !id || id === 'new';

    let actions = [
      {
        icon: <CancelIcon style={{ color: COLORS.violet, fontSize: 18 }} />,
        action: (row) => this._handleDeleteVariant(row),
      },
    ];

    if (!isNew && this.isNextDateExist()) {
      actions = [
        {
          icon: <EventIcon style={{ color: COLORS.blue, fontSize: 18 }} />,
          action: (row) => this._handleDeleteVariant(row),
          component: (props) =>
            TableAction(
              TABLE_ACTION_TYPES.NEXT_DATE,
              this._onFinishVariantDataEditing,
              this._onScheduleDataChange,
              intl,
              {
                validFrom,
                validTo,
                id,
                isScheduleExist,
                scheduleDataLoading,
                scheduleData,
              },
              { ...props }
            ),
        },
        ...actions,
      ];
    }

    return actions;
  };

  render() {
    const { intl, classes } = this.props;
    const {
      errors,
      customer,
      nextOrderError,
      error,
      period,
      weekDay,
      validFrom,
      validTo,

      scheduleData,
      isScheduleExist,
      scheduleDataLoading,
      billingType,
      columnExtensions,
      columns,
      columnWidths,
      originalSubscriptionData,
      orders,
    } = this.state;
    const {
      match: {
        params: { id },
      },
      producer,
    } = this.props;
    const subscriptionShippingTypeValue = this.deliverySelectGetValue();
    const today = new Date();

    return (
      <form onSubmit={this._handleSubmit} className={classes.form}>
        {this.state.loading && <Spinner size={40} />}
        {this.state.nextOrderLoading && (
          <div className={classes.absoluteSpinner}>
            <Spinner size={40} />
          </div>
        )}

        <PrimaryTextField
          value={this.state.name}
          className={classes.textField}
          label={intl.formatMessage({ id: 'csa.name' })}
          onChange={(event) => this._handleChange('name', event.target.value)}
          helperText={errors.name && intl.formatMessage({ id: errors.name })}
          error={!!errors.name}
        />

        <PrimaryTextField
          disabled={true}
          className={classes.textField}
          value={this.state.value}
          label={intl.formatMessage({ id: 'csa.amount' })}
          onChange={(event) => this._handleChange('value', event.target.value)}
          helperText={errors.value && intl.formatMessage({ id: errors.value })}
          InputProps={{
            endAdornment: <InputAdornment position="end">$</InputAdornment>,
          }}
          error={!!errors.value}
        />

        <PrimaryInlineDatePicker
          value={this.state.validFrom}
          className={classes.textField}
          label={intl.formatMessage({ id: 'csa.startDate' })}
          onChange={(event) => this._handleChange('validFrom', event)}
          TextFieldComponent={PrimaryTextField}
          {...(moment(originalSubscriptionData?.validFrom).isBefore(today, 'day') || !!orders.length
            ? { disabled: true }
            : { minDate: today })}
        />

        <PrimaryInlineDatePicker
          disabled={true}
          className={classes.textField}
          value={this.state.validTo}
          label={intl.formatMessage({ id: 'csa.endDate' })}
          onChange={(event) => this._handleChange('validTo', event)}
          TextFieldComponent={PrimaryTextField}
        />

        <PrimarySelect
          formControlClassName={classes.textField}
          value={weekDay}
          onChange={(e) => this.setState({ weekDay: e.target.value })}
          label={intl.formatMessage({ id: 'prepurchases.subscription.weekday' })}>
          {WEEK_DAYS.map((day) => (
            <MenuItem style={{ textTransform: 'capitalize' }} key={day} value={day}>
              {capitalizeFirstLetter(day)}
            </MenuItem>
          ))}
        </PrimarySelect>

        <PrimarySelect
          formControlClassName={classes.textField}
          value={subscriptionShippingTypeValue}
          label={intl.formatMessage({ id: 'prepurchases.subscription.shippingType' })}
          onChange={this.changeDeliveryMethod}
          helperText={errors.subscription && intl.formatMessage({ id: errors.subscription })}
          error={!!errors.subscription}>
          {subscriptionShippingTypeValue === -1 && (
            <MenuItem key={-1} value={-1}>
              {intl.formatMessage({ id: 'global.selectDeliveryMethod' })}
            </MenuItem>
          )}

          {!!producer.deliveryCompanies.length &&
            producer.deliveryCompanies.map((company) => (
              <MenuItem key={company.id} value={company.id}>
                {intl.formatMessage({ id: 'global.delivery' })} - {company.name}
              </MenuItem>
            ))}

          {!!producer.locations.length &&
            producer.locations.map((location) => (
              <MenuItem key={location.id} value={location.id}>
                {intl.formatMessage({ id: 'global.pickup' })} - {location.name}
              </MenuItem>
            ))}
        </PrimarySelect>

        <PrimarySelect
          disabled={id !== 'new'}
          formControlClassName={classes.textField}
          value={billingType}
          helperText={errors.billingType && intl.formatMessage({ id: errors.billingType })}
          error={!!errors.billingType}
          onChange={(event) => this._handleChange('billingType', event.target.value)}
          label={intl.formatMessage({
            id: 'screen.variants.subscriptionBillingType',
          })}>
          <MenuItem value="">{intl.formatMessage({ id: 'global.none' })}</MenuItem>
          <MenuItem value={BILLING_UPFRONT}>{intl.formatMessage({ id: 'global.upfront' })}</MenuItem>
          <MenuItem value={BILLING_RECURRING}>{intl.formatMessage({ id: 'global.recurring' })}</MenuItem>
        </PrimarySelect>

        {id && id === 'new' && (
          <>
            <div className={classes.textField} />
            <CustomersSuggestions
              selected={null}
              inputProps={{
                className: classNames(classes.textField, classes.fullWidthTextInput),
                helperText: errors.customer && intl.formatMessage({ id: errors.customer }),
                error: !!errors.customer,
              }}
              allowSwitchCustomerType
              labelExtractor={(item) => item.customerName}
              inputLabel={intl.formatMessage({ id: 'csa.customer' })}
              onDetach={() => {}}
              switchCustomerWrapperClass={classes.switchCustomerWrapper}
              onAttach={(customer) =>
                this._handleAddCustomer({
                  ...customer,
                  ...{
                    skipFromServerSideDeletion: true,
                    name: customer.customerName,
                  },
                })
              }
            />
          </>
        )}

        {billingType !== BILLING_UPFRONT && (
          <PrimarySelect
            disabled={!this.state.paymentMethods.length || (id && id !== 'new')}
            value={this.state.paymentMethods.length ? this.state.paymentMethod : ''}
            onChange={(event) => this._handleChange('paymentMethod', event.target.value)}
            label={intl.formatMessage({ id: 'subscriptions.form.paymentMethods' })}
            formControlClassName={classes.textField}
            helperText={errors.paymentMethod && intl.formatMessage({ id: errors.paymentMethod })}
            error={!!errors.paymentMethod}>
            {this.state.paymentMethods.map((method) => this.drawPaymentMethod(method))}
          </PrimarySelect>
        )}

        {id && id !== 'new' && this.drawTagsSelector()}

        <div className={classes.chipsWrap}>
          {id && id !== 'new' && (
            <Typography className={classes.customer}>{intl.formatMessage({ id: 'global.customer' })}:</Typography>
          )}

          {customer && (
            <CustomerNameChip
              isNew={id && id === 'new'}
              customer={customer}
              onClick={() => {
                window.open(`/customers/${customer.id}`);
              }}
              onDelete={(customer) => this._handleDeleteCustomer(customer)}
            />
          )}
        </div>

        {id && id === 'new' && <div style={{ width: '100%' }}>{this.drawTagsSelector()}</div>}

        <React.Fragment>
          <VariantsSuggestions
            selected={null}
            inputProps={{ className: classNames(classes.textField, classes.fullWidthTextInput) }}
            labelExtractor={(item) => `${item.name}`}
            inputLabel={intl.formatMessage({ id: 'csa.products' })}
            onDetach={() => {}}
            clearAfterSelect
            onAttach={(product, variant) =>
              this._handleAddVariant({
                id: variant.id,
                value: 0,
                product: product.name,
                name: variant?.name || '',
              })
            }
          />

          <div className={classes.productsTableWrap}>
            {errors.variants && <ErrorBox error={errors.variants} />}

            <Paper>
              <AdminDXTable
                onRef={(table) => (this.table = table)}
                cellRenderer={(...props) =>
                  TableCell(
                    this._onFinishPriceEditing,
                    this._onFinishLimitEditing,
                    this._onFinishPeriodEditing,
                    this._onFinishVariantDataEditing,
                    this._onScheduleDataChange,
                    intl,
                    period,
                    {
                      validFrom,
                      validTo,
                      id,
                      isScheduleExist,
                      scheduleDataLoading,
                      scheduleData,
                    },
                    ...props
                  )
                }
                enableToolbar={false}
                enableSearch={false}
                enablePager={false}
                apiRetrieve={(params, onSuccess) => onSuccess(this.state.variants || [])}
                pageSize={10}
                columnExtensions={columnExtensions}
                hiddenColumnNames={this.state.hiddenColumns}
                columns={columns}
                actionsTitle={' '}
                actionsHeaderCellStyle={{ textAlign: 'center', paddingRight: 0 }}
                actionsCellStyle={{ textAlign: 'center', paddingRight: 0 }}
                actionsColumnProps={{
                  width: 100,
                }}
                headerCellProps={{ style: { whiteSpace: 'normal' } }}
                actions={this.getLineItemsActions()}
                onColumnWidthsChange={(widths) => {
                  this.setState({ columnWidths: widths });
                }}
                minColumnWidth={DEFAULT_MIN_CELL_WIDTH}
                maxColumnWidth={DEFAULT_MAX_CELL_WIDTH}
                columnWidths={columnWidths}
              />
            </Paper>
          </div>
        </React.Fragment>

        {!scheduleDataLoading && this.isNextDateExist() && (
          <ScheduleInput
            value={this.state?.originalSubscriptionData?.nextDate}
            scheduleData={scheduleData}
            onUpdateScheduleData={(newScheduleData) => {
              this.setState({ scheduleData: new ScheduleDates(newScheduleData) });
            }}
            // Producer should be able to move date to any date in the future
            minDate={moment().add(1, 'days')}
            isScheduleExist={isScheduleExist}
            onDone={(event) => {
              this._handleChange('nextDate', moment(event).toISOString(true), this.saveAnyChange);
            }}
            onDateChange={(data, onSuccess, onError) => {
              apiChangeSubscriptionScheduleDateById(id, data, onSuccess, onError);
            }}
            onDateMoveSeries={(data, onSuccess, onError) => {
              apiMoveSeriesSubscriptionScheduleDatesById(id, data, onSuccess, onError);
            }}
            onDateDelete={(data, onSuccess, onError) => {
              apiDeleteSubscriptionScheduleDateById(id, data, onSuccess, onError);
            }}
            TextFieldComponent={PrimaryTextField}
            inputProps={{
              className: classes.textField,
              label: intl.formatMessage({ id: 'csa.subscriptionNextOrder' }),
              error: errors.nextDate,
              helperText: errors.nextDate,
            }}
            staticValueMode
          />
        )}

        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 20 }}>
          <PrimaryButton
            disabled={this.state.nextOrderLoading || !id || id === 'new' || !this.isNextDateExist()}
            type="button"
            onClick={this._handleCreateNewOrder}>
            {intl.formatMessage({ id: 'csa.subscriptionAddNextDate' })}
          </PrimaryButton>
        </div>

        {!!nextOrderError && (
          <div className={classes.errorBoxWrapper}>
            <ErrorBox error={nextOrderError} />
          </div>
        )}

        {this.getOrdersTable()}

        {error && (
          <div className={classes.errorBoxWrapper}>
            <ErrorBox error={error} />
          </div>
        )}

        <div className={classes.actionsWrap}>
          <PrimaryButton disabled={this.state.loading} type="submit">
            {intl.formatMessage({ id: 'global.save' })}
          </PrimaryButton>
        </div>
      </form>
    );
  }
}

export default injectIntl(withStyles(styles)(SubscriptionForm));
