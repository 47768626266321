import React from "react";
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

const styles = {
  image: {
    height: '44px'
  },
  imageBox: {
    width: '100%'
  },
};

const propTypes = {
  signature: PropTypes.object.isRequired
};

const Signature = (props) => {
  const {
    classes,
    signature,
  } = props;

  return (
    <React.Fragment>
      {signature.image && (
        <div className={classes.imageBox}>
          <img className={classes.image}
               alt={"Order signature"}
               src={signature.image.src}/>
        </div>
      )}
      {!signature.image && null}
    </React.Fragment>
  );
};

Signature.propTypes = propTypes;

export default withStyles(styles)(Signature);