import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import EmailIcon from '@material-ui/icons/Email';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccountBox from '@material-ui/icons/AccountBox';
import AppsIcon from '@material-ui/icons/Apps';
import InfoIcon from '@material-ui/icons/Info';
import Collapse from '@material-ui/core/Collapse';
import CardGiftCardIcon from '@material-ui/icons/CardGiftcard';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import BarChartIcon from '@material-ui/icons/BarChart';
import SettingsIcon from '@material-ui/icons/Settings';
import HomeIcon from '@material-ui/icons/Home';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../../hocs';
import { COLORS } from '../../../helpers';
import Producer from '../../../entities/Producer';
import classNames from 'classnames';

const propTypes = {
  fullWidth: PropTypes.bool,
  history: PropTypes.object,
};

const styles = {
  white: {
    color: COLORS.white,
  },
  listItem: {
    width: 'auto',
  },
  empty: {
    width: 'auto',
  },
  parent: {
    width: 'auto',
    marginTop: 11,
    paddingTop: 0,
    paddingBottom: 0,
  },
  nested: {
    width: 'auto',
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 11,
    marginLeft: 8,
  },
  nestedMB0: {
    width: 'auto',
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 0,
    marginLeft: 8,
  },
  listItemIcon: {
    marginLeft: 8,
  },
  selectedItem: {
    backgroundColor: '#676767',
    '&:hover': {
      backgroundColor: '#676767',
    },
  },
  hidden: {
    display: 'none',
  },
};

export const SIDEBAR_NAVIGATIONS = {
  home: ['/home'],
  orders: ['/orders'],
  products: ['/products'],
  product: ['/product/'],
  productsProperties: ['/products/properties'],
  productsUnique: ['/products/unique'],
  collections: ['/collections'],
  customers: ['/customers'],
  reports: ['/reports'],
  discounts: ['/discounts'],
  prepurchases: ['/prepurchases'],
  subscriptions: ['/subscriptions'],
  memberService: ['/member-service'],
  settings: ['/settings'],
  suppliers: ['/suppliers'],
  suppliersOrders: ['/suppliers/orders'],
  about: ['/about'],
};

const SidebarMenu = (props) => {
  const { intl, classes, history, match, fullWidth, producer } = props;

  const isCurrentPage = (type, exact = false) => {
    let isCurrent = false;

    SIDEBAR_NAVIGATIONS[type].forEach((link) => {
      if (!!match?.url && !!match.url.match(link) && !exact) {
        isCurrent = true;
      }
      if (exact && match.url === link) {
        isCurrent = true;
      }
    });
    return isCurrent;
  };

  return (
    <React.Fragment>
      <List>
        {Producer.hasAccessToSection('home') && (
          <ListItem
            className={classNames([classes.listItem, isCurrentPage('home') && classes.selectedItem])}
            button
            onClick={() => history.push('/home')}>
            <ListItemIcon className={classes.listItemIcon}>
              <HomeIcon className={classes.white} />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.white }} primary={intl.formatMessage({ id: 'global.home' })} />
          </ListItem>
        )}

        {Producer.hasAccessToSection('orders') && (
          <React.Fragment>
            <ListItem
              className={classNames([
                fullWidth ? classes.parent : classes.empty,
                isCurrentPage('orders', true) && classes.selectedItem,
              ])}
              button
              onClick={() => history.push('/orders')}>
              <ListItemIcon className={classes.listItemIcon}>
                <AssignmentIcon className={classes.white} />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.white }}
                primary={intl.formatMessage({ id: 'global.orders' })}
              />
            </ListItem>

            <Collapse in className={classNames([!fullWidth && classes.hidden, 'sidebar-showOnHover'])}>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={classNames([
                    classes.nested,
                    isCurrentPage('subscriptions') && classes.selectedItem,
                    Producer.hasAccessToMemberService() && classes.nestedMB0,
                  ])}
                  onClick={() => history.push('/subscriptions')}>
                  <ListItemText
                    classes={{ primary: classes.white }}
                    inset
                    primary={intl.formatMessage({ id: 'global.subscriptions' })}
                  />
                </ListItem>
              </List>

              {Producer.hasAccessToMemberService() && (
                <List component="div" disablePadding>
                  <ListItem
                    button
                    className={classNames([classes.nested, isCurrentPage('memberService') && classes.selectedItem])}
                    onClick={() => history.push('/member-service')}>
                    <ListItemText
                      classes={{ primary: classes.white }}
                      inset
                      primary={intl.formatMessage({ id: 'global.memberService' })}
                    />
                  </ListItem>
                </List>
              )}
            </Collapse>
          </React.Fragment>
        )}

        {Producer.hasAccessToSection('products') && (
          <React.Fragment>
            <ListItem
              className={classNames([
                fullWidth ? classes.parent : classes.empty,
                isCurrentPage('products', true) && classes.selectedItem,
                isCurrentPage('product') && classes.selectedItem,
              ])}
              button
              onClick={() => history.push('/products')}>
              <ListItemIcon className={classes.listItemIcon}>
                <AppsIcon className={classes.white} />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.white }}
                primary={intl.formatMessage({ id: 'global.products' })}
              />
            </ListItem>

            <Collapse in className={classNames([!fullWidth && classes.hidden, 'sidebar-showOnHover'])}>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={classNames([classes.nestedMB0, isCurrentPage('collections') && classes.selectedItem])}
                  onClick={() => history.push('/collections')}>
                  <ListItemText
                    classes={{ primary: classes.white }}
                    inset
                    primary={intl.formatMessage({ id: 'global.collections' })}
                  />
                </ListItem>
                <ListItem
                  button
                  className={classNames([
                    classes.nestedMB0,
                    isCurrentPage('productsProperties') && classes.selectedItem,
                  ])}
                  onClick={() => history.push('/products/properties')}>
                  <ListItemText
                    classes={{ primary: classes.white }}
                    inset
                    primary={intl.formatMessage({ id: 'global.typesAndUnits' })}
                  />
                </ListItem>
                <ListItem
                  button
                  className={classNames([classes.nested, isCurrentPage('productsUnique') && classes.selectedItem])}
                  onClick={() => history.push('/products/unique')}>
                  <ListItemText
                    classes={{ primary: classes.white }}
                    inset
                    primary={intl.formatMessage({ id: 'global.uniqueItems' })}
                  />
                </ListItem>
              </List>
            </Collapse>
          </React.Fragment>
        )}

        {Producer.hasAccessToSection('customers') && (
          <ListItem
            className={classNames([classes.listItem, isCurrentPage('customers') && classes.selectedItem])}
            button
            onClick={() => history.push('/customers')}>
            <ListItemIcon className={classes.listItemIcon}>
              <SupervisorAccountIcon className={classes.white} />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.white }}
              primary={intl.formatMessage({ id: 'global.customers' })}
            />
          </ListItem>
        )}

        {Producer.hasAccessToSection('reports') && (
          <ListItem
            className={classNames([classes.listItem, isCurrentPage('reports') && classes.selectedItem])}
            button
            onClick={() => history.push('/reports')}>
            <ListItemIcon className={classes.listItemIcon}>
              <BarChartIcon className={classes.white} />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.white }} primary={intl.formatMessage({ id: 'global.reports' })} />
          </ListItem>
        )}

        {Producer.hasAccessToSection('discounts') && (
          <React.Fragment>
            <ListItem
              button
              className={classNames([
                fullWidth ? classes.parent : classes.empty,
                isCurrentPage('discounts') && classes.selectedItem,
              ])}
              onClick={() => history.push('/discounts')}>
              <ListItemIcon className={classes.listItemIcon}>
                <CardGiftCardIcon className={classes.white} />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.white }}
                primary={intl.formatMessage({ id: 'global.discounts' })}
              />
            </ListItem>

            <Collapse in className={classNames([!fullWidth && classes.hidden, 'sidebar-showOnHover'])}>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={classNames([classes.nested, isCurrentPage('prepurchases') && classes.selectedItem])}
                  onClick={() => history.push('/prepurchases')}>
                  <ListItemText
                    classes={{ primary: classes.white }}
                    inset
                    primary={intl.formatMessage({ id: 'prepurchases.sideBar' })}
                  />
                </ListItem>
              </List>
            </Collapse>
          </React.Fragment>
        )}

        {Producer.hasAccessToSection('account') && (
          <ListItem
            className={classNames([classes.listItem, isCurrentPage('settings') && classes.selectedItem])}
            button
            onClick={() => history.push('/settings')}>
            <ListItemIcon className={classes.listItemIcon}>
              <SettingsIcon className={classes.white} />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.white }} primary={intl.formatMessage({ id: 'global.account' })} />
          </ListItem>
        )}

        {Producer.hasAccessToSection('suppliers') && (
          <>
            <ListItem
              className={classNames([
                producer.supplierOrdering && !!producer?.customer ? classes.parent : classes.empty,
                isCurrentPage('suppliers', true) && classes.selectedItem,
              ])}
              button
              onClick={() => history.push('/suppliers')}>
              <ListItemIcon className={classes.listItemIcon}>
                <AccountBox className={classes.white} />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.white }}
                primary={intl.formatMessage({ id: 'global.suppliers' })}
              />
            </ListItem>

            {producer.supplierOrdering && !!producer?.customer && (
              <Collapse in className={classNames([!fullWidth && classes.hidden, 'sidebar-showOnHover'])}>
                <List component="div" disablePadding>
                  <ListItem
                    button
                    className={classNames([classes.nested, isCurrentPage('suppliersOrders') && classes.selectedItem])}
                    onClick={() => history.push('/suppliers/orders')}>
                    <ListItemText
                      classes={{ primary: classes.white }}
                      inset
                      primary={intl.formatMessage({ id: 'global.supplierOrders' })}
                    />
                  </ListItem>
                </List>
              </Collapse>
            )}
          </>
        )}

        <ListItem
          className={classNames([classes.listItem, isCurrentPage('about') && classes.selectedItem])}
          button
          onClick={() => history.push('/about')}>
          <ListItemIcon className={classes.listItemIcon}>
            <InfoIcon className={classes.white} />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.white }} primary={intl.formatMessage({ id: 'global.about' })} />
        </ListItem>

        <ListItem
          className={classNames([classes.listItem])}
          button
          component={'a'}
          href={'mailto:info@fellowfarmer.com?&subject=Fellow%20Farmer%20%E2%80%A2%20App%20Feedback'}>
          <ListItemIcon className={classes.listItemIcon}>
            <EmailIcon className={classes.white} />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.white }} primary={intl.formatMessage({ id: 'global.emailUs' })} />
        </ListItem>
      </List>
    </React.Fragment>
  );
};

SidebarMenu.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    producer: state.producer.object,
  };
}

export default withStyles(styles, { withTheme: true })(injectIntl(withRouter(connect(mapStateToProps)(SidebarMenu))));
