import { getAcl } from '../helpers';

class PickupsUser {
  constructor(props = {}) {
    this.id = props.id || null;
    this.accounts = props.account || [];
    this.active = props.active || false;
    this.adminTabs = props.adminTabs || [];
    this.collections = props.collections || [];
    this.discounts = props.discounts || [];
    this.shippingDays = props.shippingDays || [];
    this.shippingRates = props.shippingRates || [];
    this.handle = props.handle || '';
    this.username = props.username || '';
    // Product properties
    this.subTypes = props.subTypes || {};
    this.types = props.types || {};
    this.units = props.units || {};
    // Address
    this.address1 = props.address1 || '';
    this.address2 = props.address2 || '';
    this.city = props.city || '';
    this.countryCode = props.countryCode || '';
    this.province = props.province || '';
    this.zip = props.zip || '';
    // Personal Info
    this.name = props.name || '';
    this.email = props.email || '';
    this.description = props.description || '';
    this.wholesaleDescription = props.wholesaleDescription || '';
    this.nameShort = props.nameShort || '';
    this.phone = props.phone || '';
    // Payments
    this.stripeAccountId = props.stripeAccountId || '';
    this.payworksConfig = props.payworksConfig || null;
    this.prepurchases = props.prepurchases || [];
    // Images
    this.image = props.image || null;
    this.logotype = props.logotype || null;
  }

  /**
   * Checks whether account has access to section
   * @param {string} section - section name
   * @return {boolean}
   */
  static hasAccessToSection(section) {
    let acl = getAcl();

    return acl
      ? acl[section]
      : // Root accounts do not have acl prop
        true;
  }
}

export default PickupsUser;
