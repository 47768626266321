import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../../hocs';
import { AdminDialogTitle, AdminDialogContent, CustomersSearch } from '../../../components';
import styles from './styles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import { CUSTOMER_TYPE } from '../../../helpers';

const propTypes = {
  producer: PropTypes.object,
  onApprove: PropTypes.func.isRequired,
  onGooglePlacesHitClick: PropTypes.func.isRequired,
};

const SearchStage = ({ intl, classes, onGooglePlacesHitClick, onApprove }) => {
  return (
    <React.Fragment>
      <AdminDialogTitle title={intl.formatMessage({ id: 'customers.search' })} />
      <AdminDialogContent className={classes.wrapper}>
        <CustomersSearch
          onGooglePlacesHitClick={(...props) => onGooglePlacesHitClick(...props, CUSTOMER_TYPE.WHOLESALE)}
          onHitClick={onApprove}
        />

        <div>
          <h3>{intl.formatMessage({ id: 'global.addCustomerManually' })}</h3>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Card
              className={classes.card}
              key={'empty-hit-wholesale'}
              onClick={() => onGooglePlacesHitClick(null, CUSTOMER_TYPE.WHOLESALE)}>
              <CardContent className={classes.content}>
                <Typography style={{ marginTop: 10, marginBottom: 10 }} className={classes.companyName}>
                  {intl.formatMessage({ id: 'customers.addWholesaleCustomer' })}
                </Typography>
              </CardContent>
            </Card>

            <Card
              className={classes.card}
              key={'empty-hit-retail'}
              onClick={() => onGooglePlacesHitClick(null, CUSTOMER_TYPE.RETAIL)}>
              <CardContent className={classes.content}>
                <Typography style={{ marginTop: 10, marginBottom: 10 }} className={classes.companyName}>
                  {intl.formatMessage({ id: 'customers.addRetailCustomer' })}
                </Typography>
              </CardContent>
            </Card>
          </div>
        </div>
      </AdminDialogContent>
    </React.Fragment>
  );
};

SearchStage.propTypes = propTypes;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(SearchStage)));
