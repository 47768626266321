import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import {withStyles} from '@material-ui/core/styles'
import {COLORS, getDefaultAddressByCustomer} from '../../helpers'

const propTypes = {
  onClick: PropTypes.func,
  customer: PropTypes.object.isRequired,
};

const styles = ({
  customer: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    width: 160,
    height: 140,
    overflow: 'visible',
    marginLeft: 2,
    marginRight: 17,
    marginBottom: 17,
  },
  content: {
    '&:last-child' : {
      padding: '10px 10px',
    },
    padding: 0,
    textAlign: 'center',
  },
  name: {
    textAlign: 'center',
    alignSelf: 'flex-end'
  },
  tile: {
    overflow: 'visible'
  },
  paper: {
    height: '100%',
    width: '100%'
  },
  companyName: {
    height: 20,
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.text,
    fontWeight: 600,
    fontSize: 14,
  },
  card: {
    marginLeft: 2,
    marginRight: 10,
    marginBottom: 10,
    cursor: 'pointer',
    width: 160,
 },
  text: {
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.text,
    fontSize: 14,
  }
});

const CustomerAsCard = ({customer, classes, onClick}) => {
  const address = getDefaultAddressByCustomer(customer, 'shipping');
  return (
    <Card className={classes.card}
          onClick={() => onClick(customer)} >
      <CardContent className={classes.content}>
        <Typography className={classes.companyName} align={"left"} noWrap >
          {customer.customerName}
        </Typography>

        {address && (
          <React.Fragment>
            <Typography align={"left"} className={classes.text}>
              {address.address1}
            </Typography>
            <Typography align={"left"} className={classes.text}>
              {address.city + ', ' || ""} {address.zip || ''}
            </Typography>
          </React.Fragment>
        )}
      </CardContent>
    </Card>
  );
};

CustomerAsCard.propTypes = propTypes;

export default withStyles(styles)(
  CustomerAsCard
);