import React from "react";
import PropTypes from 'prop-types';
import {
  FormattedMessage,
  injectIntl
} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import { withStyles } from '@material-ui/core/styles'
import {
  toCurrency,
  getLineItemQuantity,
  weightToUser
} from '../../helpers';
import OrderInfoTableCell from './OrderInfoTableCell';
import styles from './styles';

const propTypes = {
  order: PropTypes.object.isRequired
};

const OrderInfo = ({ classes,
                     order,
                     intl }) => {
  return (
    <React.Fragment>
      {order.lineItems.length && (
        <Table className={classes.table}>
          <TableHead>
            <TableRow style={{height: 15}}>
              <OrderInfoTableCell>{intl.formatMessage({id: "global.item"})}</OrderInfoTableCell>
              <OrderInfoTableCell>{intl.formatMessage({id: "global.qty"})}</OrderInfoTableCell>
              <OrderInfoTableCell>{intl.formatMessage({id: "global.unitPrice"})}</OrderInfoTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order.lineItems.map(item => (
              <TableRow className={classes.row}
                        key={item.id}>
                <OrderInfoTableCell>
                  <Typography className={classes.productName} component="p">
                    {item.name.product}
                  </Typography>
                  <Typography className={classes.variantName} component="p">
                    <span className={classes.subType}>{item.productSubType}</span>
                  </Typography>

                  {!!item.note &&
                  <Typography className={classes.note} component="p">
                    <span className={classes.noteTitle}><FormattedMessage id="global.note"/>:</span>
                    {item.note}
                  </Typography>}

                </OrderInfoTableCell>
                <OrderInfoTableCell>
                  {(item.hasOwnProperty('available') && item.available) ?
                    getLineItemQuantity(item) : <FormattedMessage id="global.notAvailable"/>}
                </OrderInfoTableCell>
                <OrderInfoTableCell>
                  {toCurrency(item.unitPrice, intl)} / {weightToUser(item.unitQuantity)} {item.unit}
                </OrderInfoTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      {!!order.note && (
        <Typography variant="body2"
                    align={"center"}
                    gutterBottom >
          <span className={classes.noteTitle}>
            <FormattedMessage id="order.note"/>
          </span>: {order.note}
        </Typography>
      )}

      {!order.lineItems.length && (
        <Typography variant="subtitle1"
                    align={"center"}
                    gutterBottom >
          <FormattedMessage id="order.noLineItems" />
        </Typography>
      )}

    </React.Fragment>
  );
};

OrderInfo.propTypes = propTypes;

export default withStyles(
  styles, {withTheme: true}
)(injectIntl(OrderInfo));