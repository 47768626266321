import { useNavigate, useLocation, useParams } from 'react-router';

export const withRouter = (Component) => {
  return (props) => {
    const history = useNavigate();
    const location = useLocation();
    const params = useParams();

    const historyAsOld = {
      push: (to, replace) => history(to, { replace }),
      replace: (to) => history(to, { replace: true }), //replace current route to new one without possibility to go back to previous
      goBack: () => history(-1),
      goForward: () => history(1),
    };

    return (
      <Component
        history={historyAsOld}
        location={location}
        params={params}
        match={{
          url: location.pathname,
          params,
          hash: location.hash,
        }}
        {...props}
      />
    );
  };
};
