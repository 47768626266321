import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import moment from 'moment';
import {
  apiRetrieveReportCSA,
  apiRetrieveReportSalesCompanyPerMonth,
  apiRetrieveReportSalesItems,
  apiRetrieveReportSalesItemsGroupedByProducts,
  apiRetrieveReportSalesItemsPerAccount,
  apiRetrieveReportSalesItemsPerCSAAccount,
  apiRetrieveReportSalesOrders,
  apiRetrieveReportSalesOrdersWithLineItems,
  apiRetrieveQuickBooksReconcile,
  apiRetrieveReportCSASummary,
  apiRetrieveReportCSALineItems,
  apiRetrieveReportSalesGroupByLocations,
} from '../../api';
import { cellRenderer as CSACellRenderer } from './Reports/CSA/cells';
import {
  getTreeChildRows as CSAGetTreeChildRows,
  prepareRowsAfterLoad as CSAPrepareRowsAfterLoad,
  tableRowRenderer as CSATableRowRenderer,
  getExpandedRowIds as CSAGetExpandedRowIds,
} from './Reports/CSA/helpers';
import { cellRenderer as ProductsCellRenderer } from './Reports/GroupedByProducts/cells';
import { cellRenderer as CSALineItemsCellRenderer } from './Reports/CSALineItems/cells';
import {
  getTreeChildRows as ProductsGetTreeChildRows,
  prepareRowsAfterLoad as ProductsPrepareRowsAfterLoad,
  tableRowRenderer as ProductsTableRowRenderer,
  getExpandedRowIds as ProductsGetExpandedRowIds,
} from './Reports/GroupedByProducts/helpers';
import {
  getTreeChildRows as CSALineItemsGetTreeChildRows,
  prepareRowsAfterLoad as CSALineItemsPrepareRowsAfterLoad,
  tableRowRenderer as CSALineItemsTableRowRenderer,
  getExpandedRowIds as CSALineItemsGetExpandedRowIds,
} from './Reports/CSALineItems/helpers';
import {
  prepareRowsAfterLoad as OrdersGroupByLocationPrepareRowsAfterLoad,
  getTreeChildRows as OrdersGroupByLocationGetTreeChildRows,
  tableRowRenderer as OrdersGroupByLocationGetTableRowRenderer,
  getExpandedRowIds as OrdersGroupByLocationGetExpandedRowIds,
} from './Reports/OrdersGroupedByLocation/helpers';
import { cellRenderer as OrdersGroupByLocationCellRenderer } from './Reports/OrdersGroupedByLocation/cells';

import { COLORS, toPaper } from '../../helpers';
import {
  DXOrderBookingStatusFilter,
  DXOrderPaidToProducerFilter,
  DXOrderSourceFilter,
  DXQBReportDiffFilter,
  DXReportExcludeFilter,
  DXNextDateFilter,
} from '../../components/AdminDXTable/Filters';

const prepareRowsAfterLoad = (rows) => {
  rows.forEach((row, index) => {
    row.id = 'key_' + index;
  });
  return {
    rows: rows,
  };
};

export const REPORTS_TYPES = {
  REPORT_SALES_ITEMS: 'salesItems',
  REPORT_SALES_ORDERS: 'salesOrders',
  REPORT_SALES_ITEMS_PER_ACCOUNT: 'salesItemsPerAccount',
  REPORT_SALES_ITEMS_PER_CSA_ACCOUNT: 'salesItemsPerCSAAccount',
  REPORT_SALES_COMPANY_PER_MONTH: 'salesCompanyPerMonth',
  REPORT_SALES_ORDERS_WITH_LINE_ITEMS: 'salesOrdersWithLineItems',
  REPORT_SALES_ITEMS_GROUPED_BY_PRODUCTS: 'salesGroupedByProducts',
  REPORT_SALES_ITEMS_GROUPED_BY_LOCATIONS: 'salesGroupedByLocations',
  REPORT_CSA: 'csa',
  REPORT_QUICK_BOOKS_RECONCILE: 'quickBooksReconcile',
  REPORT_CSA_SUMMARY: 'csa.summary',
  SUPPLIER_SALES_ITEMS: 'supplierSalesItems',
  CSA_LINE_ITEMS: 'csaLineItems',

  salesItems: 'salesItems',
  salesOrders: 'salesOrders',
  salesItemsPerAccount: 'salesItemsPerAccount',
  salesItemsPerCSAAccount: 'salesItemsPerCSAAccount',
  salesCompanyPerMonth: 'salesCompanyPerMonth',
  salesOrdersWithLineItems: 'salesOrdersWithLineItems',
  salesGroupedByProducts: 'salesGroupedByProducts',
  salesGroupedByLocations: 'salesGroupedByLocations',
  csa: 'csa',
  quickBooksReconcile: 'quickBooksReconcile',
  'csa.summary': 'csa.summary',
  supplierSalesItems: 'supplierSalesItems',
  csaLineItems: 'csaLineItems',

  // if back end have a saved filter name in old format
  0: 'salesItems',
  1: 'salesOrders',
  2: 'salesItemsPerAccount',
  3: 'salesItemsPerCSAAccount',
  4: 'salesCompanyPerMonth',
  5: 'salesOrdersWithLineItems',
  6: 'salesGroupedByProducts',
  7: 'csa',
  8: 'quickBooksReconcile',
  9: 'csa.summary',
  10: 'supplierSalesItems',
  11: 'csaLineItems',
  12: 'salesGroupedByLocations',
};

export default (intl) => {
  return {
    salesItems: {
      key: 'salesItems',
      name: intl.formatMessage({ id: 'reports.salesItems' }),
      def: {
        apiRetrieve: apiRetrieveReportSalesItems,
        allowExport: true,
        columns: [
          { name: 'productSubType', title: intl.formatMessage({ id: 'reports.column.subType' }) },
          {
            name: 'lineItemNameProduct',
            title: intl.formatMessage({ id: 'reports.column.product' }),
            getCellValue: (row) => (row.lineItemName && row.lineItemName.product) || '—',
          },
          {
            name: 'lineItemNameVariant',
            title: intl.formatMessage({ id: 'reports.column.variant' }),
            getCellValue: (row) => (row.lineItemName && row.lineItemName.variant) || '—',
          },
          { name: 'lineItemUnit', title: intl.formatMessage({ id: 'reports.column.unit' }) },
          { name: 'ordered', title: intl.formatMessage({ id: 'reports.column.ordered' }) },
          { name: 'packed', title: intl.formatMessage({ id: 'reports.column.packed' }) },
          { name: 'listPrice', title: intl.formatMessage({ id: 'reports.column.listPrice' }) },
          {
            name: 'supplier',
            title: intl.formatMessage({ id: 'reports.column.supplier' }),
            getCellValue: (row) => row.supplier || '—',
          },
          { name: 'avgPrice', title: intl.formatMessage({ id: 'reports.column.avgPrice' }) },
          { name: 'price', title: intl.formatMessage({ id: 'reports.column.lineItemTotal' }) },
          {
            name: 'firstSale',
            title: intl.formatMessage({ id: 'reports.column.firstSale' }),
            getCellValue: (row) => moment(row.firstSale).format('MMM D'),
          },
          {
            name: 'lastSale',
            title: intl.formatMessage({ id: 'reports.column.lastSale' }),
            getCellValue: (row) => moment(row.lastSale).format('MMM D'),
          },
        ],
        quantityColumnNames: ['ordered', 'packed'],
        sorting: [{ columnName: 'productSubType', direction: 'asc' }],
        totalSummaryItems: [
          { columnName: 'price', type: 'sum' },
          { columnName: 'listPrice', type: 'sum' },
          { columnName: 'avgPrice', type: 'sum' },
        ],
        columnExtensions: [
          { columnName: 'ordered', align: 'right' },
          { columnName: 'packed', align: 'right' },
          { columnName: 'price', align: 'right' },
          { columnName: 'listPrice', align: 'right' },
          { columnName: 'avgPrice', align: 'right' },
        ],
        filters: [DXOrderSourceFilter, DXReportExcludeFilter],
        prepareRowsAfterLoad: prepareRowsAfterLoad,
        groupingColumns: [],
        enableSelection: false,
      },
    },
    //same as salesItems just supplier field in the end
    supplierSalesItems: {
      key: 'supplierSalesItems',
      name: intl.formatMessage({ id: 'reports.supplierSalesItems' }),
      def: {
        apiRetrieve: apiRetrieveReportSalesItems,
        allowExport: true,
        columns: [
          {
            name: 'supplier',
            title: intl.formatMessage({ id: 'reports.column.supplier' }),
            getCellValue: (row) => row.supplier || '—',
          },
          { name: 'productSubType', title: intl.formatMessage({ id: 'reports.column.subType' }) },
          {
            name: 'lineItemNameProduct',
            title: intl.formatMessage({ id: 'reports.column.product' }),
            getCellValue: (row) => (row.lineItemName && row.lineItemName.product) || '—',
          },
          {
            name: 'lineItemNameVariant',
            title: intl.formatMessage({ id: 'reports.column.variant' }),
            getCellValue: (row) => (row.lineItemName && row.lineItemName.variant) || '—',
          },
          { name: 'lineItemUnit', title: intl.formatMessage({ id: 'reports.column.unit' }) },
          { name: 'ordered', title: intl.formatMessage({ id: 'reports.column.ordered' }) },
          { name: 'packed', title: intl.formatMessage({ id: 'reports.column.packed' }) },
          { name: 'listPrice', title: intl.formatMessage({ id: 'reports.column.listPrice' }) },

          { name: 'avgPrice', title: intl.formatMessage({ id: 'reports.column.avgPrice' }) },
          { name: 'price', title: intl.formatMessage({ id: 'reports.column.lineItemTotal' }) },
          {
            name: 'firstSale',
            title: intl.formatMessage({ id: 'reports.column.firstSale' }),
            getCellValue: (row) => moment(row.firstSale).format('MMM D'),
          },
          {
            name: 'lastSale',
            title: intl.formatMessage({ id: 'reports.column.lastSale' }),
            getCellValue: (row) => moment(row.lastSale).format('MMM D'),
          },
        ],
        quantityColumnNames: ['ordered', 'packed'],
        sorting: [{ columnName: 'productSubType', direction: 'asc' }],
        totalSummaryItems: [
          { columnName: 'price', type: 'sum' },
          { columnName: 'listPrice', type: 'sum' },
          { columnName: 'avgPrice', type: 'sum' },
        ],
        columnExtensions: [
          { columnName: 'ordered', align: 'right' },
          { columnName: 'packed', align: 'right' },
          { columnName: 'price', align: 'right' },
          { columnName: 'listPrice', align: 'right' },
          { columnName: 'avgPrice', align: 'right' },
        ],
        filters: [DXOrderSourceFilter, DXReportExcludeFilter],
        prepareRowsAfterLoad: prepareRowsAfterLoad,
        groupingColumns: [],
        enableSelection: false,
      },
    },

    salesOrders: {
      key: 'salesOrders',
      name: intl.formatMessage({ id: 'reports.salesOrders' }),
      def: {
        apiRetrieve: apiRetrieveReportSalesOrders,
        allowExport: true,
        columns: [
          { name: 'shipping', title: intl.formatMessage({ id: 'reports.column.date' }) },
          { name: 'name', title: intl.formatMessage({ id: 'reports.column.number' }) },
          { name: 'customer', title: intl.formatMessage({ id: 'reports.column.customer' }) },
          { name: 'source', title: intl.formatMessage({ id: 'reports.column.source' }) },
          {
            name: 'paymentMethod',
            title: intl.formatMessage({ id: 'reports.column.paymentMethod' }),
            originalName: 'payment',
          },
          { name: 'deliveryCompany', title: intl.formatMessage({ id: 'global.deliveryCompany' }) },
          { name: 'deliveryCompanyFee', title: intl.formatMessage({ id: 'global.deliveryCompanyFee' }) },
          { name: 'subtotal', title: intl.formatMessage({ id: 'reports.column.subtotal' }) },
          { name: 'shippingFee', title: intl.formatMessage({ id: 'reports.column.shippingFee' }) },
          { name: 'total', title: intl.formatMessage({ id: 'reports.column.total' }) },
        ],
        prepareRowsAfterLoad: prepareRowsAfterLoad,
        quantityColumnNames: [],
        sorting: [{ columnName: 'shipping', direction: 'desc' }],
        totalSummaryItems: [
          { columnName: 'subtotal', type: 'sum' },
          { columnName: 'deliveryCompanyFee', type: 'sum' },
          { columnName: 'shippingFee', type: 'sum' },
          { columnName: 'total', type: 'sum' },
        ],
        columnExtensions: [
          { columnName: 'total', align: 'right' },
          { columnName: 'deliveryCompanyFee', type: 'right' },
          { columnName: 'shippingFee', align: 'right' },
          { columnName: 'subtotal', align: 'right' },
        ],
        groupingColumns: [],
        enableSelection: false,
      },
    },

    salesItemsPerAccount: {
      key: 'salesItemsPerAccount',
      name: intl.formatMessage({ id: 'reports.salesItemsPerAccount' }),
      def: {
        apiRetrieve: apiRetrieveReportSalesItemsPerAccount,
        allowExport: true,
        columns: [
          { name: 'customerName', title: intl.formatMessage({ id: 'reports.column.customer' }) },
          { name: 'productSubType', title: intl.formatMessage({ id: 'reports.column.subType' }) },
          {
            name: 'product',
            title: intl.formatMessage({ id: 'reports.column.product' }),
            originalName: 'lineItemName',
            getCellValue: (row) => (row.lineItemName && row.lineItemName.product) || '-',
          },
          { name: 'lineItemUnit', title: intl.formatMessage({ id: 'reports.column.unit' }) },
          {
            name: 'shippingDate',
            title: intl.formatMessage({ id: 'reports.column.shippingDate' }),
            getCellValue: (row) => moment(row.shippingDate).format('MMM D'),
          },
          { name: 'ordered', title: intl.formatMessage({ id: 'reports.column.ordered' }) },
          { name: 'packed', title: intl.formatMessage({ id: 'reports.column.packed' }) },
          { name: 'listPrice', title: intl.formatMessage({ id: 'reports.column.listPrice' }) },
          { name: 'avgPrice', title: intl.formatMessage({ id: 'reports.column.avgPrice' }) },
          { name: 'price', title: intl.formatMessage({ id: 'reports.column.lineItemTotal' }) },
        ],
        prepareRowsAfterLoad: prepareRowsAfterLoad,
        quantityColumnNames: ['ordered', 'packed'],
        sorting: [{ columnName: 'customerName', direction: 'asc' }],
        totalSummaryItems: [
          { columnName: 'price', type: 'sum' },
          { columnName: 'listPrice', type: 'sum' },
          { columnName: 'avgPrice', type: 'sum' },
        ],
        columnExtensions: [
          { columnName: 'ordered', align: 'right' },
          { columnName: 'packed', align: 'right' },
          { columnName: 'price', align: 'right' },
          { columnName: 'listPrice', align: 'right' },
          { columnName: 'avgPrice', align: 'right' },
        ],
        groupingColumns: [],
        enableSelection: false,
      },
    },

    salesItemsPerCSAAccount: {
      key: 'salesItemsPerCSAAccount',
      name: intl.formatMessage({ id: 'reports.salesItemsPerCSAAccount' }),
      def: {
        apiRetrieve: apiRetrieveReportSalesItemsPerCSAAccount,
        allowExport: true,
        columns: [
          { name: 'productSubType', title: intl.formatMessage({ id: 'reports.column.subType' }) },
          { name: 'lineItemName', title: intl.formatMessage({ id: 'reports.column.product' }) },
          { name: 'lineItemUnit', title: intl.formatMessage({ id: 'reports.column.unit' }) },
          { name: 'ordered', title: intl.formatMessage({ id: 'reports.column.ordered' }) },
          { name: 'packed', title: intl.formatMessage({ id: 'reports.column.packed' }) },
          { name: 'price', title: intl.formatMessage({ id: 'reports.column.lineItemTotal' }) },
        ],
        prepareRowsAfterLoad: prepareRowsAfterLoad,
        quantityColumnNames: ['ordered', 'packed'],
        sorting: [{ columnName: 'productSubType', direction: 'asc' }],
        totalSummaryItems: [{ columnName: 'price', type: 'sum' }],
        columnExtensions: [
          { columnName: 'ordered', align: 'right' },
          { columnName: 'packed', align: 'right' },
          { columnName: 'price', align: 'right' },
        ],
        groupingColumns: [],
        enableSelection: false,
      },
    },

    salesCompanyPerMonth: {
      key: 'salesCompanyPerMonth',
      name: intl.formatMessage({ id: 'reports.salesCompanyPerMonth' }),
      def: {
        apiRetrieve: apiRetrieveReportSalesCompanyPerMonth,
        allowExport: true,
        columns: [
          { name: 'customerName', title: intl.formatMessage({ id: 'reports.column.customer' }) },
          { name: 'shippingMonth', title: intl.formatMessage({ id: 'reports.column.date' }) },
          { name: 'price', title: intl.formatMessage({ id: 'reports.column.amount' }) },
        ],
        prepareRowsAfterLoad: prepareRowsAfterLoad,
        quantityColumnNames: [],
        sorting: [{ columnName: 'customerName', direction: 'asc' }],
        totalSummaryItems: [{ columnName: 'price', type: 'sum' }],
        columnExtensions: [{ columnName: 'price', align: 'right' }],
        groupingColumns: [],
        enableSelection: false,
      },
    },

    salesOrdersWithLineItems: {
      key: 'salesOrdersWithLineItems',
      name: intl.formatMessage({ id: 'reports.salesOrdersWithLineItems' }),
      def: {
        filters: [DXOrderSourceFilter],
        apiRetrieve: apiRetrieveReportSalesOrdersWithLineItems,
        allowExport: true,
        columns: [
          { name: 'name', title: intl.formatMessage({ id: 'reports.column.number' }) },
          { name: 'customerName', title: intl.formatMessage({ id: 'reports.column.customer' }) },
          { name: 'customerEmail', title: intl.formatMessage({ id: 'reports.column.email' }) },
          { name: 'locationName', title: intl.formatMessage({ id: 'reports.column.location' }) },
          { name: 'productSubType', title: intl.formatMessage({ id: 'reports.column.subType' }) },
          { name: 'lineItemNameProduct', title: intl.formatMessage({ id: 'reports.column.product' }) },
          { name: 'lineItemNameVariant', title: intl.formatMessage({ id: 'reports.column.variant' }) },
          { name: 'ordered', title: intl.formatMessage({ id: 'reports.column.ordered' }) },
          { name: 'packed', title: intl.formatMessage({ id: 'reports.column.packed' }) },
        ],
        prepareRowsAfterLoad: prepareRowsAfterLoad,
        quantityColumnNames: ['ordered', 'packed'],
        sorting: [{ columnName: 'name', direction: 'desc' }],
        totalSummaryItems: [],
        columnExtensions: [
          { columnName: 'ordered', align: 'right' },
          { columnName: 'packed', align: 'right' },
        ],
        groupingColumns: [],
        enableSelection: false,
      },
    },

    salesGroupedByProducts: {
      key: 'salesGroupedByProducts',
      name: intl.formatMessage({ id: 'reports.salesGroupedByProducts' }),
      def: {
        apiRetrieve: apiRetrieveReportSalesItemsGroupedByProducts,
        allowExport: false,
        columns: [
          { name: 'switcher', title: ' ' },
          { name: 'productSubType', title: intl.formatMessage({ id: 'reports.column.subType' }) },
          { name: 'nameProduct', title: intl.formatMessage({ id: 'reports.column.product' }) },
          {
            name: 'nameVariant',
            title: intl.formatMessage({ id: 'reports.column.variant' }),
          },

          { name: 'customerName', title: intl.formatMessage({ id: 'reports.column.customer' }) },
          { name: 'lineItemUnit', title: intl.formatMessage({ id: 'reports.column.unit' }) },
          { name: 'ordered', title: intl.formatMessage({ id: 'reports.column.ordered' }) },
          { name: 'packed', title: intl.formatMessage({ id: 'reports.column.packed' }) },
          { name: 'price', title: intl.formatMessage({ id: 'reports.column.lineItemTotal' }) },
        ],
        quantityColumnNames: ['ordered', 'packed'],
        sorting: [{ columnName: 'productSubType', direction: 'asc' }],
        columnExtensions: [{ columnName: 'switcher', width: 100 }],
        groupingColumns: [],
        cellRenderer: ProductsCellRenderer,
        rowRenderer: ProductsTableRowRenderer,
        getExpandedRowIds: ProductsGetExpandedRowIds,
        enableTree: true,
        treeSelectionColumn: 'switcher',
        allowExpandAll: true,
        showSelectionControls: true,
        getTreeChildRows: ProductsGetTreeChildRows,
        prepareRowsAfterLoad: ProductsPrepareRowsAfterLoad,
        treeSummaryItems: [],
        enableSelection: false,
      },
    },

    csa: {
      key: 'csa',
      name: intl.formatMessage({ id: 'reports.csa' }),
      def: {
        apiRetrieve: apiRetrieveReportCSA,
        allowExport: false,
        columns: [
          { name: 'switcher', title: ' ' },
          { name: 'customer', title: intl.formatMessage({ id: 'reports.column.customer' }) },
          { name: 'csa.name', title: intl.formatMessage({ id: 'reports.column.csa.name' }) },
          { name: 'csa.amount', title: intl.formatMessage({ id: 'reports.column.csa.amount' }) },
          { name: 'csa.balance', title: intl.formatMessage({ id: 'reports.column.csa.balance' }) },
          { name: 'ordered', title: intl.formatMessage({ id: 'reports.column.ordered' }) },
          { name: 'packed', title: intl.formatMessage({ id: 'reports.column.packed' }) },
          { name: 'total', title: intl.formatMessage({ id: 'reports.column.total' }) },
        ],
        quantityColumnNames: ['ordered', 'packed'],
        columnExtensions: [
          { columnName: 'switcher', width: 100 },
          { columnName: 'ordered', align: 'right' },
          { columnName: 'packed', align: 'right' },
          { columnName: 'total', align: 'right' },
        ],
        groupingColumns: [],
        cellRenderer: CSACellRenderer,
        rowRenderer: CSATableRowRenderer,
        getExpandedRowIds: CSAGetExpandedRowIds,
        enableTree: true,
        treeSelectionColumn: 'switcher',
        getTreeChildRows: CSAGetTreeChildRows,
        prepareRowsAfterLoad: CSAPrepareRowsAfterLoad,
        enableSelection: false,
      },
    },

    csaLineItems: {
      key: 'csaLineItems',
      name: intl.formatMessage({ id: 'reports.csaLineItems' }),
      def: {
        apiRetrieve: apiRetrieveReportCSALineItems,
        allowExport: false,
        filters: [DXNextDateFilter],

        columns: [
          { name: 'switcher', title: ' ' },
          { name: 'type', title: intl.formatMessage({ id: 'reports.column.type' }) },
          { name: 'productSubType', title: intl.formatMessage({ id: 'reports.column.subType' }) },
          { name: 'nameProduct', title: intl.formatMessage({ id: 'reports.column.product' }) },
          {
            name: 'nameVariant',
            title: intl.formatMessage({ id: 'reports.column.variant' }),
          },
          { name: 'customerName', title: intl.formatMessage({ id: 'reports.column.customer' }) },
          { name: 'unit', title: intl.formatMessage({ id: 'reports.column.unit' }) },
          { name: 'nextDate', title: intl.formatMessage({ id: 'reports.column.nextDate' }) },
          { name: 'endDate', title: intl.formatMessage({ id: 'reports.column.endDate' }) },
          { name: 'limitPerPeriod', title: intl.formatMessage({ id: 'reports.column.lineItemTotal' }) },
        ],
        quantityColumnNames: ['ordered', 'packed'],
        dateColumnNames: ['nextDate', 'endDate'],
        sorting: [{ columnName: 'productSubType', direction: 'asc' }],
        columnExtensions: [{ columnName: 'switcher', width: 100 }],
        groupingColumns: [],
        cellRenderer: CSALineItemsCellRenderer,
        rowRenderer: CSALineItemsTableRowRenderer,
        getExpandedRowIds: CSALineItemsGetExpandedRowIds,
        enableTree: true,
        treeSelectionColumn: 'switcher',
        allowExpandAll: true,
        showSelectionControls: true,
        getTreeChildRows: CSALineItemsGetTreeChildRows,
        prepareRowsAfterLoad: (rows) => CSALineItemsPrepareRowsAfterLoad(rows, intl),
        treeSummaryItems: [],
        enableSelection: false,
      },
    },

    quickBooksReconcile: {
      key: 'quickBooksReconcile',
      name: intl.formatMessage({ id: 'reports.quickBooksReconcile' }),
      def: {
        apiRetrieve: apiRetrieveQuickBooksReconcile,
        allowExport: true,
        filters: [DXOrderBookingStatusFilter, DXOrderPaidToProducerFilter, DXQBReportDiffFilter],

        columns: [
          { name: 'name', title: intl.formatMessage({ id: 'reports.column.number' }) },
          { name: 'shipping', title: intl.formatMessage({ id: 'reports.column.date' }) },
          { name: 'customer', title: intl.formatMessage({ id: 'reports.column.customer' }) },
          { name: 'booked', title: intl.formatMessage({ id: 'reports.column.booked' }) },
          {
            name: 'paid',
            originalName: 'paidToProducer',
            title: intl.formatMessage({ id: 'reports.column.paidToProducer' }),
            getCellValue: (row) =>
              row.paidToProducer ? intl.formatMessage({ id: 'global.yes' }) : intl.formatMessage({ id: 'global.no' }),
          },
          { name: 'status', title: intl.formatMessage({ id: 'reports.column.status' }) },
          { name: 'financialStatus', title: intl.formatMessage({ id: 'reports.column.paymentStatus' }) },
          { name: 'total', title: intl.formatMessage({ id: 'reports.column.ffTotal' }) },
          {
            name: 'qBBalance',
            title: intl.formatMessage({ id: 'reports.column.quickBooksBalance' }),
            getCellValue: (row) => {
              return row.qBBalance ? row.qBBalance : 0;
            },
          },
          { name: 'qBTotal', title: intl.formatMessage({ id: 'reports.column.quickBooksTotal' }) },
          { name: 'qBDiff', title: intl.formatMessage({ id: 'reports.column.diffAmount' }) },
        ],
        cellRenderer: (intl, reload, { row, ...props }) => {
          if (props.column.name === 'booked') {
            return (
              <Table.Cell {...props} style={{ whiteSpace: 'normal' }}>
                <p>
                  {row.booking && row.booking.status
                    ? intl.formatMessage({ id: `global.booked` })
                    : intl.formatMessage({ id: `global.notBooked` })}
                </p>
                {row.booking && row.booking.bookingError && (
                  <p style={{ color: COLORS.violet }}>{row.booking.bookingError}</p>
                )}
              </Table.Cell>
            );
          }
          return <Table.Cell {...props} />;
        },
        prepareRowsAfterLoad: prepareRowsAfterLoad,
        sorting: [{ columnName: 'name', direction: 'desc' }],
        totalSummaryItems: [
          { columnName: 'total', type: 'sum' },
          { columnName: 'qBBalance', type: 'sum' },
          { columnName: 'qBTotal', type: 'sum' },
          { columnName: 'qBDiff', type: 'sum' },
        ],
        currencyColumnNames: ['total', 'qBBalance', 'qBTotal', 'qBDiff'],
        columnExtensions: [
          { columnName: 'total', align: 'right' },
          { columnName: 'qBBalance', align: 'right' },
          { columnName: 'qBTotal', align: 'right' },
          { columnName: 'qBDiff', align: 'right' },
        ],
        groupingColumns: [],
        enableSelection: false,
      },
    },

    'csa.summary': {
      key: 'csa.summary',
      name: intl.formatMessage({ id: 'reports.csa.summary' }),
      def: {
        apiRetrieve: apiRetrieveReportCSASummary,
        columns: [
          { name: 'customerName', title: intl.formatMessage({ id: 'reports.column.customer' }) },
          { name: 'productName', title: intl.formatMessage({ id: 'reports.column.product' }) },
          { name: 'variantName', title: intl.formatMessage({ id: 'reports.column.variant' }) },
          { name: 'packed', title: intl.formatMessage({ id: 'reports.column.packed' }) },
          { name: 'prepurchasedUnitPrice', title: intl.formatMessage({ id: 'reports.column.unitPrice' }) },
          { name: 'prepurchasedPrice', title: intl.formatMessage({ id: 'reports.column.price' }) },
        ],
        prepareRowsAfterLoad: prepareRowsAfterLoad,
        cellRenderer: (intl, reload, { row, ...props }) => {
          if (props.column.name === 'prepurchasedUnitPrice' || props.column.name === 'prepurchasedPrice') {
            return <Table.Cell {...props}>{toPaper(row[props.column.name])}</Table.Cell>;
          }

          return <Table.Cell {...props} />;
        },
        quantityColumnNames: ['ordered', 'packed'],
        columnExtensions: [
          { columnName: 'packed', align: 'right' },
          { columnName: 'prepurchasedUnitPrice', align: 'right' },
          { columnName: 'prepurchasedPrice', align: 'right' },
        ],
        totalSummaryItems: [
          { columnName: 'packed', type: 'sum' },
          { columnName: 'prepurchasedUnitPrice', type: 'sum' },
          { columnName: 'prepurchasedPrice', type: 'sum' },
        ],
        groupingColumns: [],
        enableSelection: false,
      },
    },

    salesGroupedByLocations: {
      key: 'salesGroupedByLocations',
      name: intl.formatMessage({ id: 'reports.salesGroupedByLocations' }),
      def: {
        apiRetrieve: apiRetrieveReportSalesGroupByLocations,
        allowExport: false,
        filters: [],
        columns: [
          { name: 'switcher', title: ' ' },
          { name: 'locationName', title: intl.formatMessage({ id: 'reports.column.location' }) },

          { name: 'type', title: intl.formatMessage({ id: 'reports.column.type' }) },
          { name: 'name', title: intl.formatMessage({ id: 'reports.column.number' }) },
          { name: 'customerName', title: intl.formatMessage({ id: 'reports.column.customer' }) },

          { name: 'ordered', title: intl.formatMessage({ id: 'reports.column.ordered' }) },
          { name: 'packed', title: intl.formatMessage({ id: 'reports.column.packed' }) },
          { name: 'price', title: intl.formatMessage({ id: 'reports.column.total' }) },
          { name: 'productName', title: intl.formatMessage({ id: 'reports.column.product' }) },
          { name: 'productSubType', title: intl.formatMessage({ id: 'reports.column.subType' }) },
          { name: 'unit', title: intl.formatMessage({ id: 'reports.column.unit' }) },
          { name: 'variantName', title: intl.formatMessage({ id: 'reports.column.variant' }) },
        ],
        quantityColumnNames: ['ordered', 'packed', 'price'],
        dateColumnNames: [],
        sorting: [{ columnName: 'name', direction: 'asc' }],
        columnExtensions: [
          { columnName: 'switcher', width: 100 },
          { columnName: 'locationName', width: 300 },
        ],
        groupingColumns: [],
        cellRenderer: OrdersGroupByLocationCellRenderer,
        rowRenderer: OrdersGroupByLocationGetTableRowRenderer,
        getExpandedRowIds: OrdersGroupByLocationGetExpandedRowIds,
        enableTree: true,
        treeSelectionColumn: 'switcher',
        allowExpandAll: true,
        showSelectionControls: true,
        getTreeChildRows: OrdersGroupByLocationGetTreeChildRows,
        prepareRowsAfterLoad: (rows) => OrdersGroupByLocationPrepareRowsAfterLoad(rows, intl),
        treeSummaryItems: [],
        enableSelection: false,
      },
    },
  };
};
