import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PrimaryCheckbox from '../PrimaryCheckbox/PrimaryCheckbox';
import EditIcon from '@material-ui/icons/Edit';
import { PaymentTerms } from '../../entities';
import {COLORS} from '../../helpers';

const styles = ({
  wrap: {
    padding: 10,
    width: '100%',
    margin: '0 15px 15px 0'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  disabled: {
    color: COLORS.text + ' !important'
  }
});

const propTypes = {
  terms: PropTypes.instanceOf(PaymentTerms),
  onEdit: PropTypes.func.isRequired,
};

const defaultProps = {
  terms: "",
  allowCredit: false,
  allowShipping: false,
  freeShipping: false,
  bookToQuickBooks: false,
};

const CustomerPaymentTerms = ({ intl,
                                terms,
                                onEdit,
                                classes,
                                bookToQuickBooks,
                                producerId }) => {

  return (
    <Paper className={classes.wrap}>
      <Typography component="h6" variant="subtitle1">
        {intl.formatMessage({ id: "global.terms" })}: { terms.paymentTerms }
      </Typography>

      <FormControlLabel
        checked={terms.allowCredit}
        disabled
        classes={{label: classes.disabled}}
        style={{width: '100%'}}
        control={<PrimaryCheckbox inputProps={{readOnly: true}} color="default" />}
        label={intl.formatMessage({ id: "global.allowCredit" })}
      />
      <FormControlLabel
        checked={terms.freeDelivery}
        disabled
        classes={{label: classes.disabled}}
        style={{width: '100%'}}
        control={<PrimaryCheckbox inputProps={{readOnly: true}} color="default" />}
        label={intl.formatMessage({ id: "global.freeDelivery" })}
      />
      <FormControlLabel
        checked={terms.allowShipping}
        disabled
        classes={{label: classes.disabled}}
        style={{width: '100%'}}
        control={<PrimaryCheckbox inputProps={{readOnly: true}} color="default" />}
        label={intl.formatMessage({ id: "global.allowShipping" })}
      />
  
      <FormControlLabel
        checked={bookToQuickBooks === null ? true : bookToQuickBooks[producerId]}
        disabled
        classes={{label: classes.disabled}}
        style={{width: '100%'}}
        control={<PrimaryCheckbox inputProps={{readOnly: true}} color="default" />}
        label={intl.formatMessage({ id: "global.bookToQuickBooks" })}
      />

      <div className={classes.actions}>
        <IconButton onClick={() => onEdit()}>
          <EditIcon color='secondary'/>
        </IconButton>
      </div>
    </Paper>
  )
};

CustomerPaymentTerms.propTypes = propTypes;
CustomerPaymentTerms.defaultProps = defaultProps;

export default withStyles(styles, {withTheme: true})(
  injectIntl(CustomerPaymentTerms)
)
