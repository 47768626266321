import moment from "moment";
import {apiQuickBooksGetAuthUrl} from "../api";

export default class QuickBooks {

  constructor(config, syncStatus) {
    this.url = config.url || "";
    this.authorized = false;
    if (config.authorized) {
      this.authorized = config.authorized;
    }
    this.sync = syncStatus || null;
  }

  getLastSyncDateTime() {
    if (this.sync && this.sync.time) {
      return moment(this.sync.time).format("MM/D/Y HH:mm")
    }
    return null;
  }

  authorize(onSuccess) {
    // Launch Popup
    let parameters = 'location=1,width=800,height=650';
    parameters += ',left=' + (window.innerWidth - 800) / 2 + ',top=' + (window.innerHeight - 650) / 2;
    let popup = window.open(this.url, 'connectPopup', parameters);
    let interval = setInterval(() => {
      apiQuickBooksGetAuthUrl((config) => {
        this.service = new QuickBooks(config);
        if (this.service.authorized) {
          onSuccess && onSuccess();
          clearInterval(interval);
          popup.close();
        }
      }, () => {})
    }, 1000);
  }

}