import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {makeStyles} from '@material-ui/core';
import TemporaryTooltip from '../../../components/TemporaryTooltip/TemporaryTooltip';
import useCopyToClipboard from '../../../hooks/useCopyToClipboard';

const useStyles = makeStyles({
  wrapper: {
    marginLeft: 'auto'
  },
  menuItemStyles: {
    fontSize: 14,
    fontWeight: 400,
    padding: '13px 10px',
    height: 40,
    fontFamily: "'Roboto', sans-serif",
  },
  menuList: {
    padding: 0,
  },
  menuPaper: {
    background: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    boxShadow: '0px 5px 13px rgba(0, 0, 0, 0.1)',
    borderRadius: 8
  }
});

const options = [
  'memberService.customer.editDetails',
  'memberService.customer.copyEmail'
];

const ITEM_HEIGHT = 48;

const propTypes = {
  customer: PropTypes.object.isRequired,
}

export default function CustomerMenu(props) {
  const { customer } = props;
  const history = useNavigate();
  const intl = useIntl();
  const classes = useStyles();
  const [showCopied, setShowCopied] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [, copyToClipboard] = useCopyToClipboard()

  const hideCopiedTooltip = () => setShowCopied(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async (option) => {
    if (option === 'memberService.customer.editDetails') {
      history(`/customers/${customer.id}`)
    }

    if (option === 'memberService.customer.copyEmail') {
      setShowCopied(true)
      await copyToClipboard(customer.email)
    }

    setAnchorEl(null);
  };

  return (
    <div className={classes.wrapper}>
      <IconButton
        aria-label="more"
        aria-controls="member-service-customer-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="member-service-customer-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: -150,
        }}
        keepMounted
        open={open}
        onClose={handleClose}
        classes={{
          paper: classes.menuPaper,
          list: classes.menuList
        }}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            margin: 0,
            padding: 0,
            width: 189,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} classes={{root: classes.menuItemStyles}} onClick={() => handleClose(option)}>
            {intl.formatMessage({'id': option})}
          </MenuItem>
        ))}
      </Menu>

      <TemporaryTooltip
        message={intl.formatMessage({ id: 'global.emailCopied' })}
        onHide={hideCopiedTooltip}
        isActive={showCopied}
      />
    </div>
  );
}

CustomerMenu.propTypes = propTypes;