import React from 'react';
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import DXSelectFilter from '../DXSelectFilter/DXSelectFilter'
import DXFilterConfig from '../DXFilterConfig'
import constants from '../../constants'

const propTypes = {
  equality: PropTypes.string,
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  selected: [],
};

class DXPaymentMethodFilter extends React.Component {

  render() {
    const { selected, onChange, intl, equality} = this.props;
    const config = DXPaymentMethodFilter.getConfig(intl, equality);
    return (
      <DXSelectFilter selected={selected}
                      title={config.title}
                      values={config.values}
                      onChange={onChange}
                      multi={config.multi}
      />
    )
  }
}

DXPaymentMethodFilter.propTypes = propTypes;
DXPaymentMethodFilter.defaultProps = defaultProps;
DXPaymentMethodFilter.getLabel = (intl, selectedValues) => {
  return DXSelectFilter.getLabel(intl, 'filter.paymentMethod.', selectedValues);
};
DXPaymentMethodFilter.applyToQueryParams = (queryParams, selectedValues) => {
  queryParams['paymentMethod'] = selectedValues;
  return queryParams;
};
DXPaymentMethodFilter.getConfig = (intl, equaltity) => {
  let config = new DXFilterConfig({
    name: 'paymentMethod',
    selected: [],
    property: 'paymentMethod',
    title: intl.formatMessage({id: 'filter.paymentMethod'}),
    disabled: false,
    equalityStatus: false,
    multi: true
  });

  if (equaltity === constants.FILTER_EQUALITY_EQUALS) {
    config.addFilterValue('credit_card', intl.formatMessage({id: 'filter.paymentMethod.credit_card'}));
    config.addFilterValue('bank', intl.formatMessage({id: 'filter.paymentMethod.bank'}));
    config.addFilterValue('cash', intl.formatMessage({id: 'filter.paymentMethod.cash'}));
    config.addFilterValue('check', intl.formatMessage({id: 'filter.paymentMethod.check'}));
  }

  return config;
};

export default injectIntl(
  DXPaymentMethodFilter
);
