import React from 'react';
import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { COLORS } from '../../helpers';

const materialTheme = createTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },
    MuiFormLabel: {
      root: {
        color: COLORS.cartSecondary,
        fontSize: '12px',
        fontFamily: '"Roboto", sans-serif',
        lineHeight: 1,
        '&$focused': {
          color: '#363636',
        },
      },
    },

    MuiInput: {
      root: {
        fontSize: '14px',
        color: `#363636`,
        minHeight: 40,
      },
      underline: {
        '&:after': {
          borderBottom: `2px solid #E0E0E0`,
        },
        '&$focused:after': {
          borderBottomColor: `#363636`,
        },
        '&$error:after': {
          borderBottomColor: `#363636`,
        },
        '&:before': {
          borderBottom: `1px solid #E0E0E0`,
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: `2px solid #E0E0E0`,
        },
        '&$disabled:before': {
          borderBottom: `1px dotted #E0E0E0`,
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: COLORS.text,
      },
      shrink: {
        transform: 'translate(0, 1.5px)',
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: COLORS.text,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {},
    },
    MuiPickersDay: {
      day: {
        color: COLORS.black,
      },
      current: {
        color: COLORS.text,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: COLORS.text,
      },
    },
  },
});

const PrimaryInlineDatePicker = (props) => {
  return (
    <MuiThemeProvider theme={materialTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker variant={'inline'} leftArrowIcon={<ArrowLeft />} rightArrowIcon={<ArrowRight />} {...props} />
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};

export default PrimaryInlineDatePicker;
