export default {
  spinner: {
    margin: 'auto',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  requestError: {
    backgroundColor: 'red',
    color: '#ffffff',
    marginBottom: 20,
    marginLeft: 20,
    marginRight: 20
  },
  controls: {
    display: 'flex',
    justifyContent: 'center',
    margin: "30px 0 30px",
  },
  massage: {
    margin: 10
  },
  dialogContent: {
    minWidth: 300
  },
  bmr: {
    marginRight: 20,
  },
  finalMessageContainer: {
    textAlign: 'center',
    margin: "0 0 20px",
  },
  mra: {
    marginRight: 'auto'
  },
  mla: {
    marginLeft: 'auto'
  },
  controlsCentered: {
    textAlign: 'center',
    margin: "10px 0 30px",
  },
  envelope: {
    width: 60,
    height: 60,
  },
};