import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { setProducer } from '../../store/actions'
import { injectIntl } from 'react-intl'
import {
  Spinner,
  ErrorBox,
  PrimarySwitch,
  EditableQuickBooking,
} from '../../components'
import {
  apiQuickBooksGetAuthUrl,
  apiQuickBooksSyncStatus,
  apiQuickBooksBookOrdersSettings,
  apiQuickBooksGetOrderPrefix,
  apiQuickBooksSetOrderPrefix,
  apiQuickBooksDeleteOrderPrefix
} from '../../api'
import QuickBooks from '../../services/QuickBooks'
import Wrapper from './Components/Wrapper'
import {capitalizeFirstLetter, COLORS} from '../../helpers'
import Block from './Components/Block'
import QuickBookAccounts from './Components/QuickBookAccounts'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const styles = ({
  text: {
    fontSize: 14,
    color: COLORS.textSecondary,
    fontFamily: "Roboto, sans-serif",
  },
  contentWithSwitch: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  mr0: {
    marginRight: 0
  },
  mb40: {
    marginBottom: 40
  },
  smallLabelRoot: {
    marginLeft: 0,
    marginRight: 0,
  },
  minWidth800: {
    minWidth: 800
  },
  smallLabel: {
    fontSize: 9,
    color: COLORS.actionLabel
  },
  fullWidth :{
   width: '100%'
  }
});

class AccountingTab extends React.Component {

  constructor(props) {
    super(props);
    this.service = null;
    this.state = {
      error: "",
      loading: true,
      quickBookName: '',
    }
  }

  componentDidMount() {
    this._initQuickBooksService();
  }

  _switchBookSettings = (type, status) => {
    const { dispatch } = this.props;
    this.setState({loading: true});
    const key = `book${capitalizeFirstLetter(type)}`;
    const data = {[key]: status};

    apiQuickBooksBookOrdersSettings(data,
      producer => {
        dispatch(setProducer(producer));
        this.setState({loading: false})
      },
      error => {
        console.log(error)
      })
  };

  _initQuickBooksService = () => {
    const { intl } = this.props;
    this._getQuickBook();
    apiQuickBooksSyncStatus((sync) => {
      apiQuickBooksGetAuthUrl((config) => {
        this.service = new QuickBooks(config, sync);
        this.setState({loading: false});
      }, () => {
        this.setState({
          loading: false,
          error: intl.formatMessage({id: "messages.somethingWentWrong"})
        });
      })
    }, () => {
      this.setState({
        loading: false,
        error: intl.formatMessage({id: "messages.somethingWentWrong"})
      });
    })
  };

  onAuthSuccess = () => {
    this.setState({loading: true});
    this._initQuickBooksService();
  };
  
  _getQuickBook = () => {
    apiQuickBooksGetOrderPrefix((response) => {
      if (response.status === "success")
        this.setState({quickBookName: response.orderPrefix});
    }, (error) => {
      this.setState({error: error.response.data.message});
    })
  }
  
  _setQuickBook = (name) => {
    apiQuickBooksSetOrderPrefix(name, (response) => {
      if (response.status === "success")
        this.setState({quickBookName: response.orderPrefix});
    }, (error) => {
      this.setState({error: error.response.data.message});
    })
  }
  
  _deleteQuickBook = () => {
    apiQuickBooksDeleteOrderPrefix(() => {
      this.setState({quickBookName: ''});
    }, (error) => {
      this.setState({error: error.response.data.message});
    })
  }

  render() {
    const { intl, classes, producer } = this.props;
    const { quickBookName } = this.state;
    return (
      <Wrapper>
        {this.state.error && <ErrorBox error={this.state.error} />}
        {this.state.loading && <Spinner size={80} />}

        {(!this.state.loading && this.service) && (
          <Block paperClass={classNames(classes.mr0, classes.mb40)}
                 contentClass={classes.contentWithSwitch}
                 title={intl.formatMessage({id: 'global.authenticate'})}>

            <Typography className={classes.text} variant={"body1"}>
              {intl.formatMessage({id: 'qb.authenticate'})}
            </Typography>


            <FormControlLabel
              classes={{
                label: classes.smallLabel,
                root: classes.smallLabelRoot
              }}
              labelPlacement="bottom"
              control={
                <PrimarySwitch
                  checked={this.service.authorized}
                  onChange={(e) => e.target.checked && this.service.authorize(this.onAuthSuccess)}
                />
              }
              label={this.service.authorized ?
                intl.formatMessage({id: "global.authenticated"}) :
                intl.formatMessage({id: "global.notAuthenticated"})
              }
            />

          </Block>
        )}

        {(!this.state.loading && this.service && this.service.sync) && (
          <Block title={intl.formatMessage({id: 'quickBooks.syncStatus'})}
                 paperClass={classNames(classes.mr0, classes.mb40)} >
            <Typography className={classes.text} variant={"body1"}>
              {intl.formatMessage({id: 'quickBooks.lastSync'})} : {this.service.getLastSyncDateTime() || this.service.sync.status}
            </Typography>
            <Typography className={classes.text} variant={"body1"}>
              {this.service.sync.report}
            </Typography>
          </Block>
        )}

        {(!this.state.loading && this.service && this.service.authorized) && (
          <Block paperClass={classNames(classes.mr0, classes.mb40)}
                 contentClass={classes.contentWithSwitch}
                 title={intl.formatMessage({id: 'global.wholesale'})}>
            <div className={classes.fullWidth}>
              <div>
                <Typography className={classes.text} variant={"body1"}>
                  {intl.formatMessage({id: 'qb.syncWholesaleOrders'})}
                </Typography>
      
                <FormControlLabel
                  classes={{
                    label: classes.smallLabel,
                    root: classes.smallLabelRoot
                  }}
                  labelPlacement="bottom"
                  control={
                    <PrimarySwitch
                      checked={producer.bookWholesale}
                      onChange={(e) => this._switchBookSettings('wholesale', e.target.checked)}
                    />
                  }
                  label={producer.bookWholesale ?
                    intl.formatMessage({id: "global.enabled"}) :
                    intl.formatMessage({id: "global.disabled"})
                  }
                />
              </div>
              <div style={{marginTop: 15}}>
                <Typography className={classes.text} variant={"body1"}>
                  {intl.formatMessage({id: 'qb.orderNumberPrefix'})}
                </Typography>
      
                <EditableQuickBooking
                  title={intl.formatMessage({id: "qb.setQbPlaceholder"})}
                  note={quickBookName}
                  onChange={(note) => this._setQuickBook(note)}
                  onDelete={() => this._deleteQuickBook()}
                />
              </div>
            </div>
          </Block>
        )}

        {(!this.state.loading && this.service && this.service.authorized) && (
          <Block paperClass={classNames(classes.mr0, classes.mb40, classes.minWidth800)}
                 title={intl.formatMessage({id: 'global.retail'})}>

            <div style={{width: 325}} className={classes.contentWithSwitch}>
              <Typography className={classes.text} variant={"body1"}>
                {intl.formatMessage({id: 'qb.syncRetailOrders'})}
              </Typography>

              <FormControlLabel
                classes={{
                  label: classes.smallLabel,
                  root: classes.smallLabelRoot
                }}
                labelPlacement="bottom"
                control={
                  <PrimarySwitch
                    checked={producer.bookRetail}
                    onChange={(e) => this._switchBookSettings('retail', e.target.checked)}
                  />
                }
                label={producer.bookRetail ?
                  intl.formatMessage({id: "global.enabled"}) :
                  intl.formatMessage({id: "global.disabled"})
                }
              />
            </div>

            {producer.bookRetail && (
              <QuickBookAccounts  />
            )}
          </Block>
        )}


      </Wrapper>
    )
  }
}

export default withStyles(styles)(injectIntl(
  connect(state => {
    return {producer: state.producer.object}
  })(AccountingTab)
));
