import React from 'react';
import {
  MuiThemeProvider,
  createTheme
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {COLORS} from '../../helpers'

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiButton: {
      root: {
        fontFamily: "'Roboto', sans-serif",
        fontWeight: 400,
        textTransform: 'uppercase',
        fontSize: 12,
        background: 'transparent',
        borderRadius: 6,
        border: `2px solid ${COLORS.text}`,
        color: COLORS.text,
        height: 37,
        padding: '0 37px !important',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.16)',
        minWidth: 200,
        transition: 'background 0.3s ease-in-out',
        '&:hover, &:active, &:focus': {
          backgroundColor: COLORS.lightGray
        }
      },
      label: {
        fontWeight: '400',
      }
    },
  },
  // overrides: {
  //   MuiButton: {
  //     root: {
  //       fontFamily: "'Roboto', sans-serif",
  //       fontWeight: 400,
  //       fontSize: 12,
  //       background: '#ffffff',
  //       borderRadius: 33,
  //       border: '1px solid red',
  //       color: '#41CD8C',
  //       height: 37,
  //       padding: '0 37px !important',
  //       boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.16)',
  //     },
  //   },
  // },
});

class SecondaryButton extends React.Component {
  render = () => (
    <MuiThemeProvider theme={theme}>
      <Button {...this.props}>
        {this.props.children}
      </Button>
    </MuiThemeProvider>
  )
}

export default SecondaryButton;
