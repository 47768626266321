import { SIGN_IN, SIGN_OUT, SUPPLIER_SIGN_IN, SUPPLIER_SIGN_OUT } from '../constants';
import { setProducer } from '../actions';
import {
  setToken,
  getToken,
  isJWTExpired,
  removeProducerDataWithToken,
  setAcl,
  setSupplierToken,
  removeSupplierToken,
  removeSupplierObject,
} from '../../helpers';

export function signIn(data, onReady) {
  return (dispatch) => {
    const { token, producer, acl } = data;

    setToken(token);
    setAcl(acl);

    dispatch(setProducer(producer));
    dispatch({
      type: SIGN_IN,
      payload: {
        token: token,
      },
    });

    onReady && onReady();
  };
}

export function signOut() {
  return (dispatch) => {
    removeProducerDataWithToken();
    dispatch({
      type: SIGN_OUT,
    });
  };
}

export function signOutIfTokenExpired() {
  return (dispatch) => {
    const token = getToken();
    if (token && isJWTExpired(token)) {
      removeProducerDataWithToken();
      dispatch({
        type: SIGN_OUT,
      });
    }
  };
}

export function supplierSignIn(data, onReady) {
  return (dispatch) => {
    const { token } = data;

    setSupplierToken(token);

    dispatch({
      type: SUPPLIER_SIGN_IN,
      payload: {
        token: token,
      },
    });

    onReady && onReady();
  };
}

export function supplierSignOut() {
  removeSupplierToken();
  removeSupplierObject();

  return (dispatch) => {
    dispatch({
      type: SUPPLIER_SIGN_OUT,
    });
  };
}
