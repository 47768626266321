export const getSalesTax = (price, intl) => {
  price = (0.12 * (price > 0 ? price/100 : 0)).toFixed(2);
  if (typeof(intl) !== 'undefined') {
    price = intl.formatNumber(price, {
      style: 'currency',
      minimumFractionDigits: 2,
      currency: process.env.REACT_APP_CURRENCY
    })
  }
  return price;
};

export const toCurrency = (price, intl, minimumFractionDigits) => {
  price = (price !== 0 ?
    (price/100).toString() : price.toString());

  if (typeof(intl) !== 'undefined') {
    price = intl.formatNumber(price, {
      style: 'currency',
      minimumFractionDigits: minimumFractionDigits || 2,
      currency: process.env.REACT_APP_CURRENCY
    })
  }
  return price;
};

export const toCoins = (price) => {
  return Math.round(price*100);
};

export const toPaper = (price) => {
  return price !== 0 ? parseFloat(price/100).toFixed(2) : "0.00";
};

export const toNumericPaper = (price) => {
  return price !== 0 ? (Math.round(parseFloat(price/100) * 1e2) / 1e2) : 0.00;
};

