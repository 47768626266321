import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { countTextWidth, isDateInFuture, SHIPPING_TYPES, weightToUser } from '../../../../helpers';
import Customer from '../../entities/Customer';
import TooltipWrapper from './TooltipWrapper';
import CellAsQuantityInput from '../../../../components/AdminDXTable/Editing/CellAsQuantityInput';
import { apiUpdateOrderLineItem } from '../../../../api';
import {
  apiChangeSubscriptionLineItemSchedule,
  apiDeleteSingleSubscriptionLineItemScheduleDate,
  apiGetSubscriptionSchedule,
  apiMoveDateSeriesSubscriptionLineItemSchedule,
} from '../../../../api';
import CellAsScheduler from '../../../../components/AdminDXTable/Editing/CellAsScheduler';
import moment from 'moment';
import { preventClickDefault } from '../../../../helpers/events';
import CellAsDateInput from '../../../../components/AdminDXTable/Editing/CellAsDateInput';
import { apiSetOrderShipping } from '../../../../api/order/order';
import { useDispatch } from 'react-redux';
import { showTemporaryTooltip } from '../../../../store/actions/common';
import CheckIcon from '@material-ui/icons/Check';
import { Order } from '../../../../entities';

const useStyles = makeStyles(() => ({
  rootCell: {
    flex: 1,
  },
  lastRow: {
    borderBottom: 0,
    '&:first-of-type': {
      borderBottomLeftRadius: 12,
    },
    '&:last-of-type': {
      borderBottomRightRadius: 12,
    },
  },
  actionCell: {
    cursor: 'pointer',
    paddingRight: 20,
  },
  actionCellText: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 14,
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.5)',
    textAlign: 'right',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 6,
    backgroundColor: '#DEDCDA00',
    display: 'inline',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: '#DEDCDAFF',
    },
  },
  quantityCell: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  nameCellWrapper: {
    paddingLeft: '20px !important',
  },
  nameCell: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

function Cell(props) {
  const classes = useStyles();
  const { value, textClass, tableWidth, ...other } = props;
  let withTooltip = true;
  const textWidth = countTextWidth(value, 20, 14);
  const currentCellWidth = tableWidth * parseFloat('0.' + other?.tableColumn?.width); // Allow only percentage value

  if (currentCellWidth > textWidth) {
    withTooltip = false;
  }

  const drawValue = () => {
    return <span className={classNames([textClass])}>{value}</span>;
  };

  return (
    <Table.Cell {...other} className={classNames([classes.rootCell, other?.className])}>
      {withTooltip ? <TooltipWrapper title={value}>{drawValue()}</TooltipWrapper> : drawValue()}
    </Table.Cell>
  );
}

const CellRenderer = (reload, { row, rows, ...props }, updateSingleItem, reloadAllData, table) => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const customer = new Customer(row?.customer);
  const isLastRow = rows[rows?.length - 1] && rows[rows.length - 1].id === row.id;
  const tableWidth = table?.tableWrapper?.offsetWidth || 0;

  if (props.column.name === 'name') {
    const variantName = typeof row?.name?.variant === 'object' ? row?.name?.variant?.variant : row?.name?.variant;

    const name = `${row?.name?.product}${variantName ? ' - ' + variantName : ''}`;

    return (
      <Cell
        {...props}
        value={name}
        className={classNames([classes.nameCellWrapper, isLastRow && classes.lastRow])}
        textClass={classes.nameCell}
        tableWidth={tableWidth}
      />
    );
  }

  if (props.column.name === 'type') {
    const isSubscription = !!row.subscription;
    const isOrderWithSubscription = !!row?.order?.subscription;

    if (isSubscription) {
      return (
        <Cell
          {...props}
          value={intl.formatMessage({ id: `global.${row.subscription.period.type}` })}
          className={classNames([isLastRow && classes.lastRow])}
          tableWidth={tableWidth}
        />
      );
    }

    if (isOrderWithSubscription) {
      return (
        <Cell
          {...props}
          value={intl.formatMessage({ id: `global.${row?.order?.subscription.period.type}` })}
          className={classNames([isLastRow && classes.lastRow])}
          tableWidth={tableWidth}
        />
      );
    }

    return (
      <Cell
        {...props}
        value={intl.formatMessage({ id: 'global.laCarte' })}
        className={classNames([isLastRow && classes.lastRow])}
        tableWidth={tableWidth}
      />
    );
  }

  if (props.column.name === 'quantity') {
    const quantity = weightToUser(row?.orderedQuantity || '');
    const hasOrder = !!row?.order?.id;
    const editableOrder = hasOrder ? new Order(row.order).isEditable() : false;

    if (!!editableOrder && isDateInFuture(row?.shipping?.date)) {
      return (
        <CellAsQuantityInput
          {...props}
          cellProps={{
            ...props?.cellProps,
            className: classNames([classes.quantityCell, isLastRow && classes.lastRow]),
          }}
          value={quantity}
          onFinishCellEditing={(quantity, onDone, onError) => {
            if (hasOrder) {
              apiUpdateOrderLineItem(
                row?.order?.id,
                row?.id,
                { quantity },
                () => {
                  updateSingleItem({
                    quantity,
                  });
                  onDone && onDone();
                },
                onError
              );
            }
          }}
        />
      );
    }

    return (
      <Cell
        {...props}
        value={quantity}
        className={classNames([isLastRow && classes.lastRow])}
        tableWidth={tableWidth}
      />
    );
  }

  if (props.column.name === 'location') {
    let locationOrAddress =
      row?.shipping?.type === SHIPPING_TYPES.SHIPPING_DELIVERY
        ? `${intl.formatMessage({ id: 'memberService.table.homeDelivery' })}: ${customer.getFormatterShippingAddress()}`
        : row?.shipping?.location?.name;

    return (
      <Cell
        {...props}
        value={locationOrAddress}
        className={classNames([isLastRow && classes.lastRow])}
        tableWidth={tableWidth}
      />
    );
  }

  if (props.column.name === 'action') {
    const isSubscription = !!row.subscription;
    const hasOrder = !!row?.order?.id;
    const editableOrder = row.order && new Order(row.order).isEditable();

    const editText = (props) => (
      <div className={'showOnCellHover'}>
        <div
          className={classNames([classes.actionCellText])}
          onClick={(event) => {
            preventClickDefault(event);

            props.onClick && props.onClick();
          }}>
          {intl.formatMessage({
            id: 'memberService.changeDate',
          })}
        </div>
      </div>
    );

    if ((!hasOrder && !isSubscription) || !isDateInFuture(row?.shipping?.date)) {
      return <Table.Cell {...props} className={classNames([classes.actionCell, isLastRow && classes.lastRow])} />;
    }

    if (isSubscription) {
      return (
        <CellAsScheduler
          value={row.subscription.nextDate}
          style={{ margin: 0, width: 'auto' }}
          cellProps={{
            ...props,
            className: classNames([classes.actionCell, isLastRow && classes.lastRow]),
          }}
          disableClick
          ValueComponent={editText}
          openOnSingleClick
          forVariant
          variantId={row.variantId}
          strictMode
          absolutePicker
          minDate={moment().add(1, 'days')}
          apiRetrieve={(onSuccess, onError) => apiGetSubscriptionSchedule(row.subscription.id, onSuccess, onError)}
          onFinishCellEditing={() => {
            reloadAllData();
          }}
          onDateChange={(data, onSuccess, onError) => {
            apiChangeSubscriptionLineItemSchedule(row.subscription.id, row.variantId, data, onSuccess, onError);
          }}
          onDateMoveSeries={(data, onSuccess, onError) => {
            apiMoveDateSeriesSubscriptionLineItemSchedule(row.subscription.id, row.variantId, data, onSuccess, onError);
          }}
          onDateDelete={(data, onSuccess, onError) => {
            apiDeleteSingleSubscriptionLineItemScheduleDate(
              row.subscription.id,
              row.variantId,
              data,
              onSuccess,
              onError
            );
          }}
        />
      );
    }

    if (hasOrder && editableOrder) {
      return (
        <CellAsDateInput
          value={row.shipping.date || null}
          style={{ margin: 0, width: 'auto', cursor: 'pointer' }}
          cellProps={{
            ...props,
            className: classNames([classes.actionCell, isLastRow && classes.lastRow]),
          }}
          disableClick
          applyOnChange
          onFinishCellEditing={(value) => {
            apiSetOrderShipping(
              row?.order?.id,
              row?.shipping?.type,
              {
                date: moment(value).startOf('day').toISOString(true),
              },
              undefined,
              () => {
                dispatch(showTemporaryTooltip(intl.formatMessage({ id: 'global.orderDateUpdated' }), CheckIcon));
                reloadAllData();
              },
              (error) => {
                const errorMessage =
                  error?.response?.data?.message || intl.formatMessage({ id: 'global.somethingWentWrong' });
                dispatch(showTemporaryTooltip(errorMessage));
              }
            );
          }}
          openOnSingleClick
          absolutePicker
          ValueComponent={editText}
          datePickerProps={{
            open: true,
            variant: 'static',
            autoFocus: true,
            minDate: new Date(),
          }}
        />
      );
    }

    return <Table.Cell {...props} className={classNames([classes.actionCell, isLastRow && classes.lastRow])} />;
  }

  return (
    <Table.Cell {...props} className={classNames([classes.rootCell, isLastRow && classes.lastRow])}>
      {''}
    </Table.Cell>
  );
};

export default CellRenderer;
