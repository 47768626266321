import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../hocs';
import { AdminDialog } from '../../components';
import AddressStage from './AddressStage/AddressStage';
import GeneralInfoStage from './GeneralInfoStage/GeneralInfoStage';
import InvitationStage from './InvitationStage/InvitationStage';
import SearchStage from './SearchStage/SearchStage';
import ApproveStage from './ApproveStage/ApproveStage';
import Stages from './Stages';
import { apiCustomerCreate } from '../../api';
import { CUSTOMER_TYPE } from '../../helpers';
import styles from './styles';

const propTypes = {
  producer: PropTypes.object.isRequired,
  onDone: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

const defaultProps = {
  loading: false,
};

class CustomerDialog extends React.Component {
  state = {
    customer: null,
    error: null,
    stage: Stages.SEARCH,
    billingAddress: null,
    shippingAddress: null,
    mainUser: null,
    googleHit: null,
    customerType: CUSTOMER_TYPE.WHOLESALE,
    terms: null,
  };

  save = (onError) => {
    const { billingAddress, shippingAddress, mainUser, terms, customerType } = this.state;

    let data = {
      type: customerType,
      ...mainUser,
      ...terms,
      billingAddresses: [billingAddress],
      shippingAddresses: [shippingAddress],
    };

    if (customerType === CUSTOMER_TYPE.WHOLESALE) {
      data['companyName'] = billingAddress.companyName;
    }

    apiCustomerCreate(
      data,
      (customer) => {
        this.setState({
          error: null,
          customer: customer,
          stage: Stages.INVITATION,
        });
      },
      (error) => {
        if (error.response && error.response.data && error.response.data.code && error.response.data.code === 6009) {
          this.setState({
            stage: Stages.ADDRESSES,
            error: error.response,
          });
        }

        onError && onError(error.response);
      }
    );
  };

  render() {
    const { onClose, onDone, classes, producer } = this.props;

    const { stage, customer, billingAddress, shippingAddress, googleHit } = this.state;

    return (
      <AdminDialog open closeButton paperClass={classes.paper} onClose={() => onClose(customer)}>
        {stage === Stages.SEARCH && (
          <SearchStage
            onApprove={(customer) => this.setState({ customer: customer, stage: Stages.APPROVE })}
            onGooglePlacesHitClick={(googleHit, customerType) => {
              this.setState({
                stage: Stages.ADDRESSES,
                googleHit: googleHit,
                customerType: customerType,
              });
            }}
            producer={producer}
          />
        )}

        {stage === Stages.APPROVE && (
          <ApproveStage
            onApproved={onDone}
            customer={customer}
            onBack={() => this.setState({ stage: Stages.SEARCH })}
          />
        )}

        {stage === Stages.ADDRESSES && (
          <AddressStage
            googleHit={this.state.googleHit}
            error={this.state.error}
            customerType={this.state.customerType}
            initialShippingAddressData={shippingAddress || googleHit}
            initialBillingAddressData={billingAddress || googleHit}
            onBack={() =>
              this.setState({
                stage: Stages.SEARCH,
                billingAddress: null,
                shippingAddress: null,
              })
            }
            onDone={({ billingAddress, shippingAddress }) => {
              this.setState({
                stage: Stages.GENERAL_INFO,
                billingAddress,
                shippingAddress,
              });
            }}
          />
        )}

        {stage === Stages.GENERAL_INFO && (
          <GeneralInfoStage
            initialUserData={this.state.mainUser}
            initialTermsData={this.state.terms}
            onBackClick={({ user, terms }) => {
              this.setState({
                stage: Stages.ADDRESSES,
                mainUser: user,
                terms: terms,
              });
            }}
            onDone={({ user, terms }, onError) => {
              this.setState(
                {
                  mainUser: user,
                  terms,
                },
                () => {
                  this.save(onError);
                }
              );
            }}
          />
        )}

        {stage === Stages.INVITATION && (
          <InvitationStage onDone={() => onDone && onDone(customer)} customer={this.state.customer} />
        )}
      </AdminDialog>
    );
  }
}

CustomerDialog.propTypes = propTypes;
CustomerDialog.defaultProps = defaultProps;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(CustomerDialog)));
