import axios from 'axios';
import { getRequestConfig, REQUEST_TYPES } from '../request';
import API_END_POINTS from '../endpoints';

/**
 * Retrieve "Sales — Items" report
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiRetrieveReportSalesItems = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.REPORT_SALES_ITEMS);
  config.params = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Retrieve "Sales — Orders" report
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiRetrieveReportSalesOrders = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.REPORT_SALES_ORDERS);
  config.params = data;
  axios(config)
    .then((response) => {
      let rows = response.data.map((row) => {
        return {
          ...row,
          shippingFee: row.shipping.fee,
          deliveryCompany: row.shipping.deliveryCompany ? row.shipping.deliveryCompany.name : '-',
        };
      });

      onSuccess && onSuccess(rows);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Retrieve "Sales — Items per account" report
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiRetrieveReportSalesItemsPerAccount = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.REPORT_SALES_ITEMS_PER_ACCOUNT);
  config.params = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Retrieve "Sales — Items per CSA account" report
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiRetrieveReportSalesItemsPerCSAAccount = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.REPORT_SALES_ITEMS_PER_CSA_ACCOUNT);
  config.params = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Retrieve "Sales — Company per month" report
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiRetrieveReportSalesCompanyPerMonth = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.REPORT_SALES_COMPANY_PER_MONTH);
  config.params = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Retrieve "Sales — orders with line items" report
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiRetrieveReportSalesOrdersWithLineItems = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.REPORT_SALES_ORDERS_WITH_LINE_ITEMS);
  config.params = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Retrieve "Sales — grouped by products" report
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiRetrieveReportSalesItemsGroupedByProducts = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.REPORT_SALES_ITEMS_GROUPED_BY_PRODUCTS);
  config.params = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Retrieve "Reconcile - Quick Books" report
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiRetrieveQuickBooksReconcile = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.REPORT_QUICK_BOOKS_RECONCILE);
  config.params = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Retrieve "Sales — grouped by products" report
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiRetrieveReportCSA = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.REPORT_CSA);
  config.params = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Retrieve "Sales — grouped by products" report
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiRetrieveReportCSASummary = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.REPORT_CSA_SUMMARY);
  config.params = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

export const apiRetrieveReportCSALineItems = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.REPORT_CSA_LINE_ITEMS);
  config.params = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

export const apiRetrieveReportSalesGroupByLocations = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.REPORT_ORDERS_GROUPED_BY_LOCATION);
  config.params = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};
