import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from '../hocs';
import { Spinner } from '../components';
import { deliveriesSignIn, deliveriesSignOut, setDeliveriesLoading } from '../store/actions';
import { apiGetDeliveriesUser } from '../api';
import ErrorScene from '../scenes/ErrorScene/ErrorScene';
import { isJWTExpired, jwtDecode } from '../helpers';

const PrivatRouteWithTokenLogin = (props) => {
  const {
    component: Component,
    errorComponent: ErrorComponent,
    render,
    match,
    history,
    componentProps,
    ...rest
  } = props;

  const { token } = match.params;
  const [initialLoading, setInitialLoading] = useState(!!token);
  const [tokenApproved, setTokenApproved] = useState(false);
  const dispatch = useDispatch();
  const onLogIn = (data, onReady) => dispatch(deliveriesSignIn(data, onReady));
  const onSignOut = () => dispatch(deliveriesSignOut());
  const onLoading = (isLoading) => dispatch(setDeliveriesLoading(isLoading));

  const { authenticated, deliveriesUserToken, loading } = useSelector((state) => {
    return {
      authenticated: !!state.deliveriesApp.deliveriesToken,
      deliveriesUserToken: state.deliveriesApp.deliveriesToken,
      loading: state.deliveriesApp.loading,
    };
  });

  useEffect(
    () => {
      if (isJWTExpired(deliveriesUserToken)) {
        onSignOut();
        setTokenApproved(false);
      }

      if (!!token) {
        const tokenData = jwtDecode(token);
        const savedTokenData = jwtDecode(deliveriesUserToken);
        const deliveriesUserId = tokenData?.uid;

        if (deliveriesUserId !== savedTokenData?.uid) {
          onSignOut();
        }

        apiGetDeliveriesUser(
          token,
          deliveriesUserId,
          () => {
            setInitialLoading(false);
            setTokenApproved(true);
            onLogIn({
              token: token,
            });
          },
          () => {
            onSignOut();
            onLoading(false);
            setInitialLoading(false);
            setTokenApproved(false);
          }
        );
      } else {
        setInitialLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token]
  );

  const getContent = () => {
    if (initialLoading) {
      return null;
    } else if (!!authenticated) {
      return (
        <>
          <Component {...rest} {...componentProps} />
        </>
      );
    } else if (tokenApproved) {
      return (
        <>
          <Component {...rest} {...componentProps} />
        </>
      );
    } else {
      return <ErrorScene />;
    }
  };

  return (
    <>
      {loading && <Spinner />}
      {getContent()}
    </>
  );
};

export default withRouter(PrivatRouteWithTokenLogin);
