import React from 'react';
import { COLORS } from '../constants/colors';

const CalcButton8 = (props) => {
  const { color = COLORS.blackTintedBlue, ...other } = props;
  return (
    <svg width="25" height="34" viewBox="0 0 25 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <path
        d="M2.80059 33H4.2752V27.1699H6.38945V25.9541H0.686329V27.1699H2.80059V33ZM12.3424 33.1807C14.1588 33.1807 15.2916 32.1309 15.2916 30.5684V25.9541H13.817V30.4365C13.817 31.3496 13.2896 31.9307 12.3424 31.9307C11.3951 31.9307 10.8629 31.3496 10.8629 30.4365V25.9541H9.38828V30.5684C9.38828 32.1357 10.5357 33.1807 12.3424 33.1807ZM20.5463 33H22.3627L24.7113 25.9541H23.0512L21.4984 31.3594H21.4105L19.8529 25.9541H18.1977L20.5463 33Z"
        fill={color}
      />
      <path
        d="M13.75 18.293C17.4854 18.293 20.1465 16.1934 20.1465 13.2393V13.2148C20.1465 11.042 18.6206 9.23535 16.3623 8.71045V8.66162C18.291 8.06348 19.5117 6.59863 19.5117 4.74316V4.71875C19.5117 2.05762 17.0825 0.0922852 13.75 0.0922852C10.4175 0.0922852 7.98828 2.05762 7.98828 4.71875V4.74316C7.98828 6.59863 9.20898 8.06348 11.1377 8.66162V8.71045C8.87939 9.23535 7.35352 11.042 7.35352 13.2148V13.2393C7.35352 16.1934 10.0146 18.293 13.75 18.293ZM13.75 7.84375C11.626 7.84375 10.1733 6.63525 10.1733 4.92627V4.90186C10.1733 3.19287 11.626 1.98438 13.75 1.98438C15.874 1.98438 17.3267 3.19287 17.3267 4.90186V4.92627C17.3267 6.63525 15.874 7.84375 13.75 7.84375ZM13.75 16.3765C11.3208 16.3765 9.5874 15.0093 9.5874 13.105V13.0806C9.5874 11.1519 11.3086 9.77246 13.75 9.77246C16.1914 9.77246 17.9126 11.1519 17.9126 13.0806V13.105C17.9126 15.0093 16.1792 16.3765 13.75 16.3765Z"
        fill={color}
      />
    </svg>
  );
};

export default CalcButton8;
