import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AdminDialog, AdminDialogContent, AdminDialogTitle, PrimaryButton, PrimarySelect } from '../../components';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import { TABLE_ROW_DETAIL_TYPES, toPaper } from '../../helpers';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(styles);

const propTypes = {
  onClose: PropTypes.func,
  onDone: PropTypes.func,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  availableVariants: PropTypes.array,
};

const defaultProps = {
  availableVariants: [],
};

const TableRowDetailDialog = (props) => {
  const { onClose, selected, availableVariants, onDone, open } = props;
  const intl = useIntl();
  const classes = useStyles();
  const initialDetailRowState = {
    selectedDetailRowVariant: -1,
    detailRowsPropertyName: null,
    detailRowColumns: null,
  };
  const [detailRowState, setDetailRowState] = useState(initialDetailRowState);
  const [initialLoaded, setInitialLoaded] = useState(false);

  useEffect(() => {
    if (selected !== detailRowState.selectedDetailRowVariant) {
      setDetailRowState(getVariant(selected));
      if (!initialLoaded) {
        onDone(getVariant(selected));
        setInitialLoaded(true);
      }
    }
  }, [selected]);

  const onChange = (event) => {
    const value = event.target.value;

    setDetailRowState(getVariant(value));
  };

  const onFinish = () => {
    onDone(detailRowState);
  };

  function getVariant(variant) {
    switch (variant) {
      case TABLE_ROW_DETAIL_TYPES.SUBSCRIPTION_PRODUCTS: {
        return {
          selectedDetailRowVariant: TABLE_ROW_DETAIL_TYPES.SUBSCRIPTION_PRODUCTS,
          detailRowsPropertyName: 'variants',
          detailRowColumns: [
            {
              name: 'productName',
              title: intl.formatMessage({ id: 'dialog.tableRowDetail.productName' }),
            },
            {
              name: 'variantName',
              title: intl.formatMessage({ id: 'dialog.tableRowDetail.variantName' }),
            },
            {
              name: 'period',
              title: intl.formatMessage({ id: 'dialog.tableRowDetail.period' }),
              getCellValue: (row) => row.period && <FormattedMessage id={`global.${row.period}`} />,
            },
            {
              name: 'amount',
              title: intl.formatMessage({ id: 'dialog.tableRowDetail.amount' }),
              getCellValue: (row) => toPaper(row.amount) || '-',
            },
            {
              name: 'quantity',
              title: intl.formatMessage({ id: 'dialog.tableRowDetail.quantity' }),
              getCellValue: (row) => toPaper(row.quantity) || '-',
            },
          ],
        };
      }
      default:
        return initialDetailRowState;
    }
  }

  return (
    <AdminDialog open={open} closeButton onClose={onClose}>
      <AdminDialogTitle title={intl.formatMessage({ id: 'dialog.tableRowDetail.title' })} />
      <AdminDialogContent className={classes.wrapper}>
        <PrimarySelect
          value={detailRowState.selectedDetailRowVariant}
          formControlClassName={classes.selectFormControl}
          onChange={onChange}>
          <MenuItem value={-1}>{intl.formatMessage({ id: 'dialog.tableRowDetail.chooseDetailParam' })}</MenuItem>

          {availableVariants.includes(TABLE_ROW_DETAIL_TYPES.SUBSCRIPTION_PRODUCTS) && (
            <MenuItem
              key={TABLE_ROW_DETAIL_TYPES.SUBSCRIPTION_PRODUCTS}
              value={TABLE_ROW_DETAIL_TYPES.SUBSCRIPTION_PRODUCTS}>
              {intl.formatMessage({ id: 'dialog.tableRowDetail.products' })}
            </MenuItem>
          )}
        </PrimarySelect>

        <div className={classes.controls}>
          <PrimaryButton onClick={onFinish}>{intl.formatMessage({ id: 'global.apply' })}</PrimaryButton>
        </div>
      </AdminDialogContent>
    </AdminDialog>
  );
};

TableRowDetailDialog.propTypes = propTypes;
TableRowDetailDialog.defaultProps = defaultProps;

export default TableRowDetailDialog;
