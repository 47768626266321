class KeyboardEntity {
  constructor(props) {
    this.isOpen = props.isOpen || false;
    this.componentRef = props.componentRef || null;
    this.itemToEdit = props.itemToEdit || null;
    this.initialQuantity = props.initialQuantity || 0;
    this.prevItemToEdit = props.prevItemToEdit || null;
    this.nextItemToEdit = props.nextItemToEdit || null;
    this.onDoneAction = props.onDoneAction || (() => {});
    this.onChangeQuantity = props.onChangeQuantity || (() => {});
  }
}
export default KeyboardEntity;
