import React from 'react';
import PropTypes from 'prop-types';
import AutoComplete from './AutoComplete';
import { apiCustomersSearch } from '../../api';
import { CUSTOMER_TYPE } from '../../helpers';

const propTypes = {
  onlyActive: PropTypes.bool,
  retriever: PropTypes.func,
  selected: PropTypes.object,
  inputProps: PropTypes.object,
  onAttach: PropTypes.func,
  onDetach: PropTypes.func,
  filters: PropTypes.string,
  customerTypes: PropTypes.array,
  defaultCustomerType: PropTypes.string,
  allowSwitchCustomerType: PropTypes.bool,
  clearAfterSelect: PropTypes.bool,
  switchCustomerWrapperClass: PropTypes.string,
};

const defaultProps = {
  onlyActive: true,
  customerTypes: [CUSTOMER_TYPE.RETAIL, CUSTOMER_TYPE.WHOLESALE],
  defaultCustomerType: CUSTOMER_TYPE.WHOLESALE,
  allowSwitchCustomerType: false,
  clearAfterSelect: false,
};

const CustomersSuggestions = ({
  onAttach,
  onDetach,
  labelExtractor,
  inputLabel,
  inputProps,
  onlyActive,
  clearAfterSelect,
  defaultCustomerType,
  customerTypes,
  switchCustomerWrapperClass,
  allowSwitchCustomerType,
  selected,
  retriever,
  disabled,
}) => {
  return (
    <AutoComplete
      onAttach={onAttach}
      onDetach={onDetach}
      onlyActive={onlyActive}
      labelExtractor={labelExtractor}
      inputLabel={inputLabel}
      inputProps={inputProps}
      retriever={retriever || apiCustomersSearch}
      onChangeQuery={(query) => this.setState({ query: query })}
      clearAfterSelect={clearAfterSelect}
      selected={selected}
      defaultCustomerType={defaultCustomerType}
      customerTypes={customerTypes}
      switchCustomerWrapperClass={switchCustomerWrapperClass}
      allowSwitchCustomerType={allowSwitchCustomerType}
      disabled={disabled}
    />
  );
};

CustomersSuggestions.propTypes = propTypes;
CustomersSuggestions.defaultProps = defaultProps;

export default CustomersSuggestions;
