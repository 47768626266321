import React, { useEffect, useState } from 'react';
import NavigationMenu from './components/NavigationMenu';
import HeaderArea from './components/HeaderArea';
import { withRouter } from '../../hocs';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import ErrorToast from './components/ErrorToast';
import { COLORS } from './constants/colors';
import Spinner from './components/Spinner';
import Footer from './components/Footer';
import { apiSupplierRetrieveOrder } from '../../api';
import MinimalError from './entities/minimalError';
import Title from './components/Title';
import {
  BORDER_RADIUS_MAIN,
  ORDER_DATE_FORMAT,
  ORDER_DATE_FORMAT_TODAY,
  PADDING_CONTENT,
  PADDING_CONTENT_BOTTOM,
  PADDING_CONTENT_TOP,
} from './constants/globals';
import moment from 'moment';
import { getCustomerName, isToday } from './helpers/global';
import {getSupplierParentObject, toCurrency, weightToUser} from '../../helpers';
import EmptyImage from './assets/no.jpg';
import {
  FONT_CART_PRODUCT,
  FONT_CART_VARIANT_SUB,
  FONT_CART_VARIANT_TITLE,
  FONT_ORDER_NAME,
  FONT_QUANTITY,
} from './constants/fonts';
import SyncedDeliveryBox from './components/SyncedDeliveryBox';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: COLORS.lightBg,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 1,
  },
  content: {
    boxSizing: 'border-box',
    paddingLeft: PADDING_CONTENT,
    paddingRight: PADDING_CONTENT,
    maxWidth: 900,
    textAlign: 'left',
    width: '100%',
    backgroundColor: 'white',
    marginBottom: 20,
    paddingTop: PADDING_CONTENT_TOP,
    paddingBottom: PADDING_CONTENT_BOTTOM,
  },
  title: {
    marginBottom: 20,
    textAlign: 'left',
  },

  itemsTotalWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
    marginTop: 20,
  },
  itemsTotalCount: {
    ...FONT_CART_PRODUCT,
    flex: 1,
    color: COLORS.blackTintedBlue,
  },
  itemsTotalAmount: {
    ...FONT_CART_PRODUCT,
    textAlign: 'right',
    paddingLeft: 20,
    color: COLORS.blackTintedBlue,
  },

  orderWrapper: {},
  lastUpdatedTimeWrapper: {
    marginBottom: 20,
  },
  lastUpdatedTime: {
    ...FONT_ORDER_NAME,
    color: COLORS.grey,
  },
  itemsWrapper: {},

  lineItemWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: BORDER_RADIUS_MAIN,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 5,
    paddingRight: 5,
    marginLeft: -5,
    marginRight: -5,
    backgroundColor: COLORS.white,
    transition: 'background-color 0.3s ease-in-out',
    // cursor: 'pointer',
    '&:hover': {
      backgroundColor: COLORS.backgroundLight,
    },
  },
  lineItemImage: {
    width: 56,
    height: 56,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: BORDER_RADIUS_MAIN,
    marginRight: 10,
  },
  itemImage: {
    position: 'absolute',
    maxHeight: 60,
  },
  lineItemContent: {
    flex: 1,
  },
  lineItemName: {
    ...FONT_CART_VARIANT_TITLE,
    marginBottom: 5,
    color: COLORS.blackTintedBlue,
  },
  lineItemPrice: {
    ...FONT_CART_VARIANT_SUB,
    color: COLORS.grey,
  },
  lineItemOrdered: {
    ...FONT_QUANTITY,
    paddingLeft: 20,
    color: COLORS.blackTintedBlue,
  },
}));

const SupplierSalesOrderScene = (props) => {
  const { match } = props;
  const { id: orderId } = match.params;

  const classes = useStyles();
  const intl = useIntl();

  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [parentProducer, setParentProducer] = useState(null);

  useEffect(() => {
    setParentProducer(getSupplierParentObject());
    getOrder();
  }, []);

  const getOrder = () => {
    apiSupplierRetrieveOrder(
      orderId,
      (response) => {
        setOrder(response);
        setLoading(false);
      },
      handleAnyError
    );
  };

  const handleAnyError = (errorObj) => {
    setLoading(false);
  };

  const drawLastUpdated = () => {
    if (isToday(order?.shipping?.date))
      return intl.formatMessage(
        { id: 'supplier.sales.order.lastUpdatedToday' },
        { time: moment.parseZone(order?.shipping?.date).format(ORDER_DATE_FORMAT_TODAY) }
      );

    return intl.formatMessage(
      { id: 'supplier.sales.order.lastUpdated' },
      { date: moment.parseZone(order?.shipping?.date).format(ORDER_DATE_FORMAT) }
    );
  };

  const drawLineItem = (lineItem) => {
    return (
      <div className={classes.lineItemWrapper} key={lineItem.id}>
        <div className={classes.lineItemImage}>{drawImage(lineItem)}</div>

        <div className={classes.lineItemContent}>
          <p className={classes.lineItemName}>{getProductName(lineItem)}</p>
          <p className={classes.lineItemPrice}>{getPriceAndUnit(lineItem)}</p>
        </div>

        <p className={classes.lineItemOrdered}>{weightToUser(lineItem.orderedQuantity)}</p>
      </div>
    );
  };

  const getProductName = (lineItem) => {
    if (lineItem.name.variant === lineItem.unit) return lineItem.name.product;

    if (typeof lineItem.name === 'string') return lineItem.name;

    return `${lineItem.name.product} / ${lineItem.name.variant}`;
  };

  const drawImage = (item) => {
    if (!!item?.image?.thumbSrc) {
      return <img className={classes.itemImage} src={item.image.thumbSrc} alt={item.name} />;
    } else {
      return <img className={classes.itemImage} src={EmptyImage} alt={item.name} />;
    }
  };

  const getPriceAndUnit = (variant) => {
    return `${toCurrency(variant.unitPrice, intl)} / ${weightToUser(variant.unitQuantity, true)} ${variant.unit}`;
  };

  const drawNumberOfItems = (numberOfItems) => {
    if (numberOfItems < 2)
      return intl.formatMessage({ id: 'supplier.sales.numberOfItemsSingle' }, { amount: numberOfItems });

    return intl.formatMessage({ id: 'supplier.sales.numberOfItems' }, { amount: numberOfItems });
  };

  return (
    <div className={classes.root}>
      <ErrorToast />
      <HeaderArea onlyBackButton />

      <div className={classes.contentWrapper}>
        <div className={classes.content}>
          <Title className={classes.title} text={intl.formatMessage({ id: 'supplier.sales.order.title' })} />

          {!loading ? (
            <>
              <div className={classes.orderWrapper}>
                <div className={classes.lastUpdatedTimeWrapper}>
                  <p className={classes.lastUpdatedTime}>{drawLastUpdated()}</p>
                </div>

                <SyncedDeliveryBox
                  to={'/supplier/foodHubInfo'}
                  image={parentProducer?.image?.thumbSrc}
                  textComponent={({ textClass, boldTextClass }) => (
                    <p className={textClass}>
                      <span className={boldTextClass}>{getCustomerName(order.customer)}</span>
                      <br />
                      {intl.formatMessage({ id: 'supplier.foodHub.seeDeliveryInfo' })}
                    </p>
                  )}
                />

                {!!order?.lineItems?.length && (
                  <div className={classes.itemsWrapper}>
                    <div className={classes.itemsTotalWrapper}>
                      <div className={classes.itemsTotalCount}>{drawNumberOfItems(order?.lineItems?.length)}</div>
                      <div className={classes.itemsTotalAmount}>{toCurrency(order.total, intl)}</div>
                    </div>
                    {order.lineItems.map((lineItem) => drawLineItem(lineItem))}
                  </div>
                )}
              </div>
            </>
          ) : (
            <Spinner />
          )}
        </div>
      </div>

      <NavigationMenu />
      <Footer />
    </div>
  );
};

export default withRouter(SupplierSalesOrderScene);
