import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ArrowDownward from '@material-ui/icons/KeyboardArrowDown';

const styles = {
  arrow: {
    marginRight: -24,
    cursor: 'pointer',
    zIndex: 100,
  },
  arrowInSubCell: {
    marginRight: 24,
    marginLeft: '-40px !important',
    cursor: 'pointer',
    zIndex: 100,
  },
};

const propTypes = {
  collapsed: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

class CollapseArrow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: this.props.collapsed,
    };
  }

  onToggle = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const { onToggle } = this.props;

    const collapsed = !this.state.collapsed;
    this.setState({ collapsed }, () => {
      onToggle && onToggle();
    });

    return false;
  };

  render() {
    const { classes, inSubCell } = this.props;

    return this.state.collapsed ? (
      <ArrowRight className={inSubCell ? classes.arrowInSubCell : classes.arrow} onClick={this.onToggle} />
    ) : (
      <ArrowDownward className={inSubCell ? classes.arrowInSubCell : classes.arrow} onClick={this.onToggle} />
    );
  }
}

CollapseArrow.propTypes = propTypes;

export default withStyles(styles)(CollapseArrow);
