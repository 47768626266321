import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../helpers';
import { SecondaryButton } from '../../../components';
import { apiCustomerPatch, apiOrderBook, apiOrderBookPayment } from '../../../api';

const styles = {
  booked: {
    color: COLORS.text,
    fontSize: 12,
  },
  notBooked: {
    color: COLORS.violet,
    fontSize: 12,
  },
  error: {
    color: COLORS.violet,
    marginTop: 10,
    fontSize: 12,
  },
};

const propTypes = {
  row: PropTypes.object.isRequired,
  openAddCustomerDialog: PropTypes.func,
  onForceReload: PropTypes.func,
};

// To use in special cases
/*const GENERAL_ERROR_CODES = [
  'aaba2392-1781-11eb-adc1-0242ac120002', // QuickBooks general error
  'ca9c103a-1835-11eb-adc1-0242ac120002', // QuickBooks item not found
  
  '74ddd98e-1782-11eb-adc1-0242ac120002', // QuickBooks authentication required
  'cde8c104-178e-11eb-adc1-0242ac120002', // QuickBooks is not configured
];*/

const ADD_CUSTOMER_ERROR_CODES = [
  'b20042c6-1781-11eb-adc1-0242ac120002', // QuickBooks customer not found
];

const CUSTOMER_BOOKING_DISABLED = [
  '8745f411-8946-472f-b347-0f061f4c14d1', // QuickBooks customer not found
];

class BookingCell extends React.Component {
  constructor(props) {
    super(props);
    const { row } = this.props;

    this.state = {
      booked: row.booked || false,
      bookingError: row.bookingError || '',
      loading: false,
      bookingErrorCode: (row.booking && row.booking.bookingErrorCode) || '',
    };
  }

  book = (event) => {
    event.stopPropagation();

    this.setState({ loading: true });

    const { row } = this.props;
    apiOrderBook(
      row.id,
      (order) => {
        this.setState(
          {
            booked: order.booked,
            bookingError: order.booking.bookingError || '',
            loading: false,
          },
          this.props.onForceReload
        );
      },
      () => this.setState({ loading: false }, this.props.onForceReload)
    );

    return false;
  };

  bookPayment = (event) => {
    event.stopPropagation();
    this.setState({ loading: true });
    const { row } = this.props;

    apiOrderBookPayment(
      row.id,
      (order) => {
        this.setState(
          {
            booked: order.booked,
            bookingError: order.booking.bookingError || '',
            loading: false,
          },
          this.props.onForceReload
        );
      },
      () => this.setState({ loading: false }, this.props.onForceReload)
    );

    return false;
  };

  onOpenAddCustomerDialog = (event) => {
    const { row } = this.props;

    event.stopPropagation();
    this.props.openAddCustomerDialog(row);

    return false;
  };

  onAllowCustomerAndRetry = (event) => {
    const { row } = this.props;

    event.stopPropagation();
    this.setState({ loading: true });

    if (!row?.customer?.id) {
      return;
    }

    apiCustomerPatch(
      row.customer.id,
      { bookToQuickBooks: true },
      () => {
        apiOrderBook(
          row.id,
          () => {
            this.props.onForceReload();
          },
          () => this.setState({ loading: false }, this.props.onForceReload)
        );
      },
      () => this.setState({ loading: false }, this.props.onForceReload)
    );

    return false;
  };

  drawErrorCases = () => {
    const { classes, intl } = this.props;

    const getButton = () => {
      if (ADD_CUSTOMER_ERROR_CODES.includes(this.state.bookingErrorCode) && this.state.bookingError) {
        return (
          <div style={{ color: COLORS.violet, marginTop: 10 }}>
            <SecondaryButton style={{ height: 36 }} onClick={this.onOpenAddCustomerDialog}>
              {intl.formatMessage({ id: 'global.addMapping' })}
            </SecondaryButton>
          </div>
        );
      }

      if (CUSTOMER_BOOKING_DISABLED.includes(this.state.bookingErrorCode) && this.state.bookingError) {
        return (
          <div style={{ color: COLORS.violet, marginTop: 10 }}>
            <SecondaryButton style={{ height: 36 }} onClick={this.onAllowCustomerAndRetry}>
              {intl.formatMessage({ id: 'quickBooks.allowQBAndRetry' })}
            </SecondaryButton>
          </div>
        );
      }

      return (
        <span style={{ color: COLORS.violet }}>
          <SecondaryButton style={{ height: 36 }} onClick={this.book}>
            {intl.formatMessage({ id: 'global.retry' })}
          </SecondaryButton>
        </span>
      );
    };

    return (
      <React.Fragment>
        {getButton()}
        {this.state.bookingError && <p className={classes.error}>{this.state.bookingError}</p>}
      </React.Fragment>
    );
  };

  render() {
    const { intl, classes, openAddCustomerDialog, onForceReload, ...other } = this.props;

    return (
      <React.Fragment>
        {!this.state.loading && this.state.booked && !this.state.bookingError && (
          <Table.Cell {...other}>
            <p className={classes.booked}>{intl.formatMessage({ id: 'global.booked' })}</p>
          </Table.Cell>
        )}

        {!this.state.loading && !this.state.bookingError && !this.state.booked && (
          <Table.Cell {...other}>
            <p className={classes.notBooked}>{intl.formatMessage({ id: 'global.notBooked' })}</p>
          </Table.Cell>
        )}

        {this.state.bookingError && !this.state.booked && (
          <Table.Cell {...other}>
            {!this.state.loading && this.drawErrorCases()}

            {this.state.loading && <span>{intl.formatMessage({ id: 'global.booking' })}...</span>}
          </Table.Cell>
        )}

        {/* #977 - Need to give possibility for retry when order is booked but error presented */}
        {this.state.bookingError && this.state.booked && (
          <Table.Cell {...other}>
            {!this.state.loading && (
              <React.Fragment>
                {ADD_CUSTOMER_ERROR_CODES.includes(this.state.bookingErrorCode) ? (
                  this.state.bookingError && (
                    <div style={{ color: COLORS.violet, marginTop: 10 }}>
                      <SecondaryButton style={{ height: 36 }} onClick={this.onOpenAddCustomerDialog}>
                        {intl.formatMessage({ id: 'global.addMapping' })}
                      </SecondaryButton>
                    </div>
                  )
                ) : (
                  <div style={{ color: COLORS.violet }}>
                    <SecondaryButton style={{ height: 36 }} onClick={this.bookPayment}>
                      {intl.formatMessage({ id: 'global.retry' })}
                    </SecondaryButton>
                  </div>
                )}
                {this.state.bookingError && <p className={classes.error}>{this.state.bookingError}</p>}
              </React.Fragment>
            )}

            {this.state.loading && <span>{intl.formatMessage({ id: 'global.booking' })}...</span>}
          </Table.Cell>
        )}
      </React.Fragment>
    );
  }
}

BookingCell.propTypes = propTypes;

export default withStyles(styles)(injectIntl(BookingCell));
