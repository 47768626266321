// Main tab names
import { getStore } from '../store/configure';

const MAIN_TAB_NAMES = {
  DRAFTS: 'drafts-f4j2de3j2j2df52k4e5',
  ORDERS: 'orders-s3h4de3j4j5dl56k465',
  PRODUCTS_ALL: 'products-K3h4da1j4j5dl36k428',
  PRODUCTS_ACTIVE: 'products-P4h4da1j5j5dl36k423',
  PRODUCTS_ARCHIVE: 'products-archive',
  DISCOUNTS_ALL: 'discounts-k2h5da1j4j53l36r25',
  DISCOUNTS_ACTIVE: 'discounts-d5m4da1j5j5kl43p113',
  UNIQUE_PRODUCTS_ALL: 'unique-products-V3h4da1j3j1al23k322',
  UNIQUE_PRODUCTS_ACTIVE: 'unique-products-Mh31vp5j1j2bl62k322',
  PREPURCHASES_ALL: 'prepurchases-m3h3va1j3j4dl36k423',
  SUBSCRIPTIONS_ALL: 'subscriptions-all',
  SUBSCRIPTIONS_REPORTS: 'subscriptions-reports',
  CUSTOMERS_APPROVED: 'customers-2342sdjhklh24h2ofihdf3',
  CUSTOMERS_GROUPED_BY_NAME: 'customers-23sf23422ssfddj45klh24h2o33fsdf3',
  CUSTOMERS_NOT_APPROVED: 'customers-dfnnNNfrf34HH3999ffsdee',
  CUSTOMERS_RETAIL: 'customers-re23M2sf3dj4545hklh24h2ofihdf3',
  REPORTS_ALL: 'reports-at60e59ab0519dc276136500ee',
  SUPPLIERS_ORDERS_ALL: 'suppliers-orders-all',
  SUPPLIERS_DRAFTS_ALL: 'suppliers-drafts-all',
};

function getTabSavedState(tabName) {
  const store = getStore();
  const state = store.getState();

  return state?.common?.tabsState[tabName] || null;
}

export { MAIN_TAB_NAMES, getTabSavedState };
