import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../../hocs';
import Typography from '@material-ui/core/Typography';
import BackIcon from '@material-ui/icons/ArrowBack';
import { AdminDialogTitle, AdminDialogContent, AdminDialogControls, PrimaryButton, Icon } from '../../../components';
import { CUSTOMER_TYPE } from '../../../helpers';
import BillingAddressForm from './BillingAddressForm/BillingAddressForm';
import ShippingAddressForm from './ShippingAddressForm/ShippingAddressForm';
import styles from './styles';

const propTypes = {
  error: PropTypes.object,
  customerType: PropTypes.oneOf([CUSTOMER_TYPE.WHOLESALE, CUSTOMER_TYPE.RETAIL]).isRequired,
  initialShippingAddressData: PropTypes.object,
  initialBillingAddressData: PropTypes.object,
  onDone: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

class AddressStage extends React.Component {
  constructor(props) {
    super(props);
    this.billingAddress = null;
    this.shippingAddress = null;
  }

  validate = () => {
    const { onDone } = this.props;
    const billingAddress = this.billingAddress.validate();
    const shippingAddress = this.shippingAddress.validate();
    if (billingAddress.validated && shippingAddress.validated) {
      onDone({
        billingAddress: billingAddress.data,
        shippingAddress: shippingAddress.data,
      });
    }
  };

  componentWillUnmount() {
    this.billingAddress = null;
    this.shippingAddress = null;
  }

  render() {
    const { intl, classes, onBack, initialShippingAddressData, initialBillingAddressData } = this.props;

    return (
      <React.Fragment>
        <AdminDialogTitle title={intl.formatMessage({ id: 'customers.addNewCustomer' })}>
          <Icon icon={BackIcon} style={{ fill: 'rgba(0, 0, 0, 0.5)' }} className={classes.back} onClick={onBack} />
        </AdminDialogTitle>
        <AdminDialogContent className={classes.wrapper}>
          <div className={classes.row}>
            <div style={{ marginRight: 72 }} className={classes.column}>
              <Typography gutterBottom className={classes.title}>
                <FormattedMessage id="global.billingAddress" />
              </Typography>
              <BillingAddressForm
                onRef={(address) => (this.billingAddress = address)}
                customerType={this.props.customerType}
                error={this.props.error}
                initialData={initialBillingAddressData}
              />
            </div>
            <div className={classes.column}>
              <Typography gutterBottom className={classes.title}>
                <FormattedMessage id="global.shippingAddress" />
              </Typography>
              <ShippingAddressForm
                onRef={(address) => (this.shippingAddress = address)}
                initialData={initialShippingAddressData}
              />
            </div>
          </div>

          <AdminDialogControls>
            <PrimaryButton className={classes.button} onClick={this.validate}>
              {intl.formatMessage({ id: 'global.next' })}
            </PrimaryButton>
          </AdminDialogControls>
        </AdminDialogContent>
      </React.Fragment>
    );
  }
}

AddressStage.propTypes = propTypes;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(AddressStage)));
