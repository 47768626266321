import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import {makeStyles} from '@material-ui/core/styles';
import Block from '../../scenes/SettingsScene/Components/Block';
import SubWrapper from '../../scenes/SettingsScene/Components/SubWrapper';
import RichEditorDialog from './RichEditorDialog';
import {RichTextViewer} from './index';

RichTextEditableBox.propTypes = {
  title: PropTypes.string,
  editingTitle: PropTypes.string,
  withEditIcon: PropTypes.bool,
  text: PropTypes.string,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  placeholder: PropTypes.string,
  subWrapperClass: PropTypes.string,
};

const useStyles = makeStyles(styles);

function RichTextEditableBox(props) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const {
    title,
    editingTitle,
    withEditIcon,
    text,
    onSave,
    onClose,
    notEditingWrapperClass,
    notEditingWrapperStyle,
    titleClass,
    placeholder,
    subWrapperClass,
  } = props;

  const _onStartEditing = () => {
    setOpen(true);
  };

  const _onClose = () => {
    setOpen(false);
    onClose && onClose();
  };

  const _onSave = (data, onSuccess) => {
    onSave(data, onSuccess);
    setOpen(false);
  };

  return (
    <>
      <SubWrapper subWrapperClass={subWrapperClass}>
        <Block
          title={title}
          titleClass={titleClass}
          {...(withEditIcon && {onEdit: _onStartEditing})}
          wrapperStyles={notEditingWrapperStyle}
          wrapperClass={notEditingWrapperClass}
          paperClass={classes.paperClass}>
          <div className={classes.textWrapper}>
            <RichTextViewer
              html={text || placeholder}
              className={classes.textViewer}
            />
          </div>
        </Block>
      </SubWrapper>

      {open && (
        <RichEditorDialog
          text={text}
          onSave={(data, onSuccess) => _onSave(data, onSuccess)}
          onClose={_onClose}
          requestKey={'description'}
          title={editingTitle}
        />
      )}
    </>
  );
}

export default RichTextEditableBox;
