import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../hocs';
import { injectIntl } from 'react-intl';
import Wrapper from './Components/Wrapper';
import SubWrapper from './Components/SubWrapper';
import Block from './Components/Block';
import Address from './Components/Address';
import Image from './Components/Image';
import Logotype from './Components/Logotype';
import LogotypeDialog from './Dialogs/LogotypeDialog';
import ProfileImageDialog from './Dialogs/ProfileImageDialog';
import AddressDialog from './Dialogs/AddressDialog/AddressDialog';
import { setProducer } from '../../store/actions';
import { RichTextEditableBox } from '../../components';
import { apiProducerPatch } from '../../api';

class ProfileTab extends React.Component {
  state = {
    editingAddress: false,
    editingRetailDescription: false,
    editingWholesaleDescription: false,
    editingProfileImage: false,
    editingLogotype: false,
  };

  onSave = (producer) => {
    const { dispatch } = this.props;
    dispatch(setProducer(producer));
    this.onClose();
  };

  onClose = () => {
    this.setState({
      editingAddress: false,
      editingRetailDescription: false,
      editingWholesaleDescription: false,
      editingProfileImage: false,
      editingLogotype: false,
    });
  };

  onSaveProducerDescription = (requestKey, data, onSuccess = () => {}) => {
    apiProducerPatch({ [requestKey]: data }, (producer) => {
      this.onSave(producer);
      onSuccess(producer);
    });
  };

  render() {
    const { intl, producer } = this.props;

    return (
      <Wrapper>
        <SubWrapper>
          <Block
            title={intl.formatMessage({ id: 'producer.block.address' })}
            onEdit={() => this.setState({ editingAddress: true })}>
            <Address producer={producer} />
          </Block>
        </SubWrapper>

        <RichTextEditableBox
          title={intl.formatMessage({ id: 'producer.block.retailDescription' })}
          editingTitle={intl.formatMessage({ id: 'producer.dialog.editingRetailDescription' })}
          withEditIcon
          text={producer.description}
          onSave={(data, onSuccess) => this.onSaveProducerDescription('description', data, onSuccess)}
          notEditingWrapperStyle={{ width: '736px' }}
        />

        <RichTextEditableBox
          title={intl.formatMessage({ id: 'producer.block.wholesaleDescription' })}
          editingTitle={intl.formatMessage({ id: 'producer.dialog.editingWholesaleDescription' })}
          withEditIcon
          text={producer.wholesaleDescription}
          onSave={(data, onSuccess) => this.onSaveProducerDescription('wholesaleDescription', data, onSuccess)}
          notEditingWrapperStyle={{ width: '736px' }}
        />

        <SubWrapper>
          <Block
            title={intl.formatMessage({ id: 'producer.block.profileImage' })}
            onEdit={() => this.setState({ editingProfileImage: true })}>
            <Image producer={producer} />
          </Block>

          <Block
            title={intl.formatMessage({ id: 'producer.block.logo' })}
            onEdit={() => this.setState({ editingLogotype: true })}>
            <Logotype producer={producer} />
          </Block>
        </SubWrapper>

        {this.state.editingAddress && <AddressDialog producer={producer} onSave={this.onSave} onClose={this.onClose} />}

        {this.state.editingProfileImage && (
          <ProfileImageDialog producer={producer} onSave={this.onSave} onClose={this.onClose} />
        )}

        {this.state.editingLogotype && (
          <LogotypeDialog producer={producer} onSave={this.onSave} onClose={this.onClose} />
        )}
      </Wrapper>
    );
  }
}

export default injectIntl(
  withRouter(
    connect((state) => {
      return { producer: state.producer.entity };
    })(ProfileTab)
  )
);
