export default {
  name: {
    presence: {
      message: '^validation.required'
    }
  },
  value: {
    presence: {
      message: '^validation.required'
    }
  },
  percentage: {
    presence: {
      message: '^validation.required'
    }
  },
  customer: {
    presence: {
      message: '^validation.required'
    }
  },
  subscription: {
    presence: {
      message: '^validation.csa.pleaseSpecifyShippingType'
    }
  },
}
