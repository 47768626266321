import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from '../../hocs';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { AdminDXTable, Tab, TabActions } from '../../components';
import { apiDiscountDelete, apiDiscountsRetrieve } from '../../api';
import {
  COLORS,
  countWidthOfColumns,
  DEFAULT_MAX_CELL_WIDTH,
  DEFAULT_MIN_CELL_WIDTH,
  DEFAULT_TABLE_WIDTH,
  HEADER_HEIGHT_STANDARD,
} from '../../helpers';
import { getGridPageSize, getGridPageSizes } from '../../helpers/grid';
import { CellRenderer } from './helpers';

const propTypes = {
  tab: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  columnExtensions: PropTypes.array,
  onlyActive: PropTypes.bool,
  withFilters: PropTypes.bool,
  allowCreateTab: PropTypes.bool,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onDeleteTab: PropTypes.func,
  onCreateTab: PropTypes.func,
  onPatchTab: PropTypes.func,
  onAddingFilter: PropTypes.func,
};

const defaultProps = {
  onlyActive: false,
};

class DiscountsContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      hiddenColumnNames: [],

      columnWidths: countWidthOfColumns(props.columns, [], props.columnExtensions, DEFAULT_TABLE_WIDTH - 100),
    };

    const { onRef } = this.props;
    onRef && onRef(this);
  }

  onChangeColumnsState = (hiddenColumnNames) => {
    this.setState({ hiddenColumnNames });
  };

  forceTableReload = () => {
    this.table.forceReload();
  };

  render() {
    const { tab, index, intl, columns, classes, history, allowCreateTab, columnExtensions } = this.props;

    const { hiddenColumnNames, sorting } = this.state;

    return (
      <Tab
        title={tab.name}
        key={`${index}-tab`}
        tab={tab}
        actions={
          <TabActions
            tab={tab}
            classes={classes}
            columns={columns}
            labels={{ createEntity: intl.formatMessage({ id: 'discounts.new' }) }}
            allowCreateTab={allowCreateTab}
            onCreateEntity={() => history.push(`${this.props.match.url}/new`)}
            onChangeColumnsState={this.onChangeColumnsState}
          />
        }>
        <AdminDXTable
          cellRenderer={CellRenderer}
          onRef={(table) => (this.table = table)}
          actionsHeaderCellStyle={{ textAlign: 'center' }}
          actionsCellStyle={{ textAlign: 'center' }}
          apiRetrieve={apiDiscountsRetrieve}
          pageSize={getGridPageSize()}
          pageSizes={getGridPageSizes()}
          onChangeSorting={this.onChangeSorting}
          onOpenWithFilter={(index, filter) =>
            this.setState({
              filterDialog: true,
              selectedFilter: filter,
            })
          }
          onRemoveFilter={this.handleFilterRemove}
          columns={columns}
          columnExtensions={columnExtensions}
          hiddenColumnNames={hiddenColumnNames}
          sorting={sorting}
          actions={[
            {
              icon: <EditIcon style={{ color: COLORS.text, fontSize: 18 }} />,
              action: (row) => history.push(`${this.props.match.url}/${row.id}`),
            },
            {
              icon: <DeleteIcon style={{ color: COLORS.violet, fontSize: 18 }} />,
              action: (row) =>
                apiDiscountDelete(
                  row.id,
                  () => {
                    this.table.forceReload();
                  },
                  undefined
                ),
            },
          ]}
          columnWidths={this.state.columnWidths}
          onColumnWidthsChange={(widths) => {
            this.setState({ columnWidths: widths });
          }}
          minColumnWidth={DEFAULT_MIN_CELL_WIDTH}
          maxColumnWidth={DEFAULT_MAX_CELL_WIDTH}
          allowExpandAll={true}
          stickyHeader
          stickyHeaderOffset={HEADER_HEIGHT_STANDARD.extended}
        />
      </Tab>
    );
  }
}

DiscountsContent.propTypes = propTypes;
DiscountsContent.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  return {
    producer: state.producer.object,
  };
};

export default injectIntl(withRouter(connect(mapStateToProps)(DiscountsContent)));
