import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { withStyles } from '@material-ui/core/styles';
import { PrimarySelect } from '../../../components';
import MenuItem from '@material-ui/core/MenuItem';

const styles = {};

const propTypes = {
  values: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  cellProps: PropTypes.object.isRequired,
  formatValueBeforeApplyEditing: PropTypes.func.isRequired,
  onFinishCellEditing: PropTypes.func.isRequired,
  labels: PropTypes.object,
};

const defaultProps = {
  disabled: false,
  formatValueBeforeApplyEditing: (value) => value,
};

class CellAsSelectInput extends React.Component {
  state = {
    cache: this.props.value,
    value: this.props.value,
    editingMode: false,
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown, false);
  }

  onCellDoubleClick = (event) => {
    event.preventDefault();

    if (this.props.disabled) {
      return false;
    }

    this.setState({
      editingMode: true,
    });
  };

  onFinishCellEditing = () => {
    const { formatValueBeforeApplyEditing, onFinishCellEditing } = this.props;

    let formatted = formatValueBeforeApplyEditing(this.state.value);

    this.setState(
      {
        cache: formatted,
        value: formatted,
        editingMode: false,
      },
      () => {
        onFinishCellEditing(formatted);
      }
    );
  };

  onKeyDown = (event) => {
    const { key } = event;
    if (this.state.editingMode) {
      if (key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
        this.onFinishCellEditing();
      }

      if (key === 'Escape') {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
          editingMode: false,
          value: this.state.cache,
        });
        event.preventDefault();
      }
    }
  };

  render() {
    const { classes, values, labels, disabled, cellProps, style } = this.props;

    return (
      <Table.Cell
        onDoubleClick={this.onCellDoubleClick}
        {...cellProps}
        style={{
          cursor: 'cell',
          style,
        }}>
        {!this.state.editingMode && ((labels && labels[this.state.value]) || this.state.value)}
        {this.state.editingMode && (
          <PrimarySelect
            className={classes.select}
            fullWidth
            styles={{ color: 'red' }}
            onFocus={() => this.setState({ focused: true })}
            value={this.state.value}
            onChange={(event) => this.setState({ value: event.target.value.trim() })}
            disabled={disabled}>
            {values.map((value) => (
              <MenuItem key={value} value={value}>
                {(labels && labels[value]) || value}
              </MenuItem>
            ))}
          </PrimarySelect>
        )}
      </Table.Cell>
    );
  }
}

CellAsSelectInput.propTypes = propTypes;
CellAsSelectInput.defaultProps = defaultProps;

export default withStyles(styles)(injectIntl(CellAsSelectInput));
