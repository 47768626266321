import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import withWidth from '@material-ui/core/withWidth';
import { createBrowserHistory } from 'history';
import { addAxiosInterceptors, getToken, isJWTExpired, getSupplierToken } from '../helpers';
import { PrimaryButton, Spinner } from '../components';
import { getStore } from '../store/configure';
import { showNotification, hideNotification, signOut, supplierSignOut } from '../store/actions';
import { PrivateRoute, GuestRoute, PrivatRouteWithTokenLogin } from '../routes';
import {
  HomeScene,
  LoginScene,
  OrdersScene,
  EditDraftScene,
  EditOrderScene,
  OrderScene,
  ProductsScene,
  NotFound,
  AboutScene,
  CustomerScene,
  CustomersScene,
  ReportsScene,
  DiscountsScene,
  SettingsScene,
  ProductsPropertiesScene,
  UniqueProductsScene,
  PrePurchasesScene,
  QuickBooksScene,
  CollectionsScene,
  PayoutScene,
  ProductScene,
  InvoiceScene,
  SubscriptionsScene,
  SupplierInventoryProductsScene,
  SupplierInventoryProductScene,
  SupplierSalesScene,
  SupplierContactScene,
  SupplierSalesOrderScene,
  FoodHubInfoScene,
  SuppliersScene,
  SuppliersOrdersScene,
  EditSupplierDraftScene,
  SupplierOrderScene,
  SuppliersEditOrderScene,
  SuppliersInvoiceScene,
  MemberService,
  MemberServiceCustomerReport,
} from '../scenes';
import BaseDialog from '../dialogs/BaseDialog';
import { FormattedMessage, injectIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import AppsWrapper from '../apps/AppsWrapper';
import WrappedTemporaryTooltip from '../components/TemporaryTooltip/WrappedTemporaryTooltip';

// Create history object.
const history = createBrowserHistory();
// Listen to history changes.
const historyListener = (store) => {
  history.listen(() => {
    const token = getToken();
    if (token && isJWTExpired(token)) {
      store.dispatch(signOut());
    }

    const supplierToken = getSupplierToken();
    if (supplierToken && isJWTExpired(supplierToken)) {
      store.dispatch(supplierSignOut());
    }
  });
};

class RouterWrapper extends React.Component {
  componentDidMount() {
    historyListener(getStore());
    // Add a response interceptor
    addAxiosInterceptors(this.props.showNotification, this.props.intl);
  }

  render() {
    const { initialized, globalNotification } = this.props;

    return (
      <React.Fragment>
        {!initialized && <Spinner />}

        {initialized && (
          <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<GuestRoute component={LoginScene} />} />
              <Route exact path="/sign-in-via-link" element={<GuestRoute component={LoginScene} />} />
              <Route exact path="/quickbooks/redirect" element={<PrivateRoute component={QuickBooksScene} />} />
              <Route exact path="/home" element={<PrivateRoute component={HomeScene} />} />
              <Route exact path="/orders" element={<PrivateRoute component={OrdersScene} />} />
              <Route exact path="/order/:id" element={<PrivateRoute component={OrderScene} />} />
              <Route exact path="/order/:id/invoice/:hash" element={<PrivateRoute component={InvoiceScene} />} />
              <Route path="/order/edit/:id" element={<PrivateRoute component={EditOrderScene} />} />
              <Route
                path="/order/edit/:id/discount/:did"
                element={<PrivateRoute openDiscountModal component={EditOrderScene} />}
              />
              <Route
                path="/order/edit/:id/updates"
                element={<PrivateRoute openUpdatesModal component={EditOrderScene} />}
              />
              <Route path="/draft/:id" element={<PrivateRoute component={EditDraftScene} />} />
              <Route
                path="/draft/:id/discount/:did"
                element={<PrivateRoute openDiscountModal component={EditDraftScene} />}
              />
              <Route path="/collections" element={<PrivateRoute component={CollectionsScene} />} />
              <Route path="/collections/:id" element={<PrivateRoute openAddModal component={CollectionsScene} />} />
              <Route exact path="/products" element={<PrivateRoute component={ProductsScene} />} />
              <Route exact path="/product/:id" element={<PrivateRoute component={ProductScene} />} />
              <Route exact path="/product/:id/:va" element={<PrivateRoute component={ProductScene} />} />
              <Route exact path="/products/properties" element={<PrivateRoute component={ProductsPropertiesScene} />} />
              <Route path="/products/unique" element={<PrivateRoute component={UniqueProductsScene} />} />
              <Route
                path="/products/unique/new"
                element={<PrivateRoute openAddModal component={UniqueProductsScene} />}
              />

              <Route exact path="/customers" element={<PrivateRoute component={CustomersScene} />} />
              <Route exact path="/customers/:id" element={<PrivateRoute component={CustomerScene} />} />
              <Route exact path="/reports" element={<PrivateRoute component={ReportsScene} />} />
              <Route path="/discounts" element={<PrivateRoute component={DiscountsScene} />} />
              <Route path="/discounts/:id" element={<PrivateRoute openItemModal component={DiscountsScene} />} />
              <Route path="/prepurchases" element={<PrivateRoute component={PrePurchasesScene} />} />
              <Route path="/prepurchases/:id" element={<PrivateRoute openItemModal component={PrePurchasesScene} />} />
              <Route path="/subscriptions" element={<PrivateRoute component={SubscriptionsScene} />} />
              <Route
                path="/subscriptions/:id"
                element={<PrivateRoute openItemModal component={SubscriptionsScene} />}
              />
              <Route path="/member-service" element={<PrivateRoute component={MemberService} />} />
              <Route
                path="/member-service/:customerId"
                element={<PrivateRoute component={MemberServiceCustomerReport} />}
              />
              <Route
                path="/member-service/:customerId/:id"
                element={<PrivateRoute component={MemberServiceCustomerReport} openItemModal />}
                openItemModal
              />

              <Route exact path="/settings/payouts/:id" element={<PrivateRoute component={PayoutScene} />} />
              <Route exact path="/settings" element={<PrivateRoute component={SettingsScene} />} />
              <Route path={`/settings/:id`} element={<PrivateRoute openEditModal component={SettingsScene} />} />
              <Route
                path={`/settings/password/:id`}
                element={<PrivateRoute openPwdModal component={SettingsScene} />}
              />

              <Route exact path="/supplier">
                <Route
                  path=""
                  element={
                    <PrivatRouteWithTokenLogin
                      component={SupplierInventoryProductsScene}
                      componentProps={{ active: true }}
                    />
                  }
                />
                <Route
                  path="inventory"
                  element={
                    <PrivatRouteWithTokenLogin
                      component={SupplierInventoryProductsScene}
                      componentProps={{ active: true }}
                    />
                  }
                />
                <Route
                  path="inventory/:token"
                  element={
                    <PrivatRouteWithTokenLogin
                      component={SupplierInventoryProductsScene}
                      componentProps={{ active: true }}
                    />
                  }
                />
                <Route
                  exact
                  path="inventory/unlisted"
                  element={
                    <PrivatRouteWithTokenLogin
                      component={SupplierInventoryProductsScene}
                      componentProps={{ active: false }}
                    />
                  }
                />
                <Route
                  exact
                  path="unlisted/:token"
                  element={
                    <PrivatRouteWithTokenLogin
                      component={SupplierInventoryProductsScene}
                      componentProps={{ active: false }}
                    />
                  }
                />
                <Route
                  exact
                  path="inventory/product/:id"
                  element={<PrivatRouteWithTokenLogin component={SupplierInventoryProductScene} />}
                />
                <Route
                  exact
                  path="inventory/product/:id/:token"
                  element={<PrivatRouteWithTokenLogin component={SupplierInventoryProductScene} />}
                />
                <Route path="sales" element={<PrivatRouteWithTokenLogin component={SupplierSalesScene} />} />
                <Route
                  path="sales/order/:id"
                  element={<PrivatRouteWithTokenLogin component={SupplierSalesOrderScene} />}
                />
                <Route
                  path="sales/order/:id/:token"
                  element={<PrivatRouteWithTokenLogin component={SupplierSalesOrderScene} />}
                />
                <Route path="sales/:token" element={<PrivatRouteWithTokenLogin component={SupplierSalesScene} />} />
                <Route path="contacts" element={<PrivatRouteWithTokenLogin component={SupplierContactScene} />} />
                <Route
                  path="contacts/:token"
                  element={<PrivatRouteWithTokenLogin component={SupplierContactScene} />}
                />
                <Route path="foodHubInfo" element={<PrivatRouteWithTokenLogin component={FoodHubInfoScene} />} />
                <Route path="foodHubInfo/:token" element={<PrivatRouteWithTokenLogin component={FoodHubInfoScene} />} />
                <Route
                  path=":token"
                  element={
                    <PrivatRouteWithTokenLogin
                      component={SupplierInventoryProductsScene}
                      componentProps={{ active: true }}
                    />
                  }
                />
                <Route
                  path=":token/:producerId"
                  element={
                    <PrivatRouteWithTokenLogin
                      component={SupplierInventoryProductsScene}
                      componentProps={{ active: true }}
                    />
                  }
                />
              </Route>

              <Route exact path="/suppliers" element={<PrivateRoute component={SuppliersScene} />} />
              <Route exact path="/suppliers/orders" element={<PrivateRoute component={SuppliersOrdersScene} />} />
              <Route path="/suppliers/draft/:id" element={<PrivateRoute component={EditSupplierDraftScene} />} />
              <Route
                path="/suppliers/draft/:id/discount/:did"
                element={<PrivateRoute openDiscountModal component={EditSupplierDraftScene} />}
              />
              <Route exact path="/suppliers/order/:id" element={<PrivateRoute component={SupplierOrderScene} />} />
              <Route path="/suppliers/order/edit/:id" element={<PrivateRoute component={SuppliersEditOrderScene} />} />
              <Route
                path="/suppliers/order/edit/:id/updates"
                element={<PrivateRoute openUpdatesModal component={SuppliersEditOrderScene} />}
              />
              <Route
                path="/suppliers/order/edit/:id/discount/:did"
                element={<PrivateRoute openDiscountModal component={SuppliersEditOrderScene} />}
              />
              <Route
                exact
                path="/suppliers/order/:id/invoice/:hash"
                element={<PrivateRoute component={SuppliersInvoiceScene} />}
              />

              <Route exact path="/about" element={<PrivateRoute component={AboutScene} />} />

              {/* App wrapper */}
              <Route path="/app/*" element={<AppsWrapper />} />

              <Route path={'*'} element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        )}

        <WrappedTemporaryTooltip />

        {!!globalNotification && (
          <BaseDialog
            scroll={'paper'}
            maxWidth="sm"
            open
            onClose={this.props.hideNotification}
            title={<FormattedMessage id={'global.somethingWentWrong'} />}>
            <Typography component="p" variant="body1">
              {globalNotification}
            </Typography>
            <PrimaryButton onClick={this.props.hideNotification} style={{ marginTop: 20, width: '100%' }}>
              <FormattedMessage id={'global.ok'} />
            </PrimaryButton>
          </BaseDialog>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    initialized: state.common.initialized,
    globalNotification: state.common.notification,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    showNotification: (message) => dispatch(showNotification(message)),
    hideNotification: () => dispatch(hideNotification()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(injectIntl(RouterWrapper)));
