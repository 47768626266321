export default {
  header: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 38,
    alignItems: 'center',
  },
  activity: {
    marginRight: 20
  },
  search: {
    minWidth: 300
  },
  customItem: {
    marginLeft: 'auto'
  }
};