import {ContentState, convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';

export const getEditorStateFromHtml = (html) => {
  return EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(html, (nodeName, node) => {
    // handle uncommon html tags to convert them in EditorState
    if (nodeName === 'hr') {
      return {
        type: 'HORIZONTAL_RULE',
        mutability: 'MUTABLE',
        data: {}
      };
    }
  })))
}

export const getHtmlFromEditor = (editorState) => {
  return draftToHtml(convertToRaw(editorState.getCurrentContent()));
}