class Customer {
  constructor(props ={}) {
    this.shippingAddress = props.shippingAddress || null;
  }

  getFormatterShippingAddress() {
    let address = [];

    if (this?.shippingAddress?.address1) {
      address.push(this.shippingAddress.address1)
    }

    if (this?.shippingAddress?.city) {
      address.push(this.shippingAddress.city)
    }

    if (this?.shippingAddress?.zip) {
      address.push(this.shippingAddress.zip)
    }

    return address.join(', ')
  }
}

export default Customer
