import React from 'react';
import { COLORS } from '../constants/colors';

const CalcButton2 = (props) => {
  const { color = COLORS.blackTintedBlue, ...other } = props;
  return (
    <svg width="25" height="35" viewBox="0 0 25 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <path
        d="M0.466602 34H2.01445L2.53691 32.291H5.02227L5.54473 34H7.09746L4.64629 26.9541H2.91289L0.466602 34ZM3.73809 28.3604H3.82598L4.68535 31.1875H2.87383L3.73809 28.3604ZM9.99375 34H13.1578C14.6617 34 15.5943 33.2236 15.5943 31.9883V31.9785C15.5943 31.0752 14.9156 30.377 13.983 30.3037V30.2158C14.7057 30.1084 15.2574 29.459 15.2574 28.7168V28.707C15.2574 27.623 14.442 26.9541 13.0748 26.9541H9.99375V34ZM12.6988 28.0479C13.402 28.0479 13.8072 28.3896 13.8072 28.9609V28.9707C13.8072 29.5518 13.3727 29.8838 12.5768 29.8838H11.4684V28.0479H12.6988ZM12.7477 30.8555C13.6168 30.8555 14.0904 31.207 14.0904 31.8613V31.8711C14.0904 32.54 13.6314 32.9014 12.7818 32.9014H11.4684V30.8555H12.7477ZM21.6205 34.1807C23.3197 34.1807 24.5258 33.1748 24.6771 31.7002L24.682 31.6465H23.2465L23.2367 31.6855C23.0805 32.4375 22.4701 32.9307 21.6254 32.9307C20.5121 32.9307 19.8236 31.9932 19.8236 30.4795V30.4697C19.8236 28.9561 20.5121 28.0234 21.6205 28.0234C22.4604 28.0234 23.0902 28.5605 23.2416 29.3516V29.3857H24.6771V29.3271C24.5404 27.8379 23.2904 26.7734 21.6205 26.7734C19.5746 26.7734 18.3148 28.1846 18.3148 30.4697V30.4795C18.3148 32.7646 19.5795 34.1807 21.6205 34.1807Z"
        fill={color}
      />
      <path
        d="M8.67139 19H20.0728V17.0225H11.7231V16.8271L15.7271 12.689C18.9131 9.40527 19.7798 7.94043 19.7798 5.92627V5.90186C19.7798 3.05762 17.4238 0.970215 14.3477 0.970215C10.9785 0.970215 8.56152 3.21631 8.54932 6.34131L8.57373 6.35352L10.6733 6.36572L10.6855 6.34131C10.6855 4.26611 12.0894 2.88672 14.2012 2.88672C16.2764 2.88672 17.5337 4.27832 17.5337 6.13379V6.1582C17.5337 7.69629 16.8745 8.61182 14.6284 11.041L8.67139 17.4863V19Z"
        fill={color}
      />
    </svg>
  );
};

export default CalcButton2;
