import { COLORS } from '../../../helpers';

export default {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    position: 'relative',
    width: 675,
  },
  contentWithSwitch: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  text: {
    fontSize: 14,
    color: COLORS.textSecondary,
    fontFamily: 'Roboto, sans-serif',
  },
  smallLabelRoot: {
    marginLeft: 0,
    marginRight: 0,
  },
  smallLabel: {
    fontSize: 9,
    color: COLORS.actionLabel,
  },
  mr0: {
    marginRight: 0,
  },
  mb40: {
    marginBottom: 40,
  },
  mt20: {
    marginTop: 20,
  },
};
