import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AdminDialogTitle from '../AdminDialog/AdminDialogTitle';
import AdminDialogContent from '../AdminDialog/AdminDialogContent';
import AdminDialogControls from '../AdminDialog/AdminDialogControls';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import AdminDialog from '../AdminDialog/AdminDialog';
import { Spinner } from '../../components';
import { COLORS } from '../../helpers';
import ErrorBox from '../ErrorBox/ErrorBox';
import classNames from 'classnames';

const styles = () => ({
  wrapper: {},
  title: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 24,
    fontWeight: 400,
    color: '#212121',
  },
  message: {
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 400,
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.black,
    marginBottom: 40,
    whiteSpace: 'pre-line',
  },
  button: {
    minWidth: '40%',
  },
  buttonLabel: {
    textTransform: 'uppercase',
    fontFamily: "'Roboto', sans-serif",
    fontSize: 15,
    fontWeight: 500,
  },
  mr20: {
    marginRight: 20,
  },
  controls: {
    margin: '27px 0 0 0',
    textAlign: 'center',
  },
  control: {
    padding: '0 105px',
  },
  paper_tiny: {
    padding: '27px 27px 27px',
    borderRadius: 8,
    maxHeight: 'calc(100% - 16px)',
    maxWidth: 400,
  },
  paper_medium: {
    padding: '47px 52px 74px',
    maxHeight: 'calc(100% - 16px)',
  },
  paper_high: {
    padding: '47px 110px 74px',
    maxHeight: 'calc(100% - 16px)',
  },
});

const propTypes = {
  title: PropTypes.string.isRequired,
  confirmTitle: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  error: PropTypes.string,
};
const defaultProps = {
  confirmTitle: 'global.ok',
  cancelButtonText: 'global.cancel',
  errorButtonText: 'global.ok',
  error: null,
};

export const CONFIRM_DIALOG_VARIANTS = {
  TINY: 'tiny',
};

const ConfirmDialog = ({
  message,
  onClose,
  intl,
  classes,
  loading,
  confirmTitle,
  onConfirm,
  title,
  withCancel,
  cancelButtonText,
  variant,
  error,
  errorButtonText,
  ...others
}) => (
  <AdminDialog
    open
    closeButton
    onClose={onClose}
    {...(!!variant
      ? {
          paperClass: classes[`paper_${variant}`],
        }
      : {})}
    {...others}>
    <AdminDialogTitle title={title} className={classes.title} />
    <AdminDialogContent className={classes.wrapper}>
      {loading && <Spinner size={50} />}
      {message && <p className={classes.message}>{message}</p>}
      {!!error && <ErrorBox error={error} />}
      <AdminDialogControls>
        {withCancel && cancelButtonText && (
          <PrimaryButton
            className={classNames([classes.button, classes.mr20])}
            labelClass={classes.buttonLabel}
            onClick={onClose}>
            {intl.formatMessage({ id: cancelButtonText })}
          </PrimaryButton>
        )}
        {!error && (
          <PrimaryButton className={classes.button} labelClass={classes.buttonLabel} onClick={onConfirm}>
            {intl.formatMessage({ id: confirmTitle })}
          </PrimaryButton>
        )}
        {!!error && (
          <PrimaryButton className={classes.button} labelClass={classes.buttonLabel} onClick={onClose}>
            {intl.formatMessage({ id: errorButtonText })}
          </PrimaryButton>
        )}
      </AdminDialogControls>
    </AdminDialogContent>
  </AdminDialog>
);

ConfirmDialog.propTypes = propTypes;
ConfirmDialog.defaultProps = defaultProps;

export default withStyles(styles)(injectIntl(ConfirmDialog));
