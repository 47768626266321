import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from '../../../hocs';
import { withStyles } from '@material-ui/core/styles';
import { validateField } from '../../../helpers';
import {
  ErrorBox,
  Spinner,
  PrimaryTextField,
  PrimaryButton,
  ErrorFormattedMessage,
  SimpleDialogContent,
  BlackThirdButton,
} from '../../../components';
import validationRules from './validation';
import { apiLogin } from '../../../api';
import styles from './styles';
import { onEnterPress } from '../../../helpers/events';

class EmailAndPasswordStage extends React.Component {
  constructor(props) {
    super(props);

    const { location } = props;

    let username = '';
    if (location.state && location.state.fromResolver && location.state.username) {
      username = location.state.username;
    }

    this.state = {
      isLoading: false,
      /*username: process.env.NODE_ENV === 'development' ?
        "denKison@gmail.com" : "",
      password: process.env.NODE_ENV === 'development' ?
        "123456" : "",*/
      username: username,
      password: '',

      usernameError: '',
      passwordError: '',
      requestError: '',
    };

    this.validate = this.validate.bind(this);
  }

  validate() {
    const usernameError = validateField(validationRules, 'username', this.state.username);
    const passwordError = validateField(validationRules, 'password', this.state.password);

    if (!usernameError && !passwordError) {
      this.setState({
        usernameError: '',
        passwordError: '',
        requestError: '',
        isLoading: true,
      });

      const onError = () => {
        this.setState({
          requestError: 'error.accountOrPasswordIncorrect',
          isLoading: false,
        });
      };

      const onSuccess = (response) => {
        this.setState({
          requestError: '',
          isLoading: false,
        });

        this.props.onDone({
          token: response.data.token,
          producer: response.data.producer,
          acl: response.data.acl ? response.data.acl : null,
        });
      };

      apiLogin(this.state.username, this.state.password, onSuccess, onError);
    } else {
      this.setState({
        usernameError: usernameError,
        passwordError: passwordError,
        isLoading: false,
      });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        {this.state.isLoading ? <Spinner size={50} /> : null}

        <ErrorBox error={this.state.requestError ? <FormattedMessage id={this.state.requestError} /> : ''} />
        <SimpleDialogContent>
          <div className={classes.userNameWrapper}>
            <PrimaryTextField
              error={!!this.state.usernameError}
              onChange={(event) => this.setState({ username: event.target.value.trim() })}
              value={this.state.username}
              autoFocus
              id="username-input"
              margin="dense"
              InputLabelProps={{ shrink: true }}
              label={<FormattedMessage id="global.username" />}
              type="username"
              helperText={<ErrorFormattedMessage id={this.state.usernameError} />}
              fullWidth
              onKeyPress={(event) => {
                onEnterPress(event, this.validate);
              }}
            />
          </div>
          <div className={classes.passwordWrapper}>
            <PrimaryTextField
              error={!!this.state.passwordError}
              onChange={(event) => this.setState({ password: event.target.value.trim() })}
              value={this.state.password}
              id="password-input"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              label={<FormattedMessage id="global.password" />}
              type="password"
              helperText={<ErrorFormattedMessage id={this.state.passwordError} />}
              fullWidth
              onKeyPress={(event) => {
                onEnterPress(event, this.validate);
              }}
            />
          </div>

          <div className={classes.loginWrapper}>
            <PrimaryButton className={classes.button} onClick={this.validate}>
              <FormattedMessage id="global.signIn" />
            </PrimaryButton>
          </div>

          <div className={classes.controls}>
            {/*<BlackThirdButton onClick={this.props.onForgotPassword}>*/}
            {/*  <FormattedMessage id="messages.forgotPassword"/>*/}
            {/*</BlackThirdButton>*/}

            <p className={classes.preThirdButton}>
              <FormattedMessage id="messages.DoNotHaveAnAccount" />
              <BlackThirdButton onClick={this.props.onNoAccount}>
                <FormattedMessage id="global.signUp" />
              </BlackThirdButton>
            </p>
          </div>
        </SimpleDialogContent>
      </React.Fragment>
    );
  }
}

EmailAndPasswordStage.propTypes = {
  onNotRegistered: PropTypes.func.isRequired,
  onDefaultPassword: PropTypes.func.isRequired,
  onNoAccount: PropTypes.func.isRequired,
  onForgotPassword: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
};

export default withStyles(styles)(withRouter(injectIntl(EmailAndPasswordStage)));
