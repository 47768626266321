import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../hocs';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { PrimaryTextField } from '../../components';
import styles from './styles';
import { COLORS } from '../../helpers';

const propTypes = {
  types: PropTypes.array.isRequired,
  skip: PropTypes.object,
  selected: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
};

const defaultProps = {
  types: [],
};

class ProductSharedPropertySelect extends React.Component {
  state = {
    search: '',
  };

  onSelect = (type) => {
    const { onSelect } = this.props;
    onSelect(type);
  };

  onSearch = (event) => {
    let search = event.target.value;
    this.setState({ search: search });
    this.onSelect(null);
  };

  prepareTypes = (types) => {
    const { skip } = this.props;
    const { search } = this.state;

    let prepared = types;
    if (skip) {
      prepared = types.filter((type) => type.id !== skip.id);
    }
    prepared = prepared.filter((type) => type.name.toLowerCase().indexOf(search.toLowerCase()) !== -1).slice(0, 6);

    return prepared;
  };

  render() {
    const { classes, types, selected } = this.props;

    return (
      <div className={classes.wrapper}>
        <PrimaryTextField
          className={classes.input}
          onChange={this.onSearch}
          value={this.state.search}
          id="search-input"
          type="text"
          margin="normal"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon style={{ color: COLORS.text }} />
              </InputAdornment>
            ),
          }}
        />

        <div className={classes.types}>
          {this.prepareTypes(types).map((type, index) => (
            <div onClick={() => this.onSelect(type)} key={`${type}-${index}`} className={classes.type}>
              <span className={classes.imageCell}>
                {type.image && <img src={type.image.src} alt={type.name} className={classes.img} />}
              </span>
              <span className={classes.text}>{type.name}</span>
              <span style={{ marginLeft: 'auto' }} className={classes.cell}>
                {selected && selected.id === type.id ? (
                  <CheckCircleIcon className={classes.icon} />
                ) : (
                  <RadioButtonUnchecked className={classes.icon} />
                )}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

ProductSharedPropertySelect.propTypes = propTypes;
ProductSharedPropertySelect.defaultProps = defaultProps;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(ProductSharedPropertySelect)));
