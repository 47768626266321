import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../hocs';
import MenuItem from '@material-ui/core/MenuItem';
import {
  AdminDialog,
  AdminDialogTitle,
  AdminDialogContent,
  AdminDialogControls,
  PrimaryTextField,
  PrimaryButton,
  Spinner,
  ImageUpload,
  PrimarySelect,
} from '../../components';
import { apiGetSharedProperties, apiGetTaxRates } from '../../api';
import styles from './styles';
import { SHARED_PROPERTY_TYPES, validateField, weightToSystem, weightToUser } from '../../helpers';
import validationRules from './validation';

const propTypes = {
  withTaxRate: PropTypes.bool,
  withImage: PropTypes.bool,
  withSharedTypes: PropTypes.bool,
  property: PropTypes.object,
  type: PropTypes.string.isRequired,
  typeNames: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const defaultProps = {
  withTaxRate: false,
  withImage: false,
  typeNames: [],
};

class UpsertProductSharedPropertyDialog extends React.Component {
  constructor(props) {
    super(props);
    const { typeNames, property } = this.props;

    this.state = {
      name: property ? property.name : '',
      subUnit: property ? property.subUnit : '',
      subQuantity: property && property.subQuantity ? weightToUser(property.subQuantity) : '',

      nameError: '',
      subUnitError: '',
      subQuantityError: '',
      sharedTypeError: '',

      file: null,
      loading: false,
      taxRateId: property && property.taxRate ? property.taxRate.id : '',
      taxRates: [],
      sharedTypes: [],
      sharedType: (property && property.type && property.type.name) || '',
    };

    let skip = property ? property.name : '';
    this.validationRules = validationRules(typeNames.filter((type) => type !== skip));
  }

  componentDidMount() {
    const { withTaxRate, withSharedTypes } = this.props;

    if (withTaxRate) {
      this.setState({ loading: true });
      apiGetTaxRates(
        (rates) => {
          this.setState({
            taxRates: rates,
            loading: false,
          });
        },
        () => {
          this.setState({ loading: false });
        }
      );
    }

    if (withSharedTypes) {
      this.setState({ loading: true });
      apiGetSharedProperties(
        [],
        SHARED_PROPERTY_TYPES.TYPE,
        (rows) => {
          this.setState({
            sharedTypes: rows,
            loading: false,
          });
        },
        () => {
          this.setState({ loading: false });
        }
      );
    }
  }

  validate = () => {
    this.setState({ loading: true });
    const nameError = validateField(this.validationRules, 'name', this.state.name);
    let subUnitError = false;
    let subQuantityError = false;
    let sharedTypeError = false;

    if (this.props.type === SHARED_PROPERTY_TYPES.UNIT) {
      subUnitError = validateField(this.validationRules, 'subUnit', this.state.subUnit);
      subQuantityError = validateField(this.validationRules, 'subQuantity', this.state.subQuantity);
    }

    if (this.props.type === SHARED_PROPERTY_TYPES.SUB_TYPE) {
      sharedTypeError = validateField(this.validationRules, 'sharedType', this.state.sharedType);
    }

    if (!nameError && !subUnitError && !subQuantityError && !sharedTypeError) {
      this.setState({
        nameError: '',
      });

      let data = {
        name: this.state.name,
      };

      if (this.props.type === SHARED_PROPERTY_TYPES.UNIT) {
        data['subUnit'] = this.state.subUnit;
        data['subQuantity'] = weightToSystem(this.state.subQuantity);
      }

      if (this.props.type === SHARED_PROPERTY_TYPES.SUB_TYPE) {
        data['sharedType'] = this.state.sharedType;
      }

      if (this.state.file) {
        data.image = this.state.file.split(',').pop();
      }

      if (this.state.taxRateId) {
        data.taxRateId = this.state.taxRateId;
      }

      this.props.onSave(this.props.property, data);
    } else {
      this.setState({
        nameError,
        subQuantityError,
        subUnitError,
        sharedTypeError,
        loading: false,
      });
    }
  };

  render() {
    const { onClose, intl, classes, property, withImage, withTaxRate } = this.props;

    const { taxRates, taxRateId, sharedTypes, sharedType } = this.state;

    return (
      <AdminDialog open closeButton onClose={onClose}>
        <AdminDialogTitle
          title={property ? intl.formatMessage({ id: 'global.update' }) : intl.formatMessage({ id: 'global.create' })}
        />
        <AdminDialogContent className={classes.wrapper}>
          {this.state.loading && <Spinner size={50} />}

          <PrimaryTextField
            className={classes.input}
            error={!!this.state.nameError}
            onChange={(event) => this.setState({ name: event.target.value })}
            value={this.state.name}
            id="tab-name-input"
            label={intl.formatMessage({ id: 'global.name' })}
            type="text"
            InputLabelProps={{ shrink: true }}
            margin="normal"
            helperText={this.state.nameError && intl.formatMessage({ id: this.state.nameError })}
            fullWidth
          />

          {this.props.type === SHARED_PROPERTY_TYPES.UNIT && (
            <PrimaryTextField
              className={classes.input}
              error={!!this.state.subUnitError}
              onChange={(event) => this.setState({ subUnit: event.target.value })}
              value={this.state.subUnit}
              id="tab-name-input"
              label={intl.formatMessage({ id: 'global.subUnit' })}
              type="text"
              InputLabelProps={{ shrink: true }}
              margin="normal"
              helperText={this.state.subUnitError && intl.formatMessage({ id: this.state.subUnitError })}
              fullWidth
            />
          )}

          {this.props.type === SHARED_PROPERTY_TYPES.UNIT && (
            <PrimaryTextField
              className={classes.input}
              error={!!this.state.subQuantityError}
              onChange={(event) => this.setState({ subQuantity: event.target.value })}
              value={this.state.subQuantity}
              id="tab-name-input"
              label={intl.formatMessage({ id: 'global.subQuantity' })}
              type="text"
              InputLabelProps={{ shrink: true }}
              margin="normal"
              helperText={this.state.subQuantityError && intl.formatMessage({ id: this.state.subQuantityError })}
              fullWidth
            />
          )}

          {this.props.type === SHARED_PROPERTY_TYPES.SUB_TYPE && (
            <PrimarySelect
              formControlClassName={classes.input}
              label={intl.formatMessage({ id: 'global.sharedType' })}
              value={sharedType}
              onChange={(e) => this.setState({ sharedType: e.target.value })}
              error={!!this.state.sharedTypeError}
              helperText={this.state.subUnitError && intl.formatMessage({ id: this.state.sharedTypeError })}>
              {sharedTypes.map((sharedType) => (
                <MenuItem key={sharedType.name} value={sharedType.name}>
                  {sharedType.name}
                </MenuItem>
              ))}
            </PrimarySelect>
          )}

          {withTaxRate && (
            <PrimarySelect
              formControlClassName={classes.input}
              label={intl.formatMessage({ id: 'global.taxRate' })}
              value={taxRateId}
              onChange={(e) => this.setState({ taxRateId: e.target.value })}>
              {taxRates.map((rate) => (
                <MenuItem key={rate.id} value={rate.id}>
                  {rate.name}
                </MenuItem>
              ))}
            </PrimarySelect>
          )}

          {withImage && (
            <div className={classes.imageWrapper}>
              <div className={classes.image}>
                <ImageUpload
                  object={property}
                  base64={this.state.file}
                  onUploaded={(file) => this.setState({ file: file })}
                />
              </div>
            </div>
          )}

          <AdminDialogControls>
            <PrimaryButton className={classes.button} onClick={() => this.validate()}>
              {intl.formatMessage({ id: 'global.save' })}
            </PrimaryButton>
          </AdminDialogControls>
        </AdminDialogContent>
      </AdminDialog>
    );
  }
}

UpsertProductSharedPropertyDialog.propTypes = propTypes;
UpsertProductSharedPropertyDialog.defaultProps = defaultProps;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(UpsertProductSharedPropertyDialog)));
