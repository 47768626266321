import React from 'react';
import { makeStyles } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';

const useStyles = makeStyles(() => ({
  root: {},
  singleItemWrapper: {
    border: '1px solid #dddddd',
    display: 'flex',
    margin: 15,
    borderRadius: 12,
    flexDirection: 'column',
    overflow: 'hidden',
  },
  initialItemWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  itemTitle: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 14,
    fontWeight: 600,
    userSelect: 'none',
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
  },
  numberOfItems: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 'normal',
    color: 'rgba(0, 0, 0, 0.4)',
    marginLeft: 20,
  },
  tableWrapper: {},
  table: {},
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid rgb(224, 224, 224)',
    paddingLeft: 10,
    paddingRight: 10,
    '&:last-of-type': { borderBottom: 0 },
  },
  column: {
    padding: 10,
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '&>span': {
      flex: 1,
    },
  },
  actionColumn: {
    padding: 10,
    boxSizing: 'border-box',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  toolbarRoot: {
    paddingLeft: 20,
    position: 'sticky',
    top: 50,
    backgroundColor: '#fff',
    zIndex: 99,
    paddingTop: 25,
    paddingRight: 30,
    paddingBottom: 15,
  },
  searchFieldWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const MemberServiceCustomerReportSkeleton = React.memo(
  (props) => {
    const { maxRowsNumber = 50, columns, actions, columnExtensions, defaultColumnWidths, widthHeader } = props;
    const classes = useStyles();
    const rows = Array(maxRowsNumber).fill();

    function getColumnStyle(column) {
      const columnStyles =
        columnExtensions && columnExtensions.find((extension) => extension.columnName === column.name);
      const columnDefaultWidth =
        defaultColumnWidths && defaultColumnWidths.find((extension) => extension.columnName === column.name);
      const columnDefaultStyle = Number.isInteger(columnDefaultWidth?.width)
        ? { width: columnDefaultWidth.width }
        : null;
      return columnStyles || columnDefaultStyle || {};
    }

    return (
      <div className={classes.root}>
        {widthHeader && (
          <div className={classes.toolbarRoot}>
            <div className={classes.searchFieldWrapper}>
              <Skeleton count={1} height={30} width={100 + Math.random() * 50 + 'px'} />
              <Skeleton count={1} height={35} width={260} />
            </div>
          </div>
        )}
        <div className={classes.tableWrapper}>
          <div className={classes.table}>
            {rows.map((row, rowIndex) => (
              <div className={classes.row} key={`sk_r_${rowIndex}`}>
                {columns &&
                  columns.map((column, columnIndex) => (
                    <div
                      className={classes.column}
                      key={`sk_r_${rowIndex}_c_${columnIndex}`}
                      style={{ ...getColumnStyle(column) }}>
                      <Skeleton count={1} height={15} width={'100%'} />
                    </div>
                  ))}
                {actions &&
                  actions.map((actionColumn, actionColumnIndex) => (
                    <div className={classes.actionColumn} key={`sk_r_${rowIndex}_a_${actionColumnIndex}`}>
                      <Skeleton count={1} height={42} width={42} circle style={{ marginLeft: 20 }} />
                    </div>
                  ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  },
  () => {
    // Do not change a skeleton on change any props, only on mount or unmount component
    return true;
  }
);

export default MemberServiceCustomerReportSkeleton;
