import { saveObjectToStorage, getObjectFromStorage, removeProducerFromLocalStorage } from '../helpers';

export const genHttpBasicToken = (email, password) => {
  return window.btoa(unescape(encodeURIComponent(email + ':' + password)));
};

/**
 * Checks if token is expired
 * @param {string} jwt - jwt token
 * @returns {boolean}
 */
export function isJWTExpired(jwt) {
  const decoded = jwtDecode(jwt);
  const date = new Date();
  return decoded.exp < date.getTime() / 1000;
}

/** Get producer token */
export function getToken() {
  return localStorage.getItem('producer.token');
}

/** Get producer data */
export function getProducerData() {
  return getObjectFromStorage('producer.data');
}

/**
 * Set user token
 * @param token - user token
 */
export function setToken(token) {
  return localStorage.setItem('producer.token', token);
}

/** Removes user token */
export function removeToken() {
  return localStorage.removeItem('producer.token');
}

/**
 * Set acl object
 * @param {object} acl - acl data
 */
export function setAcl(acl) {
  return acl && saveObjectToStorage('producer.acl', acl);
}

/**
 * Get acl object
 * @return {object}
 */
export function getAcl() {
  return getObjectFromStorage('producer.acl');
}

/**
 * Remove acl object
 * @param {string} acl
 */
export function removeAcl(acl) {
  return localStorage.removeItem('producer.acl');
}

/** Removes user data */
export function removeProducerData() {
  return localStorage.removeItem('producer.data');
}

/** Removes user data */
export function removeProducerDataWithToken() {
  removeAcl();
  removeToken();
  removeProducerData();
  removeProducerFromLocalStorage();
}

/**
 * Save producer data
 * @param {object} producer - producer data
 */
export function saveProducerData(producer) {
  return saveObjectToStorage('producer.data', producer);
}

/**
 * Decodes token
 * @param {string} jwt
 * @returns {object} - decoded jwt
 */
export function jwtDecode(jwt) {
  try {
    const base64Url = jwt.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(atob(base64));
  } catch (e) {
    return 0;
  }
}

/** Get supplier token */
export function getSupplierToken() {
  return localStorage.getItem('supplier.token');
}
/**
 * Set supplier token
 * @param token - user token
 */
export function setSupplierToken(token) {
  return localStorage.setItem('supplier.token', token);
}
/** Removes supplier token */
export function removeSupplierToken() {
  return localStorage.removeItem('supplier.token');
}

/** Get supplier object */
export function getSupplierObject() {
  try {
    return JSON.parse(localStorage.getItem('supplier.object'));
  } catch (e) {
    return null;
  }
}
/**
 * Set supplier object
 * @param supplier - user token
 */
export function setSupplierObject(supplier) {
  try {
    return localStorage.setItem('supplier.object', JSON.stringify(supplier));
  } catch (e) {
    return null;
  }
}
/** Removes supplier object */
export function removeSupplierObject() {
  return localStorage.removeItem('supplier.object');
}

/**
 * Set supplier object
 * @param supplier - user token
 */
export function setSupplierParentObject(supplier) {
  try {
    return localStorage.setItem('supplier.parent.object', JSON.stringify(supplier));
  } catch (e) {
    return null;
  }
}
/** Get supplier object */
export function getSupplierParentObject() {
  try {
    return JSON.parse(localStorage.getItem('supplier.parent.object'));
  } catch (e) {
    return null;
  }
}
