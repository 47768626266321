import React from "react";
import PropTypes from 'prop-types';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import {withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import styles from './styles';

const ErrorBox = (props) => {
  const {
    error,
    classes,
    className,
    onClose,
  } = props;

  return error ?
    <SnackbarContent
      className={`${classes.error} ${className}`}
      message={error}

      {...(onClose ? {
        action: [
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        ]
      } : {})}

    /> : null;
};

ErrorBox.propTypes = {
  error: PropTypes.any
};

export default withStyles(styles)(ErrorBox);