import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import CellAsTextInput from "./CellAsTextInput";

const styles = ({});

const propTypes = {
  value: PropTypes.string,
  cellProps: PropTypes.object.isRequired,
  onFinishCellEditing: PropTypes.func.isRequired,
};

const CellAsPriceInput = (props) => {
  return (
    <CellAsTextInput {...props}
                     formatValueBeforeApplyEditing={value => parseFloat(value).toFixed(2) || parseFloat(0).toFixed(2)}
                     inputProps={{
                       InputProps : {
                         type: 'number',
                         step: .01,
                         min: 0
                       }
                     }}
    />
  )
};

CellAsPriceInput.propTypes = propTypes;

export default withStyles(styles)(
  injectIntl(CellAsPriceInput)
)
