import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  MuiThemeProvider,
  createTheme
} from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';
import styles from "./styles";
import {COLORS} from '../../helpers';

const propTypes = {
  desc: PropTypes.any
};

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiButton: {
      root: {
        fontFamily: "'Roboto', sans-serif",
        fontWeight: 400,
        textTransform: 'uppercase',
        fontSize: 12,
        background: '#247356',
        borderRadius: 6,
        border: 0,
        color: 'white',
        height: 37,
        padding: '0 37px !important',
        boxShadow: '5px 5px 6px #00000029',
        minWidth: 200,
        '&:hover, &:active, &:focus': {
          backgroundColor: '#278663 !important'
        },
        '&$disabled': {
          opacity: '0.6',
          backgroundColor: '#247356 !important',
          color: COLORS.white
        },
      },
      label: {
        fontWeight: '400',
      }
    },
  },
});

class GreenButton extends React.Component {
  render = () => {
    const {classes, desc, className, ...other} = this.props;
    let wrapperClasses = {};
    wrapperClasses[classes.descWrapper] = !!desc;
    if (className) {
        wrapperClasses[className] = true;
    }

    return (
      <MuiThemeProvider theme={theme}>
        <Button className={classNames(wrapperClasses)} {...other}>
          {this.props.children}
          {desc && (
            <span className={classes.desc}>
              {desc}
            </span>
          )}
        </Button>
      </MuiThemeProvider>
    );
  }
}

GreenButton.propTypes = propTypes;

export default withStyles(styles)(GreenButton);
