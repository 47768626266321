import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AdminDialog from '../../components/AdminDialog/AdminDialog';
import AdminDialogContent from '../../components/AdminDialog/AdminDialogContent';
import { useIntl } from 'react-intl';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { QRCodeSVG } from 'qrcode.react';
import Typography from '@material-ui/core/Typography';
import { COLORS } from '../../helpers';
import { getCustomerName } from '../../helpers/customer';
import CopyToClipboard from '../../components/CopyToClipboard';
import { CopyIcon } from '../../components/CustomIcons/icons/CopyIcon';
import { apiCustomerQrCodeGenerate } from '../../api/customer/customer';
import MinimalError from '../../scenes/SupplierScene/entities/minimalError';
import ErrorBox from '../../components/ErrorBox/ErrorBox';

const useStyles = makeStyles(() => ({
  paper: {
    padding: '30px 25px',
    borderRadius: 10,
    height: 'calc(100% - 16px)',
    width: 400,
    maxHeight: 550,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },

  qrWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  customerInfoWrapper: {
    marginTop: 20,
    textAlign: 'center',
  },
  customerInfo: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 700,
    fontSize: 18,
    color: COLORS.black,
  },

  rulesListWrapper: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  rulesList: {
    padding: 0,
    margin: 0,
    paddingLeft: 25,
    listStyle: 'none',
  },
  rulesListItem: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 400,
    fontSize: 14,
    marginBottom: 20,
    '&:last-of-type': {
      marginBottom: 0,
    },
    '&::marker': {
      content: 'counter(list-item) "\xa0\xa0\xa0"',
      fontFamily: "'Roboto', sans-serif",
      fontWeight: 700,
      fontSize: 14,
      color: COLORS.black,
      backgroundColor: 'red',
    },
  },
  copyWrapper: {
    marginTop: 20,
  },
  copyButtonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 0,
    paddingLeft: 10,
  },
  copyButtonText: {
    whiteSpace: 'nowrap',
    color: 'rgba(0, 0, 0, 0.5)',
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "'Roboto', sans-serif",
  },
  copyButtonIcon: { marginLeft: 10 },

  copyButtonRoot: {
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    border: '1px solid rgba(0, 0, 0, 0.15)',
    borderRadius: 8,
    padding: '10px 15px',
  },
  text: {},
  copiedSuccessWrapper: {
    width: 130,
    height: 42,
    textAlign: 'center',
  },
  spinnerWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinner: {},
}));

const propTypes = {
  qrDialog: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

const CustomerQrCodeDialog = (props) => {
  const {
    qrDialog: { open, customer },
    onClose,
    ...others
  } = props;
  const intl = useIntl();
  const classes = useStyles();
  const [link, setLink] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(new MinimalError());

  useEffect(() => {
    if (open) {
      setError(new MinimalError());
      apiCustomerQrCodeGenerate(
        { customerId: customer?.id },
        ({ code }) => {
          setLink(`${process.env.REACT_APP_STORE_URL}qr/${code}`);
          setLoading(false);
        },
        handleAnyError
      );
    }

    if (!open) {
      setLoading(true);
    }
  }, [open, customer?.id]);

  const handleAnyError = (errorObj) => {
    setError(new MinimalError(errorObj));
    setLoading(false);
  };

  const drawCopyButton = () => {
    return (
      <div className={classes.copyButtonWrapper}>
        <p className={classes.copyButtonText}>{intl.formatMessage({ id: 'customer.qrCode.tapToCopy' })}</p>
        <CopyIcon size={16} style={{ color: COLORS.text, fontSize: 18 }} className={classes.copyButtonIcon} />
      </div>
    );
  };

  const drawContent = () => {
    if (loading || error.hasError()) {
      return;
    }

    return (
      <>
        <div className={classes.qrWrapper}>
          <QRCodeSVG value={link} width={150} height={150} />
        </div>
        <div className={classes.customerInfoWrapper}>
          <Typography classes={{ root: classes.customerInfo }} color="textSecondary">
            {customer?.email}
          </Typography>
          <Typography classes={{ root: classes.customerInfo }} color="textSecondary">
            {getCustomerName(customer)}
          </Typography>
        </div>

        <div className={classes.rulesListWrapper}>
          <ul className={classes.rulesList}>
            <li className={classes.rulesListItem}>{intl.formatMessage({ id: 'customer.qrCode.rule1' })}</li>
            <li className={classes.rulesListItem}>{intl.formatMessage({ id: 'customer.qrCode.rule2' })}</li>
          </ul>
        </div>

        <div className={classes.copyWrapper}>
          <CopyToClipboard
            text={link}
            copyContent={link}
            EndAdornmentComponent={drawCopyButton}
            classes={{
              button: classes.copyButtonRoot,
              text: classes.text,
              copiedSuccessWrapper: classes.copiedSuccessWrapper,
            }}
          />
        </div>
      </>
    );
  };

  const drawSpinner = () => {
    if (!loading) {
      return;
    }

    return (
      <div className={classes.spinnerWrapper}>
        <CircularProgress className={classes.spinner} size={60} />
      </div>
    );
  };

  return (
    <AdminDialog open={open} paperClass={classes.paper} onClose={onClose} {...others}>
      <AdminDialogContent className={classes.wrapper}>
        <ErrorBox error={error.getMessage()} />

        {drawSpinner()}
        {drawContent()}
      </AdminDialogContent>
    </AdminDialog>
  );
};

CustomerQrCodeDialog.propTypes = propTypes;

export default CustomerQrCodeDialog;
