import React from 'react';
import { Routes, Route } from 'react-router-dom';
// Add skeleton styles, it may be included to styles.css or src/index.js if we will use it everywhere
import 'react-loading-skeleton/dist/skeleton.css';
import { RoutesWrapper as PickupRoutesWrapper } from './pickups/RoutesWrapper';
import { RoutesWrapper as DeliveriesRoutesWrapper } from './deliveries/RoutesWrapper';

function AppsWrapper() {
  return (
    <Routes>
      <Route path="/pickups/*" element={<PickupRoutesWrapper />} />
      <Route path="/deliveries/*" element={<DeliveriesRoutesWrapper />} />
    </Routes>
  );
}

export default AppsWrapper;
