import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import { withStyles } from '@material-ui/core/styles';
import { toCurrency, getLineItemQuantity, weightToUser, weightToSystem } from '../../helpers';
import OrderInfoTableCell from './OrderInfoTableCell';
import styles from './styles';
import { PrimaryButton, PrimaryCheckbox, PrimaryTextField, QuantitySwitcher, Spinner } from '../../components';
import { apiRefundOrder } from '../../api';

const propTypes = {
  order: PropTypes.object.isRequired,
  refundApi: PropTypes.func,
};

const RefundOrderInfo = ({ classes, order, intl, onRefundSuccess, refundApi }) => {
  const [state, setState] = useState({
    refundAll: false,
    refundAmount: '',
    loading: false,
    order: order,
    getLineItemSystemQuantity: {},
    readyToSend: false,
  });

  const onChangeQuantity = (lineItem, qt) => {
    setState((prevState) => ({
      ...prevState,
      order: {
        ...prevState.order,
        lineItems: prevState.order.lineItems.map((item) => {
          if (item.id === lineItem) {
            return { ...item, refundQuantity: qt * 100 };
          }
          return item;
        }),
      },
      readyToSend: true,
    }));
  };

  const _submit = () => {
    const { order, refundAmount, refundAll } = state;

    let data;

    setState({ loading: true });

    if (refundAll) {
      data = null;
    } else if (state.refundAmount) {
      data = { refundAmount: weightToSystem(refundAmount) };
    } else {
      data = {
        lineItems: order.lineItems.map((item) => ({
          id: item.id,
          refundQuantity: item.refundQuantity,
        })),
      };
    }

    const api = !!refundApi ? refundApi : apiRefundOrder;

    api(
      order.id,
      data,
      (response) => {
        onRefundSuccess(response);
        setState({ loading: false });
      },
      (error) => {
        setState({ loading: false });
        console.log('Error', error.message);
      }
    );
  };

  const toggleRefundAll = () => {
    setState((prevState) => ({
      ...prevState,
      refundAll: !prevState.refundAll,
      readyToSend: true,
    }));
  };

  const onChangeAmount = (refundAmount) => {
    setState((prevState) => ({
      ...prevState,
      refundAmount: refundAmount >= 0 ? refundAmount : 0,
      readyToSend: true,
    }));
  };

  return (
    <React.Fragment>
      {order.lineItems.length && (
        <Table className={classes.table}>
          <TableHead>
            <TableRow style={{ height: 15 }}>
              <OrderInfoTableCell>{intl.formatMessage({ id: 'global.item' })}</OrderInfoTableCell>
              <OrderInfoTableCell>{intl.formatMessage({ id: 'global.unitPrice' })}</OrderInfoTableCell>
              <OrderInfoTableCell>{intl.formatMessage({ id: 'global.qty' })}</OrderInfoTableCell>
              <OrderInfoTableCell>{intl.formatMessage({ id: 'global.refundQty' })}</OrderInfoTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order.lineItems.map((item) => (
              <TableRow className={classes.row} key={item.id}>
                <OrderInfoTableCell>
                  <Typography className={classes.productName} component="p">
                    {item.name.product}
                  </Typography>
                  <Typography className={classes.variantName} component="p">
                    <span className={classes.subType}>{item.productSubType}</span>
                  </Typography>
                </OrderInfoTableCell>
                <OrderInfoTableCell>
                  {toCurrency(item.unitPrice, intl)} / {weightToUser(item.unitQuantity)} {item.unit}
                </OrderInfoTableCell>
                <OrderInfoTableCell>
                  {item.hasOwnProperty('available') && item.available ? (
                    getLineItemQuantity(item)
                  ) : (
                    <FormattedMessage id="global.notAvailable" />
                  )}
                </OrderInfoTableCell>
                <OrderInfoTableCell style={{ width: 100 }}>
                  <QuantitySwitcher
                    className={classes.quantitySwitcher}
                    onChange={(newQuantity) => {
                      onChangeQuantity(item.id, newQuantity);
                    }}
                    quantity={0}
                  />
                </OrderInfoTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      {state.loading && <Spinner size={40} />}

      {!!order.note && (
        <Typography variant="body2" align={'center'} gutterBottom>
          <span className={classes.noteTitle}>
            <FormattedMessage id="order.note" />
          </span>
          : {order.note}
        </Typography>
      )}

      {!order.lineItems.length && (
        <Typography variant="subtitle1" align={'center'} gutterBottom>
          <FormattedMessage id="order.noLineItems" />
        </Typography>
      )}

      <div className={classes.activeElementsWrapper}>
        <FormControlLabel
          className={classes.refundAll}
          control={<PrimaryCheckbox checked={state.refundAll} onChange={toggleRefundAll} color="default" />}
          label={intl.formatMessage({ id: 'order.refundAll' })}
        />

        {!state.refundAll && (
          <PrimaryTextField
            className={classes.input}
            onChange={(event) => {
              onChangeAmount(event.target.value);
            }}
            value={state.refundAmount}
            id="refund-amount-input"
            label={intl.formatMessage({ id: 'global.refundAmount' })}
            type="number"
            InputLabelProps={{ shrink: true }}
          />
        )}

        <PrimaryButton className={classes.button} onClick={_submit} disabled={!state.readyToSend}>
          {intl.formatMessage({ id: 'global.refund' })}
        </PrimaryButton>
      </div>
    </React.Fragment>
  );
};

RefundOrderInfo.propTypes = propTypes;

export default withStyles(styles, { withTheme: true })(injectIntl(RefundOrderInfo));
