import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { makeStyles } from '@material-ui/core';
import { PADDING_CONTENT, PADDING_CONTENT_TOP } from '../../constants/globals';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
  content: {
    paddingLeft: PADDING_CONTENT,
    paddingRight: PADDING_CONTENT,
    paddingTop: PADDING_CONTENT_TOP,
  },
  title: {
    marginBottom: 10,
  },
  subTitle: {
    marginBottom: 20,
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 5,
  },
  rowItem: {
    marginBottom: 20,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 5,
    paddingRight: 5,
    marginLeft: -5,
    marginRight: -5,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flex: 1,
  },
  tree: {
    marginBottom: 5,
    paddingTop: 5,
    paddingBottom: 5,
  },
  rowItem2: {
    paddingLeft: 25,
  },
  rowItem3: {
    paddingLeft: 45,
  },
}));

const LocationsSkeleton = ({ numberOfItems, isLiteView }) => {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <div className={classes.textWrapper}>
        <Skeleton className={classes.title} height={30} count={1} width={150} />
        <Skeleton className={classes.subTitle} height={20} count={1} width={200} />
      </div>

      {isLiteView
        ? Array(numberOfItems)
            .fill()
            .map((item, index) => (
              <div key={`ca_${index}`} className={classes.rowItem}>
                <Skeleton count={1} height={25} width={100 + Math.random() * 200 + 'px'} />
                <Skeleton count={1} width={15} circle />
              </div>
            ))
        : Array(numberOfItems)
            .fill()
            .map((item, index) => (
              <React.Fragment key={`ca_${index}`}>
                <div className={classNames([classes.rowItem, classes.tree])}>
                  <div className={classes.textWrapper}>
                    <Skeleton count={1} height={25} width={10 + Math.random() * 40 + '%'} />
                  </div>
                </div>
                <div className={classNames([classes.rowItem, classes.tree, classes.rowItem2])}>
                  <div className={classes.textWrapper}>
                    <Skeleton count={1} height={25} width={10 + Math.random() * 50 + '%'} />
                  </div>
                </div>
                <div className={classNames([classes.rowItem, classes.tree, classes.rowItem3])}>
                  <div className={classes.textWrapper}>
                    <Skeleton count={1} height={25} width={10 + Math.random() * 50 + '%'} />
                  </div>
                  <Skeleton count={1} height={20} width={20} style={{ marginTop: 10 }} />
                </div>
              </React.Fragment>
            ))}
    </div>
  );
};

LocationsSkeleton.propTypes = {
  id: PropTypes.string,
  numberOfItems: PropTypes.number,
};

LocationsSkeleton.defaultProps = {
  id: 'default',
  numberOfItems: 5,
};

export default LocationsSkeleton;
