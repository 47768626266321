import { COLORS } from '../../helpers';

export default () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 18,
    paddingLeft: 30,
    paddingRight: 30,
  },
  left: {
    padding: '22px 45px',
    marginRight: 50,
    flex: 3,
  },
  rightColumn: {
    flex: 1.2,
  },
  rightBox: {
    borderTop: '4px solid ' + COLORS.text,
    marginBottom: 36,
    padding: '12px 20px',
  },
  leftBoxLine: {
    marginBottom: 21,
    textAlign: 'center',
  },
  companyName: {
    fontSize: 14,
    marinLeft: 40,
    fontWeight: 600,
    marginBottom: 20,
  },
  mr12: {
    marginRight: 12,
  },
  summaryContainerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px 0 20px 0',
  },
  summaryContainer: {
    flex: 2,
  },
  rightHeader: {
    fontSize: 20,
    fontWeight: 600,
    fontFamily: 'Roboto',
    textAlign: 'center',
    marginBottom: 10,
  },
  summaryContainerGap: {
    flex: 5,
  },
  separator: {
    width: '100%',
    margin: '20px 0',
    height: '1px',
    border: 0,
    borderTop: '1px solid ' + COLORS.border,
  },
  rightBoxText: {
    fontSize: 14,
    fontWeight: 400,
    fontFamily: 'Roboto',
    color: COLORS.text,
  },
  bold: {
    fontWeight: 600,
  },
  linkButton: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 400,
    fontSize: 12,
    textTransform: 'normal',
    background: 'transparent',
    borderRadius: 0,
    color: '#363636',
    height: 18,
    padding: '0px 5px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  blackButtonWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    marginBottom: 36,
  },
  mt0: {
    marginTop: 0,
  },
  blackButton: {
    textTransform: 'none',
    marginBottom: 20,
    height: 40,
    padding: '12px 15px',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
    fontFamily: "'Roboto', sans-serif",
    fontSize: 12,
    fontWeight: 500,
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 40,
  },
  title: {
    marginBottom: 0,
  },
  backButton: {
    marginLeft: -12,
  },
  backIcon: {
    color: 'rgba(0,0,0,.5)',
  },
  backButtonPlaceholder: {
    width: 50,
  },
});
