const validation = {
  name: {
    presence: {
      message: '^validation.required'
    }
  },
  address: {
    presence: {
      message: '^validation.customer.enterAddress'
    }
  },
  city: {
    presence: {
      message: '^validation.customer.enterCity'
    }
  },
  state: {
    presence: {
      message: '^validation.customer.enterState'
    }
  },
  zipCode: {
    presence: {
      message: '^validation.customer.enterZipCode'
    }
  },
  validateTime: {
    presence: {
      message: '^validation.timeStartCantBeGraterThenEnd'
    }
  },
  weekDays: {
    presence: {
      message: '^validation.dateSelectAtLeastOne'
    }
  },

};

export default validation;
