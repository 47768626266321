import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Tab, TabActions } from '../../components';
import Factory from './Factory';
import { countWidthOfColumns, getTabSavedState, makeColumnWidthEntityArray } from '../../helpers';

const propTypes = {
  columns: PropTypes.array.isRequired,
  defaultHiddenColumnNames: PropTypes.array,
  tab: PropTypes.object.isRequired,
  withFilters: PropTypes.bool,
  allowCreateTab: PropTypes.bool,
  contentTag: PropTypes.string.isRequired,
  onCreateEntity: PropTypes.func,
  labels: PropTypes.object,
  onExportTab: PropTypes.func,
  onDeleteTab: PropTypes.func,
  onCreateTab: PropTypes.func,
  onAddingFilter: PropTypes.func,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onRowClick: PropTypes.func,
};

const defaultProps = {
  withFilters: false,
  defaultHiddenColumnNames: [],
  onRowClick: undefined,
};

class TabWrapper extends React.Component {
  constructor(props) {
    super(props);
    const { tab } = this.props;

    const enableSelection = true;
    const columnWidthEntityArray = makeColumnWidthEntityArray(tab.columnsWidth, props.columns);

    const currentTab = getTabSavedState(tab.id);

    this.state = {
      filterDialog: false,
      tabDialog: false,
      addCustomerDialog: false,
      isDefaultView: !currentTab?.excludeColumns && !tab.excludeColumns,
      addCustomerDialogRow: {},
      enableSelection,
      settings: {
        searchValue: currentTab?.searchValue || tab?.searchValue || '',
        excludeColumns:
          currentTab?.excludeColumns ||
          (!tab.excludeColumns ? props.defaultHiddenColumnNames : tab.excludeColumns || []),
        filters: currentTab?.filters || tab.filters || [],
        sorting: currentTab?.sorting || tab.sorting || [],
        columnsWidth:
          currentTab?.columnWidths || columnWidthEntityArray || this.updateInitialColumnWidths(tab.excludeColumns),
      },
    };
  }

  componentDidUpdate(prevProps) {
    const { tab } = this.props;
    if (tab && prevProps.tab.id !== this.props.tab.id) {
      this.setState((state) => {
        state.settings.excludeColumns = tab.excludeColumns || [];
        state.settings.columnsWidth = this.updateInitialColumnWidths(tab.excludeColumns || []);
        return state;
      });
    }
  }

  onColumnWidthsChange = (columnWidths) => {
    this.setState((state) => {
      state.settings['columnsWidth'] = columnWidths;
      return state;
    });
  };

  updateInitialColumnWidths = (hiddenColumnNames) => {
    return countWidthOfColumns(this.props.columns, hiddenColumnNames, []);
  };

  onAddFilter = () => {
    this.setState({ filterDialog: true });
  };

  onCreateTab = () => {
    this.setState({ tabDialog: true });
  };

  onUpdateTab = () => {
    const { tab, onPatchTab } = this.props;

    if (tab.id) {
      onPatchTab(tab, this.state.settings);
    }
  };

  onChangeColumnsState = (excludeColumns) => {
    this.setState((state) => {
      state.settings['excludeColumns'] = excludeColumns;

      state.isDefaultView = false;

      // state.settings['columnsWidth'] = this.updateInitialColumnWidths(excludeColumns);
      return state;
    });
  };

  setSettings = (tab, settings) => {
    this.setState({ settings });
  };

  onExportTab = () => {
    const { onExportTab } = this.props;

    onExportTab && onExportTab(this.wrapper.table, this.state.settings.excludeColumns);
  };

  render() {
    const {
      tab,
      index,
      contentTag,
      columns,
      classes,
      labels,
      onCreateEntity,
      onDeleteTab,
      onCreateTab,
      onExportTab,
      allowCreateTab,
      withFilters,
      sortingColumnExtensions,
      onRowClick,
    } = this.props;

    return (
      <Tab
        title={tab.name}
        key={`${index}-tab`}
        tab={tab}
        actions={
          <TabActions
            tab={tab}
            labels={labels}
            classes={classes}
            onCreateEntity={onCreateEntity}
            allowCreateTab={allowCreateTab}
            onExportTab={onExportTab && this.onExportTab}
            onCreateTab={this.onCreateTab}
            onUpdateTab={this.onUpdateTab}
            onAddingFilter={withFilters ? this.onAddFilter : undefined}
            onChangeColumnsState={this.onChangeColumnsState}
            onDeleteTab={onDeleteTab}
            columns={columns}
          />
        }>
        <Factory
          enableSelection={this.state.enableSelection}
          tag={contentTag}
          tab={tab}
          onRef={(wrapper) => (this.wrapper = wrapper)}
          key={`${index}-content`}
          showTabDialog={this.state.tabDialog}
          openFilterDialog={() => this.setState({ filterDialog: true })}
          isFiltersDialogActive={this.state.filterDialog}
          onCreateTab={onCreateTab}
          onPatchTab={this.setSettings}
          onCloseFilterDialog={() => this.setState({ filterDialog: false })}
          onCloseTabDialog={() => this.setState({ tabDialog: false })}
          isShowAddCustomerDialog={this.state.addCustomerDialog}
          addCustomerDialogRow={this.state.addCustomerDialogRow}
          openAddCustomerDialog={(row) => {
            this.setState({ addCustomerDialog: true, addCustomerDialogRow: row });
          }}
          closeAddCustomerDialog={() => this.setState({ addCustomerDialog: false })}
          columns={columns}
          sortingColumnExtensions={sortingColumnExtensions}
          onColumnWidthsChange={this.onColumnWidthsChange}
          onRowClick={onRowClick}
          columnWidths={this.state.settings.columnsWidth}
          hiddenColumnNames={this.state.settings.excludeColumns || []}
          searchValue={this.state.settings.searchValue}
          appliedFilters={this.state.settings.filters}
          sorting={this.state.settings.sorting}
        />
      </Tab>
    );
  }
}

TabWrapper.propTypes = propTypes;
TabWrapper.defaultProps = defaultProps;

export default injectIntl(TabWrapper);
