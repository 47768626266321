import { getRequestConfig, replaceUrlPlaceholder, REQUEST_TYPES } from '../request';
import API_END_POINTS from '../endpoints';
import axios from 'axios';

export const apiGetDeliveriesDays = function (params, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.GET_DELIVERIES_DAYS);
  config.params = params;

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

export const apiGetShippingItems = function (params, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.GET_SHIPPINGS);
  config.params = params;

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

export const apiGetDeliveryOrderById = function (orderId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.GET_DELIVERY_ORDER, 'id', orderId);
  const config = getRequestConfig(REQUEST_TYPES.GET, url);

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};
