import {
  CUSTOMER_TYPE_FILTER,
  CUSTOMER_TYPE_FILTER_FROM_VARIABLE
} from '../FilterTypes'

export default class DXCSCustomerTypeFilter {

  static getConfig(intl, values, readOnly, fromVariable) {
    return {
      name: CUSTOMER_TYPE_FILTER,
      title: intl.formatMessage({id: 'global.type'}),
      property: "type",
      type: fromVariable ? CUSTOMER_TYPE_FILTER_FROM_VARIABLE : CUSTOMER_TYPE_FILTER,
      selectedValues: values,
      clientSide: true,
      readOnly: readOnly ? readOnly : false,
      fromVariable: fromVariable,
    }
  }

  constructor(values, propertyName, fromVariable) {
    this.values = values;
    this.property = propertyName;
    this.fromVariable = fromVariable;
  }

  isSatisfy(row) {
  
    let values = [];
    this.values.forEach(value => {
      if (value === 'retail')
        values.push('retail');
      if (value === 'wholesale')
        values.push('wholesale');
    });

    return (
      values.length > 0 && (
      !!this.fromVariable
        ? row[this.fromVariable] ? values.indexOf(row[this.fromVariable][this.property]) !== -1 : false
        : values.indexOf(row[this.property]) !== -1)
    );
  }

  getLabel(intl) {
    let values = [];
    this.values.forEach(value => {
      switch (value) {
        case 'retail' : {
          values.push(intl.formatMessage({id: 'filter.types.retail'}));
          break;
        }
        case 'wholesale' : {
          values.push(intl.formatMessage({id: 'filter.types.wholesale'}));
          break
        }
        default: {}
      }
    });
    return values.join(', ');
  }

}
