import React from 'react';
import * as PropTypes from 'prop-types';
import { PrimaryTextField } from '../../components';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { COLORS } from '../../helpers';
import { makeStyles } from '@material-ui/core';

const propTypes = {
  onValueChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  getMessage: PropTypes.func.isRequired,
};

const defaultProps = {
  value: '',
};

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 200,
  },
}));

const SearchField = ({ onValueChange, getMessage, value, ...rest }) => {
  const classes = useStyles();
  return (
    <PrimaryTextField
      onChange={(e) => {
        onValueChange(e.target.value);
      }}
      value={value}
      placeholder={getMessage('searchPlaceholder')}
      InputProps={{
        className: classes.root,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon style={{ color: COLORS.text }} />
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};

SearchField.propTypes = propTypes;
SearchField.defaultProps = defaultProps;

export default SearchField;
