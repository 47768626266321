import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {COLORS} from "../../helpers";

const styles = {
  wrapper: {
    position: 'relative',
    minHeight: 'calc(100% - 30px)',
    display: 'flex',
    flexDirection: 'row'
  },
  left: {
    flex: 2,
    position: 'relative',
    borderRight: "1px solid " + COLORS.border,
    padding: '12px 22px'
  },
  right: {
    flex: 1
  }
};

const propTypes = {
  leftContent: PropTypes.object,
  rightContent: PropTypes.object,
};

const defaultProps = {
  leftContent: null,
  rightContent: null,
};

const TwoColumnContent = (props) => {
  const {
    classes,
    leftContent,
    rightContent
  } = props;
  return (
    <div className={classes.wrapper}>
      <div className={classes.left}>{leftContent}</div>
      <div className={classes.right}>{rightContent}</div>
    </div>
  );
};

TwoColumnContent.propTypes = propTypes;
TwoColumnContent.defaultProps = defaultProps;

export default withStyles(styles)(
  TwoColumnContent
);