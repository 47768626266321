import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import EnvelopeIcon from '@material-ui/icons/Drafts';
import {
  ErrorBox,
  MessageBox,
  Spinner,
  PrimaryButton,
  PrimaryTextField,
  Icon,
  ErrorFormattedMessage,
  SimpleDialogContent,
} from '../../../components';
import { validateField } from '../../../helpers';
import validationRules from './validation';
//import {apiCustomerResetPasswordRequest} from '../../../api';
import styles from './styles';
import { onEnterPress } from '../../../helpers/events';

class RestorePasswordStage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emailAfterSent: '',
      requestSent: false,
      isLoading: false,
      emailOrUsername: '',
      emailOrUsernameError: '',
      requestError: '',
    };

    this.validate = this.validate.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    this.props.onClose();
  }

  validate() {
    if (this.state.isLoading) return false;

    const emailOrUsernameError = validateField(validationRules, 'emailOrUsername', this.state.emailOrUsername);

    if (!emailOrUsernameError) {
      this.setState({
        emailOrUsernameError: '',
        requestError: '',
        isLoading: true,
      });

      /*const onError = () => {
        this.setState({
          requestError: "error.usernameOrEmailIncorrect",
          isLoading: false
        });
      };

      const onSuccess = (response) => {
        this.setState({
          requestError: "",
          emailAfterSent: response.data.email,
          isLoading: false,
          requestSent: true
        });
      };*/

      /*apiCustomerResetPasswordRequest(
        this.state.emailOrUsername, onSuccess, onError
      );*/
    } else {
      this.setState({
        emailOrUsernameError: emailOrUsernameError,
        isLoading: false,
      });
    }
  }

  getForm() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        {this.state.isLoading ? <Spinner className={classes.spinner} size={50} /> : null}

        <MessageBox message={this.props.message} />
        <ErrorBox error={this.state.requestError ? <FormattedMessage id={this.state.requestError} /> : ''} />

        <SimpleDialogContent>
          <div>
            <div className={classes.textWrapper}>
              <Typography gutterBottom align={'center'}>
                <FormattedMessage id="restorePassword.message" />
              </Typography>
            </div>
            <PrimaryTextField
              error={!!this.state.emailOrUsernameError}
              onChange={(event) => this.setState({ emailOrUsername: event.target.value })}
              value={this.state.emailOrUsername}
              id="email-or-username-input"
              margin="dense"
              autoFocus
              label={<FormattedMessage id="global.email" />}
              helperText={<ErrorFormattedMessage id={this.state.emailOrUsernameError} />}
              fullWidth
              onKeyPress={(event) => {
                onEnterPress(event, this.validate);
              }}
            />
            <div className={classes.controls}>
              <PrimaryButton className={classes.button} onClick={this.validate}>
                <FormattedMessage id="restorePassword.buttonTitle" />
              </PrimaryButton>
            </div>
          </div>
        </SimpleDialogContent>
      </React.Fragment>
    );
  }

  getFinalMessage() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <SimpleDialogContent className={classes.finalMessageContainer}>
          <Icon icon={EnvelopeIcon} classes={{ root: classes.envelope }} />
          <p>
            <FormattedMessage id="restorePassword.final" values={{ email: this.state.emailAfterSent }} />
          </p>
          <div className={classes.controlsCentered}>
            <PrimaryButton onClick={this.props.onDone}>
              <FormattedMessage id="global.ok" />
            </PrimaryButton>
          </div>
        </SimpleDialogContent>
      </React.Fragment>
    );
  }

  render() {
    return this.state.requestSent ? this.getFinalMessage() : this.getForm();
  }
}

RestorePasswordStage.propTypes = {
  message: PropTypes.string,
  onDone: PropTypes.func.isRequired,
};

export default withStyles(styles)(RestorePasswordStage);
