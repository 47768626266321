import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import SecondaryButton from '../SecondaryButton/SecondaryButton';

const styles = {
  item: {
    margin: '0 10px 10px 0',
  },
};

const propTypes = {
  selected: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

const InlineSelect = ({ classes, className, itemClassName, selected, onSelect, values, ...other }) => {
  return (
    <div className={classNames(classes.wrapper, className)} {...other}>
      {Object.keys(values).map((key) => {
        const Component = values[key].id === selected ? SecondaryButton : PrimaryButton;

        return (
          <Component
            onClick={() => onSelect(values[key])}
            key={values[key].id}
            className={classNames(classes.item, itemClassName)}>
            {values[key].name}
          </Component>
        );
      })}
    </div>
  );
};

InlineSelect.propTypes = propTypes;

export default withStyles(styles)(InlineSelect);
