import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { injectIntl } from 'react-intl';
import { withRouter } from '../../hocs';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { AdminLayout, Icon } from '../../components';
import { setProducer } from '../../store/actions';
import { HeaderMenu, SidebarMenu } from '../Partials';
import LeftSideTabsWrapper from './components/LeftSideTabsWrapper';
import { Paper } from '@material-ui/core';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import StorefrontIcon from '@material-ui/icons/Storefront';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ProductsContent from './components/ProductsContent';
import ProfileContent from './components/ProfileContent';
import PickupDeliveryContent from './components/PickupDeliveryContent';
import PaymentsContent from './components/PaymentsContent';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { apiAnonymousPreviewProducer } from '../../api';

HomeScene.propTypes = {
  intl: PropTypes.object,
  history: PropTypes.object,
  producer: PropTypes.object,
};

function HomeScene(props) {
  const { intl, classes, history, producer } = props;

  const [loading, setLoading] = useState(true);
  const [tabsContent, setTabContent] = useState([]);

  useEffect(() => {
    makeTabs();
  }, []);

  const makeTabs = () => {
    let tabs = [];
    tabs.push(getProductsContent());
    tabs.push(getProfileContent());
    tabs.push(getDeliveryContent());
    tabs.push(getPaymentsContent());

    setTabContent(tabs);
    setLoading(false);
  };

  const hasProducts = () => {
    return !!Object.values(producer.types).find((elem) => elem > 0);
  };
  const hasProfileData = () => {
    return !!(
      producer.address1 &&
      producer.city &&
      producer.email &&
      producer.name &&
      producer.image &&
      producer.description &&
      producer.zip &&
      producer.logotype
    );
  };
  const hasPickupAndDeliveryData = () => {
    return !!producer.locations.length;
  };
  const hasPaymentMethodsSet = () => {
    return !!producer.stripeAccountId;
  };

  const getProductsContent = () => {
    const isFinished = hasProducts();
    return {
      title: intl.formatMessage({ id: 'screen.homeScreen.addProducts' }),
      icon: LocalOfferIcon,
      isFinished,
      content: <ProductsContent isFinished={isFinished} history={history} intl={intl} />,
    };
  };

  const getProfileContent = () => {
    const isFinished = hasProfileData();
    return {
      title: intl.formatMessage({ id: 'screen.homeScreen.updateProfile' }),
      icon: StorefrontIcon,
      isFinished,
      content: <ProfileContent isFinished={isFinished} history={history} intl={intl} />,
    };
  };

  const getDeliveryContent = () => {
    const isFinished = hasPickupAndDeliveryData();

    return {
      title: intl.formatMessage({ id: 'screen.homeScreen.pickupAndDelivery' }),
      icon: LocalShippingIcon,
      isFinished,
      content: <PickupDeliveryContent isFinished={isFinished} history={history} intl={intl} />,
    };
  };

  const getPaymentsContent = () => {
    const isFinished = hasPaymentMethodsSet();

    return {
      title: intl.formatMessage({ id: 'screen.homeScreen.setUpPayments' }),
      icon: AccountBalanceIcon,
      isFinished,
      content: <PaymentsContent isFinished={isFinished} history={history} intl={intl} />,
    };
  };

  const viewYourStore = () => {
    apiAnonymousPreviewProducer(
      (res) => {
        window.open(res.url);
      },
      (e) => {
        console.log(e);
      }
    );
  };

  if (loading) return null;

  return (
    <AdminLayout sidebarComponent={SidebarMenu} headerMenuComponent={HeaderMenu}>
      <div className={classes.container}>
        <div className={classes.backButtonWrapper}>
          <IconButton onClick={() => history.goBack()} className={classes.iconButton}>
            <Icon icon={ArrowBackIosIcon} className={classes.icon} />
          </IconButton>
        </div>
        <div className={classes.mainContent}>
          <div className={classes.title}>{intl.formatMessage({ id: 'screen.homeScreen.title' })}</div>
          {hasProducts() && hasPickupAndDeliveryData() && (
            <div className={classes.viewStoreWrapper}>
              <div className={classes.viewStoreButton} onClick={viewYourStore}>
                <VisibilityIcon className={classes.viewStoreIcon} />
                {intl.formatMessage({ id: 'screen.homeScreen.viewYouStore' })}
              </div>
            </div>
          )}
          <Paper>
            <LeftSideTabsWrapper
              title={intl.formatMessage({ id: 'screen.homeScreen.stepsToGoLive' })}
              numberOfTubs={4}
              tabsContent={tabsContent}
              ariaLabel={intl.formatMessage({ id: 'screen.homeScreen.title' })}
            />
          </Paper>
        </div>
      </div>
    </AdminLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    producer: state.producer.object,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProducer: (producer) => dispatch(setProducer(producer)),
  };
};

export default injectIntl(withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(HomeScene))));
