import React from "react";
import {PageLayout} from '../../components';
import {NotFoundResolver} from "../../routes";

const NotFound = () => {
  return (
    <PageLayout>
      <NotFoundResolver>
        <h1 style={{textAlign: 'center', display: 'block', width: '100%'}}>
          Page not found
        </h1>
      </NotFoundResolver>
    </PageLayout>
  );
};

export default NotFound;
