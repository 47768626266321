import React from 'react';
import {
  MuiThemeProvider,
  createTheme
} from '@material-ui/core/styles';
import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Radio from "@material-ui/core/Radio";
import {Icon} from '../../components';

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
});

const PrimaryRadio = (props) => {
  const {...other} = props;
  return (
    <MuiThemeProvider theme={theme} >
      <Radio icon={<Icon icon={RadioButtonUnchecked} style={{fontSize: 28}} />}
             checkedIcon={<Icon icon={CheckCircleIcon} style={{fontSize: 28}} />}
             {...other}
      />
    </MuiThemeProvider>
  );
};

export default PrimaryRadio;
