import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Spinner from '../Spinner';
import AddImage from '../../assets/addImage';
import { COLORS } from '../../constants/colors';
import { BORDER_RADIUS_BUTTON } from '../../constants/globals';

const styles = {
  image: {
    width: 'auto',
    height: '100%',
  },
  imageBoxWrapper: {
    maxWidth: 160,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  imageBox: {
    width: '100%',
    height: 160,
    maxHeight: 160,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    borderRadius: BORDER_RADIUS_BUTTON,
    overflow: 'hidden',
    backgroundColor: COLORS.backgroundLight,
  },
  text: {
    fontSize: '14px',
    color: '#707070',
    fontFamily: 'Roboto',
  },
  icon: {
    fontSize: 18,
    marginRight: 10,
    '& path': {
      fill: '#707070',
    },
  },
  labelStyles: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    marginTop: 15,
  },
  insideLabelStyles: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    width: '100%',
    minHeight: 200,
    cursor: 'pointer',
  },
};

const propTypes = {
  onUploaded: PropTypes.func.isRequired,
  base64: PropTypes.string,
  object: PropTypes.object,
};

class ImageUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: props.base64 ? props.base64 : null,
      loading: false,
      uploaded: false,
    };
  }

  getImage = () => {
    const { object } = this.props;
    const { file } = this.state;

    if (file) {
      return `data:image/png;base64,${file}`;
    }

    if (object && object.base64) {
      return `data:image/png;base64,${object.base64}`;
    }

    if (object && object.image && object.image.thumbSrc) {
      return object.image.thumbSrc + `?${new Date().getTime()}`;
    }

    return null;
  };

  handleChange = (event) => {
    const reader = new FileReader();

    reader.onloadstart = () => {
      this.setState({ loading: true });
    };
    reader.onload = () => {
      const file = reader.result.split(',').pop();
      this.setState({
        loading: false,
        file: file,
      });
      this.props.onUploaded(file);
    };
    reader.onerror = () => {
      this.setState({ loading: false });
    };
    reader.onabort = () => {
      this.setState({ loading: false });
    };
    const firstFile = event?.target?.files?.length ? event?.target?.files[0] : null;

    firstFile && reader.readAsDataURL(firstFile);
  };

  render() {
    const { classes, object } = this.props;

    const image = this.getImage();

    return (
      <React.Fragment>
        {image ? (
          <div className={classes.imageBoxWrapper}>
            <label htmlFor="file" className={classes.labelStyles}>
              <div className={classes.imageBox}>
                <img className={classes.image} src={this.getImage()} alt={object ? object.name : 'Empty'} />
              </div>
            </label>

            {this.state.loading && <Spinner />}

            <input onChange={this.handleChange} accept="image/*" style={{ display: 'none' }} id="file" type="file" />
          </div>
        ) : (
          <div className={classes.imageBoxWrapper}>
            <div className={classes.imageBox}>
              <input onChange={this.handleChange} accept="image/*" style={{ display: 'none' }} id="file" type="file" />

              <label htmlFor="file" className={classes.insideLabelStyles}>
                <AddImage />
              </label>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

ImageUploader.propTypes = propTypes;

export default withStyles(styles, { withTheme: true })(injectIntl(ImageUploader));
