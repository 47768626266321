import React, { useEffect, useState } from 'react';
import { SalesIcon, InventoryIcon, CheckIcon } from '../../assets';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import Title from '../Title';
import IconSeparatedTextList from '../IconSeparatedTextList';
import { BUTTON_VARIANTS } from '../../constants/globals';
import PrimaryButton from '../SupplierButton';
import { toggleSupplierPopover } from '../../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { apiGetBuyer } from '../../../../api';
import { Spinner } from '../../../../components';
import ErrorBox from '../../../../components/ErrorBox/ErrorBox';
import { setSupplierParentObject } from '../../../../helpers';

const useStyles = makeStyles(styles);

const FirstVisitContent = () => {
  const { producerId } = useParams();
  const classes = useStyles();
  const history = useNavigate();
  const intl = useIntl();
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(toggleSupplierPopover(null));
  };
  const { supplier } = useSelector((state) => ({
    supplier: state.supplier.object,
  }));
  const [producer, setProducer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    !!producerId &&
      apiGetBuyer(
        producerId,
        (response) => {
          setSupplierParentObject(response);
          setError('');
          setProducer(response);
          setLoading(false);
        },
        () => {
          setLoading(false);
          setError(intl.formatMessage({ id: 'global.somethingWentWrong' }));
        }
      );

    return () => history('/supplier/inventory', { replace: true });
  }, [producerId]);

  if (loading) {
    return (
      <>
        <Spinner size={60} />
      </>
    );
  }

  return (
    <>
      <Title
        className={classes.title}
        text={intl.formatMessage({ id: 'supplier.popover.firstVisit.title' }, { supplierName: supplier.name })}
      />

      {!!error && <ErrorBox error={error} />}

      <div className={classes.subTextWrapper}>
        <p className={classes.text}>
          {intl.formatMessage(
            { id: 'supplier.popover.firstVisit.userInvited' },
            { user: producer?.name, strong: (...chunks) => <strong>{chunks}</strong> }
          )}
        </p>
      </div>

      <div className={classes.iconSeparatedTextWrapper}>
        <IconSeparatedTextList
          icon={InventoryIcon}
          title={intl.formatMessage({ id: 'supplier.popover.firstVisit.syncedInventory' })}
          text={intl.formatMessage(
            { id: 'supplier.popover.firstVisit.yourUpdatesAutomaticallyUpdateOnlineStore' },
            { producerName: producer?.name }
          )}
        />
        <IconSeparatedTextList
          icon={SalesIcon}
          title={intl.formatMessage({ id: 'supplier.popover.firstVisit.seeYourSales' })}
          text={intl.formatMessage({ id: 'supplier.popover.firstVisit.realTimeSalesLists' })}
        />
        <IconSeparatedTextList
          icon={CheckIcon}
          title={intl.formatMessage({ id: 'supplier.popover.firstVisit.saveTime' })}
          text={intl.formatMessage({ id: 'supplier.popover.firstVisit.focusWhatCountsMost' })}
        />
      </div>

      <PrimaryButton
        classes={{ root: classes.button }}
        variant={BUTTON_VARIANTS.blue}
        text={intl.formatMessage({ id: 'supplier.popover.firstVisit.button' })}
        link={'/supplier/inventory'}
        onClick={onClose}
      />
    </>
  );
};

export default FirstVisitContent;
