import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { PrimaryCheckbox } from '../../components';
import { COLORS } from '../../helpers';

const propTypes = {
  label: PropTypes.string,
  columns: PropTypes.array.isRequired,
  allowHideActionsColumn: PropTypes.bool.isRequired,
  defaultHiddenColumnNames: PropTypes.array,
  onChangeColumnsState: PropTypes.func.isRequired,
};
const defaultProps = {
  columns: [],
  allowHideActionsColumn: false,
  defaultHiddenColumnNames: [],
};

const styles = {
  root: {
    color: COLORS.black,
    marginLeft: 9,
    marginRight: 9,
    padding: 0,
    '&:hover': {
      background: 'none',
    },
  },
  label: {
    fontSize: 12,
    color: COLORS.text,
    flexDirection: 'column',
  },
};

class ColumnChooser extends React.Component {
  state = {
    anchorEl: null,
    hiddenColumns: this.props.defaultHiddenColumnNames,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.hiddenColumnNames?.length !== this.props.hiddenColumnNames?.length) {
      this.setState({
        hiddenColumns: this.props.hiddenColumnNames,
      });
    }
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  isColumnVisible = (name) => {
    const { hiddenColumns } = this.state;
    return hiddenColumns.indexOf(name) === -1;
  };

  changeColumnState = (column, visible) => {
    this.setState(
      (state) => {
        const { hiddenColumns } = state;
        let updated = [];
        let proceed = false;

        for (let i = 0; i < hiddenColumns.length; i++) {
          if (hiddenColumns[i] === column) {
            proceed = true;
            if (!visible) {
              updated.push(hiddenColumns[i]);
            }
          } else {
            updated.push(hiddenColumns[i]);
          }
        }

        if (!proceed && !visible) {
          updated.push(column);
        }

        return {
          hiddenColumns: updated,
        };
      },
      () => {
        //this.handleClose();
        const { onChangeColumnsState } = this.props;
        onChangeColumnsState(this.state.hiddenColumns, column);
      }
    );
  };

  render() {
    const { columns, intl, label, classes, allowHideActionsColumn } = this.props;

    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <React.Fragment>
        <IconButton
          aria-label="More"
          aria-owns={open ? 'long-menu' : undefined}
          aria-haspopup="true"
          classes={{ root: classes.root, label: classes.label }}
          onClick={this.handleClick}>
          <ViewColumnIcon style={{ color: COLORS.black }} />
          {label || ''}
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              // width: 200,
            },
          }}>
          {columns
            .filter((column) => column.title.trim())
            .map((column) => (
              <MenuItem key={column.name}>
                <FormControlLabel
                  control={
                    <PrimaryCheckbox
                      checked={this.isColumnVisible(column.name)}
                      onChange={(event) => this.changeColumnState(column.name, event.target.checked)}
                      color="default"
                    />
                  }
                  label={column.title}
                />
              </MenuItem>
            ))}

          {allowHideActionsColumn && (
            <MenuItem key={'actions-' + columns.length}>
              <FormControlLabel
                control={
                  <PrimaryCheckbox
                    checked={this.isColumnVisible('actions')}
                    onChange={(event) => this.changeColumnState('actions', event.target.checked)}
                    color="default"
                  />
                }
                label={intl.formatMessage({ id: 'global.actions' })}
              />
            </MenuItem>
          )}
        </Menu>
      </React.Fragment>
    );
  }
}

ColumnChooser.propTypes = propTypes;
ColumnChooser.defaultProps = defaultProps;

export default injectIntl(withStyles(styles)(ColumnChooser));
