import React from 'react';
import {
  Template,
  TemplatePlaceholder,
  Plugin,
  TemplateConnector,
} from '@devexpress/dx-react-core';
import { withStyles } from '@material-ui/core/styles';

const styles = ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const BatchControlsWrapper = ({ classes,
                                component,
                                reload,
                                keys,
                                rows }) => {
  const Component = component;
  return (
    <Plugin name="BatchControlsWrapper">
      <Template name="toolbarContent" className={classes.root}>
        <TemplateConnector>
          {() => !!keys.length && (
            <Component keys={keys}
                       rows={rows}
                       reload={reload} />
          )}
        </TemplateConnector>
        <TemplatePlaceholder />
      </Template>
    </Plugin>
  )
};

export default withStyles(styles)(
  BatchControlsWrapper
)