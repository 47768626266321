const validation = {
  address: {
    presence: {
      message: '^validation.customer.enterAddress'
    }
  },
  city: {
    presence: {
      message: '^validation.customer.enterCity'
    }
  },
  state: {
    presence: {
      message: '^validation.customer.enterState'
    }
  },
  zipCode: {
    presence: {
      message: '^validation.customer.enterZipCode'
    }
  },
};

export default validation;
