const validation = {
  firstName: {
    presence: {
      message: '^validation.customer.enterFirstName'
    }
  },
  lastName: {
    presence: {
      message: '^validation.customer.enterLastName'
    }
  },
  email: {
    presence: {
      message: '^validation.customer.enterEmail'
    },
    email: {
      message: '^validation.customer.enterValidEmail'
    }
  },
};

export default validation;
