import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';

RichTextViewer.propTypes = {
  html: PropTypes.string
};

function RichTextViewer(props) {
  const {html, ...otherProps} = props;
  useEffect(() => {
    if (!!viewerElem) {
      viewerElem.current.innerHTML = html
    }
  }, [html]);
  const viewerElem = useRef(null);

  return (
    <div ref={viewerElem} {...otherProps} />
  );
}

export default RichTextViewer;