import React from 'react';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';

const styles = ({
  wrapper: {
    marginTop: 50,
    textAlign: 'center',
  }
});

const AdminDialogControls = ({ classes,
                              children,
                              className,
                              ...other }) => {
  return (
    <div className={classNames(classes.wrapper, className)}
                   {...other} >
      {children}
    </div>
  );
};

export default withStyles(styles)(
  AdminDialogControls
);