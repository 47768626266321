import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';

const GuestRoute = (props) => {
  const { component: Component } = props;
  const authenticated = useSelector((state) => state.auth.authenticated);

  if (!authenticated) {
    return <Component {...props} />;
  } else {
    return <Navigate to={'/home'} replace={true} state={{ from: props.location }} />;
  }
};

export default GuestRoute;
