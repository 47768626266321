import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Icon from '../../../../components/Icon/Icon';
import {GreenButton} from '../../../../components';
import CheckIcon from '@material-ui/icons/Check';
import StorefrontIcon from '@material-ui/icons/Storefront';

ProfileContent.propTypes = {
  isFinished: PropTypes.bool,
  history: PropTypes.object,
};


const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  mainContent: {
    flexBasis: 500,
    flex: 0.8,
  },
  iconContentWrapper: {
    flex: 0.2,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  iconWrapper: {
    width: 100,
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 30,
    backgroundColor: '#EEEFF0',
    borderRadius: 50,
    position: 'relative',
  },
  sectionTitleWrapper: {},
  sectionTitle: {
    fontSize: 16,
    margin: 0,
    marginBottom: 30,
  },
  sectionInformation: {},
  icon: {
    fontSize: 60,
    fill: '#707070 !important',
  },
  iconCheck: {
    fontSize: 36,
    fill: '#fff !important',
    position: 'absolute',
    bottom: 0,
    right: 0,
    backgroundColor: '#247356',
    borderRadius: 36,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
  },
  controls: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: 30,
  },
  button: {
    textTransform: 'none',
    marginBottom: 20,
    minWidth: 220,
  },
}));

function ProfileContent(props) {
  const {intl, isFinished, history} = props;
  const classes = useStyles();

  const getContentStep = () => {
    return (
      <>
        <div className={classes.sectionTitleWrapper}><h2
          className={classes.sectionTitle}>{intl.formatMessage({id: 'screen.homeScreen.updateProfile'})}</h2></div>

        <div className={classes.sectionInformation}>
          <p>{intl.formatMessage({id: 'screen.homeScreen.updateProfile.text'})}</p>
        </div>

        <div className={classes.controls}>
          <GreenButton onClick={() => {
            history.push('/settings#profile')
          }} className={classes.button}>
            {intl.formatMessage({id: 'screen.homeScreen.updateProfile'})}
          </GreenButton>

        </div>
      </>
    )
  }

  const getFinishStep = () => {
    return (
      <>
        <div className={classes.sectionTitleWrapper}><h2
          className={classes.sectionTitle}>{intl.formatMessage({id: 'screen.homeScreen.updateProfile.final'})}</h2>
        </div>

        <div className={classes.sectionInformation}>
          <p>{intl.formatMessage({id: 'screen.homeScreen.updateProfile.finalText'})}</p>
        </div>

        <div className={classes.controls}>
          <GreenButton onClick={() => {
            history.push('/settings#profile')
          }} className={classes.button}>
            {intl.formatMessage({id: 'screen.homeScreen.updateProfile.viewProfile'})}
          </GreenButton>
        </div>
      </>
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.mainContent}>

        {!isFinished
          ? getContentStep()
          : getFinishStep()
        }

      </div>
      <div className={classes.iconContentWrapper}>
        <div className={classes.iconWrapper}>
          <Icon icon={StorefrontIcon} className={classes.icon}/>
          {isFinished && <Icon icon={CheckIcon} className={classes.iconCheck}/>}
        </div>
      </div>
    </div>
  );
}

export default ProfileContent;