import axios from 'axios';
import { getRequestConfig, REQUEST_TYPES, replaceUrlPlaceholder, replaceUrlPlaceholders } from '../request';
import API_END_POINTS from '../endpoints';

/**
 * Fetch customer
 * @param {object} customer
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerRetrieve = function (customer, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.CUSTOMER_RETRIEVE, 'cr', customer.id);
  const config = getRequestConfig(REQUEST_TYPES.GET, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Create customer
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerCreate = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.CUSTOMER_CREATE);
  config.data = {
    url: `${process.env.REACT_APP_STORE_URL}create-password?t=token&c=customerId`,
    ...data,
  };

  axios(config)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Updates customer
 * @param {string} customerId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerUpdate = function (customerId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.CUSTOMER_UPDATE, 'cr', customerId);
  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  config.data = data;

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

export const apiCustomerAddAccount = function (customerId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.CUSTOMER_ADD_ACCOUNT, {
    cr: customerId,
  });
  const config = getRequestConfig(REQUEST_TYPES.POST, url);

  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Patch account
 * @param {string} customerId
 * @param {string} accountId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerPatchAccount = function (customerId, accountId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.CUSTOMER_PATCH_ACCOUNT, {
    cr: customerId,
    ac: accountId,
  });
  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  config.data = data;

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Patch customer
 * @param {string} customerId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerPatch = function (customerId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.CUSTOMER_PATCH, 'cr', customerId);
  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

export const apiCustomerDeleteAccount = function (customerId, account, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.CUSTOMER_DELETE_ACCOUNT, {
    cr: customerId,
    ac: account.id,
  });
  const config = getRequestConfig(REQUEST_TYPES.DEL, url);

  axios(config)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Add address
 * @param {string} customerId
 * @param {string} addressType
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerAddAddress = function (customerId, addressType, data, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.CUSTOMER_ADD_ADDRESS, {
    cr: customerId,
    at: addressType,
  });
  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  config.data = data;

  axios(config)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Add billing address
 * @param {string} customerId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerAddBillingAddress = function (customerId, data, onSuccess, onError) {
  apiCustomerAddAddress(customerId, 'billing', data, onSuccess, onError);
};

/**
 * Add shipping address
 * @param {string} customerId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerAddShippingAddress = function (customerId, data, onSuccess, onError) {
  apiCustomerAddAddress(customerId, 'shipping', data, onSuccess, onError);
};

/**
 * Patch address
 * @param {string} customerId
 * @param {string} addressId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerPatchAddress = function (customerId, addressId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.CUSTOMER_PATCH_ADDRESS, {
    cr: customerId,
    ad: addressId,
  });
  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  config.data = data;

  axios(config)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Delete address
 * @param {string} customerId
 * @param {string} addressId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerDeleteAddress = function (customerId, addressId, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.CUSTOMER_PATCH_ADDRESS, {
    cr: customerId,
    ad: addressId,
  });
  const config = getRequestConfig(REQUEST_TYPES.DEL, url);

  axios(config)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Reset password
 * @param {string} customerId
 * @param {boolean} noEmail
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerResetPassword = function (customerId, noEmail, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.CUSTOMER_INVITE_RESET, 'cr', customerId);
  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  let data = {
    mode: 'reset',
    url: `${process.env.REACT_APP_STORE_URL}reset-password?t=token&c=customerId`,
  };

  if (typeof noEmail !== 'undefined') {
    data['no-email'] = noEmail;
  }

  config.data = data;

  axios(config)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Resend invite
 * @param {string} customerId
 * @param {boolean} noEmail
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerResentInvite = function (customerId, noEmail, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.CUSTOMER_INVITE_RESET, 'cr', customerId);
  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  let data = {
    mode: 'invite',
    url: `${process.env.REACT_APP_STORE_URL}create-password?t=token&c=customerId`,
  };

  if (typeof noEmail !== 'undefined') {
    data['no-email'] = noEmail;
  }

  config.data = data;

  axios(config)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Reset account password
 * @param {string} customerId
 * @param {string} accountId
 * @param {boolean} noEmail
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerResetAccountPassword = function (customerId, accountId, noEmail, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.CUSTOMER_INVITE_RESET_ACCOUNT, {
    cr: customerId,
    ac: accountId,
  });

  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  let data = {
    mode: 'reset',
    url: `${process.env.REACT_APP_STORE_URL}reset-password?t=token&c=customerId`,
  };

  if (typeof noEmail !== 'undefined') {
    data['no-email'] = noEmail;
  }

  config.data = data;

  axios(config)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Resend account invite
 * @param {string} customerId
 * @param {string} accountId
 * @param {boolean} noEmail
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerResendAccountInvite = function (customerId, accountId, noEmail, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.CUSTOMER_INVITE_RESET_ACCOUNT, {
    cr: customerId,
    ac: accountId,
  });

  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  let data = {
    mode: 'invite',
    url: `${process.env.REACT_APP_STORE_URL}create-password?t=token&c=customerId`,
  };

  if (typeof noEmail !== 'undefined') {
    data['no-email'] = noEmail;
  }

  config.data = data;

  axios(config)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Activate customer
 * @param {array} id - customer id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiActivateCustomer = function (id, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.CUSTOMER_PATCH, { cr: id });
  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  config.data = { active: true };

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Inactivate customer
 * @param {array} id - customer id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiInactivateCustomer = function (id, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.CUSTOMER_PATCH, { cr: id });
  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  config.data = { active: false };

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Approve customer
 * @param {array} id - customer id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiApproveCustomer = function (id, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.CUSTOMER_APPROVE, { cr: id });
  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Unapprove customer
 * @param {array} id - customer id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiUnapproveCustomer = function (id, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.CUSTOMER_UNAPPROVE, { cr: id });
  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Generates qr code for customer
 * @param {object} data
 * @param {string} data.customerId - is required
 * @param onSuccess
 * @param onError
 */
export const apiCustomerQrCodeGenerate = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.CUSTOMER_GENERATE_QR_CODE);

  config.data = data;

  axios(config)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch((error) => {
      onError(error);
    });
};
