const styles = theme => ({
  appBar: {
    backgroundColor: '#ffffff',
    marginBottom: '-70px'
  },
  controls: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  topToolbar: {
    height: 70,
  },
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  black: {
    color: '#212121',
  },
  logo: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    height: 40,
    color: '#ffffff'
  },
  mr10: {
    marginRight: 10,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  logoContainer: {
    flex: 1,
    textAlign: 'center',
  }
});

export default styles;