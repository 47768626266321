import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../hocs';
import { AdminLayout, AdminTabs, Spinner } from '../../components';
import { SidebarMenu, HeaderMenu } from '../Partials';
import DiscountForm from '../../forms/DiscountForm';
import BaseDialog from '../../dialogs/BaseDialog';
import TabTypes from '../../dialogs/SaveTabSettingsDialog/Types';
import { setProducer } from '../../store/actions';
import { MAIN_TAB_NAMES } from '../../helpers/tabs';
import DiscountsContent from './DiscountsContent';
import styles from './styles';

class DiscountsScene extends React.Component {
  constructor(props) {
    super(props);

    const { intl, tabs } = this.props;
    this.state = {
      tabIndex: tabs.getInitialIndex(window.location.hash, true),
      columnExtensions: [
        { columnName: 'name', width: 200 },
        { columnName: 'amount', align: 'right', width: 200 },
        { columnName: 'active', align: 'center', width: 200 },
      ],
      columns: [
        { name: 'name', title: intl.formatMessage({ id: 'discounts.column.name' }) },
        { name: 'products', title: intl.formatMessage({ id: 'discounts.column.products' }) },
        { name: 'customers', title: intl.formatMessage({ id: 'discounts.column.customers' }) },
        { name: 'used', title: intl.formatMessage({ id: 'discounts.column.used' }) },
        { name: 'start', title: intl.formatMessage({ id: 'discounts.column.start' }) },
        { name: 'end', title: intl.formatMessage({ id: 'discounts.column.end' }) },
        { name: 'amount', title: intl.formatMessage({ id: 'discounts.column.amount' }) },
        { name: 'active', title: intl.formatMessage({ id: 'products.column.active' }), type: 'active' },
      ],
    };
  }

  back = () => {
    this.props.history.push('/discounts');
  };

  render() {
    const { tabIndex, loading } = this.state;

    const { intl, tabs, openItemModal } = this.props;

    const tabsTitles = tabs.getTabNames([intl.formatMessage({ id: 'global.all' })]);

    let allTab = tabs.getTabByName(MAIN_TAB_NAMES.DISCOUNTS_ALL, intl.formatMessage({ id: 'global.discounts' }), {
      id: MAIN_TAB_NAMES.DISCOUNTS_ALL,
      name: intl.formatMessage({ id: 'global.discounts' }),
    });

    return (
      <AdminLayout sidebarComponent={SidebarMenu} headerMenuComponent={HeaderMenu}>
        {loading && <Spinner size={60} />}
        <AdminTabs onChangeTab={() => {}} tab={tabIndex} tabs={tabsTitles}>
          {tabIndex === 0 && (
            <DiscountsContent
              tab={allTab}
              onRef={(tab) => (this.tab = tab)}
              columns={this.state.columns}
              columnExtensions={this.state.columnExtensions}
              key={'all-discounts'}
              index={'all-discounts'}
              allowCreateTab
            />
          )}
        </AdminTabs>

        {openItemModal && (
          <BaseDialog
            scroll={'paper'}
            maxWidth="md"
            open
            onClose={this.back}
            title={
              this.props.match.params.id === 'new'
                ? intl.formatMessage({ id: 'discounts.adding' })
                : intl.formatMessage({ id: 'discounts.editing' })
            }>
            <DiscountForm
              {...this.props}
              producer={this.props.producer}
              onSuccess={() => {
                this.back();
                setTimeout(() => {
                  this.tab.forceTableReload();
                }, 200);
              }}
            />
          </BaseDialog>
        )}
      </AdminLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  tabs: state.producer.tabs[TabTypes.DISCOUNTS],
  producer: state.producer.object,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setProducer: (producer) => dispatch(setProducer(producer)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(withStyles(styles)(DiscountsScene))));
