import React from 'react';

export const CopyIcon = (props) => {
  const { size, fillColor = '#F7F7F7', strokeColor = 'black', ...others } = props;
  return (
    <svg width={size} height={size} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <rect
        x="4.25566"
        y="0.7"
        width="9.26667"
        height="11.0444"
        rx="1.3"
        stroke={strokeColor}
        strokeOpacity="0.3"
        strokeWidth="1.4"
      />
      <rect y="3.55554" width="10.6667" height="12.4444" rx="2" fill={fillColor} />
      <rect
        x="0.7"
        y="4.25554"
        width="9.26667"
        height="11.0444"
        rx="1.3"
        stroke={strokeColor}
        strokeOpacity="0.3"
        strokeWidth="1.4"
      />
    </svg>
  );
};
