import React from 'react';
import { Table, TableTreeColumn } from '@devexpress/dx-react-grid-material-ui';
import { TableCheckbox } from '../../components';
import {
  weightToUser,
  variantHasPriceWithLevel,
  getVariantPriceWithoutCurrency,
  getVariantPriceByLocationWithoutCurrency,
  PRICE_LEVELS,
  toPaper,
  toCoins,
  getCurrentProducer,
  weightToSystem,
  COLORS,
} from '../../helpers';
import {
  apiActivateProducts,
  apiInactivateProducts,
  apiActivateVariants,
  apiInactivateVariants,
  apiEditProductVariant,
  apiEditProduct,
  apiEditProductVariantPriceByLocation,
  apiChangeVariantsOffered,
  apiProductChangeOfferedMarketsPresetsList,
  // apiAddInventoryToAllProductVariants,
} from '../../api/product/product';
import FiltersProcessor from '../../components/AdminDXTable/ClientSideFilters/FiltersProcessor';
import {
  CellAsPriceInput,
  CellAsSelectInput,
  CellAsTextInput,
  CellAsTextArea,
  CellAsIntegerInput,
} from '../../components/AdminDXTable/Editing';
import TagsCell from './Cells/TagsCell';
import VariantInventoryCell from './Cells/VariantInventoryCell';
import {
  ACTIVITY_FILTER as ACTIVITY_FILTER_NAME,
  NAME_FILTER,
  SUB_TYPE_FILTER,
  TYPE_FILTER,
} from '../../dialogs/ProductsFilterDialog/FilterNames';
import { ACTIVITY_FILTER as ACTIVITY_FILTER_TYPE } from '../../components/AdminDXTable/ClientSideFilters/FilterTypes';
import DXFiltersFactory from '../../components/AdminDXTable/Filters/DXFiltersFactory';
import CellAsImage from '../../components/AdminDXTable/StandardizedColumns/CellAsImage';
import { makeStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';
import CircularProgress from '@material-ui/core/CircularProgress';

export const prepareActiveTabFilters = (intl, filters) => {
  const defaultActiveFilter = {
    name: ACTIVITY_FILTER_NAME,
    property: 'active',
    type: ACTIVITY_FILTER_TYPE,
    title: intl.formatMessage({ id: 'products.filter.activity' }),
    selectedValues: ['Active'],
    clientSide: true,
    entities: ['product', 'variant'],
  };
  filters.push(defaultActiveFilter);
  return filters;
};

export const TableCell = (
  reload,
  { row, ...props },
  rows,
  onUpdateRows,
  grid,
  onClick,
  dynamicColumns,
  disableEditing
) => {
  const type = props.column.type || 'text';
  const image = row.image;
  const { units, subTypes } = getCurrentProducer();

  if (type === 'image' && !!image) {
    let src = image.thumbSrc || image.src;
    src = src + `?${new Date().getTime()}`;

    return <CellAsImage {...props} onClick={() => onClick('image', row)} src={src} alt={row.name} />;
  }

  if (type === 'product.type' && isProduct(row)) {
    return <Table.Cell {...props}>{row.type}</Table.Cell>;
  }

  // inventory on product level
  // if (type === 'inventory' && isProduct(row)) {
  //   return (
  //     <CellAsIntegerInput
  //       value={row.inventory || ''}
  //       key={`inventory-${row.id}`}
  //       cellProps={props}
  //       onFinishCellEditing={(value) => {
  //         apiAddInventoryToAllProductVariants(
  //           row.id,
  //           { quantity: value, managed: true, stopSelling: false },
  //           (product) => {
  //             replaceProduct(product, rows, onUpdateRows);
  //           },
  //           (error) => {
  //             console.log('apiAddInventoryToAllProductVariants.error', error, error?.response);
  //           }
  //         );
  //       }}
  //     />
  //   );
  // }

  if (type === 'inventory' && isVariant(row)) {
    return (
      <VariantInventoryCell
        row={row}
        cellProps={props}
        onSuccess={(updatedProduct) => replaceVariant(row, updatedProduct, rows, onUpdateRows)}
      />
    );
  }

  if (type === 'tags' && isVariant(row)) {
    return (
      <TagsCell
        row={row}
        onSuccess={(product) => {
          replaceVariant(row, product, rows, onUpdateRows);
        }}
        {...props}
      />
    );
  }

  if (type === 'estimatedWeight' && isVariant(row)) {
    return (
      <CellAsIntegerInput
        value={row.estimatedWeight ? parseFloat(weightToUser(row.estimatedWeight)).toFixed(2) : ''}
        key={`${row.product.id}-${row.id}`}
        cellProps={props}
        onFinishCellEditing={(value) => {
          apiEditProductVariant(
            row.product.id,
            row.id,
            {
              estimatedWeight: weightToSystem(value),
            },
            (product) => {
              replaceVariant(row, product, rows, onUpdateRows);
            }
          );
        }}
      />
    );
  }

  if (type === 'quantity' && isVariant(row)) {
    return (
      <CellAsPriceInput
        value={row.unitQuantity ? parseFloat(weightToUser(row.unitQuantity)).toFixed(2) : ''}
        key={`${row.product.id}-${row.id}`}
        cellProps={props}
        onFinishCellEditing={(value) => {
          apiEditProductVariant(
            row.product.id,
            row.id,
            {
              unitQuantity: weightToSystem(value),
            },
            (product) => {
              replaceVariant(row, product, rows, onUpdateRows);
            }
          );
        }}
      />
    );
  }

  if (type === 'subType' && isProduct(row)) {
    const value = row.subType || '';
    if (disableEditing) {
      return <Table.Cell {...props}>{value}</Table.Cell>;
    }

    return (
      <CellAsSelectInput
        value={value}
        values={Object.keys(subTypes)}
        cellProps={props}
        onFinishCellEditing={(value) => {
          apiEditProduct(
            row.id,
            {
              subType: value,
            },
            (product) => {
              replaceProduct(product, rows, onUpdateRows);
            }
          );
        }}
      />
    );
  }

  if (props.column.name === 'variant.name' && isVariant(row)) {
    const value = row.name;
    if (disableEditing) {
      return <Table.Cell {...props}>{value}</Table.Cell>;
    }

    return (
      <CellAsTextInput
        value={value}
        key={`${row.product.id}-${row.id}`}
        cellProps={props}
        onFinishCellEditing={(value) => {
          apiEditProductVariant(
            row.product.id,
            row.id,
            {
              name: value,
            },
            (product) => {
              replaceVariant(row, product, rows, onUpdateRows);
            }
          );
        }}
      />
    );
  }

  if (type === 'name' && isVariant(row)) {
    return <Table.Cell {...props}>&nbsp;</Table.Cell>;
  }

  if (type === 'supplier' && isVariant(row)) {
    return <Table.Cell {...props}>&nbsp;</Table.Cell>;
  }

  if (type === 'translation' && isProduct(row)) {
    return (
      <CellAsTextInput
        value={row.translation}
        key={`${row.id}`}
        cellProps={props}
        onFinishCellEditing={(value) => {
          apiEditProduct(
            row.id,
            {
              translation: value,
            },
            (product) => {
              replaceProduct(product, rows, onUpdateRows);
            }
          );
        }}
      />
    );
  }

  if (type === 'name' && isProduct(row)) {
    const value = row.name;
    if (disableEditing) {
      return <Table.Cell {...props}>{value}</Table.Cell>;
    }

    return (
      <CellAsTextInput
        value={value}
        key={`${row.id}`}
        cellProps={props}
        onFinishCellEditing={(value) => {
          apiEditProduct(
            row.id,
            {
              name: value,
            },
            (product) => {
              replaceProduct(product, rows, onUpdateRows);
            }
          );
        }}
      />
    );
  }

  if (type === 'supplier' && isProduct(row)) {
    return <Table.Cell {...props}>{row?.supplier?.name || '-'}</Table.Cell>;
  }

  if (type === 'description' && isVariant(row)) {
    return (
      <CellAsTextArea
        value={row.description || ''}
        key={`${row.product.id}-${row.id}`}
        cellProps={props}
        onFinishCellEditing={(value) => {
          apiEditProductVariant(
            row.product.id,
            row.id,
            {
              description: value,
            },
            (product) => {
              replaceVariant(row, product, rows, onUpdateRows);
            }
          );
        }}
      />
    );
  }

  if (type === 'description' && isProduct(row)) {
    return (
      <CellAsTextArea
        value={row.description || ''}
        key={`${row.id}`}
        cellProps={props}
        onFinishCellEditing={(value) => {
          apiEditProduct(
            row.id,
            {
              description: value,
            },
            (product) => {
              replaceProduct(product, rows, onUpdateRows);
            }
          );
        }}
      />
    );
  }

  if (type === 'unit' && isVariant(row)) {
    const value = row.unit || '';
    if (disableEditing) {
      return <Table.Cell {...props}>{value}</Table.Cell>;
    }

    return (
      <CellAsSelectInput
        value={value}
        key={`${row.product.id}-${row.id}`}
        values={Object.keys(units)}
        cellProps={props}
        onFinishCellEditing={(value) => {
          apiEditProductVariant(
            row.product.id,
            row.id,
            {
              unit: value,
            },
            (product) => {
              replaceVariant(row, product, rows, onUpdateRows);
            }
          );
        }}
      />
    );
  }

  if (type === PRICE_LEVELS.WHOLESALE_MARKET && variantHasPriceWithLevel(row, PRICE_LEVELS.WHOLESALE_MARKET)) {
    const value = toPaper(getVariantPriceWithoutCurrency(row, PRICE_LEVELS.WHOLESALE_MARKET));
    if (disableEditing) {
      return <Table.Cell {...props}>{value}</Table.Cell>;
    }

    return (
      <CellAsPriceInput
        value={value}
        cellProps={props}
        onFinishCellEditing={(value) => {
          apiEditProductVariant(
            row.product.id,
            row.id,
            {
              prices: changePriceByType('wholesale_market', toCoins(value)),
            },
            (product) => {
              replaceVariant(row, product, rows, () => {});
            }
          );
        }}
      />
    );
  }

  if (type === PRICE_LEVELS.WHOLESALE_PREORDER && variantHasPriceWithLevel(row, PRICE_LEVELS.WHOLESALE_PREORDER)) {
    const value = toPaper(getVariantPriceWithoutCurrency(row, PRICE_LEVELS.WHOLESALE_PREORDER));
    if (disableEditing) {
      return <Table.Cell {...props}>{value}</Table.Cell>;
    }

    return (
      <CellAsPriceInput
        value={value}
        key={`${row.product.id}-${row.id}`}
        cellProps={props}
        onFinishCellEditing={(value) => {
          apiEditProductVariant(
            row.product.id,
            row.id,
            {
              prices: changePriceByType('wholesale_preorder', toCoins(value)),
            },
            (product) => {
              replaceVariant(row, product, rows, onUpdateRows);
            }
          );
        }}
      />
    );
  }

  if (type === PRICE_LEVELS.RETAIL_MARKET && variantHasPriceWithLevel(row, PRICE_LEVELS.RETAIL_MARKET)) {
    const value = toPaper(getVariantPriceWithoutCurrency(row, PRICE_LEVELS.RETAIL_MARKET));
    if (disableEditing) {
      return <Table.Cell {...props}>{value}</Table.Cell>;
    }

    return (
      <CellAsPriceInput
        value={value}
        key={`${row.product.id}-${row.id}`}
        cellProps={props}
        onFinishCellEditing={(value) => {
          apiEditProductVariant(
            row.product.id,
            row.id,
            {
              prices: changePriceByType('retail_market', toCoins(value)),
            },
            (product) => {
              replaceVariant(row, product, rows, onUpdateRows);
            }
          );
        }}
      />
    );
  }

  if (type === PRICE_LEVELS.RETAIL_PREORDER && variantHasPriceWithLevel(row, PRICE_LEVELS.RETAIL_PREORDER)) {
    const value = toPaper(getVariantPriceWithoutCurrency(row, PRICE_LEVELS.RETAIL_PREORDER));
    if (disableEditing) {
      return <Table.Cell {...props}>{value}</Table.Cell>;
    }

    return (
      <CellAsPriceInput
        value={value}
        key={`${row.product.id}-${row.id}`}
        cellProps={props}
        onFinishCellEditing={(value) => {
          apiEditProductVariant(
            row.product.id,
            row.id,
            {
              prices: changePriceByType('retail_preorder', toCoins(value)),
            },
            (product) => {
              replaceVariant(row, product, rows, onUpdateRows);
            }
          );
        }}
      />
    );
  }

  // Dynamic columns for prices
  if (dynamicColumns.some((item) => item.type === type && type.split(':').length === 2)) {
    let productPriceType = type.split(':')[0],
      productPriceLocation = type.split(':')[1];

    if (variantHasPriceWithLevel(row, productPriceType)) {
      return (
        <CellAsPriceInput
          value={toPaper(getVariantPriceByLocationWithoutCurrency(row, productPriceType, productPriceLocation))}
          key={`${row.product.id}-${row.id}`}
          cellProps={props}
          onFinishCellEditing={(value) => {
            apiEditProductVariantPriceByLocation(
              row.product.id,
              row.id,
              productPriceType,
              productPriceLocation,
              toCoins(value),
              (product) => {
                replaceVariant(row, product, rows, onUpdateRows);
              },
              () => {}
            );
          }}
        />
      );
    }
  }

  // Dynamic columns for offeredMarketPresets
  if (isProduct(row) && dynamicColumns.some((item) => item.type === type && type.split(':').length === 3)) {
    let locationId = type.split(':')[0],
      shippingDay = type.split(':')[1],
      shippingType = type.split(':')[2];

    return getOfferedMarketPresets(row, props, rows, onUpdateRows, locationId, shippingDay, shippingType);
  }

  if (type === 'active') return getActiveCell(row, props, rows, onUpdateRows);

  if (props.column.name === 'variant.retailPrice' && isVariant(row))
    return getOffered(row, props, rows, onUpdateRows, 'retail');

  if (props.column.name === 'variant.wholesalePrice' && isVariant(row))
    return getOffered(row, props, rows, onUpdateRows, 'wholesale');

  return <Table.Cell {...props} />;
};

const useNoDataComponentStyles = makeStyles(() => ({
  noDataMessageWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 40,
    paddingBottom: 40,
  },
  noDataMessage: {
    fontSize: 16,
    lineHeight: '21.6px',
    fontWeight: 400,
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.black,
    margin: 0,
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
    maxWidth: 420,
  },
  noDataSubMessage: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 14,
    lineHeight: '19px',
    marginTop: 5,
    display: 'block',
    color: 'rgba(0,0,0,0.5)',
  },
  spinner: {
    color: COLORS.text,
  },
  stillLoading: {
    fontWeight: 400,
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.black,
    fontSize: 14,
    marginRight: 10,
  },
}));

export const NoDataComponent = ({ onlyArchived, getMessage, loading, loadingToLong, ...cellProps }) => {
  const classes = useNoDataComponentStyles();
  const intl = useIntl();

  if (onlyArchived) {
    return (
      <Table.Cell {...cellProps}>
        <div className={classes.noDataMessageWrapper}>
          {loading && loadingToLong && (
            <p className={classes.stillLoading}>{intl.formatMessage({ id: 'global.stillLoading' })}</p>
          )}
          {loading && <CircularProgress className={classes.spinner} size={30} />}

          {!loading && (
            <p className={classes.noDataMessage}>
              {intl.formatMessage({ id: 'product.noArchivedProducts' })}
              <span className={classes.noDataSubMessage}>{intl.formatMessage({ id: 'product.dontSeeProduct' })}</span>
            </p>
          )}
        </div>
      </Table.Cell>
    );
  }

  return (
    <Table.Cell {...cellProps}>
      <div className={classes.noDataMessageWrapper}>
        {loading && loadingToLong && (
          <p className={classes.stillLoading}>{intl.formatMessage({ id: 'global.stillLoading' })}</p>
        )}
        {loading && <CircularProgress className={classes.spinner} size={30} />}

        {!loading && <p className={classes.noDataMessage}>{intl.formatMessage({ id: 'global.noData' })}</p>}
      </div>
    </Table.Cell>
  );
};

const isProduct = (row) => {
  return typeof row.variants !== 'undefined';
};

const isVariant = (row) => {
  return typeof row.variants === 'undefined';
};

const replaceProduct = (updatedProduct, rows, onUpdateRows) => {
  for (let i = 0; i < rows.length; i++) {
    if (updatedProduct.id === rows[i].id) {
      rows[i] = updatedProduct;
      break;
    }
  }
  // Force grid update
  onUpdateRows(rows);
};

const replaceVariant = (variant, updatedProduct, rows, onUpdateRows) => {
  // Search variant in update product
  let updatedVariant;
  for (let i = 0; i < updatedProduct.variants.length; i++) {
    if (variant.id === updatedProduct.variants[i].id) {
      updatedVariant = updatedProduct.variants[i];
      updatedVariant.product = updatedProduct;
      break;
    }
  }

  // Replace variant row in grid rows
  for (let i = 0; i < rows.length; i++) {
    if (updatedProduct.id === rows[i].id) {
      for (let j = 0; j < rows[i].variants.length; j++) {
        if (updatedVariant.id === rows[i].variants[j].id) {
          rows[i].variants[j] = updatedVariant;
          break;
        }
      }
      break;
    }
  }

  // Force grid update
  onUpdateRows(rows);
};

const getOfferedMarketPresets = (row, props, rows, onUpdateRows, locationId, shippingDay, shippingType) => {
  const onChangeOfferedMarketPresetsBeforeApiCall = (status) => {
    let newOfferedMarkets;
    for (let i = 0; i < rows.length; i++) {
      if (row.id === rows[i].id) {
        newOfferedMarkets = row.offeredMarkets.slice();
        const marketIndex = newOfferedMarkets.findIndex(
          (market) =>
            market.locationId === locationId &&
            market.shippingDay === shippingDay &&
            market.shippingType === shippingType
        );

        if (status) {
          newOfferedMarkets.push({ locationId, shippingDay, shippingType });
        } else {
          newOfferedMarkets.splice(marketIndex, 1);
        }

        rows[i].offeredMarkets = newOfferedMarkets;
        break;
      }
    }
    onUpdateRows(rows);

    return newOfferedMarkets;
  };

  const onChangeOfferedMarketPresets = (status) => {
    const data = onChangeOfferedMarketPresetsBeforeApiCall(status);
    apiProductChangeOfferedMarketsPresetsList(row.id, data, undefined, undefined);
  };

  return (
    <Table.Cell {...props}>
      {row.offeredMarkets.length && (
        <TableCheckbox
          onChange={onChangeOfferedMarketPresets}
          checked={row.offeredMarkets.some(
            (market) =>
              market.locationId === locationId &&
              market.shippingDay === shippingDay &&
              market.shippingType === shippingType
          )}
        />
      )}
    </Table.Cell>
  );
};

const getActiveCell = (row, props, rows, onUpdateRows) => {
  const onChangeProductActivityBeforeApiCall = (status) => {
    // Update statuses for variants
    for (let i = 0; i < rows.length; i++) {
      if (row.id === rows[i].id && rows[i].variants) {
        rows[i].active = status;
        for (let j = 0; j < rows[i].variants.length; j++) {
          rows[i].variants[j].active = status;
        }
        break;
      }
    }
    onUpdateRows(rows);
  };

  const onChangeProductActivity = (status) => {
    onChangeProductActivityBeforeApiCall(status);
    status
      ? apiActivateProducts([row.id], undefined, undefined)
      : apiInactivateProducts([row.id], undefined, undefined);
  };

  const onChangeVariantActivityBeforeApiCall = (status) => {
    // Update statuses for variants
    for (let i = 0; i < rows.length; i++) {
      for (let j = 0; j < rows[i].variants.length; j++) {
        if (rows[i].variants[j].id === row.id) {
          if (status) {
            rows[i].active = status;
          }
          rows[i].variants[j].active = status;
          if (!status && !rows[i].variants.some((variant) => variant.active)) {
            rows[i].active = status;
          }
          break;
        }
      }
    }
    onUpdateRows(rows);
  };

  const onChangeVariantActivity = (status) => {
    onChangeVariantActivityBeforeApiCall(status);
    status
      ? apiActivateVariants([row.id], undefined, undefined)
      : apiInactivateVariants([row.id], undefined, undefined);
  };

  return (
    <Table.Cell {...props}>
      {/* Checkbox for product */}
      {row.variants && <TableCheckbox onChange={onChangeProductActivity} checked={row.active} />}

      {/* Checkbox for variant */}
      {!row.variants && <TableCheckbox onChange={onChangeVariantActivity} checked={row.active} />}
    </Table.Cell>
  );
};

const getOffered = (row, props, rows, onUpdateRows, offeredType) => {
  const onChangeVariantActivityBeforeApiCall = (status) => {
    // Update statuses for variants
    for (let i = 0; i < rows.length; i++) {
      for (let j = 0; j < rows[i].variants.length; j++) {
        if (rows[i].variants[j].id === row.id) {
          rows[i].variants[j].offered[offeredType] = status;
          break;
        }
      }
    }
    onUpdateRows(rows);
  };

  const onChangeVariantActivity = (status) => {
    onChangeVariantActivityBeforeApiCall(status);
    apiChangeVariantsOffered(row.product.id, row.id, { offered: { [offeredType]: status } }, undefined, undefined);
  };

  return (
    <Table.Cell {...props}>
      {!row.variants && <TableCheckbox onChange={onChangeVariantActivity} checked={row.offered[offeredType]} />}
    </Table.Cell>
  );
};

export const TableRow = (onClick, { row, ...props }, onlyArchived) => {
  const isProductRow = row?.id ? !!row?.id?.match('pr_') : false;

  if (isProductRow && !row?.archived && onlyArchived) {
    // product row for NOT archived product on archived page
    return (
      <Table.Row
        {...props}
        onClick={() => onClick && onClick(row)}
        style={{
          backgroundColor: row.variants ? 'rgba(224, 224, 224, 0.4)' : '#ffffff',
          opacity: 0.5,
        }}
      />
    );
  }

  return (
    <Table.Row
      {...props}
      onClick={() => onClick && onClick(row)}
      style={{
        backgroundColor: row.variants ? 'rgba(224, 224, 224, 0.4)' : '#ffffff',
      }}
    />
  );
};

export const TableTreeCheckbox = (props, onlyArchived) => {
  const row = props?.row;
  const isProductRow = row?.id ? !!row?.id?.match('pr_') : false;

  if (isProductRow && !row?.archived && onlyArchived) {
    // Disable not archived product checkbox on archived page
    return null;
  }

  return <TableTreeColumn.Checkbox {...props} />;
};

export const filtersProcessor = (intl, filters, rows, push) => {
  let filtered = [];
  rows.forEach((row) => {
    let processor = new FiltersProcessor(intl, filters);
    if (processor.isSatisfy(row)) {
      filtered.push(row);
    }
  });
  push(filtered);
};

const changePriceByType = (type, value) => {
  return [
    {
      level: type,
      price: value,
    },
  ];
};

export const activeFilter = (intl, filters) => {
  filters = filters || [];

  if (!filters.some((filter) => filter.type === 'activity')) {
    const Filter = DXFiltersFactory.getFilterByName('activity');

    filters.push(Filter.getInitialConfig(intl, ['true'], true, true));
  }
  return filters;
};

export const clearClientSideFilters = (filters, additionalNames = []) => {
  const oldFilters = [NAME_FILTER, SUB_TYPE_FILTER, TYPE_FILTER, ACTIVITY_FILTER_NAME, ...additionalNames];
  return filters.filter((filter) => !oldFilters.includes(filter.name));
};

export const makeProductAndVariantIds = (keys) => {
  let productIds = [],
    variantIds = [];

  if (Array.isArray(keys)) {
    keys.forEach((key) => {
      if (key.match('pr_')) {
        productIds.push(key);
      }
      if (key.match('va_')) {
        variantIds.push(key);
      }
    });
  }

  return { productIds, variantIds };
};

export const ITEMS_STATUSES = {
  ARCHIVED: 'archived',
  UNARCHIVED: 'unarchived',
  DELETED: 'delete',
};
export function makeItemsStatusesByIds(ids, status = ITEMS_STATUSES.UNARCHIVED) {
  let result = {};

  if (Array.isArray(ids)) {
    ids.forEach((id) => (result[id] = { status }));
  }

  return result;
}

export function mergeFilters(...filters) {
  const margeByName = {};

  filters.forEach((currentFilters) => {
    if (!Array.isArray(currentFilters)) return;

    currentFilters.forEach((filter) => {
      margeByName[filter?.name] = filter;
    });
  });

  return Object.values(margeByName);
}
