import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withRouter } from '../../hocs';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Typography from '@material-ui/core/Typography';
import {
  AdminDialog,
  AdminDialogTitle,
  AdminDialogContent,
  AdminDialogControls,
  PrimaryButton,
} from '../../components';
import styles from './styles';
import RefundOrderInfo from './RefundOrderInfo';

const propTypes = {
  order: PropTypes.object.isRequired,
  onRefundSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  completed: PropTypes.bool,
  refundApi: PropTypes.func,
};

const RefundDialog = ({ onClose, onRefundSuccess, intl, classes, order, completed, refundApi }) => (
  <AdminDialog open closeButton onClose={onClose}>
    <AdminDialogTitle title={intl.formatMessage({ id: !completed ? 'global.refund' : 'order.refundCompleted' })} />

    <AdminDialogContent className={classes.wrapper}>
      {!completed && <RefundOrderInfo order={order} onRefundSuccess={onRefundSuccess} refundApi={refundApi} />}

      {completed && (
        <>
          <div style={styles.successMessageWrap}>
            <Typography>
              <CheckCircleIcon className={classes.okIcon} />
            </Typography>
            <div style={styles.hr} />
            <div className={classes.textSuccess}>
              <p>{intl.formatMessage({ id: 'orderRefund.receiptSentTo' })}</p>
            </div>
            <div className={classes.textSuccess}>
              <p>{order.customer.email}</p>
            </div>
            <div>
              <p className={classes.orderDetails} onClick={onClose}>
                {intl.formatMessage({ id: 'order.viewDetails' })}
              </p>
            </div>
          </div>

          <AdminDialogControls>
            <PrimaryButton className={classes.button} onClick={onClose}>
              {intl.formatMessage({ id: 'global.done' })}
            </PrimaryButton>
          </AdminDialogControls>
        </>
      )}
    </AdminDialogContent>
  </AdminDialog>
);

RefundDialog.propTypes = propTypes;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(RefundDialog)));
