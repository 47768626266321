import React from 'react';
import moment from 'moment';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import BookingCell from './Cells/BookingCell';
import ShippingCell from './Cells/ShippingCell';
import PaymentReceivedCell from './Cells/PaymentReceivedCell';
import PaymentMethodCell from './Cells/PaymentMethodCell';
import PaymentStatusCell from './Cells/PaymentStatusCell';
import { toPaper } from '../../helpers';

export const prepareShippingFilter = (intl, filters) => {
  let shippingProcessed = false;

  const defaultShippingFilter = {
    column: intl.formatMessage({ id: 'order.table.shipping' }),
    index: 1,
    name: 'shipping',
    property: 'shippingDate',
    selectedValues: [
      {
        predefined: 'past_3_months',
      },
    ],
    values: [],
  };

  for (let i = 0; i < filters.length; i++) {
    if (filters[i].name === 'shipping') {
      shippingProcessed = true;
      if (!filters[i].selectedValues.length) {
        filters[i].selectedValues[0] = {
          predefined: 'past_3_months',
        };
      }
    }
  }

  if (!shippingProcessed) {
    filters.push(defaultShippingFilter);
  }

  return filters;
};

export const prepareStatusFilter = (intl, filters) => {
  let processed = false;
  const defaultFilter = {
    column: intl.formatMessage({ id: 'order.table.status' }),
    index: 3,
    name: 'status',
    property: 'status',
    selectedValues: ['ne-cancelled'],
    values: [],
  };

  for (let i = 0; i < filters.length; i++) {
    if (filters[i].name === 'status') {
      processed = true;
      if (!filters[i].selectedValues.length) {
        filters[i].selectedValues = ['ne_cancelled'];
      }
    }
  }

  if (!processed) {
    filters.push(defaultFilter);
  }

  return filters;
};

export const prepareTypeFilter = (intl, filters) => {
  let processed = false;
  const defaultFilter = {
    column: intl.formatMessage({ id: 'order.table.types' }),
    index: 6,
    name: 'types',
    property: 'types',
    selectedValues: ['wholesale'],
    values: [],
  };

  for (let i = 0; i < filters.length; i++) {
    if (filters[i].name === 'types') {
      processed = true;
      if (!filters[i].selectedValues.length) {
        filters[i].selectedValues = ['wholesale'];
      }
    }
  }

  if (!processed) {
    filters.push(defaultFilter);
  }

  return filters;
};

export const TableCell = (intl, reload, { row, ...props }, forceReload, rows, table) => {
  const type = props.column.type || 'text';

  if (type === 'booking')
    return (
      <BookingCell
        row={row}
        openAddCustomerDialog={table.props.openAddCustomerDialog}
        onForceReload={table.props.onForceReload}
        {...props}
      />
    );
  if (type === 'paymentStatus') return <PaymentStatusCell row={row} {...props} />;
  if (type === 'paymentMethod') return <PaymentMethodCell row={row} {...props} />;
  if (type === 'paymentReceived') return <PaymentReceivedCell row={row} {...props} />;
  if (type === 'shipping') return <ShippingCell row={row} {...props} />;

  return <Table.Cell {...props} />;
};

/**
 * Converts JSON to CSV and forces file download
 * @param {object} json
 * @param {string} title
 * @param {bool} label
 * @param {array} excludeColumns
 * @param {array} columns
 */
export const jsonToCsv = (json, title, label, excludeColumns, columns) => {
  let arrData = typeof json !== 'object' ? JSON.parse(json) : json;
  let csv = '';

  // This condition will generate the Label/Header
  if (label) {
    let row = '';

    for (let i = 0; i < columns.length; i++) {
      if (columns.hasOwnProperty(i)) {
        for (let index in arrData[0]) {
          if (
            arrData[0].hasOwnProperty(index) &&
            excludeColumns.indexOf(index) === -1 &&
            excludeColumns.indexOf(columns[i].originalName) === -1 &&
            excludeColumns.indexOf(columns[i].name) === -1
          ) {
            if (columns[i].name === index || columns[i].originalName === index) {
              let name = columns[i].title;
              row += name + ',';
              break;
            }
          }
        }
      }
    }

    row = row.slice(0, -1);
    csv += row + '\r\n';
  }

  let formatter = {
    total: (row) => toPaper(row.total),
    subtotal: (row) => toPaper(row.subtotal),
    price: (row) => toPaper(row.price),
    createdAt: (row) =>
      row.createdAt
        ? moment
            .parseZone(row.createdAt)
            .format(process.env.REACT_APP_DATE_FORMAT_WITH_SHORT_CALENDAR_DAY_WITH_TIME_LOCALIZED)
        : 'Not set',
    'shipping.type': (row) => row.shipping.type,
    paymentMethod: (row) => (row.payment && row.payment.method ? row.payment.method.method : '-'),
    payment: (row) => (row.payment && row.payment.method ? row.payment.method.method : '-'),
  };

  // 1st loop is to extract each row
  for (let i = 0; i < arrData.length; i++) {
    let row = '';

    // 2nd loop will extract each column and convert it in string comma-separated

    for (let j = 0; j < columns.length; j++) {
      if (columns.hasOwnProperty(j)) {
        for (let index in arrData[i]) {
          if (
            arrData[i].hasOwnProperty(index) &&
            excludeColumns.indexOf(index) === -1 &&
            excludeColumns.indexOf(columns[j].originalName) === -1 &&
            excludeColumns.indexOf(columns[j].name) === -1
          ) {
            if (columns[j].name === index || columns[j].originalName === index) {
              if (Object.keys(formatter).indexOf(index) !== -1) {
                row += '"' + formatter[index](arrData[i]) + '",';
              } else if (columns[j].getExportCellValue) {
                row += '"' + columns[j].getExportCellValue(arrData[i], columns[j]) + '",';
              } else if (columns[j].getCellValue) {
                row += '"' + columns[j].getCellValue(arrData[i]) + '",';
              } else {
                if (typeof arrData[i][index] === 'object') {
                  row += '"object",';
                } else {
                  row += '"' + arrData[i][index] + '",';
                }
              }

              break;
            }
          }
        }
      }
    }

    row.slice(0, row.length - 1);
    csv += row + '\r\n';
  }

  if (csv === '') {
    alert('Invalid data');
    return;
  }

  //Generate a file name
  let fileName = 'Export_';
  fileName += title.replace(/( — | - )/g, ' ').replace(/ /g, '_');
  let uri = 'data:text/csv;charset=utf-8,' + escape(csv);
  let link = document.createElement('a');
  link.href = uri;
  link.style = 'visibility:hidden';
  link.download = fileName + '.csv';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
