import {COLORS} from "../../../helpers";

export default ({
  wrapper: {
  },
  name: {
    fontSize: 18,
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.text,
    marginBottom: 40
  },
  input: {
    marginBottom: 25,
  },
  label: {
    fontSize: 14,
  },
  title: {
    fontSize: 18,
    textTransform: 'capitalize'
  },
  inputText: {
    textAlign: 'right'
  },
  button: {
    minWidth: 330,
    alignSelf: 'center',
    textTransform: 'uppercase',
  },
  controls: {
    margin: '27px 0 0 0',
    textAlign: 'center'
  },
  control: {
    padding: '0 105px'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: 107,
    marginLeft: 107
  },
  column: {
    flex: 1,
  },
  message: {
    textAlign: 'center',
    fontSize: 18,
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.text,
  },
  back: {
    position: 'absolute',
    top: 21,
    left: 26,
    zIndex: 1000,
    cursor: 'pointer',
    fill: 'rgba(0, 0, 0, 0.5)'
  },
});
