import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { toCurrency } from '../../../../helpers/index';

export const cellRenderer = (intl, reload, { row, ...props }) => {
  if (props.column.name === 'customer' && row.rowType === 'csa') {
    return <Table.Cell {...props}>{row.meta.prepurchase.customer.name}</Table.Cell>;
  }

  if (props.column.name === 'customer') {
    return <Table.Cell {...props}>&nbsp;</Table.Cell>;
  }

  if (props.column.name === 'csa.name' && row.rowType === 'csa') {
    return <Table.Cell {...props}>{row.meta.prepurchase.name}</Table.Cell>;
  }

  if (props.column.name === 'csa.amount' && row.rowType === 'csa') {
    return (
      <Table.Cell {...props} style={{ fontWeight: 'bold' }}>
        {toCurrency(row.meta.prepurchase.value, intl)}
      </Table.Cell>
    );
  }

  if (props.column.name === 'csa.balance' && row.rowType === 'csa') {
    return (
      <Table.Cell {...props} style={{ fontWeight: 'bold' }}>
        {toCurrency(row.meta.prepurchase.availableValue, intl)}
      </Table.Cell>
    );
  }

  if (props.column.name === 'total' && row.rowType === 'csa') {
    return (
      <Table.Cell {...props} style={{ fontWeight: 'bold' }}>
        {toCurrency(row.meta.used, intl)}
      </Table.Cell>
    );
  }

  if (props.column.name === 'csa.amount' && row.rowType === 'order') {
    return (
      <Table.Cell {...props} style={{ fontWeight: 'bold' }}>
        {row?.name || ''}
      </Table.Cell>
    );
  }

  if (props.column.name === 'total' && row.rowType === 'order') {
    return (
      <Table.Cell {...props} style={{ fontWeight: 'bold' }}>
        {row.total}
      </Table.Cell>
    );
  }

  if (props.column.name === 'csa.balance' && row.rowType === 'lineItem') {
    return <Table.Cell {...props}>{row?.name || ''}</Table.Cell>;
  }

  if (props.column.name === 'total' && row.rowType === 'lineItem') {
    return <Table.Cell {...props}>{toCurrency(row.prepurchasedPrice, intl)}</Table.Cell>;
  }

  if (['ordered', 'packed'].indexOf(props.column.name) !== -1 && ['order', 'csa'].indexOf(row.rowType) !== -1) {
    return <Table.Cell {...props}>&nbsp;</Table.Cell>;
  }

  return <Table.Cell {...props} />;
};
