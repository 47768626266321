import {
  IMAGE_FILTER
} from '../FilterTypes'

export default class DXCSImageFilter {

  static getConfig(intl, values, readOnly, invisible) {
    return {
      name: IMAGE_FILTER,
      title: intl.formatMessage({id: 'filters.image'}),
      property: "image",
      type: IMAGE_FILTER,
      selectedValues: values,
      clientSide: true,
      readOnly: readOnly ? readOnly : false,
      invisible: invisible ? invisible : false,
    }
  }

  constructor(values, propertyName) {
    this.values = values;
    this.property = propertyName;
  }

  isSatisfy(row, intl) {
    let values = [];
    this.values.forEach(value => {
      if (value === intl.formatMessage({id: "filters.uploaded"})) {
        values.push(true);
      }
      if (value === intl.formatMessage({id: "filters.notUploaded"})) {
        values.push(false);
      }
    });

    return (
      values.length > 0 &&
      values.indexOf(Boolean(row[this.property])) !== -1
    );
  }

}