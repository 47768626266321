import React from 'react'
import Paper from '@material-ui/core/Paper'
import {withStyles} from '@material-ui/core/styles'

const styles = {
  wrapper: {
    position: 'relative',
    margin: 15,
    padding: 0,
    minHeight: 200,
  }
};

const AdminTabContent = (props) => {
  const {classes, children, ...other} = props;
  return (
    <Paper className={classes.wrapper} elevation={1} {...other}>
      {children}
    </Paper>
  );
};

export default withStyles(styles)(
  AdminTabContent
);