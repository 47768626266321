import PropTypes from "prop-types";
import {
  PAYMENT_TYPES,
  paymentMethodBrandToAbbr
} from "../../helpers";

const propTypes = {
  order: PropTypes.object.isRequired,
};

const PaymentMethod = ({ order }) => {
  let method = "";
  if (!order.payment.method) {
    return method;
  }

  if (order.payment.method.method === PAYMENT_TYPES.STRIPE_CARD ||
      order.payment.method.method === PAYMENT_TYPES.STRIPE_ACH) {
    method = paymentMethodBrandToAbbr(order.payment.method) + ' **** ' + order.payment.method.last4
  } else {
    method = order.payment.method.method;
  }
  return method;
};

PaymentMethod.propTypes = propTypes;

export default PaymentMethod;