/** Represents UI tab */
class SimpleTab {

  constructor(name, href) {
    this.name = name;
    this.href = href;
  }

}

export default SimpleTab