import React from 'react'
import PropTypes from 'prop-types'
import AutoComplete from './AutoComplete'
import {apiProductsSearch} from '../../api'

const propTypes = {
  retriever: PropTypes.func,
  selected: PropTypes.object,
  inputProps: PropTypes.object,
  onAttach: PropTypes.func,
  onDetach: PropTypes.func,
  filters: PropTypes.string,
  clearAfterSelect: PropTypes.bool,
};

const defaultProps = {
  clearAfterSelect: false,
};

const VariantsSuggestions = ({ onAttach,
                               onDetach,
                               labelExtractor,
                               inputLabel,
                               inputProps,
                               clearAfterSelect,
                               selected,
                               showVariants,
                               retriever }) =>  {
  return (
    <AutoComplete onAttach={onAttach}
                  onDetach={onDetach}
                  labelExtractor={labelExtractor}
                  inputLabel={inputLabel}
                  inputProps={inputProps}
                  retriever={retriever || apiProductsSearch}
                  showVariants={showVariants}
                  onChangeQuery={(query) => this.setState({query: query})}
                  clearAfterSelect={clearAfterSelect}
                  selected={selected}
    />
  );
};

VariantsSuggestions.propTypes = propTypes;
VariantsSuggestions.defaultProps = defaultProps;

export default VariantsSuggestions;