import React from 'react';
import {
  MuiThemeProvider,
  createTheme
} from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from "@material-ui/core/Checkbox";
import {Icon} from '../../components';


const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
});

const PrimaryCheckbox = (props) => {
  const {...other} = props;
  return (
    <MuiThemeProvider theme={theme} >
      <Checkbox icon={<Icon icon={CheckBoxOutlineBlankIcon} style={{fontSize: 28}} />}
             checkedIcon={<Icon icon={CheckBoxIcon} style={{fontSize: 28}} />}
             {...other}
      />
    </MuiThemeProvider>
  );
};

export default PrimaryCheckbox;
