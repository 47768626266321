import React from "react";
import {withStyles} from '@material-ui/core/styles';

const styles = ({
  wrapper: {
    padding: '100px 70px 57px'
  }
});

const Wrapper = ({ children,
                   classes }) => {
  return (
    <div className={classes.wrapper}>
      {children}
    </div>
  );
};

export default withStyles(styles)(
  Wrapper
);

