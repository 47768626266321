import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import ChipsSelect from '../../../../ChipsSelect/ChipsSelect';

const styles = {
  wrapper: {},
  chips: {
    display: 'block',
    width: '100%',
    margin: '20px 0 0 0',
    textAlign: 'left',
  },
  chip: {
    margin: 5,
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  selectFormControl: {
    marginBottom: 20,
  },
  column: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  mr20: {
    marginRight: 20,
  },
};

const propTypes = {
  title: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  multi: PropTypes.bool,
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  initialLimit: PropTypes.number,
};
const defaultProps = {
  selected: [],
  multi: false,
};

class DXSelectFilter extends React.Component {
  handleValueAdd = (selected) => {
    let copy = this.getCopy(selected);
    if (!Array.isArray(copy)) {
      copy = [copy];
    }
    this.props.onChange(copy);
  };

  getCopy = (object) => {
    return JSON.parse(JSON.stringify(object));
  };

  render() {
    const { selected, values, multi, title, initialLimit } = this.props;
    return (
      <ChipsSelect
        multiple={multi}
        onChange={this.handleValueAdd}
        label={title}
        selected={multi ? selected : selected.length ? selected[0] : ''}
        values={values}
        {...(!!initialLimit && values?.length > initialLimit
          ? {
              limited: values.slice(0, initialLimit),
            }
          : {})}
      />
    );
  }
}

DXSelectFilter.propTypes = propTypes;
DXSelectFilter.defaultProps = defaultProps;
DXSelectFilter.getLabel = (intl, localePrefix, selectedValues) => {
  let label = typeof selectedValue === 'string' ? selectedValues : '';
  if (Array.isArray(selectedValues) && selectedValues.length) {
    let parts = [];
    selectedValues.forEach((value) => {
      if (!!localePrefix) {
        parts.push(intl.formatMessage({ id: `${localePrefix}${value}` }));
      } else {
        parts.push(value);
      }
    });
    label = parts.join(', ');
  }
  return label;
};

export default injectIntl(withStyles(styles)(DXSelectFilter));
