const validation = {
  routingNumber: {
    presence: {
      message: '^validation.required'
    }
  },
  accountNumber: {
    presence: {
      message: '^validation.required'
    }
  },
  accountHolderName: {
    presence: {
      message: '^validation.required'
    }
  },
};

export default validation;
