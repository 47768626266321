import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {injectIntl} from 'react-intl'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'

const styles = theme => ({
  wrap: {
    padding: 10,
    width: 300,
    margin: '0 15px 15px 0',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});

class CustomerPaymentMethodBox extends Component {

  _renderCreditCard = () => {
    const { paymentMethod, intl } = this.props

    return (
      <div>
        <Typography component="h6" variant="subtitle1" style={{textAlign: 'center'}} >
          {intl.formatMessage({ id: 'customer.paymentMethod.creditCard' })}
        </Typography>
        <Typography component="span" variant="body1">
          {paymentMethod.brand}
        </Typography>
        <Typography component="span" variant="body1">
          **** **** **** {paymentMethod.last4}
        </Typography>
      </div>
    )
  };

  _renderBankAccount = () => {
    const { intl, paymentMethod } = this.props

    return (
      <div>
        <Typography component="h6" variant="subtitle1" style={{textAlign: 'center'}} >
          {intl.formatMessage({ id: 'customer.paymentMethod.bankAccount' })}
        </Typography>
        <Typography component="span" variant="body1">
          { paymentMethod.bankName }
        </Typography>
        <Typography component="span" variant="body1">
          **** **** **** {paymentMethod.last4}
        </Typography>
      </div>
    )
  };

  render () {
    const {
      intl,
      paymentMethod,
      onMakeDefault,
      onDelete,
      classes,
    } = this.props;

    return (
      <Paper className={classes.wrap}>
        <div>
          {
            paymentMethod.method === 'stripe_card'
              ? this._renderCreditCard()
              : this._renderBankAccount()
          }
          <Typography
            component="h6"
            variant="subtitle1"
            color='secondary'
            style={{opacity: paymentMethod.default ? 1 : 0}}
          >
            ({intl.formatMessage({ id: 'global.default' })})
          </Typography>
        </div>
        <div className={classes.actions}>
          {
            !paymentMethod.default && (
              <Button
                onClick={() => onMakeDefault(paymentMethod)}
                color='secondary'
              >
                { intl.formatMessage({ id: 'global.makeDefault' }) }
              </Button>
            )
          }
          <IconButton onClick={() => onDelete(paymentMethod)}>
            <DeleteIcon color='secondary' />
          </IconButton>
        </div>
      </Paper>
    )
  }
}

CustomerPaymentMethodBox.propTypes = {
  paymentMethod: PropTypes.object.isRequired,
  onMakeDefault: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default withStyles(styles, {withTheme: true})(injectIntl(CustomerPaymentMethodBox))
