import {
  DXCSCustomerApprovedFilter,
  DXCSCustomerAllowCreditFilter,
  DXCSCustomerDefaultPaymentMethodFilter,
  DXCSDateFilter,
  DXCSCustomerTypeFilter
} from "./";


export default class DXFiltersFactory {

  static getFilterByName(filter) {
    const FILTERS = {
      "CUSTOMER_APPROVED_FILTER": DXCSCustomerApprovedFilter,
      "CUSTOMER_ALLOW_CREDIT_FILTER": DXCSCustomerAllowCreditFilter,
      "CUSTOMER_DEFAULT_PAYMENT_METHOD_FILTER": DXCSCustomerDefaultPaymentMethodFilter,
      "DATE_FILTER": DXCSDateFilter,
      "CUSTOMER_TYPE_FILTER": DXCSCustomerTypeFilter,
    };

    if (!FILTERS.hasOwnProperty(filter.type)) {
      throw new Error(`Filter with name ${filter.name} not exists!`)
    }

    return new FILTERS[filter.name](filter.selectedValues, filter.property);
  }
}
