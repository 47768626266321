import React from 'react';
import { withRouter } from '../../hocs';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { withStyles } from '@material-ui/core/styles';
import {
  AdminDXTable,
  AdminLayout,
  AdminTabContent,
  AdminTabContentHeader,
  AdminTabContentTitle,
  AdminTabs,
  Spinner,
} from '../../components';
import { SidebarMenu, HeaderMenu } from '../Partials';
import { apiProducerPayoutsGetOne } from '../../api';
import { getGridPageSize, getGridPageSizes } from '../../helpers/grid';
import styles from './styles';
import { weightToUser, numberWithSeparator } from '../../helpers';

class PayoutScene extends React.Component {
  constructor(props) {
    super(props);
    const { intl } = this.props;

    this.table = null;
    this.deductedOrdersTable = null;

    this.state = {
      tab: 0,
      payoutInfo: false,
      sorting: [{ columnName: 'orders', direction: 'desc' }],
      deductedSorting: [{ columnName: 'orders', direction: 'desc' }],
      error: false,
    };

    this.columns = [
      {
        name: 'orders',
        title: intl.formatMessage({ id: 'global.orderNumber' }),
        getCellValue: (row) => row.name || '-',
      },
      {
        name: 'orders.creationDate',
        title: intl.formatMessage({ id: 'global.creationDate' }),
        getCellValue: (row) => row.createdAt || '-',
      },
      {
        name: 'orders.shipping.date',
        title: intl.formatMessage({ id: 'global.shippingDate' }),
        getCellValue: (row) => (row.shipping && row.shipping.date) || '-',
      },
      {
        name: 'orders.payment.initiatedAt',
        title: intl.formatMessage({ id: 'global.paymentInitiated' }),
        getCellValue: (row) => (row.payment && row.payment.initiatedAt) || '-',
      },
      {
        name: 'orders.payment.balanceAvailableOn',
        title: intl.formatMessage({ id: 'global.balanceAvailableOn' }),
        getCellValue: (row) => (row.payment && row.payment.balanceAvailableOn) || '-',
      },
      {
        name: 'orders.payment.total',
        title: intl.formatMessage({ id: 'global.orderTotal' }),
        getCellValue: (row) => (row.payment && row.payment.total ? row.payment.total : '-'),
      },
      {
        name: 'orders.payment.systemTransferFee',
        title: intl.formatMessage({ id: 'global.systemFee' }),
        getCellValue: (row) => (row.payment && row.payment.systemTransferFee ? row.payment.systemTransferFee : '0'),
      },
      {
        name: 'orders.refundAmount',
        title: intl.formatMessage({ id: 'global.refundAmount' }),
        getCellValue: (row) => (row.refundAmount !== undefined ? row.refundAmount : '-'),
      },
      {
        name: 'netAmount',
        title: intl.formatMessage({ id: 'global.netAmount' }),
        getCellValue: (row) => (row.netAmount ? row.netAmount : '-'),
      },
    ];
  }

  componentDidMount() {
    const {
      props: { match },
    } = this;

    apiProducerPayoutsGetOne(
      match.params.id,
      (response) => {
        this.onPayoutInfoReceived(response);
      },
      (error) => {
        this.setState({ error });
      }
    );
  }

  onError = () => {
    return this.state.error;
  };

  onChangeSorting = (sorting) => {
    this.setState({ sorting });
  };

  onChangeDeductedSorting = (sorting) => {
    this.setState({ sorting });
  };

  onPayoutInfoReceived = (payoutInfo) => {
    this.setState({
      payoutInfo,
    });
  };

  render() {
    const {
      props: { intl, classes },
      state: { sorting, deductedSorting, payoutInfo },
    } = this;

    return (
      <AdminLayout sidebarComponent={SidebarMenu} headerMenuComponent={HeaderMenu}>
        <AdminTabs
          onChangeTab={() => {}}
          tab={this.state.tab}
          tabs={[`${intl.formatMessage({ id: 'customer.tabs.all' })}`]}>
          {!payoutInfo && <Spinner />}

          <AdminTabContent>
            <AdminTabContentHeader>
              <AdminTabContentTitle>
                {payoutInfo && (
                  <>
                    <FormattedMessage
                      id="global.payoutInfoFor"
                      values={{ payout: payoutInfo.statementDescriptor || '' }}
                    />
                    <br />
                    <span className={classes.subTittle}>
                      <FormattedMessage
                        id="global.payoutTotalPaidOut"
                        values={{ total: numberWithSeparator(weightToUser(payoutInfo.total)) || '' }}
                      />
                    </span>
                  </>
                )}
              </AdminTabContentTitle>
            </AdminTabContentHeader>

            {payoutInfo && (
              <>
                <div className={classes.tableTittleWrapper}>
                  <div className={classes.tableTitleText}>
                    <FormattedMessage id="global.payoutOrdersTableTittle" />
                  </div>
                </div>
                <AdminDXTable
                  mmmDYYYDateColumnNames={[
                    'orders.creationDate',
                    'orders.shipping.date',
                    'orders.payment.initiatedAt',
                    'orders.payment.balanceAvailableOn',
                  ]}
                  enableSearch={false}
                  onRef={(table) => (this.table = table)}
                  cellRenderer={(reload, { row, ...props }) => {
                    return <Table.Cell {...props} />;
                  }}
                  apiRetrieve={(filters, onSuccess) => {
                    onSuccess(payoutInfo.orders);
                  }}
                  columnExtensions={[
                    { columnName: 'netAmount', align: 'right' },
                    { columnName: 'orders.refundAmount', align: 'right' },
                    { columnName: 'orders.payment.total', align: 'right' },
                    { columnName: 'orders.payment.systemTransferFee', align: 'right' },
                  ]}
                  onChangeSorting={this.onChangeSorting}
                  sorting={sorting}
                  pageSize={getGridPageSize()}
                  pageSizes={getGridPageSizes()}
                  columns={this.columns}
                  quantityWithSeparatorColumnNames={[
                    'netAmount',
                    'orders.refundAmount',
                    'orders.payment.total',
                    'orders.payment.system',
                    'orders.payment.systemTransferFee',
                  ]}
                  totalSummaryItems={[
                    { columnName: 'netAmount', type: 'sum' },
                    { columnName: 'orders.refundAmount', type: 'sum' },
                    { columnName: 'orders.payment.systemTransferFee', type: 'sum' },
                    { columnName: 'orders.payment.total', type: 'sum' },
                  ]}
                />
              </>
            )}

            {payoutInfo && !!payoutInfo.deductedOrders.length && (
              <>
                <div className={classes.tableTittleWrapper}>
                  <div className={classes.tableTitleText}>
                    <FormattedMessage id="global.payoutDeductedOrdersTableTittle" />
                  </div>
                </div>
                <AdminDXTable
                  style={{ '.MuiToolbar-regular': { display: 'none' } }}
                  mmmDYYYDateColumnNames={[
                    'orders.creationDate',
                    'orders.shipping.date',
                    'orders.payment.initiatedAt',
                    'orders.payment.balanceAvailableOn',
                  ]}
                  enableSearch={false}
                  onRef={(table) => (this.deductedOrdersTable = table)}
                  cellRenderer={(reload, { row, ...props }) => {
                    return <Table.Cell {...props} />;
                  }}
                  apiRetrieve={(filters, onSuccess) => {
                    onSuccess(payoutInfo.deductedOrders);
                  }}
                  columnExtensions={[
                    { columnName: 'netAmount', align: 'right' },
                    { columnName: 'orders.refundAmount', align: 'right' },
                    { columnName: 'orders.payment.total', align: 'right' },
                    { columnName: 'orders.payment.systemTransferFee', align: 'right' },
                  ]}
                  onChangeSorting={this.onChangeDeductedSorting}
                  sorting={deductedSorting}
                  pageSize={getGridPageSize()}
                  pageSizes={getGridPageSizes()}
                  columns={this.columns}
                  quantityColumnNames={[
                    'netAmount',
                    'orders.refundAmount',
                    'orders.payment.total',
                    'orders.payment.systemTransferFee',
                  ]}
                  totalSummaryItems={[
                    { columnName: 'netAmount', type: 'sum' },
                    { columnName: 'orders.refundAmount', type: 'sum' },
                    { columnName: 'orders.payment.systemTransferFee', type: 'sum' },
                    { columnName: 'orders.payment.total', type: 'sum' },
                  ]}
                />
              </>
            )}
          </AdminTabContent>
        </AdminTabs>
      </AdminLayout>
    );
  }
}

export default injectIntl(withRouter(withStyles(styles)(PayoutScene)));
