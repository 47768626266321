import React, { useEffect, useState } from 'react';
import { Title, HeaderArea, Footer, LocationsViewComponent, FewButtonsComponent } from '../../components';
import { makeStyles } from '@material-ui/core/styles';
import { LOCATIONS_VIEW_TYPES, ORDER_DATE_FORMAT, PADDING_CONTENT, PADDING_CONTENT_TOP } from '../../constants/globals';
import { useIntl } from 'react-intl';
import { FONT_CART_VARIANT_TITLE, FONT_SUB_TITLE } from '../../constants/fonts';
import { COLORS } from '../../constants';
import { apiGetPickupsLocations } from '../../api';
import moment from 'moment';
import { withRouter } from '../../hocs';
import { useDispatch, useSelector } from 'react-redux';
import { setPickupsLoading } from '../../store/actions';
import { getObjectFromStorage, saveObjectToStorage } from '../../helpers';
import LocationsSkeleton from '../../components/SkeletonTemplates/locationsSkeleton';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  content: {
    paddingLeft: PADDING_CONTENT,
    paddingRight: PADDING_CONTENT,
    paddingTop: PADDING_CONTENT_TOP,
  },
  title: {
    marginBottom: 10,
  },
  subTitle: {
    ...FONT_SUB_TITLE,
    marginBottom: 20,
  },
  buttonWrapper: {
    marginBottom: 20,
    paddingLeft: PADDING_CONTENT,
    paddingRight: PADDING_CONTENT,
  },
  error: {
    ...FONT_CART_VARIANT_TITLE,
    color: COLORS.danger,
    paddingBottom: 20,
    paddingTop: 20,
  },
  viewSwitcherWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
  },
  viewSwitcher: {
    marginRight: 20,
  },
  viewSwitcherActive: {
    backgroundColor: COLORS.primary,
    marginRight: 20,
    '&:hover': {
      backgroundColor: COLORS.primary,
      opacity: 0.8,
    },
  },
}));

const LocationsScene = (props) => {
  const intl = useIntl();
  const classes = useStyles();
  const { day } = props.params;

  const [locations, setLocations] = useState([]);
  const [locationsViewType, setLocationsViewType] = useState(
    getObjectFromStorage('pickupsApp:locationsViewType') || LOCATIONS_VIEW_TYPES.LOCATIONS
  );
  const isLiteView = locationsViewType === LOCATIONS_VIEW_TYPES.LOCATIONS;
  const [error, setError] = useState('');
  const [initialLoading, setInitialLoading] = useState(true);
  const dispatch = useDispatch();
  const onLoading = (isLoading) => dispatch(setPickupsLoading(isLoading));
  const { loading } = useSelector((state) => {
    return {
      loading: state.pickupsApp.loading,
    };
  });

  useEffect(
    () => {
      onLoading(false);
      getLocations();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locationsViewType]
  );

  const onSetLocationViewType = (viewType) => {
    if (viewType !== locationsViewType) {
      setInitialLoading(true);
      setLocations([]);
    }
    saveObjectToStorage('pickupsApp:locationsViewType', viewType);
    setLocationsViewType(viewType);
  };

  const getLocations = () => {
    setInitialLoading(true);
    setLocations([]);
    const params = {
      limit: 1000,
      'shipping:from': moment(day).startOf('day').toISOString(true),
      'shipping:to': moment(day).endOf('day').toISOString(true),
      sort: ['customer.companyName:asc', 'customer.firstName:asc'],
    };

    if (!isLiteView) {
      params['withOrders'] = true;
      params['limit'] = 1000;
    }

    apiGetPickupsLocations(
      params,
      (response) => {
        setLocations(response);
        setInitialLoading(false);
      },
      (error) => {
        setInitialLoading(false);
        setError(error?.response?.data?.message || intl.formatMessage({ id: 'pickups.somethingWentWrong' }));
      }
    );
  };

  const drawDate = (date) => {
    return moment.parseZone(date).format(ORDER_DATE_FORMAT);
  };

  if (loading) {
    return null;
  }

  return (
    <div className={classes.root}>
      <HeaderArea
        onlyBackButton
        rightSideContent={() => {
          return (
            <FewButtonsComponent
              buttons={[
                {
                  label: intl.formatMessage({ id: 'pickups.locations.liteView' }),
                  action: () => onSetLocationViewType(LOCATIONS_VIEW_TYPES.LOCATIONS),
                  isActive: isLiteView,
                },
                {
                  label: intl.formatMessage({ id: 'pickups.locations.treeView' }),
                  action: () => onSetLocationViewType(LOCATIONS_VIEW_TYPES.NESTED_ORDERS_LINE_ITEMS),
                  isActive: !isLiteView,
                },
              ]}
            />
          );
        }}
      />

      {!initialLoading ? (
        <div className={classes.content}>
          <Title className={classes.title} text={intl.formatMessage({ id: 'pickups.locations.title' })} />
          <p className={classes.subTitle}>{drawDate(day)}</p>

          {!!error && <p className={classes.error}>{error}</p>}
          {!error && <LocationsViewComponent locations={locations} day={day} locationsViewType={locationsViewType} />}
        </div>
      ) : (
        <LocationsSkeleton isLiteView={isLiteView} />
      )}
      <Footer />
    </div>
  );
};

export default withRouter(LocationsScene);
