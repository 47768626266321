export default ({
  wrapper: {
  },
  controls: {
    margin: '27px 0 0 0',
    textAlign: 'center'
  },
  input: {
    marginBottom: 20
  },
  control: {
    padding: '0 105px'
  },
  close: {
    position: 'absolute',
    top: 21,
    right: 26,
    zIndex: 1000,
    cursor: 'pointer'
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  column: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  mr40: {
    marginRight: 40
  },
});
