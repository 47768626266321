import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Chip from '@material-ui/core/Chip';
import DXFilterConfig from '../DXFilterConfig';
import constants from '../../constants';
import { PrimaryChip } from '../../../../index';

const styles = {
  wrapper: {},
  chips: {
    display: 'block',
    width: '100%',
    margin: '20px 0 0 0',
    textAlign: 'left',
  },
  chip: {
    margin: 5,
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  mr20: {
    marginRight: 20,
  },
};

const propTypes = {
  shared: PropTypes.object,
  selected: PropTypes.object,
  equality: PropTypes.string,
  producer: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
const defaultProps = {
  selected: {},
};

class DXTagCSAFilter extends React.Component {
  handleValueAdd = (tag) => {
    this.props.onChange([tag]);
  };

  handleValueDelete = (id) => {
    let customers = this.getValuesCopy();
    delete customers[id];
    this.props.onChange(customers);
  };

  getValuesCopy = () => {
    let copy = {};
    Object.keys(this.props.selected).forEach((key) => {
      copy[key] = this.props.selected[key];
    });
    return copy;
  };

  getValue = (id) => {
    return this.props.selected[id];
  };

  getValuesView = () => {
    const { classes, selected } = this.props;
    const keys = Object.keys(selected || {});

    return (
      <React.Fragment>
        {!!keys.length && (
          <div className={classes.chips}>
            {keys.map((id) => (
              <Chip
                key={id}
                label={this.getValue(id)}
                className={classes.chip}
                onDelete={() => this.handleValueDelete(id)}
              />
            ))}
          </div>
        )}
      </React.Fragment>
    );
  };

  drawTagsSelector = () => {
    const { intl, classes } = this.props;

    return (
      <PrimaryChip
        className={classes.textField}
        fullWidth
        value={[]}
        blurBehavior={'add'}
        onUpdateInput={(e) => {
          this.props.onChange([e.target.value]);
        }}
        onAdd={(chip) => {
          this.handleValueAdd(chip);
        }}
        onDelete={() => {
          this.handleValueAdd([]);
        }}
        label={intl.formatMessage({ id: 'filter.csaTag' })}
        InputLabelProps={{ shrink: true }}
      />
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div className={classes.row}>
          <div className={classes.column}>
            <div className={classes.wrapper}>
              <div className={classes.mr20}>{this.drawTagsSelector()}</div>
            </div>
          </div>
          <div className={classes.column} />
        </div>
        {this.getValuesView()}
      </React.Fragment>
    );
  }
}

DXTagCSAFilter.propTypes = propTypes;
DXTagCSAFilter.defaultProps = defaultProps;
DXTagCSAFilter.getLabel = (intl, selectedValues) => {
  let values = [];
  for (let key in selectedValues) {
    if (selectedValues.hasOwnProperty(key)) {
      values.push(selectedValues[key]);
    }
  }
  return values.join(', ');
};
DXTagCSAFilter.applyToQueryParams = (queryParams, selectedValues) => {
  queryParams['tag'] = selectedValues[0];

  return queryParams;
};
DXTagCSAFilter.getConfig = (intl, equality) => {
  return new DXFilterConfig({
    name: 'csaTag',
    selected: {},
    property: 'csaTag',
    title:
      equality === constants.FILTER_EQUALITY_NOT_EQUALS
        ? intl.formatMessage({ id: 'filter.notEqualsCsaTag' })
        : intl.formatMessage({ id: 'filter.csaTag' }),
    disabled: false,
    equalityStatus: false,
    selectedEquality: equality,
  });
};

export default injectIntl(withStyles(styles)(DXTagCSAFilter));
