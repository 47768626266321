export default {
  requestError: {
    backgroundColor: 'red',
    color: '#ffffff',
    marginBottom: 20,
    marginLeft: 20,
    marginRight: 20
  },
  controls: {
    textAlign: 'center',
    margin: "40px 0 40px 0",
  },
  button: {
    width: '100%',
    height: 43,
    textTransform: 'none'
  },
  controlsCentered: {
    textAlign: 'center',
    margin: "0 0 50px 0",
  },
  finalMessageContainer: {
    marginTop: 30,
    textAlign: 'center'
  },
  mra: {
    marginRight: 'auto'
  },
  mla: {
    marginLeft: 'auto'
  },

  envelope: {
    width: 60,
    height: 60,
  },
  bmr: {
    marginRight: 20,
  },
  textWrapper: {
    marginTop: 40,
    marginBottom: 40,
    maxWidth: 500
  }
};