import { apiAllSubscriptionsRetrieve, apiAllSubscriptionsGroupedBySubTypeRetrieve } from '../../api';
import { toPaper } from '../../helpers';
import moment from 'moment';
import { getCustomerName } from '../../helpers/customer';

export const SUBSCRIPTIONS_VIEW_TYPES = {
  DEFAULT: 'default',
  GROUPED_BY_SUB_TYPE: 'groupedBySubType',
  LOCATION: 'location',
};

const exportCellValues = {
  customerFullName: (row) => (row.customer ? getCustomerName(row.customer) : '-'),
  amount: (row) => toPaper(row.amount),
  tags: (row) => {
    return !!row?.tags?.length ? row.tags.join(', ') : '-';
  },

  nextDate: (row) =>
    row.nextDate ? moment.parseZone(row.nextDate).format(process.env.REACT_APP_DATE_FORMAT_WITH_FULL_DAY_OF_WEEK) : '-',
  lastOrderDate: (row) =>
    row.lastOrderDate
      ? moment.parseZone(row.lastOrderDate).format(process.env.REACT_APP_DATE_FORMAT_WITH_FULL_DAY_OF_WEEK)
      : '-',
};

function standard(intl, withoutColumns = []) {
  return {
    key: SUBSCRIPTIONS_VIEW_TYPES.DEFAULT,
    columnExtensions: [],
    apiRetrieve: apiAllSubscriptionsRetrieve,
    columns: [
      { name: 'name', title: intl.formatMessage({ id: 'subscriptions.tableFields.name' }) },
      {
        name: 'customerName',
        originalName: 'customer',
        title: intl.formatMessage({ id: 'subscriptions.tableFields.customer' }),
        getExportCellValue: exportCellValues.customerFullName,
        getCellValue: (row) => (row.customer ? getCustomerName(row.customer) : '-'),
      },
      {
        name: 'email',
        originalName: 'customer',
        title: intl.formatMessage({ id: 'subscriptions.tableFields.customerEmail' }),
        getCellValue: (row) => (!!row?.customer?.email ? row.customer.email : '-'),
      },
      {
        name: 'amount',
        title: intl.formatMessage({ id: 'subscriptions.tableFields.amount' }),
        getExportCellValue: exportCellValues.amount,
      },
      {
        name: 'variants',
        title: intl.formatMessage({ id: 'subscriptions.tableFields.products' }),
        getCellValue: (row) => row?.variants?.length || '-',
      },
      {
        name: 'shippingType',
        title: intl.formatMessage({ id: 'subscriptions.tableFields.shippingType' }),
        getCellValue: (row) => intl.formatMessage({ id: `global.${row?.shippingType}` }) || '-',
      },
      {
        name: 'period',
        title: intl.formatMessage({ id: 'subscriptions.tableFields.period' }),
        getExportCellValue: (row) => intl.formatMessage({ id: `global.${row.period.type}` }),
      },
      {
        name: 'type',
        title: intl.formatMessage({ id: 'subscriptions.tableFields.paymentType' }),
        getExportCellValue: (row) => intl.formatMessage({ id: `global.${row.period.type}` }),
      },
      {
        name: 'weekday',
        originalName: 'period',
        title: intl.formatMessage({ id: 'subscriptions.tableFields.weekday' }),
        getExportCellValue: (row) => intl.formatMessage({ id: `global.day.${row.period.weekday}` }),
      },
      {
        name: 'tags',
        originalName: 'tags',
        title: intl.formatMessage({ id: 'subscriptions.tableFields.tags' }),
        getExportCellValue: exportCellValues.tags,
      },
      {
        name: 'location',
        originalName: 'market',
        title: intl.formatMessage({ id: 'subscriptions.tableFields.locationName' }),
        getCellValue: (row) => row?.market?.name || intl.formatMessage({ id: 'dialog.groupBy.noLocation' }),
      },
      {
        name: 'nextDate',
        title: intl.formatMessage({ id: 'subscription.column.nextOrder' }),
        getExportCellValue: exportCellValues.nextDate,
      },
      {
        name: 'lastOrderDate',
        title: intl.formatMessage({ id: 'subscription.column.lastOrder' }),
        getExportCellValue: exportCellValues.lastOrderDate,
        getCellValue: (row) =>
          row.lastOrderDate
            ? moment.parseZone(row.lastOrderDate).format(process.env.REACT_APP_DATE_FORMAT_WITH_FULL_DAY_OF_WEEK)
            : '-',
      },
      {
        name: 'active',
        title: intl.formatMessage({ id: 'prepurchases.column.active' }),
        type: 'active',
        getExportCellValue: (row) => intl.formatMessage({ id: `exportCellValue.boolean.${row.active}` }),
      },
    ].filter((column) => !withoutColumns.includes(column.name)),
  };
}

export default (intl) => {
  return {
    default: standard(intl),
    location: standard(intl),
    groupedBySubType: {
      key: SUBSCRIPTIONS_VIEW_TYPES.GROUPED_BY_SUB_TYPE,
      columnExtensions: [],
      apiRetrieve: apiAllSubscriptionsGroupedBySubTypeRetrieve,
      groupingColumns: [
        { columnName: 'productType' },
        { columnName: 'productSubType' },
        { columnName: 'productName' },
        { columnName: 'productVariant' },
      ],
      // Add standard columns with additional for groupedBySubType
      columns: [
        ...standard(intl, ['variants']).columns,

        {
          name: 'productType',
          title: intl.formatMessage({ id: 'subscriptions.tableFields.productType' }),
          getCellValue: (row) => (!!row?.productType?.name ? row?.productType?.name : '-'),
        },
        {
          name: 'productSubType',
          title: intl.formatMessage({ id: 'subscriptions.tableFields.productSubType' }),
          getCellValue: (row) => (!!row?.productSubType?.name ? row?.productSubType?.name : '-'),
        },
        {
          name: 'productName',
          title: intl.formatMessage({ id: 'subscriptions.tableFields.productName' }),
          getCellValue: (row) => (!!row?.variant?.productName ? row?.variant?.productName : '-'),
        },
        {
          name: 'productVariant',
          title: intl.formatMessage({ id: 'subscriptions.tableFields.productVariant' }),
          getCellValue: (row) => (!!row?.variant?.variantName ? row?.variant?.variantName : '-'),
        },
      ],
    },
  };
};
