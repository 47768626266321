class Stripe {

	static ON_BOARDING_CUSTOM_ACCOUNT_VERIFICATION = 'custom_account_verification';
	static ON_BOARDING_CUSTOM_ACCOUNT_UPDATE = 'custom_account_update';
	static ON_BOARDING_FAILURE = 'failure';
	static ON_BOARDING_SUCCESS = 'success';

	static getOnBoardingType(settings) {
		return settings.stripeAccountExist
			? Stripe.ON_BOARDING_CUSTOM_ACCOUNT_UPDATE
			: Stripe.ON_BOARDING_CUSTOM_ACCOUNT_VERIFICATION
	}

	static exists(settings) {
		return (
			settings &&
			settings.stripeAccountExist
		);
	}

	static isBoardingStatusFailure(status) {
		return status === Stripe.ON_BOARDING_FAILURE;
	}

	static isBoardingStatusSuccess(status) {
		return status === Stripe.ON_BOARDING_SUCCESS;
	}

	static requireUpdate(settings) {
		return (
			settings &&
			settings.stripeAccountExist &&
			settings.requirements &&
			settings.requirements.eventually_due &&
			Array.isArray(settings.requirements.eventually_due) &&
			!!settings.requirements.eventually_due.length
		);
	}

	static getOnBoardingSuccessUrl() {
		return process.env.REACT_APP_URL + `settings?stripe_boarding_status=success#payment`;
	}

	static getOnBoardingFailureUrl() {
		return process.env.REACT_APP_URL + `settings?stripe_boarding_status=failure#payment`;
	}

}

export default Stripe
