import axios from 'axios';
import { getRequestConfig, REQUEST_TYPES, replaceUrlPlaceholder, replaceUrlPlaceholders } from '../request';
import API_END_POINTS from '../endpoints';

/**
 * fetch all producer subscriptions
 * @param {object} filters
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiAllSubscriptionsRetrieve = function (filters, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.GET_ALL_SUBSCRIPTIONS);

  config.params = filters;

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * fetch all producer subscriptions grouped by items
 * @param {object} filters
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiAllSubscriptionsGroupedBySubTypeRetrieve = function (filters, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.GET_ALL_SUBSCRIPTIONS_GROUPS);

  config.params = filters;

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * fetch single producer subscriptions
 * @param {string} sbs
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiGetSubscriptionById = function (sbs, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.GET_SUBSCRIPTION_BY_ID, 'sbs', sbs);
  const config = getRequestConfig(REQUEST_TYPES.GET, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * fetch single subscription schedule
 * @param {string} sbs
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiGetSubscriptionSchedule = function (sbs, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.GET_SUBSCRIPTIONS_SCHEDULE, 'sbs', sbs);

  const config = getRequestConfig(REQUEST_TYPES.GET, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Change subscription single schedule date to a new one
 * @param {string} sbs
 * @param {object} data
 * @param {string} data.fromDate - required
 * @param {string} data.toDate - required
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiChangeSubscriptionScheduleDateById = function (sbs, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.CHANGE_SUBSCRIPTIONS_SCHEDULE_DATE, 'sbs', sbs);
  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Move all next subscription schedule date to fixed period
 * @param {string} sbs
 * @param {object} data
 * @param {string} data.fromDate - required
 * @param {string} data.toDate - required
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiMoveSeriesSubscriptionScheduleDatesById = function (sbs, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.MOVE_SERIES_SUBSCRIPTIONS_SCHEDULE_DATES, 'sbs', sbs);
  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * remove subscription schedule date
 * @param {string} sbs
 * @param {object} data
 * @param {string} data.date - required
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDeleteSubscriptionScheduleDateById = function (sbs, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.REMOVE_SINGLE_SUBSCRIPTIONS_SCHEDULE_DATE, 'sbs', sbs);
  const config = getRequestConfig(REQUEST_TYPES.DEL, url);
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Change single subscription schedule date for line item
 * @param {string} subscriptionId
 * @param {string} lineItemId
 * @param {object} data
 * @param {string} data.fromDate - required
 * @param {string} data.toDate - required
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiChangeSubscriptionLineItemSchedule = function (subscriptionId, lineItemId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.CHANGE_SUBSCRIPTIONS_SCHEDULE_DATE_FOR_LINE_ITEM, {
    sbs: subscriptionId,
    it: lineItemId,
  });
  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * move all subscription schedule date for line item
 * @param {string} subscriptionId
 * @param {string} lineItemId
 * @param {object} data
 * @param {string} data.fromDate - required
 * @param {string} data.toDate - required
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiMoveDateSeriesSubscriptionLineItemSchedule = function (
  subscriptionId,
  lineItemId,
  data,
  onSuccess,
  onError
) {
  const url = replaceUrlPlaceholders(API_END_POINTS.MOVE_SERIES_SUBSCRIPTIONS_SCHEDULE_DATE_FOR_LINE_ITEM, {
    sbs: subscriptionId,
    it: lineItemId,
  });
  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};
/**
 * Remove single subscription schedule date for line item
 * @param {string} subscriptionId
 * @param {string} lineItemId
 * @param {object} data
 * @param {string} data.date - required
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDeleteSingleSubscriptionLineItemScheduleDate = function (
  subscriptionId,
  lineItemId,
  data,
  onSuccess,
  onError
) {
  const url = replaceUrlPlaceholders(API_END_POINTS.REMOVE_SINGLE_SUBSCRIPTIONS_SCHEDULE_DATE_FOR_LINE_ITEM, {
    sbs: subscriptionId,
    it: lineItemId,
  });
  const config = getRequestConfig(REQUEST_TYPES.DEL, url);
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * create new producer subscriptions
 * @param {object} data
 * @param {} data.name - string (required)
 * @param {} data.amount - int (required)
 * @param {} data.shippingType - string (required) - pickup/delivery
 * @param {} data.period - string (required) - week/bi_week/month
 * @param {} data.weekday - string (required) - for week/bi_week period
 * @param {} data.day - int (required) - for month period
 * @param {} data.customerId - string (required)
 * @param {} data.locationId - string (required)
 * @param {} data.paymentMethodId - string (required)
 * @param {} data.validFrom - string (required)
 * @param {} data.variants - array (required) - variants.id & variants.amount keys
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiAddNewSubscription = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.CREATE_NEW_SUBSCRIPTION);

  config.data = data;

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * edit single producer subscriptions
 * @param {string} sbs - subscription id to remove
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiEditSubscriptionById = function (sbs, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.CHANGE_SUBSCRIPTION_BY_ID, 'sbs', sbs);
  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  config.data = data;

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * remove single producer subscriptions
 * @param {string} sbs - subscription id to remove
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiRemoveSubscriptionById = function (sbs, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.REMOVE_SUBSCRIPTION_BY_ID, 'sbs', sbs);

  const config = getRequestConfig(REQUEST_TYPES.DEL, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Create next subscription order
 * @param {string} id - subscription id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSubscriptionCreateNextOrder = function (id, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.SUBSCRIPTIONS_CREATE_NEXT_ORDER, 'sbs', id);
  const config = getRequestConfig(REQUEST_TYPES.POST, url);

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Add tags to selected subscriptions
 * @param {data} data
 * @param {array} data.subscriptionIds - (required)
 * @param {array} data.tags - (required)
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSubscriptionsBulkAddTags = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.SUBSCRIPTIONS_BULK_SET_TAGS);
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Move schedule date for all subscriptions
 * @param {object} data
 * @param {string} data.fromDate - required
 * @param {string} data.toDate - required
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiMoveAllSubscriptionsScheduleDate = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.MOVE_SCHEDULE_DATE_FOR_ALL_SUBSCRIPTIONS);
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Move schedule date for variant for all subscriptions
 * @param {string} variantId
 * @param {object} data
 * @param {string} data.fromDate - required
 * @param {string} data.toDate - required
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiMoveAllSubscriptionsScheduleDateForLineItem = function (variantId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(
    API_END_POINTS.MOVE_VARIANT_SCHEDULE_DATE_FOR_ALL_SUBSCRIPTIONS,
    'variantId',
    variantId
  );
  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Get subscription customer report
 * @param {object} params
 * @param {string} params.fromDate - required
 * @param {string} params.toDate - required
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiGetSubscriptionReportByCustomers = function (params, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.SUBSCRIPTIONS_REPORTS_SCHEDULE_BY_CUSTOMER);
  config.params = params;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Get subscription line items report
 * @param {object} params
 * @param {string} params.fromDate - required
 * @param {string} params.toDate - required
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiGetSubscriptionReportByLineItems = function (params, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.SUBSCRIPTIONS_REPORTS_SCHEDULE_BY_LINE_ITEM);
  config.params = params;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};
