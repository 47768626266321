import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import DialogContent from '@material-ui/core/DialogContent'
import {withStyles} from '@material-ui/core/styles'
import {
  ErrorBox,
  Spinner,
  PrimaryButton,
  PrimaryTextField,
} from '../../components'
import { validateField } from '../../helpers'
import validationRules from './validation'
import {
  apiProducerAccountsPatch,
  apiProducerAccountsGetOne,
} from "../../api";

const styles = {
  actionsWrap: {
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
    paddingTop: 40,
    paddingBottom: 20
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: 600,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
};

const propTypes = {
  onDone: PropTypes.func.isRequired,
};

class ProducerAccountPasswordForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      account: null,
      loading: false,

      password: "",
      confirmPassword: "",

      passwordError: "",
      confirmPasswordError: "",

      requestError: ""
    };
  }

  componentDidMount() {
    const { match: { params: { id } }, intl } = this.props;
    this.setState({loading: true});
    apiProducerAccountsGetOne(id,(account) => {
      this.setState({
        account,
        loading: false,
      })
    }, () => {
      this.setState({
        loading: false,
        requestError: intl.formatMessage({id: "messages.somethingWentWrong"})
      })
    })
  }

  _validate = () => {
    if (this.state.loading)
      return false;

    this.setState({loading: true});

    let passwordError = validateField(validationRules, 'password', this.state.password);
    let confirmPasswordError = validateField(validationRules, 'confirmPassword', this.state.confirmPassword, {
      password: this.state.password
    });

    let valid = true;
    if (!passwordError && !confirmPasswordError) {
      this.setState({
        passwordError: "",
        confirmPasswordError: "",
        requestError: "",
        loading: true
      });
    } else {
      this.setState({
        passwordError: passwordError,
        confirmPasswordError: confirmPasswordError,
        requestError: "",
        loading: false
      });
      valid = false;
    }

    return valid;
  };

  validateField(fieldName) {
    const errorFieldName = fieldName + "Error";
    let state = {};
    state[errorFieldName] = validateField(validationRules, fieldName, this.state[fieldName]);
    this.setState(state);
  }

  _handleSubmit = e => {
    e.preventDefault();
    const valid = this._validate();

    if (!valid) return;

    const {
      password,
    } = this.state;

    let data = {
      password: password,
    };

    const { match: { params: { id } } } = this.props;
    this.setState({loading: true});

    apiProducerAccountsPatch(id, data, response => {
      this.setState({loading: false});
      this.props.onDone(response)
    }, error => {
      this.setState({
        loading: false,
        requestError: error.response.data && error.response.data.message
      })
    });
  };

  render() {
    const {
      intl,
      classes,
    } = this.props;
    const { loading } = this.state;

    return (
      <form onSubmit={this._handleSubmit} className={classes.form}>
        {loading && <Spinner size={50}/>}

        <ErrorBox error={this.state.requestError}/>
        <DialogContent>
          <PrimaryTextField
            error={!!this.state.passwordError}
            onChange={event => this.setState({password: event.target.value.trim()})}
            value={this.state.password}
            id="password-input"
            margin="dense"
            label={intl.formatMessage({id: "global.password"})  + " *"}
            type="password"
            helperText={this.state.passwordError}
            fullWidth
          />

          <PrimaryTextField
            error={!!this.state.confirmPasswordError}
            onChange={event => this.setState({confirmPassword: event.target.value.trim()})}
            value={this.state.confirmPassword}
            id="password-repeat-input"
            margin="dense"
            label={intl.formatMessage({id: "global.confirmPassword"})  + " *"}
            type="password"
            helperText={this.state.confirmPasswordError}
            fullWidth
          />
        </DialogContent>

        <div className={classes.actionsWrap}>
          <PrimaryButton type="submit">
            {intl.formatMessage({id: "global.save"})}
          </PrimaryButton>
        </div>
      </form>
    );
  }
}

ProducerAccountPasswordForm.propTypes = propTypes;

export default withStyles(styles)(
  injectIntl(ProducerAccountPasswordForm)
);