import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import AddIcon from '@material-ui/icons/Add';
import {OutlineButton} from '../index';
import {Checkbox} from '@material-ui/core';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: 'white',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: '#363636',
      },
    },
  },
}))(MenuItem);

export default function ButtonWithSelect(props) {
  const {buttonText, values, selected, onChange} = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleChange = (value) => {
    const index = selected.indexOf(value);
    let newSelected = [...selected];
    if(index === -1) {
      newSelected.push(value);
    } else {
      newSelected.splice(index, 1);
    }

    onChange(newSelected);
    setAnchorEl(null);
  };

  return (
    <div>
      <OutlineButton
        icon={AddIcon}
        onClick={handleClick}>
        {buttonText}
      </OutlineButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {values.map((value) => (
          <StyledMenuItem key={value.value} onClick={() => handleChange(value.value)}>
            <Checkbox color="default" checked={selected.includes(value.value)} />
            <ListItemText primary={value.title} />
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </div>
  );
}