import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import PrimaryTextField from '../../../../PrimaryTextField/PrimaryTextField';

const styles = {
  wrapper: {},
  chips: {
    display: 'block',
    width: '100%',
    margin: '20px 0 0 0',
    textAlign: 'left',
  },
  chip: {
    margin: 5,
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  selectFormControl: {
    marginBottom: 20,
  },
  column: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  mr20: {
    marginRight: 20,
  },
};

const propTypes = {
  title: PropTypes.string.isRequired,
  isNumber: PropTypes.bool,
  selected: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
const defaultProps = {
  selected: '',
  isNumber: false,
};

class DXTextFilter extends React.Component {
  render() {
    const { value, title, onChange, isNumber } = this.props;
    return (
      <PrimaryTextField
        type={isNumber ? 'number' : 'text'}
        value={value}
        label={title}
        onChange={(event) => onChange([event.target.value])}
      />
    );
  }
}

DXTextFilter.propTypes = propTypes;
DXTextFilter.defaultProps = defaultProps;
DXTextFilter.getLabel = (intl, localePrefix, selectedValues) => {
  let label = typeof selectedValues === 'string' ? selectedValues : '';
  if (Array.isArray(selectedValues) && selectedValues.length) {
    let parts = [];
    selectedValues.forEach((value) => {
      parts.push(value);
    });
    label = parts.join(', ');
  }
  return label;
};

export default injectIntl(withStyles(styles)(DXTextFilter));
