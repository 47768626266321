import {getRequestConfig, REQUEST_TYPES} from '../request';
import API_END_POINTS from '../endpoints';
import axios from 'axios';

/**
 * Create customer
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerCreate = function (data, onSuccess, onError) {
    const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.PRODUCER_CREATE);
    config.data = {
        verifyUrl: `${process.env.REACT_APP_URL}sign-in-via-link?t=token`,
        ...data
    };

    axios(config).then((response) => {
        onSuccess(response.data);
    }).catch((error) => {
        onError(error);
    });
};