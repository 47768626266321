import React from "react";
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import {withStyles} from '@material-ui/core/styles';

const styles = ({
  header: {
    display: 'block',
    textAlign: 'center',
    padding: 0,
    margin: 0,
  },
  title: {
    color: '#363636',
    display: 'block',
    fontSize: 16,
    fontWeight: 700,
  },
});

const propTypes = {
  justifyContent: PropTypes.string,
  height: PropTypes.any,
  marginBottom: PropTypes.number,
  title: PropTypes.any.isRequired,
};

const defaultProps = {
  justifyContent: 'center'
};

const SimpleDialogTitle = (props) => {
  const {
    title,
    classes,
    marginBottom,
    height,
    justifyContent,
    children
  } = props;

  return (
    <DialogTitle style={{
                    height: height,
                    marginBottom: marginBottom,
                    justifyContent: justifyContent
                 }}
                 classes={{root: classes.header}}>
      {children}
      <span className={classes.title}>{title}</span>
    </DialogTitle>
  );
};

SimpleDialogTitle.propTypes = propTypes;
SimpleDialogTitle.defaultProps = defaultProps;

export default withStyles(styles)(
  SimpleDialogTitle
);