import React from "react";
import classNames from 'classnames';
import {injectIntl} from 'react-intl';
import Typography from "@material-ui/core/Typography";
import {withStyles} from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import {
  getLineItemVariantName,
  getLineItemWeight,
  getVariantUnit,
  toCurrency,
  weightToUser,
  COLORS,
} from '../../helpers';
import Image from './Image';
import ReceiveImage from './ReceiveImage';
import styles from './styles';
import {LineItem as LineItemEntity} from "../../entities";
import { DISCOUNT_TYPES } from "../../entities/Discount";

const OrderLineItemDesktop = (props) => {
  const {
    classes,
    lineItem,
    order,
    intl,
  } = props;

  const ItemEntity = new LineItemEntity(lineItem);

  const getPrice = () => {
    return ItemEntity.hasDiscount() && ItemEntity.discount.definition.type === DISCOUNT_TYPES.FIXED
      ? `${toCurrency(ItemEntity.discount.price, intl)}`
      : `${toCurrency(lineItem.price, intl)}`
  };

  const getUnitPrice = () => {
    return ItemEntity.hasDiscount() && ItemEntity.discount.definition.type === DISCOUNT_TYPES.FIXED
      ? `${toCurrency(lineItem.discount.unitPrice, intl)} / ${weightToUser(lineItem.unitQuantity)} ${getVariantUnit(lineItem)}`
      : `${toCurrency(lineItem.unitPrice, intl)} / ${weightToUser(lineItem.unitQuantity)} ${getVariantUnit(lineItem)}`
  };

  return (
    <div className={classes.row}>
      <span className={classes.imageCell}>
        <Image lineItem={lineItem}/>
      </span>
      <span className={classes.cell}>
        {typeof lineItem.name === 'object' ? lineItem.name.product : lineItem.name} {getLineItemVariantName(lineItem, '-')}
        {ItemEntity.hasDiscount() && (
          <div className={classes.discount}>
            {intl.formatMessage({id: "global.discount"})}: {ItemEntity.hasDiscount() && ItemEntity.discount.getDiscountLabel(intl)}
          </div>
        )}
        {lineItem.note && <Typography>{lineItem.note}</Typography>}
      </span>
      <span className={classes.cell}>
        {getLineItemWeight(order, lineItem)}{getVariantUnit(lineItem)}
      </span>

      {!lineItem.prepurchasedUnitPrice && (
        <span className={classes.cell}>
          {getUnitPrice()}
        </span>
      )}

      {lineItem.prepurchasedUnitPrice && (
        <span className={classNames(classes.cell, classes.green)}>
          {toCurrency(lineItem.prepurchasedUnitPrice, intl)} / {weightToUser(lineItem.unitQuantity)} {getVariantUnit(lineItem)}
        </span>
      )}

      <span className={classNames(classes.cell, classes.center, classes.priceCell)}>
        {(!lineItem.prepurchasedPrice) && (
          <span className={
            classNames(
              classes.price,
              {[classes.green] : ItemEntity.hasDiscount() && ItemEntity.discount.definition.type === DISCOUNT_TYPES.FIXED}
            )
          }>{getPrice()}</span>
        )}

        {ItemEntity.hasDiscount() && ItemEntity.discount.definition.type !== DISCOUNT_TYPES.FIXED && (
          <p style={{color: COLORS.text}} className={classNames(classes.text, classes.price)}>
            {ItemEntity.discount.getAmountLabel(intl)}
          </p>
        )}

        {lineItem.prepurchasedPrice && (
          <span className={classes.prepurchasePrice}>
           {toCurrency(lineItem.prepurchasedPrice, intl)}
          </span>
        )}
      </span>
      <span className={classNames(classes.cell, classes.lastCell, classes.approveColumn)}>
        {lineItem.approved ?
          <CheckIcon className={classes.checkIcon}/> :
          <CloseIcon className={classes.removeIcon}/>}
        {(lineItem.received && lineItem.received.note) && (
          <Typography>{lineItem.received.note}</Typography>
        )}
        <ReceiveImage lineItem={lineItem}/>
      </span>
    </div>
  )
};

export default withStyles(styles)(
  injectIntl(OrderLineItemDesktop)
);
