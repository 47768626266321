import React, { useState } from 'react';
import classNames from 'classnames';
import { preventClickDefault } from '../../helpers/global';
import SupplierSwitch from '../SupplierSwitch';
import QuantityBoxSwitcher from '../QuantityBoxSwitcher';
import { toCurrency, weightToUser } from '../../../../helpers';
import { useIntl } from 'react-intl';

const VariantDrawer = (props) => {
  const {
    product,
    variant,
    fromProduct,

    drawImage,
    unlisted,
    changeVariantActivity,
    updateVariantInventory,
    classes,
    onClick,
  } = props;
  const intl = useIntl();

  const [loading, setLoading] = useState(false);

  const drawPriceAndUnit = (variant) => {
    return `${toCurrency(variant.retailPreorderPrice.price, intl)} / ${weightToUser(variant.unitQuantity, true)} ${
      variant.unit
    }`;
  };

  return (
    <div
      className={classNames([classes.variantWrapper, 'noDrag'])}
      key={`${product.id}_${variant.id}`}
      onClick={onClick}>
      <div className={classes.variantImageWrapper}>{fromProduct && drawImage(product)}</div>
      <div className={classes.variantContent}>
        <p className={classes.variantName}>{fromProduct ? product.name : variant.name}</p>
        <p className={classes.variantPriceAndUnit}>{drawPriceAndUnit(variant)}</p>
      </div>
      <div className={classes.variantCounterWrapper} onClick={preventClickDefault}>
        {unlisted ? (
          <SupplierSwitch
            checked={variant.active}
            disabled={loading}
            onChange={(e) => {
              changeVariantActivity(product, variant, e.target.checked);
            }}
          />
        ) : (
          <QuantityBoxSwitcher
            initialValue={!!variant?.inventory?.quantity ? weightToUser(variant.inventory.quantity) : 0}
            item={variant}
            product={product}
            onDone={(newValue, onSuccess, onError) => {
              setLoading(true);
              updateVariantInventory(
                variant,
                newValue,
                (response) => {
                  setLoading(false);
                  onSuccess && onSuccess(response);
                },
                (oldValue) => {
                  setLoading(false);
                  onError && onError(oldValue);
                }
              );
            }}
            isUpdating={loading}
          />
        )}
      </div>
    </div>
  );
};

export default VariantDrawer;
