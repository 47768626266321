import React from 'react';
import PropTypes from 'prop-types';
import { toggleSupplierDrawer } from '../../../../store/actions';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { PADDING_CONTENT, PADDING_HEADER_TOP, PADDING_HEADER_BOTTOM, BUTTON_VARIANTS } from '../../constants/globals';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '../../assets/menu';
import BackIcon from '../../assets/backButton';
import { withRouter } from '../../../../hocs';
import PrimaryButton from '../SupplierButton';
import { useIntl } from 'react-intl';
import { COLORS } from '../../constants';

export const HEIGHT_OF_HEADER_AREA = 55;

const useStyles = makeStyles(() => ({
  headerWrapper: {
    height: HEIGHT_OF_HEADER_AREA,
    boxSizing: 'border-box',
    paddingLeft: PADDING_CONTENT,
    paddingRight: PADDING_CONTENT,
    paddingTop: PADDING_HEADER_TOP,
    paddingBottom: PADDING_HEADER_BOTTOM,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: COLORS.white,
    position: 'sticky',
    top: 0,
    zIndex: 100,
    '@media print': {
      display: 'none',
    },
  },
  menuButton: {
    width: 40,
    height: 40,
  },
  leftSide: {
    minWidth: 40,
  },
  center: {
    flex: 1,
  },
  rightSide: {
    minWidth: 40,
  },
}));

const propTypes = {
  onlyBackButton: PropTypes.bool,
  noMenuButton: PropTypes.bool,
  onSaveButtonClick: PropTypes.func,
  saveButton: PropTypes.bool,
  saveButtonDisabled: PropTypes.bool,
  rightSideContent: PropTypes.func,
};

const HeaderArea = (props) => {
  const { history, onlyBackButton, onSaveButtonClick, saveButton, saveButtonDisabled, noMenuButton, rightSideContent } =
    props;
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const toggle = () => dispatch(toggleSupplierDrawer());

  const drawSaveButton = () => {
    return (
      <PrimaryButton
        variant={BUTTON_VARIANTS.smallBlue}
        text={intl.formatMessage({ id: 'supplier.global.save' })}
        link={'#'}
        onClick={onSaveButtonClick}
        disabled={saveButtonDisabled}
      />
    );
  };

  const drawLeftSideButton = () => {
    return (
      !noMenuButton && (
        <IconButton className={classes.menuButton} onClick={onlyBackButton ? history.goBack : toggle}>
          {onlyBackButton ? <BackIcon /> : <MenuIcon />}
        </IconButton>
      )
    );
  };
  return (
    <div className={classes.headerWrapper}>
      <div className={classes.leftSide}>{drawLeftSideButton()}</div>
      <div className={classes.center}>{/* Center content */}</div>
      <div className={classes.rightSide}>
        {rightSideContent && rightSideContent()}
        {!!saveButton && drawSaveButton()}
      </div>
    </div>
  );
};

HeaderArea.propTypes = propTypes;

export default withRouter(HeaderArea);
