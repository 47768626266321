import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { BUTTON_VARIANTS, BORDER_RADIUS_BUTTON, TRANSITION_MAIN } from '../../constants/globals';
import { Link } from 'react-router-dom';
import { Link as ExternalLink } from '@material-ui/core';
import classNames from 'classnames';
import { COLORS } from '../../constants';
import { FONT_BUTTON } from '../../constants/fonts';

const useStyles = makeStyles(() => ({
  root: {
    ...FONT_BUTTON,
    display: 'flex',
    height: 48,
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 20,
    paddingRight: 20,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: BORDER_RADIUS_BUTTON,
    textDecoration: 'none !important',
    boxSizing: 'border-box',
  },
  disabled: {
    cursor: 'not-allowed',
    opacity: 0.2,
  },
  icon: {
    marginRight: 10,
  },
}));

const propsTypes = {
  link: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
  variant: PropTypes.string,
  external: PropTypes.bool,
  classes: PropTypes.object,
};

const PrimaryButton = (props) => {
  const {
    link,
    onClick,
    text,
    icon,
    variant = BUTTON_VARIANTS.blue,
    disabled,
    classes: classesObj,
    external = false,
    ...other
  } = props;
  const classes = useStyles();
  const Icon = icon;
  const LinkComponent = external ? ExternalLink : Link;

  const useVariants = makeStyles(() => {
    const blue = {
      backgroundColor: COLORS.primary,
      color: COLORS.white,
      transition: TRANSITION_MAIN,
      '&:hover': {
        backgroundColor: COLORS.primaryHovered,
      },
    };

    return {
      blueButton: blue,
      smallBlue: {
        ...blue,
        height: 'auto',
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 10,
        paddingRight: 10,
        '&:visited': {
          ...blue,
        },
      },
      smallGrey: {
        backgroundColor: COLORS.backgroundLight,
        color: COLORS.grey,
        transition: TRANSITION_MAIN,
        height: 'auto',
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 10,
        paddingRight: 10,
        '&:visited': {
          backgroundColor: COLORS.backgroundLight,
          color: COLORS.grey,
          transition: TRANSITION_MAIN,
        },
      },
      greyButton: {
        backgroundColor: COLORS.backgroundLight,
        color: COLORS.grey,
        transition: TRANSITION_MAIN,
        '&:hover': {
          backgroundColor: COLORS.hover,
        },
      },
      transparentRed: {
        backgroundColor: 'transparent',
        color: COLORS.danger,
        transition: TRANSITION_MAIN,
        '&:hover': {
          backgroundColor: COLORS.backgroundLight,
          color: COLORS.dangerHover,
        },
        '&:active': {
          backgroundColor: COLORS.greyLight,
          color: COLORS.blackTintedBlue,
        },
      },
      transparentBlue: {
        backgroundColor: 'transparent',
        color: COLORS.primary,
        transition: TRANSITION_MAIN,
        '&:hover': {
          backgroundColor: COLORS.backgroundLight,
          color: COLORS.primaryHovered,
        },
        '&:active': {
          backgroundColor: COLORS.greyLight,
          color: COLORS.blackTintedBlue,
        },
      },
    };
  });

  const variantClasses = useVariants();

  return (
    <LinkComponent
      className={classNames([
        classes.root,
        variant === BUTTON_VARIANTS.blue && variantClasses.blueButton,
        variant === BUTTON_VARIANTS.smallBlue && variantClasses.smallBlue,
        variant === BUTTON_VARIANTS.smallGrey && variantClasses.smallGrey,
        variant === BUTTON_VARIANTS.grey && variantClasses.greyButton,
        variant === BUTTON_VARIANTS.transparentRed && variantClasses.transparentRed,
        variant === BUTTON_VARIANTS.transparentBlue && variantClasses.transparentBlue,
        disabled && classes.disabled,
        !!classesObj?.root && classesObj?.root,
      ])}
      to={link}
      href={link}
      disabled={disabled}
      {...(!disabled
        ? {
            onClick: onClick,
          }
        : {})}
      {...other}>
      {!!icon && (
        <span className={classNames([classes.icon, !!classesObj?.icon && classesObj?.icon])}>
          <Icon />
        </span>
      )}
      {text}
    </LinkComponent>
  );
};

PrimaryButton.propTypes = propsTypes;

export default PrimaryButton;
