import {
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  MAKE_INITIALIZED,
  MAKE_NON_INITIALIZED,
  TOGGLE_MENU_DRAWER,
  SHOW_TEMPORARY_TOOLTIP,
  HIDE_TEMPORARY_TOOLTIP,
} from '../constants';
import { CHANGE_TABS_STATE } from '../constants/common';

const initialState = {
  initialized: false,
  notification: '',
  drawerExpand: true,
  temporaryTooltip: { open: false, message: '', icon: null },
  tabsState: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MAKE_INITIALIZED: {
      return {
        ...state,
        initialized: true,
      };
    }
    case MAKE_NON_INITIALIZED: {
      return {
        ...state,
        initialized: false,
      };
    }
    case SHOW_NOTIFICATION: {
      const { notification } = action.payload;
      return {
        ...state,
        notification: notification,
      };
    }
    case HIDE_NOTIFICATION: {
      return {
        ...state,
        notification: '',
      };
    }
    case TOGGLE_MENU_DRAWER: {
      return {
        ...state,
        drawerExpand: !state.drawerExpand,
      };
    }
    case SHOW_TEMPORARY_TOOLTIP: {
      const { message, icon } = action.payload;
      return {
        ...state,
        temporaryTooltip: { open: true, message, icon },
      };
    }
    case HIDE_TEMPORARY_TOOLTIP: {
      return {
        ...state,
        temporaryTooltip: { open: false, message: '', icon: null },
      };
    }

    case CHANGE_TABS_STATE: {
      const { newTabState } = action.payload;

      return { ...state, tabsState: { ...state.tabsState, [newTabState.key]: newTabState } };
    }
    default:
      return state;
  }
};

export default reducer;
