const validation = {
  username: {
    presence: {
      message: '^Please enter a username'
    }
  },
  email: {
    email: {
      message: '^Please enter a valid email'
    },
  },
  phone: {
    format: {
      pattern: /^\+[1-9]\d{1,14}$/,
      message: '^Please enter a valid phone'
    },
  },
  password: {
    presence: {
      message: '^Please enter a password'
    }
  },
  confirmPassword: {
    presence: {
      message: '^Please enter confirm password'
    },
    equality: {
      attribute: "password",
      message: "^Confirm password is not equal to password",
    }
  },
};

export default validation;
