import moment from 'moment';
import { CUSTOMER_TYPE } from '../../../helpers';

export function preventClickDefault(event) {
  event.preventDefault();
  event.stopPropagation();
}
export function isToday(date) {
  const momentDate = moment.parseZone(date);
  const today = moment(new Date());

  return momentDate.isSame(today, 'day');
}

export function getCustomerName(customer) {
  if (!!customer?.customerName) return customer.customerName;

  return customer?.type === CUSTOMER_TYPE.WHOLESALE
    ? customer.companyName
    : `${customer.firstName} ${customer.lastName}`;
}

export function isTouchableDevice() {
  return !!navigator.maxTouchPoints;
}

export function scrollToElement(elem, offsetTop = 0) {
  const elemOffsetTop = elem.current.offsetParent.offsetTop;

  window.scrollTo({
    top: elemOffsetTop - offsetTop,
    behavior: 'smooth',
  });
}
