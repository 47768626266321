import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../../../hocs';
import {
  AdminDialog,
  AdminDialogTitle,
  AdminDialogContent,
  AdminDialogControls,
  PrimaryTextField,
  PrimaryButton,
  Spinner,
} from '../../../../components';
import { COLORS, validateField } from '../../../../helpers';
import { apiProducerPatch } from '../../../../api';
import validationRules from './validate';

const styles = {
  wrapper: {},
  name: {
    fontSize: 18,
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.text,
    marginBottom: 40,
  },
  variants: {
    marginBottom: 50,
  },
  input: {
    marginBottom: 25,
  },
  inputText: {
    textAlign: 'right',
  },
  button: {
    minWidth: '40%',
    textTransform: 'uppercase',
  },
  mr20: {
    marginRight: 10,
  },
  controls: {
    margin: '27px 0 0 0',
    textAlign: 'center',
  },
  control: {
    padding: '0 105px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    flex: 1,
  },
};

const propTypes = {
  producer: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

class AddressDialog extends React.Component {
  constructor(props) {
    super(props);
    const { producer } = this.props;
    this.state = {
      loading: false,

      name: producer.name,
      nameShort: producer.nameShort,
      phone: producer.phone,
      email: producer.email,
      address1: producer.address1,
      address2: producer.address2,
      zipCode: producer.zip,
      city: producer.city,
      province: producer.province,

      nameError: '',
      emailError: '',
      address1Error: '',
      cityError: '',
      zipError: '',
      provinceError: '',
    };
  }

  getTextField(fieldName, required) {
    const { classes, intl } = this.props;

    return (
      <PrimaryTextField
        className={classes.input}
        InputLabelProps={{
          classes: {
            root: classes.label,
          },
        }}
        autoComplete="no"
        error={!!this.state[`${fieldName}Error`]}
        onChange={(event) => {
          let value = event.target.value;
          this.setState((state) => {
            state[fieldName] = value;
            return state;
          });
        }}
        value={this.state[fieldName]}
        id={`${fieldName}-input`}
        label={(required ? '* ' : '') + intl.formatMessage({ id: `global.${fieldName}` })}
        type="text"
        margin="normal"
        helperText={this.state[`${fieldName}Error`] && intl.formatMessage({ id: this.state[`${fieldName}Error`] })}
        fullWidth
      />
    );
  }

  validate = () => {
    const { onSave } = this.props;
    const nameError = validateField(validationRules, 'name', this.state.name);
    const emailError = validateField(validationRules, 'email', this.state.email);
    const address1Error = validateField(validationRules, 'address1', this.state.address1);
    const cityError = validateField(validationRules, 'city', this.state.city);
    const zipCodeError = validateField(validationRules, 'zipCode', this.state.zipCode);
    const provinceError = validateField(validationRules, 'province', this.state.province);

    this.setState({ loading: true });
    if (!nameError && !emailError && !address1Error && !cityError && !zipCodeError && !provinceError) {
      this.setState({
        nameError: '',
        emailError: '',
        addressError: '',
        cityError: '',
        zipCodeError: '',
        provinceError: '',

        requestError: '',
      });

      this.setState({ loading: true });
      apiProducerPatch(
        {
          name: this.state.name,
          nameShort: this.state.nameShort,
          phone: this.state.phone,
          email: this.state.email,
          address1: this.state.address1,
          address2: this.state.address2,
          zip: this.state.zipCode,
          city: this.state.city,
          province: this.state.province,
        },
        (producer) => onSave && onSave(producer),
        () => this.setState({ loading: false })
      );
    } else {
      this.setState({
        nameError: nameError,
        emailError: emailError,
        address1Error: address1Error,
        cityError: cityError,
        zipCodeError: zipCodeError,
        provinceError: provinceError,
        loading: false,
      });
    }
  };

  render() {
    const { intl, classes, onClose } = this.props;

    return (
      <AdminDialog open closeButton onClose={onClose}>
        <AdminDialogTitle title={intl.formatMessage({ id: 'producer.dialog.editingAddress' })} />
        <AdminDialogContent className={classes.wrapper}>
          {this.state.loading && <Spinner size={50} />}

          <div className={classes.row}>
            <div style={{ marginRight: 72 }} className={classes.column}>
              {this.getTextField('name', true)}
              {this.getTextField('email', true)}
              {this.getTextField('address1', true)}
              {this.getTextField('city', true)}
              {this.getTextField('zipCode', true)}
            </div>
            <div className={classes.column}>
              {this.getTextField('nameShort')}
              {this.getTextField('phone')}
              {this.getTextField('address2')}
              {this.getTextField('province', true)}
            </div>
          </div>

          {this.state.description !== this.props.producer.description && (
            <AdminDialogControls>
              <PrimaryButton className={classes.button} onClick={() => this.validate()}>
                {intl.formatMessage({ id: 'global.save' })}
              </PrimaryButton>
            </AdminDialogControls>
          )}
        </AdminDialogContent>
      </AdminDialog>
    );
  }
}

AddressDialog.propTypes = propTypes;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(AddressDialog)));
