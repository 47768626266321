import React from "react";
import PropTypes from "prop-types";
import {
  ErrorBox,
  Spinner
} from "../../components";
import {
  Elements,
  StripeProvider
} from "react-stripe-elements";
import {withStyles} from "@material-ui/core/styles";
import StripeCreditCardForm from './StripeCreditCardForm';
import styles from "./styles";

class Wrapper extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      stripe: null,
      error: "",
    };
  }

  componentDidMount() {
    if (window.Stripe) {
      this.setState({stripe: window.Stripe(process.env.REACT_APP_STRIPE_API_KEY)});
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        this.setState({stripe: window.Stripe(process.env.REACT_APP_STRIPE_API_KEY)});
      });
    }
  }

  render() {
    const {
      error,
      loading,
      onValidated,
    } = this.props;

    return (
      <React.Fragment>
        <ErrorBox error={error}/>
        {loading && <Spinner size={50}/>}

        {this.state.stripe && (
          <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
            <Elements>
              <StripeCreditCardForm
                onValidated={onValidated}
                loading={loading}
                fontSize={"18px"}
              />
            </Elements>
          </StripeProvider>
        )}
      </React.Fragment>
    );
  }
}

Wrapper.propTypes = {
  onValidated: PropTypes.func.isRequired,
  loading:     PropTypes.bool,
};

export default withStyles(
  styles, {withTheme: true}
)(Wrapper);