import React from 'react';
import { AdminDXTable } from '../index';
import PropTypes from 'prop-types';
import styles from './styles';
import { makeStyles } from '@material-ui/core';
import RowRenderer from './RowRenderer';
import SearchField from './SearchField';
import MemberServiceCustomerReportSkeleton from './AdminDxTableSkeleton';

const propTypes = {
  title: PropTypes.string,
};
const useStyles = makeStyles(styles);

const AdminDxTableDesigned = (props) => {
  const { title, searchPlaceholder, onRowClick, ...others } = props;
  const classes = useStyles();

  return (
    <AdminDXTable
      rowRenderer={(onClick, params) => RowRenderer(onRowClick || onClick, params)}
      headerCellProps={{
        className: classes.headerCell,
      }}
      actionsHeaderCellStyle={styles().headerCell}
      actionsCellStyle={{
        textAlign: 'right',
        paddingRight: 50,
      }}
      SearchFieldComponent={(params) => <SearchField title={title} placeholder={searchPlaceholder} {...params} />}
      toolbarRootComponent={(props) => <div {...props} className={classes.toolbarRoot} />}
      stickyHeader
      stickyHeaderOffset={125}
      hideTableOnLoading
      LoadingComponent={() => (
        <MemberServiceCustomerReportSkeleton
          {...props}
          SearchFieldComponent={(params) => (
            <div {...props} className={classes.toolbarRoot}>
              <SearchField title={title} placeholder={searchPlaceholder} {...params} />
            </div>
          )}
        />
      )}
      {...others}
    />
  );
};

AdminDxTableDesigned.propTypes = propTypes;

export default AdminDxTableDesigned;
