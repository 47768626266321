import axios from 'axios';
import { getRequestConfig, REQUEST_TYPES, replaceUrlPlaceholder } from '../request';
import API_END_POINTS from '../endpoints';

export const apiGetProducer = function (id, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.GET_PRODUCER, 'id', id);
  const config = getRequestConfig(REQUEST_TYPES.GET, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

export const apiGetDistinctShippingDates = function (onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.GET_DISTINCT_SHIPPING_DATES);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

export const apiProducerPatch = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.PATCH, API_END_POINTS.PRODUCER_PATCH_PROFILE);
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

export const apiGetTaxRates = function (onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.PRODUCER_GET_TAX_RATES);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Patch delivery company settings
 * @param {object} data
 * @param {boolean} data.allocatePickupOrders
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSetDeliveryCompanySettings = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.PATCH, API_END_POINTS.PATCH_DELIVERY_COMPANY_SETTINGS);
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Supplier add customer
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierAddCustomer = function (onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.SUPPLIER_ADD_CUSTOMER);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Supplier add customer
 * @param {boolean} supplierOrdering
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiChangeSupplierOrderingSettings = function (supplierOrdering, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.PATCH, API_END_POINTS.SUPPLIER_CHANGE_ORDERING_SETTINGS);
  config.data = { supplierOrdering };

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};
