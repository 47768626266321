import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../../hocs';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { PrimaryTextField, PrimaryCheckbox } from '../../../components/index';
import styles from './styles';

const propTypes = {
  initialData: PropTypes.object,
  onRef: PropTypes.func.isRequired,
};

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.props.onRef(this);

    const { initialData } = this.props;

    this.state = {
      terms: initialData ? initialData.terms : '',
      allowCredit: initialData ? initialData.allowCredit : false,
      freeShipping: initialData ? initialData.freeDelivery : false,
      allowShipping: initialData ? initialData.allowShipping : false,
      termsError: '',
    };
  }

  getTextField(fieldName) {
    const { classes, intl } = this.props;

    return (
      <PrimaryTextField
        className={classes.input}
        InputLabelProps={{
          classes: {
            root: classes.label,
          },
        }}
        autoComplete="no"
        error={!!this.state[`${fieldName}Error`]}
        onChange={(event) => {
          let value = event.target.value;
          this.setState((state) => {
            state[fieldName] = value;
            return state;
          });
        }}
        value={this.state[fieldName]}
        id={`${fieldName}-input`}
        label={intl.formatMessage({ id: `global.${fieldName}` })}
        type="text"
        margin="normal"
        helperText={this.state[`${fieldName}Error`] && intl.formatMessage({ id: this.state[`${fieldName}Error`] })}
        fullWidth
      />
    );
  }

  getCheckbox = (fieldName) => {
    const { intl } = this.props;

    return (
      <FormControlLabel
        style={{ width: '100%' }}
        control={
          <PrimaryCheckbox
            checked={this.state[fieldName]}
            onChange={(event) => {
              let checked = event.target.checked;
              this.setState((state) => {
                state[fieldName] = checked;
                return state;
              });
            }}
            color="default"
          />
        }
        label={intl.formatMessage({ id: `global.${fieldName}` })}
      />
    );
  };

  validate = () => {
    return {
      validated: true,
      data: {
        paymentTerms: this.state.terms,
        allowCredit: this.state.allowCredit,
        freeDelivery: this.state.freeShipping,
        allowShipping: this.state.allowShipping,
      },
    };
  };

  getInput = () => {
    return {
      paymentTerms: this.state.terms,
      allowCredit: this.state.allowCredit,
      freeDelivery: this.state.freeShipping,
      allowShipping: this.state.allowShipping,
    };
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.wrapper}>
        {this.getTextField('terms')}
        {this.getCheckbox('allowCredit')}
        {this.getCheckbox('freeShipping')}
        {this.getCheckbox('allowShipping')}
      </div>
    );
  }
}

UserForm.propTypes = propTypes;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(UserForm)));
