import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ExportIcon from '@material-ui/icons/CallMade';
import { COLORS } from '../../../helpers';

const styles = ({
  label: {
    flexDirection: 'column',
    fontSize: 9,
    color: COLORS.actionLabel
  },
  button: {
    marginLeft: 9,
    marginRight: 9,
    padding: 0,
    '&:hover' : {
      background: 'none',
    }
  },
  icon: {
    color: COLORS.black
  }
});

const propTypes = {
  classes: PropTypes.object.isRequired,
  allowExport: PropTypes.bool,
  onExport: PropTypes.func,
  currentReportIndex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

const Actions = ({
  intl,
  classes,
  allowExport,
  onExport,
}) => {
  return (
    <React.Fragment>
      {allowExport && (
        <IconButton classes={{root: classes.button, label: classes.label}} onClick={onExport}>
          <ExportIcon className={classes.icon} />
          {intl.formatMessage({id: "global.csvExport"})}
        </IconButton>
      )}
    </React.Fragment>
  );
};

Actions.propTypes = propTypes;

export default withStyles(styles)(
  injectIntl(Actions)
);
