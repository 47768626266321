import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { withStyles } from '@material-ui/core/styles'
import { AdminDialog, Image } from '../../components'
import classNames from 'classnames'

const styles = ({
  wrapper: {
  },
  paper: {
    padding: "47px 52px 74px",
    borderRadius: 8,
    maxHeight: 'calc(100% - 16px)',
    minWidth: 980
  },
});

const propTypes = {
  // Product or Variant
  object: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

const ImageDialog = ({ object, onClose, classes }) => {
  return (
    <AdminDialog open
                 closeButton
                 paperClass={classes.paper}
                 onClose={onClose}>

      <div className={classNames(classes.column, classes.mr40)}>
        <Image object={object} />
      </div>

    </AdminDialog>
  );
};

ImageDialog.propTypes = propTypes;

export default withStyles(styles)(
  injectIntl(ImageDialog)
);
