import React from "react";
import PropTypes from 'prop-types';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import {withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import styles from './styles';

const MessageBox = (props) => {
  const {
    message,
    classes,
    className,
    onClose,
  } = props;

  return message ?
    <SnackbarContent
      className={`${classes.message} ${className}`}
      message={message}


      {...(onClose ? {
        action: [
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={onClose}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
        ]
      } : {})}

    /> : null;
};

MessageBox.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string
};

export default withStyles(styles)(MessageBox);