import React from 'react';
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem';
import { injectIntl } from 'react-intl'
import {withStyles} from '@material-ui/core/styles';
import DXFilterConfig from '../DXFilterConfig'
import constants from '../../constants'
import {getStore} from '../../../../../store/configure';
import PrimarySelect from '../../../../PrimarySelect/PrimarySelect';

const styles = ({
  wrapper: {
  },
  chips: {
    display: 'block',
    width: '100%',
    margin: '20px 0 0 0',
    textAlign: 'left',
  },
  chip: {
    margin: 5,
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row'
  },
  column: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  mr20: {
    marginRight: 20
  },
  select: {
    minWidth: '100%'
  }
});

const propTypes = {
  equality: PropTypes.string,
  selected: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  selected: [],
};

class DXDeliveryCompanyFilter extends React.Component {
  
  render() {
    const { selected, onChange, intl, equality, classes } = this.props;
    let config = DXDeliveryCompanyFilter.getConfig(intl, equality);
    
    return (
      <div className={classes.row}>
        <div className={classes.column}>
          <div className={classes.wrapper}>
            <div className={classes.mr20}>
              <PrimarySelect
                label={config.title}
                value={selected}
                fullWidth
                formControlClassName={classes.select}
                onChange={(e) => {
                  if (e.target.value !== -1)
                    onChange( [e.target.value] )
                }}
              >

                <MenuItem key={-1} value={-1}>
                  {intl.formatMessage({id: "global.deliveryCompany"})}
                </MenuItem>

                {config.values.map((item) => {
                  return(
                    <MenuItem key={item.key} value={item.key}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </PrimarySelect>
            </div>
          </div>
        </div>
        <div className={classes.column} />
      </div>
    )
  }
}

DXDeliveryCompanyFilter.propTypes = propTypes;
DXDeliveryCompanyFilter.defaultProps = defaultProps;
DXDeliveryCompanyFilter.getLabel = (intl, selectedValues) => {
  let label = "";
  let parts = [];
  if (Array.isArray(selectedValues) && selectedValues.length) {
    const config = DXDeliveryCompanyFilter.getConfig(intl, '*');
    const values = config.values;
    
    selectedValues.forEach(value => {
      const translation = values.filter(item => item.key === value);
      if (translation.length && parts.length < 2) {
        parts.push(translation[0].label)
      }
    });
    
    label = parts.join(', ');
  }
  return label;
};
DXDeliveryCompanyFilter.applyToQueryParams = (queryParams, selectedValues) => {
  queryParams['deliveryCompanyId'] = selectedValues[0];
  return queryParams;
};

DXDeliveryCompanyFilter.getConfig = (intl, equality) => {
  const store = getStore();
  const deliveryCompanies = store.getState().producer.object.deliveryCompanies;
  
  let config = new DXFilterConfig({
    name: 'deliveryCompany',
    selected: -1,
    property: 'deliveryCompanyId',
    title: intl.formatMessage({id: 'global.deliveryCompany'}),
    disabled: false,
    equalityStatus: false,
    multi: false
  });
  
  if (equality === '*' || equality === constants.FILTER_EQUALITY_EQUALS) {
    deliveryCompanies &&
    deliveryCompanies.map((item) => config.addFilterValue(item.id, item.name));
  }
  
  return config;
};

export default injectIntl(
  withStyles(styles)(DXDeliveryCompanyFilter)
);
