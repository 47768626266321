import React from 'react';
import { COLORS } from '../constants/colors';

const CalcButtonRemove = (props) => {
  const { color = COLORS.blackTintedBlue, ...other } = props;
  return (
    <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <path
        d="M11.2144 6.52515C10.9215 6.23224 10.9215 5.75739 11.2144 5.46448C11.5073 5.17157 11.9822 5.17157 12.275 5.46448L14.75 7.93939L17.2249 5.46448C17.5177 5.17157 17.9926 5.17157 18.2855 5.46448C18.5784 5.75732 18.5784 6.23224 18.2855 6.52515L15.8105 9L18.2855 11.4749C18.5784 11.7678 18.5784 12.2427 18.2855 12.5355C17.9926 12.8284 17.5177 12.8284 17.2249 12.5355L14.75 10.0607L12.275 12.5355C11.9822 12.8284 11.5073 12.8284 11.2145 12.5355C10.9215 12.2426 10.9215 11.7678 11.2145 11.4749L13.6893 9.00006L11.2144 6.52515Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.12134 1.37866L0.5 9L8.12134 16.6213C8.68396 17.184 9.44702 17.5 10.2427 17.5H20.5C22.1569 17.5 23.5 16.1569 23.5 14.5V3.5C23.5 1.84314 22.1569 0.5 20.5 0.5H10.2427C9.44702 0.5 8.68396 0.816101 8.12134 1.37866ZM20.5 16.2H10.2427C9.79175 16.2 9.35938 16.0209 9.04053 15.7021L2.3385 9L9.04053 2.29791C9.35938 1.97913 9.79175 1.79999 10.2427 1.79999H20.5C21.4388 1.79999 22.2 2.5611 22.2 3.5V14.5C22.2 15.4389 21.4388 16.2 20.5 16.2Z"
        fill={color}
      />
    </svg>
  );
};

export default CalcButtonRemove;
