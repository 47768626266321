import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../../hocs';
import {
  AdminDialogTitle,
  AdminDialogContent,
  PrimaryTextField,
  SearchHitsSubWrapper,
  SearchHits,
  SearchHitsWrapper,
} from '../../../components';
import styles from './styles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import SearchIcon from '@material-ui/icons/Search';
import MarketListComponent from '../components/MarketListComponent';

const propTypes = {
  onApprove: PropTypes.func,
  locations: PropTypes.array,
  onAddLocation: PropTypes.func,
};

const SearchStage = ({ intl, classes, onApprove, locations, onAddLocation }) => {
  const [searchedLocations, setSearchedLocations] = useState(locations || []);

  const getHitComponent = (hit) => {
    return <MarketListComponent location={hit} onClick={onApprove} />;
  };

  const filterLocationsByQuery = (query) => {
    setSearchedLocations(
      locations.filter((location) => !!location.name.toLocaleLowerCase().match(query.toLocaleLowerCase()))
    );
  };

  const noItemFind = () => {
    return <div className={classes.noItems}>{intl.formatMessage({ id: 'search.location.noItems' })}</div>;
  };

  const onAddNewMarket = () => {
    onAddLocation();
  };

  return (
    <React.Fragment>
      <AdminDialogTitle title={intl.formatMessage({ id: 'search.location.searchTitle' })} />
      <AdminDialogContent className={classes.wrapper}>
        <div className={classes.searchWrapper}>
          <PrimaryTextField
            onChange={(event) => {
              filterLocationsByQuery(event.target.value);
            }}
            className={classes.search}
            margin="dense"
            autoFocus
            label={intl.formatMessage({ id: 'search.location.searchLabel' })}
          />
          <SearchIcon />
        </div>

        <SearchHitsWrapper allowSwitchView={false} view={'list'}>
          <SearchHitsSubWrapper>
            <SearchHits
              getComponentCallback={(hit) => getHitComponent(hit)}
              getEmptyComponent={noItemFind}
              hits={searchedLocations.slice(0, 6)}
            />
          </SearchHitsSubWrapper>
        </SearchHitsWrapper>

        <div>
          <h3>{intl.formatMessage({ id: 'search.location.addLocationManually' })}</h3>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Card className={classes.card} key={'empty-hit-location'} onClick={() => onAddNewMarket()} elevation={3}>
              <CardContent className={classes.content}>
                <Typography style={{ marginTop: 10, marginBottom: 10 }} className={classes.companyName}>
                  {intl.formatMessage({ id: 'search.location.addLocation' })}
                </Typography>
              </CardContent>
            </Card>
          </div>
        </div>
      </AdminDialogContent>
    </React.Fragment>
  );
};

SearchStage.propTypes = propTypes;
SearchStage.defaultProps = {
  locations: [],
};

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(SearchStage)));
