import { SIGN_IN, SIGN_OUT, SUPPLIER_SIGN_IN, SUPPLIER_SIGN_OUT } from '../constants';

const initialState = {
  authenticated: false,
  token: null,
  supplierToken: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN: {
      const { token } = action.payload;
      return {
        ...state,
        authenticated: true,
        token: token,
      };
    }
    case SIGN_OUT: {
      return {
        ...state,
        authenticated: false,
        customer: {},
        token: null,
      };
    }
    case SUPPLIER_SIGN_IN: {
      const { token } = action.payload;

      return {
        ...state,
        supplierToken: token,
      };
    }
    case SUPPLIER_SIGN_OUT: {
      return {
        ...state,
        supplierToken: null,
      };
    }
    default:
      return state;
  }
};

export default reducer;
