import React from 'react';
import { COLORS } from '../constants/colors';

const CalcButton7 = (props) => {
  const { color = COLORS.blackTintedBlue, ...other } = props;
  return (
    <svg width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <path
        d="M0.799217 33H2.27383V30.7783H3.70449C5.18887 30.7783 6.18008 29.8213 6.18008 28.3711V28.3613C6.18008 26.9111 5.18887 25.9541 3.70449 25.9541H0.799217V33ZM3.34316 27.1211C4.18789 27.1211 4.68594 27.5654 4.68594 28.3662V28.376C4.68594 29.1768 4.18789 29.626 3.34316 29.626H2.27383V27.1211H3.34316ZM12.3186 33.1807C12.7727 33.1807 13.1926 33.1123 13.5686 32.9805L14.1057 33.6982H15.4777L14.5305 32.4336C15.2824 31.7988 15.7072 30.7783 15.7072 29.4795V29.4697C15.7072 27.1943 14.3937 25.7734 12.3186 25.7734C10.2434 25.7734 8.925 27.1895 8.925 29.4697V29.4795C8.925 31.7549 10.2238 33.1807 12.3186 33.1807ZM12.3186 31.9307C11.1564 31.9307 10.4289 30.9688 10.4289 29.4795V29.4697C10.4289 27.9756 11.1711 27.0234 12.3186 27.0234C13.4611 27.0234 14.2033 27.9756 14.2033 29.4697V29.4795C14.2033 30.2412 14.008 30.8613 13.6662 31.291L13.2121 30.6807H11.84L12.7434 31.8818C12.6066 31.916 12.465 31.9307 12.3186 31.9307ZM18.8086 33H20.2832V30.4219H21.4893L22.8027 33H24.4727L22.9883 30.2021C23.7939 29.8848 24.2627 29.1084 24.2627 28.2051V28.1953C24.2627 26.8086 23.3447 25.9541 21.7432 25.9541H18.8086V33ZM20.2832 29.3477V27.1064H21.5625C22.2998 27.1064 22.7539 27.5459 22.7539 28.2246V28.2344C22.7539 28.9277 22.3193 29.3477 21.582 29.3477H20.2832ZM29.9715 33.1807C31.7293 33.1807 32.8133 32.2969 32.8133 30.9004V30.8955C32.8133 29.7969 32.1639 29.2012 30.6746 28.8936L29.9275 28.7422C29.0975 28.5713 28.7557 28.2979 28.7557 27.8389V27.834C28.7557 27.3066 29.2391 26.9746 29.9666 26.9697C30.7088 26.9697 31.1971 27.3213 31.2801 27.8242L31.2898 27.8828H32.6766L32.6717 27.8193C32.5789 26.6426 31.6121 25.7734 29.9666 25.7734C28.4041 25.7734 27.2811 26.6377 27.2811 27.9463V27.9512C27.2811 29.0205 27.9109 29.7041 29.3758 30.002L30.118 30.1533C30.992 30.334 31.3387 30.5928 31.3387 31.0566V31.0615C31.3387 31.6035 30.7967 31.9795 30.0105 31.9795C29.1853 31.9795 28.6141 31.623 28.5603 31.0957L28.5555 31.0469H27.1346L27.1395 31.125C27.2225 32.375 28.2869 33.1807 29.9715 33.1807Z"
        fill={color}
      />
      <path d="M13.3311 18H15.6382L23.4751 2.42383V0.385254H11.8174V2.35059H21.2412V2.52148L13.3311 18Z" fill={color} />
    </svg>
  );
};

export default CalcButton7;
