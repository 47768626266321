import { COLORS } from '../../helpers';

export default {
  errorBox: {
    marginTop: 20,
    marginLeft: 20,
    marginRight: 20,
  },
  button: {
    position: 'relative',
    marginLeft: 9,
    marginRight: 9,
    padding: 0,
    '&:hover': {
      background: 'none',
    },
  },
  buttonActive: {
    '&:hover': {
      background: 'none',
    },
  },
  label: {
    fontSize: 12,
    color: COLORS.text,
    flexDirection: 'column',
  },
  labelActive: {},
  icon: {
    color: COLORS.black,
  },
  iconActive: {},
  buttonWrapper: {
    border: '1px solid transparent',
    display: 'inline-block',
    backgroundColor: COLORS.white,
    color: COLORS.text,
    width: 58,
    height: 40,
    '&:hover': {
      background: 'none',
    },
  },
  buttonWrapperActive: {
    border: '1px solid black',
  },
  timeToAppear: {
    flex: 1,
    fontFamily: "'Roboto', sans-serif",
    fontSize: 14,
    fontWeight: 400,
    color: 'rgba(0,0,0,0.5)',
  },
  noActions: {
    height: 38,
  },
};
