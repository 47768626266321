import React from 'react';
import ItemsByDateTreeComponent from '../ItemsByDateTreeComponent/ItemsByDateTreeComponent';

const DatesListComponent = React.memo(
  (props) => {
    const {
      itemsData,
      collapsingState,
      query,
      updateSingleItem,
      reloadAllData,
      columnsConfig,
      onCollapseChange,
    } = props;

    if (!itemsData?.length) {
      return null;
    }

    return itemsData.map((item, index) => (
      <ItemsByDateTreeComponent
        key={item.id}
        initialOpen={item.open || collapsingState.includes(item.id) || !!query}
        item={item}
        index={index}
        itemsData={itemsData}
        updateSingleItem={updateSingleItem}
        reloadAllData={reloadAllData}
        columnsConfig={columnsConfig}
        onCollapseChange={onCollapseChange}
        dontShowEmpty={!!query}
      />
    ));
  },
  (prev, next) => {
    if (prev?.itemsData?.length !== next?.itemsData?.length) {
      return false;
    }

    // Compare all days ids
    if (prev?.itemsData?.map((day) => day.id).toString() !== next?.itemsData?.map((day) => day.id).toString()) {
      return false;
    }

    // Compare all items ids
    if (
      prev?.itemsData?.map((day) => day?.items.map((item) => item.id).toString()).toString() !==
      next?.itemsData?.map((day) => day?.items.map((item) => item.id).toString()).toString()
    ) {
      return false;
    }

    return true;
  }
);

export default DatesListComponent;
