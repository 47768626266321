import { isOrderPacked } from './orders';

export const weightToUser = (weight, emptyIfOne) => {
  weight = weight || 0;
  weight = weight > 0 ? weight / 100 : weight;
  return (weight === 1 && emptyIfOne ? ' ' : weight).toString();
};

export const getLineItemQuantity = (item) => {
  const { orderedQuantity, unitQuantity } = item;
  return orderedQuantity / unitQuantity;
};

export const getLineItemPackedQuantity = (item) => {
  const { packedQuantity, unitQuantity } = item;
  return packedQuantity / unitQuantity;
};

export const getLineItemWeight = (order, item) => {
  const unitQuantity = weightToUser(item.unitQuantity, false);
  return isOrderPacked(order)
    ? `${getLineItemPackedQuantity(item) * unitQuantity}/${getLineItemQuantity(item) * unitQuantity}`
    : getLineItemQuantity(item) * unitQuantity;
};

export const getLineItemExtendedQuantity = (order, item) => {
  return isOrderPacked(order)
    ? `${getLineItemPackedQuantity(item)}/${getLineItemQuantity(item)}`
    : getLineItemQuantity(item);
};

export const getLineItemSystemQuantity = (item, newQuantity) => {
  const { unitQuantity } = item;
  return newQuantity * unitQuantity;
};

export const getSystemQuantity = (unitQuantity, newQuantity) => {
  return newQuantity * unitQuantity;
};

export const weightToSystem = (weight) => {
  return weight * 100;
};

export const numberWithSeparator = (number) => {
  const parts = parseFloat(number).toFixed(2).toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

export const getNumberFromSeparatedNumber = (separated) => {
  const parts = separated.toString().split('.');
  parts[0] = parts[0].replace(/\./g, '');
  return parseFloat(parts.join('.')).toFixed(2);
};
