import React from 'react';
import { injectIntl } from 'react-intl';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import ProductCell from './Cells/ProductCell';
import CellAsPriceInput from '../../components/AdminDXTable/Editing/CellAsPriceInput';
import CellAsSelectInput from '../../components/AdminDXTable/Editing/CellAsSelectInput';
import {
  apiGetSubscriptionSchedule,
  apiChangeSubscriptionLineItemSchedule,
  apiMoveDateSeriesSubscriptionLineItemSchedule,
  apiDeleteSingleSubscriptionLineItemScheduleDate,
} from '../../api';
import CellAsScheduler from '../../components/AdminDXTable/Editing/CellAsScheduler';
import { CellAsIntegerInput } from '../../components/AdminDXTable/Editing';
import { getChainObjectByPath } from '../../helpers/object';
import moment from 'moment';
import EventIcon from '@material-ui/icons/Event';
import CellAsDateInput from '../../components/AdminDXTable/Editing/CellAsDateInput';

export const PERIODS = {
  PER_WEEK: 'week',
  PER_MONTH: 'month',
  BI_WEEK: 'bi_week',
};

const styles = {
  subscriptionText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

export const PARAM_COMPARISON_TYPES = {
  STRING: 'string',
  DATE: 'date',
  DATE_TIME: 'date_time',
  OBJECT: 'object',
  ARRAY: 'array',
  VARIANT: 'variant',
};

export function objectParamComparison(compareParams = {}, originalObject = {}, extendsParams = {}) {
  let changedParams = {};

  Object.entries(compareParams).forEach(([key, value]) => {
    if (extendsParams.hasOwnProperty(key)) {
      const type = extendsParams[key]?.type;
      const path = extendsParams[key].key;
      const originalValue = getChainObjectByPath(originalObject, path);

      if (!originalValue && !!value) {
        changedParams[key] = value;
        return;
      }

      switch (type) {
        case PARAM_COMPARISON_TYPES.DATE: {
          const dateFormat = 'DD-MM-YYYY';

          if (moment(originalValue).format(dateFormat) !== moment(value).format(dateFormat)) {
            changedParams[key] = value;
          }
          break;
        }

        case PARAM_COMPARISON_TYPES.ARRAY: {
          if (
            Array.isArray(originalValue) &&
            Array.isArray(value) &&
            JSON.stringify(originalValue) !== JSON.stringify(value)
          ) {
            changedParams[key] = value;
          }
          break;
        }

        case PARAM_COMPARISON_TYPES.VARIANT: {
          // TODO: make a normal comparison for variants objects
          changedParams[key] = value;
          break;
        }

        default: {
          if (originalValue !== value) {
            changedParams[key] = value;
          }
          break;
        }
      }
    } else {
      if (originalObject[key] !== value) {
        changedParams[key] = value;
      }
    }
  });

  return changedParams;
}

export const TableCell = (
  onFinishPriceEditing,
  onFinishLimitEditing,
  onFinishPeriodEditing,
  onFinishVariantDataEditing,
  onScheduleDataChange,
  intl,
  period,
  subscriptionData,
  reload,
  { row, ...props }
) => {
  if (props.column.name === 'product') {
    return <ProductCell row={row} {...props} />;
  }

  if (props.column.name === 'subscription') {
    return (
      <Table.Cell {...props}>
        {!!row?.subscriptionProductName || !!row?.subscriptionVariantName ? (
          <>
            <div style={styles.subscriptionText}>{row?.subscriptionProductName}</div>
            <div style={styles.subscriptionText}>{row?.subscriptionVariantName}</div>
          </>
        ) : (
          '-'
        )}
      </Table.Cell>
    );
  }

  if (props.column.name === 'price') {
    return (
      <CellAsPriceInput
        value={row.value || '0.00'}
        style={{ margin: 0, width: 'auto' }}
        cellProps={props}
        onFinishCellEditing={(value) => onFinishPriceEditing(row, value)}
      />
    );
  }

  if (props.column.name === 'quantity') {
    return (
      <CellAsPriceInput
        value={(row.quantity && row.quantity) || 0}
        style={{ margin: 0, width: 'auto' }}
        cellProps={props}
        onFinishCellEditing={(value) => onFinishLimitEditing(row, value)}
      />
    );
  }

  if (props.column.name === 'period') {
    return (
      <CellAsSelectInput
        disabled={period === PERIODS.BI_WEEK}
        value={period === PERIODS.BI_WEEK ? PERIODS.BI_WEEK : row.period ? row.period : PERIODS.PER_WEEK}
        values={[PERIODS.PER_WEEK, PERIODS.BI_WEEK]}
        labels={{
          [PERIODS.PER_WEEK]: intl.formatMessage({ id: 'global.perWeek' }),
          [PERIODS.BI_WEEK]: intl.formatMessage({ id: 'global.biWeek' }),
        }}
        style={{ margin: 0, width: 'auto' }}
        cellProps={props}
        onFinishCellEditing={(value) => onFinishPeriodEditing(row, value)}
      />
    );
  }

  if (props.column.name === 'ordersQuantity') {
    return (
      <CellAsIntegerInput
        value={row?.ordersQuantity || '0'}
        style={{ margin: 0, width: 'auto' }}
        cellProps={props}
        onFinishCellEditing={(value) => {
          onFinishVariantDataEditing({ ordersQuantity: value }, row, value);
        }}
      />
    );
  }

  if (props.column.name === 'nextDate') {
    const hasSchedule = !row?.endDate;

    if (hasSchedule) {
      return (
        <CellAsDateInput
          value={row.nextDate || null}
          style={{ margin: 0, width: 'auto' }}
          cellProps={props}
          applyOnChange
          onFinishCellEditing={(value) => onFinishVariantDataEditing({ nextDate: value }, row, value)}
          datePickerProps={{
            open: true,
            minDate: new Date(),
          }}
        />
      );
    }

    return (
      <Table.Cell {...props}>
        {row?.nextDate ? moment(row.nextDate).format(process.env.REACT_APP_DATE_FORMAT_WITH_SHORT_DAY_OF_WEEK) : '-'}
      </Table.Cell>
    );
  }

  return <Table.Cell {...props} />;
};

export const TABLE_ACTION_TYPES = {
  NEXT_DATE: 'nextDate',
};

export const TableAction = (
  type = TABLE_ACTION_TYPES.NEXT_DATE,
  onFinishVariantDataEditing,
  onScheduleDataChange,
  intl,
  subscriptionData,
  { row, props }
) => {
  if (type === TABLE_ACTION_TYPES.NEXT_DATE) {
    const lineItemId = row.id;
    const hasSchedule = !row?.endDate;

    if (hasSchedule) {
      return null;
    }

    return (
      <CellAsScheduler
        asIcon
        icon={EventIcon}
        value={row.nextDate}
        style={{ margin: 0, width: 'auto' }}
        cellProps={props}
        forceReloadTable={props.reload}
        forVariant
        variantId={lineItemId}
        strictMode
        minDate={moment().add(1, 'days')}
        apiRetrieve={(onSuccess, onError) => apiGetSubscriptionSchedule(subscriptionData.id, onSuccess, onError)}
        onClose={(value) => {
          onFinishVariantDataEditing({ nextDate: value }, row, value);
        }}
        onFinishCellEditing={(value) => {
          onFinishVariantDataEditing({ nextDate: value }, row, value);
        }}
        onDateChange={(data, onSuccess, onError) => {
          apiChangeSubscriptionLineItemSchedule(subscriptionData.id, lineItemId, data, onSuccess, onError);
        }}
        onDateMoveSeries={(data, onSuccess, onError) => {
          apiMoveDateSeriesSubscriptionLineItemSchedule(subscriptionData.id, lineItemId, data, onSuccess, onError);
        }}
        onDateDelete={(data, onSuccess, onError) => {
          apiDeleteSingleSubscriptionLineItemScheduleDate(subscriptionData.id, lineItemId, data, onSuccess, onError);
        }}
        onScheduleDataChange={onScheduleDataChange}
      />
    );
  }
};

export default injectIntl(TableCell);
