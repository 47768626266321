class Variant {

  static TYPE_REGULAR = 'regular';
  static TYPE_SUBSCRIPTION = 'subscription';
	static OFFERED_RETAIL = 'retail';
	static OFFERED_WHOLESALE = 'wholesale';

  constructor(props = {}) {
    this.id = props.id || null;
    this.type = props.type || Variant.TYPE_REGULAR;
    this.subscriptionQuantity = props.subscriptionQuantity || '';
  }

  isRegular() {
    return this.type === Variant.TYPE_REGULAR;
  }

  isSubscription() {
    return this.type === Variant.TYPE_SUBSCRIPTION;
  }
}

export default Variant;
