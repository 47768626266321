const styles = theme => ({
  root: {
    overflowX: 'auto',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  invoice: {
    color: "#000000",
    textDecoration: 'none',
    '&:hover' : {
      textDecoration: 'underline'
    }
  },
  content: {
    flexGrow: 1,
    position: 'relative',
    backgroundColor: theme.palette.background.default,
  },
  cell: {
    textAlign: 'center'
  },
  headerCell: {
    textAlign: 'center'
  }
});

export default styles;