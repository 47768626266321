import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import SearchField from './SearchField';
import {
  PagingState,
  SelectionState,
  IntegratedPaging,
  SortingState,
  IntegratedSorting,
  SearchState,
  IntegratedFiltering,
  GroupingState,
  TableColumnVisibility,
  DataTypeProvider,
  IntegratedSelection,
  IntegratedGrouping,
  CustomTreeData,
  TreeDataState,
  SummaryState,
  IntegratedSummary,
  CustomPaging,
  RowDetailState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableGroupRow,
  TableHeaderRow,
  TableSelection,
  PagingPanel,
  Toolbar,
  SearchPanel,
  TableTreeColumn,
  TableSummaryRow,
  TableBandHeader,
  TableColumnResizing,
  TableRowDetail,
} from '@devexpress/dx-react-grid-material-ui';
import { Spinner, FilterToggle } from '../../components';
import {
  weightToUser,
  toPaper,
  numberWithSeparator,
  EXPANDED_ALL_STAGES,
  makeColumnWidthEntityArray,
  countWidthOfTable,
} from '../../helpers';
import { COLORS } from '../../helpers';
import ActionsColumn from './ActionsColumn';
import BatchControlsWrapper from './BatchControlsWrapper';
import DXFiltersFactory from './Filters/DXFiltersFactory';
import CollapseArrow from './CollapseArrow';
import { PaginationMeta } from '../../entities/PaginationMeta';
import GridDetailContainer from './GridDetailContainer';
import Tooltip from '@material-ui/core/Tooltip';

const CurrencyTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={({ value }) => {
      return toPaper(value);
    }}
    {...props}
  />
);

const QuantityTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={({ value }) => {
      return parseFloat(weightToUser(value)).toFixed(2);
    }}
    {...props}
  />
);

const QuantityWithSeparatorTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={({ value }) => {
      return numberWithSeparator(parseFloat(weightToUser(value)).toFixed(2));
    }}
    {...props}
  />
);

const ShippingDateTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={({ value }) =>
      value ? value.format(process.env.REACT_APP_DATE_FORMAT_WITH_FULL_DAY_OF_WEEK) : 'Not set'
    }
    {...props}
  />
);

const MMMDYYYYDateProvider = (props) => (
  <DataTypeProvider
    formatterComponent={({ value }) => (value ? moment.parseZone(value).format('MMM D, YYYY') : 'Not set')}
    {...props}
  />
);

const CreatedDateTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={({ value }) =>
      value ? value.format(process.env.REACT_APP_DATE_FORMAT_WITH_FULL_DAY_OF_WEEK) : 'Not set'
    }
    {...props}
  />
);

const DateTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={({ value }) =>
      value ? value.format(process.env.REACT_APP_DATE_FORMAT_WITH_FULL_DAY_OF_WEEK) : 'Not set'
    }
    {...props}
  />
);

const CellWithTooltip = (props) => (
  <DataTypeProvider
    formatterComponent={({ value }) => (
      <Tooltip title={value} placement="bottom-start">
        {/* span is important, should be component with ref accessibility */}
        <span>{value}</span>
      </Tooltip>
    )}
    {...props}
  />
);

const propTypes = {
  columnBands: PropTypes.array,
  onRef: PropTypes.func,
  filtersToggle: PropTypes.bool,
  actionsTitle: PropTypes.string,
  forceReload: PropTypes.bool,
  expandedGroups: PropTypes.array,
  allowServerSideFilters: PropTypes.bool,
  prepareRowsAfterLoad: PropTypes.func,
  batchControlsComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  showSelectAll: PropTypes.bool,
  enableSelection: PropTypes.bool,
  treeSelectionColumn: PropTypes.string,
  enableTreeSelection: PropTypes.bool,
  getTreeChildRows: PropTypes.func,
  enableTree: PropTypes.bool,
  enablePager: PropTypes.bool,
  enableToolbar: PropTypes.bool,
  totalSummaryItems: PropTypes.array,
  groupSummaryItems: PropTypes.array,
  pageSize: PropTypes.number,
  pageSizes: PropTypes.array,
  hiddenColumnNames: PropTypes.array,
  sorting: PropTypes.array,
  onChangeSorting: PropTypes.func,
  actions: PropTypes.array,
  onOpenWithFilter: PropTypes.func,
  onRowClick: PropTypes.func,
  cellRenderer: PropTypes.func,
  rowRenderer: PropTypes.func,
  serverSideFilters: PropTypes.array,
  clientSideFilters: PropTypes.array,
  clientSideFiltersProcessor: PropTypes.func,
  getExpandedRowIds: PropTypes.func,
  enableSearch: PropTypes.bool,
  enableRemoteSearch: PropTypes.bool,
  sortingColumnExtensions: PropTypes.array,

  currencyColumnNames: PropTypes.array,
  quantityColumnNames: PropTypes.array,
  quantityWithSeparatorColumnNames: PropTypes.array,
  dateColumnNames: PropTypes.array,
  actionsHeaderCellStyle: PropTypes.object,
  actionsCellStyle: PropTypes.object,
  summaryCalculator: PropTypes.func,
  tableSummaryRowMessages: PropTypes.object,
  treeSummaryItems: PropTypes.array,
  actionsColumnProps: PropTypes.object,
  allowCollapseAll: PropTypes.bool,
  headerCellProps: PropTypes.object,
  mmmDYYYDateColumnNames: PropTypes.array,
  actionsGroupButton: PropTypes.bool,
  columnWidths: PropTypes.any,
  onColumnWidthsChange: PropTypes.func,
  minColumnWidth: PropTypes.number,
  maxColumnWidth: PropTypes.number,
  tableWidth: PropTypes.number,
  flexibleTableWidth: PropTypes.bool,
  serverSidePagination: PropTypes.bool,
  stickyHeader: PropTypes.bool,
  stickyHeaderOffset: PropTypes.number,
  groupByOptions: PropTypes.array,
  onRemoveGroupBy: PropTypes.func,
  onOpenWithGroupBy: PropTypes.func,
  detailRowColumns: PropTypes.array,
  detailExpandedRowIds: PropTypes.array,
  detailRowColumnExtensions: PropTypes.array,
  detailRowsGetter: PropTypes.func,
  detailRowsPropertyName: PropTypes.string,
  onRemoveDetailRowsProperty: PropTypes.func,
  onOpenWithDetailRowsProperty: PropTypes.func,
  groupRowInlineSummaryItemComponent: PropTypes.func,
  onExpandClientGroupedRows: PropTypes.func,
  SearchFieldComponent: PropTypes.elementType,
  defaultColumnWidths: PropTypes.array,
  columnResizingMode: PropTypes.string,
  toolbarRootComponent: PropTypes.elementType,
  CustomHeaderComponent: PropTypes.elementType,
  TableStubCellComponent: PropTypes.elementType,
  tooltipColumns: PropTypes.array,
  noDataCellComponentMessage: PropTypes.string,
  NoDataCellComponentMessageComponent: PropTypes.elementType,
  ToolbarFlexibleSpaceComponent: PropTypes.elementType,
  NoDataCellComponent: PropTypes.elementType,
  defaultSearchValue: PropTypes.string,
};

const defaultProps = {
  enableSearch: true,
  enableRemoteSearch: false,

  filtersToggle: true,
  enableToolbar: true,
  enablePager: true,
  enableTree: false,
  totalSummaryItems: [],
  groupSummaryItems: [],
  tableSummaryRowMessages: {},
  enableTreeSelection: false,
  treeSelectionColumn: 'name',
  showSelectAll: true,
  enableSelection: false,
  allowServerSideFilters: true,
  forceReload: false,
  hiddenColumnNames: [],
  sorting: [],
  serverSideFilters: [],
  clientSideFilters: [],
  treeSummaryItems: [],
  actions: [],
  actionsGroupButton: false,
  sortingColumnExtensions: [],
  actionsHeaderCellStyle: {},
  actionsCellStyle: {},
  mmmDYYYDateColumnNames: [],
  columnWidths: null,
  onColumnWidthsChange: () => {},
  minColumnWidth: 10,
  maxColumnWidth: 1000,
  currencyColumnNames: [
    'total',
    'subtotal',
    'price',
    'shippingFee',
    'deliveryCompanyFee',
    //'shipping.fee',
    'prepurchasedUnitPrice',
    'prepurchasedPrice',
    'avgPrice',
    'listPrice',
  ],
  quantityColumnNames: [],
  quantityWithSeparatorColumnNames: [],
  dateColumnNames: [],
  allowCollapseAll: false,
  rowRenderer: (onClick, { row, ...props }) => {
    return (
      <Table.Row
        {...props}
        onClick={() => onClick && onClick(row)}
        style={{
          ...(onClick ? { cursor: 'pointer' } : {}),
        }}
      />
    );
  },
  cellRenderer: (reload, props) => {
    return <Table.Cell {...props} />;
  },
  flexibleTableWidth: false,
  serverSidePagination: false,

  stickyHeader: false,
  stickyHeaderOffset: 0,

  detailExpandedRowIds: [],
};

const TO_LONG_LOADING_DELAY = 3000; // after 3000ms show extend loading message

class AdminDXTable extends React.PureComponent {
  constructor(props) {
    super(props);

    const {
      columns,
      onRef,
      quantityColumnNames,
      quantityWithSeparatorColumnNames,
      currencyColumnNames,
      dateColumnNames,
      treeSummaryItems,
      defaultSearchValue,
      enableRemoteSearch,
    } = props;

    onRef && onRef(this);

    this.searchTimer = null;

    const tableWidth = props.flexibleTableWidth
      ? countWidthOfTable(
          props.columnWidths,
          props.hiddenColumnNames,
          props.enableSelection,
          props.enableTreeSelection,
          props.actions
        )
      : 'calc(100% + 1px)';

    this.state = {
      batchControls: false,
      searchValue: enableRemoteSearch ? defaultSearchValue : '',
      savedSearchValue: defaultSearchValue || '',
      treeSummaryItems: treeSummaryItems,
      columns: this.generateColumns(columns),
      quantityColumns: this.defineColumns(quantityColumnNames, columns),
      quantityWithSeparatorColumn: this.defineColumns(quantityWithSeparatorColumnNames, columns),
      currencyColumns: this.defineColumns(currencyColumnNames, columns),
      dateColumns: this.defineColumns(dateColumnNames, columns),
      createdDateColumns: columns.some((column) => column.name === 'createdAt') ? ['createdAt'] : undefined,
      shippingDateColumns: ['shipping'],
      tableColumnExtensions: props.columnExtensions,
      groupingColumns: props.groupingColumns,
      rows: [],
      initialRows: [],
      totalSummaryItems: props.totalSummaryItems,
      groupSummaryItems: props.groupSummaryItems,
      sorting: props.sorting,
      totalCount: 0,
      pageSize: Number.isInteger(props.pageSize) ? props.pageSize : 10,
      pageSizes: props.pageSizes || [10, 15, 20, 0],
      currentPage: 0,
      loading: true,
      loadingToLong: false,
      forceReload: props.forceReload,
      selection: [],
      expandedRowIds: [],
      collapsedAll: false,
      expandedGroups: props.expandedGroups,
      allGroupsIds: [],
      expandedAllState: EXPANDED_ALL_STAGES.EXPAND_FIRST_LEVEL,
      tableWidth,
      paginationMeta: new PaginationMeta({}),
      detailExpandedRowIds: props.detailExpandedRowIds,
    };

    this.tableWrapper = null;
  }

  resetColumns = (columns) => {
    const { quantityColumnNames, quantityWithSeparatorColumnNames, currencyColumnNames, dateColumnNames } = this.props;

    this.setState(
      {
        columns: this.generateColumns(columns),
        quantityColumns: this.defineColumns(quantityColumnNames, columns),
        quantityWithSeparatorColumn: this.defineColumns(quantityWithSeparatorColumnNames, columns),
        currencyColumns: this.defineColumns(currencyColumnNames, columns),
        dateColumns: this.defineColumns(dateColumnNames, columns),
        createdDateColumns: columns.some((column) => column.name === 'createdAt') ? ['createdAt'] : undefined,
      },
      () => {
        this.forceReload();
      }
    );
  };

  componentWillUnmount() {
    clearTimeout(this.searchTimer);
  }

  componentDidMount() {
    this.loadData(this.prepareQueryParams());
  }

  prepareQueryParams = (additionalParams) => {
    const { serverSideFilters, serverSidePagination, enableRemoteSearch } = this.props;
    const { searchValue, currentPage, pageSize } = this.state;

    let queryParams = {};
    if (serverSideFilters && serverSideFilters.length) {
      serverSideFilters.forEach((filter) => {
        if (filter && !filter.clientSide && filter.selectedValues) {
          try {
            const Filter = DXFiltersFactory.getFilterByName(filter.name);
            queryParams = Filter.applyToQueryParams(queryParams, filter.selectedValues, filter);
          } catch (e) {
            queryParams[filter.property] = filter.selectedValues;
          }
        }
      });
    }

    if (!!serverSidePagination) {
      queryParams['limit'] = pageSize;
      queryParams['skip'] = currentPage * pageSize;
    }

    if (enableRemoteSearch && searchValue) {
      queryParams['query'] = searchValue;
    }

    if (additionalParams) {
      queryParams = { ...queryParams, ...additionalParams };
    }

    return queryParams;
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      allowServerSideFilters,
      clientSideFilters,
      clientSideFiltersProcessor,
      serverSideFilters,
      enableRemoteSearch,
      intl,
    } = this.props;

    const { initialRows } = this.state;

    if (this.state.forceReload) {
      this.setState({
        forceReload: false,
        selection: [],
      });
      this.loadData(this.prepareQueryParams());
    }

    /** on hidden column change */
    if (this.props?.hiddenColumnNames?.length !== prevProps?.hiddenColumnNames?.length) {
      this.updateTableWidth(this.props.columnWidths);
    }

    /** Server side value filter */
    if (enableRemoteSearch && this.state.searchValue !== prevState.searchValue) {
      this.setState({ selection: [], currentPage: 0 }, () => {
        this.loadData(this.prepareQueryParams());
      });
    }

    /** Server side filters on change */
    if (allowServerSideFilters && JSON.stringify(serverSideFilters) !== JSON.stringify(prevProps.serverSideFilters)) {
      this.setState({ selection: [], currentPage: 0 }, () => {
        this.loadData(this.prepareQueryParams());
      });
    }

    /** Client side filters */
    if (JSON.stringify(prevProps.clientSideFilters) !== JSON.stringify(clientSideFilters)) {
      clientSideFiltersProcessor &&
        clientSideFiltersProcessor(intl, clientSideFilters.slice(), initialRows.slice(), this.onUpdateRowsExternally);
    }
  }

  loadData(queryParams = {}, onLoaded) {
    const {
      clientSideFilters,
      clientSideFiltersProcessor,
      prepareRowsAfterLoad,
      getExpandedRowIds,
      intl,
      serverSidePagination,
    } = this.props;

    this.setState({ loading: true });
    const toLongTimer = setTimeout(() => {
      this.setState({ loadingToLong: true });
    }, TO_LONG_LOADING_DELAY);

    this.props.apiRetrieve(
      queryParams,
      (_originalRows) => {
        clearTimeout(toLongTimer);
        let originalRows;
        let meta;
        if (!!serverSidePagination) {
          originalRows = _originalRows?.data || _originalRows;
          meta = _originalRows?.meta || new PaginationMeta({ total: 100 });
        } else {
          originalRows = _originalRows;
        }

        let rows = originalRows;
        let expandedGroups = this.state.expandedGroups;
        if (prepareRowsAfterLoad) {
          let prepared = prepareRowsAfterLoad(originalRows);
          rows = prepared.rows || rows;
          expandedGroups = prepared.expandedGroups || this.state.expandedGroups;
        }
        this.setState(
          {
            rows: rows,
            initialRows: rows,
            expandedRowIds: (getExpandedRowIds && getExpandedRowIds(rows)) || rows.map((item) => item.id),
            expandedGroups: expandedGroups,
            allGroupsIds:
              expandedGroups || (getExpandedRowIds && getExpandedRowIds(rows)) || rows.map((item) => item.id),
            loading: false,
            loadingToLong: false,
            paginationMeta: new PaginationMeta(meta),
          },
          () => {
            onLoaded && onLoaded();
          }
        );
        // Apply client side filters
        clientSideFiltersProcessor &&
          clientSideFiltersProcessor(intl, clientSideFilters.slice(), rows.slice(), this.onUpdateRowsExternally);
      },
      undefined
    );
  }

  defineColumns(columnNames, columns) {
    let prepared = [];
    columns.forEach((column) => {
      if (columnNames.indexOf(column.name) !== -1) {
        prepared.push(column.name);
      }
    });
    return prepared.length ? prepared : undefined;
  }

  generateColumns(columns) {
    const cellValueGetters = {
      createdAt: (row) => moment.parseZone(row.createdAt),
      customer: (row) => (row.customer ? row.customer.companyName : 'Not set'),
      shipping: (row) => (row.shipping ? moment.parseZone(row.shipping.date) : undefined),
      deliveryCompany: (row) => (row.shipping && row.shipping.deliveryCompany ? row.shipping.deliveryCompany.name : ''),
      'shipping.type': (row) =>
        row.shipping.type + (row.shipping.deliveryCompany ? ' — ' + row.shipping.deliveryCompany : ''),
      //'shipping.fee': (row => row.shipping && row.shipping.fee ? toPaper(row.shipping.fee) : "0.00"),
      paymentMethod: (row) => (row.payment && row.payment.method ? row.payment.method.method : undefined),
      lineItemName: (row) =>
        typeof row.lineItemName === 'object'
          ? row.lineItemName.product + (!!row?.lineItemName?.variant ? ', ' + row.lineItemName.variant : '')
          : row.lineItemName,
    };

    let columnsMap = [];
    columns.forEach((column) => {
      let getter = cellValueGetters[column.name];
      if (getter !== undefined) {
        column.getCellValue = getter;
      }
      columnsMap.push(column);
    });

    return columnsMap;
  }

  handleChangeInlineDateFilter(value) {
    let queryParams = {};
    if (value.from) {
      queryParams['shipping:from'] = value.from;
    }

    if (value.to) {
      queryParams['shipping:to'] = value.to;
    }

    this.setState({ loading: true });
    this.loadData(this.prepareQueryParams(queryParams));
  }

  onChangeSorting = (sorting) => {
    const { onChangeSorting } = this.props;
    const { searchValue } = this.state;

    /** If searchValue is not empty we should disable sorting */
    this.setState({
      sorting: searchValue ? [] : sorting,
    });

    onChangeSorting && onChangeSorting(sorting);
  };

  onChangeSelection = (selection) => {
    this.setState({
      selection: selection,
      selectedRowsData: this.state.rows.filter((row) => selection.findIndex((selectId) => selectId === row.id) !== -1),
    });
  };

  onUpdateRowsExternally = (rows) => {
    this.setState({
      rows: rows,
      selection: [],
    });
  };

  forceReload = () => {
    this.setState({ forceReload: true });
  };

  toggleTreeRows = () => {
    const { rows, expandedRowIds } = this.state;

    this.setState({
      collapsedAll: !!expandedRowIds.length,
      expandedRowIds: !!expandedRowIds.length ? [] : rows.map((item) => item.id),
    });
  };

  toggleClientGroupedRows = () => {
    const { detailRowsPropertyName } = this.props;

    this.setState((state) => {
      let data = {};
      let expandedAllState = state.expandedAllState;

      switch (expandedAllState) {
        case EXPANDED_ALL_STAGES.NOT_EXPANDED: {
          if (!!state.groupingColumns?.length && !!detailRowsPropertyName) {
            expandedAllState = EXPANDED_ALL_STAGES.EXPAND_FIRST_LEVEL;
            data.expandedGroups = state.allGroupsIds;
          } else {
            expandedAllState = EXPANDED_ALL_STAGES.EXPAND_ALL_LEVELS;
            if (!!state?.allGroupsIds?.length) {
              data.expandedGroups = state.allGroupsIds;
            }
            data.detailExpandedRowIds = state.rows.map((item) => item.id);
          }

          break;
        }
        case EXPANDED_ALL_STAGES.EXPAND_FIRST_LEVEL: {
          expandedAllState = EXPANDED_ALL_STAGES.EXPAND_ALL_LEVELS;
          data.detailExpandedRowIds = state.rows.map((item) => item.id);
          break;
        }
        case EXPANDED_ALL_STAGES.EXPAND_ALL_LEVELS: {
          expandedAllState = EXPANDED_ALL_STAGES.NOT_EXPANDED;
          data.expandedGroups = [];
          data.detailExpandedRowIds = [];
          expandedAllState = EXPANDED_ALL_STAGES.NOT_EXPANDED;
          break;
        }

        default: {
          data.expandedGroups = [];
          data.detailExpandedRowIds = [];
          expandedAllState = EXPANDED_ALL_STAGES.NOT_EXPANDED;
        }
      }

      return {
        ...state,
        collapsedAll: expandedAllState !== EXPANDED_ALL_STAGES.NOT_EXPANDED,
        expandedAllState,
        ...data,
      };
    });
  };

  customToggleClientGroupedRows = () => {
    const { expandedAllState, expandedGroups, rows } = this.state;

    const result = this.props.onExpandClientGroupedRows(rows, expandedGroups, expandedAllState);

    this.setState({
      expandedGroups: result.expandedGroups,
      expandedAllState: result.expandedAllState,
      collapsedAll: result.collapsedAll,
      ...(result.detailExpandedRowIds ? { detailExpandedRowIds: result.detailExpandedRowIds } : {}),
    });

    return result;
  };

  treeExpandRows = () => {
    const { rows, expandedAllState, expandedRowIds } = this.state;

    switch (expandedAllState) {
      case EXPANDED_ALL_STAGES.NOT_EXPANDED: {
        this.setState({
          expandedAllState: EXPANDED_ALL_STAGES.EXPAND_FIRST_LEVEL,
          expandedRowIds: [],
        });
        break;
      }
      case EXPANDED_ALL_STAGES.EXPAND_FIRST_LEVEL: {
        this.setState({
          expandedAllState: EXPANDED_ALL_STAGES.EXPAND_SECOND_LEVEL,
          expandedRowIds: rows.map((item) => item.id),
        });
        break;
      }
      case EXPANDED_ALL_STAGES.EXPAND_SECOND_LEVEL: {
        this.setState({
          expandedAllState: EXPANDED_ALL_STAGES.EXPAND_ALL_LEVELS,
          expandedRowIds: [
            ...expandedRowIds,
            ...(rows?.length && rows[0]?.expandSecondLevel ? rows[0].expandSecondLevel : []),
          ],
        });
        break;
      }
      case EXPANDED_ALL_STAGES.EXPAND_ALL_LEVELS: {
        this.setState({
          expandedAllState: EXPANDED_ALL_STAGES.NOT_EXPANDED,
          expandedRowIds: [
            ...expandedRowIds,
            ...(rows?.length && rows[0]?.expandAllLevels ? rows[0].expandAllLevels : []),
          ],
        });
        break;
      }
      default: {
        this.setState({
          expandedAllState: EXPANDED_ALL_STAGES.NOT_EXPANDED,
          expandedRowIds: [],
        });
      }
    }
  };

  getRows = () => {
    return this.state.rows;
  };

  headerCell = (cellProps) => {
    const { headerCellProps } = this.props;

    if (cellProps.column.name === 'selection' && this.props.allowCollapseAll) {
      return (
        <TableHeaderRow.Cell {...cellProps}>
          <CollapseArrow onToggle={this.toggleTreeRows} collapsed={this.state.collapsedAll} />
          {cellProps.children}
        </TableHeaderRow.Cell>
      );
    }

    if (cellProps.column.name === 'switcher' && this.props.allowExpandAll) {
      return (
        <TableHeaderRow.Cell {...cellProps}>
          <CollapseArrow
            onToggle={this.treeExpandRows}
            collapsed={this.state.expandedAllState === EXPANDED_ALL_STAGES.EXPAND_FIRST_LEVEL}
          />
          {cellProps.children}
        </TableHeaderRow.Cell>
      );
    }
    return <TableHeaderRow.Cell {...cellProps} {...headerCellProps} />;
  };

  onChangeSearchValue = (searchValue, immediate = false) => {
    clearTimeout(this.searchTimer);

    // Prevent search the same query
    if (searchValue === this.state.searchValue) {
      return;
    }

    if (immediate) {
      this.setState({
        loading: true,
        searchValue,
        savedSearchValue: searchValue,
        sorting: searchValue ? [] : this.props.sorting,
      });
      return;
    }

    this.searchTimer = setTimeout(() => {
      this.setState({
        loading: true,
        searchValue,
        savedSearchValue: searchValue,
        sorting: searchValue ? [] : this.props.sorting,
      });
    }, 1000);
  };

  onChangeSavedSearchValue = (savedSearchValue) => {
    this.setState({
      savedSearchValue,
    });
  };

  updateTableWidth = (newColumnWidths) => {
    !!this.props?.flexibleTableWidth &&
      this.setState({
        tableWidth: countWidthOfTable(
          newColumnWidths,
          this.props?.hiddenColumnNames,
          this.props?.enableSelection,
          this.props?.enableTreeSelection,
          this.props?.actions
        ),
      });
  };

  onDetailExpandedRowIdsChange = (expanded) => {
    this.setState({ detailExpandedRowIds: expanded });
  };

  drawSearchFieldComponent = (props) => {
    const { SearchFieldComponent } = this.props;

    return (
      <SearchFieldComponent {...props} searchValue={this.state.searchValue} onValueChange={this.onChangeSearchValue} />
    );
  };

  onCurrentPageChange = (newCurrentPage) => {
    this.setState(
      {
        currentPage: newCurrentPage,
      },
      () => {
        this.loadData(this.prepareQueryParams());
      }
    );
  };

  render() {
    const {
      rows,
      columns,
      currencyColumns,
      quantityColumns,
      quantityWithSeparatorColumn,
      createdDateColumns,
      shippingDateColumns,
      tableColumnExtensions,
      groupingColumns,
      sorting,
      pageSize,
      pageSizes,
      currentPage,
      loading,
      loadingToLong,
      selection,
      expandedRowIds,
      totalSummaryItems,
      groupSummaryItems,
      treeSummaryItems,
      dateColumns,
      tableWidth,
      paginationMeta,
      detailExpandedRowIds,
    } = this.state;

    const {
      intl,
      serverSideFilters,
      onRemoveFilter,
      onOpenWithFilter,
      hiddenColumnNames,
      actions,
      enableSelection,
      showSelectAll,
      batchControlsComponent,
      onRowClick,
      enableTreeSelection,
      enableTree,
      getTreeChildRows,
      treeSelectionColumn,
      cellRenderer,
      rowRenderer,
      clientSideFilters,
      actionsTitle,
      enableSearch,
      enableRemoteSearch,
      actionsHeaderCellStyle,
      actionsCellStyle,
      filtersToggle,
      enableToolbar,
      enablePager,
      summaryCalculator,
      tableSummaryRowMessages,
      columnBands,
      actionsColumnProps,
      sortingColumnExtensions,
      mmmDYYYDateColumnNames,
      actionsGroupButton,
      columnWidths,
      onColumnWidthsChange,
      minColumnWidth,
      maxColumnWidth,
      serverSidePagination,
      stickyHeader,
      stickyHeaderOffset,
      groupByOptions,
      onRemoveGroupBy,
      onOpenWithGroupBy,
      detailRowColumns,
      detailRowColumnExtensions,
      detailRowsGetter,
      detailRowsPropertyName,
      onRemoveDetailRowsProperty,
      onOpenWithDetailRowsProperty,
      groupRowInlineSummaryItemComponent,
      onExpandClientGroupedRows,
      SearchFieldComponent,
      defaultColumnWidths,
      columnResizingMode,
      toolbarRootComponent,
      CustomHeaderComponent,
      tooltipColumns,
      hideTableOnLoading,
      LoadingComponent,
      TableStubCellComponent,
      NoDataCellComponent,
      noDataCellComponentMessage,
      NoDataCellComponentMessageComponent,
      treeCheckboxRenderer,
      ToolbarFlexibleSpaceComponent,
      defaultSearchValue,
    } = this.props;
    const stickyStyles = { backgroundColor: '#fff', position: 'sticky', top: stickyHeaderOffset, zIndex: 1000 };

    const getRows = () => {
      if (hideTableOnLoading && loading) {
        // Hide all table data is loading with hideTableOnLoading prop
        return [];
      }

      return rows;
    };

    return (
      <div style={{ width: tableWidth }} ref={(ref) => (this.tableWrapper = ref)}>
        <Grid rows={getRows()} columns={columns} getRowId={(row) => row.id}>
          {enableTree && (
            <TreeDataState
              onExpandedRowIdsChange={(expandedRowIds) => {
                this.setState({
                  expandedRowIds,
                  collapsedAll: expandedRowIds.length === 0,
                });
              }}
              expandedRowIds={expandedRowIds}
            />
          )}

          {enableSearch && (
            <SearchState
              {...(defaultSearchValue !== undefined
                ? {
                    defaultValue: defaultSearchValue,
                    onValueChange: this.onChangeSavedSearchValue,
                  }
                : {})}
              {...(enableRemoteSearch ? { onValueChange: this.onChangeSearchValue } : {})}
            />
          )}

          {!enableRemoteSearch && <IntegratedFiltering />}

          {currencyColumns && <CurrencyTypeProvider for={currencyColumns} intl={intl} />}
          {quantityColumns && <QuantityTypeProvider for={quantityColumns} />}
          {quantityWithSeparatorColumn && <QuantityWithSeparatorTypeProvider for={quantityWithSeparatorColumn} />}
          {createdDateColumns && <CreatedDateTypeProvider for={createdDateColumns} />}
          {shippingDateColumns && <ShippingDateTypeProvider for={shippingDateColumns} />}
          {mmmDYYYDateColumnNames && <MMMDYYYYDateProvider for={mmmDYYYDateColumnNames} />}
          {dateColumns && <DateTypeProvider for={dateColumns} />}
          {tooltipColumns && <CellWithTooltip for={tooltipColumns} />}

          {detailRowColumns && (
            <RowDetailState
              expandedRowIds={detailExpandedRowIds}
              onExpandedRowIdsChange={this.onDetailExpandedRowIdsChange}
            />
          )}

          {(enableSelection || enableTreeSelection) && (
            <SelectionState selection={selection} onSelectionChange={this.onChangeSelection} />
          )}

          {(!!totalSummaryItems.length || !!treeSummaryItems.length || !!groupSummaryItems.length) && (
            <SummaryState totalItems={totalSummaryItems} treeItems={treeSummaryItems} groupItems={groupSummaryItems} />
          )}

          <SortingState sorting={sorting} onSortingChange={this.onChangeSorting} />
          <IntegratedSorting columnExtensions={sortingColumnExtensions} />
          {(enableSelection || enableTreeSelection) && <IntegratedSelection />}

          {groupingColumns && (
            <GroupingState
              grouping={groupingColumns}
              expandedGroups={this.state.expandedGroups}
              onExpandedGroupsChange={(expandedGroups) => {
                this.setState({ expandedGroups });
              }}
            />
          )}
          {groupingColumns && <IntegratedGrouping />}

          {(!!totalSummaryItems.length || !!treeSummaryItems.length || !!groupSummaryItems.length) &&
            (summaryCalculator ? <IntegratedSummary calculator={summaryCalculator} /> : <IntegratedSummary />)}

          {enableTree && <CustomTreeData getChildRows={getTreeChildRows} />}

          {enablePager && (
            <PagingState
              defaultCurrentPage={currentPage}
              defaultPageSize={pageSize}
              {...(!!serverSidePagination
                ? {
                    currentPage: currentPage,
                    onCurrentPageChange: this.onCurrentPageChange,
                    onPageSizeChange: (newPageSize) => {
                      this.setState(
                        {
                          pageSize: newPageSize,
                          currentPage: 0,
                        },
                        () => {
                          this.loadData(this.prepareQueryParams());
                        }
                      );
                    },
                  }
                : {})}
            />
          )}

          {enablePager && !serverSidePagination && <IntegratedPaging />}

          {enablePager && !!serverSidePagination && <CustomPaging totalCount={paginationMeta.total} />}

          <Table
            cellComponent={(props) =>
              cellRenderer(
                () => this.setState({ forceReload: true }),
                props,
                rows.slice(),
                this.onUpdateRowsExternally,
                this
              )
            }
            rowComponent={(props) => rowRenderer(onRowClick, props)}
            columnExtensions={tableColumnExtensions}
            noDataCellComponent={(props) =>
              loading && !!LoadingComponent ? (
                <Table.Cell {...props} style={{ padding: 0 }}>
                  <LoadingComponent />
                </Table.Cell>
              ) : (
                !!NoDataCellComponent ? (
                  <NoDataCellComponent {...props} loading={loading} loadingToLong={loadingToLong} />
                ) : (
                  <Table.NoDataCell
                    {...props}
                    getMessage={() => {
                      if (!!loadingToLong && !!loading) return intl.formatMessage({ id: 'global.stillLoading' });
                      if (!loading)
                        return NoDataCellComponentMessageComponent ? (
                          <NoDataCellComponentMessageComponent />
                        ) : (
                          intl.formatMessage({
                            id: noDataCellComponentMessage ? noDataCellComponentMessage : 'global.noData',
                          })
                        );
                    }}
                  />
                )
              )
            }
            {...(stickyHeader && {
              containerComponent: (props) => <Table.Container {...props} style={{ overflow: 'unset' }} />,
              headComponent: (props) => <Table.TableHead {...props} style={{ ...stickyStyles }} />,
            })}
            {...(TableStubCellComponent ? { stubCellComponent: TableStubCellComponent } : {})}
          />

          <TableColumnVisibility
            defaultHiddenColumnNames={hiddenColumnNames}
            hiddenColumnNames={hiddenColumnNames}
            emptyMessageComponent={() => null}
          />

          {(columnWidths || defaultColumnWidths) && (
            <TableColumnResizing
              defaultColumnWidths={defaultColumnWidths}
              minColumnWidth={minColumnWidth}
              maxColumnWidth={maxColumnWidth}
              {...(!!columnWidths
                ? {
                    columnWidths,
                    onColumnWidthsChange: (newColumnWidths) => {
                      this.updateTableWidth(newColumnWidths);
                      onColumnWidthsChange(makeColumnWidthEntityArray(newColumnWidths));
                    },
                  }
                : {})}
              {...(columnResizingMode ? { resizingMode: columnResizingMode } : {})}
            />
          )}

          {CustomHeaderComponent ? (
            CustomHeaderComponent
          ) : (
            <TableHeaderRow showSortingControls cellComponent={(...props) => this.headerCell(...props)} />
          )}

          {enableTree && (
            <TableTreeColumn
              for={treeSelectionColumn}
              showSelectionControls={enableTreeSelection}
              showSelectAll={enableTreeSelection}
              cellComponent={(props) => {
                return (
                  <TableTreeColumn.Cell
                    {...props}
                    children={props?.children?.map((children) => {
                      if (!children?.props) {
                        return children;
                      }
                      return {
                        ...children,
                        props: { ...children.props, row: props.row },
                      };
                    })}
                  />
                );
              }}
              {...(treeCheckboxRenderer ? { checkboxComponent: treeCheckboxRenderer } : {})}
            />
          )}

          {enableSelection && (
            <TableSelection
              showSelectAll={showSelectAll}
              headerCellComponent={({ row, onToggle, children, allSelected, someSelected, ...others }) => {
                return (
                  <>
                    {(groupingColumns || detailRowColumns) && this.props.allowCollapseAll ? (
                      <TableHeaderRow.Cell {...others}>
                        <CollapseArrow
                          onToggle={
                            onExpandClientGroupedRows
                              ? this.customToggleClientGroupedRows
                              : this.toggleClientGroupedRows
                          }
                          collapsed={this.state.collapsedAll}
                          inSubCell
                        />
                        <TableSelection.HeaderCell
                          row={row}
                          onToggle={onToggle}
                          allSelected={allSelected}
                          someSelected={someSelected}
                          {...others}
                        />
                      </TableHeaderRow.Cell>
                    ) : (
                      <TableSelection.HeaderCell
                        row={row}
                        onToggle={onToggle}
                        allSelected={allSelected}
                        someSelected={someSelected}
                        {...others}
                      />
                    )}
                  </>
                );
              }}
              cellComponent={({ row, selected, onToggle, children, ...others }) => (
                <TableSelection.Cell
                  row={row}
                  style={{ color: COLORS.green }}
                  onToggle={onToggle}
                  selected={selected}
                  {...others}>
                  {children}
                </TableSelection.Cell>
              )}
            />
          )}

          {detailRowColumns && (
            <TableRowDetail
              contentComponent={(props) => (
                <GridDetailContainer
                  {...props}
                  detailRowColumns={detailRowColumns}
                  detailRowColumnExtensions={detailRowColumnExtensions}
                  detailRowsGetter={detailRowsGetter}
                  detailRowsPropertyName={detailRowsPropertyName}
                />
              )}
            />
          )}

          {groupingColumns && (
            <>
              <TableGroupRow
                stubCellComponent={() => null}
                {...(!!groupRowInlineSummaryItemComponent && {
                  inlineSummaryItemComponent: groupRowInlineSummaryItemComponent,
                })}
              />
            </>
          )}

          {enablePager && <PagingPanel pageSizes={pageSizes} />}

          {enableToolbar && (
            <Toolbar
              {...(toolbarRootComponent
                ? {
                    rootComponent: toolbarRootComponent,
                  }
                : {})
              }
              {...(ToolbarFlexibleSpaceComponent
                ? {
                  flexibleSpaceComponent: (props) => <ToolbarFlexibleSpaceComponent {...props} rows={rows} />,
                }
                : {})
              }
            />
          )}

          {!!actions.length && hiddenColumnNames.indexOf('actions') === -1 && (
            <ActionsColumn
              actions={actions}
              actionsGroupButton={actionsGroupButton}
              headerCellStyle={actionsHeaderCellStyle}
              cellStyle={actionsCellStyle}
              title={actionsTitle}
              reload={() => this.setState({ forceReload: true })}
              {...actionsColumnProps}
            />
          )}

          {filtersToggle && (
            <FilterToggle
              filters={[...serverSideFilters, ...clientSideFilters]}
              onRemoveFilter={onRemoveFilter}
              onOpenWithFilter={onOpenWithFilter}
              groupByOptions={groupByOptions}
              onRemoveGroupBy={onRemoveGroupBy}
              onOpenWithGroupBy={onOpenWithGroupBy}
              detailRowsPropertyName={detailRowsPropertyName}
              onRemoveDetailRowsProperty={onRemoveDetailRowsProperty}
              onOpenWithDetailRowsProperty={onOpenWithDetailRowsProperty}
            />
          )}

          {batchControlsComponent && (
            <BatchControlsWrapper
              keys={selection}
              rows={rows}
              reload={() => this.setState({ forceReload: true })}
              component={batchControlsComponent}
            />
          )}

          {(!!totalSummaryItems.length || !!treeSummaryItems.length || !!groupSummaryItems.length) && (
            <TableSummaryRow
              formatlessSummaryTypes={Object.keys(tableSummaryRowMessages)}
              messages={tableSummaryRowMessages}
            />
          )}

          {enableSearch && (
            <SearchPanel inputComponent={SearchFieldComponent ? this.drawSearchFieldComponent : SearchField} />
          )}

          {loading && !NoDataCellComponent && !LoadingComponent && <Spinner size={30} />}

          {columnBands && <TableBandHeader columnBands={columnBands} />}
        </Grid>
      </div>
    );
  }
}

AdminDXTable.propTypes = propTypes;
AdminDXTable.defaultProps = defaultProps;

export default injectIntl(AdminDXTable);
