export function makeServerValue(value) {
  return Number.parseInt(value * 100);
}

export function makeClientValue(value) {
  const newValue = Number.parseFloat(value) / 100;
  const isFloat = !isInt(newValue);

  return isFloat ? newValue.toFixed(2) : newValue;
}

export function isInt(n) {
  return n % 1 === 0;
}

export function getValueForNumberTextInput(event) {
  const value = typeof event === 'object' ? event?.target?.value : event;
  const numberValue = Math.abs(value);
  return !!numberValue ? numberValue : value;
}

export function getValueForInventoryQuantity(event) {
  const value = typeof event === 'object' ? event?.target?.value : event;
  return Number.parseInt(value).toString();
}
