export const filterObject = (obj, predicate) => {
  let result = {},
    key;
  for (key in obj) {
    if (obj.hasOwnProperty(key) && !predicate(obj[key])) {
      result[key] = obj[key];
    }
  }
  return result;
};

/**
 * Access to object value by path
 * @param object - any object
 * @param path - format 'a.b.c' to access object.a.b.c prop
 * @param defaultValue - return if object not find
 */
export const getChainObjectByPath = (object, path, defaultValue) =>
  path.split('.').reduce((o, p) => (o ? o[p] : defaultValue), object);
