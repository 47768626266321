export const COLORS = {
  black: '#212121',
  white: '#ffffff',
  border: '#e6e6e6',
  lightGray: '#ececec',
  gray: '#CACED5',
  green: '#41CD8C',
  blue: '#2464d2',
  headerText: '#4D4F5C',
  text: '#363636',
  textSecondary: '#1F1F1F',
  cartSecondary: '#707070',
  violet: '#C51162',
  actionLabel: '#A3A6B4',
};
