
import { Customer } from "../../entities";

export const SET_CUSTOMER = 'CUSTOMERS/SET_CUSTOMER'
export const UPDATE_CUSTOMER = 'CUSTOMERS/UPDATE_CUSTOMER'
export const REPLACE_CUSTOMER = 'CUSTOMERS/REPLACE_CUSTOMER'

const initialState = {
  customer: new Customer()
}

export default function (state = initialState, action) {

  switch (action.type) {
    case SET_CUSTOMER:
      return {
        ...state,
        customer: new Customer(action.customer)
      }

    case UPDATE_CUSTOMER:
      return {
        ...state,
        customer: new Customer({ ...state.customer, ...action.valuesToUpdate })
      }

    case REPLACE_CUSTOMER:
      return {
        ...state,
        customer: new Customer(action.customer)
      }

    default:
      return state
  }
}
