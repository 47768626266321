import React from 'react';
import NavigationMenu from './components/NavigationMenu';
import HeaderArea from './components/HeaderArea';
import Title from './components/Title';
import { makeStyles } from '@material-ui/core/styles';
import { PADDING_CONTENT } from './constants/globals';
import { useIntl } from 'react-intl';
import NumberKeyboard from './components/NumberKeyboard';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  content: {
    paddingLeft: PADDING_CONTENT,
    paddingRight: PADDING_CONTENT,
  },
  title: {
    marginBottom: 20,
  },
}));

const SupplierContactScene = () => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <HeaderArea />

      <div className={classes.content}>
        <Title className={classes.title} text={intl.formatMessage({ id: 'supplier.contact.title' })} />

        <NumberKeyboard quantity={10} onChange={() => {}} label={''} coloredLabel={false} />

        <NavigationMenu />
      </div>
    </div>
  );
};

export default SupplierContactScene;
