import React from 'react';
import { COLORS } from '../constants/colors';

const CalcButton0 = (props) => {
  const { color = COLORS.blackTintedBlue, ...other } = props;
  return (
    <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <path
        d="M6.73779 19.415C10.6318 19.415 12.9756 15.8628 12.9756 10.1987V10.1743C12.9756 4.51025 10.6318 0.970215 6.73779 0.970215C2.84375 0.970215 0.524414 4.51025 0.524414 10.1743V10.1987C0.524414 15.8628 2.84375 19.415 6.73779 19.415ZM6.73779 17.4985C4.21094 17.4985 2.73389 14.6909 2.73389 10.1987V10.1743C2.73389 5.68213 4.21094 2.89893 6.73779 2.89893C9.26465 2.89893 10.7661 5.68213 10.7661 10.1743V10.1987C10.7661 14.6909 9.26465 17.4985 6.73779 17.4985Z"
        fill={color}
      />
    </svg>
  );
};

export default CalcButton0;
