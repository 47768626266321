const styles = theme => ({
  container: {
    width: '100%',
  },
  blurBanner: {
    filter: 'blur(5px)',
  },
  banner: {
    position: 'relative',
    height: 1200,
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      height: '100vh',
      minHeight: 300
    }
  },
  login: {
    padding: '0 140px',
    [theme.breakpoints.down('xs')]: {
      padding: '0 100px',
    }
  },
  bannerImage: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      height: '100vh',
      width: 'auto',
      zIndex: 100
    },
  },
  controls: {
    padding: 10,
  },
  content: {
    marginTop: '-10px',
    textAlign: 'center',
    background: '#f6f7f9',
    minHeight: 500,
  },
  contentContainer: {
    padding: 20,
    margin: '0 auto 0 !important',
    position: 'relative',
  },
  caption: {
    position: 'absolute',
    minHeight: 200,
    zIndex: 200,
    margin: 'auto',
    top: 350,
    left: 0,
    right: 0,
    textAlign: 'center',
    maxWidth: '40%',
    padding: '30px',
    [theme.breakpoints.down('md')]: {
      top: 250
    },
    [theme.breakpoints.down('lg')]: {
      top: 350
    },
    [theme.breakpoints.down('sm')]: {
      top: 228,
      maxWidth: 'none',
      height: ''
    },
  },
  headLine: {
    color: '#ffffff',
    fontWeight: 200,
    fontSize: '68px',
    lineHeight: '1.4em',
    marginTop: 0,
    marginBottom: 200,
    [theme.breakpoints.up('xs')]: {
      fontSize: '28px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '38px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '68px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '68px',
    },
  },
  h1: {
    color: '#363636',
    letterSpacing: 2,
    textRendering: 'optimizeLegibility',
    fontSize: '22px',
    lineHeight: 1.25,
    margin: "0 auto 15px",
    fontWeight: 'lighter',
    paddingTop: '100px',
    [theme.breakpoints.down('sm')]: {
      padding: '50px 50px 0',
    },
  },
  h2: {
    fontWeight: 200,
    fontSize: '22px',
    margin: '20px 0',
    color: '#363636',
    textAlign: 'center',
    textRendering: 'optimizeLegibility',
    webkitFontSmoothing: 'antialiased',
    fontStyle: 'normal',
    letterSpacing: '2px'
  },
  p: {
    textAlign: 'left',
    fontSize: '16px',
    margin: '0 0 15px',
    fontStyle: 'normal',
    lineHeight: '1.6em',
    fontWeight: 200,
    padding: '15px 10px 0 10px',
    borderTop: '1px solid #aaaaaa',
  },
  benefitImg: {
    maxWidth: '100%',
    height: 'auto'
  },
  benefits: {
    margin: "50px 0 0",
    width: '100%',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'inline-block',
      textAlign: 'center'
    },
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: 'auto',
  },
  benefit: {
    maxWidth: 380,
    margin: "0 10px 20px",
    [theme.breakpoints.down('sm')]: {
      margin: "0 auto 40px",
    },
  },
  bottomButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 35,
    paddingBottom: 35,
  }
});

export default styles;