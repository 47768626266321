import React from "react";
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import {withStyles} from '@material-ui/core/styles';
import MediaQuery from 'react-responsive';
import styles from './styles';

class Notification extends React.Component {
  render() {
    const {
      autoHideDuration, onClose,
      anchorOrigin, message, open,
    } = this.props;

    return (
      <React.Fragment>
        <MediaQuery minWidth={767}>
          <Snackbar
            autoHideDuration={autoHideDuration}
            onClose={onClose}
            anchorOrigin={anchorOrigin}
            open={open}
            message={message}
          />
        </MediaQuery>
        <MediaQuery maxWidth={767}>
          <Snackbar
            autoHideDuration={autoHideDuration}
            onClose={onClose}
            anchorOrigin={{
              'vertical': 'bottom',
              'horizontal': 'center'
            }}
            open={open}
            message={message}
          />
        </MediaQuery>
      </React.Fragment>
    );
  }
}

Notification.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string.isRequired,
  autoHideDuration: PropTypes.number,
  onClose: PropTypes.func,
  anchorOrigin: PropTypes.object
};

Notification.defaultProps = {
  open: true,
  autoHideDuration: 5000,
  anchorOrigin: {
    'vertical': 'top',
    'horizontal': 'center'
  }
};

export default withStyles(styles)(Notification);