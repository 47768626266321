import {
  DXCustomerFilter,
  DXOrderBookingStatusFilter,
  DXOrderPaymentStatusFilter,
  DXOrderSourceFilter,
  DXOrderStatusFilter,
  DXCustomerTypeFilter,
  DXPaymentMethodFilter,
  DXShippingFilter,
  DXShippingTypeFilter,
  DXOrderPaidToProducerFilter,
  DXQBReportDiffFilter,
  DXOrderLocationFilter,
  DXOrderTypeFilter,
  DXDeliveryCompanyFilter,
  DXActivityFilter,
  DXSubscriptionFilter,
  DXCustomerTypeCSAFilter,
  DXReportFilter,
  DXReportsShippingDatesFilter,
  DXAmountFilter,
  DXNextDateFilter,
  DXTagCSAFilter,
  DXShippingTypesFilter,
  DXReportExcludeFilter,
  DXCustomerAllowCreditFilter,
  DXCustomerCreatedDateFilter,
  DXCustomerDefaultPaymentMethodFilter,
  DXCustomerLastOrderDateFilter,
  DXCustomerApprovedFilter,
  DXCustomerTypeSingleValueFilter,
  DXHathImageFilter,
  DXNameSearchFilter,
  DXProductTypesFilter,
  DXProductSubTypesFilter,
  DXSuppliersFilter,
  DXTagsSubscriptionFilter,
  DXSubscriptionPaymentTypeFilter,
  DXSubscriptionPeriodFilter,
  DXSubscriptionReportFilter,
  DXSubscriptionReportsDatesFilter,
} from './index';

export default class DXFiltersFactory {
  static getFilterByName(filterName) {
    const FILTERS = {
      customer: DXCustomerFilter,
      booked: DXOrderBookingStatusFilter,
      financialStatus: DXOrderPaymentStatusFilter,
      source: DXOrderSourceFilter,
      status: DXOrderStatusFilter,
      types: DXCustomerTypeFilter,
      paymentMethod: DXPaymentMethodFilter,
      shipping: DXShippingFilter,
      paidToProducer: DXOrderPaidToProducerFilter,
      'shipping.type': DXShippingTypeFilter,
      'report.qb.diff': DXQBReportDiffFilter,
      location: DXOrderLocationFilter,
      deliveryCompany: DXDeliveryCompanyFilter,
      orderType: DXOrderTypeFilter,
      activity: DXActivityFilter,
      subscription: DXSubscriptionFilter,
      csaType: DXCustomerTypeCSAFilter,
      reportSelection: DXReportFilter,
      reportShippingDates: DXReportsShippingDatesFilter,
      amount: DXAmountFilter,
      nextDate: DXNextDateFilter,
      csaTag: DXTagCSAFilter,
      'shipping.types': DXShippingTypesFilter,
      'reports.exclude': DXReportExcludeFilter,
      allowCredit: DXCustomerAllowCreditFilter,
      created: DXCustomerCreatedDateFilter,
      hasDefaultPaymentMethod: DXCustomerDefaultPaymentMethodFilter,
      lastOrder: DXCustomerLastOrderDateFilter,
      customerApproved: DXCustomerApprovedFilter,
      type: DXCustomerTypeSingleValueFilter,
      hasImage: DXHathImageFilter,
      name: DXNameSearchFilter,
      productTypes: DXProductTypesFilter,
      productSubTypes: DXProductSubTypesFilter,
      suppliers: DXSuppliersFilter,
      subscriptionTags: DXTagsSubscriptionFilter,
      paymentTypes: DXSubscriptionPaymentTypeFilter,
      subscriptionPeriod: DXSubscriptionPeriodFilter,
      subscriptionReportFilter: DXSubscriptionReportFilter,
      subscriptionReportDatesFilter: DXSubscriptionReportsDatesFilter,
    };

    if (!FILTERS.hasOwnProperty(filterName)) {
      throw new Error(`Filter with name ${filterName} not exists!`);
    }
    return FILTERS[filterName];
  }
}
