import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { withRouter } from '../../hocs';
import AdminDXTable from '../../components/AdminDXTable/AdminDXTable';
import { apiCustomersSearch, apiUnapproveCustomer } from '../../api';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { getGridPageSize, getGridPageSizes } from '../../helpers/grid';
import {
  COLORS,
  countWidthOfColumns,
  DEFAULT_MAX_CELL_WIDTH,
  DEFAULT_MIN_CELL_WIDTH,
  DEFAULT_TABLE_WIDTH,
  isNorwich,
} from '../../helpers/index';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';
import CustomersFilterDialog from '../../dialogs/CustomersFilterDialogNew/CustomersFilterDialog';
import Tab from './Tab';
import TabActions from '../../components/TabActions/TabActions';
import CustomerDialog from '../../dialogs/CustomerDialog/CustomerDialog';
import SaveTabSettingDialog from '../../dialogs/SaveTabSettingsDialog/SaveTabSettingDialog';
import TabTypes from '../../dialogs/SaveTabSettingsDialog/Types';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { clearClientSideFilters } from './helpers';
import { QrCodeIcon } from '../../components/CustomIcons/icons/QrCodeIcon';
import CustomerQrCodeDialog from '../../dialogs/CustomerQrCodeDialog';

const propTypes = {
  tab: PropTypes.object,
  width: PropTypes.number,
  serverSideFilters: PropTypes.array,
  hiddenColumnNames: PropTypes.array,
  onRef: PropTypes.func,
  allowCreateTab: PropTypes.bool,
  onDeleteTab: PropTypes.func,
  onCreateTab: PropTypes.func,
  onPatchTab: PropTypes.func,
};
const defaultProps = {
  serverSideFilters: [],
};

class GroupedByNameTab extends React.Component {
  constructor(props) {
    super(props);
    const { onRef } = props;
    onRef && onRef(this);

    const { tab, serverSideFilters, intl } = this.props;

    const columns = [
      { name: 'selection', title: ' ' },
      { name: 'companyName', title: intl.formatMessage({ id: 'customer.table.companyName' }) },
      { name: 'firstName', title: intl.formatMessage({ id: 'customer.table.firstName' }) },
      { name: 'lastName', title: intl.formatMessage({ id: 'customer.table.lastName' }) },
      { name: 'email', title: intl.formatMessage({ id: 'customer.table.email' }) },
      {
        name: 'activity',
        title: intl.formatMessage({ id: 'customer.table.lastLogin' }),
        getCellValue: (row) => {
          const activity = row?.activity || [];
          const login = Array.isArray(activity) && activity?.filter((type) => type.type === 'login');
          return login.length ? moment(login[0].date).format('MM/D/Y HH:mm') : '-';
        },
      },
    ];

    const appliedFilters = (!!tab?.filters?.length && [...tab.filters]) || serverSideFilters || [];

    this.state = {
      columns,

      tabDialog: false,
      loading: true,
      deactivating: null,
      deactivatingLoading: false,
      hiddenColumnNames: tab.excludeColumns || [],
      creatingCustomer: false,
      appliedFilters: clearClientSideFilters(appliedFilters),
      selectedFilter: null,
      sorting: tab.sorting || [{ columnName: 'createdAt', direction: 'asc' }],
      filterDialog: false,
      qrDialog: { open: false, customer: null },
      columnWidths: countWidthOfColumns(
        columns,
        tab.excludeColumns || [],
        props.columnExtensions || [],
        DEFAULT_TABLE_WIDTH - 100
      ),
    };
  }

  onChangeSorting = (sorting) => {
    this.setState({ sorting });
  };

  onCloseDialog = (customer) => {
    this.setState({ creatingCustomer: false });
    customer && this.table.forceReload();
  };

  onChangeColumnsState = (hiddenColumnNames) => {
    this.setState({ hiddenColumnNames });
  };

  handleCustomerEdit = (customer) => {
    const { history } = this.props;
    customer && history.push(`/customers/${customer.id}`);
  };

  onUpdateTab = () => {
    const { tab, onPatchTab } = this.props;

    if (tab.id) {
      onPatchTab &&
        onPatchTab(tab, {
          filters: this.state.appliedFilters,
          excludeColumns: this.state.hiddenColumnNames,
          sorting: this.state.sorting,
        });
    }
  };

  onCreateTab = () => {
    this.setState({ tabDialog: true });
  };

  forceTableReload = () => {
    this.table.forceReload();
  };

  onDeactivate = () => {
    this.setState({ deactivatingLoading: true });
    apiUnapproveCustomer(
      this.state.deactivating.id,
      () => {
        this.setState({
          deactivating: null,
          deactivatingLoading: false,
        });
        this.table.forceReload();
      },
      undefined
    );
  };

  closeFiltersDialog = () => {
    this.setState({ filtersDialog: false });
  };

  onAddFilter = () => {
    this.setState({ filtersDialog: true });
  };

  onApplyFilters = (appliedFilters) => {
    this.setState({ appliedFilters }, () => {
      this.closeFiltersDialog();
    });
  };

  handleFilterRemove = (removeFilter) => {
    this.setState((state) => {
      const appliedFilters = state.appliedFilters;
      const filters = appliedFilters.filter((filter) => {
        return `${filter.name}-${filter.property}` !== `${removeFilter.name}-${removeFilter.property}`;
      });
      return { appliedFilters: filters };
    });
  };

  getActions = () => {
    const { tab, intl, allowCreateTab, onDeleteTab } = this.props;
    return (
      <TabActions
        tab={tab}
        onCreateTab={this.onCreateTab}
        onUpdateTab={this.onUpdateTab}
        onDeleteTab={onDeleteTab}
        allowCreateTab={allowCreateTab}
        labels={{ createEntity: intl.formatMessage({ id: 'customers.new' }) }}
        columns={this.state.columns}
        onAddingFilter={this.onAddFilter}
        onChangeColumnsState={this.onChangeColumnsState}
        onCreateEntity={() => this.setState({ creatingCustomer: true })}
      />
    );
  };

  onOpenQrCodeModal = (row) => {
    this.setState({ qrDialog: { open: true, customer: row } });
  };

  onCloseQrCodeDialog = () => {
    this.setState((state) => ({ ...state, qrDialog: { ...state.qrDialog, open: false } }));
  };

  getTableActions = () => {
    const { intl, producer } = this.props;

    let actions = [
      {
        icon: <EditIcon style={{ color: COLORS.text, fontSize: 18 }} />,
        action: (row) => {
          this.handleCustomerEdit(row.original);
        },
      },
      {
        icon: <DeleteIcon style={{ color: COLORS.violet, fontSize: 18 }} />,
        action: (row) => this.setState({ deactivating: row.original }),
      },
    ];

    if (isNorwich(producer)) {
      actions = [
        {
          name: 'generateQrCode',
          icon: <QrCodeIcon size={18} height={18} style={{ color: COLORS.text, fontSize: 18 }} />,
          tooltipMessage: intl.formatMessage({ id: 'customer.qrCode.generateQrCode' }),
          action: (row) => this.onOpenQrCodeModal(row),
        },
        ...actions,
      ];
    }

    return actions;
  };

  // Show qr only on customer root
  isActionDisable = (tableRow, action) => {
    return tableRow?.row?.treeType !== 'root' && action?.name === 'generateQrCode';
  };

  makeAccounts = (row, accounts) => {
    if (!accounts) return undefined;

    return accounts.map((children) => {
      return {
        ...children,
        treeType: 'children',
        original: JSON.parse(JSON.stringify(children)),
        id: `${row.id}-${children.id}`,
      };
    });
  };

  makeRows = (rows) => {
    return rows.map((row) => ({
      ...row,
      original: JSON.parse(JSON.stringify(row)),
      treeType: 'root',
    }));
  };

  render() {
    const { appliedFilters, selectedFilter, qrDialog } = this.state;
    const { intl, tab, onCreateTab, producer } = this.props;

    return (
      <Tab title={tab.name} style={{ width: this.props.width }} actions={this.getActions()}>
        <AdminDXTable
          onRef={(table) => (this.table = table)}
          cellRenderer={(reload, { row, ...props }) => {
            if (row.treeType === 'children' && props.column.name === 'companyName') {
              return <Table.Cell {...props}>-</Table.Cell>;
            }
            return <Table.Cell {...props} />;
          }}
          apiRetrieve={(filters, onSuccess, onError) => apiCustomersSearch(filters, onSuccess, onError)}
          pageSize={getGridPageSize()}
          pageSizes={getGridPageSizes()}
          onOpenWithFilter={(index, filter) =>
            this.setState({
              filtersDialog: true,
              selectedFilter: filter,
            })
          }
          onRemoveFilter={(removedFilter) => this.handleFilterRemove(removedFilter)}
          columns={this.state.columns}
          columnExtensions={[{ columnName: 'selection', width: 120 }]}
          hiddenColumnNames={this.state.hiddenColumnNames}
          onChangeSorting={this.onChangeSorting}
          allowCollapseAll
          treeSelectionColumn={'selection'}
          sorting={this.state.sorting}
          actionsHeaderCellStyle={{ textAlign: 'center', verticalAlign: 'bottom', paddingBottom: '16px' }}
          actionsCellStyle={{ textAlign: 'center' }}
          actionsColumnProps={{
            ...this.props.actionsColumnProps,
            disableActionByRow: this.isActionDisable,
            ...(isNorwich(producer) ? { width: 210 } : {}),
          }}
          actions={this.getTableActions()}
          serverSideFilters={[...JSON.parse(JSON.stringify(appliedFilters))]}
          enablePager
          serverSidePagination
          enableRemoteSearch
          enableTree
          getTreeChildRows={(row, rootRows) => {
            // Children
            if (row) {
              if (!row.type) {
                return null;
              }

              return this.makeAccounts(row, row.accounts);
            }

            return this.makeRows(rootRows);
          }}
          columnBands={this.props.columnBands}
          columnWidths={this.state.columnWidths}
          onColumnWidthsChange={(widths) => {
            this.setState({ columnWidths: widths });
          }}
          minColumnWidth={DEFAULT_MIN_CELL_WIDTH}
          maxColumnWidth={DEFAULT_MAX_CELL_WIDTH}
        />

        {this.state.deactivating && (
          <ConfirmDialog
            onClose={() => this.setState({ deactivating: null })}
            loading={this.state.deactivatingLoading}
            confirmTitle={'confirm.deactivateCustomer'}
            title={intl.formatMessage({ id: 'titles.deactivateCustomer' })}
            message={intl.formatMessage({ id: 'messages.confirmDeactivateCustomer' })}
            onConfirm={this.onDeactivate}
          />
        )}

        {this.state.filtersDialog && (
          <CustomersFilterDialog
            onApplyFilters={this.onApplyFilters}
            onClose={this.closeFiltersDialog}
            appliedFilters={JSON.parse(JSON.stringify(appliedFilters))}
            openWithFilter={JSON.parse(JSON.stringify(selectedFilter))}
          />
        )}

        {this.state.creatingCustomer && (
          <CustomerDialog onClose={this.onCloseDialog} producer={producer} onDone={this.onCloseDialog} />
        )}

        {this.state.tabDialog && (
          <SaveTabSettingDialog
            onClose={() => this.setState({ tabDialog: false })}
            filters={JSON.parse(JSON.stringify(appliedFilters))}
            excludeColumns={this.state.hiddenColumnNames}
            sorting={this.state.sorting}
            onSave={(data) => onCreateTab(data, () => this.setState({ tabDialog: false }))}
            type={TabTypes.CUSTOMERS}
          />
        )}

        <CustomerQrCodeDialog qrDialog={qrDialog} onClose={this.onCloseQrCodeDialog} />
      </Tab>
    );
  }
}

GroupedByNameTab.propTypes = propTypes;
GroupedByNameTab.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  return {
    producer: state.producer.object,
  };
};

export default injectIntl(withRouter(connect(mapStateToProps)(GroupedByNameTab)));
