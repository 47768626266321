import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  MuiThemeProvider,
  createTheme
} from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';
import styles from "./styles";

const propTypes = {
  desc: PropTypes.any
};

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiButton: {
      root: {
        fontFamily: "'Roboto', sans-serif",
        fontWeight: 400,
        fontSize: 12,
        background: 'linear-gradient(90deg, #CF87AE, #CD41B6, #C51162)',
        borderRadius: 33,
        border: 0,
        color: 'rgba(255,255,255, 0.87)',
        height: 50,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.16)',
        minWidth: 200,
      },
      label: {
        fontWeight: '400',
      }
    },
  },
});

class VioletButton extends React.Component {
  render = () => {
    const {classes, desc, className, ...other} = this.props;
    let wrapperClasses = {};
    wrapperClasses[classes.descWrapper] = !!desc;
    if (className) {
        wrapperClasses[className] = true;
    }

    return (
      <MuiThemeProvider theme={theme}>
        <Button className={classNames(wrapperClasses)} {...other}>
          {this.props.children}
          {desc && (
            <span className={classes.desc}>
              {desc}
            </span>
          )}
        </Button>
      </MuiThemeProvider>
    );
  }
}

VioletButton.propTypes = propTypes;

export default withStyles(styles)(VioletButton);
