import React, { useState, useEffect } from 'react';
import NavigationMenu from './components/NavigationMenu';
import HeaderArea from './components/HeaderArea';
import foodHubLogo from './assets/foodHub.png';
import { makeStyles } from '@material-ui/core/styles';
import { BORDER_RADIUS_MAIN, PADDING_CONTENT, PADDING_CONTENT_TOP } from './constants/globals';
import { useIntl } from 'react-intl';
import { FONT_CART_PRODUCT, FONT_CART_VARIANT_TITLE, FONT_ORDER_TOTAL } from './constants/fonts';
import { COLORS } from './constants/colors';
import { Typography } from '@material-ui/core';
import Footer from './components/Footer';
import MessageIcon from './assets/messageIcon';
import { getSupplierParentObject, makeProducerAddress } from '../../helpers';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: COLORS.lightBg,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  content: {
    boxSizing: 'border-box',
    paddingLeft: PADDING_CONTENT,
    paddingRight: PADDING_CONTENT,
    maxWidth: 900,
    width: '100%',
    backgroundColor: 'white',
    paddingTop: PADDING_CONTENT_TOP,
    flex: 1,
  },

  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 15,
    maxWidth: 160,
    maxHeight: 160,
    overflow: 'hidden',
    margin: 'auto',
    borderRadius: BORDER_RADIUS_MAIN,
  },
  logoImg: {
    maxHeight: 160,
    height: '100%',
    width: 'auto',
  },
  titleWrapper: {
    marginBottom: 20,
    textAlign: 'center',
  },
  title: {
    ...FONT_CART_PRODUCT,
    color: COLORS.blackTintedBlue,
  },

  numberItemsWrapper: {},
  numberItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingTop: 20,
    paddingBottom: 20,
  },
  numberItemIcon: {
    ...FONT_CART_PRODUCT,
    color: COLORS.blackTintedBlue,

    paddingRight: 23,
  },
  numberItemContent: {
    flex: 1,
  },
  numberItemTitle: {
    ...FONT_CART_VARIANT_TITLE,
    color: COLORS.blackTintedBlue,
    marginBottom: 5,
  },
  numberItemText: {
    ...FONT_ORDER_TOTAL,
    color: COLORS.greyText,
    lineHeight: 1.4,
  },
  numberItemLink: {
    ...FONT_CART_VARIANT_TITLE,
    color: COLORS.brightBlue,
    textDecoration: 'none',
    '&:visited': {
      color: COLORS.brightBlue,
    },
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const FoodHubInfoScene = () => {
  const classes = useStyles();
  const intl = useIntl();

  const [parentProducer, setParentProducer] = useState(null);

  useEffect(() => {
    setParentProducer(getSupplierParentObject());
  }, []);

  return (
    <div className={classes.root}>
      <HeaderArea onlyBackButton />
      <div className={classes.contentWrapper}>
        <div className={classes.content}>
          <div className={classes.logoWrapper}>
            <img src={parentProducer?.image?.thumbSrc || foodHubLogo} alt="HoodHub" className={classes.logoImg} />
          </div>

          <div className={classes.titleWrapper}>
            <div className={classes.title}>{parentProducer?.name || ''}</div>
          </div>

          <div className={classes.numberItemsWrapper}>
            {/* #1 start */}
            <div className={classes.numberItem}>
              <div className={classes.numberItemIcon}>{'#1'}</div>
              <div className={classes.numberItemContent}>
                <Typography variant={'inherit'} component={'p'} className={classes.numberItemTitle}>
                  {intl.formatMessage({ id: 'supplier.foodHub.updateInventory' })}
                </Typography>

                <Typography variant={'inherit'} component={'p'} className={classes.numberItemText}>
                  {intl.formatMessage({ id: 'supplier.foodHub.whenUpdateOnlineStore' })}
                </Typography>
              </div>
            </div>
            {/* #1 end */}

            {/* #2 start */}
            <div className={classes.numberItem}>
              <div className={classes.numberItemIcon}>{'#2'}</div>
              <div className={classes.numberItemContent}>
                <Typography variant={'inherit'} component={'p'} className={classes.numberItemTitle}>
                  {intl.formatMessage({ id: 'supplier.foodHub.seeSales' })}
                </Typography>

                <Typography variant={'inherit'} component={'p'} className={classes.numberItemText}>
                  {intl.formatMessage({ id: 'supplier.foodHub.realTimePermanent' })}
                </Typography>
              </div>
            </div>
            {/* #2 end */}

            {/* #3 start */}
            <div className={classes.numberItem}>
              <div className={classes.numberItemIcon}>{'#3'}</div>
              <div className={classes.numberItemContent}>
                <Typography variant={'inherit'} component={'p'} className={classes.numberItemTitle}>
                  {intl.formatMessage({ id: 'supplier.foodHub.deliverTuesday' })}
                </Typography>

                {!!parentProducer?.address1 && (
                  <Typography variant={'inherit'} component={'p'} className={classes.numberItemText}>
                    Deliver to our warehouse at{' '}
                    <span className={classes.numberItemLink}>
                      {!!parentProducer?.address1 && makeProducerAddress(parentProducer)}
                    </span>
                  </Typography>
                )}
              </div>
            </div>
            {/* #3 end */}

            {/* #4 start */}
            <div className={classes.numberItem}>
              <div className={classes.numberItemIcon}>{'#4'}</div>
              <div className={classes.numberItemContent}>
                <Typography variant={'inherit'} component={'p'} className={classes.numberItemTitle}>
                  {intl.formatMessage({ id: 'supplier.foodHub.invoiceLikeUsual' })}
                </Typography>

                <Typography variant={'inherit'} component={'p'} className={classes.numberItemText}>
                  {intl.formatMessage({ id: 'supplier.foodHub.streamlinePaymentsPermanent' })}
                </Typography>
              </div>
            </div>
            {/* #4 end */}

            {/* #5 start */}
            <div className={classes.numberItem}>
              <div className={classes.numberItemIcon}>
                <MessageIcon />
              </div>
              <div className={classes.numberItemContent}>
                <Typography variant={'inherit'} component={'p'} className={classes.numberItemTitle}>
                  {intl.formatMessage({ id: 'supplier.foodHub.questions' })}
                </Typography>
                <Typography variant={'inherit'} component={'p'} className={classes.numberItemText}>
                  {intl.formatMessage(
                    { id: 'supplier.foodHub.reportBugsGiveFeedback' },
                    {
                      link: (
                        <a
                          className={classes.numberItemLink}
                          href={
                            'mailto:wiley@permanent.ag?cc=ludvig@fellowfarmer.com&subject=Permanent%20%E2%80%A2%20App%20Feedback&body=Hey%20Wiley%20and%20Ludvig%2C%0AQuestions%2Fhelp%3A%20%0ABugs%2Fissues%3A%20%0AFeedback%2Fideas%3A%20'
                          }>
                          {intl.formatMessage({ id: 'supplier.foodHub.emailWileyLudvig' })}
                        </a>
                      ),
                    }
                  )}
                </Typography>
              </div>
            </div>
            {/* #5 end */}
          </div>
          <Footer />
        </div>
      </div>

      <NavigationMenu />
    </div>
  );
};

export default FoodHubInfoScene;
