import React from 'react';
import { AdminLayout } from '../../components';
import Development from './Development';
import Production from './Production';
import {
  SidebarMenu,
  HeaderMenu,
} from '../Partials';

const AboutScene = () => {
  return (
    <AdminLayout sidebarComponent={SidebarMenu}
                 headerMenuComponent={HeaderMenu}>
      {process.env.NODE_ENV === 'production'
        ? <Production />
        : <Development />
      }
    </AdminLayout>
  );
};

export default AboutScene;

