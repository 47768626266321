import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants/colors';
import { FONT_TEXT_INPUT_MULTILINE } from '../../constants/fonts';
import { BORDER_RADIUS_MAIN } from '../../constants/globals';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles(() => ({
  root: {
    ...FONT_TEXT_INPUT_MULTILINE,
    fontWeight: 500,
    position: 'fixed',
    top: 10,
    left: 'auto',
    minWidth: 200,
    maxWidth: 330,
    width: '100%',
    backgroundColor: COLORS.toastBg,
    padding: 15,
    paddingRight: 20,
    paddingLeft: 20,
    color: COLORS.white,
    zIndex: 120,
    justifySelf: 'center',
    alignSelf: 'center',
    borderRadius: BORDER_RADIUS_MAIN,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    boxSizing: 'border-box',
    minHeight: 50,
  },
}));

const propTypes = {
  message: PropTypes.string,
  delay: PropTypes.number,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
};

const SimpleToast = (props) => {
  const { message, delay = 5000, onClose, isOpen = false } = props;
  const classes = useStyles();

  const [visible, setVisible] = useState(isOpen);

  useEffect(() => {
    if (!message) {
      setVisible(false);
      return;
    }

    setVisible(true);

    const timer = setTimeout(() => {
      turnOff();
    }, delay);

    return () => clearTimeout(timer);
  }, [message, isOpen]);

  const turnOff = () => {
    setVisible(false);
    onClose && onClose();
  };

  return (
    <Fade in={visible}>
      <div className={classes.root} onClick={turnOff}>
        {message}
      </div>
    </Fade>
  );
};

SimpleToast.propTypes = propTypes;

export default SimpleToast;
