import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../../../hocs';
import { PrimaryTextField } from '../../../../components';
import { CUSTOMER_TYPE, validateField } from '../../../../helpers';
import validationRules from './validation';
import styles from '../styles';

const propTypes = {
  customerType: PropTypes.oneOf([CUSTOMER_TYPE.WHOLESALE, CUSTOMER_TYPE.RETAIL]).isRequired,
  error: PropTypes.object,
  initialData: PropTypes.object,
  onRef: PropTypes.func.isRequired,
};

class BillingAddressForm extends React.Component {
  constructor(props) {
    super(props);
    this.props.onRef(this);

    const initialData = this.props.initialData || {};

    this.state = {
      companyName: initialData.companyName || '',
      address: initialData.address1 || '',
      city: initialData.city || '',
      state: initialData.province || '',
      zipCode: initialData.zip || '',

      companyNameError: '',
      addressError: '',
      cityError: '',
      stateError: '',
      zipCodeError: '',
    };
  }

  getError(fieldName) {
    const { error, intl } = this.props;
    let message = '';
    if (error && error.data.code === 6009 && fieldName === 'companyName') {
      message = intl.formatMessage({ id: 'messages.customerWithSameCompanyNameAlreadyExists' });
    }
    return message;
  }

  getTextField(fieldName) {
    const { classes, intl } = this.props;

    return (
      <PrimaryTextField
        className={classes.input}
        InputLabelProps={{
          classes: {
            root: classes.label,
          },
        }}
        autoComplete="no"
        error={!!this.state[`${fieldName}Error`] || !!this.getError(fieldName)}
        onChange={(event) => {
          let value = event.target.value;
          this.setState((state) => {
            state[fieldName] = value;
            return state;
          });
        }}
        value={this.state[fieldName]}
        id={`${fieldName}-input`}
        label={'* ' + intl.formatMessage({ id: `global.${fieldName}` })}
        type="text"
        margin="normal"
        helperText={
          (this.state[`${fieldName}Error`] && intl.formatMessage({ id: this.state[`${fieldName}Error`] })) ||
          this.getError(fieldName)
        }
        fullWidth
      />
    );
  }

  validate = () => {
    let companyNameError = '';

    if (this.props.customerType === CUSTOMER_TYPE.WHOLESALE)
      companyNameError = validateField(validationRules, 'companyName', this.state.companyName);

    const addressError = validateField(validationRules, 'address', this.state.address);
    const cityError = validateField(validationRules, 'city', this.state.city);
    const stateError = validateField(validationRules, 'state', this.state.state);
    const zipCodeError = validateField(validationRules, 'zipCode', this.state.zipCode);

    let validated = false;
    if (!companyNameError && !addressError && !cityError && !stateError && !zipCodeError) {
      this.setState({
        companyNameError: '',
        addressError: '',
        cityError: '',
        stateError: '',
        zipCodeError: '',

        requestError: '',
        isLoading: true,
      });

      validated = true;
    } else {
      this.setState({
        companyNameError: companyNameError,
        addressError: addressError,
        cityError: cityError,
        stateError: stateError,
        zipCodeError: zipCodeError,
        isLoading: false,
      });
    }

    let data = {
      address1: this.state.address,
      city: this.state.city,
      province: this.state.state,
      zip: this.state.zipCode,
    };

    if (this.props.customerType === CUSTOMER_TYPE.WHOLESALE) {
      data['companyName'] = this.state.companyName;
    }

    return {
      validated: validated,
      data: data,
    };
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.wrapper}>
        {this.props.customerType === CUSTOMER_TYPE.WHOLESALE && this.getTextField('companyName')}

        {this.getTextField('address')}
        {this.getTextField('city')}
        {this.getTextField('state')}
        {this.getTextField('zipCode')}
      </div>
    );
  }
}

BillingAddressForm.propTypes = propTypes;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(BillingAddressForm)));
