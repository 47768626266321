import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import ProductCell from './Cells/ProductCell';
import CellAsPriceInput from '../../components/AdminDXTable/Editing/CellAsPriceInput';
import CellAsSelectInput from '../../components/AdminDXTable/Editing/CellAsSelectInput';
import CellAsDateInput from '../../components/AdminDXTable/Editing/CellAsDateInput';
import { toPaper } from '../../helpers';

export const PERIODS = {
  PER_WEEK: 'per_week',
  PER_MONTH: 'per_month',
  BI_WEEK: 'per_bi_week',
};

export const TableCell = (
  onFinishPriceEditing,
  onFinishLimitEditing,
  onFinishPeriodEditing,
  onFinishNextDateEditing,
  onFinishDateEditing,
  intl,
  period,
  reload,
  { row, ...props }
) => {
  if (props.column.name === 'product') {
    return <ProductCell row={row} {...props} />;
  }

  if (props.column.name === 'price') {
    return (
      <CellAsPriceInput
        value={row.value || '0.00'}
        style={{ margin: 0, width: 'auto' }}
        cellProps={props}
        onFinishCellEditing={(value) => {
          onFinishPriceEditing(row, value);
        }}
      />
    );
  }

  if (props.column.name === 'limitPerPeriod') {
    return (
      <CellAsPriceInput
        value={(row.limited && row.limited.limitPerPeriod) || '0.00'}
        style={{ margin: 0, width: 'auto' }}
        cellProps={props}
        onFinishCellEditing={(value) => {
          onFinishLimitEditing('limitPerPeriod', row, value);
        }}
      />
    );
  }

  if (props.column.name === 'limitOverAll') {
    return (
      <CellAsPriceInput
        value={(row.limited && row.limited.limitOverAll) || '0.00'}
        style={{ margin: 0, width: 'auto' }}
        cellProps={props}
        onFinishCellEditing={(value) => {
          onFinishLimitEditing('limitOverAll', row, value);
        }}
      />
    );
  }

  if (props.column.name === 'period') {
    return (
      <CellAsSelectInput
        disabled={period === PERIODS.BI_WEEK}
        value={row.limited && row.limited.period ? row.limited.period : PERIODS.PER_WEEK}
        values={[PERIODS.PER_WEEK, PERIODS.BI_WEEK, PERIODS.PER_MONTH]}
        labels={{
          [PERIODS.PER_WEEK]: intl.formatMessage({ id: 'global.perWeek' }),
          [PERIODS.PER_MONTH]: intl.formatMessage({ id: 'global.perMonth' }),
          [PERIODS.BI_WEEK]: intl.formatMessage({ id: 'global.biWeek' }),
        }}
        style={{ margin: 0, width: 'auto' }}
        cellProps={props}
        onFinishCellEditing={(value) => onFinishPeriodEditing(row, value)}
      />
    );
  }

  if (props.column.name === 'nextDate') {
    return (
      <CellAsDateInput
        value={row.nextDate || null}
        style={{ margin: 0, width: 'auto' }}
        cellProps={props}
        applyOnChange
        onFinishCellEditing={(value) => onFinishNextDateEditing(row, value)}
        datePickerProps={{
          open: true,
          minDate: new Date(),
        }}
      />
    );
  }

  if (props.column.name === 'endDate') {
    return (
      <CellAsDateInput
        value={row.endDate || null}
        style={{ margin: 0, width: 'auto' }}
        cellProps={props}
        applyOnChange
        onFinishCellEditing={(value) => onFinishDateEditing(row, value, 'endDate')}
        datePickerProps={{
          open: true,
          minDate: new Date(),
        }}
      />
    );
  }

  if (props.column.name === 'scheduledDate') {
    return (
      <CellAsDateInput
        value={row.scheduledDate || null}
        style={{ margin: 0, width: 'auto' }}
        cellProps={props}
        applyOnChange
        onFinishCellEditing={(value) => onFinishDateEditing(row, value, 'scheduledDate')}
        datePickerProps={{
          open: true,
          minDate: new Date(),
        }}
      />
    );
  }

  return <Table.Cell {...props} />;
};

/**
 * Tries to get variant preorder price by customer
 * @param {object} customer
 * @param {object} variant
 * @return {string} variant price in string format
 */
export const getVariantPreorderPriceByCustomer = (customer, variant) => {
  if (customer && variant.prices) {
    const level = variant.prices.find((price) => price.level === `${customer.type}_preorder`);

    if (level) {
      return toPaper(level.price);
    }
  }
  return toPaper(0);
};

/**
 * Calculates suggested amount
 * @param {array} variants
 * @return {string} total amount
 */
export const calculateSuggestedAmount = (variants) => {
  let total = 0;

  if (variants.length) {
    variants.forEach((variant) => {
      if (variant.limited && variant.limited.limitOverAll) {
        total += parseFloat(variant.value) * parseFloat(variant.limited.limitOverAll);
      }
    });
  }

  return total;
};

export const getVariantsHiddenColumns = (variants, subscription, globalPeriod) => {
  let columns = [];

  if (!variants || !variants.length || !globalPeriod) {
    columns = [...columns, 'limitPerPeriod', 'limitOverAll', 'period'];
  }

  if (!!!subscription) {
    columns = [...columns, 'nextDate', 'endDate', 'scheduledDate'];
  }

  return columns;
};

export const getGlobalPeriodActivity = (variants) => {
  return !!(Array.isArray(variants) && variants.some((variant) => !!variant.limited));
};
