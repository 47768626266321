/**
 * Checks if object has nested prop
 * @param obj
 * @returns {boolean}
 */
export const checkNested = (obj /*, level1, level2, ... levelN*/) => {
  // eslint-disable-next-line
  let args = Array.prototype.slice.call(arguments, 1);
  for (let i = 0; i < args.length; i++) {
    if (!obj || !obj.hasOwnProperty(args[i])) {
      return false;
    }
    obj = obj[args[i]];
  }
  return true;
};

/**
 * Checks whether object is empty or not
 * @param obj
 * @returns {boolean}
 */
export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const sumOfArrayOfObjectsValue = (arr, key) => {
  let sum = 0;

  arr.forEach((obj) => {
    if (obj.hasOwnProperty(key)) {
      sum += obj[key];
    }
  });

  return sum;
};
