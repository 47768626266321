import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../../../hocs';
import { PrimaryTextField } from '../../../../components';
import { validateField } from '../../../../helpers';
import validationRules from './validation';
import styles from '../styles';

const propTypes = {
  initialData: PropTypes.object,
  error: PropTypes.object,
  onRef: PropTypes.func.isRequired,
};

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.props.onRef(this);
    const initialData = this.props.initialData || {};

    this.state = {
      firstName: initialData.firstName || '',
      lastName: initialData.lastName || '',
      email: initialData.email || '',
      phone: initialData.phone || '',

      firstNameError: '',
      lastNameError: '',
      emailError: '',
      phoneError: '',
    };
  }

  getError(fieldName) {
    const { error, intl } = this.props;
    let message = '';
    if (error && error.data.code === 6008 && fieldName === 'email') {
      message = intl.formatMessage({ id: 'messages.customerWithSameEmailAlreadyExists' });
    }
    return message;
  }

  getTextField(fieldName, prefix = '* ') {
    const { classes, intl } = this.props;

    return (
      <PrimaryTextField
        className={classes.input}
        InputLabelProps={{
          classes: {
            root: classes.label,
          },
        }}
        autoComplete="no"
        error={!!this.state[`${fieldName}Error`] || !!this.getError(fieldName)}
        onChange={(event) => {
          let value = event.target.value;
          this.setState((state) => {
            state[fieldName] = value;
            return state;
          });
        }}
        value={this.state[fieldName]}
        id={`${fieldName}-input`}
        label={`${prefix}` + intl.formatMessage({ id: `global.${fieldName}` })}
        type="text"
        margin="normal"
        helperText={
          (this.state[`${fieldName}Error`] && intl.formatMessage({ id: this.state[`${fieldName}Error`] })) ||
          this.getError(fieldName)
        }
        fullWidth
      />
    );
  }

  validate = () => {
    const firstNameError = validateField(validationRules, 'firstName', this.state.firstName);
    const lastNameError = validateField(validationRules, 'lastName', this.state.lastName);
    const emailError = validateField(validationRules, 'email', this.state.email);
    const phoneError = validateField(validationRules, 'phone', this.state.phone);

    let validated = false;
    if (!firstNameError && !lastNameError && !emailError && !phoneError) {
      this.setState({
        firstNameError: '',
        lastNameError: '',
        emailError: '',
        phoneError: '',
        isLoading: true,
      });

      validated = true;
    } else {
      this.setState({
        firstNameError: firstNameError,
        lastNameError: lastNameError,
        emailError: emailError,
        phoneError: phoneError,
        isLoading: false,
      });
    }

    return {
      validated: validated,
      data: {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        phone: this.state.phone,
      },
    };
  };

  getInput = () => {
    return {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      phone: this.state.phone,
    };
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.wrapper}>
        {this.getTextField('firstName')}
        {this.getTextField('lastName')}
        {this.getTextField('email')}
        {this.getTextField('phone', '')}
      </div>
    );
  }
}

UserForm.propTypes = propTypes;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(UserForm)));
