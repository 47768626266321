import React from "react";
import Menu from '@material-ui/core/Menu';
import {PrimarySelect} from '../../components';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';

const propTypes = {};
const defaultProps = {};

class DropDownMenu extends React.Component {

  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({anchorEl: event.currentTarget});
  };

  handleClose = () => {
    this.setState({anchorEl: null});
  };

  render() {
    const {
      children,
      label,
      classes,
      ...other
    } = this.props;

    const {anchorEl} = this.state;
    const open = Boolean(anchorEl);

    return (
      <React.Fragment>
        <div className={classes.button}
             onClick={(event) => {
               event.stopPropagation();
               this.handleClick(event);
               return false;
             }}>
          <PrimarySelect native>
            <option>{label}</option>
          </PrimarySelect>
        </div>

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          {...other}
        >
          {children}
        </Menu>
      </React.Fragment>
    );
  }
}

DropDownMenu.propTypes = propTypes;
DropDownMenu.defaultProps = defaultProps;

export default withStyles(styles, {
  withTheme: true
})(DropDownMenu);