import { getObjectFromStorage } from './storage';

export const genHttpBasicToken = (email, password) => {
  return window.btoa(unescape(encodeURIComponent(email + ':' + password)));
};

/**
 * Checks if token is expired
 * @param {string} jwt - jwt token
 * @returns {boolean}
 */
export function isJWTExpired(jwt) {
  const decoded = jwtDecode(jwt);
  const date = new Date();
  return decoded.exp < date.getTime() / 1000;
}

/**
 * Decodes token
 * @param {string} jwt
 * @returns {object} - decoded jwt
 */
export function jwtDecode(jwt) {
  try {
    const base64Url = jwt.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(atob(base64));
  } catch (e) {
    return 0;
  }
}

/**
 * Set deliveries user token
 * @param token - user token
 */
export function setDeliveriesUserToken(token) {
  return localStorage.setItem('deliveries.token', token);
}

/**
 * get Deliveries token
 */
export function getDeliveriesUserToken() {
  return localStorage.getItem('deliveries.token');
}

/** Removes Deliveries token */
export function removeDeliveriesUserToken() {
  return localStorage.removeItem('deliveries.token');
}

/**
 * Get acl object
 * @return {object}
 */
export function getAcl() {
  return getObjectFromStorage('deliveries.acl');
}
