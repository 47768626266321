import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import reportConfig, { SUBSCRIPTIONS_REPORTS_TYPES } from './reportsConfig';
import { AdminDXTable, Spinner, Tab, TabActions } from '../../components';
import { getGridPageSize, getGridPageSizes } from '../../helpers/grid';
import { COLORS, countWidthOfColumns, DEFAULT_MAX_CELL_WIDTH, HEADER_HEIGHT_STANDARD } from '../../helpers';
import { CellRenderer, renderItemsForExport, RowRenderer } from './reportsHelpers';
import { SubscriptionsReportsFilterDialog } from '../../dialogs';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { DEFAULT_NORMALIZED_CELL_WIDTH } from '../../helpers/table';
import { jsonToCsv } from '../OrdersScene/helpers';

const propTypes = {
  tab: PropTypes.any,
  onCreateTab: PropTypes.any,
  index: PropTypes.any,
  onPatchTab: PropTypes.any,
  allowCreateTab: PropTypes.any,
  onRef: PropTypes.any,
  withFilters: PropTypes.any,
  appliedFilters: PropTypes.any,
};

const useStyles = makeStyles(() => ({
  button: {
    position: 'relative',
    marginLeft: 9,
    marginRight: 9,
    padding: 0,
    '&:hover': {
      background: 'none',
    },
  },
  label: {
    fontSize: 12,
    color: COLORS.text,
    flexDirection: 'column',
  },
  icon: {
    color: COLORS.black,
  },
  noDateInform: {
    width: '100%',
    textAlign: 'center',
    padding: 40,
    fontSize: 22,
  },
}));

const SubscriptionsReportsContent = (props) => {
  const { tab, index, onPatchTab, withFilters, appliedFilters } = props;
  const classes = useStyles();
  const intl = useIntl();

  const [config, setConfig] = useState(
    getColumnConfig(filtersToParams(appliedFilters).reportType || SUBSCRIPTIONS_REPORTS_TYPES.CUSTOMERS)
  );
  const isCustomerReport = config.key === SUBSCRIPTIONS_REPORTS_TYPES.CUSTOMERS;
  const [state, setState] = useState({
    selectedFilter: null,
    isDatesSelected: filtersToParams(makeInitialFilters(appliedFilters)).datesSelected || false,
    settings: {
      excludeColumns: tab.excludeColumns || [],
      filters: makeInitialFilters(appliedFilters),
    },
    isFiltersDialogActive: false,
  });
  const [columnWidths, setColumnWidths] = useState([]);
  const [initialDataLoading, setInitialDataLoading] = useState(true);
  const [reportData, setReportData] = useState({
    columns: [],
    rows: [],
    data: [],
  });
  const [tableRef, setTableRef] = useState(null); // it's not a ref, its instance of class component

  useEffect(() => {
    initialDataLoading && init();
  }, [initialDataLoading]);

  const init = () => {
    const params = filtersToParams(state.settings.filters);

    if (!params.datesSelected) {
      setInitialDataLoading(false);
      return;
    }

    config.apiRetrieve(
      params,
      (response) => {
        isCustomerReport ? makeCustomerData(response) : makeLineItemsData(response);
        setInitialDataLoading(false);
      },
      () => {
        setInitialDataLoading(false);
      }
    );
  };

  function makeInitialFilters(filters) {
    let hasReportType = false;
    let hasDateSelected = false;
    let preselectedFilters = [...filters];

    filters.forEach((filter) => {
      if (filter.name === 'subscriptionReportDatesFilter') {
        hasDateSelected = true;
      }
      if (filter.name === 'subscriptionReportFilter') {
        hasReportType = true;
      }
    });

    if (!hasReportType) {
      preselectedFilters.push({
        name: 'subscriptionReportDatesFilter',
        column: intl.formatMessage({ id: 'filter.subscriptionDate' }),
        readOnly: true,
        selectedValues: [
          {
            from: moment().add(-30, 'days').startOf('day').toISOString(true),
            to: moment().endOf('day').toISOString(true),
          },
        ],
      });
    }

    if (!hasDateSelected) {
      preselectedFilters.push({
        name: 'subscriptionReportFilter',
        column: intl.formatMessage({ id: 'filter.report.reportType' }),
        readOnly: true,
        selectedValues: [SUBSCRIPTIONS_REPORTS_TYPES.CUSTOMERS],
      });
    }

    return preselectedFilters;
  }

  function filtersToParams(filters) {
    let fromDate,
      toDate,
      datesSelected = false,
      reportType;

    filters.forEach((filter) => {
      if (filter.name === 'subscriptionReportDatesFilter') {
        const sameDayFrom = moment(filter.selectedValues[0].from || filter.selectedValues[0].to).startOf('day');
        const sameDayTo = moment(filter.selectedValues[0].to || filter.selectedValues[0].from).endOf('day');
        fromDate = moment(sameDayFrom).toISOString(true);
        toDate = moment(sameDayTo).toISOString(true);
        datesSelected = true;
      }
      if (filter.name === 'subscriptionReportFilter') {
        reportType = filter.selectedValues[0];
      }
    });

    return {
      fromDate,
      toDate,
      reportType,
      datesSelected,
    };
  }

  // need to convert data from server to cell/row data for client
  const makeCustomerData = (response) => {
    const responseAsArray = Object.entries(response);
    let datesInColumns = [];
    let rows = {};

    responseAsArray.forEach(([date, customers]) => {
      if (!datesInColumns.includes(date)) {
        datesInColumns.push(date);
      }

      customers.forEach((customerObj) => {
        const { name: customerName, lineItems: customerItems, id: customerId } = customerObj;
        if (rows.hasOwnProperty(customerName)) {
          rows[customerName].items[date] = {
            id: `${date}_${customerId}`,
            customerId,
            date,
            name: customerName,
            items: customerItems,
          };
        } else {
          rows[customerName] = {
            name: customerName,
            id: `${date}_${customerId}`,
            customerId,
            date,
            items: {
              [date]: {
                id: `${date}_${customerId}`,
                customerId,
                date,
                name: customerName,
                items: customerItems,
              },
            },
          };
        }
      });
    });

    setReportData({
      columns: makeColumns(datesInColumns),
      rows: Object.values(rows),
      data: response,
    });
  };

  // need to convert data from server to cell/row data for line items (they have different structure)
  const makeLineItemsData = (response) => {
    const responseAsArray = Object.entries(response);
    let datesInColumns = [];
    let rows = {};

    responseAsArray.forEach(([date, lineItems]) => {
      if (!datesInColumns.includes(date)) {
        datesInColumns.push(date);
      }

      lineItems.forEach((item) => {
        const variantId = item.variantId;
        if (rows.hasOwnProperty(variantId)) {
          rows[variantId].items[date] = {
            id: `${date}_${item.variantId}`,
            productId: item.productId,
            variantId: item.variantId,
            name: `${item.productName} - ${item.variantName}`,
            items: item.customers,
            date,
          };
        } else {
          rows[variantId] = {
            name: `${item.productName} - ${item.variantName}`,
            id: item.variantId,
            productId: item.productId,
            variantId: item.variantId,
            items: {
              [date]: {
                id: `${date}_${item.variantId}`,
                productId: item.productId,
                variantId: item.variantId,
                name: `${item.productName} - ${item.variantName}`,
                items: item.customers,
                date,
              },
            },
          };
        }
      });
    });

    setReportData({
      columns: makeColumns(datesInColumns),
      rows: Object.values(rows),
      data: response,
    });
  };

  const makeColumns = (datesInColumns) => {
    const columns = datesInColumns.map((column) => ({
      name: column,
      originalName: 'items',
      title: moment(column).format('MMM D YYYY'),
      getExportCellValue: (row, column) => {
        return renderItemsForExport(row.items[column.name]);
      },
      getCellValue: (row, date) => {
        return renderItemsForExport(row.items[date]);
      },
    }));

    setColumnWidths(
      countWidthOfColumns(getColumns(columns), state.settings.excludeColumns || [], config.columnExtensions)
    );

    return columns;
  };

  function getColumns(columns) {
    return [
      {
        name: 'customerName',
        originalName: 'name',
        title: intl.formatMessage({ id: isCustomerReport ? 'global.customer' : 'global.lineItems' }),
        getExportCellValue: (row) => row.name,
        getCellValue: (row) => row.name,
      },
      ...columns,
    ];
  }

  function getColumnConfig(configType) {
    const columnsConfig = reportConfig(intl);

    return columnsConfig[configType] || columnsConfig[SUBSCRIPTIONS_REPORTS_TYPES.CUSTOMERS];
  }

  const onUpdateTab = () => {
    if (tab.id) {
      onPatchTab(tab, {
        ...state.settings,
      });
    }
  };

  const onOpenFilterDialog = () => {
    setState((state) => ({ ...state, isFiltersDialogActive: true }));
  };

  const onCloseFilterDialog = () => {
    setState((state) => ({ ...state, isFiltersDialogActive: false }));
  };

  const getFiltersDialog = () => {
    const { settings, selectedFilter } = state;

    return (
      <SubscriptionsReportsFilterDialog
        onApplyFilters={onFiltersChange}
        onClose={onCloseFilterDialog}
        appliedFilters={JSON.parse(JSON.stringify(settings.filters))}
        openWithFilter={selectedFilter}
      />
    );
  };

  const onFiltersChange = (newFilters) => {
    const params = filtersToParams(newFilters);

    setState((state) => ({
      ...state,
      settings: {
        ...state.settings,
        filters: newFilters,
      },
      isDatesSelected: params.datesSelected,
    }));

    setConfig(getColumnConfig(params.reportType));
    setInitialDataLoading(true);

    onCloseFilterDialog();
  };

  const onChangeColumnsState = (excludeColumns) => {
    setState((state) => ({
      ...state,
      settings: {
        ...state.settings,
        excludeColumns: excludeColumns,
      },
    }));
  };

  const handleFilterRemove = (removedFilter) => {
    setState((state) => ({
      ...state,
      settings: {
        ...state.settings,
        filters: state.settings.filters.filter((f) => f.name === removedFilter.name),
      },
    }));
  };

  const onChangeSorting = (sorting) => {
    setState((state) => ({
      ...state,
      settings: {
        ...state.settings,
        sorting,
      },
    }));
  };

  const onExportTab = () => {
    const { settings } = state;

    jsonToCsv(
      tableRef.getRows(),
      `${intl.formatMessage({
        id: isCustomerReport ? 'reports.subscriptionsByCustomers' : 'reports.subscriptionsByLineItems',
      })} ${moment().format('ddd DD.MM.YYYY HH:mm')}`,
      true,
      ['id', ...(settings?.excludeColumns || [])],
      getColumns(reportData.columns)
    );
  };

  return (
    <Tab
      title={tab.name}
      key={`${index}-tab`}
      tab={tab}
      actions={
        <TabActions
          tab={tab}
          classes={classes}
          columns={getColumns(reportData.columns)}
          appliedFilters={state.settings.filters}
          withFilters={withFilters}
          onUpdateTab={onUpdateTab}
          onAddingFilter={onOpenFilterDialog}
          onChangeColumnsState={onChangeColumnsState}
          onExportTab={onExportTab}
        />
      }>
      {initialDataLoading && <Spinner size={50} />}
      {!initialDataLoading && !state.isDatesSelected && (
        <p className={classes.noDateInform}>{intl.formatMessage({ id: 'subscription.reports.pleaseSelectDate' })}</p>
      )}
      {!initialDataLoading && state.isDatesSelected && (
        <AdminDXTable
          enableRemoteSearchWithFilters={true}
          onRef={(ref) => {
            setTableRef(ref);
          }}
          serverSideFilters={JSON.parse(JSON.stringify(state.settings.filters))}
          onRemoveFilter={handleFilterRemove}
          onOpenWithFilter={(index, filter) =>
            setState((state) => ({
              ...state,
              isFiltersDialogActive: true,
              selectedFilter: filter,
            }))
          }
          pageSize={getGridPageSize()}
          pageSizes={getGridPageSizes()}
          columnExtensions={config.columnExtensions}
          hiddenColumnNames={state.settings.excludeColumns || []}
          onChangeSorting={onChangeSorting}
          sorting={tab.sorting || state.settings.sorting}
          appliedFilters={tab.filters}
          stickyHeader
          stickyHeaderOffset={HEADER_HEIGHT_STANDARD.extended}
          onColumnWidthsChange={(widths) => {
            setColumnWidths(widths);
          }}
          minColumnWidth={DEFAULT_NORMALIZED_CELL_WIDTH}
          maxColumnWidth={DEFAULT_MAX_CELL_WIDTH}
          columnWidths={columnWidths}
          cellRenderer={CellRenderer}
          apiRetrieve={(props, onSuccess) => {
            onSuccess(reportData.rows);
          }}
          columns={getColumns(reportData.columns)}
          rowRenderer={RowRenderer}
        />
      )}

      {state.isFiltersDialogActive && getFiltersDialog()}
    </Tab>
  );
};

SubscriptionsReportsContent.propTypes = propTypes;

export default SubscriptionsReportsContent;
