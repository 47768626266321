import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import PrimaryTextField from '../PrimaryTextField/PrimaryTextField';
import PrimaryCheckbox from '../PrimaryCheckbox/PrimaryCheckbox';

const theme = createTheme({});

export default function SelectWithSearch(props) {
  const {
    options = [
      // label - is required
      // key - is required
    ],
    value,
    onChange,
    label,

    inputProps,

    ...others
  } = props;
  return (
    <MuiThemeProvider theme={theme}>
      <Autocomplete
        multiple
        id={label}
        options={options}
        disableCloseOnSelect
        value={value}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(selected, fromValues) => {
          return selected.key === fromValues.key;
        }}
        renderOption={(option, { selected }) => {
          return (
            <React.Fragment>
              <PrimaryCheckbox style={{ marginRight: 8 }} checked={selected} />
              {option.label}
            </React.Fragment>
          );
        }}
        renderInput={(params) => {
          return <PrimaryTextField {...params} label={label} {...inputProps} />;
        }}
        {...others}
      />
    </MuiThemeProvider>
  );
}
