import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../../hocs';
import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from '@material-ui/icons/Search';
import {
  PrimaryTextField,
  PrimaryButton,
  PrimarySelect,
  ProductAsCard,
  ProductAsListItem,
  ProductAsCompactListItem,
  SearchHitsWrapper,
  Spinner,
  SearchHitsSubWrapper,
  SearchHits,
} from '../../../components';
import { apiProductsSearch } from '../../../api';
import { SearchProductsParams } from '../../../entities';
import {
  getCollectionViewType,
  setCollectionViewType,
  COLLECTION_VIEW_TYPE_LIST,
  COLLECTION_VIEW_TYPE_LIST_COMPACT,
} from '../../../helpers';
import styles from './styles';

const propTypes = {
  active: PropTypes.bool,
  onProductClick: PropTypes.func,
  onAddCustomItem: PropTypes.func,
  producer: PropTypes.object.isRequired,
  history: PropTypes.object,
  retriever: PropTypes.func,
  updaterCounter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const defaultProps = {
  active: true,
};

const LOCAL_STORAGE_ACTIVE_KEY = 'products.search.active';

class ProductsSearch extends React.Component {
  state = {
    loading: false,
    view: getCollectionViewType(),
    active:
      localStorage.getItem(LOCAL_STORAGE_ACTIVE_KEY) !== null
        ? localStorage.getItem(LOCAL_STORAGE_ACTIVE_KEY).toLowerCase() === 'true'
        : true,
    query: '',
    products: [],
  };

  componentDidMount() {
    // Initial load of products
    this.loadProducts();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.updaterCounter !== this.props.updaterCounter) {
      this.loadProducts();
    }
  }

  loadProducts = () => {
    const { retriever } = this.props;
    const { limit, query, active } = this.state;

    const apiRetriever = retriever ? retriever : apiProductsSearch;

    let options = new SearchProductsParams({
      query: query,
      limit: limit,
      ...(active === true ? { active } : {}),
    });

    this.setState({ loading: true });
    apiRetriever(
      options,
      (products) => {
        this.setState({
          products: products,
          loading: false,
        });
      },
      () => {
        /* Something went wrong */
      }
    );
  };

  getHitComponent = (hit) => {
    const { onProductClick } = this.props;

    let Component = ProductAsCard;
    switch (this.state.view) {
      case COLLECTION_VIEW_TYPE_LIST: {
        Component = ProductAsListItem;
        break;
      }
      case COLLECTION_VIEW_TYPE_LIST_COMPACT: {
        Component = ProductAsCompactListItem;
        break;
      }
      default: {
      }
    }

    return <Component product={hit} onClick={onProductClick} />;
  };

  render() {
    const { intl, classes, loading, onAddCustomItem } = this.props;

    const { products } = this.state;

    return (
      <React.Fragment>
        <div className={classes.header}>
          <PrimaryTextField
            onChange={(event) => this.setState({ query: event.target.value }, () => this.loadProducts())}
            className={classes.search}
            margin="dense"
            autoFocus
            label={intl.formatMessage({ id: 'search.products' })}
          />

          <SearchIcon />

          <div style={{ marginLeft: 'auto' }}>
            <PrimarySelect
              className={classes.activity}
              label={intl.formatMessage({ id: 'global.status' })}
              value={this.state.active ? 'active' : 'all'}
              onChange={(e) => {
                const active = e.target.value === 'active' ? true : null;
                localStorage.setItem(LOCAL_STORAGE_ACTIVE_KEY, active);
                this.setState({ active: active }, () => {
                  this.loadProducts();
                });
              }}>
              <MenuItem key={'active'} value="active">
                {intl.formatMessage({ id: 'global.onlyActive' })}
              </MenuItem>
              <MenuItem key={'all'} value="all">
                {intl.formatMessage({ id: 'global.all' })}
              </MenuItem>
            </PrimarySelect>

            {!!onAddCustomItem && (
              <PrimaryButton className={classes.customItem} onClick={onAddCustomItem}>
                {intl.formatMessage({ id: 'global.customItem' })}
              </PrimaryButton>
            )}
          </div>
        </div>

        {loading && <Spinner />}
        {!loading && (
          <SearchHitsWrapper
            view={this.state.view}
            onSwitchView={(view) => setCollectionViewType(view, (view) => this.setState({ view: view }))}>
            <SearchHitsSubWrapper>
              <SearchHits getComponentCallback={(hit) => this.getHitComponent(hit)} hits={products} />
            </SearchHitsSubWrapper>
          </SearchHitsWrapper>
        )}
      </React.Fragment>
    );
  }
}

ProductsSearch.propTypes = propTypes;
ProductsSearch.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(injectIntl(withRouter(ProductsSearch)));
