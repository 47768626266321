import React from 'react';
import { withRouter } from '../../hocs';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { SidebarMenu, HeaderMenu } from '../Partials';
import { AdminTabs, AdminLayout, Spinner } from '../../components';
import Tab from './Tab/Tab';
import styles from './styles';
import { SHARED_PROPERTY_TYPES } from '../../helpers';

class ProductsPropertiesScene extends React.Component {
  state = {
    tab: 0,
  };

  render() {
    const { tab, loading } = this.state;

    const { intl } = this.props;

    const tabs = [
      intl.formatMessage({ id: 'products.properties.tab.types' }),
      intl.formatMessage({ id: 'products.properties.tab.subTypes' }),
      intl.formatMessage({ id: 'products.properties.tab.units' }),
    ];

    return (
      <AdminLayout sidebarComponent={SidebarMenu} headerMenuComponent={HeaderMenu}>
        {loading && <Spinner size={60} />}

        <AdminTabs onChangeTab={(index) => this.setState({ tab: index })} tab={tab} tabs={tabs}>
          {tab === 0 && (
            <Tab type={SHARED_PROPERTY_TYPES.TYPE} name={intl.formatMessage({ id: 'products.props.column.type' })} />
          )}
          {tab === 1 && (
            <Tab
              type={SHARED_PROPERTY_TYPES.SUB_TYPE}
              name={intl.formatMessage({ id: 'products.props.column.subType' })}
            />
          )}
          {tab === 2 && (
            <Tab type={SHARED_PROPERTY_TYPES.UNIT} name={intl.formatMessage({ id: 'products.props.column.unit' })} />
          )}
        </AdminTabs>
      </AdminLayout>
    );
  }
}

export default withStyles(styles)(withRouter(injectIntl(ProductsPropertiesScene)));
