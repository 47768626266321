import {
  OPEN_SUPPLIER_DRAWER,
  CLOSE_SUPPLIER_DRAWER,
  TOGGLE_SUPPLIER_DRAWER,
  TOGGLE_SUPPLIER_POPOVER,
  SET_SUPPLIER,
} from '../constants';
import {
  REPORT_SUPPLIER_ERROR,
  SET_KEYBOARD_ITEM_TO_EDIT,
  UPDATE_KEYBOARD_ITEM,
  SET_QUANTITY_OVERWRITE,
} from '../constants/supplier';
import { setSupplierObject } from '../../helpers';

export function openSupplierDrawer() {
  return (dispatch) => {
    dispatch({ type: OPEN_SUPPLIER_DRAWER });
  };
}
export function closeSupplierDrawer() {
  return (dispatch) => {
    dispatch({ type: CLOSE_SUPPLIER_DRAWER });
  };
}
export function toggleSupplierDrawer() {
  return (dispatch) => {
    dispatch({ type: TOGGLE_SUPPLIER_DRAWER });
  };
}
export function toggleSupplierPopover(popoverStep) {
  return (dispatch) => {
    dispatch({ type: TOGGLE_SUPPLIER_POPOVER, popoverStep });
  };
}

export function reportSupplierError(error) {
  return (dispatch) => {
    dispatch({ type: REPORT_SUPPLIER_ERROR, error });
  };
}

export function setKeyboardItemToEdit(keyboardObj) {
  return (dispatch) => {
    dispatch({ type: SET_KEYBOARD_ITEM_TO_EDIT, keyboardObj });
  };
}

export function updateKeyboardItem(payload) {
  return (dispatch) => {
    dispatch({ type: UPDATE_KEYBOARD_ITEM, payload });
  };
}
export function setQuantityOverwrite(isOverwrite) {
  return (dispatch) => {
    dispatch({ type: SET_QUANTITY_OVERWRITE, isOverwrite });
  };
}
export function setSupplier(supplier) {
  setSupplierObject(supplier);

  return (dispatch) => {
    dispatch({ type: SET_SUPPLIER, payload: supplier });
  };
}
