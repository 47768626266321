import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { withStyles } from '@material-ui/core/styles'
import { Table } from '@devexpress/dx-react-grid-material-ui'
import { Suggestions } from '../../../components'
import { apiQuickBooksMapAccount } from '../../../api'

const styles = ({
  cell: {
    textTransform: 'capitalize'
  }
});

const propTypes = {
  row: PropTypes.object.isRequired,
};

const QBAccountCell = ({intl, row, classes, ...other}) =>  {
  const filtered = row.suggestions.filter(item => row.qb === item.id);
  const selected = filtered.length ? filtered[0] : null;

  return (
    <Table.Cell {...other}>
      <Suggestions suggestions={row.suggestions}
                   selected={selected}
                   onAttach={(event, data) => {
                     apiQuickBooksMapAccount(row.account, data.suggestion.id)
                   }}
      />
    </Table.Cell>
  );
};

QBAccountCell.propTypes = propTypes;

export default withStyles(styles)(
  injectIntl(QBAccountCell)
);
