import React from 'react';
import { COLORS } from '../constants/colors';

const CalcButtonSymbols = (props) => {
  const { color = COLORS.blackTintedBlue, ...other } = props;
  return (
    <svg width="49" height="14" viewBox="0 0 49 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 13V7H0V6H6V0H7V6H13V7H7V13H6ZM20.8169 11.8789L23.6335 7H18V6H23.6343L20.8169 1.12012L21.6829 0.620117L24.4998 5.5L27.3169 0.620117L28.1829 1.12012L25.3655 6H31V7H25.3662L28.1829 11.8789L27.3169 12.3789L24.5 7.5L21.6829 12.3789L20.8169 11.8789ZM40 14V9H36V8H40V5H36V4H40V1H41V4H44V1H45V4H49V5H45V8H49V9H45V14H44V9H41V14H40ZM44 8V5H41V8H44Z"
        fill={color}
      />
    </svg>
  );
};

export default CalcButtonSymbols;
