import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import CancelIcon from '@material-ui/icons/Cancel';
import {
  apiDiscountRetrieve,
  apiDiscountPatch,
  apiDiscountCreate,
  apiDiscountDeleteCustomer,
  apiDiscountDeleteVariant,
} from '../../api';
import {
  PrimaryTextField,
  PrimaryButton,
  PrimarySelect,
  PrimaryInlineDatePicker,
  Spinner,
  VariantsSuggestions,
  CustomersSuggestions,
  AdminDXTable,
  PrimaryCheckbox,
} from '../../components';
import { COLORS, toCoins, validateField, toPaper, weightToUser, weightToSystem } from '../../helpers';
import validationRules from './validation';
import { TableCell } from './helper';
import ErrorBox from '../../components/ErrorBox/ErrorBox';
import { DISCOUNT_TYPES, DISCOUNT_THRESHOLD_TYPES } from '../../entities/Discount';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = {
  textField: {
    margin: '10px',
    width: 300,
  },
  chipsWrap: {
    width: '50%',
    margin: 10,
  },
  chip: {
    margin: 5,
  },
  productsTableWrap: {
    width: '100%',
    margin: 10,
  },
  actionsWrap: {
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
    paddingTop: 30,
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: 640,
  },
  tint: {
    color: COLORS.actionLabel,
    fontSize: 11,
  },
  switchCustomerWrapper: {
    margin: '0 10px 10px 10px',
  },
  anyCustomerWrapper: {
    width: '100%',
  },
  errorBoxWrapper: {
    marginTop: 20,
    marginBottom: 0,
    marginLeft: 10,
    marginRight: 10,
  },
};

class DiscountForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      type: '',
      thresholdType: DISCOUNT_THRESHOLD_TYPES.NONE,
      thresholdValue: null,
      validFrom: null,
      validTo: null,
      value: '',
      usageLimit: null,
      customers: [],
      variants: [],
      errors: {},
      error: '',
      loading: false,
      anyCustomer: false,

      hiddenColumns: ['price'],
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    if (id && id !== 'new') {
      this.setState({ loading: true });
      apiDiscountRetrieve(
        id,
        (res) => {
          this.setState(
            {
              ...res,
              ...{
                loading: false,
                value: toPaper(res.value),
                anyCustomer: res.anyCustomer,

                thresholdType: (res.threshold && res.threshold.type) || DISCOUNT_THRESHOLD_TYPES.NONE,
                thresholdValue: (() => {
                  if (res.threshold) {
                    /**
                     * In case of Quantity type and Order level discount
                     * (does not have variants added) it should be an actual number
                     */
                    if (res.threshold.type === DISCOUNT_THRESHOLD_TYPES.QUANTITY && !!!res.variants.length) {
                      return res.threshold.value;
                    }

                    return weightToUser(res.threshold.value);
                  }

                  return null;
                })(),

                variants: res.variants.map((item) => {
                  let variant = { ...item, ...{ id: item.id } };
                  if (res.type === DISCOUNT_TYPES.FIXED) {
                    variant.value = toPaper(item.value);
                  }
                  return variant;
                }),
              },
            },
            () => {
              if (this.state.variants.length && this.state.type === DISCOUNT_TYPES.FIXED) {
                this.setState(
                  {
                    loading: false,
                    hiddenColumns: [],
                  },
                  () => this.table.forceReload()
                );
              } else {
                this.setState({ loading: false });
                this.table.forceReload();
              }
            }
          );
        },
        (err) => {
          console.warn(err);
        }
      );
    }
  }

  _handleChange = (key, value) => {
    this.setState({ [key]: value, errors: { ...this.state.errors, [key]: null } }, () => {
      if (this.state.type === DISCOUNT_TYPES.FIXED) {
        this.setState({ hiddenColumns: [] });
      } else {
        this.setState({ hiddenColumns: ['price'] });
      }
    });
  };

  _validate = () => {
    const { intl } = this.props;

    const { name, type, value, thresholdType, thresholdValue, variants } = this.state;
    let errors = {};

    errors.name = validateField(validationRules, 'name', name);
    errors.type = validateField(validationRules, 'type', type);

    if (type !== DISCOUNT_TYPES.FIXED) {
      errors.value = validateField(validationRules, 'value', value);
    }

    if (thresholdType !== DISCOUNT_THRESHOLD_TYPES.NONE) {
      errors.thresholdValue = validateField(validationRules, 'thresholdValue', thresholdValue);
    }

    if (type === DISCOUNT_TYPES.FIXED) {
      if (!Boolean(variants.length)) {
        errors.variants = intl.formatMessage({ id: 'validation.csa.specifyAtLeastOneVariant' });
      }

      for (let i = 0; i < variants.length; i++) {
        if (!Number(variants[i].value)) {
          errors.variants = intl.formatMessage({ id: 'validation.csa.pleaseSpecifyPriceForEachVariant' });
          break;
        }
      }
    }

    this.setState({ errors, error: '' });
    return Object.values(errors).every((item) => item === null);
  };

  _handleSubmit = (e) => {
    e.preventDefault();

    const {
      name,
      validFrom,
      validTo,
      value,
      type,
      thresholdType,
      thresholdValue,
      usageLimit,
      customers,
      variants,
      anyCustomer,
    } = this.state;

    const valid = this._validate();

    if (!valid) return;

    let data = {
      name,
      validFrom,
      validTo,
      value: toCoins(value),
      type,
      usageLimit: parseInt(usageLimit) >= 0 ? Number(usageLimit) : null,
      anyCustomer,
      customerIds: anyCustomer ? [] : customers.map((item) => item.id),
    };

    if (thresholdType !== DISCOUNT_THRESHOLD_TYPES.NONE) {
      let threshold = {
        type: thresholdType,
        value: weightToSystem(Number(thresholdValue)),
      };

      /**
       * In case of Quantity type and Order level discount
       * (does not have variants added) it should be an actual number
       */
      if (thresholdType === DISCOUNT_THRESHOLD_TYPES.QUANTITY && !!!variants.length) {
        threshold.value = Number(thresholdValue);
      }

      data.threshold = threshold;
    } else {
      data.threshold = null;
    }

    data.variants = variants.map((item) => {
      let variant = { id: item.id };
      if (type === DISCOUNT_TYPES.FIXED) {
        variant.value = toCoins(item.value);
      }
      return variant;
    });

    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.setState({ loading: true });
    if (id === 'new') {
      apiDiscountCreate(
        data,
        (response) => {
          this.setState({ loading: false });
          this.props.onSuccess(response);
        },
        this.handleError
      );
    } else {
      apiDiscountPatch(
        id,
        data,
        (response) => {
          this.setState({ loading: false });
          this.props.onSuccess(response);
        },
        this.handleError
      );
    }
  };

  handleError = (error) => {
    const { intl } = this.props;
    const errorData = error?.response?.data;

    if (errorData?.code === 1660049586) {
      this.setState((state) => ({ ...state, loading: false, errors: { ...state.errors, name: errorData?.message } }));
      return;
    }

    this.setState({
      error: errorData?.message || intl.formatMessage({ id: 'global.somethingWentWrong' }),
      loading: false,
    });
  };

  _handleAddCustomer = (customer) => {
    this.setState({
      customers: _.unionBy(this.state.customers, [customer], (item) => item.id),
    });
  };

  _handleDeleteCustomer = (customer) => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.setState(
      {
        customers: this.state.customers.filter((item) => item.id !== customer.id),
      },
      () => {
        if (id !== 'new' && typeof customer.skipServerSideDeletion === 'undefined') {
          apiDiscountDeleteCustomer(id, customer.id, undefined, undefined);
        }
      }
    );
  };

  _handleAddProduct = (product) => {
    this.setState(
      {
        variants: _.unionBy(this.state.variants, [product], (item) => item.id),
      },
      () => this.table.forceReload()
    );
  };

  _handleDeleteProduct = (product) => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.setState(
      {
        variants: this.state.variants.filter((item) => item.id !== product.id),
      },
      () => {
        this.table.forceReload();
        if (id !== 'new' && typeof product.skipServerSideDeletion === 'undefined') {
          apiDiscountDeleteVariant(id, product.id, () => undefined, undefined);
        }
      }
    );
  };

  _onFinishPriceEditing = (variant, price) => {
    this.setState((state) => {
      for (let i = 0; i < state.variants.length; i++) {
        if (state.variants[i].id === variant.id) {
          state.variants[i].value = price;
          break;
        }
      }
      return state;
    });
  };

  render() {
    const { intl, classes } = this.props;

    const { errors, thresholdType, anyCustomer, error, loading, customers } = this.state;
    PrimaryTextField.defaultProps = { className: classes.textField };

    return (
      <form onSubmit={this._handleSubmit} className={classes.form}>
        {this.state.loading && <Spinner size={40} />}

        <PrimaryTextField
          value={this.state.name}
          label={intl.formatMessage({ id: 'discount.name' })}
          onChange={(event) => this._handleChange('name', event.target.value)}
          helperText={errors.name && intl.formatMessage({ id: errors.name })}
          error={!!errors.name}
        />

        <div style={{ width: '100%' }} />

        <PrimarySelect
          value={this.state.type}
          onChange={(event) => this._handleChange('type', event.target.value)}
          label={intl.formatMessage({ id: 'discount.type' })}
          formControlClassName={classes.textField}
          helperText={errors.type && intl.formatMessage({ id: errors.type })}
          error={!!errors.type}>
          <MenuItem value={DISCOUNT_TYPES.PERCENTAGE}>{intl.formatMessage({ id: 'global.percentage' })}</MenuItem>
          <MenuItem value={DISCOUNT_TYPES.AMOUNT}>{intl.formatMessage({ id: 'global.amount' })}</MenuItem>
          <MenuItem value={DISCOUNT_TYPES.FIXED}>{intl.formatMessage({ id: 'global.fixed' })}</MenuItem>
        </PrimarySelect>

        {this.state.type !== DISCOUNT_TYPES.FIXED && (
          <PrimaryTextField
            value={this.state.value}
            label={intl.formatMessage({ id: 'discount.amount' })}
            onChange={(event) => this._handleChange('value', event.target.value)}
            helperText={errors.value && intl.formatMessage({ id: errors.value })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{this.state.type === DISCOUNT_TYPES.AMOUNT ? '$' : '%'}</InputAdornment>
              ),
            }}
            error={!!errors.value}
          />
        )}

        <PrimarySelect
          value={this.state.thresholdType}
          onChange={(event) => this._handleChange('thresholdType', event.target.value)}
          label={intl.formatMessage({ id: 'discount.thresholdType' })}
          formControlClassName={classes.textField}
          helperText={errors.thresholdType && intl.formatMessage({ id: errors.thresholdType })}
          error={!!errors.thresholdType}>
          <MenuItem value={DISCOUNT_THRESHOLD_TYPES.NONE}>{intl.formatMessage({ id: 'global.none' })}</MenuItem>
          <MenuItem value={DISCOUNT_THRESHOLD_TYPES.AMOUNT}>{intl.formatMessage({ id: 'global.amount' })}</MenuItem>
          <MenuItem value={DISCOUNT_THRESHOLD_TYPES.QUANTITY}>{intl.formatMessage({ id: 'global.quantity' })}</MenuItem>
        </PrimarySelect>

        {thresholdType === DISCOUNT_THRESHOLD_TYPES.NONE && <div style={{ width: '100%' }} />}

        {thresholdType !== DISCOUNT_THRESHOLD_TYPES.NONE && (
          <PrimaryTextField
            value={this.state.thresholdValue}
            label={intl.formatMessage({ id: 'discount.threshold' })}
            onChange={(event) => this._handleChange('thresholdValue', event.target.value)}
            helperText={errors.thresholdValue && intl.formatMessage({ id: errors.thresholdValue })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{thresholdType === DISCOUNT_TYPES.AMOUNT ? '$' : ''}</InputAdornment>
              ),
            }}
            error={!!errors.thresholdValue}
          />
        )}

        <PrimaryInlineDatePicker
          value={this.state.validFrom}
          emptyLabel={intl.formatMessage({ id: 'global.none' })}
          label={intl.formatMessage({ id: 'discount.startDate' })}
          onChange={(event) => this._handleChange('validFrom', event)}
          TextFieldComponent={PrimaryTextField}
        />
        <PrimaryInlineDatePicker
          value={this.state.validTo}
          emptyLabel={intl.formatMessage({ id: 'global.none' })}
          label={intl.formatMessage({ id: 'discount.endDate' })}
          onChange={(event) => this._handleChange('validTo', event)}
          TextFieldComponent={PrimaryTextField}
        />

        <CustomersSuggestions
          selected={null}
          allowSwitchCustomerType
          labelExtractor={(item) => item.customerName}
          inputLabel={intl.formatMessage({ id: 'discount.customer' })}
          onDetach={() => {}}
          disabled={anyCustomer}
          switchCustomerWrapperClass={classes.switchCustomerWrapper}
          onAttach={(customer) => this._handleAddCustomer({ ...customer, skipServerSideDeletion: true })}
        />

        <PrimaryTextField
          value={parseInt(this.state.usageLimit) >= 0 ? this.state.usageLimit : ''}
          InputLabelProps={{
            shrink: true,
          }}
          placeholder={intl.formatMessage({ id: 'global.none' })}
          label={intl.formatMessage({ id: 'discount.maxUsage' })}
          onChange={(event) => this._handleChange('usageLimit', event.target.value)}
        />

        <div className={classes.chipsWrap}>
          {!anyCustomer &&
            this.state.customers.map((item) => (
              <Chip
                classes={{ root: classes.chip }}
                key={item.id}
                label={`${item.customerName || item.name}`}
                onDelete={() => this._handleDeleteCustomer(item)}
                className={classes.chip}
              />
            ))}

          <div className={classes.anyCustomerWrapper}>
            <FormControlLabel
              disabled={!!customers?.length}
              control={
                <PrimaryCheckbox
                  checked={anyCustomer}
                  onChange={(event) => this.setState({ anyCustomer: event.target.checked })}
                  color="default"
                />
              }
              label={intl.formatMessage({ id: 'global.anyCustomer' })}
            />
          </div>
        </div>

        <VariantsSuggestions
          selected={null}
          labelExtractor={(item) => `${item.name}`}
          inputLabel={intl.formatMessage({ id: 'discount.products' })}
          onDetach={() => {}}
          clearAfterSelect
          onAttach={(product, variant) =>
            this._handleAddProduct({
              id: variant.id,
              skipServerSideDeletion: true,
              product: product.name,
              name: variant?.name || '',
            })
          }
        />

        <div className={classes.productsTableWrap}>
          {errors.variants && <ErrorBox error={errors.variants} />}

          <Paper>
            <AdminDXTable
              onRef={(table) => (this.table = table)}
              cellRenderer={(...props) => TableCell(this._onFinishPriceEditing, ...props)}
              enableToolbar={false}
              enableSearch={false}
              enablePager={false}
              apiRetrieve={(params, onSuccess) => onSuccess(this.state.variants || [])}
              pageSize={10}
              columnExtensions={[{ columnName: 'price', width: 100 }]}
              hiddenColumnNames={this.state.hiddenColumns}
              columns={[
                { name: 'product', title: intl.formatMessage({ id: 'global.product' }) },
                { name: 'price', title: intl.formatMessage({ id: 'global.newPrice' }) },
              ]}
              actionsTitle={' '}
              actionsHeaderCellStyle={{ textAlign: 'center', paddingRight: 0 }}
              actionsCellStyle={{ textAlign: 'center', paddingRight: 0 }}
              headerCellProps={{ style: { whiteSpace: 'normal' } }}
              actions={[
                {
                  icon: <CancelIcon style={{ color: COLORS.violet, fontSize: 18 }} />,
                  action: (row) => this._handleDeleteProduct(row),
                },
              ]}
            />
          </Paper>
        </div>

        <ErrorBox error={error} className={classes.errorBoxWrapper} />

        <div className={classes.actionsWrap}>
          <PrimaryButton type="submit" isLoading={loading}>
            {intl.formatMessage({ id: 'global.save' })}
          </PrimaryButton>
        </div>
      </form>
    );
  }
}

export default withStyles(styles)(injectIntl(DiscountForm));
