import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../../../hocs';
import {
  AdminDialogTitle,
  AdminDialogContent,
  AdminDialogControls,
  PrimaryButton,
  AdminDialog,
  Spinner,
  ErrorBox,
} from '../../../../components/index';
import EntityForm from './EntityForm/EntityForm';
import { COLORS } from '../../../../helpers';
import { apiProducerShippingRateCreate, apiProducerShippingRatePatch } from '../../../../api';

const styles = {
  wrapper: {},
  name: {
    fontSize: 18,
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.text,
    marginBottom: 40,
  },
  input: {
    marginBottom: 25,
  },
  label: {
    fontSize: 14,
  },
  title: {
    fontSize: 18,
    textTransform: 'capitalize',
  },
  inputText: {
    textAlign: 'right',
  },
  button: {
    minWidth: 330,
    alignSelf: 'center',
    textTransform: 'uppercase',
  },
  controls: {
    margin: '27px 0 0 0',
    textAlign: 'center',
  },
  control: {
    padding: '0 105px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    flex: 1,
  },
};

const propTypes = {
  entity: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
};

class Dialog extends React.Component {
  state = {
    loading: false,
    error: '',
  };

  constructor(props) {
    super(props);
    this.form = null;
  }

  validate = () => {
    const { onDone, entity } = this.props;
    const form = this.form.validate();
    if (form.validated) {
      this.setState({
        loading: true,
        error: '',
      });

      let data = {
        ...form.data,
      };

      if (entity && entity.id) {
        apiProducerShippingRatePatch(
          entity.id,
          data,
          (options) => {
            onDone(options);
          },
          () => this.setState({ loading: false })
        );
      } else {
        apiProducerShippingRateCreate(
          data,
          (options) => {
            onDone(options);
          },
          () => this.setState({ loading: false })
        );
      }
    }
  };

  componentWillUnmount() {
    this.form = null;
  }

  render() {
    const { intl, classes, entity, onClose } = this.props;

    return (
      <AdminDialog open closeButton onClose={onClose}>
        <AdminDialogTitle
          title={
            entity.id
              ? intl.formatMessage({ id: 'producer.dialog.editingDeliveryRate' })
              : intl.formatMessage({ id: 'producer.dialog.addingDeliveryRate' })
          }
        />
        <AdminDialogContent className={classes.wrapper}>
          {this.state.loading && <Spinner size={60} />}
          {this.state.error && <ErrorBox error={this.state.error} />}

          <EntityForm data={entity} onRef={(form) => (this.form = form)} />

          <AdminDialogControls>
            <PrimaryButton className={classes.button} onClick={this.validate}>
              {intl.formatMessage({ id: 'global.save' })}
            </PrimaryButton>
          </AdminDialogControls>
        </AdminDialogContent>
      </AdminDialog>
    );
  }
}

Dialog.propTypes = propTypes;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(Dialog)));
