import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withRouter } from '../../hocs';
import {
  AdminDialog,
  AdminDialogTitle,
  PrimaryCheckbox,
  AdminDialogContent,
  AdminDialogControls,
  PrimaryTextField,
  PrimaryButton,
  Spinner,
} from '../../components';
import styles from './styles';
import { validateField } from '../../helpers';
import validationRules from './validation';

const propTypes = {
  type: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,

  excludeColumns: PropTypes.array,
  filters: PropTypes.array,
  sorting: PropTypes.array,

  detailsRow: PropTypes.string,
};

const defaultProps = {
  excludeColumns: [],
  filters: [],
  sorting: [],
};

class SaveTabSettingDialog extends React.Component {
  state = {
    name: '',
    default: false,
    nameError: '',
  };

  validate = (event) => {
    event.preventDefault();

    const nameError = validateField(validationRules, 'name', this.state.name);
    if (!nameError) {
      this.setState({
        nameError: '',
      });

      this.props.onSave({
        type: this.props.type,
        name: this.state.name,
        default: this.state.default,
        excludeColumns: this.props.excludeColumns,
        columnsWidth: this.props.columnWidths,
        filters: this.props.filters,
        sorting: this.props.sorting,
        groupBy: this.props.groupBy,
        detailColumns: [this.props.detailColumns],
      });
    } else {
      this.setState({
        nameError: nameError,
      });
    }
  };

  render() {
    const { onClose, intl, classes, loading } = this.props;

    return (
      <AdminDialog open closeButton onClose={onClose}>
        <AdminDialogTitle title={intl.formatMessage({ id: 'global.saveTabSetting' })} />
        <AdminDialogContent className={classes.wrapper}>
          {loading && <Spinner size={50} />}

          <form onSubmit={this.validate}>
            <PrimaryTextField
              className={classes.input}
              error={!!this.state.nameError}
              onChange={(event) => this.setState({ name: event.target.value })}
              value={this.state.name}
              id="tab-name-input"
              label={intl.formatMessage({ id: 'tab.name' })}
              type="text"
              InputLabelProps={{ shrink: true }}
              margin="normal"
              helperText={this.state.nameError && intl.formatMessage({ id: this.state.nameError })}
              fullWidth
            />

            <FormControlLabel
              control={
                <PrimaryCheckbox
                  checked={this.state.default}
                  onChange={(event) => this.setState({ default: event.target.checked })}
                  color="default"
                />
              }
              label={intl.formatMessage({ id: 'global.default' })}
            />

            <AdminDialogControls>
              <PrimaryButton className={classes.button} type="submit">
                {intl.formatMessage({ id: 'global.save' })}
              </PrimaryButton>
            </AdminDialogControls>
          </form>
        </AdminDialogContent>
      </AdminDialog>
    );
  }
}

SaveTabSettingDialog.propTypes = propTypes;
SaveTabSettingDialog.defaultProps = defaultProps;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(SaveTabSettingDialog)));
