import axios from 'axios';
import {
  getRequestConfig,
  REQUEST_TYPES,
  replaceUrlPlaceholders
} from  '../request';
import API_END_POINTS from  '../endpoints';

/**
 * Add email subscription
 * @param {string} customerId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerAddEmailSubscription = function (customerId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.CUSTOMER_ADD_EMAIL_SUBSCRIPTION, {
    'cr': customerId
  });
  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  data = data || {};
  config.data = data;
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Patch email subscription
 * @param {string} customerId - customer id
 * @param {string} subscriptionId - subscription id
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerPatchEmailSubscription = function (customerId, subscriptionId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.CUSTOMER_PATCH_EMAIL_SUBSCRIPTION, {
    'cr': customerId,
    'sbs': subscriptionId
  });
  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  data = data || {};
  config.data = data;
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Delete email subscription
 * @param {string} customerId - customer id
 * @param {string} subscriptionId - subscription id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerDeleteEmailSubscription = function (customerId, subscriptionId, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.CUSTOMER_DELETE_EMAIL_SUBSCRIPTION, {
    'cr': customerId,
    'sbs': subscriptionId
  });
  const config = getRequestConfig(REQUEST_TYPES.DEL, url);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};