export default ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    position: 'relative',
    width: 675,
  }
});
