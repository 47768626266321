import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AdminDialog, AdminDialogContent, AdminDialogTitle, PrimaryButton, PrimarySelect } from '../../components';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import MenuItem from '@material-ui/core/MenuItem';
import { SUBSCRIPTIONS_VIEW_TYPES } from '../../scenes/SubscriptionsScene/config';

const useStyles = makeStyles(styles);

const propTypes = {
  onClose: PropTypes.func,
  onDone: PropTypes.func,
  selected: PropTypes.array,
  withSubType: PropTypes.bool,
};

const GroupByDialog = (props) => {
  const { onClose, selected, onDone, withSubType } = props;
  const intl = useIntl();
  const classes = useStyles();
  const [groupedBy, setGroupedBy] = useState(getFirstSelected());

  useEffect(() => {
    const firstGroup = getFirstSelected();

    firstGroup !== groupedBy && setGroupedBy(firstGroup);
  }, [selected]);

  function getFirstSelected() {
    return Array.isArray(selected) ? (!!selected[0] ? selected[0] : -1) : -1;
  }

  const onChange = (event) => {
    const value = event.target.value;

    setGroupedBy(value);
  };

  const onFinish = () => {
    if (groupedBy === -1) {
      onDone([]);
      return;
    }

    onDone([groupedBy]);
  };

  return (
    <AdminDialog open closeButton onClose={onClose}>
      <AdminDialogTitle title={intl.formatMessage({ id: 'dialog.groupBy' })} />
      <AdminDialogContent className={classes.wrapper}>
        <PrimarySelect value={groupedBy} formControlClassName={classes.selectFormControl} onChange={onChange}>
          <MenuItem value={-1}>{intl.formatMessage({ id: 'dialog.groupBy.chooseGroupingParam' })}</MenuItem>
          <MenuItem key={SUBSCRIPTIONS_VIEW_TYPES.LOCATION} value={SUBSCRIPTIONS_VIEW_TYPES.LOCATION}>
            {intl.formatMessage({ id: 'dialog.groupBy.location' })}
          </MenuItem>
          {withSubType && (
            <MenuItem
              key={SUBSCRIPTIONS_VIEW_TYPES.GROUPED_BY_SUB_TYPE}
              value={SUBSCRIPTIONS_VIEW_TYPES.GROUPED_BY_SUB_TYPE}>
              {intl.formatMessage({ id: 'dialog.groupBy.subType' })}
            </MenuItem>
          )}
        </PrimarySelect>

        <div className={classes.controls}>
          <PrimaryButton onClick={onFinish}>{intl.formatMessage({ id: 'global.apply' })}</PrimaryButton>
        </div>
      </AdminDialogContent>
    </AdminDialog>
  );
};

GroupByDialog.propTypes = propTypes;

export default GroupByDialog;
