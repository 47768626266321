import axios from 'axios';
import { getRequestConfig, replaceUrlPlaceholder, REQUEST_TYPES } from '../request';
import API_END_POINTS from '../endpoints';

/**
 * Get locations list
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerLocationsGetList = function (onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.PRODUCER_LOCATIONS_GET_LIST);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Fetch single location by id
 * @param {string} id - location id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerLocationsGetOne = function (id, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRODUCER_LOCATIONS_GET_ONE, 'id', id);
  const config = getRequestConfig(REQUEST_TYPES.GET, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Creates location
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerLocationsCreate = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.PRODUCER_LOCATIONS_CREATE);
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Patch location by id
 * @param {string} id - location id
 * @param {object} data - location data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerLocationsPatch = function (id, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRODUCER_LOCATIONS_PATCH, 'id', id);
  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Delete location by id
 * @param {string} id - location id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerLocationsDelete = function (id, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRODUCER_LOCATIONS_DELETE, 'id', id);
  const config = getRequestConfig(REQUEST_TYPES.DEL, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Creates producer approved location
 * @param {object} data
 * @param {string} data.locationId - required
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCreateProducerApprovedLocations = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.PRODUCER_APPROVED_LOCATIONS_CREATE);
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Get producer approved locations list
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiGetProducerApprovedLocations = function (onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.PRODUCER_APPROVED_LOCATIONS_GET);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Delete producer approved location by id
 * @param {string} id - location id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDeleteProducerApprovedLocation = function (id, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRODUCER_APPROVED_LOCATIONS_DELETE, 'id', id);
  const config = getRequestConfig(REQUEST_TYPES.DEL, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};
