import React from 'react'
import PropTypes from 'prop-types'
import {Table} from '@devexpress/dx-react-grid-material-ui'
import {injectIntl} from 'react-intl'
import {withStyles} from '@material-ui/core/styles'

const styles = ({
  shippingMethod: {
    fontSize: 12,
  },
  deliveryCompany: {
    fontSize: 12,
    fontWeight: 600
  },
});

const propTypes = {
  row: PropTypes.object.isRequired,
};

const PaymentMethodCell = ({intl, row, classes, ...other}) =>  {
  return (
    <Table.Cell {...other}>
      {(row.payment && row.payment.method && row.payment.method.method &&
        intl.formatMessage({id: `global.${row.payment.method.method}`})) || "-"}
    </Table.Cell>
  );
};

PaymentMethodCell.propTypes = propTypes;

export default withStyles(styles)(
  injectIntl(PaymentMethodCell)
);
