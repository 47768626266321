import {
  DELIVERIES_MAKE_INITIALIZED,
  DELIVERIES_MAKE_NON_INITIALIZED,
  DELIVERIES_SIGN_IN,
  DELIVERIES_SIGN_OUT,
  DELIVERIES_CHANGE_LOADING,
} from '../constants';

const initialState = {
  authenticated: false,
  deliveriesToken: null,
  initialized: false,
  loading: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DELIVERIES_SIGN_IN: {
      const { token } = action.payload;

      return {
        ...state,
        deliveriesToken: token,
      };
    }
    case DELIVERIES_SIGN_OUT: {
      return {
        ...state,
        deliveriesToken: null,
      };
    }
    case DELIVERIES_MAKE_INITIALIZED: {
      return {
        ...state,
        initialized: true,
      };
    }
    case DELIVERIES_MAKE_NON_INITIALIZED: {
      return {
        ...state,
        initialized: false,
      };
    }
    case DELIVERIES_CHANGE_LOADING: {
      return {
        ...state,
        loading: !!action.payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
