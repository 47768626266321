import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import moment from 'moment';
import { toPaper, weightToUser } from '../../helpers';

export const prepareTypeFilter = (intl, filters) => {
  let processed = false;
  const defaultFilter = {
    column: intl.formatMessage({ id: 'order.table.types' }),
    index: 1,
    name: 'types',
    property: 'types',
    selectedValues: ['wholesale'],
    values: [],
  };

  for (let i = 0; i < filters.length; i++) {
    if (filters[i].name === 'types') {
      processed = true;
      if (!filters[i].selectedValues.length) {
        filters[i].selectedValues = ['wholesale'];
      }
    }
  }

  if (!processed) {
    filters.push(defaultFilter);
  }

  return filters;
};

/**
 * Converts JSON to CSV and forces file download
 * @param {object} json
 * @param {string} title
 * @param {bool} label
 * @param {array} excludeColumns
 * @param {array} columns
 */
export const jsonToCsv = (json, title, label, excludeColumns, columns) => {
  let arrData = typeof json !== 'object' ? JSON.parse(json) : json;

  let csv = '';

  // This condition will generate the Label/Header
  if (label) {
    let row = '';

    for (let i = 0; i < columns.length; i++) {
      if (columns.hasOwnProperty(i)) {
        for (let index in arrData[0]) {
          if (arrData[0].hasOwnProperty(index) && excludeColumns.indexOf(index) === -1) {
            if (
              (columns[i].name === 'lineItemNameProduct' || columns[i].name === 'lineItemNameVariant') &&
              index === 'lineItemName'
            ) {
              let name = columns[i].title;
              row += name + ',';
              break;
            }

            if (columns[i].name === index || columns[i].originalName === index) {
              let name = columns[i].title;
              row += name + ',';
              break;
            }
          }
        }
      }
    }

    row = row.slice(0, -1);
    csv += row + '\r\n';
  }

  let formatter = {
    booked: (row) => (row.booked ? 'booked' : 'notBooked'),
    ordered: (row) => parseFloat(weightToUser(row.ordered)).toFixed(2),
    packed: (row) => parseFloat(weightToUser(row.packed)).toFixed(2),
    total: (row) => toPaper(row.total),
    subtotal: (row) => toPaper(row.subtotal),
    price: (row) => toPaper(row.price),
    deliveryCompanyFee: (row) => toPaper(row.deliveryCompanyFee),
    shippingFee: (row) => toPaper(row.shippingFee),
    unitPrice: (row) => toPaper(row.unitPrice),
    supplier: (row) => row.supplier || '-',
    listPrice: (row) => toPaper(row.listPrice),
    avgPrice: (row) => toPaper(row.avgPrice),
    createdAt: (row) =>
      row.createdAt
        ? moment
            .parseZone(row.createdAt)
            .format(process.env.REACT_APP_DATE_FORMAT_WITH_SHORT_CALENDAR_DAY_WITH_TIME_LOCALIZED)
        : 'Not set',
    customer: (row) => (row.customer ? row.customer.companyName : 'Not set'),
    shipping: (row) =>
      row.shipping
        ? moment.parseZone(row.shipping.date).format(process.env.REACT_APP_DATE_FORMAT_WITH_FULL_DAY_OF_WEEK)
        : '-',
    'shipping.type': (row) =>
      row.shipping.type + (row.shipping.deliveryCompany ? ' — ' + row.shipping.deliveryCompany : ''),
    paymentMethod: (row) => {
      return row.payment && row.payment.method ? row.payment.method.method : '-';
    },
    payment: (row) => {
      return row.payment && row.payment.method ? row.payment.method.method : '-';
    },
    lineItemName: (row) =>
      typeof row.lineItemName === 'object'
        ? row.lineItemName.product + (!!row?.lineItemName?.variant ? ', ' + row.lineItemName.variant : '')
        : row.lineItemName,
    qBBalance: (row) => (row.qBBalance ? toPaper(row.qBBalance) : '-'),
    qBTotal: (row) => (row.qBTotal ? toPaper(row.qBTotal) : '-'),
    qBDiff: (row) => (row.qBDiff ? toPaper(row.qBDiff) : '-'),
    paidToProducer: (row) => {
      return row.paidToProducer ? 'yes' : 'no';
    },
    lineItemNameProduct: (row) => row.lineItemName.product || '-',
    lineItemNameVariant: (row) => row.lineItemName.variant || '-',
  };

  // 1st loop is to extract each row
  for (let i = 0; i < arrData.length; i++) {
    let row = '';

    // 2nd loop will extract each column and convert it in string comma-separated

    for (let j = 0; j < columns.length; j++) {
      if (columns.hasOwnProperty(j)) {
        for (let index in arrData[i]) {
          if (arrData[i].hasOwnProperty(index) && excludeColumns.indexOf(index) === -1) {
            if (
              (columns[j].name === 'lineItemNameProduct' || columns[j].name === 'lineItemNameVariant') &&
              index === 'lineItemName'
            ) {
              if (Object.keys(formatter).indexOf(columns[j].name) !== -1) {
                row += '"' + formatter[columns[j].name](arrData[i]) + '",';
              }
            }

            if (columns[j].name === index || columns[j].originalName === index) {
              if (Object.keys(formatter).indexOf(index) !== -1) {
                row += '"' + formatter[index](arrData[i]) + '",';
              } else {
                if (typeof arrData[i][index] === 'object') {
                  switch (index) {
                    case 'lineItemName': {
                      row += '"' + arrData[i][index].product + ', ' + arrData[i][index].variant + '",';
                      break;
                    }
                    default: {
                      row += '"object",';
                    }
                  }
                } else {
                  row += '"' + arrData[i][index] + '",';
                }
              }

              break;
            }
          }
        }
      }
    }

    row.slice(0, row.length - 1);
    csv += row + '\r\n';
  }

  if (csv === '') {
    alert('Invalid data');
    return;
  }

  //Generate a file name
  let fileName = 'Export_';
  fileName += title.replace(/( — | - )/g, ' ').replace(/ /g, '_');
  let uri = 'data:text/csv;charset=utf-8,' + escape(csv);
  let link = document.createElement('a');
  link.href = uri;
  link.style = 'visibility:hidden';
  link.download = fileName + '.csv';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const TableCellRenderer = (intl, reload, { row, ...props }) => {
  if (props.column.name === 'paymentMethod') {
    return (
      <Table.Cell {...props}>
        {(row.payment &&
          row.payment.method &&
          row.payment.method.method &&
          intl.formatMessage({ id: `global.${row.payment.method.method}` })) ||
          '-'}
      </Table.Cell>
    );
  }

  return <Table.Cell {...props} />;
};

export const TableRowRenderer = (onClick, { row, ...props }) => {
  return <Table.Row {...props} onClick={() => onClick && onClick(row)} />;
};
