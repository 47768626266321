import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter } from '../../hocs';
import { withStyles } from '@material-ui/core/styles';
import { AdminTabs, AdminLayout, Spinner } from '../../components';
import { SidebarMenu, HeaderMenu } from '../Partials';
import { TabTypes } from '../../dialogs';
import { apiCreateDraft, apiCreateTab, apiDeleteTab, apiPatchTab } from '../../api';
import { MAIN_TAB_NAMES } from '../../helpers/tabs';
import { setProducer } from '../../store/actions';
import styles from './styles';
import TabWrapper from './TabWrapper';
import { Order } from '../../entities';
import { jsonToCsv } from './helpers';
import { compareStringByNumber, compareMomentDates, compareNotMomentDates } from '../../helpers';

const customer = {
  name: (row) => new Order(row).getCustomerName() || '-',
  email: (row) => new Order(row).getCustomer().email || '-',
  phone: (row) => new Order(row).getCustomer().phone || '-',
  orderName: (row) => (row.customer.orderName && row.name !== row.customer.orderName ? row.customer.orderName : '-'),
};

const shipping = {
  date: (row) =>
    row.shipping
      ? moment.parseZone(row.shipping.date).format(process.env.REACT_APP_DATE_FORMAT_WITH_FULL_DAY_OF_WEEK)
      : '-',
  type: (row) => (row.shipping ? row.shipping.type : '-'),
  deliveryCompanyName: (row) =>
    (row.shipping && row.shipping.deliveryCompany && row.shipping.deliveryCompany.name) || '-',
  address: (row) => (row.customer.shippingAddress ? row.customer.shippingAddress.address1 : '-'),
  address2: (row) =>
    row.customer.shippingAddress && row.customer.shippingAddress.address2 ? row.customer.shippingAddress.address2 : '-',
  city: (row) => (row.customer.shippingAddress ? row.customer.shippingAddress.city : '-'),
  zip: (row) => (row.customer.shippingAddress ? row.customer.shippingAddress.zip : '-'),
  location: (row) => (row.shipping.location ? row.shipping.location.name : '-'),
};

class OrdersScene extends React.Component {
  constructor(props) {
    super(props);
    const { intl, tabs } = this.props;

    this.state = {
      loading: false,
      tabIndex: tabs.getInitialIndex(window.location.hash, true),
      sortingColumnExtensions: [
        {
          columnName: 'name',
          compare: compareStringByNumber,
        },
        {
          columnName: 'shipping',
          compare: compareMomentDates,
        },
        {
          columnName: 'shipping.date',
          compare: compareNotMomentDates,
        },
        {
          columnName: 'customer.orderName',
          compare: compareStringByNumber,
        },
        {
          columnName: 'createdAt',
          compare: compareMomentDates,
        },
      ],
      defaultDraftColumns: [
        {
          name: 'name',
          title: intl.formatMessage({ id: 'order.table.name' }),
        },
        { name: 'createdAt', title: intl.formatMessage({ id: 'order.table.createdAt' }) },
        {
          name: 'customer.name',
          title: intl.formatMessage({ id: 'order.table.customer.name' }),
          getCellValue: customer.name,
        },
        { name: 'shipping', title: intl.formatMessage({ id: 'order.table.shippingType' }) },
        {
          name: 'createdBy',
          title: intl.formatMessage({ id: 'order.table.createdBy' }),
          getCellValue: (row) => (row.createdBy && row.createdBy.name) || '-',
        },
        { name: 'source', title: intl.formatMessage({ id: 'order.table.source' }) },
        { name: 'total', title: intl.formatMessage({ id: 'order.table.total' }) },
      ],
      defaultColumnsAvailable: [
        { name: 'intradayNumber', title: intl.formatMessage({ id: 'order.table.intraday' }) },
        { name: 'name', title: intl.formatMessage({ id: 'order.table.name' }) },
        {
          name: 'customer.orderName',
          title: intl.formatMessage({ id: 'order.table.customer.orderName' }),
          type: 'customerOrderName',
          getCellValue: customer.orderName,
          getExportCellValue: customer.orderName,
          originalName: 'customer',
        },
        {
          name: 'customer.name',
          originalName: 'customer',
          title: intl.formatMessage({ id: 'order.table.customer.name' }),
          getCellValue: customer.name,
        },
        {
          name: 'customer.email',
          originalName: 'customer',
          title: intl.formatMessage({ id: 'order.table.customer.email' }),
          getCellValue: customer.email,
        },
        {
          name: 'customer.phone',
          originalName: 'customer',
          title: intl.formatMessage({ id: 'order.table.customer.phone' }),
          getCellValue: customer.phone,
        },
        {
          name: 'shipping.date',
          title: intl.formatMessage({ id: 'order.table.shipping' }),
          originalName: 'shipping',
          getCellValue: shipping.date,
        },
        {
          name: 'shipping.type',
          title: intl.formatMessage({ id: 'order.table.shippingType' }),
          type: 'shipping',
          getExportCellValue: shipping.type,
          originalName: 'shipping',
          getCellValue: (row) => (row.shipping ? row.shipping.type : '-'),
        },
        {
          name: 'shipping.deliveryCompany.name',
          title: intl.formatMessage({ id: 'order.table.deliveryCompany' }),
          type: 'shipping',
          getExportCellValue: shipping.deliveryCompanyName,
          getCellValue: (row) =>
            (row.shipping && row.shipping.deliveryCompany && row.shipping.deliveryCompany.name) || '-',
          originalName: 'shipping',
        },
        {
          name: 'shipping.address1',
          title: intl.formatMessage({ id: 'customer.delivery.address1' }),
          type: 'shippingAddress1',
          getCellValue: shipping.address,
          getExportCellValue: shipping.address,
          originalName: 'customer',
        },
        {
          name: 'shipping.address2',
          title: intl.formatMessage({ id: 'customer.delivery.address2' }),
          type: 'shippingAddress2',
          getCellValue: shipping.address2,
          getExportCellValue: shipping.address2,
          originalName: 'customer',
        },
        {
          name: 'shipping.city',
          title: intl.formatMessage({ id: 'customer.delivery.city' }),
          type: 'shippingCity',
          getCellValue: shipping.city,
          getExportCellValue: shipping.city,
          originalName: 'customer',
        },
        {
          name: 'shipping.zip',
          title: intl.formatMessage({ id: 'customer.delivery.zip' }),
          type: 'shippingZip',
          getCellValue: shipping.zip,
          getExportCellValue: shipping.zip,
          originalName: 'customer',
        },
        {
          name: 'note',
          title: intl.formatMessage({ id: 'order.note' }),
          type: 'shippingNote',
          getCellValue: (row) => (row.note ? row.note : '-'),
        },
        {
          name: 'shipping.location',
          title: intl.formatMessage({ id: 'global.location' }),
          type: 'shippingLocation',
          getCellValue: shipping.location,
          getExportCellValue: shipping.location,
          originalName: 'shipping',
        },
        { name: 'status', title: intl.formatMessage({ id: 'order.table.status' }) },
        { name: 'source', title: intl.formatMessage({ id: 'order.table.source' }) },
        { name: 'type', title: intl.formatMessage({ id: 'order.table.type' }) },
        {
          name: 'financialStatus',
          title: intl.formatMessage({ id: 'order.table.financialStatus' }),
          type: 'paymentStatus',
        },
        {
          name: 'paymentReceived',
          title: intl.formatMessage({ id: 'order.table.paymentReceived' }),
          type: 'paymentReceived',
        },
        {
          name: 'paymentMethod',
          title: intl.formatMessage({ id: 'order.table.paymentMethod' }),
          type: 'paymentMethod',
        },
        { name: 'createdAt', title: intl.formatMessage({ id: 'order.table.createdAt' }) },
        { name: 'booking', title: intl.formatMessage({ id: 'global.booking' }), type: 'booking' },
        { name: 'total', title: intl.formatMessage({ id: 'order.table.total' }) },
      ],
      defaultHiddenColumnOrderNames: [
        'intradayNumber',
        'customer.orderName',
        'customer.email',
        'customer.phone',
        'shipping.address1',
        'shipping.address2',
        'shipping.city',
        'shipping.zip',
        'note',
        'shipping.location',
        'source',
        'type',
        'paymentReceived',
        'paymentMethod',
        'createdAt',
        'booking',
      ],
    };
  }

  onCreateTab = (data, onDone) => {
    this.setState({ loading: true });
    apiCreateTab(
      data,
      (producer) => {
        this.props.setProducer(producer);
        this.setState((state) => {
          state.loading = false;
          onDone && onDone();
          return state;
        });
      },
      undefined
    );
  };

  onDeleteTab = (tab) => {
    this.setState({ loading: true });
    apiDeleteTab(
      tab.id,
      (producer) => {
        this.setState(
          {
            tabIndex: 0,
            loading: false,
          },
          () => {
            this.props.setProducer(producer);
          }
        );
      },
      undefined
    );
  };

  onPatchTab = (tab, data, onDone) => {
    this.setState({ loading: true });
    apiPatchTab(
      tab.id,
      data,
      (producer) => {
        this.props.setProducer(producer);
        this.setState({ loading: false });
        onDone && onDone();
      },
      undefined
    );
  };

  onPatchOrdersTab = (tab, data, onDone) => {
    const { tabs } = this.props;
    let ordersTab = tabs.getTabByName(MAIN_TAB_NAMES.ORDERS);
    if (ordersTab) {
      this.onPatchTab(ordersTab, data, onDone);
    } else {
      data.name = MAIN_TAB_NAMES.ORDERS;
      data.type = TabTypes.ORDERS;
      this.onCreateTab(data);
    }
  };

  onPatchDraftsTab = (tab, data, onDone) => {
    const { draftTabs } = this.props;
    let draftsTab = draftTabs.getTabByName(MAIN_TAB_NAMES.DRAFTS);
    if (draftsTab) {
      this.onPatchTab(draftsTab, data, onDone);
    } else {
      data.name = MAIN_TAB_NAMES.DRAFTS;
      data.type = TabTypes.DRAFTS;
      this.onCreateTab(data);
    }
  };

  onChangeTab = (index) => {
    const { tabs } = this.props;
    window.location.hash = tabs.getHashByIndex(index);
    this.setState({ tabIndex: index });
  };

  onExportTab = (table, hiddenColumns) => {
    const { intl } = this.props;

    jsonToCsv(
      table.getRows(),
      `${intl.formatMessage({ id: 'global.orders' })} ${moment().format('ddd DD.MM.YYYY HH:mm')}`,
      true,
      ['id', ...hiddenColumns],
      [...table.state.columns]
    );
  };

  getTabs() {
    const { tabIndex } = this.state;
    const { intl, tabs, history, draftTabs } = this.props;

    const tabsTitles = tabs.getTabNames([
      intl.formatMessage({ id: 'global.drafts' }),
      intl.formatMessage({ id: 'global.orders' }),
    ]);
    const customTabs = tabs.getOnlyCustom();
    let ordersTab = tabs.getTabByName(MAIN_TAB_NAMES.ORDERS, tabsTitles[1], {
      id: MAIN_TAB_NAMES.ORDERS,
      name: tabsTitles[1],
    });

    let draftsTab = draftTabs.getTabByName(MAIN_TAB_NAMES.DRAFTS, tabsTitles[1], {
      id: MAIN_TAB_NAMES.DRAFTS,
      name: tabsTitles[1],
    });

    return (
      <AdminTabs onChangeTab={this.onChangeTab} tab={tabIndex} tabs={tabsTitles}>
        {this.state.loading && <Spinner size={30} />}

        {tabIndex === 0 && (
          <TabWrapper
            key={'draft-0'}
            contentTag={'DraftsTabContent'}
            index={'drafts'}
            onCreateEntity={() => apiCreateDraft((draft) => history.push(`/draft/${draft.id}`), undefined)}
            labels={{ createEntity: intl.formatMessage({ id: 'drafts.new' }) }}
            tab={draftsTab}
            onPatchTab={this.onPatchDraftsTab}
            columns={this.state.defaultDraftColumns}
            sortingColumnExtensions={this.state.sortingColumnExtensions}
          />
        )}

        {tabIndex === 1 && (
          <TabWrapper
            key={'orders-1'}
            contentTag={'OrdersTabContent'}
            index={'orders'}
            allowCreateTab
            withFilters
            onCreateTab={this.onCreateTab}
            onExportTab={this.onExportTab}
            onPatchTab={this.onPatchOrdersTab}
            tab={ordersTab}
            columns={this.state.defaultColumnsAvailable}
            defaultHiddenColumnNames={this.state.defaultHiddenColumnOrderNames}
            sortingColumnExtensions={this.state.sortingColumnExtensions}
          />
        )}

        {!!customTabs.length && tabIndex > 1 && (
          <TabWrapper
            contentTag={'OrdersTabContent'}
            key={`custom-${tabIndex}`}
            index={tabIndex}
            withFilters
            tab={customTabs[tabIndex - 2]}
            onExportTab={this.onExportTab}
            onPatchTab={this.onPatchTab}
            onDeleteTab={this.onDeleteTab}
            columns={this.state.defaultColumnsAvailable}
            defaultHiddenColumnNames={this.state.defaultHiddenColumnOrderNames}
            sortingColumnExtensions={this.state.sortingColumnExtensions}
          />
        )}
      </AdminTabs>
    );
  }

  render() {
    return (
      <AdminLayout sidebarComponent={SidebarMenu} headerMenuComponent={HeaderMenu}>
        {this.getTabs()}
      </AdminLayout>
    );
  }
}

OrdersScene.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    tabs: state.producer.tabs[TabTypes.ORDERS],
    draftTabs: state.producer.tabs[TabTypes.DRAFTS],
    producer: state.producer.object,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProducer: (producer) => dispatch(setProducer(producer)),
  };
};

export default withStyles(styles)(withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(OrdersScene))));
