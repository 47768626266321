import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { COLORS } from '../../../../../helpers';
import { PrimarySwitch } from '../../../../../components';

const propTypes = {
  onAttachLocation: PropTypes.func.isRequired,
  onDetachLocation: PropTypes.func.isRequired,
  locations: PropTypes.array,
};

const styles = {
  wrapper: {
    width: '100%',
    borderTop: '1px solid ' + COLORS.border,
    padding: '20px 25px 5px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 5,
    paddingBottom: 5,
    justifyContent: 'space-between',
  },
  title: {
    color: COLORS.cartSecondary,
    fontSize: '12px',
  },
  button: {
    padding: 0,
  },
  icon: {
    fontSize: '26px',
    color: COLORS.text,
  },
  location: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid' + COLORS.border,
    padding: '5px 0 10px',
    alignItems: 'center',
    justifyContent: 'left',
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid' + COLORS.border,
    padding: '10px 0 10px',
    alignItems: 'center',
    justifyContent: 'left',
  },
  cell: {
    flex: 2,
    fontSize: '14px',
    color: COLORS.text,
    fontFamily: 'Roboto',
    marginRight: 5,
    textAlign: 'left',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  image: {
    height: 40,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
};

const Locations = ({
  intl,
  classes,
  locations,
  onDetachLocation,
  onAttachLocation,
  onEditLocation,
  onToggleLocation,
}) => {
  function getSelected(location, type) {
    let result = [];
    location[type].pickup && result.push('pickup');
    location[type].delivery && result.push('delivery');
    return result.join(' & ');
  }

  return (
    <Paper className={classes.wrapper} elevation={1}>
      <div className={classes.header}>
        <Typography className={classes.title}>{intl.formatMessage({ id: 'global.locations' })}</Typography>

        <IconButton className={classes.button} onClick={onAttachLocation}>
          <AddCircleIcon className={classes.icon} />
        </IconButton>
      </div>

      {!!locations.length && (
        <div className={classes.headerRow}>
          <div className={classes.cell} style={{ minWidth: 200 }}>
            {intl.formatMessage({ id: 'global.name' })}
          </div>
          <div style={{ textAlign: 'center' }} className={classes.cell}>
            {intl.formatMessage({ id: 'filter.types.retail' })}
          </div>
          <div style={{ textAlign: 'center' }} className={classes.cell}>
            {intl.formatMessage({ id: 'filter.types.wholesale' })}
          </div>
          <div style={{ textAlign: 'center', flex: 3 }} className={classes.cell}>
            {intl.formatMessage({ id: 'global.actions' })}
          </div>
        </div>
      )}

      {locations.map((location) => (
        <div key={location.id} className={classes.location}>
          <div className={classes.cell} style={{ minWidth: 200 }}>
            {location.name}
          </div>
          <div style={{ textAlign: 'center' }} className={classes.cell}>
            <span className={classes.capitalize}>{getSelected(location, 'retail')}</span>
          </div>
          <div style={{ textAlign: 'center' }} className={classes.cell}>
            <span className={classes.capitalize}>{getSelected(location, 'wholesale')}</span>
          </div>
          <div style={{ textAlign: 'center', flex: 1 }} className={classes.cell}>
            <IconButton className={classes.button} onClick={() => onEditLocation(location)}>
              <EditIcon className={classes.icon} />
            </IconButton>
          </div>
          <div style={{ textAlign: 'center', flex: 1 }} className={classes.cell}>
            <PrimarySwitch checked={location.active} onClick={() => onToggleLocation(location)} value="active" />
          </div>

          <div style={{ textAlign: 'center', flex: 1 }} className={classes.cell}>
            <IconButton className={classes.button} onClick={() => onDetachLocation(location)}>
              <DeleteIcon className={classes.icon} />
            </IconButton>
          </div>
        </div>
      ))}
    </Paper>
  );
};

Locations.propTypes = propTypes;

export default withStyles(styles)(injectIntl(Locations));
