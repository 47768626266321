import axios from 'axios';
import {
  getRequestConfig,
  replaceUrlPlaceholder,
  REQUEST_TYPES,
} from  '../request';
import API_END_POINTS from  '../endpoints';

/**
 * Get settings
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerPaymentSettingsGet = function (onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.PRODUCER_PAYMENTS_SETTINGS_GET);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Accept TOS
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerPaymentSettingsAcceptTOS = function (onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.PRODUCER_PAYMENTS_SETTINGS_ACCEPT_TOS);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Edit account
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerPaymentSettingsEditAccount = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.PATCH, API_END_POINTS.PRODUCER_PAYMENTS_SETTINGS_EDIT_ACCOUNT);
  config.data = data;
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Add bank account
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerPaymentSettingsAddBankAccount = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.PRODUCER_PAYMENTS_SETTINGS_ADD_BANK_ACCOUNT);
  config.data = data;
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Retrieve Stripe persons
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerStripePersonGetAll = function (onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.PRODUCER_STRIPE_PERSON_GET_ALL);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Retrieve Stripe person
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerStripePersonGetOne = function (onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.PRODUCER_STRIPE_PERSON_GET_ONE);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Create Stripe person
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerStripePersonCreate = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.PRODUCER_STRIPE_PERSON_CREATE);
  config.data = data;
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Patch Stripe person
 * @param {string} id
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerStripePersonPatch = function (id, data, onSuccess, onError) {
  let url = replaceUrlPlaceholder(API_END_POINTS.PRODUCER_STRIPE_PERSON_PATCH, 'id', id);
  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  config.data = data;
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Delete Stripe person
 * @param {string} id
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerStripePersonDelete = function (id, data, onSuccess, onError) {
  let url = replaceUrlPlaceholder(API_END_POINTS.PRODUCER_STRIPE_PERSON_DELETE, 'id', id);
  const config = getRequestConfig(REQUEST_TYPES.DEL, url);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Delete Stripe person
 * @param {string} type - on boarding type (custom_account_verification|custom_account_update)
 * @param {string} successUrl - success url
 * @param {string} failureUrl - failure url
 */
export const apiProducerStripeOnBoard = ( type,
                                          successUrl,
                                          failureUrl ) => new Promise((resolve, reject) => {

  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.PRODUCER_STRIPE_START_ON_BOARD);

  config.data = {
    type,
    successUrl,
    failureUrl
  };

  axios(config).then((response) => {
    resolve(response);
  }).catch((error) => {
    reject(error);
  });

});
