import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from '../hocs';
import { Spinner } from '../components';
import { supplierSignIn, supplierSignOut, toggleSupplierPopover } from '../store/actions';
import { apiSupplierInitialData } from '../api';
import ErrorScene from '../scenes/SupplierScene/SupplierErrorScene';
import PopoverWithContent, { POPOVER_STEPS } from '../scenes/SupplierScene/components/PopoverWithContent';
import { isJWTExpired, jwtDecode } from '../helpers/auth';
import { setSupplier } from '../store/actions/supplier';

const PrivatRouteWithTokenLogin = (props) => {
  const {
    component: Component,
    errorComponent: ErrorComponent,
    render,
    match,
    history,
    componentProps,
    ...rest
  } = props;
  const { token, producerId } = match.params;
  const [loading, setLoading] = useState(!!token);
  const [tokenApproved, setTokenApproved] = useState(false);
  const dispatch = useDispatch();
  const onLogIn = (data, onReady) => dispatch(supplierSignIn(data, onReady));
  const onSignOut = () => dispatch(supplierSignOut());
  const onSetSupplier = (supplierData) => dispatch(setSupplier(supplierData));
  const setPopover = (newStep) => {
    dispatch(toggleSupplierPopover(newStep));
  };

  const { authenticated, supplierToken } = useSelector((state) => ({
    authenticated: !!state.auth.supplierToken,
    supplierToken: state.auth.supplierToken,
  }));

  useEffect(() => {
    if (isJWTExpired(supplierToken)) {
      onSignOut();
      setTokenApproved(false);
    }

    if (!!token) {
      const tokenData = jwtDecode(token);
      const savedTokenData = jwtDecode(supplierToken);
      const supplierId = tokenData?.uid;

      if (supplierId !== savedTokenData?.uid) {
        onSignOut();
      }

      apiSupplierInitialData(
        token,
        supplierId,
        (response) => {
          setLoading(false);
          setTokenApproved(true);
          onLogIn({
            token: token,
          });

          onSetSupplier(response);

          if (!!producerId) {
            setPopover(POPOVER_STEPS.firstVisit);
          }
        },
        () => {
          onSignOut();
          setLoading(false);
          setTokenApproved(false);
        }
      );
    } else {
      setLoading(false);
    }
  }, [token]);

  if (loading) {
    return <Spinner size={50} />;
  } else if (!!authenticated) {
    return (
      <>
        <Component {...rest} {...componentProps} />
        <PopoverWithContent />
      </>
    );
  } else if (tokenApproved) {
    return (
      <>
        <Component {...rest} {...componentProps} />
        <PopoverWithContent />
      </>
    );
  } else {
    return <ErrorScene />;
  }
};

export default withRouter(PrivatRouteWithTokenLogin);
