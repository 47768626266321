import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { PrimarySelect, ConfirmDialog } from '../../components';
import { apiArchiveItem, apiUnArchiveItem } from '../../api';
import { ITEMS_STATUSES, makeItemsStatusesByIds, makeProductAndVariantIds } from './helpers';

const styles = {
  select: {
    marginRight: 10,
  },
  paperClass: {
    padding: '47px 110px 74px',
    borderRadius: 9,
  },
};

const LABEL = -1;
const ARCHIVE = 2;
const UN_ARCHIVE = 3;

const propTypes = {
  reload: PropTypes.func,
  keys: PropTypes.array.isRequired,
};

class ProductsBatchControls extends React.Component {
  state = {
    confirmLoading: false,
    confirmArchiveDialog: false,
    confirmUnarchiveDialog: false,
    confirmDeleteDialog: false,
  };

  onSelectAction = (action) => {
    switch (action) {
      case ARCHIVE: {
        this.setState({ confirmArchiveDialog: true });
        break;
      }
      case UN_ARCHIVE: {
        this.setState({ confirmUnarchiveDialog: true });
        break;
      }
      default: {
        break;
      }
    }
  };

  onArchive = () => {
    const { keys, reload } = this.props;

    this.setState({ confirmLoading: true });
    apiArchiveItem(
      makeProductAndVariantIds(keys),
      () => {
        this.setState(
          {
            confirmArchiveDialog: false,
            confirmLoading: false,
          },
          () => {
            this.saveItemsStatuses(ITEMS_STATUSES.ARCHIVED);

            reload();
          }
        );
      },
      undefined
    );
  };

  onUnarchive = () => {
    const { keys, reload } = this.props;

    this.setState({ confirmLoading: true });
    apiUnArchiveItem(
      makeProductAndVariantIds(keys),
      () => {
        this.setState(
          {
            confirmUnarchiveDialog: false,
            confirmLoading: false,
          },
          () => {
            this.saveItemsStatuses(ITEMS_STATUSES.UNARCHIVED);

            reload();
          }
        );
      },
      undefined
    );
  };

  confirmArchive = () => {
    const { intl, keys, classes } = this.props;
    const { confirmArchiveDialog, confirmUnarchiveDialog, confirmLoading } = this.state;

    const title = confirmArchiveDialog ? 'product.confirmArchive.title' : 'product.confirmUnarchive.title';
    const message = confirmArchiveDialog ? 'product.confirmArchive.content' : 'product.confirmUnarchive.content';
    const confirmTitle = confirmArchiveDialog ? 'product.confirmArchive.button' : 'product.confirmUnarchive.button';
    const action = confirmArchiveDialog ? this.onArchive : this.onUnarchive;

    return (
      <ConfirmDialog
        open={confirmArchiveDialog || confirmUnarchiveDialog}
        onClose={() => this.setState({ confirmArchiveDialog: false, confirmUnarchiveDialog: false })}
        loading={confirmLoading}
        paperClass={classes.paperClass}
        title={intl.formatMessage({ id: title }, { numberOfItems: keys?.length })}
        message={intl.formatMessage({ id: message })}
        confirmTitle={confirmTitle}
        onConfirm={action}
      />
    );
  };

  saveItemsStatuses = (status) => {
    const { onUpdateItemsStatuses, keys } = this.props;

    onUpdateItemsStatuses(makeItemsStatusesByIds(keys, status));
  };

  render() {
    const { intl, classes, onlyArchived } = this.props;

    return (
      <React.Fragment>
        <PrimarySelect className={classes.select} value={LABEL} onChange={(e) => this.onSelectAction(e.target.value)}>
          <MenuItem key={LABEL} value={LABEL}>
            {intl.formatMessage({ id: 'products.table.batchActions' })}
          </MenuItem>
          {!onlyArchived && (
            <MenuItem key={ARCHIVE} value={ARCHIVE}>
              {intl.formatMessage({ id: 'global.archiveSelectedItems' })}
            </MenuItem>
          )}
          {onlyArchived && (
            <MenuItem key={UN_ARCHIVE} value={UN_ARCHIVE}>
              {intl.formatMessage({ id: 'global.unArchiveSelectedItems' })}
            </MenuItem>
          )}
        </PrimarySelect>

        {this.confirmArchive()}
      </React.Fragment>
    );
  }
}

ProductsBatchControls.propTypes = propTypes;

export default withStyles(styles)(injectIntl(ProductsBatchControls));
