import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import DXSelectFilter from '../DXSelectFilter/DXSelectFilter';
import DXFilterConfig from '../DXFilterConfig';
import constants from '../../constants';
import PrimarySelect from '../../../../PrimarySelect/PrimarySelect';
import MenuItem from '@material-ui/core/MenuItem';

const propTypes = {
  equality: PropTypes.string,
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  selected: [],
};

class DXReportExcludeFilter extends React.Component {
  render() {
    const { selected, onChange, intl, equality } = this.props;
    const config = DXReportExcludeFilter.getConfig(intl, equality);
    return (
      <DXSelectFilter
        selected={selected}
        title={config.title}
        values={config.values}
        onChange={onChange}
        multi={config.multi}
      />
    );
  }
}

DXReportExcludeFilter.propTypes = propTypes;
DXReportExcludeFilter.defaultProps = defaultProps;
DXReportExcludeFilter.getLabel = (intl, selectedValues) => {
  return DXSelectFilter.getLabel(intl, 'filter.exclude.', selectedValues);
};
DXReportExcludeFilter.applyToQueryParams = (queryParams, selectedValues) => {
  if (selectedValues.includes('excludePrepurchased')) {
    queryParams['excludePrepurchased'] = true;
  }

  if (selectedValues.includes('excludeSubscription')) {
    queryParams['excludeSubscription'] = true;
  }

  return queryParams;
};
DXReportExcludeFilter.getConfig = (intl, equality, queryParamsByFilterName) => {
  let config = new DXFilterConfig({
    name: 'reports.exclude',
    selected: [],
    property: 'reports.exclude',
    title: intl.formatMessage({ id: 'filter.excludeFilterName' }),
    column: intl.formatMessage({ id: 'filter.excludeChipsName' }),
    disabled: false,
    equalityStatus: false,
    variants: () => (
      <PrimarySelect
        label={intl.formatMessage({ id: 'global.filter' })}
        value={constants.FILTER_EQUALITY_EXCLUDE}
        fullWidth
        disabled={true}
        onChange={() => {}}>
        <MenuItem key={constants.FILTER_EQUALITY_EXCLUDE} value={constants.FILTER_EQUALITY_EXCLUDE}>
          {intl.formatMessage({ id: 'filter.amount.exclude' })}
        </MenuItem>
      </PrimarySelect>
    ),
    multi: true,
    queryParams: queryParamsByFilterName,
  });

  if (equality === constants.FILTER_EQUALITY_EQUALS) {
    config.addFilterValue('excludePrepurchased', intl.formatMessage({ id: 'filter.exclude.excludePrepurchased' }));
    config.addFilterValue('excludeSubscription', intl.formatMessage({ id: 'filter.exclude.excludeSubscription' }));
  }

  return config;
};

export default injectIntl(DXReportExcludeFilter);
