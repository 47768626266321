import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowIcon } from '../../assets';
import { BORDER_RADIUS_MAIN, CUSTOMER_TYPES, LOCATIONS_VIEW_TYPES, ORDER_DATE_FORMAT } from '../../constants/globals';
import { COLORS } from '../../constants';
import { FONT_CART_VARIANT_SUB, FONT_FAMILY, FONT_SIZES } from '../../constants/fonts';
import { withRouter } from '../../hocs';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { weightToUser } from '../../helpers';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',

    '@media print': {
      display: 'block',
    },
  },

  singleItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: 20,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 5,
    paddingRight: 5,
    marginLeft: -5,
    marginRight: -5,
    boxSizing: 'border-box',
    borderRadius: BORDER_RADIUS_MAIN,
    cursor: 'pointer',
    backgroundColor: COLORS.white,
    transition: 'background-color 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: COLORS.backgroundLight,
    },
  },
  simpleViewLocation: {
    paddingBottom: 15,
    paddingTop: 15,
    marginBottom: 10,
  },
  locationName: {
    paddingBottom: 7,
    paddingTop: 7,
    marginBottom: 5,
  },
  singleItemTree: {
    marginBottom: 0,
    paddingTop: 3,
    paddingBottom: 3,
  },
  costumerItem: {
    paddingLeft: 25,
  },
  lineItem: {
    paddingLeft: 45,
    cursor: 'auto',
  },
  lastLineItem: {
    marginBottom: 10,
  },
  itemContentWrapper: {
    flex: 1,
  },
  locationTitle: {
    fontFamily: FONT_FAMILY,
    fontSize: FONT_SIZES.textHeader,
    fontWeight: '700',
    marginBottom: 0,
    color: COLORS.blackTintedBlue,
  },
  itemTitle: {
    fontFamily: FONT_FAMILY,
    fontSize: FONT_SIZES.label,
    fontWeight: '700',
    marginBottom: 0,
    color: COLORS.blackTintedBlue,
  },
  itemLiteTitle: {
    fontFamily: FONT_FAMILY,
    fontSize: FONT_SIZES.text,
    fontWeight: '700',
    marginBottom: 0,
    color: COLORS.blackTintedBlue,
  },
  itemSubTitle: {
    fontFamily: FONT_FAMILY,
    fontSize: FONT_SIZES.label,
    fontWeight: '500',
    color: COLORS.grey,
    marginBottom: 5,
  },
  itemContent: {
    fontFamily: FONT_FAMILY,
    fontSize: FONT_SIZES.label,
    fontWeight: '400',
    color: COLORS.blackTintedBlue,
  },
  arrowForward: {
    paddingLeft: 20,
  },
  lineItemWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: BORDER_RADIUS_MAIN,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 5,
    paddingRight: 5,
    marginLeft: -5,
    marginRight: -5,
  },
  lineItemImage: {
    width: 56,
    height: 56,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: BORDER_RADIUS_MAIN,
    marginRight: 10,
  },
  itemImage: {
    position: 'absolute',
    maxHeight: 60,
  },
  lineItemContent: {
    flex: 1,
  },
  lineItemName: {
    fontFamily: FONT_FAMILY,
    fontSize: FONT_SIZES.label,
    fontWeight: '400',
    marginBottom: 0,
    color: COLORS.blackTintedBlue,
  },
  lineItemPrice: {
    ...FONT_CART_VARIANT_SUB,
    color: COLORS.grey,
  },
  lineItemOrdered: {
    fontFamily: FONT_FAMILY,
    fontSize: FONT_SIZES.label,
    fontWeight: '700',
    paddingLeft: 20,
    color: COLORS.blackTintedBlue,
  },
  pageBreak: {
    '@media print': {
      breakAfter: 'always',
      breakBefore: 'always',
      pageBreakAfter: 'always',
      pageBreakBefore: 'always',
    },
  },
}));

const propTypes = {
  unlisted: PropTypes.bool,
};

const LocationsViewComponent = (props) => {
  const { locations, history, day, locationsViewType } = props;
  const intl = useIntl();
  const isLiteView = locationsViewType === LOCATIONS_VIEW_TYPES.LOCATIONS;

  const classes = useStyles();

  const drawSingleItem = (location) => {
    if (isLiteView) {
      return (
        <div
          key={`loc_${location?.id}`}
          className={classNames([classes.singleItem, classes.simpleViewLocation])}
          onClick={() => onLocationClick(location)}>
          {renderLocation(location)}
          <div className={classes.arrowForward}>
            <ArrowIcon />
          </div>
        </div>
      );
    }

    return (
      <React.Fragment key={`loc_${location?.id}`}>
        <div
          className={classNames([classes.singleItem, classes.locationName])}
          onClick={() => onLocationClick(location)}>
          {renderLocation(location)}
        </div>
        {drawOrders(location)}
        <div className={classes.pageBreak} />
      </React.Fragment>
    );
  };

  const drawOrders = (location) => {
    return location.orders.map((order) => (
      <React.Fragment key={`ord_${order.id}`}>
        <div
          className={classNames([classes.singleItem, classes.singleItemTree, classes.costumerItem])}
          onClick={() => onOrderClick(location, order)}>
          <div className={classes.itemContentWrapper}>
            <div className={classes.itemTitle}>{drawCustomerName(order)}</div>
          </div>
        </div>
        {drawLineItems(location, order)}
      </React.Fragment>
    ));
  };

  const drawLineItems = (location, order) => {
    return order.lineItems.map((lineItem, index) => (
      <div
        key={`${order.id}_${lineItem.id}`}
        className={classNames([
          classes.singleItem,
          classes.singleItemTree,
          classes.lineItem,
          order?.lineItems?.length - 1 === index && classes.lastLineItem,
        ])}>
        {drawLineItem(lineItem)}
      </div>
    ));
  };

  const drawCustomerName = (order) => {
    if (order?.customer?.type === CUSTOMER_TYPES.WHOLESALE) return order.customer.companyName;
    if (order?.customer?.type === CUSTOMER_TYPES.ANONYMOUS)
      return intl.formatMessage({ id: 'pickups.sales.anonymous' });

    return `${order.customer.firstName ? order.customer.firstName + ' ' : ''}${
      order.customer.lastName ? order.customer.lastName : ''
    }${order?.customer?.email ? ' / ' + order.customer.email : ''}${
      order?.customer?.phone ? ' / ' + order.customer.phone : ''
    }`;
  };

  const drawLineItem = (lineItem) => {
    return (
      <div className={classes.lineItemWrapper}>
        <div className={classes.lineItemContent}>
          <p className={classes.lineItemName}>{getProductName(lineItem)}</p>
        </div>
        <p className={classes.lineItemOrdered}>{weightToUser(lineItem.orderedQuantity)}</p>
      </div>
    );
  };

  const getProductName = (lineItem) => {
    if (lineItem.name.variant === lineItem.unit)
      return `${lineItem.name.product}${!!lineItem?.unit ? ' / ' + lineItem.unit : ''}`;

    if (typeof lineItem.name === 'string') return lineItem.name;

    return `${lineItem.name.product}${!!lineItem?.name?.variant ? ' / ' + lineItem.name.variant : ''}${
      lineItem?.unit && lineItem?.unit !== lineItem.name.variant ? ' / ' + lineItem.unit : ''
    }`;
  };

  const renderLocation = (location) => {
    return (
      <div className={classes.itemContentWrapper}>
        <div className={classes.locationTitle}>{location.name}</div>
        {!isLiteView && (
          <div className={classes.itemContent}>
            {intl.formatMessage(
              { id: 'pickups.locations.pickupList' },
              { date: moment.parseZone(day).format(ORDER_DATE_FORMAT) }
            )}
          </div>
        )}
      </div>
    );
  };

  const onLocationClick = (location) => history.push(`/app/pickups/day/${day}/location/${location.id}/orders`);
  const onOrderClick = (location, order) =>
    history.push(`/app/pickups/day/${day}/location/${location.id}/orders/${order.id}`);

  return <div className={classes.root}>{!!locations?.length && locations.map(drawSingleItem)}</div>;
};

LocationsViewComponent.propTypes = propTypes;

export default withRouter(LocationsViewComponent);
