import React from 'react';
import classNames from 'classnames';
import DialogContent from '@material-ui/core/DialogContent';
import {withStyles} from '@material-ui/core/styles';

const styles = ({
  content: {
    padding: 0,
    margin: 0,
    overflow: 'visible'
  }
});

const AdminDialogContent = ({ classes,
                              children,
                              className,
                              ...other }) => {
  return (
    <DialogContent className={classNames(classes.content, className)}
                   {...other} >
      {children}
    </DialogContent>
  );
};

export default withStyles(styles)(
  AdminDialogContent
);