import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../../hocs';
import {
  AdminDialog,
  AdminDialogTitle,
  AdminDialogContent,
  AdminDialogControls,
  PrimaryButton,
  Spinner,
  ImageUpload,
} from '../../../components';
import { COLORS } from '../../../helpers';
import { apiProducerPatch } from '../../../api';

const styles = {
  wrapper: {},
  name: {
    fontSize: 18,
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.text,
    marginBottom: 40,
  },
  variants: {
    marginBottom: 50,
  },
  input: {
    marginBottom: 25,
  },
  inputText: {
    textAlign: 'right',
  },
  button: {
    minWidth: '40%',
    textTransform: 'uppercase',
  },
  mr20: {
    marginRight: 10,
  },
  controls: {
    margin: '27px 0 0 0',
    textAlign: 'center',
  },
  control: {
    padding: '0 105px',
  },
  image: {
    display: 'inline-block',
    width: 300,
    maxHeight: 400,
  },
  imageWrapper: {
    textAlign: 'center',
  },
};

const propTypes = {
  producer: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

class ProfileImageDialog extends React.Component {
  state = {
    loading: false,
    file: null,
  };

  validate = () => {
    const { onSave } = this.props;
    this.setState({ loading: true });
    apiProducerPatch(
      { image: this.state.file },
      (producer) => onSave && onSave(producer),
      () => this.setState({ loading: false })
    );
  };

  render() {
    const { intl, classes, producer, onClose } = this.props;

    return (
      <AdminDialog open closeButton onClose={onClose}>
        <AdminDialogTitle title={intl.formatMessage({ id: 'producer.dialog.editingProfileImage' })} />
        <AdminDialogContent className={classes.wrapper}>
          {this.state.loading && <Spinner size={50} />}

          <div className={classes.imageWrapper}>
            <div className={classes.image}>
              <ImageUpload
                object={{ image: producer.image, name: producer.name }}
                base64={this.state.file}
                onUploaded={(file) => this.setState({ file: file })}
              />
            </div>
          </div>

          {this.state.file && (
            <AdminDialogControls>
              <PrimaryButton className={classes.button} onClick={() => this.validate()}>
                {intl.formatMessage({ id: 'global.save' })}
              </PrimaryButton>
            </AdminDialogControls>
          )}
        </AdminDialogContent>
      </AdminDialog>
    );
  }
}

ProfileImageDialog.propTypes = propTypes;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(ProfileImageDialog)));
