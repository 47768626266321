import { COLORS } from '../../helpers';

export default {
  title: {
    textAlign: 'left',
    fontSize: 14,
  },
  priceCell: {
    display: 'flex',
    flexDirection: 'column',
  },
  prepurchasePrice: {
    color: COLORS.text,
  },
  prepurchaseName: {
    color: COLORS.text,
  },
  wrapper: {},
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    borderBottom: '1px solid ' + COLORS.border,
  },
  green: {
    color: COLORS.text,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 5,
    paddingBottom: 5,
    width: '100%',
    borderBottom: '1px solid ' + COLORS.border,
  },
  imageHeaderCell: {
    width: '70px',
    paddingRight: '5px',
  },
  headerCell: {
    flex: 2,
    fontSize: 14,
  },
  center: {
    textAlign: 'center',
  },
  lastCell: {
    paddingRight: 5,
  },
  imageCell: {
    width: '70px',
    paddingRight: '10px',
  },
  cell: {
    flex: 2,
    fontSize: 14,
  },
  approveColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  priceMobile: {
    alignItems: 'flex-end',
  },
  image: {
    height: '40px',
  },
  imageBox: {
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  checkIcon: {
    color: COLORS.text,
  },
  removeIcon: {
    color: COLORS.violet,
  },
  discount: {
    fontSize: 16,
    fontWeight: 400,
    color: COLORS.text,
  },
};
