import React from 'react';
import { COLORS } from '../constants/colors';

const CalcButton1 = (props) => {
  const { color = COLORS.blackTintedBlue, ...other } = props;
  return (
    <svg width="8" height="18" viewBox="0 0 8 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <path
        d="M5.22607 18H7.42334V0.385254H5.23828L0.550781 3.75439V6.07373L5.03076 2.82666H5.22607V18Z"
        fill={color}
      />
    </svg>
  );
};

export default CalcButton1;
