import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import DXFilterConfig from '../DXFilterConfig';
import constants from '../../constants';
import { getStore } from '../../../../../store/configure';
import PrimarySelect from '../../../../PrimarySelect/PrimarySelect';
import MenuItem from '@material-ui/core/MenuItem';

const propTypes = {
  equality: PropTypes.string,
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  selected: [],
};

class DxOrderLocationFilter extends React.Component {
  render() {
    const { selected, onChange, intl, equality } = this.props;
    let config = DxOrderLocationFilter.getConfig(intl, equality);

    return (
      <PrimarySelect
        label={config.title}
        value={selected}
        fullWidth
        onChange={(e) => {
          if (e.target.value !== -1) onChange([e.target.value]);
        }}>
        <MenuItem key={-1} value={-1}>
          {intl.formatMessage({ id: 'global.selectLocation' })}
        </MenuItem>

        {config.values.map((item) => {
          return (
            <MenuItem key={item.key} value={item.key}>
              {item.label}
            </MenuItem>
          );
        })}
      </PrimarySelect>
    );
  }
}

DxOrderLocationFilter.propTypes = propTypes;
DxOrderLocationFilter.defaultProps = defaultProps;
DxOrderLocationFilter.getLabel = (intl, selectedValues) => {
  let label = '';
  let parts = [];
  if (Array.isArray(selectedValues) && selectedValues.length) {
    const config = DxOrderLocationFilter.getConfig(intl, '*');
    const values = config.values;

    selectedValues.forEach((value) => {
      const translation = values.filter((item) => item.key === value);
      if (translation.length && parts.length < 2) {
        parts.push(translation[0].label);
      }
    });

    label = parts.join(', ');
  }
  return label;
};
DxOrderLocationFilter.applyToQueryParams = (queryParams, selectedValues) => {
  queryParams['locationId'] = selectedValues[0];
  return queryParams;
};

DxOrderLocationFilter.getConfig = (intl, equality) => {
  const store = getStore();
  const locations = store.getState()?.producer?.object?.locations
    ? store.getState().producer.object.locations.sort((prev, next) => (prev.name < next.name ? 1 : -1))
    : [];

  let config = new DXFilterConfig({
    name: 'location',
    selected: -1,
    property: 'locationId',
    title: intl.formatMessage({ id: 'filter.orderLocation' }),
    disabled: false,
    equalityStatus: false,
    multi: false,
  });

  if (equality === '*' || equality === constants.FILTER_EQUALITY_EQUALS) {
    locations && locations.sort().map((item) => config.addFilterValue(item.id, item.name));
  }

  return config;
};

export default injectIntl(DxOrderLocationFilter);
