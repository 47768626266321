import React from "react";
import {
  injectIntl,
  FormattedMessage
} from 'react-intl';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';

const HeaderDesktop = (props) => {
  const {
    classes,
  } = props;

  return (
    <div className={classes.headerRow}>
      <span className={classes.imageHeaderCell}>&nbsp;</span>
      <span className={classes.headerCell}>
        <FormattedMessage id="global.item"/>
      </span>
      <span className={classes.headerCell}>
        <FormattedMessage id="global.qty"/>
      </span>
      <span className={classes.headerCell}>
        <FormattedMessage id="global.unitPrice"/>
      </span>
      <span className={classNames(classes.headerCell, classes.center)}>
        <FormattedMessage id="global.total"/>
      </span>
      <span className={classNames(classes.headerCell, classes.lastCell, classes.center)}>
        <FormattedMessage id="global.approved"/>
      </span>
    </div>
  )
};

export default withStyles(styles)(
  injectIntl(HeaderDesktop)
);