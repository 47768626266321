import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { withStyles } from '@material-ui/core/styles';
import { PrimaryTextField } from '../../../components';

const styles = {};

const propTypes = {
  value: PropTypes.any,
  inputProps: PropTypes.object,
  cellProps: PropTypes.object.isRequired,
  formatValueBeforeApplyEditing: PropTypes.func.isRequired,
  onFinishCellEditing: PropTypes.func.isRequired,
};

const defaultProps = {
  formatValueBeforeApplyEditing: (value) => value,
};

class CellAsTextInput extends React.Component {
  state = {
    cache: this.props.value,
    value: this.props.value,
    editingMode: false,
  };

  onCellDoubleClick = (event) => {
    event && event.preventDefault();
    this.setState({ editingMode: true });
  };

  onFinishCellEditing = (onStateChanged) => {
    const { formatValueBeforeApplyEditing, onFinishCellEditing } = this.props;

    let formatted = formatValueBeforeApplyEditing(this.state.value);

    this.setState(
      {
        cache: formatted,
        value: formatted,
        editingMode: false,
      },
      () => {
        onStateChanged && onStateChanged();
        onFinishCellEditing(formatted);
      }
    );
  };

  onKeyDown = (event) => {
    const { key } = event;

    if (key === 'Enter') {
      this.onFinishCellEditing();
      event.preventDefault();
    }

    if (key === 'Escape') {
      this.setState({
        editingMode: false,
        value: this.state.cache,
      });
      event.preventDefault();
    }

    /*if (key === 'Tab') {
      const {
        getNexRef
      } = this.props;
      const next = getNexRef();
      next && next.ref.onCellDoubleClick();
      event.preventDefault();
    }*/
  };

  onChange = (value) => {
    const { inputProps } = this.props;

    if (inputProps && inputProps.InputProps && inputProps.InputProps.min !== undefined) {
      this.setState({ value: value >= inputProps.InputProps.min ? value : 0 });
    } else {
      this.setState({ value: value });
    }
  };

  render() {
    const { cellProps, inputProps, view } = this.props;

    return (
      <Table.Cell
        onDoubleClick={this.onCellDoubleClick}
        {...cellProps}
        style={{
          cursor: 'cell',
        }}>
        {!this.state.editingMode && (view ? view : this.state.value)}
        {this.state.editingMode && (
          <PrimaryTextField
            {...inputProps}
            onKeyDown={this.onKeyDown}
            onChange={(event) => this.onChange(event.target.value)}
            value={this.state.value}
            autoFocus
            margin="dense"
            fullWidth
          />
        )}
      </Table.Cell>
    );
  }
}

CellAsTextInput.propTypes = propTypes;
CellAsTextInput.defaultProps = defaultProps;

export default withStyles(styles)(injectIntl(CellAsTextInput));
