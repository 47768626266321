import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { Icon } from '../../components';
import styles from './styles';

class QuantitySwitcher extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      quantity: props.quantity,
    };

    this.timeout = null;
    this.hideTimeout = null;
  }

  UNSAFE_componentWillMount() {
    this.resetHideTimeout();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.changeQuantityByProp && !this.timeout && nextProps.quantity !== this.state.quantity) {
      this.setState({ quantity: nextProps.quantity });
    }
  }

  resetHideTimeout = () => {
    if (this.props.hideDelay) {
      clearTimeout(this.hideTimeout);
      this.hideTimeout = setTimeout(() => {
        this.props.onClose && this.props.onClose();
        this.hideTimeout = null;
      }, this.props.hideDelay);
    }
  };

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    if (this.hideTimeout) {
      clearTimeout(this.hideTimeout);
    }
  }

  _onPlusOne = (event) => {
    event.stopPropagation();
    const { quantity } = this.state,
      newQuantity = quantity + 1;
    this.setState({ quantity: newQuantity });
    this._callback(newQuantity);
    this.resetHideTimeout();
  };

  _onMinusOne = (event) => {
    event.stopPropagation();
    const { quantity } = this.state,
      newQuantity = quantity > 1 ? quantity - 1 : 1;
    this.setState({ quantity: newQuantity });
    this._callback(newQuantity);
    this.resetHideTimeout();
  };

  _onInputChange = (event) => {
    event.stopPropagation();
    const value = event.target.value,
      parsed = parseInt(value, 10),
      newQuantity = parsed > 0 ? parsed : '';
    this.setState({ quantity: newQuantity });
    this._callback(newQuantity);
    this.resetHideTimeout();
  };

  _callback = (newQuantity) => {
    const { onChange, onStartChange } = this.props;
    onStartChange && onStartChange();
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      onChange && onChange(newQuantity);
      this.timeout = null;
    }, this.props.changeDelay);
  };

  render() {
    const { classes, className, readOnly } = this.props;
    return (
      <div onClick={(e) => e.stopPropagation()} className={`${classes.root} ${className}`}>
        {!readOnly && (
          <Button
            // mini
            onClick={this._onMinusOne}
            color="primary"
            aria-label="remove"
            className={classes.control}>
            <Icon icon={RemoveIcon} />
          </Button>
        )}

        <TextField
          value={this.state.quantity}
          disabled={readOnly}
          onClick={(e) => e.stopPropagation()}
          onChange={(event) => !readOnly && this._onInputChange(event)}
          InputProps={{
            disableUnderline: true,
            classes: {
              root: classes.inputRoot,
              input: classes.input,
            },
          }}
        />

        {!readOnly && (
          <Button
            //mini
            onClick={this._onPlusOne}
            color="primary"
            aria-label="add"
            className={classes.control}>
            <Icon icon={AddIcon} />
          </Button>
        )}
      </div>
    );
  }
}

QuantitySwitcher.propTypes = {
  onClose: PropTypes.func,
  changeQuantityByProp: PropTypes.bool,
  changeDelay: PropTypes.number,
  hideDelay: PropTypes.number,
  className: PropTypes.string,
  quantity: PropTypes.number,
  onStartChange: PropTypes.func,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

QuantitySwitcher.defaultProps = {
  changeDelay: 1000,
  className: '',
};

export default withStyles(styles, {
  withTheme: true,
})(QuantitySwitcher);
