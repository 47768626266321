import React from 'react';
import PropTypes from 'prop-types';
import { Link, Typography } from '@material-ui/core';
import { FONT_TEXT_INPUT_MULTILINE } from '../../constants/fonts';
import { COLORS } from '../../constants/colors';
import { makeStyles } from '@material-ui/core/styles';
import foodHubLogo from '../../assets/foodHub.png';
import ArrowIcon from '../../assets/arrow';
import { BORDER_RADIUS_BUTTON } from '../../constants/globals';

const propTypes = {
  text: PropTypes.string,
  textComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: COLORS.lightBg,
    padding: 20,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    borderRadius: BORDER_RADIUS_BUTTON,
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },

  iconWrapper: {
    marginRight: 10,
    overflow: 'hidden',
    height: 40,
    width: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  logoImg: {
    width: 'auto',
    height: 40,
  },
  contentWrapper: {
    flex: 1,
  },
  text: {
    ...FONT_TEXT_INPUT_MULTILINE,
  },
  textBold: {
    ...FONT_TEXT_INPUT_MULTILINE,
    fontWeight: 700,
  },
  chevronWrapper: {
    marginLeft: 20,
  },
}));

const SyncedDeliveryBox = (props) => {
  const { text, textComponent, to = '#', onClick = () => {}, image, ...other } = props;

  const classes = useStyles();
  const TextComponent = textComponent;

  return (
    <Link href={to} onClick={onClick} className={classes.root}>
      <div className={classes.iconWrapper}>
        <img src={image || foodHubLogo} alt="HoodHub" className={classes.logoImg} />
      </div>
      <div className={classes.contentWrapper}>
        {!!textComponent ? (
          <TextComponent textClass={classes.text} boldTextClass={classes.textBold} />
        ) : (
          <Typography variant={'inherit'} component={'p'} className={classes.text} {...other}>
            {text || ''}
          </Typography>
        )}
      </div>
      <div className={classes.chevronWrapper}>
        <ArrowIcon />
      </div>
    </Link>
  );
};

SyncedDeliveryBox.propTypes = propTypes;

export default SyncedDeliveryBox;
