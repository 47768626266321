import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Chip from '@material-ui/core/Chip';
import DXFilterConfig from '../DXFilterConfig';
import constants from '../../constants';
import { PrimaryChip } from '../../../../index';

const styles = {
  wrapper: {},
  chips: {
    display: 'block',
    width: '100%',
    margin: '20px 0 0 0',
    textAlign: 'left',
  },
  chip: {
    margin: 5,
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  mr20: {
    marginRight: 20,
  },
};

const propTypes = {
  shared: PropTypes.object,
  selected: PropTypes.object,
  equality: PropTypes.string,
  producer: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
const defaultProps = {
  selected: {},
};

class DXTagsSubscriptionFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: '',
      tags: this?.props?.selected || [],
    };
  }
  handleValueAdd = (tag) => {
    const tags = [...this.state.tags];

    if (!tags.includes(tag)) {
      tags.push(tag);
    }

    this.setState({
      tags,
    });
    this.props.onChange(tags);
  };

  handleValueDelete = (index) => {
    let tags = this.state.tags;
    tags.splice(index, 1);

    this.props.onChange(tags);
  };

  getValue = (id) => {
    return this.props.selected[id];
  };

  getValuesView = () => {
    const { classes, selected } = this.props;
    const keys = Object.keys(selected || {});

    return (
      <React.Fragment>
        {!!keys.length && (
          <div className={classes.chips}>
            {keys.map((id) => (
              <Chip
                key={id}
                label={this.getValue(id)}
                className={classes.chip}
                onDelete={() => this.handleValueDelete(id)}
              />
            ))}
          </div>
        )}
      </React.Fragment>
    );
  };

  drawTagsSelector = () => {
    const { intl, classes } = this.props;

    return (
      <PrimaryChip
        className={classes.textField}
        fullWidth
        value={[]}
        blurBehavior={'add'}
        onUpdateInput={(e) => {
          this.setState({
            inputValue: e.target.value,
          });
        }}
        onAdd={(chip) => {
          this.handleValueAdd(chip);
        }}
        onDelete={() => {
          this.handleValueAdd([]);
        }}
        label={intl.formatMessage({ id: 'filter.subscriptionTags' })}
        InputLabelProps={{ shrink: true }}
      />
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div className={classes.row}>
          <div className={classes.column}>
            <div className={classes.wrapper}>
              <div className={classes.mr20}>{this.drawTagsSelector()}</div>
            </div>
          </div>
          <div className={classes.column} />
        </div>
        {this.getValuesView()}
      </React.Fragment>
    );
  }
}

DXTagsSubscriptionFilter.propTypes = propTypes;
DXTagsSubscriptionFilter.defaultProps = defaultProps;
DXTagsSubscriptionFilter.getLabel = (intl, selectedValues) => {
  return selectedValues.join(', ');
};
DXTagsSubscriptionFilter.applyToQueryParams = (queryParams, selectedValues) => {
  queryParams['tags'] = selectedValues;

  return queryParams;
};
DXTagsSubscriptionFilter.getConfig = (intl, equality) => {
  return new DXFilterConfig({
    name: 'subscriptionTags',
    selected: [],
    property: 'tags',
    title:
      equality === constants.FILTER_EQUALITY_NOT_EQUALS
        ? intl.formatMessage({ id: 'filter.notEqualsSubscriptionTags' })
        : intl.formatMessage({ id: 'filter.subscriptionTags' }),
    disabled: false,
    equalityStatus: false,
    selectedEquality: equality,
    multi: true,
  });
};

export default injectIntl(withStyles(styles)(DXTagsSubscriptionFilter));
