import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { AdminDialog, AdminDialogTitle, AdminDialogContent, PrimaryButton } from '../../components';
import {
  DXFiltersManager,
  // Filters
  DXActivityFilter,
  DXHathImageFilter,
  DXNameSearchFilter,
  DXProductTypesFilter,
  DXProductSubTypesFilter,
  DXSuppliersFilter,
} from '../../components/AdminDXTable/Filters';
import styles from './styles';

const propTypes = {
  openWithFilter: PropTypes.object,
  appliedFilters: PropTypes.array,
  onApplyFilters: PropTypes.func,
  onClose: PropTypes.func,
  disableActivityFilter: PropTypes.bool,
  subTypes: PropTypes.array,
  types: PropTypes.array,
};

const defaultProps = {
  appliedFilters: [],
};

class ProductsFilterDialog extends React.Component {
  constructor(props) {
    super(props);

    this.filters = [
      DXNameSearchFilter,
      DXProductSubTypesFilter,
      DXProductTypesFilter,
      ...(!props.disableActivityFilter ? [DXActivityFilter] : []),
      DXHathImageFilter,
      DXSuppliersFilter,
    ];
  }

  render() {
    const { intl, classes, onClose, onApplyFilters, openWithFilter, appliedFilters, subTypes, types } = this.props;

    return (
      <AdminDialog open closeButton onClose={onClose}>
        <AdminDialogTitle title={intl.formatMessage({ id: 'global.addFilter' })} />
        <AdminDialogContent className={classes.wrapper}>
          <DXFiltersManager
            filters={this.filters}
            onRef={(filters) => (this.filtersManager = filters)}
            openWithFilter={openWithFilter}
            appliedFilters={appliedFilters}
            defaultFilterName={'name'}
            queryParamsByFilterName={{
              subTypes,
              types,
            }}
          />

          <div className={classes.controls}>
            <PrimaryButton onClick={() => onApplyFilters(this.filtersManager.getAppliedFilters())}>
              {intl.formatMessage({ id: 'global.apply' })}
            </PrimaryButton>
          </div>
        </AdminDialogContent>
      </AdminDialog>
    );
  }
}

ProductsFilterDialog.propTypes = propTypes;
ProductsFilterDialog.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(injectIntl(ProductsFilterDialog));
