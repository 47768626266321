import React from "react";
import styles from './styles';

const Icon = (props) => {
  const {icon, style, ...other} = props;
  const IconComponent = icon;

  const inlineStyles = style ?
    Object.assign({}, styles.icon, style) :
    styles.icon;

  return (
    <IconComponent {...other} style={inlineStyles}/>
  )
};

export default Icon;