import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  CardElement,
  injectStripe
} from 'react-stripe-elements';
import {withStyles} from '@material-ui/core/styles';
import {
  PrimaryButton,
  ErrorBox,
} from '../../components';
import styles from './styles';

const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize,
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
        ...(padding ? {padding} : {}),
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
};

class CardForm extends React.Component<InjectedProps & {fontSize: string}> {

  state = {
    error: "",
  };

  handleSubmit = (ev, context) => {
    ev.preventDefault();
    const {stripe} = context.props;
    if (stripe) {
      stripe
        .createToken()
        .then((payload) => {
          if (payload.hasOwnProperty('error')) {
            this.setState({error: payload.error.message})
          } else {
            context.props.onValidated(payload);
          }
        });
    } else {
      console.error("Stripe.js hasn't loaded yet.");
    }
  };

  render() {
    const {
      classes,
      loading,
    } = this.props;

    return (
      <form onSubmit={(event) => this.handleSubmit(event, this)}>
        {this.state.error && (
          <ErrorBox error={this.state.error} />
        )}
        <div className={classes.show}>
          <label>
            <FormattedMessage id="payment.cardDetails"/>
            <CardElement {...createOptions(this.props.fontSize)} />
          </label>
          <div className={classes.actions}>
            <PrimaryButton disabled={loading} type={"submit"}>
              <FormattedMessage id="payment.addCard" />
            </PrimaryButton>
          </div>
        </div>
      </form>
    );
  }
}

CardForm.propTypes = {
  loading: PropTypes.bool,
  onValidated: PropTypes.func.isRequired,
};

export default withStyles(
  styles, {withTheme: true}
)(injectStripe(CardForm));
