import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../../hocs';
import Typography from '@material-ui/core/Typography';
import {
  AdminDialogContent,
  AdminDialogControls,
  PrimaryButton,
  Spinner,
  TextAreaWithCopyButton,
} from '../../../components';
import { apiCustomerResentInvite } from '../../../api';
import styles from './styles';

const propTypes = {
  customer: PropTypes.object.isRequired,
  onDone: PropTypes.func.isRequired,
};

class InvitationStage extends React.Component {
  state = {
    url: false,
    email: false,
    loading: false,
  };

  _sendInviteViaEmail = () => {
    const { customer } = this.props;
    this.setState({ loading: true });
    apiCustomerResentInvite(customer.id, false, () => {
      this.setState({
        email: true,
        loading: false,
      });
    });
  };

  _copyInviteUrl = () => {
    const { customer } = this.props;
    this.setState({ loading: true });
    apiCustomerResentInvite(customer.id, true, (data) => {
      this.setState({
        url: data.url,
        loading: false,
      });
    });
  };

  render() {
    const { intl, classes } = this.props;

    const { loading } = this.state;

    return (
      <AdminDialogContent className={classes.wrapper}>
        {loading && <Spinner size={50} />}

        {!this.state.loading && (
          <React.Fragment>
            {!this.state.url && !this.state.email && (
              <Typography gutterBottom className={classes.text}>
                {intl.formatMessage({ id: 'messages.customerCreated' })}
              </Typography>
            )}

            {this.state.url && (
              <TextAreaWithCopyButton
                style={{ marginBottom: 20 }}
                value={this.state.url}
                id="url-input"
                label={intl.formatMessage({ id: 'global.url' })}
                type="text"
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            )}

            {this.state.email && (
              <Typography gutterBottom className={classes.text}>
                {intl.formatMessage({ id: 'messages.emailSent' })}
              </Typography>
            )}

            {!this.state.url && !this.state.email && (
              <div className={classes.controls}>
                <PrimaryButton style={{ marginRight: 10 }} onClick={this._copyInviteUrl}>
                  {intl.formatMessage({ id: 'customer.copyInviteUrl' })}
                </PrimaryButton>
                <PrimaryButton className={classes.button} onClick={this._sendInviteViaEmail}>
                  {intl.formatMessage({ id: 'customer.sendInviteViaEmail' })}
                </PrimaryButton>
              </div>
            )}

            {(this.state.url || this.state.email) && (
              <AdminDialogControls>
                <PrimaryButton className={classes.button} onClick={this.props.onDone}>
                  {intl.formatMessage({ id: 'global.done' })}
                </PrimaryButton>
              </AdminDialogControls>
            )}
          </React.Fragment>
        )}
      </AdminDialogContent>
    );
  }
}

InvitationStage.propTypes = propTypes;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(InvitationStage)));
