import React from 'react';
import {
  MuiThemeProvider,
  createTheme
} from '@material-ui/core/styles';
import Component from './Component';

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: '#363636',
        '&$focused': {
          color: '#363636',
        },
      },
      /*"&$focused": {
        "&$focused": {
          color: "#41CD8C"
        }
      }*/
    },
  },
});

class PrimaryChip extends React.Component {
  render = () => (
    <MuiThemeProvider theme={theme}>
      <Component {...this.props}>{this.props.children}</Component>
    </MuiThemeProvider>
  );
}

export default PrimaryChip;
