const constants = {
  FILTER_EQUALITY_EQUALS: 'equals',
  FILTER_EQUALITY_NOT_EQUALS: 'not_equals',

  FILTER_EQUALITY_LESS: 'less',
  FILTER_EQUALITY_MORE: 'more',

  FILTER_EQUALITY_EXCLUDE: 'exclude',
};
export default constants;
