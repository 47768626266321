import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import DXFilterConfig from '../DXFilterConfig';
import constants from '../../constants';
import PrimarySelect from '../../../../PrimarySelect/PrimarySelect';
import MenuItem from '@material-ui/core/MenuItem';
import reportsConfig from '../../../../../scenes/ReportsScene/config';

const propTypes = {
  equality: PropTypes.string,
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  selected: [],
};

class DXReportFilter extends React.Component {
  render() {
    const { selected, onChange, intl, equality } = this.props;

    let config = DXReportFilter.getConfig(intl, equality);

    return (
      <PrimarySelect
        label={config.title}
        value={selected}
        fullWidth
        onChange={(e) => {
          if (e.target.value !== -1) onChange([e.target.value]);
        }}>
        <MenuItem key={-1} value={-1}>
          {intl.formatMessage({ id: 'filter.report.selectReport' })}
        </MenuItem>

        {config.values.map((item) => {
          return (
            <MenuItem key={item.key} value={item.key}>
              {item.label}
            </MenuItem>
          );
        })}
      </PrimarySelect>
    );
  }
}

DXReportFilter.propTypes = propTypes;
DXReportFilter.defaultProps = defaultProps;
DXReportFilter.getLabel = (intl, selectedValues) => {
  let label = '';
  let parts = [];
  if (Array.isArray(selectedValues) && selectedValues.length) {
    const config = DXReportFilter.getConfig(intl, '*');
    const values = config.values;

    selectedValues.forEach((value) => {
      const translation = values.filter((item) => item.key === value);
      if (translation.length && parts.length < 2) {
        parts.push(translation[0].label);
      }
    });

    label = parts.join(', ');
  }
  return label;
};

DXReportFilter.applyToQueryParams = (queryParams, selectedValues) => {
  queryParams['reportSelection'] = selectedValues[0];
  return queryParams;
};

DXReportFilter.getConfig = (intl, equality) => {
  const reports = Object.entries(reportsConfig(intl));

  let config = new DXFilterConfig({
    name: 'reportSelection',
    selected: -1,
    property: 'reportSelection',
    title: intl.formatMessage({ id: 'filter.report.reportType' }),
    disabled: false,
    equalityStatus: false,
    multi: false,
    readOnly: true,
  });

  if (equality === '*' || equality === constants.FILTER_EQUALITY_EQUALS) {
    reports.forEach((report) => config.addFilterValue(report[0], report[1].name));
  }

  return config;
};

export default injectIntl(DXReportFilter);
