import axios from 'axios';
import {
  getRequestConfig,
  REQUEST_TYPES,
  replaceUrlPlaceholder,
} from '../request'
import API_END_POINTS from  '../endpoints'

/**
 * Get all accounts
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerAccountsGetList = function (onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.PRODUCER_ACCOUNTS_GET_ALL);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Get one accounts
 * @param {string} id - account id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerAccountsGetOne = function (id, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRODUCER_ACCOUNTS_GET_ONE, 'id', id);
  const config = getRequestConfig(REQUEST_TYPES.GET, url);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Create account
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerAccountsCreate = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.PRODUCER_ACCOUNTS_CREATE);
  config.data = data;
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Patch account
 * @param {string} id - account id
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerAccountsPatch = function (id, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRODUCER_ACCOUNTS_PATCH, 'id', id);
  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  config.data = data;
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Delete account
 * @param {string} id - account id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerAccountsDelete = function (id, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRODUCER_ACCOUNTS_DELETE, 'id', id);
  const config = getRequestConfig(REQUEST_TYPES.DEL, url);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

export const apiGetProducerByToken = function(token, onSuccess, onError) {
  let config = {
    method: REQUEST_TYPES.GET,
    url: process.env.REACT_APP_API_URL + API_END_POINTS.PRODUCER_PATCH_PROFILE,
    headers: {
      Authorization: `Bearer ${token}`
    }
  };

  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
}


/**
 * Validate account phone name
 * In case if phone number valid - return empty 200 OK response.
 * In case in phone number NOT valid - return 412 error response.
 * @param {string} phone - phone number
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerAccountsPhoneValidate = function (phone, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRODUCER_ACCOUNTS_VALIDATE_PHONE, 'ph', phone);
  const config = getRequestConfig(REQUEST_TYPES.get, url);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

export const apiProducerChangeTokenExpirationTime = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.PATCH, API_END_POINTS.PRODUCER_PATCH_PROFILE);
  config.data = data;
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};