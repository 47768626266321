import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import { ErrorBox, Spinner, PrimaryButton, PrimaryTextField, PrimaryCheckbox } from '../../components';
import { validateField } from '../../helpers';
import validationRules from './validation';
import {
  apiProducerAccountsPatch,
  apiProducerAccountsCreate,
  apiProducerAccountsGetOne,
  apiProducerAccountsPhoneValidate,
} from '../../api';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = {
  actionsWrap: {
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
    paddingTop: 40,
    paddingBottom: 20,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: 600,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
};

const propTypes = {
  onDone: PropTypes.func.isRequired,
};

class ProducerAccountForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      username: '',
      password: '',
      confirmPassword: '',
      email: '',
      phone: '',
      firstName: '',
      lastName: '',

      // Admin access
      adminOrders: true,
      adminProducts: true,
      adminCustomers: true,
      adminReports: true,
      adminDiscounts: true,
      adminAccount: true,
      adminHome: true,
      adminSuppliers: true,

      // POS access
      posHome: true,
      posOrdersDrafts: true,
      posProducts: true,
      posDeliveries: true,
      posPickups: true,
      posHarvesting: true,
      posMarketPacking: true,
      posOrderPacking: true,
      posInventories: true,
      posFields: true,
      productPacking: true,
      posContainers: true,

      usernameError: '',
      passwordError: '',
      confirmPasswordError: '',
      phoneError: '',

      requestError: '',
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      intl,
    } = this.props;
    if (id !== 'new') {
      this.setState({ loading: true });
      apiProducerAccountsGetOne(
        id,
        (account) => {
          this.setState({
            username: account.username || '',
            email: account.email || '',
            phone: account.phone || '',
            firstName: account.firstName || '',
            lastName: account.lastName || '',

            // Admin access
            adminOrders: account.acl.admin.orders,
            adminProducts: account.acl.admin.products,
            adminCustomers: account.acl.admin.customers,
            adminReports: account.acl.admin.reports,
            adminDiscounts: account.acl.admin.discounts,
            adminAccount: account.acl.admin.account,
            adminHome: account.acl.admin.home,
            adminSuppliers: account.acl.admin.suppliers,

            // POS access
            posHome: account.acl.pos.home,
            posOrdersDrafts: account.acl.pos.orders_drafts,
            posProducts: account.acl.pos.products,
            posDeliveries: account.acl.pos.deliveries,
            posPickups: account.acl.pos.pickups,
            posHarvesting: account.acl.pos.harvesting,
            posMarketPacking: account.acl.pos.market_packing,
            posOrderPacking: account.acl.pos.order_packing,
            posInventories: account.acl.pos.inventories,
            posFields: account.acl.pos.fields,
            productPacking: account.acl.pos.product_packing,
            posContainers: account.acl.pos.containers,

            loading: false,
          });
        },
        () => {
          this.setState({
            loading: false,
            requestError: intl.formatMessage({ id: 'messages.somethingWentWrong' }),
          });
        }
      );
    }
  }

  _validate = async () => {
    if (this.state.loading) return false;

    this.setState({ loading: true });

    const {
      match: {
        params: { id },
      },
    } = this.props;

    const usernameError = validateField(validationRules, 'username', this.state.username);
    let passwordError = '',
      confirmPasswordError = '';

    if (id === 'new') {
      passwordError = validateField(validationRules, 'password', this.state.password);
      confirmPasswordError = validateField(validationRules, 'confirmPassword', this.state.confirmPassword, {
        password: this.state.password,
      });
    }
    const emailError = validateField(validationRules, 'email', this.state.email);
    const phoneError = await this.validatePhoneOnBackEnd();

    let valid = true;
    if (!usernameError && !passwordError && !confirmPasswordError && !emailError && !phoneError) {
      this.setState({
        usernameError: '',
        passwordError: '',
        confirmPasswordError: '',
        emailError: '',
        phoneError: '',
        requestError: '',
        loading: true,
      });
    } else {
      this.setState({
        usernameError: usernameError,
        passwordError: passwordError,
        confirmPasswordError: confirmPasswordError,
        emailError: emailError,
        phoneError: phoneError,
        requestError: '',
        loading: false,
      });
      valid = false;
    }

    return valid;
  };

  getCheckbox = (key) => {
    const { intl } = this.props;
    return (
      <FormControlLabel
        control={
          <PrimaryCheckbox
            checked={this.state[key]}
            onChange={(event) => this.setState({ [key]: event.target.checked })}
            color="default"
          />
        }
        label={intl.formatMessage({ id: `access.${key}` })}
      />
    );
  };

  validateField(fieldName) {
    const errorFieldName = fieldName + 'Error';
    let state = {};
    state[errorFieldName] = validateField(validationRules, fieldName, this.state[fieldName]);
    this.setState(state);
  }

  validatePhoneOnBackEnd = async () => {
    return new Promise((resolve) => {
      const { phone } = this.state;
      const phoneError = validateField(validationRules, 'phone', phone);

      if (!phoneError && !!phone) {
        apiProducerAccountsPhoneValidate(
          phone,
          () => {
            this.setState({ phoneError: '' });
            resolve();
          },
          (error) => {
            this.setState({ phoneError: error.response.data.message, loading: false });
          }
        );
      } else {
        this.setState({ phoneError: phoneError });
        resolve(phoneError);
      }
    });
  };

  _handleSubmit = async (e) => {
    e.preventDefault();
    const valid = await this._validate();

    if (!valid) return;

    const { username, password, email, phone, firstName, lastName } = this.state;

    let data = {
      username: username,
      email: email ? email : null,
      phone: phone ? phone : null,
      firstName: firstName ? firstName : null,
      lastName: lastName ? lastName : null,
    };

    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.setState({ loading: true });
    if (id === 'new') {
      data['password'] = password;

      apiProducerAccountsCreate(
        data,
        (response) => {
          this.setState({ loading: false });
          this.props.onDone(response);
        },
        (error) => {
          this.setState({
            loading: false,
            requestError: error.response.data && error.response.data.message,
          });
        }
      );
    } else {
      data = {
        ...data,
        ...{
          acl: {
            admin: {
              orders: this.state.adminOrders,
              products: this.state.adminProducts,
              customers: this.state.adminCustomers,
              reports: this.state.adminReports,
              discounts: this.state.adminDiscounts,
              account: this.state.adminAccount,
              home: this.state.adminHome,
              suppliers: this.state.adminSuppliers,
            },

            pos: {
              home: this.state.posHome,
              orders_drafts: this.state.posOrdersDrafts,
              products: this.state.posProducts,
              deliveries: this.state.posDeliveries,
              pickups: this.state.posPickups,
              harvesting: this.state.posHarvesting,
              market_packing: this.state.posMarketPacking,
              order_packing: this.state.posOrderPacking,
              inventories: this.state.posInventories,
              fields: this.state.posFields,
              product_packing: this.state.productPacking,
              containers: this.state.posContainers,
            },
          },
        },
      };

      apiProducerAccountsPatch(
        id,
        data,
        (response) => {
          this.setState({ loading: false });
          this.props.onDone(response);
        },
        (error) => {
          this.setState({
            loading: false,
            requestError: error.response.data && error.response.data.message,
          });
        }
      );
    }
  };

  render() {
    const {
      intl,
      classes,
      match: {
        params: { id },
      },
    } = this.props;
    const { loading } = this.state;

    return (
      <form onSubmit={this._handleSubmit} className={classes.form}>
        {loading && <Spinner size={50} />}

        <ErrorBox error={this.state.requestError} />
        <DialogContent>
          <PrimaryTextField
            error={!!this.state.usernameError}
            onBlur={() => this.validateField('username')}
            onChange={(event) => this.setState({ username: event.target.value.trim() })}
            value={this.state.username}
            autoFocus
            margin="dense"
            id="username"
            autoComplete="off"
            label={intl.formatMessage({ id: 'global.username' }) + ' *'}
            helperText={this.state.usernameError}
            type="text"
            fullWidth
          />

          {id === 'new' && (
            <React.Fragment>
              <PrimaryTextField
                error={!!this.state.passwordError}
                onChange={(event) => this.setState({ password: event.target.value.trim() })}
                value={this.state.password}
                id="password-input"
                margin="dense"
                label={intl.formatMessage({ id: 'global.password' }) + ' *'}
                type="password"
                helperText={this.state.passwordError}
                fullWidth
              />

              <PrimaryTextField
                error={!!this.state.confirmPasswordError}
                onChange={(event) => this.setState({ confirmPassword: event.target.value.trim() })}
                value={this.state.confirmPassword}
                id="password-repeat-input"
                margin="dense"
                label={intl.formatMessage({ id: 'global.confirmPassword' }) + ' *'}
                type="password"
                helperText={this.state.confirmPasswordError}
                fullWidth
              />
            </React.Fragment>
          )}

          <PrimaryTextField
            onChange={(event) => this.setState({ firstName: event.target.value.trim() })}
            value={this.state.firstName}
            margin="dense"
            id="firstName"
            autoComplete="off"
            label={intl.formatMessage({ id: 'global.firstName' })}
            type="text"
            fullWidth
          />

          <PrimaryTextField
            onChange={(event) => this.setState({ lastName: event.target.value.trim() })}
            value={this.state.lastName}
            margin="dense"
            id="lastName"
            autoComplete="off"
            label={intl.formatMessage({ id: 'global.lastName' })}
            type="text"
            fullWidth
          />
          <PrimaryTextField
            error={!!this.state.emailError}
            onBlur={() => this.validateField('email')}
            onChange={(event) => this.setState({ email: event.target.value.trim() })}
            value={this.state.email}
            margin="dense"
            id="email"
            autoComplete="off"
            label={intl.formatMessage({ id: 'global.email' })}
            helperText={this.state.emailError}
            type="text"
            fullWidth
          />
          <PrimaryTextField
            error={!!this.state.phoneError}
            onBlur={() => this.validatePhoneOnBackEnd()}
            onChange={(event) => this.setState({ phone: event.target.value.trim() })}
            value={this.state.phone}
            margin="dense"
            id="phone"
            autoComplete="off"
            label={intl.formatMessage({ id: 'global.phone' })}
            helperText={this.state.phoneError}
            type="text"
            fullWidth
          />

          {id !== 'new' && (
            <div className={classes.wrapper}>
              <FormControl style={{ marginTop: 30 }} component="fieldset">
                <FormLabel component="legend">{intl.formatMessage({ id: 'access.admin' })}</FormLabel>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <FormGroup>
                    {this.getCheckbox('adminHome')}
                    {this.getCheckbox('adminOrders')}
                    {this.getCheckbox('adminProducts')}
                  </FormGroup>
                  <FormGroup>
                    {this.getCheckbox('adminCustomers')}
                    {this.getCheckbox('adminReports')}
                    {this.getCheckbox('adminSuppliers')}
                  </FormGroup>
                  <FormGroup>
                    {this.getCheckbox('adminDiscounts')}
                    {this.getCheckbox('adminAccount')}
                  </FormGroup>
                </div>
              </FormControl>

              <FormControl style={{ marginTop: 30 }} component="fieldset">
                <FormLabel component="legend">{intl.formatMessage({ id: 'access.pos' })}</FormLabel>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <FormGroup>
                    {this.getCheckbox('posHome')}
                    {this.getCheckbox('posOrdersDrafts')}
                    {this.getCheckbox('posProducts')}
                    {this.getCheckbox('posDeliveries')}
                  </FormGroup>
                  <FormGroup>
                    {this.getCheckbox('posPickups')}
                    {this.getCheckbox('posHarvesting')}
                    {this.getCheckbox('posMarketPacking')}
                    {this.getCheckbox('productPacking')}
                  </FormGroup>
                  <FormGroup>
                    {this.getCheckbox('posOrderPacking')}
                    {this.getCheckbox('posInventories')}
                    {this.getCheckbox('posFields')}
                    {this.getCheckbox('posContainers')}
                  </FormGroup>
                </div>
              </FormControl>
            </div>
          )}
        </DialogContent>

        <div className={classes.actionsWrap}>
          <PrimaryButton type="submit">{intl.formatMessage({ id: 'global.save' })}</PrimaryButton>
        </div>
      </form>
    );
  }
}

ProducerAccountForm.propTypes = propTypes;

export default withStyles(styles)(injectIntl(ProducerAccountForm));
