import React from 'react';
import {injectIntl} from 'react-intl';
import {
  PrimaryTextField,
  ThirdButton,
} from '../../components';

class TextAreaWithCopyButton extends React.Component {

  state = {
    copySuccess: ''
  };

  copyToClipboard = (e) => {
    const { intl } = this.props;
    this.textArea.select();
    document.execCommand('copy');
    e.target.focus();
    this.setState({
      copySuccess: intl.formatMessage({id: "global.copied"})
    });
  };

  render() {
    const {intl, ...other} = this.props;

    return (
      <div>
        <PrimaryTextField
          {...other}
          multiline
          inputRef={input => this.textArea = input}
          rows={3}
          rowsMax={3}
        />

        {document.queryCommandSupported('copy') && (
          <div style={{textAlign: 'center', marginBottom: 20}}>
            <ThirdButton onClick={this.copyToClipboard}>{intl.formatMessage({id: "global.copy"})}</ThirdButton>
            {this.state.copySuccess ? `(${this.state.copySuccess})` : ""}
          </div>
        )}
      </div>
    )
  }
}

export default injectIntl(
  TextAreaWithCopyButton
);
