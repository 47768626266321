import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ImageUploader from '../ImageUploader';
import { BORDER_RADIUS_MAIN } from '../../constants/globals';
import SupplierSelect from '../SupplierSelect';
import SupplierTextField from '../SupplierTextField';
import { useIntl } from 'react-intl';
import { COLORS } from '../../constants/colors';
import { MenuItem } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 30,
  },
  image: {
    display: 'inline-block',
    width: 160,
    maxHeight: 400,
  },
  content: {
    paddingTop: 30,
    width: '100%',
    boxSizing: 'border-box',
  },
  fieldWrapper: {
    marginBottom: 32,
  },
  input: {
    width: '100%',
    flex: 1,
  },

  twoFieldsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  leftField: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    paddingRight: 20,
  },
  rightField: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  descriptionInputRoot: {},
  descriptionInput: {
    border: '1px solid ' + COLORS.grey,
    borderRadius: BORDER_RADIUS_MAIN,
  },
  selectRoot: { textAlign: 'left' },
}));

const propTypes = {
  isNew: PropTypes.bool,
  product: PropTypes.object.isRequired,
  types: PropTypes.array.isRequired,
  subTypes: PropTypes.array.isRequired,
  onChangeProduct: PropTypes.func,
};

const ProductFieldSet = (props) => {
  const { product, types, subTypes, onChangeProduct, isNew } = props;
  const classes = useStyles();
  const intl = useIntl();

  const [file, setFile] = useState(null);
  const [name, setName] = useState(product?.name || '');
  const [nameError, setNameError] = useState(null);
  const [description, setDescription] = useState(product?.description || '');
  const [descriptionError, setDescriptionError] = useState(null);
  const [type, setType] = useState(product?.type || types[0] || 'new');
  const [typeError, setTypeError] = useState(null);
  const [subType, setSubType] = useState(product?.subType || subTypes[0] || 'new');
  const [subTypeError, setSubTypeError] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    !initialLoad &&
      onChangeProduct({
        name,
        description,
        type,
        subType,
      });
    setInitialLoad(false);
  }, [file, name, description, type, subType]);

  useEffect(() => {
    !initialLoad &&
      onChangeProduct({
        image: file,
      });
  }, [file]);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.image}>
          <ImageUploader
            object={{ image: product.image, name: product.name }}
            base64={file}
            onUploaded={(newFile) => {
              setFile(newFile);
            }}
          />
        </div>

        <div className={classes.fieldWrapper}>
          <SupplierTextField
            coloredLabel={isNew && !name}
            className={classes.input}
            error={!!nameError}
            onChange={(event) => setName(event.target.value)}
            value={name}
            id="name-input"
            label={intl.formatMessage({ id: 'supplier.product.name' })}
            type="text"
            InputLabelProps={{ shrink: true }}
            helperText={nameError && intl.formatMessage({ id: nameError })}
            fullWidth
          />
        </div>

        <div className={classes.fieldWrapper}>
          <SupplierTextField
            coloredLabel={isNew && !description}
            className={classes.descriptionInputRoot}
            error={!!descriptionError}
            onChange={(event) => setDescription(event.target.value)}
            value={description}
            id="description-input"
            label={intl.formatMessage({ id: 'supplier.product.description' })}
            type="text"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              className: classes.descriptionInput,
              multiline: true,
            }}
            helperText={descriptionError && intl.formatMessage({ id: descriptionError })}
            fullWidth
          />
        </div>
      </div>

      <div className={classes.twoFieldsWrapper}>
        <div className={classes.leftField}>
          <SupplierSelect
            coloredLabel={isNew}
            className={classes.input}
            classes={{ root: classes.selectRoot }}
            label={intl.formatMessage({ id: 'supplier.product.type' })}
            value={type}
            error={typeError}
            helperText={typeError && intl.formatMessage({ id: typeError })}
            onChange={(e) => setType(e.target.value)}>
            {!types?.length && (
              <MenuItem key={'new'} value={'new'}>
                {'new'}
              </MenuItem>
            )}
            {types?.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </SupplierSelect>
        </div>
        <div className={classes.rightField}>
          <SupplierSelect
            coloredLabel={isNew}
            className={classes.input}
            classes={{ root: classes.selectRoot }}
            label={intl.formatMessage({ id: 'supplier.product.subtype' })}
            value={subType}
            error={subTypeError}
            helperText={subTypeError && intl.formatMessage({ id: subTypeError })}
            onChange={(e) => setSubType(e.target.value)}>
            {!subTypes?.length && (
              <MenuItem key={'new'} value={'new'}>
                {'new'}
              </MenuItem>
            )}
            {subTypes?.map((subType) => (
              <MenuItem key={subType} value={subType}>
                {subType}
              </MenuItem>
            ))}
          </SupplierSelect>
        </div>
      </div>
    </div>
  );
};

ProductFieldSet.propTypes = propTypes;

export default ProductFieldSet;
