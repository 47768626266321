import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSupplierPopover } from '../../../../store/actions';
import IconButton from '@material-ui/core/IconButton';
import { CloseIcon } from '../../assets';
import styles from './styles';
import FirstVisitContent from './FirstVisitContent';
import FirstSaleContent from './FirstSaleContent';
import FirstItemAddedContent from './FirstItemAddedContent';
import { apiSupplierRetrieveOrdersForSuppliers } from '../../../../api';
import { useLocation } from 'react-router';
import { getObjectFromStorage, saveObjectToStorage } from '../../../../helpers';

const useStyles = makeStyles(styles);

const propTypes = {
  isOpen: PropTypes.bool,
  producer: PropTypes.object,
};

export const POPOVER_STEPS = {
  none: null,
  firstVisit: 'firstVisit',
  firstSale: 'firstSale',
  firstItemAdded: 'firstItemAdded',
};

const POPOVER_STORAGE_KEY = 'supplier:popoversState';
const INITIAL_POPOVER_STORAGE = {
  awaitFirstSail: true,
  numberOfSavedOrders: 0,
};
const MINIMAL_AMOUNT_OF_ORDERS_TO_SHOW_FIRST_SALE = 10;

const PopoverWithContent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  const onClose = () => dispatch(toggleSupplierPopover(null));
  const setPopover = (newStep) => dispatch(toggleSupplierPopover(newStep));
  const { isOpened, step } = useSelector((state) => ({
    isOpened: !!state.supplier.popoverStep,
    step: state.supplier.popoverStep,
  }));

  useEffect(() => {
    const popoverVal = getObjectFromStorage(POPOVER_STORAGE_KEY);

    if (!popoverVal || !!popoverVal?.awaitFirstSail) {
      // Get single order to get orders meta
      apiSupplierRetrieveOrdersForSuppliers(
        {
          limit: 1,
          skip: 0,
        },
        (response) => {
          if (
            popoverVal?.awaitFirstSail &&
            !popoverVal?.numberOfSavedOrders &&
            !!response?.meta?.total &&
            response?.meta?.total < MINIMAL_AMOUNT_OF_ORDERS_TO_SHOW_FIRST_SALE
          ) {
            setPopover(POPOVER_STEPS.firstSale);

            saveObjectToStorage(POPOVER_STORAGE_KEY, {
              awaitFirstSail: false,
              numberOfSavedOrders: response?.meta?.total,
            });
          } else if (response?.meta?.total < MINIMAL_AMOUNT_OF_ORDERS_TO_SHOW_FIRST_SALE) {
            saveObjectToStorage(POPOVER_STORAGE_KEY, INITIAL_POPOVER_STORAGE);
          } else {
            saveObjectToStorage(POPOVER_STORAGE_KEY, {
              awaitFirstSail: false,
              numberOfSavedOrders: 0,
            });
          }
        },
        () => {}
      );
    }
  }, [location]);

  const getContent = () => {
    switch (step) {
      case POPOVER_STEPS.firstVisit: {
        return <FirstVisitContent />;
      }
      case POPOVER_STEPS.firstSale: {
        return <FirstSaleContent />;
      }
      case POPOVER_STEPS.firstItemAdded: {
        return <FirstItemAddedContent />;
      }
      default: {
        return <FirstVisitContent />;
      }
    }
  };

  return (
    <>
      <Dialog fullScreen open={isOpened} onClose={onClose} transitionDuration={0}>
        <div className={classes.dialogWrapper}>
          <div className={classes.dialogHeader}>
            <IconButton className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>

          <div className={classes.dialogContent}>
            <div className={classes.itemsWrapper}>{getContent()}</div>
          </div>
        </div>
      </Dialog>
      {/* Use to force show any popover
      <div className={classes.debugButtons}>
        <div onClick={() => setPopover(POPOVER_STEPS.firstVisit)}>open firstVisit</div>
        <div onClick={() => setPopover(POPOVER_STEPS.firstSale)}>open firstSale</div>
        <div onClick={() => setPopover(POPOVER_STEPS.firstItemAdded)}>open firstItemAdded</div>
      </div>
      */}
    </>
  );
};

PopoverWithContent.propTypes = propTypes;

export default PopoverWithContent;
