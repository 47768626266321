import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';

const styles = {};

const propTypes = {
  row: PropTypes.object.isRequired,
};

const Cell = ({ intl, row, classes, ...other }) => {
  return <Table.Cell {...other}>{`${row.product}${!!row.name ? ' - ' + row.name : ''}`}</Table.Cell>;
};

Cell.propTypes = propTypes;

export default withStyles(styles)(injectIntl(Cell));
