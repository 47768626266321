export default {
  desc: {
    display: 'block',
    fontSize: 12,
    textTransform: 'none'
  },
  descWrapper: {
    display: 'block',
    fontSize: 14,
    textTransform: 'none'
  }
};