import React from 'react';
import { COLORS } from '../constants/colors';

const Plus = (props) => {
  const { color = COLORS.blackTintedBlue, ...other } = props;
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <path
        d="M13.4999 8.5H8.49994V13.5C8.49994 14.05 8.04994 14.5 7.49994 14.5C6.94994 14.5 6.49994 14.05 6.49994 13.5V8.5H1.49994C0.949939 8.5 0.499939 8.05 0.499939 7.5C0.499939 6.95 0.949939 6.5 1.49994 6.5H6.49994V1.5C6.49994 0.95 6.94994 0.5 7.49994 0.5C8.04994 0.5 8.49994 0.95 8.49994 1.5V6.5H13.4999C14.0499 6.5 14.4999 6.95 14.4999 7.5C14.4999 8.05 14.0499 8.5 13.4999 8.5Z"
        fill={color}
      />
    </svg>
  );
};

export default Plus;
