import React from "react";
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles'
import EnvelopeIcon from '@material-ui/icons/Drafts';
import {
  ErrorBox,
  AdminDialogTitle,
  Spinner,
  Icon,
  PrimaryButton
} from '../../../components';
//import {apiCustomerCreatePasswordRequest} from '../../../api';
import styles from './styles';

class CreatePasswordStage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      emailAfterSent: "",
      isLoading: false,
      requestSent: false,
      requestError: ""
    };
  }

  request = () => {
    this.setState({isLoading: true});

    /*const onError = () => {
      this.setState({
        requestError: "messages.somethingWentWrong",
        isLoading: false
      });
    };

    const onSuccess = (response) => {
      this.setState({
        emailAfterSent: response.data.email,
        requestError: "",
        requestSent: true,
        isLoading: false
      });
    };*/

    //apiCustomerCreatePasswordRequest(this.props.email, onSuccess, onError);
  };

  getInitialView() {
    const {classes} = this.props;
    return (
      <React.Fragment>
        {this.state.isLoading ?
          <Spinner size={50}/> : null}

        <AdminDialogTitle title={<FormattedMessage id="createPassword.title"/>}/>
        <ErrorBox error={this.state.requestError}/>

        <DialogContent>
          <p style={{textAlign: 'center'}}>
            <FormattedMessage id="createPassword.accountMessage"
                              values={{firstName: this.props.firstName}}
            />
          </p>
        </DialogContent>

        <div className={classes.controls}>
          <PrimaryButton onClick={this.request}>
            <FormattedMessage id="createPassword.buttonTitle"/>
          </PrimaryButton>
        </div>

      </React.Fragment>
    );
  }

  getFinalView() {
    const {classes} = this.props;
    return (
      <React.Fragment>
        <DialogContent className={classes.finalMessageContainer}>
          <Icon icon={EnvelopeIcon} classes={{root: classes.envelope}}/>
          <p>
            <FormattedMessage id="createPassword.buttonTitle"
                              values={{email: this.state.emailAfterSent}}
            />
          </p>
        </DialogContent>

        <div className={classes.controlsCentered}>
          <PrimaryButton onClick={this.props.onDone}>
            <FormattedMessage id="global.ok"/>
          </PrimaryButton>
        </div>
      </React.Fragment>
    )
  }

  render() {
    return this.state.requestSent ?
      this.getFinalView() : this.getInitialView();
  }
}

CreatePasswordStage.propTypes = {
  firstName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  onDone: PropTypes.func.isRequired,
};

export default withStyles(styles)(CreatePasswordStage);