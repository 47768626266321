import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl'
import {withStyles} from '@material-ui/core/styles';
import {AdminDialog} from '../../components';
import styles from './styles'
import VariantContainer from "./VariantContainer/VariantContainer";

const propTypes = {
  product: PropTypes.object,
  variant: PropTypes.object,
  onUpdateProduct: PropTypes.func.isRequired,
  producer: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

const defaultProps = {
  variant: null,
};

const VariantDialog = ({ intl,
                         classes,
                         onClose,
                         product,
                         variant,
                         onUpdateProduct,
                         producer }) => {
  return (
    <AdminDialog open
                 paperClass={classes.paper}
                 onClose={onClose}>

      {!variant && (
        <VariantContainer title={"variant.title.create"}
                          producer={producer}
                          product={product}
                          onUpdateProduct={onUpdateProduct}
                          buttonTitle={"variant.button.create"}
                          onClose={onClose}
                          onDone={() => onClose(true)} />
      )}

      {variant && (
        <VariantContainer title={"variant.title.update"}
                          producer={producer}
                          product={product}
                          variant={variant}
                          onUpdateProduct={onUpdateProduct}
                          buttonTitle={"variant.button.update"}
                          onClose={onClose}
                          onDone={() => onClose(true)} />
      )}

    </AdminDialog>
  );
};

VariantDialog.propTypes = propTypes;
VariantDialog.defaultProps = defaultProps;

export default withStyles(styles, {withTheme: true})(
  injectIntl(VariantDialog)
);
