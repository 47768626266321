import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import CellAsTextInput from './CellAsTextInput';

const styles = {};

const propTypes = {
  value: PropTypes.any,
  cellProps: PropTypes.object.isRequired,
  onFinishCellEditing: PropTypes.func.isRequired,
};

const CellAsIntegerInput = (props) => {
  return (
    <CellAsTextInput
      {...props}
      formatValueBeforeApplyEditing={(value) => parseInt(value) || 0}
      inputProps={{
        InputProps: {
          type: 'number',
        },
      }}
    />
  );
};

CellAsIntegerInput.propTypes = propTypes;

export default withStyles(styles)(injectIntl(CellAsIntegerInput));
