import React from 'react';

export const LanIcon = (props) => {
  const { size } = props;
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="LanIcon"
      height={size}
      width={size}
      {...props}>
      <path d="M13 22h8v-7h-3v-4h-5V9h3V2H8v7h3v2H6v4H3v7h8v-7H8v-2h8v2h-3z" />
    </svg>
  );
};
