/** Represents customer payment terms */
class PaymentTerms {

  constructor(props = {}) {
    this.paymentTerms = props.paymentTerms || "";
    this.allowCredit = props.allowCredit || false;
    this.allowShipping = props.allowShipping || false;
    this.freeDelivery = props.freeDelivery || false;
  }

}

export default PaymentTerms
