import axios from 'axios';
import { genHttpBasicToken } from '../../helpers';
import API_END_POINTS from '../endpoints';
import { REQUEST_TYPES } from '../request';

/**
 * Login request
 * @param {string} email
 * @param {string} password
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiLogin = function (email, password, onSuccess, onError) {
  axios({
    method: REQUEST_TYPES.POST,
    url: process.env.REACT_APP_API_URL + API_END_POINTS.LOGIN,
    headers: {
      Authorization: 'Basic ' + genHttpBasicToken(email, password),
    },
  })
    .then((response) => {
      onSuccess && onSuccess(response);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Login request
 * @param {string} token
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiLoginByToken = function (token, onSuccess, onError) {
  axios({
    method: REQUEST_TYPES.POST,
    url: process.env.REACT_APP_API_URL + API_END_POINTS.LOGIN,
    headers: {
      Authorization: 'Basic ' + token,
    },
  })
    .then((response) => {
      onSuccess && onSuccess(response);
    })
    .catch((error) => {
      onError && onError(error);
    });
};
