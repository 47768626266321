import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl'
import {withStyles} from '@material-ui/core/styles';
import {AdminDialog} from '../../components';
import ProductContainer from './ProductContainer/ProductContainer';
import Stages from './Stages'
import styles from './styles'

const propTypes = {
  product: PropTypes.object,
  producer: PropTypes.object.isRequired,
  stage: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

const defaultProps = {
  stage: Stages.CREATE_PRODUCT,
};

const ProductDialog = ({ intl,
                         classes,
                         onClose,
                         product,
                         producer,
                         stage }) => {
  return (
    <AdminDialog open
                 paperClass={classes.paper}
                 onClose={() => onClose(false)}>

      {stage === Stages.CREATE_PRODUCT && (
        <ProductContainer title={"products.title.create"}
                          producer={producer}
                          buttonTitle={"products.button.create"}
                          onDone={onClose} />
      )}

      {stage === Stages.UPDATE_PRODUCT && (
        <ProductContainer  title={"products.title.update"}
                           buttonTitle={"products.button.update"}
                           producer={producer}
                           onDone={onClose}
                           product={product} />
      )}

    </AdminDialog>
  );
};

ProductDialog.propTypes = propTypes;
ProductDialog.defaultProps = defaultProps;

export default withStyles(styles, {withTheme: true})(
  injectIntl(ProductDialog)
);
