import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Fade, Typography } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

const propTypes = {
  copyContent: PropTypes.string,
  text: PropTypes.string,
};

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    width: '100%',
    backgroundColor: '#fff',
    border: '1px solid #D0D7DE',
    padding: '0px 0px 0px 20px',
    borderRadius: 4,
    transition: 'background-color 0.3s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F6F8FA',
    },
  },

  text: {
    fontSize: 14,
  },
  icon: {
    fontSize: 22,
    color: '#000',
    marginLeft: 10,
    padding: '10px 20px 10px 20px',
    boxSizing: 'content-box',
    height: '100%',
    width: 22,
    borderLeft: '1px solid rgb(208, 215, 222)',
  },
  copiedSuccessWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: '#000',
    padding: '10px 20px',
    border: '2px solid #000',
    color: 'white',
    borderRadius: 4,
  },
}));

const CopyToClipboard = (props) => {
  const { copyContent, text, onSuccessMessage, EndAdornmentComponent, classes, ...others } = props;
  const intl = useIntl();
  const componentClasses = useStyles();
  const [isCopied, setIsCopied] = useState(false);

  const successMessage = onSuccessMessage || intl.formatMessage({ id: 'global.copied' });

  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => {
        setIsCopied(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isCopied]);

  const onClick = () => {
    if (isCopied) return;

    navigator.clipboard.writeText(copyContent).then(() => {
      setIsCopied(true);
    });
  };

  return (
    <div className={classNames([componentClasses?.root, classes?.root])} {...others}>
      <div onClick={onClick} className={classNames([componentClasses.button, classes?.button])}>
        <Typography
          component={'p'}
          variant={'inherit'}
          noWrap
          className={classNames([componentClasses.text, classes?.text])}>
          {text}
        </Typography>
        {EndAdornmentComponent ? (
          <EndAdornmentComponent />
        ) : (
          <LinkIcon className={classNames([componentClasses.icon, classes?.icon])} />
        )}
      </div>
      <Fade in={isCopied}>
        <div className={classNames([componentClasses.copiedSuccessWrapper, classes?.copiedSuccessWrapper])}>
          {successMessage}
        </div>
      </Fade>
    </div>
  );
};

CopyToClipboard.propTypes = propTypes;

export default CopyToClipboard;
