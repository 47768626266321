import React from 'react';
import {
  MuiThemeProvider,
  createTheme
} from '@material-ui/core/styles';
import Label from "@material-ui/core/FormLabel";

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: "#707070",
        fontSize: 12,
        "&$focused": {
          color: "#707070"
        }
      },
      /*"&$focused": {
        "&$focused": {
          color: "#707070"
        }
      }*/
    },
  },
});

const PrimaryFormLabel = (props) => {
  const {children, ...other} = props;
  return (
    <MuiThemeProvider theme={theme} >
      <Label {...other}>{children}</Label>
    </MuiThemeProvider>
  );
};

export default PrimaryFormLabel;
