import {
  HAS_SUBSTRING_FILTER,
  IN_ARRAY_FILTER,
  ACTIVITY_FILTER,
  CUSTOMER_APPROVED_FILTER,
  CUSTOMER_ALLOW_CREDIT_FILTER,
  CUSTOMER_DEFAULT_PAYMENT_METHOD_FILTER,
  IMAGE_FILTER,
  DATE_FILTER,
  CUSTOMER_TYPE_FILTER,
  CUSTOMER_TYPE_FILTER_FROM_VARIABLE,
} from './FilterTypes'
import HasSubstringFilter from './Filters/HasSubstringFilter'
import InArrayFilter from './Filters/InArrayFilter'
import DXCSActivityFilter from './Filters/DXCSActivityFilter'
import DXCSCustomerApprovedFilter from './Filters/DXCSCustomerApprovedFilter'
import DXCSCustomerAllowCreditFilter from './Filters/DXCSCustomerAllowCreditFilter'
import DXCSCustomerDefaultPaymentMethodFilter from './Filters/DXCSCustomerDefaultPaymentMethodFilter'
import DXCSDateFilter from './Filters/DXCSDateFilter'
import DXCSImageFilter from './Filters/DXCSImageFilter'
import DXCSCustomerTypeFilter from "./Filters/DXCSCustomerTypeFilter";

export default class FiltersProcessor {

  constructor(intl, filters) {
    this.filters = [];
    this.intl = intl;

    filters.forEach((filter) => {
      if (filter.type === HAS_SUBSTRING_FILTER) {
        this.filters.push(new HasSubstringFilter(filter.selectedValue, filter.property));
      }

      if (filter.type === IN_ARRAY_FILTER) {
        this.filters.push(new InArrayFilter(filter.selectedValues, filter.property));
      }

      if (filter.type === ACTIVITY_FILTER) {
        this.filters.push(new DXCSActivityFilter(filter.selectedValues, filter.property));
      }
      
      if (filter.type === CUSTOMER_APPROVED_FILTER) {
        this.filters.push(new DXCSCustomerApprovedFilter(filter.selectedValues, filter.property));
      }

      if (filter.type === CUSTOMER_ALLOW_CREDIT_FILTER) {
        this.filters.push(new DXCSCustomerAllowCreditFilter(filter.selectedValues, filter.property));
      }

      if (filter.type === CUSTOMER_DEFAULT_PAYMENT_METHOD_FILTER) {
        this.filters.push(new DXCSCustomerDefaultPaymentMethodFilter(filter.selectedValues, filter.property));
      }

      if (filter.type === DATE_FILTER) {
        this.filters.push(new DXCSDateFilter(filter.selectedValues, filter.property));
      }

      if (filter.type === CUSTOMER_TYPE_FILTER) {
        this.filters.push(new DXCSCustomerTypeFilter(filter.selectedValues, filter.property));
      }
  
      if (filter.type === CUSTOMER_TYPE_FILTER_FROM_VARIABLE) {
        this.filters.push(new DXCSCustomerTypeFilter(filter.selectedValues, filter.property, filter.fromVariable));
      }

      if (filter.type === IMAGE_FILTER) {
        this.filters.push(new DXCSImageFilter(filter.selectedValues, filter.property));
      }
    });
  }

  setFilter(filter) {
    this.filters.push(filter);
  }

  isSatisfy(row) {
    let satisfy = true;
    for (let i = 0; i < this.filters.length; i++) {
      if (!this.filters[i].isSatisfy(row, this.intl)) {
        satisfy = false;
        break;
      }
    }
    return satisfy;
  }
}