import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import TodayIcon from '@material-ui/icons/Today';
import Chip from '@material-ui/core/Chip';
import { COLORS } from '../../../../../helpers';
import DXFilterConfig from '../DXFilterConfig';
import PrimaryInlineDatePicker from '../../../../PrimaryInlineDatePicker/PrimaryInlineDatePicker';

const styles = {
  wrapper: {},
  chips: {
    display: 'block',
    width: '100%',
    margin: '20px 0 0 0',
    textAlign: 'left',
  },
  chip: {
    margin: 5,
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  selectFormControl: {
    marginBottom: 20,
  },
  column: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  mr20: {
    marginRight: 20,
  },
};

const propTypes = {
  shared: PropTypes.object,
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onChangeShared: PropTypes.func.isRequired,
};
const defaultProps = {
  selected: [],
};

class DXSubscriptionReportsDatesFilter extends React.Component {
  handleValueDelete = () => {
    this.props.onChange([]);
  };

  getCopy = (object) => {
    return JSON.parse(JSON.stringify(object));
  };

  getValue = () => {
    const { classes, intl, selected } = this.props;

    let value = '';
    if (selected.length) {
      let filter = selected[0];

      if (filter.from || filter.to) {
        let parts = [];
        if (filter.from) {
          parts.push(moment(filter.from).format('L'));
        }

        if (filter.to) {
          parts.push(moment(filter.to).format('L'));
        }

        value = parts.join('—');
      }
    }

    return (
      <div className={classes.chips}>
        {value && <Chip label={value} className={classes.chip} onDelete={() => this.handleValueDelete()} />}
      </div>
    );
  };

  getShippingFilterValue = (key) => {
    const { selected } = this.props;
    let date = null;
    if (selected && selected.length) {
      if (key === 'from' && selected[0]['from']) {
        date = moment(selected[0][key]).startOf('day').toISOString(true);
      }
      if (key === 'to' && selected[0]['to']) {
        date = moment(selected[0][key]).endOf('day').toISOString(true);
      }
    }
    return date;
  };

  handleValueAdd = (selectedFilterValue, subName = null) => {
    let shipping = this.getCopy(this.props.selected);

    let val = selectedFilterValue;
    if (shipping.length === 0) {
      selectedFilterValue = {
        from: subName === 'from' ? val : null,
        to: subName === 'to' ? val : null,
      };
    } else {
      selectedFilterValue = {
        from: subName === 'from' ? val : shipping[0]['from'],
        to: subName === 'to' ? val : shipping[0]['to'],
      };
    }

    shipping = [];
    shipping.push(selectedFilterValue);

    this.props.onChange(shipping);
  };

  render() {
    const { classes, intl } = this.props;
    return (
      <React.Fragment>
        <div className={classes.row}>
          <div className={classes.column}>
            <div className={classNames(classes.dateSelects, classes.mr20)}>
              <PrimaryInlineDatePicker
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <TodayIcon style={{ color: COLORS.text }} />
                    </InputAdornment>
                  ),
                }}
                label={intl.formatMessage({ id: 'global.from' })}
                value={this.getShippingFilterValue('from')}
                onChange={(value) => this.handleValueAdd(value, 'from')}
              />
            </div>
          </div>
          <div className={classes.column}>
            <div className={classes.dateSelects}>
              <PrimaryInlineDatePicker
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <TodayIcon style={{ color: COLORS.text }} />
                    </InputAdornment>
                  ),
                }}
                label={intl.formatMessage({ id: 'global.to' })}
                value={this.getShippingFilterValue('to')}
                onChange={(value) => this.handleValueAdd(value, 'to')}
              />
            </div>
          </div>
        </div>

        {!!this.props.selected.length && this.getValue()}
      </React.Fragment>
    );
  }
}

DXSubscriptionReportsDatesFilter.propTypes = propTypes;
DXSubscriptionReportsDatesFilter.defaultProps = defaultProps;
DXSubscriptionReportsDatesFilter.getLabel = (intl, selectedValues) => {
  let label = '';
  if (Array.isArray(selectedValues) && selectedValues.length) {
    if (selectedValues[0].from || selectedValues[0].to) {
      let parts = [];

      if (selectedValues[0].from) {
        parts.push(moment(selectedValues[0].from).format('L'));
      }

      if (selectedValues[0].to) {
        parts.push(moment(selectedValues[0].to).format('L'));
      }

      label = parts.join('—');
    }

    if (selectedValues[0].day) {
      label = moment(selectedValues[0].day).format('MMM D');
    }
  }
  return label;
};

DXSubscriptionReportsDatesFilter.applyToQueryParams = (queryParams, selectedValues) => {
  if (selectedValues.length) {
    queryParams['shipping:from'] = moment(selectedValues[0].day).startOf('day').toISOString(true);
    queryParams['shipping:to'] = moment(selectedValues[0].day).endOf('day').toISOString(true);
  }
  return queryParams;
};
DXSubscriptionReportsDatesFilter.getConfig = (intl, equals, queryParamsByFilterName) => {
  let readOnly = true;

  if (queryParamsByFilterName?.subscriptionReportDatesFilter.hasOwnProperty('readOnly')) {
    readOnly = queryParamsByFilterName.subscriptionReportDatesFilter.readOnly;
  }

  return new DXFilterConfig({
    name: 'subscriptionReportDatesFilter',
    property: 'subscriptionReportDatesFilter',
    title: intl.formatMessage({ id: 'filter.subscriptionDate' }),
    disabled: false,
    selected: [],
    shared: { filterType: -1 },
    equalityStatus: false,
    readOnly,
  });
};

export default injectIntl(withStyles(styles)(DXSubscriptionReportsDatesFilter));
