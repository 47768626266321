import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment-timezone';
import momentSv from 'moment/locale/sv';
import { Provider } from 'react-redux';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import {
  IntlProvider,
  //addLocaleData,
} from 'react-intl';
// import englishLocale from 'react-intl/locale-data/en';
// import swedishLocale from 'react-intl/locale-data/sv';
import * as serviceWorker from './serviceWorker';
import englishMessages from './translations/en.json';
import swedishMessages from './translations/sv.json';
import englishPickupsMessages from './apps/pickups/translations/en.json';
import swedishPickupsMessages from './apps/pickups/translations/sv.json';
import englishDeliveriesMessages from './apps/deliveries/translations/en.json';
import swedishDeliveriesMessages from './apps/deliveries/translations/sv.json';

import { RouterWrapper } from './routes';
import configureStore from './store/configure';

/*addLocaleData([
  ...englishLocale,
  ...swedishLocale
]);*/

// eslint-disable-next-line
import './styles/index.css';

const store = configureStore();
const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  fontFamily: "'Roboto', sans-serif",
  palette: {
    primary: {
      main: '#212121',
    },
    secondary: {
      main: '#363636',
    },
    white: {
      main: '#ffffff',
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: '#363636',
      },
      /*"&$focused": {
        color: '#41CD8C',
      }*/
    },
  },
});

const messages = {
  en: { ...englishMessages, ...englishPickupsMessages, ...englishDeliveriesMessages },
  sv: { ...swedishMessages, ...swedishPickupsMessages, ...swedishDeliveriesMessages },
};

const momentLocales = {
  sv: momentSv,
};

moment.tz.setDefault(process.env.REACT_APP_TIME_ZONE);
moment.locale(process.env.REACT_APP_LOCALE, momentLocales[process.env.REACT_APP_LOCALE]);

const Root = () => {
  return (
    <IntlProvider messages={messages[process.env.REACT_APP_LOCALE]} locale={process.env.REACT_APP_LOCALE}>
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <RouterWrapper />
        </MuiThemeProvider>
      </Provider>
    </IntlProvider>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));

if (process.env.NODE_ENV !== 'production') {
  if (module.hot) {
    module.hot.accept(Root, () => {
      ReactDOM.render(Root, document.getElementById('root'));
    });
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
