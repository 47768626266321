const validation = {
  name: {
    presence: {
      message: '^validation.customItem.enterName'
    }
  },
  unit: {
    presence: {
      message: '^validation.customItem.selectUnit'
    }
  },
  quantity: {
    presence: {
      message: '^validation.customItem.enterQuantity'
    }
  },
  unitPrice: {
    presence: {
      message: '^validation.customItem.enterUnitPrice'
    }
  },
};

export default validation;
