import {
  COLLECTION_VIEW_TYPE_GRID,
  COLLECTION_VIEW_TYPE_LIST_COMPACT,
  COLLECTION_VIEW_TYPE_LIST
} from '../helpers';

/** Get collection view type */
export function getCollectionViewType() {
  return localStorage['collection.view'] ||
    COLLECTION_VIEW_TYPE_GRID;
}

/** Set collection view type */
export function setCollectionViewType(type, onDone) {
  localStorage.setItem('collection.view', type);
  onDone && onDone(type);
}

/**
 * Check whether type of view equals list or not
 * @return {boolean}
 */
export const isList = () => {
  const view = getCollectionViewType();
  return (view === COLLECTION_VIEW_TYPE_LIST_COMPACT ||
    view === COLLECTION_VIEW_TYPE_LIST);
};