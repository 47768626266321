import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../../../hocs';
import {
  AdminDialogTitle,
  AdminDialogContent,
  AdminDialogControls,
  PrimaryButton,
  AdminDialog,
  Spinner,
  ErrorBox,
} from '../../../../components/index';
import LocationForm from './Location/LocationForm';
import EntityForm from './EntityForm/EntityForm';
import { COLORS } from '../../../../helpers';
import {
  apiProducerShippingDayCreate,
  apiProducerShippingDayPatch,
  apiProducerShippingDaysActiveChange,
} from '../../../../api';

const styles = {
  paper: {
    padding: '40px 52px 74px',
    borderRadius: 8,
    height: 'calc(100% - 16px)',
    minWidth: 800,
  },
  wrapper: {},
  name: {
    fontSize: 18,
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.text,
    marginBottom: 40,
  },
  input: {
    marginBottom: 25,
  },
  label: {
    fontSize: 14,
  },
  title: {
    fontSize: 18,
    textTransform: 'capitalize',
  },
  inputText: {
    textAlign: 'right',
  },
  button: {
    minWidth: 330,
    alignSelf: 'center',
    textTransform: 'uppercase',
  },
  controls: {
    margin: '27px 0 0 0',
    textAlign: 'center',
  },
  control: {
    padding: '0 105px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    flex: 1,
  },
};

const propTypes = {
  days: PropTypes.array.isRequired,
  entity: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
};

const STAGES = {
  DELIVERY_DAY: 'DELIVERY_DAY',
  LOCATION: 'LOCATION',
  EDIT_LOCATION: 'EDIT_LOCATION',
};

class Dialog extends React.Component {
  state = {
    loading: false,
    error: '',
    stage: STAGES.DELIVERY_DAY,
    locations: this.props.entity && this.props.entity.locations ? this.props.entity.locations : [],
    patchLocation: null,
    editLocation: null,
  };

  constructor(props) {
    super(props);
    this.dayForm = null;
    this.locationForm = null;
  }

  validate = () => {
    if (this.state.stage === STAGES.DELIVERY_DAY) this.validateDeliveryDay();
    if (this.state.stage === STAGES.LOCATION) this.validateLocation();
    if (this.state.stage === STAGES.EDIT_LOCATION) this.validateEditLocation();
  };

  validateEditLocation = () => {
    const form = this.locationForm.validate();

    if (form.validated) {
      this.setState(
        (state) => {
          let locations = [...state.locations];
          const index = locations.findIndex((loc) => loc.id === form.data.id);
          locations[index] = form.data;

          return {
            locations,
            stage: STAGES.DELIVERY_DAY,
          };
        },
        () => this.dayForm.validate()
      );
    }
  };

  validateLocation = () => {
    const form = this.locationForm.validate();

    if (form.validated) {
      this.setState(
        (state) => {
          let locations = state.locations.filter((item) => item.id !== form.data.id);
          locations.push(form.data);
          return {
            locations,
            stage: STAGES.DELIVERY_DAY,
          };
        },
        () => this.dayForm.validate()
      );
    }
  };

  validateDeliveryDay = (close = true) => {
    const { onDone, entity } = this.props;
    const form = this.dayForm.validate();
    if (form.validated) {
      this.setState({
        loading: true,
        error: '',
      });

      let data = {
        ...form.data,
      };

      data['locations'] = this.state.locations;
      if (entity && entity.id) {
        apiProducerShippingDayPatch(
          entity.id,
          data,
          (options) => {
            onDone(options, close);
            this.setState({ loading: false });
          },
          () => this.setState({ loading: false })
        );
      } else {
        apiProducerShippingDayCreate(
          data,
          (options) => {
            onDone(options, close);
            this.setState({ loading: false });
          },
          () => this.setState({ loading: false })
        );
      }
    } else {
      this.setState({
        error: form.error,
      });
    }
  };

  componentWillUnmount() {
    this.form = null;
    this.dayForm = null;
  }

  onAttachLocation = () => {
    this.validateDeliveryDay(false);
    this.setState({ stage: STAGES.LOCATION });
  };

  onDetachLocation = (location) => {
    this.setState((state) => {
      let locations = state.locations.filter((item) => item.id !== location.id);
      return { locations };
    });
  };

  onEditLocation = (location) => {
    this.setState({ stage: STAGES.EDIT_LOCATION, editLocation: location });
  };

  onToggleLocation = (location) => {
    apiProducerShippingDaysActiveChange(
      this.props.entity.id,
      location.id,
      !location.active,
      (res) => {
        const currentDay = res.days.find((day) => day.id === this.props.entity.id);

        this.setState({
          locations: currentDay.locations,
        });
      },
      (error) => {
        this.setState({ error: error && error.response && error.response.data && error.response.data.message });
      }
    );
  };

  getTitle = () => {
    const { entity, intl } = this.props;
    const { stage } = this.state;

    if (stage === STAGES.DELIVERY_DAY) {
      return entity.id
        ? intl.formatMessage({ id: 'producer.dialog.editingDeliveryDay' })
        : intl.formatMessage({ id: 'producer.dialog.addingDeliveryDay' });
    }

    if (stage === STAGES.LOCATION) {
      return intl.formatMessage({ id: 'producer.dialog.addLocation' });
    }

    if (stage === STAGES.EDIT_LOCATION) {
      return intl.formatMessage({ id: 'producer.dialog.editLocation' });
    }
  };

  render() {
    const { intl, classes, entity, onClose, days } = this.props;

    const { stage } = this.state;

    return (
      <AdminDialog open closeButton onClose={onClose} paperClass={classes.paper}>
        <AdminDialogTitle title={this.getTitle()} />
        <AdminDialogContent className={classes.wrapper}>
          {this.state.loading && <Spinner size={60} />}

          {this.state.error && <ErrorBox error={this.state.error} />}

          <EntityForm
            data={entity}
            days={days}
            visible={stage === STAGES.DELIVERY_DAY}
            locations={this.state.locations}
            onAttachLocation={this.onAttachLocation}
            onDetachLocation={this.onDetachLocation}
            onEditLocation={this.onEditLocation}
            onToggleLocation={this.onToggleLocation}
            onRef={(form) => (this.dayForm = form)}
          />

          {stage === STAGES.LOCATION && <LocationForm onRef={(form) => (this.locationForm = form)} />}
          {stage === STAGES.EDIT_LOCATION && (
            <LocationForm onRef={(form) => (this.locationForm = form)} editLocation={this.state.editLocation} />
          )}

          <AdminDialogControls>
            <PrimaryButton className={classes.button} onClick={this.validate}>
              {intl.formatMessage({ id: 'global.save' })}
            </PrimaryButton>
          </AdminDialogControls>
        </AdminDialogContent>
      </AdminDialog>
    );
  }
}

Dialog.propTypes = propTypes;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(Dialog)));
