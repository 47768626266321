import React from 'react'
import { makeStyles } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: '#000',
  },
  tooltipArrow: {
    color: '#000',
    padding: 10,
    borderRadius: 9,
  },
}));

function TooltipWrapper(props) {
  const { title } = props
  const classes = useStyles()

  return (
    <Tooltip
      title={title}
      classes={{
        arrow: classes.tooltipArrow,
        tooltip: classes.tooltip,
      }}
      placement="bottom-start"
    >
      {props.children}
    </Tooltip>
  )
}

export default TooltipWrapper
