import {
  apiSuppliersRetrieveOrder,
  apiSuppliersAddVariantToOrder,
  apiSuppliersOrderAddNote,
  apiSuppliersRemoveLineItemFromOrder,
  apiSuppliersAddInvoicePaymentMethodOnOrder,
  apiSuppliersOrderLineItemSetQuantity,
  apiSuppliersOrderLineItemSetNote,
  apiSuppliersSetOrderShipping,
  apiSuppliersUpdateOrderLineItem,
  apiSuppliersOrderDeleteDeliveryCompany,
  apiCustomerRetrieve,
  apiSuppliersAddCustomLineItemToOrder,
  apiSuppliersAddPrepurchasePaymentMethodOnOrder,
  apiSuppliersOrderDeleteDiscount,
  apiSuppliersOrderUpdatedSendEmails,
  apiSuppliersApproveOrderLineItems,
  apiSuppliersAddCustomerOnOrder,
  apiSuppliersRemoveCustomerFromOrder,
} from '../../api';

import { ORDER_SET, ORDER_UNSET, ORDER_LOADING, ORDER_LOADED } from '../constants/order';
import { ORDER_STATUSES, PAYMENT_TYPES } from '../../helpers';

export function setSuppliersOrder(id, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });
    apiSuppliersRetrieveOrder(
      id,
      (order) => dispatch(refreshSuppliersOrder(order, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}

export function refreshSuppliersOrder(order, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });
    if (order.customer) {
      apiCustomerRetrieve(
        order.customer,
        (customer) => {
          dispatch({
            type: ORDER_SET,
            payload: {
              order: order,
              customer: customer,
            },
          });
          onSuccess && onSuccess(order);
          dispatch({ type: ORDER_LOADED });
        },
        (error) => onError && onError(error)
      );
    } else {
      dispatch({
        type: ORDER_SET,
        payload: {
          order: order,
          customer: null,
        },
      });
      onSuccess && onSuccess(order);
      dispatch({ type: ORDER_LOADED });
    }
  };
}

export function unsetSuppliersOrder() {
  return (dispatch) => {
    dispatch({ type: ORDER_UNSET });
  };
}

export function orderSuppliersAttachVariant(order, product, variant, data, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });
    apiSuppliersAddVariantToOrder(
      order.id,
      variant.id,
      data,
      (order) => dispatch(refreshSuppliersOrder(order, onSuccess, onError)),
      (error) => {
        dispatch({ type: ORDER_LOADED });

        onError && onError(error);
      }
    );
  };
}

export function orderSuppliersAttachCustomItem(order, data, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });
    apiSuppliersAddCustomLineItemToOrder(
      order.id,
      data,
      (order) => dispatch(refreshSuppliersOrder(order, onSuccess, onError)),
      (error) => {
        dispatch({ type: ORDER_LOADED });

        onError && onError(error);
      }
    );
  };
}

export function orderSuppliersChangeLineItemNote(order, lineItem, note, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });
    apiSuppliersOrderLineItemSetNote(
      order.id,
      lineItem.id,
      note,
      (order) => dispatch(refreshSuppliersOrder(order, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}

export function orderSuppliersChangeLineItemQuantity(order, lineItem, quantity, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });
    apiSuppliersOrderLineItemSetQuantity(
      order.id,
      lineItem.id,
      quantity,
      (order) => dispatch(refreshSuppliersOrder(order, onSuccess, onError)),
      (error) => {
        dispatch({ type: ORDER_LOADED });
        onError && onError(error);
      }
    );
  };
}

export function orderSuppliersAddNote(order, note, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });
    apiSuppliersOrderAddNote(
      order.id,
      note,
      (order) => dispatch(refreshSuppliersOrder(order, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}

export function orderSuppliersPatchLineItem(order, lineItem, data, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });
    apiSuppliersUpdateOrderLineItem(
      order.id,
      lineItem.id,
      data,
      (order) => dispatch(refreshSuppliersOrder(order, onSuccess, onError)),
      (error) => {
        dispatch({ type: ORDER_LOADED });

        onError && onError(error);
      }
    );
  };
}

export function orderSuppliersDetachLineItem(order, lineItem, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });
    apiSuppliersRemoveLineItemFromOrder(
      order.id,
      lineItem.id,
      (order) => dispatch(refreshSuppliersOrder(order, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}

export function orderSuppliersAttachShipping(order, method, data, deliveryCompany, location, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });

    if (location) {
      data['locationId'] = location;
    }

    apiSuppliersSetOrderShipping(
      order.id,
      method,
      data,
      deliveryCompany,
      (order) => dispatch(refreshSuppliersOrder(order, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}

export function orderSuppliersRemoveShippingDeliveryCompany(draft, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });

    apiSuppliersOrderDeleteDeliveryCompany(
      draft.id,
      (order) => dispatch(refreshSuppliersOrder(order, onSuccess, onError)),
      onError
    );
  };
}

export function orderSuppliersFinishEditing(order, emails, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });

    if (order.status === ORDER_STATUSES.ORDER_RECEIVED) {
      apiSuppliersApproveOrderLineItems(
        order.id,
        () => {},
        () => {}
      );
    }

    apiSuppliersOrderUpdatedSendEmails(order, emails, onSuccess, onError);
  };
}

export function orderSuppliersDeleteDiscount(order, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });
    apiSuppliersOrderDeleteDiscount(
      order.id,
      (order) => dispatch(refreshSuppliersOrder(order, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}

export function orderSuppliersAttachPaymentMethod(order, method, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });

    // Prepurchase order should use another method for adding payment method
    if (order.prepurchaseOrder) {
      if ([PAYMENT_TYPES.INVOICE].indexOf(method) !== -1) {
        apiSuppliersAddPrepurchasePaymentMethodOnOrder(
          order.id,
          { method: null },
          (order) => dispatch(refreshSuppliersOrder(order, onSuccess, onError)),
          (error) => onError && onError(error)
        );
      }

      if ([PAYMENT_TYPES.CASH, PAYMENT_TYPES.CHECK, PAYMENT_TYPES.COD].indexOf(method) !== -1) {
        apiSuppliersAddPrepurchasePaymentMethodOnOrder(
          order.id,
          { method: method },
          (order) => dispatch(refreshSuppliersOrder(order, onSuccess, onError)),
          (error) => onError && onError(error)
        );
      }

      if ([PAYMENT_TYPES.CASH, PAYMENT_TYPES.CHECK, PAYMENT_TYPES.COD, PAYMENT_TYPES.INVOICE].indexOf(method) === -1) {
        apiSuppliersAddPrepurchasePaymentMethodOnOrder(
          order.id,
          { paymentMethodId: method },
          (order) => dispatch(refreshSuppliersOrder(order, onSuccess, onError)),
          (error) => onError && onError(error)
        );
      }
    } else {
      if ([PAYMENT_TYPES.INVOICE].indexOf(method) !== -1) {
        apiSuppliersAddInvoicePaymentMethodOnOrder(
          order.id,
          '',
          {},
          (order) => dispatch(refreshSuppliersOrder(order, onSuccess, onError)),
          (error) => onError && onError(error)
        );
      }

      if ([PAYMENT_TYPES.CASH, PAYMENT_TYPES.CHECK, PAYMENT_TYPES.COD].indexOf(method) !== -1) {
        apiSuppliersAddInvoicePaymentMethodOnOrder(
          order.id,
          method,
          {},
          (order) => dispatch(refreshSuppliersOrder(order, onSuccess, onError)),
          (error) => onError && onError(error)
        );
      }

      if ([PAYMENT_TYPES.CASH, PAYMENT_TYPES.CHECK, PAYMENT_TYPES.COD, PAYMENT_TYPES.INVOICE].indexOf(method) === -1) {
        apiSuppliersAddInvoicePaymentMethodOnOrder(
          order.id,
          '',
          { paymentMethodId: method },
          (order) => dispatch(refreshSuppliersOrder(order, onSuccess, onError)),
          (error) => onError && onError(error)
        );
      }
    }
  };
}

export function orderSuppliersAttachCustomer(order, customer, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });
    apiSuppliersAddCustomerOnOrder(
      order.id,
      { id: customer.id },
      (order) => dispatch(refreshSuppliersOrder(order, onSuccess, onError)),
      (error) => {
        dispatch({ type: ORDER_LOADED });
        onError && onError(error);
      }
    );
  };
}

export function orderSuppliersDetachCustomer(order, customer, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });
    apiSuppliersRemoveCustomerFromOrder(
      order.id,
      (order) => dispatch(refreshSuppliersOrder(order, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}

export function orderSuppliersChangeCustomerAddress(order, customer, addressId, addressType, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: ORDER_LOADING });

    const data = {
      id: customer.id,
      [`${addressType}Address`]: { id: addressId },
    };

    apiSuppliersAddCustomerOnOrder(
      order.id,
      data,
      (order) => dispatch(refreshSuppliersOrder(order, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}
