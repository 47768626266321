export default class HasSubstringFilter {

  constructor(value, propertyName) {
    this.value = value;
    this.property = propertyName;
  }

  isSatisfy(row) {
    let satisfy = true;
    if (this.value !== "" && row[this.property].toLowerCase().indexOf(this.value.toLowerCase()) === -1) {
      satisfy = false;
    }
    return satisfy;
  }
}