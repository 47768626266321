import {COLORS} from "../../helpers";

export default ({
  close: {
    position: 'absolute',
    top: 21,
    right: 26,
    zIndex: 1000,
    cursor: 'pointer'
  },
  wrapper: {
    display: 'flex',
    minHeight: 200,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  inputWrapper: {
    marginBottom: 20,
    '&:last-of-type': {
      marginBottom: 0
    },
    width: '100%'
  },
  error: {
    width: '100%',
    marginBottom: 20
  },
  message: {
    display: 'inline-block',
    padding: 20,
    fontSize: 18,
    color: COLORS.text,
    fontWeight: 300,
    fontFamily: "Roboto, sans-serif",
  }
});
