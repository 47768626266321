import React from "react";
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

const styles = {
  image: {
    height: '40px'
  },
};

const propTypes = {
  lineItem: PropTypes.object.isRequired
};

const ReceiveImage = ({ classes,
                        lineItem }) => {
  return (
    <React.Fragment>
      {(lineItem.received && lineItem.received.image) ? (
        <div className={classes.imageBox}>
          <img className={classes.image}
               alt={"Line item"}
               src={lineItem.received.image.src} />
        </div>
      ) : null}
    </React.Fragment>
  );
};

ReceiveImage.propTypes = propTypes;

export default withStyles(styles)(
  ReceiveImage
);