import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import styles from './styles';
import { COLORS } from '../../helpers';
import CircularProgress from '@material-ui/core/CircularProgress';

const propTypes = {
  desc: PropTypes.any,
  isLoading: PropTypes.bool,
};

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiButton: {
      root: {
        fontFamily: "'Roboto', sans-serif",
        fontWeight: 400,
        textTransform: 'uppercase',
        fontSize: 12,
        background: '#363636',
        borderRadius: 6,
        border: 0,
        color: 'white',
        height: 37,
        padding: '0 37px !important',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.16)',
        minWidth: 200,
        '&:hover, &:active, &:focus': {
          backgroundColor: '#666666',
        },
        '&$disabled': {
          opacity: '0.6',
          backgroundColor: '#363636',
          color: COLORS.white,
        },
      },
      label: {
        fontWeight: '400',
      },
    },
  },
});

const PrimaryButton = (props) => {
  const { children, desc, isLoading, classes, className, labelClass, ...other } = props;

  let wrapperClasses = {};
  wrapperClasses[classes.descWrapper] = !!desc;
  if (className) {
    wrapperClasses[className] = true;
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Button className={classNames(wrapperClasses)} classes={{ label: labelClass }} disabled={isLoading} {...other}>
        {children}
        {desc && <span className={classes.desc}>{desc}</span>}
        {isLoading && <CircularProgress className={classes.spinner} size={20} />}
      </Button>
    </MuiThemeProvider>
  );
};

PrimaryButton.propTypes = propTypes;

export default withStyles(styles)(PrimaryButton);
