const validation = {
  quantity: {
    presence: {
      message: '^validation.addToCart.enterQuantity'
    }
  },
  unitPrice: {
    presence: {
      message: '^validation.addToCart.enterUnitPrice'
    },
    numericality: {
      onlyInteger: false,
      message: "^validation.addToCart.invalidUnitPrice"
    }
  },
};

export default validation;