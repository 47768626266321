import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../hocs';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import {
  AdminDialog,
  AdminDialogTitle,
  AdminDialogContent,
  AdminDialogControls,
  PrimaryButton,
  PrimaryTextField,
  PrimarySelect,
  Spinner,
} from '../../components';
import { weightToSystem, toCoins, validateField } from '../../helpers';
import validationRules from './validation';
import styles from './styles';

const propTypes = {
  producer: PropTypes.object.isRequired,
  onDone: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

const defaultProps = {
  loading: false,
};

class AddCustomItemDialog extends React.Component {
  constructor(props) {
    super(props);

    const { producer } = props;
    const { units } = producer;
    const unitsArray = Object.keys(units);

    this.state = {
      loading: false,

      name: '',
      unit: (unitsArray && unitsArray[0]) || '',
      quantity: '',
      unitPrice: '',
      note: '',
      isLoading: false,

      quantityError: '',
      unitPriceError: '',
    };
  }

  validate = () => {
    const nameError = validateField(validationRules, 'name', this.state.name);
    const unitError = validateField(validationRules, 'unit', this.state.unit);
    const quantityError = validateField(validationRules, 'quantity', this.state.quantity);
    const unitPriceError = validateField(validationRules, 'unitPrice', this.state.unitPrice);

    if (!nameError && !unitError && !quantityError && !unitPriceError) {
      this.setState({
        nameError: '',
        unitError: '',
        quantityError: '',
        unitPriceError: '',
        requestError: '',
        isLoading: true,
      });

      this.props.onDone({
        name: this.state.name,
        unit: this.state.unit,
        quantity: weightToSystem(this.state.quantity),
        unitPrice: toCoins(parseFloat(this.state.unitPrice)),
        note: this.state.note,
      });
    } else {
      this.setState({
        nameError: nameError,
        unitError: unitError,
        quantityError: quantityError,
        unitPriceError: unitPriceError,
        isLoading: false,
      });
    }
  };

  render() {
    const { onClose, intl, classes, loading, producer } = this.props;
    const { isLoading } = this.state;

    return (
      <AdminDialog open closeButton onClose={onClose}>
        <AdminDialogTitle title={intl.formatMessage({ id: 'global.customItem' })} />
        <AdminDialogContent className={classes.wrapper}>
          {loading && <Spinner size={50} />}

          <PrimaryTextField
            className={classes.input}
            error={!!this.state.nameError}
            onChange={(event) => this.setState({ name: event.target.value })}
            value={this.state.name}
            id="quantity-input"
            label={intl.formatMessage({ id: 'global.name' })}
            type="text"
            InputLabelProps={{ shrink: true }}
            margin="normal"
            helperText={this.state.nameError && intl.formatMessage({ id: this.state.nameError })}
            fullWidth
          />

          <PrimarySelect
            fullWidth
            className={classNames(classes.input, classes.select)}
            formControlClassName={classes.selectFormControl}
            error={!!this.state.unitError}
            value={this.state.unit}
            label={intl.formatMessage({ id: 'global.unit' })}
            onChange={(e) => this.setState({ unit: e.target.value })}>
            {Object.keys(producer.units).map((unit) => (
              <MenuItem key={unit} value={unit}>
                {unit}
              </MenuItem>
            ))}
          </PrimarySelect>

          <PrimaryTextField
            className={classes.input}
            error={!!this.state.quantityError}
            onChange={(event) => this.setState({ quantity: event.target.value.trim() })}
            value={this.state.quantity}
            id="quantity-input"
            label={intl.formatMessage({ id: 'global.quantity' })}
            type="text"
            autoComplete="off"
            InputLabelProps={{ shrink: true }}
            margin="normal"
            InputProps={{
              classes: { input: classes.inputText },
              endAdornment: (
                <InputAdornment position="end">
                  <Typography classes={{ root: classes.nowrap }} color="textSecondary">
                    {this.state.unit}
                  </Typography>
                </InputAdornment>
              ),
            }}
            helperText={this.state.quantityError && intl.formatMessage({ id: this.state.quantityError })}
            fullWidth
          />

          <PrimaryTextField
            className={classes.input}
            error={!!this.state.unitPriceError}
            onChange={(event) => this.setState({ unitPrice: event.target.value.trim() })}
            value={this.state.unitPrice}
            id="unit-price-input"
            autoComplete="off"
            margin="normal"
            label={intl.formatMessage({ id: 'global.unitPrice' })}
            type="text"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                input: classes.inputText,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <Typography classes={{ root: classes.nowrap }} color="textSecondary">
                    {this.state.unit}
                  </Typography>
                </InputAdornment>
              ),
            }}
            helperText={this.state.unitPriceError && intl.formatMessage({ id: this.state.unitPriceError })}
            fullWidth
          />

          <PrimaryTextField
            className={classes.input}
            onChange={(event) => this.setState({ note: event.target.value })}
            value={this.state.note}
            id="note"
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            label={intl.formatMessage({ id: 'order.lineItemNote' })}
            type="text"
            fullWidth
          />

          <AdminDialogControls>
            <PrimaryButton className={classes.button} onClick={this.validate} isLoading={isLoading}>
              {intl.formatMessage({ id: 'global.addToCart' })}
            </PrimaryButton>
          </AdminDialogControls>
        </AdminDialogContent>
      </AdminDialog>
    );
  }
}

AddCustomItemDialog.propTypes = propTypes;
AddCustomItemDialog.defaultProps = defaultProps;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(AddCustomItemDialog)));
