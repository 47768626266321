import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl'
import {withStyles} from '@material-ui/core/styles';
import {
  AdminDialog,
  AdminDialogTitle,
  AdminDialogContent,
  PrimaryButton,
} from '../../components';
import {
  DXFiltersManager,
  DXCustomerFilter,
  DXShippingFilter,
  DXShippingTypeFilter,
  DXOrderStatusFilter,
  DXOrderSourceFilter,
  DXOrderPaymentStatusFilter,
  DXCustomerTypeFilter,
  DXPaymentMethodFilter,
  DXOrderBookingStatusFilter,
  DXOrderTypeFilter,
  DXOrderLocationFilter
} from '../../components/AdminDXTable/Filters'
import styles from './styles'

const propTypes = {
  openWithFilter: PropTypes.object,
  appliedFilters: PropTypes.array,
  onApplyFilters: PropTypes.func,
  onClose: PropTypes.func
};

const defaultProps = {
  appliedFilters: [],
};

class OrdersFilterDialog extends React.Component {

  constructor(props) {
    super(props);

    this.filters = [
      DXCustomerFilter,
      DXShippingFilter,
      DXShippingTypeFilter,
      DXOrderStatusFilter,
      DXOrderSourceFilter,
      DXOrderPaymentStatusFilter,
      DXCustomerTypeFilter,
      DXPaymentMethodFilter,
      DXOrderBookingStatusFilter,
      DXOrderTypeFilter,
      DXOrderLocationFilter,
    ];
  }

  render() {
    const {
      intl,
      classes,
      onClose,
      onApplyFilters,
      openWithFilter,
      appliedFilters,
    } = this.props;

    return (
      <AdminDialog open
                   closeButton
                   onClose={onClose}>
        <AdminDialogTitle title={intl.formatMessage({id: 'global.addFilter'})} />
        <AdminDialogContent className={classes.wrapper}>

          <DXFiltersManager filters={this.filters}
                            onRef={filters => this.filtersManager = filters}
                            openWithFilter={openWithFilter}
                            appliedFilters={appliedFilters}
                            defaultFilterName={'customer'}
          />

          <div className={classes.controls}>
            <PrimaryButton onClick={() => onApplyFilters(this.filtersManager.getAppliedFilters())}>
              {intl.formatMessage({id: 'global.apply'})}
            </PrimaryButton>
          </div>
        </AdminDialogContent>
      </AdminDialog>
    );
  }
}

OrdersFilterDialog.propTypes = propTypes;
OrdersFilterDialog.defaultProps = defaultProps;

export default withStyles(styles, {withTheme: true})(
  injectIntl(OrdersFilterDialog)
);
