import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

RichTextEditor.propTypes = {
  onSave: PropTypes.func,
  value: PropTypes.string,
};

const RICH_TEXT_TOOLBAR = {
  options: ['inline', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'remove', 'history'],
  inline: {
    options: ['bold', 'italic', 'underline', 'strikethrough'],
  },
};

const useStyles = makeStyles(styles);

function RichTextEditor(props) {
  const { state, onChange } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Editor
        disabled
        toolbar={RICH_TEXT_TOOLBAR}
        editorState={state}
        toolbarClassName={classes.toolbar}
        wrapperClassName={classes.wrapper}
        editorClassName={classes.editor}
        onEditorStateChange={onChange}
      />
    </div>
  );
}

export default RichTextEditor;
