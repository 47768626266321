String.indexOfAny = function (s, arr, begin) {
  let minIndex = -1;
  for (let i = 0; i < arr.length; i++) {
    let index = s.indexOf(arr[i], begin);
    if (index !== -1) {
      if (minIndex === -1 || index < minIndex) {
        minIndex = index;
      }
    }
  }
  return (minIndex);
};

String.splitByAny = function (s, arr) {
  let parts = [];
  let index;
  do {
    index = String.indexOfAny(s, arr);
    if (index !== -1) {
      parts.push(s.substr(0, index));
      s = s.substr(index + 1);
    } else {
      parts.push(s);
    }
  } while (index !== -1);

  return (parts);
};

export function parseGoogleFormatterAddress(address) {
  let obj = {
    formatted: "",
    streetNumber: "",
    address1: "",
    city: "",
    province: "",
    postalCode: "",
    country: ""
  };

  if(!address) {
    return (obj);
  }

  let parts = address.split(',');
  for(let i = 0; i < parts.length; i++) {
    parts[i] = parts[i].trim();
  }
  let i = parts.length - 1;

  let fnIsPostalCode = function(value) {
    return (/^\d+$/.test(value));
  };

  let fnParsePostalCode = function(value) {
    let subParts = String.splitByAny(value, [' ', '-']);
    for(let j = 0; j < subParts.length; j++) {
      if (fnIsPostalCode(subParts[j].trim())) {
        obj.postalCode = subParts[j].trim();
        if(j > 0) {
          return (subParts[j-1]);
        }
      }
    }
    return(value);
  };

  if(i >= 0) {
    if(fnIsPostalCode(parts[i])) {obj.postalCode = parts[i]; i--;}
    let part = fnParsePostalCode(parts[i]);
    if(part) {
      obj.country = part;
    }
    i--;
  }

  if(i >= 0) {
    if(fnIsPostalCode(parts[i])) {obj.postalCode = parts[i]; i--;}
    let part = fnParsePostalCode(parts[i]);
    if(part) {
      obj.province = part;
    }
    i--;
  }

  if(i >= 0) {
    if(fnIsPostalCode(parts[i])) {obj.postalCode = parts[i]; i--;}
    let part = fnParsePostalCode(parts[i]);
    if(part) {
      obj.city = part;
    }
    i--;
  }

  if(i >= 0) {
    parts = parts.slice(0, i + 1);
    obj.address1 = parts.join(', ');
    obj.streetNumber = obj.address1.replace(/(^\d+)(.+$)/i,'$1');
    obj.address1 = obj.address1.replace(obj.streetNumber, "").trim() + ` ${obj.streetNumber}`
  }

  obj.formatted = obj.formatted + (obj.address1 ? `${obj.address1}` : "");
  obj.formatted = obj.formatted + (obj.city ? `${obj.formatted ? ', ' : ""}${obj.city}` : "");
  obj.formatted = obj.formatted + (obj.province ? `${obj.formatted ? ', ' : ""}${obj.province}` : "");
  obj.formatted = obj.formatted + (obj.postalCode ? `${obj.formatted ? ', ' : ""}${obj.postalCode}` : "");
  obj.formatted = obj.formatted + (obj.country ? `${obj.formatted ? ', ' : ""}${obj.country}` : "");

  return(obj);
}

export function parseGooglePlace(data){
  let parsed = {
    phone: "",
    companyName: data.name || ""
  };

  let address = data.address_components;
  for (let i = 0; i < address.length; i++){
    switch (address[i].types[0]){
      case "street_number":
        parsed.street_number = address[i].long_name;
        break;
      case "route":
        parsed.street_name = address[i].long_name;
        break;
      case "locality":
        parsed.city = address[i].long_name;
        break;
      case "administrative_area_level_1":
        parsed.province = address[i].long_name;
        break;
      case "postal_code":
        parsed.zip = address[i].long_name;
        break;
      default:
        break;
    }
  }

  if (!parsed.city) {
    for (let i = 0; i < address.length; i++){
      switch (address[i].types[0]){
        case "sublocality_level_1":
          parsed.city = address[i].long_name;
          break;
        default:
          break;
      }
    }
  }

  parsed.address1 = (parsed.street_name || "") + " " + (parsed.street_number || "");

  return parsed;
}