import moment from 'moment'

class Shipping {

  constructor(props ={}) {
    this.date = props?.date
    this.type = props?.type
  }

  isDateInThePast() {
    if (!this.date) {
      throw new Error(`Shipping date is not defined`)
    }

    return moment(this.date).isBefore(moment())
  }

}

export default Shipping
