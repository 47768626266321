import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { getProducerData, getToken, isJWTExpired, getSupplierToken, getSupplierObject } from '../helpers';
import { signIn, signOut, makeInitialized, supplierSignOut, supplierSignIn } from './actions';
import { producer, customer, common, auth, draft, order, supplier } from './reducers';
import { apiGetProducer } from '../api';
import axios from 'axios';
import { setSupplier } from './actions/supplier';
import { auth as pickupAuth } from '../apps/pickups/store/reducers';
import { auth as deliveriesAuth } from '../apps/deliveries/store/reducers';
import { getPickupsUserToken, getPickupsUserObject } from '../apps/pickups/helpers';
import {
  makeInitialized as makeInitializedPickupApp,
  pickupsSignOut,
  pickupsSignIn,
  setPickupsUser,
} from '../apps/pickups/store/actions';
import { getDeliveriesUserToken } from '../apps/deliveries/helpers';
import {
  makeInitialized as makeInitializedDeliveriesApp,
  deliveriesSignOut,
  deliveriesSignIn,
} from '../apps/deliveries/store/actions';

const initialState = {};
const middleware = [thunk];
const enhancers = [];
const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const rootReducer = combineReducers({
  producer: producer,
  customer: customer,
  common: common,
  auth: auth,
  draft: draft,
  order: order,
  supplier: supplier,
  pickupsApp: pickupAuth,
  deliveriesApp: deliveriesAuth,
});

let store = {};
const configureStore = (onConfigured) => {
  store = createStore(rootReducer, initialState, composedEnhancers);

  /*if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./reducers', () => {
        store.replaceReducer(rootReducer);
      });
    }
  }*/

  axios.interceptors.response.use(undefined, function (error) {
    // Handling auth error
    if (error.response.status === 403) {
      store.dispatch(signOut());
      store.dispatch(makeInitialized());
      //return Promise.reject(error);
    }

    return Promise.reject(error);
  });

  // Suppliers app
  const supplierObj = getSupplierObject();
  const supplierToken = getSupplierToken();
  if (!!supplierToken && !isJWTExpired(supplierToken)) {
    store.dispatch(
      supplierSignIn({
        token: supplierToken,
      })
    );

    !!supplierObj && store.dispatch(setSupplier(supplierObj));
  } else {
    store.dispatch(supplierSignOut());
  }

  // Pickup app
  const pickupUserObj = getPickupsUserObject();
  const pickupUserToken = getPickupsUserToken();
  if (!!pickupUserToken && !isJWTExpired(pickupUserToken)) {
    store.dispatch(
      pickupsSignIn({
        token: pickupUserToken,
      })
    );

    !!pickupUserObj && store.dispatch(setPickupsUser(pickupUserObj));
    store.dispatch(makeInitializedPickupApp());
  } else {
    store.dispatch(pickupsSignOut());
  }

  // Deliveries app
  const deliveriesUserToken = getDeliveriesUserToken();
  if (!!deliveriesUserToken && !isJWTExpired(deliveriesUserToken)) {
    store.dispatch(
      deliveriesSignIn({
        token: deliveriesUserToken,
      })
    );

    store.dispatch(makeInitializedDeliveriesApp());
  } else {
    store.dispatch(deliveriesSignOut());
  }

  // Producer admin
  const token = getToken();
  if (token && !isJWTExpired(token)) {
    const producer = getProducerData();
    apiGetProducer(
      producer.id,
      (response) => {
        const onReady = () => {
          store.dispatch(makeInitialized());
          onConfigured && onConfigured();
        };
        store.dispatch(
          signIn(
            {
              token: token,
              producer: response,
            },
            onReady
          )
        );
      },
      () => {}
    );
  } else {
    store.dispatch(signOut());
    store.dispatch(makeInitialized());
  }

  return store;
};

export function getStore() {
  return store;
}

export default configureStore;
