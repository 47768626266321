import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {injectIntl} from 'react-intl'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

const styles = theme => ({
  wrap: {
    padding: 10,
    width: 300,
    margin: '0 15px 15px 0',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
})

class CustomerAddressBox extends Component {

  render () {
    const { intl, address, onEdit, onDelete, classes } = this.props

    return (
      <Paper className={classes.wrap}>
        <div>
          <Typography component="h6" variant="subtitle1">
            {address.address1}, {address.zip} {address.province ? ", " + address.province : ""}
          </Typography>

          {address.address2 && (
            <Typography component="h6" variant="subtitle1">
              {address.address2}
            </Typography>
          )}
          <Typography component="h6" variant="subtitle1" color='secondary' style={{opacity: address.default ? 1 : 0}}>
            ({intl.formatMessage({ id: 'global.default' })})
          </Typography>
        </div>
        <div className={classes.actions}>
          <IconButton onClick={() => onEdit(address)}>
            <EditIcon  color='secondary'/>
          </IconButton>
          <IconButton onClick={() => onDelete(address)}>
            <DeleteIcon color='secondary' />
          </IconButton>
        </div>
      </Paper>
    )
  }
}

CustomerAddressBox.propTypes = {
  address: PropTypes.object,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default withStyles(styles, { withTheme: true})(injectIntl(CustomerAddressBox))
