import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { setProducer } from '../../store/actions';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../hocs';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { apiGetAllSuppliers, apiGetSharedProperties, apiProductGet } from '../../api';
import { AdminLayout, Icon, Spinner, ErrorBox, Notification } from '../../components';
import { SidebarMenu } from '../Partials';
import HeaderMenu from '../Partials/HeaderMenu/HeaderMenu';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ProductView from './views/ProductView';
import VariantView from './views/VariantView';
import { validateField } from '../../helpers';
import validationRules from './validation';
import { apiAddProduct, apiEditProduct } from '../../api/product/product';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';

ProductScene.propTypes = {
  product: PropTypes.object,
};

const PAGE_STEP = {
  PRODUCT_EDIT: 'PRODUCT_EDIT',
  PRODUCT_ADD: 'PRODUCT_ADD',
  VARIANT_EDIT: 'VARIANT_EDIT',
  VARIANT_ADD: 'VARIANT_ADD',
};

function ProductScene(props) {
  const { match, classes, intl, history, producer } = props;

  const [step, setStep] = useState('');
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState({});
  const [suppliers, setSuppliers] = useState([]);
  const [file, setFile] = useState(null);
  const [types, setTypes] = useState([]);
  const [subTypes, setSubTypes] = useState([]);
  const [nameError, setNameError] = useState('');
  const [typeError, setTypeError] = useState('');
  const [subTypeError, setSubTypeError] = useState('');
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [subType, setSubType] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionWholesale, setDescriptionWholesale] = useState('');
  const [separateDescriptions, setSeparateDescriptions] = useState(false);
  const [translation, setTranslation] = useState('');
  const [supplier, setSupplier] = useState({ id: -1 });
  const [search, setSearch] = useState([]);
  const [variants, setVariants] = useState([]);
  const [error, setError] = useState('');
  const [variant, setVariant] = useState({});
  const [prevStep, setPrevStep] = useState('');
  const [productIsSaved, setProductIsSaved] = useState('');
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [hasUnsavedChangesVariant, setHasUnsavedChangesVariant] = useState(false);
  const [confirmGoBack, setConfirmGoBack] = useState(false);
  const [pageAsVariant, setPageAsVariant] = useState(false);

  useEffect(() => {
    initialChoosePage();
  }, []);

  useEffect(() => {
    !loading && setHasUnsavedChanges(true);
  }, [file, name, type, subType, description, descriptionWholesale, translation, supplier, search]);

  const initialChoosePage = () => {
    const id = match.params && match.params.id;
    const va = match.params && match.params.va;
    const type = id.split('_')[0];
    if (type === 'new-product') {
      setStep(PAGE_STEP.PRODUCT_ADD);
      loadProductsProperties();
      setLoading(false);
    }
    if (type === 'pr' && !va) {
      loadProduct(id);
      loadProductsProperties();
      setStep(PAGE_STEP.PRODUCT_EDIT);
    }
    if (va) {
      findVariant(id, va);
      loadProductsProperties();
      setStep(PAGE_STEP.VARIANT_EDIT);
      setPageAsVariant(true);
    }
  };

  const loadProduct = (
    productId,
    onSuccess = () => {
      setLoading(false);
    }
  ) => {
    apiProductGet(
      productId,
      (response) => {
        updateProductState(response);
        onSuccess(response);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const findVariant = (productId, variantId) => {
    loadProduct(productId, (responseProduct) => {
      if (responseProduct && responseProduct.variants && responseProduct.variants.length) {
        const searchedVariant = responseProduct.variants.find((va) => va.id === variantId);
        setVariant(searchedVariant);
        setLoading(false);
      }
    });
  };

  const loadSuppliers = () => {
    apiGetAllSuppliers(
      (rows) => {
        setSuppliers(rows);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const updateProductState = (newProduct) => {
    setName(newProduct.name || '');
    setType(newProduct.type);
    setSubType(newProduct.subType);
    setDescription(newProduct.description || '');
    setDescriptionWholesale(newProduct.wholesaleDescription || '');
    setSeparateDescriptions(!!newProduct.wholesaleDescription);
    setTranslation(newProduct.translation);
    setSupplier(newProduct.supplier || { id: -1 });
    setSearch(newProduct.search);
    setVariants(newProduct.variants || []);
    setProduct(newProduct);
  };

  const loadProductsProperties = () => {
    // Retrieving types from server
    retrieveSharedProductProperties('type', (types) => {
      // Retrieving sub types from server
      retrieveSharedProductProperties('subType', (subTypes) => {
        setTypes(types.map((type) => type.name));
        setSubTypes(subTypes.map((type) => type.name));
      });
    });

    loadSuppliers();
  };

  const retrieveSharedProductProperties = (type, onSuccess, onError) => {
    apiGetSharedProperties(
      {},
      type,
      (rows) => {
        onSuccess(rows);
      },
      onError
    );
  };

  const onUpdateVariant = (updatedVariant) => {
    setVariant(updatedVariant);
    setStep(PAGE_STEP.VARIANT_EDIT);
    setPrevStep(step);
  };
  const onCreateVariant = () => {
    setVariant({});
    setStep(PAGE_STEP.VARIANT_ADD);
    setPrevStep(step);
  };

  const preValidate = () => {
    if (step === PAGE_STEP.PRODUCT_EDIT || step === PAGE_STEP.PRODUCT_ADD) {
      if (variants.length) {
        setError('');
        saveProduct();
      } else {
        setError(intl.formatMessage({ id: 'messages.needToCreateVariant' }));
      }
    }
  };

  const saveProduct = () => {
    const nameError = validateField(validationRules, 'name', name);
    const typeError = validateField(validationRules, 'type', type);
    const subTypeError = validateField(validationRules, 'subType', subType);

    if (!nameError && !typeError && !subTypeError) {
      setNameError('');
      setTypeError('');
      setSubTypeError('');
      setLoading(true);

      let data = {
        name,
        type,
        subType,
        translation,
        search,
        description,
        //send
        wholesaleDescription: descriptionWholesale,
        // supplier,
        ...(!!supplier?.id && supplier.id !== -1 ? { supplierId: supplier.id } : {}),
      };

      if (file) {
        data.image = file.split(',').pop();
      }

      if (product.id) {
        apiEditProduct(product.id, data, () => {
          setLoading(false);
          productSaved();
        });
      } else {
        data.variants = variants;
        apiAddProduct(
          data,
          (response) => {
            setLoading(false);
            setStep(PAGE_STEP.PRODUCT_EDIT);
            setProduct(response);
            setVariants(response.variants);
            history.replace(`/product/${response.id}`);
            setPrevStep('');
            productSaved();
          },
          handleError
        );
      }
    } else {
      setNameError(nameError);
      setTypeError(typeError);
      setSubTypeError(subTypeError);
      setLoading(false);
    }
  };

  const handleError = (error) => {
    const errorMessage = getError(error);
    setError(errorMessage);
    setLoading(false);
  };

  const getError = (error) => {
    const somethingWentWrong = intl.formatMessage({ id: 'global.somethingWentWrong' });
    if (!error?.response?.data?.code) setError(somethingWentWrong);

    const code = error?.response?.data?.code;
    const message = error?.response?.data?.message || somethingWentWrong;
    let errorMessage;

    switch (code) {
      case 4021: {
        errorMessage = message;
        break;
      }

      case 2055: {
        errorMessage = message;
        break;
      }

      default: {
        errorMessage = somethingWentWrong;
      }
    }

    return errorMessage;
  };

  const handleBackButton = (force = false) => {
    setConfirmGoBack(false);

    if (!!prevStep) {
      if (hasUnsavedChangesVariant && !force) {
        setConfirmGoBack(true);
        return false;
      }
      setStep(prevStep);
      setHasUnsavedChangesVariant(false);
    } else {
      if (hasUnsavedChanges && !force) {
        setConfirmGoBack(true);
        return false;
      }
      history.goBack();
    }
    setPrevStep('');
    setError('');
  };

  const productUpdate = (newProduct) => {
    updateProductState(newProduct);

    if (!!prevStep) {
      setStep(prevStep);
    } else {
      history.goBack();
    }
    setPrevStep('');
  };

  const variantToNewProduct = (newVariant) => {
    if (step === PAGE_STEP.VARIANT_ADD) {
      let updateVariants = [...variants, newVariant];
      setVariants(updateVariants);
      setProduct({ ...product, variants: updateVariants });
    } else {
      // on edit variant
      const indexToEdit = variants.findIndex((itemVariant) => itemVariant.id === newVariant.id);
      if (indexToEdit !== -1) {
        let editedVariants = [...variants];
        editedVariants.splice(indexToEdit, 1, newVariant);
        setVariants(editedVariants);
      } else {
        setVariants([...variants, newVariant]);
      }
    }
    setPrevStep('');
    setStep(prevStep);
  };

  const productSaved = (isVariant = false) => {
    const text = isVariant
      ? intl.formatMessage({ id: 'global.variantIsSaved' })
      : intl.formatMessage({ id: 'global.productIsSaved' });
    setProductIsSaved(text);

    setHasUnsavedChanges(false);
  };

  return (
    <AdminLayout sidebarComponent={SidebarMenu} headerMenuComponent={HeaderMenu}>
      {loading && <Spinner size={60} />}

      <div className={classes.container}>
        <div className={classes.productHeaderBackButton}>
          <IconButton onClick={() => handleBackButton()} className={classes.iconButton}>
            <Icon icon={ArrowBackIosIcon} className={classes.icon} />
          </IconButton>
        </div>
        <div className={classes.productHeaderLeftSideWrapper}>
          {!!error && (
            <div className={classes.errorWrapper}>
              <ErrorBox error={error} />
            </div>
          )}
          {!loading && (
            <div className={classes.productMainContent}>
              {(step === PAGE_STEP.PRODUCT_ADD || step === PAGE_STEP.PRODUCT_EDIT) && (
                <ProductView
                  intl={intl}
                  product={product}
                  file={file}
                  setFile={setFile}
                  setName={setName}
                  name={name}
                  nameError={nameError}
                  type={type}
                  setType={setType}
                  typeError={typeError}
                  types={types}
                  subType={subType}
                  subTypeError={subTypeError}
                  setSubType={setSubType}
                  subTypes={subTypes}
                  setDescription={setDescription}
                  description={description}
                  setDescriptionWholesale={setDescriptionWholesale}
                  descriptionWholesale={descriptionWholesale}
                  separateDescriptions={separateDescriptions}
                  setSeparateDescriptions={setSeparateDescriptions}
                  setTranslation={setTranslation}
                  suppliers={suppliers}
                  supplier={supplier}
                  setSupplier={setSupplier}
                  translation={translation}
                  search={search}
                  setSearch={setSearch}
                  variants={variants}
                  onUpdateVariant={onUpdateVariant}
                  onCreateVariant={onCreateVariant}
                  preValidate={preValidate}
                  hasUnsavedChanges={hasUnsavedChanges}
                  loading={loading}
                />
              )}

              {(step === PAGE_STEP.VARIANT_EDIT || step === PAGE_STEP.VARIANT_ADD) && (
                <VariantView
                  product={product}
                  intl={intl}
                  variant={variant}
                  producer={producer}
                  setError={setError}
                  onDone={productUpdate}
                  onDoneNewProduct={variantToNewProduct}
                  notifySaved={productSaved}
                  getError={getError}
                  // If we open variant directly we need to handle changes only for variant
                  setUnsavedChanges={pageAsVariant ? setHasUnsavedChanges : setHasUnsavedChangesVariant}
                  hasUnsavedChanges={pageAsVariant ? hasUnsavedChanges : hasUnsavedChangesVariant}
                />
              )}
            </div>
          )}
        </div>

        {productIsSaved && (
          <Notification
            message={productIsSaved}
            onClose={() => {
              setProductIsSaved('');
            }}
          />
        )}

        {confirmGoBack && (
          <ConfirmDialog
            onClose={() => setConfirmGoBack(false)}
            loading={false}
            confirmTitle={'product.confirm.button'}
            title={intl.formatMessage({ id: 'product.confirm.title' })}
            message={intl.formatMessage({ id: 'product.confirm.message' })}
            onConfirm={() => {
              handleBackButton(true);
            }}
          />
        )}
      </div>
    </AdminLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    producer: state.producer.object,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProducer: (producer) => dispatch(setProducer(producer)),
  };
};

export default withStyles(styles)(withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(ProductScene))));
