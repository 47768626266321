class MinimalError {
  constructor(props) {
    this.code = props?.response?.data?.code || props?.data?.code || props?.code || 0;
    this.message = props?.response?.data?.message || props?.data?.message || props?.message || '';
  }
  getError() {
    return this;
  }
  getCode() {
    return this.code;
  }
  getMessage() {
    return this.message;
  }
  hasError() {
    return !!this.message;
  }
}
export default MinimalError;
