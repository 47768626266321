import React, { useEffect, useState } from 'react';
import { Title, HeaderArea, Footer, ShippingItemsViewComponent, FewButtonsComponent } from '../../components';
import { makeStyles } from '@material-ui/core/styles';
import {
  SHIPPING_ITEMS_VIEW_TYPES,
  ORDER_DATE_FORMAT,
  PADDING_CONTENT,
  PADDING_CONTENT_TOP,
} from '../../constants/globals';
import { useIntl } from 'react-intl';
import { FONT_CART_VARIANT_TITLE, FONT_SUB_TITLE } from '../../constants/fonts';
import { COLORS } from '../../constants';
import { apiGetShippingItems } from '../../api';
import moment from 'moment';
import { withRouter } from '../../hocs';
import { useDispatch, useSelector } from 'react-redux';
import { setDeliveriesLoading } from '../../store/actions';
import { getObjectFromStorage, saveObjectToStorage } from '../../helpers';
import { LocationsSkeleton } from '../../components/SkeletonTemplates';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  content: {
    paddingLeft: PADDING_CONTENT,
    paddingRight: PADDING_CONTENT,
    paddingTop: PADDING_CONTENT_TOP,
  },
  title: {
    marginBottom: 10,
  },
  subTitle: {
    ...FONT_SUB_TITLE,
    marginBottom: 20,
  },
  buttonWrapper: {
    marginBottom: 20,
    paddingLeft: PADDING_CONTENT,
    paddingRight: PADDING_CONTENT,
  },
  error: {
    ...FONT_CART_VARIANT_TITLE,
    color: COLORS.danger,
    paddingBottom: 20,
    paddingTop: 20,
  },
  viewSwitcherWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
  },
  viewSwitcher: {
    marginRight: 20,
  },
  viewSwitcherActive: {
    backgroundColor: COLORS.primary,
    marginRight: 20,
    '&:hover': {
      backgroundColor: COLORS.primary,
      opacity: 0.8,
    },
  },
}));

const LocationsScene = (props) => {
  const intl = useIntl();
  const classes = useStyles();
  const { day } = props.params;

  const [items, setItems] = useState([]);
  const [viewType, setViewType] = useState(
    getObjectFromStorage('deliveriesApp:shippingItemsViewType') || SHIPPING_ITEMS_VIEW_TYPES.LITE
  );
  const isLiteView = viewType === SHIPPING_ITEMS_VIEW_TYPES.LITE;
  const [error, setError] = useState('');
  const [initialLoading, setInitialLoading] = useState(true);
  const dispatch = useDispatch();
  const onLoading = (isLoading) => dispatch(setDeliveriesLoading(isLoading));
  const { loading } = useSelector((state) => {
    return {
      loading: state.deliveriesApp.loading,
    };
  });

  useEffect(
    () => {
      onLoading(false);
      getShippingItems();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewType]
  );

  const onSetViewType = (newViewType) => {
    if (newViewType !== viewType) {
      setInitialLoading(true);
      setItems([]);
    }
    saveObjectToStorage('deliveriesApp:shippingItemsViewType', newViewType);
    setViewType(newViewType);
  };

  const getShippingItems = () => {
    setInitialLoading(true);
    setItems([]);
    const params = {
      limit: 1000,
      'shipping:from': moment(day).startOf('day').toISOString(true),
      'shipping:to': moment(day).endOf('day').toISOString(true),
      sort: ['customer.companyName:asc', 'customer.firstName:asc'],
    };

    if (!isLiteView) {
      params['withOrders'] = true;
    }

    apiGetShippingItems(
      params,
      (response) => {
        setItems(response);
        setInitialLoading(false);
      },
      (error) => {
        setInitialLoading(false);
        setError(error?.response?.data?.message || intl.formatMessage({ id: 'deliveries.somethingWentWrong' }));
      }
    );
  };

  const drawDate = (date) => {
    return moment.parseZone(date).format(ORDER_DATE_FORMAT);
  };

  if (loading) {
    return null;
  }

  return (
    <div className={classes.root}>
      <HeaderArea
        onlyBackButton
        rightSideContent={() => {
          return (
            <FewButtonsComponent
              buttons={[
                {
                  label: intl.formatMessage({ id: 'deliveries.customers.liteView' }),
                  action: () => onSetViewType(SHIPPING_ITEMS_VIEW_TYPES.LITE),
                  isActive: isLiteView,
                },
                {
                  label: intl.formatMessage({ id: 'deliveries.customers.treeView' }),
                  action: () => onSetViewType(SHIPPING_ITEMS_VIEW_TYPES.TREE),
                  isActive: !isLiteView,
                },
              ]}
            />
          );
        }}
      />

      {!initialLoading ? (
        <div className={classes.content}>
          <Title className={classes.title} text={intl.formatMessage({ id: 'deliveries.customers.title' })} />
          <p className={classes.subTitle}>{drawDate(day)}</p>

          {!!error && <p className={classes.error}>{error}</p>}
          {!error && <ShippingItemsViewComponent items={items} day={day} viewType={viewType} />}
        </div>
      ) : (
        <LocationsSkeleton isLiteView={isLiteView} />
      )}
      <Footer />
    </div>
  );
};

export default withRouter(LocationsScene);
