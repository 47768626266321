import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { injectIntl } from 'react-intl'
import Chip from '@material-ui/core/Chip'
import DXFilterConfig from '../DXFilterConfig'
import constants from '../../constants'
import CustomersSuggestions from '../../../../CustomersSuggestions/CustomersSuggestions'

const styles = ({
  wrapper: {
  },
  chips: {
    display: 'block',
    width: '100%',
    margin: '20px 0 0 0',
    textAlign: 'left',
  },
  chip: {
    margin: 5,
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row'
  },
  column: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  mr20: {
    marginRight: 20
  }
});

const propTypes = {
  shared: PropTypes.object,
  selected: PropTypes.object,
  equality: PropTypes.string,
  producer: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
const defaultProps = {
  selected: {},
};

class DXCustomerFilter extends React.Component {

  handleValueAdd = (customer) => {
    let customers = this.getValuesCopy();
    customers[customer.id] = customer.customerName;
    this.props.onChange(customers)
  };

  handleValueDelete = (id) => {
    let customers = this.getValuesCopy();
    delete customers[id];
    this.props.onChange(customers);
  };

  getValuesCopy = () => {
    let copy = {};
    Object.keys(this.props.selected).forEach((key) => {
      copy[key] = this.props.selected[key]
    });
    return copy;
  };

  getValue = (id) => {
    return this.props.selected[id]
  };

  getValuesView = () => {
    const { classes, selected } = this.props;
    const keys = Object.keys(selected || {});

    return (
      <React.Fragment>
        {!!keys.length && (
          <div className={classes.chips}>
            {keys.map((id) => (
              <Chip
                key={id}
                label={this.getValue(id)}
                className={classes.chip}
                onDelete={() => this.handleValueDelete(id)}
              />
            ))}
          </div>
        )}
      </React.Fragment>
    )
  };

  render() {
    const { intl, classes } = this.props;
    return (
      <React.Fragment>
        <div className={classes.row}>
          <div className={classes.column}>
            <div className={classes.wrapper}>
              <div className={classes.mr20}>
                <CustomersSuggestions selected={null}
                                      labelExtractor={item => item.customerName}
                                      inputLabel={intl.formatMessage({ id: 'global.customer' })}
                                      clearAfterSelect
                                      onDetach={() => {}}
                                      onAttach={(customer) => this.handleValueAdd(customer)}
                />
              </div>
            </div>
          </div>
          <div className={classes.column} />
        </div>
        {this.getValuesView()}
      </React.Fragment>
    )
  }
}

DXCustomerFilter.propTypes = propTypes;
DXCustomerFilter.defaultProps = defaultProps;
DXCustomerFilter.getLabel = (intl, selectedValues) => {
  let values = [];
  for (let key in selectedValues) {
    if (selectedValues.hasOwnProperty(key)) {
      values.push(selectedValues[key]);
    }
  }
  return values.join(', ');
};
DXCustomerFilter.applyToQueryParams = (queryParams, selectedValues, filter) => {
  queryParams['customerIds'] = Object.keys(selectedValues);
  if (filter.selectedEquality === constants.FILTER_EQUALITY_NOT_EQUALS) {
    queryParams['customerIds'] = queryParams['customerIds'].map(id => `ne-${id}`)
  }
  return queryParams;
};
DXCustomerFilter.getConfig = (intl, equality) => {
  return new DXFilterConfig({
    name: 'customer',
    selected: {},
    property: 'customerIds',
    title: equality === constants.FILTER_EQUALITY_NOT_EQUALS ?
      intl.formatMessage({id: 'filter.notEqualsCustomer'}) :
      intl.formatMessage({id: 'filter.customer'}),
    disabled: false,
    equalityStatus: true,
    selectedEquality: equality
  });
};

export default injectIntl(
  withStyles(styles)(DXCustomerFilter)
);
