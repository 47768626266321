import React, { useEffect, useState } from 'react';
import NavigationMenu from './components/NavigationMenu';
import HeaderArea from './components/HeaderArea';
import Title from './components/Title';
import { makeStyles } from '@material-ui/core/styles';
import { BUTTON_VARIANTS, PADDING_CONTENT, PADDING_CONTENT_TOP, PRODUCTS_PER_PAGE } from './constants/globals';
import { COLORS } from './constants/colors';
import { useIntl } from 'react-intl';
import ProductsList from './components/ProductsList';
import { apiSupplierGetListOfProducts } from '../../api';
import { PaginationMeta } from '../../entities/PaginationMeta';
import ProductPagination from './components/ProductPagination';
import PrimaryButton from './components/SupplierButton';
import AddOutlined from './assets/addOutlined';
import Unlisted from './assets/unlisted';
import Footer from './components/Footer';
import Spinner from './components/Spinner';
import { FONT_CART_VARIANT_TITLE } from './constants/fonts';
import classNames from 'classnames';
import ErrorToast from './components/ErrorToast';
import ActionToast, { TOAST_ACTIONS } from './components/ActionToast';
import SyncedDeliveryBox from './components/SyncedDeliveryBox';
import { isTouchableDevice } from './helpers/global';
import NumberKeyboard from './components/NumberKeyboard';
import { useSelector } from 'react-redux';
import { getSupplierParentObject } from '../../helpers';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  content: {
    paddingLeft: PADDING_CONTENT,
    paddingRight: PADDING_CONTENT,
    paddingTop: PADDING_CONTENT_TOP,
  },
  title: {
    marginBottom: 20,
  },
  buttonWrapper: {
    marginBottom: 20,
    paddingLeft: PADDING_CONTENT,
    paddingRight: PADDING_CONTENT,
  },
  error: {
    ...FONT_CART_VARIANT_TITLE,
    color: COLORS.danger,
    paddingBottom: 20,
    paddingTop: 20,
  },
  stickyBottomButton: {
    // position: 'sticky',
    bottom: 0,
    paddingBottom: 20,
    marginBottom: 0,
    backgroundColor: COLORS.white,
    paddingTop: 5,
  },
}));

const SupplierInventoryProductsScene = (props) => {
  const { active } = props;

  const intl = useIntl();
  const classes = useStyles();

  const [products, setProducts] = useState([]);
  const [orderedDOMIds, setOrderedDOMIds] = useState([]);
  const [meta, setMeta] = useState(new PaginationMeta());
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [unlistedCount, setUnlistedCount] = useState(0);
  const [toastAction, setToastAction] = useState('');
  const [parentProducer, setParentProducer] = useState(getSupplierParentObject());

  const { keyboardObj, popoverStep } = useSelector((state) => ({
    keyboardObj: state.supplier.keyboardObj,
    popoverStep: state.supplier.popoverStep,
  }));

  useEffect(() => {
    setParentProducer(getSupplierParentObject());
  }, [popoverStep]);

  // Load unlisted count
  useEffect(() => {
    active && getUnlisted();
  }, [active]);

  useEffect(() => {
    getProducts();
  }, [currentPage, active]);

  const getProducts = () => {
    setLoading(true);
    const data = {
      limit: PRODUCTS_PER_PAGE,
      skip: (currentPage - 1) * PRODUCTS_PER_PAGE,
      active: active,
    };

    apiSupplierGetListOfProducts(
      data,
      (response) => {
        setLoading(false);
        setMeta(response.meta);
        const sortedProducts = response.data.sort((prevProduct, nextProduct) => {
          let typeCompare = prevProduct.type > nextProduct.type;
          if (typeCompare) {
            return 1;
          }

          return prevProduct.subType > nextProduct.subType;
        });

        setOrderedDOMIds(makeOrderedDOMIds(sortedProducts));
        setProducts(sortedProducts);
        setParentProducer(getSupplierParentObject());
      },
      (error) => {
        setLoading(false);
        setError(error?.response?.data?.message || intl.formatMessage({ id: 'global.somethingWentWrong' }));
      }
    );
  };

  const getUnlisted = () => {
    apiSupplierGetListOfProducts(
      { limit: 1, skip: 0, active: false },
      (response) => {
        setUnlistedCount(response?.meta?.total);
      },
      undefined
    );
  };

  const onPageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const makeOrderedDOMIds = (products) => {
    const domIds = [];

    products.forEach((product) => {
      product.variants.forEach((variant) => {
        domIds.push(`${product.id}_${variant.id}`);
      });
    });
    return domIds;
  };

  return (
    <div className={classes.root}>
      <ErrorToast />
      <HeaderArea onlyBackButton={!active} />

      <ActionToast action={toastAction} onClose={() => setToastAction(TOAST_ACTIONS.empty)} />

      <div className={classes.content}>
        <Title
          className={classes.title}
          text={intl.formatMessage({ id: active ? 'supplier.inventory.title' : 'supplier.inventory.unlistedItems' })}
        />

        <SyncedDeliveryBox
          to={'/supplier/foodHubInfo'}
          image={parentProducer?.image?.thumbSrc}
          textComponent={({ textClass, boldTextClass }) => (
            <p className={textClass}>
              {intl.formatMessage({ id: 'supplier.foodHub.syncedWith' })}{' '}
              <span className={boldTextClass}>{parentProducer?.name || ''}.</span>
            </p>
          )}
        />

        {!!error && <p className={classes.error}>{error}</p>}
        {loading ? (
          <Spinner />
        ) : (
          <ProductsList products={products} unlisted={!active} setToastAction={setToastAction} />
        )}

        <ProductPagination meta={meta} page={currentPage} onChange={onPageChange} />
      </div>

      {!!active && (
        <>
          <div className={classNames([classes.buttonWrapper, classes.stickyBottomButton])}>
            <PrimaryButton
              link={'/supplier/inventory/product/new'}
              text={intl.formatMessage({ id: 'supplier.global.addItem' })}
              icon={() => <AddOutlined color={COLORS.white} />}
            />
          </div>
          <div className={classes.buttonWrapper}>
            <PrimaryButton
              link={'/supplier/inventory/unlisted'}
              text={intl.formatMessage({ id: 'supplier.global.unlisted' }, { amount: unlistedCount })}
              icon={() => <Unlisted color={COLORS.grey} />}
              variant={BUTTON_VARIANTS.grey}
            />
          </div>
        </>
      )}

      <NavigationMenu />
      <Footer />

      {keyboardObj.isOpen && isTouchableDevice() && (
        <NumberKeyboard
          currentItem={keyboardObj.itemToEdit}
          orderedDOMIds={orderedDOMIds}
          quantity={keyboardObj.initialQuantity}
          onChange={(newValue) => {
            keyboardObj.onChangeQuantity(newValue);
          }}
          onDone={(newValue, initValue) => {
            keyboardObj.onDoneAction(newValue, initValue);
          }}
        />
      )}
    </div>
  );
};

export default SupplierInventoryProductsScene;
