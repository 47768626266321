import axios from 'axios';
import { replaceUrlPlaceholder, replaceUrlPlaceholders, getRequestConfig, REQUEST_TYPES } from '../request';
import API_END_POINTS from '../endpoints';

/**
 * Retrieve drafts
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierRetrieveDrafts = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.SUPPLIER_DRAFT_ORDER_GET_ALL);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Retrieve draft
 * @param {string} id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierRetrieveDraft = function (id, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.SUPPLIER_DRAFT_ORDER_GET_ONE, 'dr', id);
  const config = getRequestConfig(REQUEST_TYPES.GET, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Creates draft order
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierCreateDraft = function (onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.SUPPLIER_DRAFT_ORDER_CREATE);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Add existing customer to draft
 * @param {string} draftOrderId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierAddCustomerOnDraftOrder = function (draftOrderId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.SUPPLIER_DRAFT_ORDER_ADD_CUSTOMER, 'dr', draftOrderId);
  const config = getRequestConfig(REQUEST_TYPES.PUT, url);

  // Set request params
  data = data || {};
  config.data = data;

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Remove customer from draft order
 * @param {string} draftOrderId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierRemoveCustomerFromDraftOrder = function (draftOrderId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.SUPPLIER_DRAFT_ORDER_REMOVE_CUSTOMER, 'dr', draftOrderId);
  const config = getRequestConfig(REQUEST_TYPES.DEL, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Adds variant to cart
 * @param {string} draftOrderId
 * @param {string} variantId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierAddVariantToDraft = function (draftOrderId, variantId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.SUPPLIER_DRAFT_ORDER_ADD_VARIANT, 'dr', draftOrderId);
  const config = getRequestConfig(REQUEST_TYPES.POST, url);

  // Set request params
  data = data || {};
  data.variantId = variantId;
  config.data = data;

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Adds custom line item
 * @param {string} draftOrderId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierAddCustomLineItemToDraft = function (draftOrderId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.SUPPLIER_DRAFT_ORDER_ADD_CUSTOM_LINE_ITEM, 'dr', draftOrderId);
  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  // Set request params
  data = data || {};
  config.data = data;

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Removes line item from draft
 * @param {string} draftOrderId
 * @param {string} lineItemId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierRemoveLineItemFromDraft = function (draftOrderId, lineItemId, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.SUPPLIER_DRAFT_ORDER_REMOVE_LINE_ITEM, {
    dr: draftOrderId,
    it: lineItemId,
  });
  const config = getRequestConfig(REQUEST_TYPES.DEL, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Updates line item of the draft order
 * @param {string} draftOrderId
 * @param {string} lineItemId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierUpdateDraftLineItem = function (draftOrderId, lineItemId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.SUPPLIER_DRAFT_ORDER_UPDATE_LINE_ITEM, {
    dr: draftOrderId,
    it: lineItemId,
  });
  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  // Set request params
  data = data || {};
  config.data = data;

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Adds shipping to draft order
 * @param {string} draftOrderId
 * @param {string} type delivery|pickup
 * @param {object} data
 * @param {string} deliveryCompanyId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierSetDraftShipping = function (draftOrderId, type, data, deliveryCompanyId, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.SUPPLIER_DRAFT_ORDER_SET_SHIPPING, {
    dr: draftOrderId,
    type: type,
  });
  const config = getRequestConfig(REQUEST_TYPES.PUT, url);
  // Set request params
  data = data || {};
  config.data = { ...data, type };

  axios(config)
    .then((shippingResponse) => {
      if (deliveryCompanyId) {
        apiSupplierDraftSetDeliveryCompany(
          draftOrderId,
          deliveryCompanyId,
          (deliveryCompanyResponse) => {
            onSuccess && onSuccess(deliveryCompanyResponse);
          },
          (error) => onError && onError(error)
        );
      } else {
        onSuccess && onSuccess(shippingResponse.data);
      }
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Adds draft order shipping as delivery
 * @param {string} draftOrderId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierSetDraftShippingAsDelivery = function (draftOrderId, data, onSuccess, onError) {
  apiSupplierSetDraftShipping(draftOrderId, 'delivery', data, onSuccess, onError);
};

/**
 * Adds draft order shipping as pickup
 * @param {string} draftOrderId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierSetDraftShippingAsPickup = function (draftOrderId, data, onSuccess, onError) {
  apiSupplierSetDraftShipping(draftOrderId, 'pickup', data, onSuccess, onError);
};

/**
 * Completes draft order
 * @param {string} draftOrderId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierCompleteDraftOrder = function (draftOrderId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.SUPPLIER_DRAFT_ORDER_COMPLETE, 'dr', draftOrderId);
  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Add note on draft
 * @param {string} draftId
 * @param {string} note
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierDraftAddNote = function (draftId, note, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.SUPPLIER_DRAFT_ORDER_ADD_NOTE, 'dr', draftId);
  const config = getRequestConfig(REQUEST_TYPES.PUT, url);
  config.data = {
    note: note,
  };

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Adds note for line item
 * @param {string} draftOrderId
 * @param {string} lineItemId
 * @param {string} note
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierDraftLineItemSetNote = function (draftOrderId, lineItemId, note, onSuccess, onError) {
  apiSupplierUpdateDraftLineItem(draftOrderId, lineItemId, { note: note }, onSuccess, onError);
};

/**
 * Set line item quantity
 * @param {string} draftOrderId
 * @param {string} lineItemId
 * @param {string} quantity
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierDraftLineItemSetQuantity = function (draftOrderId, lineItemId, quantity, onSuccess, onError) {
  apiSupplierUpdateDraftLineItem(draftOrderId, lineItemId, { quantity: quantity }, onSuccess, onError);
};

/**
 * Adds invoice payment method on draft order
 * @param {string} draftOrderId
 * @param {string} method
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierAddInvoicePaymentMethodOnDraftOrder = function (
  draftOrderId,
  method,
  data,
  onSuccess,
  onError
) {
  const url = replaceUrlPlaceholders(API_END_POINTS.SUPPLIER_DRAFT_ORDER_ADD_INVOICE_PAYMENT_METHOD, {
    dr: draftOrderId,
  });
  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  // Set request params
  data = data || {};
  data.method = method ? method : null;

  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Adds payment method on prepurchase draft order
 * @param {string} draftOrderId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierAddPrepurchasePaymentMethodOnDraftOrder = function (draftOrderId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.SUPPLIER_DRAFT_ORDER_ADD_PREPURCHASE_PAYMENT_METHOD, {
    dr: draftOrderId,
  });

  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  // Set request params
  data = data || {};
  config.data = data;

  axios(config)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Adds direct payment method on draft order
 * @param {string} draftOrderId
 * @param {string} method
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierAddDirectPaymentMethodOnDraftOrder = function (draftOrderId, method, data, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.SUPPLIER_DRAFT_ORDER_ADD_DIRECT_PAYMENT_METHOD, {
    dr: draftOrderId,
    md: method,
  });
  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  // Set request params
  data = data || {};
  config.data = data;

  axios(config)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Batch remove of drafts
 * @param {array} ids
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierDraftBulkRemove = function (ids, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.SUPPLIER_DRAFT_BULK_REMOVE);
  // Set request params
  config.data = {
    draftIds: ids,
  };

  axios(config)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Set delivery company
 * @param {string} draftId
 * @param {string} companyId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierDraftSetDeliveryCompany = function (draftId, companyId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.SUPPLIER_DRAFT_PUT_DELIVERY_COMPANY, 'dr', draftId);
  const config = getRequestConfig(REQUEST_TYPES.PUT, url);
  config.data = { id: companyId };
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Delete delivery company
 * @param {string} draftId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierDraftDeleteDeliveryCompany = function (draftId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.SUPPLIER_DRAFT_DELETE_DELIVERY_COMPANY, 'dr', draftId);
  const config = getRequestConfig(REQUEST_TYPES.DEL, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

export const apiSupplierDraftAddDirectDiscount = (draftId, data, onSuccess, onError) => {
  const url = replaceUrlPlaceholder(API_END_POINTS.SUPPLIER_DRAFT_ADD_DIRECT_DISCOUNT, 'dr', draftId);
  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

export const apiSupplierDraftDeleteDiscount = (draftId, onSuccess, onError) => {
  const url = replaceUrlPlaceholder(API_END_POINTS.SUPPLIER_DRAFT_DELETE_DISCOUNT, 'dr', draftId);
  const config = getRequestConfig(REQUEST_TYPES.DEL, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};
