import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { toCurrency } from '../../../../helpers';

export const getTreeChildRows = (intl, row, rootRows) => {
  // Orders
  if (row && row.rowType === 'csa') {
    let orders = [];

    row.orders &&
      row.orders.forEach((order) => {
        orders.push({
          ...order,
          ...{
            id: `${row.meta.prepurchase.id}-${order.name}`,
          },
          total: toCurrency(order.amountPrepurchased, intl),
          rowType: 'order',
        });
      });

    return orders;
  }

  // Line items
  if (row && row.rowType === 'order') {
    let lineItems = [];
    row.prepurchasedLineItems.forEach((item) => {
      lineItems.push({
        ...item,
        ...{
          id: `${row.id}-${item?.name?.product?.replace(' ', '').toLowerCase()}-${item?.name?.variant?.toLowerCase()}`,
        },
        name: `${item.name.product || ''} ${item.name.variant || ''}`,
        rowType: 'lineItem',
      });
    });
    return lineItems;
  }

  if (row && row.rowType === 'lineItem') {
    return null;
  }

  return rootRows;
};

export const prepareRowsAfterLoad = (rows) => {
  rows.forEach((row, index) => {
    rows[index]['id'] = row.meta.prepurchase.id;
    rows[index]['rowType'] = 'csa';
  });

  return {
    rows: rows,
    expandedGroups: [],
  };
};

export const getExpandedRowIds = (rows) => {
  let expanded = [];
  rows.forEach((row) => {
    // Preparing expanded rows
    expanded.push(`${row.meta.prepurchase.id}`);
    if (row.orders) {
      row.orders.forEach((order) => {
        const orderId = `${row.meta.prepurchase.id}-${order.name}`;
        expanded.push(orderId);
        order.prepurchasedLineItems.forEach((item) => {
          const lineItemId = `${row.id}-${item?.name?.product
            ?.replace(' ', '')
            .toLowerCase()}-${item?.name?.variant?.toLowerCase()}`;
          expanded.push(lineItemId);
        });
      });
    } else {
      row.orders = [];
    }
  });
  return expanded;
};

export const tableRowRenderer = (onClick, { row, ...props }) => {
  const getColor = (row) => {
    switch (row.rowType) {
      case 'csa': {
        return '#ffffff';
      }
      case 'order': {
        return 'rgba(224, 224, 224, 0.4)';
      }
      case 'lineItem': {
        return 'rgba(224, 224, 224, 0.8)';
      }
      default: {
        return '#ffffff';
      }
    }
  };

  return <Table.Row {...props} onClick={() => onClick && onClick(row)} style={{ backgroundColor: getColor(row) }} />;
};
