import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../../../hocs';
import { PrimaryTextField } from '../../../../components';
import { validateField } from '../../../../helpers';
import validationRules from './validation';
import styles from '../styles';

const propTypes = {
  initialData: PropTypes.object,
  onRef: PropTypes.func.isRequired,
};

class ShippingAddressForm extends React.Component {
  constructor(props) {
    super(props);
    this.props.onRef(this);

    const initialData = this.props.initialData || {};

    this.state = {
      address: initialData.address1 || '',
      city: initialData.city || '',
      state: initialData.province || '',
      zipCode: initialData.zip || '',

      addressError: '',
      cityError: '',
      stateError: '',
      zipCodeError: '',
    };
  }

  getTextField(fieldName) {
    const { classes, intl } = this.props;

    return (
      <PrimaryTextField
        className={classes.input}
        InputLabelProps={{
          classes: {
            root: classes.label,
          },
        }}
        autoComplete="no"
        error={!!this.state[`${fieldName}Error`]}
        onChange={(event) => {
          let value = event.target.value;
          this.setState((state) => {
            state[fieldName] = value;
            return state;
          });
        }}
        value={this.state[fieldName]}
        id={`${fieldName}-input`}
        label={'* ' + intl.formatMessage({ id: `global.${fieldName}` })}
        type="text"
        margin="normal"
        helperText={this.state[`${fieldName}Error`] && intl.formatMessage({ id: this.state[`${fieldName}Error`] })}
        fullWidth
      />
    );
  }

  validate = () => {
    const addressError = validateField(validationRules, 'address', this.state.address);
    const cityError = validateField(validationRules, 'city', this.state.city);
    const stateError = validateField(validationRules, 'state', this.state.state);
    const zipCodeError = validateField(validationRules, 'zipCode', this.state.zipCode);

    let validated = false;
    if (!addressError && !cityError && !stateError && !zipCodeError) {
      this.setState({
        addressError: '',
        cityError: '',
        stateError: '',
        zipCodeError: '',

        requestError: '',
        isLoading: true,
      });

      validated = true;
    } else {
      this.setState({
        addressError: addressError,
        cityError: cityError,
        stateError: stateError,
        zipCodeError: zipCodeError,
        isLoading: false,
      });
    }

    return {
      validated: validated,
      data: {
        address1: this.state.address,
        city: this.state.city,
        province: this.state.state,
        zip: this.state.zipCode,
      },
    };
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.wrapper}>
        {this.getTextField('address')}
        {this.getTextField('city')}
        {this.getTextField('state')}
        {this.getTextField('zipCode')}
      </div>
    );
  }
}

ShippingAddressForm.propTypes = propTypes;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(ShippingAddressForm)));
