import {
  apiSupplierRetrieveDraft,
  apiSupplierAddCustomerOnDraftOrder,
  apiSupplierRemoveCustomerFromDraftOrder,
  apiSupplierAddVariantToDraft,
  apiSupplierAddCustomLineItemToDraft,
  apiSupplierDraftAddNote,
  apiSupplierRemoveLineItemFromDraft,
  apiSupplierUpdateDraftLineItem,
  apiSupplierDraftLineItemSetQuantity,
  apiSupplierDraftLineItemSetNote,
  apiSupplierCompleteDraftOrder,
  apiSupplierSetDraftShipping,
  apiSupplierAddInvoicePaymentMethodOnDraftOrder,
  apiSupplierAddPrepurchasePaymentMethodOnDraftOrder,
  apiCustomerRetrieve,
  apiSupplierDraftDeleteDiscount,
  apiSupplierDraftDeleteDeliveryCompany,
} from '../../api';
import { DRAFT_SET, DRAFT_UNSET, DRAFT_LOADING, DRAFT_LOADED } from '../constants/draft';
import { PAYMENT_TYPES } from '../../helpers';

export function setSupplierDraft(id, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });
    apiSupplierRetrieveDraft(
      id,
      (draft) => dispatch(refreshSupplierDraft(draft, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}

export function refreshSupplierDraft(draft, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });
    if (draft.customer) {
      apiCustomerRetrieve(
        draft.customer,
        (customer) => {
          dispatch({
            type: DRAFT_SET,
            payload: {
              draft: draft,
              customer: customer,
            },
          });
          onSuccess && onSuccess(draft);
          dispatch({ type: DRAFT_LOADED });
        },
        (error) => onError && onError(error)
      );
    } else {
      dispatch({
        type: DRAFT_SET,
        payload: {
          draft: draft,
          customer: null,
        },
      });
      onSuccess && onSuccess(draft);
      dispatch({ type: DRAFT_LOADED });
    }
  };
}

export function unsetSupplierDraft() {
  return (dispatch) => {
    dispatch({ type: DRAFT_UNSET });
  };
}

export function supplierDraftAttachVariant(draft, product, variant, data, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });
    apiSupplierAddVariantToDraft(
      draft.id,
      variant.id,
      data,
      (draft) => dispatch(refreshSupplierDraft(draft, onSuccess, onError)),
      (error) => {
        dispatch({ type: DRAFT_LOADED });

        onError && onError(error);
      }
    );
  };
}

export function supplierDraftAttachCustomItem(draft, data, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });
    apiSupplierAddCustomLineItemToDraft(
      draft.id,
      data,
      (draft) => dispatch(refreshSupplierDraft(draft, onSuccess, onError)),
      (error) => {
        dispatch({ type: DRAFT_LOADED });

        onError && onError(error);
      }
    );
  };
}

export function supplierDraftChangeLineItemNote(draft, lineItem, note, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });
    apiSupplierDraftLineItemSetNote(
      draft.id,
      lineItem.id,
      note,
      (draft) => dispatch(refreshSupplierDraft(draft, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}

export function supplierDraftChangeLineItemQuantity(draft, lineItem, quantity, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });
    apiSupplierDraftLineItemSetQuantity(
      draft.id,
      lineItem.id,
      quantity,
      (draft) => dispatch(refreshSupplierDraft(draft, onSuccess, onError)),
      (error) => {
        dispatch({ type: DRAFT_LOADED });
        onError && onError(error);
      }
    );
  };
}

export function supplierDraftAddNote(draft, note, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });
    apiSupplierDraftAddNote(
      draft.id,
      note,
      (draft) => dispatch(refreshSupplierDraft(draft, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}

export function supplierDraftPatchLineItem(draft, lineItem, data, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });
    apiSupplierUpdateDraftLineItem(
      draft.id,
      lineItem.id,
      data,
      (draft) => dispatch(refreshSupplierDraft(draft, onSuccess, onError)),
      (error) => {
        dispatch({ type: DRAFT_LOADED });

        onError && onError(error);
      }
    );
  };
}

export function supplierDraftDetachLineItem(draft, lineItem, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });
    apiSupplierRemoveLineItemFromDraft(
      draft.id,
      lineItem.id,
      (draft) => dispatch(refreshSupplierDraft(draft, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}

export function supplierDraftAttachCustomer(draft, customer, addresses, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });

    apiSupplierAddCustomerOnDraftOrder(
      draft.id,
      { id: customer.id },
      (draft) => dispatch(refreshSupplierDraft(draft, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}

export function supplierDraftChangeCustomerAddress(draft, customer, addressId, addressType, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });

    const data = {
      id: customer.id,
      [`${addressType}Address`]: { id: addressId },
    };

    apiSupplierAddCustomerOnDraftOrder(
      draft.id,
      data,
      (draft) => dispatch(refreshSupplierDraft(draft, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}

export function supplierDraftDetachCustomer(draft, customer, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });
    apiSupplierRemoveCustomerFromDraftOrder(
      draft.id,
      (draft) => dispatch(refreshSupplierDraft(draft, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}

export function supplierDraftAttachShipping(draft, method, data, deliveryCompany, location, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });

    if (location) {
      data['locationId'] = location;
    }

    apiSupplierSetDraftShipping(
      draft.id,
      method,
      data,
      deliveryCompany,
      (draft) => dispatch(refreshSupplierDraft(draft, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}

export function supplierDraftRemoveShippingDeliveryCompany(draft, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });

    apiSupplierDraftDeleteDeliveryCompany(
      draft.id,
      (draft) => dispatch(refreshSupplierDraft(draft, onSuccess, onError)),
      onError
    );
  };
}

export function supplierDraftDeleteDiscount(draft, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });
    apiSupplierDraftDeleteDiscount(
      draft.id,
      (draft) => dispatch(refreshSupplierDraft(draft, onSuccess, onError)),
      (error) => onError && onError(error)
    );
  };
}

export function supplierDraftAttachPaymentMethod(draft, method, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });

    // Prepurchase order should use another method for adding payment method
    if (draft.prepurchaseOrder) {
      if ([PAYMENT_TYPES.INVOICE].indexOf(method) !== -1) {
        apiSupplierAddPrepurchasePaymentMethodOnDraftOrder(
          draft.id,
          { method: null },
          (draft) => dispatch(refreshSupplierDraft(draft, onSuccess, onError)),
          (error) => onError && onError(error)
        );
      }

      if ([PAYMENT_TYPES.CASH, PAYMENT_TYPES.CHECK, PAYMENT_TYPES.COD].indexOf(method) !== -1) {
        apiSupplierAddPrepurchasePaymentMethodOnDraftOrder(
          draft.id,
          { method: method },
          (draft) => dispatch(refreshSupplierDraft(draft, onSuccess, onError)),
          (error) => onError && onError(error)
        );
      }

      if ([PAYMENT_TYPES.CASH, PAYMENT_TYPES.CHECK, PAYMENT_TYPES.COD, PAYMENT_TYPES.INVOICE].indexOf(method) === -1) {
        apiSupplierAddPrepurchasePaymentMethodOnDraftOrder(
          draft.id,
          { paymentMethodId: method },
          (draft) => dispatch(refreshSupplierDraft(draft, onSuccess, onError)),
          (error) => onError && onError(error)
        );
      }
    } else {
      if ([PAYMENT_TYPES.INVOICE].indexOf(method) !== -1) {
        apiSupplierAddInvoicePaymentMethodOnDraftOrder(
          draft.id,
          '',
          {},
          (draft) => dispatch(refreshSupplierDraft(draft, onSuccess, onError)),
          (error) => onError && onError(error)
        );
      }

      if ([PAYMENT_TYPES.CASH, PAYMENT_TYPES.CHECK, PAYMENT_TYPES.COD].indexOf(method) !== -1) {
        apiSupplierAddInvoicePaymentMethodOnDraftOrder(
          draft.id,
          method,
          {},
          (draft) => dispatch(refreshSupplierDraft(draft, onSuccess, onError)),
          (error) => onError && onError(error)
        );
      }

      if ([PAYMENT_TYPES.CASH, PAYMENT_TYPES.CHECK, PAYMENT_TYPES.COD, PAYMENT_TYPES.INVOICE].indexOf(method) === -1) {
        apiSupplierAddInvoicePaymentMethodOnDraftOrder(
          draft.id,
          '',
          { paymentMethodId: method },
          (draft) => dispatch(refreshSupplierDraft(draft, onSuccess, onError)),
          (error) => onError && onError(error)
        );
      }
    }
  };
}

export function supplierDraftComplete(draft, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: DRAFT_LOADING });
    apiSupplierCompleteDraftOrder(
      draft.id,
      (order) => {
        onSuccess && onSuccess(order);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
}
