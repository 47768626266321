import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Spinner } from '../../components';
import { apiSuppliersGetOrderInvoice } from '../../api';

function SuppliersInvoiceScene() {
  const { hash } = useParams();
  const [loading, setLoading] = useState(true);
  const [pdf, setPdf] = useState('');

  useEffect(() => {
    apiSuppliersGetOrderInvoice(
      hash,
      (response) => {
        setPdf(response);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  }, [hash]);

  if (loading) return <Spinner size={40} />;

  return (
    <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      <embed src={pdf} type="application/pdf" width={'100%'} height={'100%'} />
    </div>
  );
}

export default SuppliersInvoiceScene;
