import {COLORS} from "../../helpers";

export default ({
  wrapper: {
  },
  types: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 340
  },
  img: {
    width: 50
  },
  text: {
    fontSize: 13,
    marginLeft: 10,
    color: COLORS.headerText
  },
  type: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingTop: 10,
    paddingBottom: 10,
    borderTop: `1px solid ${COLORS.border}`,
    borderBottom: `1px solid ${COLORS.border}`,
    cursor: 'pointer',
    '&:first-child' : {
      borderTop: `none`,
    },
    alignItems: 'center',
  },
  cell: {
  },
  icon: {
    fill: COLORS.text,
    fontSize: 28,
  },
  imageCell: {
    minWidth: 70
  }
});
