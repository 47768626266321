import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import { Spinner, PrimaryButton, PrimaryCheckbox } from '../../components';
import { ORDER_STATUSES } from '../../helpers';

const styles = {
  actionsWrap: {
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
    paddingTop: 40,
    paddingBottom: 20,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: 600,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
};

const propTypes = {
  order: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
};

class OrderUpdatedEmailForm extends React.Component {
  state = {
    loading: false,
    orderUpdated: true,
    invoiceUpdated: true,
  };

  getCheckbox = (key) => {
    const { intl } = this.props;
    return (
      <FormControlLabel
        control={
          <PrimaryCheckbox
            checked={this.state[key]}
            onChange={(event) => this.setState({ [key]: event.target.checked })}
            color="default"
          />
        }
        label={intl.formatMessage({ id: `order.sendUpdates.${key}` })}
      />
    );
  };

  _handleSubmit = (e) => {
    e.preventDefault();
    const { onDone, order } = this.props;

    let orderUpdated = false;
    let invoiceUpdated = false;

    if (order.status !== ORDER_STATUSES.ORDER_RECEIVED) {
      orderUpdated = this.state.orderUpdated;
    }

    if (order.status === ORDER_STATUSES.ORDER_RECEIVED) {
      orderUpdated = this.state.orderUpdated;
      invoiceUpdated = this.state.invoiceUpdated;
    }

    this.setState({ loading: true });

    onDone({
      orderUpdated,
      invoiceUpdated,
    });
  };

  render() {
    const { intl, classes, order } = this.props;

    const { loading } = this.state;

    return (
      <form onSubmit={this._handleSubmit} className={classes.form}>
        {loading && <Spinner size={50} />}
        <DialogContent>
          <Typography style={{ textAlign: 'center' }}>
            {intl.formatMessage({ id: 'order.sendUpdatesMessage' })}
          </Typography>

          <FormControl style={{ marginTop: 10, minWidth: '100%' }} component="fieldset">
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
              <FormGroup>
                {order?.status !== ORDER_STATUSES.ORDER_RECEIVED && this.getCheckbox('orderUpdated')}

                {order?.status === ORDER_STATUSES.ORDER_RECEIVED && (
                  <React.Fragment>
                    {this.getCheckbox('orderUpdated')}
                    {this.getCheckbox('invoiceUpdated')}
                  </React.Fragment>
                )}
              </FormGroup>
            </div>
          </FormControl>
        </DialogContent>
        <div className={classes.actionsWrap}>
          <PrimaryButton disabled={loading} type="submit">
            {intl.formatMessage({ id: 'global.send' })}
          </PrimaryButton>
        </div>
      </form>
    );
  }
}

OrderUpdatedEmailForm.propTypes = propTypes;

export default withStyles(styles)(injectIntl(OrderUpdatedEmailForm));
