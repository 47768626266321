import React from "react";
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import EmptyImage from "./assets/no.jpg";
import styles from './styles';

const propTypes = {
  lineItem: PropTypes.object.isRequired
};

const Image = (props) => {
  const {
    classes,
    lineItem,
  } = props;

  const image =  lineItem.image && lineItem.image.thumbSrc ?
    lineItem.image.thumbSrc : EmptyImage;

  return (
    <div className={classes.imageBox}>
      <img className={classes.image}
           src={image}
           alt={lineItem.name.product}/>
    </div>
  );
};

Image.propTypes = propTypes;

export default withStyles(styles)(Image);