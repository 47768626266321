export default {
  email: {
    presence: {
      message: '^validation.required',
    },
    email: {
      message: '^validation.enterValidEmail',
    },
  },
  username: {
    presence: {
      message: '^validation.required',
    },
  },
  firstName: {
    presence: {
      message: '^validation.required',
    },
  },
  lastName: {
    presence: {
      message: '^validation.required',
    },
  },
  companyName: {
    presence: {
      message: '^validation.required',
    },
  },
};
