import {
  PICKUPS_MAKE_INITIALIZED,
  PICKUPS_MAKE_NON_INITIALIZED,
  PICKUPS_SIGN_IN,
  PICKUPS_SIGN_OUT,
  PICKUPS_SET_USER_OBJECT,
  PICKUPS_CHANGE_LOADING,
} from '../constants';
import PickupsUser from '../../entities/PickupsUser';

const initialState = {
  authenticated: false,
  pickupToken: null,
  initialized: false,
  loading: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PICKUPS_SIGN_IN: {
      const { token } = action.payload;

      return {
        ...state,
        pickupToken: token,
      };
    }
    case PICKUPS_SIGN_OUT: {
      return {
        ...state,
        pickupToken: null,
      };
    }
    case PICKUPS_SET_USER_OBJECT: {
      return {
        ...state,
        object: new PickupsUser(action.payload),
      };
    }
    case PICKUPS_MAKE_INITIALIZED: {
      return {
        ...state,
        initialized: true,
      };
    }
    case PICKUPS_MAKE_NON_INITIALIZED: {
      return {
        ...state,
        initialized: false,
      };
    }
    case PICKUPS_CHANGE_LOADING: {
      return {
        ...state,
        loading: !!action.payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
