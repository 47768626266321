import React from 'react';
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import DXSelectFilter from '../DXSelectFilter/DXSelectFilter'
import DXFilterConfig from '../DXFilterConfig'
import constants from '../../constants'

const propTypes = {
  equality: PropTypes.string,
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  selected: [],
};

class DXOrderStatusFilter extends React.Component {

  render() {
    const { selected, onChange, intl, equality} = this.props;
    const config = DXOrderStatusFilter.getConfig(intl, equality);
    return (
      <DXSelectFilter selected={selected}
                      title={config.title}
                      values={config.values}
                      onChange={onChange}
                      multi={config.multi}
      />
    )
  }
}

DXOrderStatusFilter.propTypes = propTypes;
DXOrderStatusFilter.defaultProps = defaultProps;
DXOrderStatusFilter.getLabel = (intl, selectedValues) => {
  let label = "";
  if (Array.isArray(selectedValues) && selectedValues.length) {
    const config = DXOrderStatusFilter.getConfig(intl, '*');
    const values = config.values;
    let parts = [];

    selectedValues.forEach(value => {
      const translation = values.filter(item => item.key === value);
      if (translation.length) {
        parts.push(translation[0].label)
      }
    });
    label = parts.join(', ');
  }
  return label;
};
DXOrderStatusFilter.applyToQueryParams = (queryParams, selectedValues) => {
  queryParams['status'] = selectedValues;
  return queryParams;
};
DXOrderStatusFilter.getConfig = (intl, equality) => {
  let config = new DXFilterConfig({
    name: 'status',
    selected: [],
    property: 'status',
    title: intl.formatMessage({id: 'filter.status'}),
    disabled: false,
    equalityStatus: true,
    multi: true
  });

  if (equality === '*' || equality === constants.FILTER_EQUALITY_EQUALS) {
    config.addFilterValue('new', intl.formatMessage({id: 'filter.status.new'}));
    config.addFilterValue('received', intl.formatMessage({id: 'filter.status.received'}));
    config.addFilterValue('out_for_delivery', intl.formatMessage({id: 'filter.status.out_for_delivery'}));
    config.addFilterValue('packed', intl.formatMessage({id: 'filter.status.packed'}));
    config.addFilterValue('cancelled', intl.formatMessage({id: 'filter.status.cancelled'}));
    config.addFilterValue('not_approved', intl.formatMessage({id: 'filter.status.not_approved'}));
  }

  if (equality === '*' || equality === constants.FILTER_EQUALITY_NOT_EQUALS) {
    config.addFilterValue('ne-new', intl.formatMessage({id: 'filter.status.not_new'}));
    config.addFilterValue('ne-received', intl.formatMessage({id: 'filter.status.not_received'}));
    config.addFilterValue('ne-out_for_delivery', intl.formatMessage({id: 'filter.status.not_out_for_delivery'}));
    config.addFilterValue('ne-packed', intl.formatMessage({id: 'filter.status.not_packed'}));
    config.addFilterValue('ne-cancelled', intl.formatMessage({id: 'filter.status.not_cancelled'}));
    config.addFilterValue('ne-not_approved', intl.formatMessage({id: 'filter.status.not_not_approved'}));
  }

  return config;
};

export default injectIntl(
  DXOrderStatusFilter
);
