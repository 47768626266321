export default {
  button: {
    fontSize: 12,
    cursor: 'pointer',
    color: 'rgb(65, 205, 140)',
    '&:hover': {
      color: 'rgb(65, 205, 140)'
    }
  },
  container: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
};