import React from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SupplierInputLabel from '../SupplierInputLabel';
import { COLORS } from '../../constants/colors';
import { FONT_TEXT_INPUT } from '../../constants/fonts';
import { makeStyles } from '@material-ui/core/styles';

const propTypes = {
  helperText: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  formControlClassName: PropTypes.string,
};

const defaultProps = {
  error: false,
};

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiSelect: {
      root: {
        ...FONT_TEXT_INPUT,
      },
      icon: {
        fill: COLORS.text,
        fontSize: 22,
      },
    },
    MuiInput: {
      root: {
        ...FONT_TEXT_INPUT,
        minHeight: 40,
      },
      underline: {
        '&:after': {
          borderBottom: `2px solid ` + COLORS.border,
        },
        '&$focused:after': {
          borderBottomColor: COLORS.border,
        },
        '&$error:after': {
          borderBottomColor: COLORS.border,
        },
        '&:before': {
          borderBottom: `1px solid ` + COLORS.border,
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: `2px solid ` + COLORS.border,
        },
        '&$disabled:before': {
          borderBottom: `1px dotted ` + COLORS.border,
        },
        '&$focused svg': {
          fill: COLORS.border,
        },
      },
    },
  },
});
const useStyles = makeStyles((theme) => ({
  coloredLabel: {
    fontWeight: '700',
    color: COLORS.brightBlue,
  },
}));

const SupplierSelect = (props) => {
  const classes = useStyles();
  const { formControlClassName, children, label, helperText, error, disabled, coloredLabel, ...other } = props;

  return (
    <MuiThemeProvider theme={theme}>
      <FormControl className={formControlClassName} error={error} disabled={disabled}>
        {label && (
          <SupplierInputLabel classes={{ root: coloredLabel ? classes.coloredLabel : '' }}>{label}</SupplierInputLabel>
        )}
        <Select IconComponent={KeyboardArrowDownIcon} {...other}>
          {children}
        </Select>

        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </MuiThemeProvider>
  );
};

SupplierSelect.propTypes = propTypes;
SupplierSelect.defaultProps = defaultProps;

export default SupplierSelect;
