import React from 'react';

const ArrowIcon = (props) => {
  const { color = '#001E2E', rotateAngle = 0, left = false, ...other } = props;

  const getArrow = () => {
    let d = 'M1 9L5 5L1 1'; //right
    if (left) d = 'M5 1L1 5L5 9';

    return <path d={d} stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />;
  };

  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
      style={{
        transform: `rotate(${rotateAngle}deg)`,
        ...(!!other?.style ? other.style : {}),
      }}>
      {getArrow()}
    </svg>
  );
};

export default ArrowIcon;
