import React from 'react';
import PropTypes from 'prop-types';
import { AdminDialogContent, AdminDialogControls, OutlineButton, PrimaryButton } from '../../components';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from '../../hocs';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const propTypes = {
  onDeleteNotification: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const defaultProps = {};

function DeleteStep(props) {
  const { classes, onDeleteNotification, onCancel } = props;

  return (
    <AdminDialogContent className={classes.wrapper}>
      <div className={classes.deleteWrapper}>
        <p className={classes.deleteText}>
          <FormattedMessage id="screen.producerNotificationDialog.deleteText" />
        </p>
      </div>

      <AdminDialogControls>
        <OutlineButton onClick={onCancel} style={{ marginRight: 40 }}>
          <FormattedMessage id="global.cancel" />
        </OutlineButton>

        <PrimaryButton onClick={onDeleteNotification}>
          <FormattedMessage id="screen.producerNotificationDialog.delete" />
        </PrimaryButton>
      </AdminDialogControls>
    </AdminDialogContent>
  );
}

DeleteStep.propTypes = propTypes;
DeleteStep.defaultProps = defaultProps;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(DeleteStep)));
