const validation = {
  email: {
    email: {
      message: '^validation.emailSubscriptions.enterValidEmail'
    },
    presence: {
      message: '^validation.emailSubscriptions.enterEmail'
    }
  },
};

export default validation;
