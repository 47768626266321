import React from 'react';
import PropTypes from 'prop-types';
import EmptyImage from '../assets/no.jpg';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

MarketListComponent.propTypes = {
  location: PropTypes.object,
  onClick: PropTypes.func,
};
MarketListComponent.defaultProps = {
  location: {},
  onClick: () => {},
};

const useStyles = makeStyles((theme) => ({
  itemWrapper: {
    padding: 10,
    width: '50%',
  },
  itemPaper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: 10,
    width: '100%',
    borderRadius: 9,
    height: '100%',
  },
  titleWrapper: {},
  title: {
    fontWeight: 500,
    fontSize: 16,
  },
  imgWrapper: {
    width: 100,
    minWidth: 100,
    height: '100%',
    minHeight: 72,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginRight: 20,
    borderRadius: 9,
    position: 'relative',
    zIndex: 1,
  },
  img: {
    position: 'absolute',
    height: '100%',
  },
  informationWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  addressWrapper: {},
  address: {},
}));

function MarketListComponent(props) {
  const { location, onClick } = props;
  const classes = useStyles();
  const name = location.name;

  const drawImg = () => {
    const imgSrc = location.image
      ? location.image.thumbSrc
        ? location.image.thumbSrc
        : location.image.src
      : EmptyImage;

    return (
      <div className={classes.imgWrapper}>
        <img src={imgSrc} alt={name} className={classes.img} />
      </div>
    );
  };

  const drawTitle = () => {
    return (
      <div className={classes.titleWrapper}>
        <Typography className={classes.title}>{name}</Typography>
      </div>
    );
  };

  const drawAddress = () => {
    return (
      <div className={classes.addressWrapper}>
        <Typography className={classes.address}>{`${!!location.countryCode ? location.countryCode + ' ' : ''}${
          !!location.city ? location.city + ' ' : ''
        }${!!location.address1 ? location.address1 + ' ' : ''}${
          !!location.address2 ? location.address2 : ''
        }`}</Typography>
      </div>
    );
  };

  const drawZip = () => {
    return (
      <div className={classes.addressWrapper}>
        <Typography className={classes.address}>{location.zip}</Typography>
      </div>
    );
  };

  return (
    <div className={classes.itemWrapper} key={location.id}>
      <Paper className={classes.itemPaper} onClick={() => onClick(location)} elevation={3}>
        {drawImg()}
        <div className={classes.informationWrapper}>
          {drawTitle()}
          {drawAddress()}
          {drawZip()}
        </div>
      </Paper>
    </div>
  );
}

export default MarketListComponent;
