import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import DXTextFilter from '../DXTextFilter/DXTextFilter';
import DXFilterConfig from '../DXFilterConfig';

const propTypes = {
  equality: PropTypes.string,
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  selected: [],
};

class DXNameSearchFilter extends React.Component {
  render() {
    const { selected, onChange, intl, equality } = this.props;
    const config = DXNameSearchFilter.getConfig(intl, equality);

    return (
      <DXTextFilter value={selected} title={config.title} isNumber={false} onChange={onChange} values={config.values} />
    );
  }
}

DXNameSearchFilter.propTypes = propTypes;
DXNameSearchFilter.defaultProps = defaultProps;
DXNameSearchFilter.getLabel = (intl, selectedValues) => {
  return DXTextFilter.getLabel(intl, 'filter.name', selectedValues);
};
DXNameSearchFilter.applyToQueryParams = (queryParams, selectedValues) => {
  selectedValues[0] && (queryParams['name'] = selectedValues[0]);
  return queryParams;
};
DXNameSearchFilter.getConfig = (intl) => {
  return new DXFilterConfig({
    name: 'name',
    selected: [],
    property: 'name',
    title: intl.formatMessage({ id: 'filter.name' }),
    disabled: false,
    equalityStatus: false,
  });
};

export default injectIntl(DXNameSearchFilter);
