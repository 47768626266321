import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withRouter } from '../../hocs';
import { injectIntl } from 'react-intl';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { withStyles } from '@material-ui/core/styles';
import { apiProducerInvoicesGetList } from '../../api';
import { AdminDXTable } from '../../components';
import { COLORS, countWidthOfColumns, DEFAULT_MAX_CELL_WIDTH, DEFAULT_MIN_CELL_WIDTH, toPaper } from '../../helpers';
import { getGridPageSize, getGridPageSizes } from '../../helpers/grid';
import Tab from './Components/Tab';
import InvoiceActionsCell from './Cells/InvoiceActionsCell';
import BaseDialog from '../../dialogs/BaseDialog';
import { PayInvoiceForm } from '../../forms';

const styles = {
  wrapper: {
    marginBottom: 40,
  },
  title: {
    fontSize: 25,
    display: 'inline-block',
    color: COLORS.text,
    fontWeight: 300,
    fontFamily: 'Roboto, sans-serif',
  },
  accounts: {
    display: 'flex',
    flexDirection: 'row',
    width: 750,
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
};

const propTypes = {
  producer: PropTypes.object.isRequired,
};

class InvoicesTab extends React.Component {
  constructor(props) {
    super(props);
    const { intl } = this.props;

    this.table = null;

    this.columns = [
      { name: 'number', title: intl.formatMessage({ id: 'global.number' }) },
      {
        name: 'from',
        title: intl.formatMessage({ id: 'global.periodStart' }),
        getCellValue: (row) => (row.from ? moment.parseZone(row.from).format('MMM D, YYYY') : '-'),
      },
      {
        name: 'to',
        title: intl.formatMessage({ id: 'global.periodEnd' }),
        getCellValue: (row) => (row.to ? moment.parseZone(row.to).format('MMM D, YYYY') : '-'),
      },
      {
        name: 'paid',
        title: intl.formatMessage({ id: 'global.paymentStatus' }),
        getCellValue: (row) =>
          row.paid ? intl.formatMessage({ id: 'global.paid' }) : intl.formatMessage({ id: 'global.notPaid' }),
      },
      {
        name: 'amount',
        title: intl.formatMessage({ id: 'global.amount' }),
        getCellValue: (row) => (row.total ? `$${toPaper(row.total)}` : '-'),
      },
      { name: 'actions', title: intl.formatMessage({ id: 'global.actions' }) },
    ];

    this.state = {
      currentInvoicePayed: false,

      columnWidths: countWidthOfColumns(this.columns, [], []),
    };
  }

  componentWillUnmount() {
    this.table = null;
  }

  onClose = () => {
    const {
      props: { history },
    } = this;
    this.setState({ currentInvoicePayed: false });
    history.push(`/settings#invoices`);
  };

  render() {
    const {
      state: { currentInvoicePayed },
      props: { intl, history, openEditModal },
    } = this;

    return (
      <Tab title={intl.formatMessage({ id: 'producer.tab.invoices' })} style={{ width: 'auto' }}>
        <AdminDXTable
          enableSearch={false}
          onRef={(table) => (this.table = table)}
          cellRenderer={(reload, { row, ...props }) => {
            if (props.column.name === 'actions') {
              return (
                <InvoiceActionsCell
                  row={row}
                  onPayInvoice={(row) => history.push(`${this.props.match.url}/${row.id}`)}
                  {...props}
                />
              );
            }
            return <Table.Cell {...props} />;
          }}
          apiRetrieve={(filters, onSuccess, onError) => apiProducerInvoicesGetList(onSuccess, onError)}
          columnExtensions={[
            { columnName: 'actions', align: 'right' },
            { columnName: 'amount', align: 'right' },
          ]}
          pageSize={getGridPageSize()}
          pageSizes={getGridPageSizes()}
          columns={this.columns}
          columnWidths={this.state.columnWidths}
          onColumnWidthsChange={(widths) => {
            this.setState({ columnWidths: widths });
          }}
          minColumnWidth={DEFAULT_MIN_CELL_WIDTH}
          maxColumnWidth={DEFAULT_MAX_CELL_WIDTH}
          allowExpandAll={true}
        />

        {openEditModal && (
          <BaseDialog
            scroll={'paper'}
            maxWidth="md"
            open
            onClose={this.onClose}
            title={
              !currentInvoicePayed
                ? intl.formatMessage({ id: 'global.payInvoice' })
                : intl.formatMessage({ id: 'invoice.paymentSuccessful' })
            }>
            <PayInvoiceForm
              {...this.props}
              producer={this.props.producer}
              onClose={this.onClose}
              onSuccess={() => {
                this.setState({ currentInvoicePayed: true });
                setTimeout(() => {
                  this.table.forceReload();
                }, 200);
              }}
            />
          </BaseDialog>
        )}
      </Tab>
    );
  }
}

InvoicesTab.propTypes = propTypes;

export default injectIntl(withRouter(withStyles(styles)(InvoicesTab)));
