import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CloseIcon from '@material-ui/icons/Close';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';
import {
  AdminDialogTitle,
  AdminDialogContent,
  PrimaryButton,
  PrimaryTextField,
  PrimarySelect,
  PrimaryCheckbox,
  Spinner,
} from '../../../components';
import { apiAddVariantToProduct, apiEditProductVariant } from '../../../api/product/product';
import {
  PRICE_LEVELS,
  validateField,
  weightToSystem,
  toCoins,
  toPaper,
  getVariantPriceWithoutCurrency,
  weightToUser,
} from '../../../helpers';
import Icon from '../../../components/Icon/Icon';
import Image from '../Image/Image';
import validationRules from './validation';
import styles from './styles';
import { Variant } from '../../../entities';

const propTypes = {
  variant: PropTypes.object,
  product: PropTypes.object,
  totalVariantsCount: PropTypes.number.isRequired,
  producer: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  onUpdateProduct: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
};

const defaultProps = {
  title: 'product.title.create',
  buttonTitle: 'product.button.create',
};

class VariantContainer extends React.Component {
  constructor(props) {
    super(props);

    const { variant, producer, intl } = this.props;

    const variantEntity = new Variant(variant);

    this.state = {
      variantEntity,
      loading: false,
      file: null,
      name: variant ? variant?.name || '' : '',
      unit: variant ? variant.unit : Object.keys(producer.units)[0],
      unitQuantity: variant ? weightToUser(variant.unitQuantity, false) : 1,
      retailMarketPrice: variant ? this.getVariantPrice(variant, intl, PRICE_LEVELS.RETAIL_MARKET) : '',
      retailPreorderPrice: variant ? this.getVariantPrice(variant, intl, PRICE_LEVELS.RETAIL_PREORDER) : '',
      wholesaleMarketPrice: variant ? this.getVariantPrice(variant, intl, PRICE_LEVELS.WHOLESALE_MARKET) : '',
      wholesalePreorderPrice: variant ? this.getVariantPrice(variant, intl, PRICE_LEVELS.WHOLESALE_PREORDER) : '',
      activeRetail: variant ? variant.offered[Variant.OFFERED_RETAIL] : true,
      activeWholesale: variant ? variant.offered[Variant.OFFERED_WHOLESALE] : false,
      description: variant ? variant.description || '' : '',
      active: variant ? variant.active : true,

      nameError: '',
      unitError: '',
      unitQuantityError: '',
      retailMarketPriceError: '',
      retailPreorderPriceError: '',
      wholesaleMarketPriceError: '',
      wholesalePreorderPriceError: '',

      // Variant subscription
      type: variant ? variant.type : Variant.TYPE_REGULAR,
      subscriptionQuantity: variantEntity.subscriptionQuantity ? weightToUser(variantEntity.subscriptionQuantity) : '',
    };
  }

  getVariantPrice = (variant, intl, level) => {
    if (variant.prices) return toPaper(getVariantPriceWithoutCurrency(variant, level));
    if (variant[`price.${level}`]) return toPaper(variant[`price.${level}`]);
    return '';
  };

  validate = () => {
    const { onDone, product, variant, onUpdateProduct, totalVariantsCount } = this.props;

    const { type, subscriptionQuantity, activeRetail, activeWholesale } = this.state;

    const nameError = validateField(validationRules, 'name', this.state.name);
    const unitError = validateField(validationRules, 'unit', this.state.unit);
    const unitQuantityError = validateField(validationRules, 'unitQuantity', this.state.unitQuantity);

    let retailMarketPriceError, retailPreorderPriceError, wholesaleMarketPriceError, wholesalePreorderPriceError;

    if (activeRetail) {
      retailMarketPriceError = validateField(validationRules, 'retailMarketPrice', this.state.retailMarketPrice);
      retailPreorderPriceError = validateField(validationRules, 'retailPreorderPrice', this.state.retailPreorderPrice);
    }

    if (activeWholesale) {
      wholesaleMarketPriceError = validateField(
        validationRules,
        'wholesaleMarketPrice',
        this.state.wholesaleMarketPrice
      );
      wholesalePreorderPriceError = validateField(
        validationRules,
        'wholesalePreorderPrice',
        this.state.wholesalePreorderPrice
      );
    }

    const atLeastOnePriceSelect = !activeRetail && !activeWholesale;
    // Subscription qty validation
    let subscriptionQuantityError = '';
    if (type === Variant.TYPE_SUBSCRIPTION)
      subscriptionQuantityError = validateField(
        validationRules,
        'subscriptionQuantity',
        this.state.subscriptionQuantity
      );

    if (
      !nameError &&
      !unitError &&
      !unitQuantityError &&
      !retailMarketPriceError &&
      !wholesaleMarketPriceError &&
      !wholesalePreorderPriceError &&
      !subscriptionQuantityError &&
      !atLeastOnePriceSelect
    ) {
      this.setState({
        nameError: '',
        unitError: '',
        unitQuantityError: '',
        retailMarketPriceError: '',
        retailPreorderPriceError: '',
        wholesaleMarketPriceError: '',
        wholesalePreorderPriceError: '',
        subscriptionQuantityError: '',
      });

      let data = {
        name: this.state.name,
        unit: this.state.unit,
        unitQuantity: weightToSystem(this.state.unitQuantity),
        description: this.state.description,
        active: this.state.active,
        prices: [
          {
            level: 'wholesale_market',
            price: toCoins(this.state.wholesaleMarketPrice),
          },
          {
            level: 'wholesale_preorder',
            price: toCoins(this.state.wholesalePreorderPrice),
          },
          {
            level: 'retail_market',
            price: toCoins(this.state.retailMarketPrice),
          },
          {
            level: 'retail_preorder',
            price: toCoins(this.state.retailPreorderPrice),
          },
        ],
        offered: {
          retail: activeRetail,
          wholesale: activeWholesale,
        },

        /**
         * Variant subscription
         * Subscription should work only in case we have more or equals to one variant
         */
        ...(totalVariantsCount >= 1 && variant && variant.id ? {} : { type }),
        ...(totalVariantsCount >= 1 && type === Variant.TYPE_SUBSCRIPTION
          ? {
              subscriptionQuantity: weightToSystem(subscriptionQuantity),
            }
          : {}),
      };

      if (this.state.file) {
        data.image = this.state.file;
      }

      if (product && product.id) {
        this.setState({ loading: true });
        if (variant && variant.id) {
          apiEditProductVariant(
            product.id,
            variant.id,
            data,
            (product) => {
              onUpdateProduct(product);
            },
            undefined
          );
        } else {
          data.price = toCoins(this.state.wholesaleMarketPrice);
          data.prices = [
            {
              level: 'retail_market',
              price: toCoins(this.state.retailMarketPrice),
            },
            {
              level: 'retail_preorder',
              price: toCoins(this.state.retailPreorderPrice),
            },
            {
              level: 'wholesale_preorder',
              price: toCoins(this.state.wholesalePreorderPrice),
            },
            {
              level: 'wholesale_market',
              price: toCoins(this.state.wholesaleMarketPrice),
            },
          ];
          apiAddVariantToProduct(
            product.id,
            data,
            (product) => {
              onUpdateProduct(product);
            },
            undefined
          );
        }
      } else {
        data.base64 = this.state.file;
        data.price = toCoins(this.state.wholesaleMarketPrice);
        data.prices = [
          {
            level: 'retail_market',
            price: toCoins(this.state.retailMarketPrice),
          },
          {
            level: 'retail_preorder',
            price: toCoins(this.state.retailPreorderPrice),
          },
          {
            level: 'wholesale_preorder',
            price: toCoins(this.state.wholesalePreorderPrice),
          },
          {
            level: 'wholesale_market',
            price: toCoins(this.state.wholesaleMarketPrice),
          },
        ];
        data.id = (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase();
        onDone(data);
      }
    } else {
      this.setState({
        nameError,
        unitError,
        unitQuantityError,
        retailPreorderPriceError,
        retailMarketPriceError,
        wholesaleMarketPriceError,
        wholesalePreorderPriceError,
        subscriptionQuantityError,
      });
    }
  };

  render() {
    const { title, buttonTitle, intl, classes, onClose, variant, producer, totalVariantsCount } = this.props;

    const { activeRetail, activeWholesale } = this.state;

    return (
      <React.Fragment>
        <Icon icon={CloseIcon} style={{ fill: 'rgba(0, 0, 0, 0.5)' }} className={classes.close} onClick={onClose} />

        {this.state.loading && <Spinner size={60} />}

        <AdminDialogTitle title={intl.formatMessage({ id: title })} />
        <AdminDialogContent className={classes.wrapper}>
          <div className={classes.row}>
            <div style={{ maxWidth: 265, alignItems: 'center' }} className={classNames(classes.column, classes.mr40)}>
              <Image object={variant} base64={this.props.file} onUploaded={(file) => this.setState({ file: file })} />

              {totalVariantsCount >= 1 && (
                <>
                  <FormControlLabel
                    control={
                      <PrimaryCheckbox
                        disabled={!!variant}
                        checked={this.state.type === Variant.TYPE_SUBSCRIPTION}
                        onChange={(event) =>
                          this.setState({
                            type: event.target.checked ? Variant.TYPE_SUBSCRIPTION : Variant.TYPE_REGULAR,
                          })
                        }
                        color="default"
                      />
                    }
                    label={intl.formatMessage({ id: `products.allowSubscription` })}
                  />

                  {this.state.type === Variant.TYPE_SUBSCRIPTION && (
                    <PrimaryTextField
                      className={classes.input}
                      disabled={this.state.type !== Variant.TYPE_SUBSCRIPTION}
                      error={!!this.state.subscriptionQuantityError}
                      onChange={(event) => this.setState({ subscriptionQuantity: event.target.value })}
                      value={this.state.subscriptionQuantity}
                      id="subscription-qty-input"
                      label={intl.formatMessage({ id: 'products.subscriptionQty' })}
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      helperText={
                        this.state.subscriptionQuantityError &&
                        intl.formatMessage({ id: this.state.subscriptionQuantityError })
                      }
                      fullWidth
                    />
                  )}
                </>
              )}
            </div>

            <div className={classNames(classes.column, { flex: 2 })}>
              <div className={classes.row}>
                <div className={classNames(classes.column, classes.mr40, { flex: 1 })}>
                  <PrimaryTextField
                    className={classes.input}
                    error={!!this.state.nameError}
                    onChange={(event) => this.setState({ name: event.target.value })}
                    value={this.state.name}
                    id="name-input"
                    label={intl.formatMessage({ id: 'variant.name' })}
                    type="text"
                    InputLabelProps={{ shrink: true }}
                    helperText={this.state.nameError && intl.formatMessage({ id: this.state.nameError })}
                    fullWidth
                  />

                  <PrimarySelect
                    className={classes.input}
                    label={intl.formatMessage({ id: 'variant.unit' })}
                    value={this.state.unit}
                    onChange={(e) => this.setState({ unit: e.target.value })}>
                    {Object.keys(producer.units).map((unit) => (
                      <MenuItem key={unit} value={unit}>
                        {unit}
                      </MenuItem>
                    ))}
                  </PrimarySelect>

                  <PrimaryTextField
                    className={classes.input}
                    error={!!this.state.unitQuantityError}
                    onChange={(event) => this.setState({ unitQuantity: event.target.value })}
                    value={this.state.unitQuantity}
                    id="unitQuantity-input"
                    label={intl.formatMessage({ id: 'variant.unitQuantity' })}
                    type="text"
                    InputLabelProps={{ shrink: true }}
                    helperText={
                      this.state.unitQuantityError && intl.formatMessage({ id: this.state.unitQuantityError })
                    }
                    fullWidth
                  />
                </div>

                <div className={classNames(classes.column, { flex: 1 })}>
                  <FormControl
                    required
                    error={!activeRetail && !activeWholesale}
                    component="fieldset"
                    className={classes.formControl}>
                    <FormControlLabel
                      style={{
                        alignSelf: 'flex-start',
                        ...(!activeRetail && !activeWholesale && { color: '#ff0000' }),
                      }}
                      control={
                        <PrimaryCheckbox
                          checked={activeRetail}
                          onChange={(event) => this.setState({ activeRetail: event.target.checked })}
                          color="default"
                        />
                      }
                      label={intl.formatMessage({ id: 'variant.activeRetail' })}
                    />

                    {activeRetail && (
                      <>
                        <PrimaryTextField
                          className={classes.input}
                          error={!!this.state.retailMarketPriceError}
                          onChange={(event) => this.setState({ retailMarketPrice: event.target.value })}
                          value={this.state.retailMarketPrice}
                          id="retailMarketPrice-input"
                          label={intl.formatMessage({ id: 'variant.retailMarketPrice' })}
                          type="text"
                          InputLabelProps={{ shrink: true }}
                          helperText={
                            this.state.retailMarketPriceError &&
                            intl.formatMessage({ id: this.state.retailMarketPriceError })
                          }
                          fullWidth
                        />

                        <PrimaryTextField
                          className={classes.input}
                          error={!!this.state.retailPreorderPriceError}
                          onChange={(event) => this.setState({ retailPreorderPrice: event.target.value })}
                          value={this.state.retailPreorderPrice}
                          id="retailPreorderPrice-input"
                          label={intl.formatMessage({ id: 'variant.retailPreorderPrice' })}
                          type="text"
                          InputLabelProps={{ shrink: true }}
                          helperText={
                            this.state.retailPreorderPriceError &&
                            intl.formatMessage({ id: this.state.retailPreorderPriceError })
                          }
                          fullWidth
                        />
                      </>
                    )}

                    <FormControlLabel
                      style={{
                        alignSelf: 'flex-start',
                        ...(!activeRetail && !activeWholesale && { color: '#ff0000' }),
                      }}
                      control={
                        <PrimaryCheckbox
                          checked={activeWholesale}
                          onChange={(event) => this.setState({ activeWholesale: event.target.checked })}
                          color="default"
                        />
                      }
                      label={intl.formatMessage({ id: 'variant.activeWholesale' })}
                    />

                    {activeWholesale && (
                      <>
                        <PrimaryTextField
                          className={classes.input}
                          error={!!this.state.wholesaleMarketPriceError}
                          onChange={(event) => this.setState({ wholesaleMarketPrice: event.target.value })}
                          value={this.state.wholesaleMarketPrice}
                          id="wholesaleMarketPrice-input"
                          label={intl.formatMessage({ id: 'variant.wholesaleMarketPrice' })}
                          type="text"
                          InputLabelProps={{ shrink: true }}
                          helperText={
                            this.state.wholesaleMarketPriceError &&
                            intl.formatMessage({ id: this.state.wholesaleMarketPriceError })
                          }
                          fullWidth
                        />

                        <PrimaryTextField
                          className={classes.input}
                          error={!!this.state.wholesalePreorderPriceError}
                          onChange={(event) => this.setState({ wholesalePreorderPrice: event.target.value })}
                          value={this.state.wholesalePreorderPrice}
                          id="wholesalePreorderPrice-input"
                          label={intl.formatMessage({ id: 'variant.wholesalePreorderPrice' })}
                          type="text"
                          InputLabelProps={{ shrink: true }}
                          helperText={
                            this.state.wholesalePreorderPriceError &&
                            intl.formatMessage({ id: this.state.wholesalePreorderPriceError })
                          }
                          fullWidth
                        />
                      </>
                    )}

                    {!activeRetail && !activeWholesale && (
                      <FormHelperText>{intl.formatMessage({ id: 'validation.needAtLeastOnePrice' })}</FormHelperText>
                    )}
                  </FormControl>
                </div>
              </div>

              <PrimaryTextField
                multiline
                rows={2}
                rowsMax={2}
                className={classes.input}
                onChange={(event) => this.setState({ description: event.target.value })}
                value={this.state.description}
                id="description-input"
                label={intl.formatMessage({ id: 'variant.description' })}
                type="text"
                InputLabelProps={{ shrink: true }}
                fullWidth
              />

              <FormControlLabel
                style={{ alignSelf: 'flex-end' }}
                control={
                  <PrimaryCheckbox
                    checked={this.state.active}
                    onChange={(event) => this.setState({ active: event.target.checked })}
                    color="default"
                  />
                }
                label={intl.formatMessage({ id: 'variant.active' })}
              />
            </div>
          </div>

          <div className={classes.controls}>
            <PrimaryButton onClick={this.validate}>{intl.formatMessage({ id: buttonTitle })}</PrimaryButton>
          </div>
        </AdminDialogContent>
      </React.Fragment>
    );
  }
}

VariantContainer.propTypes = propTypes;
VariantContainer.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(injectIntl(VariantContainer));
