import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../hocs';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import { IconButton } from '@material-ui/core';
import PlusIcon from '@material-ui/icons/AddCircleOutline';
import {
  apiProducerGetShippingOptions,
  apiProducerShippingDayDelete,
  apiProducerShippingRateDelete,
  apiProducerLocationsGetList,
  apiProducerLocationsDelete,
  apiCreateProducerApprovedLocations,
  apiGetProducerApprovedLocations,
  apiDeleteProducerApprovedLocation,
  apiSetDeliveryCompanySettings,
} from '../../api';
import { Spinner, AdminDXTable, PrimarySwitch } from '../../components';
import Wrapper from './Components/Wrapper';
import {
  DeliveryDayTableCell,
  // DeliveryRateTableCell,
  DeliveryDayTableRow,
} from './helpers';
import { COLORS } from '../../helpers';
import DeliveryDayDialog from './Dialogs/DeliveryDayDialog/Dialog';
import DeliveryRateDialog from './Dialogs/DeliveryRateDialog/Dialog';
import { ProducerSearchLocationDialog, ErrorDialog } from '../../dialogs';
import classNames from 'classnames';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Block from './Components/Block';

const styles = {
  wrapper: {
    marginBottom: 40,
  },
  title: {
    fontSize: 25,
    display: 'inline-block',
    color: COLORS.text,
    fontWeight: 300,
    fontFamily: 'Roboto, sans-serif',
  },
  add: {
    padding: 0,
    marginLeft: 10,
  },
  text: {
    fontSize: 14,
    color: COLORS.textSecondary,
    fontFamily: 'Roboto, sans-serif',
  },
  deliveryDaysWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: 950,
  },
  accounts: {
    display: 'flex',
    flexDirection: 'row',
    width: 750,
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  spinnerWrapper: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
  },
  contentWithSwitch: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  mr0: {
    marginRight: 0,
  },
  mb40: {
    marginBottom: 40,
  },
  smallLabelRoot: {
    marginLeft: 0,
    marginRight: 0,
  },
  smallLabel: {
    fontSize: 9,
    color: COLORS.actionLabel,
  },
};

class DeliveryTab extends React.Component {
  constructor(props) {
    super(props);
    const { intl } = this.props;

    this.locationsTable = null;
    this.daysTable = null;
    this.ratesTable = null;

    this.locationsColumns = [
      { name: 'name', title: intl.formatMessage({ id: 'global.location' }) },
      { name: 'city', title: intl.formatMessage({ id: 'global.city' }) },
      { name: 'province', title: intl.formatMessage({ id: 'global.state' }) },
      { name: 'zip', title: intl.formatMessage({ id: 'global.zipCode' }) },
    ];

    this.daysColumns = [
      { name: 'selection', title: ' ' },
      { name: 'day', title: intl.formatMessage({ id: 'global.day' }) },
      { name: 'until', title: intl.formatMessage({ id: 'global.cutOffTime' }) },
      {
        name: 'location',
        title: intl.formatMessage({ id: 'global.location' }),
      },
      {
        name: 'method',
        title: intl.formatMessage({ id: 'filter.types.retail' }),
      },
      {
        name: 'type',
        title: intl.formatMessage({ id: 'filter.types.wholesale' }),
      },
    ];

    this.ratesColumns = [
      { name: 'min', title: intl.formatMessage({ id: 'global.min' }) },
      { name: 'max', title: intl.formatMessage({ id: 'global.max' }) },
      { name: 'rate', title: intl.formatMessage({ id: 'global.rate' }) },
    ];

    this.state = {
      loading: true,
      options: null,
      locations: null,
      locationError: '',

      producerLocations: [],
      addingProducerLocation: false,

      addingLocation: false,
      addingDay: false,
      addingRate: false,

      editingLocation: null,
      editingDay: null,
      editingRate: null,

      allocatePickupOrders: false,
      sendingRequest: false,
    };
  }

  componentWillUnmount() {
    this.locationsTable = null;
    this.daysTable = null;
    this.ratesTable = null;
    this.locationsColumns = null;
    this.daysColumns = null;
    this.ratesColumns = null;
  }

  componentDidMount() {
    this._fetchShippingOptions();
    this.getAllocationPickupSettings();
  }

  _fetchShippingOptions = () => {
    apiProducerGetShippingOptions((options) => {
      this.getAllLocations((locations) => {
        this.setState({
          options,
          locations,
          loading: false,
        });
      });
    });

    this.getProducerLocations();
  };

  getAllocationPickupSettings = () => {
    const { producer } = this.props;

    this.setState({
      allocatePickupOrders: producer.allocatePickupOrders,
    });
  };

  getAllLocations = (onSuccess) => {
    apiProducerLocationsGetList((locations) => {
      onSuccess && onSuccess(locations);
    });
  };

  getProducerLocations = (onSuccess) => {
    apiGetProducerApprovedLocations(
      (producerLocations) => {
        onSuccess && onSuccess(producerLocations);
        this.setState({ producerLocations }, () => {
          this.locationsTable.forceReload();
        });
      },
      (error) => {
        this.setState({ locationError: error });
      }
    );
  };

  _deleteLocation = (row) => {
    apiProducerLocationsDelete(row.id, (locations) => {
      this.setState({ locations }, () => {
        this.locationsTable.forceReload();
      });
    });
  };

  _deleteShippingDay = (row) => {
    apiProducerShippingDayDelete(row.id, (options) => {
      this.setState({ options }, () => {
        this.daysTable.forceReload();
      });
    });
  };

  _deleteShippingRate = (row) => {
    apiProducerShippingRateDelete(row.id, (options) => {
      this.setState({ options }, () => {
        this.ratesTable.forceReload();
      });
    });
  };

  closeDayDialog = (options, close = true) => {
    let state = {
      addingDay: close ? false : this.state.addingDay,
      editingDay: close ? null : this.state.editingDay,
    };

    if (options) {
      state['options'] = options;
    }

    this.setState(state, () => {
      if (options) {
        this.daysTable.forceReload();
      }
    });
  };

  closeRateDialog = (options) => {
    let state = {
      addingRate: false,
      editingRate: null,
    };

    if (options) {
      state['options'] = options;
    }

    this.setState(state, () => {
      if (options) {
        this.ratesTable.forceReload();
      }
    });
  };

  _handleProducerAddLocation = (location) => {
    this.setState({ loading: true });

    apiCreateProducerApprovedLocations(
      { locationId: location.id },
      (response) => {
        this.setState({ addingProducerLocation: false, producerLocations: response, loading: false }, () => {
          this.locationsTable.forceReload();
        });
      },
      (error) => {
        this.setState({ loading: false, locationError: error });
      }
    );
  };

  onFinishEditing = () => {
    this.getProducerLocations(() => {
      this.setState({ addingProducerLocation: false, editingLocation: null });
    });
  };

  _deleteProducerLocation = (location) => {
    this.setState({ loading: true });

    apiDeleteProducerApprovedLocation(
      location.id,
      (response) => {
        this.setState({ producerLocations: response, loading: false }, () => {
          this.locationsTable.forceReload();
        });
      },
      (error) => {
        this.setState({ loading: false, locationError: error });
      }
    );
  };

  _handleLocationsUpdate = (locations) => {
    this.setState({ locations });
  };

  _handleOpenLocationToEdit = (location) => {
    this.setState({ editingLocation: location, addingProducerLocation: true });
  };

  onAllocatePickupOrders = (e) => {
    this.setState(
      {
        allocatePickupOrders: e.target.checked,
        sendingRequest: true,
      },
      () => {
        apiSetDeliveryCompanySettings(
          {
            allocatePickupOrders: e.target.checked,
          },
          () => {
            this.setState({
              sendingRequest: false,
            });
          },
          () => {
            this.setState({
              allocatePickupOrders: !e.target.checked,
              sendingRequest: false,
            });
          }
        );
      }
    );
  };

  render() {
    const { classes, intl } = this.props;
    const { addingProducerLocation, producerLocations } = this.state;

    return (
      <Wrapper>
        {this.state.loading && (
          <div className={classes.spinnerWrapper}>
            <Spinner size={80} />
          </div>
        )}

        {this.state.options && (
          <div className={classes.wrapper}>
            <div className={classes.titleWrapper}>
              <Typography className={classes.title} variant={'h5'}>
                {intl.formatMessage({ id: 'global.deliveryAndPickups' })}
              </Typography>
              {this.state.options.days.length < 7 && (
                <IconButton className={classes.add} onClick={() => this.setState({ addingDay: true })}>
                  <PlusIcon color="secondary" />
                </IconButton>
              )}
            </div>

            <Paper className={classes.deliveryDaysWrapper}>
              <AdminDXTable
                onRef={(table) => (this.daysTable = table)}
                cellRenderer={DeliveryDayTableCell}
                rowRenderer={DeliveryDayTableRow}
                columnExtensions={[
                  { columnName: 'selection', width: 60 },
                  { columnName: 'location', width: 200 },
                ]}
                enableToolbar={false}
                enableSearch={false}
                enableTree
                treeSelectionColumn={'selection'}
                getTreeChildRows={(row, rootRows) => {
                  if (row) {
                    if (row.locations) {
                      return row.locations.map((location) => {
                        return {
                          ...location,
                          id: `${row.id}-${location.id}`,
                          showActions: () => false,
                        };
                      });
                    }
                    return row.locations;
                  }
                  return rootRows;
                }}
                getExpandedRowIds={(rows) => {
                  let ids = [];
                  rows.forEach((row) => ids.push(row.id));
                  return ids;
                }}
                apiRetrieve={(params, onSuccess) => onSuccess(this.state.options.days || [])}
                pageSize={60}
                pageSizes={[60]}
                columns={this.daysColumns}
                actionsTitle={' '}
                actionsHeaderCellStyle={{
                  textAlign: 'center',
                  paddingRight: 0,
                }}
                actionsCellStyle={{ textAlign: 'center', paddingRight: 0 }}
                actions={[
                  {
                    icon: <EditIcon style={{ color: COLORS.text, fontSize: 18 }} />,
                    action: (row) => this.setState({ editingDay: row }),
                  },
                  {
                    icon: <DeleteIcon style={{ color: COLORS.text, fontSize: 18 }} />,
                    action: (row) => this._deleteShippingDay(row),
                  },
                ]}
              />
            </Paper>
          </div>
        )}

        <div className={classes.wrapper}>
          <div className={classes.titleWrapper}>
            <Typography className={classes.title} variant={'h5'}>
              {intl.formatMessage({ id: 'global.locations' })}
            </Typography>
            <IconButton className={classes.add} onClick={() => this.setState({ addingProducerLocation: true })}>
              <PlusIcon color="secondary" />
            </IconButton>
          </div>

          <Paper className={classes.accounts}>
            <AdminDXTable
              onRef={(table) => (this.locationsTable = table)}
              enableToolbar={false}
              enableSearch={false}
              apiRetrieve={(params, onSuccess) => onSuccess(producerLocations || [])}
              pageSize={0}
              pageSizes={[10, 25, 0]}
              columns={this.locationsColumns}
              columnExtensions={[{ columnName: 'name', width: 250 }]}
              actionsTitle={' '}
              actionsHeaderCellStyle={{
                textAlign: 'center',
                paddingRight: 0,
              }}
              actionsCellStyle={{ textAlign: 'center', paddingRight: 0 }}
              actions={[
                {
                  icon: <EditIcon style={{ color: COLORS.text, fontSize: 18 }} />,
                  action: (row) => this._handleOpenLocationToEdit(row),
                },
                {
                  icon: <DeleteIcon style={{ color: COLORS.text, fontSize: 18 }} />,
                  action: (row) => this._deleteProducerLocation(row),
                },
              ]}
            />
          </Paper>
        </div>

        {addingProducerLocation && (
          <ProducerSearchLocationDialog
            onClose={() => this.setState({ addingProducerLocation: false, editingLocation: null })}
            locations={this.state.locations || []}
            onDone={this._handleProducerAddLocation}
            onLocationsUpdate={this._handleLocationsUpdate}
            editingLocation={this.state.editingLocation}
            selected={producerLocations.map((location) => location.id)}
            setEditingLocation={(editingLocation) => {
              this.setState({ editingLocation });
            }}
            onFinishEditing={this.onFinishEditing}
          />
        )}

        {/*{this.state.options && (*/}
        {/*  <div className={classes.wrapper}>*/}
        {/*    <div className={classes.titleWrapper}>*/}
        {/*      <Typography className={classes.title}*/}
        {/*                  variant={"h5"}>{intl.formatMessage({id: "global.deliveryRates"})}</Typography>*/}

        {/*      <IconButton className={classes.add} onClick={() => this.setState({addingRate: true})}>*/}
        {/*        <PlusIcon color='secondary' />*/}
        {/*      </IconButton>*/}
        {/*    </div>*/}

        {/*    <Paper className={classes.accounts}>*/}
        {/*      <AdminDXTable*/}
        {/*        onRef={table => this.ratesTable = table}*/}
        {/*        cellRenderer={DeliveryRateTableCell}*/}
        {/*        enableToolbar={false}*/}
        {/*        enableSearch={false}*/}
        {/*        apiRetrieve={(params, onSuccess) =>*/}
        {/*          onSuccess(this.state.options.rates || [])}*/}
        {/*        pageSize={5}*/}
        {/*        pageSizes={[5,10,15]}*/}
        {/*        columns={this.ratesColumns}*/}
        {/*        actionsTitle={" "}*/}
        {/*        actionsHeaderCellStyle={{textAlign: 'center', paddingRight: 0}}*/}
        {/*        actionsCellStyle={{textAlign: 'center', paddingRight: 0}}*/}
        {/*        actions={[*/}
        {/*          {*/}
        {/*            icon: <EditIcon style={{color: COLORS.text, fontSize: 18}} />,*/}
        {/*            action: row => this.setState({editingRate: row}),*/}
        {/*          },*/}
        {/*          {*/}
        {/*            icon: <DeleteIcon style={{color: COLORS.text, fontSize: 18}} />,*/}
        {/*            action: row => this._deleteShippingRate(row),*/}
        {/*          },*/}
        {/*        ]}*/}
        {/*      />*/}
        {/*    </Paper>*/}
        {/*  </div>*/}
        {/*)}*/}

        <Block
          paperClass={classNames(classes.mr0, classes.mb40)}
          contentClass={classes.contentWithSwitch}
          title={intl.formatMessage({ id: 'account.deliverySettings.deliveryCompanySettings' })}>
          <Typography className={classes.text} variant={'body1'}>
            {intl.formatMessage({ id: 'account.deliverySettings.allocatePickupOrders' })}
          </Typography>

          <FormControlLabel
            classes={{
              label: classes.smallLabel,
              root: classes.smallLabelRoot,
            }}
            labelPlacement="bottom"
            control={
              <PrimarySwitch
                checked={this.state.allocatePickupOrders}
                disabled={this.state.sendingRequest}
                onChange={this.onAllocatePickupOrders}
              />
            }
            label={
              this.state.allocatePickupOrders
                ? intl.formatMessage({ id: 'account.deliverySettings.allocate' })
                : intl.formatMessage({ id: 'account.deliverySettings.notAllocate' })
            }
          />
        </Block>

        {this.state.addingDay && (
          <DeliveryDayDialog
            entity={{}}
            days={this.state.options.days || []}
            onClose={() => this.closeDayDialog(null)}
            onDone={(options, close) => this.closeDayDialog(options, close)}
          />
        )}

        {this.state.addingRate && (
          <DeliveryRateDialog
            entity={{}}
            onClose={() => this.closeRateDialog(null)}
            onDone={(options) => this.closeRateDialog(options)}
          />
        )}

        {this.state.editingDay && (
          <DeliveryDayDialog
            entity={this.state.editingDay}
            days={this.state.options.days || []}
            onClose={() => this.closeDayDialog(null)}
            onDone={(options, close) => this.closeDayDialog(options, close)}
          />
        )}

        {this.state.editingRate && (
          <DeliveryRateDialog
            entity={this.state.editingRate}
            onClose={() => this.closeRateDialog(null)}
            onDone={(options) => this.closeRateDialog(options)}
          />
        )}
        {!!this.state.locationError && (
          <ErrorDialog
            open
            error={this.state.locationError}
            onClose={() => {
              this.setState({ locationError: '' });
            }}
          />
        )}
      </Wrapper>
    );
  }
}

export default injectIntl(
  withRouter(
    connect((state) => {
      return { producer: state.producer.object };
    })(withStyles(styles)(DeliveryTab))
  )
);
