import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowIcon } from '../../assets';
import { BORDER_RADIUS_MAIN } from '../../constants/globals';
import { COLORS } from '../../constants';
import { FONT_CART_VARIANT_TITLE, FONT_ORDER_NAME, FONT_ORDER_TOTAL } from '../../constants/fonts';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },

  singleItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: 20,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 5,
    paddingRight: 5,
    marginLeft: -5,
    marginRight: -5,
    boxSizing: 'border-box',
    borderRadius: BORDER_RADIUS_MAIN,
    cursor: 'pointer',
    backgroundColor: COLORS.white,
    transition: 'background-color 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: COLORS.backgroundLight,
    },
  },
  itemContentWrapper: {
    flex: 1,
  },
  itemTitle: {
    ...FONT_CART_VARIANT_TITLE,
    marginBottom: 5,
    color: COLORS.blackTintedBlue,
  },
  itemSubTitle: {
    ...FONT_ORDER_NAME,
    color: COLORS.grey,
    marginBottom: 5,
  },
  itemContent: {
    ...FONT_ORDER_TOTAL,
    color: COLORS.blackTintedBlue,
  },
  arrowForward: {
    paddingLeft: 20,
  },
  emptyMessage: {
    ...FONT_ORDER_NAME,
    marginTop: 20,
    marginBottom: 20,
  },
}));

const propTypes = {
  unlisted: PropTypes.bool,
};

const ItemsList = (props) => {
  const { items, itemBodyContentRenderer, onItemClick, emptyMessage } = props;

  const classes = useStyles();

  const drawSingleItem = (item, index) => {
    const key = `item_${item?.id || index}`;
    const { title, content, subTitle } = itemBodyContentRenderer(item);
    return (
      <div key={key} className={classes.singleItem} onClick={() => onItemClick(item)}>
        <div className={classes.itemContentWrapper}>
          {title && <div className={classes.itemTitle}>{title}</div>}
          {subTitle && <div className={classes.itemSubTitle}>{subTitle}</div>}
          {content && <div className={classes.itemContent}>{content}</div>}
        </div>
        <div className={classes.arrowForward}>
          <ArrowIcon />
        </div>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      {!!items?.length && items.map(drawSingleItem)}
      {!items?.length && !!emptyMessage && <p className={classes.emptyMessage}>{emptyMessage}</p>}
    </div>
  );
};

ItemsList.propTypes = propTypes;

export default ItemsList;
