import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../../hocs';
import {
  AdminDialogTitle,
  AdminDialogContent,
  AdminDialogControls,
  PrimaryButton,
  Spinner,
  ErrorBox,
} from '../../../components';
import styles from './styles';
import EntityForm from '../../../scenes/SettingsScene/Dialogs/LocationsDialog/EntityForm/EntityForm';
import { apiProducerLocationsCreate, apiProducerLocationsGetList, apiProducerLocationsPatch } from '../../../api';

const propTypes = {
  entity: PropTypes.object,
  onDone: PropTypes.func,
  onLocationsUpdate: PropTypes.func,
};

const AddLocationStage = ({ intl, classes, onDone, entity, onLocationsUpdate }) => {
  const [formRef, setFormRef] = useState(() => {});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const _validate = () => {
    const form = formRef.validate();
    if (form.validated) {
      setLoading(true);
      setError('');

      let data = {
        ...form.data,
      };

      if (entity && entity.id) {
        apiProducerLocationsPatch(
          entity.id,
          data,
          (location) => {
            onDone(location);

            apiProducerLocationsGetList((locations) => {
              onLocationsUpdate(locations);
            });
          },
          () => setLoading(false)
        );
      } else {
        apiProducerLocationsCreate(
          data,
          (location) => {
            onDone(location);

            apiProducerLocationsGetList((locations) => {
              onLocationsUpdate(locations);
            });
          },
          () => setLoading(false)
        );
      }
    } else {
      setLoading(false);
      setError(form.error);
    }
  };

  return (
    <React.Fragment>
      <AdminDialogTitle
        title={intl.formatMessage({
          id: entity && entity.id ? 'search.location.editMarketDialogTitle' : 'search.location.addMarketDialogTitle',
        })}
      />
      <AdminDialogContent className={classes.wrapper}>
        {loading && <Spinner size={60} />}
        {error && <ErrorBox error={error} />}

        <EntityForm data={entity} locations={[]} onRef={setFormRef} />
      </AdminDialogContent>

      <AdminDialogControls>
        <PrimaryButton className={classes.button} onClick={_validate}>
          {intl.formatMessage({ id: 'global.save' })}
        </PrimaryButton>
      </AdminDialogControls>
    </React.Fragment>
  );
};

AddLocationStage.propTypes = propTypes;
AddLocationStage.defaultProps = {
  locations: [],
};

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(AddLocationStage)));
