import constants from '../constants';

export default class DXFilterConfig {
  constructor(props) {
    this._name = props.name;
    this._property = props.property;
    this._title = props.title;
    this._column = props.column || props.title;
    this._disabled = props.disabled;
    this._values = [];
    this._selectedValues = props.selected || {};
    this._variants = props.variants || null;
    this._shared = props.shared || {};
    this._multi = props.multi ? props.multi : false;
    this._equalityStatus = props.equalityStatus;
    this._selectedEquality =
      typeof props.selectedEquality !== 'undefined' ? props.selectedEquality : constants.FILTER_EQUALITY_EQUALS;
    this._queryParams = props.queryParams || null;
    this._readOnly = props.readOnly;
  }

  setSelectedValues(values) {
    this._selectedValues = values;
  }

  setSelectedEquality(equality) {
    this._selectedEquality = equality;
  }

  addFilterValue(key, translation) {
    this._values.push({ key: key, label: translation });
  }

  getConfig() {
    return {
      name: this._name,
      disabled: this._disabled,
      property: this._property,
      column: this._column,
      values: this._values.map((v) => v.key),
      selectedValues: this._selectedValues,
      selectedEquality: this._selectedEquality,
      queryParams: this._queryParams,
      readOnly: this._readOnly,
    };
  }

  get multi() {
    return this._multi;
  }

  get selected() {
    return this._selectedValues;
  }

  get shared() {
    return this._shared;
  }

  get equalityStatus() {
    return this._equalityStatus;
  }

  get selectedEquality() {
    return this._selectedEquality;
  }

  get name() {
    return this._name;
  }

  get disabled() {
    return this._disabled;
  }

  get property() {
    return this._property;
  }

  get variants() {
    return this._variants;
  }

  get title() {
    return this._title;
  }

  get values() {
    return this._values;
  }
}
