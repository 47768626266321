import React from 'react'
import PropTypes from 'prop-types'
import {injectIntl} from 'react-intl'
import {withStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {COLORS} from '../../../helpers/index'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/ErrorOutline'


const styles = ({
  text: {
    fontSize: 14,
    color: COLORS.textSecondary,
    fontFamily: "Roboto, sans-serif",
  },

  okIcon: {
    fill: COLORS.text,
    fontSize: 28,
    marginRight: 10
  },

  errorIcon: {
    fill: COLORS.violet,
    fontSize: 28,
    marginRight: 10
  },

  ssn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    fontSize: 14,
    color: COLORS.textSecondary,
    fontFamily: "Roboto, sans-serif",
  },

  icon: {
    color: COLORS.text,
    fontSize: 18
  },

  wrapper: {
    position: 'relative'
  },

  controls: {
    position: 'absolute',
    top: 0,
    right: 0,
  }

});

const propTypes = {
  classes: PropTypes.object.isRequired,
  person: PropTypes.object.isRequired,
};

const PersonAccount = ({ person,
                         intl,
                         classes }) => {
  return (
    <div className={classes.wrapper}>
      <Typography className={classes.text} variant={"body1"}>
        {person.first_name || ""}
      </Typography>
      <Typography className={classes.text} variant={"body1"}>
        {person.last_name || ""}
      </Typography>
      <Typography className={classes.text} variant={"body1"}>
        {(person.relationship && person.relationship.title) || ""}
      </Typography>
      <Typography className={classes.text} variant={"body1"}>
        {person.phone || ""}
      </Typography>
      <Typography className={classes.text} variant={"body1"}>
        {person.email || ""}
      </Typography>
      <Typography className={classes.ssn} variant={"body1"}>
        {person.ssn_last_4_provided ?
          <CheckCircleIcon className={classes.okIcon} /> :
          <ErrorIcon className={classes.errorIcon} />
        }
        {person.ssn_last_4_provided ?
          intl.formatMessage({id: "business.SsnLast4Provided"}) :
          intl.formatMessage({id: "business.SsnLast4NotProvided"})}
      </Typography>
    </div>
  );
};

PersonAccount.propTypes = propTypes;

export default withStyles(styles)(
  injectIntl(PersonAccount)
);

