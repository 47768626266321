import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { BORDER_RADIUS_MAIN, TRANSITION_MAIN } from '../../constants/globals';
import { FONT_FAMILY, FONT_SIZES } from '../../constants/fonts';
import { COLORS } from '../../constants/colors';

const useStyles = makeStyles(() => ({
  itemWrapper: {
    paddingLeft: 10,
    paddingRight: 10,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 40,
    textDecoration: 'none',
    transition: TRANSITION_MAIN,
    borderRadius: BORDER_RADIUS_MAIN,
  },
  itemIcon: {
    marginRight: 20,
  },
  textWrapper: {
    flex: 1,
  },
  itemTitle: {
    fontSize: FONT_SIZES.text,
    color: COLORS.text,
    fontFamily: FONT_FAMILY,
    fontWeight: 700,
  },
  itemText: {
    fontSize: FONT_SIZES.text,
    color: COLORS.text,
    fontFamily: FONT_FAMILY,
  },
  itemSubText: {
    color: COLORS.grey + ' !important',
    marginTop: 5,
  },
}));

const IconSeparatedTextList = (props) => {
  const { icon, iconColor = COLORS.blackTintedBlue, title, text } = props;
  const Icon = icon;
  const classes = useStyles();

  return (
    <div className={classes.itemWrapper}>
      <div className={classes.itemIcon}>
        <Icon className={'supplier-menu-svg-icon'} color={iconColor} />
      </div>
      <div className={classes.textWrapper}>
        <p className={classes.itemTitle}>{title}</p>
        {!!text && <p className={classNames(classes.itemText, classes.itemSubText)}>{text}</p>}
      </div>
    </div>
  );
};

IconSeparatedTextList.propTypes = {
  icon: PropTypes.elementType,
  iconColor: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
};

export default IconSeparatedTextList;
