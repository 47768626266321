import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {injectIntl} from 'react-intl'
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import PrimaryTextField from '../PrimaryTextField/PrimaryTextField'
import {withStyles} from '@material-ui/core/styles';
import ErrorBox from "../ErrorBox/ErrorBox";

const styles = ({
  textField: {
    margin: '15px 0 4px 0',
  },
  actionsWrap: {
    marginTop: 50,
    marginBottom: 20,
    justifyContent: 'center',
    display: 'flex'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
})

class CustomerAccountForm extends Component {

  constructor(props) {
    super(props)
    const { account } = this.props
    this.state = { account }
  }

  _handleChange = (key, value) => {
    this.setState({
      account: {
        ...this.state.account,
        [key]: value
      }
    })
  }

  _handleSubmit = e => {
    e.preventDefault()
    const { account } = this.state
    this.props.onSubmit(account)
  }

  render () {
    const { intl, classes } = this.props
    const { account } = this.state

    return (
      <form onSubmit={this._handleSubmit} className={classes.form}>
        {this.props.requestError && (
          <ErrorBox error={this.props.requestError.response.data.message}/>
        )}

        <PrimaryTextField
          value={account.email}
          label={intl.formatMessage({ id: 'global.email' }) + ' *'}
          onChange={event => this._handleChange('email', event.target.value)}
          className={classes.textField}
        />
        <PrimaryTextField
          value={account.firstName}
          label={intl.formatMessage({ id: 'global.firstName' })}
          onChange={event => this._handleChange('firstName', event.target.value)}
          className={classes.textField}
        />
        <PrimaryTextField
          value={account.lastName}
          label={intl.formatMessage({ id: 'global.lastName' })}
          onChange={event => this._handleChange('lastName', event.target.value)}
          className={classes.textField}
        />
        <div className={classes.actionsWrap}>
          <PrimaryButton type='submit'>
            {
              account.id === 'new'
                ? intl.formatMessage({ id: 'customer.createAccount' })
                : intl.formatMessage({ id: 'customer.editAccount' })
            }
          </PrimaryButton>
        </div>
      </form>
    )
  }
}

CustomerAccountForm.propTypes = {
  requestError: PropTypes.object,
  account: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default withStyles(styles)(
  injectIntl(CustomerAccountForm)
)
