import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { toCurrency } from '../../../helpers';

const styles = ({
  cell: {
    textTransform: 'capitalize'
  }
});

const propTypes = {
  row: PropTypes.object.isRequired,
};

const Cell = ({intl, row, classes, ...other}) =>  {
  return (
    <Table.Cell {...other}>
      <span className={classes.cell}>{toCurrency(row.fee, intl)}</span>
    </Table.Cell>
  );
};

Cell.propTypes = propTypes;

export default withStyles(styles)(
    injectIntl(Cell)
);
