import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../../hocs';
import {
  AdminDialog,
  AdminDialogTitle,
  AdminDialogContent,
  AdminDialogControls,
  PrimaryButton,
  FileUpload,
  ErrorBox,
} from '../../../components';
import { COLORS } from '../../../helpers';
import { apiImportFields } from '../../../api';

const styles = {
  wrapper: {},
  name: {
    fontSize: 18,
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.text,
    marginBottom: 40,
  },
  variants: {
    marginBottom: 50,
  },
  input: {
    marginBottom: 25,
  },
  inputText: {
    textAlign: 'right',
  },
  button: {
    minWidth: '40%',
    textTransform: 'uppercase',
  },
  mr20: {
    marginRight: 10,
  },
  controls: {
    margin: '27px 0 0 0',
    textAlign: 'center',
  },
  control: {
    padding: '0 105px',
  },
  image: {
    display: 'inline-block',
    width: 300,
    maxHeight: 400,
  },
  imageWrapper: {
    textAlign: 'center',
  },

  successMessageWrapper: {
    marginTop: 20,
  },
  successMessageText: {
    fontSize: 18,
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.text,
    marginBottom: 40,
    textAlign: 'center',
  },
  errorBoxWrapper: {
    marginTop: 40,
    marginBottom: 0,
  },
};

const propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

class ImportFieldsDialog extends React.Component {
  state = {
    loading: false,
    file: null,
    error: '',
    successMessage: '',
  };

  validate = () => {
    const { onSave, intl } = this.props;

    this.setState({ loading: true, error: '' });

    apiImportFields(
      this.state.file,
      (response) => {
        this.setState({
          successMessage: response.message || intl.formatMessage({ id: 'file.fileSuccessfullyImported' }),
          loading: false,
        });

        onSave && onSave(response);
      },
      (error) => {
        let message = intl.formatMessage({ id: 'file.somethingWentWrongDuringImportProcess' });

        if (error?.response?.status === 412) {
          message = error?.response?.data?.message;
        }

        this.setState({
          loading: false,
          error: message,
        });
      }
    );
  };

  onDone = () => {
    this.props.onClose();
  };

  render() {
    const { intl, classes, onClose } = this.props;
    const { loading, file, successMessage, error } = this.state;

    return (
      <AdminDialog open closeButton onClose={onClose}>
        <AdminDialogTitle title={intl.formatMessage({ id: 'setting.import.fields' })} />
        <AdminDialogContent className={classes.wrapper}>
          {!successMessage && (
            <div className={classes.imageWrapper}>
              <div className={classes.image}>
                <FileUpload file={file} accept={'.csv'} onUploaded={(file) => this.setState({ file: file })} />
              </div>
            </div>
          )}

          {!!successMessage && (
            <div className={classes.successMessageWrapper}>
              <p className={classes.successMessageText}>{successMessage}</p>
            </div>
          )}

          {!!error && <ErrorBox error={error} className={classes.errorBoxWrapper} />}

          {!!file && (
            <AdminDialogControls>
              {!successMessage ? (
                <PrimaryButton className={classes.button} onClick={() => this.validate()} isLoading={loading}>
                  {intl.formatMessage({ id: 'global.import' })}
                </PrimaryButton>
              ) : (
                <PrimaryButton className={classes.button} onClick={() => this.onDone()} isLoading={loading}>
                  {intl.formatMessage({ id: 'global.ok' })}
                </PrimaryButton>
              )}
            </AdminDialogControls>
          )}
        </AdminDialogContent>
      </AdminDialog>
    );
  }
}

ImportFieldsDialog.propTypes = propTypes;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(ImportFieldsDialog)));
