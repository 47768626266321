import { COLORS } from '../../../../helpers';

const styles = (theme) => ({
  wrapper: {},
  name: {
    fontSize: 18,
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.text,
    marginBottom: 40,
  },
  variants: {
    marginBottom: 50,
  },
  input: {
    marginBottom: 25,
  },
  inputText: {
    textAlign: 'right',
  },
  button: {
    minWidth: '40%',
    textTransform: 'uppercase',
  },
  mr20: {
    marginRight: 10,
  },
  controls: {
    margin: '27px 0 0 0',
    textAlign: 'center',
  },
  control: {
    padding: '0 105px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    flex: 1,
  },

  viewElementWrapper: {
    marginBottom: 20,
    width: '100%',
  },
  viewElementPaper: {
    marginRight: 0,
  },
  viewElementWrapperLeft: {
    marginRight: 10,
  },
  viewElementWrapperRight: {
    marginLeft: 10,
  },
  editDialogPaper: {
    padding: '47px 52px 74px',
    borderRadius: 8,
    maxHeight: 'calc(100% - 16px)',
    [theme.breakpoints.down('md')]: {
      minWidth: 600,
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 900,
    },
  },

  copyLinkWrapper: {
    padding: 0,
    margin: 0,
    width: '100%',
  },
  subWrapperClass: {
    paddingBottom: 0,
  },
});

export default styles;
