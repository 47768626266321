export default (theme) => ({
  date: {},
  errorBoxWrapper: {
    marginTop: 10,
    maxWidth: 400,
  },
  paper: {
    padding: 26,

    borderRadius: 10,
    maxHeight: 'calc(100% - 16px)',
    [theme.breakpoints.down('md')]: {
      margin: 10,
      minWidth: 344,
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 450,
    },
    boxShadow: 'box-shadow: 0px 14px 24px 0px #00000026',
    '& *': {
      fontFamily: 'Inter, sans-serif',
    },
  },

  pickerWrapper: {
    width: '100%',
    borderBottom: '1px solid #00000028',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'space-between',
  },
  title: {
    padding: 0,
    margin: 0,

    fontSize: 24,
    fontWeight: 700,
  },
  close: {
    borderRadius: 30,
    cursor: 'pointer',
    padding: 4,
    width: 24,
    height: 24,
    boxSizing: 'content-box',
  },

  contentWrapper: {
    padding: 0,
    margin: 0,
    marginTop: 35,
    marginBottom: 35,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'visible',
  },

  actionsWrapper: {
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  nextDateWrapper: {},
  nextDateText: {
    fontSize: 18,
    fontWeight: 600,
    color: '#222',
  },
  actionsChooserWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: 35,
  },
  action: {
    cursor: 'pointer',
    fontSize: 16,
    fontWeight: 700,
    color: '#216CF6',
    paddingRight: 10,
    '&:not(:first-child)': {
      paddingLeft: 10,
      borderLeft: '1px solid #00000028',
    },
  },
  actionsTextWrapper: {
    marginTop: 15,
  },
  actionsText: {
    fontSize: 14,
    fontWeight: 400,
  },
  buttonWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonRoot: {
    boxShadow: 'none',
    width: 'auto',
    minWidth: 'auto',
  },
  button: {
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: 18,
    fontFamily: 'Inter, sans-serif',
  },
  availableDay: {
    '& > button': {
      backgroundColor: '#F1F5FE',
    },
  },
  moveFromDay: {
    '& > button': {
      border: '1px solid #1F63DE',
    },
  },
  mr20: {
    marginRight: 20,
  },

  absolutePickerRoot: {
    position: 'fixed',
    right: 0,
    top: 0,
    zIndex: 10000,
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'auto',
  },
  absolutePickerBackdrop: {
    position: 'fixed',
    right: 0,
    top: 0,
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  absolutePicker: {
    position: 'relative',
    zIndex: 100001,
    backgroundColor: 'white',
    boxShadow: '0px 14px 24px 0px #00000026',
    borderRadius: 9,
    overflow: 'hidden',
  },
  absolutePickerWrapper: {
    padding: 26,

    borderRadius: 10,
    maxHeight: '95vh',
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      margin: 10,
      minWidth: 344,
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 450,
    },
    boxShadow: 'box-shadow: 0px 14px 24px 0px #00000026',
    '& *': {
      fontFamily: 'Inter, sans-serif',
    },
  },
});
