import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/core';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';

const propTypes = {
  onValueChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  getMessage: PropTypes.func.isRequired,
};

const defaultProps = {
  value: '',
  title: '',
};

const theme = createTheme({
  overrides: {
    MuiFormLabel: {
      root: {},
    },
    MuiInput: {
      root: {
        borderRadius: 9,
        minWidth: 260,
        padding: 5,
        paddingBottom: 3,
        paddingLeft: 10,
        paddingRight: 10,
        border: '1px solid #cecece',
        backgroundColor: '#f7f7f7',
      },
      input: {
        padding: 5,
        paddingLeft: 0,
        fontSize: 14,
        margin: 0,
        fontFamily: "'Roboto', sans-serif",
        '&::placeholder': {
          color: 'rgba(0, 0, 0, 0.5)',
          opacity: 1,
        },
      },
      underline: {
        '&:after': {
          content: '',
        },

        '&:before': {
          content: '',
        },
      },
    },
    MuiInputBase: {
      multiline: {},
    },
  },
});

const useStyles = makeStyles(() => ({
  searchFieldWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 20,
    color: '#363636',
    marginTop: 0,
    marginBottom: 0,
  },
  searchIcon: {
    fontSize: 22,
    color: 'rgba(0, 0, 0, 0.5)',
  },
  clearIcon: {
    fontSize: 16,
    color: 'rgba(0, 0, 0, 0.5)',
  },
  iconButton: {
    margin: 0,
    marginRight: -10,
  },
}));

const SearchField = (props) => {
  const { title, onValueChange, getMessage, value, placeholder, searchFieldWrapperClass, searchValue, ...rest } = props;
  const classes = useStyles();
  const [query, setQuery] = useState(value || searchValue || ''); // It's needed to load last search value after unmount search component

  useEffect(() => {
    onValueChange(query);
  }, [query]);

  const onClear = () => {
    onValueChange('', true);
    setQuery('');
  };

  return (
    <div className={classNames([classes.searchFieldWrapper, searchFieldWrapperClass])}>
      {title && <h1 className={classes.title}>{title}</h1>}
      <MuiThemeProvider theme={theme}>
        <TextField
          onChange={(e) => {
            setQuery(e.target.value);
          }}
          value={query}
          placeholder={placeholder || getMessage('searchPlaceholder')}
          InputProps={{
            classes: { root: classes.inputRoot },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className={classes.searchIcon} />
              </InputAdornment>
            ),
            ...(query
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      {query ? (
                        <IconButton onClick={onClear} className={classes.iconButton}>
                          <ClearIcon className={classes.clearIcon} />
                        </IconButton>
                      ) : (
                        <div />
                      )}
                    </InputAdornment>
                  ),
                }
              : {}),
          }}
          {...rest}
        />
      </MuiThemeProvider>
    </div>
  );
};

SearchField.propTypes = propTypes;
SearchField.defaultProps = defaultProps;

export default SearchField;
