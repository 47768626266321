import React, { useEffect, useState } from 'react';
import { Title, HeaderArea, ItemsList, Footer } from '../../components';
import { makeStyles } from '@material-ui/core/styles';
import {
  PADDING_CONTENT,
  PADDING_CONTENT_TOP,
  CUSTOMER_TYPES,
  ORDER_DATE_FORMAT,
  SHIPPING_TYPES,
} from '../../constants/globals';
import { useIntl } from 'react-intl';
import { FONT_CART_VARIANT_TITLE, FONT_SUB_TITLE } from '../../constants/fonts';
import { COLORS } from '../../constants';
import { apiGetShippingItems } from '../../api';
import moment from 'moment';
import { withRouter } from '../../hocs';
import { isCustomer, isLocation, makeStringAddress } from '../../helpers/global';
import { useDispatch, useSelector } from 'react-redux';
import { setDeliveriesLoading } from '../../store/actions';
import { OrdersSkeleton } from '../../components/SkeletonTemplates';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  content: {
    paddingLeft: PADDING_CONTENT,
    paddingRight: PADDING_CONTENT,
    paddingTop: PADDING_CONTENT_TOP,
  },
  title: {
    marginBottom: 10,
  },
  subTitle: {
    ...FONT_SUB_TITLE,
    marginBottom: 10,
  },
  buttonWrapper: {
    marginBottom: 20,
    paddingLeft: PADDING_CONTENT,
    paddingRight: PADDING_CONTENT,
  },
  error: {
    ...FONT_CART_VARIANT_TITLE,
    color: COLORS.danger,
    paddingBottom: 20,
    paddingTop: 20,
  },
}));

const OrdersScene = (props) => {
  const { history } = props;
  const intl = useIntl();
  const classes = useStyles();
  const { day, itemId } = props.params;

  const [initialLoading, setInitialLoading] = useState(true);
  const [items, setItems] = useState(null);
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const onLoading = (isLoading) => dispatch(setDeliveriesLoading(isLoading));
  const { loading } = useSelector((state) => {
    return {
      loading: state.deliveriesApp.loading,
    };
  });

  useEffect(
    () => {
      onLoading(false);

      getOrders();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getOrders = () => {
    setInitialLoading(true);

    const params = {
      'shipping:from': moment(day).startOf('day').toISOString(true),
      'shipping:to': moment(day).endOf('day').toISOString(true),
      sort: ['customer.companyName:asc', 'customer.firstName:asc'],
      withOrders: true,
    };

    if (isLocation(itemId)) {
      params['locationId'] = itemId;
      params['shippingType'] = [SHIPPING_TYPES.SHIPPING_PICKUP];
    } else if (isCustomer(itemId)) {
      params['customerIds'] = [itemId];
      params['shippingType'] = [SHIPPING_TYPES.SHIPPING_DELIVERY];
    }

    apiGetShippingItems(
      params,
      (response) => {
        setItems(response);
        setInitialLoading(false);
      },
      (error) => {
        setInitialLoading(false);
        setError(error?.response?.data?.message || intl.formatMessage({ id: 'deliveries.somethingWentWrong' }));
      }
    );
  };

  const drawDate = (date) => {
    return moment.parseZone(date).format(ORDER_DATE_FORMAT);
  };

  const drawCustomerName = (customer) => {
    if (customer?.type === CUSTOMER_TYPES.WHOLESALE) return customer?.companyName || '';
    if (customer?.type === CUSTOMER_TYPES.ANONYMOUS) return intl.formatMessage({ id: 'deliveries.sales.anonymous' });

    return `${customer?.firstName ? customer.firstName + ' ' : ''}${customer?.lastName ? customer.lastName : ''}${
      customer?.email ? ' / ' + customer.email : ''
    }${customer?.phone ? ' / ' + customer.phone : ''}`;
  };

  const drawNumberOfItems = (order) => {
    const numberOfItems = order?.lineItems?.length || 0;

    if (numberOfItems < 2)
      return intl.formatMessage({ id: 'deliveries.sales.numberOfItemsSingle' }, { amount: numberOfItems });

    return intl.formatMessage({ id: 'deliveries.sales.numberOfItems' }, { amount: numberOfItems });
  };

  const itemBodyContentRenderer = (item, order) => {
    if (item?.shippingType === SHIPPING_TYPES.SHIPPING_DELIVERY) {
      return {
        title: `${order.name} / ${makeStringAddress(order.customer.shippingAddress)}`,
        content: `${drawNumberOfItems(order)}`,
      };
    }

    return {
      title: drawPickupCustomerName(order),
      content: `${drawNumberOfItems(order)}`,
    };
  };

  const drawPickupCustomerName = (order) => {
    if (order?.customer?.type === CUSTOMER_TYPES.WHOLESALE) return order.customer.companyName;
    if (order?.customer?.type === CUSTOMER_TYPES.ANONYMOUS)
      return intl.formatMessage({ id: 'pickups.sales.anonymous' });

    return `${order.customer.firstName ? order.customer.firstName + ' ' : ''}${
      order.customer.lastName ? order.customer.lastName : ''
    }`;
  };

  // Single customer expected
  const drawSingleShippingItem = (item) => {
    return (
      <React.Fragment key={item?.id}>
        {item?.shippingType === SHIPPING_TYPES.SHIPPING_DELIVERY && (
          <p
            className={classes.subTitle}
            style={{
              marginBottom: 20,
            }}>
            {drawCustomerName(item)}
          </p>
        )}

        {!error && (
          <ItemsList
            items={item?.orders || []}
            itemBodyContentRenderer={(order) => itemBodyContentRenderer(item, order)}
            onItemClick={(order) => history.push(`/app/deliveries/orders/${order?.id}`)}
            emptyMessage={intl.formatMessage({ id: 'deliveries.orders.ordersNotFound' })}
          />
        )}
      </React.Fragment>
    );
  };

  if (loading) {
    return null;
  }

  return (
    <div className={classes.root}>
      <HeaderArea onlyBackButton />

      {!initialLoading ? (
        <div className={classes.content}>
          <Title className={classes.title} text={intl.formatMessage({ id: 'deliveries.orders.title' })} />
          <p className={classes.subTitle}>{drawDate(day)}</p>
          {!!error && <p className={classes.error}>{error}</p>}
          {!error && !!items?.length && items.map((item) => drawSingleShippingItem(item))}
        </div>
      ) : (
        <OrdersSkeleton />
      )}

      <Footer />
    </div>
  );
};

export default withRouter(OrdersScene);
