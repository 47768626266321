import React from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import { COLORS } from '../../constants/colors';
import { FONT_LABEL } from '../../constants/fonts';
import { makeStyles } from '@material-ui/core/styles';

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiFormLabel: {
      root: {
        ...FONT_LABEL,
        '&$focused': {
          color: COLORS.blackTintedBlue,
        },
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: 'translate(0, 1.5px)',
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  coloredLabel: {
    fontWeight: '700',
    color: COLORS.brightBlue,
  },
}));

const SupplierInputLabel = (props) => {
  const classes = useStyles();
  const { children, coloredLabel, ...other } = props;
  return (
    <MuiThemeProvider theme={theme}>
      <InputLabel classes={{ root: coloredLabel ? classes.coloredLabel : '' }} {...other}>
        {children}
      </InputLabel>
    </MuiThemeProvider>
  );
};

export default SupplierInputLabel;
