import Tab from './Tab';
import { MAIN_TAB_NAMES } from '../../helpers/tabs';

/** Represents UI tabs */
class Tabs {
  getMainTabNames = () => {
    return {
      orders: [MAIN_TAB_NAMES.DRAFTS, MAIN_TAB_NAMES.ORDERS],
      products: [MAIN_TAB_NAMES.PRODUCTS_ALL, MAIN_TAB_NAMES.PRODUCTS_ACTIVE, MAIN_TAB_NAMES.PRODUCTS_ARCHIVE],
      discounts: [MAIN_TAB_NAMES.DISCOUNTS_ALL, MAIN_TAB_NAMES.DISCOUNTS_ACTIVE],
      unique_products: [MAIN_TAB_NAMES.UNIQUE_PRODUCTS_ALL, MAIN_TAB_NAMES.UNIQUE_PRODUCTS_ACTIVE],
      prepurchases: [MAIN_TAB_NAMES.PREPURCHASES_ALL],
      subscriptions: [MAIN_TAB_NAMES.SUBSCRIPTIONS_ALL, MAIN_TAB_NAMES.SUBSCRIPTIONS_REPORTS],
      customers: [
        MAIN_TAB_NAMES.CUSTOMERS_APPROVED,
        // MAIN_TAB_NAMES.CUSTOMERS_NOT_APPROVED,
        MAIN_TAB_NAMES.CUSTOMERS_GROUPED_BY_NAME,
        MAIN_TAB_NAMES.CUSTOMERS_RETAIL,
      ],
      reports: [MAIN_TAB_NAMES.REPORTS_ALL],
      supplier_orders: [MAIN_TAB_NAMES.SUPPLIERS_DRAFTS_ALL, MAIN_TAB_NAMES.SUPPLIERS_ORDERS_ALL],
      supplier_drafts: [MAIN_TAB_NAMES.SUPPLIERS_DRAFTS_ALL, MAIN_TAB_NAMES.SUPPLIERS_ORDERS_ALL],
      drafts: [MAIN_TAB_NAMES.DRAFTS, MAIN_TAB_NAMES.ORDERS],
    };
  };

  constructor(type, tabsConfig) {
    tabsConfig = tabsConfig || [];
    this.tabs = [];
    this.type = type;

    this.hashMap = this.getMainTabNames()[type];
    this.defaultNames = this.getMainTabNames()[type] || [];
    for (let i = 0; i < tabsConfig.length; i++) {
      if (tabsConfig[i].type === type) {
        if (this.hashMap.indexOf(tabsConfig[i].name) === -1) {
          this.hashMap.push(tabsConfig[i].id);
        }
        this.tabs.push(new Tab(tabsConfig[i]));
      }
    }
  }

  getHashByIndex = (index) => {
    return this.hashMap[index];
  };

  getIndexByHash = (hash) => {
    return this.hashMap?.indexOf(hash);
  };

  getInitialIndex = (hash, setHash) => {
    let index = 0;
    hash = hash || '';
    hash = hash.replace('#', '');

    if (!hash && setHash && this.hashMap?.length) {
      window.location.hash = this?.hashMap[0]; // TODO: refactor to normal navigation without pushing in history
    }

    if (this.getIndexByHash(hash) !== -1) {
      return this.getIndexByHash(hash);
    }

    for (let i = 0; i < this.tabs.length; i++) {
      if (this.tabs[i].default) {
        window.location.hash = this.tabs[i].id;
        const _index = this.getIndexByHash(this.tabs[i].id);
        return _index !== -1 ? _index : 0;
      }
    }

    return index;
  };

  getTabNames = (prepend, append) => {
    prepend = prepend || [];
    for (let i = 0; i < this.tabs.length; i++) {
      if (this.defaultNames.indexOf(this.tabs[i].name) === -1) {
        prepend.push(this.tabs[i].name);
      }
    }

    if (Array.isArray(append)) {
      prepend = [...prepend, ...append];
    }

    return prepend;
  };

  getOnlyCustom = () => {
    return this.tabs.filter((tab) => this.defaultNames.indexOf(tab.name) === -1);
  };

  getTabs = (skipNames) => {
    return this.tabs.filter((tab) => skipNames.indexOf(tab.name) === -1);
  };

  getTabByName = (name, replaceName, defaultObject) => {
    let filtered = this.tabs.filter((tab) => tab.name === name);

    if (filtered.length) {
      let tab = JSON.parse(JSON.stringify(filtered[0]));

      if (replaceName) {
        tab.name = replaceName;
      }

      return tab;
    }

    return defaultObject ? defaultObject : null;
  };
}

export default Tabs;
