import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../../hocs';
import {
  AdminDialogTitle,
  AdminDialogContent,
  AdminDialogControls,
  PrimaryButton,
  Spinner,
  ErrorBox,
} from '../../../components';
import styles from './styles';
import Typography from '@material-ui/core/Typography';
import UserForm from './UserForm/UserForm';
import TermsForm from './TermsForm';
import Icon from '../../../components/Icon/Icon';
import BackIcon from '@material-ui/icons/ArrowBack';

const propTypes = {
  initialUserData: PropTypes.object,
  initialTermsData: PropTypes.object,
  error: PropTypes.object,
  onBackClick: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
};

class GeneralInfoStage extends React.Component {
  constructor(props) {
    super(props);
    this.user = null;
    this.terms = null;

    this.state = {
      error: null,
      loading: false,
    };
  }

  validate = () => {
    const { onDone } = this.props;
    this.setState({
      loading: true,
      error: null,
    });

    const user = this.user.validate();
    const terms = this.terms.validate();
    if (user.validated && terms.validated) {
      onDone(
        {
          user: user.data,
          terms: terms.data,
        },
        (error) => {
          this.setState({
            error,
            loading: false,
          });
        }
      );
    } else {
      this.setState({ loading: false });
    }
  };

  componentWillUnmount() {
    this.user = null;
    this.terms = null;
  }

  drawError = () => {
    const { intl, classes } = this.props;
    const { error } = this.state;

    let message = error?.data?.message || intl.formatMessage({ id: 'global.somethingWentWrong' });
    const code = error?.data?.code;

    if (!error) {
      return null;
    }

    if (code === 6007) {
      message = intl.formatMessage({ id: 'messages.customerWithSameEmailAlreadyExists' });
    }

    if (code === 6008) {
      message = intl.formatMessage({ id: 'messages.customerWithSameUserNameAlreadyExists' });
    }

    if (code === 6009) {
      message = intl.formatMessage({ id: 'messages.customerWithSameCompanyNameAlreadyExists' });
    }

    return <ErrorBox error={message} className={classes.errorBox} />;
  };

  render() {
    const { intl, classes, initialUserData, initialTermsData, onBackClick } = this.props;

    const { loading, error } = this.state;

    return (
      <React.Fragment>
        <AdminDialogTitle title={intl.formatMessage({ id: 'customers.addNewCustomer' })}>
          {
            <Icon
              icon={BackIcon}
              style={{ fill: 'rgba(0, 0, 0, 0.5)' }}
              className={classes.back}
              onClick={() =>
                onBackClick &&
                onBackClick({
                  terms: this.terms.getInput(),
                  user: this.user.getInput(),
                })
              }
            />
          }
        </AdminDialogTitle>
        <AdminDialogContent className={classes.wrapper}>
          {loading && <Spinner size={50} />}

          <div className={classes.row}>
            <div style={{ marginRight: 72 }} className={classes.column}>
              <Typography gutterBottom className={classes.title}>
                <FormattedMessage id="global.mainUser" />
              </Typography>
              <UserForm onRef={(user) => (this.user = user)} error={error} initialData={initialUserData} />
            </div>
            <div className={classes.column}>
              <Typography gutterBottom className={classes.title}>
                <FormattedMessage id="global.terms" />
              </Typography>
              <TermsForm onRef={(terms) => (this.terms = terms)} initialData={initialTermsData} />
            </div>
          </div>

          {this.drawError()}
          <AdminDialogControls>
            <PrimaryButton className={classes.button} onClick={this.validate}>
              {intl.formatMessage({ id: 'global.save' })}
            </PrimaryButton>
          </AdminDialogControls>
        </AdminDialogContent>
      </React.Fragment>
    );
  }
}

GeneralInfoStage.propTypes = propTypes;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(GeneralInfoStage)));
