import React from "react";
import PropTypes from "prop-types";
import {Table} from '@devexpress/dx-react-grid-material-ui';
import {injectIntl} from "react-intl";
import {withStyles} from "@material-ui/core/styles";
import {toCurrency} from "../../../helpers";

const styles = ({});

const propTypes = {
  row: PropTypes.object.isRequired,
};

const Cell = ({intl, row, classes, ...other}) =>  {
  let value = "-";

  if (row.rule) {
    switch (row.rule.type) {
      case 'lower_than' : {
        value = `> 0`;
        break;
      }
      case 'higher_than' : {
        value = `≥ ${toCurrency(row.rule.min, intl)}`;
        break;
      }
      default : {
        value = `≥ ${toCurrency(row.rule.min, intl)}`;
      }
    }
  }

  return (
    <Table.Cell {...other}>
      {value}
    </Table.Cell>
  );
};

Cell.propTypes = propTypes;

export default withStyles(styles)(
    injectIntl(Cell)
);
