import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import styles from './styles';
import SearchField from '../../../../components/AdminDxTableDesigned/SearchField';
import BreadcrumbsComponent from '../BreadcrumbsComponent/BreadcrumbsComponent';
import classNames from 'classnames';
import CustomerMenu from '../CusomerMenu';

const useStyles = makeStyles(styles);

const propTypes = {
  customer: PropTypes.object,
};

const TitleAsBreadcrumbsWithSearchComponent = (props) => {
  const {
    customer,
    searchPlaceholder,
    breadcrumbConfig,
    isSticky,
    headerOffset = 0,
    onSearch,
    searchDelay = 1000,
    searchQuery,
    disableSearch,
  } = props;
  const classes = useStyles();
  const intl = useIntl();
  const [query, setQuery] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchQuery !== query) {
        onSearch && onSearch(query);
      }
    }, searchDelay);

    return () => clearTimeout(timer);
  }, [query]);

  const onChange = (newValue, immediate) => {
    if (immediate && searchQuery !== newValue) {
      onSearch && onSearch(newValue);
      return;
    }

    setQuery(newValue);
  };

  return (
    <div
      className={classNames([classes.root, isSticky && classes.stickyHeader])}
      {...(isSticky
        ? {
            style: {
              top: headerOffset,
            },
          }
        : {})}>
      <BreadcrumbsComponent breadcrumbConfig={breadcrumbConfig} />

      {customer && <CustomerMenu customer={customer} />}

      {!disableSearch && (
        <SearchField
          value={query}
          placeholder={searchPlaceholder}
          getMessage={() => intl.formatMessage({ id: 'memberService.search.searchForAnItemDate' })}
          onValueChange={onChange}
          searchFieldWrapperClass={classes.searchFieldWrapperClass}
        />
      )}
    </div>
  );
};

TitleAsBreadcrumbsWithSearchComponent.propTypes = propTypes;

export default TitleAsBreadcrumbsWithSearchComponent;
