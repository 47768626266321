const validation = {
  day: {
    presence: {
      message: '^validation.required'
    }
  },
  untilDay: {
    presence: {
      message: '^validation.required'
    }
  },
  untilTime: {
    presence: {
      message: '^validation.required'
    }
  },
};

export default validation;
