import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { makeStyles } from '@material-ui/core';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import classNames from 'classnames';

const useStyles = makeStyles(styles);

const RowRenderer = (onClick, { row, ...props }) => {
  const classes = useStyles();

  return (
    <Table.Row
      {...props}
      onClick={() => onClick && onClick(row)}
      className={classNames([classes.rowHover, onClick && classes.rowWithClick])}
      children={props.children}
    />
  );
};

RowRenderer.propTypes = {
  onClick: PropTypes.func,
};

export default RowRenderer;
