export default {
  name: {
    presence: {
      message: '^validation.required',
    },
  },
  value: {
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
      message: '^validation.required',
    },
    presence: {
      message: '^validation.required',
    },
  },
  percentage: {
    presence: {
      message: '^validation.required',
    },
  },
  customer: {
    presence: {
      message: '^validation.required',
    },
  },
  paymentMethod: {
    presence: {
      message: '^validation.required',
    },
  },
  subscription: {
    presence: {
      message: '^validation.csa.pleaseSpecifyShippingType',
    },
  }
};
