import React from 'react';
import { COLORS } from '../constants/colors';

const CalcButton5 = (props) => {
  const { color = COLORS.blackTintedBlue, ...other } = props;
  return (
    <svg width="23" height="34" viewBox="0 0 23 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <path d="M3.16777 33.1807C4.69121 33.1807 5.575 32.3164 5.575 30.832V25.9541H4.10039V30.8223C4.10039 31.5303 3.77324 31.9062 3.14824 31.9062C2.57207 31.9062 2.24981 31.5254 2.22051 30.9834V30.9541H0.789845V30.9932C0.828907 32.3164 1.7127 33.1807 3.16777 33.1807ZM8.98887 33H10.4635V30.8467L11.1617 30.0068L13.2076 33H14.9752L12.2262 29.04L14.7945 25.9541H13.1539L10.5514 29.1182H10.4635V25.9541H8.98887V33ZM17.7885 33H22.3979V31.7842H19.2631V25.9541H17.7885V33Z" fill={color}/>
      <path d="M13.2744 18.415C16.8511 18.415 19.4023 15.937 19.4023 12.3848V12.3604C19.4023 8.95459 17.022 6.47656 13.7139 6.47656C12.1147 6.47656 10.7354 7.12354 9.9541 8.30762H9.75879L10.3569 2.35059H18.4258V0.385254H8.62354L7.6958 10.5049H9.6001C9.81982 10.0898 10.1006 9.74805 10.4058 9.45508C11.1626 8.73486 12.1636 8.38086 13.3354 8.38086C15.6182 8.38086 17.2539 10.0532 17.2539 12.397V12.4214C17.2539 14.8018 15.6426 16.4741 13.2988 16.4741C11.2358 16.4741 9.70996 15.1313 9.50244 13.4468L9.49023 13.3491H7.37842L7.39062 13.4834C7.64697 16.3032 9.99072 18.415 13.2744 18.415Z" fill={color}/>
    </svg>
  );
};

export default CalcButton5;
