export default () => ({
  root: {
    backgroundColor: '#fff',
    marginLeft: 15,
    marginRight: 15,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 20,
    paddingBottom: 20,
  },
  stickyHeader: {
    position: 'sticky',
    zIndex: 1100,
  },

  searchFieldWrapperClass: {
    width: 'auto',
  },
});
