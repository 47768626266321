import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Fade, makeStyles } from '@material-ui/core';
import Icon from '../../scenes/SupplierScene/components/Icon/Icon';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'fixed',
    bottom: 30,
    left: 'calc(50% - 150px)',
    backgroundColor: '#000',
    padding: '10px 20px',
    border: '2px solid #000',
    color: 'white',
    borderRadius: 4,
    zIndex: 1000,
    minWidth: 300,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  icon: {
    fontSize: 18,
    marginRight: 5,
  },
}));

const propTypes = {
  onHide: PropTypes.func,
  isActive: PropTypes.bool,
  message: PropTypes.string,
};

const TemporaryTooltip = (props) => {
  const { message, isActive, onHide, icon } = props;
  const classes = useStyles();
  const [isActiveInternal, setIsActiveInternal] = useState(false);

  useEffect(() => {
    if (isActive) {
      setIsActiveInternal(true);
      const timer = setTimeout(() => {
        onHide && onHide();
        setIsActiveInternal(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isActive]);

  return (
    <Fade in={isActiveInternal}>
      <div className={classes.wrapper}>
        {icon && <Icon icon={icon} style={{ fill: 'white', color: 'white' }} className={classes.icon} />}
        {message}
      </div>
    </Fade>
  );
};

TemporaryTooltip.propTypes = propTypes;

export default TemporaryTooltip;
