import React from 'react';
import DraftsTabContent from './Content/DraftsTabContent';
import OrdersTabContent from './Content/OrdersTabContent';
import SupplierDraftsTabContent from './Content/SupplierDraftsTabContent';
import SupplierOrdersTabContent from './Content/SupplierOrdersTabContent';

const Factory = ({ tag, ...other }) => {
  let components = {
    DraftsTabContent: DraftsTabContent,
    OrdersTabContent: OrdersTabContent,
    SupplierDraftsTabContent: SupplierDraftsTabContent,
    SupplierOrdersTabContent: SupplierOrdersTabContent,
  };
  const TagName = components[tag];
  return <TagName {...other} />;
};

export default Factory;
