import { COLORS, WIDTH_OF_SIDEBAR } from '../../helpers';

const drawerWidth = WIDTH_OF_SIDEBAR;

const styles = (theme) => ({
  root: {
    display: 'flex',
    minHeight: '100%',
  },
  appBar: {
    height: 50,
    boxShadow: 'none',
    borderTop: '1px solid ' + COLORS.border,
    borderBottom: '1px solid ' + COLORS.border,
    backgroundColor: '#ffffff',
    zIndex: theme.zIndex.drawer - 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarSmallShift: {
    marginLeft: 73,
    width: `calc(100% - ${73}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  headerToolbar: {
    minHeight: 50,
    display: 'flex',
    paddingLeft: 0,
  },
  headerMenu: {
    marginLeft: 'auto',
  },
  menuButton: {
    marginLeft: 12,
    color: '#fff',
  },
  hide: {
    display: 'none',
  },
  logoSmall: {
    maxHeight: 25,
    marginLeft: 5,
  },
  logo: {
    maxHeight: 35,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    // backgroundColor: COLORS.black,
    whiteSpace: 'nowrap',
    '&:hover .sidebar-showOnHover': {
      display: 'block',
    },
    '&:hover .sidebar-hideOnHover': {
      display: 'none',
    },
  },
  drawerPaper: {
    backgroundColor: COLORS.black,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  hoverAfterDelay: {
    '&:hover': {
      width: drawerWidth,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    minHeight: '50px !important',
  },
  toolbarCenter: {
    justifyContent: 'center',
  },
  content: {
    flexGrow: 1,
    minHeight: '100%',
    padding: theme.spacing(3),
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
  },
  hidden: {
    display: 'none',
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 20,
    paddingLeft: 0,
  },
  logoText: {
    marginLeft: 30,
    color: 'white',
  },
});

export default styles;
