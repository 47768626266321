import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Icon from '../Icon/Icon';
import Typography from '@material-ui/core/Typography';
import { Spinner } from '../../components';
import { AttachFile } from '@material-ui/icons';

const styles = {
  image: {
    width: '100%',
  },
  imageBox: {
    width: '100%',
  },
  text: {
    fontSize: '12px',
    color: '#777775',
    fontFamily: 'Roboto',
  },
  icon: {
    color: '#777775',
    fontSize: 18,
  },

  fileSelectorWrapper: {
    borderRadius: 4,
    border: '1px solid #777775',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    cursor: 'pointer',
    margin: 0,
    boxSizing: 'border-box',

    '& *': {
      boxSizing: 'border-box',
    },
  },
  fileNameWrapper: {
    padding: 10,
    width: 'calc(100% - 110px)',
  },
  fileName: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textAlign: 'left',
    paddingRight: 20,
    fontSize: '12px',
    color: '#777775',
    fontFamily: 'Roboto',
  },
  fileButtonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    borderLeft: '1px solid #777775',
    margin: 0,
    width: 110,
  },
};

const propTypes = {
  onUploaded: PropTypes.func.isRequired,
  file: PropTypes.string,
};

class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: props.file ? props.file : null,
      fileName: '',
      loading: false,
      uploaded: false,
    };
  }

  getFileName = () => {
    const { intl } = this.props;
    const { fileName } = this.state;
    if (!!fileName) {
      return fileName;
    }

    return intl.formatMessage({ id: 'file.chooseFile' });
  };

  handleChange = (event) => {
    this.setState({
      file: event.target.files[0],
      fileName: event.target.files[0].name,
    });
    this.props.onUploaded(event.target.files[0]);
  };

  render() {
    const { intl, classes, accept = '*' } = this.props;
    const { file } = this.state;

    return (
      <div className={classes.root}>
        <input onChange={this.handleChange} accept={accept} style={{ display: 'none' }} id="file" type="file" />
        {this.state.loading && <Spinner />}

        <label htmlFor="file" className={classes.fileSelectorWrapper}>
          <div className={classes.fileNameWrapper}>
            <p className={classes.fileName}>{this.getFileName()}</p>
          </div>
          <div className={classes.fileButtonWrapper}>
            <Icon icon={AttachFile} className={classes.icon} />
            <Typography className={classes.text}>
              {!!file ? intl.formatMessage({ id: 'file.update' }) : intl.formatMessage({ id: 'file.upload' })}
            </Typography>
          </div>
        </label>
      </div>
    );
  }
}

FileUpload.propTypes = propTypes;

export default withStyles(styles, { withTheme: true })(injectIntl(FileUpload));
