import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from '../../hocs';
import { AdminDXTable, Tab, TabActions } from '../../components';
import { TableCell, TableRow } from './helpers';
import { getGridPageSize, getGridPageSizes } from '../../helpers/grid';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';
import { apiCollectionGetProducts, apiDeleteCollection } from '../../api';
import { setProducer } from '../../store/actions';
import {
  countWidthOfColumns,
  DEFAULT_MAX_CELL_WIDTH,
  DEFAULT_MIN_CELL_WIDTH,
  HEADER_HEIGHT_STANDARD,
} from '../../helpers';

const propTypes = {
  tab: PropTypes.object.isRequired,
  collection: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  columnExtensions: PropTypes.array,
  onDeleteCollection: PropTypes.func,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const defaultProps = {};

class CollectionsContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleting: false,
      deletingLoading: false,
      loading: true,
      hiddenColumnNames: [],
      columnWidths: countWidthOfColumns(props.columns, [], props.columnExtensions),
    };
  }

  onDelete = () => {
    const { onDeleteCollection } = this.props;
    this.setState({ deletingLoading: true });
    apiDeleteCollection(this.state.deleting.id, (producer) => {
      onDeleteCollection && onDeleteCollection(this.state.deleting, producer);
    });
  };

  onChangeColumnsState = (hiddenColumnNames) => {
    this.setState({ hiddenColumnNames });
  };

  render() {
    const { tab, index, columns, intl, collection, columnExtensions } = this.props;

    const { hiddenColumnNames } = this.state;

    return (
      <Tab
        title={tab.name}
        key={`${index}-tab`}
        tab={tab}
        actions={
          <TabActions
            tab={tab}
            labels={{
              createEntity: intl.formatMessage({ id: 'tab.newCollection' }),
              deleteTab: intl.formatMessage({ id: 'tab.deleteCollection' }),
            }}
            allowHideActionsColumn={false}
            columns={columns}
            onDeleteTab={() => this.setState({ deleting: collection })}
            onCreateEntity={() => this.props.history.push('/collections/new')}
            onChangeColumnsState={this.onChangeColumnsState}
          />
        }>
        <AdminDXTable
          onRef={(table) => (this.table = table)}
          cellRenderer={TableCell}
          rowRenderer={TableRow}
          apiRetrieve={(filters, onSuccess) => {
            apiCollectionGetProducts(collection.id, (products) => {
              onSuccess(products);
            });
          }}
          pageSize={getGridPageSize()}
          pageSizes={getGridPageSizes()}
          onChangeSorting={this.onChangeSorting}
          hiddenColumnNames={hiddenColumnNames}
          columns={columns}
          enableTree
          columnExtensions={columnExtensions}
          //enableTreeSelection
          //treeSelectionColumn={"selection"}
          getTreeChildRows={(row, rootRows) => {
            if (row) {
              row.variants &&
                row.variants.forEach((variant, index) => {
                  row.variants[index]['product'] = row;
                });
              return row.variants;
            }
            return rootRows;
          }}
          columnWidths={this.state.columnWidths}
          onColumnWidthsChange={(widths) => {
            this.setState({ columnWidths: widths });
          }}
          minColumnWidth={DEFAULT_MIN_CELL_WIDTH}
          maxColumnWidth={DEFAULT_MAX_CELL_WIDTH}
          allowExpandAll={true}
          stickyHeader
          stickyHeaderOffset={HEADER_HEIGHT_STANDARD.extended}
        />

        {this.state.deleting && (
          <ConfirmDialog
            onClose={() => this.setState({ confirmDelete: false })}
            loading={this.state.deletingLoading}
            confirmTitle={'confirm.deleteCollection'}
            title={intl.formatMessage({ id: 'titles.deleteCollection' })}
            message={intl.formatMessage({ id: 'messages.confirmDeleteCollection' })}
            onConfirm={this.onDelete}
          />
        )}
      </Tab>
    );
  }
}

CollectionsContent.propTypes = propTypes;
CollectionsContent.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  return {
    producer: state.producer.object,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProducer: (producer) => dispatch(setProducer(producer)),
  };
};

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(CollectionsContent)));
