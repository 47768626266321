import React from "react";
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import HeaderDesktop from './HeaderDesktop'
import styles from './styles';
import OrderLineItemDesktop from "./OrderLineItemDesktop";

const propTypes = {
  order: PropTypes.object.isRequired,
};

const OrderLineItems = (props) => {
  const {
    classes,
    order,
  } = props;

  return (
    <div className={classes.wrapper}>
      <HeaderDesktop/>
      {order.lineItems.map((item) => {
        return (
          <OrderLineItemDesktop key={item.id}
                                order={order}
                                lineItem={item} />
        )
      })}
    </div>
  )
};

OrderLineItems.propTypes = propTypes;

export default withStyles(styles)(
  OrderLineItems
);