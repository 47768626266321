import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../hocs';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { SidebarMenu } from '../Partials';
import HeaderMenu from '../Partials/HeaderMenu/HeaderMenu';
import { AdminTabs, AdminLayout, Spinner, ErrorBox } from '../../components';
import ProductsContent from './ProductsContent';
import {
  apiCreateTab,
  apiDeleteTab,
  apiPatchTab,
  apiGetListOfProductsPricesLocations,
  apiGetOfferedMarketPresets,
} from '../../api';
import styles from './styles';
import { MAIN_TAB_NAMES } from '../../helpers/tabs';
import { TabTypes } from '../../dialogs';
import { setProducer } from '../../store/actions';
import { activeFilter } from './helpers';
import config from './config';

class ProductsScene extends React.Component {
  constructor(props) {
    super(props);

    const { tabs } = this.props;
    this.state = {
      loading: true,
      tabIndex: tabs.getInitialIndex(window.location.hash, true),
      error: '',
      dynamicColumns: [],
    };
  }

  getTabConfig = (tabIndex, lastTabIndex) => {
    const { intl } = this.props;

    const columnsConfig = config(intl);

    return tabIndex === lastTabIndex ? columnsConfig.archived : columnsConfig.default;
  };

  UNSAFE_componentWillMount() {
    apiGetOfferedMarketPresets(
      (response) => {
        this.getDynamicColumns(() => {}, this.makeColumnsFromLocationsRequest(response));
      },
      (error) => {
        this.setState({ loading: false, error: error.message });
      }
    );
  }

  getDynamicColumns = (onSuccess, additionalColumns) => {
    const { tabIndex } = this.state;
    apiGetListOfProductsPricesLocations(
      (response) => {
        let newColumns = this.getTabConfig(tabIndex).columns.slice();

        let columns = [...additionalColumns, ...this.makeColumnsFromRequest(response)];
        columns.forEach((column) => {
          !newColumns.some((item) => item.name === column.name) && newColumns.push(column);
        });

        this.setState(
          {
            columns: newColumns,
            dynamicColumns: columns,
            loading: false,
          },
          () => {
            onSuccess && onSuccess(newColumns);
          }
        );
      },
      (error) => {
        this.setState({ loading: false, error: error.message });
      }
    );
  };

  makeColumnsFromRequest = (response) => {
    const { intl } = this.props;
    return response.map((level) => {
      return {
        name: `${level.priceLevel}_${level.locationId}`,
        title: intl.formatMessage({ id: `products.column.${level.priceLevel}` }) + ` (${level.locationName})`,
        type: `${level.priceLevel}:${level.locationId}`,
      };
    });
  };

  makeColumnsFromLocationsRequest = (response) => {
    return response.map((location) => {
      return {
        name: `${location.locationId}:${location.shippingDay}:${location.shippingType}`,
        title: location.name,
        type: `${location.locationId}:${location.shippingDay}:${location.shippingType}`,
      };
    });
  };

  addColumnToTable = (newColumnsData) => {
    const { dynamicColumns, tabIndex } = this.state;
    let newColumns = this.getTabConfig(tabIndex).columns.slice();
    this.setState(
      {
        columns: newColumns.concat(this.makeColumnsFromLocationsRequest(newColumnsData)),
        dynamicColumns: dynamicColumns.concat(this.makeColumnsFromLocationsRequest(newColumnsData)),
        loading: true,
      },
      () => {
        this.setState({ loading: false });
      }
    );
  };

  onCreateTab = (data, onDone) => {
    this.setState({ loading: true });
    apiCreateTab(
      data,
      (producer) => {
        this.props.setProducer(producer);
        this.setState((state) => {
          state.loading = false;
          onDone && onDone();
          return state;
        });
      },
      undefined
    );
  };

  onDeleteTab = (tab) => {
    this.setState({ loading: true });
    apiDeleteTab(
      tab.id,
      (producer) => {
        this.setState(
          {
            loading: false,
            tabIndex: 0,
          },
          () => {
            this.props.setProducer(producer);
          }
        );
      },
      undefined
    );
  };

  onPatchTab = (tab, data, onDone) => {
    this.setState({ loading: true });
    apiPatchTab(
      tab.id,
      data,
      (producer) => {
        this.props.setProducer(producer);
        this.setState({ loading: false });
        onDone && onDone();
      },
      undefined
    );
  };

  onPatchDefaultTab = (name, tab, data, onDone) => {
    const { tabs } = this.props;
    let ordersTab = tabs.getTabByName(name);
    if (ordersTab) {
      this.onPatchTab(ordersTab, data, onDone);
    } else {
      data.name = name;
      data.type = TabTypes.PRODUCTS;
      this.onCreateTab(data);
    }
  };

  onChangeTab = (index) => {
    const { tabs } = this.props;
    window.location.hash = tabs.getHashByIndex(index);
    this.setState({ tabIndex: index });
  };

  render() {
    const { tabIndex, loading, dynamicColumns } = this.state;

    const { intl, tabs, classes } = this.props;

    const tabsTitles = tabs.getTabNames(
      [intl.formatMessage({ id: 'products.tab.all' }), intl.formatMessage({ id: 'products.tab.active' })],
      [intl.formatMessage({ id: 'products.tab.archive' })]
    );
    const lastTabIndex = tabsTitles.length - 1;

    const customTabs = tabs.getOnlyCustom();
    let allTab = tabs.getTabByName(MAIN_TAB_NAMES.PRODUCTS_ALL, intl.formatMessage({ id: 'global.products' }), {
      id: MAIN_TAB_NAMES.PRODUCTS_ALL,
      name: intl.formatMessage({ id: 'global.products' }),
    });
    let activeTab = tabs.getTabByName(MAIN_TAB_NAMES.PRODUCTS_ACTIVE, intl.formatMessage({ id: 'global.products' }), {
      id: MAIN_TAB_NAMES.PRODUCTS_ACTIVE,
      name: intl.formatMessage({ id: 'global.products' }),
    });
    let archiveTab = tabs.getTabByName(
      MAIN_TAB_NAMES.PRODUCTS_ARCHIVE,
      intl.formatMessage({ id: 'product.archiveScene.title' }),
      {
        id: MAIN_TAB_NAMES.PRODUCTS_ARCHIVE,
        name: intl.formatMessage({ id: 'product.archiveScene.title' }),
      }
    );

    return (
      <AdminLayout sidebarComponent={SidebarMenu} headerMenuComponent={HeaderMenu}>
        {loading && <Spinner size={60} />}

        <AdminTabs onChangeTab={this.onChangeTab} tab={tabIndex} tabs={tabsTitles}>
          {!loading && (
            <>
              <ErrorBox className={classes.errorBox} error={this.state.error} />
              {tabIndex === 0 && (
                <ProductsContent
                  tab={allTab}
                  key={'all-products'}
                  index={'all-products'}
                  allowCreateTab
                  addColumnToTable={this.addColumnToTable}
                  refetchColumns={this.getDynamicColumns}
                  onPatchTab={(tab, data, onDone) =>
                    this.onPatchDefaultTab(MAIN_TAB_NAMES.PRODUCTS_ALL, tab, data, onDone)
                  }
                  onCreateTab={this.onCreateTab}
                  dynamicColumns={dynamicColumns}
                  {...this.getTabConfig(tabIndex, lastTabIndex)}
                />
              )}

              {tabIndex === 1 && (
                <ProductsContent
                  tab={activeTab}
                  key={'active-products'}
                  index={'active-products'}
                  //only active filter allowed
                  onlyActive
                  serverSideFilters={activeFilter(intl)}
                  allowCreateTab
                  addColumnToTable={this.addColumnToTable}
                  refetchColumns={this.getDynamicColumns}
                  onPatchTab={(tab, data, onDone) =>
                    this.onPatchDefaultTab(MAIN_TAB_NAMES.PRODUCTS_ACTIVE, tab, data, onDone)
                  }
                  onCreateTab={this.onCreateTab}
                  dynamicColumns={dynamicColumns}
                  {...this.getTabConfig(tabIndex, lastTabIndex)}
                />
              )}

              {tabIndex === lastTabIndex && (
                <ProductsContent
                  tab={archiveTab}
                  key={'archive-products'}
                  index={'archive-products'}
                  addColumnToTable={this.addColumnToTable}
                  refetchColumns={this.getDynamicColumns}
                  onPatchTab={(tab, data, onDone) =>
                    this.onPatchDefaultTab(MAIN_TAB_NAMES.PRODUCTS_ARCHIVE, tab, data, onDone)
                  }
                  onCreateTab={this.onCreateTab}
                  dynamicColumns={[]}
                  {...this.getTabConfig(tabIndex, lastTabIndex)}
                />
              )}

              {!!customTabs.length && tabIndex > 1 && tabIndex !== lastTabIndex && (
                <ProductsContent
                  tab={customTabs[tabIndex - 2]}
                  key={`custom-${customTabs[tabIndex - 2].id}`}
                  index={tabIndex}
                  addColumnToTable={this.addColumnToTable}
                  refetchColumns={this.getDynamicColumns}
                  onPatchTab={this.onPatchTab}
                  onDeleteTab={this.onDeleteTab}
                  dynamicColumns={dynamicColumns}
                  {...this.getTabConfig(tabIndex, lastTabIndex)}
                />
              )}
            </>
          )}
        </AdminTabs>
      </AdminLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tabs: state.producer.tabs[TabTypes.PRODUCTS],
    producer: state.producer.object,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProducer: (producer) => dispatch(setProducer(producer)),
  };
};

export default withStyles(styles)(withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(ProductsScene))));
