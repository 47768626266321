import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import PrimaryTextField from '../PrimaryTextField/PrimaryTextField';
import { withStyles } from '@material-ui/core/styles';
import GeneralInfoFields from './GeneralInfoFields';
import { CUSTOMER_TYPE, validateField } from '../../helpers';
import validationRules from './validate';
import { ErrorBox } from '../index';

const styles = {
  textField: {
    margin: '15px 0 4px 0',
  },
  actionsWrap: {
    marginTop: 50,
    marginBottom: 20,
    justifyContent: 'center',
    display: 'flex',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  errorBox: {
    marginTop: 20,
    marginBottom: -20,
  },
};

class CustomerGeneralInfoForm extends Component {
  constructor(props) {
    super(props);

    const { customer } = this.props;

    this.state = {
      customer,
      userNameError: '',
      companyNameError: '',
      loading: false,
      error: '',

      emailError: '',
      usernameError: '',
      firstNameError: '',
      lastNameError: '',
    };
  }

  _handleChange = (key, value) => {
    this.setState({
      customer: {
        ...this.state.customer,
        [key]: value,
      },
    });
  };

  _handleSubmit = (e) => {
    e.preventDefault();
    const { customer } = this.state;
    const { intl } = this.props;

    const emailError = validateField(validationRules, 'email', customer.email);
    const usernameError = validateField(validationRules, 'username', customer.username);
    const firstNameError = validateField(validationRules, 'firstName', customer.firstName);
    const lastNameError = validateField(validationRules, 'lastName', customer.lastName);
    let companyNameError = '';

    if (customer?.type === CUSTOMER_TYPE.WHOLESALE) {
      companyNameError = validateField(validationRules, 'companyName', customer.companyName);
    }

    if (!!emailError || !!usernameError || !!firstNameError || !!lastNameError || !!companyNameError) {
      this.setState({
        emailError: emailError ? intl.formatMessage({ id: emailError }) : '',
        usernameError: usernameError ? intl.formatMessage({ id: usernameError }) : '',
        firstNameError: firstNameError ? intl.formatMessage({ id: firstNameError }) : '',
        lastNameError: lastNameError ? intl.formatMessage({ id: lastNameError }) : '',
        companyNameError: companyNameError ? intl.formatMessage({ id: companyNameError }) : '',
      });
      return;
    }

    this.setState(
      {
        userNameError: '',
        companyNameError: '',
        emailError: '',
        usernameError: '',
        firstNameError: '',
        lastNameError: '',
        error: '',
        loading: true,
      },
      () => {
        this.props.onSubmit(new GeneralInfoFields(customer), (error) => {
          const message = error?.data?.message || intl.formatMessage({ id: 'global.somethingWentWrong' });
          const code = error?.data?.code;

          this.setState({
            loading: false,
          });

          if (code === 6007) {
            this.setState({
              emailError: intl.formatMessage({ id: 'messages.customerWithSameEmailAlreadyExists' }),
            });
            return;
          }

          if (code === 6008) {
            this.setState({
              userNameError: intl.formatMessage({ id: 'messages.customerWithSameUserNameAlreadyExists' }),
            });
            return;
          }

          if (code === 6009) {
            this.setState({
              companyNameError: intl.formatMessage({ id: 'messages.customerWithSameCompanyNameAlreadyExists' }),
            });
            return;
          }

          this.setState({ error: message });
        });
      }
    );
  };

  render() {
    const { intl, classes } = this.props;
    const {
      customer,

      emailError,
      usernameError,
      firstNameError,
      lastNameError,
    } = this.state;

    return (
      <form onSubmit={this._handleSubmit} className={classes.form}>
        <PrimaryTextField
          value={customer.email}
          label={intl.formatMessage({ id: 'global.email' }) + ' *'}
          onChange={(event) => this._handleChange('email', event.target.value)}
          className={classes.textField}
          error={!!emailError}
          helperText={emailError}
        />
        <PrimaryTextField
          value={customer.username}
          error={!!this.state.userNameError || !!usernameError}
          label={intl.formatMessage({ id: 'global.username' }) + ' *'}
          onChange={(event) => this._handleChange('username', event.target.value)}
          helperText={this.state.userNameError || usernameError}
          className={classes.textField}
        />
        {customer.type === CUSTOMER_TYPE.WHOLESALE && (
          <PrimaryTextField
            value={customer.companyName}
            error={!!this.state.companyNameError}
            label={intl.formatMessage({ id: 'customer.companyName' }) + ' *'}
            onChange={(event) => this._handleChange('companyName', event.target.value)}
            helperText={this.state.companyNameError}
            className={classes.textField}
          />
        )}
        <PrimaryTextField
          value={customer.firstName}
          label={intl.formatMessage({ id: 'global.firstName' }) + ' *'}
          onChange={(event) => this._handleChange('firstName', event.target.value)}
          className={classes.textField}
          error={!!firstNameError}
          helperText={firstNameError}
        />
        <PrimaryTextField
          value={customer.lastName}
          label={intl.formatMessage({ id: 'global.lastName' }) + ' *'}
          onChange={(event) => this._handleChange('lastName', event.target.value)}
          className={classes.textField}
          error={!!lastNameError}
          helperText={lastNameError}
        />
        <PrimaryTextField
          value={customer.phone}
          label={intl.formatMessage({ id: 'global.phone' })}
          onChange={(event) => this._handleChange('phone', event.target.value)}
          className={classes.textField}
        />

        <ErrorBox error={this.state.error} className={classes.errorBox} />

        <div className={classes.actionsWrap}>
          <PrimaryButton type="submit" isLoading={this.state.loading}>
            {intl.formatMessage({ id: 'global.saveChanges' })}
          </PrimaryButton>
        </div>
      </form>
    );
  }
}

CustomerGeneralInfoForm.propTypes = {
  customer: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(injectIntl(CustomerGeneralInfoForm));
