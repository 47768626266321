import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {withStyles} from '@material-ui/core/styles';
import Icon from "../Icon/Icon";
import EditIcon from "@material-ui/icons/Edit";
import Typography from "@material-ui/core/Typography";
import {
  Spinner,
} from '../../components';
import EmptyImage from './assets/no.jpg';

const styles = ({
  image: {
    width: '100%'
  },
  imageBox: {
    width: '100%'
  },
  text: {
    fontSize: '12px',
    color: '#777775',
    fontFamily: 'Roboto',
  },
  icon: {
    fontSize: 18
  }
});

const propTypes = {
  onUploaded: PropTypes.func.isRequired,
  base64: PropTypes.string,
  object: PropTypes.object,
};

class ImageUpload extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      file: props.base64 ? props.base64 : null,
      loading: false,
      uploaded: false
    };
  }

  getImage = () => {
    const {object} = this.props;
    const {file} = this.state;

    if (file) {
      return `data:image/png;base64,${file}`;
    }

    if (object && object.base64) {
      return `data:image/png;base64,${object.base64}`;
    }

    if (object && object.image && object.image.thumbSrc) {
      return object.image.thumbSrc;
    }

    if (object && object.image && object.image.src) {
      return object.image.src;
    }

    return EmptyImage;
  };

  handleChange = (event) => {
    const reader = new FileReader();
    this.setState({loading: true});
    reader.onload = () => {
      const file = reader.result.split(',').pop();
      this.setState({
        loading: false,
        file: file
      });
      this.props.onUploaded(file);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  render() {
    const {
      intl,
      classes,
      object,
    } = this.props;


    const labelStyles = {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      cursor: 'pointer',
      marginTop: 5
    };

    return (
      <React.Fragment>
        <div className={classes.imageBox}>
          <img className={classes.image}
               src={this.getImage()}
               alt={object ? object.name : "Empty"}/>
        </div>

        {this.state.loading && (
          <Spinner/>
        )}

        <input
          onChange={this.handleChange}
          accept="image/*"
          style={{display: 'none'}}
          id="file"
          type="file"
        />
        <label htmlFor="file" style={labelStyles}>
          <Icon icon={EditIcon} className={classes.icon}/>
          <Typography className={classes.text}>
            {object && object.image ?
              intl.formatMessage({id: "image.update"}) :
              intl.formatMessage({id: "image.upload"})}
          </Typography>
        </label>
      </React.Fragment>
    );
  }
}

ImageUpload.propTypes = propTypes;

export default withStyles(styles, {withTheme: true})(
  injectIntl(ImageUpload)
);
