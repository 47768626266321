import React from "react";
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  PrimaryCheckbox,
  Icon,
} from '../../components';
import styles from './styles';

class EmailSubscriptionsTable extends React.Component {

  onPatchSubscription = (id, name, status) => {
    const {patchEmailSubscription} = this.props;
    let subscriptions = {};
    subscriptions[name] = status;
    patchEmailSubscription(id, subscriptions);
  };

  getCheckbox = (subscription, name) => {
    return (
      <PrimaryCheckbox onChange={() => this.onPatchSubscription(subscription.id, name, !subscription[name])}
                       checked={subscription[name]} />
    )
  };

  render() {
    const {
      classes,
      subscriptions,
      intl,
    } = this.props;

    return (
      <React.Fragment>
        {subscriptions.length && (
          <div className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.headerCell}>{intl.formatMessage({id: "global.email"})}</TableCell>
                  <TableCell className={classes.headerCell}>{intl.formatMessage({id: "emailSubscriptions.orderConfirmations"})}</TableCell>
                  <TableCell className={classes.headerCell}>{intl.formatMessage({id: "emailSubscriptions.invoices"})}</TableCell>
                  <TableCell className={classes.headerCell}>{intl.formatMessage({id: "emailSubscriptions.news"})}</TableCell>
                  <TableCell className={classes.headerCell}>{intl.formatMessage({id: "emailSubscriptions.orderFulfillment"})}</TableCell>
                  <TableCell className={classes.headerCell}>{intl.formatMessage({id: "emailSubscriptions.orderUpdates"})}</TableCell>
                  <TableCell className={classes.headerCell}>{intl.formatMessage({id: "emailSubscriptions.paymentConfirmation"})}</TableCell>
                  <TableCell className={classes.headerCell}>{intl.formatMessage({id: "emailSubscriptions.actions"})}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subscriptions.map(subscription => {
                  return (
                    <TableRow className={classes.row} key={subscription.id}>
                      <TableCell className={classes.cell}>{subscription.email}</TableCell>
                      <TableCell className={classes.cell}>{this.getCheckbox(subscription, 'confirmation')}</TableCell>
                      <TableCell className={classes.cell}>{this.getCheckbox(subscription, 'invoice')}</TableCell>
                      <TableCell className={classes.cell}>{this.getCheckbox(subscription, 'news')}</TableCell>
                      <TableCell className={classes.cell}>{this.getCheckbox(subscription, 'orderFulfillment')}</TableCell>
                      <TableCell className={classes.cell}>{this.getCheckbox(subscription, 'orderUpdated')}</TableCell>
                      <TableCell className={classes.cell}>{this.getCheckbox(subscription, 'paymentConfirmation')}</TableCell>
                      <TableCell className={classes.cell}>
                        <IconButton onClick={() => this.props.onDelete(subscription.id)} aria-label="Delete">
                          <Icon icon={DeleteIcon}/>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        )}

        {!subscriptions.length &&
        <Typography variant="subtitle1" align={"center"}
                    gutterBottom>{<FormattedMessage id="emailSubscriptions.empty"/>}</Typography>}
      </React.Fragment>
    );
  }
}

EmailSubscriptionsTable.propTypes = {
  subscriptions: PropTypes.array.isRequired,
  patchEmailSubscription: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default withStyles(
  styles, {withTheme: true}
)(injectIntl(EmailSubscriptionsTable));