import axios from 'axios';
import {
  replaceUrlPlaceholder,
  replaceUrlPlaceholders,
  getRequestConfig,
  REQUEST_TYPES,
} from '../request';
import API_END_POINTS from '../endpoints';

/**
 * Get shared properties
 * @param {object} data
 * @param {string} type
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiGetSharedProperties = function (data, type, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRODUCT_SHARED_PROPERTIES_GET_ALL, 'type', type);
  const config = getRequestConfig(REQUEST_TYPES.GET, url);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Create shared property
 * @param {string} type
 * @param {array} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCreateSharedProperty = function (type, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRODUCT_SHARED_PROPERTY_CREATE, 'type', type);
  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  config.data = data;
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Create shared property
 * @param {string} id
 * @param {string} type
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiUpdateSharedProperty = function (id, type, data, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.PRODUCT_SHARED_PROPERTY_UPDATE, {
    id: id,
    type: type
  });
  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  config.data = data;
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Delete shared property
 * @param {string} id
 * @param {string} type
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDeleteSharedProperty = function (id, type, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.PRODUCT_SHARED_PROPERTY_DELETE, {
    id: id,
    type: type
  });
  const config = getRequestConfig(REQUEST_TYPES.DEL, url);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Reorder units
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiReorderUnits = function (onSuccess, onError) {
  const config = getRequestConfig(
    REQUEST_TYPES.POST,
    API_END_POINTS.PRODUCT_SHARED_PROPERTIES_REORDER_UNITS
  );
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Replace shared property
 * @param {string} id - property id
 * @param {string} type - property type
 * @param {object} data - property data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiReplaceSharedProperty = function (id, type, data, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.PRODUCT_SHARED_PROPERTY_REPLACE, {
    id: id,
    type: type
  });

  const config = getRequestConfig(
    REQUEST_TYPES.POST,
    url
  );

  config.data = data;
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

