export default () => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  separator: {
    fontSize: 16,
    marginLeft: 10,
    marginRight: 10,
  },
  singleItem: {},
  withLink: {
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.4)',
    transition: 'color 0.3s ease-in-out',
    '&:hover': {
      color: '#363636',
    },
  },
  item: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 20,
    marginTop: 0,
    marginBottom: 0,
    flex: 1,
  },
});
