import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import styles from './styles'

const propTypes = {
  hits: PropTypes.array,
  preFilterHits: PropTypes.func,
  getEmptyComponent: PropTypes.func,
  getComponentCallback: PropTypes.func.isRequired,
};
const defaultProps = {
  hits: []
};

class SearchHits extends React.Component {

  getContent = (hits) => {
    const { getComponentCallback } = this.props;
    return hits.map((hit) => (
      <React.Fragment key={'wrapper-' + hit.id}>
        {getComponentCallback(hit)}
      </React.Fragment>
    ))
  };

  render() {
    const {
      getEmptyComponent,
      preFilterHits,
    } = this.props;

    let hits = this.props.hits;
    const hasResults = !!hits.length;

    if (preFilterHits) {
      hits = preFilterHits(hits)
    }

    if (hasResults && hits.length) {
      return this.getContent(hits);
    }

    if (!hasResults && getEmptyComponent) {
      return getEmptyComponent();
    }

    return null;
  }
}

SearchHits.propTypes = propTypes;
SearchHits.defaultProps = defaultProps;

export default withStyles(styles)(
  SearchHits
);