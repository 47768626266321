import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../hocs';
import { injectIntl } from 'react-intl';
import { SidebarMenu, HeaderMenu } from '../Partials';
import { AdminTabs, Tab, AdminLayout } from '../../components';
import { apiCustomerRetrieve } from '../../api';
import CustomerView from './CustomerView';
import EmailSubscriptionsView from './EmailSubscriptionsView/EmailSubscriptionsView';
import { SET_CUSTOMER } from '../../store/reducers/customer';

class CustomerScene extends React.Component {
  state = {
    tab: 0,
    loaded: false,
    addingEmailSubscription: false,
    addingEmailSubscriptionError: '',
    patchingEmailSubscription: null,
    patchingEmailSubscriptionError: '',
  };

  componentDidMount() {
    this._fetchCustomer();
  }

  _fetchCustomer = () => {
    const {
      match: {
        params: { id },
      },
      dispatch,
    } = this.props;
    apiCustomerRetrieve(
      { id },
      (customer) => {
        dispatch({ type: SET_CUSTOMER, customer });
        this.setState({ loaded: true });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  render() {
    const { intl } = this.props;
    const { tab } = this.state;

    const tabs = [
      intl.formatMessage({ id: 'customers.tab.accountInformation' }),
      intl.formatMessage({ id: 'customers.tab.emailSubscriptions' }),
    ];

    return (
      <AdminLayout sidebarComponent={SidebarMenu} headerMenuComponent={HeaderMenu}>
        <AdminTabs onChangeTab={(index) => this.setState({ tab: index })} tab={tab} tabs={tabs}>
          {tab === 0 && (
            <Tab key="profile-tab" tab={{ name: tabs[0], withBackButton: true }}>
              <CustomerView />
            </Tab>
          )}

          {tab === 1 && <EmailSubscriptionsView />}
        </AdminTabs>
      </AdminLayout>
    );
  }
}

export default injectIntl(withRouter(connect((state) => state.customer)(CustomerScene)));
