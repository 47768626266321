const validation = {
  username: {
    presence: {
      message: '^validation.login.enterUsername'
    }
  },
  password: {
    presence: {
      message: '^validation.login.enterPassword'
    },
  }
};

export default validation;
