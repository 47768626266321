export default {
  ORDERS: 'orders',
  DRAFTS: 'drafts',
  PRODUCTS: 'products',
  PREPURCHASES: 'prepurchases',
  SUBSCRIPTIONS: 'subscriptions',
  UNIQUE_PRODUCTS: 'unique_products',
  DISCOUNTS: 'discounts',
  COLLECTIONS: 'collections',
  CUSTOMERS: 'customers',
  REPORTS: 'reports',
  SUPPLIERS_ORDERS: 'supplier_orders',
  SUPPLIERS_DRAFTS: 'supplier_drafts',
};
