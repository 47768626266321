import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import MenuItem from '@material-ui/core/MenuItem';
import {
  PrimarySelect,
  SelectDatesDialog,
  SelectTagsDialog,
  SELECT_DATES_DIALOG_STEPS,
  SELECT_TAGS_DIALOG_STEPS,
} from '../../components';
import { apiPrePurchaseBulkSetNextDate, apiPrePurchaseBulkAddTags } from '../../api';
import { makeStyles } from '@material-ui/core';

const styles = {
  select: {
    marginRight: 10,
  },
};

const LABEL = -1;
const BATCH_DATES = 1;
const BATCH_TAGS = 2;

const propTypes = {
  reload: PropTypes.func,
  keys: PropTypes.array.isRequired,
};

const useStyles = makeStyles(styles);

const PrePurchasesBatchControls = (props) => {
  const { reload, keys } = props;

  const intl = useIntl();
  const classes = useStyles();

  const [datesDialog, setDatesDialog] = useState(false);
  const [tagsDialog, setTagsDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSelectAction = (action) => {
    switch (action) {
      case BATCH_DATES: {
        setDatesDialog(true);
        break;
      }
      case BATCH_TAGS: {
        setTagsDialog(true);
        break;
      }
      default: {
        break;
      }
    }
  };

  const onDateSelected = (date, onSuccess = () => {}) => {
    const data = {
      prepurchases: keys,
      nextDate: date,
    };

    setLoading(true);

    apiPrePurchaseBulkSetNextDate(
      data,
      () => {
        onSuccess(SELECT_DATES_DIALOG_STEPS.STEP_COMPLETE);
        setLoading(false);
      },
      (error) => {
        const message = error && error.response && error.response.data && error.response.data.message;
        const noMessage = intl.formatMessage({ id: 'global.somethingWentWrong' });
        setLoading(false);
        onSuccess(SELECT_DATES_DIALOG_STEPS.STEP_ERROR, message || noMessage);
      }
    );
  };

  const onTagsSelected = (tags, onSuccess = () => {}) => {
    const data = {
      prepurchases: keys,
      tags,
    };

    apiPrePurchaseBulkAddTags(
      data,
      () => {
        onSuccess(SELECT_TAGS_DIALOG_STEPS.STEP_COMPLETE);
      },
      (error) => {
        const message = error && error.response && error.response.data && error.response.data.message;
        const noMessage = intl.formatMessage({ id: 'global.somethingWentWrong' });
        onSuccess(SELECT_TAGS_DIALOG_STEPS.STEP_ERROR, message || noMessage);
      }
    );
  };

  return (
    <React.Fragment>
      <PrimarySelect className={classes.select} value={LABEL} onChange={(e) => onSelectAction(e.target.value)}>
        <MenuItem key={LABEL} value={LABEL}>
          {intl.formatMessage({ id: 'order.table.batchActions' })}
        </MenuItem>
        <MenuItem key={BATCH_DATES} value={BATCH_DATES}>
          {intl.formatMessage({ id: 'prePurchase.updateDates' })}
        </MenuItem>
        <MenuItem key={BATCH_TAGS} value={BATCH_TAGS}>
          {intl.formatMessage({ id: 'prePurchase.addTags' })}
        </MenuItem>
      </PrimarySelect>

      {datesDialog && (
        <SelectDatesDialog
          onClose={() => setDatesDialog(false)}
          loading={loading}
          onConfirm={onDateSelected}
          reloadTable={reload}
        />
      )}

      {tagsDialog && (
        <SelectTagsDialog
          onClose={() => setTagsDialog(false)}
          loading={loading}
          onConfirm={onTagsSelected}
          reloadTable={reload}
        />
      )}
    </React.Fragment>
  );
};

PrePurchasesBatchControls.propTypes = propTypes;

export default PrePurchasesBatchControls;
