import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { BORDER_RADIUS_MAIN, BUTTON_VARIANTS, PRICES_NAMES } from '../../constants/globals';
import SupplierSelect from '../SupplierSelect';
import SupplierTextField from '../SupplierTextField';
import { useIntl } from 'react-intl';
import { COLORS } from '../../constants/colors';
import { MenuItem } from '@material-ui/core';
import PrimaryButton from '../SupplierButton';
import QuantitySwitcher from '../QuantitySwitcher';
import { getValueForNumberTextInput, makeClientValue, makeServerValue } from '../../helpers/convertors';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 30,
  },
  image: {
    display: 'inline-block',
    width: 160,
    maxHeight: 400,
  },
  content: {
    paddingTop: 30,
    width: '100%',
    boxSizing: 'border-box',
  },
  fieldWrapper: {
    marginBottom: 32,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  input: {
    width: '100%',
    flex: 1,
  },

  twoFieldsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: 32,
  },
  leftField: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    paddingRight: 20,
  },
  rightField: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  descriptionInputRoot: {},
  descriptionInput: {
    border: '1px solid ' + COLORS.grey,
    borderRadius: BORDER_RADIUS_MAIN,
  },
  selectRoot: { textAlign: 'left' },
  buttonWrapper: {},
}));

const propTypes = {
  product: PropTypes.object.isRequired,
  variant: PropTypes.object.isRequired,
  initialUnits: PropTypes.object,
  isFirst: PropTypes.bool,
  onRemoveVariant: PropTypes.func,
  onChangeVariant: PropTypes.func,
  isNew: PropTypes.bool,
};

const VariantFieldSet = (props) => {
  const { product, variant, units: initialUnits = {}, isFirst, onRemoveVariant, onChangeVariant, isNew } = props;
  const classes = useStyles();
  const intl = useIntl();

  // ea - initial unit for all suppliers
  const units = Object.keys(initialUnits).includes('ea')
    ? Object.keys(initialUnits)
    : ['ea', ...Object.keys(initialUnits)];

  const prices = variant.prices;

  const [unit, setUnit] = useState(variant.unit || 'ea');
  const [unitError, setUnitError] = useState(null);
  const [quantity, setQuantity] = useState(variant?.inventory?.quantity || 0);
  const [name, setName] = useState(variant?.name || '');
  const [nameError, setNameError] = useState(null);
  const [retailPrice, setRetailPrice] = useState(getPrice(PRICES_NAMES.RETAIL_PREORDER_PRICE_LEVEL));
  const [retailPriceError, setRetailPriceError] = useState(null);
  const [wholesalePrice, setWholesalePrice] = useState(getPrice(PRICES_NAMES.WHOLESALE_PREORDER_PRICE_LEVEL));
  const [wholesalePriceError, setWholesalePriceError] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (!initialLoad) {
      const inventory = { quantity };

      onChangeVariant({
        ...variant,
        name,
        unit,
        inventory,
        ...makePrices(),
      });
    }

    setInitialLoad(false);
  }, [unit, quantity, retailPrice, wholesalePrice, name]);

  const makePrices = () => {
    let offered = {
      retail: !!retailPrice,
      wholesale: !!wholesalePrice,
    };
    let prices = [
      { level: 'wholesale_market', price: makeServerValue(wholesalePrice) },
      { level: 'wholesale_preorder', price: makeServerValue(wholesalePrice) },
      { level: 'retail_market', price: makeServerValue(retailPrice) },
      { level: 'retail_preorder', price: makeServerValue(retailPrice) },
    ];
    return {
      offered,
      prices,
    };
  };

  function getPrice(level) {
    const res = prices.find((price) => price.level === level);
    return res?.price / 100 || 0;
  }

  function updateQuantity(newQuantity) {
    const updatedQuantity = makeServerValue(Number.parseInt(newQuantity));

    setQuantity(updatedQuantity);
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {(!!name || product?.variants?.length > 1) && (
          <div className={classes.fieldWrapper}>
            <SupplierTextField
              coloredLabel={isNew && !name}
              className={classes.input}
              error={!!nameError}
              onChange={(event) => setName(event.target.value)}
              value={name}
              id="name-input"
              label={intl.formatMessage({ id: 'supplier.product.variantName' })}
              type="text"
              InputLabelProps={{ shrink: true }}
              helperText={nameError && intl.formatMessage({ id: nameError })}
              fullWidth
            />
          </div>
        )}

        <div className={classes.fieldWrapper}>
          <SupplierSelect
            coloredLabel={isNew}
            className={classes.input}
            classes={{ root: classes.selectRoot }}
            label={intl.formatMessage({ id: 'supplier.variant.unit' })}
            value={unit}
            error={unitError}
            helperText={unitError && intl.formatMessage({ id: unitError })}
            onChange={(e) => setUnit(e.target.value)}>
            {units?.map((unt) => (
              <MenuItem key={unt} value={unt}>
                {unt}
              </MenuItem>
            ))}
          </SupplierSelect>
        </div>

        <div className={classes.twoFieldsWrapper}>
          <div className={classes.leftField}>
            <SupplierTextField
              coloredLabel={isNew}
              moneyField
              error={!!wholesalePriceError}
              helperText={wholesalePriceError && intl.formatMessage({ id: wholesalePriceError })}
              className={classes.input}
              onChange={(event) => setWholesalePrice(getValueForNumberTextInput(event))}
              value={wholesalePrice.toString()}
              id={`${PRICES_NAMES.WHOLESALE_PREORDER_PRICE_LEVEL}-input`}
              label={intl.formatMessage({
                id: `supplier.variant.wholesalePrice`,
              })}
              type="number"
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </div>
          <div className={classes.rightField}>
            <SupplierTextField
              coloredLabel={isNew}
              moneyField
              error={!!retailPriceError}
              helperText={intl.formatMessage({ id: 'supplier.variant.retailPriceHelperText' })}
              className={classes.input}
              onChange={(event) => {
                setRetailPrice(getValueForNumberTextInput(event));
              }}
              value={retailPrice.toString()}
              id={`${PRICES_NAMES.RETAIL_PREORDER_PRICE_LEVEL}-input`}
              label={intl.formatMessage({
                id: `supplier.variant.retailPrice`,
              })}
              type="number"
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </div>
        </div>

        <div className={classes.fieldWrapper}>
          {
            <QuantitySwitcher
              coloredLabel={isNew}
              label={intl.formatMessage({
                id: isNew ? 'supplier.variant.available' : 'supplier.variant.quantityAvailable',
              })}
              quantity={makeClientValue(quantity)}
              onChange={updateQuantity}
            />
          }
        </div>

        {!isFirst && (
          <div className={classes.buttonWrapper}>
            <PrimaryButton
              link={'#'}
              onClick={() => {
                onRemoveVariant(variant.id);
              }}
              text={intl.formatMessage({ id: 'supplier.variant.deleteVariant' })}
              variant={BUTTON_VARIANTS.transparentRed}
            />
          </div>
        )}
      </div>
    </div>
  );
};

VariantFieldSet.propTypes = propTypes;

export default VariantFieldSet;
