import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import { toCurrency } from '../../../../helpers';
import moment from 'moment';
import EmptyImage from '../../assets/no.jpg';
import { ArrowIcon } from '../../assets';
import {
  BORDER_RADIUS_MAIN,
  CUSTOMER_TYPES,
  ORDER_DATE_FORMAT,
  ORDER_DATE_FORMAT_TODAY,
} from '../../constants/globals';
import { COLORS } from '../../constants/colors';
import { FONT_CART_VARIANT_TITLE, FONT_ORDER_NAME, FONT_ORDER_TOTAL } from '../../constants/fonts';
import { withRouter } from '../../../../hocs';
import { isToday } from '../../helpers/global';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },

  singleOrder: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: 20,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 5,
    paddingRight: 5,
    marginLeft: -5,
    marginRight: -5,
    boxSizing: 'border-box',
    borderRadius: BORDER_RADIUS_MAIN,
    cursor: 'pointer',
    backgroundColor: COLORS.white,
    transition: 'background-color 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: COLORS.backgroundLight,
    },
  },
  orderLogoWrapper: {
    width: 38,
    height: 38,
    marginRight: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    borderRadius: 40,
    position: 'relative',
  },
  orderLogo: {
    maxWidth: 40,
    position: 'absolute',
    imageRendering: 'pixelated',
  },
  orderContent: {
    flex: 1,
  },
  orderDate: {
    ...FONT_CART_VARIANT_TITLE,
    marginBottom: 5,
    color: COLORS.blackTintedBlue,
  },
  orderCustomer: {
    ...FONT_ORDER_NAME,
    color: COLORS.grey,
    marginBottom: 5,
  },
  orderTotal: {
    ...FONT_ORDER_TOTAL,
    color: COLORS.blackTintedBlue,
  },
  arrowForward: {
    paddingLeft: 20,
  },
}));

const propTypes = {
  orders: PropTypes.array.isRequired,
  supplier: PropTypes.object.isRequired,
  unlisted: PropTypes.bool,
};

const OrdersList = (props) => {
  const { orders, supplier, history } = props;

  const intl = useIntl();
  const classes = useStyles();

  const drawSingleOrder = (order) => {
    const key = `order_${order.id}`;
    return (
      <div key={key} className={classes.singleOrder} onClick={() => goToOrder(order)}>
        <div className={classes.orderLogoWrapper}>{drawImage()}</div>
        <div className={classes.orderContent}>
          <div className={classes.orderDate}>{drawDate(order)}</div>
          <div className={classes.orderCustomer}>{drawCustomerName(order)}</div>
          <div className={classes.orderTotal}>
            {drawNumberOfItems(order)}
            {', '}
            {toCurrency(order.total, intl)}
          </div>
        </div>
        <div className={classes.arrowForward}>
          <ArrowIcon />
        </div>
      </div>
    );
  };

  const goToOrder = (order) => {
    history.push(`/supplier/sales/order/${order.id}`);
  };

  const drawNumberOfItems = (order) => {
    const numberOfItems = order.packingBinsTotal || 0;

    if (numberOfItems < 2)
      return intl.formatMessage({ id: 'supplier.sales.numberOfItemsSingle' }, { amount: numberOfItems });

    return intl.formatMessage({ id: 'supplier.sales.numberOfItems' }, { amount: numberOfItems });
  };

  const drawCustomerName = (order) => {
    if (order?.customer?.type === CUSTOMER_TYPES.WHOLESALE) return order.customer.companyName;
    if (order?.customer?.type === CUSTOMER_TYPES.ANONYMOUS)
      return intl.formatMessage({ id: 'supplier.sales.anonymous' });

    return `${order.customer.firstName ? order.customer.firstName + ' ' : ''}${
      order.customer.lastName ? order.customer.lastName : ''
    }`;
  };

  const drawDate = (order) => {
    const date = order?.shipping?.date;

    if (isToday(date))
      return (
        intl.formatMessage({ id: 'supplier.sales.today' }) +
        ', ' +
        moment.parseZone(date).format(ORDER_DATE_FORMAT_TODAY)
      );

    return moment.parseZone(date).format(ORDER_DATE_FORMAT);
  };

  const drawImage = () => {
    if (!!supplier?.image?.thumbSrc) {
      return <img className={classes.orderLogo} src={supplier.logotype.thumbSrc} alt={supplier.name} />;
    } else {
      return <img className={classes.orderLogo} src={EmptyImage} alt={supplier.name} />;
    }
  };

  return <div className={classes.root}>{!!orders?.length && orders.map(drawSingleOrder)}</div>;
};

OrdersList.propTypes = propTypes;

export default withRouter(OrdersList);
