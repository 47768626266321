import axios from 'axios';
import {
  getRequestConfig,
  REQUEST_TYPES,
  replaceUrlPlaceholder,
  replaceUrlPlaceholders,
} from  '../request';
import API_END_POINTS from  '../endpoints';

/**
 * Fetch discount
 * @param {id} id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDiscountRetrieve = function(id, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.DISCOUNT_GET_ONE, 'id', id);
  const config = getRequestConfig(REQUEST_TYPES.GET, url);

  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Fetch discount
 * @param {filters} filters
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDiscountsRetrieve = function(filters, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.DISCOUNT_GET_ALL);
  const config = getRequestConfig(REQUEST_TYPES.GET, url);

  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Creates discount
 * @param {data} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDiscountCreate = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.DISCOUNT_CREATE);
  config.data = data;

  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Patches discount
 * @param {string} id
 * @param {data} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDiscountPatch = function (id, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.DISCOUNT_PATCH, 'id', id);
  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  config.data = data;
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Enable discount
 * @param {string} id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDiscountEnable = function (id, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.DISCOUNT_PATCH, 'id', id);
  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  config.data = {active: true};
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Disable discount
 * @param {string} id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDiscountDisable = function (id, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.DISCOUNT_PATCH, 'id', id);
  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  config.data = {active: false};
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Deletes discount
 * @param {string} id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDiscountDelete = function (id, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.DISCOUNT_DELETE, 'id', id);
  const config = getRequestConfig(REQUEST_TYPES.DEL, url);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Deletes discount variant
 * @param {string} id - discount id
 * @param {string} vid - variant id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDiscountDeleteVariant = function (id, vid, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.DISCOUNT_DELETE_VARIANT, {
    id: id,
    vid: vid
  });
  const config = getRequestConfig(REQUEST_TYPES.DEL, url);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Deletes discount customer
 * @param {string} id - discount id
 * @param {string} cid - customer id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDiscountDeleteCustomer = function (id, cid, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.DISCOUNT_DELETE_CUSTOMER, {
    id: id,
    cid: cid
  });
  const config = getRequestConfig(REQUEST_TYPES.DEL, url);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};