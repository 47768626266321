import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../../../hocs';
import MenuItem from '@material-ui/core/MenuItem';
import { PrimarySelect, PrimaryInlineDatePicker } from '../../../../components';
import { SHIPPING_TYPES } from '../../../../helpers';

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  shippingType: {
    width: '80%',
    marginBottom: 5,
  },
  shippingDate: {
    width: '80%',
    marginBottom: 5,
  },
};

const propTypes = {
  producer: PropTypes.object.isRequired,
  wrapperClass: PropTypes.string,
  order: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemoveShippingDeliveryCompany: PropTypes.func.isRequired,
};

const companyRegExp = new RegExp('^dc_');

class Shipping extends React.Component {
  constructor(props) {
    super(props);
    const {
      order: { shipping, customer },
    } = props;

    const selectedShippingAddress = customer?.shippingAddress;

    let shippingMethod = shipping ? shipping.type : -1;
    let shippingMethodHidden = shipping ? shipping.type : -1;

    if (!selectedShippingAddress && shippingMethod === SHIPPING_TYPES.SHIPPING_DELIVERY) {
      shippingMethod = -1;
    }

    let deliveryCompanyId =
      shipping && shipping.deliveryCompany && shipping.deliveryCompany.id ? shipping.deliveryCompany.id : null;

    let pickupLocationId = shipping && shipping.location && shipping.location.id ? shipping.location.id : null;

    if (shippingMethod === SHIPPING_TYPES.SHIPPING_DELIVERY && deliveryCompanyId) {
      shippingMethod = deliveryCompanyId;
      shippingMethodHidden = SHIPPING_TYPES.SHIPPING_DELIVERY;
    }

    if (shippingMethod === SHIPPING_TYPES.SHIPPING_PICKUP && pickupLocationId) {
      shippingMethod = pickupLocationId;
      shippingMethodHidden = SHIPPING_TYPES.SHIPPING_PICKUP;
    }

    this.state = {
      deliveryCompany: deliveryCompanyId,
      pickupLocation: pickupLocationId,
      shippingMethod: shippingMethod,
      shippingMethodHidden: shippingMethodHidden,
      shippingDate: shipping ? shipping.date : null,
      selectedShippingAddress: selectedShippingAddress || null,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.onUpdateCustomer(prevProps);
  }

  onUpdateCustomer = (prevProps) => {
    if (prevProps.order?.customer?.id !== this.props.order?.customer?.id && !!this.props.order?.customer?.id) {
      const { onRemoveShippingDeliveryCompany } = this.props;

      const selectedShippingAddress = this.props.order?.customer?.shippingAddress || null;
      let shippingMethod = this.state.shippingMethod;
      const isCompany = this.state?.shippingMethod !== -1 && this.state.shippingMethod.match(companyRegExp);

      if (!this.props.order?.customer?.shippingAddress && isCompany) {
        shippingMethod = -1;
      }

      this.setState(
        {
          selectedShippingAddress,
          ...(!!shippingMethod
            ? {
                shippingMethod,
                deliveryCompany: null,
              }
            : {}),
        },
        () => {
          if (!!this?.props?.order?.shipping?.deliveryCompany) onRemoveShippingDeliveryCompany(this.props.order);
        }
      );
    }
  };

  onChangeShippingMethod = (method) => {
    const { producer } = this.props;
    // All possible values except delivery companies
    const values = [-1, SHIPPING_TYPES.SHIPPING_DELIVERY, SHIPPING_TYPES.SHIPPING_PICKUP];
    const location = new RegExp('^loc_');

    if (values.indexOf(method) === -1 && method.match(companyRegExp)) {
      // This is delivery company
      let state = {
        shippingMethod: method,
        shippingMethodHidden: SHIPPING_TYPES.SHIPPING_DELIVERY,
        pickupLocation: null,
        deliveryCompany: method,
      };

      this.setState(state, () => {
        this.onReadyForSave();
      });
    }

    if (values.indexOf(method) === -1 && method.match(location)) {
      // This is location
      let state = {
        shippingMethod: method,
        shippingMethodHidden: SHIPPING_TYPES.SHIPPING_PICKUP,
        deliveryCompany: null,
        pickupLocation: method,
      };

      this.setState(state, () => {
        this.onReadyForSave();
      });
    }

    if (method === SHIPPING_TYPES.SHIPPING_PICKUP && !!producer?.locations?.length) {
      let state = {
        shippingMethod: method,
        shippingMethodHidden: method,
        deliveryCompany: null,
        pickupLocation: null,
      };

      this.setState(state);
      return;
    }

    if (values.indexOf(method) !== -1) {
      // This is something that is not delivery company and not location
      let state = {
        shippingMethod: method,
        shippingMethodHidden: method,
        deliveryCompany: null,
        pickupLocation: null,
      };

      this.setState(state, () => {
        this.onReadyForSave();
      });
    }
  };

  isPickup = () => {
    return SHIPPING_TYPES.SHIPPING_PICKUP === this.state.shippingMethodHidden;
  };

  onChangeShippingDate = (date) => {
    this.setState({ shippingDate: date.startOf('day').toISOString(true) }, () => {
      this.onReadyForSave();
    });
  };

  onReadyForSave = () => {
    if (this.state.shippingMethod && this.state.shippingMethod !== -1 && this.state.shippingDate) {
      this.props.onChange(
        this.state.shippingMethodHidden,
        { date: this.state.shippingDate },
        this.state.deliveryCompany,
        this.state.pickupLocation
      );
    }
  };

  getShippingTypeValue = () => {
    const method = String(this.state.shippingMethod);
    const isLocation = method.match('^loc_');
    const isCompany = method.match('^dc_');

    if (isLocation || method === SHIPPING_TYPES.SHIPPING_PICKUP) {
      return SHIPPING_TYPES.SHIPPING_PICKUP;
    }
    if (isCompany || method === SHIPPING_TYPES.SHIPPING_DELIVERY) {
      return method;
    }

    return -1;
  };

  getPickupLocationValue = () => {
    const method = String(this.state.shippingMethod);
    const isLocation = method.match('^loc_');

    if (isLocation) return method;

    return -1;
  };

  render() {
    const { intl, producer, wrapperClass, classes } = this.props;

    return (
      <div className={classNames(classes.wrapper, wrapperClass)}>
        <PrimarySelect
          displayEmpty
          className={classes.shippingType}
          label={intl.formatMessage({ id: 'global.deliveryMethod' })}
          value={this.getShippingTypeValue()}
          onChange={(e) => this.onChangeShippingMethod(e.target.value)}>
          <MenuItem key={-1} value={-1}>
            {intl.formatMessage({ id: 'global.selectDeliveryMethod' })}
          </MenuItem>

          <MenuItem key={SHIPPING_TYPES.SHIPPING_PICKUP} value={SHIPPING_TYPES.SHIPPING_PICKUP}>
            {intl.formatMessage({ id: 'global.pickup' })}
          </MenuItem>

          {(!producer.deliveryCompanies.length || this.state.shippingMethod === SHIPPING_TYPES.SHIPPING_DELIVERY) &&
            !!this.state.selectedShippingAddress && (
              <MenuItem key={SHIPPING_TYPES.SHIPPING_DELIVERY} value={SHIPPING_TYPES.SHIPPING_DELIVERY}>
                {intl.formatMessage({ id: 'global.delivery' })}
              </MenuItem>
            )}

          {!!producer.deliveryCompanies.length &&
            !!this.state.selectedShippingAddress &&
            producer.deliveryCompanies.map((company) => (
              <MenuItem key={company.id} value={company.id}>
                {intl.formatMessage({ id: 'global.delivery' })} - {company.name}
              </MenuItem>
            ))}
        </PrimarySelect>

        {this.isPickup() && (
          <PrimarySelect
            displayEmpty
            className={classes.shippingType}
            label={intl.formatMessage({ id: 'global.pickupLocation' })}
            value={this.getPickupLocationValue()}
            onChange={(e) => this.onChangeShippingMethod(e.target.value)}>
            <MenuItem key={-1} value={-1} selected>
              {intl.formatMessage({ id: 'global.selectLocation' })}
            </MenuItem>
            {!!producer.locations.length &&
              producer.locations.map((location) => (
                <MenuItem key={location.id} value={location.id}>
                  {location.name}
                </MenuItem>
              ))}
          </PrimarySelect>
        )}

        <PrimaryInlineDatePicker
          label={intl.formatMessage({ id: 'global.deliveryDate' })}
          emptyLabel={intl.formatMessage({ id: 'global.selectDeliveryDate' })}
          className={classes.shippingDate}
          value={this.state.shippingDate}
          onChange={(date) => this.onChangeShippingDate(date)}
        />
      </div>
    );
  }
}

Shipping.propTypes = propTypes;

export default withStyles(styles, { withTheme: true })(injectIntl(withRouter(Shipping)));
