import { getSupplierRequestConfig, replaceUrlPlaceholder, REQUEST_TYPES } from '../request';
import API_END_POINTS from '../endpoints';
import axios from 'axios';

/**
 * Retrieve order
 * @param {string} id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierRetrieveOrder = function (id, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.ORDER_GET_ONE, 'or', id);
  const config = getSupplierRequestConfig(REQUEST_TYPES.GET, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Retrieve orders for suppliers
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierRetrieveOrdersForSuppliers = function (data, onSuccess, onError) {
  const config = getSupplierRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.ORDER_GET_ALL_FOR_SUPPLIERS);
  config.params = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data, response.headers);
    })
    .catch((error) => {
      onError && onError(error);
    });
};
