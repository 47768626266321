import React from 'react';
import { withRouter } from '../../hocs';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { apiProducerAccountsGetList, apiProducerAccountsDelete, apiProducerChangeTokenExpirationTime } from '../../api';
import { AdminDXTable, TabActions } from '../../components';
import {
  COLORS,
  countWidthOfColumns,
  DEFAULT_MAX_CELL_WIDTH,
  DEFAULT_MIN_CELL_WIDTH,
  DEFAULT_TABLE_WIDTH,
  getProducerData,
  HEADER_HEIGHT_STANDARD,
  saveProducerData,
  TOKEN_EXPIRATION_TIME,
} from '../../helpers';
import { getGridPageSize, getGridPageSizes } from '../../helpers/grid';
import Tab from './Components/Tab';
import BaseDialog from '../../dialogs/BaseDialog';
import { ProducerAccountForm, ProducerAccountPasswordForm } from '../../forms';
import { CustomerNotificationDialog } from '../../dialogs';

const styles = {
  wrapper: {
    marginBottom: 40,
  },
  title: {
    fontSize: 25,
    display: 'inline-block',
    color: COLORS.text,
    fontWeight: 300,
    fontFamily: 'Roboto, sans-serif',
  },
  accounts: {
    display: 'flex',
    flexDirection: 'row',
    width: 750,
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  checked: {
    margin: 15,
    fontFamily: 'Roboto',
  },
};

const propTypes = {};
const defaultProps = {};

class AccountsTab extends React.Component {
  constructor(props) {
    super(props);
    const { intl } = this.props;

    this.columns = [
      {
        name: 'firstName',
        title: intl.formatMessage({ id: 'global.firstName' }),
        getCellValue: (row) => row.firstName || '-',
      },
      {
        name: 'lastName',
        title: intl.formatMessage({ id: 'global.lastName' }),
        getCellValue: (row) => row.lastName || '-',
      },
      {
        name: 'username',
        title: intl.formatMessage({ id: 'global.username' }),
        getCellValue: (row) => row.username || '-',
      },
      {
        name: 'email',
        title: intl.formatMessage({ id: 'global.email' }),
        getCellValue: (row) => row.email || '-',
      },
    ];

    this.state = {
      customerNotificationDialog: false,
      selectedRow: null,
      checked: false,

      columnWidths: countWidthOfColumns(this.columns, [], [], DEFAULT_TABLE_WIDTH - 100),
    };

    this.table = null;
  }

  getTableActions = () => {
    const { intl, history } = this.props;

    return [
      {
        title: intl.formatMessage({ id: 'screen.customers.notifications' }),
        action: (row) => this.setState({ customerNotificationDialog: true, selectedRow: row }),
      },
      {
        title: intl.formatMessage({ id: 'screen.customers.edit' }),
        action: (row) => history.push(`${this.props.match.url}/${row.id}#accounts`),
      },
      // {
      //   title: intl.formatMessage({id: 'screen.customers.access'}),
      //   action: row => this.handleCustomerEdit(row),
      // },
      {
        title: intl.formatMessage({ id: 'screen.customers.changePassword' }),
        action: (row) => history.push(`${this.props.match.url}/password/${row.id}#accounts`),
      },
      {
        title: intl.formatMessage({ id: 'screen.customers.deleteUser' }),
        separator: true,
        action: (row) => this.onDeleteAccount(row),
      },
    ];
  };

  componentWillUnmount() {
    this.table = null;
  }

  getActions = () => {
    const { intl, history } = this.props;
    return (
      <TabActions
        onCreateEntity={() => history.push(`${this.props.match.url}/new#accounts`)}
        labels={{ createEntity: intl.formatMessage({ id: 'producer.tab.accounts.newUser' }) }}
      />
    );
  };

  onDeleteAccount = (row) => {
    apiProducerAccountsDelete(row.id, () => {
      this.table.forceReload();
    });
  };

  componentDidMount() {
    this.setState({ checked: this.checkedState() });
  }

  changeTokenExpirationTime = async (e) => {
    let checked = e.target.checked;
    let time;

    if (checked) {
      time = TOKEN_EXPIRATION_TIME.WEEKLY;
    } else {
      time = TOKEN_EXPIRATION_TIME.MONTHLY;
    }
    await apiProducerChangeTokenExpirationTime(
      { tokenExpirationTime: time },
      async (res) => {
        await saveProducerData(res);
        this.setState({ checked: this.checkedState() });
      },
      (e) => {
        console.log(e);
      }
    );
  };

  checkedState = () => {
    if (!getProducerData().tokenExpirationTime) {
      return false;
    }

    return getProducerData().tokenExpirationTime === TOKEN_EXPIRATION_TIME.WEEKLY;
  };

  render() {
    const {
      props: { intl, history, openEditModal, openPwdModal },
    } = this;

    return (
      <>
        <Tab
          title={intl.formatMessage({ id: 'producer.tab.users' })}
          actions={this.getActions()}
          style={{ width: 'auto' }}>
          <AdminDXTable
            enableSearch={false}
            onRef={(table) => (this.table = table)}
            apiRetrieve={(filters, onSuccess, onError) => apiProducerAccountsGetList(onSuccess, onError)}
            columnExtensions={[{ columnName: 'actions', align: 'center', width: 200 }]}
            pageSize={getGridPageSize()}
            pageSizes={getGridPageSizes()}
            columns={this.columns}
            actions={this.getTableActions()}
            actionsGroupButton={true}
            columnWidths={this.state.columnWidths}
            onColumnWidthsChange={(widths) => {
              this.setState({ columnWidths: widths });
            }}
            minColumnWidth={DEFAULT_MIN_CELL_WIDTH}
            maxColumnWidth={DEFAULT_MAX_CELL_WIDTH}
            allowExpandAll={true}
            stickyHeader
            stickyHeaderOffset={HEADER_HEIGHT_STANDARD.standard}
          />

          <FormControlLabel
            className={this.props.classes.checked}
            control={
              <Checkbox
                checked={this.state.checked}
                onChange={(e) => this.changeTokenExpirationTime(e)}
                name="checkedF"
                color="default"
              />
            }
            label="Sign out users weekly"
          />

          {this.state.customerNotificationDialog && (
            <CustomerNotificationDialog
              selectedRow={this.state.selectedRow}
              reloadTable={() => this.table.forceReload()}
              onClose={() => this.setState({ customerNotificationDialog: false, selectedRow: null })}
            />
          )}
        </Tab>
        {openEditModal && (
          <BaseDialog
            scroll={'paper'}
            maxWidth="md"
            open
            onClose={() => history.push(`/settings#accounts`)}
            title={
              this.props.match.params.id === 'new'
                ? intl.formatMessage({ id: 'producer.tab.accounts.newUser' })
                : intl.formatMessage({ id: 'producer.tab.accounts.updateUser' })
            }>
            <ProducerAccountForm
              {...this.props}
              onDone={() => {
                this.table.forceReload();
                history.goBack();
              }}
            />
          </BaseDialog>
        )}
        {openPwdModal && (
          <BaseDialog
            scroll={'paper'}
            maxWidth="md"
            open
            onClose={() => history.push(`/settings#accounts`)}
            title={intl.formatMessage({ id: 'producer.tab.accounts.changePassword' })}>
            <ProducerAccountPasswordForm
              {...this.props}
              onDone={() => {
                this.table.forceReload();
                history.goBack();
              }}
            />
          </BaseDialog>
        )}
      </>
    );
  }
}

AccountsTab.propTypes = propTypes;
AccountsTab.defaultProps = defaultProps;

export default injectIntl(withRouter(withStyles(styles)(AccountsTab)));
