import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../../../../hocs';
import { PrimaryTextField, PrimarySelect, PrimaryInlineTimePicker, ErrorBox } from '../../../../../components';
import { validateField, capitalizeFirstLetter } from '../../../../../helpers';
import Locations from './Locations';
import validationRules from './validation';

const styles = {
  wrapper: {},
  wrapperHidden: {
    composes: '$wrapper',
    display: 'none',
  },
  selectFormControlClassName: {
    display: 'block',
    marginTop: 20,
    marginBottom: 20,
  },
  select: {
    width: '100%',
  },
  shippingType: {
    color: '#707070',
    padding: 0,
    fontSize: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  label: {
    fontSize: 14,
  },
};

const propTypes = {
  days: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  onRef: PropTypes.func.isRequired,
  locations: PropTypes.array.isRequired,
  onAttachLocation: PropTypes.func.isRequired,
  onDetachLocation: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  onEditLocation: PropTypes.func,
  onToggleLocation: PropTypes.func,
};

const WEEK_DAYS = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

class EntityForm extends React.Component {
  constructor(props) {
    super(props);
    this.props.onRef(this);

    const { data } = this.props;

    let untilTimeMoment = moment();
    if (data.until && data.until.time) {
      untilTimeMoment = moment(data.until.time, 'HH:mm:ss');
    }
    let untilTime = untilTimeMoment.format('HH:mm:ss');

    this.state = {
      day: data.day || this.getFreeDays()[0],
      untilDay: (data.until && data.until.day) || WEEK_DAYS[0],
      untilTimeMoment: untilTimeMoment,
      untilTime: untilTime,

      error: '',
      dayError: '',
      pickupError: '',
      deliveryError: '',
      untilDayError: '',
      untilTimeError: '',
    };
  }

  getFreeDays = () => {
    let days = WEEK_DAYS.slice();
    for (let i = 0; i < this.props.days.length; i++) {
      let index = days.indexOf(this.props.days[i].day);
      if (index !== -1) {
        days.splice(index, 1);
      }
    }
    return days;
  };

  getTextField(fieldName, namespace, prefix) {
    const { classes, intl } = this.props;

    return (
      <PrimaryTextField
        className={classes.input}
        InputLabelProps={{
          classes: {
            root: classes.label,
          },
        }}
        autoComplete="no"
        error={!!this.state[`${fieldName}Error`]}
        onChange={(event) => {
          let value = event.target.value;
          this.setState((state) => {
            state[fieldName] = value;
            return state;
          });
        }}
        value={this.state[fieldName]}
        id={`${fieldName}-input`}
        label={(prefix || '') + intl.formatMessage({ id: `${namespace}.${fieldName}` })}
        type="text"
        margin="normal"
        helperText={this.state[`${fieldName}Error`] && intl.formatMessage({ id: this.state[`${fieldName}Error`] })}
        fullWidth
      />
    );
  }

  validate = () => {
    const { intl } = this.props;
    const dayError = validateField(validationRules, 'day', this.state.day);
    const untilDayError = validateField(validationRules, 'untilDay', this.state.untilDay);
    const untilTimeError = validateField(validationRules, 'untilTime', this.state.untilTime);
    let error = '';
    if (!this.props.locations.length) {
      error = intl.formatMessage({ id: 'validation.specifyAtLeastOneLocation' });
    }

    let validated = false;
    if (!dayError && !untilDayError && !untilTimeError && !error) {
      this.setState({
        dayError: '',
        pickupError: '',
        deliveryError: '',
        untilDayError: '',
        untilTimeError: '',
        error: '',
      });
      validated = true;
    } else {
      this.setState({
        dayError: dayError,
        error: error,
        deliveryError: '',
        untilDayError: untilDayError,
        untilTimeError: untilTimeError,
      });
    }

    return {
      validated: validated,
      data: {
        day: this.state.day,
        until: {
          day: this.state.untilDay,
          time: this.state.untilTime,
        },
      },
    };
  };

  render() {
    const { classes, intl, visible } = this.props;

    return (
      <div className={visible ? classes.wrapper : classes.wrapperHidden}>
        {this.state.error && <ErrorBox error={this.state.error} />}

        {!this.props.data.id && (
          <PrimarySelect
            classes={{ root: classes.select }}
            className={classes.select}
            formControlClassName={classes.selectFormControlClassName}
            value={this.state.day}
            label={intl.formatMessage({ id: 'global.day' })}
            onChange={(e) => this.setState({ day: e.target.value })}>
            {this.getFreeDays().map((day) => (
              <MenuItem style={{ textTransform: 'capitalize' }} key={day} value={day}>
                {capitalizeFirstLetter(day)}
              </MenuItem>
            ))}
          </PrimarySelect>
        )}

        <PrimarySelect
          classes={{ root: classes.select }}
          className={classes.select}
          formControlClassName={classes.selectFormControlClassName}
          value={this.state.untilDay}
          label={intl.formatMessage({ id: 'global.untilDay' })}
          onChange={(e) => this.setState({ untilDay: e.target.value })}>
          {WEEK_DAYS.map((day) => (
            <MenuItem style={{ textTransform: 'capitalize' }} key={day} value={day}>
              {capitalizeFirstLetter(day)}
            </MenuItem>
          ))}
        </PrimarySelect>

        <PrimaryInlineTimePicker
          fullWidth
          style={{ marginBottom: 20 }}
          error={!!this.state.untilTimeError}
          helperText={this.state.untilTimeError && intl.formatMessage({ id: this.state.untilTimeError })}
          label={intl.formatMessage({ id: 'global.untilTime' })}
          value={this.state.untilTimeMoment}
          onChange={(untilTimeMoment) => {
            this.setState({
              untilTimeMoment: untilTimeMoment,
              untilTime: untilTimeMoment.format('HH:mm:ss'),
            });
          }}
        />

        <Locations
          locations={this.props.locations}
          onAttachLocation={this.props.onAttachLocation}
          onDetachLocation={this.props.onDetachLocation}
          onEditLocation={this.props.onEditLocation}
          onToggleLocation={this.props.onToggleLocation}
        />
      </div>
    );
  }
}

EntityForm.propTypes = propTypes;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(EntityForm)));
