import React from 'react';
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import DXSelectFilter from '../DXSelectFilter/DXSelectFilter'
import DXFilterConfig from '../DXFilterConfig'

const propTypes = {
  equality: PropTypes.string,
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  selected: [],
};

class DXOrderPaidToProducerFilter extends React.Component {

  render() {
    const { selected, onChange, intl, equality} = this.props;
    const config = DXOrderPaidToProducerFilter.getConfig(intl, equality);
    return (
      <DXSelectFilter selected={selected}
                      title={config.title}
                      values={config.values}
                      onChange={onChange}
      />
    )
  }
}

DXOrderPaidToProducerFilter.propTypes = propTypes;
DXOrderPaidToProducerFilter.defaultProps = defaultProps;
DXOrderPaidToProducerFilter.getLabel = (intl, selectedValues) => {
  let label = "";
  if (Array.isArray(selectedValues) && selectedValues.length) {
    const config = DXOrderPaidToProducerFilter.getConfig(intl);
    const values = config.values;
    let parts = [];

    selectedValues.forEach(value => {
      const translation = values.filter(item => item.key === value);
      if (translation.length) {
        parts.push(translation[0].label)
      }
    });
    label = parts.join(', ');
  }
  return label;
};
DXOrderPaidToProducerFilter.applyToQueryParams = (queryParams, selectedValues) => {
  if (selectedValues.length) {
    queryParams[`paidToProducer`] = selectedValues[0] === 'paid';
  }
  return queryParams;
};
DXOrderPaidToProducerFilter.getConfig = (intl) => {
  let config = new DXFilterConfig({
    name: 'paidToProducer',
    selected: [],
    property: 'paidToProducer',
    title: intl.formatMessage({id: 'filter.paidToProducer'}),
    disabled: false,
    equalityStatus: false,
  });

  config.addFilterValue('paid', intl.formatMessage({id: 'filter.paidToProducer.paid'}));
  config.addFilterValue('not_paid', intl.formatMessage({id: 'filter.paidToProducer.notPaid'}));

  return config;
};

export default injectIntl(
  DXOrderPaidToProducerFilter
);
