export default {
  container: {
    padding: 20,
    backgroundColor: 'white',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  productHeaderLeftSideWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 800,
    flex: 1,
  },
  productHeaderWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  productHeaderBackButton: {},
  productHeaderText: {},
  productHeaderH1: {
    fontSize: 25,
    fontWeight: 'bold',
  },
  productHeaderSaveButton: {},
  productMainContent: {
    marginBottom: 40,
  },
  icon: {
    '& path': {
      fill: '#000',
    },
  },
  productMainTwoSidesWrapper: {
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'row',
  },
  imageBoxWrapper: {
    maxWidth: 400,
    flex: 1,
    paddingRight: 20,
  },
  fieldSetWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  descriptionWrapper: {
    marginTop: 40,
    marginBottom: 40,
  },
  input: {
    width: '100%',
  },
  productMainLeft: {
    maxWidth: '50%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginRight: 20,
  },
  productsMainRight: {
    maxWidth: '50%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  errorWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },

  sectionWrapper: {
    marginTop: 40,
  },
  sectionHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    justifyItems: 'center',
  },
  sectionTitleWrapper: {
    display: 'flex',
  },
  sectionButtonWrapper: {},
  sectionBodyWrapper: {},
  descriptionTitleClass: {
    fontSize: 14,
    fontWeight: 400,
  },
  wholesaleDescriptionWrapper: {},
  subWrapperClass: {
    paddingBottom: 0,
  },
  mt20: {
    marginTop: 20,
  },

  variantsSuggestionInput: {
    marginTop: 0,
  },
  selectWrapper: {
    width: '100%',
  },
};
