import React from 'react';
import { COLORS } from '../constants/colors';

const CalcButton9 = (props) => {
  const { color = COLORS.blackTintedBlue, ...other } = props;
  return (
    <svg width="37" height="34" viewBox="0 0 37 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <path
        d="M2.77969 34H4.17129L5.48965 29.2539H5.56777L6.90078 34H8.28262L10.1527 26.9541H8.6293L7.54043 31.9883H7.4623L6.15371 26.9541H4.90859L3.62441 31.9883H3.54629L2.44765 26.9541H0.914452L2.77969 34ZM12.7121 34H14.3039L15.842 31.5293H15.8908L17.4338 34H19.1135L16.8381 30.4795V30.46L19.1477 26.9541H17.4973L16.0129 29.4932H15.9543L14.4602 26.9541H12.7219L14.9484 30.4355V30.4502L12.7121 34ZM24.0801 34H25.5547V31.3682L28.0107 26.9541H26.4141L24.8662 29.918H24.7783L23.2256 26.9541H21.6289L24.0801 31.3682V34ZM30.7166 34H36.1561V32.7842H32.5232V32.6963L36.0584 27.9111V26.9541H30.7996V28.1699H34.2615V28.2578L30.7166 33.0381V34Z"
        fill={color}
      />
      <path
        d="M19.5181 0.970215C15.9658 0.970215 13.3535 3.52148 13.3535 7.03711V7.06152C13.3535 10.4429 15.8193 12.9575 19.1274 12.9575C21.5078 12.9575 23.168 11.6758 23.8149 10.2354H24.0469C24.0469 10.3696 24.0469 10.5161 24.0347 10.6504C23.9004 14.3247 22.5942 17.4497 19.396 17.4497C17.6138 17.4497 16.3809 16.5342 15.8438 15.0571L15.7949 14.9229H13.5854L13.6221 15.0815C14.2202 17.6816 16.4175 19.415 19.3716 19.415C23.644 19.415 26.1465 15.8994 26.1465 9.89355V9.86914C26.1465 3.24072 22.7407 0.970215 19.5181 0.970215ZM19.5059 11.041C17.2231 11.041 15.563 9.35645 15.563 7.0127V6.98828C15.563 4.70557 17.333 2.91113 19.5425 2.91113C21.7764 2.91113 23.522 4.74219 23.522 7.06152V7.07373C23.522 9.36865 21.7886 11.041 19.5059 11.041Z"
        fill={color}
      />
    </svg>
  );
};

export default CalcButton9;
