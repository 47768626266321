import React, { Component } from 'react';
import PropTypes from 'prop-types';

/** Component that alerts if you click outside of it */
export default class OutsideAlerter extends Component {
  constructor(props) {
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  UNSAFE_componentWillMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /** Alert if clicked on outside of element */
  handleClickOutside(event) {
    const { onClickOutside } = this.props;
    if (this.node.contains(event.target)) {
      return;
    }
    onClickOutside && onClickOutside();
    return true;
  }

  render() {
    return <div ref={(node) => (this.node = node)}>{this.props.children}</div>;
  }
}

OutsideAlerter.propTypes = {
  onClickOutside: PropTypes.func,
  children: PropTypes.element.isRequired,
};
