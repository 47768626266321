import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { PrimarySelect, ConfirmDialog } from '../../../components';
import { PrintInvoicesDialog } from '../../../dialogs';
import {
  apiOrdersCancel,
  apiOrdersPrint,
  apiOrdersMarkReceived,
  apiOrderPackingListBulkPrint,
  apiQuickBooksSetMultiplyOrdersAsBooked,
  apiQuickBooksSetMultiplyOrdersAsPaid,
} from '../../../api';

const styles = {
  select: {
    marginRight: 10,
  },
};

const LABEL = -1;
const CANCEL = 1;
const PRINT_INVOICES = 2;
const MARK_RECEIVED = 3;
const PRINT_PACKING_LIST = 4;
const MARK_AS_BOOKED = 5;
const MARK_AS_PAID = 6;

const propTypes = {
  reload: PropTypes.func,
  keys: PropTypes.array.isRequired,
};

class OrderBatchControls extends React.Component {
  state = {
    confirmError: '',
    confirmBooking: false,
    confirmPaid: false,
    confirmLoading: false,
    confirmCancel: false,
    printInvoices: false,
    printInvoicesLoading: false,
    printInvoicesUrl: false,
    printPackingList: false,
    apiError: '',
  };

  onSelectAction = (action) => {
    switch (action) {
      case CANCEL: {
        this.setState({ confirmCancel: true, apiError: '' });
        break;
      }
      case PRINT_INVOICES: {
        this.setState(
          {
            printInvoices: true,
            printInvoicesLoading: true,
          },
          () => {
            this.onPrintInvoices();
          }
        );
        break;
      }
      case MARK_RECEIVED: {
        this.onMarkReceived();
        break;
      }
      case PRINT_PACKING_LIST: {
        this.onPrintPackingList();
        break;
      }
      case MARK_AS_BOOKED: {
        this.setState({ confirmBooking: true });
        break;
      }
      case MARK_AS_PAID: {
        this.setState({ confirmPaid: true });
        break;
      }
      default: {
        break;
      }
    }
  };

  onPrintPackingList = () => {
    const { keys } = this.props;

    apiOrderPackingListBulkPrint(
      keys,
      (data) => {
        // Create a Blob from the PDF Stream
        const file = new Blob([data], { type: 'application/pdf' });

        // Build a URL from the file
        const fileURL = URL.createObjectURL(file);

        // Open the URL on new Window
        window.open(fileURL);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  onMarkReceived = () => {
    const { keys, reload } = this.props;

    apiOrdersMarkReceived(
      keys,
      () => {
        reload();
      },
      undefined
    );
  };

  onMarkBooked = async () => {
    const { keys, rows, reload } = this.props;

    let orderIds = rows.filter((item) => !item.booked && keys.includes(item.id)).map((item) => item.id);

    this.setState({ confirmLoading: true });
    return apiQuickBooksSetMultiplyOrdersAsBooked(
      orderIds,
      () => {
        this.setState(
          {
            confirmBooking: false,
            confirmLoading: false,
          },
          () => {
            reload();
          }
        );
      },
      this.handleOnMarkBookedError
    );
  };

  handleOnMarkBookedError = (error) => {
    const { intl } = this.props;
    const errorCode = error?.response?.data?.code || 0;

    let errorMessage = error?.response?.data?.message;
    let isClose = true;

    switch (errorCode) {
      case '8745f411-8946-472f-b347-0f061f4c14d1': {
        errorMessage = intl.formatMessage({ id: 'error.quickBooksCustomerDisabled' });
        isClose = false;
        break;
      }
      default: {
        break;
      }
    }

    this.setState({
      confirmError: errorMessage,
      confirmBooking: !isClose,
      confirmLoading: false,
    });
  };

  onMarkPaid = async () => {
    const { keys, rows, reload } = this.props;

    this.setState({ confirmLoading: true });
    return apiQuickBooksSetMultiplyOrdersAsPaid(
      keys,
      () => {
        this.setState(
          {
            confirmPaid: false,
            confirmLoading: false,
            confirmError: '',
          },
          () => {
            reload();
          }
        );
      },
      () => {
        const notBooked = rows
          .filter((item) => keys.includes(item.id) && !item.booked)
          .map((item) => item.name)
          .join(', ');

        this.setState({
          confirmError: notBooked,
          confirmLoading: false,
        });
      }
    );
  };

  onMarkBookedAndPaid = async () => {
    await this.onMarkBooked();
    await this.onMarkPaid();
  };

  onPrintInvoices = () => {
    const { keys } = this.props;
    apiOrdersPrint(
      keys,
      (data) => {
        this.setState({
          printInvoicesUrl: data.url.replace('http://', 'https://'),
          printInvoicesLoading: false,
        });
      },
      undefined
    );
  };

  onCancel = () => {
    const { keys, reload } = this.props;

    this.setState({ confirmLoading: true });
    apiOrdersCancel(
      keys,
      () => {
        this.setState(
          {
            confirmCancel: false,
            confirmLoading: false,
          },
          () => {
            reload();
          }
        );
      },
      this.onError
    );
  };

  onError = (error) => {
    const { intl } = this.props;
    const message = error?.response?.data?.message || intl.formatMessage({ id: 'global.somethingWentWrong' });

    this.setState({
      apiError: message,
      confirmLoading: false,
    });
  };

  render() {
    const { intl, classes } = this.props;

    const {
      confirmError,
      confirmBooking,
      confirmPaid,
      confirmCancel,
      confirmLoading,
      printInvoices,
      printInvoicesLoading,
      printInvoicesUrl,
      apiError,
    } = this.state;

    return (
      <React.Fragment>
        <PrimarySelect className={classes.select} value={LABEL} onChange={(e) => this.onSelectAction(e.target.value)}>
          <MenuItem key={LABEL} value={LABEL}>
            {intl.formatMessage({ id: 'order.table.batchActions' })}
          </MenuItem>
          <MenuItem key={CANCEL} value={CANCEL}>
            {intl.formatMessage({ id: 'order.table.cancel' })}
          </MenuItem>
          <MenuItem key={PRINT_INVOICES} value={PRINT_INVOICES}>
            {intl.formatMessage({ id: 'order.table.prepareInvoicesForPrint' })}
          </MenuItem>
          <MenuItem key={MARK_RECEIVED} value={MARK_RECEIVED}>
            {intl.formatMessage({ id: 'order.table.markOrdersAsReceived' })}
          </MenuItem>
          <MenuItem key={PRINT_PACKING_LIST} value={PRINT_PACKING_LIST}>
            {intl.formatMessage({ id: 'order.table.printPackingList' })}
          </MenuItem>
          <MenuItem key={MARK_AS_BOOKED} value={MARK_AS_BOOKED}>
            {intl.formatMessage({ id: 'order.table.markAsBooked' })}
          </MenuItem>
          <MenuItem key={MARK_AS_PAID} value={MARK_AS_PAID}>
            {intl.formatMessage({ id: 'order.table.markAsPaid' })}
          </MenuItem>
        </PrimarySelect>

        {printInvoices && (
          <PrintInvoicesDialog
            onClose={() => this.setState({ printInvoices: false })}
            loading={printInvoicesLoading}
            url={printInvoicesUrl}
          />
        )}

        {confirmCancel && (
          <ConfirmDialog
            onClose={() => this.setState({ confirmCancel: false })}
            loading={confirmLoading}
            confirmTitle={'confirm.cancelOrders'}
            title={intl.formatMessage({ id: 'titles.cancelOrders' })}
            message={!apiError && intl.formatMessage({ id: 'messages.confirmCancelOrders' })}
            error={apiError}
            onConfirm={this.onCancel}
          />
        )}

        {confirmBooking && (
          <ConfirmDialog
            onClose={() => this.setState({ confirmBooking: false })}
            loading={confirmLoading}
            confirmTitle={'confirm.markAsBooked'}
            title={intl.formatMessage({ id: 'confirm.markAsBookedTitle' })}
            message={intl.formatMessage({ id: 'confirm.markAsBookedMessage' })}
            error={confirmError}
            onConfirm={this.onMarkBooked}
          />
        )}

        {confirmPaid && (
          <ConfirmDialog
            onClose={() => this.setState({ confirmPaid: false, confirmError: '' })}
            loading={confirmLoading}
            confirmTitle={!confirmError ? 'confirm.marksAsPaid' : 'confirm.bookAndPaid'}
            title={intl.formatMessage({ id: 'confirm.marksAsPaidTitle' })}
            message={
              !confirmError
                ? intl.formatMessage({ id: 'confirm.marksAsPaidMessage' })
                : intl.formatMessage({ id: 'confirm.marksAsPaidError' }, { list: confirmError })
            }
            onConfirm={!confirmError ? this.onMarkPaid : this.onMarkBookedAndPaid}
          />
        )}
      </React.Fragment>
    );
  }
}

OrderBatchControls.propTypes = propTypes;

export default withStyles(styles)(injectIntl(OrderBatchControls));
