import React, { useEffect, useState } from 'react';
import SimpleToast from '../SimpleToast';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

export const TOAST_ACTIONS = {
  empty: '',
  added: 'added',
  updated: 'updated',
  unlisted: 'unlisted',
  listed: 'listed',
  inventoryUpdated: 'inventoryUpdated',
};

const propTypes = {
  action: PropTypes.string,
  onClose: PropTypes.func,
};

const ActionToast = (props) => {
  const { action, delay = 5000, onClose } = props;
  const intl = useIntl();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (action !== TOAST_ACTIONS.empty) setIsOpen(true);
  }, [action]);

  const getMessageFromAction = () => {
    let intlId = '';

    switch (action) {
      case TOAST_ACTIONS.added: {
        intlId = 'supplier.actions.added';
        break;
      }
      case TOAST_ACTIONS.updated: {
        intlId = 'supplier.actions.updated';
        break;
      }
      case TOAST_ACTIONS.unlisted: {
        intlId = 'supplier.actions.unlisted';
        break;
      }
      case TOAST_ACTIONS.listed: {
        intlId = 'supplier.actions.listed';
        break;
      }
      case TOAST_ACTIONS.inventoryUpdated: {
        intlId = 'supplier.actions.inventoryUpdated';
        break;
      }
      default: {
        return null;
      }
    }

    return intl.formatMessage({ id: intlId });
  };

  return (
    <SimpleToast
      message={getMessageFromAction()}
      isOpen={isOpen}
      delay={delay}
      onClose={() => {
        setIsOpen(false);
        onClose && onClose();
      }}
    />
  );
};

ActionToast.propTypes = propTypes;

export default ActionToast;
