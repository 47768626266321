import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 57,
  },
};

const SubWrapper = ({children, classes, subWrapperClass}) => {
  return (
    <div className={classNames([classes.wrapper, subWrapperClass])}>
      {children}
    </div>
  );
};

export default withStyles(styles)(SubWrapper);
