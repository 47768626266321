import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import DXSelectFilter from '../DXSelectFilter/DXSelectFilter';
import DXFilterConfig from '../DXFilterConfig';

const propTypes = {
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  selected: [],
};

class DXShippingTypesFilter extends React.Component {
  render() {
    const { selected, onChange, intl } = this.props;
    const config = DXShippingTypesFilter.getConfig(intl);
    return (
      <DXSelectFilter
        selected={selected}
        title={config.title}
        values={config.values}
        onChange={onChange}
        multi={config.multi}
      />
    );
  }
}

DXShippingTypesFilter.propTypes = propTypes;
DXShippingTypesFilter.defaultProps = defaultProps;
DXShippingTypesFilter.getLabel = (intl, selectedValues) => {
  return DXSelectFilter.getLabel(intl, 'global.', selectedValues);
};
DXShippingTypesFilter.applyToQueryParams = (queryParams, selectedValues) => {
  queryParams['shippingTypes'] = selectedValues;
  return queryParams;
};
DXShippingTypesFilter.getConfig = (intl) => {
  let config = new DXFilterConfig({
    name: 'shipping.types',
    selected: [],
    property: 'shippingTypes',
    title: intl.formatMessage({ id: 'filter.shippingType' }),
    disabled: false,
    equalityStatus: false,
    multi: true,
  });

  config.addFilterValue('delivery', intl.formatMessage({ id: 'global.delivery' }));
  config.addFilterValue('pickup', intl.formatMessage({ id: 'global.pickup' }));

  return config;
};

export default injectIntl(DXShippingTypesFilter);
