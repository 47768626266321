import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from '../../hocs';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { AdminDXTable, ConfirmDialog, MoveDown, Tab, TabActions } from '../../components';
import { apiAllSubscriptionsRetrieve, apiRemoveSubscriptionById } from '../../api';
import {
  COLORS,
  countWidthOfColumns,
  DEFAULT_MAX_CELL_WIDTH,
  DEFAULT_MIN_CELL_WIDTH,
  EXPANDED_ALL_STAGES,
  HEADER_HEIGHT_STANDARD,
  makeColumnWidthEntityArray,
  TABLE_ROW_DETAIL_TYPES,
} from '../../helpers';
import { getGridPageSize, getGridPageSizes } from '../../helpers/grid';
import { CellRenderer, prepareRowsAfterLoadGroupedSubType, summaryCalculator } from './helpers';
import {
  GroupByDialog,
  SubscriptionsFilterDialog,
  SaveTabSettingDialog,
  TabTypes,
  TableRowDetailDialog,
  ChangeSubscriptionsDatesDialog,
} from '../../dialogs';
import {
  ACTIVITY_FILTER,
  CUSTOMER_ALLOW_CREDIT_FILTER,
  CUSTOMER_APPROVED_FILTER,
  CUSTOMER_DEFAULT_PAYMENT_METHOD_FILTER,
  CUSTOMER_TYPE_FILTER,
  CUSTOMER_TYPE_FILTER_FROM_VARIABLE,
  DATE_FILTER,
  HAS_SUBSTRING_FILTER,
  IMAGE_FILTER,
  IN_ARRAY_FILTER,
} from '../../components/AdminDXTable/ClientSideFilters/FilterTypes';
import SubscriptionsScene from './SubscriptionsBatchControls';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { SELECT_DATES_DIALOG_MOVING_VARIANTS } from '../../dialogs/ChangeSubscriptionsDatesDialog';
import { jsonToCsv } from '../OrdersScene/helpers';
import moment from 'moment';
import config, { SUBSCRIPTIONS_VIEW_TYPES } from './config';
import { PREPURCHASES_GROUP_BY } from '../../helpers';

const propTypes = {
  tab: PropTypes.object.isRequired,
  columnExtensions: PropTypes.array,
  onlyActive: PropTypes.bool,
  withFilters: PropTypes.bool,
  allowCreateTab: PropTypes.bool,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onDeleteTab: PropTypes.func,
  onCreateTab: PropTypes.func,
  onPatchTab: PropTypes.func,
  onAddingFilter: PropTypes.func,
};

const defaultProps = {
  onlyActive: false,
};

const CLIENT_SIDE_FILTERS_TO_EXCLUDE = [
  CUSTOMER_APPROVED_FILTER,
  CUSTOMER_TYPE_FILTER,
  CUSTOMER_TYPE_FILTER_FROM_VARIABLE,
  CUSTOMER_ALLOW_CREDIT_FILTER,
  CUSTOMER_DEFAULT_PAYMENT_METHOD_FILTER,
  HAS_SUBSTRING_FILTER,
  IN_ARRAY_FILTER,
  ACTIVITY_FILTER,
  DATE_FILTER,
  IMAGE_FILTER,
];

class SubscriptionsContent extends React.Component {
  constructor(props) {
    super(props);
    const { onRef, tab, appliedFilters } = this.props;

    const columnsConfig = this.getColumnConfig(SUBSCRIPTIONS_VIEW_TYPES.DEFAULT);

    this.state = {
      columns: columnsConfig.columns,
      columnExtensions: columnsConfig.columnExtensions,
      loading: true,
      deleting: null,
      deletingLoading: false,
      hiddenColumnNames: tab.excludeColumns || [],
      tabDialog: false,
      isFiltersDialogActive: false,
      appliedFilters: this.prepareFilters(appliedFilters),
      openWithFilter: null,
      settings: {
        excludeColumns: tab.excludeColumns || [],
        filters: this.prepareFilters(appliedFilters),
      },

      columnWidths:
        makeColumnWidthEntityArray(tab.columnsWidth, columnsConfig.columns) ||
        countWidthOfColumns(columnsConfig.columns, tab.excludeColumns, columnsConfig.columnExtensions),
      // Grouping state
      isGroupByDialogActive: false,
      rebuildingTable: false,
      groupBy: tab?.groupBy || [],
      // Details state
      isDetailDialogActive: false,
      selectedDetailRowVariant: !!tab?.detailColumns && !!tab?.detailColumns[0] ? tab.detailColumns[0] : -1,
      detailRowsPropertyName: null,
      detailRowColumns: null,
      updateSubscriptionsDatesDialog: false,
      updateSubscriptionsDatesDialogStep: null,
      anchorEl: null,
    };
    onRef && onRef(this);
  }

  getColumnConfig = (configType) => {
    const { intl } = this.props;

    const columnsConfig = config(intl);

    return columnsConfig[configType] || columnsConfig[SUBSCRIPTIONS_VIEW_TYPES.DEFAULT];
  };

  prepareFilters = (filters) => {
    const { withFilters } = this.props;

    if (!withFilters) {
      return [];
    }

    return filters.filter((filter) => !CLIENT_SIDE_FILTERS_TO_EXCLUDE.includes(filter.name));
  };

  onChangeColumnsState = (excludeColumns) => {
    this.setState((state) => {
      state.settings['excludeColumns'] = excludeColumns;
      return state;
    });
  };

  onDelete = () => {
    this.setState({ deletingLoading: true });
    apiRemoveSubscriptionById(
      this.state.deleting.subscriptionId,
      () => {
        this.setState({
          deleting: null,
          deletingLoading: false,
        });
        this.table.forceReload();
      },
      undefined
    );
  };

  forceTableReload = () => {
    this.table.forceReload();
  };

  rebuildTable = () => {
    this.setState({ rebuildingTable: true }, () => {
      this.setState({ rebuildingTable: false });
    });
  };

  onCreateTab = () => {
    this.setState({ tabDialog: true });
  };

  onAddFilter = () => {
    this.setState({ isFiltersDialogActive: true });
  };
  onGroupBy = () => {
    this.setState({ isGroupByDialogActive: true });
  };
  onDetails = () => {
    this.setState({ isDetailDialogActive: true });
  };

  onUpdateTab = () => {
    const { tab, onPatchTab } = this.props;
    const { groupBy, columnWidths, selectedDetailRowVariant } = this.state;

    if (tab.id) {
      onPatchTab(tab, {
        ...this.state.settings,
        groupBy,
        detailColumns: [selectedDetailRowVariant],
        columnsWidth: columnWidths,
      });
    }
  };

  getFiltersDialog = (onClose) => {
    const { appliedFilters, selectedFilter } = this.state;

    return (
      <SubscriptionsFilterDialog
        onApplyFilters={(appliedFilters) => {
          this.handleFiltersApply(appliedFilters);
          onClose && onClose();
        }}
        onClose={() => this.setState({ selectedFilter: null }, () => onClose && onClose())}
        appliedFilters={JSON.parse(JSON.stringify(appliedFilters))}
        openWithFilter={selectedFilter}
      />
    );
  };

  getGroupedByDialog = () => {
    const { groupBy } = this.state;

    return (
      <GroupByDialog
        onClose={() => this.setState({ isGroupByDialogActive: null })}
        selected={groupBy}
        onDone={this.onChangeGroupBy}
        withSubType
      />
    );
  };

  onChangeGroupBy = (newGroupedBy) => {
    const columnsConfig = this.getColumnConfig(newGroupedBy);
    const { excludeColumns } = this.state;

    // Disable detailRowColumns on groupedBySubType
    let additionalParams = {};
    if (newGroupedBy.includes(SUBSCRIPTIONS_VIEW_TYPES.GROUPED_BY_SUB_TYPE)) {
      additionalParams = {
        isDetailDialogActive: false,
        selectedDetailRowVariant: -1,
        detailRowsPropertyName: null,
        detailRowColumns: null,
        columnWidths: countWidthOfColumns(columnsConfig.columns, excludeColumns, columnsConfig.columnExtensions),
      };
    }

    this.setState(
      {
        groupBy: newGroupedBy,
        isGroupByDialogActive: false,
        ...additionalParams,
      },
      this.rebuildTable
    );
  };

  getTableRowDetailDialog = () => {
    const { selectedDetailRowVariant, isDetailDialogActive } = this.state;

    return (
      <TableRowDetailDialog
        open={isDetailDialogActive}
        onClose={() => this.setState({ isDetailDialogActive: false })}
        selected={selectedDetailRowVariant}
        onDone={this.onChangeDetailRowState}
        availableVariants={[TABLE_ROW_DETAIL_TYPES.SUBSCRIPTION_PRODUCTS]}
      />
    );
  };

  onChangeDetailRowState = (newDetailRowState) => {
    this.setState({
      isDetailDialogActive: false,
      selectedDetailRowVariant: newDetailRowState?.selectedDetailRowVariant || -1,
      detailRowsPropertyName: newDetailRowState?.detailRowsPropertyName || null,
      detailRowColumns: newDetailRowState?.detailRowColumns || null,
    });
  };

  handleFiltersApply = (appliedFilters) => {
    this.setState({
      settings: {
        excludeColumns: this.state.settings.excludeColumns || [],
        filters: appliedFilters,
      },
      appliedFilters: appliedFilters,
      addingFilter: false,
    });
  };

  onChangeSorting = (sorting) => {
    this.setState({
      sorting,
      settings: {
        ...this.state.settings,
        sorting,
      },
    });
  };

  handleFilterRemove = (removedFilter) => {
    this.setState((state) => {
      let appliedFilters = state.appliedFilters;
      let indexToDelete = appliedFilters.findIndex((appliedFilter) => appliedFilter.name === removedFilter.name);
      appliedFilters.splice(indexToDelete, 1);
      return { appliedFilters, settings: { ...this.state.settings, filters: appliedFilters } };
    });
  };

  getTableGroupByConfig = () => {
    const { groupBy } = this.state;

    if (groupBy.includes(PREPURCHASES_GROUP_BY.LOCATION)) {
      return {
        groupSummaryItems: [{ columnName: 'location', showInGroupFooter: false, type: 'count' }],
        summaryCalculator: summaryCalculator,
        groupRowInlineSummaryItemComponent: this.groupRowInlineSummaryItemComponent,
        groupingColumns: [{ columnName: PREPURCHASES_GROUP_BY.LOCATION }],
        prepareRowsAfterLoad: this.makeAllExpanded,
      };
    }

    if (groupBy.includes(SUBSCRIPTIONS_VIEW_TYPES.GROUPED_BY_SUB_TYPE)) {
      const groupingConfig = this.getColumnConfig(SUBSCRIPTIONS_VIEW_TYPES.GROUPED_BY_SUB_TYPE);

      return {
        groupSummaryItems: [{ columnName: 'productType', showInGroupFooter: false, type: 'count' }],
        summaryCalculator: summaryCalculator,
        groupRowInlineSummaryItemComponent: this.groupRowInlineSummaryItemComponent,
        detailRowsPropertyName: null,
        onExpandClientGroupedRows: this.onExpandClientGroupedRows,
        prepareRowsAfterLoad: prepareRowsAfterLoadGroupedSubType,
        ...groupingConfig,
      };
    }

    return {};
  };

  onExpandClientGroupedRows = (rows, expandedGroups, expandedAllState) => {
    const collapsedAll = expandedAllState !== EXPANDED_ALL_STAGES.NOT_EXPANDED;

    const result = Array.isArray(expandedGroups) ? [...expandedGroups] : [];

    if (expandedAllState === EXPANDED_ALL_STAGES.NOT_EXPANDED) {
      rows.forEach((row) => {
        const productType = row?.productType?.name;
        const productSubType = `${row?.productType?.name}|${row?.productSubType?.name}`;

        if (!result.includes(productType)) {
          result.push(row?.productType?.name);
        }
        if (!result.includes(productSubType)) {
          result.push(productSubType);
        }
      });

      return {
        expandedGroups: result,
        expandedAllState: EXPANDED_ALL_STAGES.EXPAND_FIRST_LEVEL,
        collapsedAll,
      };
    }
    if (expandedAllState === EXPANDED_ALL_STAGES.EXPAND_FIRST_LEVEL) {
      rows.forEach((row) => {
        const productName = `${row?.productType?.name}|${row?.productSubType?.name}|${row?.variant?.productName}`;
        const variantName = `${row?.productType?.name}|${row?.productSubType?.name}|${row?.variant?.productName}|${row?.variant?.variantName}`;

        if (!result.includes(productName)) {
          result.push(productName);
        }
        if (!result.includes(variantName)) {
          result.push(variantName);
        }
      });
      return {
        expandedGroups: result,
        expandedAllState: EXPANDED_ALL_STAGES.EXPAND_ALL_LEVELS,
        collapsedAll,
      };
    }

    return {
      expandedGroups: [],
      expandedAllState: EXPANDED_ALL_STAGES.NOT_EXPANDED,
      collapsedAll,
    };
  };

  groupRowInlineSummaryItemComponent = ({ summary, getMessage }) => {
    const { intl } = this.props;

    if (summary.type === 'count') {
      return intl.formatMessage(
        { id: 'subscriptions.inlineSummaryCell.totalWithPeriods' },
        { total: summary.value.total, weekly: summary.value.weekly, biweekly: summary.value.biweekly }
      );
    }

    return getMessage(summary.messageKey) + summary.value;
  };

  makeAllExpanded = (rows) => {
    const { intl } = this.props;
    let result = [];

    rows.forEach((row) => {
      if (!!row?.market?.name && !result.includes(row?.market?.name)) {
        result.push(row?.market?.name);
      }
    });

    result.push(intl.formatMessage({ id: 'dialog.groupBy.noLocation' }));

    return { expandedRowIds: result, expandedGroups: result, rows: rows };
  };

  makeGroupOptions = () => {
    const { intl } = this.props;
    const { groupBy } = this.state;

    if (!groupBy?.length) {
      return [];
    }

    return groupBy.map((group) => ({
      key: group,
      title: intl.formatMessage({ id: `adminTable.chips.groupBy.${group}` }),
      value: group,
    }));
  };

  additionalIconsRenderer = () => {
    const { classes, intl } = this.props;
    const { anchorEl } = this.state;

    return (
      <>
        <IconButton
          id={'moveDate-button'}
          aria-controls={'moveDate-button'}
          aria-haspopup="true"
          classes={{ root: classes.button, label: classes.label }}
          disableRipple
          onClick={(event) => {
            this.setState({ anchorEl: event.currentTarget });
          }}>
          <MoveDown className={classes.icon} size={24} />
          {intl.formatMessage({ id: 'subscription.updateSubscriptionsDate' })}
        </IconButton>
        <Menu
          id={'moveDate-menu'}
          style={{ top: 50 }}
          anchorEl={anchorEl}
          open={anchorEl ? anchorEl.getAttribute('aria-controls') === 'moveDate-button' : false}
          onClose={() => {
            this.setState({
              anchorEl: null,
            });
          }}>
          <MenuItem
            style={{ paddingLeft: 25, paddingRight: 25, minWidth: 200 }}
            onClick={() => {
              this.setState({
                anchorEl: null,
                updateSubscriptionsDatesDialog: true,
                updateSubscriptionsDatesDialogStep: SELECT_DATES_DIALOG_MOVING_VARIANTS.FOR_VARIANT,
              });
            }}>
            {intl.formatMessage({ id: 'subscription.dateModal.moveForVariant' })}
          </MenuItem>
          <MenuItem
            style={{ paddingLeft: 25, paddingRight: 25, minWidth: 200, borderTop: '1px solid #e6e6e6', paddingTop: 10 }}
            onClick={() => {
              this.setState({
                anchorEl: null,
                updateSubscriptionsDatesDialog: true,
                updateSubscriptionsDatesDialogStep: SELECT_DATES_DIALOG_MOVING_VARIANTS.FOR_SUBSCRIPTION,
              });
            }}>
            {intl.formatMessage({ id: 'subscription.dateModal.moveForSubscriptions' })}
          </MenuItem>
        </Menu>
      </>
    );
  };

  getDatesDialog = () => {
    return (
      <ChangeSubscriptionsDatesDialog
        onClose={() =>
          this.setState({
            updateSubscriptionsDatesDialog: false,
          })
        }
        moveVariant={this.state.updateSubscriptionsDatesDialogStep}
        reloadTable={this.forceTableReload}
      />
    );
  };

  onExportTab = () => {
    const { intl } = this.props;
    const { settings, columns } = this.state;

    jsonToCsv(
      this.table.getRows(),
      `${intl.formatMessage({ id: 'global.subscriptions' })} ${moment().format('ddd DD.MM.YYYY HH:mm')}`,
      true,
      ['id', ...(settings?.excludeColumns ? settings.excludeColumns : [])],
      [...columns]
    );
  };

  render() {
    const { tab, index, intl, classes, history, allowCreateTab, withFilters, onDeleteTab, onCreateTab } = this.props;

    const {
      sorting,
      isFiltersDialogActive,
      tabDialog,
      appliedFilters,
      isGroupByDialogActive,
      groupBy,
      rebuildingTable,
      columnWidths,
      detailRowsPropertyName,
      detailRowColumns,
      selectedDetailRowVariant,
      updateSubscriptionsDatesDialog,
      columns,
      columnExtensions,
    } = this.state;

    return (
      <Tab
        title={tab.name}
        key={`${index}-tab`}
        tab={tab}
        actions={
          <TabActions
            tab={tab}
            classes={classes}
            columns={columns}
            labels={{ createEntity: intl.formatMessage({ id: 'subscription.new' }) }}
            appliedFilters={this.state.appliedFilters}
            withFilters={withFilters}
            allowCreateTab={allowCreateTab}
            onCreateTab={this.onCreateTab}
            onUpdateTab={this.onUpdateTab}
            onAddingFilter={withFilters ? this.onAddFilter : undefined}
            onDeleteTab={onDeleteTab}
            onCreateEntity={() => history.push(`${this.props.match.url}/new`)}
            onChangeColumnsState={this.onChangeColumnsState}
            onGroupBy={this.onGroupBy}
            onDetails={this.onDetails}
            showDetails={Array.isArray(groupBy) && !groupBy?.includes(SUBSCRIPTIONS_VIEW_TYPES.GROUPED_BY_SUB_TYPE)}
            additionalIconsRenderer={this.additionalIconsRenderer}
            onExportTab={this.onExportTab}
          />
        }>
        {!rebuildingTable && (
          <AdminDXTable
            enableRemoteSearchWithFilters={true}
            batchControlsComponent={SubscriptionsScene}
            onRef={(table) => (this.table = table)}
            serverSideFilters={JSON.parse(JSON.stringify(appliedFilters))}
            onRemoveFilter={this.handleFilterRemove}
            onOpenWithFilter={(index, filter) =>
              this.setState({
                isFiltersDialogActive: true,
                selectedFilter: filter,
              })
            }
            pageSize={getGridPageSize()}
            pageSizes={getGridPageSizes()}
            columnExtensions={columnExtensions}
            hiddenColumnNames={this.state.settings.excludeColumns || []}
            actionsHeaderCellStyle={{ textAlign: 'center' }}
            actionsCellStyle={{ textAlign: 'center' }}
            onChangeSorting={this.onChangeSorting}
            sorting={tab.sorting || sorting}
            appliedFilters={tab.filters}
            actions={[
              {
                icon: <EditIcon style={{ color: COLORS.text, fontSize: 18 }} />,
                action: (row) =>
                  history.push(`${this.props.match.url}/${row.subscriptionId}${this.props.location.hash}`),
              },
              {
                icon: <DeleteIcon style={{ color: COLORS.violet, fontSize: 18 }} />,
                action: (row) => this.setState({ deleting: row }),
              },
            ]}
            allowExpandAll={true}
            stickyHeader
            stickyHeaderOffset={HEADER_HEIGHT_STANDARD.extended}
            onColumnWidthsChange={(widths) => {
              this.setState({ columnWidths: widths });
            }}
            minColumnWidth={DEFAULT_MIN_CELL_WIDTH}
            maxColumnWidth={DEFAULT_MAX_CELL_WIDTH}
            columnWidths={this.state.columnWidths}
            groupByOptions={this.makeGroupOptions()}
            onRemoveGroupBy={() => this.onChangeGroupBy('')}
            onOpenWithGroupBy={this.onGroupBy}
            enableSelection
            cellRenderer={CellRenderer}
            apiRetrieve={apiAllSubscriptionsRetrieve}
            columns={columns}
            detailRowColumns={detailRowColumns}
            detailRowsPropertyName={detailRowsPropertyName}
            onRemoveDetailRowsProperty={() => this.onChangeDetailRowState(null)}
            onOpenWithDetailRowsProperty={this.onDetails}
            allowCollapseAll
            {...this.getTableGroupByConfig()}
          />
        )}

        {this.state.deleting && (
          <ConfirmDialog
            onClose={() => this.setState({ deleting: null })}
            loading={this.state.deletingLoading}
            confirmTitle={'confirm.deleteSubscription'}
            title={intl.formatMessage({ id: 'titles.deleteSubscription' })}
            message={intl.formatMessage({ id: 'messages.confirmDeleteSubscription' })}
            onConfirm={this.onDelete}
          />
        )}

        {isFiltersDialogActive && this.getFiltersDialog(() => this.setState({ isFiltersDialogActive: false }))}
        {isGroupByDialogActive && this.getGroupedByDialog()}
        {this.getTableRowDetailDialog()}
        {updateSubscriptionsDatesDialog && this.getDatesDialog()}

        {tabDialog && (
          <SaveTabSettingDialog
            onClose={() => this.setState({ tabDialog: false })}
            filters={appliedFilters.slice()}
            excludeColumns={this.state.settings.excludeColumns || []}
            groupBy={groupBy}
            detailColumns={selectedDetailRowVariant}
            columnWidths={columnWidths}
            sorting={this.state.sorting}
            onSave={(data) => onCreateTab(data, () => this.setState({ tabDialog: false }))}
            type={TabTypes.SUBSCRIPTIONS}
          />
        )}
      </Tab>
    );
  }
}

SubscriptionsContent.propTypes = propTypes;
SubscriptionsContent.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  return {
    producer: state.producer.object,
  };
};

export default injectIntl(withRouter(connect(mapStateToProps)(withStyles(styles)(SubscriptionsContent))));
