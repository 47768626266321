import { useState } from 'react'

function useCopyToClipboard() {
  const [errorText, setErrorText] = useState(null)
  const [copiedText, setCopiedText] = useState(null)

  const copy = async text => {
    if (!navigator?.clipboard) {
      setErrorText('Clipboard not supported')
      return false
    }

    try {
      await navigator.clipboard.writeText(text)
      setCopiedText(text)
      return true
    } catch (error) {
      setErrorText('Copy failed')
      setCopiedText(null)
      return false
    }
  }

  return [copiedText, copy, errorText]
}

export default useCopyToClipboard