import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { withStyles } from '@material-ui/core/styles'
import {
  apiGetCollectionTags,
  apiCreateCollection,
  apiPatchCollection,
} from '../../api'
import {
  PrimaryTextField,
  PrimaryButton,
  ImageUpload,
} from '../../components'
import {
  capitalizeFirstLetter,
  validateField,
} from '../../helpers';
import validationRules from './validation'
import Spinner from "../../components/Spinner/Spinner";
import MenuItem from "@material-ui/core/MenuItem";
import PrimarySelect from "../../components/PrimarySelect/PrimarySelect";

const styles = ({
  textField: {
    margin: '10px',
    width: '100%'
  },
  chip: {
    margin: 5,
  },
  productsTableWrap: {
    width: '100%',
    margin: 10,
  },
  actionsWrap: {
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
    paddingTop: 30,
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: 440,
  },
  tint: {
    color: '#A3A6B4',
    fontSize: 11,
  },
  image: {
    display: 'inline-block',
    width: 300,
    maxHeight: 400
  },
  imageWrapper: {
    marginTop: 10,
    width: '100%',
    textAlign: 'center'
  }
});

class CollectionForm extends Component {

  constructor (props) {
    super(props);
    this.state = {
      name: '',
      tag: '',
      tags: [],
      file: null,
      loading: true,
      collection: null,
      errors: {},
    }
  }

  componentDidMount () {
    const { match: { params: { id } }, collections } = this.props

    apiGetCollectionTags((tags) => {
      if (id && id !== 'new') {
        for (let i = 0; i < collections.length; i++) {
          if (id === collections[i].id) {
            this.setState({
              loading: false,
              tags,
              tag: collections[i].id,
              name: collections[i].name,
              collection: collections[i]
            });

            break;
          }
        }
      } else {
        this.setState({
          loading: false,
          tag: tags.length ? tags[0].id : "",
          tags
        })
      }

    }, (error) => console.warn(error))

  }


  _handleChange = (key, value) => {
    this.setState({ [key]: value, errors: { ...this.state.errors, [key]: null } })
  }

  _validate = () => {
    const {
      name,
      tag,
    } = this.state
    let errors = {}
    errors.name = validateField(validationRules, 'name', name)
    errors.tag = validateField(validationRules, 'tag', tag)

    this.setState({ errors })
    return Object.values(errors).every(item => item === null)
  }

  _handleSubmit = e => {
    e.preventDefault()

    const {
      name,
      tag
    } = this.state

    const valid = this._validate()

    if (!valid) return

    this.setState({loading: true});
    const data = {
      name: name,
      tagId: tag
    };

    if (this.state.file) {
      data.image = this.state.file.split(',').pop();
    }

    const { match: { params: { id } } } = this.props
    if (id && id !== 'new') {
      apiPatchCollection(id, data, response => {
        this.setState({loading: false});
        this.props.onSuccess(response)
      }, error => {
        console.log(error)
      })
    } else {
      apiCreateCollection(data, response => {
        this.setState({loading: false});
        this.props.onSuccess(response)
      }, error => {
        console.log(error)
      })
    }
  }

  render () {
    const { intl, classes } = this.props
    const { errors, collection } = this.state
    PrimaryTextField.defaultProps = {className: classes.textField};

    return (
      <form onSubmit={this._handleSubmit} className={classes.form}>
        {this.state.loading && <Spinner size={40}/>}

        <PrimaryTextField
          value={this.state.name}
          fullWidth
          label={intl.formatMessage({ id: 'collections.name' })}
          onChange={event => this._handleChange('name', event.target.value)}
          helperText={errors.name && intl.formatMessage({ id: errors.name })}
          error={!!errors.name}
        />

        <PrimarySelect
          value={this.state.tag}
          fullWidth
          formControlClassName={classes.textField}
          onChange={event => this._handleChange('tag', event.target.value)}
          label={intl.formatMessage({ id: 'collections.tag' })}
          helperText={errors.tag && intl.formatMessage({ id: errors.tag })}
          error={!!errors.tag}
        >
          {this.state.tags.map(tag => (
            <MenuItem key={tag.id} value={tag.id}>{capitalizeFirstLetter(tag.name)}</MenuItem>
          ))}
        </PrimarySelect>

        <div className={classes.imageWrapper}>
          <div className={classes.image}>
            <ImageUpload object={collection}
                         base64={this.state.file}
                         onUploaded={(file) => this.setState({file: file})}/>
          </div>
        </div>

        <div className={classes.actionsWrap}>
          <PrimaryButton type='submit'>
            {intl.formatMessage({ id: 'global.save'})}
          </PrimaryButton>
        </div>
      </form>
    )
  }
}

export default withStyles(styles)(injectIntl(CollectionForm))
