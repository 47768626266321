import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { sumOfArrayOfObjectsValue } from '../../../../helpers';

export const getTreeChildRows = (intl, row, rootRows) => {
  if (row && row.rowType === 'productSubType') {
    return row.currentProducts;
  }

  if (row && row.rowType === 'lineItemNameProduct') {
    return row.variants;
  }

  if (row && row.rowType === 'lineItemNameVariant') {
    return row.items;
  }

  if (row && row.rowType === 'end') {
    return null;
  }

  return rootRows;
};

export const prepareRowsAfterLoad = (rows) => {
  let expanded = [];

  let _rows = [];
  const sortedRows = rows.sort((rowPre, rowNext) => (rowPre.productSubType > rowNext.productSubType ? 1 : -1));

  let currentSubType = { productSubType: '' };

  sortedRows.forEach((row) => {
    if (row.productSubType !== currentSubType.productSubType) {
      let byProducts = [];
      let allVariantsAllIds = [];
      currentSubType.productSubType = row.productSubType;
      currentSubType.rowType = 'productSubType';
      currentSubType.id = `key_${row.productSubType}`;

      const products = rows
        .filter((r) => r.productSubType === currentSubType.productSubType)
        .sort((rowPre, rowNext) => (rowPre.lineItemName.product > rowNext.lineItemName.product ? 1 : -1));

      let currentProductName = null;

      products.forEach((pro) => {
        if (pro.lineItemName.product !== currentProductName) {
          currentProductName = pro.lineItemName.product;

          let variantsByProducts = products
            .filter((p) => p.lineItemName.product === pro.lineItemName.product)
            .sort((variantPre, variantNext) =>
              variantPre.lineItemName.variant > variantNext.lineItemName.variant ? 1 : -1
            );

          let currVariants = [];
          let currVariant = null;
          variantsByProducts.forEach((variant) => {
            if (variant.lineItemName.variant !== currVariant) {
              currVariant = variant.lineItemName.variant;
              let variants = variantsByProducts.filter((v) => v.lineItemName.variant === variant.lineItemName.variant);

              allVariantsAllIds.push(
                `key_${row.productSubType}_${variant.lineItemName.product}_${variant.lineItemName.variant}`
              );

              currVariants.push({
                nameVariant: variant.lineItemName.variant,
                rowType: 'lineItemNameVariant',
                lineItemNameVariantTotalOrdered: sumOfArrayOfObjectsValue(variants, 'ordered'),
                lineItemNameVariantTotalPacked: sumOfArrayOfObjectsValue(variants, 'packed'),
                lineItemNameVariantTotalPrice: sumOfArrayOfObjectsValue(variants, 'price'),
                id: `key_${row.productSubType}_${variant.lineItemName.product}_${variant.lineItemName.variant}`,
                items: variants.map((i, index) => ({
                  ...i,
                  rowType: 'end',
                  id: `key_${row.productSubType}_${variant.lineItemName.product}_${variant.lineItemName.variant}_${index}`,
                })),
              });
            }
          });

          byProducts.push({
            nameProduct: pro.lineItemName.product,
            rowType: 'lineItemNameProduct',
            id: `key_${row.productSubType}_${pro.lineItemName.product}`,
            lineItemNameProductTotalOrdered: sumOfArrayOfObjectsValue(currVariants, 'lineItemNameVariantTotalOrdered'),
            lineItemNameProductTotalPacked: sumOfArrayOfObjectsValue(currVariants, 'lineItemNameVariantTotalPacked'),
            lineItemNameProductTotalPrice: sumOfArrayOfObjectsValue(currVariants, 'lineItemNameVariantTotalPrice'),
            variants: currVariants,
          });
        }
      });

      currentSubType.currentProducts = byProducts.reverse();

      _rows.push({
        ...currentSubType,
        productSubTypeTotalOrdered: sumOfArrayOfObjectsValue(
          currentSubType.currentProducts,
          'lineItemNameProductTotalOrdered'
        ),
        productSubTypeTotalPacked: sumOfArrayOfObjectsValue(
          currentSubType.currentProducts,
          'lineItemNameProductTotalPacked'
        ),
        productSubTypeTotalPrice: sumOfArrayOfObjectsValue(
          currentSubType.currentProducts,
          'lineItemNameProductTotalPrice'
        ),
        expandSecondLevel: currentSubType.currentProducts.map((product) => product.id),
        expandAllLevels: allVariantsAllIds,
      });
    }
  });

  return {
    rows: _rows,
    expandedGroups: expanded,
  };
};

export const getExpandedRowIds = () => {
  return [];
};

export const tableRowRenderer = (onClick, { row, ...props }) => {
  const getColor = (row) => {
    switch (row.rowType) {
      case 'productSubType': {
        return '#ffffff';
      }
      case 'lineItemNameProduct': {
        return 'rgba(224, 224, 224, 0.4)';
      }
      case 'lineItemNameVariant': {
        return 'rgba(224, 224, 224, 0.8)';
      }

      default: {
        return '#ffffff';
      }
    }
  };

  return <Table.Row {...props} onClick={() => onClick && onClick(row)} style={{ backgroundColor: getColor(row) }} />;
};
