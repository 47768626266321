import axios from 'axios';
import {
  getRequestConfig,
  REQUEST_TYPES,
  replaceUrlPlaceholder,
} from '../request'
import API_END_POINTS from  '../endpoints'

/**
 * Get all payouts
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerPayoutsGetList = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.PRODUCER_PAYOUTS_GET_ALL);
  config.params = data;
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Fetch single payout by id
 * @param {string} id - payout id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerPayoutsGetOne = function (id, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRODUCER_PAYOUTS_GET_ONE, 'id', id);
  const config = getRequestConfig(REQUEST_TYPES.GET, url);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};


