import ScheduleVariant from './ScheduleVariant';

class ScheduleDates {
  constructor(props) {
    this.dates = props.dates || [];
    this.nextDate = props.nextDate || null;
    this.variants = props?.variants || [];
  }

  getDates() {
    return this.dates;
  }

  getDatesWithoutStatuses() {
    return this.dates.map((date) => date.date);
  }

  getNextDate() {
    return this.nextDate;
  }

  getVariants() {
    return this.variants;
  }

  getVariantById(variantId) {
    return (
      new ScheduleVariant(this.variants.find((variant) => variant.variantId === variantId)) || new ScheduleVariant({})
    );
  }
}

export default ScheduleDates;
