import React from 'react';
import { COLORS } from '../constants/colors';

const MinusTen = (props) => {
  const { color = COLORS.blackTintedBlue, ...other } = props;
  return (
    <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <path d="M14.5001 11.5H0.500122V9.5H14.5001V11.5Z" fill={color} />
      <path
        d="M10.2233 7.5V2.4032L9.16529 2.6516V1.75L10.7201 1.06H11.4469V7.5H10.2233ZM15.336 7.6104C14.741 7.6104 14.232 7.4724 13.8088 7.1964C13.3856 6.91427 13.0574 6.5248 12.8244 6.028C12.5974 5.52507 12.484 4.9424 12.484 4.28C12.484 3.62373 12.5974 3.04413 12.8244 2.5412C13.0574 2.03827 13.3856 1.6488 13.8088 1.3728C14.232 1.09067 14.741 0.9496 15.336 0.9496C15.9309 0.9496 16.44 1.09067 16.8632 1.3728C17.2864 1.6488 17.6114 2.03827 17.8384 2.5412C18.0714 3.04413 18.188 3.62373 18.188 4.28C18.188 4.9424 18.0714 5.52507 17.8384 6.028C17.6114 6.5248 17.2864 6.91427 16.8632 7.1964C16.44 7.4724 15.9309 7.6104 15.336 7.6104ZM15.336 6.5524C15.8205 6.5524 16.2161 6.35 16.5228 5.9452C16.8294 5.5404 16.9828 4.98533 16.9828 4.28C16.9828 3.57467 16.8294 3.0196 16.5228 2.6148C16.2161 2.21 15.8205 2.0076 15.336 2.0076C14.8453 2.0076 14.4466 2.21 14.14 2.6148C13.8394 3.0196 13.6892 3.57467 13.6892 4.28C13.6892 4.98533 13.8394 5.5404 14.14 5.9452C14.4466 6.35 14.8453 6.5524 15.336 6.5524Z"
        fill={color}
      />
    </svg>
  );
};

export default MinusTen;
