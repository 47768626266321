export default {
  title: {
    textAlign: 'left',
    fontSize: 14,
  },
  wrapper: {
    marginTop: 10,
    paddingTop: 10,
    paddingBottom: 10,
    borderTop: '1px solid #41CD8C',
    borderBottom: '1px solid #41CD8C'
  }
};