const styles = () => ({
  dialogPaper: {},
  controls: {
    margin: '27px 0 0 0',
    textAlign: 'center',
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  control: {
    padding: '0 105px',
  },
  selectFormControl: {
    width: '100%',
    marginBottom: 20,
  },
  chips: {
    margin: '20px 0 0 0',
    textAlign: 'left',
  },
  chip: {
    margin: 5,
  },
  mr20: {
    marginRight: 20,
  },
  dateSelects: {
    marginBottom: 20,
  },
});

export default styles;
