export default {
  requestError: {
    backgroundColor: 'red',
    color: '#ffffff',
    marginBottom: 20,
    marginLeft: 20,
    marginRight: 20
  },
  controls: {
    marginTop: 30,
    display: 'flex',
  },
  button: {
    width: '100%',
    height: 43,
    textTransform: 'none'
  },
  controlsCentered: {
    textAlign: 'center',
    margin: "0 0 30px",
  },
  finalMessageContainer: {
    textAlign: 'center'
  },
  envelope: {
    width: 60,
    height: 60,
  },
  bmr: {
    marginRight: 20,
  },
  topInputs: {
    marginTop: 30,
    marginBottom: 50,
  },
  bottomInputs: {
    marginBottom: 50,
  },

  termsOfServiceAndPrivacyPolicyLink: {
    fontSize: 10,
    color: "#707070",
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  termsOfServiceAndPrivacyPolicy: {
    fontSize: 10,
  },
  mt40: {
    marginTop: 40
  },

};