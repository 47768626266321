import React, {Component} from 'react'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import {withStyles} from '@material-ui/core/styles';

const styles = ({
  container: {
    padding: 24 + 7,
  },
  closeBtn: {
    position: 'absolute',
    right: 15,
    top: 12,
    zIndex: 100,
  },
  dialogTitle: {
    textAlign: 'center',
    padding: '30px 0 40px 0',
    fontWeight: '300',
    color: '#363636',
  }
});

class BaseDialog extends Component {
  
  render () {
    const {
      classes,
      containerStyle,
      title,
      onClose,
      ...restProps
    } = this.props;

    return (
      <Dialog open={this.props.open} onClose={onClose} {...restProps}>
        <IconButton className={classes.closeBtn} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <div className={classes.container} style={containerStyle}>
          <Typography component="h5" variant="h5" className={classes.dialogTitle}>
            {title}
          </Typography>
          {
            this.props.children
          }
        </div>
      </Dialog>
    )
  }
}

export default withStyles(styles, {withTheme: true})(BaseDialog)
