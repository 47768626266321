import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import MinusTen from '../../assets/minusTen';
import Minus from '../../assets/minus';
import Plus from '../../assets/plus';
import PlusTen from '../../assets/plusTen';
import { TextField } from '@material-ui/core';
import { COLORS } from '../../constants/colors';
import classNames from 'classnames';
import { FONT_LABEL, FONT_QUANTITY } from '../../constants/fonts';
import { getValueForInventoryQuantity } from '../../helpers/convertors';

const propTypes = {
  quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  noLabel: PropTypes.bool,
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '& *': {
      userSelect: 'none',
    },
  },
  labelWrapper: {
    width: '100%',
    marginBottom: 20,
  },
  label: {
    ...FONT_LABEL,
    textAlign: 'left',
    userSelect: 'none',
  },

  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  controlButton: {
    width: 44,
    height: 44,
    backgroundColor: COLORS.greyLight,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 5,
    marginLeft: 5,
    boxSizing: 'border-box',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: COLORS.lightBg,
    },
    '&:active': {
      backgroundColor: COLORS.hover,
    },
  },
  inputWrapper: {
    marginRight: 5,
    marginLeft: 5,
    width: 60,
    textAlign: 'center',
    backgroundColor: COLORS.white,
    height: 44,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textFieldRoot: {},
  inputRoot: {
    width: 60,
    textAlign: 'center',
    borderBottom: 0,
    ...FONT_QUANTITY,
    fontSize: 24,

    '&:after, &:before': {
      display: 'none',
      border: 0,
    },
  },
  input: {
    textAlign: 'center',
    borderBottom: 0,
    '&:-webkit-outer-spin-button, &:-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
    '-moz-appearance': 'none',
  },
  coloredLabel: {
    fontWeight: '700',
    color: COLORS.brightBlue,
    justifySelf: 'center',
    textAlign: 'center',
  },
}));

const QuantitySwitcher = (props) => {
  const { quantity: quantityProps, onChange, label, noLabel, coloredLabel } = props;
  const [quantity, setQuantity] = useState(quantityProps);

  useEffect(() => {
    setQuantity(quantityProps);
  }, [quantityProps]);

  const classes = useStyles();
  const intl = useIntl();

  const defaultLabel = intl.formatMessage({ id: 'supplier.variant.quantityAvailable' });

  const drawControlButtonWithIcon = (icon, action) => {
    const Icon = icon;
    return (
      <div className={classes.controlButton} onClick={action}>
        <Icon />
      </div>
    );
  };

  const changeQuantity = (amount) => {
    const newQuantity = Number.parseFloat(quantity) + amount;

    onChange(newQuantity >= 0 ? newQuantity : 0);
  };

  const onFinishEditing = () => {
    let newValue = quantity;
    if (quantity < 0 || !quantity) newValue = 0;
    if (isNaN(quantity)) newValue = quantityProps;
    if (quantityProps === newValue) {
      setQuantity(quantityProps);
      return;
    }

    onChange(quantity);
  };

  return (
    <div className={classes.root}>
      {!noLabel && (
        <div className={classes.labelWrapper}>
          <p className={classNames([classes.label, coloredLabel && classes.coloredLabel])}>{label || defaultLabel}</p>
        </div>
      )}

      <div className={classes.buttonsWrapper}>
        {drawControlButtonWithIcon(MinusTen, () => changeQuantity(-10))}
        {drawControlButtonWithIcon(Minus, () => changeQuantity(-1))}
        <div className={classes.inputWrapper}>
          <TextField
            classes={{
              root: classes.textFieldRoot,
            }}
            className={classes.input}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              classes: { root: classes.inputRoot, input: classNames([classes.input, 'hideArrow']) },
              inputProps: { min: 0, step: 1 },
            }}
            value={quantity}
            onBlur={onFinishEditing}
            onFocus={() => {
              quantity <= 0 && setQuantity('');
            }}
            onChange={(event) => {
              setQuantity(getValueForInventoryQuantity(event));
            }}
          />
        </div>
        {drawControlButtonWithIcon(Plus, () => changeQuantity(1))}
        {drawControlButtonWithIcon(PlusTen, () => changeQuantity(10))}
      </div>
    </div>
  );
};

QuantitySwitcher.propTypes = propTypes;

export default QuantitySwitcher;
