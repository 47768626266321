import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  shippingMethod: {
    fontSize: 12,
  },
  deliveryCompany: {
    fontSize: 12,
    fontWeight: 600,
  },
};

const propTypes = {
  row: PropTypes.object.isRequired,
};

const ShippingCell = ({ intl, row, classes, ...other }) => {
  if (other.column.name === 'shipping.type') {
    return (
      <React.Fragment>
        <Table.Cell {...other}>
          {row.shipping && row.shipping.type && (
            <span className={classes.shippingMethod}>
              {row.shipping.type.charAt(0).toUpperCase() + row.shipping.type.slice(1) + ' '}
            </span>
          )}
        </Table.Cell>
      </React.Fragment>
    );
  }

  if (other.column.name === 'shipping.deliveryCompany.name') {
    return (
      <React.Fragment>
        <Table.Cell {...other}>
          {row.shipping && row.shipping.deliveryCompany && row.shipping.deliveryCompany.name && (
            <span className={classes.deliveryCompany}>{row.shipping.deliveryCompany.name}</span>
          )}
        </Table.Cell>
      </React.Fragment>
    );
  }

  return <Table.Cell {...other} />;
};

ShippingCell.propTypes = propTypes;

export default withStyles(styles)(injectIntl(ShippingCell));
