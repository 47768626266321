import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import {COLORS} from '../../helpers'

const styles = ({
  colorSwitchBase: {
    color: '#707070',
    transition: 'all 0.5s ease-in-out',
    '&$colorChecked, &:hover': {
      color: '#707070',
      '& + $colorBar, &:hover + $colorBar': {
        backgroundColor: 'rgb(65, 205, 140)',
      },
    },
  },
  colorBar: {
    transition: 'all 0.5s ease-in-out',
    backgroundColor: COLORS.actionLabel,
  },
  colorChecked: {},
});

const propTypes = {
  classes: PropTypes.object.isRequired,
};

const PrimarySwitch = (props) => {
  const {
    classes,
    ...other
  } = props;

  const custom = {
    switchBase: classes.colorSwitchBase,
    checked: classes.colorChecked,
    track: classes.colorBar,
  };

  return (
    <Switch
      {...other}
      classes={custom}
    />
  );
};

PrimarySwitch.propTypes = propTypes;

export default withStyles(styles)(PrimarySwitch);
