import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { AdminDialog, AdminDialogTitle, AdminDialogContent, PrimaryButton } from '../../components';
import {
  DXFiltersManager,
  DXSubscriptionReportsDatesFilter,
  DXSubscriptionReportFilter,
} from '../../components/AdminDXTable/Filters';
import styles from './styles';

const propTypes = {
  openWithFilter: PropTypes.object,
  appliedFilters: PropTypes.array,
  onApplyFilters: PropTypes.func,
  onClose: PropTypes.func,
};

const defaultProps = {
  appliedFilters: [],
};

class SubscriptionsReportsFilterDialog extends React.Component {
  constructor(props) {
    super(props);

    this.filters = [DXSubscriptionReportsDatesFilter, DXSubscriptionReportFilter];
  }

  render() {
    const { intl, classes, onClose, onApplyFilters, openWithFilter, appliedFilters } = this.props;

    return (
      <AdminDialog open closeButton onClose={onClose}>
        <AdminDialogTitle title={intl.formatMessage({ id: 'global.addFilter' })} />
        <AdminDialogContent className={classes.wrapper}>
          <DXFiltersManager
            filters={this.filters}
            onRef={(filters) => (this.filtersManager = filters)}
            openWithFilter={openWithFilter}
            appliedFilters={appliedFilters}
            defaultFilterName={'subscriptionReportDatesFilter'}
            readOnly={false}
            queryParamsByFilterName={{ subscriptionReportDatesFilter: { readOnly: true } }}
          />

          <div className={classes.controls}>
            <PrimaryButton onClick={() => onApplyFilters(this.filtersManager.getAppliedFilters())}>
              {intl.formatMessage({ id: 'global.apply' })}
            </PrimaryButton>
          </div>
        </AdminDialogContent>
      </AdminDialog>
    );
  }
}

SubscriptionsReportsFilterDialog.propTypes = propTypes;
SubscriptionsReportsFilterDialog.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(injectIntl(SubscriptionsReportsFilterDialog));
