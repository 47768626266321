import React from 'react'
import PropTypes from 'prop-types'
import { Table } from '@devexpress/dx-react-grid-material-ui'
import MenuItem from '@material-ui/core/MenuItem'
import { injectIntl } from 'react-intl'
import { withStyles } from '@material-ui/core/styles'
import { PrimarySelect } from '../../../components'
import { producerGetInvoicePdfUrl } from '../../../api'

const styles = ({
  cell: {
    textTransform: 'capitalize'
  },
  item: {
  }
});

const propTypes = {
  onPayInvoice: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
};

const DEFAULT = -1;
const VIEW_INVOICE = 1;
const PAY_INVOICE = 2;


const Cell = ({ intl,
                row,
                classes,
                onPayInvoice,
                ...other }) =>  {

  const onChange = (index) => {
    switch (index) {
      case VIEW_INVOICE : {
        // Open invoice page
        window.open(producerGetInvoicePdfUrl(row.id), "_blank");
        break;
      }
      case PAY_INVOICE : {
        // Pay invoice
        onPayInvoice(row);
        break;
      }
      default: {}
    }
  };

  return (
    <Table.Cell {...other}>
      <PrimarySelect
        displayEmpty
        value={-1}
        onChange={(e) => onChange(e.target.value)}
      >
        <MenuItem className={classes.item} key={DEFAULT} value={DEFAULT}>
          {intl.formatMessage({id: "global.more"})}
        </MenuItem>
        <MenuItem className={classes.item} key={VIEW_INVOICE} value={VIEW_INVOICE}>
          {intl.formatMessage({id: "global.viewInvoice"})}
        </MenuItem>
        {!row.paid && (
          <MenuItem className={classes.item} key={PAY_INVOICE} value={PAY_INVOICE}>
            {intl.formatMessage({id: "global.payInvoice"})}
          </MenuItem>
        )}
      </PrimarySelect>
    </Table.Cell>
  );
};

Cell.propTypes = propTypes;

export default withStyles(styles)(
  injectIntl(Cell)
);
