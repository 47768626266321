import React from 'react';
import { COLORS } from '../constants/colors';

const PlusTen = (props) => {
  const { color = COLORS.blackTintedBlue, ...other } = props;
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <path
        d="M13.4999 11.5H8.49994V16.5C8.49994 17.05 8.04994 17.5 7.49994 17.5C6.94994 17.5 6.49994 17.05 6.49994 16.5V11.5H1.49994C0.949939 11.5 0.499939 11.05 0.499939 10.5C0.499939 9.95 0.949939 9.5 1.49994 9.5H6.49994V4.5C6.49994 3.95 6.94994 3.5 7.49994 3.5C8.04994 3.5 8.49994 3.95 8.49994 4.5V9.5H13.4999C14.0499 9.5 14.4999 9.95 14.4999 10.5C14.4999 11.05 14.0499 11.5 13.4999 11.5Z"
        fill={color}
      />
      <path
        d="M10.9732 7.5V2.4032L9.91518 2.6516V1.75L11.47 1.06H12.1968V7.5H10.9732ZM16.0859 7.6104C15.4909 7.6104 14.9819 7.4724 14.5587 7.1964C14.1355 6.91427 13.8073 6.5248 13.5743 6.028C13.3473 5.52507 13.2339 4.9424 13.2339 4.28C13.2339 3.62373 13.3473 3.04413 13.5743 2.5412C13.8073 2.03827 14.1355 1.6488 14.5587 1.3728C14.9819 1.09067 15.4909 0.9496 16.0859 0.9496C16.6808 0.9496 17.1899 1.09067 17.6131 1.3728C18.0363 1.6488 18.3613 2.03827 18.5883 2.5412C18.8213 3.04413 18.9379 3.62373 18.9379 4.28C18.9379 4.9424 18.8213 5.52507 18.5883 6.028C18.3613 6.5248 18.0363 6.91427 17.6131 7.1964C17.1899 7.4724 16.6808 7.6104 16.0859 7.6104ZM16.0859 6.5524C16.5704 6.5524 16.966 6.35 17.2727 5.9452C17.5793 5.5404 17.7327 4.98533 17.7327 4.28C17.7327 3.57467 17.5793 3.0196 17.2727 2.6148C16.966 2.21 16.5704 2.0076 16.0859 2.0076C15.5952 2.0076 15.1965 2.21 14.8899 2.6148C14.5893 3.0196 14.4391 3.57467 14.4391 4.28C14.4391 4.98533 14.5893 5.5404 14.8899 5.9452C15.1965 6.35 15.5952 6.5524 16.0859 6.5524Z"
        fill={color}
      />
    </svg>
  );
};

export default PlusTen;
