import {withStyles} from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import {COLORS} from '../../helpers';

export default withStyles(({
  head: {
    color: COLORS.text,
    padding: 0,
    fontWeight: 600,
    '&:last-child' : {
      textAlign: 'right',
      padding: 0
    }
  },
  body: {
    fontSize: 14,
    padding: 0,
    '&:last-child' : {
      textAlign: 'right',
      padding: 0
    }
  },
}))(TableCell);