import {COLORS} from "../../helpers";

export default {
  badge: {
    borderRadius: 10,
    padding: '2px 10px'
  },
  danger: {
    backgroundColor: '#f50057',
    color: '#ffffff'
  },
  success: {
    backgroundColor: COLORS.text,
    color: '#ffffff'
  },
  default: {
    backgroundColor: '#212121',
    color: '#ffffff'
  }
};