import React from 'react';
import moment from 'moment';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { apiProducerPayoutsGetList } from '../../../api';
import {
  capitalizeFirstLetter,
  COLORS
} from '../../../helpers';

const prepareRowsAfterLoad = (rows) => {
  rows.forEach((row, index) => {
    row.id = row.id ? row.id : 'key_' + index;
  });
  return {
    rows: rows,
  };
};

export default (intl, history) => {
  return (
    {
      name: intl.formatMessage({id: 'producer.tab.payouts'}),
      def: {
        apiRetrieve: apiProducerPayoutsGetList,
        allowExport: true,
        columns: [
          {
            name: 'statementDescriptor',
            title: intl.formatMessage({id: 'global.description'}),
            getCellValue: row => row.statementDescriptor || "-"
          },
          {
            name: 'status',
            title: intl.formatMessage({id: 'global.status'}),
            getCellValue: row => capitalizeFirstLetter(row.status) || "-"
          },
          {
            name: 'initiatedAt',
            title: intl.formatMessage({id: 'global.initiated'}),
            getCellValue: (row) => moment(row.initiatedAt).format('MMM D')
          },
          {
            name: 'availableOn',
            title: intl.formatMessage({id: 'global.estimatedArrival'}),
            getCellValue: (row) => moment(row.availableOn).format('MMM D')
          },
          {
            name: 'ordersCount',
            title: intl.formatMessage({id: 'global.orders'})
          },
          {
            name: 'amount',
            originalName: 'total',
            title: intl.formatMessage({id: 'global.amount'}),
            getCellValue: row => row.total
          },
        ],
        quantityColumnNames: ['amount'],
        quantityWithSeparatorColumnNames: ['amount'],
        sorting: [
          { columnName: 'initiatedAt', direction: 'desc' },
        ],
        totalSummaryItems : [
          { columnName: 'amount', type: 'sum' },
        ],
        prepareRowsAfterLoad: prepareRowsAfterLoad,
        actions: [
          {
            icon: <ArrowForwardIosIcon style={{color: COLORS.text, fontSize: 20}} />,
            action: row => history.push(`/settings/payouts/${row.id}`),
          },
        ]
      }
    }
  )
};