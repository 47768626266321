import React from "react";
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import withWidth, {isWidthDown} from '@material-ui/core/withWidth';
import {withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import BackIcon from "@material-ui/icons/ArrowBack";
import {
  SimpleDialogTitle,
  Icon,
  Nbsp
} from '../../components';

const styles = theme => ({
  paper: {
    borderRadius: 16,
    maxHeight: 'calc(100% - 16px)',
    [theme.breakpoints.down('md')]: {
      margin: 10,
      minWidth: 344,
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 500,
    },
    boxShadow: '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',
},

  dialogTitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    borderBottom: '1px solid #ccc',
    padding: 20,
  },
  back: {
    cursor: 'pointer',
    fill: 'rgba(0, 0, 0, 0.5)'
  },
  close: {
    cursor: 'pointer'
  },

});

class SimpleDialog extends React.Component {
  render() {
    const {
      content,
      actions,
      title,
      width,
      onClose,
      closeButton,
      classes,
      withBack,
      onBackClick,
      backdropInvisible,
      mobileFullScreen,
      paperClass,
      step,
      ...other
    } = this.props;

    return (
      <Dialog onClose={onClose}
              BackdropProps={{
                invisible: backdropInvisible
              }}
              classes={{paper: paperClass ? paperClass : classes.paper}}
              fullScreen={mobileFullScreen ? isWidthDown('sm', width) : false}
              {...other} >

        <div className={classes.dialogTitleWrapper}>

          {withBack ? (
            <Icon icon={BackIcon}
                  style={{fill: 'rgba(0, 0, 0, 0.5)'}}
                  className={classes.back}
                  onClick={() => onBackClick && onBackClick()} />
          ) : <Nbsp />}

        {title ? <SimpleDialogTitle title={title}/> : `&nbsp;`}

        {closeButton ? (
          <Icon icon={CloseIcon}
                style={{fill: 'rgba(0, 0, 0, 0.5)'}}
                className={classes.close}
                onClick={onClose} />
        ) : <Nbsp />}
        </div>
        {content()}
      </Dialog>
    );
  }
}

SimpleDialog.propTypes = {
  mobileFullScreen: PropTypes.bool,
  backdropInvisible: PropTypes.bool,
  closeButton: PropTypes.bool,
  step: PropTypes.number,
  paperClass: PropTypes.string,
  withBack: PropTypes.bool,
  onBackClick: PropTypes.func,
  content: PropTypes.func.isRequired,
  actions: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

SimpleDialog.defaultProps = {
  title: "",
  withBack: false,
  backdropInvisible: false,
  mobileFullScreen: false,
  closeButton: false,
  onClose: () => {},
  actions: () => {},
  content: () => {},
};

export default withStyles(styles, {withTheme: true})(
  withWidth()(SimpleDialog)
);