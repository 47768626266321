import React from 'react';
import { makeStyles } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
  root: {},
  singleItemWrapper: {
    border: '1px solid #dddddd',
    display: 'flex',
    margin: 15,
    borderRadius: 12,
    flexDirection: 'column',
    overflow: 'hidden',
  },
  initialItemWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  itemTitle: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 14,
    fontWeight: 600,
    userSelect: 'none',
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
  },
  numberOfItems: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 'normal',
    color: 'rgba(0, 0, 0, 0.4)',
    marginLeft: 20,
  },
  tableWrapper: {},
  table: { paddingLeft: 10, paddingRight: 10 },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid rgb(224, 224, 224)',
    '&:last-of-type': { borderBottom: 0 },
  },
  column: { padding: 10, boxSizing: 'border-box', flex: 1 },
  columnCell0: {
    flexBasis: '30%',
  },
  columnCell1: {
    flexBasis: '12%',
  },
  columnCell2: {
    flexBasis: '15%',
    textAlign: 'center',
  },
  columnCell3: {
    flexBasis: '23%',
  },
  columnCell4: {
    flexBasis: '20%',
  },
}));

const MemberServiceCustomerReportSkeleton = React.memo(
  (props) => {
    const { numberOfItems = 10, columnsNumber = 3, maxRowsNumber = 5, openAll = false } = props;
    const classes = useStyles();
    const items = Array(numberOfItems).fill();
    const columns = Array(columnsNumber).fill();

    const getRowsCount = () => {
      return Array(Math.ceil(Math.random() * maxRowsNumber)).fill();
    };

    return (
      <div>
        {items.map((item, index) => (
          <div key={`sk_${index}`} className={classes.singleItemWrapper}>
            <div className={classes.initialItemWrapper}>
              <div className={classes.itemTitle}>
                <Skeleton count={1} height={15} width={100 + Math.random() * 50 + 'px'} />
                {index !== 0 && (
                  <span className={classes.numberOfItems}>
                    <Skeleton count={1} height={15} width={50} />
                  </span>
                )}
              </div>
              <div className={classes.expandIconWrapper}>
                <Skeleton count={1} height={15} width={15} />
              </div>
            </div>

            {(openAll || index === 0) && (
              <div className={classes.tableWrapper}>
                <div className={classes.table}>
                  {getRowsCount().map((row, rowIndex) => (
                    <div className={classes.row} key={`sk_${index}_r_${rowIndex}`}>
                      {columns.map((column, columnIndex) => (
                        <div
                          className={classNames([classes.column, classes['columnCell' + columnIndex]])}
                          key={`sk_${index}_r_${rowIndex}_c_${columnIndex}`}>
                          <Skeleton count={1} height={15} width={'100%'} />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  },
  () => {
    // Do not change a skeleton on change any props, only on mount or unmount component
    return true;
  }
);

export default MemberServiceCustomerReportSkeleton;
