import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { BORDER_RADIUS_MAIN, PADDING_CONTENT } from '../../constants/globals';
import { FONT_CART_PRODUCT, FONT_CART_VARIANT_SUB, FONT_CART_VARIANT_TITLE } from '../../constants/fonts';
import { COLORS } from '../../constants/colors';
import EmptyImage from '../../assets/no.jpg';
import {
  apiSupplierChangeInventoryOnProductVariant,
  apiSupplierActivateProducts,
  apiSupplierActivateVariants,
  apiSupplierInactivateProducts,
  apiSupplierInactivateVariants,
} from '../../../../api';
import SupplierSwitch from '../SupplierSwitch';
import { isTouchableDevice, preventClickDefault } from '../../helpers/global';
import MinimalError from '../../entities/minimalError';
import { ErrorBox } from '../../../../components';
import classNames from 'classnames';
import { makeServerValue, makeClientValue } from '../../helpers/convertors';
import VariantDrawer from './VariantDrawer';
import { withRouter } from '../../../../hocs';
import { TOAST_ACTIONS } from '../ActionToast';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 30,
  },
  noUserSelect: {
    userSelect: 'none',
  },
  productName: {
    ...FONT_CART_PRODUCT,
    color: COLORS.blackTintedBlue,
    marginBottom: 10,
  },
  productWrapper: {
    marginBottom: 10,
  },

  variantWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textDecoration: 'none !important',
    borderRadius: BORDER_RADIUS_MAIN,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: COLORS.backgroundLight,
    },
    '&:hover .quantityBoxSwitcherRoot': {
      backgroundColor: COLORS.white,
    },
  },
  variantImageWrapper: {
    width: 56,
    height: 56,
    marginRight: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: BORDER_RADIUS_MAIN,
    overflow: 'hidden',
    position: 'relative',
  },
  variantImage: {
    position: 'absolute',
    height: 56,
    backgroundColor: COLORS.white,
  },
  variantContent: {
    flex: 1,
    paddingTop: 5,
    paddingBottom: 5,
  },
  variantName: {
    ...FONT_CART_VARIANT_TITLE,
    color: COLORS.blackTintedBlue,
  },
  variantPriceAndUnit: {
    ...FONT_CART_VARIANT_SUB,
    color: COLORS.greyText,
  },
  variantCounterWrapper: {
    position: 'relative',
    zIndex: 20,
    minWidth: '10%',
    paddingLeft: PADDING_CONTENT,
    display: 'flex',
    height: 'auto',
    alignSelf: 'stretch',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  variantsWrapper: {},
}));

const propTypes = {
  product: PropTypes.object.isRequired,
  isDrawTitle: PropTypes.bool,
  unlisted: PropTypes.bool,
  onReportError: PropTypes.func,
};

const ProductSingle = (props) => {
  const { product: productFromProps, isDrawTitle, unlisted, history, setToastAction } = props;

  const classes = useStyles();

  const [product, setProduct] = useState(productFromProps);
  const [error, setError] = useState(new MinimalError({}));
  const [loading, setLoading] = useState(false);

  const goToProduct = () => {
    history.push(`/supplier/inventory/product/${product.id}`);
  };

  const drawProduct = (product) => {
    const isOnlyOneVariant = product?.variants?.length < 2;
    const variantToDraw = product?.variants && product?.variants[0];

    if (isOnlyOneVariant) {
      return drawVariant(variantToDraw, true);
    }

    return (
      <div
        className={classNames([classes.variantWrapper, classes.productWrapper, 'noDrag'])}
        key={`${product.id}`}
        onClick={goToProduct}>
        <div className={classes.variantImageWrapper}>{drawImage(product)}</div>
        <div className={classes.variantContent}>
          <p className={classes.variantName}>{product.name}</p>
        </div>
        <div className={classes.variantCounterWrapper} onClick={preventClickDefault}>
          {unlisted && (
            <SupplierSwitch
              checked={product.active}
              disabled={loading}
              onChange={(e) => {
                changeProductActivity(product, e.target.checked);
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const drawVariant = (variant, fromProduct = false) => {
    return (
      <VariantDrawer
        key={`${product.id}_${variant.id}`}
        product={product}
        variant={variant}
        fromProduct={fromProduct}
        drawImage={drawImage}
        unlisted={unlisted}
        changeVariantActivity={changeVariantActivity}
        updateVariantInventory={updateVariantInventory}
        classes={classes}
        onClick={goToProduct}
      />
    );
  };

  const drawImage = (product) => {
    if (!!product?.image?.thumbSrc) {
      return <img className={classes.variantImage} src={product.image.thumbSrc} alt={product.name} />;
    } else {
      return <img className={classes.variantImage} src={EmptyImage} alt={product.name} />;
    }
  };

  const updateVariantInventory = (variant, newInventoryQuantity, onSuccess, onError) => {
    const variantId = variant.id;

    apiSupplierChangeInventoryOnProductVariant(
      product.id,
      variantId,
      {
        quantity: makeServerValue(newInventoryQuantity),
      },
      (updatedProduct) => {
        setToastAction(TOAST_ACTIONS.inventoryUpdated);

        setProduct(updatedProduct);
        onSuccess && onSuccess(updatedProduct);
      },
      () => {
        setProduct(productFromProps);
        onError && onError(variant?.inventory?.quantity ? makeClientValue(variant?.inventory?.quantity) : 0);
      }
    );
  };

  const changeProductActivity = (product, newStatus) => {
    if (newStatus) {
      apiSupplierActivateProducts(
        [product.id],
        () => {
          setToastAction(TOAST_ACTIONS.listed);
          setProduct({ ...productFromProps, active: newStatus });
        },
        handleAnyError
      );
    } else {
      apiSupplierInactivateProducts(
        [product.id],
        () => {
          setToastAction(TOAST_ACTIONS.unlisted);
          setProduct({ ...productFromProps, active: newStatus });
        },
        handleAnyError
      );
    }
  };

  const changeVariantActivity = (product, variant, newStatus) => {
    const variants = [...product.variants];
    const indexToChange = variants.findIndex((v) => v.id === variant.id);
    variants[indexToChange] = { ...variants[indexToChange], active: newStatus };

    if (newStatus) {
      apiSupplierActivateVariants(
        [variant.id],
        () => {
          setToastAction(TOAST_ACTIONS.listed);
          setProduct({ ...productFromProps, variants, active: true });
        },
        handleAnyError
      );
    } else {
      const isActivate = variants.find((v) => v.active === true);
      apiSupplierInactivateVariants(
        [variant.id],
        () => {
          setToastAction(TOAST_ACTIONS.unlisted);
          setProduct({ ...productFromProps, variants, active: !!isActivate });
        },
        handleAnyError
      );
    }
  };

  const handleAnyError = (errorObj) => {
    setError(new MinimalError(errorObj));
    setLoading(false);
  };

  return (
    <div className={classNames([classes.root, !isTouchableDevice() && classes.noUserSelect])}>
      {isDrawTitle && <div className={classes.productName}>{product.subType}</div>}
      {error.hasError() && <ErrorBox error={error.getMessage()} />}

      <div className={classes.variantsWrapper}>
        {drawProduct(product)}
        {product?.variants?.length > 1 && product?.variants.map((variant) => drawVariant(variant))}
      </div>
    </div>
  );
};

ProductSingle.propTypes = propTypes;

export default withRouter(ProductSingle);
