import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { sumOfArrayOfObjectsValue } from '../../../../helpers';

export const getTreeChildRows = (intl, row, rootRows) => {
  if (row && row.rowType === 'types') {
    return row.typeItems;
  }

  if (row && row.rowType === 'productSubType') {
    return row.subTypeItems;
  }

  if (row && row.rowType === 'lineItemNameProduct') {
    return row.productItems;
  }

  if (row && row.rowType === 'lineItemNameVariant') {
    return row.variantItems;
  }

  if (row && row.rowType === 'end') {
    return null;
  }

  return rootRows;
};

export const prepareRowsAfterLoad = (rows, intl) => {
  let expanded = [];

  let _rows = [];
  let expandSecondLevel = [];
  let expandAllLevels = [];

  for (const [typeKey, typeValue] of Object.entries(rows)) {
    let typeFinishArray = [];
    for (const [subTypeKey, subTypeValue] of Object.entries(typeValue)) {
      let subTypeFinishArray = [];
      for (const [productKey, productValue] of Object.entries(subTypeValue)) {
        let productFinishArray = [];
        let index = 0;
        for (const [variantKey, variantValue] of Object.entries(productValue)) {
          index++;
          productFinishArray.push({
            variantName: variantKey.startsWith('va_')
              ? intl.formatMessage({ id: 'global.table.noVariantName' }, { index: index })
              : variantKey,
            lineItemNameVariantTotalPrice: sumOfArrayOfObjectsValue(variantValue, 'limitPerPeriod'),
            variantItems: variantValue.map((variantItem, index) => ({
              ...variantItem,
              rowType: 'end',
              id: `${typeKey}_${subTypeKey}_${productKey}_${variantKey}_${index}`,
            })),
            rowType: 'lineItemNameVariant',
            id: `${typeKey}_${subTypeKey}_${productKey}_${variantKey}`,
          });
          expandAllLevels.push(`${typeKey}_${subTypeKey}_${productKey}_${variantKey}`);
        }
        subTypeFinishArray.push({
          productName: productKey,
          productItems: productFinishArray,
          lineItemNameProductTotalPrice: sumOfArrayOfObjectsValue(productFinishArray, 'lineItemNameVariantTotalPrice'),
          rowType: 'lineItemNameProduct',
          id: `${typeKey}_${subTypeKey}_${productKey}`,
        });
        expandSecondLevel.push(`${typeKey}_${subTypeKey}_${productKey}`);
      }
      typeFinishArray.push({
        subTypeName: subTypeKey,
        subTypeItems: subTypeFinishArray,
        rowType: 'productSubType',
        productSubTypeTotalPrice: sumOfArrayOfObjectsValue(subTypeFinishArray, 'lineItemNameProductTotalPrice'),
        id: `${typeKey}_${subTypeKey}`,
      });
      expandSecondLevel.push(`${typeKey}_${subTypeKey}`);
    }
    _rows.push({
      typeName: typeKey,
      typeItems: typeFinishArray,
      typeTotalPrice: sumOfArrayOfObjectsValue(typeFinishArray, 'productSubTypeTotalPrice'),
      expandSecondLevel,
      expandAllLevels,
      rowType: 'types',
      id: `${typeKey}`,
    });
  }

  return {
    rows: _rows,
    expandedGroups: expanded,
  };
};

export const getExpandedRowIds = () => {
  return [];
};

export const tableRowRenderer = (onClick, { row, ...props }) => {
  const getColor = (row) => {
    switch (row.rowType) {
      case 'types': {
        return '#ffffff';
      }
      case 'productSubType': {
        return 'rgba(224, 224, 224, 0.2)';
      }
      case 'lineItemNameProduct': {
        return 'rgba(224, 224, 224, 0.4)';
      }
      case 'lineItemNameVariant': {
        return 'rgba(224, 224, 224, 0.8)';
      }

      default: {
        return '#ffffff';
      }
    }
  };

  return <Table.Row {...props} onClick={() => onClick && onClick(row)} style={{ backgroundColor: getColor(row) }} />;
};
