import { COLORS } from '../../helpers';

export default {
  paper: {
    padding: '40px 52px 74px',
    borderRadius: 8,
    height: 'calc(100% - 16px)',
    maxWidth: 980,
    width: '100%',
  },
  wrapper: {},
  name: {
    fontSize: 18,
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.text,
    marginBottom: 40,
  },
  variants: {
    marginBottom: 50,
  },
  input: {
    marginBottom: 25,
  },
  inputText: {
    textAlign: 'right',
  },
  button: {
    minWidth: 330,
    alignSelf: 'center',
    textTransform: 'uppercase',
  },
  controls: {
    margin: '27px 0 0 0',
    textAlign: 'center',
  },
  control: {
    padding: '0 105px',
  },
};
