import React from 'react';
import { withRouter } from '../../hocs';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { SidebarMenu, HeaderMenu } from '../Partials';
import { AdminTabs, AdminLayout, Spinner } from '../../components';
import Tab from './Tab/Tab';
import styles from './styles';
import { SUPPLIERS_STANDARD_TABS } from '../../helpers/constants';

class SuppliersScene extends React.Component {
  state = {
    tab: 0,
  };

  render() {
    const { tab, loading } = this.state;
    const { intl } = this.props;
    const tabs = [intl.formatMessage({ id: 'suppliers.properties.tab.all' })];

    return (
      <AdminLayout sidebarComponent={SidebarMenu} headerMenuComponent={HeaderMenu}>
        {loading && <Spinner size={60} />}

        <AdminTabs onChangeTab={(index) => this.setState({ tab: index })} tab={tab} tabs={tabs}>
          {tab === 0 && (
            <Tab
              type={SUPPLIERS_STANDARD_TABS.ALL}
              name={intl.formatMessage({ id: 'suppliers.properties.tab.name' })}
              addButtonText={intl.formatMessage({ id: 'suppliers.properties.tab.addSupplier' })}
            />
          )}
        </AdminTabs>
      </AdminLayout>
    );
  }
}

export default withStyles(styles)(withRouter(injectIntl(SuppliersScene)));
