import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../../../hocs';
import {
  AdminDialog,
  AdminDialogTitle,
  AdminDialogContent,
  AdminDialogControls,
  PrimaryTextField,
  PrimaryButton,
  Spinner,
  ErrorFormattedMessage,
} from '../../../../components';
import styles from './styles';
import { validateField } from '../../../../helpers';
import validationRules from './validation';
import ErrorBox from '../../../../components/ErrorBox/ErrorBox';

const propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const defaultProps = {};

class CreateNewSupplierDialog extends React.Component {
  constructor(props) {
    super(props);
    const { typeNames, property } = this.props;

    this.state = {
      email: property ? property.email : '',
      name: property ? property.name : '',
      phone: property ? property.phone : '',

      nameError: '',
      emailError: '',

      loading: false,

      requestError: '',
    };

    this.validationRules = validationRules(typeNames);
  }

  validate = () => {
    this.setState({ loading: true });
    const nameError = validateField(this.validationRules, 'name', this.state.name);
    const emailError = validateField(this.validationRules, 'email', this.state.email);

    if (!nameError && !emailError) {
      this.setState({
        nameError: '',
        emailError: '',
        requestError: '',
      });

      let data = {
        name: this.state.name,
        email: this.state.email,
      };

      if (this.state.phone) {
        data.phone = this.state.phone;
      }

      this.props.onSave(
        this.props.property,
        data,
        () => {
          this.setState({
            loading: false,
          });
        },
        (error) => {
          this.setState({
            requestError: error?.response?.data?.message,
            loading: false,
          });
        }
      );
    } else {
      this.setState({
        nameError,
        emailError,
        loading: false,
      });
    }
  };

  render() {
    const { onClose, intl, classes } = this.props;
    const { requestError, loading } = this.state;

    return (
      <AdminDialog open closeButton onClose={onClose}>
        <AdminDialogTitle title={intl.formatMessage({ id: 'suppliers.create.dialog.title' })} />
        <AdminDialogContent className={classes.wrapper}>
          {loading && <Spinner size={50} />}

          <PrimaryTextField
            className={classes.input}
            error={!!this.state.nameError}
            onChange={(event) => this.setState({ name: event.target.value })}
            value={this.state.name}
            id="tab-name-input"
            label={intl.formatMessage({ id: 'global.name' })}
            type="text"
            InputLabelProps={{ shrink: true }}
            margin="normal"
            helperText={this.state.nameError && intl.formatMessage({ id: this.state.nameError })}
            fullWidth
          />

          <PrimaryTextField
            error={!!this.state.emailError}
            onChange={(event) => this.setState({ email: event.target.value })}
            value={this.state.email}
            id="email-input"
            margin="dense"
            InputLabelProps={{ shrink: true }}
            label={<FormattedMessage id="global.email" />}
            helperText={<ErrorFormattedMessage id={this.state.emailError} />}
            fullWidth
          />

          <PrimaryTextField
            className={classes.input}
            onChange={(event) => this.setState({ phone: event.target.value })}
            value={this.state.phone}
            id="tab-phone-input"
            label={intl.formatMessage({ id: 'global.phone' })}
            type="tel"
            InputLabelProps={{ shrink: true }}
            margin="normal"
            fullWidth
          />

          {!!requestError && <ErrorBox error={requestError} />}

          <AdminDialogControls>
            <PrimaryButton className={classes.button} onClick={() => this.validate()} isLoading={loading}>
              {intl.formatMessage({ id: 'global.save' })}
            </PrimaryButton>
          </AdminDialogControls>
        </AdminDialogContent>
      </AdminDialog>
    );
  }
}

CreateNewSupplierDialog.propTypes = propTypes;
CreateNewSupplierDialog.defaultProps = defaultProps;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(CreateNewSupplierDialog)));
