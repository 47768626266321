import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import MenuItem from "@material-ui/core/MenuItem";
import {withStyles} from "@material-ui/core/styles";
import {
  PrimarySelect,
  ConfirmDialog
} from "../../../components";
import {
  apiDraftBulkRemove,
} from '../../../api';

const styles = ({
  select: {
    marginRight: 10
  }
});

const LABEL = -1;
const BATCH_DELETE = 1;

const propTypes = {
  reload: PropTypes.func,
  keys: PropTypes.array.isRequired
};

class DraftBatchControls extends React.Component {

  state = {
    confirmLoading: false,
    confirmDelete: false,
  };

  onSelectAction = (action) => {
    switch (action) {
      case BATCH_DELETE: {
        this.setState({confirmDelete: true});
        break;
      }
      default: {
        break;
      }
    }
  };

  onDelete = () => {
    const {
      keys,
      reload,
    } = this.props;

    this.setState({confirmLoading: true});
    apiDraftBulkRemove(keys, () => {
      this.setState({
        confirmDelete: false,
        confirmLoading: false
      }, () => {
        reload();
      });
    }, undefined);
  };

  render() {
    const {
      intl,
      classes,
    } = this.props;

    const {
      confirmDelete,
      confirmLoading,
    } = this.state;

    return (
      <React.Fragment>
        <PrimarySelect
          className={classes.select}
          value={LABEL}
          onChange={(e) => this.onSelectAction(e.target.value)}
        >
          <MenuItem key={LABEL} value={LABEL}>
            {intl.formatMessage({id: "order.table.batchActions"})}
          </MenuItem>
          <MenuItem key={BATCH_DELETE} value={BATCH_DELETE}>
            {intl.formatMessage({id: "draft.table.delete"})}
          </MenuItem>
        </PrimarySelect>

        {confirmDelete && (
          <ConfirmDialog onClose={() => this.setState({confirmDelete: false})}
                         loading={confirmLoading}
                         confirmTitle={"confirm.deleteDrafts"}
                         title={intl.formatMessage({id: "titles.deleteDrafts"})}
                         message={intl.formatMessage({id: "messages.confirmDeleteDrafts"})}
                         onConfirm={this.onDelete}
          />
        )}

      </React.Fragment>
    )
  };
}

DraftBatchControls.propTypes = propTypes;

export default withStyles(styles)(
  injectIntl(
    DraftBatchControls
  )
);