import { COLORS } from '../../helpers';

export default () => ({
  button: {
    position: 'relative',
    marginLeft: 9,
    marginRight: 9,
    padding: 0,
    '&:hover': {
      background: 'none',
    },
  },
  label: {
    fontSize: 12,
    color: COLORS.text,
    flexDirection: 'column',
  },
  icon: {
    color: COLORS.black,
  },
});
