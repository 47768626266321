import React from 'react'
import PropTypes from 'prop-types'
import {injectIntl} from 'react-intl'
import MenuItem from '@material-ui/core/MenuItem'
import {withStyles} from '@material-ui/core/styles'
import {
  AdminDialog,
  AdminDialogTitle,
  AdminDialogContent,
  PrimaryButton,
  PrimarySelect,
  PrimaryTextField,
  ChipsSelect,
} from '../../components'
import {
  NAME_FILTER,
  ACTIVITY_FILTER as ACTIVITY_FILTER_NAME,
  SUB_TYPE_FILTER,
  TYPE_FILTER
} from './FilterNames'
import {
  IN_ARRAY_FILTER,
  HAS_SUBSTRING_FILTER,
  ACTIVITY_FILTER as ACTIVITY_FILTER_TYPE,
  IMAGE_FILTER,
} from '../../components/AdminDXTable/ClientSideFilters/FilterTypes'
import styles from './styles'

const propTypes = {
  subTypes: PropTypes.array.isRequired,
  types: PropTypes.array.isRequired,
  selectedFilter: PropTypes.object,
  appliedFilters: PropTypes.array,
  onApplyFilter: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const FILTERS = (intl) => {
  return [
    {
      name: NAME_FILTER,
      property: "name",
      type: HAS_SUBSTRING_FILTER,
      title: intl.formatMessage({id: 'products.filter.name'}),
      selectedValue: "",
      clientSide: true,
      entities: ['product', 'variant']
    },
    {
      name: SUB_TYPE_FILTER,
      property: "subType",
      type: IN_ARRAY_FILTER,
      title: intl.formatMessage({id: 'products.filter.subType'}),
      selectedValues: [],
      clientSide: true,
      entities: ['product']
    },
    {
      name: TYPE_FILTER,
      property: "type",
      type: IN_ARRAY_FILTER,
      title: intl.formatMessage({id: 'products.filter.type'}),
      selectedValues: [],
      clientSide: true,
      entities: ['product']
    },
    {
      name: ACTIVITY_FILTER_NAME,
      property: "active",
      type: ACTIVITY_FILTER_TYPE,
      title: intl.formatMessage({id: 'products.filter.activity'}),
      selectedValues: [],
      clientSide: true,
      entities: ['product', 'variant']
    },
    {
      name: IMAGE_FILTER,
      property: "image",
      type: IMAGE_FILTER,
      title: intl.formatMessage({id: 'filters.image'}),
      selectedValues: [intl.formatMessage({id: "filters.uploaded"}), intl.formatMessage({id: "filters.notUploaded"})],
      clientSide: true,
      entities: ['product', 'variant']
    },
  ]
};

class ProductsFilterDialog extends React.Component {

  constructor(props) {
    super(props);

    const {
      intl,
      selectedFilter,
      appliedFilters
    } = this.props;

    this.state = {
      selectedFilter: selectedFilter || FILTERS(intl)[0],
      appliedFilters: appliedFilters || []
    };
  }

  onChange = (filter) => {
    const { intl } = this.props;
    const { appliedFilters } = this.state;

    let selectedFilter;
    for (let i = 0; i < FILTERS(intl).length; i++) {
      if (FILTERS(intl)[i].name === filter) {
        selectedFilter = FILTERS(intl)[i];
        break;
      }
    }

    for (let i = 0; i < appliedFilters.length; i++) {
      if (appliedFilters[i].name === filter) {
        selectedFilter.values = appliedFilters[i].values;
        break;
      }
    }

    this.setState({ selectedFilter });
  };

  onApply = () => {
    const {
      onApplyFilter,
      appliedFilters,
    } = this.props;

    const {
      selectedFilter,
    } = this.state;

    let replaced = false;
    let filters = appliedFilters.slice();

    for (let i = 0; i < filters.length; i++) {
      if (filters[i].name === selectedFilter.name) {
        replaced = true;
        filters[i] = selectedFilter;
        break;
      }
    }

    if (!replaced) {
      filters.push(selectedFilter);
    }

    onApplyFilter && onApplyFilter(filters);
  };

  render() {
    const {
      intl,
      classes,
      onClose,
      subTypes,
      types,
    } = this.props;

    const {
      selectedFilter,
    } = this.state;

    return (
      <AdminDialog open
                   closeButton
                   onClose={onClose}>
        <AdminDialogTitle title={intl.formatMessage({id: 'global.addFilter'})} />
        <AdminDialogContent className={classes.wrapper}>

          <div className={classes.row}>
            <div className={classes.column}>
              <PrimarySelect
                label={intl.formatMessage({id: 'global.column'})}
                value={selectedFilter.name}
                onChange={e => this.onChange(e.target.value)}
                fullWidth
                formControlClassName={classes.selectFormControl}
              >
                {FILTERS(intl).map(filter => (
                  <MenuItem key={filter.name} value={filter.name}>
                    {filter.title}
                  </MenuItem>
                ))}
              </PrimarySelect>
            </div>
          </div>

          <div className={classes.row}>
            <div className={classes.column}>
              {selectedFilter.name === NAME_FILTER && (
                <PrimaryTextField
                  className={classes.input}
                  onChange={event => {
                    const value = event.target.value;
                    this.setState((state) => {
                      state.selectedFilter.selectedValue = value;
                      return state;
                    })
                  }}
                  value={selectedFilter.selectedValue}
                  id={selectedFilter.name}
                  label={selectedFilter.title}
                  type="text"
                  InputLabelProps={{shrink: true}}
                  fullWidth
                />
              )}

              {selectedFilter.name === SUB_TYPE_FILTER && (
                <ChipsSelect values={subTypes}
                             label={intl.formatMessage({id: "products.filter.selectSubTypes"})}
                             limited={subTypes.slice(0, 9)}
                             selected={selectedFilter.selectedValues}
                             onChange={(selected) => {
                               this.setState((state) => {
                                 state.selectedFilter.selectedValues = selected;
                                 return state;
                               })
                             }}
                />
              )}

              {selectedFilter.name === TYPE_FILTER && (
                <ChipsSelect values={types}
                             label={intl.formatMessage({id: "products.filter.selectTypes"})}
                             selected={selectedFilter.selectedValues}
                             onChange={(selected) => {
                               this.setState((state) => {
                                 state.selectedFilter.selectedValues = selected;
                                 return state;
                               })
                             }}
                />
              )}

              {selectedFilter.name === ACTIVITY_FILTER_NAME && (
                <ChipsSelect values={['Active', 'Inactive']}
                             label={intl.formatMessage({id: "products.filter.selectActivity"})}
                             selected={selectedFilter.selectedValues}
                             onChange={(selected) => {
                               this.setState((state) => {
                                 state.selectedFilter.selectedValues = selected;
                                 return state;
                               })
                             }}
                />
              )}

              {selectedFilter.name === IMAGE_FILTER && (
                <ChipsSelect values={[intl.formatMessage({id: "filters.uploaded"}), intl.formatMessage({id: "filters.notUploaded"})]}
                             label={intl.formatMessage({id: "filters.image"})}
                             selected={selectedFilter.selectedValues}
                             onChange={(selected) => {
                               this.setState((state) => {
                                 state.selectedFilter.selectedValues = selected;
                                 return state;
                               })
                             }}
                />
              )}
            </div>
          </div>

          <div className={classes.controls}>
            <PrimaryButton onClick={this.onApply}>
              {intl.formatMessage({id: 'global.apply'})}
            </PrimaryButton>
          </div>
        </AdminDialogContent>
      </AdminDialog>
    );
  }
}

ProductsFilterDialog.propTypes = propTypes;

export default withStyles(styles, {withTheme: true})(
  injectIntl(ProductsFilterDialog)
);
