const validation = {
  name: {
    presence: {
      message: '^validation.variant.enterName',
    },
  },
  unit: {
    presence: {
      message: '^validation.variant.enterUnit',
    },
  },
  subscriptionVariantId: {
    presence: {
      message: '^validation.variant.enterVariantId',
    },
  },
  unitQuantity: {
    numericality: {
      onlyInteger: false,
      greaterThan: 0,
      message: '^validation.required',
    },
    presence: {
      message: '^validation.variant.enterUnitQuantity',
    },
  },
  retailMarketPrice: {
    numericality: {
      onlyInteger: false,
      greaterThan: 0,
      message: '^validation.required',
    },
    presence: {
      message: '^validation.variant.price',
    },
  },
  retailPreorderPrice: {
    numericality: {
      onlyInteger: false,
      greaterThan: 0,
      message: '^validation.required',
    },
    presence: {
      message: '^validation.variant.price',
    },
  },
  wholesaleMarketPrice: {
    numericality: {
      onlyInteger: false,
      greaterThan: 0,
      message: '^validation.required',
    },
    presence: {
      message: '^validation.variant.price',
    },
  },
  wholesalePreorderPrice: {
    numericality: {
      onlyInteger: false,
      greaterThan: 0,
      message: '^validation.required',
    },
    presence: {
      message: '^validation.variant.price',
    },
  },
  atLeastOnePrice: {
    presence: {
      message: '^validation.needAtLeastOnePrice',
    },
  },
  shippingLocation: {
    presence: {
      message: '^validation.screen.variants.shippingLocation',
    },
  },
  shippingMethod: {
    presence: {
      message: '^validation.screen.variants.shippingMethod',
    },
  },
  subscriptionQuantity: {
    numericality: {
      onlyInteger: false,
      message: '^validation.required',
    },
    presence: {
      message: '^validation.required',
    },
  },
};

export default validation;
