import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { EmailAndPasswordStage, CreatePasswordStage, RestorePasswordStage, RegisterStage } from '../LoginForm';
import { SimpleDialog } from '../../components';
import {
  AUTH_EMAIL_AND_PASSWORD_STAGE as EMAIL_AND_PASSWORD_STAGE,
  AUTH_CREATE_PASSWORD_STAGE as CREATE_PASSWORD_STAGE,
  AUTH_RESTORE_PASSWORD_STAGE as RESTORE_PASSWORD_STAGE,
  AUTH_REGISTER_STAGE as REGISTER_STAGE,
  AUTH_REGISTER_STAGE_FINAL as REGISTER_STAGE_FINAL,
} from '../../helpers';
import styles from './styles';
import { injectIntl } from 'react-intl';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      firstName: '',
      message: '',
      stage: this.props.stage,
      isLoading: false,
      requestError: '',
    };
  }

  onClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      this.setState({ stage: EMAIL_AND_PASSWORD_STAGE });
      this.props.onClose();
    }
  };

  onEmailAndPasswordStageDone = (data) => {
    this.props.onLogin({
      token: data.token,
      producer: data.producer,
      acl: data.acl,
    });
  };

  onBackClick = () => {
    if (this.props.withBack) {
      this.setState({ message: '', stage: EMAIL_AND_PASSWORD_STAGE });
    }
  };

  getFormContent = () => {
    return (
      <>
        {this.state.stage === EMAIL_AND_PASSWORD_STAGE && (
          <EmailAndPasswordStage
            onNoAccount={() => this.setState({ message: '', stage: REGISTER_STAGE })}
            onDone={this.onEmailAndPasswordStageDone}
            onDefaultPassword={(data) => {
              this.setState({
                stage: CREATE_PASSWORD_STAGE,
                firstName: data.firstName,
                email: data.email,
              });
            }}
            onNotRegistered={() => {
              this.setState({
                message: "You don't have an account yet. Please use the form below to create an acocunt.",
                stage: REGISTER_STAGE,
              });
            }}
            onForgotPassword={() => this.setState({ message: '', stage: RESTORE_PASSWORD_STAGE })}
          />
        )}

        {this.state.stage === RESTORE_PASSWORD_STAGE && (
          <RestorePasswordStage
            onDone={() => this.setState({ message: '', stage: EMAIL_AND_PASSWORD_STAGE, email: '' })}
          />
        )}

        {this.state.stage === CREATE_PASSWORD_STAGE && (
          <CreatePasswordStage
            firstName={this.state.firstName}
            email={this.state.email}
            onDone={() => this.setState({ message: '', stage: EMAIL_AND_PASSWORD_STAGE, email: '', firstName: '' })}
          />
        )}

        {(this.state.stage === REGISTER_STAGE || this.state.stage === REGISTER_STAGE_FINAL) && (
          <RegisterStage
            message={this.state.message}
            isFinal={this.state.stage === REGISTER_STAGE_FINAL}
            onFinish={() => this.setState({ stage: REGISTER_STAGE_FINAL })}
            onDone={() =>
              (this.props.onRegisterEnd && this.props.onRegisterEnd()) ||
              this.setState({ message: '', stage: EMAIL_AND_PASSWORD_STAGE })
            }
          />
        )}
      </>
    );
  };

  getFormTitle = () => {
    const {
      state: { stage },
      props: { intl },
    } = this;

    switch (stage) {
      case EMAIL_AND_PASSWORD_STAGE: {
        return intl.formatMessage({ id: 'global.signIn' });
      }
      case RESTORE_PASSWORD_STAGE: {
        return intl.formatMessage({ id: 'restorePassword.title' });
      }
      case CREATE_PASSWORD_STAGE: {
        return intl.formatMessage({ id: 'createPassword.title' });
      }
      case REGISTER_STAGE: {
        return intl.formatMessage({ id: 'reg.signUp' });
      }
      case REGISTER_STAGE_FINAL: {
        return intl.formatMessage({ id: 'reg.final.title' });
      }
      default: {
        return intl.formatMessage({ id: 'global.login' });
      }
    }
  };

  render() {
    const { open, withBack } = this.props;
    return (
      <SimpleDialog
        onClose={this.onClose}
        title={this.getFormTitle()}
        content={this.getFormContent}
        disableEscapeKeyDown
        withBack={withBack && this.state.stage !== EMAIL_AND_PASSWORD_STAGE}
        onBackClick={() => withBack && this.onBackClick()}
        backdropInvisible
        open={open}
      />
    );
  }
}

LoginForm.propTypes = {
  open: PropTypes.bool,
  stage: PropTypes.string,
  withBack: PropTypes.bool,
  onLogin: PropTypes.func.isRequired,
  onRegisterEnd: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

LoginForm.defaultProps = {
  withBack: true,
  open: false,
  stage: EMAIL_AND_PASSWORD_STAGE,
};

export default withStyles(styles, { withTheme: true })(injectIntl(LoginForm));
