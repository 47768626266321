import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import CellAsTextInput from '../../components/AdminDXTable/Editing/CellAsTextInput';
import { apiUpdateSharedProperty } from '../../api';

export const TableCell = (typeName, reload, { row, ...props }, rows, onUpdateRows) => {
  const type = props.column.type || '';
  const image = row.image;
  if (type === 'image' && image) {
    return (
      <Table.Cell style={{ paddingLeft: 0 }} {...props}>
        <img src={image.src} alt={row.name} style={{ maxWidth: 50 }} />
      </Table.Cell>
    );
  }

  if (type === 'text') {
    return (
      <CellAsTextInput
        value={row.name}
        key={`${row.id}`}
        cellProps={props}
        onFinishCellEditing={(value) => {
          apiUpdateSharedProperty(
            row.id,
            typeName,
            { name: value },
            (rows) => {
              onUpdateRows(rows);
            },
            () => {}
          );
        }}
      />
    );
  }

  return <Table.Cell {...props} />;
};
