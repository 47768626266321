export const COLORS = {
  primary: '#1D00CF',
  primaryHovered: '#1600ad',

  secondary: '',
  background: '#fff',
  backgroundLight: '#F7F7F7',
  active: '',
  focused: '',
  visited: '',
  hover: '#E5E5E5E6',
  danger: '#B80000',
  dangerHover: '#860101',
  text: '#001E2E',

  border: '#595959',
  transparent: 'transparent',

  blackTintedBlue: '#001621',
  darkBlue: '#15004F',
  white: '#fff',
  grey: '#737373',
  greyText: '#595959',
  greyLight: '#f5f5f5',
  lightGrey: '#E6E6E6',
  brightBlue: '#1D00CF',
  lightBg: '#F7F7F7',
  red: '#B80000',
  switcherOff: '#D1D1D1',
  numberPadBackground: '#DCDFE4',
  toastBg: '#3D3D3D',
  textSelection: '#99dbe3',
};
