import {COLORS} from "../../../helpers";

export default ({
  wrapper: {
  },
  button: {
    minWidth: 330,
    alignSelf: 'center',
    textTransform: 'uppercase',
  },
  controls: {
    margin: '27px 0 0 0',
    textAlign: 'center'
  },
  text: {
    textAlign: 'center',
    color: COLORS.text,
    fontWeight: 300,
    fontSize: 20,
  }
});
