import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl'
import classNames from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';
import {withStyles} from '@material-ui/core/styles';
import {
  AdminDialogTitle,
  AdminDialogContent,
  PrimaryButton,
  PrimaryTextField,
  PrimarySelect,
  Spinner,
  PrimaryChip,
  ErrorBox,
} from '../../../components';
import {
  validateField
} from '../../../helpers';
import Image from '../Image/Image';
import Variants from './Variants';
import VariantContainer from '../VariantContainer/VariantContainer';
import validationRules from './validation';
import {
  apiAddProduct,
  apiEditProduct,
} from '../../../api/product/product'
import styles from './styles'
import Icon from "../../../components/Icon/Icon";
import CloseIcon from "@material-ui/icons/Close";
import { apiGetSharedProperties } from '../../../api';

const propTypes = {
  product: PropTypes.object,
  producer: PropTypes.object.isRequired,
  onDone: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
};

const defaultProps = {
  title: "product.title.create",
  buttonTitle: "product.button.create",
};

class ProductContainer extends React.Component {

  constructor(props) {
    super(props);
    const {
      product,
      producer,
    } = this.props;

    this.state = {
      loading: true,
      editingVariant: null,
      creatingVariant: false,
      variantChanged: false,

      file: null,

      name: product ? product.name : "",
      type: product ? product.type : Object.keys(producer.types)[0],
      subType: product ? product.subType : Object.keys(producer.subTypes)[0],
      translation: product ? product.translation : "",
      search: product ? product.search : [],
      description: product ? product.description : "",
      variants: product ? product.variants : [],

      nameError: "",
      typeError: "",
      subTypeError: "",
      error: "",

      types: [],
      subTypes: []
    };
  }

  componentDidMount() {
    // Retrieving types from server
    this.retrieveSharedProductProperties('type', (types) => {
      // Retrieving sub types from server
      this.retrieveSharedProductProperties('subType', (subTypes) => {
        this.setState({
          subTypes: subTypes.map(type => type.name),
          types: types.map(type => type.name),
          loading: false,
        })
      });
    });
  }

  /** Gets shared properties */
  retrieveSharedProductProperties = (type, onSuccess, onError) => {
    apiGetSharedProperties({}, type, (rows) => {
      onSuccess(rows);
    }, onError)
  };

  preValidate = (variant) => {
    const { intl } = this.props;
    if (!!this.state.variants.length) {
      this.setState({
        error: ""
      }, () => {
        this.validate();
      });

    } else {
      this.setState({error: intl.formatMessage({id: "messages.needToCreateVariant"})})
    }
  };

  validate = () => {
    const {
      onDone,
    } = this.props;

    const nameError = validateField(validationRules, 'name', this.state.name);
    const typeError = validateField(validationRules, 'type', this.state.type);
    const subTypeError = validateField(validationRules, 'subType', this.state.subType);

    if (!nameError && !typeError && !subTypeError) {
      this.setState({
        nameError: "",
        typeError: "",
        subTypeError: "",
        loading: true,
      });

      let data = {
        name: this.state.name,
        type: this.state.type,
        subType: this.state.subType,
        translation: this.state.translation,
        search: this.state.search,
        description: this.state.description,
      };

      if (this.state.file) {
        data.image = this.state.file.split(',').pop();
      }

      if (this.props.product) {
        apiEditProduct(this.props.product.id, data, () => {
          onDone(true);
        })
      } else {
        data.variants = this.state.variants;
        apiAddProduct(data, () => {
          onDone(true);
        })
      }

    } else {
      this.setState({
        nameError: nameError,
        typeError: typeError,
        subTypeError: subTypeError,
        loading: false
      });
    }
  };

  handleAddSearchWord = (word) => {
    this.setState((state) => {
      state.search.push(word);
      return state;
    })
  };

  handleDeleteSearchWord = (word, index) => {
    this.setState((state) => {
      let search = [];
      state.search.forEach((item, i) => {
        if (i !== index) {
          search.push(item)
        }
      });
      state.search = search;
      return state;
    })
  };

  onVariantEdited = (data) => {
    this.setState(state => {
      state.editingVariant = null;
      for (let i = 0; i < state.variants.length; i++) {
        if (state.variants[i].id === data.id) {
          state.variants[i] = data;
          break;
        }
      }
      return state;
    });
    this.preValidate(data);
  };

  onVariantCreated = (variant) => {
    this.setState(state => {
      state.creatingVariant = false;
      state.variants.push(variant);
      state.error = "";
      return state;
    })
  };

  onUpdateProduct = (product) => {
    this.setState(state => {
      state.creatingVariant = false;
      state.editingVariant = null;
      state.variants = product.variants;
      state.variantChanged = true;
      return state;
    })
  };

  getProductForm = () => {
    const {
      title,
      buttonTitle,
      intl,
      classes,
      product,
      onDone,
    } = this.props;

    const {
      types,
      subTypes,
    } = this.state;

    return (
      <React.Fragment>

        <Icon icon={CloseIcon}
              style={{fill: 'rgba(0, 0, 0, 0.5)'}}
              className={classes.close}
              onClick={() => onDone(this.state.variantChanged)} />

        {this.state.loading && <Spinner size={40}/>}

        <AdminDialogTitle title={intl.formatMessage({id: title})} />
        <AdminDialogContent className={classes.wrapper}>

          <div className={classes.row}>
            <div className={classNames(classes.column, classes.mr40)}>
              <Image object={product}
                     base64={this.state.file}
                     onUploaded={(file) => this.setState({file: file})} />
            </div>

            <div className={classNames(classes.column, classes.mr40)}>

              <PrimaryTextField
                className={classes.input}
                error={!!this.state.nameError}
                onChange={event => this.setState({name: event.target.value})}
                value={this.state.name}
                id="name-input"
                label={intl.formatMessage({id: "product.name"})}
                type="text"
                InputLabelProps={{shrink: true}}
                helperText={this.state.nameError && intl.formatMessage({id: this.state.nameError})}
                fullWidth
              />

              <PrimarySelect
                className={classes.input}
                label={intl.formatMessage({id: "product.type"})}
                value={this.state.type}
                onChange={(e) => this.setState({type: e.target.value})}
              >
                {types.map(type => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </PrimarySelect>

              <PrimarySelect
                className={classes.input}
                label={intl.formatMessage({id: "product.subtype"})}
                value={this.state.subType}
                onChange={(e) => this.setState({subType: e.target.value})}
              >
                {subTypes.map(subType => (
                  <MenuItem key={subType} value={subType}>
                    {subType}
                  </MenuItem>
                ))}
              </PrimarySelect>

              <PrimaryTextField
                className={classes.input}
                onChange={event => this.setState({translation: event.target.value})}
                value={this.state.translation}
                id="translation-input"
                label={intl.formatMessage({id: "product.translation"})}
                type="text"
                InputLabelProps={{shrink: true}}
                fullWidth
              />

            </div>

            <div className={classes.column}>

              <PrimaryChip
                className={classes.input}
                fullWidth
                value={this.state.search}
                onAdd={(chip) => this.handleAddSearchWord(chip)}
                label={intl.formatMessage({id: "product.search"})}
                InputLabelProps={{shrink: true}}
                onDelete={(chip, index) => this.handleDeleteSearchWord(chip, index)}
              />

              <PrimaryTextField
                multiline
                rows={8}
                rowsMax={8}
                className={classes.input}
                onChange={event => this.setState({description: event.target.value})}
                value={this.state.description}
                id="search-input"
                label={intl.formatMessage({id: "product.description"})}
                type="text"
                InputLabelProps={{shrink: true}}
                fullWidth
              />

            </div>
          </div>

          {this.state.error && (
            <div className={classes.errorWrapper}>
              <ErrorBox error={this.state.error} />
            </div>
          )}

          <Variants variants={this.state.variants}
                    onUpdateVariant={(variant) => this.setState({editingVariant: variant})}
                    onCreateVariant={() => this.setState({creatingVariant: true})} />

          <div className={classes.controls}>
            <PrimaryButton onClick={this.preValidate}>
              {intl.formatMessage({id: buttonTitle})}
            </PrimaryButton>
          </div>
        </AdminDialogContent>
      </React.Fragment>
    );
  };

  render() {
    const {
      producer,
      product,
    } = this.props;

    const {
      variants,
    } = this.state;

    return (
      <React.Fragment>
        {(!this.state.editingVariant && !this.state.creatingVariant) && this.getProductForm()}

        {this.state.creatingVariant && (
          <VariantContainer title={"variant.title.create"}
                            producer={producer}
                            product={product}
                            totalVariantsCount={variants.length}
                            onUpdateProduct={this.onUpdateProduct}
                            buttonTitle={"variant.button.create"}
                            onClose={() => this.setState({creatingVariant: false})}
                            onDone={this.onVariantCreated} />
        )}

        {this.state.editingVariant && (
          <VariantContainer title={"variant.title.update"}
                            producer={producer}
                            product={product}
                            totalVariantsCount={variants.length}
                            variant={this.state.editingVariant}
                            onUpdateProduct={this.onUpdateProduct}
                            buttonTitle={"variant.button.update"}
                            onClose={() => this.setState({editingVariant: null})}
                            onDone={this.onVariantEdited} />
        )}
      </React.Fragment>
    )
  }
}

ProductContainer.propTypes = propTypes;
ProductContainer.defaultProps = defaultProps;

export default withStyles(styles, {withTheme: true})(
  injectIntl(ProductContainer)
);
