import React from "react";
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import {
  PrimarySwitch
} from "../../components";

const styles = {};

const propTypes = {
  onChange: PropTypes.func.isRequired,
  onChangeBefore: PropTypes.func,
  checked: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
};

class TableCheckbox extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      checked: this.props.checked
    };
  }

  handleChange = event => {
    event.preventDefault();
    event.stopPropagation();

    const {
      onChange,
    } = this.props;

    const checked = !this.state.checked;
    this.setState({ checked }, () => {
      onChange && onChange(checked);
    });

    return false;
  };

  render() {
    return (
      <PrimarySwitch
        checked={this.state.checked}
        onClick={this.handleChange}
        value="active"
      />
    )
  }
}

TableCheckbox.propTypes = propTypes;

export default withStyles(styles)(TableCheckbox);