import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../../../hocs';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import { toCurrency, COLORS, SHIPPING_TYPES, toCoins, parseFloatFromString } from '../../../../helpers';
import { EditableNote } from '../../../../components';
import { Order } from '../../../../entities';

const styles = {
  wrapper: {
    marginRight: 50,
  },
  paymentsTitle: {},
  row: {
    display: 'flex',
    position: 'relative',
    marginBottom: 10,
  },
  title: {
    flex: 1,
    fontSize: 16,
    fontWeight: 600,
    fontFamily: 'Roboto, sans-serif',
    color: COLORS.text,
    textAlign: 'left',
  },
  green: {
    color: COLORS.green,
  },
  titleAlign: {
    minWidth: 100,
    textAlign: 'right',
  },
  info: {
    flex: 1,
    fontSize: 16,
    fontFamily: 'Roboto, sans-serif',
    textAlign: 'right',
  },
  feeWrapper: {
    marginLeft: 10,
  },
  removeDiscount: {
    position: 'absolute',
    padding: 0,
    top: -3,
    right: -45,
  },
};

const propTypes = {
  onDeleteDiscount: PropTypes.func.isRequired,
  onPatchShippingFee: PropTypes.func.isRequired,
  wrapperClass: PropTypes.string,
  order: PropTypes.object.isRequired,
};
const defaultProps = {};

const Summary = ({ order, intl, onDeleteDiscount, onPatchShippingFee, wrapperClass, classes }) => {
  let orderEntity = new Order(order);

  return (
    <div className={classNames(classes.wrapper, wrapperClass)}>
      <div className={classes.row}>
        <span className={classNames(classes.title, classes.titleAlign)}>
          {intl.formatMessage({ id: 'global.subtotal' })}
        </span>
        <span className={classes.info}>{toCurrency(order.subtotal, intl)}</span>
      </div>
      {order.discount && order.discount.applied && (
        <div className={classes.row}>
          <span className={classNames(classes.title, classes.titleAlign)}>
            {intl.formatMessage({ id: 'global.discount' })}
          </span>
          <span className={classes.info}>-{toCurrency(order.discount.amount, intl)}</span>

          {orderEntity.isEditable() && (
            <IconButton onClick={() => onDeleteDiscount(order)} className={classes.removeDiscount}>
              <CancelIcon />
            </IconButton>
          )}
        </div>
      )}
      {order.shipping && (
        <div className={classes.row}>
          <span className={classNames(classes.title, classes.titleAlign)}>
            {intl.formatMessage({ id: 'global.delivery' })}
          </span>
          <span className={classes.info}>
            <EditableNote
              title={''}
              editable={orderEntity.isFeeEditable()}
              multiLine={false}
              wrapperClass={classes.feeWrapper}
              note={toCurrency(order.shipping.fee, intl)}
              // Format fee
              beforeSubmitFormatter={(value) => toCoins(parseFloatFromString(value))}
              // Change fee
              onChange={(fee) => onPatchShippingFee(SHIPPING_TYPES.SHIPPING_DELIVERY, { fee, feeBasedOnRates: false })}
            />
          </span>
        </div>
      )}

      {!!order.taxesTotal && (
        <div className={classes.row}>
          <span className={classNames(classes.title, classes.titleAlign)}>
            {intl.formatMessage({ id: 'global.taxes' })}
          </span>
          <span className={classNames(classes.info)}>{toCurrency(order.taxesTotal, intl)}</span>
        </div>
      )}

      <div className={classes.row}>
        <span className={classNames(classes.title, classes.titleAlign)}>
          {intl.formatMessage({ id: 'global.total' })}
        </span>
        <span className={classNames(classes.title, classes.info)}>{toCurrency(order.total, intl)}</span>
      </div>

      {order.prepurchaseOrder && order.payment && (
        <React.Fragment>
          <div style={{ marginTop: 20 }} className={classes.row}>
            <span className={classNames(classes.title, classes.titleAlign)}>
              {intl.formatMessage({ id: 'global.payments' })}:
            </span>
            <span className={classNames(classes.title, classes.info)} />
          </div>
          <div className={classes.row}>
            <span className={classNames(classes.title, classes.titleAlign, classes.green)}>
              {intl.formatMessage({ id: 'global.csa' })}: {order.payment.name || ''}
            </span>
            <span className={classNames(classes.info, classes.green)}>{toCurrency(order.payment.amount, intl)}</span>
          </div>
          <div className={classes.row}>
            <span className={classNames(classes.title, classes.titleAlign)}>
              {intl.formatMessage({ id: 'global.amountDue' })}
            </span>
            <span className={classes.info}>{toCurrency(order.amountDue, intl)}</span>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

Summary.propTypes = propTypes;
Summary.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(injectIntl(withRouter(Summary)));
