import React from "react";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import {Icon} from '../../components';
import styles from './styles';

const propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.func.isRequired,
};

const IconWithTextButton = (props) => {
  const {icon, label, className, classes, onClick, ...other} = props;
  return (
    <Button className={classNames(classes.button, className)} onClick={onClick} {...other}>
      <Icon icon={icon}/>&nbsp;{label}
    </Button>
  )
};

IconWithTextButton.propTypes = propTypes;

export default withStyles(styles)(IconWithTextButton);
