/** Represents UI tab */
class Tab {
  constructor(props = {}) {
    this.id = props.id;
    this.name = props.name;
    this.type = props.type;

    this.default = props.default || false;
    this.excludeColumns = props.excludeColumns || [];
    this.filters = props.filters || [];
    this.groupBy = props.groupBy || [];
    this.detailColumns = props.detailColumns || [];
    this.sorting = props.sorting || [];
    this.columnsWidth = props.columnsWidth || [];

    if (props.type === 'reports') {
      this.reports = props.reports || [];
    }
  }
}

export default Tab;
