import React from 'react';
import Typography from '@material-ui/core/Typography';

const Production = () => (
	<Typography style={{padding: 20}} paragraph>
		Release version v{process.env.REACT_APP_VERSION}
	</Typography>
);

export default Production;

