import React from 'react';
import { useIntl } from 'react-intl';
import Tab from './Components/Tab';
import { AdminDXTable } from '../../components';
import { HEADER_HEIGHT_STANDARD, CUSTOMER_TYPE } from '../../helpers';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import LinksActionCell from './Cells/LinksActionCell';

const LinksTab = () => {
  const intl = useIntl();
  const columns = [
    { name: 'name', title: intl.formatMessage({ id: 'global.name' }) },
    { name: 'actions', title: intl.formatMessage({ id: 'global.actions' }) },
  ];

  const getRows = (filters, onSuccess) => {
    onSuccess([
      {
        id: 'retail-link',
        name: intl.formatMessage({ id: 'producer.tab.links.retailLink' }),
        type: CUSTOMER_TYPE.RETAIL,
      },
      {
        id: 'wholesale-link',
        name: intl.formatMessage({ id: 'producer.tab.links.wholesaleLink' }),
        type: CUSTOMER_TYPE.WHOLESALE,
      },
    ]);
  };

  return (
    <Tab title={intl.formatMessage({ id: 'producer.tab.links' })} style={{ width: 'auto' }}>
      <AdminDXTable
        enableSearch={false}
        enablePager={false}
        apiRetrieve={getRows}
        columns={columns}
        columnExtensions={[{ columnName: 'name', width: 200 }]}
        cellRenderer={(reload, { row, ...props }) => {
          if (props.column.name === 'actions') {
            return <LinksActionCell customerType={row.type} />;
          }
          return <Table.Cell {...props} />;
        }}
        stickyHeader
        stickyHeaderOffset={HEADER_HEIGHT_STANDARD.standard}
      />
    </Tab>
  );
};

export default LinksTab;
