import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../hocs';
import { AdminLayout, AdminTabs, Spinner } from '../../components';
import { SidebarMenu, HeaderMenu } from '../Partials';
import BaseDialog from '../../dialogs/BaseDialog';
import TabTypes from '../../dialogs/SaveTabSettingsDialog/Types';
import { setProducer } from '../../store/actions';
import { MAIN_TAB_NAMES } from '../../helpers/tabs';
import SubscriptionsContent from './SubscriptionsContent';
import styles from './styles';
import { apiCreateTab, apiDeleteTab, apiPatchTab } from '../../api';
import { SubscriptionForm } from '../../forms';
import SubscriptionsReportsContent from './SubscriptionsReportsContent';

class SubscriptionsScene extends React.Component {
  constructor(props) {
    super(props);

    const { tabs } = this.props;

    this.state = {
      tabIndex: tabs.getInitialIndex(window.location.hash, true),
    };
  }

  back = (event, reason) => {
    if (reason !== 'backdropClick') {
      this.props.history.replace(`/subscriptions${this.props.location.hash}`);
    }
  };

  onCreateTab = (data, onDone) => {
    this.setState({ loading: true });
    apiCreateTab(
      data,
      (producer) => {
        this.props.setProducer(producer);
        this.setState((state) => {
          state.loading = false;
          onDone && onDone();
          return state;
        });
      },
      undefined
    );
  };

  onDeleteTab = (tab) => {
    this.setState({ loading: true });
    apiDeleteTab(
      tab.id,
      (producer) => {
        this.setState(
          {
            tabIndex: 0,
            loading: false,
          },
          () => {
            this.props.setProducer(producer);
          }
        );
      },
      undefined
    );
  };

  onPatchTab = (tab, data, onDone) => {
    this.setState({ loading: true });
    apiPatchTab(
      tab.id,
      data,
      (producer) => {
        this.props.setProducer(producer);
        this.setState({ loading: false });
        onDone && onDone();
      },
      undefined
    );
  };

  onPatchCustomTab = (tab, data, onDone) => {
    if (tab) {
      this.onPatchTab(tab, data, onDone);
    } else {
      data.name = MAIN_TAB_NAMES.SUBSCRIPTIONS_ALL;
      data.type = TabTypes.SUBSCRIPTIONS;
      this.onCreateTab(data);
    }
  };

  onPatchSubscriptionTab = (tab, data, onDone) => {
    const { tabs } = this.props;
    let subscriptionsTab = tabs.getTabByName(MAIN_TAB_NAMES.SUBSCRIPTIONS_ALL);

    if (subscriptionsTab) {
      this.onPatchTab(subscriptionsTab, data, onDone);
    } else {
      data.name = MAIN_TAB_NAMES.SUBSCRIPTIONS_ALL;
      data.type = TabTypes.SUBSCRIPTIONS;
      this.onCreateTab(data);
    }
  };
  onPatchSubscriptionReportsTab = (tab, data, onDone) => {
    const { tabs } = this.props;
    let subscriptionsTab = tabs.getTabByName(MAIN_TAB_NAMES.SUBSCRIPTIONS_REPORTS);

    if (subscriptionsTab) {
      this.onPatchTab(subscriptionsTab, data, onDone);
    } else {
      data.name = MAIN_TAB_NAMES.SUBSCRIPTIONS_REPORTS;
      data.type = TabTypes.SUBSCRIPTIONS;
      this.onCreateTab(data);
    }
  };

  onChangeTab = (index) => {
    const { tabs } = this.props;
    window.location.hash = tabs.getHashByIndex(index);
    this.setState({ tabIndex: index });
  };

  getTabs() {
    const { tabIndex } = this.state;
    const { intl, tabs } = this.props;

    const tabsTitles = tabs.getTabNames([
      intl.formatMessage({ id: 'global.all' }),
      intl.formatMessage({ id: 'global.reports' }),
    ]);
    const customTabs = tabs.getOnlyCustom();
    let subscriptionsTab = tabs.getTabByName(MAIN_TAB_NAMES.SUBSCRIPTIONS_ALL, tabsTitles[0], {
      id: MAIN_TAB_NAMES.SUBSCRIPTIONS_ALL,
      name: intl.formatMessage({ id: 'global.subscriptions' }),
    });
    let subscriptionReportTab = tabs.getTabByName(MAIN_TAB_NAMES.SUBSCRIPTIONS_REPORTS, tabsTitles[1], {
      id: MAIN_TAB_NAMES.SUBSCRIPTIONS_REPORTS,
      name: intl.formatMessage({ id: 'global.reports' }),
    });

    return (
      <AdminTabs onChangeTab={this.onChangeTab} tab={tabIndex} tabs={tabsTitles}>
        {tabIndex === 0 && (
          <SubscriptionsContent
            tab={subscriptionsTab}
            onPatchTab={this.onPatchSubscriptionTab}
            hiddenColumnNames={[]}
            allowCreateTab
            onCreateTab={this.onCreateTab}
            onRef={(tab) => (this.tab = tab)}
            key={'subscriptions-all'}
            index={'subscriptions-all'}
            withFilters
            appliedFilters={subscriptionsTab.filters || []}
            openFilterDialog={() => this.setState({ filterDialog: true })}
            isFiltersDialogActive={this.state.filterDialog}
            onCloseFilterDialog={() => this.setState({ filterDialog: false })}
          />
        )}

        {tabIndex === 1 && (
          <SubscriptionsReportsContent
            tab={subscriptionReportTab}
            onPatchTab={this.onPatchSubscriptionReportsTab}
            hiddenColumnNames={[]}
            onCreateTab={this.onCreateTab}
            onRef={(tab) => (this.tab = tab)}
            key={'subscriptions-reports'}
            index={'subscriptions-reports'}
            withFilters
            appliedFilters={subscriptionReportTab.filters || []}
            isFiltersDialogActive={this.state.filterDialog}
          />
        )}

        {!!customTabs.length && tabIndex > 1 && (
          <SubscriptionsContent
            tab={customTabs[tabIndex - 2]}
            contentTag={'subscriptionsTabContent'}
            showTabDialog={true}
            allowCreateTab
            onCreateTab={this.onCreateTab}
            onPatchTab={this.onPatchCustomTab}
            onDeleteTab={this.onDeleteTab}
            onRef={(tab) => (this.tab = tab)}
            key={`custom-${tabIndex}`}
            index={`custom-${tabIndex}`}
            withFilters
            appliedFilters={customTabs[tabIndex - 2].filters || []}
          />
        )}
      </AdminTabs>
    );
  }

  render() {
    const { loading } = this.state;
    const { intl, openItemModal } = this.props;

    return (
      <AdminLayout sidebarComponent={SidebarMenu} headerMenuComponent={HeaderMenu}>
        {loading && <Spinner size={60} />}
        {this.getTabs()}

        {openItemModal && (
          <BaseDialog
            scroll={'paper'}
            maxWidth="xl"
            open
            onClose={this.back}
            title={
              this.props.match.params.id === 'new'
                ? intl.formatMessage({ id: 'subscriptions.addingNew' })
                : intl.formatMessage({ id: 'subscriptions.editSbs' })
            }>
            <SubscriptionForm
              match={this.props.match}
              producer={this.props.producer}
              onSuccess={() => {
                this.back();
                setTimeout(() => {
                  this.tab.forceTableReload();
                }, 200);
              }}
            />
          </BaseDialog>
        )}
      </AdminLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  tabs: state.producer.tabs[TabTypes.SUBSCRIPTIONS],
  producer: state.producer.object,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setProducer: (producer) => dispatch(setProducer(producer)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(withStyles(styles)(SubscriptionsScene))));
