export function onEnterPress(event, action = () => {}) {
  if (event.key === 'Enter' || event.keyCode === 13) {
    action();
  }
}

export function preventClickDefault(event) {
  event?.preventDefault && event.preventDefault();
  event?.stopPropagation && event.stopPropagation();
}
