import React from 'react';
import { COLORS } from '../constants/colors';

const Minus = (props) => {
  const { color = COLORS.blackTintedBlue, ...other } = props;
  return (
    <svg width="15" height="3" viewBox="0 0 15 3" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <path d="M14.5001 2.5H0.500061V0.5H14.5001V2.5Z" fill={color} />
    </svg>
  );
};

export default Minus;
