import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Icon from '../../components/Icon/Icon';
import CloseIcon from '@material-ui/icons/Close';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import {
  AdminDialog,
  AdminDialogContent,
  AdminDialogControls,
  AdminDialogTitle,
  ErrorBox,
  PrimaryButton,
  PrimarySelect,
  Spinner,
} from '../../components';
import styles from './styles';
import { PRICE_LEVELS } from '../../helpers';
import {
  apiPriceAllAvailableLocationsGet,
  apiPriceBaseLocationsGet,
  apiPriceSet
} from '../../api';

const propTypes = {
  onClose: PropTypes.func,
	onCreated: PropTypes.func,
};

const defaultProps = {};

class PriceDialog extends Component {

	state = {
		loading: true,
		type: PRICE_LEVELS.RETAIL_MARKET,

		location: '',
		baseLocation: -1,

		availableLocations: [],
		baseLocations: [],
		error: '',
	};

  componentDidMount() {
    this.getAvailableLocations();
    this.getBaseLocations();
  }
  
  getAvailableLocations = () => {
    const { type } = this.state;

		apiPriceAllAvailableLocationsGet(type)
			.then(response => {
				this.setState({
					availableLocations: response,
					...(!!response.length ? {location: response[0].id} : {location: ''}),
					loading: false
				});
			})
			.catch((error) => {
				this.setState({
					loading: false,
					error: error.message
				});
			});
  };
  
  getBaseLocations = () => {
    const {
    	type,
    } = this.state;

		apiPriceBaseLocationsGet(type)
			.then((response) => {
				this.setState({
					baseLocations: response,
					baseLocation: -1,
					loading: false
				});
			})
			.catch((error) => {
				this.setState({
					loading: false,
					error: error.message
				});
			});
  };
  
  changePriceType = (e) => {
    this.setState({
      type: e.target.value,
      loading: true
    }, () => {
      this.getAvailableLocations();
      this.getBaseLocations();
    });
  };
  
  changeLocation = (e) => {
    this.setState({location: e.target.value});
  };
  
  changeBaseLocation = (e) => {
    this.setState({baseLocation: e.target.value});
  };
  
  onComplete = () => {
    const {
    	props: {
    		onClose,
				onCreated
			},
			state: {
				type,
				location,
				baseLocation
			}
		} = this;

    const base = baseLocation !== -1
			? baseLocation
			: '';

    this.setState({loading: true});
    apiPriceSet(type, location, base, () => {
			onClose && onClose();
			onCreated && onCreated();
    }, (error) => {
      this.setState({ error: error.message });
    })
  };
  
  render() {
    const {
      props: {
				intl,
				classes,
				onClose,
			},
			state: {
				availableLocations,
				baseLocations,
				error
			}
    } = this;

    
    return (
      <AdminDialog open
                   paperClass={classes.paper}
                   onClose={onClose}>
        
        <Icon icon={CloseIcon}
              style={{fill: 'rgba(0, 0, 0, 0.5)'}}
              className={classes.close}
              onClick={onClose}
        />

        <AdminDialogTitle title={intl.formatMessage({id: 'prices.title.create'})}/>

				{this.state.loading && (
					<div style={{margin: 20}}>
						<Spinner size={40}/>
					</div>
				)}


				<AdminDialogContent className={classes.wrapper}>
					{!this.state.loading && (
						<>
							<ErrorBox error={error}
												className={classes.error}
												onClose={() => this.setState({error: ''})}
							/>

							<PrimarySelect
								formControlClassName={classes.inputWrapper}
								label={intl.formatMessage({id: 'prices.select.priceType'})}
								value={this.state.type}
								onChange={this.changePriceType}
							>
								{Object.values(PRICE_LEVELS).map(type => (
									<MenuItem key={type} value={type}>
										{intl.formatMessage({id: `prices.${type}`})}
									</MenuItem>
								))}
							</PrimarySelect>

							{!!availableLocations.length && (
								<PrimarySelect
									formControlClassName={classes.inputWrapper}
									label={intl.formatMessage({id: 'prices.select.location'})}
									value={this.state.location || availableLocations[0].id}
									onChange={this.changeLocation}
								>
									{availableLocations.map(location => (
										<MenuItem key={location.id} value={location.id}>
											{location.name}
										</MenuItem>
									))}
								</PrimarySelect>
							)}

							{!!availableLocations.length && (
								<PrimarySelect
									formControlClassName={classes.inputWrapper}
									label={intl.formatMessage({id: 'prices.select.locationBased'})}
									value={this.state.baseLocation}
									onChange={this.changeBaseLocation}
								>
									<MenuItem key={'default-price'} value={-1}>
										{intl.formatMessage({id: 'prices.baseOnDefaultPrice'})}
									</MenuItem>

									{baseLocations.map(location => (
										<MenuItem key={location.id} value={location.id}>
											{location.name}
										</MenuItem>
									))}
								</PrimarySelect>
							)}

							{!!!availableLocations.length && (
								<Typography align={"center"} variant={"body2"} className={classes.message}>
									{intl.formatMessage({id: 'prices.noLocations'})}
								</Typography>
							)}
						</>
					)}
				</AdminDialogContent>

				{!this.state.loading && !!availableLocations.length && (
					<AdminDialogControls>
						<PrimaryButton onClick={this.onComplete}>
							{intl.formatMessage({id: 'global.create'})}
						</PrimaryButton>
					</AdminDialogControls>
				)}

      </AdminDialog>
    );
  }
}


PriceDialog.propTypes = propTypes;
PriceDialog.defaultProps = defaultProps;

export default withStyles(styles, {withTheme: true})(
  injectIntl(PriceDialog)
);