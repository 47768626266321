import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { FONT_QUANTITY } from '../../constants/fonts';
import { COLORS } from '../../constants/colors';
import { BORDER_RADIUS_MAIN, NO_KEYBOARD_CLOSE_DATA_ATTRIBUTE } from '../../constants/globals';
import classNames from 'classnames';
import { getValueForInventoryQuantity } from '../../helpers/convertors';
import { isTouchableDevice, preventClickDefault, scrollToElement } from '../../helpers/global';
import { useDispatch, useSelector } from 'react-redux';
import { setKeyboardItemToEdit, setQuantityOverwrite, updateKeyboardItem } from '../../../../store/actions';
import KeyboardEntity from '../../entities/keyboardEntity';
import { HEIGHT_OF_HEADER_AREA } from '../HeaderArea';
import { weightToUser } from '../../../../helpers';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    ...FONT_QUANTITY,
    height: 44,
    minWidth: 44,
    backgroundColor: COLORS.backgroundLight,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: BORDER_RADIUS_MAIN,
    boxSizing: 'border-box',
    color: COLORS.text,
    marginRight: 5,
  },

  inputWrapper: {
    paddingLeft: 5,
    paddingRight: 5,
    minWidth: 70,
    maxWidth: 150,
    width: 'auto',
    textAlign: 'center',
    height: 44,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textFieldRoot: {},
  input: {
    ...FONT_QUANTITY,
    color: COLORS.darkBlue,

    textAlign: 'center',
    borderBottom: 0,
    '&:-webkit-outer-spin-button, &:-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
    '-moz-appearance': 'none',
  },
  inputRoot: {
    width: 70,
    textAlign: 'center',
    borderBottom: 0,
    fontSize: 24,
    userSelect: 'text',

    '&:after, &:before': {
      display: 'none',
      border: 0,
    },
  },

  pseudoInput: {
    minWidth: 70,
    maxWidth: 150,
    width: 'auto',
    textAlign: 'center',
    borderBottom: 0,
    fontSize: 24,
    userSelect: 'text !important',
    overflowX: 'auto',
  },
  disabled: {
    cursor: 'text',
    opacity: 0.5,
  },
  textSelectionBlock: {},
  textSelectionBlockSelected: {
    backgroundColor: COLORS.textSelection,
  },
}));

const propTypes = {
  initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onDone: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
};

const QuantityBoxSwitcher = (props) => {
  const { initialValue, onDone, isUpdating, item, product } = props;
  const classes = useStyles();
  const [value, setValue] = useState(initialValue);
  const [isSelected, setIsSelected] = useState(false);
  const quantityBoxRef = useRef(null);

  const globalOverwrite = useSelector((state) => state.supplier.overwrite);
  const keyboardObj = useSelector((state) => state.supplier.keyboardObj);

  useEffect(() => {
    if (!globalOverwrite) {
      setIsSelected(false);
    }
    if (item.id !== keyboardObj?.itemToEdit?.id) {
      setIsSelected(false);
    }
  }, [globalOverwrite, keyboardObj]);

  const dispatch = useDispatch();

  const setKeyboard = (keyboardObj) => dispatch(setKeyboardItemToEdit(keyboardObj));
  const updateKeyBoard = (updateKeyboardObj) => dispatch(updateKeyboardItem(updateKeyboardObj));
  const setOverwrite = (isSet) => dispatch(setQuantityOverwrite(isSet));

  const onChange = (event) => {
    setValue(getValueForInventoryQuantity(event));
  };

  const onFinishEditing = (forceValue, forceInitialValue) => {
    let newValue = value;
    if (value < 0 || !value) newValue = 0;
    if (isNaN(value)) newValue = initialValue;
    if (forceValue !== undefined) newValue = forceValue;
    if (parseInt(forceValue) === parseInt(forceInitialValue) && forceValue !== undefined) return false;

    if (parseInt(initialValue) === parseInt(newValue)) {
      setValue(initialValue);
      return;
    }

    onDone(
      newValue,
      (response) => {
        const updatedVariant = getVariantById(response, item.id);

        updateKeyBoard({
          initialQuantity: weightToUser(updatedVariant?.inventory?.quantity || 0),
        });
      },
      (prevValue) => {
        setValue(prevValue);
      }
    );
  };

  const getVariantById = (product, variantId) => {
    return product.variants.find((variant) => variant.id === variantId);
  };

  const showKeyboard = (newItem) => {
    setKeyboard(
      new KeyboardEntity({
        isOpen: true,
        componentRef: quantityBoxRef,
        itemToEdit: { ...newItem, name: makeItemName(), itemDomId: `${product.id}_${item.id}` },
        onDoneAction: (newValue, initValue) => {
          onFinishEditing(newValue, initValue);
        },
        onChangeQuantity: (newValue) => setValue(newValue),
        initialQuantity: weightToUser(newItem?.inventory?.quantity || 0),
      })
    );
  };

  const makeItemName = () => {
    return `${!!product?.name ? product.name : ''}${!!product?.name && !!item.name && ' / '}${item.name}`;
  };

  const toggleSelection = (forceSelection) => {
    setIsSelected(forceSelection);

    setOverwrite(forceSelection);
  };

  return (
    <>
      <div className={classNames([classes.root, 'quantityBoxSwitcherRoot'])}>
        <div className={classes.inputWrapper}>
          {isTouchableDevice() ? (
            <div
              className={classNames([classes.pseudoInput, isUpdating && classes.disabled, 'noScrollbar'])}
              id={`${product.id}_${item.id}`}
              ref={quantityBoxRef}
              onContextMenu={preventClickDefault}
              onClick={() => {
                if (isUpdating) return;
                scrollToElement(quantityBoxRef, HEIGHT_OF_HEADER_AREA);

                if (item.id !== keyboardObj?.itemToEdit?.id) {
                  toggleSelection(true);
                } else {
                  toggleSelection(!globalOverwrite);
                }

                showKeyboard(item);
              }}
              {...{ 'data-keyboard': NO_KEYBOARD_CLOSE_DATA_ATTRIBUTE.keyboardNoClose }}>
              <span
                className={classNames([classes.textSelectionBlock, !!isSelected && classes.textSelectionBlockSelected])}
                {...{ 'data-keyboard': NO_KEYBOARD_CLOSE_DATA_ATTRIBUTE.keyboardNoClose }}>
                {value}
              </span>
            </div>
          ) : (
            <TextField
              ref={quantityBoxRef}
              disabled={isUpdating}
              classes={{
                root: classes.textFieldRoot,
              }}
              className={classes.input}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                classes: { root: classes.inputRoot, input: classNames([classes.input, 'hideArrow']) },
                inputProps: {
                  min: 0,
                  step: 1,
                },
                readOnly: isTouchableDevice(),
              }}
              value={value}
              onBlur={() => {
                !isTouchableDevice() && onFinishEditing();
              }}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  document?.activeElement?.blur();
                }
              }}
              onFocus={(event) => {
                preventClickDefault(event);
                // Force select input
                event?.target?.select();
                // Clear 0 value on focus
                value === 0 && setValue('');
              }}
              onChange={onChange}
            />
          )}
        </div>
      </div>
    </>
  );
};

QuantityBoxSwitcher.propTypes = propTypes;

export default QuantityBoxSwitcher;
