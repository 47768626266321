import React, { useEffect, useState, useMemo } from 'react';
import { Title, HeaderArea, ItemsList, Footer } from '../../components';
import { makeStyles } from '@material-ui/core/styles';
import {
  PADDING_CONTENT,
  PADDING_CONTENT_TOP,
  CUSTOMER_TYPES,
  ORDER_DATE_FORMAT,
  ORDERS_PER_PAGE,
  SHIPPING_TYPES,
} from '../../constants/globals';
import { useIntl } from 'react-intl';
import { FONT_CART_VARIANT_TITLE, FONT_SUB_TITLE } from '../../constants/fonts';
import { COLORS } from '../../constants';
import { apiGetPickupsOrders } from '../../api';
import moment from 'moment';
import { withRouter } from '../../hocs';
import ProductPagination from '../../components/ProductPagination';
import { PaginationMeta } from '../../entities/PaginationMeta';
import { useDispatch, useSelector } from 'react-redux';
import { setPickupsLoading } from '../../store/actions';
import { OrdersSkeleton } from '../../components/SkeletonTemplates';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  content: {
    paddingLeft: PADDING_CONTENT,
    paddingRight: PADDING_CONTENT,
    paddingTop: PADDING_CONTENT_TOP,
  },
  title: {
    marginBottom: 10,
  },
  subTitle: {
    ...FONT_SUB_TITLE,
    marginBottom: 20,
  },
  buttonWrapper: {
    marginBottom: 20,
    paddingLeft: PADDING_CONTENT,
    paddingRight: PADDING_CONTENT,
  },
  error: {
    ...FONT_CART_VARIANT_TITLE,
    color: COLORS.danger,
    paddingBottom: 20,
    paddingTop: 20,
  },
}));

const OrdersScene = (props) => {
  const { history } = props;
  const intl = useIntl();
  const classes = useStyles();
  const { day, location } = props.params;

  const [initialLoading, setInitialLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState('');
  const [meta, setMeta] = useState(new PaginationMeta());
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const onLoading = (isLoading) => dispatch(setPickupsLoading(isLoading));
  const { loading } = useSelector((state) => {
    return {
      loading: state.pickupsApp.loading,
    };
  });

  useEffect(
    () => {
      onLoading(false);

      getOrders();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getOrders = () => {
    setInitialLoading(true);

    const params = {
      'shipping:from': moment(day).startOf('day').toISOString(true),
      'shipping:to': moment(day).endOf('day').toISOString(true),
      locationId: location,
      orderType: 'standard',
      'shippingType[]': SHIPPING_TYPES.SHIPPING_PICKUP,
      withLineItems: true,
    };

    apiGetPickupsOrders(
      params,
      (response) => {
        setOrders(response);
        setMeta(
          new PaginationMeta({
            limit: ORDERS_PER_PAGE,
            skip: 0,
            total: response.length,
            hasNext: currentPage * ORDERS_PER_PAGE < response.length,
          })
        );

        setInitialLoading(false);
      },
      (error) => {
        setInitialLoading(false);
        setError(error?.response?.data?.message || intl.formatMessage({ id: 'pickups.somethingWentWrong' }));
      }
    );
  };

  const onPageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const getPageContent = useMemo(
    () => () => {
      const from = (currentPage - 1) * ORDERS_PER_PAGE;
      const to = currentPage * ORDERS_PER_PAGE;

      return orders.slice(from, to);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage, orders?.length]
  );

  const drawDate = (date) => {
    return moment.parseZone(date).format(ORDER_DATE_FORMAT);
  };

  const drawCustomerName = (order) => {
    if (order?.customer?.type === CUSTOMER_TYPES.WHOLESALE) return order.customer.companyName;
    if (order?.customer?.type === CUSTOMER_TYPES.ANONYMOUS)
      return intl.formatMessage({ id: 'pickups.sales.anonymous' });

    return `${order.customer.firstName ? order.customer.firstName + ' ' : ''}${
      order.customer.lastName ? order.customer.lastName : ''
    }`;
  };

  const drawNumberOfItems = (order) => {
    const numberOfItems = order?.lineItems?.length || 0;

    if (numberOfItems < 2)
      return intl.formatMessage({ id: 'pickups.sales.numberOfItemsSingle' }, { amount: numberOfItems });

    return intl.formatMessage({ id: 'pickups.sales.numberOfItems' }, { amount: numberOfItems });
  };

  const itemBodyContentRenderer = (order) => {
    return {
      title: drawCustomerName(order),
      content: `${drawNumberOfItems(order)}`,
    };
  };

  if (loading) {
    return null;
  }

  return (
    <div className={classes.root}>
      <HeaderArea onlyBackButton />

      {!initialLoading ? (
        <div className={classes.content}>
          <Title className={classes.title} text={intl.formatMessage({ id: 'pickups.orders.title' })} />
          <p className={classes.subTitle}>{drawDate(day)}</p>

          {!!error && <p className={classes.error}>{error}</p>}
          {!error && (
            <>
              <ItemsList
                items={getPageContent()}
                itemBodyContentRenderer={itemBodyContentRenderer}
                onItemClick={(order) => history.push(`/app/pickups/orders/${order?.id}`)}
                emptyMessage={intl.formatMessage({ id: 'deliveries.orders.ordersNotFound' })}
              />
              <ProductPagination meta={meta} page={currentPage} onChange={onPageChange} />
            </>
          )}
        </div>
      ) : (
        <OrdersSkeleton />
      )}

      <Footer />
    </div>
  );
};

export default withRouter(OrdersScene);
