import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import classNames from 'classnames';

class SceneTitle extends React.Component {
  render() {
    const { classes, className } = this.props;
    return <h1 className={classNames([classes.title, className])}>{this.props.children}</h1>;
  }
}

export default withStyles(styles)(SceneTitle);
