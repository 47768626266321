import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import DXSelectFilter from '../DXSelectFilter/DXSelectFilter'
import DXFilterConfig from '../DXFilterConfig'

const propTypes = {
  equality: PropTypes.string,
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  selected: [],
};

class DXQBReportDiffFilter extends React.Component {

  render() {
    const { selected, onChange, intl, equality} = this.props;
    const config = DXQBReportDiffFilter.getConfig(intl, equality);
    return (
      <DXSelectFilter selected={selected}
                      title={config.title}
                      values={config.values}
                      onChange={onChange}
      />
    )
  }
}

DXQBReportDiffFilter.propTypes = propTypes;
DXQBReportDiffFilter.defaultProps = defaultProps;
DXQBReportDiffFilter.getLabel = (intl, selectedValues) => {
  let label = "";
  if (Array.isArray(selectedValues) && selectedValues.length) {
    const config = DXQBReportDiffFilter.getConfig(intl);
    const values = config.values;
    let parts = [];

    selectedValues.forEach(value => {
      const translation = values.filter(item => item.key === value);
      if (translation.length) {
        parts.push(translation[0].label)
      }
    });
    label = parts.join(', ');
  }
  return label;
};
DXQBReportDiffFilter.applyToQueryParams = (queryParams, selectedValues) => {
  if (selectedValues.length) {
    queryParams[`qBDiff`] = selectedValues[0] === 'exists';
  }
  return queryParams;
};
DXQBReportDiffFilter.getConfig = (intl) => {
  let config = new DXFilterConfig({
    name: 'report.qb.diff',
    selected: [],
    property: 'qbDiff',
    title: intl.formatMessage({id: 'filter.report.qb.diff'}),
    disabled: false,
    equalityStatus: false,
  });

  config.addFilterValue('exists', intl.formatMessage({id: 'filter.exists'}));
  config.addFilterValue('not_exists', intl.formatMessage({id: 'filter.notExists'}));

  return config;
};

export default injectIntl(
  DXQBReportDiffFilter
);
