import React, { useEffect, useState } from 'react';
import { HeaderArea, Title } from '../../components';
import { withRouter } from '../../hocs';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants';
import {
  BORDER_RADIUS_MAIN,
  ORDER_DATE_FORMAT,
  PADDING_CONTENT,
  PADDING_CONTENT_BOTTOM,
  PADDING_CONTENT_TOP,
  SHIPPING_TYPES,
} from '../../constants/globals';
import moment from 'moment';
import { getCustomerName, makeStringAddress } from '../../helpers/global';
import { toCurrency, weightToUser } from '../../helpers';
import EmptyImage from '../../assets/no.jpg';
import {
  FONT_CART_PRODUCT,
  FONT_CART_VARIANT_SUB,
  FONT_CART_VARIANT_TITLE,
  FONT_FAMILY,
  FONT_ORDER_NAME,
  FONT_QUANTITY,
  FONT_SIZES,
} from '../../constants/fonts';
import { apiGetDeliveryOrderById } from '../../api';
import { useDispatch, useSelector } from 'react-redux';
import { setDeliveriesLoading } from '../../store/actions';
import OrderSkeleton from '../../components/SkeletonTemplates/orderSkeleton';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: COLORS.lightBg,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 1,
  },
  content: {
    boxSizing: 'border-box',
    paddingLeft: PADDING_CONTENT,
    paddingRight: PADDING_CONTENT,
    maxWidth: 900,
    textAlign: 'left',
    width: '100%',
    backgroundColor: 'white',
    marginBottom: 20,
    paddingTop: PADDING_CONTENT_TOP,
    paddingBottom: PADDING_CONTENT_BOTTOM,
    minHeight: '100%',
  },
  title: {
    marginBottom: 20,
    textAlign: 'left',
  },

  itemsTotalWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
    marginTop: 20,
  },
  itemsTotalCount: {
    ...FONT_CART_PRODUCT,
    flex: 1,
    color: COLORS.blackTintedBlue,
  },
  itemsTotalAmount: {
    ...FONT_CART_PRODUCT,
    textAlign: 'right',
    paddingLeft: 20,
    color: COLORS.blackTintedBlue,
  },

  orderWrapper: {},
  orderCustomerInfoWrapper: {
    marginBottom: 20,
  },
  orderCustomerInfoItem: {
    marginBottom: 5,
  },
  orderCustomerInfoFewItems: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  orderCustomerInfoText: {
    ...FONT_ORDER_NAME,
    color: COLORS.grey,
  },
  orderCustomerInfoTextTitle: {
    fontSize: FONT_SIZES.textHeader,
    fontFamily: FONT_FAMILY,
    fontWeight: '700',
    color: COLORS.text,
  },
  itemsWrapper: {},

  lineItemWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: BORDER_RADIUS_MAIN,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 5,
    paddingRight: 5,
    marginLeft: -5,
    marginRight: -5,
    backgroundColor: COLORS.white,
    transition: 'background-color 0.3s ease-in-out',
    // cursor: 'pointer',
    '&:hover': {
      backgroundColor: COLORS.backgroundLight,
    },
  },
  lineItemImage: {
    width: 56,
    height: 56,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: BORDER_RADIUS_MAIN,
    marginRight: 10,
  },
  itemImage: {
    position: 'absolute',
    maxHeight: 60,
  },
  lineItemContent: {
    flex: 1,
  },
  lineItemName: {
    ...FONT_CART_VARIANT_TITLE,
    marginBottom: 5,
    color: COLORS.blackTintedBlue,
  },
  lineItemPrice: {
    ...FONT_CART_VARIANT_SUB,
    color: COLORS.grey,
  },
  lineItemOrdered: {
    ...FONT_QUANTITY,
    paddingLeft: 20,
    color: COLORS.blackTintedBlue,
  },
  error: {
    ...FONT_CART_VARIANT_TITLE,
    color: COLORS.danger,
    paddingBottom: 20,
    paddingTop: 20,
  },
}));

const OrderScene = (props) => {
  const { match } = props;
  const { orderId } = match.params;

  const classes = useStyles();
  const intl = useIntl();

  const [order, setOrder] = useState(null);
  const [error, setError] = useState('');
  const [initialLoading, setInitialLoading] = useState(true);

  const dispatch = useDispatch();
  const onLoading = (isLoading) => dispatch(setDeliveriesLoading(isLoading));
  const { loading } = useSelector((state) => {
    return {
      loading: state.deliveriesApp.loading,
    };
  });

  useEffect(
    () => {
      onLoading(false);
      getOrder();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getOrder = () => {
    setInitialLoading(true);
    apiGetDeliveryOrderById(
      orderId,
      (response) => {
        setOrder(response);
        setInitialLoading(false);
      },
      handleAnyError
    );
  };

  const handleAnyError = (error) => {
    setError(error?.response?.data?.message || intl.formatMessage({ id: 'deliveries.somethingWentWrong' }));
    setInitialLoading(false);
  };

  const drawLineItem = (lineItem) => {
    return (
      <div className={classes.lineItemWrapper} key={lineItem.id}>
        <div className={classes.lineItemImage}>{drawImage(lineItem)}</div>

        <div className={classes.lineItemContent}>
          <p className={classes.lineItemName}>{getProductName(lineItem)}</p>
          <p className={classes.lineItemPrice}>{getPriceAndUnit(lineItem)}</p>
        </div>

        <p className={classes.lineItemOrdered}>{weightToUser(lineItem.orderedQuantity)}</p>
      </div>
    );
  };

  const getProductName = (lineItem) => {
    if (lineItem.name.variant === lineItem.unit) return lineItem.name.product;

    if (typeof lineItem.name === 'string') return lineItem.name;

    return `${lineItem.name.product}${!!lineItem?.name?.variant ? ' / ' + lineItem.name.variant : ''}`;
  };

  const drawImage = (item) => {
    if (!!item?.image?.thumbSrc) {
      return <img className={classes.itemImage} src={item.image.thumbSrc} alt={item.name} />;
    } else {
      return <img className={classes.itemImage} src={EmptyImage} alt={item.name} />;
    }
  };

  const getPriceAndUnit = (variant) => {
    return `${toCurrency(variant.unitPrice, intl)} / ${weightToUser(variant.unitQuantity, true)} ${variant.unit}`;
  };

  const drawNumberOfItems = (numberOfItems) => {
    if (numberOfItems < 2)
      return intl.formatMessage({ id: 'deliveries.sales.numberOfItemsSingle' }, { amount: numberOfItems });

    return intl.formatMessage({ id: 'deliveries.sales.numberOfItems' }, { amount: numberOfItems });
  };

  const drawCustomerInfo = () => {
    const { customer } = order;

    return (
      <>
        <div className={classes.orderCustomerInfoItem}>
          <p className={classes.orderCustomerInfoTextTitle}>{getCustomerName(customer)}</p>
        </div>
        <div className={classes.orderCustomerInfoFewItems}>
          <p className={classes.orderCustomerInfoText}>{order.name}</p>
          <p className={classes.orderCustomerInfoText}>
            {intl.formatMessage({ id: `deliveries.orderType.${order.shipping.type}` })}
          </p>
        </div>
        <div className={classes.orderCustomerInfoFewItems}>
          {customer?.email && <p className={classes.orderCustomerInfoText}>{customer.email}</p>}
          {order?.shipping?.date && (
            <p className={classes.orderCustomerInfoText}>
              {moment.parseZone(order?.shipping?.date).format(ORDER_DATE_FORMAT)}
            </p>
          )}
        </div>
        {order.shipping.type === SHIPPING_TYPES.SHIPPING_DELIVERY && (
          <div className={classes.orderCustomerInfoItem}>
            <p className={classes.orderCustomerInfoText}>{makeStringAddress(customer.shippingAddress)}</p>
          </div>
        )}
        {!!customer.phone && (
          <div className={classes.orderCustomerInfoItem}>
            <p className={classes.orderCustomerInfoText}>{customer.phone}</p>
          </div>
        )}
      </>
    );
  };

  if (loading) {
    return null;
  }

  return (
    <div className={classes.root}>
      <HeaderArea onlyBackButton />

      {!initialLoading ? (
        <div className={classes.contentWrapper}>
          <div className={classes.content}>
            <Title className={classes.title} text={intl.formatMessage({ id: 'deliveries.sales.order.title' })} />

            {!!error && <p className={classes.error}>{error}</p>}
            {!error && order && (
              <>
                <div className={classes.orderWrapper}>
                  <div className={classes.orderCustomerInfoWrapper}>{drawCustomerInfo()}</div>

                  {!!order?.lineItems?.length && (
                    <div className={classes.itemsWrapper}>
                      <div className={classes.itemsTotalWrapper}>
                        <div className={classes.itemsTotalCount}>{drawNumberOfItems(order?.lineItems?.length)}</div>
                        <div className={classes.itemsTotalAmount}>{toCurrency(order.total, intl)}</div>
                      </div>
                      {order.lineItems.map((lineItem) => drawLineItem(lineItem))}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <OrderSkeleton />
      )}
    </div>
  );
};

export default withRouter(OrderScene);
