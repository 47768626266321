import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../../../../hocs';
import { PrimaryTextField, ChipsSelect } from '../../../../../components';
import { toPaper, validateField, toCoins } from '../../../../../helpers';
import validationRules from './validation';

const styles = {
  selectFormControlClassName: {
    display: 'block',
    marginTop: 20,
  },
  select: {
    width: '100%',
  },
  label: {
    fontSize: 14,
  },
};

const propTypes = {
  data: PropTypes.object.isRequired,
  onRef: PropTypes.func.isRequired,
};

const RULE_BETWEEN = 'between';
const RULE_LOWER_THAN = 'lower_than';
const RULE_HIGHER_THAN = 'higher_than';

class EntityForm extends React.Component {
  constructor(props) {
    super(props);
    this.props.onRef(this);

    const { data } = this.props;

    this.state = {
      rule: data.rule ? data.rule.type : RULE_BETWEEN,
      min: data.rule ? toPaper(data.rule.min) : '',
      max: data.rule ? toPaper(data.rule.max) : '',
      fee: data.fee ? toPaper(data.fee) : '',

      ruleError: '',
      minError: '',
      maxError: '',
      feeError: '',
    };
  }

  getTextField(fieldName, namespace, prefix) {
    const { classes, intl } = this.props;

    return (
      <PrimaryTextField
        className={classes.input}
        inputProps={{
          type: 'number',
          min: '0',
          step: '1',
        }}
        InputLabelProps={{
          classes: {
            root: classes.label,
          },
        }}
        autoComplete="no"
        error={!!this.state[`${fieldName}Error`]}
        onChange={(event) => {
          let value = event.target.value;
          this.setState((state) => {
            state[fieldName] = value;
            return state;
          });
        }}
        value={this.state[fieldName]}
        id={`${fieldName}-input`}
        label={(prefix || '') + intl.formatMessage({ id: `${namespace}.${fieldName}` })}
        type="text"
        margin="normal"
        helperText={this.state[`${fieldName}Error`] && intl.formatMessage({ id: this.state[`${fieldName}Error`] })}
        fullWidth
      />
    );
  }

  validate = () => {
    let minError = '';
    let maxError = '';

    const ruleError = validateField(validationRules, 'rule', this.state.rule);
    if (this.state.rule === RULE_BETWEEN || this.state.rule === RULE_HIGHER_THAN) {
      minError = validateField(validationRules, 'min', this.state.min);
    }

    if (this.state.rule === RULE_BETWEEN || this.state.rule === RULE_LOWER_THAN) {
      maxError = validateField(validationRules, 'max', this.state.max);
    }

    const feeError = validateField(validationRules, 'fee', this.state.fee);

    let validated = false;
    if (!ruleError && !minError && !maxError && !feeError) {
      this.setState({
        ruleError: '',
        minError: '',
        maxError: '',
        feeError: '',
      });
      validated = true;
    } else {
      this.setState({
        ruleError: ruleError,
        minError: minError,
        maxError: maxError,
        feeError: feeError,
      });
    }

    let data = {
      rule: this.state.rule,
      fee: toCoins(this.state.fee),
    };

    switch (this.state.rule) {
      case RULE_LOWER_THAN: {
        data.max = toCoins(this.state.max);
        break;
      }
      case RULE_HIGHER_THAN: {
        data.min = toCoins(this.state.min);
        break;
      }
      default: {
        data.max = toCoins(this.state.max);
        data.min = toCoins(this.state.min);
      }
    }

    return {
      validated: validated,
      data: data,
    };
  };

  onChangeRule = (rule) => {
    this.setState({ rule });
  };

  render() {
    const { classes, intl } = this.props;
    return (
      <div className={classes.wrapper}>
        <ChipsSelect
          onChange={this.onChangeRule}
          multiple={false}
          label={intl.formatMessage({ id: 'global.rule' })}
          values={[
            { label: intl.formatMessage({ id: 'global.between' }), key: RULE_BETWEEN },
            { label: intl.formatMessage({ id: 'global.lowerThan' }), key: RULE_LOWER_THAN },
            { label: intl.formatMessage({ id: 'global.higherThan' }), key: RULE_HIGHER_THAN },
          ]}
          selected={this.state.rule}
        />

        {(this.state.rule === RULE_BETWEEN || this.state.rule === RULE_HIGHER_THAN) &&
          this.getTextField('min', 'global', '* ')}

        {(this.state.rule === RULE_BETWEEN || this.state.rule === RULE_LOWER_THAN) &&
          this.getTextField('max', 'global', '* ')}

        {this.getTextField('fee', 'global', '* ')}
      </div>
    );
  }
}

EntityForm.propTypes = propTypes;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(EntityForm)));
