import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NavigationMenu from './components/NavigationMenu';
import HeaderArea from './components/HeaderArea';
import Title from './components/Title';
import { makeStyles } from '@material-ui/core/styles';
import { ORDERS_PER_PAGE, PADDING_CONTENT, PADDING_CONTENT_TOP } from './constants/globals';
import { useIntl } from 'react-intl';
import { FONT_CART_VARIANT_TITLE } from './constants/fonts';
import { COLORS } from './constants/colors';
import { PaginationMeta } from '../../entities/PaginationMeta';
import { apiSupplierRetrieveOrdersForSuppliers } from '../../api';
import ErrorToast from './components/ErrorToast';
import Spinner from './components/Spinner';
import OrdersList from './components/OrdersList';
import ProductPagination from './components/ProductPagination';
import Footer from './components/Footer';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  content: {
    paddingLeft: PADDING_CONTENT,
    paddingRight: PADDING_CONTENT,
    paddingTop: PADDING_CONTENT_TOP,
  },
  title: {
    marginBottom: 20,
  },
  buttonWrapper: {
    marginBottom: 20,
    paddingLeft: PADDING_CONTENT,
    paddingRight: PADDING_CONTENT,
  },
  error: {
    ...FONT_CART_VARIANT_TITLE,
    color: COLORS.danger,
    paddingBottom: 20,
    paddingTop: 20,
  },
}));

const SupplierSalesScene = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { supplier } = useSelector((state) => ({ supplier: state.supplier.object }));

  const [orders, setOrders] = useState([]);
  const [meta, setMeta] = useState(new PaginationMeta());
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    getOrders();
  }, [currentPage]);

  const getOrders = () => {
    setLoading(true);
    const data = {
      limit: ORDERS_PER_PAGE,
      skip: (currentPage - 1) * ORDERS_PER_PAGE,
    };

    apiSupplierRetrieveOrdersForSuppliers(
      data,
      (response) => {
        setOrders(response.data);
        setMeta(new PaginationMeta(response.meta));
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        setError(error?.response?.data?.message || intl.formatMessage({ id: 'global.somethingWentWrong' }));
      }
    );
  };

  const onPageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className={classes.root}>
      <ErrorToast />
      <HeaderArea />

      <div className={classes.content}>
        <Title className={classes.title} text={intl.formatMessage({ id: 'supplier.sales.title' })} />

        {!!error && <p className={classes.error}>{error}</p>}
        {loading ? (
          <Spinner />
        ) : (
          <>
            <OrdersList orders={orders} supplier={supplier} />
            <ProductPagination meta={meta} page={currentPage} onChange={onPageChange} />
          </>
        )}
      </div>

      <NavigationMenu />
      <Footer />
    </div>
  );
};

export default SupplierSalesScene;
