import { Table } from '@devexpress/dx-react-grid-material-ui';
import React from 'react';

export const getTreeChildRows = (intl, row, rootRows) => {
  if (row && row.rowType === 'location') {
    return row.items;
  }

  if (row && !!row.lineItems) {
    return row.lineItems;
  }

  if (row && !!row.productSubType) {
    return null;
  }

  return rootRows;
};

export const prepareRowsAfterLoad = (rows) => {
  let expanded = [];

  let _rows = [];

  rows.forEach((location) => {
    _rows.push({
      id: location[0].locationName,
      name: location[0].locationName,
      rowType: 'location',
      items: location,
    });
  });

  return {
    rows: _rows,
    expandedGroups: expanded,
  };
};

export const getExpandedRowIds = () => {
  return [];
};

export const tableRowRenderer = (onClick, { row, ...props }) => {
  const getColor = (row) => {
    if (row.rowType === 'location') {
      return '#ffffff';
    }

    if (row && !!row.lineItems) {
      return 'rgba(224, 224, 224, 0.2)';
    }

    if (row && !!row.productSubType) {
      return 'rgba(224, 224, 224, 0.4)';
    }
    return '#ffffff';
  };

  return <Table.Row {...props} onClick={() => onClick && onClick(row)} style={{ backgroundColor: getColor(row) }} />;
};
