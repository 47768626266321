export default {
  header: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  typesSwitcher: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 0,
    marginBottom: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  activity: {
    marginRight: 20
  },
  search: {
    minWidth: 300
  },
  customItem: {
    marginLeft: 'auto'
  }
};