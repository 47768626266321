/**
 * Checks whether variable is empty
 * @param val
 * @return {boolean}
 */
export function isEmpty(val){
  return (
    val === undefined ||
    val == null ||
    val.length <= 0 ||
    (typeof val === 'object' && Object.keys(val).length === 0)
  );
}

export function parseFloatFromString(string) {
  let regex = /\d+(\.\d+)?/;
  let floats = string.match(regex);
  if (Array.isArray(floats) && floats.length) {
    return parseFloat(floats[0]);
  }
  return NaN;
}
