import React from 'react';
import moment from 'moment';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { apiPrePurchaseEnable, apiPrePurchaseDisable } from '../../api';
import TableCheckbox from '../../components/AdminDXTable/TableCheckbox';
import { toPaper, PREPURCHASES_GROUP_BY } from '../../helpers';
import Chip from '@material-ui/core/Chip';
import { FormattedMessage } from 'react-intl';

export const CellRenderer = (reload, { row, ...props }, rows, onUpdateRows) => {
  if (props.column.name === 'name') {
    return <Table.Cell {...props}>{(row.name && row.name.toString()) || ''}</Table.Cell>;
  }

  if (props.column.name === 'tags') {
    if (!row.tags) return <Table.Cell {...props}>{'-'}</Table.Cell>;

    return (
      <Table.Cell {...props}>
        {row.tags.map((tag) => (
          <Chip key={tag} label={tag} style={{ margin: 5 }} />
        ))}
      </Table.Cell>
    );
  }

  if (props.column.name === 'customer') {
    return <Table.Cell {...props}>{row.customer && row.customer.name}</Table.Cell>;
  }

  if (['value', 'availableValue'].indexOf(props.column.name) !== -1) {
    return <Table.Cell {...props}>{'$' + toPaper(row[props.column.name] || 0)}</Table.Cell>;
  }

  if (props.column.name === 'products') {
    return <Table.Cell {...props}>{(row.variantsCount && row.variantsCount.toString()) || '0'}</Table.Cell>;
  }

  if (props.column.name === 'start' || props.column.name === 'end') {
    return (
      <Table.Cell {...props}>
        {moment(row[props.column.name === 'start' ? 'validFrom' : 'validTo']).format('MMM D, Y')}
      </Table.Cell>
    );
  }

  if (props.column.name === 'nextOrder') {
    return (
      <Table.Cell {...props}>
        {row.subscription ? moment(row.subscription.nextDate).format('MMM D, Y') : '-'}
      </Table.Cell>
    );
  }

  if (props.column.name === 'active') {
    return getActiveCell(row, props, rows, onUpdateRows);
  }

  return <Table.Cell {...props} />;
};

const getActiveCell = (row, props, rows, onUpdateRows) => {
  const onChangeActivityBeforeApiCall = (status) => {
    const isGroupedBy = !!rows[0] && rows[0].groupByColumn;

    if (isGroupedBy) {
      for (let i = 0; i < rows.length; i++) {
        for (let j = 0; j < rows[i].items.length; j++) {
          if (row.id === rows[i].items[j].id) {
            rows[i].items[j].active = status;
          }
        }
      }
    } else {
      for (let i = 0; i < rows.length; i++) {
        if (row.id === rows[i].id) {
          rows[i].active = status;
        }
      }
    }

    onUpdateRows(rows);
  };

  const onChangeActivity = (status) => {
    onChangeActivityBeforeApiCall(status);
    status ? apiPrePurchaseEnable(row.id, undefined, undefined) : apiPrePurchaseDisable(row.id, undefined, undefined);
  };

  return (
    <Table.Cell {...props}>
      <TableCheckbox onChange={onChangeActivity} checked={row.active} />
    </Table.Cell>
  );
};

export const TableRow = (onClick, { row, ...props }) => {
  const isChildRow = !row.items;

  let managedProps = { ...props };
  // Remove actions on group cell
  if (!isChildRow) {
    managedProps.children = managedProps.children.filter((col) => col.key !== 'actionsColumnType');
  }

  return (
    <Table.Row
      {...managedProps}
      onClick={() => onClick && onClick(row)}
      style={{
        backgroundColor: row.variants ? 'rgba(224, 224, 224, 0.4)' : '#ffffff',
      }}
    />
  );
};

export const TableCell = (reload, { row, ...props }, rows, onUpdateRows) => {
  const isChildRow = !row.items;

  if (isChildRow && props.column.name === 'subscription.location.name') {
    return <Table.Cell />;
  }

  if (isChildRow) {
    return CellRenderer(reload, { row, ...props }, rows, onUpdateRows);
  }

  if (!isChildRow && props.column.name === 'subscription.location.name') {
    const groupedById = row?.groupedById;
    let groupedByName = row?.groupedByName;

    if (groupedById === PREPURCHASES_GROUP_BY.NO_GROUP) {
      groupedByName = <FormattedMessage id="dialog.groupBy.noLocation" />;
    }

    return <Table.Cell {...props}>{groupedByName || '-'}</Table.Cell>;
  }

  if (!isChildRow && props.column.name === 'name') {
    return <Table.Cell {...props}>{row?.items?.length}</Table.Cell>;
  }

  if (!isChildRow) {
    return <Table.Cell />;
  }

  return <Table.Cell {...props} />;
};
//
// export const TableGroupHeaderCell = (reload, { row, ...props }, rows, onUpdateRows) => {
//   return <TableGroupRow.Content {...props}>{row.value}</TableGroupRow.Content>;
// };
