import { getRequestConfig, replaceUrlPlaceholder, REQUEST_TYPES } from '../request';
import API_END_POINTS from '../endpoints';
import axios from 'axios';

/**
 * Get all suppliers
 * @param {string} supplierId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiMakeSupplierToken = function (supplierId, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.MAKE_SUPPLIER_TOKEN);
  config.data = { supplierId };

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Get all suppliers
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiGetAllSuppliers = function (onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.GET_SUPPLIERS);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Get single suppliers
 * @param {string} supplierId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiGetSupplierById = function (supplierId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.GET_SINGLE_SUPPLIER, 'sid', supplierId);
  const config = getRequestConfig(REQUEST_TYPES.GET, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response?.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};
/**
 * Update single supplier by id
 * @param {string} supplierId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiEditSupplierById = function (supplierId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.SUPPLIER_PATCH_PROFILE, 'sid', supplierId);
  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);

  config.data = data;

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Create new supplier
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierCreate = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.SUPPLIER_CREATE);
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};
