import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../../hocs';
import Typography from '@material-ui/core/Typography';
import BackIcon from '@material-ui/icons/ArrowBack';
import {
  AdminDialogTitle,
  AdminDialogContent,
  Icon,
  PrimaryButton,
  AdminDialogControls,
  Spinner,
} from '../../../components';
import { apiApproveCustomer } from '../../../api';
import styles from './styles';

const propTypes = {
  customer: PropTypes.object.isRequired,
  onApproved: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

class ApproveStage extends React.Component {
  state = {
    loading: false,
  };

  process = () => {
    const { onApproved, customer } = this.props;
    this.setState({ loading: true });
    apiApproveCustomer(
      customer.id,
      (customer) => {
        this.setState({ loading: false });
        onApproved(customer);
      },
      undefined
    );
  };

  render() {
    const { intl, classes, onBack } = this.props;
    return (
      <React.Fragment>
        <AdminDialogTitle title={intl.formatMessage({ id: 'customer.dialogTitles.approveCustomer' })}>
          <Icon icon={BackIcon} style={{ fill: 'rgba(0, 0, 0, 0.5)' }} className={classes.back} onClick={onBack} />
        </AdminDialogTitle>
        <AdminDialogContent className={classes.wrapper}>
          {this.state.loading && <Spinner size={50} />}

          <Typography className={classes.message}>
            {intl.formatMessage({ id: 'customer.dialogMessages.approveCustomer' })}
          </Typography>

          <AdminDialogControls>
            <PrimaryButton className={classes.button} onClick={this.process}>
              {intl.formatMessage({ id: 'global.approve' })}
            </PrimaryButton>
          </AdminDialogControls>
        </AdminDialogContent>
      </React.Fragment>
    );
  }
}

ApproveStage.propTypes = propTypes;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(ApproveStage)));
