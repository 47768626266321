import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import CellAsTextInput from './CellAsTextInput';

const styles = ({});

const propTypes = {
  value: PropTypes.string,
  cellProps: PropTypes.object.isRequired,
  onFinishCellEditing: PropTypes.func.isRequired,
};

const CellAsTextArea = (props) => {
  return (
    <CellAsTextInput
      {...props}
      inputProps={{
        multiline: true,
        rows: 3,
        rowsMax: 3,
      }}
    />
  )
};

CellAsTextArea.propTypes = propTypes;

export default withStyles(styles)(
  injectIntl(CellAsTextArea)
)
