import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";

const propTypes = {
  actions: PropTypes.object,
};

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: "16px 13px",
  },
  actions: {
    marginLeft: 'auto'
  }
};

const AdminTabContentHeader = (props) => {
  const {
    classes,
    actions
  } = props;

  return (
    <div className={classes.wrapper}>
      {props.children}

      {actions && (
        <div className={classes.actions}>
          {actions}
        </div>
      )}
    </div>
  );
};

AdminTabContentHeader.propTypes = propTypes;

export default withStyles(styles)(
  AdminTabContentHeader
);