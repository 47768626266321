import moment from 'moment';

/**
 * Format date
 * @param date
 * @param {bool} time - should we add time here
 * @return {string}
 */
export const formatDate = (date, time) => {
  return moment(date).format(process.env.REACT_APP_DATE_FORMAT_WITH_SHORT_DAY_OF_WEEK + (time ? ' hh:mm A' : ''));
};

/**
 * Generates start of current day in ISO8601 format
 * @param {string} timeZone - timezone name
 * @return {string|*}
 */
export const getStartOfTheDayISO8601 = (timeZone) => {
  timeZone = timeZone || process.env.REACT_APP_TIME_ZONE;
  return moment().tz(timeZone).startOf('day').toISOString(true);
};

/**
 * Generates start of current day in ISO8601 format
 * @param {string} date - date
 * @return {string|*}
 */
export const dateTOISO8601 = (date) => {
  return moment(date).tz(process.env.REACT_APP_TIME_ZONE).startOf('day').toISOString(true);
};

/**
 * Format date
 * @param date
 * @return {string}
 */
export const dateToMMDY = (date) => {
  return moment.parseZone(date).format('MM/D/Y');
};

/**
 * Searches full date by MM/D/Y part
 * @param {array} dates
 * @param {string} date
 * @param {string} returnType
 * @return {string|boolean}
 */
export const searchDateBYMMDY = (dates, date, returnType) => {
  // Search date by MM/D/Y part
  let result = false;
  for (let dateKey in dates) {
    if (dates.hasOwnProperty(dateKey) && dates[dateKey].formatted.nextMMDY === date) {
      switch (returnType) {
        case 'object': {
          result = dates[dateKey];
          break;
        }
        default: {
          result = dates[dateKey].next;
          break;
        }
      }
      break;
    }
  }
  return result;
};

export function isDateInFuture(date) {
  const today = moment().startOf('day').valueOf();
  const valueOfDate = moment(date).startOf('day').valueOf();

  return valueOfDate > today;
}

export function getDateFromHash(hash, format = 'MM-DD-YYYY') {
  const hashDate = moment(hash, format);

  return hashDate.isValid() ? hashDate : moment();
}
