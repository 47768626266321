import React from 'react';
import ImageList from '@material-ui/core/ImageList';
import { withStyles } from '@material-ui/core/styles';

const styles = {};

const SearchHitsSubWrapper = (props) => {
  const { children } = props;

  return (
    <ImageList gap={1} rowHeight={120}>
      {children}
    </ImageList>
  );
};

export default withStyles(styles)(SearchHitsSubWrapper);
