import moment from 'moment';

const AVAILABILITY_LOCATION_ALL = 'all';
const AVAILABILITY_SHIPPING_TYPE_ALL = 'all';

class Availability {
  constructor(props = {}) {
    this.locationId = props.locationId || AVAILABILITY_LOCATION_ALL;
    this.shippingType = props.shippingType || AVAILABILITY_SHIPPING_TYPE_ALL;
    this.startDate = moment(props.startDate).startOf('day').toISOString() || moment().startOf('day').toISOString();
    this.locationIdError = props.locationIdError || '';
    this.shippingTypeError = props.shippingTypeError || '';
  }
}

export default Availability;
