import React from 'react';
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import MenuItem from '@material-ui/core/MenuItem'
import {
  COLORS,
} from '../../helpers'

function getSuggestionProperty(prop, query) {
  const matches = match(prop, query);
  const parts = parse(prop, matches);

  return (
    <div style={{textAlign: 'left'}}>
      {parts.map((part, index) => {
        return part.highlight ? (
          <span key={String(index)} style={{ fontWeight: 500, fontStyle: 10 }}>
              {part.text}
            </span>
        ) : (
          <strong key={String(index)} style={{ fontWeight: 300, fontStyle: 10 }}>
            {part.text}
          </strong>
        );
      })}
    </div>
  );
}

export default function SuggestionComponent(suggestion, { query, isHighlighted }, labelExtractor) {

  const styles = {
    display: "flex",
    flexDirection: 'column',
    height: 'auto',
    minHeight: 20,
    textAlign: 'left',
    justifyContent: 'flex-start',
    borderBottom: '1px solid ' + COLORS.border,
    alignItems: 'flex-start',
    '&:firstLetter': {
      textTransform: 'uppercase'
    }
  };

  return (
    <MenuItem style={styles} selected={isHighlighted} component="div">
      {getSuggestionProperty(labelExtractor(suggestion), query)}
    </MenuItem>
  );
}
