import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../../../../hocs';
import {
  PrimaryTextField,
  ErrorBox,
  PrimaryInlineTimePicker,
  ChipsSelect,
  ImageUpload,
  RichTextEditableBox,
  PrimaryChip,
} from '../../../../../components';
import { validateField } from '../../../../../helpers';
import validationRules from './validation';
import EmptyImage from '../../../../../components/Image/assets/no.jpg';

const styles = {
  selectFormControlClassName: {
    display: 'block',
    marginTop: 20,
    marginBottom: 20,
  },
  select: {
    width: '100%',
  },
  shippingType: {
    color: '#707070',
    padding: 0,
    fontSize: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  label: {
    fontSize: 14,
  },
  globalTimePickersWrapper: {
    marginTop: 20,
  },
  chipsSelectError: {
    color: '#ff0000',
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    maxWidth: 300,
  },
  subWrapperClass: {
    paddingBottom: 20,
  },
  descriptionWrapper: {},
  descriptionTitleClass: {
    marginTop: 20,
    fontSize: 14,
  },
  tagsWrapper: {
    marginTop: 16,
    marginBottom: 8,
  },
};

const propTypes = {
  locations: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  onRef: PropTypes.func.isRequired,
};

class EntityForm extends React.Component {
  constructor(props) {
    super(props);
    this.props.onRef(this);

    const { data } = this.props;

    this.state = {
      name: data.name || '',
      address: data.address1 || '',
      city: data.city || '',
      locality: data.locality || '',
      state: data.province || '',
      zipCode: data.zip || '',
      tags: data.tags || [],

      error: '',
      nameError: '',
      addressError: '',
      cityError: '',
      stateError: '',
      zipCodeError: '',
      marketStart: data.marketStart ? this.convertFromHmm(data.marketStart) : moment().format('h:mm A'),
      marketEnd: data.marketEnd ? this.convertFromHmm(data.marketEnd) : moment().format('h:mm A'),
      pickupRetailStart: data.pickupRetailStart
        ? this.convertFromHmm(data.pickupRetailStart)
        : moment().format('h:mm A'),
      pickupRetailEnd: data.pickupRetailEnd ? this.convertFromHmm(data.pickupRetailEnd) : moment().format('h:mm A'),
      pickupWholesaleStart: data.pickupWholesaleStart
        ? this.convertFromHmm(data.pickupWholesaleStart)
        : moment().format('h:mm A'),
      pickupWholesaleEnd: data.pickupWholesaleEnd
        ? this.convertFromHmm(data.pickupWholesaleEnd)
        : moment().format('h:mm A'),
      marketStartError: '',
      marketEndError: '',
      pickupRetailStartError: '',
      pickupRetailEndError: '',
      pickupWholesaleStartError: '',
      pickupWholesaleEndError: '',
      image: this.getImage(data) || {},
      file: null,
      weekDays: data.weekDays || [],
      weekDaysError: '',
      description: data.description || '',
    };
  }
  getImage = (object) => {
    if (object && object.image && object.image.thumbSrc) {
      return object.image;
    }

    return EmptyImage;
  };

  getTextField(fieldName, namespace, prefix) {
    const { classes, intl } = this.props;

    return (
      <PrimaryTextField
        className={classes.input}
        InputLabelProps={{
          classes: {
            root: classes.label,
          },
        }}
        autoComplete="no"
        error={!!this.state[`${fieldName}Error`]}
        onChange={(event) => {
          let value = event.target.value;
          this.setState((state) => {
            state[fieldName] = value;
            return state;
          });
        }}
        value={this.state[fieldName]}
        id={`${fieldName}-input`}
        label={(prefix || '') + intl.formatMessage({ id: `${namespace}.${fieldName}` })}
        type="text"
        margin="normal"
        helperText={this.state[`${fieldName}Error`] && intl.formatMessage({ id: this.state[`${fieldName}Error`] })}
        fullWidth
      />
    );
  }

  getTimePicker = (stateValueName = '', errorName) => {
    const { intl } = this.props;

    return (
      <PrimaryInlineTimePicker
        fullWidth
        style={{ marginBottom: 20 }}
        error={!!errorName}
        helperText={!!errorName && intl.formatMessage({ id: errorName })}
        label={intl.formatMessage({ id: [`locations.${stateValueName}Label`] })}
        value={this.state[`${stateValueName}`]}
        onChange={(moment) => {
          this.setState({
            [`${stateValueName}`]: moment,
            [`${stateValueName}Moment`]: moment.format('HH:mm:ss'),
          });
        }}
      />
    );
  };

  getChipsSelector = () => {
    const { intl, classes } = this.props;

    return (
      <>
        <ChipsSelect
          onChange={this.onChangeWeekDays}
          label={intl.formatMessage({ id: 'locations.weekDaysLabel' })}
          values={[
            { label: intl.formatMessage({ id: 'global.day.sunday' }), key: 'sunday' },
            { label: intl.formatMessage({ id: 'global.day.monday' }), key: 'monday' },
            { label: intl.formatMessage({ id: 'global.day.tuesday' }), key: 'tuesday' },
            { label: intl.formatMessage({ id: 'global.day.wednesday' }), key: 'wednesday' },
            { label: intl.formatMessage({ id: 'global.day.thursday' }), key: 'thursday' },
            { label: intl.formatMessage({ id: 'global.day.friday' }), key: 'friday' },
            { label: intl.formatMessage({ id: 'global.day.saturday' }), key: 'saturday' },
          ]}
          selected={this.state.weekDays}
        />
        {!!this.state.weekDaysError && (
          <div className={classes.chipsSelectError}>
            <p>{intl.formatMessage({ id: this.state.weekDaysError })}</p>
          </div>
        )}
      </>
    );
  };

  onChangeWeekDays = (days) => {
    this.setState({
      weekDays: days,
    });
  };

  convertToHmm = (timeToServer = '') => {
    return moment(timeToServer, 'H:mm A').format('Hmm');
  };

  convertFromHmm = (timeFromServer = 0) => {
    return moment(timeFromServer, 'Hmm');
  };

  validate = () => {
    const {
      marketStart,
      marketEnd,
      pickupRetailStart,
      pickupRetailEnd,
      pickupWholesaleStart,
      pickupWholesaleEnd,
      weekDays,
      file,
    } = this.state;
    const nameError = validateField(validationRules, 'name', this.state.name);
    const addressError = validateField(validationRules, 'address', this.state.address);
    const cityError = validateField(validationRules, 'city', this.state.city);
    const stateError = validateField(validationRules, 'state', this.state.state);
    const zipCodeError = validateField(validationRules, 'zipCode', this.state.zipCode);

    const marketError = marketStart >= marketEnd ? validateField(validationRules, 'validateTime', '') : false;
    const pickupRetailError =
      pickupRetailStart >= pickupRetailEnd ? validateField(validationRules, 'validateTime', '') : false;
    const pickupWholesaleError =
      pickupWholesaleStart >= pickupWholesaleEnd ? validateField(validationRules, 'validateTime', '') : false;

    const weekDaysError = !weekDays.length ? validateField(validationRules, 'weekDays', '') : false;

    let error = '';

    let validated = false;
    if (
      !nameError &&
      !addressError &&
      !cityError &&
      !stateError &&
      !zipCodeError &&
      !error &&
      !marketError &&
      !pickupRetailError &&
      !pickupWholesaleError &&
      !weekDaysError
    ) {
      this.setState({
        nameError: '',
        addressError: '',
        cityError: '',
        stateError: '',
        zipCodeError: '',
        error: '',
        marketError: '',
        pickupRetailError: '',
        pickupWholesaleError: '',
        weekDaysError: '',
      });
      validated = true;
    } else {
      this.setState({
        nameError: nameError,
        addressError: addressError,
        cityError: cityError,
        stateError: stateError,
        zipCodeError: zipCodeError,
        error: error,

        marketError: marketError,
        pickupRetailError: pickupRetailError,
        pickupWholesaleError: pickupWholesaleError,
        weekDaysError: weekDaysError,
      });
    }

    return {
      validated: validated,
      data: {
        name: this.state.name,
        address1: this.state.address,
        city: this.state.city,
        locality: this.state.city,
        province: this.state.state,
        zip: this.state.zipCode,
        tags: this.state.tags,
        description: this.state.description,
        ...(file ? { image: file } : {}),

        marketStart: this.convertToHmm(marketStart),
        marketEnd: this.convertToHmm(marketEnd),
        pickupRetailStart: this.convertToHmm(pickupRetailStart),
        pickupRetailEnd: this.convertToHmm(pickupRetailEnd),
        pickupWholesaleStart: this.convertToHmm(pickupWholesaleStart),
        pickupWholesaleEnd: this.convertToHmm(pickupWholesaleEnd),
        weekDays,
      },
    };
  };

  getImageLoader = () => {
    const { classes } = this.props;
    const { image, name } = this.state;
    return (
      <div className={classes.imageWrapper}>
        <div className={classes.image}>
          <ImageUpload
            object={{ image, name }}
            base64={this.state.file}
            onUploaded={(file) => this.setState({ file: file })}
          />
        </div>
      </div>
    );
  };

  getDescriptionEditor = () => {
    const { classes, intl } = this.props;
    const { description } = this.state;

    return (
      <div className={classes.descriptionWrapper}>
        <RichTextEditableBox
          titleClass={classes.descriptionTitleClass}
          title={intl.formatMessage({ id: 'variant.description' })}
          editingTitle={intl.formatMessage({ id: 'variant.description' })}
          withEditIcon
          text={description}
          onSave={(html) => this.setState({ description: html })}
          notEditingWrapperStyle={{ width: '100%' }}
          placeholder={intl.formatMessage({ id: 'global.descriptionNotAdded' })}
          subWrapperClass={classes.subWrapperClass}
        />
      </div>
    );
  };

  drawTagsSelector = () => {
    const { intl, classes } = this.props;

    return (
      <div className={classes.tagsWrapper}>
        <PrimaryChip
          className={classes.input}
          InputLabelProps={{
            shrink: true,
            classes: {
              root: classes.label,
            },
          }}
          fullWidth
          value={this.state.tags}
          allowDuplicates={false}
          onAdd={(chip) => {
            this.setState({ tags: [...this.state.tags, chip] });
          }}
          onDelete={(chip) => {
            let chipsArr = [...this.state.tags];
            const index = chipsArr.indexOf(chip);
            chipsArr.splice(index, 1);
            this.setState({ tags: chipsArr });
          }}
          label={intl.formatMessage({ id: 'global.tags' })}
        />
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    const { marketError, pickupRetailError, pickupWholesaleError } = this.state;
    return (
      <div className={classes.wrapper}>
        {this.state.error && <ErrorBox error={this.state.error} />}

        {this.getImageLoader()}

        {this.getTextField('name', 'global')}
        {this.getTextField('address', 'global')}
        {this.getTextField('city', 'global')}
        {this.getTextField('state', 'global')}
        {this.getTextField('zipCode', 'global')}

        {this.drawTagsSelector()}

        {this.getDescriptionEditor()}

        {this.getChipsSelector()}

        <div className={classes.globalTimePickersWrapper}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              {this.getTimePicker('marketStart', marketError)}
            </Grid>
            <Grid item xs={6}>
              {this.getTimePicker('marketEnd', marketError)}
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              {this.getTimePicker('pickupRetailStart', pickupRetailError)}
            </Grid>
            <Grid item xs={6}>
              {this.getTimePicker('pickupRetailEnd', pickupRetailError)}
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              {this.getTimePicker('pickupWholesaleStart', pickupWholesaleError)}
            </Grid>
            <Grid item xs={6}>
              {this.getTimePicker('pickupWholesaleEnd', pickupWholesaleError)}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

EntityForm.propTypes = propTypes;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(EntityForm)));
