/**
 * Save object to storage
 * @param {string} key - object key
 * @param {object} object - object
 */
export function saveObjectToStorage(key, object) {
  return localStorage.setItem(key,
    JSON.stringify(object)
  );
}

/** Get object from storage */
export function getObjectFromStorage(key) {
  let data = localStorage.getItem(key);
  return data ?
    JSON.parse(data) : false;
}