import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AdminDialog, AdminDialogTitle } from '../../components';
import { withRouter } from '../../hocs';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { injectIntl } from 'react-intl';
import {
  apiGetProducerNotifications,
  apiAddProducerNotification,
  apiUpdateProducerNotification,
  apiRemoveProducerNotification,
} from '../../api';
import TableStep from './TableStep';
import AddStep from './AddStep';
import DeleteStep from './DeleteStep';

const propTypes = {
  selectedRow: PropTypes.object,
  reloadTable: PropTypes.func,
};

const defaultProps = {};

const STEPS = {
  STEP_TABLE: 'STEP_TABLE',
  STEP_ADD: 'STEP_ADD',
  STEP_EDIT: 'STEP_EDIT',
  STEP_DELETE: 'STEP_DELETE',
};

function CustomerNotificationDialog(props) {
  const { onClose, classes, selectedRow, reloadTable, intl } = props;

  const [step, setStep] = useState(STEPS.STEP_TABLE);
  const [loading, setLoading] = useState(true);
  const [notification, setNotification] = useState(null);
  const [error, setError] = useState('');
  const [editLoading, setEditLoading] = useState(false);
  const accountId = selectedRow.id;

  useEffect(() => {
    apiGetProducerNotifications(
      accountId,
      (response) => {
        init(response);
      },
      (error) => {
        //in case notification for this account doesn't exist
        if (error.response && error.response.data && error.response.data.code === 3501) {
          setStep(STEPS.STEP_ADD);
        }
        setLoading(false);
      }
    );
  }, []);

  const init = (response) => {
    const emailNotifications = response && response.emailNotifications;
    const pushNotifications = response && response.pushNotifications;

    if (!!emailNotifications || !!pushNotifications) {
      setNotification(response);
    } else {
      setStep(STEPS.STEP_ADD);
    }
    setLoading(false);
  };

  const onAddNotification = (data) => {
    apiAddProducerNotification(
      accountId,
      data,
      (response) => {
        setNotification(response);
        setError('');
        setStep(STEPS.STEP_TABLE);
      },
      (error) => {
        setError(error.response && error.response.data && error.response.data.message);
        setStep(STEPS.STEP_TABLE);
      }
    );
  };

  const getTitle = () => {
    switch (step) {
      case STEPS.STEP_TABLE: {
        return intl.formatMessage({ id: 'screen.producerNotificationDialog.title' });
      }
      case STEPS.STEP_ADD: {
        return intl.formatMessage({ id: 'screen.producerNotificationDialog.AddTitle' });
      }
      case STEPS.STEP_DELETE: {
        return intl.formatMessage({ id: 'screen.producerNotificationDialog.DeleteTitle' });
      }
      default: {
        return intl.formatMessage({ id: 'screen.producerNotificationDialog.title' });
      }
    }
  };

  const onEditNotification = (data, type) => {
    setEditLoading(true);
    apiUpdateProducerNotification(
      accountId,
      type,
      data,
      (response) => {
        setEditLoading(false);
        setNotification(response);
      },
      (error) => {
        setError(error.response && error.response.data && error.response.data.message);
        setEditLoading(false);
      }
    );
  };

  const onDeleteNotification = () => {
    apiRemoveProducerNotification(
      accountId,
      () => {
        onClose();
      },
      () => {
        onClose();
      }
    );
  };

  if (loading) return null;

  return (
    <AdminDialog open={true} closeButton paperClass={classes.paper} onClose={onClose}>
      <AdminDialogTitle title={getTitle()} />

      {step === STEPS.STEP_TABLE && (
        <TableStep
          error={error}
          selectedRow={selectedRow}
          notification={notification}
          onDelete={() => setStep(STEPS.STEP_DELETE)}
          onEditNotification={onEditNotification}
          loading={editLoading}
        />
      )}

      {step === STEPS.STEP_ADD && (
        <AddStep
          selectedRow={selectedRow}
          setError={setError}
          reloadTable={reloadTable}
          onAddNotification={onAddNotification}
        />
      )}

      {step === STEPS.STEP_DELETE && (
        <DeleteStep onDeleteNotification={onDeleteNotification} onCancel={() => setStep(STEPS.STEP_TABLE)} />
      )}
    </AdminDialog>
  );
}

CustomerNotificationDialog.propTypes = propTypes;
CustomerNotificationDialog.defaultProps = defaultProps;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(CustomerNotificationDialog)));
