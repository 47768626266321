import {COLORS} from "../../helpers";

export default ({
  wrapper: {
    marginLeft: 23,
    marginRight: 23
  },
  text: {
    fontSize: 14,
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.text,
    marginBottom: 20
  },
  button: {
    minWidth: '40%',
    textTransform: 'uppercase',
  },
  mr20: {
    marginRight: 10
  },
  controls: {
    margin: '27px 0 0 0',
    textAlign: 'center'
  },
  control: {
    padding: '0 105px'
  },

});
