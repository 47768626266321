import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../hocs';
import { injectIntl } from 'react-intl';
import { SidebarMenu, HeaderMenu } from '../Partials';
import { AdminTabs, AdminLayout, withTabsHasher, Spinner } from '../../components';
import { setProducer } from '../../store/actions';
import { apiGetProducer } from '../../api';
import ProfileTab from './ProfileTab';
import PaymentTab from './PaymentTab';
import DeliveryTab from './DeliveryTab';
import AccountingTab from './AccountingTab';
import PayoutsTab from './PayoutsTab/PayoutsTab';
import InvoicesTab from './InvoicesTab';
import AccountsTab from './AccountsTab';
import SimpleTab from '../../entities/UI/SimpleTab';
import ImportTab from './ImportTab';
import LinksTab from './LinksTab';

const map = ['profile', 'qb', 'payment', 'delivery', 'invoices', 'payouts', 'accounts', 'import', 'links'];

class SettingsScene extends React.Component {
  constructor(props) {
    super(props);
    const { tabsHasher } = this.props;
    this.state = {
      tab: tabsHasher.getTabIndex(),
      loaded: false,
    };
  }

  componentDidMount() {
    this._fetchProducer();
  }

  _fetchProducer = () => {
    const { producer, dispatch } = this.props;
    apiGetProducer(
      producer.id,
      (producer) => {
        dispatch(setProducer(producer));
        this.setState({ loaded: true });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  onChangeTab = (index) => {
    const { tabsHasher } = this.props;
    tabsHasher.setHashByIndex(index);
    this.setState({ tab: index });
  };

  render() {
    const { intl, tabsHasher, producer, openEditModal, openPwdModal } = this.props;
    const { tab } = this.state;

    const tabs = [
      new SimpleTab(intl.formatMessage({ id: 'producer.tab.profile' }), tabsHasher.getHrefByIndex(0)),
      new SimpleTab(intl.formatMessage({ id: 'producer.tab.accounting' }), tabsHasher.getHrefByIndex(1)),
      new SimpleTab(intl.formatMessage({ id: 'producer.tab.payment' }), tabsHasher.getHrefByIndex(2)),
      new SimpleTab(intl.formatMessage({ id: 'producer.tab.delivery' }), tabsHasher.getHrefByIndex(3)),
      new SimpleTab(intl.formatMessage({ id: 'producer.tab.invoices' }), tabsHasher.getHrefByIndex(4)),
      new SimpleTab(intl.formatMessage({ id: 'producer.tab.payouts' }), tabsHasher.getHrefByIndex(5)),
      new SimpleTab(intl.formatMessage({ id: 'producer.tab.users' }), tabsHasher.getHrefByIndex(6)),
      new SimpleTab(intl.formatMessage({ id: 'producer.tab.import' }), tabsHasher.getHrefByIndex(7)),
      new SimpleTab(intl.formatMessage({ id: 'producer.tab.links' }), tabsHasher.getHrefByIndex(8)),
    ];

    return (
      <AdminLayout sidebarComponent={SidebarMenu} headerMenuComponent={HeaderMenu}>
        {!this.state.loaded && <Spinner size={80} />}
        {this.state.loaded && (
          <AdminTabs onChangeTab={this.onChangeTab} tab={tab} tabs={tabs}>
            {tab === 0 && <ProfileTab />}
            {tab === 1 && <AccountingTab />}
            {tab === 2 && <PaymentTab />}
            {tab === 3 && <DeliveryTab />}
            {tab === 4 && <InvoicesTab openEditModal={openEditModal} producer={producer} />}
            {tab === 5 && <PayoutsTab />}
            {tab === 6 && <AccountsTab openEditModal={openEditModal} openPwdModal={openPwdModal} />}
            {tab === 7 && <ImportTab />}
            {tab === 8 && <LinksTab />}
          </AdminTabs>
        )}
      </AdminLayout>
    );
  }
}

export default injectIntl(
  withRouter(
    connect((state) => {
      return { producer: state.producer.object };
    })(withTabsHasher(SettingsScene, '/settings', map))
  )
);
