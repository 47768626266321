import React from 'react';

export const AccountTreeIcon = (props) => {
  const { size } = props;
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="AccountTreeIcon"
      height={size}
      width={size}
      {...props}>
      <path d="M22 11V3h-7v3H9V3H2v8h7V8h2v10h4v3h7v-8h-7v3h-2V8h2v3z" />
    </svg>
  );
};
