class SearchProductsParams {
  constructor(props = {}) {
    this.query = props.query || null;
    this.name = props.name || null;
    this.locationId = props.locationId || null;
    this.active = props.active !== undefined ? props.active : null;
    this.image = props.image || null;
    this.productType = props.productType || '';
    this.subType = props.subType || '';
    this.types = props.types || [];
    this.subTypes = props.subTypes || [];
    this.producerId = props.producerId || null;
    this.onlyUnique = props.onlyUnique || null;
    this.customerId = props.customerId || null;
    this.collectionId = props.collectionId || null;
    this.supplierIds = props.supplierIds || null;
    this.producerIds = props.producerIds || null;
    this.onlyArchived = props.onlyArchived || null;

    this.limit = props.limit || 10;
    this.skip = props.skip || 0;
  }

  toData() {
    let data = {};

    if (!!this.query) data['query'] = this.query;
    if (!!this.name) data['name'] = this.name;
    if (!!this.locationId) data['locationId'] = this.locationId;
    if (this.active !== null) data['active'] = this.active;
    if (this.supplierIds !== null) data['supplierIds'] = this.supplierIds;
    if (!!this.image) data['image'] = this.image;
    if (!!this.productType) data['productType'] = this.productType;
    if (!!this.subType) data['subType'] = this.subType;
    if (!!this.types?.length) data['types'] = this.types;
    if (!!this.subTypes?.length) data['subTypes'] = this.subTypes;
    if (!!this.producerId) data['producerId'] = this.producerId;
    if (!!this.onlyUnique) data['onlyUnique'] = this.onlyUnique;
    if (!!this.customerId) data['customerId'] = this.customerId;
    if (!!this.collectionId) data['collectionId'] = this.collectionId;
    if (!!this.producerIds) data['producerIds'] = this.producerIds;
    if (this.onlyArchived !== null) data['onlyArchived'] = this.onlyArchived;

    data['limit'] = this.limit;
    data['skip'] = this.skip;

    return data;
  }
}

export default SearchProductsParams;
