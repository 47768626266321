import axios from 'axios';
import {
  getRequestConfig,
  REQUEST_TYPES,
  replaceUrlPlaceholders
} from  '../request';
import {
  PAYMENT_METHODS,
  checkNested,
} from '../../helpers';
import API_END_POINTS from  '../endpoints';

/**
 * Add payment method
 * @param {object} customer
 * @param {string} method
 * @param {array} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerAddPaymentMethod = function(customer, method, data, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.CUSTOMER_ADD_PAYMENT_METHOD, {
    'cr': customer.id,
    'method' : method
  });

  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  // Set request params
  data = data || {};
  config.data = data;
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Add credit card
 * @param {object} stripePayload
 * @param {object} customer
 * @param {array} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerAddCreditCard = function(stripePayload, customer, data, onSuccess, onError) {
  data = data || {};
  data.token = stripePayload.token;
  // Make card as default payment method
  if (!checkNested(customer, 'paymentMethods') && customer.paymentMethods.length === 0) {
    data.default = true;
  }
  apiCustomerAddPaymentMethod(customer, PAYMENT_METHODS.STRIPE_CREDIT_CARD, data,
    response => onSuccess && onSuccess(response),
    error => onError && onError("Something went wrong while adding customer payment method")
  );
};

/**
 * Make payment method default
 * @param {object} customer
 * @param {string} paymentMethodId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerMakePaymentMethodDefault = function(customer, paymentMethodId, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.CUSTOMER_MAKE_PAYMENT_METHOD_DEFAULT, {
    'cr': customer.id,
    'paymentMethodId' : paymentMethodId
  });

  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Delete payment method
 * @param {object} customer
 * @param {string} paymentMethodId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerDeletePaymentMethod = function(customer, paymentMethodId, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.CUSTOMER_DELETE_PAYMENT_METHOD, {
    'cr': customer.id,
    'paymentMethodId' : paymentMethodId
  });

  const config = getRequestConfig(REQUEST_TYPES.DEL, url);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};