import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { PrimaryButton } from '../../components';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    padding: 40,
    minWidth: 320,
    maxWidth: 900,
    minHeight: 260,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  errorWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flex: 1,
    height: '100%',
  },
  error: {
    fontSize: 16,
    fontWeight: 400,
    textAlign: 'center',
  },
  closeBtn: {
    position: 'absolute',
    right: 15,
    top: 12,
    zIndex: 100,
  },
  dialogTitle: {
    textAlign: 'center',
    padding: '30px 0 40px 0',
    fontWeight: '300',
    color: '#363636',
  },
  controls: {
    width: '100%',
  },
  button: {
    width: '100%',
  },
}));

const ErrorDialog = (props) => {
  const { open, containerStyle, onClose, error, ...restProps } = props;
  const classes = useStyles();
  const intl = useIntl();

  const getError = () => {
    const somethingWentWrong = intl.formatMessage({ id: 'messages.somethingWentWrong' });

    if (typeof error === 'string') {
      return error;
    }
    if (typeof error === 'object') {
      return (error && error.response && error.response.data && error.response.data.message) || somethingWentWrong;
    }

    return somethingWentWrong;
  };

  return (
    <Dialog open={open} onClose={onClose} {...restProps}>
      <IconButton className={classes.closeBtn} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <div className={classes.root}>
        <div className={classes.errorWrapper} style={containerStyle}>
          <p className={classes.error}>{getError()}</p>
        </div>

        <div className={classes.controls}>
          <PrimaryButton onClick={onClose} className={classes.button}>
            {intl.formatMessage({ id: 'global.ok' })}
          </PrimaryButton>
        </div>
      </div>
    </Dialog>
  );
};

export default ErrorDialog;
