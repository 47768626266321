import moment from 'moment';

export const getShippingDays = (rates, type) => {
  let days = [];
  for (let key in rates) {
    if (rates.hasOwnProperty(key) && typeof(rates[key][type]) !== 'undefined') {
      const {next, until} = rates[key][type];

      days.push({
        day: key,
        next: next,
        until: until,
        formatted: {
          next: moment.parseZone(next).format(process.env.REACT_APP_DATE_FORMAT_WITH_SHORT_DAY_OF_WEEK),
          nextWithFullWeekDay: moment.parseZone(next).format(process.env.REACT_APP_DATE_FORMAT_WITH_FULL_DAY_OF_WEEK),
          nextMMDY: moment.parseZone(next).format("MM/D/Y"),
          nextHHMMA: moment.parseZone(next).format("hh:mm A"),
          until: moment.parseZone(until).format(process.env.REACT_APP_DATE_FORMAT_WITH_SHORT_DAY_OF_WEEK + ", hh:mm A"),
          untilMMDY: moment.parseZone(until).format("MM/D/Y"),
          untilHHMMA: moment.parseZone(until).format("hh:mm A"),
        }
      });

      days.sort((a, b) => {
        return moment(a.next).unix() - moment(b.next).unix();
      });
    }
  }
  return days;
};

/**
 * Searches actual shipping date
 * @param dates
 * @return {*}
 */
export const getActualShippingDate = (dates) => {
  const current = moment.tz(process.env.REACT_APP_TIME_ZONE).format('MM/D/Y');
  for (let i = 0; i < dates.length; i++) {
    if (moment.parseZone(dates[i].until).format('MM/D/Y') >= current) {
      return dates[i].next;
    }
  }
  return false;
};





