export default ({
  paper: {
    padding: "40px 52px 44px",
    borderRadius: 8,
    minWidth: 980
  },
  addButtonWrapper: {
    display: 'flex',
    justifyContent: "flex-end",
  },
  deleteWrapper:{
    textAlign: 'center'
  },
  deleteText:{
    fontSize: 16,
  },

  //table
  accountsWrap: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  titleWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonWrapper: {
    marginTop: 10,
    marginBottom: 20,
  },
  root: {
    flexGrow: 1,
  },
  mb20: {
    marginBottom: 20,
  },
  alignRight: {
    textAlign: 'right'
  },
  mt20: {
    marginTop: 10
  },
  gridListTile: {
    height: 300,
    padding: 20
  },
  headerButton: {
    marginLeft: 10,
    height: 20,
    width: 20,
    padding: 0,
    fontSize: '10px',
    minHeight: 25,
    minWidth: 'auto',
    borderRadius: 10
  },
  empty: {
    minHeight: 180,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    fontFamily: "'Roboto', sans-serif",
  },
  bold: {
    fontWeight: 'bold'
  },
  headerButtonIcon: {
    fontSize: 20,
  },
  pointer: {
    cursor: 'pointer'
  },
  sectionWrapper: {
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  buttonContainer: {
    marginTop: 30
  }
});
