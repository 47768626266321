import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withRouter } from '../../../hocs';
import { withStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import { apiGetSharedProperties } from '../../../api';
import { AdminDXTable, AdminTabContentHeader, TabActions } from '../../../components';
import { TableCell } from '../helpers';
import {
  COLORS,
  countWidthOfColumns,
  DEFAULT_MAX_CELL_WIDTH,
  DEFAULT_MIN_CELL_WIDTH,
  SHARED_PROPERTY_TYPES,
} from '../../../helpers';
import {
  apiCreateSharedProperty,
  apiUpdateSharedProperty,
  apiDeleteSharedProperty,
  apiReplaceSharedProperty,
} from '../../../api';
import { UpsertProductSharedPropertyDialog, DeleteProductSharedPropertyDialog } from '../../../dialogs';
import styles from './styles';
import { getGridPageSize, getGridPageSizes } from '../../../helpers/grid';

const propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf([SHARED_PROPERTY_TYPES.TYPE, SHARED_PROPERTY_TYPES.UNIT, SHARED_PROPERTY_TYPES.SUB_TYPE]),
};
const defaultProps = {};

class Tab extends React.Component {
  constructor(props) {
    super(props);
    const { intl, name } = props;

    this.columnExtensions = [
      { columnName: 'image', width: 90 },
      { columnName: 'name' },
      { columnName: 'total', width: 150, align: 'right' },
      { columnName: 'active', width: 150, align: 'right' },
    ];

    this.columns = [
      { name: 'image', title: ' ', type: 'image' },
      { name: 'name', title: name, type: 'text' },
      { name: 'total', title: intl.formatMessage({ id: 'products.props.column.totalProducts' }) },
      { name: 'active', title: intl.formatMessage({ id: 'products.props.column.activeProducts' }) },
    ];

    this.state = {
      loading: true,
      creating: false,
      updating: null,
      deleting: null,
      typeNames: [],
      types: [],

      columnWidths: countWidthOfColumns(this.columns, [], this.columnExtensions, 550),
    };
  }

  componentWillUnmount() {
    this.table = null;
  }

  /** Gets shared properties */
  _retrieve = (queryParams, onSuccess, onError) => {
    const { type } = this.props;
    apiGetSharedProperties(
      queryParams,
      type,
      (rows) => {
        this.setState({
          typeNames: rows.map((obj) => obj.name),
          types: rows,
        });
        onSuccess(rows);
      },
      onError
    );
  };

  handleDelete = ({ object, isAttached, replaceWith, typeName }) => {
    if (isAttached) {
      // Replace shared property
      apiReplaceSharedProperty(
        object.id,
        typeName,
        { id: replaceWith.id },
        (rows) => {
          this.table.onUpdateRowsExternally(rows);
          this.setState({
            deleting: null,
            typesNames: rows.map((obj) => obj.name),
            types: rows,
          });
        },
        () => {}
      );
    } else {
      // Delete shared property
      apiDeleteSharedProperty(
        object.id,
        typeName,
        (rows) => {
          this.table.onUpdateRowsExternally(rows);
          this.setState({
            deleting: null,
            typesNames: rows.map((obj) => obj.name),
            types: rows,
          });
        },
        () => {}
      );
    }
  };

  handleUpdate = (property, data) => {
    const { type } = this.props;
    apiUpdateSharedProperty(
      property.id,
      type,
      data,
      (rows) => {
        this.table.onUpdateRowsExternally(rows);
        this.setState({
          updating: null,
          typesNames: rows.map((obj) => obj.name),
          types: rows,
        });
      },
      () => {}
    );
  };

  handleCreate = (property, data) => {
    const { type } = this.props;
    apiCreateSharedProperty(
      type,
      data,
      (rows) => {
        this.table.onUpdateRowsExternally(rows);
        this.setState({
          creating: false,
          typesNames: rows.map((obj) => obj.name),
          types: rows,
        });
      },
      () => {}
    );
  };

  render() {
    const { intl, classes, type } = this.props;

    const { creating, deleting, updating } = this.state;

    return (
      <div className={classes.wrapper}>
        <div className={classes.table}>
          <AdminTabContentHeader
            actions={
              <TabActions
                onCreateEntity={() => this.setState({ creating: true })}
                labels={{
                  createEntity: intl.formatMessage({ id: `${type}.new` }),
                }}
              />
            }
          />
          <AdminDXTable
            onRef={(table) => (this.table = table)}
            apiRetrieve={this._retrieve}
            cellRenderer={(...props) => {
              return TableCell(type, ...props);
            }}
            enableSearch
            pageSize={getGridPageSize()}
            pageSizes={getGridPageSizes()}
            currencyColumnNames={[]}
            columnExtensions={this.columnExtensions}
            actionsTitle={intl.formatMessage({ id: 'global.actions' })}
            columns={this.columns}
            sorting={[{ columnName: 'name', direction: 'asc' }]}
            actionsHeaderCellStyle={{ textAlign: 'center', paddingRight: 0 }}
            actionsCellStyle={{ textAlign: 'center', paddingRight: 0 }}
            actions={[
              {
                icon: <EditIcon style={{ color: COLORS.text, fontSize: 18 }} />,
                action: (row) => this.setState({ updating: row }),
              },
              {
                icon: <CancelIcon style={{ color: COLORS.text, fontSize: 18 }} />,
                action: (row) => this.setState({ deleting: row }),
              },
            ]}
            columnWidths={this.state.columnWidths}
            onColumnWidthsChange={(widths) => {
              this.setState({ columnWidths: widths });
            }}
            minColumnWidth={DEFAULT_MIN_CELL_WIDTH}
            maxColumnWidth={DEFAULT_MAX_CELL_WIDTH}
            allowExpandAll={true}
          />
        </div>

        {creating && (
          <UpsertProductSharedPropertyDialog
            onClose={() => this.setState({ creating: false })}
            typeNames={this.state.typeNames}
            type={type}
            withTaxRate={type === SHARED_PROPERTY_TYPES.TYPE}
            withSharedTypes={type === SHARED_PROPERTY_TYPES.SUB_TYPE}
            withImage={type === SHARED_PROPERTY_TYPES.SUB_TYPE}
            onSave={this.handleCreate}
          />
        )}

        {updating && (
          <UpsertProductSharedPropertyDialog
            onClose={() => this.setState({ updating: null })}
            typeNames={this.state.typeNames}
            property={this.state.updating}
            type={type}
            withTaxRate={type === SHARED_PROPERTY_TYPES.TYPE}
            withSharedTypes={type === SHARED_PROPERTY_TYPES.SUB_TYPE}
            withImage={type === SHARED_PROPERTY_TYPES.SUB_TYPE}
            onSave={this.handleUpdate}
          />
        )}

        {deleting && (
          <DeleteProductSharedPropertyDialog
            onClose={() => this.setState({ deleting: null })}
            typeName={type}
            object={deleting}
            types={this.state.types}
            onDelete={this.handleDelete}
          />
        )}
      </div>
    );
  }
}

Tab.propTypes = propTypes;
Tab.defaultProps = defaultProps;

export default withStyles(styles)(withRouter(injectIntl(Tab)));
