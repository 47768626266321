import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../hocs';
import {
  AdminDialog,
  AdminDialogContent,
  AdminDialogControls,
  AdminDialogTitle,
  CustomersSuggestions,
  ErrorBox,
  PrimaryButton,
  PrimaryCheckbox,
  Spinner,
} from '../../components';
import styles from './styles';
import {
  apiQuickBooksGetCustomersByName,
  apiQuickBooksAddCustomerToAccount,
  apiOrderBook,
  apiRetrieveOrder,
} from '../../api';
import Chip from '@material-ui/core/Chip';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const propTypes = {
  url: PropTypes.string,
  classes: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
};

const defaultProps = {};

const QuickBooksAddCustomerDialog = ({ onClose, intl, classes, row, forceReload }) => {
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState({});
  const [bookToQuickBooks, setBookToQuickBooks] = useState(false);
  const [order, serOrder] = useState({});
  const [error, setError] = useState('');

  useEffect(() => {
    apiRetrieveOrder(
      row.id,
      (response) => {
        setLoading(false);
        serOrder(response);
      },
      onError
    );
  }, [row.id]);

  const onError = (error) => {
    setError(error.response.data.message);
    setLoading(false);
  };

  const _handleAddCustomer = async (customer) => {
    setCustomer(customer);
  };

  const _handleDeleteCustomer = () => {
    setCustomer({});
  };

  const _handleAttachCustomer = () => {
    setLoading(true);

    const data = {
      customerId: order.customer.id,
      ...(bookToQuickBooks
        ? {
            orderId: order.id,
            bookToQuickBooks: !bookToQuickBooks,
          }
        : { qbCustomerId: customer.id.toString() }),
    };

    apiQuickBooksAddCustomerToAccount(
      data,
      () => {
        if (!bookToQuickBooks) {
          apiOrderBook(
            row.id,
            () => {
              setLoading(false);
              forceReload();
              onClose && onClose();
            },
            onError
          );
        } else {
          setLoading(false);
          forceReload();
          onClose && onClose();
        }
      },
      onError
    );
  };

  const onCheckboxChange = (event) => {
    setCustomer({});
    setBookToQuickBooks(event.target.checked);
  };

  return (
    <AdminDialog open closeButton onClose={onClose}>
      <AdminDialogTitle title={intl.formatMessage({ id: 'global.mapCustomer' })} />
      <AdminDialogContent className={classes.wrapper}>
        {loading && <Spinner size={50} />}
        {error && <ErrorBox error={error} />}
        {!loading && !error && (
          <>
            <CustomersSuggestions
              selected={null}
              retriever={apiQuickBooksGetCustomersByName}
              labelExtractor={(item) => item.name}
              {...(bookToQuickBooks
                ? {
                    inputProps: { disabled: true, value: '' },
                  }
                : {})}
              inputLabel={intl.formatMessage({ id: 'global.customer' })}
              onDetach={() => {}}
              onAttach={(customer) => _handleAddCustomer(customer)}
            />

            {customer.id && (
              <div className={classes.chipsWrap}>
                <Chip
                  classes={{ root: classes.chip }}
                  key={customer.id}
                  label={`${customer.name}`}
                  onDelete={() => _handleDeleteCustomer()}
                  className={classes.chip}
                />
              </div>
            )}

            <FormControlLabel
              control={<PrimaryCheckbox checked={bookToQuickBooks} onChange={onCheckboxChange} color="default" />}
              label={intl.formatMessage({ id: 'global.dontBookCustomer' })}
            />

            <AdminDialogControls>
              <PrimaryButton
                className={classes.button}
                disabled={!customer.id && !bookToQuickBooks}
                onClick={_handleAttachCustomer}>
                {intl.formatMessage({ id: 'global.submit' })}
              </PrimaryButton>
            </AdminDialogControls>
          </>
        )}
      </AdminDialogContent>
    </AdminDialog>
  );
};

QuickBooksAddCustomerDialog.propTypes = propTypes;
QuickBooksAddCustomerDialog.defaultProps = defaultProps;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(QuickBooksAddCustomerDialog)));
