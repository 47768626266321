import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { FONT_FAMILY, FONT_SIZES } from '../../constants/fonts';
import { COLORS } from '../../constants/colors';

const propTypes = {
  text: PropTypes.string.isRequired,
};

const Title = (props) => {
  const { text, fontSize = FONT_SIZES.title, fontWeight = '700', color = COLORS.blackTintedBlue, ...other } = props;
  return (
    <Typography
      style={{ fontFamily: FONT_FAMILY, fontSize, fontWeight, color }}
      variant={'h1'}
      component={'h1'}
      {...other}>
      {text}
    </Typography>
  );
};

Title.propTypes = propTypes;

export default Title;
