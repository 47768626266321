import React from 'react';
import moment from 'moment';
import { withRouter } from '../../hocs';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { SidebarMenu } from '../Partials';

import { connect } from 'react-redux';
import HeaderMenu from '../Partials/HeaderMenu/HeaderMenu';
import { AdminTabs, AdminLayout } from '../../components';
import styles from './styles';
import { approvedFilter, activeFilter, customerTypeFilter } from './helpers';
import CustomersTab from './CustomersTab';
import TabTypes from '../../dialogs/SaveTabSettingsDialog/Types';
import { setProducer } from '../../store/actions';
import { MAIN_TAB_NAMES } from '../../helpers/tabs';
import { apiCreateTab, apiDeleteTab, apiPatchTab } from '../../api';
import Spinner from '../../components/Spinner/Spinner';
import GroupedByName from './GroupedByNameTab';
import { CUSTOMER_TYPE } from '../../helpers';

class CustomersScene extends React.Component {
  constructor(props) {
    super(props);
    const { intl, tabs } = this.props;

    this.columns = [
      { name: 'companyName', title: intl.formatMessage({ id: 'customer.table.companyName' }) },
      { name: 'firstName', title: intl.formatMessage({ id: 'customer.table.firstName' }) },
      { name: 'lastName', title: intl.formatMessage({ id: 'customer.table.lastName' }) },
      { name: 'email', title: intl.formatMessage({ id: 'customer.table.email' }) },
      {
        name: 'billingAddress.address',
        title: intl.formatMessage({ id: 'customer.table.address' }),
        getCellValue: (row) => (row.defaultBillingAddress ? row.defaultBillingAddress.address1 : undefined),
      },
      {
        name: 'billingAddress.city',
        title: intl.formatMessage({ id: 'customer.table.city' }),
        getCellValue: (row) => (row.defaultBillingAddress ? row.defaultBillingAddress.city : undefined),
      },
      {
        name: 'billingAddress.state',
        title: intl.formatMessage({ id: 'customer.table.state' }),
        getCellValue: (row) => (row.defaultBillingAddress ? row.defaultBillingAddress.province : undefined),
      },
      {
        name: 'billingAddress.zipCode',
        title: intl.formatMessage({ id: 'customer.table.zipCode' }),
        getCellValue: (row) => (row.defaultBillingAddress ? row.defaultBillingAddress.zip : undefined),
      },
      {
        name: 'shippingAddress.address',
        title: intl.formatMessage({ id: 'customer.table.address' }),
        getCellValue: (row) => (row.defaultShippingAddress ? row.defaultShippingAddress.address1 : undefined),
      },
      {
        name: 'shippingAddress.city',
        title: intl.formatMessage({ id: 'customer.table.city' }),
        getCellValue: (row) => (row.defaultShippingAddress ? row.defaultShippingAddress.city : undefined),
      },
      {
        name: 'shippingAddress.state',
        title: intl.formatMessage({ id: 'customer.table.state' }),
        getCellValue: (row) => (row.defaultShippingAddress ? row.defaultShippingAddress.province : undefined),
      },
      {
        name: 'shippingAddress.zipCode',
        title: intl.formatMessage({ id: 'customer.table.zipCode' }),
        getCellValue: (row) => (row.defaultShippingAddress ? row.defaultShippingAddress.zip : undefined),
      },
      {
        name: 'defaultPaymentMethod',
        title: intl.formatMessage({ id: 'customer.table.defaultPaymentMethod' }),
        getCellValue: (row) =>
          row.defaultPaymentMethod
            ? intl.formatMessage({ id: `global.${row.defaultPaymentMethod.method}` })
            : undefined,
      },
      {
        name: 'allowCredit',
        title: intl.formatMessage({ id: 'customer.table.allowCredit' }),
        getCellValue: (row) =>
          row.allowCredit ? intl.formatMessage({ id: 'global.yes' }) : intl.formatMessage({ id: 'global.no' }),
      },
      {
        name: 'allowShipping',
        title: intl.formatMessage({ id: 'customer.table.allowShipping' }),
        getCellValue: (row) =>
          row.allowShipping ? intl.formatMessage({ id: 'global.yes' }) : intl.formatMessage({ id: 'global.no' }),
      },
      {
        name: 'terms',
        title: intl.formatMessage({ id: 'customer.table.terms' }),
        getCellValue: (row) => (row.paymentTerms ? row.paymentTerms : ''),
      },
      {
        name: 'created',
        title: intl.formatMessage({ id: 'customer.table.created' }),
        getCellValue: (row) => (row.createdAt ? moment.parseZone(row.createdAt).format('MMM D, YYYY') : ''),
      },
      {
        name: 'lastOrder',
        title: intl.formatMessage({ id: 'customer.table.lastOrder' }),
        getCellValue: (row) => (row.lastOrder ? moment.parseZone(row.lastOrder).format('MMM D, YYYY') : '-'),
      },
      { name: 'ordered', title: intl.formatMessage({ id: 'customer.table.numberOfOrders' }) },
    ];

    this.columnBands = [
      {
        title: intl.formatMessage({ id: 'customer.table.billingAddress' }),
        children: [
          { columnName: 'billingAddress.address' },
          { columnName: 'billingAddress.city' },
          { columnName: 'billingAddress.state' },
          { columnName: 'billingAddress.zipCode' },
        ],
      },
      {
        title: intl.formatMessage({ id: 'customer.table.shippingAddress' }),
        children: [
          { columnName: 'shippingAddress.address' },
          { columnName: 'shippingAddress.city' },
          { columnName: 'shippingAddress.state' },
          { columnName: 'shippingAddress.zipCode' },
        ],
      },
    ];

    this.state = {
      tabIndex: tabs.getInitialIndex(window.location.hash, true),
      width: 0,
    };
  }

  getColumnExtensions = () => {
    return [];
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      width: window.innerWidth - 290,
    });
  };

  onCreateTab = (data, onDone) => {
    this.setState({ loading: true });
    apiCreateTab(
      data,
      (producer) => {
        this.props.setProducer(producer);
        this.setState((state) => {
          state.loading = false;
          onDone && onDone();
          return state;
        });
      },
      undefined
    );
  };

  onDeleteTab = (tab) => {
    this.setState({ loading: true });
    apiDeleteTab(
      tab.id,
      (producer) => {
        this.setState(
          {
            loading: false,
            tabIndex: 0,
          },
          () => {
            this.props.setProducer(producer);
          }
        );
      },
      undefined
    );
  };

  onPatchTab = (tab, data, onDone) => {
    this.setState({ loading: true });
    apiPatchTab(
      tab.id,
      data,
      (producer) => {
        this.props.setProducer(producer);
        this.setState({ loading: false });
        onDone && onDone();
      },
      undefined
    );
  };

  onPatchDefaultTab = (name, tab, data, onDone) => {
    const { tabs } = this.props;
    let customersTab = tabs.getTabByName(name);
    if (customersTab) {
      this.onPatchTab(customersTab, data, onDone);
    } else {
      data.name = name;
      data.type = TabTypes.CUSTOMERS;
      this.onCreateTab(data);
    }
  };

  onChangeTab = (index) => {
    const { tabs } = this.props;
    window.location.hash = tabs.getHashByIndex(index);
    this.setState({ tabIndex: index });
  };

  render() {
    const { tabIndex, loading } = this.state;
    const { intl, tabs } = this.props;

    const tabsTitles = tabs.getTabNames([
      intl.formatMessage({ id: 'customers.tab.approved' }),
      intl.formatMessage({ id: 'customers.tab.users' }),
      intl.formatMessage({ id: 'customers.tab.retail' }),
    ]);

    const customTabs = tabs.getOnlyCustom();

    let approvedTab = tabs.getTabByName(
      MAIN_TAB_NAMES.CUSTOMERS_APPROVED,
      intl.formatMessage({ id: 'customers.tab.approved' }),
      {
        id: MAIN_TAB_NAMES.CUSTOMERS_APPROVED,
        name: intl.formatMessage({ id: 'customers.tab.approved' }),
      }
    );

    let groupedByNameTab = tabs.getTabByName(
      MAIN_TAB_NAMES.CUSTOMERS_GROUPED_BY_NAME,
      intl.formatMessage({ id: 'customers.tab.users' }),
      {
        id: MAIN_TAB_NAMES.CUSTOMERS_GROUPED_BY_NAME,
        name: intl.formatMessage({ id: 'customers.tab.users' }),
      }
    );

    let retailTab = tabs.getTabByName(
      MAIN_TAB_NAMES.CUSTOMERS_RETAIL,
      intl.formatMessage({ id: 'customers.tab.retail' }),
      {
        id: MAIN_TAB_NAMES.CUSTOMERS_RETAIL,
        name: intl.formatMessage({ id: 'customers.tab.retail' }),
      }
    );

    const drawCustomTab = () => {
      const customTabKey = customTabs[tabIndex - 3] && customTabs[tabIndex - 3].id;

      return (
        <CustomersTab
          onRef={(tab) => (this.tab = tab)}
          serverSideFilters={activeFilter(intl, approvedFilter(intl, approvedTab.filters))}
          key={customTabKey}
          tab={customTabs[tabIndex - 3]}
          width={this.state.width}
          columns={this.columns}
          columnBands={this.columnBands}
          columnExtensions={this.getColumnExtensions()}
          actionsColumnProps={{ rowspan: 2 }}
          onPatchTab={this.onPatchTab}
          onDeleteTab={this.onDeleteTab}
        />
      );
    };

    return (
      <AdminLayout sidebarComponent={SidebarMenu} headerMenuComponent={HeaderMenu}>
        {loading && <Spinner size={60} />}

        <AdminTabs onChangeTab={this.onChangeTab} tab={tabIndex} tabs={tabsTitles}>
          {tabIndex === 0 && (
            <CustomersTab
              onRef={(tab) => (this.tab = tab)}
              columns={this.columns}
              allowCreateTab
              tab={approvedTab}
              width={this.state.width}
              columnBands={this.columnBands}
              columnExtensions={this.getColumnExtensions()}
              serverSideFilters={activeFilter(intl, approvedFilter(intl, approvedTab.filters))}
              actionsColumnProps={{ rowspan: 2 }}
              onPatchTab={(tab, data, onDone) =>
                this.onPatchDefaultTab(MAIN_TAB_NAMES.CUSTOMERS_APPROVED, tab, data, onDone)
              }
              onCreateTab={this.onCreateTab}
            />
          )}

          {tabIndex === 1 && (
            <GroupedByName
              onRef={(tab) => (this.tab = tab)}
              allowCreateTab
              tab={groupedByNameTab}
              width={this.state.width}
              columnExtensions={this.getColumnExtensions()}
              serverSideFilters={activeFilter(intl, approvedFilter(intl, approvedTab.filters))}
              actionsColumnProps={{ rowspan: 2 }}
              onPatchTab={(tab, data, onDone) =>
                this.onPatchDefaultTab(MAIN_TAB_NAMES.CUSTOMERS_GROUPED_BY_NAME, tab, data, onDone)
              }
              onCreateTab={this.onCreateTab}
            />
          )}

          {tabIndex === 2 && (
            <CustomersTab
              onRef={(tab) => (this.tab = tab)}
              columns={this.columns.filter((column) => column.name !== 'companyName')}
              allowCreateTab
              customersType={CUSTOMER_TYPE.RETAIL}
              tab={retailTab}
              width={this.state.width}
              columnBands={this.columnBands}
              columnExtensions={this.getColumnExtensions()}
              // We should ignore `approved` property for `retail` customers (It will be always true)
              serverSideFilters={activeFilter(intl, customerTypeFilter(intl, CUSTOMER_TYPE.RETAIL))}
              actionsColumnProps={{ rowspan: 2 }}
              onPatchTab={(tab, data, onDone) =>
                this.onPatchDefaultTab(MAIN_TAB_NAMES.CUSTOMERS_RETAIL, tab, data, onDone)
              }
              onCreateTab={this.onCreateTab}
            />
          )}

          {!!customTabs.length && tabIndex > 2 && drawCustomTab()}
        </AdminTabs>
      </AdminLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tabs: state.producer.tabs[TabTypes.CUSTOMERS],
    producer: state.producer.object,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProducer: (producer) => dispatch(setProducer(producer)),
  };
};

export default withStyles(styles)(withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(CustomersScene))));
