import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../hocs';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PlusIcon from '@material-ui/icons/AddCircleOutline';
import IconButton from '@material-ui/core/IconButton';
import { Spinner, PlaidLink, PrimaryButton, ErrorBox, MessageBox } from '../../components';
import {
  apiProducerInvoiceAddBankAccountViaPlaid,
  apiProducerPaymentSettingsGet,
  apiProducerStripePersonGetAll,
  apiProducerStripeOnBoard,
} from '../../api';
import SubWrapper from './Components/SubWrapper';
import Wrapper from './Components/Wrapper';
import Block from './Components/Block';
import CompanyAccount from './Components/CompanyAccount';
import PersonAccount from './Components/PersonAccount';
import ExternalAccount from './Components/ExternalAccount';
import BankAccountDialog from './Dialogs/BankAccountDialog/Dialog';
import BusinessEntityHeader from './Components/BusinessEntityHeader';
import BlockSeparator from './Components/BlockSeparator';
import { COLORS } from '../../helpers';
import { setProducer } from '../../store/actions';
import { Stripe } from '../../entities';

const styles = {
  wrapper: {
    marginBottom: 20,
  },
  title: {
    fontSize: 25,
    display: 'inline-block',
    color: COLORS.text,
    fontWeight: 300,
    fontFamily: 'Roboto, sans-serif',
  },
  add: {
    padding: 0,
    marginLeft: 10,
  },
  text: {
    fontSize: 14,
    color: COLORS.textSecondary,
    fontFamily: 'Roboto, sans-serif',
  },
  accounts: {
    display: 'flex',
    flexDirection: 'row',
    width: 368,
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  stripeButton: {
    marginBottom: 20,
  },
  noHorizontalMargin: {
    marginLeft: 0,
    marginRight: 0,
  },
};

class PaymentTab extends React.Component {
  state = {
    error: '',
    message: '',
    loading: true,
    settings: null,
    stripePersons: [],
    addingExternalAccount: false,
    stripe_boarding_status: null,
  };

  componentDidMount() {
    const { intl } = this.props;

    const params = new URLSearchParams(document.location.search);
    const stripe_boarding_status = params.get('stripe_boarding_status');

    if (stripe_boarding_status) {
      this.setState({
        stripe_boarding_status,
        ...(Stripe.isBoardingStatusFailure(stripe_boarding_status)
          ? {
              error: intl.formatMessage({ id: 'stripe.boarding.failure' }),
            }
          : {}),
        ...(Stripe.isBoardingStatusSuccess(stripe_boarding_status)
          ? {
              message: intl.formatMessage({ id: 'stripe.boarding.success' }),
            }
          : {}),
      });
    }

    this._fetchPaymentSettings();
  }

  _addPlaidACH = (token, meta) => {
    apiProducerInvoiceAddBankAccountViaPlaid(
      token,
      meta,
      (response) => {
        this.props.setProducer(response);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  _fetchPaymentSettings = () => {
    apiProducerPaymentSettingsGet(
      (settings) => {
        this.setState({ settings });

        if (settings.stripeAccountExist) {
          apiProducerStripePersonGetAll((stripePersons) => {
            this.setState({
              stripePersons,
              loading: false,
            });
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      },
      () => {
        this.setState({ loading: false });
      }
    );
  };

  _onBoarding = () => {
    const {
      props: { intl },
      state: { settings },
    } = this;

    this.setState({ loading: true });

    const type = Stripe.getOnBoardingType(settings),
      successUrl = Stripe.getOnBoardingSuccessUrl(),
      failureUrl = Stripe.getOnBoardingFailureUrl();
    apiProducerStripeOnBoard(type, successUrl, failureUrl)
      .then(({ data }) => {
        window.location.href = data.url;
      })
      .catch(() => {
        this.setState({
          error: intl.formatMessage({ id: 'messages.somethingWentWrong' }),
          loading: false,
        });
      });
  };

  render() {
    const { intl, classes, producer } = this.props;
    const { stripePersons, error, message, loading, settings } = this.state;

    return (
      <Wrapper>
        <ErrorBox error={error} onClose={() => this.setState({ error: '' })} />

        <MessageBox
          message={message}
          className={classes.noHorizontalMargin}
          onClose={() => this.setState({ message: '' })}
        />

        {this.state.loading && <Spinner size={80} />}

        {!Stripe.exists(settings) && (
          <PrimaryButton className={classes.stripeButton} disabled={loading} onClick={this._onBoarding}>
            {intl.formatMessage({ id: 'stripe.setup' })}
          </PrimaryButton>
        )}

        {Stripe.requireUpdate(settings) && (
          <PrimaryButton className={classes.stripeButton} disabled={loading} onClick={this._onBoarding}>
            {intl.formatMessage({ id: 'stripe.update' })}
          </PrimaryButton>
        )}

        {!this.state.loading && Stripe.exists(settings) && (
          <React.Fragment>
            <SubWrapper>
              <Block headerComponent={() => <BusinessEntityHeader />} wrapperStyles={{ width: 700 }}>
                <CompanyAccount settings={this.state.settings} />

                {this.state.settings.company && !!stripePersons.length && (
                  <React.Fragment>
                    <BlockSeparator title={intl.formatMessage({ id: 'global.managementAndOwnership' })} />
                    {stripePersons.map((person) => (
                      <PersonAccount key={person.id} person={person} />
                    ))}
                  </React.Fragment>
                )}
              </Block>
            </SubWrapper>

            {this.state.settings.company && (
              <div className={classes.wrapper}>
                <div className={classes.titleWrapper}>
                  <Typography className={classes.title} variant={'h5'}>
                    {intl.formatMessage({ id: 'producer.payment.accountReceivable' })}
                  </Typography>

                  {!this.state.settings.external_accounts.length && (
                    <IconButton className={classes.add} onClick={() => this.setState({ addingExternalAccount: true })}>
                      <PlusIcon color="secondary" />
                    </IconButton>
                  )}
                </div>

                <div className={classes.accounts}>
                  {this.state.settings.external_accounts.map((account) => (
                    <Block key={account.id}>
                      <ExternalAccount account={account} />
                    </Block>
                  ))}

                  {!this.state.settings.external_accounts.length && (
                    <Typography style={{ width: '100%' }} className={classes.text} variant={'body1'}>
                      {intl.formatMessage({ id: 'messages.notAdded' })}
                    </Typography>
                  )}
                </div>
              </div>
            )}

            <div className={classes.wrapper}>
              <div className={classes.titleWrapper}>
                <Typography className={classes.title} variant={'h5'}>
                  {intl.formatMessage({ id: 'producer.payment.accountPayable' })}
                </Typography>
                {(!producer.invoiceSettings || !producer.invoiceSettings.paymentMethod) && (
                  <PlaidLink
                    buttonComponent={IconButton}
                    product={['auth']}
                    buttonComponentProps={{ className: classes.add }}
                    onSuccess={(...props) => this._addPlaidACH(...props)}>
                    <PlusIcon color="secondary" />
                  </PlaidLink>
                )}
              </div>

              <div className={classes.accounts}>
                {producer.invoiceSettings && producer.invoiceSettings.paymentMethod && (
                  <Block>
                    <ExternalAccount account={producer.invoiceSettings.paymentMethod} />
                  </Block>
                )}

                {(!producer.invoiceSettings || !producer.invoiceSettings.paymentMethod) && (
                  <Typography style={{ width: '100%' }} className={classes.text} variant={'body1'}>
                    {intl.formatMessage({ id: 'messages.notAdded' })}
                  </Typography>
                )}
              </div>
            </div>
          </React.Fragment>
        )}

        {this.state.addingExternalAccount && (
          <BankAccountDialog
            entity={{}}
            onClose={() => this.setState({ addingExternalAccount: false })}
            onDone={(settings) =>
              this.setState({
                addingExternalAccount: false,
                settings: settings,
              })
            }
          />
        )}
      </Wrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setProducer: (producer) => dispatch(setProducer(producer)),
  };
};

export default injectIntl(
  withRouter(
    connect((state) => {
      return { producer: state.producer.object };
    }, mapDispatchToProps)(withStyles(styles)(PaymentTab))
  )
);
