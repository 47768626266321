import React from 'react';

const Unlisted = (props) => {
  const { color = '#000', ...others } = props;
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        d="M15.9999 0H1.99998C0.899992 0 0 0.9 0 2V16C0 17.1 0.889992 18 1.99998 18H15.9999C17.0998 18 17.9998 17.1 17.9998 16V2C17.9998 0.9 17.0998 0 15.9999 0ZM15.9999 16H1.99998V13H5.55995C6.24994 14.19 7.52993 15 9.00992 15C10.4899 15 11.7599 14.19 12.4599 13H15.9999V16ZM15.9999 11H11.0099C11.0099 12.1 10.1099 13 9.00992 13C7.90993 13 7.00993 12.1 7.00993 11H1.99998V2H15.9999V11Z"
        fill={color}
      />
    </svg>
  );
};

export default Unlisted;
