// Auth stages
export const AUTH_EMAIL_AND_PASSWORD_STAGE = 'email-and-password';
export const AUTH_REGISTER_STAGE = 'register';
export const AUTH_REGISTER_STAGE_FINAL = 'register-final';
export const AUTH_RESTORE_PASSWORD_STAGE = 'restore';
export const AUTH_CREATE_PASSWORD_STAGE = 'create-password';
// Checkout stages
export const CHECKOUT_SHIPPING_STAGE = 'shipping';
export const CHECKOUT_PAYMENT_METHOD_STAGE = 'payment-method';
export const CHECKOUT_COMPLETED_STAGE = 'completed';
export const CHECKOUT_SELECT_ADDRESS_STAGE = 'select-address';
export const CHECKOUT_PATCH_ADDRESS_STAGE = 'patch-address';
export const CHECKOUT_ADD_ADDRESS_STAGE = 'add-address';
export const CHECKOUT_ADD_BANK_ACCOUNT_STAGE = 'bank-account';
export const CHECKOUT_ADD_CREDIT_CARD_STAGE = 'credit-card';
export const CHECKOUT_VERIFY_BANK_ACCOUNT_STAGE = 'verify-bank-account';
export const CHECKOUT_PAYMENT_PREFERENCE_STAGE = 'payment-preference';
// Collection view types
export const COLLECTION_VIEW_TYPE_GRID = 'grid';
export const COLLECTION_VIEW_TYPE_LIST = 'list';
export const COLLECTION_VIEW_TYPE_LIST_COMPACT = 'list-compact';
// Hashes
export const AUTH_CREATE_PASSWORD_HASH = '#create-password';
export const AUTH_RESTORE_PASSWORD_HASH = '#restore-password';
// Payment
export const PAYMENT_MANUAL = 'payment-manual';
export const PAYMENT_AUTO = 'payment-auto';
// Addresses
export const BILLING_ADDRESS_TYPE = 'billing';
export const DELIVERY_ADDRESS_TYPE = 'shipping';
export const BILLING_ADDRESS = 'billingAddress';
export const BILLING_ADDRESSES = 'billingAddresses';
export const DELIVERY_ADDRESS = 'shippingAddress';
export const DELIVERY_ADDRESSES = 'shippingAddresses';
// Verified
export const PAYMENT_METHOD_VERIFIED = 'verified';
// Shipping
export const SHIPPING_TYPES = {
  SHIPPING_DELIVERY: 'delivery',
  SHIPPING_PICKUP: 'pickup',
};
// Payment types
export const PAYMENT_TYPES = {
  CHECK: 'check',
  INVOICE: 'invoice',
  PREPURCHASE: 'prepurchase',
  CASH: 'cash',
  COD: 'cod',
  STRIPE_CARD: 'stripe_card',
  STRIPE_ACH: 'stripe_ach',
};
// User types
export const USER_TYPES = {
  ACCOUNT: 'account',
  CUSTOMER: 'customer',
};
// Price levels
export const PRICE_LEVELS = {
  RETAIL_MARKET: 'retail_market',
  RETAIL_PREORDER: 'retail_preorder',
  WHOLESALE_MARKET: 'wholesale_market',
  WHOLESALE_PREORDER: 'wholesale_preorder',
};

export const CUSTOMER_TYPE = {
  RETAIL: 'retail',
  WHOLESALE: 'wholesale',
};

export const FINANCIAL_STATUSES = {
  UNPAID: 'unpaid',
  PAID: 'paid',
  PENDING: 'pending',
  REFUNDED: 'refunded',
  PARTIALLY_REFUNDED: 'partially_refunded',
};

// Order statuses
export const ORDER_STATUSES = {
  ORDER_DRAFT: 'draft',
  ORDER_COMPLETED: 'completed',
  ORDER_RECEIVED: 'received',
  ORDER_NEW: 'new',
  ORDER_PACKED: 'packed',
  ORDER_NOT_APPROVED: 'not_approved',
  ORDER_OUT_FOR_DELIVERY: 'out_for_delivery',
  ORDER_CANCELLED: 'cancelled',
  ORDER_UNPAID: 'unpaid',
  ORDER_PENDING: 'pending',
  ORDER_PAID: 'paid',
};
// Shared properties
export const SHARED_PROPERTY_TYPES = {
  TYPE: 'type',
  SUB_TYPE: 'subType',
  UNIT: 'unit',
};
// Shared properties
export const SUPPLIERS_STANDARD_TABS = {
  ALL: 'all',
};
// Prepurchase types
export const PREPURCHASE_TYPES = {
  AMOUNT: 'amount',
  PERCENTAGE: 'percentage',
};
export const NOTIFICATIONS_TYPES = {
  EMAIL: 'email',
  SMS: 'sms',
  PUSH: 'push',
};
export const TOKEN_EXPIRATION_TIME = {
  WEEKLY: 60 * 60 * 24 * 7, // week 604_800
  MONTHLY: 60 * 60 * 24 * 30, // month 2_592_000(30 days)
};

export const HEADER_HEIGHT_STANDARD = {
  standard: 100,
  extended: 170,
};

export const WIDTH_OF_SIDEBAR = 280;
export const WIDTH_OF_SIDEBAR_COLLAPSED = 73;
export const HEIGHT_OF_TOP_BAR = 50;

export const PREPURCHASES_GROUP_BY = {
  LOCATION: 'location',
  SUB_TYPE: 'subType',
  NO_GROUP: 'no_group',
};

export const TABLE_ROW_DETAIL_TYPES = {
  SUBSCRIPTION_PRODUCTS: 'SUBSCRIPTION_PRODUCTS',
};

export const BILLING_UPFRONT = 'upfront';
export const BILLING_RECURRING = 'recurring';

export const PERIOD_PER_WEEK = 'week';
export const PERIOD_BI_WEEK = 'bi_week';
