import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../hocs';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { SidebarMenu } from '../Partials';
import HeaderMenu from '../Partials/HeaderMenu/HeaderMenu';
import { AdminTabs, AdminLayout, Spinner, withTabsHasher, AdminTabContent, PrimaryButton } from '../../components';
import { PRICE_LEVELS } from '../../helpers';
import CollectionsContent from './CollectionsContent';
import SimpleTab from '../../entities/UI/SimpleTab';
import styles from './styles';
import BaseDialog from '../../dialogs/BaseDialog';
import CollectionForm from '../../forms/CollectionForm';
import { setProducer } from '../../store/actions';

class CollectionsScene extends React.Component {
  constructor(props) {
    super(props);
    const { intl, tabsHasher } = this.props;

    this.state = {
      tab: tabsHasher.getTabIndex(),
      collections: [],
      loading: true,
      columnExtensions: [
        //{ columnName: 'selection', width: 120 },
        { columnName: 'image.src', width: 70 },
        { columnName: 'subType', width: 100 },
        { columnName: 'name', width: 200 },
        { columnName: 'tags', width: 100 },
        { columnName: 'quantity', width: 150, align: 'right' },
        { columnName: 'unit', width: 100 },
        { columnName: 'descriptions', width: 200 },
        { columnName: 'wholesaleMarket', align: 'right' },
        { columnName: 'wholesalePreorder', align: 'right' },
        { columnName: 'retailMarket', align: 'right' },
      ],
      columns: [
        //{ name: 'selection',    title: " ",                                                     },
        { name: 'image.src', title: ' ', type: 'image' },
        { name: 'subType', title: intl.formatMessage({ id: 'products.column.subType' }), type: 'subType' },
        { name: 'name', title: intl.formatMessage({ id: 'products.column.product' }), type: 'name' },
        { name: 'tags', title: intl.formatMessage({ id: 'products.column.tags' }), type: 'tags' },
        { name: 'quantity', title: intl.formatMessage({ id: 'products.column.quantity' }), type: 'quantity' },
        { name: 'unit', title: intl.formatMessage({ id: 'products.column.unit' }), type: 'unit' },
        { name: 'description', title: intl.formatMessage({ id: 'products.column.description' }), type: 'description' },
        {
          name: 'wholesaleMarket',
          title: intl.formatMessage({ id: 'products.column.wholesaleMarket' }),
          type: PRICE_LEVELS.WHOLESALE_MARKET,
        },
        {
          name: 'wholesalePreorder',
          title: intl.formatMessage({ id: 'products.column.wholesalePreorder' }),
          type: PRICE_LEVELS.WHOLESALE_PREORDER,
        },
        {
          name: 'retailMarket',
          title: intl.formatMessage({ id: 'products.column.retailMarket' }),
          type: PRICE_LEVELS.RETAIL_MARKET,
        },
        {
          name: 'retailPreorder',
          title: intl.formatMessage({ id: 'products.column.retailPreorder' }),
          type: PRICE_LEVELS.RETAIL_PREORDER,
        },
      ],
    };
  }

  componentDidMount() {
    const { producer } = this.props;
    this._fetchCollections(producer);
  }

  _fetchCollections = (producer) => {
    this.setState({ loading: true });
    const { tabsHasher } = this.props;
    let tabs = producer.collections.map((collection) => {
      return collection.id;
    });
    tabsHasher.replaceHasherMap(tabs, () => {
      let index = tabsHasher.getTabIndex();
      this.onChangeTab(index);
      this.setState({ loading: false });
    });
  };

  onChangeTab = (index) => {
    const { tabsHasher } = this.props;
    tabsHasher.setHashByIndex(index);
    this.setState({
      tab: index,
    });
  };

  render() {
    const { tab, loading, columns, columnExtensions } = this.state;

    const { classes, intl, tabsHasher, producer, setProducer, openAddModal } = this.props;

    let tabs = [];
    producer.collections.forEach((collection, index) => {
      tabs.push(new SimpleTab(collection.name, tabsHasher.getHrefByIndex(index)));
    });

    return (
      <AdminLayout sidebarComponent={SidebarMenu} headerMenuComponent={HeaderMenu}>
        {loading && <Spinner size={60} />}

        {!loading && (
          <React.Fragment>
            {tab > -1 && (
              <AdminTabs onChangeTab={this.onChangeTab} tab={tab} tabs={tabs}>
                <CollectionsContent
                  tab={tabs[tab]}
                  collection={producer.collections[tab]}
                  key={producer.collections[tab].id}
                  index={tab}
                  columns={columns}
                  onDeleteCollection={(collection, producer) => {
                    tabsHasher.replaceHasherMap(producer.collections, () => {
                      setProducer(producer);
                      this._fetchCollections(producer);
                    });
                  }}
                  columnExtensions={columnExtensions}
                />
              </AdminTabs>
            )}

            {tab === -1 && (
              <AdminTabContent>
                <div className={classes.empty}>
                  <PrimaryButton onClick={() => this.props.history.push('/collections/new')}>
                    {intl.formatMessage({ id: 'tab.newCollection' })}
                  </PrimaryButton>
                </div>
              </AdminTabContent>
            )}
          </React.Fragment>
        )}

        {openAddModal && (
          <BaseDialog
            scroll={'paper'}
            maxWidth="md"
            open
            onClose={() => this.props.history.push('/collections')}
            title={
              this.props.match.params.id === 'new'
                ? intl.formatMessage({ id: 'collections.adding' })
                : intl.formatMessage({ id: 'collections.editing' })
            }>
            <CollectionForm
              {...this.props}
              collections={producer.collections || []}
              onSuccess={(producer) => {
                this.props.history.push('/collections');
                setProducer(producer);
                this._fetchCollections(producer);
              }}
            />
          </BaseDialog>
        )}
      </AdminLayout>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setProducer: (producer) => dispatch(setProducer(producer)),
  };
};

export default injectIntl(
  withRouter(
    withStyles(styles)(
      connect((state) => {
        return { producer: state.producer.object };
      }, mapDispatchToProps)(withTabsHasher(CollectionsScene, '/collections', []))
    )
  )
);
