import axios from 'axios';
import {
  replaceUrlPlaceholder,
  getRequestConfig,
  REQUEST_TYPES,
} from '../request';
import API_END_POINTS from '../endpoints';

/**
 * Fetches available tags (Tags which is not used)
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiGetCollectionTags = function (onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.PRODUCT_COLLECTIONS_GET_AVAILABLE_TAGS);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Fetches all tags
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiGetAllTags = function (onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.PRODUCT_COLLECTIONS_GET_ALL_TAGS);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};


/**
 * Fetches collection products
 * @param {string} id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCollectionGetProducts = function (id, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRODUCT_COLLECTIONS_GET_PRODUCTS, 'id', id);
  let config = getRequestConfig(REQUEST_TYPES.GET, url);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Create collection
 * @param {object} data - data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCreateCollection = function (data, onSuccess, onError) {
  let config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.PRODUCT_COLLECTIONS_CREATE);
  config.data = data;
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Patch collection by id
 * @param {string} id - collection id
 * @param {object} data - data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiPatchCollection = function (id, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRODUCT_COLLECTIONS_PATCH, 'id', id);
  let config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  config.data = data;
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Delete collection by id
 * @param {string} id - collection id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDeleteCollection = function (id, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRODUCT_COLLECTIONS_DELETE, 'id', id);
  let config = getRequestConfig(REQUEST_TYPES.DEL, url);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};