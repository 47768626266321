import axios from 'axios';
import {
  getRequestConfig,
  REQUEST_TYPES,
  replaceUrlPlaceholder
} from  '../request';
import API_END_POINTS from  '../endpoints';

/**
 * Get shipping settings
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerGetShippingOptions = function (onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.PRODUCER_SHIPPING_GET);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Create shipping day
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerShippingDayCreate = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.PRODUCER_SHIPPING_DAYS_CREATE);
  config.data = data;
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Patch shipping day
 * @param {string} id
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerShippingDayPatch = function (id, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRODUCER_SHIPPING_DAYS_PATCH, 'id', id);
  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  config.data = data;
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Delete shipping day
 * @param {string} id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerShippingDayDelete = function (id, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRODUCER_SHIPPING_DAYS_DELETE, 'id', id);
  const config = getRequestConfig(REQUEST_TYPES.DEL, url);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Create shipping rate
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerShippingRateCreate = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.PRODUCER_SHIPPING_RATES_CREATE);
  config.data = data;
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Patch shipping rate
 * @param {string} id
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerShippingRatePatch = function (id, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRODUCER_SHIPPING_RATES_PATCH, 'id', id);
  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  config.data = data;
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Delete shipping rate
 * @param {string} id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerShippingRateDelete = function (id, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRODUCER_SHIPPING_RATES_DELETE, 'id', id);
  const config = getRequestConfig(REQUEST_TYPES.DEL, url);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Change active checkbox in days
 * @param {string} dayId
 * @param {boolean} active
 * @param {string} locationId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerShippingDaysActiveChange = function (dayId, locationId, active,onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRODUCER_SHIPPING_DAYS_ACTIVE_PATCH, 'id', dayId);
  const config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  config.data = {
    active,
    locationId
  };
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
}
