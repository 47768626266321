import moment from 'moment';

export const compareStringByNumber = (prev, next) => {
  const prevNum = prev.replace(/\D/g, '');
  const nextNum = next.replace(/\D/g, '');

  return nextNum - prevNum;
};

export const compareMomentDates = (prev, next) => {
  if (!!prev && !!prev.unix && !!next && !!next.unix) {
    const prevDateNum = prev.unix();
    const nextDateNum = next.unix();
    return nextDateNum - prevDateNum;
  }
  return 0;
};

export const compareNotMomentDates = (prev, next) => {
  const prevDateNum = moment(prev).unix();
  const nextDateNum = moment(next).unix();

  if (!!prevDateNum && !!nextDateNum) {
    return nextDateNum - prevDateNum;
  }
  return 0;
};
