import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import TodayIcon from '@material-ui/icons/Today';
import Chip from '@material-ui/core/Chip';
import { COLORS } from '../../../../../helpers';
import DXFilterConfig from '../DXFilterConfig';
import PrimaryInlineDatePicker from '../../../../PrimaryInlineDatePicker/PrimaryInlineDatePicker';

const styles = {
  wrapper: {},
  chips: {
    display: 'block',
    width: '100%',
    margin: '20px 0 0 0',
    textAlign: 'left',
  },
  chip: {
    margin: 5,
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  selectFormControl: {
    marginBottom: 20,
  },
  column: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  mr20: {
    marginRight: 20,
  },
};

const propTypes = {
  shared: PropTypes.object,
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onChangeShared: PropTypes.func.isRequired,
};
const defaultProps = {
  selected: [],
};

class DXNextDateFilter extends React.Component {
  componentDidMount() {
    if (this.props.selected && this.props.selected.length === 0) {
      this.handleValueAdd(new Date());
    }
  }

  handleValueDelete = () => {
    this.props.onChange([]);
  };

  getCopy = (object) => {
    return JSON.parse(JSON.stringify(object));
  };

  getValue = () => {
    const { classes, selected } = this.props;

    let value = '';
    if (selected.length) {
      let filter = selected[0];

      value = moment(filter).format('L');
    }

    return (
      <div className={classes.chips}>
        {value && <Chip label={value} className={classes.chip} onDelete={() => this.handleValueDelete()} />}
      </div>
    );
  };

  getShippingFilterValue = () => {
    const { selected } = this.props;

    return moment(selected[0]).startOf('day').toISOString(true);
  };

  handleValueAdd = (selectedFilterValue) => {
    this.props.onChange([selectedFilterValue]);
  };

  render() {
    const { classes, intl } = this.props;
    return (
      <React.Fragment>
        <div className={classes.row}>
          <div className={classes.column}>
            <div className={classNames(classes.dateSelects, classes.mr20)}>
              <PrimaryInlineDatePicker
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <TodayIcon style={{ color: COLORS.text }} />
                    </InputAdornment>
                  ),
                }}
                label={intl.formatMessage({ id: 'filter.nextDate' })}
                value={this.getShippingFilterValue()}
                onChange={(value) => this.handleValueAdd(value)}
              />
            </div>
          </div>
        </div>

        {!!this.props.selected.length && this.getValue()}
      </React.Fragment>
    );
  }
}

DXNextDateFilter.propTypes = propTypes;
DXNextDateFilter.defaultProps = defaultProps;
DXNextDateFilter.getLabel = (intl, selectedValues) => {
  return moment(selectedValues[0]).format('L');
};
DXNextDateFilter.applyToQueryParams = (queryParams, selectedValues) => {
  queryParams['nextDate'] = moment(selectedValues[0]).startOf('day').toISOString(true);

  return queryParams;
};
DXNextDateFilter.getConfig = (intl) => {
  return new DXFilterConfig({
    name: 'nextDate',
    property: 'nextDate',
    title: intl.formatMessage({ id: 'subscription.nextDate' }),
    disabled: false,
    selected: [],
    shared: { filterType: -1 },
    equalityStatus: false,
  });
};

export default injectIntl(withStyles(styles)(DXNextDateFilter));
