import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from '../../hocs';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { PrimaryButton } from '../../components';
import { COLORS } from '../../helpers';
import SubWrapper from './Components/SubWrapper';
import Block from './Components/Block';
import Wrapper from './Components/Wrapper';
import ImportFieldsDialog from './Dialogs/ImportFieldsDialog';

const styles = {
  wrapper: {
    marginBottom: 40,
  },
  title: {
    fontSize: 25,
    display: 'inline-block',
    color: COLORS.text,
    fontWeight: 300,
    fontFamily: 'Roboto, sans-serif',
  },
  accounts: {
    display: 'flex',
    flexDirection: 'row',
    width: 750,
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  button: {
    width: '100%',
  },
  titleText: {
    fontSize: 16,
    display: 'inline-block',
    color: COLORS.text,
    fontWeight: 300,
    fontFamily: 'Roboto, sans-serif',
    marginBottom: 15,
  },
  blockWrapper: {
    boxShadow: 'none',
    marginLeft: 12,
    width: 300,
  },
};

const propTypes = {
  producer: PropTypes.object.isRequired,
};

const ImportTab = (props) => {
  const { intl, classes } = props;
  const [fieldsImportModal, setFieldsImportModal] = useState(false);

  const openImportFieldsDialog = () => {
    setFieldsImportModal(true);
  };

  return (
    <>
      <Wrapper>
        <SubWrapper>
          <Block title={intl.formatMessage({ id: 'setting.import.fields' })}>
            <PrimaryButton className={classes.button} onClick={() => openImportFieldsDialog()}>
              {intl.formatMessage({ id: 'file.importPlantedItemsButton' })}
            </PrimaryButton>
          </Block>
        </SubWrapper>

        {fieldsImportModal && <ImportFieldsDialog onSave={() => {}} onClose={() => setFieldsImportModal(false)} />}
      </Wrapper>
    </>
  );
};

ImportTab.propTypes = propTypes;

export default injectIntl(withRouter(withStyles(styles)(ImportTab)));
