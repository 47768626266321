import moment from 'moment';
import { CUSTOMER_TYPES } from '../constants/globals';

export function preventClickDefault(event) {
  event.preventDefault();
  event.stopPropagation();
}
export function isToday(date) {
  const momentDate = moment.parseZone(date);
  const today = moment(new Date());

  return momentDate.isSame(today, 'day');
}

export function getCustomerName(customer) {
  if (!!customer?.customerName) return customer.customerName;

  return customer?.type === CUSTOMER_TYPES.WHOLESALE
    ? customer.companyName
    : `${customer.firstName} ${customer.lastName}`;
}

export function isTouchableDevice() {
  return !!navigator.maxTouchPoints;
}

export function scrollToElement(elem, offsetTop = 0) {
  const elemOffsetTop = elem.current.offsetParent.offsetTop;

  window.scrollTo({
    top: elemOffsetTop - offsetTop,
    behavior: 'smooth',
  });
}

export const makeStringAddress = (address) => {
  return [
    ...(address.address1 ? [address.address1] : []),
    ...(address.address2 ? [address.address2] : []),
    ...(address.zip ? [address.zip] : []),
    ...(address.city ? [address.city] : []),
    ...(address.countryCode ? [address.countryCode] : []),
  ]
    .map((item) => item && item.replace(/,/g, ''))
    .join(', ');
};

export const toCurrency = (price, intl, minimumFractionDigits) => {
  price = price !== 0 ? (price / 100).toString() : price.toString();

  if (typeof intl !== 'undefined') {
    price = intl.formatNumber(price, {
      style: 'currency',
      minimumFractionDigits: minimumFractionDigits || 2,
      currency: process.env.REACT_APP_CURRENCY,
    });
  }
  return price;
};

export const weightToUser = (weight, emptyIfOne) => {
  weight = weight || 0;
  weight = weight > 0 ? weight / 100 : weight;
  return (weight === 1 && emptyIfOne ? ' ' : weight).toString();
};
