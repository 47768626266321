import React from 'react';
import { Template, TemplatePlaceholder, Plugin, TemplateConnector } from '@devexpress/dx-react-core';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import DXFiltersFactory from './Filters/DXFiltersFactory';
import DXClientSideFiltersFactory from './ClientSideFilters/DXClientSideFiltersFactory';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(1) / 2,
  },
  chip: {
    margin: theme.spacing(1) / 2,
  },
});

function getFilterValue(intl, original) {
  let filter = { ...original };
  const fallback = (filter) => {
    if (filter.getLabel) {
      return filter.getLabel(intl);
    }

    // Fallback for client side filters
    if (typeof filter.selectedValue === 'string') {
      return filter.selectedValue;
    }

    return filter.selectedValues
      .join(', ')
      .replace(new RegExp('ne-', 'g'), `${intl.formatMessage({ id: `global.not` })} `)
      .replace(new RegExp('not_', 'g'), `${intl.formatMessage({ id: `global.not` }).toLowerCase()} `);
  };

  if (filter.clientSide) {
    // Handling client side filters
    try {
      const Filter = DXClientSideFiltersFactory.getFilterByName(filter);
      return Filter.getLabel(intl);
    } catch (e) {
      return fallback(filter);
    }
  }

  if (!filter.clientSide) {
    // Handling server side filters
    try {
      const Filter = DXFiltersFactory.getFilterByName(filter.name);
      return Filter.getLabel(intl, filter.selectedValues);
    } catch (e) {
      return fallback(filter);
    }
  }
}

function prepareFilters(filters) {
  // Exclude empty filters
  return filters.filter((filter) => {
    return (
      !filter.invisible &&
      ((typeof filter.selectedValues === 'object' && Object.keys(filter.selectedValues).length > 0) ||
        (Array.isArray(filter.selectedValues) && filter.selectedValues.length > 0) ||
        (filter.selectedValue && filter.selectedValue !== ''))
    );
  });
}

export default withStyles(styles)(
  injectIntl(function FilterToggle(props) {
    const {
      classes,
      filters,
      onRemoveFilter,
      onOpenWithFilter,
      intl,
      groupByOptions,
      onRemoveGroupBy,
      onOpenWithGroupBy,
      detailRowsPropertyName,
      onRemoveDetailRowsProperty,
      onOpenWithDetailRowsProperty,
    } = props;

    const getGroupByChips = () => {
      return () =>
        groupByOptions.map((groupByItem) => (
          <Chip
            key={`groupBy-${groupByItem.key}`}
            className={classes.chip}
            label={groupByItem.title || ''}
            onDelete={() => onRemoveGroupBy && onRemoveGroupBy(groupByItem)}
            onClick={() => onOpenWithGroupBy && onOpenWithGroupBy(groupByItem)}
          />
        ));
    };

    const getDetailRowChips = () => {
      return () => (
        <Chip
          key={`groupBy-${detailRowsPropertyName}`}
          className={classes.chip}
          label={
            detailRowsPropertyName
              ? intl.formatMessage(
                  { id: `adminTable.chips.tableRowDetail` },
                  {
                    columnName: intl.formatMessage({ id: `adminTable.chips.tableRowDetail.${detailRowsPropertyName}` }),
                  }
                )
              : ''
          }
          onDelete={() => onRemoveDetailRowsProperty && onRemoveDetailRowsProperty(detailRowsPropertyName)}
          onClick={() => onOpenWithDetailRowsProperty && onOpenWithDetailRowsProperty(detailRowsPropertyName)}
        />
      );
    };

    return (
      <Plugin name="FilterToggle">
        <Template name="toolbarContent" className={classes.root}>
          {filters && (
            <TemplateConnector>
              {() =>
                prepareFilters(filters).map((filter, index) => {
                  return (
                    <Chip
                      key={`filter-${index}`}
                      className={classes.chip}
                      label={(filter.column || filter.title) + ': ' + getFilterValue(intl, filter)}
                      onDelete={filter.readOnly ? undefined : () => onRemoveFilter && onRemoveFilter(filter)}
                      onClick={() => onOpenWithFilter && onOpenWithFilter(filter.index || null, filter)}
                    />
                  );
                })
              }
            </TemplateConnector>
          )}
          {!!groupByOptions && !!groupByOptions?.length && <TemplateConnector>{getGroupByChips()}</TemplateConnector>}
          {!!detailRowsPropertyName && <TemplateConnector>{getDetailRowChips()}</TemplateConnector>}
          <TemplatePlaceholder />
        </Template>
      </Plugin>
    );
  })
);
