import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';

export const cellRenderer = (intl, reload, { row, ...props }) => {
  if (row.rowType === 'location') {
    if (props.column.name === 'locationName') {
      return <Table.Cell {...props}>{row.name || intl.formatMessage({ id: 'global.noLocation' })}</Table.Cell>;
    }

    return <Table.Cell {...props}>&nbsp;</Table.Cell>;
  }

  if (!!row.lineItems) {
    if (props.column.name === 'type') {
      return <Table.Cell {...props}>{row.type && intl.formatMessage({ id: `global.${row.type}` })}</Table.Cell>;
    }
    if (props.column.name === 'name') {
      return <Table.Cell {...props}>{row.name}</Table.Cell>;
    }
    if (props.column.name === 'customerName') {
      return <Table.Cell {...props}>{row.customerName}</Table.Cell>;
    }

    return <Table.Cell {...props}>&nbsp;</Table.Cell>;
  }

  return <Table.Cell {...props} />;
};
