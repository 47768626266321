import { getRequestConfig, REQUEST_TYPES } from '../request';
import API_END_POINTS from '../endpoints';
import axios from 'axios';
import { getStore } from '../../store/configure';
import { CUSTOMER_TYPE } from '../../helpers';

/**
 * Anonymous Preview Producer
 * @param {function} onSuccess
 * @param {function} onError
 */

export const apiAnonymousPreviewProducer = (onSuccess, onError) => {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.ANONYMOUS_PREVIEW_PRODUCER);
  config.data = { url: `${process.env.REACT_APP_STORE_URL}preview-producer?type=retail&token={token}` };
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Generate anonymous link for current producer
 *
 * @param {string} type
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiGenerateAnonymousLink = (type, onSuccess, onError) => {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.GENERATE_ANONYMOUS_WHOLESALE_LINK);
  const store = getStore().getState();
  const producerHandle = store?.producer?.object?.handle || '';

  config.data = {
    url: `${process.env.REACT_APP_STORE_URL}preview-producer?type=retail&token={token}`,
  };

  if (type === CUSTOMER_TYPE.WHOLESALE) {
    config.data['url'] = `${process.env.REACT_APP_STORE_URL}producer/${producerHandle}?${type}={token}`;
  }

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};
