import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from '../../../hocs';
import { withStyles } from '@material-ui/core/styles';
import {
  AdminTabContent,
  AdminTabContentHeader,
  AdminTabContentTitle,
  AdminDXTable,
  Spinner,
  InlineDatesFilter,
} from '../../../components';
import Actions from './Actions';
import styles from './styles';
import { getGridPageSize, getGridPageSizes } from '../../../helpers/grid';
import { jsonToCsv } from './helpers';
import config from './config';
import { Table } from '@devexpress/dx-react-grid-material-ui';

const DATE_PREFIX_PAYOUT = 'payout';

class PayoutsTab extends React.Component {
  constructor(props) {
    const report = config(props.intl, props.history);

    super(props);
    this.state = {
      tab: 0,
      loading: false,
      serverSideFilters: null,

      currentReportName: report.name,
      currentReport: report.def,

      openWithFilter: null,
      filtersDialog: false,
    };
  }

  render() {
    const { intl, classes, history } = this.props;

    const { loading, currentReport, currentReportName, serverSideFilters } = this.state;

    return (
      <>
        {loading && <Spinner size={80} />}
        <AdminTabContent>
          <AdminTabContentHeader
            actions={
              <Actions
                history={history}
                allowExport={serverSideFilters && currentReport && currentReport.allowExport}
                onExport={() => jsonToCsv(this.table.getRows(), currentReportName, true, ['id'], currentReport.columns)}
                onReportSelect={this.onReportSelect}
              />
            }>
            <AdminTabContentTitle>{intl.formatMessage({ id: 'producer.tab.payouts' })}</AdminTabContentTitle>
          </AdminTabContentHeader>

          <div className={classes.filtersWrapper}>
            <InlineDatesFilter
              prefix={DATE_PREFIX_PAYOUT}
              onChangeInlineDateFilter={(filters) => {
                filters = filters.map((filter) => {
                  filter.invisible = true;
                  return filter;
                });
                this.setState({ serverSideFilters: filters });
              }}
            />
          </div>

          {currentReport && serverSideFilters && (
            <AdminDXTable
              cellRenderer={(reload, { row, ...props }) => {
                return <Table.Cell {...props} />;
              }}
              onRef={(table) => (this.table = table)}
              serverSideFilters={[...JSON.parse(JSON.stringify(serverSideFilters))]}
              apiRetrieve={currentReport.apiRetrieve}
              columns={currentReport.columns}
              sorting={currentReport.sorting}
              pageSize={getGridPageSize()}
              pageSizes={getGridPageSizes()}
              totalSummaryItems={currentReport.totalSummaryItems}
              currencyColumnNames={currentReport.currencyColumnNames ? currentReport.currencyColumnNames : undefined}
              prepareRowsAfterLoad={currentReport.prepareRowsAfterLoad}
              quantityWithSeparatorColumnNames={currentReport.quantityWithSeparatorColumnNames}
              columnExtensions={[{ columnName: 'amount', align: 'right' }]}
              actionsTitle={' '}
              actionsHeaderCellStyle={{ textAlign: 'center', paddingRight: 0 }}
              actionsCellStyle={{ textAlign: 'center', paddingRight: 0 }}
              headerCellProps={{ style: { whiteSpace: 'normal' } }}
              actions={!!currentReport.actions ? currentReport.actions : undefined}
            />
          )}
        </AdminTabContent>
      </>
    );
  }
}

export default withStyles(styles)(withRouter(injectIntl(PayoutsTab)));
