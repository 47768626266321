export default class InArrayFilter {

  constructor(values, propertyName) {
    this.values = values;
    this.property = propertyName;
  }

  isSatisfy(row) {
    return (
      this.values.length > 0 &&
      this.values.indexOf(row[this.property]) !== -1
    );
  }

}