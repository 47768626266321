import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AdminDialogTitle from '../AdminDialog/AdminDialogTitle';
import AdminDialogContent from '../AdminDialog/AdminDialogContent';
import AdminDialogControls from '../AdminDialog/AdminDialogControls';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import AdminDialog from '../AdminDialog/AdminDialog';
import { PrimaryChip, Spinner } from '../../components';
import { COLORS } from '../../helpers';

const styles = {
  wrapper: {},
  message: {
    textAlign: 'center',
    fontSize: 18,
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.text,
    marginBottom: 40,
    whiteSpace: 'pre-line',
  },
  button: {
    minWidth: '40%',
    textTransform: 'uppercase',
  },
  mr20: {
    marginRight: 10,
  },
  controls: {
    margin: '27px 0 0 0',
    textAlign: 'center',
  },
  control: {
    padding: '0 105px',
  },
  actionWrapper: {
    width: '100%',
  },
  emptyError: {
    fontSize: 14,
    textAlign: 'left',
    marginTop: 10,
    color: 'red',
  },
};

const propTypes = {
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,

  label: PropTypes.string,
  buttonText: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  completeButtonText: PropTypes.string,
  completeTitle: PropTypes.string,
  completeMessage: PropTypes.string,
  completedMessageMany: PropTypes.string,
  errorButtonText: PropTypes.string,
  errorTitle: PropTypes.string,
  emptyErrorText: PropTypes.string,
};
const defaultProps = {
  label: 'prePurchase.tagsModal.addTagsInputLabel',
  buttonText: 'prePurchase.tagsModal.addTagsButton',
  title: 'prePurchase.tagsModal.addTagsTitle',
  message: 'prePurchase.tagsModal.addTagsText',
  completeButtonText: 'global.ok',
  completeTitle: 'prePurchase.tagsModal.completed',
  completeMessage: 'prePurchase.tagsModal.completedText',
  completedMessageMany: 'prePurchase.tagsModal.completedTextMany',
  errorButtonText: 'global.ok',
  errorTitle: 'prePurchase.tagsModal.errorTitle',
  emptyErrorText: 'prePurchase.tagsModal.emptyError',
};

const STEP_ACTION = 1;
const STEP_COMPLETE = 2;
const STEP_ERROR = 3;

export const SELECT_TAGS_DIALOG_STEPS = {
  STEP_ACTION,
  STEP_COMPLETE,
  STEP_ERROR,
};

const SelectTagsDialog = ({
  onClose,
  intl,
  classes,
  loading,
  onConfirm,

  buttonText,
  title,
  message,
  completeButtonText,
  completeTitle,
  completeMessage,
  completedMessageMany,
  errorButtonText,
  errorTitle,
  label,
  reloadTable,
  emptyErrorText,
}) => {
  const [step, setStep] = useState(STEP_ACTION);
  const [tags, setTags] = useState([]);
  const [error, setError] = useState('');
  const [emptyError, setEmptyError] = useState(false);

  const getTitle = () => {
    switch (step) {
      case STEP_ACTION: {
        return intl.formatMessage({ id: title });
      }
      case STEP_COMPLETE: {
        return intl.formatMessage({ id: completeTitle });
      }
      case STEP_ERROR: {
        return intl.formatMessage({ id: errorTitle });
      }
      default: {
        return intl.formatMessage({ id: title });
      }
    }
  };

  const getButton = () => {
    switch (step) {
      case STEP_ACTION: {
        return (
          <PrimaryButton className={classes.button} onClick={onActionFinish}>
            {intl.formatMessage({ id: buttonText })}
          </PrimaryButton>
        );
      }
      case STEP_COMPLETE: {
        return (
          <PrimaryButton className={classes.button} onClick={_onClose}>
            {intl.formatMessage({ id: completeButtonText })}
          </PrimaryButton>
        );
      }
      case STEP_ERROR: {
        return (
          <PrimaryButton className={classes.button} onClick={_onClose}>
            {intl.formatMessage({ id: errorButtonText })}
          </PrimaryButton>
        );
      }
      default: {
        return (
          <PrimaryButton className={classes.button} onClick={onConfirm}>
            {intl.formatMessage({ id: buttonText })}
          </PrimaryButton>
        );
      }
    }
  };

  const onActionFinish = () => {
    if (!!tags.length) {
      onConfirm(tags, (step, error) => {
        setStep(step);
        if (error) {
          setError(error);
        }
      });
    } else {
      setEmptyError(true);
    }
  };

  const getContent = () => {
    switch (step) {
      case STEP_ACTION: {
        return actionContent();
      }
      case STEP_COMPLETE: {
        const tagsString = tags.join(', ');

        return (
          <p className={classes.message}>
            {intl.formatMessage({ id: tags.length > 1 ? completedMessageMany : completeMessage }, { tags: tagsString })}
          </p>
        );
      }
      case STEP_ERROR: {
        return <p className={classes.message}>{error}</p>;
      }
      default: {
        return actionContent();
      }
    }
  };

  const actionContent = () => {
    return (
      <div className={classes.actionWrapper}>
        <p className={classes.message}>{intl.formatMessage({ id: message })}</p>

        <PrimaryChip
          className={classes.textField}
          fullWidth
          value={tags}
          allowDuplicates={false}
          onAdd={(newChip) => {
            setEmptyError(false);
            // prevent duplicate values
            let mergedArray = [...tags, newChip];
            mergedArray = mergedArray.filter((value, index, array) => array.indexOf(value) === index);
            setTags(mergedArray);
          }}
          onDelete={(chip) => {
            let chipsArr = [...tags];
            const index = chipsArr.indexOf(chip);
            chipsArr.splice(index, 1);
            setTags(chipsArr);
          }}
          label={intl.formatMessage({ id: label })}
          InputLabelProps={{ shrink: true }}
        />

        {emptyError && <p className={classes.emptyError}>{intl.formatMessage({ id: emptyErrorText })}</p>}
      </div>
    );
  };

  const _onClose = () => {
    if (step === STEP_ACTION) {
      onClose();
    } else {
      reloadTable && reloadTable();
    }
  };

  return (
    <AdminDialog open closeButton onClose={_onClose}>
      <AdminDialogTitle title={getTitle()} />
      <AdminDialogContent className={classes.wrapper}>
        {loading && <Spinner size={50} />}

        {getContent()}

        <AdminDialogControls>{getButton()}</AdminDialogControls>
      </AdminDialogContent>
    </AdminDialog>
  );
};

SelectTagsDialog.propTypes = propTypes;
SelectTagsDialog.defaultProps = defaultProps;

export default withStyles(styles)(injectIntl(SelectTagsDialog));
