import React from 'react'
import PropTypes from 'prop-types'
import DialogContent from '@material-ui/core/DialogContent'
import {withStyles} from '@material-ui/core/styles'
import DoneIcon from '@material-ui/icons/Done'
import {
  ErrorBox,
  AdminDialogTitle,
  Spinner,
  Icon,
  AdminDialog,
  PrimaryButton,
  PrimaryTextField,
} from '../../components'
import {validateField} from '../../helpers'
import validationRules from './validation'
import styles from './styles'

class NewAccountForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      requestSent: false,
      isLoading: false,
      password: "",
      passwordError: "",

      passwordRepeat: "",
      passwordRepeatError: "",

      requestError: ""
    };

    this.validate = this.validate.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    this.props.onClose();
  }

  validate() {
    if (this.state.isLoading)
      return false;

    const passwordError = validateField(validationRules, 'password', this.state.password);
    const passwordRepeatError = validateField(validationRules, 'passwordRepeat', this.state.passwordRepeat, {
      password: this.state.password
    });

    if (!passwordError && !passwordRepeatError) {
      this.setState({
        passwordError: "",
        passwordRepeatError: "",
        requestError: "",
        isLoading: true
      });
    } else {
      this.setState({
        passwordError: passwordError,
        passwordRepeatError: passwordRepeatError,
        isLoading: false
      });
    }
  }

  getForm() {
    const {classes} = this.props;
    return (
      <React.Fragment>
        <AdminDialogTitle title={"Create password"}/>

        {this.state.isLoading ?
          <Spinner size={50}/> : null}

        <ErrorBox error={this.state.requestError}/>
        <DialogContent>

          <p style={{textAlign: 'center'}}>
            You have an account, but you have not created a password yet.<br/>
            Please create a password to setup your account.
          </p>

          {/*<PrimaryTextField
            error={!!this.state.emailError}
            onBlur={() => this.validateField('email')}
            onChange={event => this.setState({email: event.target.value.trim()})}
            value={this.state.email}
            required
            autoFocus
            margin="dense"
            id="email"
            label="Email"
            helperText={this.state.emailError}
            type="email"
            fullWidth
          />*/}

          <PrimaryTextField
            error={!!this.state.passwordError}
            onChange={event => this.setState({password: event.target.value.trim()})}
            value={this.state.password}
            id="password-input"
            margin="dense"
            label="Password"
            type="password"
            autoFocus
            helperText={this.state.passwordError}
            fullWidth
          />

          <PrimaryTextField
            error={!!this.state.passwordRepeatError}
            onChange={event => this.setState({passwordRepeat: event.target.value.trim()})}
            value={this.state.passwordRepeat}
            id="password-repeat-input"
            margin="dense"
            label="Confirm password"
            type="password"
            helperText={this.state.passwordRepeatError}
            fullWidth
          />
        </DialogContent>

        <div className={classes.controls}>
          <PrimaryButton onClick={this.validate}>
            Create account
          </PrimaryButton>
        </div>
      </React.Fragment>
    )
  }

  getFinalMessage() {
    const {classes} = this.props;
    return (
      <React.Fragment>
        <DialogContent className={classes.finalMessageContainer}>
          <Icon icon={DoneIcon} classes={{root: classes.envelope}}/>
          <p style={{textAlign: 'center'}}>Account was created</p>
        </DialogContent>

        <div className={classes.controlsCentered}>
          <PrimaryButton onClick={this.props.onDone}>Ok</PrimaryButton>
        </div>
      </React.Fragment>
    )
  }

  render() {
    return (
      <AdminDialog onClose={this.onClose}
                          open >
        {this.state.requestSent ?
          this.getFinalMessage() : this.getForm()}
      </AdminDialog>
    );
  }
}

NewAccountForm.propTypes = {
  token: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  onDone: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default withStyles(styles)(NewAccountForm);