import axios from 'axios';
import { getRequestConfig, REQUEST_TYPES } from '../request';
import API_END_POINTS from '../endpoints';
import { SearchCustomersParams } from '../../entities';

/**
 * Search customers
 * @param {object} params
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomersSearch = function (params, onSuccess, onError) {
  let config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.CUSTOMERS_SEARCH);

  const options = new SearchCustomersParams(params || {});
  config.params = options.toData();

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};
