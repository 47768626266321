import React from "react";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';

const Badge = (props) => {
  const {color, classes, title} = props;
  return (
    <span className={classNames(classes.badge, classes[color])}>
      {title}
    </span>
  );
};

Badge.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string
};

Badge.defaultProps = {
  title: "",
  color: 'black'
};

export default withStyles(styles)(Badge);