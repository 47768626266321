import React from 'react';
import { makeStyles } from '@material-ui/core';
import styles from './styles';
import classNames from 'classnames';

const useStyles = makeStyles(styles);

const SeparatedTableHeader = (props) => {
  const { columns, isSticky, headerOffset = 0 } = props;
  const classes = useStyles();

  if (!Array.isArray(columns)) {
    return null;
  }

  return (
    <div
      className={classNames([classes.root, isSticky && classes.stickyHeader])}
      {...(isSticky
        ? {
            style: {
              top: headerOffset,
            },
          }
        : {})}>
      {columns.map((column, index) => {
        const isFirst = index === 0,
          isLast = index === columns.length - 1;

        if (!!column.component) {
          return (
            <div className={classNames([classes.headerCell, classes['headerCell' + index]])} key={column.name}>
              {column.component()}
            </div>
          );
        }

        return (
          <div className={classNames([classes.headerCell, classes['headerCell' + index]])} key={column.name}>
            <div
              className={classNames([
                classes.cellContent,
                isFirst && classes.cellContentFirst,
                isLast && classes.cellContentLast,
                classes['cellContent' + index],
              ])}>
              {column?.title || ''}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SeparatedTableHeader;
