import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../helpers';

const styles = {
  cellStyle: {
    verticalAlign: 'baseline',
  },
  cellCustomer: {
    verticalAlign: 'baseline',
    cursor: 'pointer',
  },
  textStyle: {
    display: 'block',
    textDecoration: 'none',
    paddingBottom: 3,
    paddingTop: 3,
    color: COLORS.text,
    overflow: 'hidden',
  },
};

export const CellRenderer = (reload, { row, ...props }) => {
  if (props.column.name === 'customerName') {
    return (
      <Table.Cell
        {...props}
        style={styles.cellCustomer}
        onClick={() => {
          window.open(
            row?.variantId ? `/product/${row.productId}/${row.variantId}` : `/customers/${row.customerId}`,
            '_blank'
          );
        }}>
        {row.name}
      </Table.Cell>
    );
  }
  if (row.items.hasOwnProperty(props.column.name)) {
    return (
      <Table.Cell {...props} style={styles.cellStyle}>
        {renderItems(row.items[props.column.name])}
      </Table.Cell>
    );
  }

  return (
    <Table.Cell {...props} style={styles.cellStyle}>
      {' '}
    </Table.Cell>
  );
};

const useStyles = makeStyles(() => ({
  rowHover: {
    color: '#fff',
    transition: 'background-color 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: 'rgba(224, 224, 224, 0.2)',
    },
  },
}));

export const RowRenderer = (onClick, { row, ...props }) => {
  const classes = useStyles();
  return <Table.Row {...props} onClick={() => onClick && onClick(row)} className={classes.rowHover} />;
};

const renderItems = (dayCell) => {
  if (!dayCell?.items || !Array.isArray(dayCell.items)) {
    return '-';
  }

  return dayCell.items.map((item, index) => (
    <a
      target="_blank"
      href={item?.variantId ? `/product/${item.productId}/${item.variantId}` : `/customers/${item.id}`}
      style={styles.textStyle}
      key={`${dayCell.id}_${index}`}>
      {`${item.productName || item.name || ''} - ${item.variantName || item.email || ''}`}
    </a>
  ));
};

export function renderItemsForExport(dayCell) {
  if (!dayCell?.items || !Array.isArray(dayCell.items)) {
    return '-';
  }

  let result = '';

  dayCell.items.forEach((item) => {
    result += `${item.productName || item.name || ''} - ${item.variantName || item.email || ''}\n`;
  });

  return result;
}
