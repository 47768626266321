import moment from 'moment';

export function getStartDateAndEndDateOfMonthByDate(date = new Date()) {
  return {
    from: moment(date).startOf('month').startOf('day').toISOString(true),
    to: moment(date).endOf('month').endOf('day').toISOString(true),
  };
}

export function getPrevMonthByDate(date = new Date()) {
  return moment(date).add(-1, 'month').startOf('day')
}

export function getNextMonthByDate(date = new Date()) {
  return moment(date).add(1, 'month').startOf('day')
}

export function getAllItemsCount(itemsData) {
  let count = 0;

  if (!Array.isArray(itemsData)) {
    return count;
  }

  itemsData.forEach((day) => (count += day?.items?.length || 0));

  return count;
}
