import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../hocs';
import SearchIcon from '@material-ui/icons/Search';
import {
  PrimaryTextField,
  CustomerAsCard,
  SearchHitsSubWrapper,
  Spinner,
  GooglePlacesHits,
  SearchHitsWrapper,
  SearchHits,
  ChipsSelect,
} from '../../components';
import { apiCustomersSearch } from '../../api';
import { CUSTOMER_TYPE } from '../../helpers';
import styles from './styles';

const propTypes = {
  filter: PropTypes.string,
  onHitClick: PropTypes.func.isRequired,
  onGooglePlacesHitClick: PropTypes.func.isRequired,
  history: PropTypes.object,
};

class CustomersSearch extends React.Component {
  state = {
    loading: false,
    query: '',
    customerType: CUSTOMER_TYPE.WHOLESALE,
    customers: [],
  };

  componentDidMount() {
    // Initial load of customers
    this.loadCustomers();
  }

  loadCustomers = () => {
    const { limit, query, customerType } = this.state;
    let options = {
      query: query,
      notApproved: true,
      active: true,
      type: customerType,
      limit: limit,
    };

    this.setState({ loading: true });
    apiCustomersSearch(
      options,
      (response) => {
        const customers = response?.data;
        this.setState({
          customers,
          loading: false,
        });
      },
      () => {
        /* Something went wrong */
      }
    );
  };

  getHitComponent = (hit) => {
    const { onHitClick } = this.props;
    return <CustomerAsCard customer={hit} onClick={onHitClick} />;
  };

  render() {
    const { intl, classes, onGooglePlacesHitClick, loading } = this.props;

    const { query, customers, customerType } = this.state;

    return (
      <React.Fragment>
        <div className={classes.header}>
          <PrimaryTextField
            onChange={(event) => this.setState({ query: event.target.value }, () => this.loadCustomers())}
            className={classes.search}
            margin="dense"
            autoFocus
            label={intl.formatMessage({ id: 'search.customers' })}
          />
          <SearchIcon />
        </div>

        <ChipsSelect
          onChange={(type) => this.setState({ customerType: type }, () => this.loadCustomers())}
          label={''}
          multiple={false}
          wrapperClass={classes.typesSwitcher}
          selected={customerType}
          values={[CUSTOMER_TYPE.WHOLESALE, CUSTOMER_TYPE.RETAIL]}
        />

        {loading && <Spinner />}
        {!loading && (
          <SearchHitsWrapper allowSwitchView={false}>
            <SearchHitsSubWrapper>
              <SearchHits
                getComponentCallback={(hit) => this.getHitComponent(hit)}
                getEmptyComponent={() => (
                  <GooglePlacesHits onAddCustomer={onGooglePlacesHitClick} queryString={query} />
                )}
                hits={customers}
              />
            </SearchHitsSubWrapper>
          </SearchHitsWrapper>
        )}
      </React.Fragment>
    );
  }
}

CustomersSearch.propTypes = propTypes;

export default withStyles(styles, { withTheme: true })(injectIntl(withRouter(CustomersSearch)));
