import MinimalVariant from './minimalVariant';

class MinimalProduct {
  constructor(props) {
    this.id = props.id || 'new';
    this.name = props.name || '';
    this.image = props.image || null;
    this.description = props.description || '';
    this.type = props.type || '';
    this.subType = props.subType || '';
    this.variants = !!props?.variants?.length
      ? props.variants.map((variant) => new MinimalVariant(variant))
      : [new MinimalVariant({})];
  }

  getProductData() {
    let productObj = this;
    delete productObj.variants;
    if (typeof productObj.image === 'object') {
      delete productObj.image;
    }

    return productObj;
  }

  getProductWithVariantsData() {
    return this;
  }
}
export default MinimalProduct;
