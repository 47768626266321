import moment from 'moment';
import { toPaper } from '../../../helpers';

/**
 * Converts JSON to CSV and forces file download
 * @param {object} json
 * @param {string} title
 * @param {bool} label
 * @param {array} excludeColumns
 * @param {array} columns
 */
export const jsonToCsv = (json,
                          title,
                          label,
                          excludeColumns,
                          columns) => {
  let arrData = typeof json !== 'object' ?
    JSON.parse(json) : json;

  let csv = '';

  // This condition will generate the Label/Header
  if (label) {
    let row = "";

    for (let i = 0; i < columns.length; i++) {
      if (columns.hasOwnProperty(i)) {
        for (let index in arrData[0]) {
          if (arrData[0].hasOwnProperty(index) &&
              excludeColumns.indexOf(index) === -1) {

            if (columns[i].name === index || columns[i].originalName === index) {
              let name = columns[i].title;
              row += name + ',';
              break;
            }
          }
        }

      }
    }

    row = row.slice(0, -1);
    csv += row + '\r\n';
  }

  let formatter = {
    initiatedAt: (row => (row.initiatedAt ? moment.parseZone(row.initiatedAt)
        .format(process.env.REACT_APP_DATE_FORMAT_WITH_SHORT_DAY_OF_WEEK) : 'Not set')),
    availableOn: (row => (row.availableOn ? moment.parseZone(row.availableOn)
        .format(process.env.REACT_APP_DATE_FORMAT_WITH_SHORT_DAY_OF_WEEK) : 'Not set')),
    total: (row => `$${toPaper(row.total)}`)
  };

  // 1st loop is to extract each row
  for (let i = 0; i < arrData.length; i++) {
    let row = "";

    // 2nd loop will extract each column and convert it in string comma-separated

    for (let j = 0; j < columns.length; j++) {
      if (columns.hasOwnProperty(j)) {
        for (let index in arrData[i]) {
          if (arrData[i].hasOwnProperty(index) &&
              excludeColumns.indexOf(index) === -1) {

            if (columns[j].name === index || columns[j].originalName === index) {
              if (Object.keys(formatter).indexOf(index) !== -1) {
                row += '"' + formatter[index](arrData[i]) + '",';
              } else {
                row += '"' + arrData[i][index] + '",';
              }

              break;
            }
          }
        }
      }
    }

    row.slice(0, row.length - 1);
    csv += row + '\r\n';
  }

  if (csv === '') {
    alert("Invalid data");
    return;
  }

  //Generate a file name
  let fileName = "Export_";
  fileName += title
    .replace(/( — | - )/g, " ")
    .replace(/ /g, "_");
  let uri = 'data:text/csv;charset=utf-8,' + escape(csv);
  let link = document.createElement("a");
  link.href = uri;
  link.style = "visibility:hidden";
  link.download = fileName + ".csv";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

