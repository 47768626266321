export default () => {
  return {
    name: {
      presence: {
        message: '^validation.product.prop.nameIsMandatory',
      },
    },
    email: {
      email: {
        message: '^validation.register.enterValidEmail',
      },
      presence: {
        message: '^validation.register.enterEmail',
      },
    },
  };
};
