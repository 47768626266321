import axios from 'axios';
import {
  getRequestConfig,
  replaceUrlPlaceholder,
  REQUEST_TYPES,
} from '../request';
import API_END_POINTS from  '../endpoints';

export const apiQuickBooksGetAuthUrl = function (onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.QUICK_BOOKS_GET_AUTH_URL);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

export const apiQuickBooksExchangeAuthCode = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.QUICK_BOOKS_EXCHANGE_AUTH_CODE);
  config.data = data;
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

export const apiQuickBooksSyncStatus = function (onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.QUICK_BOOKS_SYNC_STATUS);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Api change book settings
 * @param {object} data - {bookRetail: <bool>, bookWholesale: <bool>>}
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiQuickBooksBookOrdersSettings = function(data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.PATCH, API_END_POINTS.QUICK_BOOKS_BOOK_ORDERS_SETTINGS);
  config.data = data;
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Api get QuickBooks accounts
 * @param {object} filters
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiQuickBooksGetAccounts = function(filters, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.QUICK_BOOKS_GET_ACCOUNTS);
  config.filters = filters;
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Api get QuickBooks accounts

 * @param {string} accountKey
 * @param {string} accountId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiQuickBooksMapAccount = function(accountKey, accountId, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.QUICK_BOOKS_MAP_ACCOUNT);
  config.data = {
    accountKey,
    accountId
  };

  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Api get QuickBooks accounts mapping
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiQuickBooksGetAccountsMapping = function(onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.QUICK_BOOKS_GET_ACCOUNTS_MAPPING);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * 2 settings for producer (bookWholesale & bookRetail) that should be used to control QB orders sync.
 * They are both false by default.
 * @param {boolean} bookWholesale
 * @param {boolean} bookRetail
 * @param onSuccess
 * @param onError
 */
export const apiQuickBooksSetOrderSettings = function(bookWholesale, bookRetail, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.PATCH, API_END_POINTS.QUICK_BOOKS_ORDER_SETTINGS);

  config.data = {
    bookWholesale,
    bookRetail,
  };

  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * APIs to create QB order prefix:
 * @param prefix - name of order prefix
 * @param onSuccess
 * @param onError
 */
export const apiQuickBooksSetOrderPrefix = function(prefix, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.QUICK_BOOKS_CREATE_ORDER_PREFIX);

  config.data = {
    prefix
  };

  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * APIs to fetch QB order prefix:
 * @param onSuccess
 * @param onError
 */
export const apiQuickBooksGetOrderPrefix = function(onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.QUICK_BOOKS_CREATE_ORDER_PREFIX);

  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * APIs to delete QB order prefix:
 * @param onSuccess
 * @param onError
 */
export const apiQuickBooksDeleteOrderPrefix = function(onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.DEL, API_END_POINTS.QUICK_BOOKS_DELETE_ORDER_PREFIX);

  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * APIs to manually set order as booked:
 * @param orderId - single order id
 * @param onSuccess
 * @param onError
 */
export const apiQuickBooksSetSingleOrderAsBooked = function(orderId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.QUICK_BOOKS_SET_SINGLE_ORDER_AS_BOOKED, 'id', orderId);
  const config = getRequestConfig(REQUEST_TYPES.POST, url);

  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * APIs to manually set orders as booked:
 * @param {array} orderIds
 * @param onSuccess
 * @param onError
 */
export const apiQuickBooksSetMultiplyOrdersAsBooked = function(orderIds, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.QUICK_BOOKS_SET_MULTIPLY_ORDERS_AS_BOOKED);

  config.data = {
    orderIds
  }

  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * APIs to manually set order as paid:
 * @param orderId - single order id
 * @param onSuccess
 * @param onError
 */
export const apiQuickBooksSetSingleOrderAsPaid = function(orderId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.QUICK_BOOKS_SET_SINGLE_ORDER_AS_PAID, 'id', orderId);
  const config = getRequestConfig(REQUEST_TYPES.POST, url);

  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * APIs to manually set orders as paid:
 * @param {array} orderIds
 * @param onSuccess
 * @param onError
 */
export const apiQuickBooksSetMultiplyOrdersAsPaid = function(orderIds, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.QUICK_BOOKS_SET_MULTIPLY_ORDERS_AS_PAID);

  config.data = {
    orderIds
  }

  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Get list of quick book customers by searchPhrase:
 * @param {SearchCustomersParams} options
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiQuickBooksGetCustomersByName = function(options, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.QUICK_BOOKS_GET_CUSTOMERS);

  config.params = {
    name: options.query
  }

  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Attach QB customer to FF customer:
 * @param {Object} data - params obj
 * @param {string} data.ffCustomerId - FF wholesale customer ID
 * @param {string} data.qbCustomerId - QB customer ID
 * @param {boolean} data.bookToQuickBooks - If bookToQuickBooks is false then we exclude customer from booking and remove order booking error
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiQuickBooksAddCustomerToAccount = function(data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.QUICK_BOOKS_ADD_CUSTOMERS_TO_FF);

  config.data = data;

  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

