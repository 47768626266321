import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from '../../../hocs';
import { AdminDXTable } from '../../../components';
import { apiRetrieveDrafts } from '../../../api';
import { getGridPageSize, getGridPageSizes } from '../../../helpers/grid';
import DraftBatchControls from '../Controls/DraftBatchControls';
import { DEFAULT_MAX_CELL_WIDTH, DEFAULT_MIN_CELL_WIDTH, HEADER_HEIGHT_STANDARD } from '../../../helpers';
import { useDispatch } from 'react-redux';
import { changeTabState } from '../../../store/actions/common';

const propTypes = {
  columns: PropTypes.array.isRequired,
  hiddenColumnNames: PropTypes.array,
  columnWidths: PropTypes.array,
  onColumnWidthsChange: PropTypes.func,
  enableSelection: PropTypes.bool,
  onRowClick: PropTypes.func,
};

const defaultProps = {
  hiddenColumnNames: [],
  columnWidths: null,
  onColumnWidthsChange: () => {},
  enableSelection: true,
  onRowClick: undefined,
};

const DraftsTabContent = (props) => {
  const {
    columns,
    history,
    hiddenColumnNames,
    sortingColumnExtensions,
    columnWidths,
    onColumnWidthsChange,
    enableSelection,
    onRowClick,
    tab,
    searchValue,
  } = props;
  let table = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      saveTabOnStore();
    };
  }, []);

  const saveTabOnStore = () => {
    const searchValue = table?.state?.savedSearchValue || '';
    const sorting = table?.state?.sorting || null;
    const excludeColumns = table?.props?.hiddenColumnNames || null;
    const columnWidths = table?.props?.columnWidths || null;

    dispatch(
      changeTabState({
        key: tab.id,
        excludeColumns,
        columnWidths,
        sorting,
        searchValue,
      })
    );
  };

  return (
    <AdminDXTable
      defaultSearchValue={searchValue || ''}
      onRef={(ref) => (table = ref)}
      flexibleTableWidth
      enableSelection={enableSelection}
      apiRetrieve={apiRetrieveDrafts}
      pageSize={getGridPageSize()}
      pageSizes={getGridPageSizes()}
      onRowClick={!!onRowClick ? onRowClick : (row) => history.push(`/draft/${row.id}`)}
      hiddenColumnNames={hiddenColumnNames}
      columns={columns}
      sorting={[{ columnName: 'createdAt', direction: 'desc' }]}
      onOpenWithFilter={() => {}}
      columnExtensions={[{ columnName: 'total', align: 'right' }]}
      batchControlsComponent={DraftBatchControls}
      sortingColumnExtensions={sortingColumnExtensions}
      columnWidths={columnWidths}
      onColumnWidthsChange={onColumnWidthsChange}
      minColumnWidth={DEFAULT_MIN_CELL_WIDTH}
      maxColumnWidth={DEFAULT_MAX_CELL_WIDTH}
      allowExpandAll={true}
      stickyHeader
      stickyHeaderOffset={HEADER_HEIGHT_STANDARD.extended}
    />
  );
};

DraftsTabContent.propTypes = propTypes;
DraftsTabContent.defaultTypes = defaultProps;

export default withRouter(DraftsTabContent);
