import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NavigationMenu from './components/NavigationMenu';
import HeaderArea from './components/HeaderArea';
import { makeStyles } from '@material-ui/core/styles';
import { BUTTON_VARIANTS, PADDING_CONTENT, PADDING_CONTENT_TOP } from './constants/globals';
import { useIntl } from 'react-intl';
import Footer from './components/Footer';
import ProductFieldSet from './components/ProductFieldSet';
import { withRouter } from '../../hocs';
import {
  apiSupplierGetSharedProperties,
  apiSupplierProductGet,
  apiSupplierActivateProducts,
  apiSupplierAddProduct,
  apiSupplierAddVariantToProduct,
  apiSupplierDeleteVariants,
  apiSupplierEditProduct,
  apiSupplierInactivateProducts,
  apiSupplierProductBulkUpdateVariants,
} from '../../api';
import Spinner from './components/Spinner';
import { COLORS } from './constants/colors';
import VariantFieldSet from './components/VariantFieldSet';
import PrimaryButton from './components/SupplierButton';
import MinimalProduct from './entities/minimalProduct';
import MinimalVariant from './entities/minimalVariant';
import MinimalError from './entities/minimalError';
import { ErrorBox } from '../../components';
import ErrorToast from './components/ErrorToast';
import ActionToast, { TOAST_ACTIONS } from './components/ActionToast';
import { toggleSupplierPopover } from '../../store/actions';
import { POPOVER_STEPS } from './components/PopoverWithContent';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: COLORS.lightBg,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    boxSizing: 'border-box',
    paddingLeft: PADDING_CONTENT,
    paddingRight: PADDING_CONTENT,
    maxWidth: 900,
    textAlign: 'center',
    width: '100%',
    backgroundColor: 'white',
    marginBottom: 20,
    paddingTop: PADDING_CONTENT_TOP,
  },
  buttonsWrapper: {
    boxSizing: 'border-box',
    paddingLeft: PADDING_CONTENT,
    paddingRight: PADDING_CONTENT,
    maxWidth: 900,
    textAlign: 'center',
    width: '100%',
    marginBottom: 20,
  },
  title: {
    marginBottom: 20,
  },
  buttonWrapper: {
    marginBottom: 5,
  },
  errorWrapper: {
    paddingTop: 20,
  },
}));

const SupplierInventoryProductScene = (props) => {
  const { match, history } = props;

  const intl = useIntl();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { id: productId } = match.params;

  const { supplier } = useSelector((state) => ({
    supplier: state.producer.object,
  }));

  const setPopover = (newStep) => {
    if (!supplier?.types) return;

    let isFirstItem = true;

    Object.values(supplier.types).forEach((typeItemsCount) => {
      if (!!typeItemsCount) {
        isFirstItem = false;
      }
    });

    if (isFirstItem) dispatch(toggleSupplierPopover(newStep));
  };

  const [product, setProduct] = useState({});
  const [isEdited, setIsEdited] = useState(false);
  const [loading, setLoading] = useState(true);
  const [markedToDelete, setMarkedToDelete] = useState([]);
  const [error, setError] = useState(new MinimalError({}));
  const [toastAction, setToastAction] = useState('');

  const [types, setTypes] = useState([]);
  const [subTypes, setSubTypes] = useState([]);

  useEffect(() => {
    getProduct();
    loadProductsProperties();
    clearErrors();
  }, [productId]);

  const getProduct = () => {
    if (productId === 'new') {
      setProduct(new MinimalProduct({}));

      return;
    }
    apiSupplierProductGet(
      productId,
      (response) => {
        setProduct(response);
        setLoading(false);
      },
      (error) => {
        handleAnyError(error);
      }
    );
  };

  const loadProductsProperties = () => {
    // Retrieving types from server
    retrieveSharedProductProperties(
      'type',
      (types) => {
        // Retrieving sub types from server
        retrieveSharedProductProperties(
          'subType',
          (subTypes) => {
            setTypes(types.map((type) => type.name));
            setSubTypes(subTypes.map((type) => type.name));
            setLoading(false);
          },
          handleAnyError
        );
      },
      handleAnyError
    );
  };

  const retrieveSharedProductProperties = (type, onSuccess, onError) => {
    apiSupplierGetSharedProperties(
      {},
      type,
      (rows) => {
        onSuccess(rows);
      },
      onError
    );
  };

  const onRemoveVariant = (variantId) => {
    setIsEdited(true);

    const isNew = MinimalVariant.isVariantNew({ id: variantId });
    if (!markedToDelete.includes(variantId) && !isNew) {
      setMarkedToDelete([...markedToDelete, variantId]);
    }
    setProduct({ ...product, variants: product.variants.filter((item) => item.id !== variantId) });
  };

  const onChangeVariant = (changedVariant) => {
    const indexToChange = product.variants.findIndex((item) => item.id === changedVariant.id);
    const newVariants = [...product.variants];
    newVariants[indexToChange] = changedVariant;
    setIsEdited(true);

    setProduct({ ...product, variants: newVariants });
  };

  const onChangeProduct = (changedProduct) => {
    setIsEdited(true);
    setProduct({ ...product, ...changedProduct });
  };

  const onSaveChanges = () => {
    let data = new MinimalProduct(product);

    setLoading(true);
    clearErrors();

    if (product.id !== 'new') {
      apiSupplierEditProduct(
        product.id,
        data.getProductData(),
        async () => {
          await deleteMarkedVariants();

          await updateAllVariants().finally(() => {
            getProduct();
          });

          setToastAction(TOAST_ACTIONS.updated);
        },
        handleAnyError
      );
    } else {
      const readyData = data.getProductWithVariantsData();

      readyData.variants = readyData.variants.map((variant) => {
        if (!variant.inventory.quantity) {
          delete variant.inventory;
        }
        return { ...variant };
      });

      apiSupplierAddProduct(
        readyData,
        (response) => {
          history.replace(`/supplier/inventory/product/${response?.id}`);
          setToastAction(TOAST_ACTIONS.added);

          setPopover(POPOVER_STEPS.firstItemAdded);
        },
        handleAnyError
      );
    }
  };

  const updateAllVariants = async () => {
    await onVariantHasUpdateOnExistProduct(product.variants);
  };

  const deleteMarkedVariants = () => {
    return new Promise((resolve, reject) => {
      // do variants remove
      if (!!markedToDelete?.length) {
        apiSupplierDeleteVariants(
          markedToDelete,
          () => {
            resolve();
          },
          (error) => {
            reject();
            handleAnyError(error);
          }
        );
      } else {
        resolve();
      }
    });
  };

  const onVariantHasUpdateOnExistProduct = (variant, onDone = () => {}) => {
    const isNew = MinimalVariant.isVariantNew(variant) && !Array.isArray(variant);

    return new Promise((resolve, reject) => {
      if (isNew) {
        const data = new MinimalVariant(variant).getVariantData();

        apiSupplierAddVariantToProduct(
          product.id,
          data,
          (product) => {
            resolve(product);
            onDone(product);
          },
          (error) => {
            reject(error?.response);
            handleAnyError(error);
          }
        );
      } else {
        const newData = {
          variants: [],
        };

        variant.forEach((va) => {
          const isNew = MinimalVariant.isVariantNew(va);

          newData.variants.push({
            ...new MinimalVariant({
              ...va,
            }).getVariantData([isNew && 'id']),
          });
        });

        apiSupplierProductBulkUpdateVariants(
          product.id,
          newData,
          (product) => {
            resolve(product);
            onDone(product);
          },
          (error) => {
            reject(error?.response);
            handleAnyError(error);
          }
        );
      }
    });
  };

  const handleAnyError = (errorObj) => {
    setError(new MinimalError(errorObj));
    setLoading(false);
  };
  const clearErrors = () => {
    setError(new MinimalError({}));
  };

  const errorPreprocessor = () => {
    switch (error.getCode()) {
      default: {
        return intl.formatMessage({ id: 'global.somethingWentWrong' });
      }
    }
  };

  const unListProduct = () => {
    setLoading(true);
    if (product.active) {
      apiSupplierInactivateProducts(
        [product.id],
        () => {
          getProduct();

          setToastAction(TOAST_ACTIONS.unlisted);
        },
        handleAnyError
      );
    } else {
      apiSupplierActivateProducts(
        [product.id],
        () => {
          getProduct();

          setToastAction(TOAST_ACTIONS.listed);
        },
        handleAnyError
      );
    }
  };

  return (
    <div className={classes.root}>
      <ErrorToast />
      <ActionToast action={toastAction} onClose={() => setToastAction(TOAST_ACTIONS.empty)} />

      <HeaderArea
        onlyBackButton
        saveButton
        saveButtonDisabled={!isEdited || loading}
        onSaveButtonClick={() => {
          onSaveChanges();
        }}
      />
      <div className={classes.contentWrapper}>
        {error.hasError() && (
          <div className={classes.content}>
            <div className={classes.errorWrapper}>
              <ErrorBox error={errorPreprocessor()} />
            </div>
          </div>
        )}

        {!loading ? (
          <>
            <div className={classes.content}>
              <ProductFieldSet
                isNew={product.id === 'new'}
                product={product}
                types={types}
                subTypes={subTypes}
                onChangeProduct={onChangeProduct}
              />
            </div>

            {!!product?.variants?.length &&
              product.variants.map((variant, index) => (
                <div className={classes.content} key={variant.id}>
                  <VariantFieldSet
                    isNew={product.id === 'new'}
                    product={product}
                    variant={variant}
                    units={supplier.units}
                    types={types}
                    subTypes={subTypes}
                    isFirst={index === 0}
                    onRemoveVariant={onRemoveVariant}
                    onChangeVariant={onChangeVariant}
                  />
                </div>
              ))}
          </>
        ) : (
          <Spinner />
        )}

        <div className={classes.buttonsWrapper}>
          <div className={classes.buttonWrapper}>
            <PrimaryButton
              link={'#'}
              onClick={() => {
                setProduct({ ...product, variants: [...product.variants, new MinimalVariant({})] });
              }}
              text={intl.formatMessage({ id: 'supplier.inventory.addVariant' })}
              variant={BUTTON_VARIANTS.transparentBlue}
            />
          </div>
          {product.id !== 'new' && (
            <div className={classes.buttonWrapper}>
              <PrimaryButton
                link={'#'}
                onClick={() => {
                  unListProduct();
                }}
                text={intl.formatMessage({
                  id: product.active ? 'supplier.inventory.unlist' : 'supplier.inventory.activate',
                })}
                variant={BUTTON_VARIANTS.transparentRed}
              />
            </div>
          )}

          {product.id === 'new' && (
            <div className={classes.buttonWrapper}>
              <PrimaryButton
                link={'#'}
                onClick={() => {
                  history.goBack();
                }}
                text={intl.formatMessage({
                  id: 'supplier.inventory.cancel',
                })}
                variant={BUTTON_VARIANTS.transparentRed}
              />
            </div>
          )}
        </div>
      </div>
      <NavigationMenu />
      <Footer />
    </div>
  );
};

export default withRouter(SupplierInventoryProductScene);
