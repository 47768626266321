import { getDeliveriesUserToken } from '../helpers';

export const REQUEST_TYPES = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DEL: 'DELETE',
};

/**
 * Replaces url placeholder with parameter
 * @param {string} url - url
 * @param {string} param - parameter
 * @param {string|number} val
 * @return {string} - url
 */
export const replaceUrlPlaceholder = (url, param, val) => {
  return url.replace('{' + param + '}', val);
};

/**
 * Replaces url placeholders with parameters
 * @param {string} url - url
 * @param {object} data - {param : val, param : val}
 * @return {string} - url
 */
export const replaceUrlPlaceholders = (url, data) => {
  Object.keys(data).forEach((key) => {
    url = replaceUrlPlaceholder(url, key, data[key]);
  });
  return url;
};

/**
 * Generates request config
 * @param {string} method - method type
 * @param {string} endpoint - endpoint name
 */
export const getRequestConfig = (method, endpoint) => {
  let config = {
    method: method,
    url: process.env.REACT_APP_DELIVERIES_API_URL + endpoint,
    headers: {
      Authorization: 'Bearer ' + getDeliveriesUserToken(),
    },
  };

  if (method === REQUEST_TYPES.POST) {
    config.data = {};
  }

  return config;
};
