import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { COLORS } from '../../../helpers/index';

const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  text: {
    display: 'inline',
    fontSize: 14,
    color: COLORS.textSecondary,
    fontFamily: 'Roboto, sans-serif',
  },
  image: {
    height: 180,
  },
};

const propTypes = {
  producer: PropTypes.object.isRequired,
  disableDescription: PropTypes.bool,
};

const Logotype = ({ producer, intl, classes, disableDescription }) => {
  return (
    <div className={classes.wrapper}>
      {producer.logotype && <img src={producer.logotype.src} className={classes.image} alt={producer.name} />}

      {!producer.logotype && (
        <Typography className={classes.text} variant={'body1'}>
          {(!disableDescription && producer.description) || intl.formatMessage({ id: 'messages.notAdded' })}
        </Typography>
      )}
    </div>
  );
};

Logotype.propTypes = propTypes;

export default withStyles(styles)(injectIntl(Logotype));
