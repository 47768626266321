const styles = theme => ({
  footer: {
    background: "#212121",
    minHeight: 100,
    padding: '60px 110px 80px 110px',
    [theme.breakpoints.down('md')]: {
      padding: '40px 40px 40px 40px',
    },
  },
  footerTop: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #ffffff',
    paddingBottom: 20,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  footerBottom: {
    padding: 20,
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  footerTopLeft: {
    textAlign: 'left',
    marginRight: 110,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      marginRight: 0,
      marginBottom: 20,
    },
  },
  footerTopRight: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    marginRight: 110,
  },
  footerLogo: {
    width: 227,
  },
  information: {
    color: '#ffffff',
    fontSize: 18,
    fontWeight: 200,
  },
  infoTitle: {
    color: '#ffffff',
    textTransform: 'uppercase',
    fontWeight: 200,
  },
  link: {
    textDecoration: 'none',
    color: '#ffffff',
    cursor: 'pointer',
    fontWeight: 200,
  },
  inc: {
    color: "#ffffff"
  }
});

export default styles;