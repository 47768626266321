import React from 'react';
import {
  MuiThemeProvider,
  createTheme
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiButton: {
      text: {
        padding: '0px 5px',
      },
      root: {
        fontFamily: "'Roboto', sans-serif",
        fontWeight: 400,
        fontSize: 12,
        textTransform: 'normal',
        background: 'transparent',
        borderRadius: 0,
        borderBottom: '1px solid #363636',
        color: '#363636',
        height: 18,
        padding: '0px 5px',
        '&:hover': {
          backgroundColor: "transparent"
        }
      },
    },
  },
});

class ThirdButton extends React.Component {
  render = () => (
    <MuiThemeProvider theme={theme}>
      <Button {...this.props}>
        {this.props.children}
      </Button>
    </MuiThemeProvider>
  )
}

export default ThirdButton;
