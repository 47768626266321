import React from "react";
import PropTypes from "prop-types";
import moment from 'moment';
import {Table} from '@devexpress/dx-react-grid-material-ui';
import {injectIntl} from "react-intl";
import {withStyles} from "@material-ui/core/styles";
import Badge from "../../../components/Badge/Badge";
import BadgeColors from "../../../components/Badge/BadgeColors";
import {
  FINANCIAL_STATUSES,
  PAYMENT_METHODS
} from "../../../helpers";

const styles = ({
  cell: {
    //whiteSpace: 'normal'
  },
  badge: {
  },
  reason: {
    paddingTop: 5,
    fontSize: 12
  }
});

const propTypes = {
  row: PropTypes.object.isRequired,
};

class PaymentReceivedCell extends React.Component  {

  render() {
    const {intl, classes, row, ...other} = this.props;

    // When payment status is not paid. Don't display anything.
    if (row.financialStatus === FINANCIAL_STATUSES.UNPAID) {
      return <Table.Cell className={classes.cell} {...other}/>
    }

    // Cash || Check
    const cashOrCheck = [PAYMENT_METHODS.check, PAYMENT_METHODS.cash];
    if ( row.financialStatus === FINANCIAL_STATUSES.PAID &&
         row.payment &&
         row.payment.method &&
         row.payment.method.method &&
          (cashOrCheck.indexOf(row.payment.method.method) !== -1) &&
         row.payment.completedAt ) {
      return (
        <Table.Cell className={classes.cell} {...other}>
          <Badge title={moment(row.payment.completedAt).format("MM/DD/YY")} color={BadgeColors.success}/>
        </Table.Cell>
      )
    }

    // Credit card || Bank account
    const creditCardOrBankAccount = [PAYMENT_METHODS.STRIPE_BANK_ACCOUNT, PAYMENT_METHODS.STRIPE_CREDIT_CARD];
    if ( row.financialStatus === FINANCIAL_STATUSES.PAID &&
         row.payment &&
         row.payment.method &&
         row.payment.method.method &&
        (creditCardOrBankAccount.indexOf(row.payment.method.method) !== -1)) {

      /*const hasEst = !!(
        row.payment &&
        row.payment.processing &&
        row.payment.processing.balanceAvailableOn &&
        !row.payment.processing.payout
      );*/

      const hasEst = !!(
        row.payment &&
        row.payment.processing &&
        row.payment.processing.payout &&
        row.payment.processing.payout.availableOn
      );

      let est = "";
      if (hasEst) {
        const date = moment(row.payment.processing.payout.availableOn);
        est = moment().isSameOrAfter(date) ?  date.format('MM/DD/YY') : `${intl.formatMessage({id: "global.est"})} ${date.format('MM/DD/YY')}`
      }

      return (
        <Table.Cell className={classes.cell} {...other}>
          {hasEst && <Badge title={est} color={row.paidToProducer ? BadgeColors.success : BadgeColors.default}/>}
          {!hasEst && (
            <Badge title={intl.formatMessage({id: "global.processing"})} color={BadgeColors.default}/>
          )}
        </Table.Cell>
      );
    }

    // All other cases
    return (
      <Table.Cell className={classes.cell} {...other}/>
    )

  }
}

PaymentReceivedCell.propTypes = propTypes;

export default withStyles(styles)(
    injectIntl(PaymentReceivedCell)
);
