import React from "react";
import PropTypes from "prop-types";
//import {FormattedMessage} from 'react-intl';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {withStyles} from '@material-ui/core/styles';
import Logo from './assets/images/logo.png';
import LogoBlack from './assets/images/logo-black.png';
//import {FourthButton} from '../../../components';
import styles from './styles';

class LandingPageHeader extends React.Component {

  state = {
    isOnTop: true,
    inlineStyles: {background: 'transparent', boxShadow: 'none'}
  };

  componentDidMount = () => {
    window.addEventListener('scroll', this.handleScroll);
  };

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll);
  };

  handleScroll = () => {
    let scrollTop = Math.max(
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop
    );

    this.setState({
      isOnTop: scrollTop === 0,
      inlineStyles: scrollTop === 0 ?
        {background: 'transparent', boxShadow: 'none'} :
        {}
    });
  };

  render() {
    const {
      classes,
      //onLoginClick,
      //onSignUpClick
    } = this.props;

    return (
      <AppBar style={this.state.inlineStyles} className={classes.appBar} position="sticky">
        <Toolbar className={classes.topToolbar}>
          <div className={classes.logoContainer}>
            <img src={this.state.isOnTop ? Logo : LogoBlack} className={classes.logo} alt="Fellow Farmer"/>
          </div>

          <div className={classes.controls}>
            {/*<FourthButton
              className={classes.mr10}
              onClick={() => {onLoginClick && onLoginClick()}}>

              {
                this.state.isOnTop ?
                  <FormattedMessage id="global.login"/> :
                  <span className={classes.black}><FormattedMessage id="global.login"/></span>
              }

            </FourthButton>*/}

            {/*<FourthButton
              onClick={() => {onSignUpClick && onSignUpClick()}}>
              {
                this.state.isOnTop ?
                  <FormattedMessage id="reg.signUp"/> :
                  <span className={classes.black}><FormattedMessage id="reg.signUp"/></span>
              }

            </FourthButton>*/}
          </div>

        </Toolbar>
      </AppBar>
    );
  }
}

LandingPageHeader.propTypes = {
  onLoginClick: PropTypes.func,
  onSignUpClick: PropTypes.func,
  inlineStyles: PropTypes.object,
};

export default withStyles(
  styles, {withTheme: true}
)(LandingPageHeader);
