import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../hocs';
import { AdminDialog, AdminDialogContent, AdminDialogControls, PrimaryButton, Spinner } from '../../components';
import styles from './styles';

const propTypes = {
  url: PropTypes.string,
  classes: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const defaultProps = {};

const PrintInvoicesDialog = ({ onClose, intl, url, classes, loading }) => {
  return (
    <AdminDialog open closeButton onClose={onClose}>
      <AdminDialogContent className={classes.wrapper}>
        {loading && <Spinner size={50} />}
        {!loading && (
          <AdminDialogControls>
            <PrimaryButton
              className={classes.button}
              onClick={() => {
                window.open(url, '_blank');
                onClose && onClose();
              }}>
              {intl.formatMessage({ id: 'order.table.printInvoices' })}
            </PrimaryButton>
          </AdminDialogControls>
        )}
      </AdminDialogContent>
    </AdminDialog>
  );
};

PrintInvoicesDialog.propTypes = propTypes;
PrintInvoicesDialog.defaultProps = defaultProps;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(PrintInvoicesDialog)));
