import {
  ORDER_SET,
  ORDER_UNSET,
  ORDER_LOADED,
  ORDER_LOADING,
} from '../constants/order';

const initialState = {
  object: null,
  customer: null,
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ORDER_SET : {
      const {order, customer} = action.payload;
      return {
        ...state,
        object: order,
        customer: customer,
      }
    }
    case ORDER_UNSET : {
      return {
        ...state,
        object: null,
        customer: null,
      }
    }
    case ORDER_LOADING : {
      return {
        ...state,
        loading: true,
      }
    }
    case ORDER_LOADED : {
      return {
        ...state,
        loading: false,
      }
    }
    default:
      return state;
  }
};

export default reducer;

