import PaymentTerms from './PaymentTerms'
import { CUSTOMER_TYPE } from '../helpers'

class Customer {

  constructor(props ={}) {
    this.id = props.id || null;
    this.active = props.active || false;
    this.accounts = Array.isArray(props.accounts) ? props.accounts : [];
    this.allowCredit = props.allowCredit || false;
    this.autoCharge = props.autoCharge || false;
    this.companyName = props.companyName || '';
    this.billingAddresses = Array.isArray(props.billingAddresses) ? props.billingAddresses : [];
    this.defaultPaymentMethod = props.defaultPaymentMethod || {};
    this.email = props.email || '';
    this.emailSubscriptions = Array.isArray(props.emailSubscriptions) ? props.emailSubscriptions : [];
    this.firstName = props.firstName || '';
    this.lastName = props.lastName || '';
    this.customerName = props.customerName || '';
    this.freeDelivery = props.freeDelivery || false;
    this.paymentMethods = Array.isArray(props.paymentMethods) ? props.paymentMethods : [];
    this.phone = props.phone || '';
    this.terms = props.terms || null;
    this.productsCollections = Array.isArray(props.productsCollections) ? props.productsCollections : [];
    this.shippingAddresses = Array.isArray(props.shippingAddresses) ? props.shippingAddresses : [];
    this.type = props.type || '';
    this.passwordDefault = props.passwordDefault || null;
    this.username = props.username || '';
    this.bookToQuickBooks = props.bookToQuickBooks || null;
  }

  updateAccount(account) {
    this.accounts = this.accounts.map(item => {
      if (account.id === item.id) {
        return account
      }
      return item
    })
  }

  getCustomerName() {
    if (this.customerName)
      return this.customerName;

    return (this.type === CUSTOMER_TYPE.WHOLESALE)
        ? this.companyName
        : `${this.firstName} ${this.lastName}`;
  }

  getTerms() {
    return this.terms ?
      new PaymentTerms(this.terms) : null;
  }

}

export default Customer
