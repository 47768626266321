import React from "react";
import PropTypes from "prop-types";
import FellowFarmerLPHeader from './FellowFarmer/LandingPageHeader';
import GardsbudetLPHeader from './Gardsbudet/LandingPageHeader';

class LandingPageHeader extends React.Component {

  render() {
    const {
      appName,
      ...other
    } = this.props;

    switch (appName) {
      case 'fellow.farmer' : {
        return <FellowFarmerLPHeader {...other}/>;
      }
      case 'gardsbudet' : {
        return <GardsbudetLPHeader {...other}/>;
      }
      case 'gardshem' : {
        return <GardsbudetLPHeader {...other}/>;
      }
      default : {
        return <FellowFarmerLPHeader {...other}/>;
      }
    }
  }
}

LandingPageHeader.propTypes = {
  appName: PropTypes.string.isRequired,
  onLoginClick: PropTypes.func,
  onSignUpClick: PropTypes.func,
  inlineStyles: PropTypes.object,
};

export default LandingPageHeader;
