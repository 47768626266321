const validation = {
  email: {
    email: {
      message: '^validation.producerNotificationDialog.enterValidEmail'
    },
    presence: {
      message: '^validation.producerNotificationDialog.enterEmail'
    }
  },
  phone: {
    format: {
      pattern: /^\+[1-9]\d{1,14}$/,
      message: '^Please enter a valid phone'
    },
    presence: {
      message: '^Please enter a valid phone'
    }
  },
};

export default validation;
