import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import MenuItem from '@material-ui/core/MenuItem';
import PrimarySelect from '../../PrimarySelect/PrimarySelect';
import constants from './constants';
import { isEmpty, getCurrentProducer } from '../../../helpers';

const styles = {
  wrapper: {},
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  leftControl: {
    marginBottom: 20,
    marginRight: 20,
  },
  rightControl: {
    marginBottom: 20,
  },
};

const propTypes = {
  onRef: PropTypes.func,
  filters: PropTypes.array.isRequired,
  appliedFilters: PropTypes.array.isRequired,
  defaultFilterName: PropTypes.string.isRequired,
  openWithFilter: PropTypes.object,
  className: PropTypes.string,
  queryParamsByFilterName: PropTypes.object,
};
const defaultProps = {
  queryParamsByFilterName: null,
};

class DXFiltersManager extends React.Component {
  constructor(props) {
    super(props);

    const { intl, queryParamsByFilterName } = this.props;

    this.props.onRef && this.props.onRef(this);

    let selectedFilter = this.props.defaultFilterName;
    if (this.props.openWithFilter) {
      selectedFilter = this.props.openWithFilter.name;
    }

    let applied = props.appliedFilters.filter((filter) => filter.name === selectedFilter);

    const Filter = props.filters.filter(
      (Filter) =>
        Filter.getConfig(intl, constants.FILTER_EQUALITY_EQUALS, queryParamsByFilterName).name === selectedFilter
    )[0];

    if (!Filter) {
      selectedFilter = this.props.defaultFilterName;
    }

    this.state = {
      selectedEquality:
        applied.length && typeof applied[0].selectedEquality !== 'undefined'
          ? applied[0].selectedEquality
          : constants.FILTER_EQUALITY_EQUALS,
      sharedValues: {},
      selectedFilter: selectedFilter,
      selectedValues: applied.length
        ? applied[0].selectedValues
        : Filter.getConfig(intl, constants.FILTER_EQUALITY_EQUALS, queryParamsByFilterName).selectedValues,
      filters: this.props.filters,
      appliedFilters: this.props.appliedFilters || [],
    };
  }

  updateShared = (newShared, onUpdated) => {
    this.setState({ sharedValues: newShared }, onUpdated);
  };

  updateSelected = (newSelected, onUpdated) => {
    this.setState({ selectedValues: newSelected }, onUpdated);
  };

  getAppliedFilters = () => {
    const { intl, queryParamsByFilterName } = this.props;
    const { appliedFilters, selectedFilter, selectedValues } = this.state;
    let appliedFiltersCopy = JSON.parse(JSON.stringify(appliedFilters));

    let index = appliedFiltersCopy.findIndex((element) => {
      return element.name === selectedFilter;
    });

    if (index !== -1 && selectedValues) {
      if (Object.keys(selectedValues).length) {
        appliedFiltersCopy[index].selectedValues = selectedValues;
      } else {
        appliedFiltersCopy.splice(index, 1);
      }
    } else {
      let config = this.getSelectedFilter().getConfig(intl, this.state.selectedEquality, queryParamsByFilterName);
      config.setSelectedValues(selectedValues);
      appliedFiltersCopy.push(config.getConfig());
    }
    return appliedFiltersCopy;
  };

  onApplyFilter = (onApplied) => {
    const { intl, queryParamsByFilterName } = this.props;
    this.setState((state) => {
      let processed = false;
      for (let i = 0; i < state.appliedFilters.length; i++) {
        if (state.appliedFilters[i].name === state.selectedFilter) {
          processed = true;

          state.appliedFilters[i].selectedEquality = this.state.selectedEquality;

          if (isEmpty(state.selectedValues)) {
            delete state.appliedFilters[i];
          } else {
            state.appliedFilters[i] = state.selectedValues;
          }
        }
      }

      if (!processed && !isEmpty(state.selectedValues)) {
        let config = this.getSelectedFilter().getConfig(intl, this.state.selectedEquality, queryParamsByFilterName);
        config.setSelectedValues(state.selectedValues);
        state.appliedFilters.push(config);
      }

      return state;
    }, onApplied);
  };

  onChangeEquality = (equality) => {
    const { intl, queryParamsByFilterName } = this.props;
    this.setState((state) => {
      state.selectedEquality = equality;
      for (let i = 0; i < state.appliedFilters.length; i++) {
        if (state.appliedFilters[i].name === state.selectedFilter) {
          let config = this.getSelectedFilter().getConfig(intl, this.state.selectedEquality, queryParamsByFilterName);
          config.setSelectedValues(state.selectedValues);
          state.appliedFilters[i] = config;
        }
      }
      return state;
    });
  };

  onChangeFilter = (selectedFilter) => {
    const { intl, queryParamsByFilterName } = this.props;

    return this.setState((state) => {
      state.selectedFilter = selectedFilter;
      let applied = state.appliedFilters.filter((filter) => filter.name === selectedFilter);

      const Filter = this.getFilter(selectedFilter);
      const config = Filter.getConfig(intl, this.state.selectedEquality, queryParamsByFilterName);

      state.sharedValues = config.shared;
      state.selectedValues = config.selected;

      if (applied.length > 0) {
        state.selectedValues = applied[0].selectedValues;
      }
      return state;
    });
  };

  getSelectedFilter = () => {
    return this.getFilter(this.state.selectedFilter);
  };

  getFilter = (name) => {
    const { intl, queryParamsByFilterName } = this.props;

    const res = this.state.filters.filter((Filter) => {
      return Filter.getConfig(intl, this.state.selectedEquality, queryParamsByFilterName).name === name;
    });

    return res[0];
  };

  getFilterView = () => {
    const { queryParamsByFilterName } = this.props;

    const Filter = this.getSelectedFilter();
    return (
      !!Filter && (
        <Filter
          selected={this.state.selectedValues}
          equality={this.state.selectedEquality}
          producer={getCurrentProducer()}
          shared={this.state.sharedValues}
          onChangeShared={this.updateShared}
          onChange={this.updateSelected}
          queryParamsByFilterName={queryParamsByFilterName}
        />
      )
    );
  };

  render() {
    const { filters, intl, classes, queryParamsByFilterName } = this.props;

    const Filter = this.getSelectedFilter();
    const config = Filter ? Filter.getConfig(intl, this.state.selectedEquality, queryParamsByFilterName) : {};
    const FilterVariants = config.variants;

    return (
      <div className={classes.wrapper}>
        <div className={classes.row}>
          <div className={classes.column}>
            <PrimarySelect
              fullWidth
              label={intl.formatMessage({ id: 'global.column' })}
              value={config.name}
              onChange={(e) => this.onChangeFilter(e.target.value)}
              formControlClassName={classes.leftControl}>
              {filters.map((Filter) => {
                let config = Filter.getConfig(intl, this.state.selectedEquality, queryParamsByFilterName);
                return (
                  <MenuItem key={config.name} value={config.name} disabled={config.disabled}>
                    {config.title}
                  </MenuItem>
                );
              })}
            </PrimarySelect>
          </div>

          <div className={classes.column}>
            {!config.variants && (
              <PrimarySelect
                label={intl.formatMessage({ id: 'global.filter' })}
                value={this.state.selectedEquality}
                fullWidth
                onChange={(e) => this.onChangeEquality(e.target.value)}
                formControlClassName={classes.rightControl}
                disabled={!config.equalityStatus}>
                <MenuItem key={constants.FILTER_EQUALITY_EQUALS} value={constants.FILTER_EQUALITY_EQUALS}>
                  {intl.formatMessage({ id: 'global.filter.equals' })}
                </MenuItem>
                <MenuItem key={constants.FILTER_EQUALITY_NOT_EQUALS} value={constants.FILTER_EQUALITY_NOT_EQUALS}>
                  {intl.formatMessage({ id: 'global.filter.not_equals' })}
                </MenuItem>
              </PrimarySelect>
            )}

            {config.variants && (
              <FilterVariants
                selected={this.state.selectedValues}
                shared={this.state.sharedValues}
                onChangeShared={this.updateShared}
                onChange={this.updateSelected}
                value={this.state.selectedEquality}
                onChangeEquality={(e) => this.onChangeEquality(e.target.value)}
              />
            )}
          </div>
        </div>

        {this.getFilterView()}
      </div>
    );
  }
}

DXFiltersManager.propTypes = propTypes;
DXFiltersManager.defaultProps = defaultProps;

export default injectIntl(withStyles(styles)(DXFiltersManager));
