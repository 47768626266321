import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import styles from './styles';
import { connect } from 'react-redux';
import { HEIGHT_OF_TOP_BAR, WIDTH_OF_SIDEBAR, WIDTH_OF_SIDEBAR_COLLAPSED } from '../../helpers';

const propTypes = {
  tab: PropTypes.number,
  href: PropTypes.string,
  onChangeTab: PropTypes.func.isRequired,
  tabs: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

class AdminTabs extends React.Component {
  handleChange = (event, value) => {
    this.props.onChangeTab(value);
  };

  getAppBarStyle = () => {
    const { drawerExpand } = this.props;

    const sideBarWidth = drawerExpand ? WIDTH_OF_SIDEBAR : WIDTH_OF_SIDEBAR_COLLAPSED;

    return { top: HEIGHT_OF_TOP_BAR, left: sideBarWidth, width: window.innerWidth - sideBarWidth };
  };

  render() {
    const { classes, tabs, tab } = this.props;

    return (
      <React.Fragment>
        <AppBar className={classes.appBar} position="fixed" style={this.getAppBarStyle()}>
          <Tabs
            value={tab}
            onChange={this.handleChange}
            classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator, scrollButtons: classes.scrollButtons }}
            variant="scrollable"
            scrollButtons="auto">
            {tabs.map((tab, index) => (
              <Tab
                label={tab.name || tab}
                href={tab.href || '#'}
                key={`${index}-${tab.name || tab}`}
                component="a"
                onClick={(event) => event.preventDefault()}
                classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
              />
            ))}
          </Tabs>
        </AppBar>
        {/*Pseudo AppBar*/}
        <div style={{ height: HEIGHT_OF_TOP_BAR }} />
        {this.props.children}
      </React.Fragment>
    );
  }
}

AdminTabs.propTypes = propTypes;

export default connect((state) => ({
  drawerExpand: state.common.drawerExpand,
}))(withStyles(styles)(AdminTabs));
