const styles = ({
  table: {
    marginBottom: 10
  },
  row: {
    padding: '0 !important',
  },
  redRow: {
    padding: 0,
    backgroundColor: 'rgba(245, 0, 87, 0.1)',
  },
  subType: {
    color: '#855E42'
  },
  note: {
    fontSize: 10
  },
  noteTitle: {
    fontWeight: 'bold'
  },
  orderNote: {
    width: '100%'
  }
});

export default styles;