import React from 'react';
import {
  MuiThemeProvider,
  createTheme
} from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: '#212121',
    },
    secondary: {
      main: '#363636'
    },
    white: {
      main: '#ffffff',
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: "#363636",
        "&$focused": {
          color: "#363636"
        }
      },
      /*"&$focused": {
        "&$focused": {
          color: "#41CD8C"
        }
      }*/
    },

    MuiInput: {
      root: {
        color: `#363636`,
        minHeight: 40,
      },
      underline: {
        '&:after': {
          borderBottom: `2px solid #363636`,
        },
        '&$focused:after': {
          borderBottomColor: `#363636`,
        },
        '&$error:after': {
          borderBottomColor: `#363636`,
        },
        '&:before': {
          borderBottom: `1px solid #E0E0E0`,
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: `2px solid #E0E0E0`,
        },
        '&$disabled:before': {
          borderBottom: `1px dotted #E0E0E0`,
        },
      },
    },
    MuiInputBase: {
      multiline: {
        marginTop: '25px !important',
        borderRadius: 10,
        border: '1px solid #E0E0E0',
        boxShadow: '0px 3px 6px #00000029',
        '&:after, &:before': {
          borderBottom: '0 !important'
        },
        padding: 10
      },
    }
  },
});

class PrimaryTextField extends React.Component {
  render = () => (
    <MuiThemeProvider theme={theme} >
      <TextField {...this.props} >
        {this.props.children}
      </TextField>
    </MuiThemeProvider>
  )
}

export default PrimaryTextField;
