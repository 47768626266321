import React from 'react'
import { injectIntl } from 'react-intl'
import { Table } from '@devexpress/dx-react-grid-material-ui'
import {withStyles} from '@material-ui/core/styles'
import QBAccountCell from '../Cells/QBAccountCell'
import {
  apiQuickBooksGetAccountsMapping,
} from '../../../api'
import {
  AdminDXTable,
  Spinner
} from '../../../components'

const styles = ({
  wrapper: {
    position: 'relative'
  }
});

export const TableCell = (reload, {row, ...props}) => {
  if (props.column.name === 'qb') {
    return <QBAccountCell row={row} {...props}/>
  }
  return <Table.Cell {...props} />;
};

class QuickBookAccounts extends React.Component {


  constructor(props) {
    super(props);
    const { intl } = this.props;

    this.table = null;
    this.columns = [
      { name: 'account',      title: intl.formatMessage({id: 'global.account'}) },
      { name: 'qb',           title: intl.formatMessage({id: 'global.qb'}) }
    ];

    this.state = {
      loading: true,
      options : []
    }
  }

  componentDidMount() {
    this._getMapping();
  }

  _getMapping = () => {
    this.setState({loading: false});

    apiQuickBooksGetAccountsMapping((mapping) => {
      this.setState({
        loading: false,
        options: mapping.map(item => {
          return {
            id: `${item.accountId}-${item.name}`,
            qb: item.accountId,
            account: item.name,
            suggestions: item.suggestions.map(suggestion => {
              return {id: suggestion.id, label: suggestion.name}
            })
          }
        }),
      }, () => this.table.forceReload())
    }, () => {
      this.setState({loading: false})
    })
  };

  componentWillUnmount() {
    this.table = null;
  }

  render() {
    const {
      props: {classes},
      state: {loading, options}
    } = this;

    return (
      <div className={classes.wrapper}>
        {loading && <Spinner size={40} />}

        <AdminDXTable
          onRef={table => this.table = table}
          cellRenderer={TableCell}
          enableToolbar={false}
          enableSearch={false}
          apiRetrieve={(params, onSuccess) =>
            onSuccess(options)}
          columns={this.columns}
        />
      </div>
    );
  }
}

export default withStyles(styles)(
  injectIntl(QuickBookAccounts)
);

