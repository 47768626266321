import {
  DRAFT_SET,
  DRAFT_UNSET,
  DRAFT_LOADING,
  DRAFT_LOADED,
} from '../constants/draft';

const initialState = {
  object: null,
  customer: null,
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DRAFT_SET : {
      const {draft, customer} = action.payload;
      return {
        ...state,
        object: draft,
        customer: customer,
      }
    }
    case DRAFT_UNSET : {
      return {
        ...state,
        object: null,
        customer: null,
      }
    }
    case DRAFT_LOADING : {
      return {
        ...state,
        loading: true,
      }
    }
    case DRAFT_LOADED : {
      return {
        ...state,
        loading: false,
      }
    }
    default:
      return state;
  }
};

export default reducer;

