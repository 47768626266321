import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import {COLORS} from "../../helpers";

const propTypes = {
  title: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  address: PropTypes.object.isRequired,
};

const styles = {
  separator: {
    width: '100%',
    margin: '20px 0',
    height: '1px',
    border: 0,
    borderTop: '1px solid ' + COLORS.border
  },
  rightBoxText: {
    fontSize: 14,
    fontWeight: 400,
    fontFamily: 'Roboto',
    color: COLORS.text
  },
  bold: {
    fontWeight: 600,
  }
};

const Address = ({title,
                  address,
                  classes }) => {
  return (
    <React.Fragment>
      <hr className={classes.separator}/>
      <Typography style={{marginTop: 10}} className={classNames(classes.rightBoxText, classes.bold)}>
        {title}
      </Typography>
      <Typography className={classes.rightBoxText}>
        {address.address1}
      </Typography>
      <Typography className={classes.rightBoxText}>
        {address.city}, {address.zip}
      </Typography>
    </React.Fragment>
  )
};

Address.propTypes = propTypes;

export default withStyles(styles)(
  Address
);