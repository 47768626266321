import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import CancelIcon from '@material-ui/icons/Cancel';
import {
  apiUniqueProductsGetByCustomer,
  apiUniqueProductsAdd,
  apiUniqueProductsDelete,
  apiProductsSearch,
} from '../../api';
import { PrimaryButton, VariantsSuggestions, Spinner, CustomersSuggestions } from '../../components';

const styles = {
  chipsWrap: {
    width: '100%',
  },
  chip: {
    margin: 5,
  },
  productsTableWrap: {
    width: '100%',
  },
  actionsWrap: {
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
    paddingTop: 30,
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: 440,
  },
  tint: {
    color: '#A3A6B4',
    fontSize: 11,
  },
};

const propTypes = {
  onSuccess: PropTypes.func,
};

class UniqueProductsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: null,
      variants: [],
      errors: {},
    };
  }

  _validate = () => {
    let errors = {};
    this.setState({ errors });
    return Object.values(errors).every((item) => item === null);
  };

  _handleSubmit = (e) => {
    e.preventDefault();

    const { onSuccess } = this.props;

    const { customer, variants } = this.state;

    const valid = this._validate();

    if (!valid) return;

    const newVariants = variants.filter((variant) => variant.justAdded).map((variant) => variant.id);

    if (newVariants.length) {
      this.setState({ loading: true });
      apiUniqueProductsAdd(customer.id, newVariants, () => {
        this.setState({ loading: false });
        onSuccess && onSuccess();
      });
    }
  };

  _handleAddCustomer = (customer) => {
    this.setState({
      loading: true,
      customer,
    });

    apiUniqueProductsGetByCustomer(
      customer.id,
      (products) => {
        this.setState({
          loading: false,
          variants: products.map((product) => {
            return {
              id: product.variants[0].id,
              name: product.name || '',
              variantName: product.variants[0].name || '',
              justAdded: false,
            };
          }),
        });
      },
      undefined
    );
  };

  _handleDeleteCustomer = () => {
    this.setState({
      customer: null,
      variants: [],
    });
  };

  _handleAddVariant = (variant) => {
    this.setState({
      variants: _.unionBy(this.state.variants, [variant], (item) => item.id),
    });
  };

  _handleDeleteVariant = (variant) => {
    const { customer } = this.state;
    this.setState({
      variants: this.state.variants.filter((item) => item.id !== variant.id),
    });

    if (!variant.justAdded) {
      this.setState({ loading: true });
      apiUniqueProductsDelete(
        customer.id,
        variant.id,
        () => {
          this.setState({ loading: false });
        },
        (error) => console.warn(error)
      );
    }
  };

  render() {
    const { intl, classes } = this.props;
    const { customer, variants } = this.state;

    return (
      <form onSubmit={this._handleSubmit} className={classes.form}>
        {this.state.loading && <Spinner size={40} />}

        <CustomersSuggestions
          selected={null}
          labelExtractor={(item) => item.customerName}
          inputLabel={intl.formatMessage({ id: 'global.customer' })}
          onDetach={() => {}}
          onAttach={(customer) => this._handleAddCustomer(customer)}
        />

        <div className={classes.chipsWrap}>
          {customer && (
            <Chip
              classes={{ root: classes.chip }}
              key={customer.id}
              label={`${customer.customerName}`}
              onDelete={() => this._handleDeleteCustomer(customer)}
              className={classes.chip}
            />
          )}
        </div>

        {customer && (
          <React.Fragment>
            <VariantsSuggestions
              selected={null}
              retriever={apiProductsSearch}
              inputLabel={intl.formatMessage({ id: 'global.products' })}
              onDetach={() => {}}
              clearAfterSelect
              onAttach={(product, variant) => {
                let data = {
                  id: variant.id,
                  variantName: variant?.name || '',
                  name: product.name,
                };

                this._handleAddVariant({ ...data, ...{ justAdded: true } });
              }}
            />

            <div className={classes.productsTableWrap}>
              <Paper>
                <List disablePadding>
                  <ListItem divider>
                    <ListItemText
                      classes={{ primary: classes.tint }}
                      primary={intl.formatMessage({ id: 'global.product' })}
                    />
                  </ListItem>
                  {this.state.variants.map((item) => (
                    <ListItem key={item.id} divider>
                      <ListItemText primary={`${item.name}${!!item?.variantName ? ' - ' + item.variantName : ''}`} />
                      <ListItemSecondaryAction>
                        <IconButton onClick={() => this._handleDeleteVariant(item)}>
                          <CancelIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </div>
          </React.Fragment>
        )}

        <div className={classes.actionsWrap}>
          <PrimaryButton
            type="submit"
            disabled={!customer || variants.filter((variant) => variant.justAdded).length === 0}>
            {intl.formatMessage({ id: 'global.save' })}
          </PrimaryButton>
        </div>
      </form>
    );
  }
}

UniqueProductsForm.propTypes = propTypes;

export default withStyles(styles)(injectIntl(UniqueProductsForm));
