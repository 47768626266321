import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import {
  PrimarySelect,
  PrimaryInlineDatePicker
} from '../../components';

const styles = ({
  selectFormControl: {
    minWidth: '10%'
  },
  dateSelects: {
    width: 200,
    display: 'inline-block',
    marginBottom: 20
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
});

const SHIPPING_FILTER_CUSTOM = 'custom';
const SHIPPING_FILTER_THIS_WEEK = 'this_week';
const SHIPPING_FILTER_LAST_WEEK = 'last_week';
const SHIPPING_FILTER_TOMORROW = 'tomorrow';
const SHIPPING_FILTER_THIS_MONTH = 'this_month';
const SHIPPING_FILTER_LAST_MONTH = 'last_month';
const SHIPPING_FILTER_PAST_THREE_MONTH = 'past_3_months';
const SHIPPING_FILTER_PAST_6_MONTHS = 'past_6_months';
const SHIPPING_FILTER_PAST_YEAR = 'past_year';

class InlineDatesFilter extends React.Component {
  state = {
    dateFilterDefinition: -1,
    dateFilterValue: {
      from: null,
      to: null
    }
  };

  handleChangeDateFilterDefinition = (e) => {
    const {prefix} = this.props;
  
    if (e.target.value !== -1) {
      const dateFilterDefinition = e.target.value;
      this.setState({dateFilterDefinition: dateFilterDefinition});
      if (dateFilterDefinition !== SHIPPING_FILTER_CUSTOM) {
        this.props.onChangeInlineDateFilter([
          {property: (prefix ? `${prefix}:date-param` : `shipping:date-param`), selectedValues: dateFilterDefinition}
        ])
      }
    }
  };

  handleChangeDateFilterValue = (value, part) => {
    const {prefix} = this.props;
    let dateFilterValue = JSON.parse(JSON.stringify(this.state.dateFilterValue));
    if (part === 'from') {
      dateFilterValue.from = value.startOf('day').toISOString(true);
    } else if (part === 'to') {
      dateFilterValue.to = value.endOf('day').toISOString(true);
    }
    this.setState({ dateFilterValue }, () => {
      if (this.state.dateFilterValue.from && this.state.dateFilterValue.to) {
        this.props.onChangeInlineDateFilter([
          {property: (prefix ? `${prefix}:from` : `shipping:from`), selectedValues: this.state.dateFilterValue.from},
          {property: (prefix ? `${prefix}:to` : `shipping:to`), selectedValues: this.state.dateFilterValue.to},
        ]);
      }
    });
  };

  render() {
    const {
      classes,
      intl
    } = this.props;

    const {
      dateFilterDefinition,
      dateFilterValue
    } = this.state;

    return (
      <div className={classes.column}>
        <PrimarySelect
          value={dateFilterDefinition}
          formControlClassName={classes.selectFormControl}
          onChange={this.handleChangeDateFilterDefinition}>
          <MenuItem value={-1}>{intl.formatMessage({id: 'reports.shippingDates'})}</MenuItem>
          <MenuItem key={SHIPPING_FILTER_TOMORROW} value={SHIPPING_FILTER_TOMORROW}>
            {intl.formatMessage({id: 'filter.date.tomorrow'})}
          </MenuItem>
          <MenuItem key={SHIPPING_FILTER_THIS_WEEK} value={SHIPPING_FILTER_THIS_WEEK}>
            {intl.formatMessage({id: 'filter.date.this_week'})}
          </MenuItem>
          <MenuItem key={SHIPPING_FILTER_LAST_WEEK} value={SHIPPING_FILTER_LAST_WEEK}>
            {intl.formatMessage({id: 'filter.date.last_week'})}
          </MenuItem>
          <MenuItem key={SHIPPING_FILTER_THIS_MONTH} value={SHIPPING_FILTER_THIS_MONTH}>
            {intl.formatMessage({id: 'filter.date.this_month'})}
          </MenuItem>
          <MenuItem key={SHIPPING_FILTER_LAST_MONTH} value={SHIPPING_FILTER_LAST_MONTH}>
            {intl.formatMessage({id: 'filter.date.last_month'})}
          </MenuItem>
          <MenuItem key={SHIPPING_FILTER_PAST_THREE_MONTH} value={SHIPPING_FILTER_PAST_THREE_MONTH}>
            {intl.formatMessage({id: 'filter.date.past_3_months'})}
          </MenuItem>
          <MenuItem key={SHIPPING_FILTER_PAST_6_MONTHS} value={SHIPPING_FILTER_PAST_6_MONTHS}>
            {intl.formatMessage({id: 'filter.date.past_6_months'})}
          </MenuItem>
          <MenuItem key={SHIPPING_FILTER_PAST_YEAR} value={SHIPPING_FILTER_PAST_YEAR}>
            {intl.formatMessage({id: 'filter.date.past_year'})}
          </MenuItem>
          <MenuItem key={SHIPPING_FILTER_CUSTOM} value={SHIPPING_FILTER_CUSTOM}>
            {intl.formatMessage({id: 'filter.date.custom'})}
          </MenuItem>
        </PrimarySelect>

        {dateFilterDefinition === SHIPPING_FILTER_CUSTOM && (
          <div style={{marginTop: 10}}>
            <div className={classes.dateSelects}>
              <PrimaryInlineDatePicker
                label={intl.formatMessage({id: 'global.from'})}
                value={dateFilterValue.from ? dateFilterValue.from : null}
                onChange={value => this.handleChangeDateFilterValue(value, 'from')}
              />
            </div>

            <div className={classes.dateSelects}>
              <PrimaryInlineDatePicker
                label={intl.formatMessage({id: 'global.to'})}
                value={dateFilterValue.to ? dateFilterValue.to : null}
                onChange={value => this.handleChangeDateFilterValue(value, 'to')}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

InlineDatesFilter.propTypes = {
  onChangeInlineDateFilter: PropTypes.func.isRequired,
  prefix: PropTypes.string,
};

export default withStyles(styles)(
  injectIntl(InlineDatesFilter)
)
