import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { PrimarySelect } from '../../../../components';
import { BILLING_ADDRESS_TYPE, DELIVERY_ADDRESS_TYPE, makeStringAddress } from '../../../../helpers';
import classNames from 'classnames';

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  address: {
    width: '80%',
    marginBottom: 5,
  },
};

const propTypes = {
  type: PropTypes.oneOf([DELIVERY_ADDRESS_TYPE, BILLING_ADDRESS_TYPE]),
  onChange: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
};

const Address = ({ classes, order, type, customer, onChange, wrapperClass, intl }) => {
  const address = order.customer[`${type}Address`];

  return (
    <div className={classNames(classes.wrapper, wrapperClass)}>
      <PrimarySelect
        displayEmpty
        className={classes.address}
        label={intl.formatMessage({ id: `global.${type}Address` })}
        value={address ? address.id : -1}
        onChange={(e) => e.target.value !== -1 && onChange(e.target.value)}>
        <MenuItem key={-1} value={-1}>
          {intl.formatMessage({ id: 'customer.selectAddress' })}
        </MenuItem>

        {customer[`${type}Addresses`].map((item) => (
          <MenuItem key={`${type}${item.id}`} value={item.id}>
            {makeStringAddress(item)}
          </MenuItem>
        ))}
      </PrimarySelect>
    </div>
  );
};

Address.propTypes = propTypes;

export default withStyles(styles)(injectIntl(Address));
