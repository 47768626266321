import axios from 'axios';
import {
  getRequestConfig,
  REQUEST_TYPES,
  replaceUrlPlaceholder,
} from '../request'
import API_END_POINTS from  '../endpoints'

/**
 * Get all invoices
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerInvoicesGetList = function (onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.PRODUCER_INVOICES_GET_ALL);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Fetch single invoice by id
 * @param {string} id - invoice id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerInvoicesGetOne = function (id, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRODUCER_INVOICES_GET_ONE, 'id', id);
  const config = getRequestConfig(REQUEST_TYPES.GET, url);
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Add invoice bank account via Plaid
 * @param {string} publicToken - plaid token
 * @param {string} plaidMetaData - plaid meta
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerInvoiceAddBankAccountViaPlaid = function (publicToken, plaidMetaData, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.PRODUCER_INVOICES_ADD_ACH_PLAID);
  config.data = {
    public_token: publicToken,
    account_id: plaidMetaData.account_id,
  };

  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Add invoice bank account via Plaid
 * @param {string} id - plaid token
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProducerPayInvoice = function (id, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRODUCER_INVOICES_PAY, 'id', id);
  const config = getRequestConfig(REQUEST_TYPES.POST, url);
  config.data = {};
  axios(config).then((response) => {
    onSuccess && onSuccess(response.data);
  }).catch((error) => {
    onError && onError(error);
  });
};

/**
 * Generates url to invoice pdf
 * @param {string} id - invoice id
 */
export const producerGetInvoicePdfUrl = function (id) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PRODUCER_INVOICES_GET_PDF, 'id', id);
  return process.env.REACT_APP_RESOURCES_URL + url;
};

