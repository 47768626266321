import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  AdminDialogContent,
  AdminDialogControls,
  PrimaryButton,
  PrimaryCheckbox,
  PrimaryTextField,
  Spinner,
} from '../../components';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from '../../hocs';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import validationRules from './validation';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { apiProducerAccountsPatch } from '../../api';
import { NOTIFICATIONS_TYPES, validateField } from '../../helpers';
import Typography from '@material-ui/core/Typography';

const propTypes = {
  onAddNotification: PropTypes.func.isRequired,
  selectedRow: PropTypes.object.isRequired,
  reloadTable: PropTypes.func.isRequired,
  setError: PropTypes.func,
};

const defaultProps = {
  setError: () => {},
};

function AddStep(props) {
  const { classes, intl, onAddNotification, selectedRow, reloadTable, setError } = props;

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(selectedRow.email);
  const [emailError, setEmailError] = useState('');
  const [phone, setPhone] = useState(selectedRow.phone);
  const [phoneError, setPhoneError] = useState('');
  const [dataEmail, setDataEmail] = useState({
    newOrder: false,
    orderNotApproved: false,
    orderPaymentDeclined: false,
    paymentReport: false,
    packedOrdersReport: false,
  });
  const [dataPush, setDataPush] = useState({
    newOrder: false,
  });
  const [dataSms, setDataSms] = useState({
    newOrder: false,
  });
  const hasEmail = selectedRow.email;
  const hasPhone = selectedRow.phone;

  const validate = () => {
    let validationEmailError = false;
    let validationPhoneError = false;
    const isEmailNotificationOn = Object.values(dataEmail).includes(true);
    const isPhoneNotificationOn = Object.values(dataSms).includes(true);

    if (!hasEmail && (!!email || isEmailNotificationOn)) {
      validationEmailError = validateField(validationRules, 'email', email);
    }
    if (!hasPhone && (!!phone || isPhoneNotificationOn)) {
      validationPhoneError = validateField(validationRules, 'phone', phone);
    }
    setLoading(true);

    if (!validationEmailError && !validationPhoneError) {
      if (!hasEmail || !hasPhone) {
        apiProducerAccountsPatch(
          selectedRow.id,
          {
            ...(!!email ? { email } : {}),
            ...(!!phone ? { phone } : {}),
          },
          () => {
            onAddNotification({
              emailNotifications: {
                ...dataEmail,
                email,
              },
              pushNotifications: dataPush,
              smsNotifications: dataSms,
            });
            setLoading(false);
            reloadTable();
          },
          (error) => {
            setLoading(false);
            setError(error.response.data && error.response.data.message);
            if (error.response.data.code === 5003) {
              setPhoneError(error.response.data.message);
            }
          }
        );
      } else {
        onAddNotification({
          emailNotifications: {
            ...dataEmail,
            email,
          },
          pushNotifications: dataPush,
          smsNotifications: dataSms,
        });
        setLoading(false);
      }
    } else {
      setEmailError(validationEmailError);
      setPhoneError(validationPhoneError);
      setLoading(false);
    }

    return false;
  };

  const getCheckbox = (fieldName, label, type) => {
    return (
      <FormControlLabel
        control={
          <PrimaryCheckbox
            checked={isChecked[fieldName]}
            onChange={(event) => onCheckboxChange(fieldName, event.target.checked, type)}
            value={fieldName}
          />
        }
        label={label}
      />
    );
  };

  const onCheckboxChange = (fieldName, value, type) => {
    if (type === NOTIFICATIONS_TYPES.EMAIL) setDataEmail({ ...dataEmail, [fieldName]: value });
    if (type === NOTIFICATIONS_TYPES.PUSH) setDataPush({ ...dataPush, [fieldName]: value });
    if (type === NOTIFICATIONS_TYPES.SMS) setDataSms({ ...dataSms, [fieldName]: value });
  };

  const isChecked = (fieldName) => {
    return dataEmail[fieldName] || false;
  };

  return (
    <AdminDialogContent className={classes.wrapper}>
      {loading && <Spinner size={50} />}

      <div className={classes.sectionWrapper}>
        <Typography component="h3" variant="subtitle1" className={classes.sectionTitle}>
          {intl.formatMessage({ id: 'global.emailNotifications' })}
        </Typography>
        <PrimaryTextField
          error={!!emailError}
          disabled={!!hasEmail}
          onBlur={() => validateField('email')}
          onChange={(event) => setEmail(event.target.value)}
          value={email || ''}
          required
          autoFocus
          margin="dense"
          id="email"
          label={intl.formatMessage({ id: 'global.email' })}
          helperText={emailError ? intl.formatMessage({ id: emailError }) : ''}
          fullWidth
        />
        <div className={classes.row}>
          <div className={classes.column}>
            {getCheckbox(
              'newOrder',
              intl.formatMessage({ id: 'screen.producerNotificationDialog.newOrder' }),
              NOTIFICATIONS_TYPES.EMAIL
            )}
            {getCheckbox(
              'orderNotApproved',
              intl.formatMessage({ id: 'screen.producerNotificationDialog.orderNotApproved' }),
              NOTIFICATIONS_TYPES.EMAIL
            )}
            {getCheckbox(
              'orderPaymentDeclined',
              intl.formatMessage({ id: 'screen.producerNotificationDialog.orderPaymentDeclined' }),
              NOTIFICATIONS_TYPES.EMAIL
            )}
          </div>
          <div className={classes.column}>
            {getCheckbox(
              'paymentReport',
              intl.formatMessage({ id: 'screen.producerNotificationDialog.paymentReport' }),
              NOTIFICATIONS_TYPES.EMAIL
            )}
            {getCheckbox(
              'packedOrdersReport',
              intl.formatMessage({ id: 'screen.producerNotificationDialog.packedOrdersReport' }),
              NOTIFICATIONS_TYPES.EMAIL
            )}
          </div>
        </div>
      </div>
      <div className={classes.sectionWrapper}>
        <Typography component="h3" variant="subtitle1" className={classes.sectionTitle}>
          {intl.formatMessage({ id: 'global.pushNotifications' })}
        </Typography>
        <div className={classes.row}>
          <div className={classes.column}>
            {getCheckbox(
              'newOrder',
              intl.formatMessage({ id: 'screen.producerNotificationDialog.newOrder' }),
              NOTIFICATIONS_TYPES.PUSH
            )}
            {/*{getCheckbox('orderNotApproved', intl.formatMessage({id: 'screen.producerNotificationDialog.orderNotApproved'}), NOTIFICATIONS_TYPES.PUSH)}*/}
            {/*{getCheckbox('orderPaymentDeclined', intl.formatMessage({id: 'screen.producerNotificationDialog.orderPaymentDeclined'}), NOTIFICATIONS_TYPES.PUSH)}*/}
          </div>
          {/*<div className={classes.column}>*/}
          {/*  {getCheckbox('paymentReport', intl.formatMessage({id: 'screen.producerNotificationDialog.paymentReport'}), NOTIFICATIONS_TYPES.PUSH)}*/}
          {/*  {getCheckbox('packedOrdersReport', intl.formatMessage({id: 'screen.producerNotificationDialog.packedOrdersReport'}), NOTIFICATIONS_TYPES.PUSH)}*/}
          {/*</div>*/}
        </div>
      </div>

      <div className={classes.sectionWrapper}>
        <Typography component="h3" variant="subtitle1" className={classes.sectionTitle}>
          {intl.formatMessage({ id: 'global.smsNotifications' })}
        </Typography>
        <PrimaryTextField
          error={!!phoneError}
          disabled={!!hasPhone}
          onBlur={() => validateField('phone')}
          onChange={(event) => setPhone(event.target.value)}
          value={phone || ''}
          required
          autoFocus
          margin="dense"
          id="phone"
          label={intl.formatMessage({ id: 'global.phone' })}
          helperText={phoneError ? intl.formatMessage({ id: phoneError }) : ''}
          fullWidth
        />
        <div className={classes.row}>
          <div className={classes.column}>
            {getCheckbox(
              'newOrder',
              intl.formatMessage({ id: 'screen.producerNotificationDialog.newOrder' }),
              NOTIFICATIONS_TYPES.SMS
            )}
            {/*{getCheckbox('orderNotApproved', intl.formatMessage({id: 'screen.producerNotificationDialog.orderNotApproved'}), NOTIFICATIONS_TYPES.SMS)}*/}
            {/*{getCheckbox('orderPaymentDeclined', intl.formatMessage({id: 'screen.producerNotificationDialog.orderPaymentDeclined'}), NOTIFICATIONS_TYPES.SMS)}*/}
          </div>
          {/*<div className={classes.column}>*/}
          {/*  {getCheckbox('paymentReport', intl.formatMessage({id: 'screen.producerNotificationDialog.paymentReport'}), NOTIFICATIONS_TYPES.SMS)}*/}
          {/*  {getCheckbox('packedOrdersReport', intl.formatMessage({id: 'screen.producerNotificationDialog.packedOrdersReport'}), NOTIFICATIONS_TYPES.SMS)}*/}
          {/*</div>*/}
        </div>
      </div>

      <AdminDialogControls className={classes.buttonContainer}>
        <PrimaryButton onClick={validate}>
          <FormattedMessage id="global.saveChanges" />
        </PrimaryButton>
      </AdminDialogControls>
    </AdminDialogContent>
  );
}

AddStep.propTypes = propTypes;
AddStep.defaultProps = defaultProps;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(AddStep)));
