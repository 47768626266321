import { toCurrency } from '../helpers'

export const DISCOUNT_TYPES = {
  FIXED: 'fixed_price',
  PERCENTAGE: 'percentage',
  AMOUNT: 'amount',
};

export const DISCOUNT_THRESHOLD_TYPES = {
  NONE: 'none',
  AMOUNT: 'amount',
  QUANTITY: 'quantity',
};

class Discount {

  constructor(props ={}) {
    this.applied = props.applied || null;
    this.amount = props.amount || null;
    this.definition = props.definition || {};
    this.price = props.price || null;
    this.unitPrice = props.unitPrice || null;
  }

  getAmountLabel(intl) {
    return "-" + toCurrency(this.amount, intl)
  }

  getDiscountLabel(intl) {
    if (this.definition) {
      return `${this.definition.name}`;
    }
    return "";
  }
}

export default Discount
