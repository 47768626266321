import axios from 'axios';
import { getRequestConfig, replaceUrlPlaceholder, REQUEST_TYPES } from '../request';
import API_END_POINTS from '../endpoints';

/**
 * Initial load pickups user by token
 * @param {string} id
 * @param {string} token
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiGetPickupsUser = function (token, id, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.GET_PRODUCER, 'id', id);
  const config = getRequestConfig(REQUEST_TYPES.GET, url);

  config.headers = {
    authorization: 'Bearer ' + token,
  };
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};
