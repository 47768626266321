import { apiGetSubscriptionReportByCustomers, apiGetSubscriptionReportByLineItems } from '../../api';

export const SUBSCRIPTIONS_REPORTS_TYPES = {
  CUSTOMERS: 'customers',
  LINT_ITEMS: 'lineItems',
};

export default (intl) => {
  return {
    customers: {
      key: SUBSCRIPTIONS_REPORTS_TYPES.CUSTOMERS,
      name: intl.formatMessage({ id: 'reports.subscriptionsByCustomers' }),
      columnExtensions: [{ columnName: 'customerName', width: 200 }],
      apiRetrieve: apiGetSubscriptionReportByCustomers,
    },
    lineItems: {
      key: SUBSCRIPTIONS_REPORTS_TYPES.LINT_ITEMS,
      name: intl.formatMessage({ id: 'reports.subscriptionsByLineItems' }),
      columnExtensions: [{ columnName: 'customerName', width: 200 }],
      apiRetrieve: apiGetSubscriptionReportByLineItems,
    },
  };
};
