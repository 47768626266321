export default {
  name: {
    presence: {
      message: '^validation.required'
    }
  },
  tag: {
    presence: {
      message: '^validation.required'
    }
  },
}
