import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import FilterListIcon from '@material-ui/icons/FilterList';
import ExportIcon from '@material-ui/icons/CallMade';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import HomeIcon from '@material-ui/icons/Home';
import { COLORS } from '../../helpers';
import { ColumnChooser, SaveTabMenu, LanIcon, AccountTreeIcon } from '../../components';

const styles = {
  button: {
    marginLeft: 9,
    marginRight: 9,
    padding: 0,
    '&:hover': {
      background: 'none',
    },
  },
  label: {
    fontSize: 12,
    color: COLORS.text,
    flexDirection: 'column',
  },
  icon: {
    color: COLORS.black,
  },
  noDateInform: {},
};

const propTypes = {
  tab: PropTypes.object,
  classes: PropTypes.object,
  columns: PropTypes.array,
  allowHideActionsColumn: PropTypes.bool,
  onChangeColumnsState: PropTypes.func,
  onDeleteTab: PropTypes.func,
  onCreateTab: PropTypes.func,
  onCreatePrice: PropTypes.func,
  onUpdateTab: PropTypes.func,
  onExportTab: PropTypes.func,
  onCreateEntity: PropTypes.func,
  onCreateLocation: PropTypes.func,
  onAddingFilter: PropTypes.func,
  allowCreateTab: PropTypes.bool,
  labels: PropTypes.object,
  hiddenColumnNames: PropTypes.array,
  onGroupBy: PropTypes.func,
  onDetails: PropTypes.func,
  showDetails: PropTypes.bool,
  additionalIconsRenderer: PropTypes.func,
};

const defaultProps = {
  allowHideActionsColumn: true,
  allowCreateTab: false,
  labels: {},
};

const TabActions = ({
  classes,
  tab,
  intl,
  onCreatePrice,
  onCreateTab,
  onExportTab,
  onUpdateTab,
  onDeleteTab,
  onAddingFilter,
  allowCreateTab,
  columns,
  allowHideActionsColumn,
  onChangeColumnsState,
  onCreateEntity,
  onCreateLocation,
  labels,
  hiddenColumnNames,
  onGroupBy,
  onDetails,
  showDetails,
  additionalIconsRenderer,
}) => {
  labels = {
    ...{
      createEntity: intl.formatMessage({ id: 'tab.createEntity' }),
      createPrice: intl.formatMessage({ id: 'tab.createPrice' }),
      createLocation: intl.formatMessage({ id: 'tab.createLocation' }),
      saveView: intl.formatMessage({ id: 'tab.saveView' }),
      exportTab: intl.formatMessage({ id: 'tab.exportTab' }),
      deleteTab: intl.formatMessage({ id: 'tab.deleteTab' }),
      columns: intl.formatMessage({ id: 'tab.columns' }),
      filters: intl.formatMessage({ id: 'tab.filters' }),
      groupBy: intl.formatMessage({ id: 'tab.groupBy' }),
      details: intl.formatMessage({ id: 'tab.details' }),
    },
    ...labels,
  };

  return (
    <React.Fragment>
      {onCreateEntity && (
        <IconButton classes={{ root: classes.button, label: classes.label }} disableRipple onClick={onCreateEntity}>
          <AddCircleIcon style={{ color: COLORS.text }} />
          {labels.createEntity || ''}
        </IconButton>
      )}

      {onCreatePrice && (
        <IconButton classes={{ root: classes.button, label: classes.label }} disableRipple onClick={onCreatePrice}>
          <AttachMoneyIcon className={classes.icon} />
          {labels.createPrice || ''}
        </IconButton>
      )}

      {onCreateLocation && (
        <IconButton classes={{ root: classes.button, label: classes.label }} disableRipple onClick={onCreateLocation}>
          <HomeIcon className={classes.icon} />
          {labels.createLocation || ''}
        </IconButton>
      )}

      {(onCreateTab || onUpdateTab) && (
        <SaveTabMenu
          allowCreateTab={allowCreateTab}
          label={labels.saveView}
          onCreateTab={onCreateTab}
          onUpdateTab={onUpdateTab}
        />
      )}

      {onExportTab && (
        <IconButton classes={{ root: classes.button, label: classes.label }} disableRipple onClick={onExportTab}>
          <ExportIcon className={classes.icon} />
          {labels.exportTab || ''}
        </IconButton>
      )}

      {onDeleteTab && (
        <IconButton
          classes={{ root: classes.button, label: classes.label }}
          disableRipple
          onClick={() => onDeleteTab(tab)}>
          <DeleteIcon className={classes.icon} />
          {labels.deleteTab || ''}
        </IconButton>
      )}

      {tab && (
        <ColumnChooser
          columns={columns}
          label={labels.columns}
          allowHideActionsColumn={allowHideActionsColumn}
          defaultHiddenColumnNames={tab.excludeColumns || hiddenColumnNames || []}
          hiddenColumnNames={hiddenColumnNames || []}
          onChangeColumnsState={onChangeColumnsState}
        />
      )}

      {showDetails && onDetails && (
        <IconButton classes={{ root: classes.button, label: classes.label }} disableRipple onClick={onDetails}>
          <AccountTreeIcon className={classes.icon} size={24} />
          {labels.details || ''}
        </IconButton>
      )}

      {onGroupBy && (
        <IconButton classes={{ root: classes.button, label: classes.label }} disableRipple onClick={onGroupBy}>
          <LanIcon className={classes.icon} size={24} />
          {labels.groupBy || ''}
        </IconButton>
      )}

      {!!additionalIconsRenderer && additionalIconsRenderer()}

      {onAddingFilter && (
        <IconButton classes={{ root: classes.button, label: classes.label }} disableRipple onClick={onAddingFilter}>
          <FilterListIcon className={classes.icon} />
          {labels.filters || ''}
        </IconButton>
      )}
    </React.Fragment>
  );
};

TabActions.propTypes = propTypes;
TabActions.defaultProps = defaultProps;

export default withStyles(styles)(injectIntl(TabActions));
