import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import { ThirdButton } from '../../components';
import { COLORS } from '../../helpers';

const styles = {
  chips: {
    margin: '20px 0 0 0',
    textAlign: 'left',
  },
  chip: {
    margin: 5,
    marginLeft: 0,
  },
};

const propTypes = {
  label: PropTypes.string,
  limited: PropTypes.array,
  multiple: PropTypes.bool,
  values: PropTypes.array.isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  wrapperClass: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  multiple: true,
};

class ChipsSelect extends React.Component {
  state = {
    expanded: false,
  };

  onClick = (status, value) => {
    const { onChange, selected, multiple } = this.props;
    let newSelected;
    if (multiple) {
      newSelected = [];
      selected.forEach((item) => {
        if (this.isSelected(item) && (item.key || item) !== value) {
          newSelected.push(item);
        }
      });

      if (status) {
        newSelected.push(value);
      }
    } else {
      newSelected = value;
    }
    return onChange(newSelected);
  };

  isSelected = (value) => {
    const { selected, multiple } = this.props;

    if (multiple) {
      return selected.some((item) => (item.key || item) === value);
    }

    return selected === value;
  };

  render() {
    const { values, classes, intl, limited, label, wrapperClass, disabled } = this.props;

    const { expanded } = this.state;

    const visible = expanded ? values : limited || values;
    return (
      <div className={classNames(classes.chips, wrapperClass)}>
        {label && <p style={{ color: COLORS.text, fontSize: 12 }}>{label}</p>}

        {visible.map((value) => {
          const key = value?.key !== undefined ? value.key : value;
          const label = value?.label !== undefined ? value.label : value;
          return (
            <Chip
              key={key}
              label={label}
              style={
                this.isSelected(key)
                  ? {
                      backgroundColor: COLORS.text,
                      color: COLORS.white,
                    }
                  : {}
              }
              className={classes.chip}
              onClick={() => this.onClick(!this.isSelected(key), key)}
              disabled={disabled}
            />
          );
        })}

        {limited && (
          <div style={{ textAlign: 'center' }}>
            <ThirdButton onClick={() => this.setState({ expanded: !expanded })}>
              {!expanded ? intl.formatMessage({ id: 'global.more' }) : intl.formatMessage({ id: 'global.less' })}
            </ThirdButton>
          </div>
        )}
      </div>
    );
  }
}

ChipsSelect.propTypes = propTypes;
ChipsSelect.defaultProps = defaultProps;

export default withStyles(styles)(injectIntl(ChipsSelect));
