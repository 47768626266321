import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl'
import {withStyles} from '@material-ui/core/styles';
import {
  AdminDialog,
  AdminDialogContent,
  AdminDialogTitle,
  ErrorBox,
  PrimaryButton,
  PrimarySelect,
  Spinner
} from '../../components';
import styles from './styles'
import classNames from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';
import {
  apiOfferedMarketPresetsGetLocations,
  apiOfferedMarketPresetsSetLocations,
} from '../../api';
import ChipsSelect from '../../components/ChipsSelect/ChipsSelect';

const propTypes = {
  addColumnToTable: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const defaultProps = {};

class AddProductLocationDialog extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      loading: true,
      error: '',
      type: '',
      locations: [],
      locationSelected: '',
      shippingDays: [],
      shippingTypes: [],
    }
  }
  
  componentDidMount() {
    apiOfferedMarketPresetsGetLocations((response) => {
      this.setState({
        locations: response,
        locationSelected: response.length ? response[0] : '',
        shippingTypes: [],
        shippingDays: [],
        loading: false,
      });
    }, (error) => {
      this.setState({
        error: error.message,
        loading: false,
      });
    });
  }
  
  getAllShippingTypes = (locationSelected) => {
    return Object.keys(locationSelected.available);
  };
  
  onChangeDeliveryLocationValue = (event) => {
    const {locations} = this.state;
    
   this.setState({
     locationSelected: locations.filter((location) => (location.id === event.target.value) )[0],
     shippingTypes: [],
     shippingDays: [],
   });
  };
  
  onChangeDeliveryMethodValue = (selected) => {
    this.setState((state) => {
      state.shippingTypes = selected;
      return state;
    }, () => {
      this.preparedTypes();
    });
  };
  
  onChangeDeliveryDayValue = (selected) => {
    this.setState((state) => {
      state.shippingDays = selected;
      return state;
    })
  };
  
  onComplete = () => {
    const {
      locationSelected,
      shippingDays,
      shippingTypes,
    } = this.state;
    const {
      intl,
      addColumnToTable,
      onClose
    } = this.props;
    
    if(!shippingDays.length || !shippingTypes.length || !locationSelected.id) {
      this.setState({error: intl.formatMessage({id: 'tab.createLocation.error'})});
      return false;
    }
    
    const data = {
      locationId: locationSelected.id,
      shippingDays: shippingDays,
      shippingTypes: shippingTypes,
    };
    
    this.setState({loading: true});
    
    apiOfferedMarketPresetsSetLocations(data, (response) => {
      this.setState({
        loading: false,
      }, () => {
        addColumnToTable(response);
        onClose(false);
      });
    }, (error) => {
      this.setState({
        error: error.message,
        loading: false,
      });
    })
  };
  
  preparedTypes = () => {
    const { locationSelected } = this.state;
    let typesValues = [];
    
    for (const type of this.getAllShippingTypes(locationSelected)){
      typesValues.push({
        label: this.props.intl.formatMessage({id: `global.${type}`}),
        key: type
      })
    }
    return typesValues;
  };
  
  preparedDays = () => {
    const {locationSelected, shippingTypes} = this.state;
    let daysValues = [];
    
    for (const type of shippingTypes) {
      if (locationSelected.available.hasOwnProperty(type)) {
        for (const day of locationSelected.available[type]) {
          if (daysValues.findIndex((item) => (item.key === day)) === -1) {
            daysValues.push({
              label: this.props.intl.formatMessage({id: `global.day.${day}`}),
              key: day
            })
          }
        }
      }
    }
    
    return daysValues;
  };
  
  render() {
    const {
      intl,
      classes,
      onClose
    } = this.props;
    const {
      locations,
      locationSelected,
      shippingDays,
      shippingTypes
    } = this.state;
    
    return (
      <AdminDialog open
                   paperClass={classes.paper}
                   closeButton
                   onClose={() => onClose(false)} >
        {this.state.loading && <Spinner size={40}/>}
        {!this.state.loading && (
          <>
            <AdminDialogTitle title={intl.formatMessage({id: 'tab.createLocation'})}/>
            <AdminDialogContent className={classes.wrapper}>
              <div className={classes.row}>
                <div className={classNames(classes.column)}>
                  
                  {this.state.error && (
                    <div className={classes.errorWrapper}>
                      <ErrorBox error={this.state.error}/>
                    </div>
                  )}
                  
                  <PrimarySelect
                    className={classes.input}
                    label={intl.formatMessage({id: "filter.orderLocation"})}
                    value={locationSelected.id}
                    onChange={this.onChangeDeliveryLocationValue}
                  >
                    {!!locations && locations.map(location => (
                      <MenuItem key={location.id} value={location.id}>
                        {location.name}
                      </MenuItem>
                    ))}
                  </PrimarySelect>
  
                  <ChipsSelect
                    values={this.preparedTypes()}
                    label={intl.formatMessage({id: "order.table.shippingType"})}
                    selected={shippingTypes}
                    onChange={this.onChangeDeliveryMethodValue}
                  />
                  
                  {!!this.state.shippingTypes.length &&
                  <ChipsSelect
                    values={this.preparedDays()}
                    label={intl.formatMessage({id: "global.weekday"})}
                    selected={shippingDays}
                    onChange={this.onChangeDeliveryDayValue}
                  />
                  }
                  
                </div>
              </div>
              
              <div className={classes.controls}>
                <PrimaryButton onClick={this.onComplete}>
                  {intl.formatMessage({id: 'global.done'})}
                </PrimaryButton>
              </div>
            </AdminDialogContent>
          </>
        )}
      </AdminDialog>
    )
  }
}

AddProductLocationDialog.propTypes = propTypes;
AddProductLocationDialog.defaultProps = defaultProps;

export default withStyles(styles, {withTheme: true})(
  injectIntl(AddProductLocationDialog)
);
