import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {Table} from '@devexpress/dx-react-grid-material-ui';
import {withStyles} from '@material-ui/core/styles';

const styles = ({
  cell: {
    textTransform: 'capitalize'
  }
});

const propTypes = {
  row: PropTypes.object.isRequired,
};

const RetailTypeCell = ({intl, row, classes, ...other}) =>  {
  let methods = [];

  if (row.retail.pickup)
    methods.push('pickup');

  if (row.retail.delivery)
    methods.push('delivery');

  return (
    <Table.Cell {...other}>
      <span className={classes.cell}>{methods.join(' & ')}</span>
    </Table.Cell>
  );
};

RetailTypeCell.propTypes = propTypes;

export default withStyles(styles)(
  injectIntl(RetailTypeCell)
);
