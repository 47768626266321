import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { withStyles } from '@material-ui/core/styles'
import EmptyImage from './assets/no.jpg'

const styles = ({
  image: {
    width: '100%'
  },
  imageBox: {
    width: '100%'
  },
  text: {
    fontSize: '12px',
    color: '#777775',
    fontFamily: 'Roboto',
  },
  icon: {
    fontSize: 18
  }
});

const propTypes = {
  // Variant or Product
  object: PropTypes.object.isRequired,
  thumb: PropTypes.bool,
};

const defaultProps = {
  thumb: false,
};

class Image extends React.PureComponent {

  getImage = () => {
    const {object, thumb} = this.props;

    if (thumb && object && object.image && object.image.thumbSrc) {
      return object.image.thumbSrc;
    }

    if (object && object.image && object.image.src) {
      return object.image.src;
    }

    return EmptyImage;
  };


  render() {
    const {
      classes,
      object,
    } = this.props;

    return (
      <div className={classes.imageBox}>
        <img className={classes.image}
             src={this.getImage()}
             alt={object ? object.name : "Empty"}/>
      </div>
    );
  }
}

Image.propTypes = propTypes;
Image.defaultProps = defaultProps;

export default withStyles(styles)(
  injectIntl(Image)
);
