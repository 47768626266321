import React from "react";
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';

const propTypes = {
  title: PropTypes.string
};

const InfoBox = (props) => {
  const {children, classes, title, ...other} = props;

  return (
    <React.Fragment>
      {title && (
        <span className={classes.title}>{title}</span>
      )}
      <div className={classes.wrapper} {...other}>
        {children}
      </div>
    </React.Fragment>
  )
};

InfoBox.propTypes = propTypes;

export default withStyles(styles)(InfoBox);