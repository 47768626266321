import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: 14,
    padding: 4,
    boxSizing: 'content-box',
    marginTop: -4,
    marginBottom: -6,
    color: 'rgba(0, 0, 0, 0.4)',
    stroke: 'rgba(0, 0, 0, 0.4)',
    strokeWidth: 1,
  },
  item: {
    fontFamily: "'Roboto', sans-serif",
    color: '#000',
    fontSize: 14,
    fontWeight: 400,
    userSelect: 'none',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
}));

const propTypes = {
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const PeriodChangeControl = (props) => {
  const { onClick, type = 'back', text, title } = props;
  const classes = useStyles();

  return (
    <div title={title} className={classes.item} onClick={onClick}>
      {type === 'back' && <ArrowBackIosIcon className={classes.icon} />}

      {text}

      {type === 'forward' && <ArrowForwardIosIcon className={classes.icon} />}
    </div>
  );
};

PeriodChangeControl.propTypes = propTypes;

export default PeriodChangeControl;
