import React from "react";
import PropTypes from 'prop-types';
import Back from "@material-ui/icons/ArrowBack";
import {withStyles} from '@material-ui/core/styles';
import {Icon} from '../../components';
import styles from './styles';

const propTypes = {
  onClick: PropTypes.func.isRequired
};

const BackIcon = (props) => {
  const {onClick, classes} = props;
  return (
    <Icon icon={Back}
          style={{fill: 'rgba(0, 0, 0, 0.5)'}}
          className={classes.back}
          onClick={onClick} />
  )
};

BackIcon.propTypes = propTypes;

export default withStyles(styles)(BackIcon);