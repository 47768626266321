import { getRequestConfig, REQUEST_TYPES } from '../request';
import API_END_POINTS from '../endpoints';
import axios from 'axios';

export const apiImportFields = function (file, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.PRODUCER_IMPORT_FIELDS);

  const bodyFormData = new FormData();
  bodyFormData.append('csv', file);

  config.headers['Content-Type'] = 'multipart/form-data';
  config.data = bodyFormData;

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};
