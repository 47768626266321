import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../hocs';
import { AdminLayout, AdminTabs, Spinner } from '../../components';
import { SidebarMenu, HeaderMenu } from '../Partials';
import PrePurchasesForm from '../../forms/PrePurchasesForm';
import BaseDialog from '../../dialogs/BaseDialog';
import TabTypes from '../../dialogs/SaveTabSettingsDialog/Types';
import { setProducer } from '../../store/actions';
import { MAIN_TAB_NAMES } from '../../helpers/tabs';
import PrePurchasesContent from './PrePurchasesContent';
import styles from './styles';
import { apiCreateTab, apiDeleteTab, apiPatchTab } from '../../api';
import moment from 'moment';
import { getCustomerName } from '../../helpers/customer';

class PrePurchasesScene extends React.Component {
  constructor(props) {
    super(props);

    const { intl, tabs } = this.props;
    this.state = {
      tabIndex: tabs.getInitialIndex(window.location.hash, true),
      columnExtensions: [
        { columnName: 'name', width: 200 },
        { columnName: 'amount', align: 'right', width: 200 },
        { columnName: 'balance', align: 'right', width: 200 },
        { columnName: 'active', align: 'center', width: 200 },
      ],
      columns: [
        { name: 'name', title: intl.formatMessage({ id: 'prepurchases.column.name' }) },
        {
          name: 'customer.name',
          originalName: 'customer',
          title: intl.formatMessage({ id: 'prepurchases.column.customer' }),
          getCellValue: (row) => (row.customer ? getCustomerName(row.customer) : '-'),
        },
        { name: 'value', title: intl.formatMessage({ id: 'prepurchases.column.amount' }) },
        { name: 'availableValue', title: intl.formatMessage({ id: 'prepurchases.column.balance' }) },
        {
          name: 'location',
          originalName: 'location',
          title: intl.formatMessage({ id: 'prepurchases.column.location' }),
          getCellValue: (row) =>
            row?.subscription?.location?.name || intl.formatMessage({ id: 'dialog.groupBy.noLocation' }),
        },
        {
          name: 'subscription.type',
          originalName: 'shippingType',
          title: intl.formatMessage({ id: 'prepurchases.column.shippingType' }),
          getCellValue: (row) =>
            (row.subscription &&
              `${intl.formatMessage({ id: `global.${row.subscription.type}` })}${
                !!row.subscription.deliveryCompany ? ' - ' + row.subscription.deliveryCompany.name : ''
              }`) ||
            '-',
        },
        {
          name: 'lastOrder.date',
          originalName: 'lastOrder',
          title: intl.formatMessage({ id: 'prepurchases.column.lastOrder' }),
          getCellValue: (row) => {
            return row.lastOrder && (row.lastOrder.date || row.lastOrder.error)
              ? !row.lastOrder.error
                ? moment(row.lastOrder.date).format(process.env.REACT_APP_DATE_FORMAT_WITH_MONTH_DAY_AND_YEAR)
                : row.lastOrder.error
              : '-';
          },
        },
        {
          name: 'tags',
          originalName: 'tags',
          title: intl.formatMessage({ id: 'prepurchases.column.tags' }),
        },
        {
          name: 'variantsCount',
          originalName: 'products',
          title: intl.formatMessage({ id: 'prepurchases.column.products' }),
          getCellValue: (row) => (row.variantsCount && row.variantsCount.toString()) || '0',
        },
        {
          name: 'validFrom',
          originalName: 'start',
          title: intl.formatMessage({ id: 'prepurchases.column.start' }),
          getCellValue: (row) => moment(row.validFrom).format('MMM D, Y'),
        },
        {
          name: 'validTo',
          originalName: 'end',
          title: intl.formatMessage({ id: 'prepurchases.column.end' }),
          getCellValue: (row) => moment(row.validTo).format('MMM D, Y'),
        },
        {
          name: 'subscription.nextDate',
          originalName: 'nextOrder',
          title: intl.formatMessage({ id: 'prepurchases.column.nextOrder' }),
          getCellValue: (row) => (row.subscription ? moment(row.subscription.nextDate).format('MMM D, Y') : '-'),
        },
        { name: 'active', title: intl.formatMessage({ id: 'prepurchases.column.active' }), type: 'active' },
      ],
    };
  }

  back = (event, reason) => {
    if (reason !== 'backdropClick') {
      this.props.history.push(`/prepurchases${this.props.location.hash}`);
    }
  };

  onCreateTab = (data, onDone) => {
    this.setState({ loading: true });
    apiCreateTab(
      data,
      (producer) => {
        this.props.setProducer(producer);
        this.setState((state) => {
          state.loading = false;
          onDone && onDone();
          return state;
        });
      },
      undefined
    );
  };

  onDeleteTab = (tab) => {
    this.setState({ loading: true });
    apiDeleteTab(
      tab.id,
      (producer) => {
        this.setState(
          {
            tabIndex: 0,
            loading: false,
          },
          () => {
            this.props.setProducer(producer);
          }
        );
      },
      undefined
    );
  };

  onPatchTab = (tab, data, onDone) => {
    this.setState({ loading: true });
    apiPatchTab(
      tab.id,
      data,
      (producer) => {
        this.props.setProducer(producer);
        this.setState({ loading: false });
        onDone && onDone();
      },
      undefined
    );
  };

  onPatchCustomTab = (tab, data, onDone) => {
    const { tabs } = this.props;
    const { tabIndex } = this.state;

    const allTabs = tabs.getTabs('');
    let prePurchasesTab = allTabs[tabIndex];

    if (prePurchasesTab) {
      this.onPatchTab(prePurchasesTab, data, onDone);
    } else {
      data.name = MAIN_TAB_NAMES.PREPURCHASES_ALL;
      data.type = TabTypes.PREPURCHASES;
      this.onCreateTab(data);
    }
  };

  onPatchPrePurchaseTabTab = (tab, data, onDone) => {
    const { tabs } = this.props;
    let prePurchaseTab = tabs.getTabByName(MAIN_TAB_NAMES.PREPURCHASES_ALL);
    if (prePurchaseTab) {
      this.onPatchTab(prePurchaseTab, data, onDone);
    } else {
      data.name = MAIN_TAB_NAMES.PREPURCHASES_ALL;
      data.type = TabTypes.PREPURCHASES;
      this.onCreateTab(data);
    }
  };

  onChangeTab = (index) => {
    const { tabs } = this.props;
    window.location.hash = tabs.getHashByIndex(index);
    this.setState({ tabIndex: index });
  };

  getTabs() {
    const { tabIndex } = this.state;
    const { intl, tabs } = this.props;

    const tabsTitles = tabs.getTabNames([intl.formatMessage({ id: 'global.all' })]);
    const customTabs = tabs.getOnlyCustom();
    let prePurchaseTab = tabs.getTabByName(MAIN_TAB_NAMES.PREPURCHASES_ALL, tabsTitles[0], {
      id: MAIN_TAB_NAMES.PREPURCHASES_ALL,
      name: tabsTitles[0],
    });

    return (
      <AdminTabs onChangeTab={this.onChangeTab} tab={tabIndex} tabs={tabsTitles}>
        {tabIndex === 0 && (
          <PrePurchasesContent
            tab={prePurchaseTab}
            openFilterDialog={() => this.setState({ filterDialog: true })}
            isFiltersDialogActive={this.state.filterDialog}
            onCloseFilterDialog={() => this.setState({ filterDialog: false })}
            onPatchTab={this.onPatchPrePurchaseTabTab}
            hiddenColumnNames={[]}
            appliedFilters={prePurchaseTab.filters || []}
            allowCreateTab
            withFilters
            onCreateTab={this.onCreateTab}
            onRef={(tab) => (this.tab = tab)}
            columns={this.state.columns}
            columnExtensions={this.state.columnExtensions}
            key={'all-prepurchases'}
            index={'all-prepurchases'}
          />
        )}

        {!!customTabs.length && tabIndex > 0 && (
          <PrePurchasesContent
            tab={customTabs[tabIndex - 1]}
            contentTag={'prePurchasesTabContent'}
            appliedFilters={customTabs[tabIndex - 1].filters || []}
            showTabDialog={true}
            allowCreateTab
            withFilters
            onCreateTab={this.onCreateTab}
            onPatchTab={this.onPatchCustomTab}
            onDeleteTab={this.onDeleteTab}
            onRef={(tab) => (this.tab = tab)}
            columns={this.state.columns}
            columnExtensions={this.state.columnExtensions}
            key={`custom-${tabIndex}`}
            index={`custom-${tabIndex}`}
          />
        )}
      </AdminTabs>
    );
  }

  render() {
    const { loading } = this.state;
    const { intl, openItemModal } = this.props;

    return (
      <AdminLayout sidebarComponent={SidebarMenu} headerMenuComponent={HeaderMenu}>
        {loading && <Spinner size={60} />}
        {this.getTabs()}

        {openItemModal && (
          <BaseDialog
            scroll={'paper'}
            maxWidth={'lg'}
            open
            onClose={this.back}
            title={
              this.props.match.params.id === 'new'
                ? intl.formatMessage({ id: 'prepurchases.adding' })
                : intl.formatMessage({ id: 'prepurchases.editing' })
            }>
            <PrePurchasesForm
              {...this.props}
              producer={this.props.producer}
              onSuccess={() => {
                this.back();
                setTimeout(() => {
                  this.tab.forceTableReload();
                }, 200);
              }}
            />
          </BaseDialog>
        )}
      </AdminLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  tabs: state.producer.tabs[TabTypes.PREPURCHASES],
  producer: state.producer.object,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setProducer: (producer) => dispatch(setProducer(producer)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(withStyles(styles)(PrePurchasesScene))));
