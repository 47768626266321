import React from 'react';

/** TabsHash */
export default (WrappedComponent, route, map) => {
  return class extends React.Component {

    constructor(props) {
      super(props);

      this.state = {
        map: map || [],
        route: route,
      };

      this.setInitialHash();
    }

    setInitialHash = () => {
      if (!window.location.hash && this.state.map.length) {
        window.location.hash = this.state.map[0];
      }
    };

    replaceHasherMap = (tabs, onReplaced) => {
      this.setState((state) => {
        state.map = tabs;
        return state;
      }, () => {
        onReplaced && onReplaced();
        this.setInitialHash();
      })
    };

    getHrefByIndex = (index) => {
      return `${this.state.route}#${this.getHashByIndex(index)}`
    };

    setHashByIndex(index) {
      window.location.hash = this.getHashByIndex(index);
    }

    getCurrentHash = () => {
      return window.location.hash;
    };

    getTabIndex = () => {
      let hash = this.getCurrentHash();
      let index = this.getIndexByHash(hash);
      return (index > -1) ?
        index : (this.state.map.length ? 0 : -1);
    };

    getHashByIndex = (index) => {
      return this.state.map[index];
    };

    getIndexByHash = (hash) => {
      hash = hash.replace("#", "");
      return this.state.map.indexOf(hash);
    };

    render() {
      return (
        <WrappedComponent
          tabsHasher={this}
          {...this.props}
        />
      )
    }
  };
}