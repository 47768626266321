
/**
 * Opens file from blob
 * @param {string} blob - blob data
 * @param {string} fileName - file name
 * @param {string} ext - file extension
 */
export const showFileFromBlob = (blob, fileName = 'file', ext = 'pdf') => {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    let newBlob = new Blob([blob], {type: `application/${ext}`});

    return URL.createObjectURL(newBlob);
};