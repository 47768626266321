import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import DXSelectFilter from '../DXSelectFilter/DXSelectFilter';
import DXFilterConfig from '../DXFilterConfig';
import constants from '../../constants';

const propTypes = {
  equality: PropTypes.string,
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  selected: [],
};

class DXSubscriptionPeriodFilter extends React.Component {
  render() {
    const { selected, onChange, intl, equality } = this.props;
    const config = DXSubscriptionPeriodFilter.getConfig(intl, equality);
    return (
      <DXSelectFilter
        selected={selected}
        title={config.title}
        values={config.values}
        onChange={onChange}
        multi={config.multi}
      />
    );
  }
}

DXSubscriptionPeriodFilter.propTypes = propTypes;
DXSubscriptionPeriodFilter.defaultProps = defaultProps;
DXSubscriptionPeriodFilter.getLabel = (intl, selectedValues) => {
  return DXSelectFilter.getLabel(intl, 'filter.subscriptionPeriod.', selectedValues);
};
DXSubscriptionPeriodFilter.applyToQueryParams = (queryParams, selectedValues, filter) => {
  const param = !!filter?.queryParams?.periods?.default
    ? filter.queryParams.periods.default
    : 'periods';

  queryParams[param] = selectedValues;
  return queryParams;
};
DXSubscriptionPeriodFilter.getConfig = (intl, equality, queryParamsByFilterName) => {
  let config = new DXFilterConfig({
    name: 'subscriptionPeriod',
    selected: [],
    property: 'periods',
    title: intl.formatMessage({ id: 'filter.subscriptionPeriod' }),
    disabled: false,
    equalityStatus: false,
    multi: true,
    queryParams: queryParamsByFilterName,
  });

  if (equality === constants.FILTER_EQUALITY_EQUALS) {
    config.addFilterValue('week', intl.formatMessage({ id: 'filter.subscriptionPeriod.week' }));
    config.addFilterValue('bi_week', intl.formatMessage({ id: 'filter.subscriptionPeriod.bi_week' }));
  }

  return config;
};

DXSubscriptionPeriodFilter.getInitialConfig = (intl, values, readOnly, invisible) => {
  return {
    name: 'subscriptionPeriod',
    title: intl.formatMessage({ id: 'filter.subscriptionPeriod' }),
    property: 'periods',
    type: 'subscriptionPeriod',
    selectedValues: values,
    clientSide: false,
    readOnly: readOnly ? readOnly : false,
    fromVariable: !!invisible || false,
  };
};

export default injectIntl(DXSubscriptionPeriodFilter);
