import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { Fade, makeStyles } from '@material-ui/core';
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton';
import { CUSTOMER_TYPE } from '../../../helpers';
import { apiGenerateAnonymousLink } from '../../../api';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
  copiedSuccessWrapper: {
    position: 'fixed',
    bottom: 30,
    left: 'calc(50% - 150px)',
    backgroundColor: '#000',
    padding: '10px 20px',
    border: '2px solid #000',
    color: 'white',
    borderRadius: 4,
    zIndex: 1000,
    minWidth: 300,
  },
  textWrapper: {
    marginRight: 5,
  },
  iconWrapper: {
    display: 'inline-block',
    position: 'relative',
  },
  icon: {},
}));

const propTypes = {
  buttonText: PropTypes.string,
  customerType: PropTypes.string,
};

const defaultProps = {
  buttonText: 'producer.tab.links.copyToClipboard',
  customerType: CUSTOMER_TYPE.RETAIL,
};

const LinksActionCell = (props) => {
  const { cellProps, customerType, buttonText, onSuccessMessage } = props;
  const classes = useStyles();
  const intl = useIntl();

  const [isCopied, setIsCopied] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const successMessage = onSuccessMessage
    ? intl.formatMessage({ id: onSuccessMessage })
    : intl.formatMessage({ id: 'global.copied' });

  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => {
        setIsCopied(false);
        setError('');
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isCopied, error]);

  const onClick = (e) => {
    e.stopPropagation();

    setLoading(true);

    apiGenerateAnonymousLink(
      customerType,
      (value) => {
        navigator.clipboard.writeText(value?.url).then(() => {
          setIsCopied(true);
          setLoading(false);
        });
      },
      (e) => {
        setError(e?.response?.data?.message || intl.formatMessage({ id: 'global.somethingWentWrong' }));
        setLoading(false);
      }
    );
  };

  return (
    <Table.Cell
      {...cellProps}
      style={{
        cursor: 'pointer',
      }}>
      <div className={classes.root}>
        <div className={classes.iconWrapper}>
          <PrimaryButton className={classes.button} onClick={onClick} isLoading={loading}>
            {intl.formatMessage({ id: buttonText })}
          </PrimaryButton>
          <Fade in={isCopied}>
            <div className={classes.copiedSuccessWrapper}>{successMessage}</div>
          </Fade>
          <Fade in={!!error}>
            <div className={classes.copiedSuccessWrapper}>{error}</div>
          </Fade>
        </div>
      </div>
    </Table.Cell>
  );
};

LinksActionCell.propTypes = propTypes;
LinksActionCell.defaultProps = defaultProps;

export default LinksActionCell;
