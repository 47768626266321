export default () => ({
  root: {
    backgroundColor: '#fff',
    width: 'calc(100% - 1px)',
    minHeight: '100%',
    paddingLeft: 13,
    paddingRight: 13
  },
  itemsWrapper: {
    paddingBottom: 100,
  },
  errorBox: {
    margin: 20,
  },
  noItemsInformer: {
    border: '1px solid #dddddd',
    display: 'flex',
    margin: 15,
    padding: 20,
    paddingTop: 40,
    paddingBottom: 40,
    borderRadius: 12,
    flexDirection: 'column',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noItemsInformerText: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 16,
  },
});
