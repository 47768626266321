import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../../../../hocs';
import { PrimaryTextField, PrimarySelect } from '../../../../../components';
import { validateField } from '../../../../../helpers';
import validationRules from './validation';

const styles = {
  selectFormControlClassName: {
    display: 'block',
    marginTop: 20,
  },
  select: {
    width: '100%',
  },
  label: {
    fontSize: 14,
  },
};

const propTypes = {
  data: PropTypes.object.isRequired,
  onRef: PropTypes.func.isRequired,
};

const ACCOUNT_HOLDER_COMPANY = 'company';
const ACCOUNT_HOLDER_INDIVIDUAL = 'individual';

class EntityForm extends React.Component {
  constructor(props) {
    super(props);
    this.props.onRef(this);

    const { data } = this.props;

    this.state = {
      routingNumber: data.routing_number || '',
      accountNumber: data.account_number || '',
      accountHolderName: data.account_holder_name || '',
      accountHolderType: data.account_holder_type || ACCOUNT_HOLDER_COMPANY,

      routingNumberError: '',
      accountNumberError: '',
      accountHolderNameError: '',
      accountHolderTypeError: '',
    };
  }

  getTextField(fieldName, namespace, prefix) {
    const { classes, intl } = this.props;

    return (
      <PrimaryTextField
        className={classes.input}
        InputLabelProps={{
          classes: {
            root: classes.label,
          },
        }}
        autoComplete="no"
        error={!!this.state[`${fieldName}Error`]}
        onChange={(event) => {
          let value = event.target.value;
          this.setState((state) => {
            state[fieldName] = value;
            return state;
          });
        }}
        value={this.state[fieldName]}
        id={`${fieldName}-input`}
        label={(prefix || '') + intl.formatMessage({ id: `${namespace}.${fieldName}` })}
        type="text"
        margin="normal"
        helperText={this.state[`${fieldName}Error`] && intl.formatMessage({ id: this.state[`${fieldName}Error`] })}
        fullWidth
      />
    );
  }

  validate = () => {
    const routingNumberError = validateField(validationRules, 'routingNumber', this.state.routingNumber);
    const accountNumberError = validateField(validationRules, 'accountNumber', this.state.accountNumber);
    const accountHolderNameError = validateField(validationRules, 'accountHolderName', this.state.accountHolderName);
    const accountHolderTypeError = validateField(validationRules, 'accountHolderType', this.state.accountHolderType);

    let validated = false;
    if (!routingNumberError && !accountNumberError && !accountHolderNameError && !accountHolderTypeError) {
      this.setState({
        routingNumberError: '',
        accountNumberError: '',
        accountHolderNameError: '',
        accountHolderTypeError: '',
      });
      validated = true;
    } else {
      this.setState({
        routingNumberError: routingNumberError,
        accountNumberError: accountNumberError,
        accountHolderNameError: accountHolderNameError,
        accountHolderTypeError: accountHolderTypeError,
      });
    }

    return {
      validated: validated,
      data: {
        routing_number: this.state.routingNumber,
        account_number: this.state.accountNumber,
        account_holder_name: this.state.accountHolderName,
        account_holder_type: this.state.accountHolderType,
      },
    };
  };

  render() {
    const { classes, intl } = this.props;
    return (
      <div className={classes.wrapper}>
        {this.getTextField('routingNumber', 'payment', '* ')}
        {this.getTextField('accountNumber', 'payment', '* ')}
        {this.getTextField('accountHolderName', 'payment', '* ')}

        <PrimarySelect
          classes={{ root: classes.select }}
          className={classes.select}
          formControlClassName={classes.selectFormControlClassName}
          value={this.state.accountHolderType}
          label={intl.formatMessage({ id: 'payment.accountHolderType' })}
          onChange={(e) => this.setState({ accountHolderType: e.target.value })}>
          <MenuItem key={ACCOUNT_HOLDER_COMPANY} value={ACCOUNT_HOLDER_COMPANY}>
            {intl.formatMessage({ id: 'global.company' })}
          </MenuItem>
          <MenuItem key={ACCOUNT_HOLDER_INDIVIDUAL} value={ACCOUNT_HOLDER_INDIVIDUAL}>
            {intl.formatMessage({ id: 'global.individual' })}
          </MenuItem>
        </PrimarySelect>
      </div>
    );
  }
}

EntityForm.propTypes = propTypes;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(EntityForm)));
