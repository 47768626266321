export default {
  message: {
    backgroundColor: '#212121',
    color: '#ffffff',
    marginBottom: 20,
    marginLeft: 20,
    marginRight: 20
  },
  icon: {
    fontSize: 20,
  },
};
