import { WIDTH_OF_SIDEBAR } from './constants';

export const DEFAULT_TABLE_WIDTH = window.innerWidth - WIDTH_OF_SIDEBAR - 120;
export const DEFAULT_MIN_CELL_WIDTH = 50;
export const DEFAULT_NORMALIZED_CELL_WIDTH = 100;
export const DEFAULT_MAX_CELL_WIDTH = 1000;
export const INFINITY_MAX_CELL_WIDTH = 9999;

/**
 * count of width for all cellc in table
 * @param {number} numberOfCells - the active cells number
 * @param {number} tableWidth - the amount of space that table contains
 * @param {number} columnExtensionWidth - initial column width from columnExtensions
 * @returns {number}
 */
export const getCellsWidthFromCellsNumber = (
  numberOfCells = 5,
  tableWidth = DEFAULT_TABLE_WIDTH,
  columnExtensionWidth = 0
) => {
  const countedWidthOfCell = tableWidth / numberOfCells;

  if (columnExtensionWidth) {
    return columnExtensionWidth;
  }

  if (countedWidthOfCell < DEFAULT_NORMALIZED_CELL_WIDTH) {
    return DEFAULT_NORMALIZED_CELL_WIDTH;
  }

  if (countedWidthOfCell > DEFAULT_MAX_CELL_WIDTH) {
    return DEFAULT_MAX_CELL_WIDTH;
  }

  return countedWidthOfCell;
};

export const countNumberOfCellsFromColumnsAndExcluded = (columns, excluded) => {
  let num = 0;

  columns.forEach((column) => !excluded.includes(column.name) && num++);

  return num;
};

export const getCellWidthFromColumnExtensions = (column, columnExtensions) => {
  return columnExtensions.find((c) => c.columnName === column.name)?.width || 0;
};

export const EXPANDED_ALL_STAGES = {
  NOT_EXPANDED: 'NOT_EXPANDED',
  EXPAND_FIRST_LEVEL: 'EXPAND_FIRST_LEVEL',
  EXPAND_SECOND_LEVEL: 'EXPAND_SECOND_LEVEL',
  EXPAND_ALL_LEVELS: 'EXPAND_ALL_LEVELS',
};

export const countWidthOfColumns = (columns, excludeColumns, columnExtensions, tableWidth = DEFAULT_TABLE_WIDTH) => {
  const filteredColumns = excludeColumns ? columns.filter((column) => !excludeColumns.includes(column.name)) : columns;
  let filledWidth = 0;

  let columnWidths = [];

  if (!Array.isArray(columns)) return false;

  filteredColumns.forEach((column, index) => {
    const extensionColumnWidth = getCellWidthFromColumnExtensions(column, columnExtensions);
    const countedCellWidth = getCellsWidthFromCellsNumber(filteredColumns.length - index, tableWidth - filledWidth);
    const stepWidth = !!extensionColumnWidth ? extensionColumnWidth : countedCellWidth;

    columnWidths.push(
      new ColumnWidthSimpleEntity({
        columnName: column.name,
        width: stepWidth,
      })
    );

    filledWidth += stepWidth;
  });

  //TODO: Temp need to remove
  excludeColumns?.length &&
    excludeColumns.forEach((column) => {
      const columnEntity = new ColumnWidthSimpleEntity({
        columnName: column,
        width: DEFAULT_NORMALIZED_CELL_WIDTH,
      });

      columnWidths.push(columnEntity);
    });

  return columnWidths;
};

/**
 * Important! All fields should have width param, otherwise there will be exception
 *
 * @param {array} backendSideColumnWidth
 * @param {array} clientSideColumnWidth - All existing columns on table
 * @returns {null|*}
 */
export const makeColumnWidthEntityArray = (backendSideColumnWidth, clientSideColumnWidth) => {
  if (!backendSideColumnWidth?.length) {
    return null;
  }

  if (!Array.isArray(clientSideColumnWidth)) {
    return backendSideColumnWidth.map((item) => new ColumnWidthSimpleEntity(item));
  }

  // Create an array of unique column names from client and backend columns
  const mergedColumnNames = {};
  backendSideColumnWidth.forEach((backendColumn) => (mergedColumnNames[backendColumn.name] = null));
  clientSideColumnWidth.forEach((clientColumn) => (mergedColumnNames[clientColumn.name] = null));

  return Object.keys(mergedColumnNames).map((item) => {
    const backEndItem = backendSideColumnWidth.find((bItem) => bItem.name === item);
    const clientItem = clientSideColumnWidth.find((cItem) => cItem.name === item);

    if (!backEndItem) {
      return new ColumnWidthSimpleEntity(clientItem);
    }

    return new ColumnWidthSimpleEntity(backEndItem);
  });
};

export const countWidthOfTable = (
  columnWidthArray,
  excludedColumns,
  enableSelection = false,
  enableTreeSelection = false,
  actions = []
) => {
  if (!excludedColumns || !columnWidthArray?.length) return 'calc(100% + 1px)';

  const onlyActiveColumns = columnWidthArray.filter((column) => !excludedColumns.includes(column.columnName));
  let countedWidth = 1;
  if (enableSelection) countedWidth += 60;
  if (enableTreeSelection) countedWidth += 60;
  if (!!actions?.length) countedWidth += actions.length * 60;

  onlyActiveColumns.forEach((column) => {
    countedWidth += column.width;
  });

  if (countedWidth > DEFAULT_TABLE_WIDTH) {
    return 'calc(100% + 1px)';
  }

  return countedWidth;
};

/**
 * Need convert for backend format {'name', 'width'}
 */
const ColumnWidthSimpleEntity = class {
  constructor(props) {
    this.name = props.columnName || props.name;
    this.columnName = props.columnName || props.name;
    this.width = props.width || DEFAULT_NORMALIZED_CELL_WIDTH;
  }
};

export function isGroupingHeaderRow(row) {
  return !!row?.groupedBy;
}
