import axios from 'axios';
import { getRequestConfig, replaceUrlPlaceholder, REQUEST_TYPES } from '../request';
import API_END_POINTS from '../endpoints';

/**
 * Search products
 * @param {SearchProductsParams} options - search options
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiProductsSearch = function (options, onSuccess, onError) {
  let config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.PRODUCTS_SEARCH);
  config.params = options.toData();

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Search products
 * @param {string} supplierId
 * @param {SearchProductsParams} options - search options
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiSupplierProductsSearch = function (supplierId, options, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.SUPPLIER_PRODUCTS_SEARCH, 'sid', supplierId);

  let config = getRequestConfig(REQUEST_TYPES.GET, url);
  config.params = options.toData();

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error);
    });
};
