import React from "react";
import moment from 'moment';
import {Table} from '@devexpress/dx-react-grid-material-ui';
import {
  apiDiscountEnable,
  apiDiscountDisable,
} from "../../api/discounts/discounts";
import TableCheckbox from "../../components/AdminDXTable/TableCheckbox";
import {toPaper} from "../../helpers";

const AMOUNT_TYPE = 'amount';

export const CellRenderer = (reload, {row, ...props}, rows, onUpdateRows) => {
  if (props.column.name === 'name') {
    return (
      <Table.Cell {...props}>
        {(row.name && row.name.toString()) || ""} - {row.type === AMOUNT_TYPE ? '$' + toPaper(row.value) : toPaper(row.value) + '%'}
      </Table.Cell>
    )
  }

  if (props.column.name === 'amount') {
    return (
      <Table.Cell {...props}>
        {row.type === AMOUNT_TYPE ? '$' + toPaper(row.value) : toPaper(row.value) + '%'}
      </Table.Cell>
    )
  }

  if (['customers', 'products', 'used'].indexOf(props.column.name) !== -1) {
    return (
      <Table.Cell {...props}>
        {(row[props.column.name] && row[props.column.name].toString()) || "0"}
      </Table.Cell>
    )
  }

  if (props.column.name === 'start' || props.column.name === 'end') {
    return (
      <Table.Cell {...props}>
        {moment(row[props.column.name === 'start' ? 'validFrom' : 'validTo'])
          .format('MMM D, Y')}
      </Table.Cell>
    )
  }

  if (props.column.name === 'active') {
    return getActiveCell(row, props, rows, onUpdateRows);
  }

  return <Table.Cell {...props} />;
};


const getActiveCell = (row, props, rows, onUpdateRows) => {
  const onChangeActivityBeforeApiCall = (status) => {
    // Update statuses for variants
    for (let i = 0; i < rows.length; i++) {
      if ((row.id === rows[i].id)) {
        rows[i].active = status;
      }
    }
    onUpdateRows(rows);
  };

  const onChangeProductActivity = (status) => {
    onChangeActivityBeforeApiCall(status);
    (status) ?
      apiDiscountEnable(row.id, undefined, undefined) :
      apiDiscountDisable(row.id, undefined, undefined)
  };

  return (
    <Table.Cell {...props}>
      <TableCheckbox onChange={onChangeProductActivity}
                     checked={row.active}
      />
    </Table.Cell>
  )
};