import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import DaysScene from './scenes/DaysScene/DaysScene';
import LocationsScene from './scenes/LocationsScene/LocationsScene';
import OrdersScene from './scenes/OrdersScene/OrdersScene';
import OrderScene from './scenes/OrderScene/OrderScene';
import ErrorScene from './scenes/ErrorScene/ErrorScene';
import { PrivatRouteWithTokenLogin } from './router';
// Add skeleton styles, it may be included to styles.css or src/index.js if we will use it everywhere
import 'react-loading-skeleton/dist/skeleton.css';

function RoutesWrapper() {
  return (
    <Routes>
      <Route index element={<Navigate to="/app/deliveries/days" replace />} />
      <Route
        exact
        path="login/:token"
        element={<PrivatRouteWithTokenLogin component={() => <Navigate to="/app/deliveries/days" replace />} />}
      />
      <Route exact path="days" element={<PrivatRouteWithTokenLogin component={DaysScene} />} />
      <Route exact path="day/:day/locations" element={<PrivatRouteWithTokenLogin component={LocationsScene} />} />
      <Route
        exact
        path="day/:day/location/:itemId/orders"
        element={<PrivatRouteWithTokenLogin component={OrdersScene} />}
      />
      <Route exact path="orders/:orderId" element={<PrivatRouteWithTokenLogin component={OrderScene} />} />
      <Route exact path="*" element={<ErrorScene />} />
    </Routes>
  );
}

export { RoutesWrapper };
