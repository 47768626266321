import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {COLORS} from "../../../helpers/index";

const styles = ({
  text: {
    fontSize: 14,
    color: COLORS.textSecondary,
    fontFamily: "Roboto, sans-serif",
  },
});

const propTypes = {
  producer: PropTypes.object.isRequired,
};

const Address = ({ producer,
                 classes }) => {
  return (
    <div className={classes.wrapper}>
      <Typography className={classes.text} variant={"body1"}>{producer.name}</Typography>
      <Typography className={classes.text} variant={"body1"}>{producer.nameShort}</Typography>
      <Typography className={classes.text} variant={"body1"}>{producer.address1}</Typography>
      <Typography className={classes.text} variant={"body1"}>{producer.city}, {producer.zip}, {producer.countryCode}</Typography>
      <Typography className={classes.text} variant={"body1"}>{producer.phone}</Typography>
      <Typography className={classes.text} variant={"body1"}>{producer.email}</Typography>
    </div>
  );
};

Address.propTypes = propTypes;

export default withStyles(styles)(
  Address
);

