import React from 'react';
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import DXSelectFilter from '../DXSelectFilter/DXSelectFilter'
import DXFilterConfig from '../DXFilterConfig'
import constants from '../../constants'

const propTypes = {
  equality: PropTypes.string,
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  selected: [],
};

class DXOrderBookingStatusFilter extends React.Component {

  render() {
    const { selected, onChange, intl, equality} = this.props;
    const config = DXOrderBookingStatusFilter.getConfig(intl, equality);
    return (
      <DXSelectFilter selected={selected}
                      title={config.title}
                      values={config.values}
                      onChange={onChange}
                      multi={config.multi}
      />
    )
  }
}

DXOrderBookingStatusFilter.propTypes = propTypes;
DXOrderBookingStatusFilter.defaultProps = defaultProps;
DXOrderBookingStatusFilter.getLabel = (intl, selectedValues) => {
  let label = typeof(selectedValue) === 'string' ? selectedValues : "";
  if (Array.isArray(selectedValues) && selectedValues.length) {
    if (selectedValues[0] === 'yes') {
      label = intl.formatMessage({id: 'filter.orderBookingStatus.booked'})
    }
    if (selectedValues[0] === 'no') {
      label = intl.formatMessage({id: 'filter.orderBookingStatus.notBooked'})
    }
    if (selectedValues[0] === 'error') {
      label = intl.formatMessage({id: 'filter.orderBookingStatus.failed'})
    }
  }
  return label;
};
DXOrderBookingStatusFilter.applyToQueryParams = (queryParams, selectedValues) => {
  queryParams['booked'] = selectedValues;
  return queryParams;
};
DXOrderBookingStatusFilter.getConfig = (intl, equaltity) => {
  let config = new DXFilterConfig({
    name: 'booked',
    selected: [],
    property: 'booked',
    title: intl.formatMessage({id: 'filter.orderBookingStatus'}),
    disabled: false,
    equalityStatus: false,
    multi: false
  });

  if (equaltity === constants.FILTER_EQUALITY_EQUALS) {
    config.addFilterValue('yes', intl.formatMessage({id: 'filter.orderBookingStatus.booked'}));
    config.addFilterValue('no', intl.formatMessage({id: 'filter.orderBookingStatus.notBooked'}));
    config.addFilterValue('error', intl.formatMessage({id: 'filter.orderBookingStatus.failed'}));
  }

  return config;
};

export default injectIntl(
  DXOrderBookingStatusFilter
);
