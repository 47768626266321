export default ({
  wrapper: {
  },
  paper: {
    padding: "47px 52px 74px",
    borderRadius: 8,
    maxHeight: 'calc(100% - 16px)',
    minWidth: 980
  },
});
