import React, { useEffect, useState, useMemo } from 'react';
import { Title, HeaderArea, ItemsList, Footer, ProductPagination } from '../../components';
import { makeStyles } from '@material-ui/core/styles';
import { PADDING_CONTENT, PADDING_CONTENT_TOP, ORDER_DATE_FORMAT, ORDERS_PER_PAGE } from '../../constants/globals';
import { useIntl } from 'react-intl';
import { FONT_CART_VARIANT_TITLE } from '../../constants/fonts';
import { COLORS } from '../../constants';
import { apiGetPickupsDays } from '../../api';
import moment from 'moment';
import { withRouter } from '../../hocs';
import { PaginationMeta } from '../../entities/PaginationMeta';
import { setPickupsLoading } from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { DaysSkeleton } from '../../components/SkeletonTemplates';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  content: {
    paddingLeft: PADDING_CONTENT,
    paddingRight: PADDING_CONTENT,
    paddingTop: PADDING_CONTENT_TOP,
  },
  title: {
    marginBottom: 20,
  },
  buttonWrapper: {
    marginBottom: 20,
    paddingLeft: PADDING_CONTENT,
    paddingRight: PADDING_CONTENT,
  },
  error: {
    ...FONT_CART_VARIANT_TITLE,
    color: COLORS.danger,
    paddingBottom: 20,
    paddingTop: 20,
  },
}));

const DaysScene = (props) => {
  const { history } = props;
  const intl = useIntl();
  const classes = useStyles();

  const [dates, setDates] = useState([]);
  const [error, setError] = useState('');
  const [initialLoading, setInitialLoading] = useState(true);
  const [meta, setMeta] = useState(new PaginationMeta());
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const onLoading = (isLoading) => dispatch(setPickupsLoading(isLoading));
  const { loading } = useSelector((state) => {
    return {
      loading: state.pickupsApp.loading,
    };
  });

  useEffect(
    () => {
      onLoading(false);

      getDates();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getDates = () => {
    setInitialLoading(true);
    const params = {
      shippingDateFrom: moment().startOf('day').toISOString(true),
    };

    apiGetPickupsDays(
      params,
      (response) => {
        setDates(response);
        setMeta(
          new PaginationMeta({
            limit: ORDERS_PER_PAGE,
            skip: 0,
            total: response.length,
            hasNext: currentPage * ORDERS_PER_PAGE < response.length,
          })
        );

        setInitialLoading(false);
      },
      (error) => {
        setInitialLoading(false);

        setError(error?.response?.data?.message || intl.formatMessage({ id: 'pickups.somethingWentWrong' }));
      }
    );
  };

  const onPageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const getPageContent = useMemo(
    () => () => {
      const from = (currentPage - 1) * ORDERS_PER_PAGE;
      const to = currentPage * ORDERS_PER_PAGE;

      return dates.slice(from, to);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage, dates?.length]
  );

  if (loading) {
    return null;
  }

  return (
    <div className={classes.root}>
      <HeaderArea noMenuButton />

      {!initialLoading ? (
        <div className={classes.content}>
          <Title className={classes.title} text={intl.formatMessage({ id: 'pickups.days.title' })} />

          {!!error && <p className={classes.error}>{error}</p>}

          {!error && (
            <>
              <ItemsList
                items={getPageContent()}
                itemBodyContentRenderer={(date) => ({
                  title: moment.parseZone(date).format(ORDER_DATE_FORMAT),
                })}
                onItemClick={(item) => history.push(`/app/pickups/day/${item}/locations`)}
                emptyMessage={intl.formatMessage({ id: 'pickups.days.daysNotFound' })}
              />
              <ProductPagination meta={meta} page={currentPage} onChange={onPageChange} />
            </>
          )}
        </div>
      ) : (
        <DaysSkeleton />
      )}

      <Footer />
    </div>
  );
};

export default withRouter(DaysScene);
