import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from '../../hocs';
import EditIcon from '@material-ui/icons/Edit';
import { AdminDXTable, Tab, TabActions } from '../../components';
import {
  ProductDialog,
  ProductDialogStages,
  SaveTabSettingDialog,
  TabTypes,
  ProductsFilterDialogNew,
  VariantDialog,
  ImageDialog,
  AddProductLocationDialog,
} from '../../dialogs';
import { apiGetListOfProducts } from '../../api/product/product';
import { ITEMS_STATUSES, mergeFilters, NoDataComponent, TableCell, TableRow, TableTreeCheckbox } from './helpers';
import {
  COLORS,
  DEFAULT_MAX_CELL_WIDTH,
  DEFAULT_MIN_CELL_WIDTH,
  countWidthOfColumns,
  makeColumnWidthEntityArray,
  HEADER_HEIGHT_STANDARD,
} from '../../helpers';
import { getGridPageSize, getGridPageSizes } from '../../helpers/grid';
import ProductsBatchControls from './ProductsBatchControls';
import PriceDialog from '../../dialogs/PriceDialog/PriceDialog';
import { clearClientSideFilters } from './helpers';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { PaginationMeta } from '../../entities/PaginationMeta';

const propTypes = {
  tab: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  defaultHiddenColumnNames: PropTypes.array,
  columnExtensions: PropTypes.array,
  onlyActive: PropTypes.bool,
  withFilters: PropTypes.bool,
  allowCreateTab: PropTypes.bool,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onDeleteTab: PropTypes.func,
  onCreateTab: PropTypes.func,
  onPatchTab: PropTypes.func,
  onAddingFilter: PropTypes.func,
  refetchColumns: PropTypes.func,
  serverSideFilters: PropTypes.array,
};

const defaultProps = {
  onlyActive: false,
  defaultHiddenColumnNames: [],
  serverSideFilters: [],
};

class ProductsContent extends React.Component {
  constructor(props) {
    super(props);

    const { tab, serverSideFilters, onlyArchived } = props;

    const appliedFilters = mergeFilters(
      (!!tab?.filters?.length && [...tab.filters, ...serverSideFilters]) || serverSideFilters || []
    );

    const hiddenColumnNames = !!tab.excludeColumns ? tab.excludeColumns : props.defaultHiddenColumnNames;

    this.state = {
      loading: true,
      creatingProduct: false,
      createPrice: false,
      creatingLocation: false,
      updatingProduct: null,
      updatingVariant: null,
      appliedFilters: clearClientSideFilters(appliedFilters),
      selectedFilter: null,
      filterDialog: false,
      tabDialog: false,
      onlyArchived: onlyArchived,
      imageDialog: null,
      sorting: tab.sorting || [{ columnName: 'subType', direction: 'asc' }],
      hiddenColumnNames: [...hiddenColumnNames, ...this.props.hiddenColumnNames],
      updatedItemsStatuses: {},

      columnWidths:
        makeColumnWidthEntityArray(tab.columnsWidth, props.columns) ||
        this.updateInitialColumnWidths(tab.excludeColumns),
    };
  }

  onColumnWidthsChange = (columnWidths) => {
    this.setState({ columnWidths });
  };

  updateInitialColumnWidths = (hiddenColumnNames) => {
    return countWidthOfColumns(this.props.columns, hiddenColumnNames, this.props.columnExtensions);
  };

  onUpdateTab = () => {
    const { tab, onPatchTab } = this.props;

    if (tab.id) {
      onPatchTab &&
        onPatchTab(tab, {
          filters: this.state.appliedFilters,
          excludeColumns: this.state.hiddenColumnNames,
          columnsWidth: this.state.columnWidths,
          sorting: this.state.sorting,
        });
    }
  };

  onAddFilter = () => {
    this.setState({ filterDialog: true });
  };

  onCreateTab = () => {
    this.setState({ tabDialog: true });
  };

  onCreateProduct = () => {
    this.props.history.push(`/product/new-product`);
  };

  onCreatePrice = () => {
    this.setState({ createPrice: true });
  };

  onCreateLocation = () => {
    this.setState({ creatingLocation: true });
  };

  onCloseCreateLocation = (changed) => {
    let state = {
      creatingLocation: false,
      updatingProduct: null,
    };

    this.setState(state, () => {
      if (changed) {
        this.table.forceReload();
      }
    });
  };

  onCloseCreatePrice = () => {
    this.setState({ createPrice: false });
  };

  onCreatedPriceForLocation = () => {
    // Update columns
    this.props.refetchColumns &&
      this.props.refetchColumns((columns) => {
        this.table.resetColumns(columns);
      });
  };

  onChangeColumnsState = (hiddenColumnNames) => {
    this.setState({
      hiddenColumnNames,
      columnWidths: this.updateInitialColumnWidths(hiddenColumnNames),
    });
  };

  getFiltersDialog = (onClose) => {
    const { appliedFilters, selectedFilter } = this.state;

    const { producer, onlyActive } = this.props;

    return (
      <ProductsFilterDialogNew
        onApplyFilters={(appliedFilters) => {
          this.setState({ appliedFilters }, () => {
            onClose && onClose();
          });
        }}
        subTypes={Object.keys(producer.subTypes)}
        types={Object.keys(producer.types)}
        onClose={() => onClose && onClose()}
        disableActivityFilter={onlyActive}
        appliedFilters={JSON.parse(JSON.stringify(appliedFilters))}
        openWithFilter={selectedFilter}
      />
    );
  };

  onChangeSorting = (sorting) => {
    this.setState({ sorting });
  };

  onCloseFilterDialog = () => {
    this.setState({
      filterDialog: false,
    });
  };

  handleFilterRemove = (removeFilter) => {
    this.setState((state) => {
      const appliedFilters = state.appliedFilters;
      const filters = appliedFilters.filter((filter) => {
        return filter.name !== removeFilter.name;
      });
      return { appliedFilters: filters };
    });
  };

  onUpdate = (row) => {
    if (row.product) {
      this.props.history.push(`/product/${row.product.id}/${row.id}`);
    } else {
      this.props.history.push(`/product/${row.id}`);
    }
  };

  onCloseProductDialog = (changed) => {
    let state = {
      creatingProduct: false,
      updatingProduct: null,
    };

    this.setState(state, () => {
      if (changed) {
        this.table.forceReload();
      }
    });
  };

  onCloseVariantDialog = (changed) => {
    let state = {
      updatingVariant: null,
    };

    this.setState(state, () => {
      if (changed) {
        this.table.forceReload();
      }
    });
  };

  onCellClick = (cell, row) => {
    if (cell === 'image') {
      this.setState({ imageDialog: row });
    }
  };

  apiRetrieve = (params, onSuccess, onError) => {
    const { onlyArchived, updatedItemsStatuses } = this.state;

    let data = { ...params, onlyArchived };

    apiGetListOfProducts(
      data,
      (response) => {
        const result = [];

        // Make instant response update depend on bulk operation statuses
        response?.data?.length &&
          response?.data.forEach((product) => {
            const resultProduct = { ...product, variants: [] };
            const productStatus = updatedItemsStatuses[product?.id];

            if (
              productStatus &&
              (productStatus?.status === ITEMS_STATUSES.DELETED ||
                (productStatus?.status === ITEMS_STATUSES.ARCHIVED && !onlyArchived) ||
                (productStatus?.status === ITEMS_STATUSES.UNARCHIVED && onlyArchived))
            ) {
              return; //exclude item from result
            }

            if (Array.isArray(product?.variants)) {
              product.variants.forEach((variant) => {
                const variantStatus = updatedItemsStatuses[variant?.id];

                if (
                  variantStatus &&
                  (variantStatus?.status === ITEMS_STATUSES.DELETED ||
                    (variantStatus?.status === ITEMS_STATUSES.ARCHIVED && !onlyArchived) ||
                    (variantStatus?.status === ITEMS_STATUSES.UNARCHIVED && onlyArchived))
                ) {
                  return; //exclude variant from result
                }

                resultProduct.variants.push(variant);
              });
            }

            if (!resultProduct?.variants?.length) {
              return; // exclude products without variants
            }

            return result.push(resultProduct);
          });

        // Prevent empty results by jumping next page
        if (
          !result?.length &&
          this.table.state?.paginationMeta?.hasNext &&
          Object.keys(this.state.updatedItemsStatuses)?.length
        ) {
          this?.table?.onCurrentPageChange && this?.table?.onCurrentPageChange(this?.table?.state?.currentPage + 1);
        }

        onSuccess({ data: result, meta: response?.meta || new PaginationMeta() });
      },
      onError
    );
  };

  updateItemsStatuses = (updatedItemsStatuses) => {
    this.setState((state) => ({ updatedItemsStatuses: { ...state.updatedItemsStatuses, ...updatedItemsStatuses } }));
  };

  additionalOnlyArchivedProps = () => {
    const { onlyArchived } = this.state;
    const { intl, classes } = this.props;

    if (onlyArchived) {
      return {
        ToolbarFlexibleSpaceComponent: (props) => {
          return (
            <div className={classes.timeToAppear}>
              {!!props?.rows?.length && intl.formatMessage({ id: 'product.timeToAppear' })}
            </div>
          );
        },
      };
    }

    return {};
  };

  render() {
    const {
      tab,
      index,
      intl,
      producer,
      allowCreateTab,
      onDeleteTab,
      onCreateTab,
      columnExtensions,
      columns,
      classes,
    } = this.props;

    const {
      appliedFilters,
      tabDialog,
      creatingProduct,
      createPrice,
      creatingLocation,
      updatingProduct,
      hiddenColumnNames,
      sorting,
      filterDialog,
      updatingVariant,
      imageDialog,
      onlyArchived,
    } = this.state;

    return (
      <Tab
        title={tab.name}
        key={`${index}-tab`}
        tab={tab}
        actions={
          onlyArchived ? (
            <div className={classes.noActions} />
          ) : (
            <TabActions
              tab={tab}
              columns={columns}
              hiddenColumnNames={hiddenColumnNames}
              allowCreateTab={allowCreateTab}
              labels={{
                createEntity: intl.formatMessage({ id: 'products.new' }),
              }}
              onCreatePrice={this.onCreatePrice}
              onCreateEntity={this.onCreateProduct}
              onCreateLocation={this.onCreateLocation}
              onCreateTab={this.onCreateTab}
              onUpdateTab={this.onUpdateTab}
              onAddingFilter={this.onAddFilter}
              onChangeColumnsState={this.onChangeColumnsState}
              onDeleteTab={onDeleteTab}
              allowHideActionsColumn={!onlyArchived}
            />
          )
        }>
        <AdminDXTable
          flexibleTableWidth
          onRef={(table) => (this.table = table)}
          actionsHeaderCellStyle={{ textAlign: 'center' }}
          actionsCellStyle={{ textAlign: 'center' }}
          cellRenderer={(...props) => TableCell(...props, this.onCellClick, this.props.dynamicColumns, onlyArchived)}
          rowRenderer={(onClick, props) => TableRow(onClick, props, onlyArchived)}
          treeCheckboxRenderer={(props) => {
            return TableTreeCheckbox(props, onlyArchived);
          }}
          apiRetrieve={this.apiRetrieve}
          pageSize={getGridPageSize()}
          pageSizes={getGridPageSizes()}
          onChangeSorting={this.onChangeSorting}
          onOpenWithFilter={(index, filter) =>
            this.setState({
              filterDialog: true,
              selectedFilter: filter,
            })
          }
          columns={columns}
          hiddenColumnNames={hiddenColumnNames}
          sorting={sorting}
          allowCollapseAll
          enableTree
          columnExtensions={columnExtensions}
          enableTreeSelection
          treeSelectionColumn={'selection'}
          batchControlsComponent={(props) => (
            <ProductsBatchControls
              onlyArchived={onlyArchived}
              onUpdateItemsStatuses={this.updateItemsStatuses}
              {...props}
            />
          )}
          actions={[
            {
              icon: <EditIcon style={{ color: COLORS.text, fontSize: 18 }} />,
              action: (row) => this.onUpdate(row),
            },
          ]}
          getTreeChildRows={(row, rootRows) => {
            if (row) {
              row.variants &&
                row.variants.forEach((variant, index) => {
                  row.variants[index]['product'] = row;
                });
              return row.variants;
            }
            return rootRows;
          }}
          columnWidths={this.state.columnWidths}
          onColumnWidthsChange={this.onColumnWidthsChange}
          minColumnWidth={DEFAULT_MIN_CELL_WIDTH}
          maxColumnWidth={DEFAULT_MAX_CELL_WIDTH}
          allowExpandAll={true}
          enableRemoteSearch
          enablePager
          serverSidePagination
          serverSideFilters={JSON.parse(JSON.stringify(appliedFilters))}
          onRemoveFilter={this.handleFilterRemove}
          stickyHeader
          stickyHeaderOffset={HEADER_HEIGHT_STANDARD.extended}
          NoDataCellComponent={(props) => <NoDataComponent {...props} onlyArchived={onlyArchived} />}
          {...this.additionalOnlyArchivedProps()}
        />

        {creatingProduct && (
          <ProductDialog
            onClose={this.onCloseProductDialog}
            producer={producer}
            stage={ProductDialogStages.CREATE_PRODUCT}
          />
        )}

        {createPrice && <PriceDialog onClose={this.onCloseCreatePrice} onCreated={this.onCreatedPriceForLocation} />}

        {creatingLocation && (
          <AddProductLocationDialog
            onClose={this.onCloseCreateLocation}
            addColumnToTable={this.props.addColumnToTable}
          />
        )}

        {updatingProduct && (
          <ProductDialog
            onClose={this.onCloseProductDialog}
            product={updatingProduct}
            variant={updatingVariant}
            producer={producer}
            stage={ProductDialogStages.UPDATE_PRODUCT}
          />
        )}

        {updatingVariant && (
          <VariantDialog
            onClose={this.onCloseVariantDialog}
            onUpdateProduct={() => this.onCloseVariantDialog(true)}
            product={updatingVariant.product}
            variant={updatingVariant}
            producer={producer}
          />
        )}

        {imageDialog && <ImageDialog object={imageDialog} onClose={() => this.setState({ imageDialog: null })} />}
        {filterDialog && this.getFiltersDialog(this.onCloseFilterDialog)}

        {tabDialog && (
          <SaveTabSettingDialog
            onClose={() => this.setState({ tabDialog: false })}
            filters={JSON.parse(JSON.stringify(appliedFilters))}
            excludeColumns={hiddenColumnNames}
            columnWidths={this.state.columnWidths}
            sorting={sorting}
            onSave={(data) => onCreateTab(data, () => this.setState({ tabDialog: false }))}
            type={TabTypes.PRODUCTS}
          />
        )}
      </Tab>
    );
  }
}

ProductsContent.propTypes = propTypes;
ProductsContent.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  return {
    producer: state.producer.object,
  };
};

export default injectIntl(withRouter(connect(mapStateToProps)(withStyles(styles)(ProductsContent))));
