import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../hocs';
import { AdminLayout, AdminTabs, Spinner } from '../../components';
import { SidebarMenu, HeaderMenu } from '../Partials';
import TabTypes from '../../dialogs/SaveTabSettingsDialog/Types';
import { setProducer } from '../../store/actions';
import { MAIN_TAB_NAMES } from '../../helpers/tabs';
import ReportsContent from './ReportsContent';
import styles from './styles';
import { apiCreateTab, apiDeleteTab, apiPatchTab } from '../../api';
import config from './config';
import { REPORTS_TYPES } from './config';

class ReportsScene extends React.Component {
  constructor(props) {
    super(props);

    const { tabs } = props;
    const tabIndex = tabs.getInitialIndex(window.location.hash, true);

    this.state = {
      tabIndex,
      currentReport: {},
      filtersLoading: true,
    };
  }

  componentDidMount() {
    const { tabs } = this.props;
    const tabIndex = tabs.getInitialIndex(window.location.hash, true);
    this.getInitialReport(tabIndex);
  }

  getInitialReport = (tabIndex, reportKey) => {
    const { tabs } = this.props;
    const selectedTab = tabs.getTabs('')[tabIndex];
    const allReports = config(this.props.intl);

    let initialReportName = reportKey ? reportKey : REPORTS_TYPES.REPORT_SALES_ITEMS;

    if (selectedTab && selectedTab.filters && selectedTab.filters.length && !reportKey) {
      initialReportName = selectedTab.filters.find((filter) => filter.name === 'reportSelection').selectedValues[0];
    }

    let reportSavedParams = {};

    if (selectedTab && selectedTab.reports && selectedTab.reports.length) {
      reportSavedParams = selectedTab.reports.find((report) => report.name === initialReportName);
    }

    const reportName = REPORTS_TYPES[initialReportName];

    this.setState({ filtersLoading: false, currentReport: allReports[reportName], ...reportSavedParams });
  };

  onCreateTab = (data, onDone) => {
    this.setState({ loading: true });
    apiCreateTab(
      data,
      (producer) => {
        this.props.setProducer(producer);
        this.setState((state) => {
          state.loading = false;
          onDone && onDone();
          return state;
        });
      },
      undefined
    );
  };

  onDeleteTab = (tab) => {
    this.setState({ loading: true });
    apiDeleteTab(
      tab.id,
      (producer) => {
        this.setState(
          {
            tabIndex: 0,
            loading: false,
          },
          () => {
            this.props.setProducer(producer);
          }
        );
      },
      undefined
    );
  };

  onPatchTab = (tab, data, onDone) => {
    this.setState({ loading: true });

    apiPatchTab(
      tab.id,
      data,
      (producer) => {
        this.props.setProducer(producer);
        this.setState({ loading: false });
        onDone && onDone();
      },
      undefined
    );
  };

  onPatchCustomTab = (tab, data, onDone) => {
    const { tabs } = this.props;
    const { tabIndex } = this.state;

    const allTabs = tabs.getTabs('');
    let allReportsTab = allTabs[tabIndex];

    if (allReportsTab) {
      this.onPatchTab(tab, data, onDone);
    } else {
      data.name = MAIN_TAB_NAMES.REPORTS_ALL;
      data.type = TabTypes.REPORTS;
      this.onCreateTab(data);
    }
  };

  onPatchReportsTab = (tab, data, onDone) => {
    const { tabs } = this.props;
    let reportsTab = tabs.getTabByName(MAIN_TAB_NAMES.REPORTS_ALL);
    if (reportsTab) {
      this.onPatchTab(reportsTab, data, onDone);
    } else {
      data.name = MAIN_TAB_NAMES.REPORTS_ALL;
      data.type = TabTypes.REPORTS;
      this.onCreateTab(data);
    }
  };

  onChangeTab = (index) => {
    const { tabs } = this.props;
    window.location.hash = tabs.getHashByIndex(index);
    this.setState({ tabIndex: index, filtersLoading: true }, () => {
      this.getInitialReport(index);
    });
  };

  getTabs() {
    const { tabIndex, currentReport } = this.state;
    const { intl, tabs } = this.props;

    const tabsTitles = tabs.getTabNames([intl.formatMessage({ id: 'global.reports' })]);
    const customTabs = tabs.getOnlyCustom();
    let reportsTab = tabs.getTabByName(MAIN_TAB_NAMES.REPORTS_ALL, tabsTitles[0], {
      id: MAIN_TAB_NAMES.REPORTS_ALL,
      name: tabsTitles[0],
    });

    return (
      <AdminTabs onChangeTab={this.onChangeTab} tab={tabIndex} tabs={tabsTitles}>
        {tabIndex === 0 && (
          <ReportsContent
            tab={reportsTab}
            openFilterDialog={() => this.setState({ filterDialog: true })}
            isFiltersDialogActive={this.state.filterDialog}
            onCloseFilterDialog={() => this.setState({ filterDialog: false })}
            onPatchTab={this.onPatchReportsTab}
            hiddenColumnNames={[]}
            allowCreateTab
            withFilters
            onCreateTab={this.onCreateTab}
            onRef={(tab) => (this.tab = tab)}
            key={'all-reports'}
            index={'all-reports'}
            currentReport={currentReport}
            allBackEndSavedReports={reportsTab.reports || []}
          />
        )}

        {!!customTabs.length && tabIndex > 0 && (
          <ReportsContent
            tab={customTabs[tabIndex - 1]}
            contentTag={'allReportsTabContent'}
            showTabDialog={true}
            allowCreateTab
            withFilters
            onCreateTab={this.onCreateTab}
            onPatchTab={this.onPatchCustomTab}
            onDeleteTab={this.onDeleteTab}
            onRef={(tab) => (this.tab = tab)}
            key={`custom-${tabIndex}`}
            index={`custom-${tabIndex}`}
            currentReport={currentReport}
            allBackEndSavedReports={customTabs[tabIndex - 1].reports || []}
          />
        )}
      </AdminTabs>
    );
  }

  render() {
    const { loading, filtersLoading } = this.state;

    if (filtersLoading) return null;

    return (
      <AdminLayout sidebarComponent={SidebarMenu} headerMenuComponent={HeaderMenu}>
        {loading && <Spinner size={60} />}
        {this.getTabs()}
      </AdminLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  tabs: state.producer.tabs[TabTypes.REPORTS],
  producer: state.producer.object,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setProducer: (producer) => dispatch(setProducer(producer)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(withStyles(styles)(ReportsScene))));
