import React from 'react';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const propTypes = {
  meta: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    marginBottom: 20,
  },
}));

const ProductPagination = (props) => {
  const { meta, page, onChange } = props;
  const classes = useStyles();

  const count = Math.ceil(meta.total / meta.limit);

  return (
    <div className={classes.root}>
      <Pagination page={page} count={count} onChange={onChange} variant="outlined" shape="rounded" />
    </div>
  );
};

ProductPagination.propTypes = propTypes;

export default ProductPagination;
