import React from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import PeriodChangeControl from './PeriodChangeControl';
import { getPrevMonthByDate, getNextMonthByDate } from '../../helpers';

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  separator: {
    borderLeft: '1px solid rgba(0, 0, 0, 0.2)',
    marginLeft: 20,
    marginRight: 20,
    height: 20,
  },
}));

const PeriodChangeHeaderCell = (props) => {
  const {
    amountOfPeriod = 1,
    periodValue = 'month',
    selectedPeriod = { from: '', to: '' },
    onChange = () => {},
    disabled,
  } = props;
  const classes = useStyles();
  const intl = useIntl();

  const changePeriod = (direction) => {
    const { from, to } = selectedPeriod;
    let newFrom, newTo;

    if (disabled) {
      return;
    }

    if (!from || !to) {
      return;
    }

    if (direction === 'forward') {
      newFrom = moment(from).add(amountOfPeriod, periodValue).startOf('day').toISOString(true);
      newTo = moment(to).add(amountOfPeriod, periodValue).endOf('month').endOf('day').toISOString(true);
    } else {
      newFrom = moment(from).add(-amountOfPeriod, periodValue).startOf('day').toISOString(true);
      newTo = moment(to).add(-amountOfPeriod, periodValue).endOf('month').endOf('day').toISOString(true);
    }

    const result = { from: newFrom, to: newTo };
    onChange(result);
    return result;
  };

  return (
    <div className={classes.root}>
      <PeriodChangeControl
        onClick={() => changePeriod('back')}
        type={'back'}
        text={getPrevMonthByDate(selectedPeriod.from).format('MMM YYYY')}
        title={intl.formatMessage({ id: 'memberService.header.prevMonth' })}
      />

      <div className={classes.separator} />

      <PeriodChangeControl
        onClick={() => changePeriod('forward')}
        type={'forward'}
        text={getNextMonthByDate(selectedPeriod.from).format('MMM YYYY')}
        title={intl.formatMessage({ id: 'memberService.header.nextMonth' })}
      />
    </div>
  );
};

export default PeriodChangeHeaderCell;
