import React from "react";
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import withWidth, {isWidthDown} from '@material-ui/core/withWidth';
import {withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import BackIcon from "@material-ui/icons/ArrowBack";
import {
  AdminDialogTitle,
  Icon
} from '../../components';

const styles = theme => ({
  paper: {
    padding: "47px 52px 74px",
    borderRadius: 8,
    maxHeight: 'calc(100% - 16px)',
    [theme.breakpoints.down('md')]: {
      margin: 10,
      minWidth: 344,
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 580,
    }
  },
  stepper: {
    height: 4,
    backgroundColor: '#68D7A3',
    position: 'absolute',
    top: 0,
    left: 0
  },
  back: {
    position: 'absolute',
    top: 21,
    left: 26,
    zIndex: 1000,
    cursor: 'pointer',
    fill: 'rgba(0, 0, 0, 0.5)'
  },
  close: {
    position: 'absolute',
    top: 21,
    right: 26,
    zIndex: 1000,
    cursor: 'pointer'
  }
});

class AdminDialog extends React.Component {
  render() {
    const {
      content,
      actions,
      title,
      width,
      onClose,
      closeButton,
      classes,
      withBack,
      onBackClick,
      backdropInvisible,
      mobileFullScreen,
      paperClass,
      step,
      ...other
    } = this.props;

    return (
      <Dialog onClose={onClose}
              BackdropProps={{
                invisible: backdropInvisible
              }}
              classes={{paper: paperClass ? paperClass : classes.paper}}
              fullScreen={mobileFullScreen ? isWidthDown('sm', width) : false}
              {...other} >

        {step && (
          <span style={{width: `${step}%`}} className={classes.stepper}/>
        )}

        {title ? <AdminDialogTitle title={title}/> : null}
        {withBack && (
            <Icon icon={BackIcon}
                  style={{fill: 'rgba(0, 0, 0, 0.5)'}}
                  className={classes.back}
                  onClick={() => onBackClick && onBackClick()} />
        )}
        {closeButton && (
          <Icon icon={CloseIcon}
                style={{fill: 'rgba(0, 0, 0, 0.5)'}}
                className={classes.close}
                onClick={onClose} />
        )}
        {content()}
        {actions()}

        {this.props.children}
      </Dialog>
    );
  }
}

AdminDialog.propTypes = {
  mobileFullScreen: PropTypes.bool,
  backdropInvisible: PropTypes.bool,
  closeButton: PropTypes.bool,
  step: PropTypes.number,
  paperClass: PropTypes.string,
  withBack: PropTypes.bool,
  onBackClick: PropTypes.func,
  content: PropTypes.func.isRequired,
  actions: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

AdminDialog.defaultProps = {
  title: "",
  withBack: false,
  backdropInvisible: false,
  mobileFullScreen: false,
  closeButton: false,
  onClose: () => {},
  actions: () => {},
  content: () => {},
};

export default withStyles(styles, {withTheme: true})(
  withWidth()(AdminDialog)
);