export default {
  wrapper: {
    paddingTop: 18,
    paddingLeft: 18,
    paddingRight: 18,
  },
  filtersWrapper: {
    marginLeft: 13,
    marginRight: 13,
  },
  noRequiredFiltersMessageWrapper: {
    width: '100%',
    padding: 40,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  noRequiredFiltersMessageText: {
    fontSize: 18,
    color: '#4D4F5C',
  },
  textButton: {
    borderRadius: 30,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 5,
    paddingBottom: 5,
    marginLeft: 10,
    marginRight: 10,
    boxShadow: '0px 3px 6px #36363640',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    transition: 'background 0.5s ease-in-out',
    '&:hover': {
      backgroundColor: '#36363610',
    },
  },
};
