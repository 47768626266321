import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../hocs';
import {
  AdminDialog,
  AdminDialogTitle,
  AdminDialogContent,
  AdminDialogControls,
  PrimaryButton,
  Spinner,
  RichTextEditor,
} from '../../components';
import { COLORS } from '../../helpers';
import { getEditorStateFromHtml, getHtmlFromEditor } from './helpers';

const styles = {
  wrapper: {},
  name: {
    fontSize: 18,
    fontFamily: "'Roboto', sans-serif",
    color: COLORS.text,
    marginBottom: 40,
  },
  variants: {
    marginBottom: 50,
  },
  input: {
    marginBottom: 25,
  },
  inputText: {
    textAlign: 'right',
  },
  button: {
    minWidth: '40%',
    textTransform: 'uppercase',
  },
  mr20: {
    marginRight: 10,
  },
  controls: {
    margin: '27px 0 0 0',
    textAlign: 'center',
  },
  control: {
    padding: '0 105px',
  },
};

const propTypes = {
  text: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  requestKey: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.string,
};

const RichEditorDialog = ({ intl, classes, onClose, onSave, title, text }) => {
  const defaultEditorState = getEditorStateFromHtml(text);
  const [loading, setLoading] = React.useState(false);
  const [editorState, setEditorState] = React.useState(defaultEditorState);

  const forceSave = React.useCallback((state) => {
    setLoading(true);
    // Convert data to html and call onSave callback
    const html = getHtmlFromEditor(state);
    onSave &&
      onSave(html, () => {
        setLoading(false);
      });
  }, []);

  return (
    <AdminDialog open closeButton onClose={onClose}>
      <AdminDialogTitle title={title} />
      <AdminDialogContent className={classes.wrapper}>
        {loading && <Spinner size={50} />}

        <RichTextEditor onChange={(state) => setEditorState(state)} state={editorState} fullWidth />

        <AdminDialogControls>
          <PrimaryButton className={classes.button} onClick={() => forceSave(editorState)}>
            {intl.formatMessage({ id: 'global.save' })}
          </PrimaryButton>
        </AdminDialogControls>
      </AdminDialogContent>
    </AdminDialog>
  );
};

RichEditorDialog.propTypes = propTypes;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(RichEditorDialog)));
