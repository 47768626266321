import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    height: 140,
    '@media print': {
      display: 'none',
    },
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    // for now it empty
    <div className={classes.root} />
  );
};

export default Footer;
