import React from 'react';
import {Table} from '@devexpress/dx-react-grid-material-ui';
import DayCell from './Cells/DayCell';
import UntilCell from './Cells/UntilCell';
import LocationCell from './Cells/LocationCell';
import MinCell from './Cells/MinCell';
import MaxCell from './Cells/MaxCell';
import RateCell from './Cells/RateCell';
import RetailTypeCell from './Cells/RetailTypeCell';
import WholesaleTypeCell from './Cells/WholesaleTypeCell';

export const DeliveryDayTableCell = (reload, {row, ...props}) => {
  if (row.hasOwnProperty('locations') && props.column.name === 'day') {
    return <DayCell row={row} {...props}/>
  }

  if (row.hasOwnProperty('locations') && props.column.name === 'until') {
    return <UntilCell row={row} {...props}/>
  }

  if (!row.hasOwnProperty('locations') && props.column.name === 'location') {
    return <LocationCell row={row} {...props}/>
  }

  if (!row.hasOwnProperty('locations') && props.column.name === 'method') {
    return <RetailTypeCell row={row} {...props}/>
  }
  
  if (!row.hasOwnProperty('locations') && props.column.name === 'type') {
    return <WholesaleTypeCell row={row} {...props}/>
  }

  return <Table.Cell {...props} />;
};

export const DeliveryDayTableRow = (onClick, {row, ...props}) => {
  return (
    <Table.Row
      {...props}
      onClick={() => onClick && onClick(row)}
      style={{
        backgroundColor: (row.locations) ?
          'rgba(224, 224, 224, 0.4)' : '#ffffff'
      }}
    />
  )
};


export const DeliveryRateTableCell = (reload, {row, ...props}) => {
  if (props.column.name === 'min') {
    return <MinCell row={row} {...props}/>
  }

  if (props.column.name === 'max') {
    return <MaxCell row={row} {...props}/>
  }

  if (props.column.name === 'rate') {
    return <RateCell row={row} {...props}/>
  }

  return <Table.Cell {...props} />;
};