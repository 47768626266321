import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { Getter, Template, Plugin } from '@devexpress/dx-react-core';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import Button from '@material-ui/core/Button';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { COLORS, isGroupingHeaderRow } from '../../helpers';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TooltipWrapper from '../TooltipWrapper';

const pluginDependencies = [{ name: 'Table' }];

const ACTIONS_COLUMN_TYPE = 'actionsColumnType';
const TABLE_HEADING_TYPE = 'heading';
const TABLE_NO_DATA_TYPE = 'nodata';

function tableColumnsWithActions(tableColumns, width) {
  return [...tableColumns, { key: ACTIONS_COLUMN_TYPE, type: ACTIONS_COLUMN_TYPE, width: width }];
}

function isHeadingActionsTableCell(tableRow, tableColumn) {
  return tableRow.type.toString().indexOf(TABLE_HEADING_TYPE) !== -1 && tableColumn.type === ACTIONS_COLUMN_TYPE;
}

function isActionsTableCell(tableRow, tableColumn) {
  return (
    tableRow.type.toString().indexOf(TABLE_HEADING_TYPE) === -1 &&
    tableRow.type.toString().indexOf(TABLE_NO_DATA_TYPE) === -1 &&
    tableColumn.type === ACTIONS_COLUMN_TYPE
  );
}

function showActions(row) {
  if (!!row && !isGroupingHeaderRow(row)) {
    return row.showActions && typeof row.showActions === 'function' ? row.showActions(row) : true;
  }
  return false;
}

export default class ActionsColumn extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
    };
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const {
      actions,
      width,
      title,
      headerCellStyle,
      cellStyle,
      rowspan,
      actionsGroupButton,
      disableActionByRow,
    } = this.props;

    const tableColumnsComputed = ({ tableColumns }) => tableColumnsWithActions(tableColumns, width);

    const drawAction = (tableRow, action, index) => {
      if (disableActionByRow && disableActionByRow(tableRow, action, index)) {
        return null;
      }

      if (action?.component) {
        return action.component({ row: tableRow.row, props: this.props });
      }

      if (action?.tooltipMessage) {
        return (
          <TooltipWrapper title={action?.tooltipMessage} key={`${action}-${index}`}>
            <IconButton onClick={() => action.action(tableRow.row)}>{action.icon}</IconButton>
          </TooltipWrapper>
        );
      }

      return (
        <IconButton key={`${action}-${index}`} onClick={() => action.action(tableRow.row)}>
          {action.icon}
        </IconButton>
      );
    };

    return (
      <Plugin name="ActionsColumn" dependencies={pluginDependencies}>
        <Getter name="tableColumns" computed={tableColumnsComputed} />

        <Template
          name="tableCell"
          predicate={({ tableRow, tableColumn }) => isHeadingActionsTableCell(tableRow, tableColumn)}>
          <Table.Cell
            rowSpan={rowspan}
            style={headerCellStyle}
            className={'actionColumnHeaderCell' /* static classes uses for hover effect */}>
            {title}
          </Table.Cell>
        </Template>
        <Template name="tableCell" predicate={({ tableRow, tableColumn }) => isActionsTableCell(tableRow, tableColumn)}>
          {({ tableRow, style }) => {
            if (isGroupingHeaderRow(tableRow?.row)) {
              return null;
            }

            return (
              <Table.Cell
                style={{
                  ...cellStyle,
                  ...style,
                }}
                row={tableRow.row}
                className={'actionColumnBodyCell' /* static classes uses for hover effect */}>
                {!actionsGroupButton &&
                  showActions(tableRow.row) &&
                  actions.map((action, index) => drawAction(tableRow, action, index))}

                {actionsGroupButton && (
                  <>
                    <Button
                      aria-controls={tableRow.row.id}
                      aria-haspopup="true"
                      onClick={(event) => this.handleClick(event)}>
                      <MoreHorizIcon style={{ color: COLORS.text, fontSize: 18 }} />
                    </Button>
                    <Menu
                      id={tableRow.row.id}
                      anchorEl={this.state.anchorEl}
                      open={
                        this.state.anchorEl
                          ? this.state.anchorEl.getAttribute('aria-controls') === tableRow.row.id
                          : false
                      }
                      onClose={this.handleClose}>
                      {showActions(tableRow.row) &&
                        actions.map((action, index) => {
                          return (
                            <MenuItem
                              style={{
                                paddingLeft: 25,
                                paddingRight: 25,
                                minWidth: 200,
                                ...(action.separator ? { borderTop: '1px solid #e6e6e6', paddingTop: 10 } : {}),
                              }}
                              key={`${action}-${index}`}
                              onClick={() => {
                                this.handleClose();
                                action.action(tableRow.row);
                              }}>
                              {action.title}
                            </MenuItem>
                          );
                        })}
                    </Menu>
                  </>
                )}
              </Table.Cell>
            );
          }}
        </Template>
      </Plugin>
    );
  }
}

ActionsColumn.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.PropTypes.shape({
      icon: PropTypes.node,
      action: PropTypes.func.isRequired,
    })
  ).isRequired,
  width: PropTypes.number,
  title: PropTypes.string,
  rowspan: PropTypes.number.isRequired,
  actionsGroupButton: PropTypes.bool,
};

ActionsColumn.defaultProps = {
  width: 120,
  title: 'Actions',
  rowspan: 1,
  actionsGroupButton: false,
};
