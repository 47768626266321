import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../hocs';
import { COLORS, toCurrency } from '../../helpers';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';

const styles = {
  separator: {
    width: '100%',
    margin: '20px 0',
    height: '1px',
    border: 0,
    borderTop: '1px solid ' + COLORS.border,
  },
  rightBoxText: {
    fontSize: 14,
    fontWeight: 400,
    fontFamily: 'Roboto',
    color: COLORS.text,
  },
  bold: {
    fontWeight: 600,
  },
};

const propTypes = {
  order: PropTypes.object.isRequired,
};
const defaultProps = {};

const Prepurchase = ({ order, classes, intl }) => {
  return (
    <React.Fragment>
      {order.prepurchaseOrder && order.payment && order.payment.prepurchase && (
        <React.Fragment>
          <hr className={classes.separator} />
          <Typography style={{ marginTop: 10 }} className={classNames(classes.rightBoxText, classes.bold)}>
            {intl.formatMessage({ id: 'order.csa' })}
          </Typography>
          <Typography className={classes.rightBoxText}>
            {order.payment.prepurchase.name} {toCurrency(order.payment.amountPrepurchased, intl)}
          </Typography>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

Prepurchase.propTypes = propTypes;
Prepurchase.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(injectIntl(withRouter(Prepurchase)));
