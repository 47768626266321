import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Icon from '../../components/Icon/Icon';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Typography from '@material-ui/core/Typography';
import { Spinner } from '../../components';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = {
  image: {
    width: '100%',
  },
  imageBoxWrapper: {
    maxWidth: 300,
    minHeight: 200,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  imageBox: {
    width: '100%',
    height: 200,
    maxHeight: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 3px 6px #36363680',
    flex: 1,
    borderRadius: 16,
    overflow: 'hidden',
    position: 'relative',
  },
  text: {
    fontSize: '14px',
    color: '#707070',
    fontFamily: 'Roboto',
  },
  icon: {
    fontSize: 18,
    marginRight: 10,
    '& path': {
      fill: '#707070',
    },
  },
  labelStyles: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    marginTop: 15,
  },
  insideLabelStyles: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    width: '100%',
    minHeight: 200,
    cursor: 'pointer',
  },
  imageLoading: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const propTypes = {
  onUploaded: PropTypes.func.isRequired,
  base64: PropTypes.string,
  object: PropTypes.object,
};

class BlackImageUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: props.base64 ? props.base64 : null,
      loading: false,
      uploaded: false,
      imageLoading: true,
    };
  }

  getImage = () => {
    const { object } = this.props;
    const { file } = this.state;

    if (file) {
      return `data:image/png;base64,${file}`;
    }

    if (!!object?.base64) {
      return `data:image/png;base64,${object.base64}`;
    }

    if (!!object?.image?.thumbSrc) {
      return object.image.thumbSrc + `?${object?.image?.id}`;
    }

    return null;
  };

  handleChange = (event) => {
    const reader = new FileReader();

    reader.onloadstart = () => {
      this.setState({ loading: true });
    };
    reader.onload = () => {
      const file = reader.result.split(',').pop();
      this.setState({
        loading: false,
        file: file,
      });
      this.props.onUploaded(file);
    };
    reader.onerror = () => {
      this.setState({ loading: false });
    };
    reader.onabort = () => {
      this.setState({ loading: false });
    };
    const firstFile = event?.target?.files?.length ? event?.target?.files[0] : null;

    firstFile && reader.readAsDataURL(firstFile);
  };

  render() {
    const { intl, classes, object } = this.props;

    const image = this.getImage();

    return (
      <React.Fragment>
        {image ? (
          <div className={classes.imageBoxWrapper}>
            <div className={classes.imageBox}>
              <img
                className={classes.image}
                src={image}
                alt={!!object ? object.name : 'Empty'}
                onLoad={() => {
                  this.setState({
                    imageLoading: false,
                  });
                }}
              />
              {this.state.imageLoading && (
                <div className={classes.imageLoading}>
                  <CircularProgress className={classes.spinner} size={25} />
                </div>
              )}
            </div>

            {this.state.loading && <Spinner />}

            <input onChange={this.handleChange} accept="image/*" style={{ display: 'none' }} id="file" type="file" />
            <label htmlFor="file" className={classes.labelStyles}>
              <Icon icon={EditIcon} className={classes.icon} />
              <Typography className={classes.text}>
                {object && object.image
                  ? intl.formatMessage({ id: 'image.update' })
                  : intl.formatMessage({ id: 'image.upload' })}
              </Typography>
            </label>
          </div>
        ) : (
          <div className={classes.imageBoxWrapper}>
            <div className={classes.imageBox}>
              <input onChange={this.handleChange} accept="image/*" style={{ display: 'none' }} id="file" type="file" />
              <label htmlFor="file" className={classes.insideLabelStyles}>
                <Icon icon={AddCircleOutlineIcon} className={classes.icon} />
                <Typography className={classes.text}>
                  {object && object.image
                    ? intl.formatMessage({ id: 'image.update' })
                    : intl.formatMessage({ id: 'image.upload' })}
                </Typography>
              </label>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

BlackImageUploader.propTypes = propTypes;

export default withStyles(styles, { withTheme: true })(injectIntl(BlackImageUploader));
