import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../hocs';
import {
  AdminDialog,
  AdminDialogTitle,
  AdminDialogContent,
  AdminDialogControls,
  PrimaryButton,
  Spinner,
  ProductSharedPropertySelect,
} from '../../components';
import styles from './styles';

const propTypes = {
  types: PropTypes.array.isRequired,
  typeName: PropTypes.string.isRequired,
  object: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const defaultProps = {
  types: [],
};

class DeleteProductSharedPropertyDialog extends React.Component {
  constructor(props) {
    super(props);
    const { object, types } = this.props;

    let state = {
      selected: null,
      loading: false,
    };

    if (object.total === 0 && types.length > 1) {
      state['selected'] = true;
    }

    this.state = state;
  }

  delete = () => {
    const { object, typeName } = this.props;
    this.setState({ loading: true });

    this.props.onDelete({
      object: object,
      typeName: typeName,
      replaceWith: this.state.selected,
      isAttached: !!object.total,
    });
  };

  render() {
    const { onClose, intl, classes, object, typeName, types } = this.props;

    return (
      <AdminDialog open closeButton onClose={onClose}>
        <AdminDialogTitle title={intl.formatMessage({ id: 'global.delete' })} />
        <AdminDialogContent className={classes.wrapper}>
          {this.state.loading && <Spinner size={50} />}

          {object.total > 0 && types.length > 1 && (
            <p className={classes.text}>
              {intl.formatMessage(
                { id: 'messages.deleteProductSharedProperty' },
                {
                  typeName: intl.formatMessage({ id: `products.props.column.${typeName}` }).toLowerCase(),
                  name: object.name,
                  count: object.active,
                }
              )}
            </p>
          )}

          {object.total === 0 && types.length > 1 && (
            <p style={{ textAlign: 'center' }} className={classes.text}>
              {intl.formatMessage(
                { id: 'messages.confirmProductTypeDelete' },
                {
                  typeName: intl.formatMessage({ id: `products.props.column.${typeName}` }).toLowerCase(),
                  name: object.name,
                }
              )}
            </p>
          )}

          {types.length === 1 && (
            <p style={{ textAlign: 'center' }} className={classes.text}>
              {intl.formatMessage(
                { id: 'messages.productTypeCanNotBeDeleted' },
                {
                  typeName: intl.formatMessage({ id: `products.props.column.${typeName}` }),
                }
              )}
            </p>
          )}

          {object.total > 0 && types.length > 0 && (
            <ProductSharedPropertySelect
              onSelect={(type) => this.setState({ selected: type })}
              types={types}
              skip={object}
              selected={this.state.selected}
            />
          )}

          <AdminDialogControls>
            {this.state.selected && (
              <PrimaryButton className={classes.button} onClick={() => this.delete()}>
                {intl.formatMessage({ id: 'global.delete' })}
              </PrimaryButton>
            )}
          </AdminDialogControls>
        </AdminDialogContent>
      </AdminDialog>
    );
  }
}

DeleteProductSharedPropertyDialog.propTypes = propTypes;
DeleteProductSharedPropertyDialog.defaultProps = defaultProps;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(DeleteProductSharedPropertyDialog)));
