import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import FiltersProcessor from '../../components/AdminDXTable/ClientSideFilters/FiltersProcessor';
import DXFiltersFactory from '../../components/AdminDXTable/Filters/DXFiltersFactory';
import {
  ACTIVITY_FILTER,
  CUSTOMER_ALLOW_CREDIT_FILTER,
  CUSTOMER_APPROVED_FILTER,
  CUSTOMER_DEFAULT_PAYMENT_METHOD_FILTER,
  CUSTOMER_TYPE_FILTER,
  CUSTOMER_TYPE_FILTER_FROM_VARIABLE,
  DATE_FILTER,
  HAS_SUBSTRING_FILTER,
  IMAGE_FILTER,
  IN_ARRAY_FILTER,
} from '../../components/AdminDXTable/ClientSideFilters/FilterTypes';
import { CUSTOMER_TYPE } from '../../helpers';

export const TableCell = (reload, { row, ...props }) => {
  return <Table.Cell {...props} />;
};

export const approvedFilter = (intl, filters) => {
  filters = filters || [];

  if (!filters.some((filter) => filter.type === 'customerApproved')) {
    const Filter = DXFiltersFactory.getFilterByName('customerApproved');

    filters.push(Filter.getInitialConfig(intl, ['true'], true));
  }

  return filters;
};

export const activeFilter = (intl, filters) => {
  filters = filters || [];

  if (!filters.some((filter) => filter.type === 'activity')) {
    const Filter = DXFiltersFactory.getFilterByName('activity');

    filters.push(Filter.getInitialConfig(intl, ['true'], true, true));
  }
  return filters;
};

export const customerTypeFilter = (intl, type = CUSTOMER_TYPE.RETAIL, filters) => {
  filters = filters || [];

  if (!filters.some((filter) => filter.type === 'type')) {
    const Filter = DXFiltersFactory.getFilterByName('type');

    filters.push(Filter.getInitialConfig(intl, [type], true, true));
  }
  return filters;
};

export const filtersProcessor = (intl, filters, rows, push) => {
  let filtered = [];
  rows.forEach((row) => {
    let processor = new FiltersProcessor(intl, filters);
    if (processor.isSatisfy(row)) {
      filtered.push(row);
    }
  });
  push(filtered);
};

export const clearClientSideFilters = (filters) => {
  const oldFilters = [
    CUSTOMER_APPROVED_FILTER,
    CUSTOMER_TYPE_FILTER,
    CUSTOMER_TYPE_FILTER_FROM_VARIABLE,
    CUSTOMER_ALLOW_CREDIT_FILTER,
    CUSTOMER_DEFAULT_PAYMENT_METHOD_FILTER,
    HAS_SUBSTRING_FILTER,
    IN_ARRAY_FILTER,
    ACTIVITY_FILTER,
    DATE_FILTER,
    IMAGE_FILTER,
  ];
  return filters.filter((filter) => !oldFilters.includes(filter.name));
};
