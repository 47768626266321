import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { COLORS } from '../../helpers';

const SAVE = 'SAVE';
const SAVE_AS_NEW = 'SAVE_AS_NEW';

const styles = {
  label: {
    fontSize: 12,
    color: COLORS.text,
    flexDirection: 'column',
  },
  button: {
    marginLeft: 9,
    marginRight: 9,
    padding: 0,
    '&:hover': {
      background: 'none',
    },
  },
  icon: {
    color: COLORS.black,
  },
};

const propTypes = {
  label: PropTypes.string,
  allowCreateTab: PropTypes.bool,
  onCreateTab: PropTypes.func,
  onUpdateTab: PropTypes.func,
};

const defaultProps = {
  allowCreateTab: false,
};

class SaveTabMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = (value) => {
    const { onUpdateTab, onCreateTab } = this.props;

    switch (value) {
      case SAVE: {
        onUpdateTab && onUpdateTab();
        break;
      }
      case SAVE_AS_NEW: {
        onCreateTab && onCreateTab();
        break;
      }
      default: {
        break;
      }
    }

    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { intl, classes, allowCreateTab, label } = this.props;
    const open = Boolean(anchorEl);

    return (
      <React.Fragment>
        <IconButton
          aria-label={intl.formatMessage({ id: 'global.more' })}
          aria-owns={open ? 'long-menu' : undefined}
          aria-haspopup="true"
          classes={{ root: classes.button, label: classes.label }}
          onClick={this.handleClick}>
          <SaveIcon className={classes.icon} />
          {label || ''}
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              width: 200,
            },
          }}>
          <MenuItem key={SAVE} onClick={() => this.handleClose(SAVE)}>
            {intl.formatMessage({ id: 'global.save' })}
          </MenuItem>

          {allowCreateTab && (
            <MenuItem key={SAVE_AS_NEW} onClick={() => this.handleClose(SAVE_AS_NEW)}>
              {intl.formatMessage({ id: 'global.saveAsNew' })}
            </MenuItem>
          )}
        </Menu>
      </React.Fragment>
    );
  }
}

SaveTabMenu.propTypes = propTypes;
SaveTabMenu.defaultProps = defaultProps;

export default injectIntl(withStyles(styles)(SaveTabMenu));
